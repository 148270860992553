import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  Matter,
  DEFAULT_MATTER
} from '../../../services/matters/matters.service';

export interface MatterHandle {
  data: Matter;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isActive: boolean;
  };
} // end MatterHandle{}

@Component({
  selector: 'app-datalist-matter',
  templateUrl: './datalist-matter.component.html',
  styleUrls: ['./datalist-matter.component.scss']
})
export class DatalistMatterComponent implements OnInit, OnChanges {

  @Input() matter: Matter;
  @Input() list: Matter[];
  @Output() matterChange: EventEmitter<Matter> = new EventEmitter<Matter>();

  matterList: MatterHandle[] = [];
  isActive: boolean;
  iconClass: string;

  private timeoutId;
  private blurDebounceTime = 300;

  constructor() { } // end constructor()

  /**
   * Fired when the datalist input has focus and the user releases a
   * depressed key.
   * @param {KeyboardEvent} event The keyboard event.
   */
  onKeyUp(event) {
    this.filterMatterList();
    this.showList();
  } // end onKeyUp()

  ngOnInit() {
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.matter && !changes.matter.currentValue) {
      this.matter = Object.assign({}, DEFAULT_MATTER);
    }

    if (changes.list && changes.list.currentValue) {
      this.buildMatterList();
    }
  } // end ngOnChanges()

  onBlur() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
        this.hideList();
      }, this.blurDebounceTime);
    // this.hideList();
  } // end onBlur()

  onFocus() {
    this.filterMatterList();
    this.showList();
  } // end onFocus()

  onInput() {
    if (this.matter.Description) {
      this.filterMatterList();
    }
  } // end onInput()

  toggleDropdown() {
    this.isActive = !this.isActive;
  } // end toggleDropdown()

  onSelect(selectedMatter: MatterHandle) {
    this.matter = Object.assign({}, selectedMatter.data);
    this.matterChange.emit(this.matter);
  } // end onSelect()

  onKeydown(event) {
    // this.matter.Description = event.target.value;
    // this.matterChange.emit(this.matter);
    this.buildMatterList();
  } // end onKeydown()

  private hideList() {
    this.isActive = false;
  } // end hideList()

  private showList() {
    this.isActive = true;
  } // end hideList()

  private buildMatterList() {
    // Empty matterList
    this.matterList = [];

    // For each list item
    this.list.map(item => {
      // Create a MatterHandle object and add it to matterList
      this.matterList.push({
        data: item,
        state: {
          isVisible: true,
          isSelected: false,
          isActive: false
        }
      });
    });
  } // end buildMatterList()

  private filterMatterList() {
    if (this.matter && this.matterList) {
      this.matterList.forEach(item => {
        item.state.isVisible =
          Boolean(
            item.data.Description.match(
              new RegExp(this.matter.Description, 'gi')
            )
          );
      });
    }
  } // end filterMatterList()
} // end DatalistMatterComponent{}
