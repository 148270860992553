import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FromCalendarItemsService } from '../../../services/from-calendar-items/from-calendar-items.service';

@Component({
  selector: 'app-outlook-handler',
  templateUrl: './outlook-handler.component.html',
  styleUrls: ['./outlook-handler.component.scss']
})
export class OutlookHandlerComponent implements OnInit {

  constructor(private route: ActivatedRoute, private fromCalendarItemsService: FromCalendarItemsService) { }
  openedWindow: any;

  ngOnInit(): void {

    let authCode = this.route.snapshot.queryParams['code'];
    let error = this.route.snapshot.queryParams['error'];

    if (error) {
      this.fromCalendarItemsService.sendAuthCode(false);
      window.close();
    }
    let scopes = localStorage.getItem("Scopes");
    this.fromCalendarItemsService.generateAccessToken(authCode, scopes).subscribe({
      next: (res: any) => {
        this.fromCalendarItemsService.sendAuthCode(true);
        window.close();
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => { }
    });
  }
}