import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { ApiService } from '../../../services/api.service';
import { MessagesService } from '../../../services/messaging/messages/messages.service';
import { ReportsService } from '../../../services/reports/reports.service';

@Component({
  selector: 'app-client-statement-modal',
  templateUrl: './client-statement-modal.component.html',
  styleUrls: ['./client-statement-modal.component.scss']
})
export class ClientStatementModalComponent implements AfterViewInit {

  // title = 'Statement of Account';
  _fileName;
  reportUrl: string;

  @Input() isVisible: Boolean;
  @Input() messageId: number;
  @Input() title: string;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('pdfViewer') public pdfViewer;

  constructor(
    private api: ApiService,
    private ReportService: ReportsService,
    private loadingService: LoadingService,
    private MessageService: MessagesService
  ) { } // end construction()

  ngAfterViewInit() {
    this.loadingService.showOverlay();

    this.reportUrl = this.api.endpoints.statementDocumentReport +
    '=' + this.messageId;

    this.ReportService.getReports(this.reportUrl).subscribe({
      next: (res) => {
      this.pdfViewer.name = 'Statement.pdf';
      this.pdfViewer.pdfSrc = res;
      this.pdfViewer.refresh();
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On Complete
      this.loadingService.hideOverlay();
    }
  });
  } // end ngOnInit()

  dismiss() {
    this.closeModal.emit('statement');
  } // end dismiss()

  @Input()
  set fileName(fileName: string) {
    this._fileName = fileName;
  }
  get fileName() {
    return this._fileName;
  }

} // end ClientStatementModalComponent{}
