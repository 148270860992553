import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AgeAnalysisData } from '../../../services/age-analysis/age-analysis.service';
import { AdvocateDetailStatementService } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { Router } from '@angular/router';
import { PATH_ATTORNEY_ALL_CUSTOMER } from '../../../services/appdata/app-config.service';

@Component({
  selector: 'app-age-analysis-attorney',
  templateUrl: './age-analysis-attorney.component.html',
  styleUrls: ['./age-analysis-attorney.component.scss']
})
export class AgeAnalysisAttorneyComponent implements OnInit, OnChanges {

  isDataLoaded = false;
  agingCurrentTotal = 0;
  aging30Total = 0;
  aging60Total = 0;
  aging90Total = 0;
  aging98Total = 0;
  agingCurrentPercentage = 0;
  aging30Percentage = 0;
  aging60Percentage = 0;
  aging90Percentage = 0;
  aging98Percentage = 0;
  renderTooltipOverlay = true;
  totalOutstanding = 0;
  _currentTooltipData: any = [];
  _30TooltipData: any = [];
  _60TooltipData: any = [];
  _90TooltipData: any = [];
  _98TooltipData: any = [];
  _allTooltipData: any = [];
  agePercentageColor = '';
  apiData: any = [];
  _data: any = [];

  @Input() data: AgeAnalysisData;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private advocateDetailStatementService: AdvocateDetailStatementService,
  ) { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    this.getAgeAnalysisReport();
  } // end ngOnChanges()

  getAgeAnalysisReport(): void {
    this.isDataLoaded = false;
    if (this.data) {
      this.agingCurrentPercentage = Math.round(this.data.ZeroDays * 100 / this.data.Total);
      this.aging30Percentage = Math.round(this.data.ThirtyDays * 100 / this.data.Total);
      this.aging60Percentage = Math.round(this.data.SixtyDays * 100 / this.data.Total);
      this.aging90Percentage = Math.round(this.data.NinetyDays * 100 / this.data.Total);
      this.aging98Percentage = Math.round(this.data.NinetyEightDays * 100 / this.data.Total);

      const agingData: any = {
        aging30Total: this.data.ThirtyDays,
        aging60Total: this.data.SixtyDays,
        aging90Total: this.data.NinetyDays,
        aging98Total: this.data.NinetyEightDays,
        agingCurrentTotal: this.data.ZeroDays,
        totalOutstanding: this.data.Total,
      };
      this.aging30Total = this.data.ThirtyDays;
      this.aging60Total = this.data.SixtyDays;
      this.aging90Total = this.data.NinetyDays;
      this.aging98Total = this.data.NinetyEightDays;
      this.agingCurrentTotal = this.data.ZeroDays;
      this.totalOutstanding = this.data.Total;

      if (this.aging98Percentage <= 25) {
        this.agePercentageColor = '#8abd37';
      } else if (this.aging98Percentage >= 26 && this.aging98Percentage <= 49) {
        this.agePercentageColor = '#e5c100';
      } else if (this.aging98Percentage >= 50 && this.aging98Percentage <= 75) {
        this.agePercentageColor = '#eb9e02';
      } else if (this.aging98Percentage >= 76) {
        this.agePercentageColor = '#cf624f';
      }
      this._data.push(agingData);
    }
    this.isDataLoaded = true;
    this.getTooltipData();
  } // end getAgeAnalysisReport()

  getBarHeight(value: number): string {
    return (value * 150 / 100) + 'px';
  } // end getBarHeight()

  getBarTextColor(value: number): string {
    const height = (value * 150 / 100);
    if (height < 17) {
      return '#000';
    }
    return '#fff';
  } // end getBarTextColor()

  getBarTextTopMargin(value: number): string {
    const height = (value * 150 / 100);
    if (height < 17) {
      return '-20px';
    }
    return ((height / 2) - 9) + 'px';
  } // end getBarTextTopMargin()


  getTooltipData(): void {
    this.renderTooltipOverlay = false;
    this.advocateDetailStatementService.getAllAdvocatesReport().subscribe({
      next: (response) => {
      this.apiData = response;
      this._currentTooltipData = this.setTooltipData('CurrentTotals');
      this._30TooltipData = this.setTooltipData('ThirtyDaysTotals');
      this._60TooltipData = this.setTooltipData('SixtyDaysTotals');
      this._90TooltipData = this.setTooltipData('NinetyDaysTotals');
      this._98TooltipData = this.setTooltipData('NinetyEightDaysTotals');
      this._allTooltipData = this.setTooltipData('AllTotals');
    },
      error: (error) => {
        // Error
      },
      complete: () => {
        this.loadingService.hideOverlay();
        this.renderTooltipOverlay = true;
      }
    });
  } // end getTooltipData()

  setTooltipData(key: string): any {
    this.apiData.CurrentTotals.sort((a, b) => b.Outstanding - a.Outstanding);
    this.apiData.ThirtyDaysTotals.sort((a, b) => b.Outstanding - a.Outstanding);
    this.apiData.SixtyDaysTotals.sort((a, b) => b.Outstanding - a.Outstanding);
    this.apiData.NinetyDaysTotals.sort((a, b) => b.Outstanding - a.Outstanding);
    this.apiData.NinetyEightDaysTotals.sort((a, b) => b.Outstanding - a.Outstanding);
    this.apiData.AllTotals.sort((a, b) => b.Outstanding - a.Outstanding);

    if (this.apiData[key].length > 10) {
      return this.apiData[key].slice(0, 10);
    } else {
      return this.apiData[key];
    }
  } // end setTooltipData()

  getBottomRow(index: number, length: number): string {
    if (length > 0 && index === length - 1) {
      return '20px';
    }
    return '0px';
  } // end getBottomRow()

  receiveToolTipAge(event) {
    this.advocateDetailStatementService.allCustomerActiveTab = event;
    this.router.navigate([PATH_ATTORNEY_ALL_CUSTOMER]);
  } // end receiveToolTipAge()
} // end AgeAnalysisAttorneyComponent{}
