import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { MattersService } from '../../../services/matters/matters.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { ReportsService } from '../../../services/reports/reports.service';

@Component({
  selector: 'app-client-matter-documents',
  templateUrl: './client-matter-documents.component.html',
  styleUrls: ['./client-matter-documents.component.scss']
})
export class ClientMatterDocumentsComponent implements AfterViewInit {

  _fileName;
  reportUrl: string;

  @Input() title: string;
  @Input() isVisible: boolean;
  @Input() serviceId: number;
  @Input() briefId: number;
  @Input() selectedFile: File;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('pdfViewer') public pdfViewer;

  constructor(
    private api: ApiService,
    private ReportService: ReportsService,
    private matterService: MattersService,
    private loadingService: LoadingService
  ) { } // end constructor()

  ngAfterViewInit() {
    this.loadingService.showOverlay();

    this.reportUrl = this.api.endpoints.matterDocument +
    '=' + this.briefId;

    this.ReportService.getReports(this.reportUrl).subscribe({
      next: (doc) => {
      this.pdfViewer.name = 'Matter Document.pdf';
      this.pdfViewer.pdfSrc = doc;
      this.pdfViewer.refresh();
    },
    error: (error) => {
      // on error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.loadingService.hideOverlay();
    }
  });
  } // end ngAfterViewInit()

  dismiss() {
    this.closeModal.emit('document');
  } // end dismiss()

  @Input()
  set fileName(fileName: string) {
    this._fileName = fileName;
  }
  get fileName() {
    return this._fileName;
  }

} // end ClientMatterDocumentsComponent{}
