import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvoiceDetails } from '../invoice-details/invoice-details.service';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';

export interface InvoiceMessage {
  InvoiceID: number;
  InvoiceDetails: InvoiceDetails;
  MessageTemplate: MessageTemplate;
  MessageSend: MessageSend;
} // end InvoiceMessage{}

export interface MessageTemplate {
  ID: number;
  InvoiceID: number;
  MessageDate: string;
  Source: string;
  SourceId: number;
  Recipients: string;
  EMailAddresses: string;
  Description: string;
  DocumentPath: string;
  ContactId: number;
  Status: string;
  Subject: string;
  Body: string;
  RolloverService: boolean;
  SendMessage: boolean;
  ServiceId: number;
  ClientName: string;
  InvoiceNote: string;
  InvoiceTotal: number;
  InvoiceParties: string;
  InvoiceDate: string;
  InvoiceHighestDate: string;
  InvoiceNo: string;
} // end MessageTemplate{}

export interface MessageSend {
  Id: number;
  InvoiceID: number;
  ServiceProviderID: number;
  MessageType: number;
  InvoiceDate: string;
  InvoiceNote: string;
  Recipients: string;
  EMailAddresses: string[];
  Subject: string;
  Body: string;
  InvoiceNumber: string;
  RolloverService: boolean;
  SendMessage: boolean;
}
export const DEFAULT_OUTBOX_MESSAGE_SEND: MessageSend = {
  Id: 0,
  InvoiceID: 0,
  ServiceProviderID: 0,
  MessageType: 0,
  InvoiceDate: '',
  InvoiceNote: '',
  Recipients: '',
  EMailAddresses: [],
  Subject: '',
  Body: '',
  InvoiceNumber: '',
  RolloverService: false,
  SendMessage: false,
};

export const DEFAULT_PREPARE_MESSAGE_SEND: MessageTemplate = {
  ID: 0,
  InvoiceID: 0,
  MessageDate: '',
  Source: '',
  SourceId: 0,
  Recipients: '',
  EMailAddresses: '',
  Description: '',
  DocumentPath: '',
  ContactId: 0,
  Status: '',
  Subject: '',
  Body: '',
  RolloverService: false,
  SendMessage: false,
  ServiceId: 0,
  ClientName: '',
  InvoiceNote: '',
  InvoiceTotal: 0,
  InvoiceParties: '',
  InvoiceDate: '',
  InvoiceHighestDate: '',
  InvoiceNo: ''
};

@Injectable()
export class InvoiceMessageService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  /**
   * GET: Get all Invoice and Message data for a particular Invoice.
   * @param {number} id The unique identifier of the invoice to be mailed.
   * @returns {Observable<*>} Returns initial Invoice and Message data which
   * will be used to pre-populate an email message for a given Invoice (ID)
   * This method returns several data objects in one message, thus avoiding
   * many round trips.
   */
  getInvoiceMessage(Ids: any): Observable<any> {
    return this.http.post(this.api.endpoints.invoiceMessage +
      '/GetInvoiceMessage', Ids, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getInvoiceMessage()

  /**
   * UPDATE: Update Invoice and Message for sending an Invoice via EMail.
   * This will allow updating of EMail Message data in preparation for sending an Invoice.
   *
   * All of these steps will performed in the single API call:
   *
   * - Update Invoice data
   * - Create Invoice PDF
   * - Change EMail Subject
   * - Change EMail Body
   * - Change Recipients
   * - Send EMail
   * @param {number} id The Message ID value.
   * @returns {Observable<*>} Returns initial Invoice and Message data which
   * will be used to pre-populate an email message for a given Invoice (ID)
   * This method returns several data objects in one message, thus avoiding
   * many round trips.
   */

  updateInvoiceMessage(id: number, message: MessageSend[]): Observable<any> {
    return this.http.put(this.api.endpoints.invoiceMessage +
      '?id=' + id, message, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end updateInvoiceMessage()

  /**
   * DELETE: Delete Message data.
   * @param {number} id The unique identifier of the invoice to be mailed.
   * @returns {Observable<*>} Returns initial Invoice and Message data which
   * will be used to pre-populate an email message for a given Invoice (ID)
   * This method returns several data objects in one message, thus avoiding
   * many round trips.
   */
  deleteInvoiceMessage(id: number): Observable<any> {
    return this.http.delete(this.api.endpoints.invoiceMessage +
      '?id=' + id, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end deleteInvoiceMessage()

  // generateDocument(InvoiceId: number): Observable<any> {
  //   return this.http.get(this.api.endpoints.invoiceMessage +
  //     '/GenerateDocument?InvoiceId=' + InvoiceId, this.api.httpOptions).pipe(
  //       retry(3),
  //       catchError(this.api.handleError)
  //     );
  // }

  generateDocument(InvoiceId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.api.endpoints.invoiceMessage +
        '/GenerateDocument?InvoiceId=' + InvoiceId, this.api.httpOptions).pipe(
          retry(3),
          catchError(error => {
            console.error('Error generating document:', error);
            reject(error);
            throw error;
          })
        ).subscribe((response) => {
          resolve(response);
        });
    });
  }
}
