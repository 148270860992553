<div *ngIf="isBasicInfoReceived">
    <div class="container card" style="background-color: #F5F6F6; width: 600px;">
        <div class="brand">
            <img [src]="imageLogo.path" [alt]="imageLogo.alt">
            <label>Registration</label>
        </div>
        <hr>
        <div *ngIf="inValidVarificationRequest && !isNewAccountCreated" class="row">
            <div class="col-sm-12">
                <h3 class="titleCancel">Something went wrong</h3>
                <div class="row icon-danger">
                    <i class="fal fa-times-circle fa-6x"></i>
                </div>
                <div class="message">
                    <span>{{varificationErrorMessage}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="isNewAccountCreated" class="row">
            <div class="col-sm-12">
                <h3 class="titleCancel">Registration Successful</h3>
                <div class="row icon-success">
                    <i class="fal fa-check-circle fa-6x"></i>
                </div>
                <div class="message">
                    <span>{{getCustomeSuccessfulMessage}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="!inValidVarificationRequest && !isNewAccountCreated">
            <div class="form-group">
                <label class="formLabel" for="emialId">Email Address</label>
                <input [value]="externalUserModel.EmailAddress" type="email" class="form-control formInput" id="emialId"
                    aria-describedby="emailHelp" placeholder="Enter email" readonly>
            </div>
            <md-input-container>
                <div class="form-group">
                    <label class="formLabel" for="UserFirstName">First Name</label>
                    <input type="text" (keypress)="onKeyUpFirstName($event)" mdInput name="name"
                        [value]="externalUserModel.UserFirstName" (blur)="onBlurFirstName($event)"
                        [(ngModel)]="externalUserModel.UserFirstName" class="form-control formInput"
                        id="UserFirstName" required>
                </div>           
                <div class="form-group">
                    <label class="formLabel" for="UserLastName">Last Name</label>
                    <input  type="text" (keypress)="onKeyUpLastName($event)"
                        [value]="externalUserModel.UserLastName" (blur)="onBlurLastName($event)"
                        [(ngModel)]="externalUserModel.UserLastName" class="form-control formInput"
                        id="UserLastName" required>
                </div>
            </md-input-container>          
        </div>
        <div class="container-footer" *ngIf="!inValidVarificationRequest">
            <button type="submit" *ngIf="isNewAccountCreated && !isOffice365User" (click)="onLogIn()"
                class="btn btn-success">Log In</button>
            <button type="submit" *ngIf="!isNewAccountCreated" (click)="onMicrosoftSign()"
                class="btn btn-success">Register</button>
        </div>
    </div>
</div>
<app-prompt-unsaved-changes-modal title="Warning Message" message="{{varificationErrorMessage}}" btnTextCancel="Cancel"
    btnTextContinue="Ok" [isVisible]="showWarningMessagePrompt" (cancel)="onWarningMessagePrompt()"
    (continue)="onWarningMessagePrompt()">
</app-prompt-unsaved-changes-modal>