<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">

<div class="contact-body row">
    <div class="left-block col-sm-6">
        <div class="block-title">Secretary</div>

    <!-- #start search -->
    <div class="row contact-search input-group">
    <input
    type="search"
    #inputCheckbox
    class="form-control"
    placeholder="Search for Law firm"
    [(ngModel)]="secretarySearchTerm"
    (keyup)="performSecretarySearch()">
    <div class="input-group-append">
        <i *ngIf="!secretarySearchTerm" class="far fa-search"></i>
        <i *ngIf="secretarySearchTerm" (click)="clearSecretarySearch()" class="far fa-close"></i>
    </div>
    </div> <!-- #end .row reconcile-search input-group -->

        <div class="row m-0">
            <div class="table-responsive" style="max-height: 400px; overflow-y: auto;">
                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <th scope="col" style="width: 30%;" (click)="toggleSortSecretary('name')">Name
                                <span><i class="fa fa-sort" [class.fa.sort-down]="sortSecretaryName"></i></span>
                            </th>
                            <th scope="col" style="width: 30%;" (click)="toggleSortSecretary('user-name')">User Name
                                <span><i class="fa fa-sort" [class.fa.sort-down]="sortSecretaryName"></i></span>
                            </th>
                            <th scope="col" style="width: 10%;" (click)="toggleSortSecretary('cell')">Cell Number 
                                <span><i class="fa fa-sort" [class.fa.sort-down]="sortSecretaryFax"></i></span>
                            </th>
                            <th scope="col" style="width: 20%;" (click)="toggleSortSecretary('email')">Email 
                                <span><i class="fa fa-sort" [class.fa.sort-down]="sortSecretaryEmail"></i></span>
                            </th>
                            <th scope="col" style="width: 2%;"></th>
                            <th scope="col" style="width: 2%;"></th>
                        </tr>
                        <tr class="tr" *ngFor="let secretary of secretaryHandle"
                            [class.active]="row == secretary.data.Id">
                            <td *ngIf="secretary.state.isVisible" style="width: 30%;" (click)="onSecretaryRowClick(secretary.data)">{{ secretary.data.FullName }}</td>
                            <td *ngIf="secretary.state.isVisible" style="width: 30%;" (click)="onSecretaryRowClick(secretary.data)">{{ secretary.data.UserName }}</td>
                            <td *ngIf="secretary.state.isVisible" style="width: 10%;"  (click)="onSecretaryRowClick(secretary.data)">{{ secretary.data.Cell }}</td>
                            <td *ngIf="secretary.state.isVisible" style="width: 20%;"  (click)="onSecretaryRowClick(secretary.data)">{{ secretary.data.Email }}</td>   
                            <td *ngIf="secretary.state.isVisible" style="width: 2%;"><i class="fal fa-eye" style="color: #8ABD37;"></i></td>         
                            <td *ngIf="secretary.state.isVisible" (click)="onEditSecretary(secretary.data)" style="width: 2%;"><i class="fal fa-edit" style="color: #E5C100;"></i></td>                    
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <button class="btn btn-success"
            (click)="addNewContact()">Add new Contact</button>
    </div>

    <div class="right-block col-sm-6">
        <div class="block-title">Advocates</div>
        <!-- #start search -->
        <div class="row contact-search input-group">
            <input
            type="search"
            #inputCheckbox
            class="form-control"
            placeholder="Search for an Advocate"
            [(ngModel)]="advocateSearchTerm"
            (keyup)="performAdvocateSearch()">
            <div class="input-group-append">
                <i *ngIf="!advocateSearchTerm" class="far fa-search"></i>
                <i *ngIf="advocateSearchTerm" (click)="clearAdvocateSearch()" class="far fa-close"></i>
            </div>
        </div> <!-- #end .row reconcile-search input-group -->

        <div class="row m-0">
            <div class="table-responsive" style="max-height: 400px; overflow-y: auto;">
                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <th scope="col" style="width: 20%;" (click)="toggleSortAdvocate('advocate-name')">Advocate Name
                                <span><i class="fa fa-sort" [class.fa.sort-down]="sortAdvocateName"></i></span>
                            </th>
                            <th scope="col" style="width: 15%;" (click)="toggleSortAdvocate('advocate-phone')">Phone Number
                                <span><i class="fa fa-sort" [class.fa.sort-down]="sortAdvocatePhone"></i></span>
                            </th>
                            <th scope="col" style="width: 15%;" (click)="toggleSortAdvocate('advocate-cell')">Cell Number 
                                <span><i class="fa fa-sort" [class.fa.sort-down]="sortAdvocateCell"></i></span>
                            </th>
                            <th scope="col" style="width: 20%;" (click)="toggleSortAdvocate('advocate-email')">Email 
                                <span><i class="fa fa-sort" [class.fa.sort-down]="sortAdvocateEmail"></i></span>
                            </th>
                            <th scope="col" style="width: 8%;" (click)="toggleSortAdvocate('advocate-email')">VAT Number 
                                <span><i class="fa fa-sort" [class.fa.sort-down]="sortAdvocateEmail"></i></span>
                            </th>
                            <th scope="col" style="width: 20%;" (click)="toggleSortAdvocate('advocate-email')">Street Address 
                                <span><i class="fa fa-sort" [class.fa.sort-down]="sortAdvocateEmail"></i></span>
                            </th>
                            <!-- <th scope="col" (click)="toggleSortAdvocate('advocate-email')">Postal Address 
                                <span><i class="fa fa-sort" [class.fa.sort-down]="sortAdvocateEmail"></i></span>
                            </th> -->
                            <!-- <th scope="col"></th> -->
                            <th scope="col" style="width: 2%;"></th>
                        </tr>
                        <tr class="tr" *ngFor="let advocate of advocatesHandle"
                            [class.active]="row == advocate.data.Id">
                            <td *ngIf="advocate.state.isVisible" style="width: 20%;">{{ advocate.data.FullName }}</td>
                            <td *ngIf="advocate.state.isVisible" style="width: 15%;">{{ advocate.data.Phone }}</td>
                            <td *ngIf="advocate.state.isVisible" style="width: 15%;">{{ advocate.data.Cell }}</td>
                            <td *ngIf="advocate.state.isVisible" style="width: 20%;">{{ advocate.data.Email }}</td>
                            <td *ngIf="advocate.state.isVisible" style="width: 8%;">{{ advocate.data.Vatno }}</td>
                            <td *ngIf="advocate.state.isVisible" style="width: 20%;">{{ advocate.data.StreetAddress }}</td>
                            <!-- <td *ngIf="advocate.state.isVisible" style="width: 20%;">{{ advocate.data.PostalAddress }}</td> -->
                            <!-- <td *ngIf="advocate.state.isVisible" style="width: 2%;" class="clickable"><i class="fal fa-eye" style="color: #8ABD37;"></i></td>          -->
                            <td *ngIf="advocate.state.isVisible" style="width: 2%;" class="clickable" (click)="onRemoveAdvocate(advocate.data)"><i class="fal fa-minus-circle" style="color: #CF624F;"></i></td>                    
                        </tr>
                    </tbody>
                </table>
            </div>
            <button class="btn btn-primary"
            [class.disabled]="!canAssignAdvocate"
            [disabled]="!canAssignAdvocate"
            (click)="assignNewAdvocate()">Assign Advocate</button>
        </div>
    </div>
</div>

<app-assign-advocate-to-secretary
    *ngIf="showAssignAdvocate"
    [isVisible]="showAssignAdvocate"
    [advocateList]="advocateList"
    [selectedSecretary]="selectedSecretary"
    (closeModal)="onCloseModal($event)"
    (select)="onAssignedAdvocate($event)"></app-assign-advocate-to-secretary>

<app-add-new-secretary
    *ngIf="showAddNewSecretary"
    [isVisible]="showAddNewSecretary"
    [selectedSecretary]="selectedSecretary"
    (closeModal)="onCloseModal($event)"
    (onSaveSecretary)="onSaveSecretary($event)"></app-add-new-secretary>

<app-prompt-unsaved-changes-modal
    title="Remove the Advocate"
    [message]="message"
    [isVisible]="showRemoveAdvocatePrompt"
    (cancel)="onHideRemoveAdvocatePrompt()"
    btnTextCancel="No, Cancel"
    btnTextContinue="Yes, Save"
    (continue)="onRemoveAdvocateFromSecretary()"></app-prompt-unsaved-changes-modal>