import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
const TITLE_WRITE_OFF = 'Write-off';
import {
  DEFAULT_WRITEOFF_TRANSACTIONS_LINE,
  WriteOffTransactionsLines,
  ProcessPaymentService
} from '../../../services/process-payment/process-payment.service';
import * as moment from 'moment';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { InvoiceDetails } from '../../../services/invoice-details/invoice-details.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

@Component({
  selector: 'app-write-off-outstanding-modal',
  templateUrl: './write-off-outstanding-modal.component.html',
  styleUrls: ['./write-off-outstanding-modal.component.scss']
})
export class WriteOffOutstandingModalComponent implements OnInit {

  title = TITLE_WRITE_OFF;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Input() invoiceDetails: InvoiceDetails;
  @Input() invoiceId: number;
  writeOff: any = {
    Notes: ""
  }
  btnText: string = "ADD Write-Off";

  paymentAndCredits: number = 0;

  toWriteOffTransactionLine: WriteOffTransactionsLines = Object.assign({}, DEFAULT_WRITEOFF_TRANSACTIONS_LINE);

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()
  constructor(
    private processPaymentService: ProcessPaymentService,
    private userProfileService: UserProfileService,
    private loadingService: LoadingService,
  ) { }

  ngOnInit() {
    this.paymentAndCredits = this.invoiceDetails.TotalPaidAmount + this.invoiceDetails.TotalCreditAmount;
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  dismiss() {
    this.closeModal.emit('write-off');
  } // end dismiss()

  onAddWriteOff() {
    this.loadingService.showOverlay();

    this.toWriteOffTransactionLine.Id = 0;
    this.toWriteOffTransactionLine.TransactionDate = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
    this.toWriteOffTransactionLine.InvoiceType = "WriteOff";
    this.toWriteOffTransactionLine.Description = this.writeOff.Notes;
    this.toWriteOffTransactionLine.Amount = this.invoiceDetails.OutstandingBalance;
    this.toWriteOffTransactionLine.ServiceInvoice_Transaction = this.invoiceId;
    this.toWriteOffTransactionLine.Contact_Transaction = 0;

    this.processPaymentService.putWriteOffBankTransactions(this.toWriteOffTransactionLine).subscribe({next: (_next) => {

    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
        // On complete
        this.closeModal.emit('write-off');
        this.loadingService.hideOverlay();
      }
    });
  }

  isNotes: boolean = true;
  onWriteOffNotesBlur() {
    if (!this.writeOff.Notes) {
      this.isNotes = false;
    } else {
      this.isNotes = true;
    }
  }

}
