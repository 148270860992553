import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserProfile,
         UserProfileService 
       } from '../../services/user-profile/user-profile.service';
import { UtilitiesService } from '../../services/utilities.service';
import { UsercurrencyPipe } from '../../pipe/usercurrency.pipe';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-edit-my-profile',
  templateUrl: './edit-my-profile.component.html',
  styleUrls: ['./edit-my-profile.component.scss']
})
export class EditMyProfileComponent implements OnInit {

  token: string | null = null;
  specialities: any;
  professionalLevels: any;
  userProfile: UserProfile;

  get hasFirstName(): Boolean {
    return !this.util.hasTextValue(this.userProfile.personalDetails.firstName);
  } // end hasFirstName()

  get hasLastName(): Boolean {
    return !this.util.hasTextValue(this.userProfile.personalDetails.lastName);
  } // end hasLastName()

  get hasCellNumberError(): boolean {
    return !this.util.isValidPhoneNumber(this.userProfile.contactDetails.mobileNumber,
      { code: this.userProfile.currencyDetails.code });
  } // end hasCellNumberError()

  onPhoneNumberInputChange(event) {
    // this.util.formatPhoneNumberInput(event.target);
  } // end onPhoneNumberInputChange()

  onPhoneNumberInputFocus(event) {
    // this.util.formatPhoneNumberInput(event.target);
  } // end onPhoneNumberInputFocus()

  get hasContactEmailError(): boolean {
    return !this.util.isValidEmail(this.userProfile.contactDetails.emailAddress);
  } // end hasContactEmailError()

  get currencySymbol(): string {
    return this.userProfile.currencyDetails.symbol + ' ';
  } // end currencySymbol()

  loadPage: boolean = false;

  @ViewChild('inputHourly') focusHourly: ElementRef;
  @ViewChild('inputDaily') focusDaily: ElementRef;
  @ViewChild('inputPerKilometer') focusPerKilometer: ElementRef;
  @ViewChild('inputFeeTarget') focusFeeTarget: ElementRef;
  @ViewChild('inputIncomeTarget') focusIncomeTarget: ElementRef;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService
  ) { } // end constructor()


  ngOnInit(): void {
     this.token = this.route.snapshot.paramMap.get('token');

    if (this.token) {
      localStorage.setItem('authToken', this.token); // Store securely
    } else {
      
    }
    // console.log('Token received:', this.token);
    this.getProfile();
    this.professionalLevels = [
      { label: 'Junior', value: 'Junior' },
      { label: 'Senior', value: 'Senior' },
    ];

    this.specialities = [
      { label: 'Banking and Finance Law', value: 'Banking and Finance Law' },
      { label: 'Corporate and Commercial Law', value: 'Corporate and Commercial Law' },
      { label: 'Employment Law', value: 'Employment Law' },
      { label: 'Environmental and Mining Law', value: 'Environmental and Mining Law' },
      { label: 'Family and Matrimonial Law', value: 'Family and Matrimonial Law' },
      { label: 'General Litigation', value: 'General Litigation' },
      { label: 'Insolvency Law', value: 'Insolvency Law' },
      { label: 'Intellectual Property Law', value: 'Intellectual Property Law' },
      { label: 'Personal Injury Law', value: 'Personal Injury Law' },
      { label: 'Property Law', value: 'Property Law' },
      { label: 'Tax Law', value: 'Tax Law' },
      { label: 'Other', value: 'Other' },
    ];
  } // end ngOnInit()

  
  getProfile() {
    this.loadingService.showOverlay();
    this.userProfileService.getUserProfileForMobile().subscribe({
      next:
        (profile: UserProfile) => {
          
          this.loadPage = true;
            const fullName = profile.personalDetails && profile.personalDetails.fullName
              ? profile.personalDetails.fullName.split(' ')
              : [];
            if (fullName.length) {
              if (fullName.length === 2) {
                profile.personalDetails.firstName = fullName[0];
                profile.personalDetails.lastName = fullName[1];
              } else if (fullName.length > 2) {
                profile.personalDetails.title = fullName[0];
                profile.personalDetails.firstName = fullName[1];
                profile.personalDetails.lastName = fullName[fullName.length - 1];
              }
            }
            this.userProfile = profile;
        },
      error: (error) => {
        console.error('ERROR GETTING TEH PROFILE.', error);
        this.loadingService.hideOverlay();
      },
      complete: () => { 
        this.loadingService.hideOverlay();       
      }
    });
  } // end getProfile()

  save() {
    this.loadingService.showOverlay();
    this.api.serviceProviderID = this.userProfile.serviceProviderID;
    console.log('UPDATE PROFILE: ', this.userProfile);
    
    this.userProfileService
      .updateUserProfile(this.userProfile).subscribe({
        next: (profile) => {
          // On next: returns empty body in this case
        },
        error: (error) => {
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
        this.loadingService.hideOverlay()
        }
      });
  } // end save()

  onFeeTargetChange() {
    this.focusFeeTarget.nativeElement.value = this.cpipe.transform(this.focusFeeTarget.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol, 2);
  } // end onFeeTargetChange()

  onFeeTargetClicked() {
    this.focusFeeTarget.nativeElement.value = this.cpipe.parse(this.focusFeeTarget.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol);
  } // end onFeeTargetClicked()

  onIncomeTargetChange() {
    this.focusIncomeTarget.nativeElement.value = this.cpipe.transform(this.focusIncomeTarget.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol, 2);
  } // end onIncomeTargetChange()

  onIncomeTargetClicked() {
    this.focusIncomeTarget.nativeElement.value = this.cpipe.parse(this.focusIncomeTarget.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol);
  } // end onIncomeTargetClicked()

  onHourlyRateChange() {
    this.focusHourly.nativeElement.value = this.cpipe.transform(this.focusHourly.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol, 2);
  } // end onHourlyRateChange()

  onHourlyRateClicked() {
    this.focusHourly.nativeElement.value = this.cpipe.parse(this.focusHourly.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol);
  } // end onHourlyRateClicked()

  onDailyRateChange() {
    this.focusDaily.nativeElement.value = this.cpipe.transform(this.focusDaily.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol, 2);
  } // end onDailyRateChange()

  onDailyRateClicked() {
    this.focusDaily.nativeElement.value = this.cpipe.parse(this.focusDaily.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol);
  } // end onDailyRateClicked()

  onPerkilometerRateChange() {
    this.focusPerKilometer.nativeElement.value = this.cpipe.transform(this.focusPerKilometer.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol, 2);
  } // end onPerkilometerRateChange()

  onPerkilometerRateClicked() {
    this.focusPerKilometer.nativeElement.value = this.cpipe.parse(this.focusPerKilometer.nativeElement.value,
      this.userProfileService.userProfile.currencyDetails.symbol);
  } // end onPerkilometerRateClicked()

} // end ()
