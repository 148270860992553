import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  NotesObject,
  InstructionResponses,
  DEFAULT_INSTRUCTION_RESPONSES,
  Notes,
  NotesService
} from '../../../services/notes/notes.service';
// import { TypeaheadMatch } from 'ngx-bootstrap';
import { InvoiceDetails } from '../../../services/invoice-details/invoice-details.service';
import { LawfirmSummaryOverview } from '../../../services/reports/reports.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import * as moment from 'moment';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-advocate-note-modal',
  templateUrl: './advocate-note-modal.component.html',
  styleUrls: ['./advocate-note-modal.component.scss']
})
export class AdvocateNoteModalComponent implements OnInit {

  title: string;
  selectedResponseText: string;
  instructionText: string;
  selectedResponse: InstructionResponses;

  @Input() isVisible: boolean;
  @Input() defendant: string;
  @Input() plaintiff: string;
  @Input() objectType: string;
  @Input() selectedNote: NotesObject;
  @Input() invoiceDetails: InvoiceDetails;
  @Input() lawfirmSummaryData: LawfirmSummaryOverview;
  @Input() instructionResponses: InstructionResponses[];
  @Output() closemodal: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveInstruction: EventEmitter<any> = new EventEmitter<any>();

  get isSaveable(): boolean {
    return Boolean(this.selectedResponse && this.selectedResponse.Id > 0) &&
          Boolean(this.selectedResponseText);
  } // end isSaveable()

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private notesService: NotesService,
    private userProfileService: UserProfileService
  ) { } // end construction()

  ngOnInit() {
    this.title = this.selectedNote.Subject + ' ' + this.selectedNote.ObjectTypeDescription;
  } // end ngOnInit()

  onCancel() {
    this.closemodal.emit();
  } // end onCancel()

  onInputResponse() {
    // To do, Add invoice details and law firm summary view html view of this component.
  } // end onInputResponse()

  onNoteResponseSelect(event: TypeaheadMatch): void {
    if (event) {
      this.selectedResponse = Object.assign({}, DEFAULT_INSTRUCTION_RESPONSES);
      this.selectedResponse = event.item;
      this.selectedResponseText = event.item.Response;
    }
  } // end onNoteResponseSelect()

  onNoteSave() {
    const noteToSave: Notes = {
      Id: this.selectedNote.Id,
      ObjectId: this.selectedNote.ObjectId,
      Subject: this.selectedNote.Subject,
      NoteTypeId: this.selectedNote.NoteTypeId,
      InternalText: this.selectedNote.InternalText,
      ExternalText: this.selectedNote.ExternalText,
      UserOwner: this.selectedNote.UserOwner,
      UserCreator: this.selectedNote.UserCreator,
      UserAssigned: this.selectedNote.UserAssigned,
      Priority: this.selectedNote.Priority,
      CaptureDate: this.selectedNote.CaptureDate,
      ActionDate: this.selectedNote.ActionDate,
      ActivityDate: this.selectedNote.ActivityDate,
      InstructionResponseId: this.selectedResponse.Id,
      InstructionText: this.instructionText,
      ResolutionText: '',
      MutliObjects: null,
      InternalInstructionNote: this.selectedNote.InternalInstructionNote,
      InstructionCaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      ResolutionCaptureDate: null,
      IsVisibleUserOwner: false,
      IsVisibleUserCreator: false,
      IsVisibleUserAssigned: true
    };
    this.selectedNote.UserOwnerStatus = false;
    this.selectedNote.UserCreatorStatus = false;
    this.selectedNote.UserAssignedStatus = true;
    //  this.notesService.putNoteInboxStatus(this.selectedNote).toPromise();
    this.saveInstruction.emit(noteToSave);
    this.closemodal.emit();
  } // end onNoteSave()

} // end AdvocateNoteModalComponent{}
