import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { UserProfileService, UserProfile, ContactSubscriptions } from '../../services/user-profile/user-profile.service';
import {
  PATH_DASHBOARD,
  PATH_ATTORNEY_DASHBOARD,
  PATH_PRACTICE_MANAGER_DASHBOARD,
  PATH_MANAGE_BLACKLIST,
  PATH_REPORTS,
  PATH_PRACTICE_MANAGER_REPORTS
} from '../../services/appdata/app-config.service';
import { Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { ActivityLogs, DEFAULT_ACTIVITY_LOGS } from '../../services/activity-logs/activity-logs.service';
import * as moment from 'moment';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, EventMessage, EventType } from '@azure/msal-browser';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-advocate-sign-in-loading',
  templateUrl: './advocate-sign-in-loading.component.html',
  styleUrls: ['./advocate-sign-in-loading.component.scss']
})
export class AdvocateSignInLoadingComponent implements OnInit {
  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    LAWFIRM: 'Lawfirm',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTENAL_LAWFIRM: 'Lawfirm (External)',
    EXTENAL_ATTORNEY: 'Attorney (External)',
    BAR_SOCIETY: 'Bar Society - User',
    GCB: 'GCB - User',
    ADHOC: 'Ad-hoc',
    HEAD_OF_STATE: 'Head of State'
  };
  loginDisplay = false;
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);
  private readonly _destroying$ = new Subject<void>();
  subscriptions: Subscription[] = [];
  contactSubscriptions: ContactSubscriptions = {
    contactID: 0,
    subscriptionPlanID: 0,
    freeTrialDays: 0,
    startDate: "",
    nextBillingDate: "",
    status: ""
  };
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private router: Router,
    private api: ApiService,
    private authServices: AuthService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) { } // end constructor()

  async ngOnInit() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(async () => {
        try {
          await this.checkAndSetActiveAccount();
        }
        catch (e) {

        }
        if (!this.authService.instance.getActiveAccount() || !localStorage.getItem('msal.token')) {
          this.authServices.login();
          return;
        }
        await this.getProfileAndRedirect();
      });


    // if (auth_yes) { // Checking if the user is authenticated,
    //   // then sign the user in if true, sign the user out if false.

    //   // Geting the user profile.

    // } else {
    //   this.loadingService.hideOverlay();
    //   sessionStorage.removeItem('userProfile');
    //   sessionStorage.clear();
    //   localStorage.clear(); // TODO: Move to sign out page
    //   this.api.serviceProviderID = 0;
    //   // this.adalService.logOut();`
    //   //  this.router.navigate([PATH_SIGN_IN]);

    //   this.loginRedirect();
    // }


  } // end ngOnInit()

  getProfileAndRedirect() {
    this.userProfileService.getUserProfile().subscribe({
      next:
        (profile: UserProfile) => {
          if (profile.isImpersonator === true) {
            if (profile.profileType === this.profileTypes.ASSISTANT) { // checking what kind of profile is impersonated: (Assistant)
              this.api.serviceProviderID = profile.impersonates[0].serviceProviderID;
              const advocateUser = profile.impersonates[0];

              const fullName = profile.personalDetails && profile.personalDetails.fullName
                ? profile.personalDetails.fullName.replace('Advocate', '').split(' ')
                : [];
              const advfullName = advocateUser.personalDetails && advocateUser.personalDetails.fullName
                ? advocateUser.personalDetails.fullName.replace('Advocate', '').split(' ')
                : [];

              if (fullName.length) {
                profile.personalDetails.firstName = fullName[0];
                profile.personalDetails.lastName = profile.personalDetails.fullName.replace(fullName[0], '');
              }
              if (advfullName.length) {
                advocateUser.personalDetails.firstName = advfullName[0];
                advocateUser.personalDetails.lastName = profile.personalDetails.fullName.replace(fullName[0], '');
              }
              sessionStorage.setItem('advocateUserProfil', JSON.stringify(advocateUser));
              sessionStorage.setItem('userProfile', JSON.stringify(profile));
              this.userProfileService.userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
              this.userProfileService.selectedUserProfile = advocateUser;

            } else if (profile.profileType === this.profileTypes.PRACTICE_MANAGER) {
              // checking what kind of profile is impersonated: (Practice Manager)
              this.api.serviceProviderID = profile.impersonates.
                filter(pm => pm.serviceProviderID === profile.serviceProviderID)[0].serviceProviderID;
              const advocateUser = profile.impersonates.
                filter(pm => pm.serviceProviderID === profile.serviceProviderID)[0];

              const fullName = profile.personalDetails && profile.personalDetails.fullName
                ? profile.personalDetails.fullName.replace('Advocate', '').split(' ')
                : [];
              const advfullName = advocateUser.personalDetails && advocateUser.personalDetails.fullName
                ? advocateUser.personalDetails.fullName.replace('Advocate', '').split(' ')
                : [];

              if (fullName.length) {
                profile.personalDetails.firstName = fullName[0];
                profile.personalDetails.lastName = profile.personalDetails.fullName.replace(fullName[0], '');
              }
              if (advfullName.length) {
                advocateUser.personalDetails.firstName = advfullName[0];
                advocateUser.personalDetails.lastName = profile.personalDetails.fullName.replace(fullName[0], '');
              }
              sessionStorage.setItem('advocateUserProfil', JSON.stringify(advocateUser));
              sessionStorage.setItem('userProfile', JSON.stringify(profile));
              this.userProfileService.userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
              this.userProfileService.selectedUserProfile = advocateUser;
            } else if (profile.profileType === this.profileTypes.HEAD_OF_STATE) {
              this.api.serviceProviderID = profile.impersonates[0].serviceProviderID;
              const advocateUser = profile.impersonates[0];

              const fullName = profile.personalDetails && profile.personalDetails.fullName
                ? profile.personalDetails.fullName.replace('Advocate', '').split(' ')
                : [];
              const advfullName = advocateUser.personalDetails && advocateUser.personalDetails.fullName
                ? advocateUser.personalDetails.fullName.replace('Advocate', '').split(' ')
                : [];

              if (fullName.length) {
                profile.personalDetails.firstName = fullName[0];
                profile.personalDetails.lastName = profile.personalDetails.fullName.replace(fullName[0], '');
              }
              if (advfullName.length) {
                advocateUser.personalDetails.firstName = advfullName[0];
                advocateUser.personalDetails.lastName = profile.personalDetails.fullName.replace(fullName[0], '');
              }
              sessionStorage.setItem('advocateUserProfil', JSON.stringify(advocateUser));
              sessionStorage.setItem('userProfile', JSON.stringify(profile));
              this.userProfileService.userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
              this.userProfileService.selectedUserProfile = advocateUser;
            }
          } else {
            this.api.serviceProviderID = profile.serviceProviderID;
            // profile.profileType = 'Lawfirm';
            const fullName = profile.personalDetails && profile.personalDetails.fullName
              ? profile.personalDetails.fullName.split(' ')
              : [];
            if (fullName.length) {
              if (fullName.length === 2) {
                profile.personalDetails.firstName = fullName[0];
                profile.personalDetails.lastName = fullName[1];
              } else if (fullName.length > 2) {
                profile.personalDetails.title = fullName[0];
                profile.personalDetails.firstName = fullName[1];
                profile.personalDetails.lastName = fullName[fullName.length - 1];
              }
            }
            sessionStorage.setItem('userProfile', JSON.stringify(profile));
            this.userProfileService.userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
          }
          if (profile.contactSubscriptions) {
            this.contactSubscriptions.contactID = profile.contactSubscriptions.contactID;
            this.contactSubscriptions.subscriptionPlanID = profile.contactSubscriptions.subscriptionPlanID;
            this.contactSubscriptions.freeTrialDays = profile.contactSubscriptions.freeTrialDays;
            this.contactSubscriptions.startDate = profile.contactSubscriptions.startDate;
            this.contactSubscriptions.nextBillingDate = profile.contactSubscriptions.nextBillingDate;
            this.contactSubscriptions.status = profile.contactSubscriptions.status;
            this.userProfileService.setContactSubscription(this.contactSubscriptions);
          }
        },
      error: (error) => {
        const msg = 'Error loading user profile.';
        console.error(msg, error);
        this.loadingService.hideOverlay();
        if (error.status !== 'canceled') {
          sessionStorage.removeItem('userProfile');
          sessionStorage.clear();
          localStorage.clear(); // TODO: Move to sign out page
          this.api.serviceProviderID = 0;
          this.authService.logout();
        }
      },
      complete: () => {        
        if (this.userProfileService.userProfile) {
          this.userProfileService.isJustSignedIn = true;
          this.userProfileService.isJustSignedInNav = true;
          // Log activity Login
          const currentDate = new Date();
          this.activityLog.Action = 'Login';
          this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
          this.activityLog.LoggedApp = 'Web Application';
          this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
          this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
          // this.activityLogService.addActivityLog(this.activityLog).toPromise();
          // Check if it is lawfirm then navigate to the relevant dashboard

          // console.log('LOGGED IN PROFILE: ', this.userProfileService.userProfile);
          if ((this.userProfileService.userProfile.isImpersonator &&
            this.userProfileService.selectedUserProfile.profileType === this.profileTypes.EXTENAL_ATTORNEY) ||
            (this.userProfileService.userProfile.isImpersonator &&
              this.userProfileService.selectedUserProfile.profileType === this.profileTypes.EXTENAL_LAWFIRM) ||
            (this.userProfileService.userProfile.profileType === this.profileTypes.EXTENAL_LAWFIRM ||
              this.userProfileService.userProfile.profileType === this.profileTypes.EXTENAL_ATTORNEY ||
              this.userProfileService.userProfile.profileType === this.profileTypes.HEAD_OF_STATE)) {
            this.router.navigate([PATH_ATTORNEY_DASHBOARD]);
          } else if (this.userProfileService.userProfile.profileType === this.profileTypes.ADHOC) {
            this.router.navigate([PATH_REPORTS]);
          } else if (this.userProfileService.userProfile.profileType === this.profileTypes.BAR_SOCIETY || this.userProfileService.userProfile.profileType === this.profileTypes.GCB) {
            this.router.navigate([PATH_MANAGE_BLACKLIST]);
          } else if (this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER) {
            this.router.navigate([PATH_PRACTICE_MANAGER_REPORTS]);
          } else {
            this.router.navigate([PATH_DASHBOARD]);
          }
        } else {
          // this.loadingService.hideOverlay();
          // sessionStorage.removeItem('userProfile');
          // sessionStorage.clear();
          // localStorage.clear(); // TODO: Move to sign out page
          // this.api.serviceProviderID = 0;
          // this.authService.logout();
        }
      }
    });
  }

  async checkAndSetActiveAccount() {
    var request = {
      scopes: ["user.read"],
    };
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }

    if (this.authService.instance.getAllAccounts().length > 0) {
      let accounts1 = this.authService.instance.getActiveAccount();
      if (accounts1.username.split('@')[1] != 'auxconlegal.com') {
        let localStorageKeys = Object.keys(localStorage).find(x => x.includes('accesstoken'))
        let tempToken = localStorage.getItem(localStorageKeys);
        localStorage.setItem('msal.token', tempToken);
        return
      }
    }
    return this.authService.instance.acquireTokenSilent(request).then(function (response) {
      const token = response.accessToken;
      localStorage.setItem('msal.token', token);
    })
      .catch(function (error) {
        //handle error
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  loginRedirect() {
    this.authService.loginRedirect();
  }
} // end AdvocateSignInLoadingComponent{}
