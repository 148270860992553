import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild } from '@angular/core';
import { ProcessPaymentService, BankStatementSummary } from '../../../services/process-payment/process-payment.service';
declare var jQuery: any;
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {
  ActivityLogs,
  DEFAULT_ACTIVITY_LOGS,
  ActivityLogsService
} from '../../../services/activity-logs/activity-logs.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

export interface ImportParameters {
  fromDate: string;
  toDate: string;
}

@Component({
  selector: 'app-import-bank-statements',
  templateUrl: './import-bank-statements.component.html',
  styleUrls: ['./import-bank-statements.component.scss']
})
export class ImportBankStatementsComponent implements OnInit {

  attachmentFile: File;
  isReadOnly = true;
  attachmentName = '';
  attachment: string;
  message: string;
  excludeNegatives = true;
  isHistorical = false;

  formData: FormData = new FormData();
  showErrorDuplicatePrompt: boolean;

  importDateParameter: ImportParameters = {
    fromDate: '',
    toDate: ''
  };
  errorFromDate: string;
  errorToDate: string;

  errorMessage: string;

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  get isHistoricalValid(): boolean {
    let isV = false;
    if (this.inputAttachmentFile && this.inputAttachmentFile.nativeElement.value && 
      this.inputUploadFile && this.inputUploadFile.nativeElement.value) {
      if (this.isHistorical) {
        if (this.importDateParameter.fromDate && this.importDateParameter.toDate) {
          isV = true;
          // console.log('date true');
          } else {
            // console.log('date false');
            isV = false;
          }
        } else {
          isV = true;
      }
    } else {
      isV = false;
    }
    // console.log('ISV: ', isV, 'Historical: ', this.isHistorical, 'input: ', Boolean(this.inputAttachmentFile.nativeElement.value));
    return isV;
  } // end isHistoricalValid()

  @Input() bankStatementSummary: BankStatementSummary;
  @Output() refreshPage = new EventEmitter<any>();
  @Output() isImported = new EventEmitter<boolean>();
  @ViewChild('inputAttachmentFile') inputAttachmentFile: ElementRef;
  @ViewChild('inputUploadFile') inputUploadFile: ElementRef;

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private processPaymentService: ProcessPaymentService,
    ) {
  } // end constructor()

  ngOnInit() {

  } // end ngOnInit()

  attachmentChange(e) {
    this.attachmentFile = e.target.files[0];
    const ext = this.getExtension(this.attachmentFile.name);
    if (ext.toLowerCase() === 'ofx') {
      this.attachment = this.attachmentFile.name;
      this.message = '';
    } else {
      this.attachment = null;
      this.message = 'Only allowed file with .ofx extension';
    }
  } // end attachmentChange()

  promptAttachment() {
    jQuery('#attachmentUpload').val(null);
    jQuery('#attachmentUpload').trigger('click');
  } // end promptAttachment()

  getExtension(filename) {
    return filename.toString().split('.')[1];
  } // end getExtension()

  onValueStartChange(value: Date): void {
    this.importDateParameter.fromDate = value.toDateString();
  } // end onValueStartChange()

  onValueEndChange(value: Date): void {
    this.importDateParameter.toDate = value.toDateString();
  } // end onValueEndChange()

  toggleCheckbox() {
    this.excludeNegatives = !this.excludeNegatives;
  } // end toggleCheckbox()

  toggleCheckboxHistorical() {
    this.isHistorical = !this.isHistorical;
  } // end toggleCheckboxHistorical()

  importFile() {
    const snack: Snack = {
      label: 'Import Bank Statement',
      action: null
    };
    this.loadingService.showOverlay();
    if (this.isHistorical) {
      const ext = this.getExtension(this.attachment);
      if (this.attachmentFile && this.attachment !== undefined && ext.toLowerCase() === 'ofx') {

        this.formData.append('Statement', this.attachmentFile);
        const fromDate = moment(this.importDateParameter.fromDate).format('YYYY-MM-DD');
        const toDate = moment(this.importDateParameter.toDate).format('YYYY-MM-DD');
        // console.log('date param: ', fromDate , 'TO D: ', toDate);

        // Log activity Login
        const currentDate = new Date();
        this.activityLog.Action = 'Import Bank Statement';
        this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (Import-bank-statement)';
        if (this.userProfileService.userProfile.isImpersonator) {
          this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
          this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'BankStatement';
        this.activityLog.JsonData = JSON.stringify(this.formData);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

         this.processPaymentService.postUploadBankStatementWithDate(this.formData, this.excludeNegatives, fromDate, toDate)
          .subscribe({ next: (statement) => {
            // On next
            // console.log('UPLOADED STATEMENT: ', statement);
          },
            error: (error) => {
              // On error
                console.log('ERROR MESSAGE: ', error.error.Message);
                this.formData.delete('Statement');
                this.inputAttachmentFile.nativeElement.value = '';
                this.inputUploadFile.nativeElement.value = '';
                this.message = error.error.Message;
                this.attachment = '';
                snack.label = error.error.Message;
                snack.action = null;
                snack.type = SnackType.ERROR;
                this.loadingService.hideOverlay();
                this.snackbarsService.dismiss().make(snack).show();
                this.isImported.emit(false);
            },
            complete: () => {
              // On complete
              this.message = '';
              snack.label = 'Bank statement imported successfully';
              snack.type = SnackType.SUCCESS;
              this.snackbarsService.dismiss().make(snack).show();
              this.loadingService.hideOverlay();
              this.isImported.emit(true);
              this.closeModel('succes');
            }
          });
      } else {
        this.message = 'Only allowed file with .ofx extension';
      }
    } else {
       // Log activity Login
       const currentDate = new Date();
       this.activityLog.Action = 'Import Historical Bank Statement';
       this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
       this.activityLog.LoggedApp = 'Web Application (Import-bank-statment)';
       if (this.userProfileService.userProfile.isImpersonator) {
         this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
         this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
       } else {
         this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
         this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
       }
       this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
       this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
       this.activityLog.ActionTable = 'BankStatement';
       this.activityLog.JsonData = JSON.stringify(this.formData);
       this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});
      const ext = this.getExtension(this.attachment);
      if (this.attachmentFile && this.attachment !== undefined && ext.toLowerCase() === 'ofx') {

        this.formData.append('Statement', this.attachmentFile);
         this.processPaymentService.postUploadBankStatement(this.formData, this.excludeNegatives)
        .subscribe({ next: (statement) => {
          // On next
          // console.log('UPLOADED STATEMENT: ', statement);
        },
        error: (error) => {
          // On error
          console.log('ERROR MESSAGE: ', error.error);
          this.errorFromDate = moment(this.bankStatementSummary.LastTransactionDate).format('YYYY-MM-DD');
          this.errorToDate = error.error.errorMessage2;
          if (error.error.errorMessage1 === 'Your statement does not contain a duplicate transaction') {
            this.errorMessage = 'Your last import included transactions up to' +
              moment(this.bankStatementSummary.LastTransactionDate).format('YYYY-MM-DD') +
              '. This statement starts on ' + error.error.errorMessage2
              + '. If you proceed with this import, transaction from '
              + moment(this.bankStatementSummary.LastTransactionDate).format('YYYY-MM-DD') + ' to '
              + error.error.errorMessage2 +
              ' will not be included. Do you wish to proceed?';
            
            this.loadingService.hideOverlay();
            this.showErrorDuplicatePrompt = true;
          } 
          else if (error.error.errorMessage1 === 'Your statement date is before the last transaction date, please select import historical transactions') {
            this.errorMessage = 'Your last import included transactions up to' +
              moment(this.bankStatementSummary.LastTransactionDate).format('YYYY-MM-DD') +
              '. This statement starts on ' + error.error.errorMessage2
              + '. Your statement date is before the last transaction date, please select import historical transactions';
              console.log('ERROR MESSAGE 4: ', this.errorMessage);

              this.formData.delete('Statement');
            this.inputAttachmentFile.nativeElement.value = '';
            this.inputUploadFile.nativeElement.value = '';
            this.message = error.error.errorMessage1;
            this.attachment = '';
            snack.label = error.error.errorMessage1;
            snack.action = null;
            snack.type = SnackType.ERROR;
            this.loadingService.hideOverlay();
            this.snackbarsService.dismiss().make(snack).show();
            this.isImported.emit(false);

              this.loadingService.hideOverlay();
            
          } 
          else {
            this.formData.delete('Statement');
            this.inputAttachmentFile.nativeElement.value = '';
            this.inputUploadFile.nativeElement.value = '';
            this.message = error.error.Message;
            this.attachment = '';
            snack.label = error.error.Message;
            snack.action = null;
            snack.type = SnackType.ERROR;
            this.loadingService.hideOverlay();
            this.snackbarsService.dismiss().make(snack).show();
            this.isImported.emit(false);
          }
        },
        complete: () => {
          // On complete
          this.message = '';
          snack.label = 'Bank statement imported successfully';
          snack.type = SnackType.SUCCESS;
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
          this.isImported.emit(true);
          this.closeModel('succes');
        }
      });
      } else {
        this.message = 'Only allowed file with .ofx extension';
      }
    }
  } // end importFile()

  closeModel(value: string) {
    this.refreshPage.emit(value);
  } // end closeModel()

  onProceedUploadStatement() {
    // console.log('SELECTED FILE: ', this.attachmentFile, this.attachment);
    const snack: Snack = {
      label: 'Import Bank Statement',
      action: null
    };

    this.loadingService.showOverlay();
    const ext = this.getExtension(this.attachment);
    if (this.attachmentFile && this.attachment !== undefined && ext.toLowerCase() === 'ofx') {

      this.formData.append('Statement', this.attachmentFile);
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Import Bank Statement';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Import-bank-statment)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'BankStatement';
      this.activityLog.JsonData = JSON.stringify(this.formData);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});
       this.processPaymentService.postUploadBankStatementNoDuplicate(this.formData, this.excludeNegatives)
        .subscribe({ next: (statement) => {
          // On next
          // console.log('UPLOADED STATEMENT: ', statement);
          console.log('IMPORT DATA: ', statement);
        },
          error: (error) => {
            console.log('Error message: ', error);
            // On error
            if (error.error.message === 'Your statement does not contain a duplicate transaction') {
              this.showErrorDuplicatePrompt = true;
              this.loadingService.hideOverlay();
            } else {
              this.formData.delete('Statement');
              this.inputAttachmentFile.nativeElement.value = '';
              this.inputUploadFile.nativeElement.value = '';
              this.message = error.error.message;
              this.attachment = '';
              snack.label = error.error.message;
              snack.action = null;
              snack.type = SnackType.ERROR;
              this.loadingService.hideOverlay();
              // this.snackbarsService.dismiss().make(snack).show();
              this.isImported.emit(false);
            }
          },
          complete: () => {
            // On complete
            console.log('Completed')
            this.message = '';
            snack.label = 'Bank statement imported successfully';
            snack.type = SnackType.SUCCESS;
            this.snackbarsService.dismiss().make(snack).show();
            this.loadingService.hideOverlay();
            this.isImported.emit(true);
            this.closeModel('succes');
          }
        });
    } else {
      this.message = 'Only allowed file with .ofx extension';
    }
  } // end onProceedUploadStatement()

  onHideErrorDuplicatePrompt() {
    this.showErrorDuplicatePrompt = false;
  } // end onHideErrorDuplicatePrompt()
} // end ImportBankStatementsComponent{}

