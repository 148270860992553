<div class="popup-menu card" [class.hide]="!isVisible">
  <ul class="menu-list">
    <li
    *ngIf="options.openMatterQuotation"
    class="menu-item clickable"
    (click)="onOpenQuotation()">Open quotation</li>
    <li
    *ngIf="options.previewQuotation && isInOutbox"
    class="menu-item clickable"
    (click)="onPreviewQuotation()">Preview quotation</li>
    <li
    *ngIf="options.previewSent && canAccept"
    class="menu-item clickable"
    (click)="onMoveToAccepted()">Move to accepted</li>
    <li
    *ngIf="options.previewSent && canAccept"
    class="menu-item clickable"
    (click)="onMoveToRejected()">Move to rejected</li>
    <li
    *ngIf="options.deleteQuotation"
    class="menu-item clickable"
    (click)="onDeleteQuotation()">Delete quotation</li>
  </ul>
</div>