import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Contacts, ContactsHandle, ContactsService, DEFAULT_CONTACTS, RegionalBar, DEFAULT_REGIONALBAR, RegionalBarHandle, } from '../../../services/contacts/contacts.service';
import { UtilitiesService } from '../../../services/utilities.service';
import * as moment from 'moment';
import { Snack, SnackType, SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { DateParameters } from '../../../services/manage-BlackList/manage-BlackList.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { ChambersHandler
       , OnBoardingRegister, OnboardingService } from '../../../services/onboarding/onboarding.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-new-advocate',
  templateUrl: './add-new-advocate.component.html',
  styleUrls: ['./add-new-advocate.component.scss']
})
export class AddNewAdvocateComponent implements OnInit {

  bsValue = new Date();
  title = 'Add Advocate';
  workingAdvocate: Contacts = DEFAULT_CONTACTS;
  professionalLevels: any;
  //ContactsHandle: ContactsHandle[] = [];
  RegionalBarHandle: RegionalBarHandle[] = [];
  regionalBar: RegionalBar[] = [];
  selectedRegionalBarlist: RegionalBar;
  selectedRegionalBar: RegionalBar = DEFAULT_REGIONALBAR;
  chambersDrp: any[];
  ChambersHandler: ChambersHandler[] = [];
  ethnicityDrp: any[];
  genderDrp: any[];
  seniorityDrp: any[];
  emailSendTo: string;
  isEmailResponseSuccess = false;

  @Input() isVisible: boolean;
  @Input() selectedAdvocate: Contacts;
  @Input() AdvocateOtherdetails: any;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSaveAdvocate: EventEmitter<any> = new EventEmitter<any>();

  dateParameters: DateParameters = {
    AdmissionDay: null,
    AdmissionMonth: null,
    AdmissionYear: null
  };

  regionalbarlist: any;
  responseRegionalBarList: any;
  regionalBarName: string
  admissionDate: string;
  regionalbar: number;
  admissionDay: any[];
  admissionYear: any[];
  checkAdmissionMonth: number = 0;
  checkAdmissionYear: number = 0;
  checkAdmissionDay: number = 0;
  isValidAdmissionMonth = false;
  isValidAdmissionYear = false;
  showEmailExistsPopup: boolean;
  serviceProvider: boolean = true;

  get hasAdvocateNameError(): boolean {
    return !this.util.hasTextValue(this.workingAdvocate.FullName);
  } // end hasAdvocateNameError()

  get hasAdvocateEmailError(): boolean {
    return !this.util.isValidEmail(this.workingAdvocate.Email);
  } // end hasAdvocateEmailError()

  get hasPhoneNumberError(): boolean {
    return !this.util.isValidPhoneNumber(this.workingAdvocate.Phone,
      { code: this.userProfileService.userProfile.currencyDetails.code });
  } // end hasPhoneNumberError()

  get hasCellError(): boolean {
    return !this.util.isValidPhoneNumber(this.workingAdvocate.Cell,
      { code: this.userProfileService.userProfile.currencyDetails.code });
  } // end hasCellError()

  get canActivateAdvocate(): boolean {
    return Boolean(this.workingAdvocate && this.workingAdvocate.Id) &&
      Boolean(this.workingAdvocate && !this.workingAdvocate.UserName);
  } // end canActivateAdvocate()

  get isSubmittable(): Boolean {
    return Boolean(!this.hasAdvocateNameError) && Boolean(!this.hasPhoneNumberError) &&
      Boolean(!this.hasAdvocateEmailError) 
      && Boolean(this.checkAdmissionYear > 0)
      && Boolean(!this.isValidAdmissionMonth)
      && Boolean(this.util.hasTextValue(this.workingAdvocate.RegionalBarName))
      && Boolean(this.util.hasTextValue(this.workingAdvocate.Chamber))
      && Boolean(this.util.hasTextValue(this.workingAdvocate.Ethincity))
      && Boolean(this.util.hasTextValue(this.workingAdvocate.Gender))
      && Boolean(this.util.hasTextValue(this.workingAdvocate.Gender))
      && Boolean(this.util.hasTextValue(this.workingAdvocate.Level))
      && Boolean(this.util.hasTextValue(this.workingAdvocate.StreetAddress))
  }

  get isSubmittableUpdate(): Boolean {
    return Boolean(!this.hasAdvocateNameError) && Boolean(!this.hasPhoneNumberError) &&
      Boolean(!this.hasAdvocateEmailError) && Boolean(!this.isValidAdmissionYear)
      && Boolean(!this.isValidAdmissionMonth)
  }

  onBoardingRegister: OnBoardingRegister = {
    FirstName: '',
    LastName: '',
    IDNumber: '',
    Gender: 'Female',
    Ethincity: 'African',
    AdmissionDate: this.bsValue,
    AdmissionDay: 0,
    AdmissionMonth: 0,
    AdmissionYear: 0,
    Seniority: 'Junior',
    ContactNumber: '',
    Email: '',
    BarSocietyID: 0,
    ChamberID: 0,
    TenantID: '',
    AccountType: '',
    Domain: '',
    ContactTypeID: 2,
  };

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private contactService: ContactsService,
    private snackbarsService: SnackbarsService,
    private onBoardingService: OnboardingService,
    private userProfileService: UserProfileService,
  ) { } // end constructor()

  ngOnInit(): void {
    this.onBoardingRegister = {
      FirstName: '',
      LastName: '',
      IDNumber: '',
      Gender: 'Female',
      Ethincity: 'African',
      AdmissionDate: this.bsValue,
      AdmissionDay: 0,
      AdmissionMonth: 0,
      AdmissionYear: 0,
      Seniority: 'Junior',
      ContactNumber: '',
      Email: '',
      BarSocietyID: 0,
      ChamberID: 0,
      TenantID: '',
      AccountType: '',
      Domain: '',
      ContactTypeID: 2,
    };
    this.admissionDay = [];
    for (let index = 1; index <= 31; index++) {
      this.admissionDay.push(index);
    }
    this.admissionYear = [];
    let years = moment().year();
    for (let index = 1960; index <= years; index++) {
      this.admissionYear.push({
        year: index
      });
    }
    this.admissionYear.sort((a, b) => (a.year < b.year ? 1 : -1));
    if (this.selectedAdvocate && this.selectedAdvocate.Id) {
      this.title = 'Edit Advocate';

      if (this.selectedAdvocate.AdmissionDay)
        this.checkAdmissionDay = this.selectedAdvocate.AdmissionDay;
      else
        this.checkAdmissionDay = 0;
      if (this.selectedAdvocate.AdmissionMonth)
        this.checkAdmissionMonth = this.selectedAdvocate.AdmissionMonth;
      else
        this.checkAdmissionMonth = 0;
      if (this.selectedAdvocate.AdmissionYear)
        this.checkAdmissionYear = this.selectedAdvocate.AdmissionYear;
      else
        this.checkAdmissionYear = 0;
    }

    this.workingAdvocate = this.util.objectCopy(this.selectedAdvocate);
    this.professionalLevels = [
      { label: 'Junior', value: 'Junior' },
      { label: 'Senior', value: 'Senior' },
    ];

    this.ethnicityDrp = [
      { label: 'African', value: 'African' },
      { label: 'White', value: 'White' },
      { label: 'Coloured', value: 'Coloured' },
      { label: 'Indian / Asian', value: 'Indian / Asian' }
    ];

    this.genderDrp = [
      { label: 'Male', value: 'Male' },
      { label: 'Female', value: 'Female' }
    ];

    this.seniorityDrp = [
      { label: 'Senior', value: 'Senior' },
      { label: 'Junior', value: 'Junior' }
    ]
    this.getAllRegionalBar();
  } // end ngOnInit()

  onEmailBlur() {
      this.onBoardingService.verifyDuplicateEmailPopup(this.workingAdvocate.Email).subscribe({
        
        next: (response) => {
          this.onBoardingRegister.Email = this.workingAdvocate.Email;
          this.onBoardingRegister.AccountType = response.AccountType;
          this.onBoardingRegister.Domain = response.Domain;
          this.onBoardingRegister.TenantID = response.TenantID;
          if(response === 'Email is already exist: 1') {
            this.showEmailExistsPopup = true;
          }
        },
        error: (error) => {
          if (error === 'Email is already exist') {
            this.showEmailExistsPopup = true;
          }
        }
      });
    // }
  } // end onEmailBlur()

  closeEmailExistsPopup() {
    this.showEmailExistsPopup = false;
  } // end closeEmailExistsPopup()

  changeStatus() {
    if (this.serviceProvider) {
      this.serviceProvider = false;
    } else {
      this.serviceProvider = true;
    }
  } // end changeStatus()

  getAllRegionalBar() {
    this.loadingService.showOverlay();
    this.contactService.getAllRegionalBar().subscribe({
      next: (data) => {
        // console.log('RETURNED DATA: ', data);
        this.regionalbarlist = data;
        if (data) {
          data.forEach(element => {
            this.RegionalBarHandle.push({
              data: element,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          });
        }
      },
      complete: () => {
        this.loadingService.hideOverlay();
      }
    })
  } // end getAllAdvocates()

  onDismiss() {
    this.closeModal.emit('advocate');
  } // end onDismiss()

  save() {
    this.loadingService.showOverlay();
    this.workingAdvocate.ContactContactType = 2;
    //this.workingAdvocate.AdmissionDateStr = this.workingAdvocate.AdmissionDate ? moment(this.workingAdvocate.AdmissionDate).format('YYYY-MM-DD 00:00:00') : null;
    this.workingAdvocate.AdmissionDay = this.checkAdmissionDay ? this.checkAdmissionDay : null,
      this.workingAdvocate.AdmissionMonth = this.checkAdmissionMonth ? this.checkAdmissionMonth : null,
      this.workingAdvocate.AdmissionYear = this.checkAdmissionYear ? this.checkAdmissionYear : null

      if(this.serviceProvider === true) {        
       // Split the name into an array of words
      const nameParts = this.workingAdvocate.FullName.trim().split(' ');

      // Take the last word as Surname
      this.onBoardingRegister.LastName = nameParts.pop() || '';

      // Join the remaining words as FirstName
      this.onBoardingRegister.FirstName = nameParts.join(' ');
      this.onBoardingRegister.Email = this.workingAdvocate.Email;
      this.onBoardingRegister.ContactNumber = this.workingAdvocate.Cell;
      this.onBoardingRegister.BarSocietyID = this.workingAdvocate.RegionalBarId;
      this.onBoardingRegister.ChamberID = this.workingAdvocate.ChamberID;
      this.onBoardingRegister.Ethincity = this.workingAdvocate.Ethincity;
      this.onBoardingRegister.Gender = this.workingAdvocate.Gender;
      this.onBoardingRegister.Seniority = this.workingAdvocate.Level;
      this.onBoardingRegister.IDNumber = this.workingAdvocate.Identity;
      this.onBoardingRegister.AdmissionDay = this.workingAdvocate.AdmissionDay;
      this.onBoardingRegister.AdmissionMonth = this.workingAdvocate.AdmissionMonth;
      this.onBoardingRegister.AdmissionYear = this.workingAdvocate.AdmissionYear;
    

        this.onBoardingService.inOfficeSaveRegister(this.onBoardingRegister).subscribe({
          next: (response: any) => {
            this.emailSendTo = response.Email
            // if (this.onBoardingRegister.AccountType != null && this.onBoardingRegister.AccountType == 'Non-Office') {
            // this.onBoardingRegister.AccountType == 'Non-Office'
              this.isEmailResponseSuccess = true; 
            // }
            // if (this.onBoardingRegister.AccountType === 'Office') {
            //   localStorage.setItem('OnBoardingPreRegistrationID', response.PreRegistrationsID)
            //   // this.navigateToMicrosoft();
            // }
          },
          error: (error) => {
            const snack: Snack = {
              label: error,
              type: SnackType.ERROR,
              action: null,
            };
            this.snackbarsService.dismiss().make(snack).show();
            this.loadingService.hideOverlay();
          },
          complete: () => {
            const snack: Snack = {
              label: 'Advocate added successfully.',
              action: null
            };
            this.loadingService.hideOverlay();
            this.snackbarsService.dismiss().make(snack).show();
            this.onSaveAdvocate.emit('saved');
          }
        })
      } else {
        this.contactService.postNewAdvocate(this.workingAdvocate).subscribe({
          next: (data) => {
          },
          error: (error) => {
            const snack: Snack = {
              label: error,
              type: SnackType.ERROR,
              action: null
            };
            this.snackbarsService.dismiss().make(snack).show();
            this.loadingService.hideOverlay();
          },
          complete: () => {
            const snack: Snack = {
              label: 'Advocate added successfully.',
              action: null
            };
            this.loadingService.hideOverlay();
            this.snackbarsService.dismiss().make(snack).show();
            this.onSaveAdvocate.emit('saved');
          }
        });
      }
  } // end save()

  onActivateUser() {
    this.loadingService.showOverlay();
    this.contactService.putActivateUser(this.workingAdvocate.Id).subscribe({
      next: (data) => {
        // ON next
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.onSaveAdvocate.emit('updated');
      }
    });
  } // end onActivateUser()

  update(event) {
    this.loadingService.showOverlay();
    //this.workingAdvocate.AdmissionDateStr = this.workingAdvocate.AdmissionDate ? moment(this.workingAdvocate.AdmissionDate).format('YYYY-MM-DD') : null;
    this.workingAdvocate.AdmissionDay = this.checkAdmissionDay ? this.checkAdmissionDay : null,
      this.workingAdvocate.AdmissionMonth = this.checkAdmissionMonth ? this.checkAdmissionMonth : null,
      this.workingAdvocate.AdmissionYear = this.checkAdmissionYear ? this.checkAdmissionYear : null

    if (!this.workingAdvocate.RegionalBarName) {
      this.workingAdvocate.RegionalBarName = null;
      this.workingAdvocate.RegionalBarId = null;
    }

    this.loadingService.showOverlay();
    console.log('SELECTED ADVOCATE TO UPDATE: ', this.workingAdvocate);
    this.contactService.PutContact(this.workingAdvocate).subscribe({
      next: (data) => {
      },
      error: (error) => {
        const snack: Snack = {
          label: error,
          type: SnackType.ERROR,
          action: null
        };
        this.loadingService.hideOverlay();
        this.snackbarsService.dismiss().make(snack).show();
        console.error('Something went wrong: ', error);
      },
      complete: () => {
        const snack: Snack = {
          label: 'Advocate saved successfully. ',
          action: null
        };
        this.loadingService.hideOverlay();
        this.snackbarsService.dismiss().make(snack).show();
        this.onSaveAdvocate.emit('updated');
      }
    });
  } // end update()

  onInput(event) {
    if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
    }
  }
  onModelChange(event, selectedRegionalBar: RegionalBar) {
    this.workingAdvocate.RegionalBarId = selectedRegionalBar.RegionalBarId;
  } // end onModelChange()

  onSelect(event: TypeaheadMatch): void {
    this.selectedRegionalBarlist = DEFAULT_REGIONALBAR;
    this.selectedRegionalBarlist.RegionalBarId = event.item.Id;
    this.selectedRegionalBarlist.RegionalBarName = event.item.Name;
    this.workingAdvocate.RegionalBarName = event.item.Name
    this.workingAdvocate.RegionalBarId = event.item.Id
    this.getChambers();
  } // end onSelect()

  onChamberSelect(event: TypeaheadMatch): void {
    this.workingAdvocate.Chamber = event.item.ChamberName
    this.workingAdvocate.ChamberID = event.item.Id
    this.getChambers();
  } // end onChamberSelect()

  getChambers() {
    this.chambersDrp = [];
    this.onBoardingService.getChambers(this.selectedRegionalBarlist.RegionalBarId).subscribe({
      next: (response) => {
        if (response) {
          let res = [];
          response.forEach(element => {
            if ((element.ChamberName != "Other")) {
              res.push(element);
            }
          });
          this.chambersDrp = res;
          this.chambersDrp.forEach(item => {
            this.ChambersHandler.push({
              data: item,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          });
        }
      }
    })
  } // end getChambers()

  validateAdmissionDate(day, month, year) {
    this.isValidAdmissionMonth = false;
    this.isValidAdmissionYear = false;

    if (day > 0) {
      if (month === null || month === "" || month === undefined || month == "0") {
        this.isValidAdmissionMonth = true;
      }
      if (year === null || year === "" || year === undefined || year == "0") {
        this.isValidAdmissionYear = true;
      }
    }
    if (month > 0) {
      if (year === null || year === "" || year === undefined || year == "0") {
        this.isValidAdmissionYear = true;
      }
    }
  }

  onChangeAdmissionDay(day) {
    this.validateAdmissionDate(this.checkAdmissionDay, this.checkAdmissionMonth, this.checkAdmissionYear);
  }

  onChangeAdmissionMonth(month) {
    this.validateAdmissionDate(this.checkAdmissionDay, this.checkAdmissionMonth, this.checkAdmissionYear);
  }

  onChangeAdmissionYear(year) {
    this.validateAdmissionDate(this.checkAdmissionDay, this.checkAdmissionMonth, this.checkAdmissionYear);
  }

  // onDateValueChange(e) {
  //   if (e instanceof Date) {
  //     this.workingAdvocate.AdmissionDate = e;
  //   }
  // }
  navigateToMicrosoft() {
    localStorage.setItem('OnBoardingPreRegistration', JSON.stringify(this.onBoardingRegister))
    if (this.onBoardingRegister.AccountType && this.onBoardingRegister.TenantID) {
      window.location.href = 'https://login.microsoftonline.com/' + this.onBoardingRegister.TenantID + '/oauth2/v2.0/authorize?client_id=' + environment.clientId + '&redirect_uri=' + environment.redirectUrl + 'onboarding/verify-user&response_mode=query&scope=openid&response_type=code&acr_values=urn%3amicrosoft%3apolicies%3amfa&prompt=select_account';
    }
    this.loadingService.hideOverlay();
  }

} // end AddNewAdvocateComponent {}

// export class EmailValidator {
//   static createValidator(onBoardingService: OnboardingService, loadingService: LoadingService): AsyncValidatorFn {
//     function extract(result) {
//       if (result.duplicate === true)
//         return { duplicate: true, AccountType: "Office" };

//       window['email-data'] = result;
//       result['duplicate'] = false;
//       return result;
//     }

//     function extractError(error: any): Observable<any> {
//       return of({ duplicate: true });
//     }

//     return (control: AbstractControl): Observable<ValidationErrors> => {
//       return timer(500)
//         .pipe(switchMap(() => {
//           return onBoardingService
//             .verifyDuplicateEmail(control.value)
//             .pipe(
//               catchError(extractError),
//               map(extract));
//         }));
//     };
//   }
// }