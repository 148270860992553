<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal=header">
                <h2>{{title}}</h2>
                <!-- <button class="modal-dismiss" style="border: none; background-color: transparent;" (click)="onCancel()">
                    <i class="fal fa-times-circle"></i>
                </button> -->
            </div>

            <div class="modal-body">
                <!-- <div class="container-fluid"> -->
                    <!-- <div class="row"> -->
                        <div class="form-group">
                            <label for="category">Category</label>
                            <select
                                class="form-control"
                                id="category"
                                [(ngModel)]="Category">
                                <option [value]="null">Choose a category</option>
                                <option *ngFor="let category of categories"
                                [value]="category.value">{{category.label}}</option>
                            </select>
                        </div>
                    <!-- </div> -->
                <!-- </div> -->
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" (click)="onCancel()">Cencel</button>
                <button class="btn btn-success" (click)="onConfirm()">Proceed</button>
              </div>
        </div>
    </div>
</div>