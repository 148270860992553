<div class="modal" *ngIf="isVisible">
    <div class="modal-content" style="margin: 5.75rem auto auto !important;">
        <div class="modal-dialog">

            <div class="modal-header">
                <h2>Edit LPC Complaint for {{CurrentReportingAdvocateName}}</h2>
                <button class="modal-dismiss" (click)="dismiss(false)">
                    <i class="fa fa-close"></i>
                </button>
            </div><!--#end .modal-header -->

            <div class="modal-body form">
                <!-- tab -->
                <div class="form-group">
                    <label>LPC Status</label>
                    <select class='select-option' [(ngModel)]='LPCStatus_id' (change)='onSelectLPCstatus($event)'>
                        <option [value]="null" [disabled]="false">Select LPC Status</option>
                        <option *ngFor='let status of lPCStatusDrp' [value]="status.Id">{{status.FullName}}
                        </option>
                    </select>
                </div>

                <!-- </div> -->
                <div class="form-group pt-2">
                    <label for="phone-number">LPC Reference Number</label>
                    <input type="text" [id]="phone-number" class="form-control"
                        [class.has-error]="isValidAdvocatePhoneNumber" [(ngModel)]="LPCReferenceNo"
                        placeholder="Enter LPC Refrence Number" />
                </div>

                <!-- Date -->
                <div class="form-group">
                    <div class="col-md-12 pl-0 pr-0">
                        <label class="ml">Due Date</label>
                        <div class="input-group">
                            <input class="form-control input-date" name="date" style="margin: 0 !important;" type="text"
                                id="date-from" #_dateFrom="bsDatepicker" bsDatepicker [bsValue]="bsValue"
                                [(ngModel)]="DueDate" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}" placement="right"
                                autocomplete="off">
                            <div class="input-group-append" style="cursor: pointer;" (click)="_dateFrom.toggle()"
                                [attr.aria-expanded]="_dateFrom.isOpen">
                                <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group  pt-2">
                    <label for="notes">Notes</label>
                    <input type="text" class="form-control" [id]="notes" [(ngModel)]="Notes"
                        placeholder="Enter your Notes" />
                </div>

            </div>
            <!--#end .modal-body -->
            <div class="modal-footer">
                <button class="btn btn-danger" (click)="dismiss(false)">Cancel</button>
                <button class="btn btn-success" (click)="updateLPCdetails($event)">SAVE</button>
            </div>
            <!-- #end .modal-footer -->

        </div><!--#end .modal-dialog -->
    </div><!--#end .modal-content -->
</div><!--#end .modal -->