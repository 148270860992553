import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import * as moment from 'moment';
import {
    ProcessPaymentService,
    SendStatement,
    DEFAULT_SEND_STATEMENT,
    SendMessageRecipients,
    BankTransaction
} from '../../../services/process-payment/process-payment.service';

import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { Router, ActivatedRoute, NavigationStart, Event } from '@angular/router';
import {
    PATH_DASHBOARD,
    PATH_PROCESS,
    PATH_PROOF_OF_PAYMENTS,
    PATH_RECONCILE_BANK_TRANSACTIONS,
    PATH_SEND_STATEMENT
} from '../../../services/appdata/app-config.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { ICrumb } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
// import { SubscriptionLike as ISubscription } from 'rxjs';
import { SubCategoriesService } from '../../../services/sub-categories/sub-categories.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { DEFAULT_ACTIVITY_LOGS, ActivityLogs, ActivityLogsService } from '../../../services/activity-logs/activity-logs.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { json } from 'd3';

interface SendStatementHandle {
    data: SendStatement;
    state: {
        isVisible: boolean;
        isSelected: boolean;
        isOpen: boolean;
    };
} // end SendStatementHandle{}

@Component({
    selector: 'app-send-bank-statements',
    templateUrl: './send-bank-statements.component.html',
    styleUrls: ['./send-bank-statements.component.scss']
})
export class SendBankStatementsComponent implements OnInit, OnDestroy {
    totalAccountingTransaction: number = 0;
    totalAmountTransaction: number = 0;
    totalCreditAmountTransaction: number = 0;
    totalDebitAmountTransaction: number = 0;
    sendStatements: any = [];
    sendStatementHandle: SendStatementHandle[] = [];
    initSendStatementHandle: SendStatementHandle[] = [];
    statementToSend: SendStatement[] = [];
    statementToSave: SendStatement;
    selectedSendStatement: SendStatement;
    sendMessageRecipient: SendMessageRecipients[] = [];
    bankTransaction: BankTransaction[] = [];
    revisedBankTransaction: BankTransaction[] = [];
    sortLawFirm = false;
    sortStatement = false;
    sortDate = false;
    sortDue = false;
    sortAscending = false;
    sortBy = '';
    previousSortBy = '';
    selectAll: boolean;
    isSent = false;
    count = 0;
    showRecipientsModal = false;
    searchTerm: string;
    crumbs: ICrumb[];
    data: any = [];
    showErrorMessagePrompt: boolean;
    showRevisedPrompt: boolean;
    showUnsavedChangesPrompt: boolean;
    showUnAllocatedTransactionPrompt: boolean;

    // Activity logs
    activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

    profileTypes = {
        ADVOCATE: 'advocate',
        ASSISTANT: 'assistant',
        ATTORNEY: 'Attorney',
        EXTERNAL_ATTORNEY: 'Attorney (External)',
        PRACTICE_MANAGER: "Practice Manager",
        LAWFIRM: 'Lawfirm',
        EXTERNAL_LAWFIRM: 'Lawfirm (External)'
    };

    get currencySymbol(): string {
        if (this.userProfileService.userProfile.isImpersonator) {
            return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
        } else {
            return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
        }
    } // end currencySymbol()

    get canSaveEmails(): boolean {
        return !this.util.objectIsSame(this.initSendStatementHandle, this.sendStatementHandle);
    } // end canSaveEmails()

    @ViewChild('inputCheckSelectAll') inputCheckSelectAll: ElementRef;

    get isDirty(): boolean {
        return !this.util.objectIsSame(this.sendStatementHandle, this.initSendStatementHandle);
    } // end isDirty()

    get isSaveable(): boolean {
        return Boolean(!this.sendStatementHandle.find(s => s.data.EmailAddresses === '') &&
            !Boolean(this.sendStatementHandle
                .filter(s => this.util.areValidEmails(s.data.EmailAddresses) === false).length > 0))
            && Boolean(this.sendStatementHandle.filter(x => x.state.isSelected === true).length > 0);
    } // end isSaveable()

    get isAssistantToLawfirm(): boolean {
        return Boolean(this.userProfileService.userProfile.isImpersonator)
            && Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
    } // end isAssistantToLawfirm()

    // Is Internal Attorney
    get isAttorney(): boolean {
        let attorneyProfile = false;
        if (this.userProfileService.userProfile.isImpersonator) {
            attorneyProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.ATTORNEY);
        } else {
            attorneyProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
        }
        return attorneyProfile;
    } // end isAttorney()

    // Check User using Sage Accounting Plateform or not
    get isSageAccountingPlatform(): boolean {
        if (this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER) {
            let currentAdvocateProfile = JSON.parse(sessionStorage.getItem('advocateUserProfil'));
            if (Array.isArray(currentAdvocateProfile))
                return currentAdvocateProfile[0].accountingPlatform == "Sage" ? true : false
            else
                return currentAdvocateProfile.accountingPlatform == "Sage" ? true : false
        } else {
            if (this.userProfileService.userProfile) {
                return this.userProfileService.userProfile.accountingPlatform == "Sage" ? true : false
            }
            else
            return this.userProfileService.userProfile ? this.userProfileService.userProfile.accountingPlatform == "Sage" ? true : false : false
        }
        return false
    }
    // Is Internal Lawfirm
    get isLawfirm(): boolean {
        let lawfirmProfile = false;
        if (this.userProfileService.userProfile.isImpersonator) {
            lawfirmProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
        } else {
            lawfirmProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
        }
        return lawfirmProfile;
    } // end isLawfirm()

    private _subscriptionNavigationStart: ISubscription;
    private _exitRoute: string;
    private _canIgnoreNavigationBlock: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private util: UtilitiesService,
        private navigation: NavigationService,
        private loadingService: LoadingService,
        private snackbarsService: SnackbarsService,
        private userProfileService: UserProfileService,
        private activityLogService: ActivityLogsService,
        private subCategoriesService: SubCategoriesService,
        private processPaymentService: ProcessPaymentService,
    ) {
    } // end constructor()

    hasValidEmailError(statement: SendStatement): boolean {
        return !this.util.areValidEmails(statement.EmailAddresses);
    } // end hasValidEmailError()

    ngOnInit() {
        this.loadingService.showOverlay();
        this.getPayment();
        this.processPaymentService.getBankTransactions('').subscribe({
            next: (_bank_transaction) => {
                this.bankTransaction = _bank_transaction;
            }
        });
        this.getSendStatements();

        this.crumbs = [
            {
                label: 'PROCESS',
                link: PATH_PROCESS
            },
            {
                label: 'SEND STATEMENTS'
            }
        ];
        // $(document).ready(function () {
        //     $(this).scrollTop(0);
        // });
        this._subscriptionNavigationStart = this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart && this.isDirty) {
                this._exitRoute = event.url;
                if (location.href.includes(PATH_SEND_STATEMENT)) {
                    if (this.isDirty && !this._canIgnoreNavigationBlock) {
                        this.navigation.canNavigate = false;
                        this.showUnsavedChangesPrompt = true;
                    } else {
                        this.navigation.canNavigate = true;
                    }
                }
            }
        });
    } // end ngOnInit()

    ngOnDestroy() {
        this._subscriptionNavigationStart.unsubscribe();
    } // end ngOnDestroy()

    getPayment() {
        this.processPaymentService.getProofOfPayment().subscribe({
            next: (_next) => {
                this.data = _next;
            }
        });
    } // end getPayment()

    getSendStatements() {
        this.processPaymentService.getSendStatements().subscribe({
            next: (_send_statement) => {
                this.sendStatements = _send_statement;
                this.initSendStatement();
                this.loadingService.hideOverlay();
            },
            error: (error) => {
                // On Error
                this.loadingService.hideOverlay();
            }
        });
    } // end getSendStatement()

    onFirmAllocationClick() {
        this.processPaymentService.selectedTab = 'processedBankTransactions';
        this.router.navigate([PATH_RECONCILE_BANK_TRANSACTIONS]);
    } // end onFirmAllocationClick()

    onHideErrorMessagePrompt() {
        this.showErrorMessagePrompt = false;
        this.router.navigate([PATH_PROOF_OF_PAYMENTS]);
    } // end onHideErrorMessagePrompt()

    // onHideErrorContinueMessagePrompt() {
    //     this.onSendClick();
    // } // end onHideErrorContinueMessagePrompt()

    initSendStatement() {
        this.initSendStatementHandle = [];
        this.sendStatements.forEach(statement => {
            if (statement.LastStatementSent) {

            } else {
                statement.LastStatementSent = '1901-01-01';
            }
            if (statement.TotalDue > 0 || statement.AmountAllocatedToFirm === true) {
                if (statement.AmountAllocatedToFirm === true && statement.TotalDue === 0) {
                    statement.TotalDue = statement.TotalDue - statement.AmountAllocated;
                }

                this.initSendStatementHandle.push({
                    data: statement,
                    state: {
                        isVisible: true,
                        isSelected: false, // Wiseman changed this to false to uncheck checkboxes.
                        isOpen: false
                    }
                });
            }
        });
        this.selectAll = false; // Wiseman changed this to false to uncheck the checkbox so.
        this.inputCheckSelectAll.nativeElement.checked = false; // Wiseman changed this to false to uncheck the checkbox so.
        this.initWorkinkingStatement();
    } // end initSendStatement()

    onHideUnsavedChangesPrompt() {
        this.showUnsavedChangesPrompt = false;
    } // end onHideUnsavedChangesPrompt()

    leavePage() {
        this._canIgnoreNavigationBlock = true;
        this.onHideErrorMessagePrompt();
        setTimeout((thisObj) => {
            thisObj.router.navigate([this._exitRoute.replace('/', '')]);
        }, 300, this);
    }

    onSearch() {
        if (this.searchTerm) {
            this.sendStatementHandle.forEach(statement => {
                if (
                    !(statement.data.LawFirm.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(statement.data.EmailAddresses.match(new RegExp(this.searchTerm, 'gi'))) &&
                    // !(statement.data.LastStatementSent.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(statement.data.DaysSinceLast.toString().match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(statement.data.TotalDue.toString().match(new RegExp(this.searchTerm, 'gi')))
                ) {
                    statement.state.isVisible = false;
                    // statement.state.isSelected = false;
                }
            });
        } else {
            this.sendStatementHandle.forEach(statement => {
                statement.state.isVisible = true;
                // statement.state.isSelected = true;
            });
        }
    } // end onSearch()

    onHideRevisedPrompt() {
        this.processPaymentService.selectedTab = 'newBankTransactions';
        this.router.navigate([PATH_RECONCILE_BANK_TRANSACTIONS]);
        this.showRevisedPrompt = false;
    } // end onHideRevisedPrompt()

    clearSearch() {
        this.searchTerm = '';
        this.onSearch();
    } // end clearSearch()

    toggleSelectAll() {
        // this.selectAll = !this.selectAll;
        // this.inputCheckSelectAll.nativeElement.checked = this.selectAll;
        this.sendStatementHandle.forEach(statement => {
            if (statement.state.isVisible) {
                statement.state.isSelected = this.inputCheckSelectAll.nativeElement.checked;
            }
        });
    } // end toggleSelectAll()

    toggleCheckbox(statement: SendStatementHandle) {
        statement.state.isSelected = !statement.state.isSelected;
        if (this.sendStatementHandle.filter(_statement => _statement.state.isSelected !== true).length > 0) {
            this.inputCheckSelectAll.nativeElement.checked = false;
        } else {
            // this.inputCheckSelectAll.nativeElement.checked = true;
        }
    } // end toggleCheckbox()

    onEmailChanges(statement: SendStatementHandle) {
        statement.state.isOpen = true;
    } // end onEmailChanges()

    getDateFormet(value: Date): string {

        if (value == null) {
            return '';
        }
        return moment(value).format('DD/MM/YYYY');
    }

    numberConversion(value: number): string {
        return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    showAddEmailModal(email: SendStatement) {
        this.processPaymentService.getMessageRecipients(email.MessageId).subscribe({
            next: (_message) => {
                this.sendMessageRecipient = _message;
            },
            error: (error) => {
                // On error
            },
            complete: () => {
                // On complete
                this.showRecipientsModal = true;
            }
        });
        // this.selectedSendStatement = email;
    } // end showAddEmailModal()

    onSaveOrUpdateEmails(update: SendStatement) {
        this.statementToSend = [];
        this.loadingService.showOverlay();
        this.statementToSend.push(update);

        // Log activity Login
        const currentDate = new Date();
        this.activityLog.Action = 'Update Client outstanding Statement Recipients';
        this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (Send-bank-statement)';
        if (this.userProfileService.userProfile.isImpersonator) {
            this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
            this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'Messages';
        this.activityLog.JsonData = JSON.stringify(this.statementToSend);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_activity) => { } });

        this.getSendStatements();
    } // end onSaveOrUpdateEmails()

    onCloseModal() {
        this.showRecipientsModal = false;
    } // end onCloseModal()

    onHideUnAllocatedTransactionPrompt() {
        this.showUnAllocatedTransactionPrompt = false;
    } // end onHideUnAllocatedTransactionPrompt()

    sendStatementToClient() {
        // console.log('DATA LENGTH: ', this.data.length);
        // console.log('REVISED STATEMENTS: ', this.bankTransaction && this.bankTransaction.filter(bank => bank.Allocated === 'Revised').length);
        // console.log('IS SAGE ACCOUNT PLATFORM: ', this.isSageAccountingPlatform);
        if (this.data.length > 0) {
            this.showErrorMessagePrompt = true;
        } else if (this.bankTransaction && this.bankTransaction.filter(bank => bank.Allocated === 'Revised').length > 0) {
            this.revisedBankTransaction = this.bankTransaction.filter(bank => bank.Allocated === 'Revised');
            this.showRevisedPrompt = true;
        } else if (this.isSageAccountingPlatform) {
            let count = 0;
            this.processPaymentService.getBankTransactionCount().subscribe({
                next: (_next) => {
                  count = _next;
                //   console.log('CHECK BANK TRANSACTIONS COUNT: ', count);
                },
                complete: () => {
                    // On complete
                    // console.log('CHECK BANK TRANSACTIONS COUNT: Complete check: ', count);
                    if (count && count > 0) {
                        this.showUnAllocatedTransactionPrompt = true;
                    } else {
                        if (this.sendStatementHandle.filter(statement => statement.state.isSelected === true).length > 0) {
                            this.onSendClick();
                        } else {
                            const snack: Snack = {
                                label: 'Please select a Law Firm to continue',
                                action: null
                            };
                            this.snackbarsService.make(snack, 5000).show();
                        }
                    }
                }
              });
        } else {
            if (this.sendStatementHandle.filter(statement => statement.state.isSelected === true).length > 0) {
                this.onSendClick();
            } else {
                const snack: Snack = {
                    label: 'Please select a Law Firm to continue',
                    action: null
                };
                this.snackbarsService.make(snack, 5000).show();
            }
        }
    }

    sendIndicationStatementToClient() {
        this.showErrorMessagePrompt = false;
        if (this.isSageAccountingPlatform) {
            let count = 0;
            this.processPaymentService.getBankTransactionCount().subscribe({
                next: (_next) => {
                  count = _next;
                },
                complete: () => {
                    // On complete
                    if (count && count > 0) {
                        this.showUnAllocatedTransactionPrompt = true;
                    } else {
                        if (this.sendStatementHandle.filter(statement => statement.state.isSelected === true).length > 0) {
                            this.onSendClick();
                        } else {
                            const snack: Snack = {
                                label: 'Please select a Law Firm to continue',
                                action: null
                            };
                            this.snackbarsService.make(snack, 5000).show();
                        }
                    }
                }
              });
        } else {
            if (this.sendStatementHandle.filter(statement => statement.state.isSelected === true).length > 0) {
                this.onSendClick();
            } else {
                const snack: Snack = {
                    label: 'Please select a Law Firm to continue',
                    action: null
                };
                this.snackbarsService.make(snack, 5000).show();
            }
        }
    } // end sendIndicationStatementToClient()

    onSendClick() {
        this.showErrorMessagePrompt = false;
        this.showUnAllocatedTransactionPrompt = false;
        this.showRevisedPrompt = false;
        if (this.revisedBankTransaction && this.revisedBankTransaction.length > 0) {
            for (const revised of this.revisedBankTransaction) {
                for (const line of revised.BankTransactionLines) {
                    this.processPaymentService.removeTransactionLines(line.TransactionId).subscribe({ next: (_remove) => { } });
                }
            }
        }
        this.loadingService.showOverlay();
        const snack: Snack = {
            label: 'Send Statements',
            action: null
        };
        try {
            this.statementToSend = [];
            this.sendStatementHandle.forEach(statement => {
                if (statement.state.isSelected) {
                    statement.data.LastStatementSent = moment().format('YYYY-MM-DD');
                    this.statementToSend.push(statement.data);
                }
            });
            // if (this.statementToSend) {
            this.count = this.statementToSend.length;
            // Log activity Login
            const currentDate = new Date();
            this.activityLog.Action = 'Sent Client outstanding Statement';
            this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
            this.activityLog.LoggedApp = 'Web Application (Send-bank-statement)';
            if (this.userProfileService.userProfile.isImpersonator) {
                this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
            } else {
                this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
            }
            this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
            this.activityLog.ActionTable = 'Messages';
            this.activityLog.JsonData = JSON.stringify(this.statementToSend);
            this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_activity) => { } });
            this.processPaymentService.putSendStatements(this.statementToSend).subscribe({
                next: (_send) => {
                    if (_send) {
                        this.totalAccountingTransaction = JSON.parse(_send).totalTransaction;
                        this.totalAmountTransaction = JSON.parse(_send).totalAmount;
                        this.totalCreditAmountTransaction = JSON.parse(_send).creditAmount;
                        this.totalDebitAmountTransaction = JSON.parse(_send).debitAmount;
                        this.statementToSend = [];
                        this.revisedBankTransaction = [];
                        this.isSent = true;
                        this.initSendStatementHandle = this.util.objectCopy(this.sendStatementHandle);
                        this.loadingService.hideOverlay();
                        setTimeout(() => {
                            this.getSendStatements();
                            this.router.navigate([PATH_DASHBOARD])
                        }, 3000);
                    } else {
                        this.loadingService.hideOverlay();
                        setTimeout(() => {
                            this.getSendStatements();
                            this.router.navigate([PATH_DASHBOARD]);
                        }, 5000);
                    }
                }
            });
        } catch (e) {
            this.loadingService.hideOverlay();
            snack.label = 'Something went wrong';
            snack.action = null;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
        }
    }

    onCancelClicked() {
        this.router.navigate([PATH_PROCESS]);
    } // end onCancelClicked()

    onSaveEmails() {
        this.loadingService.showOverlay();
        this.sendStatementHandle.forEach(statement => {
            if (statement.data.EmailAddresses) {
                this.statementToSend.push(statement.data);
            }
        });

        // Log activity Login
        const currentDate = new Date();
        this.activityLog.Action = 'Update Client outstanding Statement Recipients';
        this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (Send-bank-statement)';
        if (this.userProfileService.userProfile.isImpersonator) {
            this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
            this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'Messages';
        this.activityLog.JsonData = JSON.stringify(this.statementToSend);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_activity) => { } });

        this.processPaymentService.putSendStatementRecipient(this.statementToSend).subscribe({
            next: (_statement) => {
                // On next
            },
            error: (error) => {
                // On error
                this.loadingService.hideOverlay();
            },
            complete: () => {
                // On complete
                this.loadingService.hideOverlay();
            }
        });
    } // end onSaveEmails()

    toggleSort(sortBy: string) {
        this.sortDate = false;
        this.sortLawFirm = false;
        this.sortStatement = false;
        this.sortDue = false;

        this.sortBy = sortBy;
        if (this.sortBy === this.previousSortBy) {
            this.sortAscending = !this.sortAscending;
        }

        switch (this.sortBy) {
            case 'date':
                this.sendStatementHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDate = true;
                        return a.data.LastStatementSent.toUpperCase().localeCompare(b.data.LastStatementSent.toUpperCase());
                    }
                    return b.data.LastStatementSent.toUpperCase().localeCompare(a.data.LastStatementSent.toUpperCase());
                });
                break;
            case 'statement':
                this.sendStatementHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortStatement = true;
                        return a.data.DaysSinceLast - b.data.DaysSinceLast;
                    }
                    return b.data.DaysSinceLast - a.data.DaysSinceLast;
                });
                break;
            case 'law-firm':
                this.sendStatementHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortLawFirm = true;
                        return a.data.LawFirm.toUpperCase().localeCompare(b.data.LawFirm.toUpperCase());
                    }
                    return b.data.LawFirm.toUpperCase().localeCompare(a.data.LawFirm.toUpperCase());
                });
                break;

            case 'due':
                this.sendStatementHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortDue = true;
                        return a.data.TotalDue - b.data.TotalDue;
                    }
                    return b.data.TotalDue - a.data.TotalDue;
                });
                break;

        }
        this.previousSortBy = this.sortBy;
    } // end toggleSort()

    initWorkinkingStatement() {
        this.sendStatementHandle = this.util.objectCopy(this.initSendStatementHandle);
    } // end initWorkingStatement()

    getCellColor(data: SendStatement): string {
        if (data.LastStatementSent === '1901-01-01') {
            return 'transparent';
        }
    } // end getCellColor()

} // end SendBankStatementsComponent{}

