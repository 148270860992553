<div class="container-fluid">
    <h1 class="page-title">{{title}}</h1>
</div> <!-- #end .container-fluid{} -->
<!-- <div class="container-fluid"></div> -->
  <div class="container-fluid container-page">
    <div class="well">
      <div class="table">
        <div class="table-head">

          <div class="table-row">
            <span class="column-name clickable">
              Name
              <!-- <i class="fa fa-lg" [class.fa-angle-down]="!sortAscending" [class.fa-angle-up]="sortAscending" *ngIf="orderBy === 'Description'"></i> -->
            </span>
            <!--#end .column-name -->
            <span class="column-email clickable">
              Email
              <!-- <i class="fa fa-lg" [class.fa-angle-down]="!sortAscending" [class.fa-angle-up]="sortAscending" *ngIf="orderBy === 'Category'"></i> -->
            </span>
            <!--#end .column-email -->
            <span class="column-role clickable">
              Role
              <!-- <i class="fa fa-lg" [class.fa-angle-down]="!sortAscending" [class.fa-angle-up]="sortAscending" *ngIf="orderBy === 'Rate type'"></i> -->
            </span>
            <!--#end .column-role -->

            <span class="column-status clickable">
              Status
              <!-- <i class="fa fa-lg" [class.fa-angle-down]="!sortAscending" [class.fa-angle-up]="sortAscending" *ngIf="orderBy === 'Rate type'"></i> -->
            </span>
            <!--#end .column-status -->
            <!-- <span class="column-action form">
              <div class="input-group">
                <button class="btn btn-text btn-input-group" (click)="filterFeeTemplates()">
                  <i class="fa fa-search"></i>
                </button>
                <input type="search" placeholder="Search fee templates" (input)="filterFeeTemplates()" (keyup.enter)="filterFeeTemplates()"
                  [(ngModel)]="searchTerm">
              </div> -->
              <!--#end .input-group -->
            <!-- </span> -->
            <!--#end .column-action -->
          </div>
          <!--#end .table-row -->

        </div>
        <!--#end .table-head -->

        <!-- <div *ngIf="isLoading" class="table-body">
          <app-circular-indicator [isVisible]="isLoading"></app-circular-indicator>
        </div> -->
        <!--#end .table-body -->

        <div class="table-body">
          <div *ngFor="let teams of assistantHandles" class="table-row" [class.hide]="!teams.state.isVisible">
            <span class="column-name">
              {{teams.data.FullName}}
            </span>
            <!--#end .column-name -->
            <span class="column-email">
              {{teams.data.Email}}
            </span>
            <!--#end .column-email -->
            <span class="column-role">
              {{teams.data.RoleName}}
            </span>
            <!--#end .column-role -->
            <span class="column-status">
              {{teams.data.Status}}
            </span>
            <!--#end .column-status -->
            <span class="column-action">
              <span class="clickable" (click)="onEditTeams(teams.data)">Edit</span>
            </span>
            <!--#end .column-action -->
          </div>
          <!--#end .table-row -->

        </div>
        <!--#end .table-body -->

        <div class="table-foot">
          <div class="table-row">
            <span class="clickable" (click)="onAddNewTeamMember()">
              <i class="fa fa-plus-circle fa-lg auxcon-green"></i>
              <u class="auxcon-green">ADD A TEAM MEMBER</u>
            </span>
          </div>
        </div>
        <!--#end .table-foot -->

      <!-- </div> -->
      <!--#end .table -->
    </div>
    <!--#end .well -->
  </div>
  <!--#end .container-fluid .container-page -->
  </div> 
  <!-- #end container-fluid -->
  <app-add-team-member-modal
  [isVisible]="showAddTeamMember"
  [teamMember]="activeTeamMembers"
  (closeModal)="onCloseModal()"></app-add-team-member-modal>
