import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BankTransaction,
         DEFAULT_BANK_TRANSACTION,
         ProcessPaymentService } from '../../../services/process-payment/process-payment.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import * as moment from 'moment';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-edit-transaction',
  templateUrl: './edit-transaction.component.html',
  styleUrls: ['./edit-transaction.component.scss']
})
export class EditTransactionComponent implements OnInit {

  workingEditTransaction: BankTransaction = Object.assign({}, DEFAULT_BANK_TRANSACTION);

  @Input() isVisible: boolean;
  @Input() title: string;
  @Input() editTransaction: BankTransaction;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() save: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private processPaymentService: ProcessPaymentService
  ) { } // end constructor ()

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.workingEditTransaction, this.editTransaction);
  } // end isDirty()

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
        return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
        return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
} // end currencySymbol()

  ngOnInit(): void {
    this.workingEditTransaction = Object.assign({}, this.editTransaction);
    console.log('DO WE HAVE DATA HERE: ', this.workingEditTransaction);
  } // end ngOnInit()

  parseTransactionDate(transactionDate: string): Date {
    if (transactionDate) {
      return new Date(transactionDate);
    } else {
      return null;
    }
  } // end parseTransactionDate()

  onValueChange(transaction: BankTransaction, value: Date): void {
    transaction.Date = moment(value).format('YYYY-MM-DDTHH:mm:ss');
  } // end onValueChange()

  onAmountChange(bank: BankTransaction, event) {
    if (this.userProfileService.userProfile.isImpersonator) {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onAmountChange()

  onAmountClicked(bank: BankTransaction, event) {
    if (this.userProfileService.userProfile.isImpersonator) {
      event.target.value = this.cpipe.parse(event.target.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
      } else {
      event.target.value = this.cpipe.parse(event.target.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
} // end onAmountClicked()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onSaveTransaction() {
    this.loadingService.showOverlay();
    let bankTransaction = {
      Id: this.workingEditTransaction.Id,
      DatePosted: moment(this.workingEditTransaction.Date).format('YYYY-MM-DD HH:mm:ss'),
      Name: this.workingEditTransaction.TransactionDescription,
      Amount: this.workingEditTransaction.Amount,
      BankStatementId: 0,
      FitId: this.workingEditTransaction.FitId,
      CheckNum: 0,
      Type: this.workingEditTransaction.Type,
      Processed: this.workingEditTransaction.Processed,
      Reason: this.workingEditTransaction.Reason,
      PersonalFlag: false, 
      ExcludedFlag: false,
      ReferredFlag: false,
      DuplicateTransactionId: 0,
      PossibleCancelledCheque: false,
      HasProofOfPayment: this.workingEditTransaction.HasProofOfPayment,
      POPDocumentPath: this.workingEditTransaction.POPDocumentPath,
      IsRevised: this.workingEditTransaction.IsReferred,
      AdHocCollection: false,
      Category: this.workingEditTransaction.AccountNo,
  };
    this.processPaymentService.putManualTransactions(bankTransaction).subscribe({ next: (onNext) => {
      // On next
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
      complete: () => {
        // on complete
        this.editTransaction = Object.assign({}, this.workingEditTransaction);
        this.save.emit(this.workingEditTransaction);
        this.loadingService.hideOverlay();
      }
    });
  } // end onSaveTransaction()

} // end EditTransactionComponent {}
