import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';

import { Router, NavigationEnd, Event } from "@angular/router";

import {
  UserProfileService,
  UserProfile,
} from "../../../services/user-profile/user-profile.service";

import {
  AppConfigService,
  PATH_DASHBOARD,
  PATH_CALENDAR,
  PATH_REPORTS,
  PATH_SEARCH,
  PATH_MATTERS,
  PATH_MESSAGES,
  PATH_MANAGE_BLACKLIST,
  PATH_CREATE_NEW_USER,
  PATH_USER_SAGE_VALIDATION,
  PATH_OUTLOOK_SYNC,
  PATH_EDIT_FEE_TEMPLATES,
  PATH_SIGN_OUT,
  PATH_SIGN_IN,
  PATH_SIGN_IN_LOADING,
  PATH_EDIT_MY_TEAM,
  PATH_ATTORNEY_DASHBOARD,
  PATH_ADD_EDIT_CLIENTS,
  PATH_LAWFIRM_DASHBOARD,
  PATH_BLACKLIST_PDF_VIEWER,
  PATH_LAWFIRM_CALENDAR,
  PATH_LAWFIRM_SEARCH,
  PATH_LAWFIRM_MESSAGES,
  PATH_PROCESS,
  PATH_LAWFIRM_PROCESS,
  PATH_PRACTICE_MANAGER_DASHBOARD,
  PATH_PRACTICE_MANAGER_REPORTS,
  PATH_PRACTICE_ADVOCATE_DETAIL,
  PATH_PRACTICE_MANAGER_MESSAGES,
  PATH_PRACTICE_ADVOCATE_OVERVIEW,
  PATH_GCB_RESPONSE_HANDLER,
  PATH_QUOTATION,
  PATH_AUXCON_ADMIN,
  PATH_SUBSCRIPTION_BILLING,
  PATH_SUBSCRIPTION_PROMPT,
  PATH_USER_SINGUP,
  PATH_AD_HOC_COLLECTIONS,
  PATH_ONBOARDING_REGISTER,
} from '../../../services/appdata/app-config.service';

import {
  TAG_EDIT_PROFILE_MODAL,
  TAG_ADD_NEW_LAW_FIRM_MODAL,
  TAG_ADD_ATTORNEY_FIRM_MODAL,
  TAG_EDIT_FEE_TEMPLATE_MODAL,
  TAG_ADD_CLIENT_MODAL,
} from "../../../services/tags/tags.service";

import {
  SnackbarsService,
  Snack,
  SnackType
} from '../../../services/messaging/snackbars/snackbars.service';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { DashboardDataService } from '../../../services/dashboard-data/dashboard-data.service';
import { PlaintiffsService } from '../../../services/plaintiffs/plaintiffs.service';
import {
  OtherClients,
  DEFAULT_OTHERCLIENTS_TEMPLATE, OtherClientsService
} from '../../../services/other-clients/other-clients.service';
import { ReportsService } from '../../../services/reports/reports.service';
import { ProcessPaymentService } from '../../../services/process-payment/process-payment.service';
// import { TypeaheadMatch } from 'ngx-bootstrap';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import * as moment from 'moment';
import { ActivityLogsService, DEFAULT_ACTIVITY_LOGS, ActivityLogs } from '../../../services/activity-logs/activity-logs.service';
import { NotesService } from '../../../services/notes/notes.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AuthService } from '../../../services/auth/auth.service';
import { SubscriptionBillingService } from '../../../services/subscription-billing/SubscriptionBilling.service';
import { OnBoardingRegister, OnboardingService } from '../../../services/onboarding/onboarding.service';

export enum ReportType {
  BusinessReports = "businessReports",
  FinancialReports = "financialReports",
  LiveStatement = "liveStatement",
  PracticeManagementReports = "practiceManagementReports",
} // end ReportType{}

@Component({
  selector: "app-navbar",
  templateUrl: "./app-navbar.component.html",
  styleUrls: ["./app-navbar.component.scss"],
})
export class AppNavbarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() pageClickEvents: Observable<any>;

  activeTab: string;
  brandURL: string;
  profile: any;
  hidden: boolean;
  links: any;
  showAddNewMenu: boolean;
  showAvatarMenu: boolean;
  showEditProfileModal: boolean;
  showEditFeeTemplateModal: boolean;
  showNewLawFirmModal: boolean;
  showNewAttorneyModal: boolean;
  showSwitchAdvocatesPrompt: boolean;
  images: any;
  showLawfirmTab: boolean = true;
  tags: any;
  canStick: boolean;
  stickyThreshold = 128;
  showAddClientModal = false;
  showJoinAuxconPrompt: boolean;
  showSaveContactModelPrompt: boolean;

  // Change the selected profile.
  changeSelectedProfilePrompt: boolean;
  currentSelectedProviderID: number;

  count: number = 0;
  inboxMessageCounter = 0;
  pmInboxMessageCounter = 0;
  displayDashboard: boolean;

  @ViewChild("navbarTabMenu") navbarTabMenu: ElementRef;
  @ViewChild("selectedAdvocateInput") selectedAdvocateInput: ElementRef;
  // @ViewChild('selectAdvocates') selectAdvocates: ElementRef;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)',
    BAR_SOCIETY: 'Bar Society - User',
    GCB: 'GCB - User',
    ADHOC: 'Ad-hoc',
    HEAD_OF_STATE: 'Head of State'
  };

  private setTimeoutHandle: any;
  private pageClickEventsSubscription: any;
  private pageNavigationEventsSubscription: any;
  selectedAdvocate: UserProfile;
  promptTitle: string;
  message: string;
  selectedTab: string;

  otherClient: OtherClients = Object.assign({}, DEFAULT_OTHERCLIENTS_TEMPLATE);

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);
  showUpdateFeeTeriffPrompt: boolean;
  isShowFreeTrial: boolean = false;
  isShowFreeTrialNotification: boolean = false;
  NextBillingDate: string;
  FreeTrialDays: number;
  TrialDays: number;
  TrialPercentage: number;
  SubscriptionStatus: string;

  bsValue = new Date();
  onBoardingRegister: OnBoardingRegister = {
    FirstName: '',
    LastName: '',
    IDNumber: '',
    Gender: 'Female',
    Ethincity: 'African',
    AdmissionDate: this.bsValue,
    AdmissionDay: 0,
    AdmissionMonth: 0,
    AdmissionYear: 0,
    Seniority: 'Junior',
    ContactNumber: '',
    Email: '',
    BarSocietyID: 0,
    ChamberID: 0,
    TenantID: '',
    AccountType: '',
    Domain: '',
    ContactTypeID: 2,
  };

  constructor(
    private router: Router,
    private api: ApiService,
    private cdr: ChangeDetectorRef,
    private notesService: NotesService,
    private appConfig: AppConfigService,
    private reportService: ReportsService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private authServices: AuthService,
    private onBoardingService: OnboardingService,
    private userProfileService: UserProfileService,
    private dashboardService: DashboardDataService,
    private otherClientService: OtherClientsService,
    private activityLogService: ActivityLogsService,
    private processPaymentService: ProcessPaymentService,
    private subscriptionBillingService: SubscriptionBillingService
  ) {
    this.subscriptionBillingService.RefreshAppNavBar.subscribe((res) => {
      if (res) {
        setTimeout(() => {
          this.isShowFreeTrial = false;
          this.isShowFreeTrialNotification = false;
          this.SubscriptionStatus = localStorage.getItem("status");
          document.getElementById("bodyTag").style.margin = !window.location.href.includes("/onboarding") ? "50px 0px 0px 0px" : "0";
        }, 3000);
      }
    });
    this.images = {
      logo: {
        path: '../../../../assets/' +
          'images/logos/81f56a2b9e02b9da38ff68cfe82732e6.png'
        // description: 'Auxcon Logo'
      }
    };
    this.tags = {
      menus: {
        navbar: {
          addNew: 'add-new'
        },
        avatar: 'avatar'
      },
      modals: {
        profileModal: TAG_EDIT_PROFILE_MODAL,
        feeTemplateModal: TAG_EDIT_FEE_TEMPLATE_MODAL,
        newLawFirmModal: TAG_ADD_NEW_LAW_FIRM_MODAL,
        newAttorney: TAG_ADD_ATTORNEY_FIRM_MODAL,
        newClient: TAG_ADD_CLIENT_MODAL
      }
    };
  } // end constructor()

  get isAssistant(): boolean {
    console
    return Boolean(this.profile && this.profile.isImpersonator) &&
      !Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY);
  } // end isAssistant()

  get sageSetupAllowed(): boolean {
    if (this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER) {
      if (this.userProfileService.userProfile.serviceProviderID !== this.userProfileService.selectedUserProfile.serviceProviderID) {
        return true
      }
      return false
    }
    return true
  }

  get internalAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator) &&
      !Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY)
      && Boolean(this.userProfileService.userProfile.serviceProviderID === 9091 ||
        this.userProfileService.userProfile.serviceProviderID === 29096 ||
        this.userProfileService.userProfile.serviceProviderID === 21647);
  } // end internalAssistant()

  get showReconScreenOnlimitedAssistant(): boolean {
    return Boolean(
        this.userProfileService.userProfile.serviceProviderID === 27920);
  } // end internalAssistant()

  get isExternalAttorneyAssistant(): boolean {
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.profile.isImpersonator) &&
      Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY ||
        this.userProfileService.selectedUserProfile.profileType === this.profileTypes.EXTERNAL_LAWFIRM  ||
        this.userProfileService.userProfile.profileType === this.profileTypes.HEAD_OF_STATE);
  } // end isExternalAttorneyAssistant()

  get isInDashboard(): boolean {
    // console.log('IS IT TRUE: ', this.internalAssistant)dh m
    if (!this.userProfileService)
      return false;
    return Boolean(this.userProfileService.isInDashboardScreen);
  } // end isInDashboard()

  get isInAttorneyDashboard(): boolean {
    if (!this.userProfileService)
      return false;
    return Boolean(this.userProfileService.isInAttorneyDashboardScreen);
  } // end isInAttorneyDashboard()

  get isInPMDashboard(): boolean {
    if (!this.userProfileService)
      return false;
    return Boolean(this.userProfileService.isInPMDashboardScreen);
  } // end isInPMDashboard()

  get isInAdvocateOverview(): boolean {
    if (!this.userProfileService)
      return false;
    return Boolean(this.activeTab === this.links.advocate_overview);
  } // end isInAdvocateOverview{}

  // Is Internal Attorney
  get isAttorney(): boolean {
    if (!this.userProfileService)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
  } // end isAttorney()

  // Is External Attorney
  get isExternalAttorney(): boolean {
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY ||
                   this.userProfileService.userProfile.profileType === this.profileTypes.HEAD_OF_STATE
    );
  } // end isExternalAttorney()

    // Is External Attorney
    get isHeadOfState(): boolean {
      if (!this.userProfileService.userProfile)
        return false;
      return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.HEAD_OF_STATE
      );
    } // end isHeadOfState()

  // Is Practice Manager
  get isPracticeManager(): boolean {
    // console.log('SELECTED TAB ON : ', this.userProfileService.currentOrRequestedTab === 'quotation');
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER &&
      Boolean(this.userProfileService.userProfile.serviceProviderID ===
        this.userProfileService.selectedUserProfile.serviceProviderID));
  } // end isPracticeManager()

  get isQuotation(): boolean {
    if (!this.userProfileService)
      return false;
    return Boolean(this.userProfileService.currentOrRequestedTab === 'quotation') ||
      Boolean(this.userProfileService.currentOrRequestedTab === 'accepted-quotation');
  } // end isQuotation()


  get isHistoricalData(): boolean {
    if (!this.userProfileService)
      return false;
    return Boolean(this.userProfileService.currentOrRequestedTab === 'take-on-data');
  } // end isQuotation()
  // logged in as Practice Manager
  get signInAsPracticeManager(): boolean {
    if (!this.userProfileService)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  }  // end signInAsPracticeManager()

  // Is Internal Lawfirm
  get isLawfirm(): boolean {
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
  } // end isLawfirm()

  // Is External Lawfirm
  get isExternalLawfirm(): boolean {
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_LAWFIRM);
  } // end isExternalLawfirm()

  // Is Bar_Society
  get isBarSociety(): boolean {
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.BAR_SOCIETY ||
      this.userProfileService.userProfile.profileType === this.profileTypes.GCB);
  } // end Is Bar_Society()

  // Is Ad-Hoc
  get isAdhoc(): boolean {
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ADHOC);
  } // end Is Ad-Hoc()

  // Is Internal isLawfirmAssistant
  get isLawfirmAssistant(): boolean {
    return Boolean(this.profile.isImpersonator) &&
      Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
  } // end isLawfirmAssistant()

  get isSubscriptionAllowed(): string {
    if (this.userProfileService.userProfile.profileType === this.profileTypes.ADVOCATE)
      this.SubscriptionStatus = this.userProfileService.userProfile.contactSubscriptions?.status;
    else if (this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER)
      this.SubscriptionStatus = 'Active';
    return this.SubscriptionStatus;
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('IS SUBSCRIPTION ALLOWED: ', this.isSubscriptionAllowed);
    this.profile = this.userProfileService.userProfile;
    this.selectedAdvocate = this.userProfileService.selectedUserProfile;
    if (this.profile) {
      // Check if the user is working on behalf of another person
      if (this.profile.isImpersonator) {
        this.profile.impersonates.sort((a, b) => a.personalDetails.fullName.toUpperCase()
          .localeCompare(b.personalDetails.fullName.toUpperCase()));
        // Check if there is a profile for the user he/she working on behalf
        if (this.userProfileService.selectedUserProfile) {
          this.displayDashboard = this.userProfileService.selectedUserProfile.permissions.view_Dashboard;
          if (this.displayDashboard) {
            this.displayDashboard = this.profile.settings.show_Dashboard;
          }
        }
      } else {
        this.displayDashboard = true;
      }
      if (this.userProfileService.isJustSignedInNav) {
        if (this.profile.contactsExtension != null && this.profile.contactsExtension.admissionYear != null) {
          if ((moment(this.profile.contactsExtension.feesLastUpdate).year() < moment().year()) || !this.profile.contactsExtension.feesLastUpdate) {
            if (this.profile.profileType != this.profileTypes.BAR_SOCIETY &&
              this.profile.profileType != this.profileTypes.ADHOC &&
              this.profile.profileType != this.profileTypes.GCB) {
              this.showUpdateFeeTeriffPrompt = true;
              this.userProfileService.isJustSignedInNav = false;
            }
          }
        }
      }
    }
  } // end ngOnChanges()

  selectedChanged(selectedEvent: string) {
    const selectedUs: UserProfile = this.profile.impersonates.filter(s => s.personalDetails.fullName === selectedEvent);
    this.selectedAdvocate = selectedUs;

    this.api.serviceProviderID = this.selectedAdvocate.serviceProviderID;
    sessionStorage.setItem('advocateUserProfil', JSON.stringify(selectedUs));
    location.reload();
  } // end selectedChanged()

  onAdvocateSelect(event: TypeaheadMatch): void {
    this.loadingService.showOverlay();
    this.currentSelectedProviderID = event.item.serviceProviderID;
    // this.changeSelectedProfilePrompt = true;
    const selectedUs: UserProfile = this.profile.impersonates
      .filter(s => s.serviceProviderID === this.currentSelectedProviderID);
    this.selectedAdvocate = selectedUs;
    sessionStorage.removeItem('allCustomerReportData');
    this.api.serviceProviderID = this.currentSelectedProviderID;
    sessionStorage.setItem('advocateUserProfil', JSON.stringify(selectedUs));

    if (this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER) {
      if (this.selectedAdvocate[0].profileType === this.profileTypes.ADVOCATE ||
        this.selectedAdvocate[0].profileType === this.profileTypes.LAWFIRM ||
        this.selectedAdvocate[0].profileType === this.profileTypes.ATTORNEY) {
        location.reload();
        this.router.navigate([PATH_DASHBOARD]);
        this.updateActiveTab(PATH_DASHBOARD);
        this.userProfileService.refreshSelectedProfile = true;
        location.reload();
      } else if (this.selectedAdvocate[0].profileType === this.profileTypes.PRACTICE_MANAGER) {
        location.reload();
        this.router.navigate([PATH_PRACTICE_MANAGER_REPORTS]);
        this.updateActiveTab(PATH_PRACTICE_MANAGER_REPORTS);
        this.userProfileService.refreshSelectedProfile = true;
      }
    } else {
      location.reload();
    }
    if (this.isExternalAttorneyAssistant) {
      sessionStorage.removeItem('advocateDetailStatement');
      sessionStorage.removeItem('lawfirmPaidStatement');
    }
  } // end onAdvocateSelect()

  onChangeSelectedProfile() {
    this.loadingService.showOverlay();
    const selectedUs: UserProfile = this.profile.impersonates
      .filter(s => s.serviceProviderID === this.currentSelectedProviderID);
    this.selectedAdvocate = selectedUs;

    this.api.serviceProviderID = this.currentSelectedProviderID;
    sessionStorage.setItem('advocateUserProfil', JSON.stringify(selectedUs));

    if (this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER) {
      if (this.selectedAdvocate[0].profileType === this.profileTypes.ADVOCATE ||
        this.selectedAdvocate[0].profileType === this.profileTypes.LAWFIRM ||
        this.selectedAdvocate[0].profileType === this.profileTypes.ATTORNEY) {
        // console.log('advocate selected');
        location.reload();
        this.router.navigate([PATH_DASHBOARD]);
        this.updateActiveTab(PATH_DASHBOARD);
        this.userProfileService.refreshSelectedProfile = true;
        location.reload();
      } else if (this.selectedAdvocate[0].profileType === this.profileTypes.PRACTICE_MANAGER) {
        // console.log('practice manager selected');
        location.reload();
        this.router.navigate([PATH_PRACTICE_MANAGER_REPORTS]);
        this.updateActiveTab(PATH_PRACTICE_MANAGER_REPORTS);
        this.userProfileService.refreshSelectedProfile = true;
      }
    } else {
      location.reload();
    }
  } // end onChangeSelectedProfile()

  onInput(event) {

  } // end onFocus()

  ngOnInit() {
    debugger
    let FreeTrialDays = localStorage.getItem("freeTrialDays");
    let NextBillingDate = localStorage.getItem("nextBillingDate");
    let Status = localStorage.getItem("status");
    if (Status !== 'undefined' && Status !== null)
      this.checkUserSubscriptionDetails(Number(FreeTrialDays), NextBillingDate, Status);
    else {
      this.userProfileService.getContactSubscription().subscribe({
        next: (response: any) => {
          if (response) {
            localStorage.setItem("freeTrialDays", response.freeTrialDays);
            localStorage.setItem("nextBillingDate", response.nextBillingDate);
            localStorage.setItem("status", response.status);
            this.checkUserSubscriptionDetails(response.freeTrialDays, response.nextBillingDate, response.status);
          }
        },
        error: (error) => {
          const snack: Snack = {
            label: 'Error while get subscription details',
            action: null,
            type: SnackType.ERROR
          };
          this.snackbarsService.dismiss().make(snack).show();
        },
        complete: () => { }
      });
    }

    this.pageNavigationEventsSubscription =
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.onNavigationEnd(event);
          this.hidden = event.urlAfterRedirects.includes(PATH_SIGN_IN_LOADING) ||
            event.urlAfterRedirects.includes(PATH_SIGN_IN) ||
            event.urlAfterRedirects.includes(PATH_SIGN_OUT) ||
            event.urlAfterRedirects.includes(PATH_BLACKLIST_PDF_VIEWER) ||
            event.urlAfterRedirects.includes(PATH_GCB_RESPONSE_HANDLER) ||
            event.urlAfterRedirects.includes(PATH_USER_SINGUP);
          event.urlAfterRedirects.includes(PATH_AD_HOC_COLLECTIONS) ||
            event.urlAfterRedirects.includes(PATH_SIGN_OUT);

          if (event.urlAfterRedirects.includes(PATH_MATTERS) ||
            event.urlAfterRedirects.includes(PATH_MESSAGES) ||
            event.urlAfterRedirects.includes(PATH_SEARCH)) {
            this.stickyThreshold = -200;
          }
        }
      });
    this.profile = this.userProfileService.userProfile;
    this.selectedAdvocate = this.userProfileService.selectedUserProfile;
    if (this.profile) {
      // Check if the user is working on behalf of another person
      if (this.profile.isImpersonator) {
        this.profile.impersonates.sort((a, b) => a.personalDetails.fullName.toUpperCase()
          .localeCompare(b.personalDetails.fullName.toUpperCase()));
        // Check if there is a profile for the user he/she working on behalf
        if (this.userProfileService.selectedUserProfile) {
          this.displayDashboard = this.userProfileService.selectedUserProfile.permissions.view_Dashboard;
          if (this.displayDashboard) {
            this.displayDashboard = this.profile.settings.show_Dashboard;
          }
        }
      } else {
        this.displayDashboard = this.profile.settings.show_Dashboard;
      }
    }
    this.brandURL = this.appConfig.baseURL + PATH_DASHBOARD;
    this.links = {
      dashboard: PATH_DASHBOARD,
      calendar: PATH_CALENDAR,
      reports: PATH_REPORTS,
      search: PATH_SEARCH,
      messages: PATH_MESSAGES,
      manageBlackList: PATH_MANAGE_BLACKLIST,
      adHocCollection: PATH_AD_HOC_COLLECTIONS,
      practiceManagerMessages: PATH_PRACTICE_MANAGER_MESSAGES,
      matters: PATH_MATTERS,
      attorneyReports: PATH_ATTORNEY_DASHBOARD,
      lawfirm_dashboard: PATH_LAWFIRM_DASHBOARD,
      lawfirm_calendar: PATH_LAWFIRM_CALENDAR,
      lawfirm_search: PATH_LAWFIRM_SEARCH,
      lawfirm_messages: PATH_LAWFIRM_MESSAGES,
      process: PATH_PROCESS,
      lawfirm_process: PATH_LAWFIRM_PROCESS,
      practiceMDashboard: PATH_PRACTICE_MANAGER_DASHBOARD,
      practice_reports: PATH_PRACTICE_MANAGER_REPORTS,
      advocate_overview: PATH_PRACTICE_ADVOCATE_OVERVIEW,
      auxcon_admin: PATH_AUXCON_ADMIN
    };
    this.showEditProfileModal = false;
    this.dismissMenus();
    this.pageClickEventsSubscription =
      this.pageClickEvents.subscribe(event => this.onPageClick(event));
    // this.getNewBankTransactions();
  } // end ngOnInit()

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  } // end ngAfterViewChecked()


  checkUserSubscriptionDetails(FreeTrialDays: number, NextBillingDate: string, Status: string) {
    if (Status === "Free Trial") {
      this.FreeTrialDays = FreeTrialDays;
      const endDate = moment(NextBillingDate);
      this.TrialDays = endDate.diff(new Date(), 'days');
      this.TrialPercentage = Number((Number(this.FreeTrialDays) - (endDate.diff(moment(new Date()), 'days'))) * (100 / Number(this.FreeTrialDays)));

      if (moment(NextBillingDate).format('YYYY-MM-DD') >= moment(new Date()).format('YYYY-MM-DD')) {
        this.isShowFreeTrial = true;
        document.getElementById("bodyTag").style.margin = !window.location.href.includes("/onboarding") ? "72px 0px 0px 0px" : "0";
      }
      else if (moment(NextBillingDate).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')) {
        this.isShowFreeTrial = false;
        return false;
      }
      return true;
    }
    else if (Status === "Active") {
      const endDate = moment(NextBillingDate);
      this.TrialDays = endDate.diff(new Date(), 'days');
      if (this.TrialDays <= 5 && moment(NextBillingDate).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')) {
        this.isShowFreeTrialNotification = true;
        this.NextBillingDate = moment(NextBillingDate).format('DD-MM-YYYY');
        document.getElementById("bodyTag").style.margin = !window.location.href.includes("/onboarding") ? "50px 0px 0px 0px" : "0";
      }
      return true;
    }
    else if (Status === "Cancelled") {
      this.router.navigate([PATH_SUBSCRIPTION_PROMPT]);
      return false;
    }
  }

  onHideUpdateFeeTeriffPrompt() {
    this.showUpdateFeeTeriffPrompt = false;
  } // end onHideUpdateFeeTeriffPrompt()

  onUpdateFeeTeriff() {
    this.selectedTab = '';
    this.selectedTab = 'billing';
    this.showEditProfileModal = true;
    this.showUpdateFeeTeriffPrompt = false;
  } // end onUpdateFeeTeriff()

  onHideChangingSelectedProfilePrompt() {
    this.selectedAdvocate = JSON.parse(sessionStorage.getItem('advocateUserProfil'));
    this.selectedAdvocateInput.nativeElement.value = this.selectedAdvocate.personalDetails.fullName;
    this.changeSelectedProfilePrompt = false;
  } // end onHideChangingSelectedProfilePrompt()

  ngOnDestroy() {
    this.pageClickEventsSubscription.unsubscribe();
    this.pageNavigationEventsSubscription.unsubscribe();
  } // end ngOnDestroy()

  onHideSwitchAdvocatesPrompt() {
    this.showSwitchAdvocatesPrompt = false;
  } // end onHideSwitchAdvocatesPrompt()

  switchAdvocates() {
    if(this.isHeadOfState) {
    } else {
      this.showSwitchAdvocatesPrompt = true;
    }
  } // end switchAdvocates()

  addNewUser() {
    this.router.navigate([PATH_CREATE_NEW_USER]);
  }

  userValidation() {
    this.router.navigate([PATH_USER_SAGE_VALIDATION]);
  }

  outlookSync() {
    this.router.navigate([PATH_OUTLOOK_SYNC]);
  }
  userSubscription() {
    this.router.navigate([PATH_SUBSCRIPTION_BILLING]);
  }

  activateToFullProfile() {
    this.loadingService.showOverlay();
    this.onBoardingService.upgradeAdHoc().subscribe({ next: (onnext) => {
      // On next
    }, 
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
      complete: () => {
        sessionStorage.removeItem('userProfile');
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigate([PATH_SIGN_IN_LOADING])
        this.loadingService.hideOverlay();
      }
    });    
  } // end activateToFullProfile()

  leavePage() {
    this.showSwitchAdvocatesPrompt = false;
    this.router.navigate([PATH_DASHBOARD]);
  } // end leavePage()

  onHideJoinAuxconPrompt() {
    this.showJoinAuxconPrompt = false;
  } // end onHideJoinAuxconPrompt()

  onJoin() {
    window.open('https://www.auxcon.co.za/contact', '_blank');
    this.showJoinAuxconPrompt = false;
  } // end onJoin()

  onPageClick(event) {
    if (event && event.target && event.target.classList) {
      if (
        !event.target.classList.contains('app-login-menu-link') &&
        !event.target.classList.contains('app-login-username') &&
        !event.target.classList.contains('fa-angle-down') &&
        !event.target.classList.contains('app-navbar-tab') &&
        !event.target.classList.contains('tab-label') &&
        !event.target.classList.contains('menu-item') &&
        !event.target.classList.contains('menu-item') &&
        !event.target.classList.contains('fa')
      ) {
        this.dismissMenus();
      }
    }
  } // end onPageClick()

  /**
   * Opens the navbar modal of the given modal 'name'.
   * @param {string} modal The name of the modal to open.
   */
  openModal(modal: string) {
    let FreeTrialDays = localStorage.getItem("freeTrialDays");
    let NextBillingDate = localStorage.getItem("nextBillingDate");
    let Status = localStorage.getItem("status");
    if (Status !== 'undefined' && Status !== null) {
      var result = this.checkUserSubscriptionDetails(Number(FreeTrialDays), NextBillingDate, Status);
      if (!result)
        return false;
    }

    this.dismissMenus();
    switch (modal) {
      case TAG_EDIT_PROFILE_MODAL:
        this.selectedTab = 'Details';
        this.showEditProfileModal = true;
        break;

      case TAG_EDIT_FEE_TEMPLATE_MODAL:
        this.showEditFeeTemplateModal = true;
        break;

      case TAG_ADD_NEW_LAW_FIRM_MODAL:
        this.showNewLawFirmModal = true;
        break;

      case TAG_ADD_ATTORNEY_FIRM_MODAL:
        this.showNewAttorneyModal = true;
        break;

      case TAG_ADD_CLIENT_MODAL:
        this.showAddClientModal = true;
        break;
    }
  } // end openModal()

  /**
   * Closes the navbar modal of the given modal 'name'.
   * @param {string} modal The name of the modal to close.
   */
  closeModal(modal: string) {
    console.log('CLOSE THE MODAL: ', modal);
    switch (modal) {
      case TAG_EDIT_PROFILE_MODAL:
        this.showEditProfileModal = false;
        break;

      case TAG_EDIT_FEE_TEMPLATE_MODAL:
        this.showEditFeeTemplateModal = false;
        break;

      case TAG_ADD_NEW_LAW_FIRM_MODAL:
        this.showNewLawFirmModal = false;
        break;

      case TAG_ADD_ATTORNEY_FIRM_MODAL:
        this.showNewAttorneyModal = false;
        break;

      case TAG_ADD_CLIENT_MODAL:
        this.showAddClientModal = false;
        break;
    }
    this.dismissMenus();
  } // end closeModal()

  /**
   * Called when the user requests for user profile to be updated.
   * @param {UserProfile} event The triggering event.
   */
  onSaveUserProfile(userProfile: UserProfile) {

    const snack: Snack = {
      label: 'Saving user details...',
      action: null
    };

    this.snackbarsService.make(snack, 5000).show();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Update profile';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Navbar)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Contacts';
    this.activityLog.JsonData = JSON.stringify(userProfile);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_next) => { } });

    this.userProfileService
      .updateUserProfile(userProfile).subscribe({
        next: (profile) => {
          // On next: returns empty body in this case

          this.userProfileService.userProfile = userProfile;
          this.profile = userProfile;
          sessionStorage.setItem('userProfile', JSON.stringify(userProfile));
        },
        error: (error) => {
          const msg = 'Error updating user details.';
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack, 5000).show();
        },
        complete: () => {
          // On complete
          snack.label = 'User details saved.';
          this.snackbarsService.dismiss().make(snack).show();
          // location.reload();
          this.router.navigate(["/"]);
        }
      });
  } // end onSaveUserProfile()

  onSaveOtherClients(otherClient) {
    if (otherClient.FullName === undefined) {
      this.otherClient = {
        Id: 0,
        FullName: otherClient,
        ContactID: 0,
        EmailAddress: '',
        IDNumber: '',
        MobileNumber: '',
        WorkPhone: '',
        WorkFax: '',
        VATNumber: '',
        StreetAddressFull: '',
        Flag: false
      };
    }
    const snack: Snack = {
      label: 'Saving new Client...',
      action: null
    };
    this.snackbarsService.make(snack).show();

    // this.otherClientService.searchContacts(this.otherClient.MobileNumber).subscribe(
    //   cell_results => {
    //     // On next
    //     if (cell_results.length > 0) {
    //       this.promptTitle = 'Cell number already in use.';
    //       this.message = 'The Cell number entered: ' +
    //         this.otherClient.MobileNumber +
    //         ' already exist on our system, please search using it or enter a different phone number.';

    //       snack.label = this.message;
    //       this.snackbarsService.dismiss().make(snack).show();
    //       this.showSaveContactModelPrompt = true;
    // this.loadingService.hideOverlay();
    // } else {
    //   this.otherClientService.searchContacts(this.otherClient.EmailAddress).subscribe(
    //     email_results => {
    //       // On next
    //       if (email_results.length > 0) {
    //         this.promptTitle = 'Email address already in use.';
    //         this.message = 'The email address entered: ' +
    //           this.otherClient.EmailAddress +
    //           ' already exist on our system, please use another email.';

    //         snack.label = this.message;
    //         this.snackbarsService.dismiss().make(snack).show();
    //         this.showSaveContactModelPrompt = true;
    // this.loadingService.hideOverlay();

    // } else {
    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Add other clients';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Navbar)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Contacts';
    this.activityLog.JsonData = JSON.stringify(this.otherClient);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_next) => { } });

    this.otherClient.Flag = true;
    this.otherClientService.createClient(this.otherClient).subscribe({
      next: (plaintiff) => {
        this.otherClient = plaintiff;
      },
      error: (error) => {
        const msg = 'Error saving new Client.';
        snack.label = msg;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
      },
      complete: () => {
        snack.label = 'Client created.';
        snack.type = null;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        this.otherClientService
          .getOtherClients()
          .subscribe(otherclients => {
            location.reload();
          });
      }
    });
    // }
    // });
    // }
    // });
  } // end onSaveOtherClients()

  onHideSaveContactModelPrompt() {
    this.showSaveContactModelPrompt = false;
  } // end onHideSaveContactModelPrompt()

  /**
   * Triggered when navigation ends successfully.
   * @param {any} event
   */
  onNavigationEnd(event: NavigationEnd) {
    if (event.urlAfterRedirects.includes(PATH_DASHBOARD)) {
      this.updateActiveTab(PATH_DASHBOARD);
      this.getNewBankTransactions();
      this.getInboxMessageCounter();
    } else if (event.urlAfterRedirects.includes(PATH_PRACTICE_ADVOCATE_OVERVIEW)) {
      this.updateActiveTab(PATH_PRACTICE_ADVOCATE_OVERVIEW);
      this.getNewBankTransactions();
      this.getInboxMessageCounter();
    } else if (event.urlAfterRedirects.includes(PATH_CALENDAR)) {
      this.updateActiveTab(PATH_CALENDAR);
      this.getNewBankTransactions();
      this.getInboxMessageCounter();
    } else if (event.urlAfterRedirects.includes(PATH_REPORTS)) {
      this.updateActiveTab(PATH_REPORTS);
      this.getNewBankTransactions();
      this.getInboxMessageCounter();
    } else if (event.urlAfterRedirects.includes(PATH_SEARCH)) {
      this.updateActiveTab(PATH_SEARCH);
      this.getNewBankTransactions();
      this.getInboxMessageCounter();
    } else if (event.urlAfterRedirects.includes(PATH_MESSAGES)) {
      this.updateActiveTab(PATH_MESSAGES);
      this.getNewBankTransactions();
    } else if (event.urlAfterRedirects.includes(PATH_MANAGE_BLACKLIST)) {
      this.updateActiveTab(PATH_MANAGE_BLACKLIST);
    } else if (event.urlAfterRedirects.includes(PATH_AD_HOC_COLLECTIONS)) {
      this.updateActiveTab(PATH_AD_HOC_COLLECTIONS);
    } else if (event.urlAfterRedirects.includes(PATH_MATTERS)) {
      this.updateActiveTab(PATH_MATTERS);
      this.getNewBankTransactions();
      this.getInboxMessageCounter();
    } else if (event.urlAfterRedirects.includes(PATH_ATTORNEY_DASHBOARD)) {
      this.updateActiveTab(PATH_REPORTS);
      this.getNewBankTransactions();
      this.getInboxMessageCounter();
    } else if (event.urlAfterRedirects.includes(PATH_PROCESS)) {
      this.updateActiveTab(PATH_PROCESS);
      this.getInboxMessageCounter();
    } else if (event.urlAfterRedirects.includes(PATH_PRACTICE_MANAGER_DASHBOARD)) {
      this.updateActiveTab(PATH_PRACTICE_MANAGER_DASHBOARD);
      this.getPMInboxMessageCounter();
    } else if (event.urlAfterRedirects.includes(PATH_PRACTICE_MANAGER_REPORTS)) {
      this.updateActiveTab(PATH_PRACTICE_MANAGER_REPORTS);
      this.getPMInboxMessageCounter();
    } else if (event.urlAfterRedirects.includes(PATH_PRACTICE_ADVOCATE_DETAIL)) {
      this.updateActiveTab(PATH_PRACTICE_MANAGER_REPORTS);
      this.getPMInboxMessageCounter();
    } else if (event.urlAfterRedirects.includes(PATH_PRACTICE_MANAGER_MESSAGES)) {
      this.updateActiveTab(PATH_PRACTICE_MANAGER_MESSAGES);
    }

  } // end onNavigationEnd()

  getNewBankTransactions() {
    if (this.isExternalAttorney === false && this.isExternalLawfirm === false) {
      this.processPaymentService.getBankTransactionCount().subscribe({
        next: (_next) => {
          this.count = _next;
        }
      });

    }
  }

  getInboxMessageCounter() {
    if (!this.isPracticeManager) {
      //this.inboxMessageCounter =  this.notesService.getAdvUnreadMessagesCounter(this.api.serviceProviderID).toPromise();
    }
  } // end getInboxMessageCounter()

  getPMInboxMessageCounter() {
    if (this.isPracticeManager) {
      this.notesService.getPMUnreadMessagesCounter(this.userProfileService.userProfile.serviceProviderID).subscribe({
        next: (_data) => {
          // On next
          this.pmInboxMessageCounter = _data;
        }
      });
    }
  } // end getPMInboxMessageCounter()

  /**
   * Closes all open navbar menus.
   */
  dismissMenus() {
    this.showAddNewMenu = false;
    this.showAvatarMenu = false;
  } // dismissMenus()

  /**
   * Sets the active tab to the given tab name. NOTE: Results in all open
   * navbar menus being dismissed.
   * @param {string} tab The new active tab.
   * NOTE: No tab set if empty string is passed.
   */
  updateActiveTab(tab: string): void {
    if (tab === this.links.lawfirm_dashboard) {
      this.showJoinAuxconPrompt = true;
    } else if (tab === this.links.lawfirm_calendar) {
      this.showJoinAuxconPrompt = true;
    } else if (tab === this.links.lawfirm_search) {
      this.showJoinAuxconPrompt = true;
      // } else if (tab === this.links.lawfirm_manage_balcklist) {
      //   this.showJoinAuxconPrompt = true;
    } else if (tab === this.links.lawfirm_messages) {
      this.showJoinAuxconPrompt = true;
    } else if (tab === this.links.lawfirm_process) {
      this.showJoinAuxconPrompt = true;
    } else {
      this.dismissMenus();
      if (this.isAdhoc)
        this.activeTab = this.links.Report;
      else
        this.activeTab = tab;
    }
    // this.getNewBankTransactions();
  } // end updateActiveTab()

  /**
   * Toggles the state of the dropdown menu of given name.
   * @param {string} menuName The name of the menu.
   */
  toggleDropdownMenu(menuName: string): void {
    switch (menuName) {
      case 'add-new':
        this.showAddNewMenu = !this.showAddNewMenu;
        break;

      case 'avatar':
        this.showAvatarMenu = !this.showAvatarMenu;
        break;
    }
  } // end toggleDropdownMenu()

  // onBehalfOpen() {
  //   this.selectAdvocates.nativeElement.attributeStyleMap.size = this.selectAdvocates.nativeElement.length;
  // } // end onBehalfOpen()

  /**
   * Called when the edit fee template option is clicked from the avatar
   * dropdown menu.
   */
  onEditFeeTemplate() {
    this.dismissMenus();
    this.router.navigate([PATH_EDIT_FEE_TEMPLATES]);
  } // end onEditFeeTemplate()

  onAddEditClients() {
    this.dismissMenus();
    this.router.navigate([PATH_ADD_EDIT_CLIENTS]);
  } // end onAddEditClients()

  onEditMyTeam() {
    this.dismissMenus();
    this.router.navigate([PATH_EDIT_MY_TEAM]);
  } // end onEditMyTeam()

  /**
   * Opens the matter of given matterID. If no matterID is provided,
   * a new matter is opened.
   * @param {number} matterID The unique matter identification number.
   */
  openMatter(matterID: number = null) {
    this.updateActiveTab(this.links.matters);
    if (this.setTimeoutHandle) {
      clearTimeout(this.setTimeoutHandle);
    }

    this.setTimeoutHandle = setTimeout(_ => {
      if (matterID) {
        this.router.navigate([PATH_MATTERS, matterID]);
      } else {
        this.router.navigate([PATH_MATTERS]);
      }
    }, 10);
  } // end openMatter()

  openQuotation() {
    this.router.navigate([PATH_QUOTATION]);
  } // end openQuotation()

  /**
   * Signs the user out of the curren session.
   */
  signOut() {
    this.dismissMenus();
    // Clear all localStorage
    // Log activity Login
    // const currentDate = new Date();
    // this.activityLog.Action = 'Sign out';
    // this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    // this.activityLog.LoggedApp = 'Web Application (Navbar)';
    // if (this.userProfileService.userProfile.isImpersonator) {
    //   this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
    //   this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    // } else {
    //   this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
    //   this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    // }
    // this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    // this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    // this.activityLog.ActionTable = 'Contacts';
    // this.activityLog.JsonData = JSON.stringify(this.userProfileService.userProfile);
    // this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_next) => {}});

    this.authServices.logout();
    this.router.navigate([PATH_SIGN_OUT]);
    sessionStorage.removeItem('userProfile');
    sessionStorage.clear();
    localStorage.clear(); // TODO: Move to sign out page
    this.api.serviceProviderID = 0;
  }// end signOut()
} // end AppNavbarComponent{}