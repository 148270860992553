import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { ReportsService } from '../../../services/reports/reports.service';
import { DashboardDataService } from '../../../services/dashboard-data/dashboard-data.service';
import {
  OutstandingBookSummary,
  DEFAULT_OUTSTANDING_BOOK_SUMMARY
} from '../../../services/practice-manager-dashoboard-service/practice-manager-dashboard.service';
import { OverdueByFirm,
  AdvocateOverviewServiceService } from '../../../services/advocate-overview-service/advocate-overview-service.service';
import {
  PATH_REPORT_ALL_CUSTOMERS,
  PATH_REPORT_AGE_ANALYSIS
} from '../../../services/appdata/app-config.service';
// import { reject } from 'q';

@Component({
  selector: 'app-overview-summary',
  templateUrl: './overview-summary.component.html',
  styleUrls: ['./overview-summary.component.scss']
})
export class OverviewSummaryComponent implements OnInit, OnChanges {

  isLoading = true;
  agingCurrentTotal = 0;
  aging30Total = 0;
  aging60Total = 0;
  aging90Total = 0;
  aging98Total = 0;
  agingCurrentPercentage = 0;
  aging30Percentage = 0;
  aging60Percentage = 0;
  aging90Percentage = 0;
  aging98Percentage = 0;
  agePercentageColor = '';
  advocateName: string;

  lawfirmOverduePercentage = 0;

  lawfirmOne: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);
  lawfirmTwo: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);
  lawfirmThree: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);
  lawfirmFour: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);
  allFirms: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  @Input() overdueByFirm: OverdueByFirm[];
  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private reportsService: ReportsService,
    private userProfileService: UserProfileService,
    private dashboardDataService: DashboardDataService,
    private advocateOverviewService: AdvocateOverviewServiceService,
  ) { } // end constrcutor()

  ngOnInit() {
    // this.loadingService.showOverlay();
    this.advocateName = this.userProfileService.selectedUserProfile.personalDetails.firstName;
    // this.overdueByFirm = this.advocateOverviewService.getOverdueByFirm().toPromise();
    // Law firm one
    const _this = this;
    Promise.all([this.getAgeAnalysisReport()]).then(function () {

      _this.loadingService.hideOverlay();
    }).catch(function (error) {

      _this.loadingService.hideOverlay();
    });
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges): void {
    if (this.overdueByFirm) {
      if (this.overdueByFirm.length > 0) {
      this.lawfirmOne.Due = this.overdueByFirm[0].TotalOutstanding;
      this.lawfirmOne.Lawfirm = this.overdueByFirm[0].Lawfirm;
      this.lawfirmOne.LawfirmId = this.overdueByFirm[0].LawfirmId;
      }
  
      // Law firm two
      if (this.overdueByFirm.length > 1) {
      this.lawfirmTwo.Due = this.overdueByFirm[1].TotalOutstanding;
      this.lawfirmTwo.Lawfirm = this.overdueByFirm[1].Lawfirm;
      this.lawfirmTwo.LawfirmId = this.overdueByFirm[1].LawfirmId;
      }
  
      // Law firm three
      if (this.overdueByFirm.length > 2) {
      this.lawfirmThree.Due = this.overdueByFirm[2].TotalOutstanding;
      this.lawfirmThree.Lawfirm = this.overdueByFirm[2].Lawfirm;
      this.lawfirmThree.LawfirmId = this.overdueByFirm[2].LawfirmId;
      }
  
      // Law firm four
      if (this.overdueByFirm.length > 3) {
      this.lawfirmFour.Due = this.overdueByFirm[3].TotalOutstanding;
      this.lawfirmFour.Lawfirm = this.overdueByFirm[3].Lawfirm;
      this.lawfirmFour.LawfirmId = this.overdueByFirm[3].LawfirmId;
      }
      this.isLoading = false;
    }

    // All firms overdue for this advocate
    let totalDue = 0;
    const totalDisplayedFirms = Number(this.lawfirmOne.Due) + Number(this.lawfirmTwo.Due) +
          Number(this.lawfirmThree.Due) + Number(this.lawfirmFour.Due);
          this.overdueByFirm.forEach(_due => {
            totalDue = totalDue + _due.TotalOutstanding;
          });
    this.allFirms.Due = totalDue - totalDisplayedFirms;
    this.allFirms.Lawfirm = 'All other law firms';
    this.allFirms.LawfirmId = 0;

    this.lawfirmOverduePercentage = this.lawfirmOne.Due / this.aging98Total * 100;
  } // end ngOnChanges()

  getTopOutstandingFirmColor() {

  } // end getTopOutstandingFirmColor()

  getAgeAnalysisReport(): Promise<any> {
    const _this = this;
    return new Promise(function (resolve, reject) {
      _this.reportsService.getAgeAnalysis().subscribe(response => {
        if (response) {
          _this.agingCurrentPercentage = response.agingCurrentPercentage;
          _this.aging30Percentage = response.aging30Percentage;
          _this.aging60Percentage = response.aging60Percentage;
          _this.aging90Percentage = response.aging90Percentage;
          _this.aging98Percentage = response.aging98Percentage;

          for (const item of response.frequencyTotals) {
            _this.aging30Total += item.aging30Total;
            _this.aging60Total += item.aging60Total;
            _this.aging90Total += item.aging90Total;
            _this.aging98Total += item.aging98Total;
            _this.agingCurrentTotal += item.agingCurrentTotal;
          }
        }

        if (_this.aging98Percentage <= 25) {
          _this.agePercentageColor = '#8abd37';
        } else if (_this.aging98Percentage >= 26 && _this.aging98Percentage <= 49) {
          _this.agePercentageColor = '#e5c100';
        } else if (_this.aging98Percentage >= 50 && _this.aging98Percentage <= 75) {
          _this.agePercentageColor = '#eb9e02';
        } else if (_this.aging98Percentage >= 76) {
          _this.agePercentageColor = '#cf624f';
        }
        // resolve();
      });
    });
  } // end getAgeAnalysisReport()

  getBarHeight(value: number): string {
    return (value * 70 / 100) + 'px';
  } // end getBarHeight()

  getBarTextTopMargin(value: number): string {
    const height = (value * 70 / 100);
    if (height < 17) {
      return '-20px';
    }
    return ((height / 2) - 9) + 'px';
  } // end getBarTextTopMargin()

  getBarTextColor(value: number): string {
    const height = (value * 70 / 100);
    if (height < 17) {
      return '#000';
    }
    return '#fff';
  } // end getBarTextColor()

  onLawfirmOverdueClick(): void {
    this.reportsService.allCustomerActiveTab = '98';
    this.router.navigate([PATH_REPORT_ALL_CUSTOMERS]);
  } // end onLawfirmOverdueClick()

  onViewMoreReport(reportName: string) {
    if (reportName === 'ageAnalysis') {
      this.router.navigate([PATH_REPORT_AGE_ANALYSIS]);
    }
  } // end onViewMoreReport()
} // end OverviewSummaryComponent{}
