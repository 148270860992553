import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, Input, ViewChild } from '@angular/core';
import { jqxChartComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

@Component({
  selector: 'app-receipt-chart',
  templateUrl: './receipt-chart.component.html',
  styleUrls: ['./receipt-chart.component.scss']
})
export class ReceiptChartComponent implements AfterViewInit, OnChanges {
  @Input() previousMonthsCanvasId: string;
  @Input() previousYearsCanvasId: string;
  @Input() summaryTitle: string;
  @Input() summaryDescription: string;
  @Input() dataMonthly: any;
  @Input() dataYearly: any;

  @ViewChild('myChart') myChart: jqxChartComponent;
  @ViewChild('myChartYearly') myChartYearly: jqxChartComponent;

  activeTab = 'monthly';
  previousMonthsChart: any = [];
  previousYearsChart: any = [];

  collectedMonthlyTarget = 'R ';
  collectedMonthlyRecord = 'R ';

  collectedYearlyTarget = 'R ';
  collectedYearlyRecord = 'R ';

  //#region Monthly chart setup
  // Chart data
  receiptData: any = [
    { Collected: 'Nov', RECEIPT: 0 },
    { Collected: 'Oct', RECEIPT: 0 },
    { Collected: 'Sep', RECEIPT: 0 },
  ]; // end receiptData:

  redchart = 100;
  yellowchart = 200;
  greenchart = 300;

  padding: any = { left: 20, top: 5, right: 20, bottom: 5 };

  xAxis: any = {
    dataField: 'Collected',
    gridLines: { visible: false },
    flip: false,
    valuesOnTicks: false,
    labels: {
      angle: 0,
      formatFunction: (value: any) => {
        return value.toString();
      }
    },
    tickMarks: {
      visible: true,
      interval: 1,
      color: '#CACACA'
    }
  }; // end xAxis:

  valueAxis: any = {
    flip: true,
    labels: {
      visible: true,
      formatFunction: (value: number) => {
        return value / 1000000;
      }
    }
  }; // end valueAxis:

  seriesGroups: any[] = [{
    orientation: 'horizontal',
    type: 'column',
    useGradient: false,
    columnsGapPercent: 10,
    columnsMaxWidth: 30,
    toolTipFormatSettings: { thousandsSeparator: ',' },
    valueAxis:
    {
      flip: true,
      textRotation: -30,
      minValue: 0,
      maxValue: 300,
      unitInterval: 30,
      tickMarks: { color: '#BCBCBC' }
    },
    series: [
      {
        dataField: 'RECEIPT',
        displayText: 'Receipt (k)',
        labels: {
          visible: true,
        },
        colorFunction: (value: any, itemIndex: any, serie: any, group: any): any => {
          return (value <= this.redchart) ? '#d57f70' : (value <= this.yellowchart) ? '#dcb967' : '#99ba62';
        }
      }
    ],
    bands:
      [
        { minValue: 0, maxValue: 100, color: '#d57f70', opacity: 0.30, showLabel: true },
        { minValue: 100, maxValue: 200, color: '#dcb967', opacity: 0.30 },
        { minValue: 200, maxValue: 300, color: '#99ba62', opacity: 0.30 }
      ]
  }]; // end seriesGroups[]

  //#endregion Monthly chart setup

  //#region Yearly chart setup
  // Chart data yearly
  receiptDataYearly: any = [
    { Collected: 'Nov', RECEIPT: 0 },
    { Collected: 'Oct', RECEIPT: 0 },
    { Collected: 'Sep', RECEIPT: 0 },
  ]; // end receiptData:

  redchartYearly = 100;
  yellowchartYearly = 200;
  greenchartYearly = 300;

  xAxisYearly: any = {
    dataField: 'Collected',
    gridLines: { visible: false },
    flip: true,
    valuesOnTicks: false,
    labels: {
      angle: 0,
      formatFunction: (value: any) => {
        return value.toString();
      }
    },
    tickMarks: {
      visible: true,
      interval: 1,
      color: '#CACACA'
    }
  }; // end xAxis:

  valueAxisYearly: any = {
    flip: true,
    labels: {
      visible: true,
      formatFunction: (value: number) => {
        return value / 1000000;
      }
    }
  }; // end valueAxis:

  seriesGroupsYearly: any[] = [{
    orientation: 'horizontal',
    type: 'column',
    useGradient: false,
    columnsGapPercent: 10,
    columnsMaxWidth: 30,
    toolTipFormatSettings: { thousandsSeparator: ',' },
    valueAxis: {
      flip: true,
      minValue: 0,
      maxValue: 300,
      unitInterval: 30,
      tickMarks: { color: '#BCBCBC' }
    },
    series: [{
      dataField: 'RECEIPT',
      displayText: 'Receipt (k)',
      labels: {
        visible: true,
      },
      colorFunction: (value: any, itemIndex: any, serie: any, group: any): any => {
        return (value <= this.redchart) ? '#d57f70' : (value <= this.yellowchart) ? '#dcb967' : '#99ba62';
      }
    }],
    bands: [
      { minValue: 0, maxValue: 100, color: '#d57f70', opacity: 0.30, showLabel: true },
      { minValue: 100, maxValue: 200, color: '#dcb967', opacity: 0.30 },
      { minValue: 200, maxValue: 300, color: '#99ba62', opacity: 0.30 }
    ]
  }]; // end seriesGroups[]

  //#endregion Yearly Chart setup
  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private userProfileService: UserProfileService
  ) { } // end constructor()

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataMonthly && this.dataMonthly.monthly.receiptMaxValue > 0) {
        this.getReceiptMatterData(this.dataMonthly.monthly);
      }
    if (this.dataYearly && this.dataYearly.yearly.receiptYearMaxValue > 0) {
        this.getReceiptMatterDataYearly(this.dataYearly.yearly);
      }
  } // end ngOnChanges()

  ngAfterViewInit() { } // end ngAfterViewInit()

  getReceiptMatterData(monthly: any) {
    // Monthly Target & Monthly record
    this.collectedMonthlyTarget = monthly.receiptMonthlyTarget;
    this.collectedMonthlyRecord = monthly.receiptMonthlyRecord;

    this.redchart = monthly.receiptRangeStart;
    this.yellowchart = monthly.receiptRangeEnd;
    this.greenchart = monthly.receiptMaxValue;

    this.receiptData = [
      {
        Collected: monthly.receiptThismonthLabel,
        RECEIPT: monthly.receiptThismonthValue / 1000
      },
      {
        Collected: monthly.receiptLastMonthLabel,
        RECEIPT: monthly.receiptLastMonthValue / 1000
      },
      {
        Collected: monthly.receiptMonthBeforeLast,
        RECEIPT: monthly.receiptMonthBeforValue / 1000
      },
    ];

    this.seriesGroups = [{
      orientation: 'horizontal',
      type: 'column',
    useGradient: false,
      columnsGapPercent: 10,
      columnsMaxWidth: 30,
      toolTipFormatSettings: { thousandsSeparator: ',' },
      valueAxis:
      {
        flip: true,
        textRotationAngle: -30,
        minValue: 0,
        maxValue: monthly.receiptMaxValue,
        unitInterval: monthly.receiptMaxValue / 10,
        tickMarks: { color: '#e6e6e6' },
        formatSettings: { sufix: 'k' }
      },
      series: [{
        dataField: 'RECEIPT',
        displayText: 'Collected (K)',
        labels: {
          visible: true,
        },
        formatFunction: (value: any) => {
          return this.currencySymbol + new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 }).format(value * 1000);
        },
        colorFunction: (value: any, itemIndex: any, serie: any, group: any): any => {
          return (value <= this.redchart) ? '#d57f70' : (value <= this.yellowchart) ? '#dcb967' : '#99ba62';
        }
      }],
      bands: [
        { minValue: 0, maxValue: monthly.receiptRangeStart, color: '#d57f70', opacity: 0.30, showLabel: true },
        {
          minValue: monthly.receiptRangeStart,
          maxValue: monthly.receiptRangeEnd, color: '#dcb967', opacity: 0.30
        },
        {
          minValue: monthly.receiptRangeEnd,
          maxValue: monthly.receiptMaxValue, color: '#99ba62', opacity: 0.30
        }
      ]
    }]; // end seriesGroups[]
  } // end getReceiptMatterData()

  getReceiptMatterDataYearly(yearly: any) {
    // Yearly Target & Monthly record
    this.collectedYearlyTarget = yearly.receiptYearlyTarget;
    this.collectedYearlyRecord = yearly.receiptYearlyRecord;

    this.redchartYearly = yearly.receiptYearRangeStart;
    this.yellowchartYearly = yearly.receiptYearRangeEnd;
    this.greenchartYearly = yearly.receiptYearMaxValue;
    this.receiptDataYearly = [
      {
        Collected: yearly.receiptThisYearLabel,
        RECEIPT: yearly.receiptThisYearValue / 1000
      },
      {
        Collected: yearly.receiptLastYearLabel,
        RECEIPT: yearly.receiptLastYearValue / 1000
      },
      {
        Collected: yearly.receiptYearBeforeLast,
        RECEIPT: yearly.receiptYearBeforValue / 1000
      },
    ];
    this.seriesGroupsYearly = [{
      orientation: 'horizontal',
      type: 'column',
    useGradient: false,
      columnsGapPercent: 10,
      columnsMaxWidth: 30,
      toolTipFormatSettings: { thousandsSeparator: ',' },
      valueAxis:
      {
        flip: true,
        minValue: 0,
        textRotationAngle: -30,
        maxValue: yearly.receiptYearMaxValue,
        unitInterval: yearly.receiptYearMaxValue / 10,
        tickMarks: { color: '#e6e6e6' },
        formatSettings: { sufix: 'k' }
      },
      series: [
        {
          dataField: 'RECEIPT',
          displayText: 'Collected (K)',
          labels: {
            visible: true,
          },
          formatFunction: (value: any) => {
            return this.currencySymbol + new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 }).format(value * 1000);
          },
          colorFunction: (value: any, itemIndex: any, serie: any, group: any): any => {
            return (value <= this.redchartYearly) ? '#d57f70' : (value <= this.yellowchartYearly) ? '#dcb967' : '#99ba62';
          }
        }
      ],
      bands: [
        { minValue: 0, maxValue: yearly.receiptYearRangeStart, color: '#d57f70', opacity: 0.30, showLabel: true },
        {
          minValue: yearly.receiptYearRangeStart,
          maxValue: yearly.receiptYearRangeEnd, color: '#dcb967', opacity: 0.30
        },
        {
          minValue: yearly.receiptYearRangeEnd,
          maxValue: yearly.receiptYearMaxValue, color: '#99ba62', opacity: 0.30
        }
      ]
    }]; // end seriesGroupsYearly[]
  } // end getReceiptMatterDataYearly()

  onTabClick(tab: string) {
    this.activeTab = tab;
     setTimeout(() => {
       if (this.dataYearly && this.dataYearly.yearly.receiptYearMaxValue > 0) {
        this.getReceiptMatterDataYearly(this.dataYearly.yearly);
       }
    }, 100);
  } // end onTabClick()
} // end ReceiptChartComponent{}
