import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../services/api.service';
import { UtilitiesService } from '../../services/utilities.service';
import { Observable ,  pipe } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface AdvocateOverviewWidget {
  InvoicedTarget: number;
  CollectionTarget: number;
  TotalInvoiced: number;
  TotalCollectable: number;
}

export const DEFAULT_ADVOCATE_OVERVIEW_WIDGET: AdvocateOverviewWidget = {
  CollectionTarget: 0,
  InvoicedTarget: 0,
  TotalCollectable: 0,
  TotalInvoiced: 0
};

export interface OverdueByFirm {
  LawfirmId: number;
  Lawfirm: string;
  AdvocateId: number;
  TotalOutstanding: number;
}

export const DEFAULT_OVERDUE_BY_FIRM: OverdueByFirm = {
  LawfirmId: 0,
  Lawfirm: '',
  AdvocateId: 0,
  TotalOutstanding: 0
};

export interface FinancialDetails {
  AdvocateId: number;
  StatementRun: string;
  HourlyRate: number;
  DailyRate: number;
  DefaultPerKilometerRate: number;
  IncomeTarget: number;
  FeeTarget: number;
  PaymentTerms: number;
  IsVatRegistered: boolean;
  VATNumber: string;
  SecretaryName: string;
  SecretaryContactNumber: string;
  SecretaryEmail: string;
  PermissionType: string;
  BankName: string;
  AccountNumber: string;
  Accounttype: string;
  BranchCode: string;
  HaveBankAccess: boolean;
  VATReport: boolean;
  InvoiceHistoryReport: boolean;
  BankStatement: boolean;
  AuditorName: string;
  AuditorEmail: string;
  AuditorPhone: string;
  ReportToAuditors: boolean;
  VATRegistrationDate: string;
  FinancialStatus: string;
  StatementReminderDay :number;
  TaxValue: number;
  VatBasedOn: string;
  LastVATPeriodEndDate: string;
  ManualTransaction: boolean;
  MAAA: string;
  NextInvoiceNumber: string;
}

export interface ContactRelationship {
  Id: number;
  ParentId: number;
  ChildId: number;
  FullName: string;
  Identity: string;
  UserName: string;
  Level: string;
  CellPhone: string;
  WorkPhone: string;
  Fax: string;
  Email: string;
  VATNo: string;
  OfficeRoom: string;
  StreetAddress: string;
  PostalAddress: string;
  Relationship: string;
  RelationshipStatus: string;
}

export const DEFAULT_CONTACT_RELATIONSHIP: ContactRelationship = {
  Id: 0,
  ParentId: 0,
  ChildId: 0,
  FullName: '',
  Identity: '',
  UserName: '',
  Level: '',
  CellPhone: '',
  WorkPhone: '',
  Fax: '',
  Email: '',
  VATNo: '',
  OfficeRoom: '',
  StreetAddress: '',
  PostalAddress: '',
  Relationship: '',
  RelationshipStatus: '',
};

export const DEFAULT_FINANCIAL_DETAILS: FinancialDetails = {
  AdvocateId: 0,
  StatementRun: '',
  HourlyRate: 0,
  DailyRate: 0,
  DefaultPerKilometerRate: 0,
  IncomeTarget: 0,
  FeeTarget: 0,
  PaymentTerms: 0,
  IsVatRegistered: false,
  VATNumber: '',
  SecretaryName: '',
  SecretaryContactNumber: '',
  SecretaryEmail: '',
  PermissionType: '',
  BankName: '',
  AccountNumber: '',
  Accounttype: '',
  BranchCode: '',
  HaveBankAccess: false,
  AuditorEmail: '',
  AuditorName: '',
  AuditorPhone: '',
  BankStatement: false,
  InvoiceHistoryReport: false,
  VATReport: false,
  ReportToAuditors: false,
  VATRegistrationDate: '',
  FinancialStatus: '',
  StatementReminderDay : 0,
  TaxValue: 0,
  VatBasedOn: '',
  LastVATPeriodEndDate: '',
  ManualTransaction: false,
  MAAA: '',
  NextInvoiceNumber: ''
};

export interface OverviewContactDetails {
  Id: number;
  CellPhone: string;
  Phone: string;
  Fax: string;
  Email: string;
  MeetingType: string;
  MeetingAppName: string;
  MeetingFrequency: string;
  Website: string;
  Chambers: string;
  ChambersAddress: string;
}
export const DEFAULT_OVERVIEW_CONTACT_DETAILS: OverviewContactDetails = {
  Id: 0,
  CellPhone: '',
  Phone: '',
  Fax: '',
  Email: '',
  MeetingType: '',
  MeetingAppName: '',
  MeetingFrequency: '',
  Website: '',
  Chambers: '',
  ChambersAddress: '',
};

export interface PersonalDetails {
  AdvocateId: number;
  AdvocateFirstName: string;
  AdvocateLastName: string;
  AdvocateIdentityNumber: string;
  AdmissionDate: string;
  AdmissionDay: number;
  AdmissionMonth: number;
  AdmissionYear: number;
  Seniority: string;
  PracticeType: string;
  InvolvementInPractice: string;
  PreAdvocacy: string;
  PreAdvocacyDetails: string;
  PersonalityType: string;
  RelationshipStatus: string;
  PartnerName: string;
  NumberOfDependants: number;
  ARFRA: boolean;
  Gender: string;
  Ethnicity: string;
  Speciality: string;
}

export const DEFAULT_PERSONAL_DETAILS: PersonalDetails = {
  AdvocateId: 0,
  AdvocateFirstName: '',
  AdvocateLastName: '',
  AdvocateIdentityNumber: '',
  AdmissionDay: 0,
  AdmissionMonth: 0,
  AdmissionYear: 0,
  AdmissionDate: '',
  Seniority: '',
  PracticeType: '',
  InvolvementInPractice: '',
  PreAdvocacy: '',
  PreAdvocacyDetails: '',
  PersonalityType: '',
  RelationshipStatus: '',
  PartnerName: '',
  NumberOfDependants: 0,
  ARFRA: false,
  Gender: '',
  Ethnicity: '',
  Speciality: ''
};

export interface UserSubscription {
  UserSettingsId: number;
  ContactId: number;
  UserName: string;
  SignedDate: string;
  SubscriptionRenewal: string;
  SubscriptionTerm: string;
  SubscriptionIncrease: string;
  DebitOrder: boolean;
  PracticeType: string;
  InvolvementInPractice: string;
  AuxconMonthlySiteRental: boolean;
  AuxconInvoicing: boolean;
  AuxconReconcilliations: boolean;
  AuxconExpenseManagement: boolean;
  AdHocCollections: boolean;
  AuxconCollections: boolean;
  PremiumInvoicing: boolean;
  PremiumCollections: boolean;
  SubscriptionStatus: boolean;
  PracticeManagerId: number;
  PracticeManager: string;
  EightFivePercent: boolean;
  TwelveMonths: boolean;
  MonthlySiteAmount: number;
  ReconciliationAmount: number;
  InvoicingAmount: number;
  ExpenseAmount: number;
  TotalOverdue: number;
  CollectionsAmount: number;
  DebitDate: string;
  TenPercentCollections: boolean;
  GoLive: boolean;
  StatementRunDate: string;
}

export const DEFAULT_USER_SUBSCRIPTION: UserSubscription = {
  UserSettingsId: 0,
  ContactId: 0,
  UserName: '',
  SignedDate: '',
  SubscriptionRenewal: '',
  SubscriptionTerm: '',
  SubscriptionIncrease: '',
  DebitOrder: false,
  PracticeType: '',
  InvolvementInPractice: '',
  AuxconMonthlySiteRental: false,
  AuxconInvoicing: false,
  AuxconReconcilliations: false,
  AuxconCollections: false,
  PremiumInvoicing: false,
  PremiumCollections: false,
  SubscriptionStatus: false,
  AuxconExpenseManagement: false,
  AdHocCollections: false,
  PracticeManager: '',
  PracticeManagerId: 0,
  EightFivePercent: false,
  TwelveMonths: false,
  MonthlySiteAmount: 0,
  ReconciliationAmount: 0,
  ExpenseAmount: 0,
  InvoicingAmount: 0,
  TotalOverdue: 0,
  CollectionsAmount: 0,
  DebitDate: '',
  TenPercentCollections: false,
  GoLive: false,
  StatementRunDate: ''
};

export interface ContactStickyNotes {
  Id: number;
  ActionDate: string;
  ContactId: number;
  Note: string;
}

export const DEFAULT_CONTACT_STICKY_NOTE: ContactStickyNotes = {
  Id: 0,
  ActionDate: '',
  ContactId: 0,
  Note: '',
};

export interface ContactStickyNoteLogs {
  Id: number;
  ActionDateLog: string;
  LoggedBy: number;
  ContactStickyNoteId: number;
  PreviousObject: string;
}

export const DEFAULT_CONTACT_STICKY_NOTE_LOGS: ContactStickyNoteLogs = {
  Id: 0,
  ActionDateLog: '',
  LoggedBy: 0,
  ContactStickyNoteId: 0,
  PreviousObject: '',
};

export interface ContactNoteActionLogsSTO {
  NoteId: number;
  NoteActionDate: string;
  NoteLoggedById: number;
  NoteLoggedByName: string;
  NoteAction: string;
}

export interface PracticeManagerDTO {
  PracticeManagerId: number;
  PracticeManagerName: string;
  SecurityLinkId: number;
  ContactId: number;
}
export const DEFAULT_PRACTICE_MANAGER_DTO: PracticeManagerDTO = {
  PracticeManagerId: 0,
  PracticeManagerName: '',
  SecurityLinkId: 0,
  ContactId: 0
};

export interface FeeTariffs {
  DateOfAdmission: string;
  CandidateInGroup: string;
  Average: number;
  Highest: number;
  Lowest: number;
}

export interface ProfileSettings {
  AdvocateId: number;
  ShowDashboard: boolean;
  ManualTransaction: boolean;
  BCC: boolean;
  BCCEmail: string;
  Active: boolean;
}

export const DEFAULT_PROFILE_SETTINGS: ProfileSettings = {
  AdvocateId: 0,
  ShowDashboard: false,
  ManualTransaction: false,
  BCC: false,
  BCCEmail: '',
  Active: false
};

@Injectable()
export class AdvocateOverviewServiceService {

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private util: UtilitiesService
  ) { } // end constructor()

  getOverdueByFirm(): Observable<any> {
    return this.http.get(this.api.endpoints.overdueByLawfirm +
      '/OverdueByFirm?ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getOverdueByFirm()
  
  getFeeTariffs(): Observable<any> {
     return this.http.get(this.api.endpoints.overdueByLawfirm +
      '/FeeTariffs', this.api.httpOptions
    )
    .pipe(
      catchError(this.api.handleError)
    )
  } // end getFeeTariffs()

  putProfileSettings(data: ProfileSettings): Observable<any> {
    return this.http.put<any>(this.api.endpoints.overdueByLawfirm +
     '/PutProfileSettings', data, this.api.httpOptions
   )
   .pipe(
     catchError(this.api.handleError)
   )
 } // end putProfileSettings()

  getPracticeManagers(): Observable<any> {
    return this.http.get(this.api.endpoints.overdueByLawfirm +
      '/PracticeManagers', this.api.httpOptions)
    .pipe(catchError(this.api.handleError)
    );
  } // end getPracticeManagers()

  getFinancialDetails(): Observable<any> {
    return this.http.get(this.api.endpoints.overdueByLawfirm +
      '/FinancialDetails?ServiceProvider=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getFinancialDetails()

  getSubscription(): Observable<any> {
    return this.http.get(this.api.endpoints.overdueByLawfirm +
      '/Subscription?ServiceProvider=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getSubscription()

  getProfileSettings(): Observable<any> {
    return this.http.get(this.api.endpoints.overdueByLawfirm +
      '/ProfileSettings?ServiceProvider=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getProfileSettings()

  getPersonalDetails(): Observable<any> {
    return this.http.get(this.api.endpoints.overdueByLawfirm +
      '/PersonalDetails?ServiceProvider=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getPersonalDetails()

  getContactDetails(): Observable<any> {
    return this.http.get(this.api.endpoints.overdueByLawfirm +
      '/ContactDetails?ServiceProvider=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
      catchError(this.api.handleError)
      );
  } // end getContactDetails()

  getContactRelationships(): Observable<any> {
    return this.http.get(this.api.endpoints.overdueByLawfirm +
      '/ContactRelationship?ServiceProvider=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end getContactRelationship()

  getContactStickyNote(): Observable<any> {
    return this.http.get(this.api.endpoints.contactStickyNote +
      '?ServiceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end getContactStickyNote()

  getNoteActionLogs(): Observable<any> {
    return this.http.get(this.api.endpoints.contactStickyNote +
      '/GetNoteActionLogs?ServiceProviderId=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end getNoteActionLogs()

  updatePracticeManager(data: PracticeManagerDTO): Observable<PracticeManagerDTO> {
    return this.http.put<any>(
      this.api.endpoints.overdueByLawfirm +
      '/ChangePracticeManager', data, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end updatePracticeManager()

  updateContactStickyNote(note: ContactStickyNotes): Observable<ContactStickyNotes> {
    return this.http.put<any>(this.api.endpoints.contactStickyNote +
      '/UpdateStickyNote', note, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end updateContactStickyNote()

  addContactStickyNoteLogs(logs: ContactStickyNoteLogs): Observable<ContactStickyNoteLogs> {
    return this.http.post<any>(
      this.api.endpoints.contactStickyNote +
      '/NoteLogs', logs, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  }

  updateSubscriptionStatus(ServiceProvider: number, Status: boolean): Observable<UserSubscription> {
    return this.http.put<any>(
      this.api.endpoints.overdueByLawfirm +
      '/SubscriptionStatus?ServiceProvider=' + ServiceProvider + '&Status=' + Status, this.api.httpOptions
    )
    .pipe(catchError(this.api.handleError)
    );
  } // end updateSubscriptionStatus()

  putPersonalDetails(data: PersonalDetails): Observable<PersonalDetails> {
    return this.http.put<any>(
      this.api.endpoints.overdueByLawfirm +
      '/PersonalDetails', data, this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end putPersonalDetails()

  putFinancialDetails(data: FinancialDetails): Observable<FinancialDetails> {
    return this.http.put<any>(
      this.api.endpoints.overdueByLawfirm +
      '/EditFinancialDetails', data, this.api.httpOptions
    )
    .pipe(
      catchError(this.api.handleError)
    );
  } // end putFinancialDetails()

  putAddNewRelationship(data: ContactRelationship): Observable<ContactRelationship> {
    return this.http.put<any>(
      this.api.endpoints.overdueByLawfirm +
      '/AddNewRelationship', data, this.api.httpOptions
    )
    .pipe(
      catchError(this.api.handleError)
    );
  } // end putAddNewRelationship()

  putContactDetails(data: OverviewContactDetails): Observable<OverviewContactDetails> {
    return this.http.put<any>(
      this.api.endpoints.overdueByLawfirm +
      '/AddEditContactDetails', data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end putContactDetails()

  putAddEditSubsription(data: UserSubscription): Observable<UserSubscription> {
    return this.http.put <any>(
      this.api.endpoints.overdueByLawfirm +
      '/AddEditSubscription', data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end putAddEditSubscription()

  deleteRelationship(Id: number): Observable<{}> {
    return this.http.delete(this.api.endpoints.overdueByLawfirm +
      '/RemoveRelationship?Id=' + Id, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end deleteRelationship()
} // end AdvocateOverviewWidget{}
