<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="container-fluid">
  <nav class="report-nav" *ngIf="isSocietyBar" style="margin-bottom: 6px;">
    <a class="tab" [class.active]="tabType === 'lawfirmsReports'" (click)="togglePanel('lawfirmsReports')">Lawfirms</a>
    <a class="tab" [class.active]="tabType === 'advocateList'" (click)="togglePanel('advocateList')">Advocates</a>
    <a class="tab" [class.active]="tabType === 'pendingRequestReports'"
      (click)="togglePanel('pendingRequestReports')">Pending Requests</a>
  </nav>
  <nav class="report-nav" *ngIf="isGCBUserBar" style="margin-bottom: 6px;">
    <a class="tab" [class.active]="tabType === 'lawfirmsReports'" (click)="togglePanel('lawfirmsReports')">Lawfirms</a>
    <a class="tab" [class.active]="tabType === 'advocateList'" (click)="togglePanel('advocateList')">Advocates</a>
    <a class="tab" [class.active]="tabType === 'pendingRequestReports'"
      (click)="togglePanel('pendingRequestReports')">Pending Requests</a>
  </nav>

  <div class="display-options" style="padding-left: 11px;" *ngIf="tabType === 'lawfirmsReports'">


    <span class="column-search">
      <button class="btn btn-text">
        <i class="fa fa-search"></i>
      </button>
      <input type="search" placeholder="Search for a Law Firm" (input)="this.searchItems.next($event)"
        [(ngModel)]="searchTerm" style="padding-left: 30px !important;" autofocus>
    </span>
  </div>
  <div class="container-fluid" style="width:97%; margin-top: -10px;" *ngIf="tabType === 'lawfirmsReports'">
    <div class="row reconcile-table pl-2 pr-2">
      <div class="table-responsive scroller">
        <table class="table table-striped ">
          <tbody>
            <tr style="position: sticky; top: -1px; z-index: 2; background-color:#dee2e6;">
              <th class="vl" scope="col" style="width: 40%;" (click)="toggleSort('lawfirm_Client')">
                Lawfirm/Client <i class="fa fa-sort" [class.fa-sort-down]="sortLawfirm_Client"></i>
              </th>
              <th class="vl" scope="col" style="width: 8%;" (click)="toggleSort('lPC_Report')">
                LPC Reported <i class="fa fa-sort" [class.fa-sort-down]="sortLPC_Report"></i>
              </th>
              <th scope="col" style="width: 9%;"></th>
              <th class="vl height:5" scope="col" style="width: 8%;" (click)="toggleSort('gCB_Report')">
                GCB Reported <i class="fa fa-sort" [class.fa-sort-down]="sortGCB_Report"></i>
              </th>
              <th scope="col" style="width: 17%;"></th>
            </tr>
            <tr *ngFor="let blacklistData of mainMasterData">
              <td class="vl">{{blacklistData.Lawfirm}}
                <i class="fa fa-info-circle fa-lg tool-tip" style="color: #0ba3c7;"
                  (click)="onEditLawFirm(blacklistData)">
                  <div class="tooltiptext">
                    <h4 class="textLeftalign mt-3" style="font-size: 12px; ">
                      <strong>{{blacklistData.Lawfirm}}</strong>
                    </h4>
                    <p class="textLeftalign mt-4" style="font-size: 10px;">Phone Number: {{blacklistData?.Phone}}
                    </p>
                    <p class="textLeftalign" style="font-size: 10px;">Email: {{blacklistData?.Email}} </p>
                    <p class="textLeftalign" style="font-size: 10px;">Street Address:
                      {{blacklistData?.StreetAddress}} </p>
                    <p class="textLeftalign" style="font-size: 10px;">click on the (i) for more information</p>
                  </div>
                </i>
              </td>

              <td class="vl">
                <span
                  [ngClass]="blacklistData.LPCCount == 0 ? 'badge badge-pill badge-white' : blacklistData.LPCCount > 1  ? 'badge badge-pill badge-danger'  :  'badge badge-pill badge-warning'"
                  style="padding: 5px 10px;"> {{blacklistData.LPCCount}} Reports
                </span>
              </td>
              <td style="cursor: pointer;">
                <i class="fa fa-plus-circle fa-lg " aria-hidden="true"></i>
                <a (click)="openModalLPC(blacklistData , true)">
                  <u style="margin-left: 10px; color: #646464 !important;">LPC Report </u>
                </a>
              </td>
              <td class="vl">
                <span
                  [ngClass]="blacklistData.GCBCount == 0 ? 'badge badge-pill badge-white' : blacklistData.GCBCount > 1  ? 'badge badge-pill badge-danger'  :  'badge badge-pill badge-warning'"
                  style="padding: 5px 12px;">
                  {{blacklistData.GCBCount}} Reports </span>
              </td>
              <td style="cursor: pointer;">
                <span *ngIf="isSocietyBar">
                  <i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i>
                  <a (click)="openModal(blacklistData, true )">
                    <u style="margin-left: 10px;color: #646464 !important;">Manage GCB Listing </u>
                  </a>
                </span>
                <a *ngIf="isGCBUserBar" (click)="openModal(blacklistData , false)">
                  <u style="margin-left: 10px; color: #646464 !important;">View GCB Listing </u>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row col-md-12"
        style="width: 100%; display: inline-block; align-content:flex-start; margin-left: 0 !important; padding-left: 0 !important;">
        <span class="clickable add-new-law-firm-btn" *ngIf="isSocietyBar" (click)="openNewLawfirmRequestModal()">
          <i class="fa fa-plus-circle fa-lg auxcon-green" style="margin-right: 5px;"></i>
          <u class="auxcon-green">New Firm Request</u>
        </span>
        <button class="btnLoadMoreEnable" *ngIf="currentCount < displayTotalCount"
          (click)="LoadMoreDataOfManageDefList()">
          <a class="fa fa-arrow-down fa-lg" style="margin-right: 5px;color:#8abd37"></a>
          <u class="auxcon-green" style="text-transform:uppercase;color:#8abd37;font-size:12px;font-weight:700;">Load More</u>
        </button>
      </div>
    </div>
  </div>

  <div class="container-fluid" style="width:98%; margin-top: -10px;" *ngIf="tabType === 'advocateList'">
    <app-advocate-list *ngIf="tabType === 'advocateList'" [isGCBUserBar]="isGCBUserBar"></app-advocate-list>
  </div>

  <div class="display-options" style="padding-left: 11px;" *ngIf="tabType === 'pendingRequestReports'">
    <span class="column-search">
      <button class="btn btn-text" (click)="performPendingReqSearch()">
        <i class="fa fa-search"></i>
      </button>
      <input type="search" placeholder="Search listing Information" (keyup)="performPendingReqSearch()"
        [(ngModel)]="searchPendingTerm" style="padding-left: 30px !important;" autofocus>
    </span>
  </div>
  <div class="container-fluid" style="width:97%; margin-top: -10px;" *ngIf="tabType === 'pendingRequestReports'">
    <div class="row reconcile-table pl-2 pr-2">
      <div class="table-responsive scroller">
        <table class="table table-striped ">
          <tbody>
            <tr style="position: sticky; top: -1px; z-index: 2; background-color:#dee2e6;">
              <th class="vl" scope="col" (click)="toggleSortPending('regional_Bar')">Regional Bar
                <i class="fa fa-sort" [class.fa-sort-down]="sortRegional_Bar"></i>
              </th>
              <th class="vl" scope="col" (click)="toggleSortPending('lawfirm')">Lawfirm
                <i class="fa fa-sort" [class.fa-sort-down]="sortLawfirm"></i>
              </th>
              <th class="vl" scope="col" (click)="toggleSortPending('reporting_Advocate')">Reporting Advocate
                <i class="fa fa-sort" [class.fa-sort-down]="sortReporting_Advocate"></i>
              </th>
              <th class="vl" scope="col" (click)="toggleSortPending('reporting_Date')">Reporting Date
                <i class="fa fa-sort" [class.fa-sort-down]="sortReporting_Date"></i>
              </th>
              <th class="vl" scope="col"></th>
              <th *ngIf="!isSocietyBar" class="vl" scope="col">Actions</th>
              <th *ngIf="isSocietyBar" class="vl" scope="col">Status</th>
            </tr>
            <tr *ngFor="let blacklistData of mainPendingMasterData">
              <td *ngIf="blacklistData.state.isVisible" class="vl">{{blacklistData.data.Name}}</td>
              <td *ngIf="blacklistData.state.isVisible" class="vl">{{blacklistData.data.LawfirmFullName}}</td>
              <td *ngIf="blacklistData.state.isVisible" class="vl">{{blacklistData.data.ReportingAdvocateFullName}}</td>
              <td *ngIf="blacklistData.state.isVisible" class="vl">{{blacklistData.data.ReportDate | date:
                "yyyy-MM-dd"}}</td>
              <td *ngIf="blacklistData.state.isVisible" class="vl text-align:center" style="text-align: center;"
                (click)="downloadBlackListDocument(blacklistData.data.FilePath)">
                <i *ngIf="blacklistData.data.FilePath" class="fal fa-paperclip fa-1x pt-1 " style="cursor: pointer;"
                  aria-hidden="true"></i>
              </td>
              <td *ngIf="blacklistData.state.isVisible && !isSocietyBar" class="vl">
                <span class="badge badge-pill badge-success mr-10" style="padding: 5px 14px;"
                  (click)="onShowApproveChangesPrompt(blacklistData.data , 'Approved')"> Approve
                </span>
                <span class="badge badge-pill badge-danger" style="padding: 5px 14px; margin-left: 10px;"
                  (click)="onShowRejectChangesPrompt(blacklistData.data , 'Rejected')"> Reject
                </span>
              </td>
              <td *ngIf="blacklistData.state.isVisible && isSocietyBar" class="vl">
                <span class="badge badge-pill badge-white mr-10" style="padding: 5px 14px;"> Pending </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>

<div class="modal" *ngIf="viewAttachedPdf">
  <div class="modal-content" style="width: 50%; height: 90%;">
    <div class="modal-dialog">
      <div class="modal-header" style="margin-top: 7%;">
        <h2>Attorney Demand</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->
      <div class="modal-body form">
        <div style="width: 100%; height:550px; max-height: 570px;">
          <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" [openFile]="true" [viewBookmark]="true"
            [download]="true" [downloadFileName]="fileName"></ng2-pdfjs-viewer>
        </div>
      </div> <!-- #end .modal-body .form -->
    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->

<app-add-BlackList-modal [isVisible]="showNewGCBHandlerModal" [pendingLawfirm]="mainPendingMasterData"
  [currentLawfirmId]="selectedLawfirmId" [selectedLawfirmName]="selectedLawfirmName"
  [blackListLawfirm]="blackListLawfirm" [isGCBUser]="isGCBUser" (closeModal)="closeModal($event)"
  (refreshGrid)="refreshExistingGrid($event)"></app-add-BlackList-modal>

<app-add-lpc-blacklist-modal [isVisible]="showNewLPCHandlerModal" [pendingLawfirm]="mainPendingMasterData"
  [currentLawfirmId]="selectedLawfirmId" [selectedLawfirmName]="selectedLawfirmName"
  [blackListLawfirm]="blackListLawfirm" (closeModal)="closeModalLPC($event)"></app-add-lpc-blacklist-modal>

<app-prompt-unsaved-changes-modal title="Please confirm" message="Are you sure you want to approve this request?"
  btnTextCancel="Cancel" btnTextContinue="Confirm" [isVisible]="showApproveChangesPrompt"
  (cancel)="onHideApproveChangesPrompt()" (continue)="onActionClickEvent()">
</app-prompt-unsaved-changes-modal>
<app-prompt-unsaved-changes-modal title="Please confirm" message="Are you sure you want to reject this request?"
  btnTextCancel="Cancel" btnTextContinue="Confirm" [isVisible]="showRejectChangesPrompt"
  (cancel)="onHideRejectChangesPrompt()" (continue)="onActionClickEvent()">
</app-prompt-unsaved-changes-modal>

<app-barsociety-new-lawfirm-request-modal [isVisible]="showBarSocietyNewLawfirmReqModal"
  (closeModal)="closeNewLawfirmRequestModal()"></app-barsociety-new-lawfirm-request-modal>

<app-add-new-law-firm *ngIf="showAddEditLawfirm" [isVisible]="showAddEditLawfirm" [selectedLawfirm]="selectedLawfirm"
  [isManageBlacklistUser]="isManageBlacklistUser" (closeModal)="onCloseModal()"
  (onSaveLawFirm)="onSaveNewLawfirm($event)"></app-add-new-law-firm>