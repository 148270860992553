<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="panel-area">
  <div class="row">
    <div class="form-group">
      <label for="subscription-start-date">AUX Number</label>
      <input
        readonly 
        type="text" 
        id="name" 
        class="input" 
        placeholder="Name"
        [(ngModel)]="userSubscription.UserName">
    </div>
    <div class="form-group">
      <label for="subscription-start-date">Subscription start date</label>
      <input
        *ngIf="userSubscription.SignedDate === null"
        bsDatepicker
        type="text" 
        id="subscription-start-date-null" 
        class="input" 
        placeholder="Subscription start date"
        [bsValue]="parseDate(workerUserSubscription.SignedDate)"
        (ngModel)="workerUserSubscription.SignedDate=$event.target.value"
        (bsValueChange)="onDateValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
        placement="right">

        <input
        *ngIf="userSubscription.SignedDate !== null"
        disabled
        bsDatepicker
        type="text" 
        id="subscription-start-date" 
        class="input" 
        placeholder="Subscription start date"
        [bsValue]="parseDate(workerUserSubscription.SignedDate)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
        placement="right">
    </div>
    <div class="form-group">
      <label for="subscription-renewal">Subscription renewal</label>
      <select
        id="subscription-renewal"
        [(ngModel)]="workerUserSubscription.SubscriptionRenewal">
        <option value="" disabled selected hidden>Select Subscription renewal</option>
        <option
          *ngFor="let renewal of subscriptionRenewal"
          [value]="renewal.value"
          [selected]="renewal.value === workerUserSubscription.SubscriptionRenewal">{{renewal.label}}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="subscription-term">Subscription term</label>
      <select 
        id="subscription-term"
        [(ngModel)]="workerUserSubscription.SubscriptionTerm">
        <option value="" disabled selected hidden>Select Subscription term</option>
        <option
          *ngFor="let term of subscriptionTerm"
          [value]="term.value"
          [selected]="term.value === workerUserSubscription.SubscriptionTerm">{{term.label}}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="subscription-increase">Subscription increase</label>
      <select
        id="subscription-increase"
        [(ngModel)]="workerUserSubscription.SubscriptionIncrease">
        <option value="" disabled selected hidden>Select Subscription increase</option>
        <option
          *ngFor="let increase of subscriptionIncrease"
          [value]="increase.value"
          [selected]="increase.value === workerUserSubscription.SubscriptionIncrease">{{increase.label}}</option>
      </select>
    </div>    
  </div>
  <div class="row">
    <div class="form-group">
      <label for="auxcon-packages">Auxcon Packages</label>
      <div class="badge">
        <label>
          <input
          type="checkbox"
          [(ngModel)]="workerUserSubscription.AuxconMonthlySiteRental"
          (ngModelChange)="onAuxconMonthlySiteRentalChange()"> Monthly Site Rental</label>
          <input
            type="text"
            class="input"
            id="monthly-site-rental-amount"
            placeholder="Monthly site rental amount"
            [ngModelOptions]="{updateOn: 'blur'}"
            [ngModel]="workerUserSubscription.MonthlySiteAmount | currency: currencySymbol: currencySymbol: '1.2-2'"
            (ngModelChange)="workerUserSubscription.MonthlySiteAmount=$event"
            (blur)="onMonthlySiteAmountChange()"
            (focus)="onMonthlySiteAmountClicked()"
            (focus)="$event.target.select()"
            pattern="[P-R\s]*[0-9,.]+"
            autocomplete="off"
            #inputMonthlySiteAmount>
        </div>
    </div>
    <div class="form-group checkboxes">
      <label for="auxcon-packages">Auxcon Packages</label>
      <label>
        <input type="checkbox" [(ngModel)]="workerUserSubscription.AdHocCollections">
        <span> Ad-Hoc Collections</span>
      </label>
    </div>

      <div class="form-group">
        <label for="amount-overdue">Auxcon Outstanding Amount</label>
        <input
          readonly
          type="text"
          class="input"
          id="amount-overdue"
          placeholder="Amount overdue"
          [ngModelOptions]="{updateOn: 'blur'}"
          [ngModel]="workerUserSubscription.TotalOverdue | currency: currencySymbol: currencySymbol: '1.2-2'"
          pattern="[P-R\s]*[0-9,.]+"
          autocomplete="off">
      </div>    
    <div class="form-group radio-group">
      <label for="Debit order">Debit order</label>
      <div class="radio junior">
        <label class="junior">
          <input
            type="radio"
            name="yes"
            (click)="ontoggleDebitYes()"
            [checked]="workerUserSubscription.DebitOrder === true">Yes</label>
        <label>
          <input
            type="radio"
            name="no"
            (click)="ontoggleDebitNo()"
            [checked]="workerUserSubscription.DebitOrder !== true">No</label>
      </div>
    </div>

    <div class="form-group">
      <label *ngIf="workerUserSubscription.DebitOrder === true" for="debit-order-date">Debit order date</label>
      <div class="row">
        <input
          *ngIf="workerUserSubscription.DebitOrder === true"
            readonly
            type="text"
            style="width: 40%;"
            id="debit-order-date"
            class="input"
            placeholder="Debit order date"
            [value]="workerUserSubscription.DebitDate">
        <input
          *ngIf="workerUserSubscription.DebitOrder === true"
          readonly
          bsDatepicker
          type="text"
          id="debit-order-date"
          class="btn btn-info"
          style="width: 52%; font-size: 13px !important; font-weight: 400 !important; height: 30px !important;"
          placeholder="D/O Date"
          [bsValue]="workerUserSubscription.DebitDate"
          (bsValueChange)="onDebitDateChange($event)"
          viewMode="days"
          placement="left">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group checkboxes">
      <div class="badge">
        <label>
          <input 
            type="checkbox"
            [(ngModel)]="workerUserSubscription.AuxconReconcilliations"
            (ngModelChange)="onAuxconReconciliationChange()"><span> Reconciliation</span></label>
          <input
            type="text"
            class="input"
            id="reconciliation-amount"
            placeholder="Reconciliation amount"
            [ngModelOptions]="{updateOn: 'blur'}"
            [ngModel]="workerUserSubscription.ReconciliationAmount | currency: currencySymbol: currencySymbol: '1.2-2'"
            (ngModelChange)="workerUserSubscription.ReconciliationAmount=$event"
            (blur)="onReconAmountChange()"
            (focus)="onReconAmountClicked()"
            (focus)="$event.target.select()"
            pattern="[P-R\s]*[0-9,.]+"
            autocomplete="off"
            #inputReconAmount>
        </div>
    </div>

    <div class="form-group checkboxes">
      <label>
        <input type="checkbox" [(ngModel)]="workerUserSubscription.EightFivePercent"> <span>8.5%</span>
      </label>
    </div>
    <!-- <div class="form-group"> -->
      <!-- <label for="reconciliation-amount">Reconciliation Amount</label> -->
      <!-- <div class="">
        </div> -->
    <!-- </div> -->
    <!-- <div class="form-group">
      <label hidden>
        <input 
          type="checkbox"
          [(ngModel)]="workerUserSubscription.PremiumCollections"> Collections</label>
    </div> -->
    <div class="form-group">
    
    </div>
    <div class="form-group">
    
    </div>    
    <div class="form-group">
    
    </div> 
  </div>
  <div class="row">
    <div class="form-group checkboxes">
      <div class="badge">
        <label>
          <input 
            type="checkbox"
            [(ngModel)]="workerUserSubscription.AuxconInvoicing"
            (ngModelChange)="onAuxconInvoicingChange()"><span> Invoicing</span></label>
        <input 
          type="text" 
          class="input" 
          id="invoicing-amount" 
          placeholder="Invoicing amount"
          [ngModelOptions]="{updateOn: 'blur'}"
          [ngModel]="workerUserSubscription.InvoicingAmount | currency: currencySymbol: currencySymbol: '1.2-2'"
          (ngModelChange)="workerUserSubscription.InvoicingAmount=$event" 
          (blur)="onInvoicingAmountChange()"
          (focus)="onInvoicingAmountClicked()" 
          (focus)="$event.target.select()"
          pattern="[P-R\s]*[0-9,.]+" 
          autocomplete="off" 
          #inputeInvoicingAmount>
        </div>
    </div>
    <div class="form-group checkboxes">
      <label>
        <input type="checkbox" [(ngModel)]="workerUserSubscription.TwelveMonths"> <span> 12 Months </span></label>
    </div>

    <div class="form-group">

    </div>
    <div class="form-group">
    
    </div>
    <div class="form-group">
    
    </div>   

    
  </div>
  <div class="row">
    <div class="form-group checkboxes">
      <div class="badge">
        <label>
          <input 
            type="checkbox"
            [(ngModel)]="workerUserSubscription.AuxconExpenseManagement"
            (ngModelChange)="onAuxconExpenseManagementChange()"><span> Expense Management</span></label>
        <input 
          type="text" 
          class="input" 
          id="expense-management"
          placeholder="Expense Management"
          [ngModelOptions]="{updateOn: 'blur'}"
          [ngModel]="workerUserSubscription.ExpenseAmount | currency: currencySymbol: currencySymbol: '1.2-2'"
          (ngModelChange)="workerUserSubscription.ExpenseAmount=$event"
          (blur)="onExpenseManagementAmountChange()"
          (focus)="onExpenseManagementAmountClicked()"
          (focus)="$event.target.select()"
          pattern="[P-R\s]*[0-9,.]+"
          autocomplete="off"
          #inputeExpenseAmount>
        </div>
    </div>
    <div class="form-group checkboxes">
      <label>
        <input type="checkbox" [(ngModel)]="workerUserSubscription.TenPercentCollections"> <span> 10% Legal Collections</span></label>
    </div>
    <div class="form-group">

    </div>
    <div class="form-group">
    
    </div>
    <div class="form-group">
    
    </div>
    <!-- <div class="form-group">
    
    </div> -->
  </div>
  <div class="row">
    <div class="form-group checkboxes">
      <div class="badge">          
        <label>
          <input
            type="checkbox"
            [(ngModel)]="workerUserSubscription.AuxconCollections"
            (ngModelChange)="onAuxconCollectionsChange()">
            <span> Collections</span>
          </label>
          <input 
            type="text" 
            class="input" 
            id="collections-amount" 
            placeholder="Expense Management"
            [ngModelOptions]="{updateOn: 'blur'}"
            [ngModel]="workerUserSubscription.CollectionsAmount | currency: currencySymbol: currencySymbol: '1.2-2'"
            (ngModelChange)="workerUserSubscription.CollectionsAmount=$event" 
            (blur)="onCollectionsAmountChange()"
            (focus)="onCollectionsAmountClicked()" 
            (focus)="$event.target.select()" 
            pattern="[P-R\s]*[0-9,.]+"
            autocomplete="off" 
            #inputeCollectionsAmount>
      </div>
    </div>
    <div class="form-group checkboxes">
      <label>
        <input type="checkbox" [(ngModel)]="workerUserSubscription.GoLive"> <span> Go Live</span></label>
    </div>
    <div class="form-group">
  
    </div>
    <div class="form-group">
      <label for="statement-run-date">Statement run date</label>
      <input
        type="text" 
        bsDatepicker 
        id="statement-run-date" 
        class="input"
        [bsValue]="parseDate(workerUserSubscription.StatementRunDate)" 
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
        (ngModel)="workerUserSubscription.StatementRunDate=$event.target.value"
        (bsValueChange)="onDateStatementRunValueChange($event)"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
        placement="right">
    </div>
    <div class="form-group">
      <label for="practice-manager">Practice Manager</label>
      <div class="practice-manager" style="position: relative;">
        <input
          type="text"
          *ngIf="loggedInUserProfile === 28263"
          id="practice-manager"
          class="input"
          placeholder="Practice Manager"
          [(ngModel)]="selectPracticeManagerText"
          [typeahead]="practiceManagers"
          typeaheadOptionField="PracticeManagerName"
          (input)="onPMInput($event)"
          style="width: 90% !important;"
          placeholder="Select or start typing"
          (typeaheadOnSelect)="onPMSelect($event)"
          [typeaheadOptionsLimit]="50"
          typeaheadScrollable=true
          typeaheadOptionsInScrollableView="20"
          [typeaheadMinLength]="0">
        <input
          readonly
          *ngIf="loggedInUserProfile !== 28263"
          type="text"
          id="practice-manager"
          class="input"
          placeholder="Practice Manager"
          [(ngModel)]="workerUserSubscription.PracticeManager">
        </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="form-group checkboxes">
      <label>
        <input 
          type="checkbox"
          [(ngModel)]="workerUserSubscription.AdHocCollections"><span> Ad-Hoc Collections</span></label>
    </div>
    <div class="form-group">

    </div>
    <div class="form-group">

    </div>
    <div class="form-group">
    
    </div>
    <div class="form-group">
    
    </div>
    <div class="form-group">
    
    </div>
  </div> -->
  <!-- <div class="row">
    <div class="form-group checkboxes">
      <label>
        <input 
          type="checkbox"
          [(ngModel)]="workerUserSubscription.EightFivePercent"> <span>8.5%</span>
      </label>
    </div>
    <div class="form-group">

    </div>
    <div class="form-group">

    </div>
    <div class="form-group">
    
    </div>
    <div class="form-group">
    
    </div>
    <div class="form-group">
    
    </div>
  </div> -->
  <!-- <div class="row">
    <div class="form-group checkboxes">
      <label>
        <input 
          type="checkbox"
          [(ngModel)]="workerUserSubscription.TwelveMonths"> <span> 12 Months </span></label>
    </div>
    <div class="form-group">

    </div>
    <div class="form-group">

    </div>
    <div class="form-group">
    
    </div>
    <div class="form-group">
    
    </div>
    <div class="form-group">
    
    </div>
  </div> -->
</div>

<!-- <button 
  class="btn btn-success save-advocate-details"
  (click)="onSubscriptionSave()">SAVE SUBSCRIPTION DETAILS</button> -->

<app-prompt-unsaved-changes-modal
  [title]="popTitle"
  [message]="popupMessage"
  [isVisible]="showProfileActivationPrompt" 
  (cancel)="onHideProfileActivationPrompt()"
  (continue)="onProfileActivateOrDeactivate()" 
  btnTextCancel="Cancel" 
  btnTextContinue="Yes, Proceed">
</app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal
  [title]="practiceMChangeTitle"
  [message]="practiceMChangeMessage"
  [isVisible]="showPracticeMChangePrompt" 
  (cancel)="onHidePracticeMChangePrompt()"
  (continue)="onPracticeManangerChange()" 
  btnTextCancel="Cancel" 
  btnTextContinue="Yes, Proceed">
</app-prompt-unsaved-changes-modal>