import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TAG_DELETE_MUTLIPLE_MATTERS_MODAL } from '../../../services/tags/tags.service';
import { Matter } from '../../../services/matters/matters.service';

const MIN_COMMENT_LENGTH = 5;
const MAX_COMMENT_LENGTH = 500;
const TITLE_PRFIX = 'Delete matters ';

interface MatterHandle {
  data: Matter;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

@Component({
  selector: 'app-delete-multiple-matter-modal',
  templateUrl: './delete-multiple-matter-modal.component.html',
  styleUrls: ['./delete-multiple-matter-modal.component.scss']
})
export class DeleteMultipleMatterModalComponent implements OnInit {

  @Input() isVisible: boolean = true;
  @Input() matter: MatterHandle[];
  @Output() closeModal: EventEmitter<string> = new EventEmitter();
  @Output() confirmDelete: EventEmitter<any> = new EventEmitter();

  title: string = TITLE_PRFIX;
  matterDescription: Matter[] = [];
  comments: string;
  isDeleteable: boolean;
  numberOfCharactersLeft: number = 500;
  exceedsMaxCommentLength: boolean = false;

  constructor() {} // end constructor()

  ngOnInit() {} // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (this.matter) {
      // this.title = TITLE_PRFIX + this.matter.Description;
      this.matter.forEach(s => this.matterDescription.push(s.data));
    }
  } // end ngOnChange()

  validate() {
    let isOk = true;
    this.numberOfCharactersLeft = MAX_COMMENT_LENGTH - this.comments.length;

    if (this.comments.length < MIN_COMMENT_LENGTH) {
      isOk = false;
    }

    if (this.comments.length > MAX_COMMENT_LENGTH) {
      this.exceedsMaxCommentLength = true;
      isOk = false;
    } else {
      this.exceedsMaxCommentLength = false;
    }

    this.isDeleteable = isOk;
  } // end validate(){}

  dismiss() {
    this.comments = '';
    this.validate();
    this.matter = [];
    this.matterDescription = [];
    this.closeModal.emit(TAG_DELETE_MUTLIPLE_MATTERS_MODAL);
  } // end dismiss()

  confirm() {
    this.matterDescription.forEach(mat => {
    this.confirmDelete.emit({
      matter: mat,
      comments: this.comments
    });
  });
    this.dismiss();
  } // end confirmDelete
} // end DeleteMultipleMatterModalComponent{}
