<div class="invoiced-collected-container">
    <nav class="invoiced-collected-nav">
        <a class="tab" [class.active]="activeTab === 'graph'" (click)="onTabClick('graph')">Graph</a>
        <!-- <a class="tab" [class.active]="activeTab === 'table'" (click)="onTabClick('table')">Table</a> -->

    </nav>
    <div class="well">
        <div class="row total m-0">
            <div class="col-md-12 text-center">
                <span class="invoice" >Total Invoiced:
                    {{totalInvoiced}}</span><span class="collection" >Total Collected:
                    {{totalCollected}}</span><span class="ratio">Ratio: {{ratio}}%</span> </div>
        </div>
        <div class="row m-0">
            <svg class="display-center" #timeScale id="timeScale">
            </svg>
        </div>
    </div>
</div>