<div class="container-fluid">
  <h1 class="page-title">{{title}}</h1>
</div><!--#end .container-fluid -->
<div class="container-fluid container-page">
  <div class="page card">
    <div class="details-section">
      <p class="preview-title">{{previewTitle}}</p>
    </div>

    <div class="contact-details details-section">
      <div class="contact-details-column column-left">
        <p class="supplier-name">{{workingInvoiceDetails.SupplierName}}</p>
        <!-- <p>{{workingInvoiceDetails.SupplierStreetAddress}}</p> -->
        <textarea 
          class="form-control-address" 
          type="text"
          id="address"
          [(ngModel)]="workingInvoiceDetails.SupplierStreetAddress"
          readonly></textarea>
      </div>

      <div class="contact-details-column column-right">
        <!-- <p> -->
          <div class="form-control">
          <span class="heading">Invoice Date:</span>
            <input 
              type="text" 
              class="form-control"
              [class.has-error]="hasInvoiceDateLessThanError || isFutureDated"
              bsDatepicker 
              [value]="parseDate(workingInvoiceDetails.InvoiceDate) | date:'yyyy-MM-dd'" 
              (ngModel)="workingInvoiceDetails.InvoiceDate=$event.target.value"
              (bsValueChange)="onDateChange(invoiceDetails, $event)" 
              [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
              [class.disabled]="workingInvoiceDetails.InvoiceNo !== 'AUX000000' && workingInvoiceDetails.InvoiceNo !== null && workingInvoiceDetails.InvoiceDate !== null"
              [disabled]="workingInvoiceDetails.InvoiceNo !== 'AUX000000' && workingInvoiceDetails.InvoiceNo !== null && workingInvoiceDetails.InvoiceDate !== null">
              <!-- </p> -->
              <p
              *ngIf="hasInvoiceDateLessThanError" 
              class="text-danger">The invoice date cannot be before the last line date.</p>
              <p
              *ngIf="isFutureDated" 
              class="text-danger">The system does not allow future dating of Invoices.</p>
            </div>
        <p><span class="heading">Invoice No:</span><span>{{workingInvoiceDetails.InvoiceNo}}</span></p>
        <br>
        <p><span class="heading">Cell:</span><span>{{workingInvoiceDetails.SupplierCell}}</span></p>
        <p><span class="heading">Email:</span><span>{{workingInvoiceDetails.SupplierEmail}}</span></p>
        <p><span class="heading">Tel:</span><span>{{workingInvoiceDetails.SupplierPhone}}</span></p>
        <p><span class="heading">Fax:</span><span>{{workingInvoiceDetails.SupplierFax}}</span></p>
        <p><span class="heading">VAT No:</span> <span>{{workingInvoiceDetails.SupplierVATNo}}</span></p>
      </div>
    </div><!--#end .details-section -->

    <div class="client-details details-section">
      <div class="client-details-column column-left">
        <p class="heading">Invoice To:</p>
        <!-- <p>{{workingInvoiceDetails.ClientName}}</p> -->
        <p [innerHTML]="formatContactDetails(workingInvoiceDetails.ClientContactDetailsEng)"></p>
        <p>&nbsp;</p>
        <p class="heading">Client Address</p>
        <textarea
          class="form-control-address-client"
          type="text"
          id="address"
          [(ngModel)]="workingInvoiceDetails.ClientStreetAddress"
          readonly></textarea>
        <p>&nbsp;</p>
        <p class="heading">VAT No: {{workingInvoiceDetails.ClientVATNo}}</p>
      </div>
      <div class="client-details-column column-right">
        <p><span class="heading">IN RE:</span><textarea wrap="soft" [title]="workingInvoiceDetails.InvoiceParties"  readonly>{{workingInvoiceDetails.InvoiceParties}}</textarea></p>
        <p><span class="heading">REFERENCE:</span><input [title]="workingInvoiceDetails.InvoiceReference" [value]="workingInvoiceDetails.InvoiceReference" readonly></p>
      </div><!--#end .client-details-column -->
    </div><!--#end .client-details.details-section -->

    <div class="details-section details-section-solid">
      <div class="table">
        <div class="table-head">
          <div class="table-row">
            <span class="column-type">Item</span>
            <span class="column-date">Date</span>
            <span class="column-description">Description</span>
            <span class="column-quantity">Hours/Qty</span>
            <span class="column-rate">Rate</span>
            <span class="column-amount">Amount</span>
          </div>
        </div><!--#end .table-head -->
        <div class="table-body">
          <div *ngFor="let line of workingInvoiceDetails.InvoiceLines; index as i" class="table-row">
            <span class="column-type">{{i + 1}}</span>
            <span class="column-date">{{line.Date | date:'yyyy/MM/dd'}}</span>
            <span
              class="column-description"
              [title]="line.Description">{{line.Description}}</span>
            <span class="column-quantity">{{line.Quantity | number:'1.0-2'}}</span>
            <span class="column-rate">{{line.Rate | currency:currencySymbol}}</span>
            <span class="column-amount">{{line.Total | currency:currencySymbol}}</span>
          </div>
        </div><!--#end .table-body -->
      </div><!--#end .table -->
    </div><!--#end .details-section -->

    <div class="details-section summary-details">
      <div class="details-section-column column-left">
        <div class="form-group">
          <label for="notes">Notes / Memorandum</label>
          <textarea
            #notes
            id="notes"
            placeholder="Enter your note to attorney here"
            [(ngModel)]="workingInvoiceDetails.InvoiceNote"
            [autofocus]></textarea>
        </div><!--#end .form-group -->
      </div>

      <div class="details-section-column"></div>

      <div class="details-section-column column-right">
        <p>&nbsp;</p>
        <div class="invoice-line-summary">

          <div class="line-summary-row">
            <span class="line-summary-label">Sub-Total:</span>
            <span class="line-summary-value">
              {{workingInvoiceDetails.InvoiceTotalExcl | currency:currencySymbol}}
            </span>
          </div><!--#end .line-summary-row -->
        
          <div class="line-summary-row">
            <span class="line-summary-label">VAT:</span>
            <span class="line-summary-value">
              {{workingInvoiceDetails.InvoiceVAT | currency:currencySymbol}}
            </span>
          </div><!--#end .line-summary-row -->
        
          <div class="line-summary-row">
            <span class="line-summary-label">Total:</span>
            <span class="line-summary-value">
              {{workingInvoiceDetails.InvoiceTotal | currency:currencySymbol}}
            </span>
          </div><!--#end .line-summary-row -->
        
        </div><!--#end .invoice-line-summary -->
      </div>
    </div><!--#end .details-section -->

  </div><!--#end .page -->
</div><!--#end .container-fluid -->
<div class="row">
  <button
    class="btn btn-success"
    (click)="onEditMatterDetails()">Edit matter details</button>
  <span></span>
  <button
    class="btn btn-success"
    [class.disabled]="!isSaveable"
    [disabled]="!isSaveable"
    (click)="onSaveInvoiceDetails()">Save invoice details</button>
  <span></span>
  <button
    class="btn btn-success"
    [class.disabled]="!isSaveable"
    [disabled]="!isSaveable"
    (click)="onShowSaveInvoiceModal()">Send invoice via email</button>
</div><!--#end .row -->

<app-save-invoice-modal 
  [isVisible]="showSaveInvoicePrompt" 
  (cancel)="onHideSaveInvoiceModal()"
  (confirm)="onSendViaEmail()"></app-save-invoice-modal>

<app-prompt-unsaved-changes-modal
  message="You have unsaved changes. Are you sure you want to leave the page?"
  [isVisible]="showUnsavedChangesPrompt"
  (cancel)="onHideUnsavedChangesPrompt()"
  (continue)="leavePage()"></app-prompt-unsaved-changes-modal>