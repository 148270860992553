import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { Event, NavigationStart, Router } from '@angular/router';
import { PATH_SEND_STATEMENT, PATH_PROCESS, PATH_PROOF_OF_PAYMENTS, PATH_RECONCILE_BANK_TRANSACTIONS } from '../../../services/appdata/app-config.service';
import {
    ProcessPaymentService,
    DEFAULT_BANK_TRANSACTION,
    BankTransaction,
    BankStatementSummary,
    DEFAULT_BANK_STATEMENT_SUMMARY
} from '../../../services/process-payment/process-payment.service';
import {
    BankTransactionHandle
} from '../../../components/reconcile-bank-transaction/new-bank-transactions/new-bank-transactions.component';
import {
    TransactionLinesHandle
} from '../../../components/reconcile-bank-transaction/available-invoices-bank-transaction/available-invoices-bank-transaction.component';
import { ICrumb } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import * as moment from 'moment';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { NavigationService } from '../../../services/navigation/navigation.service';

const DEFAUL_SELECTED_TAB = 'newBankTransactions';

@Component({
    selector: 'app-reconcile-bank-transactions',
    templateUrl: './reconcile-bank-transactions.component.html',
    styleUrls: ['./reconcile-bank-transactions.component.scss']
})
export class ReconcileBankTransactionsComponent implements OnInit {

    bankTransactionsHandle: BankTransactionHandle[] = [];
    initTransactionsHandle: BankTransactionHandle[] = [];
    availableInvoiceHandle: TransactionLinesHandle[] = [];
    fullTransaction: BankTransactionHandle[] = [];
    revisedTransaction: BankTransactionHandle[] = [];
    partialTransaction: BankTransactionHandle[] = [];
    noneTransaction: BankTransactionHandle[] = [];
    selectedBankTransaction: BankTransaction = Object.assign({}, DEFAULT_BANK_TRANSACTION);
    duplicateTransactions: BankTransaction[] = [];
    transactionsType = '';
    searchString = '';
    count = 0;
    newBankTransactionClick = false;
    crumbs: ICrumb[];
    showErrorMessagePrompt: boolean;
    showRevisedPrompt: boolean;
    bankStatementSummary: BankStatementSummary;
    _revisedBankTransaction: BankTransactionHandle[] = [];

    newBankTransactions: BankTransaction[] = [];
    workingNewBankTransactions: BankTransaction[] = [];

    selectedTransaction: any;

    public promptInvoices: EventEmitter<any> = new EventEmitter<any>();
    public promptProcessedInvoices: EventEmitter<any> = new EventEmitter<any>();

    public refresInvoices: EventEmitter<any> = new EventEmitter<any>();

    profileTypes = {
        ADVOCATE: 'Advocate',
        ASSISTANT: 'Assistant',
        ATTORNEY: 'Attorney',
        EXTERNAL_ATTORNEY: 'Attorney (External)',
        LAWFIRM: 'Lawfirm',
        EXTERNAL_LAWFIRM: 'Lawfirm (External)'
    };

    profile: any;

    @Output() tranCount: EventEmitter<any> = new EventEmitter<any>();

    // Is Internal Attorney
    get isAttorney(): boolean {
        let attorneyProfile = false;
        if (this.userProfileService.userProfile.isImpersonator) {
            attorneyProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.ATTORNEY);
        } else {
            attorneyProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
        }
        return attorneyProfile;
    } // end isAttorney()

    // Is Internal Lawfirm
    get isLawfirm(): boolean {
        let lawfirmProfile = false;
        if (this.userProfileService.userProfile.isImpersonator) {
            lawfirmProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
        } else {
            lawfirmProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
        }
        return lawfirmProfile;
    } // end isLawfirm()

    get isManualTransactionsDirty(): boolean {
        return !this.util.objectIsSame(this.newBankTransactions, this.workingNewBankTransactions);
    } // end isManualTransactionsDirty()

    showUnsavedChangesPrompt: boolean;
    private _subscriptionNavigationStart: ISubscription
    private _exitRoute: string;
    private _canIgnoreNavigationBlock: boolean;

    constructor(
        private router: Router,
        private util: UtilitiesService,
        private navigation: NavigationService,
        private loadingService: LoadingService,
        private snackbarsService: SnackbarsService,
        private userProfileService: UserProfileService,
        private processPaymentService: ProcessPaymentService
    ) {
    } // end constructor()



    ngOnInit() {
        this.profile = this.userProfileService.userProfile;
        this.crumbs = [
            {
                label: 'PROCESS',
                link: PATH_PROCESS
            },
            {
                label: 'RECONCILE BANK TRANSACTIONS'
            }
        ];
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
        this.bankStatementSummary = Object.assign({}, DEFAULT_BANK_STATEMENT_SUMMARY);
        //     this.getLatestBankStatement();
        this.selectedBankTransaction = Object.assign({}, DEFAULT_BANK_TRANSACTION);
        if (this.processPaymentService.selectedTab) {
            this.transactionsType = this.processPaymentService.selectedTab;
            this.togglePanel(this.transactionsType);
        } else if (this.profile.serviceProviderID === 29723) {
            this.transactionsType = 'processedBankTransactions';
            this.togglePanel(this.transactionsType);
        } else {
            this.transactionsType = 'newBankTransactions';
            this.togglePanel(this.transactionsType);
        }

        this._subscriptionNavigationStart = this.router.events.subscribe((event: Event) => {
            if(event instanceof NavigationStart && this.isManualTransactionsDirty) {
                this._exitRoute = event.url;
                if(location.href.includes(PATH_RECONCILE_BANK_TRANSACTIONS)) {
                    if(this.isManualTransactionsDirty && !this._canIgnoreNavigationBlock) {
                        this.navigation.canNavigate = false;
                        this.showUnsavedChangesPrompt = true;
                    } else {
                        this.navigation.canNavigate = true;
                    }
                }
            }
        });
        // let statementDate = moment(this.bankStatementSummary.LastTransactionDate);
        console.log('')
    } // end ngOnInit()

    onHideUnsavedChangesPrompt() {
        this.showUnsavedChangesPrompt = false;
      } // end onHideUnsavedChangesPrompt()

    leavePage() {
    this._canIgnoreNavigationBlock = true;
    this.onHideUnsavedChangesPrompt();
    setTimeout((thisObj) => {
        thisObj.router.navigate([this._exitRoute.replace('/', '')]);
    }, 300, this);
    } // end leavePage()

    getLatestBankStatement() {
        this.processPaymentService.getBankStatementSummary().subscribe({
            next: (_bank_statement) => {
                this.bankStatementSummary = _bank_statement;
            }
        });
    } // end getLatestBankStatement()


    togglePanel(value: string) {
        if (value === 'newBankTransactions') {
            if(this.newBankTransactionClick === false) { // I am just checking if this will handle double click
            this.newBankTransactionClick = true;
            this.loadingService.showOverlay();
            this.transactionsType = value;
            this.getNewBankTransactions();
            }
            // this.refresInvoices.next('new');
        } else if (value === 'processedBankTransactions') {
            this.newBankTransactionClick = false;
            this.transactionsType = value;
            // this.refresInvoices.next('processed');
        }
    } // end togglePanel()

    getPossibleMatches(bankTransaction: BankTransaction) {
        this.selectedBankTransaction = Object.assign({}, DEFAULT_BANK_TRANSACTION);
        if (bankTransaction.Id > 0) {
            this.selectedBankTransaction = bankTransaction;
        }
        // this.availableInvoiceHandle = [];
        // this.loadingService.showOverlay();
        // this.processPaymentService.getPossibleMatches(bankTransaction.Id)
        // .subscribe(invoices => {
        //     invoices.AvailableInvoices.forEach(invoice => {
        //         this.availableInvoiceHandle.push({
        //             data: invoice,
        //             state: {
        //                 isVisible: true,
        //                 isSelected: false,
        //                 isOpen: false
        //             }
        //         });
        //     });
        // },
        // error => {
        //     // On Error
        //     this.loadingService.hideOverlay();
        // },
        // () => {
        //     // On complete
        //     this.loadingService.hideOverlay();
        // });
    } // end getPossibleMatches()

    getNewBankTransactions() {
        this.loadingService.showOverlay();
        this.bankTransactionsHandle = [];
        this.initTransactionsHandle = [];
        this.fullTransaction = [];
        this.partialTransaction = [];
        this.revisedTransaction = [];
        this.noneTransaction = [];
        this.duplicateTransactions = [];
        // this.loadingService.showOverlay();
        try {
            this.processPaymentService.getBankTransactionCount().subscribe({
                next: (_count) => {
                    this.count = _count;
                }
            });
        } catch (error) {

        }
        this.processPaymentService.getBankTransactions(this.searchString)
            .subscribe({
                next: (transactions) => {
                    // this.count = transactions.length;
                   // this.loadingService.hideOverlay();
                    transactions.forEach(trans => {
                        if (trans.IsDuplicate) {
                            this.duplicateTransactions.push(trans);
                        } else {
                            if (trans.Amount > 0) {
                                if (trans.Allocated === 'Fully') {
                                    this.fullTransaction.push({
                                        data: trans,
                                        state: {
                                            isVisible: true,
                                            isSelected: false,
                                            isOpen: false
                                        }
                                    });
                                } else if (trans.Allocated === 'Partial') {
                                    this.partialTransaction.push({
                                        data: trans,
                                        state: {
                                            isVisible: true,
                                            isSelected: false,
                                            isOpen: false
                                        }
                                    });
                                } else if (trans.Allocated === 'Revised') {
                                    this.revisedTransaction.push({
                                        data: trans,
                                        state: {
                                            isVisible: true,
                                            isSelected: false,
                                            isOpen: false
                                        }
                                    });
                                } else if (trans.Allocated === 'None') {
                                    this.noneTransaction.push({
                                        data: trans,
                                        state: {
                                            isVisible: true,
                                            isSelected: false,
                                            isOpen: false
                                        }
                                    });
                                }
                                // this.initTransactionsHandle.push({
                                //     data: trans,
                                //     state: {
                                //         isVisible: true,
                                //         isSelected: false,
                                //         isOpen: false
                                //     }
                                // });
                            } else if (trans.Amount < 0) {
                                this.initTransactionsHandle.push({
                                    data: trans,
                                    state: {
                                        isVisible: true,
                                        isSelected: true,
                                        isOpen: false
                                    }
                                });
                            }
                        }
                    });
                },
                error: (error) => {                    
                },
                complete: () => {
                    this.bankTransactionsHandle =
                        this.fullTransaction.concat(this.partialTransaction)
                            .concat(this.revisedTransaction).concat(this.noneTransaction).concat(this.initTransactionsHandle);
                    this.bankTransactionsHandle = this.bankTransactionsHandle.sort((a, b) => b.data.Date.toUpperCase()
                        .localeCompare(a.data.Date.toUpperCase()));
                    this.newBankTransactionClick = false;
                    this.loadingService.hideOverlay();
                    if (this.duplicateTransactions.length > 0) {
                        this.deleteDuplicateTransaction();
                    }                   
                }
            });
    } // end getNewBankTransactions()

    showInvoices($event) {
        this.promptInvoices.next($event);
    } // end showInvoices()

    showProcessedInvoices($event) {
        this.selectedTransaction = Object.assign({}, $event);
        this.promptProcessedInvoices.next($event);
    } // end showProcessedInvoices

    onProcessed($event) {
        // this.promptInvoices.next($event);
        // this.processPaymentService.selectedRowId = Number($event);
        this.togglePanel('newBankTransactions');
        // this.togglePanel('processedBankTransactions');
    } // end onProcessed()

    onadded($event) {
        this.togglePanel('newBankTransactions');
    } // end onadded()

    onTranCount($event) {
        // this.count = $event;
        // this.tranCount.emit($event);
    } // end onTranCount()

    sendStatementToClient() {
        this.loadingService.showOverlay();
        // const data =  this.processPaymentService.getProofOfPayment().toPromise();
        // if (data.length > 0) {
        //     this.showErrorMessagePrompt = true;
        //     this.loadingService.hideOverlay();
        // } else

        if (this.bankTransactionsHandle.find(trans => trans.data.Allocated === 'Revised')) {
            this._revisedBankTransaction = this.bankTransactionsHandle.filter(trans => trans.data.Allocated === 'Revised');
            this.loadingService.hideOverlay();
            this.showRevisedPrompt = true;
        } else {
            this.onClick('send-bank-statements');
            this.loadingService.hideOverlay();
        }
    }

    deleteDuplicateTransaction() {
        for (const todetele of this.duplicateTransactions) {
            console.log('DUPLICATE TRANSACTIONS TO BE DELETED: ', todetele);
            this.processPaymentService.deleteBankTransactions(todetele.Id).subscribe({ next: (_delete) => { } });
        }
    } // end deleteDuplicateTransaction()

    onHideErrorMessagePrompt() {
        this.showErrorMessagePrompt = false;
        this.router.navigate([PATH_PROOF_OF_PAYMENTS]);
    } // end onHideErrorMessagePrompt()

    onHideRevisedPrompt() {
        this.showRevisedPrompt = false;
    } // end onHideRevisedPrompt()

    onClick(reportName) {
        switch (reportName) {
            case 'send-bank-statements':
                if (this._revisedBankTransaction && this._revisedBankTransaction.length) {
                    for (const reviced of this._revisedBankTransaction) {
                        for (const line of reviced.data.BankTransactionLines) {
                            this.processPaymentService.removeTransactionLines(line.TransactionId).subscribe({ next: (_remove) => { } });
                        }
                    }
                    this.router.navigate([PATH_SEND_STATEMENT]);
                } else {
                    this.router.navigate([PATH_SEND_STATEMENT]);
                }
                break;
        }
    } // end onClick()

    onCancelClicked() {
        this.router.navigate([PATH_PROCESS]);
    } // end onCancelClicked()

} // end ReconcileBankTransactionsComponent()
