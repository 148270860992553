import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { retry, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface Invoice {
  Id: number;
  InvoiceDate: string;
  InvoiceNo: string;
  Total: number;
  Vat: number;
  TotalExcl: number;
  Notes: string;
  YourRef: string;
  IsPaid: boolean;
  ServiceID: number;
  InvoiceStatus: string;
} // end Invoice{}

export const DEFAULT_INVOICE: Invoice = {
  Id: 0,
  InvoiceDate: '',
  InvoiceNo: '',
  Total: 0,
  Vat: 0,
  TotalExcl: 0,
  Notes: '',
  YourRef: '',
  IsPaid: false,
  ServiceID: 0,
  InvoiceStatus: '',
};
export interface ArbitrationView {
  AttorneyId: number;
  AttorneyName: string;
  ClientId: number;
  ClientName: string | null;
  ClientRefNo: string;
  ClientStreetAddress: string;
  ClientVATNo: string;
  Id: number;
  InvoiceDate: string;
  InvoiceNo: string;
  InvoiceNote: string;
  InvoiceParties: string;
  InvoiceReference: string;
  InvoiceStatus: string;
  InvoiceTotal: number;
  InvoiceTotalExcel: number;
  InvoiceVAT: number;
  ServiceId: number;
  SplitPercentage: number;
  SupplierCell: string;
  SupplierEmail: string;
  SupplierFax: string;
  SupplierId: number;
  SupplierName: string;
  SupplierPhone: string;
  SupplierStreetAddress: string;
  SupplierVATNo: string;
  Terms: string;
  Recipients: string;
} // end ArbitrationView()

export const DEFAULT_ARBITRATION_VIEW: ArbitrationView = {
  AttorneyId: 0,
  AttorneyName: '',
  ClientId: 0,
  ClientName: '',
  ClientRefNo: '',
  ClientStreetAddress: '',
  ClientVATNo: '',
  Id: 0,
  InvoiceNo: '',
  InvoiceDate: '',
  InvoiceStatus: '',
  InvoiceTotal: 0,
  InvoiceVAT: 0,
  InvoiceTotalExcel: 0,
  SplitPercentage: 0,
  InvoiceReference: '',
  InvoiceNote: '',
  ServiceId: 0,
  InvoiceParties: '',
  SupplierId: 0,
  SupplierName: '',
  SupplierCell: '',
  SupplierEmail: '',
  SupplierFax: '',
  SupplierPhone: '',
  SupplierStreetAddress: '',
  SupplierVATNo: '',
  Terms: '',
  Recipients: ''
}

export interface InvoiceNote {
  Id: number;
  Notes: string;
} // end Invoice{}

export const DEFAULT_INVOICE_NOTE: InvoiceNote = {
  Id: 0,
  Notes: ''
};

@Injectable()
export class InvoicesService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  getInvoice(serviceId: number): Observable<any> {
    return this.http.get(this.api.endpoints.invoice +
      '?ServiceID=' + serviceId,
      this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getInvoice()

  getArbitrationViewData(serviceId: number): Observable<any> {
    return this.http.get(this.api.endpoints.invoice +
      '/GetArbitrationData?ServiceID=' + serviceId, this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end getArbitrationViewData()

  getDisbmtInvoiceReport(invoiceId: number): Observable<any> {
    return this.http.get(this.api.endpoints.invoice +
      '/DisbmtInvoiceReport?invoiceId=' + invoiceId,
      { responseType: 'blob' as 'json' } 
      ).pipe(catchError(this.api.handleError)
    );
  } // end getDisbmtInvoiceReport()

  updateInvoice(invoice: Invoice): Observable<any> {
    return this.http.put(this.api.endpoints.invoice +
      '?id=' + invoice.Id, invoice, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end updateInvoice()

  updateReviseInvoice(serviceId: number, invoice: Invoice): Observable<any> {
    return this.http.put(this.api.endpoints.invoice +
      '/ReviseInvoice?id=' + serviceId, invoice, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end updateReviseInvoice()

  updateInvoiceAddUpdateNote(invoice: InvoiceNote): Observable<any> {
    return this.http.put(this.api.endpoints.invoice +
     '/UpdateNote?id=' + invoice.Id, invoice, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end updateInvoiceAddUpdateNote()

  updateArbitrationInvoice(arbitration: any): Observable<any> {
    return this.http.put(this.api.endpoints.invoice + '/PutArbitration?ServiceProviderId=' +
      this.api.serviceProviderID, arbitration, this.api.httpOptions
    )
    .pipe(
      catchError(this.api.handleError)
    );
  } // end updateArbitrationInvoice()
} // end InvoicesService{}
