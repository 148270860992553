<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div *ngIf="showTransactions === true">
    <div class="block-action-bar row">
        <div class=" col-sm-2 active cursor">Allocate to</div>
        <div class="col-sm-2" [class.active]="showActions">
            <a class="action-buttons" (click)="onAllocateClick('Personal')"><i class="far fa-user-plus"></i><u>Personal</u></a>
        </div>
        <div class=" col-sm-2" [class.active]="showActions">
            <a class="action-buttons" (click)="onAllocateClick('LawFirm')"><i class="far fa-landmark"></i><u>Law Firm</u></a>
        </div>
        <div class=" col-sm-2" [class.active]="showActions">
            <a class="action-buttons" (click)="onAllocateClick('Exclude')"><i class="far fa-minus-circle"></i><u>Exclude</u></a>
        </div>
        <div class=" col-sm-2 pr-0 pl-0" [class.active]="showActions">
            <a class="action-buttons" (click)="onAllocateClick('ReferToClient')"><i class="far fa-user-tie"></i><u>Refer to Client</u></a>
        </div>
        <div class=" col-sm-2 " [class.active]="showActions">
            <a class="action-buttons" (click)="onAllocateClick('Delete')"><i class="far fa-trash-alt"></i><u>Delete</u></a>
        </div>
    </div>
            <!-- <div class="col-sm-2 process" [class.active]="showActions">
                <a (click)="onAllocateClick('process')"><i class="far fa-sync"></i><u>Process</u></a>
            </div> -->

    <div class="row reconcile-search input-group">
        <input
            type="search"
            class="form-control"
            placeholder="Search"
            [(ngModel)]="searchTerm"
            (keyup)="performSearch()">
        <div class="input-group-append">
            <i *ngIf="!searchTerm" class="far fa-search"></i>
            <i *ngIf="searchTerm" (click)="clearSearch()" class="fa fa-close"></i>
        </div>
    </div>

    <div class="row reconcile-table">
        <div class="table-responsive" style="max-height: 400px; padding-bottom: 30px;">
            <table class="table table-striped">

                <tbody>
                    <tr>
                        <th scope="col">
                            <div class="checkbox" style="margin-bottom: -3px;">
                                <input
                                    #inputCheckbox
                                    type="checkbox"
                                    (change)="toggleSelectAll()">
                            </div>
                        </th>
                        <th 
                            scope="col"
                            style="min-width: 95px;"
                            class="align-bottom"
                            (click)="toggleSort('account-number')">Account No.
                            <i class="fa fa-sort" [class.fa-sort-down]="sortAccountNumber"></i>
                        </th>
                        <th 
                            scope="col"
                            style="min-width: 78px;"
                            class="align-bottom"
                            (click)="toggleSort('date')">Date
                            <i class="fa fa-sort" [class.fa-sort-down]="sortDate"></i>
                        </th>
                        <th
                            scope="col"
                            class="align-bottom"
                            (click)="toggleSort('description')">Transaction Description
                            <i class="fa fa-sort" [class.fa-sort-down]="sortDescription"></i>
                        </th>
                        <th
                            scope="col"
                            style="min-width: 80px;" 
                            class="text-center"
                            (click)="toggleSort('amount')">Amount
                            <i class="fa fa-sort" [class.fa-sort-down]="sortAmount"></i>
                        </th>
                        <th scope="col" style="width: 50px;" class="text-center pl-3 pr-3">AI</th>
                        <th scope="col" style="width: 7px;" class="text-center pl-3 pr-3"></th>
                        <th hidden scope="col" style="width: 7px;" class="text-center pl-3 pr-3"></th>
                        <th
                            scope="col"
                            style="width: 77px;"
                            (click)="toggleSort('allocated')"
                            class="text-center">Allocated
                            <i class="fa fa-sort" [class.fa-sort-down]="sortAllocated"></i>
                        </th>
                    </tr>

                    <tr class="tr" *ngFor="let bank of bankTransactionsHandle" [class.active]="row == bank.data.Id">
                        <td *ngIf="bank.state.isVisible" scope="row">
                            <!-- <div class="checkbox">
                                <input type="checkbox" (change)="onChangeCheckBox(bank.data, $event.target.checked)"
                                    id="n{{bank.data.Id}}">
                                <label for="n{{bank.data.Id}}"></label>
                            </div> -->
                            <app-small-checkbox
                                [(checked)]="bank.state.isSelected"
                                (click)="toggleCheckbox(bank)"></app-small-checkbox>
                        </td>
                        <td *ngIf="bank.state.isVisible"
                         [ngStyle]="{'color': getRevisedAllocatedColor(bank.data.Allocated),'font-weight': getRevisedAllocatedText(bank.data.Allocated)}"
                          style="min-width: 95px;" [title]="bank.data.AccountNo" (click)="onRowClick(bank.data)">{{bank.data.AccountNo}}</td>
                        <td *ngIf="bank.state.isVisible"
                         [ngStyle]="{'color': getRevisedAllocatedColor(bank.data.Allocated),'font-weight': getRevisedAllocatedText(bank.data.Allocated)}"
                          style="min-width: 78px;" [title]="bank.data.Date | date:'yyyy-MM-dd'" (click)="onRowClick(bank.data)">{{bank.data.Date | date:'yyyy-MM-dd'}}</td>
                        <td *ngIf="bank.state.isVisible" 
                         [ngStyle]="{'color': getRevisedAllocatedColor(bank.data.Allocated),'font-weight': getRevisedAllocatedText(bank.data.Allocated)}"
                         [title]="bank.data.TransactionDescription" (click)="onRowClick(bank.data)">{{bank.data.TransactionDescription}}</td>
                        <td *ngIf="bank.state.isVisible"
                         [ngStyle]="{'color': getRevisedAllocatedColor(bank.data.Allocated),'font-weight': getRevisedAllocatedText(bank.data.Allocated)}"
                          style="min-width: 80px;" [title]="bank.data.Amount | currency:currencySymbol" class="text-right" (click)="onRowClick(bank.data)">{{bank.data.Amount | currency:currencySymbol}}</td>
                        <td *ngIf="bank.state.isVisible"
                            style="width: 50px;"
                             [ngStyle]="{'color': getRevisedAllocatedColor(bank.data.Allocated),'font-weight': getRevisedAllocatedText(bank.data.Allocated)}"
                            class="text-center" (click)="onRowClick(bank.data)">
                            <i class="far" [class.fa-brain]="bank.data.Allocated==='Fully' || bank.data.Allocated==='Partial'"></i>
                        </td>
                        <td *ngIf="bank.state.isVisible"
                            style="width: 7px;"
                            class="text-center"
                            (click)="viewProofOfPayment(bank.data)">
                            <a *ngIf="bank.data.ProofOfPaymentId > 0" title="Proof of payment attactment">
                                <i class="fal fa-paperclip" style="font-size: 14px !important;"></i>
                            </a>
                        </td>
                        <td hidden *ngIf="bank.state.isVisible"
                            style="width: 7px;"
                            class="text-center"
                            (click)="viewEditTransaction(bank.data)">
                            <a title="Edit transaction">
                                <i class="fal fa-edit" style="font-size: 14px !important;"></i>
                            </a>
                        </td>
                        <td *ngIf="bank.state.isVisible"
                            style="width: 77px;"
                            class="text-center"
                            (click)="onRowClick(bank.data)"><u
                            [ngStyle]="{'color': getAllocatedColor(bank.data.Allocated),'font-weight': getRevisedAllocatedText(bank.data.Allocated)}">{{bank.data.Allocated}}</u>
                        </td>
                    </tr>  
                    
                    
                    <tr class="tr" *ngFor="let bank of workingNewBankTransactions">
                        <td scope="row">
                            <!-- <div class="checkbox">
                                <input type="checkbox" (change)="onChangeCheckBox(bank.data, $event.target.checked)"
                                    id="n{{bank.Id}}">
                                <label for="n{{bank.Id}}"></label>
                            </div> -->
                            <!-- <app-small-checkbox
                                [(checked)]="bank.state.isSelected"
                                (click)="toggleCheckbox(bank)"></app-small-checkbox> -->
                        </td>
                        <td
                         [ngStyle]="{'color': getRevisedAllocatedColor(bank.Allocated),'font-weight': getRevisedAllocatedText(bank.Allocated)}"
                          style="min-width: 95px;" [title]="bank.AccountNo" (click)="onRowClick(bank)">{{bank.AccountNo}}
                        </td>
                        <td
                         [ngStyle]="{'color': getRevisedAllocatedColor(bank.Allocated),'font-weight': getRevisedAllocatedText(bank.Allocated)}"
                          style="min-width: 78px;" [title]="bank.Date | date:'yyyy-MM-dd'" (click)="onRowClick(bank)">
                            <input
                                type="text"
                                class="categotyText"
                                bsDatepicker
                                [bsValue]="parseTransactionDate(bank.Date)"
                                (ngModel)="bank.Date=$event.target.value"
                                (bsValueChange)="onValueChange(bank, $event)"
                                [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                                placement="right">
                        </td>
                        <td 
                         [ngStyle]="{'color': getRevisedAllocatedColor(bank.Allocated),'font-weight': getRevisedAllocatedText(bank.Allocated)}"
                         [title]="bank.TransactionDescription" (click)="onRowClick(bank)">
                         <input
                         required
                         style="width: 100%;"
                         type="text"
                         class="categotyText"
                         placeholder="Type your transaction description"
                         [(ngModel)]="bank.TransactionDescription">
                        </td>
                        <td
                         [ngStyle]="{'color': getRevisedAllocatedColor(bank.Allocated),'font-weight': getRevisedAllocatedText(bank.Allocated)}"
                          style="min-width: 80px;" [title]="bank.Amount | currency:currencySymbol" class="text-right" (click)="onRowClick(bank)">
                          <input
                            class="categotyText"
                            type="text"
                            required
                            style="text-align: right;"
                            [ngModel]="bank.Amount | currency: currencySymbol: currencySymbol:'1.2-2'"
                            [ngModelOptions]="{updateOn: 'blur'}"
                            (ngModelChange)="bank.Amount=$event"
                            (blur)="onAmountChange(bank, $event)"
                            (focus)="onAmountClicked(bank, $event)"
                            (focus)="$event.target.select()"
                            pattern="[P-R\s]*[0-9,.]+"
                            [ngClass]="{'red-border': bank.Amount === 'R0.00' || bank.Amount === 0}"
                            #rateinput>
                        </td>
                        <td
                            style="width: 50px;"
                             [ngStyle]="{'color': getRevisedAllocatedColor(bank.Allocated),'font-weight': getRevisedAllocatedText(bank.Allocated)}"
                            class="text-center" (click)="onRowClick(bank)">
                            <i class="far" [class.fa-brain]="bank.Allocated==='Fully' || bank.Allocated==='Partial'"></i>
                        </td>
                        <td
                            style="width: 7px;"
                            class="text-center"
                            (click)="viewProofOfPayment(bank)">
                            <a *ngIf="bank.ProofOfPaymentId > 0" title="Proof of payment attactment">
                                <i class="fal fa-paperclip" style="font-size: 14px !important;"></i>
                            </a>
                        </td>
                        <td
                            style="width: 77px;"
                            class="text-center"
                            (click)="onRowClick(bank)" title="Remove transaction">
                            <i class="far fa-minus-circle clickable" (click)="onTransactionRemove(bank)"></i>
                        </td>
                    </tr>   
                    <tr *ngIf="isManualTransactions">                        
                        <td colspan="3">
                            <span class="column-add-another-transaction">
                                <span class="clickable" (click)="addAnotherTransaction()">
                                <i class="fa fa-plus-circle auxcon-green" style="margin-right: 5px;"></i>
                                <u class="auxcon-green">Add transaction</u>
                                </span>
                            </span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colspan="3">
                            <span class="column-add-another-transaction">
                                <button 
                                    class="btn btn-success"
                                    [class.disbled]="!canSaveManualTransaction"
                                    [disabled]="!canSaveManualTransaction"
                                    style="height: 22px;"
                                    [ngStyle]="{'background-color': (!canSaveManualTransaction) ? '#d3d3d3' : '#8abd37'}"
                                    (click)="saveTransactions()">Save
                                    </button>

                                <!-- <span class="clickable" [class.disbled]="!canSaveManualTransaction"
                                [disabled]="!canSaveManualTransaction" (click)="saveTransactions()">
                                <i class="fa fa-plus-circle auxcon-green" style="margin-right: 5px;"></i>
                                <u class="auxcon-green">Save</u>
                                </span> -->
                            </span>
                        </td>
                    </tr>       
                </tbody>
            </table>
        </div>
    </div>
</div>

<app-reconcile-bank-allocate-model
    [type]="allocatedType"
    [title]="allocatedTitle"
    [lawFirms]="lawFirms"
    [isVisible]="promptAllocateModel"
    (closeModal)="onHideAllocateModel()"
    (confirmAllocated)="onAllocateModel($event)">
</app-reconcile-bank-allocate-model>

<div class="reconcile" *ngIf="showTransactions === false">
    <div class="row">
        <i class="fal fa-check-circle"></i>
    </div>
    <div class="row">
        You have reconciled & processed all of your bank transactions
    </div>
</div>

<app-alert
    title="No invoices attached to transaction"
    message="No invoices linked to transaction - Make sure there are invoices linked to the transaction before processing."
    [isVisible]="showTransactionsErrorPrompt"
    (cancel)="onHideTransactionsErrorPrompt()"
    (confirm)="onHideTransactionsErrorPrompt()"></app-alert>

    <app-prompt-unsaved-changes-modal
        title="Invoices linked to transaction"
        message="You can not delete transaction(s) with linked invoice(s). Please unlink the invoice(s) and try again."
        [isVisible]="showErrorMessagePrompt"
        btnTextCancel="Cancel"
        btnTextContinue="OK"
        (cancel)="onHideErrorMessagePrompt()"
        (continue)="onHideErrorMessagePrompt()"></app-prompt-unsaved-changes-modal>

    <app-proforma-invoice
        #proformaInvoice
        title="Proof Of Payment"
        [popId]="popId"
        invoice="0"
        documentType="ProofOfPayment"
        matter="null"
        [isVisible]="showProofOfPaymentDoc"
        (closeModal)="closeModal()"></app-proforma-invoice>

<app-prompt-unsaved-changes-modal
    title="Send Statement of Account(s)"
    message="Do you wish to send your statement of account(s)?"
    [isVisible]="showSendStatementPrompt"
    btnTextContinue="Proceed"
    btnTextCancel="Cancel"
    (cancel)="onHideSendStatementPrompt()"
    (continue)="onSendStatement()"></app-prompt-unsaved-changes-modal>

<app-bank-account-modal
    *ngIf="onShowBankAccountModal"
    [isVisible]="onShowBankAccountModal"
    [bankAccountHandle]="bankAccountHandle"
    [(selectedBankAccountHandle)]="selectedBankAccountHandle"
    (cancel)="onHideBankAccountModal()"
    (continue)="onSelectedBankAccountModal($event)"></app-bank-account-modal>

<app-edit-transaction
    *ngIf="onShoEditTransactionModal"
    [isVisible]="onShoEditTransactionModal"
    [title]="title"
    [editTransaction]="editTransaction"
    (cancel)="onHideEditTransactionModal()"
    (save)="onSaveEditTransactionModal($event)"
></app-edit-transaction>