import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import {
  SentMessagesService,
  SentMessage,
  ClientInboxMessages
} from '../../services/sent-messages/sent-messages.service';
import {
  SnackbarsService, Snack, SnackType
} from '../../services/messaging/snackbars/snackbars.service';
import {
  ToastService,
  LENGTH_LONG
} from '../../services/messaging/toast/toast.service';
import { ApiService } from '../../services/api.service';
import { UtilitiesService } from '../../services/utilities.service';
import { MattersService, Matter, BriefDocuments, BriefDocumentHandle } from '../../services/matters/matters.service';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { PATH_MATTERS, PATH_DASHBOARD, PATH_INVOICE_PREVIEW } from '../../services/appdata/app-config.service';

import { ICrumb } from '../../services/advocate-detail-statement/advocate-detail-statement.service';
import { InvoiceDetails, InvoiceDetailsService, DEFAULT_INVOICE_DETAILS } from '../../services/invoice-details/invoice-details.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import * as moment from 'moment';
import {
  ActivityLogs,
  DEFAULT_ACTIVITY_LOGS,
  ActivityLogsService
} from '../../services/activity-logs/activity-logs.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import {
  NotesService,
  NotesObject,
  DEFAULT_NOTES_OBJECT,
  InstructionResponses,
  Notes,
  NotesObjectHandle,
  DEFAULT_INSTRUCTION_RESPONSES,
  NoteCategory,
  MultiInvoiceNote,
  DEFAULT_MULTI_OBJECT_NOTE,
  DEFAULT_NOTE,
  MessagesInboxStatus,
  DEFAULT_MESSAGES_INBOX_STATUS,
} from '../../services/notes/notes.service';
// import { TypeaheadMatch } from 'ngx-bootstrap';
import {
  LawfirmSummaryOverview,
  DEFAUL_LAWFIRM_SUMMARY_OVERVIEW,
  ReportsService,
} from '../../services/reports/reports.service';
import { QuotationFeeItem, QuotationService } from '../../services/quotation/quotation.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

const TAB_INBOX = 'inbox';
const TAB_SENT_ITEMS = 'sent-items';

const FIELD_DATE = 'date';
const FIELD_TO = 'to';
const FIELD_TYPE = 'type';
const FIELD_INVOICE_NUMBER = 'invoice number';
const FIELD_SUBJECT = 'subject';
const FIELD_STATUS = 'status';


export interface MessageHandle {
  data: SentMessage;
  state: {
    isVisible: boolean;
    showDropdown: boolean;
    isSelected: boolean;
  };
} // end MessageHandle{}

@Component({
  selector: 'app-advocate-messages',
  templateUrl: './advocate-messages.component.html',
  styleUrls: ['./advocate-messages.component.scss']
})
export class AdvocateMessagesComponent implements OnInit {

  title = 'Messages';
  crumbs: ICrumb[];
  isLoading = true;
  tabs: any = {
    inbox: TAB_INBOX,
    sentItems: TAB_SENT_ITEMS
  };
  activeTab: string = TAB_SENT_ITEMS;
  outbox: any = [];
  searchItems = new Subject<string>()
  sentMessages: MessageHandle[] = [];
  activeMessage: MessageHandle; // Warning this field is only used when Forwarding
                                // If you want to start using it for something else be carefull
                                // about resetting it. Christina
            
  // If you want to start using it for something else be carefull
  // about resetting it. Christina
  filteredSentMessages: SentMessage[] = [];
  historical: any = [];
  newMessage: any = [];
  showRecipientsModal = false;
  ServiceID: any;
  sortBy: string;
  sortAscending: boolean;
  notes: NotesObject[] = [];
  notesHandle: NotesObjectHandle[] = [];
  multiInvoiceNote: MultiInvoiceNote[];
  selectedNote: NotesObject;
  multipleObjectNotes: NotesObject[] = [];
  openedNote: NotesObjectHandle;
  showInboxInvoice: boolean;

  clientInboxMessages: ClientInboxMessages[] = [];

  sentMessagesMenus: any = [];
  dropdownOptions: any = [
    'Forward invoice',
    'Edit invoice',
    'Continue with matter'
  ];

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  // Invoice Note
  selectedTab: string;
  // notes
  showInvoiceNoteDetail: boolean;
  objectType: string;
  onShowNoteDetail: boolean;
  noteDetailData: NotesObject;
  invoiceDetails: InvoiceDetails;
  invoicePlaintiff: string;
  invoiceDefendant: string;
  noteCategory: NoteCategory[] = [];
  lawfirmSummaryData: LawfirmSummaryOverview;

  downloadLinkBaseURL: string;

  searchTerm = '';
  searchInboxTerm = '';
  orderBy = '';
  orderingOptions: string[] = [
    '',
    'date',
    'to',
    'type',
    'invoice number',
    'subject',
    'status'
  ];

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  private activeMessageHandle: MessageHandle;

  private selectedInvoiceID: number;
  private selectedServiceID: number;
  private previousSortBy: string;
  private _inputTimeHandle;

  sortDate: boolean;
  sortTo: boolean;
  sortInvoiceNo: boolean;
  sortSubject: boolean;
  sortStatus: boolean;
  showProformaInvoice: boolean;
  showQuotation: boolean;
  selectedInvoiceId = 0;
  messageId = 0;
  documentTitle: string;

  feesQuoted: QuotationFeeItem[] = [];
  multipleMessages: MessageHandle[] = [];
  activeMultipleMessages: MessageHandle[] = [];
  onShowMultipleSentMessagesPopup: boolean;
  forwardMultipleInvoices = false;


  // For Statement of Account Preview
  showStatement: boolean;

  // Brief and other documents
  briefDocuments: BriefDocuments[] = [];
  briefDocumentHandle: BriefDocumentHandle[] = [];
  showBriefDocumentModal: boolean;

  // Invoice Note Detail
  showAdvocateNoteDetail: boolean;
  instructionResponses: InstructionResponses[] = [];
  inboxMessageCounter = 0;

  selectedResponseText: string;
  selectedResponseId = 0;
  instructionText: string;
  selectedResponse: InstructionResponses;
  selectedNotesHandle: NotesObjectHandle[] = [];
  selectedNotes: NotesObject[] = [];

  showClientMultiInvoiceNote: boolean;
  activeButtonNotes: boolean;
  activeButtonInvoices: boolean;

  currentSentItemCount = 0;
  displaySentItemTotalCount = 0;
  currentSentItemIndex = 0;
  mainSentItemData: any = [];
  takeSentItems = 200;
  displayListCount: 0;

  // Is Practice Manager
  get isPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  }  // end isPracticeManager()

  get isSaveable(): boolean {
    return Boolean(this.selectedResponse && this.selectedResponse.Id > 0);
  } // end isSaveable()

  get selectedProfileType(): string {
    let profile = '';
    if (this.userProfileService.userProfile.isImpersonator) {
      profile = this.userProfileService.selectedUserProfile.profileType;
    } else {
      profile = this.userProfileService.userProfile.profileType;
    }
    return profile;
  } // end selectedProfileType()

  get selectedInvoices(): boolean {
    return Boolean(this.mainSentItemData.filter(x => x.state.isSelected).length > 0);
  } // end selectedInvoices()

  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private util: UtilitiesService,
    private notesService: NotesService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private mattersService: MattersService,
    private reportsService: ReportsService,
    private snackbarService: SnackbarsService,
    private quotationService: QuotationService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private sentMessagesService: SentMessagesService,
    private invoiceDetailService: InvoiceDetailsService,
  ) {
    document.title = route.snapshot.data.title;
    this.sortAscending = false;
    this.activeButtonNotes = true;
  } // end constructor()

  ngOnInit() {
    this.fetchSentMessages();
    this.crumbs = [{ label: 'Messages | Sent Items' }];
    this.isLoading = false;
    this.sortBy = 'date';

    this.searchItems.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    )
      .subscribe((value) => {
        this.mainSentItemData = [];
        this.currentSentItemIndex = 0;
        this.currentSentItemCount = 0;
        this.fetchSentMessages();
      });

  } // end ngOnInit()

  setActiveTab(tab: string) {
    this.activeTab = tab;
    if (this.activeTab === 'sent-items') {
      this.crumbs = [{ label: 'Messages | Sent Items' }];
      this.fetchSentMessages();
    } else if (this.activeTab === 'inbox') {
      this.crumbs = [{ label: 'Messages | Inbox' }];
      this.getAllInboxNotes();
    }
  } // end setActiveTab()

  toggleChecbox(sentMessages: MessageHandle) {
    sentMessages.state.isSelected = !sentMessages.state.isSelected;
  } // end toggleChecbox()

  toggleMultipleInvoiceMenu() {
      this.onShowMultipleSentMessagesPopup = !this.onShowMultipleSentMessagesPopup;
  } // end toggleMultipleInvoiceMenu()

  fetchSentMessages(isRefreshButtonClick: boolean = false) {
    const snack: Snack = {
      label: 'Loading sent messages',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack, 50).show();
    this.loadingService.showOverlay();

    let IndexCount = isRefreshButtonClick ? 0 : (this.currentSentItemIndex * this.takeSentItems);
    let takeSentItems = isRefreshButtonClick ? this.displayListCount : this.takeSentItems;
    
    this.sentMessagesService.getSentMessagesByPage(this.searchTerm, IndexCount, takeSentItems).subscribe({
      next: (messages) => {
        this.displaySentItemTotalCount = messages.totalCount;

        if (this.displaySentItemTotalCount <= this.takeSentItems)
          this.currentSentItemCount = this.displaySentItemTotalCount;

        if (messages.data) {
          if (isRefreshButtonClick)
            this.mainSentItemData = [];

          messages.data.forEach(element => {
            this.mainSentItemData.push({
              data: element,
              state: { showDropdown: false, isSelected: false }
            });
          });
          this.displayListCount = this.mainSentItemData.length;
        }
      },
      error: (error) => {
        const msg = 'Error loading sent messages.';
        snack.label = msg;
        this.snackbarService.dismiss().make(snack, 5000).show();
      },
      complete: () => {
        this.mainSentItemData.sort((a, b) => {
          return b.data.dateDelivered.toUpperCase().localeCompare(a.data.dateDelivered.toUpperCase());
        });
        this.loadingService.hideOverlay();
      }
    });
  } // end fetchSentMessages()

  LoadMoreSentItems() {
    this.currentSentItemCount = (this.currentSentItemIndex + 2) * this.takeSentItems;
    this.currentSentItemIndex = this.currentSentItemIndex + 1;
    this.fetchSentMessages();
  }

  refreshList() {
    this.fetchSentMessages(true);
  } //end refreshList()

  // Get all inbox notes for current advocate
  getAllInboxNotes() {
    const snack: Snack = {
      label: 'Loading inbox messages',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack, 5000).show();
    this.isLoading = true;
    this.notesService.getAllNotes(this.api.serviceProviderID).subscribe({
      next: (_not) => {
        this.isLoading = true;
        this.notes = _not;
      },
      error: (error) => {
        const msg = 'Error loading inbox messages.';
        snack.label = msg;
        this.snackbarService.dismiss().make(snack, 5000).show();
      },
      complete: () => {
        this.notes.sort((a, b) => {
          return b.CaptureDate.toUpperCase().localeCompare(a.CaptureDate.toUpperCase());
        });
        this.initNotes();
      }
    });
  } // end getAllInboxNotes()

  initNotes() {
    this.notesHandle = [];
    this.notes.forEach(n => {
      this.notesHandle.push({
        data: n,
        state: {
          isVisible: true,
          isOpen: false,
          isSelected: false
        }
      });
    });
  } // end initNotes{}

  onViewInvoice(message: SentMessage) {
    if (message.invoiceNo !== 'Multiple Invoices' && message.source && message.source === 'Invoice' ||
      (message.invoiceNo !== 'Multiple Invoices' && message.source && message.source === 'Notes' && message.description === 'Invoice')) {
      if (message.sourceId) {
        this.loadingService.showOverlay();
        this.notes = [];
        this.selectedNotesHandle = [];
        this.selectedTab = 'invoice';
        if (message.sourceId) {
          let _note = [];
          if (this.isPracticeManager) {
            this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
              message.sourceId, 4).subscribe({
                next: (_nn) => {
                  _note = _nn;
                }
              });
          } else if (!this.isPracticeManager) {
            this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID,
              message.sourceId, 4).subscribe({
                next: (_nn) => {
                  _note = _nn;
                }
              });
          }
          if (!this.isPracticeManager) {
            this.notes = _note.filter(x => x.QuestionText);
          } else {
            this.notes = _note;
          }
          this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
          // this.showProformaInvoice = true;
          this.invoiceDetailService.getInvoiceDetails(message.sourceId).subscribe({
            next: (_invoice_details) => {
              this.invoiceDetails = _invoice_details;
            }
          });
          this.selectedInvoiceId = message.sourceId;
          this.getAllQuotedFees(this.invoiceDetails.ServiceId);
          if (this.invoiceDetails) {
            const desc = this.invoiceDetails.InvoiceParties.split(' // ');
            if (desc.length > 0) {
              this.invoicePlaintiff = desc[0];
            }
            if (desc.length > 1) {
              this.invoiceDefendant = desc[1];
            }
          }

          this.notes.forEach(_notes => {
            this.selectedNotesHandle.push({
              data: _notes,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          });
        }
        this.showProformaInvoice = true;
        this.loadingService.hideOverlay();
      }
    } else if (message.source && message.invoiceNo !== 'Multiple Invoices' &&
      message.source === 'Notes' && message.description === 'Contact') {
      this.loadingService.showOverlay();
      let _note = null;
      this.notesService.getNoteById(this.api.serviceProviderID,
        message.id).subscribe({
          next: (_nn) => {
            _note = _nn;
            this.onNoteDetailView(_note);
          }
        });
    } else if (message.invoiceNo === 'Multiple Invoices') {
      this.multiInvoiceNote = [];
      this.selectedNote = Object.assign({}, DEFAULT_NOTES_OBJECT);
      this.notesService.getNoteById(this.api.serviceProviderID,
        message.id).subscribe({
          next: (_nb) => {
            this.selectedNote = _nb;
          }
        });
      this.notesService.getNotesObjects(this.selectedNote.MutliObjects).subscribe({
        next: (_next) => {
          const _multiObject = _next;
          this.multiInvoiceNote = _multiObject.filter(x => x.AdvocateStatus === 'Active');
        }
      });
      this.objectType = 'Multiple Invoices';
      this.showClientMultiInvoiceNote = true;
    } else if (message.source && message.source === 'CreditNote') {
      this.messageId = message.id;
      this.documentTitle = 'Credit note';
      this.showStatement = true;
    } else if (message.source === 'Statement') {
      if (message.id) {
        this.messageId = message.id;
        this.documentTitle = 'Statement of Account';
        this.showStatement = true;
      }
    } else if (message.source === 'Quotation') {
      this.onViewQuotation(message);
    }
    // else if (message.source && message.source === 'Notes' && message.description === 'Invoice') {
    //   this.loadingService.showOverlay();
    //   this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
    //   this.selectedNote = Object.assign({}, DEFAULT_NOTES_OBJECT);
    //   const _note =  this.notesService.getNoteById(this.api.serviceProviderID,
    //     message.id).toPromise();
    //   this.selectedNote = _note;
    //   // this.showProformaInvoice = true;
    //   this.invoiceDetails =  this.invoiceDetailService.getInvoiceDetails(message.sourceId).toPromise();
    //   this.selectedInvoiceId = message.sourceId;

    //   if (this.invoiceDetails) {
    //     const desc = this.invoiceDetails.InvoiceParties.split(' // ');
    //     if (desc.length > 0) {
    //       this.invoicePlaintiff = desc[0];
    //     }
    //     if (desc.length > 1) {
    //       this.invoiceDefendant = desc[1];
    //     }
    //   }
    //   this.objectType = 'invoice';
    //   // this.showAdvocateNoteDetail = true;
    //   this.showProformaInvoice = true;
    //   this.loadingService.hideOverlay();
    // }
  } // end onViewInvoice()

  onViewQuotation(message: SentMessage) {
    if (message.sourceId) {
      this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
      // this.showProformaInvoice = true;
      this.invoiceDetailService.getQuotationDetails(message.sourceId).subscribe({
        next: (_invoice_details) => {
          this.invoiceDetails = _invoice_details;
        }
      });
      this.selectedInvoiceId = message.sourceId;
      // this.getAllQuotedFees(this.invoiceDetails.ServiceId);
      if (this.invoiceDetails) {
        const desc = this.invoiceDetails.InvoiceParties.split(' // ');
        if (desc.length > 0) {
          this.invoicePlaintiff = desc[0];
        }
        if (desc.length > 1) {
          this.invoiceDefendant = desc[1];
        }
      }
    }
    this.showQuotation = true;
    this.loadingService.hideOverlay();
  } // end onViewInvoice()

  onViewInboxInvoice(message: any) {
    // console.log('SELECTED MESSAGE: ', message);
    // if (message.sourceId) {
    this.loadingService.showOverlay();
    if (message.ObjectId) {
      this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
      this.invoiceDetailService.getInvoiceDetails(message.ObjectId).subscribe({
        next: (_invoice_detail) => {
          this.invoiceDetails = _invoice_detail;
        }
      });
      this.selectedInvoiceId = message.ObjectId;
      this.getAllQuotedFees(this.invoiceDetails.ServiceId);
      if (this.invoiceDetails) {
        const desc = this.invoiceDetails.InvoiceParties.split(' // ');
        if (desc.length > 0) {
          this.invoicePlaintiff = desc[0];
        }
        if (desc.length > 1) {
          this.invoiceDefendant = desc[1];
        }
      }
      // }
      this.showInboxInvoice = true;
      this.loadingService.hideOverlay();
    }
  } // end onViewInvoice()

  // initSentMessages() {
  //   const snack: Snack = {
  //     label: 'Initializing sent messages',
  //     action: null
  //   };
  //   this.snackbarService.snackbarComponent.make(snack).show();
  //   // Bind each message item to a
  //   if (this.sentItems) {
  //     this.sentItems.forEach(message => {
  //       // console.log('Pushing ',message);
  //       this.sentMessages.push({
  //         data: message,
  //         state: {
  //           isVisible: true,
  //           showDropdown: false,
  //           isSelected: false
  //         }
  //       });
  //       // const id = message.id + '-' + message.messageRecipientId;
  //       // this.sentMessagesMenus[id] = false;
  //       // this.toggleSort(this.sortBy);
  //       this.filterMessages();
  //     });
  //   }
  //   console.log('SENT MESSAGES INIT: ', this.sentMessages);
  //   this.isLoading = false;
  //   this.mattersService.getSentMatters().subscribe({ next: 
  //     (matters) => {
  //       this.matters = matters;
  //     },
  //     error: (error) => {
  //       const msg = 'Error loading matters.';
  //       console.error(msg, error);
  //       snack.label = msg;
  //       snack.type = SnackType.ERROR;
  //       this.snackbarService.dismiss().make(snack, 5000).show();
  //     },
  //     complete: () => {
  //       this.snackbarService.dismiss();
  //     },
  //   });
  // } // end initSentMessages()

  // initSearchMessages() {
  //   // Bind each message item to a
  //   // console.log('initSearchMessages');
  //   if (this.searchItems) {
  //     while (this.searchItems.length > 0) {
  //       const message = this.searchItems.pop();
  //       // console.log('Popped message:', message);
  //       if (this.sentItems.find(x => x.id === message.id)) {

  //       } else {
  //         this.sentMessages.push({
  //           data: message,
  //           state: {
  //             isVisible: true,
  //             showDropdown: false,
  //             isSelected: false
  //           }
  //         });
  //       }

  //     }
  //     // this.toggleSort(this.sortBy);
  //   }
  // } // end initSearchMessages()

  /**
   * Called when input is entered into the search input field.
   * @param {KeyboardEvent} event The triggered keyboard event.
   */
  onSearchInput(event) {
    console.log('OnSearchInput', this.onSearch);
    this.util.debounce(500, this.onSearch, [event]);
  } // end onSearchInput()

  /**
   *
   */
  onSearchEnter() {
    console.log('OnSearchEnter');
    if (this.searchTerm) {
      this.filterSentMessages();
    }
  } // end onSearchEnter()

  /**
   * Initiates a search filtration if the enter key is pressed.
   * @param {Event|InputEvent} event The triggered keyboard event.
   */
  onSearch(event) {
    console.log('OnSearch');
    if (event.data) {
      if (event.key === 'Enter') {
        switch (this.activeTab) {
          case TAB_INBOX:
            break;

          case TAB_SENT_ITEMS:
            this.filterSentMessages();
            break;
        } // end switch()
      }
    }
  } // end onSearch()

  onCloseModal() {
    this.showRecipientsModal = false;
  } // end onCloseModal()

  onForwardMultipleInvoiceMessageCloseModal() {
    this.forwardMultipleInvoices = false;
  } // end onForwardMultipleInvoiceMessageCloseModal()

  closeModal(value: string) {
    switch (value) {
      case 'invoice':
        this.showProformaInvoice = false;
        break;
      case 'inbox-invoice':
        this.showInboxInvoice = false;
        break;
      case 'statement':
        this.showStatement = false;
        break;
      case 'bried-document':
        this.showBriefDocumentModal = false;
        break;
      case 'client-note':
        this.showInvoiceNoteDetail = false;
        break;
      case 'advocate-note':
        this.showAdvocateNoteDetail = false;
        break;
      case 'quotation':
        this.showQuotation = false;
        break;
    }
  } // end closeModal()

  /**
   *
   */
  resetFilterSentMessage() {
    this.sentMessages.forEach(message => {
      message.state.isVisible = true;
    });
  } // end resetFilterSentMessage()

  onKeyDown(event) {
    switch (event.key) {
      case 'Enter':
        this.filterSentMessages();
        break;
    }
  } // end onKeyDown()

  /**
   * Filters sent messages based on the value of the searchTerm
   */
  filterSentMessages() {
    let showing = 0;
    if (this.searchTerm) {
      this.sentMessages.forEach(messsage => {
        if (
          !(messsage.data.dateDelivered.match(new RegExp(this.searchTerm, 'gi'))) &&
          !(messsage.data.email.match(new RegExp(this.searchTerm, 'gi'))) &&
          !(messsage.data.invoiceNo.match(new RegExp(this.searchTerm, 'gi'))) &&
          !(messsage.data.subject.match(new RegExp(this.searchTerm, 'gi')))
        ) {
          messsage.state.isVisible = false;
        } else {
          messsage.state.isVisible = true;
          showing++;
        }
      });
    } else {
      this.sentMessages.forEach(message => {
        message.state.isVisible = true;
      });
    }
    console.log('showing: ', showing);
    if (showing < 100) {
      // this.fetchSentMessagesBySearch();
    }
  } // end filterMessages()

  onInboxSearch() {
    if (this.searchInboxTerm) {
      this.notesHandle.forEach(note => {
        if (
          !(note.data.CaptureDate.match(new RegExp(this.searchInboxTerm, 'gi'))) &&
          !(note.data.ObjectTypeDescription.match(new RegExp(this.searchInboxTerm, 'gi'))) &&
          !(note.data.Category.match(new RegExp(this.searchInboxTerm, 'gi')))
        ) {
          note.state.isVisible = false;
        } else {
          note.state.isVisible = true;
        }
      });
    } else {
      this.notesHandle.forEach(note => {
        note.state.isVisible = true;
      });
    }
  } // end onInboxSearch()

  clearSearch() {
    this.searchTerm = null;
    this.sentMessages.forEach(message => {
      message.state.isVisible = true;
    });
  }
  /**
   * Sorting
   */
  toggleSort(sortBy: string) {
    // console.log('Sorting: ', sortBy);
    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      // toggle asceding
      this.sortAscending = !this.sortAscending;
    }
    switch (this.sortBy) {
      case 'date':

        this.sortDate = true;
        this.sortTo = false;
        this.sortInvoiceNo = false;
        this.sortSubject = false;
        // this.sortStatus = false;

        this.mainSentItemData.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.dateDelivered.toUpperCase().localeCompare(b.data.dateDelivered.toUpperCase());
          }
          return b.data.dateDelivered.toUpperCase().localeCompare(a.data.dateDelivered.toUpperCase());
        });
        break;

      case 'to':

        this.sortDate = false;
        this.sortTo = true;
        this.sortInvoiceNo = false;
        this.sortSubject = false;
        // this.sortStatus = false;

        this.mainSentItemData.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.email.toUpperCase().localeCompare(b.data.email.toUpperCase());
          }
          return b.data.email.toUpperCase().localeCompare(a.data.email.toUpperCase());
        });
        break;

      case 'invoiceNo':

        this.sortDate = false;
        this.sortTo = false;
        this.sortInvoiceNo = true;
        this.sortSubject = false;
        // this.sortStatus = false;

        this.mainSentItemData.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.invoiceNo.toUpperCase().localeCompare(b.data.invoiceNo.toUpperCase());
          }
          return b.data.invoiceNo.toUpperCase().localeCompare(a.data.invoiceNo.toUpperCase());
        });
        break;

      case 'subject':

        this.sortDate = false;
        this.sortTo = false;
        this.sortInvoiceNo = false;
        this.sortSubject = true;
        // this.sortStatus = false;

        this.mainSentItemData.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.subject.toUpperCase().localeCompare(b.data.subject.toUpperCase());
          }
          return b.data.subject.toUpperCase().localeCompare(a.data.subject.toUpperCase());
        });
        break;
    }
    this.previousSortBy = this.sortBy;
  } // end sort()

  /**
   * Orders messages according to the value of the orderBy property.
   */
  orderMessages() {
    switch (this.activeTab) { // Determines which list of messages to order
      case TAB_INBOX:
        // TODO: Implent ordering of inbox messages
        break;

      case TAB_SENT_ITEMS:
        switch (this.orderBy) { // Determines the field to order the messages by

          case FIELD_DATE:
            this.mainSentItemData.sort((a, b) => {
              if (this.sortAscending) {
                return a.data
                  .dateDelivered
                  .toUpperCase()
                  .localeCompare(b.data.dateDelivered.toUpperCase());
              }

              return b.data
                .dateDelivered
                .toUpperCase()
                .localeCompare(a.data.dateDelivered.toUpperCase());
            });
            break;

          case FIELD_TO:
            this.mainSentItemData.sort((a, b) => {
              if (this.sortAscending) {
                return a.data
                  .email
                  .toUpperCase()
                  .localeCompare(b.data.email.toUpperCase());
              }

              return b.data
                .email
                .toUpperCase()
                .localeCompare(a.data.email.toUpperCase());
            });
            break;

          case FIELD_TYPE:
            this.mainSentItemData.sort((a, b) => {
              if (this.sortAscending) {
                return a.data
                  .source
                  .toUpperCase()
                  .localeCompare(b.data.source.toUpperCase());
              }

              return b.data
                .source
                .toUpperCase()
                .localeCompare(a.data.source.toUpperCase());
            });
            break;

          case FIELD_INVOICE_NUMBER:
            this.mainSentItemData.sort((a, b) => {
              if (this.sortAscending) {
                return a.data
                  .invoiceNo
                  .toUpperCase()
                  .localeCompare(b.data.invoiceNo.toUpperCase());
              }

              return b.data
                .invoiceNo
                .toUpperCase()
                .localeCompare(a.data.invoiceNo.toUpperCase());
            });
            break;

          case FIELD_SUBJECT:
            this.mainSentItemData.sort((a, b) => {
              if (this.sortAscending) {
                return a.data
                  .subject
                  .toUpperCase()
                  .localeCompare(b.data.subject.toUpperCase());
              }

              return b.data
                .subject
                .toUpperCase()
                .localeCompare(a.data.subject.toUpperCase());
            });
            break;

          case FIELD_STATUS:
            this.mainSentItemData.sort((a, b) => {
              if (this.sortAscending) {
                return a.data
                  .status
                  .toUpperCase()
                  .localeCompare(b.data.status.toUpperCase());
              }

              return b.data
                .status
                .toUpperCase()
                .localeCompare(a.data.status.toUpperCase());
            });
            break;
        } // end switch()
        break;
    } // end switch()
    this.isLoading = false;
  } // end orderMessages()

  toggleMenu(message: MessageHandle) {
    // TODO: toggle this message menu upon.
    message.state.showDropdown = !message.state.showDropdown;
    if (this.activeMessageHandle &&
      this.activeMessageHandle !== message) {
      this.activeMessageHandle.state.showDropdown = false;
    }
    this.activeMessageHandle = message;
  } // end toggleMenu()

  onDismissMenu(message: MessageHandle) {
    message.state.showDropdown = false;
    this.activeMessageHandle = null;
  } // end onDismissMenu{}

  onMultipleMenuDismiss(messages: MessageHandle[]) {    
    this.onShowMultipleSentMessagesPopup = true;
  } // end onMultipleMenuDismiss()

  onForwardMultipleInvoiceMessage(recipients: string) {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Forwarding multiple invoices...',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack, LENGTH_LONG).show();
    this.activeMultipleMessages.forEach(message => {
      this.sentMessagesService.putSentMessage(message.data,
        'Forward',
        recipients).subscribe(
  
        () => {
          // On complete
          snack.label = 'Invoice sent.';
          this.snackbarService.snackbarComponent.dismiss();
          this.snackbarService.snackbarComponent.make(snack).show();
        }
      );
    });
    this.activeMultipleMessages = [];
    this.mainSentItemData.forEach(m => {
      m.state.isSelected = false;
    });
    this.loadingService.hideOverlay();
  } // end onForwardMultipleInvoiceMessage()

  onResendMultipleInvoices() {
    const snack: Snack = {
      label: 'Resending invoice...',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack, LENGTH_LONG).show();
    this.activeMultipleMessages = this.mainSentItemData.filter(x => x.state.isSelected === true);
    // Log activity Login
    // const currentDate = new Date();
    // this.activityLog.Action = 'Resending invoice';
    // this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    // this.activityLog.LoggedApp = 'Web Application (Advocate-messages)';
    // if (this.userProfileService.userProfile.isImpersonator) {
    //   this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
    //   this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    // } else {
    //   this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
    //   this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    // }
    // this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    // this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    // this.activityLog.ActionTable = 'Invoice';
    // this.activityLog.JsonData = JSON.stringify(message.data);
    // this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});
  this.activeMultipleMessages.forEach(message => {
    console.log('SELECTED MESSAGES: ', message);
    this.sentMessagesService.putSentMessage(message.data,
      'Resend Invoice'
      , message.data.email).subscribe({
        next: (activeMessage) => {
          this.activeMessage = activeMessage;
        },
        error: (error) => {
          // On error
          const msg = 'Resend invoice error.';
          console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarService.dismiss().make(snack).show();
        },
        complete: () => {
          // On complete
          snack.label = 'Invoice sent.';
          this.snackbarService.dismiss().make(snack).show();
        }
      });
    });
    this.activeMultipleMessages = [];
    this.mainSentItemData.forEach(m => {
      m.state.isSelected = false;
    });
  } // end onResendMultipleInvoices()

  onForwardMessage(recipients: string) {
    console.log('onForwardMessage: ', this.activeMultipleMessages);
    // TODO: Fix fee template modal clearing bug
    const snack: Snack = {
      label: 'Forwarding invoice...',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack, LENGTH_LONG).show();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Forward invoice';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-messages)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Invoice';
    this.activityLog.JsonData = JSON.stringify(this.activeMessage.data);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_activity) => { } });

    this.sentMessagesService.putSentMessage(this.activeMessage.data,
      'Forward',
      recipients).subscribe(

        () => {
          // On complete
          snack.label = 'Invoice sent.';
          this.snackbarService.snackbarComponent.dismiss();
          this.snackbarService.snackbarComponent.make(snack).show();
        }
      );
  } // end onForwardMessage()

  onForwardInvoice(message: MessageHandle) {
    this.activeMessage = message;
    this.showRecipientsModal = true;
  } // end onForwardInvoice()

  onForwardMultipleInvoices() {

    this.activeMultipleMessages = this.mainSentItemData.filter(x => x.state.isSelected === true);
    this.onShowMultipleSentMessagesPopup = false;
    this.forwardMultipleInvoices = true;
  }

  onResendInvoice(message: MessageHandle) {
    const snack: Snack = {
      label: 'Resending invoice...',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack, LENGTH_LONG).show();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Resending invoice';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-messages)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Invoice';
    this.activityLog.JsonData = JSON.stringify(message.data);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_activity) => { } });

    this.sentMessagesService.putSentMessage(message.data,
      'Resend Invoice'
      , message.data.email).subscribe({
        next: (activeMessage) => {
          this.activeMessage = activeMessage;
        },
        error: (error) => {
          // On error
          const msg = 'Resend invoice error.';
          console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarService.dismiss().make(snack).show();
        },
        complete: () => {
          // On complete
          snack.label = 'Invoice sent.';
          this.snackbarService.dismiss().make(snack).show();
        }
      });
  } // end onResendInvoice()

  onEditInvoice(message: MessageHandle) {
    let messageResponse = '';
    const snack: Snack = {
      label: 'Attempting to revise matter...',
      action: null
    };
    this.snackbarService.make(snack, LENGTH_LONG).show();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Revise invoice';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-messages)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Invoice';
    this.activityLog.JsonData = JSON.stringify(message.data);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_activity) => { } });

    this.sentMessagesService.putSentMessage(message.data,
      'Edit Matter',
      'NULL').subscribe({
        next: (activeMessage) => {
          messageResponse = activeMessage;
        },
        error: (error) => {
          // On error
          const msg = 'Error updating invoice.';
          console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarService.dismiss().make(snack).show();
        },
        complete: () => {
          // On complete
          // console.log('MESSAGES SCREEN: ', messageResponse);
          if (messageResponse === 'Take On Data') {
            localStorage.setItem('selectedMatterTab', 'take-on-data');
          } else {
            localStorage.setItem('selectedMatterTab', 'current-matters');
          }
          this.router.navigate([PATH_MATTERS, message.data.serviceId]); // Open the matter on Matter screen.
          snack.label = 'Matter revised.';
          this.snackbarService.dismiss().make(snack).show();
        }
      });
  } // end onEditInvoice()

  onContinueWithMatter(message: MessageHandle) {
    const snack: Snack = {
      label: 'Attempting continue with matter...',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack, LENGTH_LONG).show();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Continue with matter';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-messages)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Invoice';
    this.activityLog.JsonData = JSON.stringify(message.data);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_actity) => { } });

    this.sentMessagesService.putSentMessage(message.data,
      'Continue with Matter',
      'NULL').subscribe({
        next:
          (selectedmessage) => {
            this.activeMessage = selectedmessage;
            this.selectedServiceID = selectedmessage;

            snack.label = selectedmessage.responseMessage;
            this.snackbarService.dismiss().make(snack, 6000).show();
          },
        error: (error) => {
          // On error
          const msg = 'Could not continue with matter. You already have a matter in current matters with the same details';
          console.log('ERROR: ', msg);
          console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarService.dismiss().make(snack).show();
        },
        complete: () => {
          // On complete
          this.router.navigate([PATH_MATTERS, this.selectedServiceID]); // Open the matter on Matter screen.
          snack.label = 'Matter continued.';
          this.snackbarService.dismiss().make(snack).show();
        }
      });
  } // end onContinueWithMatter()

  toggleAscending() {
    this.sortAscending = !this.sortAscending;
    this.orderMessages();
  } // end toggleAscending()

  onPageClick(event) {
    if (event.target.className) {
      if (!event.target.classList.contains('fa-angle-down') &&
        this.activeMessageHandle) {
        // Close the open menu
        this.onDismissMenu(this.activeMessageHandle);
      }
    } else {
      if (this.activeMessageHandle) {
        this.onDismissMenu(this.activeMessageHandle);
      }
    }
  } // end onPageClick()

  onViewBriefDocuments(messagesHandle: MessageHandle) {
    if (messagesHandle.data.otherDocuments) {
      this.briefDocumentHandle = [];
      // console.log('SELECTED MESSAGE: ', messagesHandle);
      this.loadingService.showOverlay();
      if (messagesHandle && messagesHandle.data.serviceId) {
        this.mattersService.getDocumentList(messagesHandle.data.serviceId).subscribe({
          next: (_docs) => {
            this.briefDocuments = _docs;
            this.briefDocuments.forEach(doc => {
              this.briefDocumentHandle.push({
                data: doc,
                state: {
                  isVisible: doc.Status,
                  isSelected: false,
                  isOpen: false
                }
              });
            });
            this.showBriefDocumentModal = true;
          }
        });
      }
      this.loadingService.hideOverlay();
    }
  } // end onViewBriefDocuments()

  onShowInvoiceNoteDetail(message: SentMessage) {
    this.loadingService.showOverlay();
    let _note = [];
    if (this.isPracticeManager) {
      this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
        message.sourceId, 4).subscribe({
          next: (_nn) => {
            _note = _nn;
          }
        });
    } else if (!this.isPracticeManager) {
      this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID,
        message.sourceId, 4).subscribe({
          next: (_nn) => {
            _note = _nn;
          }
        });
    }
    this.notes = _note;
    // this.notesService.getNotes(message.sourceId, 4).subscribe(
    //   invoiceNotes => {
    //     // On next
    //   },
    //   error => {
    //     // On error
    //     this.loadingService.hideOverlay();
    //   },
    //   () => {
    // On complete
    this.showInvoiceNoteDetail = true;
    this.loadingService.hideOverlay();
    // });
  } // end onShowInvoiceNoteDetail()

  getUnReadMessageFont(value: boolean): string {
    if (value === false) {
      return 'Bold';
    }
  } // #end getUnReadMessageFont()

  onRowClickExpand(notes: NotesObjectHandle) {
    this.selectedResponseText = '';
    this.selectedResponse = Object.assign({}, DEFAULT_INSTRUCTION_RESPONSES);
    this.notesHandle.forEach(s => {
      if (s.state.isOpen && notes !== s) {
        s.state.isOpen = false;
      }
    });
    if (notes.data.ObjectTypeDescription === 'Multiple Invoices') {
      this.getMultiObjectNotes(notes.data.UserAssigned, notes.data.MutliObjects);
    }
    notes.state.isOpen = !notes.state.isOpen;
    this.openedNote = notes;
    this.instructionResponses = [];
    this.loadingService.showOverlay();
    this.selectedNote = Object.assign({}, DEFAULT_NOTES_OBJECT);
    this.selectedNote = notes.data;
    // console.log('SELECTED NOTE: ', data);
    if (notes.data.UserAssignedStatus === false) {
      if (notes.data.ObjectTypeDescription === 'Multiple Invoices') {
        //  this.getMultiObjectNotes(notes.data.UserAssigned, notes.data.MutliObjects);
        if (this.multipleObjectNotes) {
          this.multipleObjectNotes.forEach(_data => {
            _data.UserAssignedStatus = true;
            this.notesService.putNoteInboxStatus(_data).subscribe({ next: (_next) => { } });
          });
        }
      } else if (notes.data.ObjectName === 'New Invoice') {
        const clientMessageStatus = Object.assign({}, DEFAULT_MESSAGES_INBOX_STATUS);
        clientMessageStatus.ClientStatus = true;
        clientMessageStatus.Id = notes.data.Priority;
        clientMessageStatus.MessageId = notes.data.Id;
        clientMessageStatus.ServiceProviderStatus = true;
        this.notesService.putClientMessagesInboxStatus(clientMessageStatus).subscribe({ next: (_next) => { } });
        notes.data.UserAssignedStatus = true;
      } else {
        notes.data.UserAssignedStatus = true;
        this.notesService.putNoteInboxStatus(notes.data).subscribe({ next: (_next) => { } });
        this.notes.forEach(_note => {
          if (_note === notes.data) {
            _note.UserAssignedStatus = true;
          }
        });
      }
      notes.data.UserAssignedStatus = true;
    }
    // console.log('SELECTED NOTE TO CHECK: ', this.selectedNote);
    if (this.selectedNote.InstructionResponseId === 0) {
      this.notesService.getInstructionResponses(this.selectedNote.QuestionId).subscribe({
        next:
          (instruction) => {
            // On next
            this.instructionResponses = instruction;
          },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
        }
      });
    } else {
      //  this.getMultiObjectNotes(notes.data.UserAssigned, notes.data.MutliObjects);
      this.loadingService.hideOverlay();
    }
    console.log('selected note: ', this.openedNote);
  } // end onRowClickExpand()

  onInstructionSave() {
    this.loadingService.showOverlay();
    if (this.selectedNote && this.selectedNote.ObjectTypeDescription !== 'Multiple Invoices') {
      const noteToSave: Notes = {
        Id: this.selectedNote.Id,
        ObjectId: this.selectedNote.ObjectId,
        Subject: this.selectedNote.Subject,
        NoteTypeId: this.selectedNote.NoteTypeId,
        InternalText: this.selectedNote.InternalText,
        ExternalText: this.selectedNote.ExternalText,
        UserOwner: this.selectedNote.UserOwner,
        UserCreator: this.selectedNote.UserCreator,
        UserAssigned: this.selectedNote.UserAssigned,
        Priority: this.selectedNote.Priority,
        CaptureDate: this.selectedNote.CaptureDate,
        ActionDate: this.selectedNote.ActionDate,
        ActivityDate: this.selectedNote.ActivityDate,
        InstructionResponseId: this.selectedResponse.Id,
        InstructionText: this.instructionText,
        ResolutionText: '',
        InternalInstructionNote: this.selectedNote.InternalInstructionNote,
        MutliObjects: this.selectedNote.MutliObjects,
        InstructionCaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        ResolutionCaptureDate: null,
        IsVisibleUserOwner: false,
        IsVisibleUserCreator: false,
        IsVisibleUserAssigned: true
      };
      this.selectedNote.UserOwnerStatus = false;
      this.selectedNote.UserOwnerStatus = false;
      this.notesService.putNoteInboxStatus(this.selectedNote).subscribe({ next: (_next) => { } });
      this.notesService.putNote(noteToSave).subscribe({
        next: (updatedNote) => {
          // On next
          // console.log('Updated note: ', updatedNote);
        },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.getAllInboxNotes();
          this.instructionText = '';
          this.selectedResponseId = 0;
          this.loadingService.hideOverlay();
        }
      });
    } else if (this.selectedNote && this.selectedNote.ObjectTypeDescription === 'Multiple Invoices') {
      // if (this.multipleObjectNotes) {
      console.log('MULTI OBJECT NOTE: ', this.multipleObjectNotes);
      this.multipleObjectNotes.forEach(_data => {
        this.selectedNote.Id = _data.Id;
        this.selectedNote.ObjectId = _data.ObjectId;
        this.selectedNote.Subject = _data.Subject;
        this.selectedNote.NoteTypeId = _data.NoteTypeId;
        this.selectedNote.UserOwnerStatus = false;
        this.selectedNote.UserAssignedStatus = true;
        this.selectedNote.UserCreatorStatus = false;

        const noteToSave: Notes = {
          Id: _data.Id,
          ObjectId: _data.ObjectId,
          Subject: _data.Subject,
          NoteTypeId: _data.NoteTypeId,
          InternalText: this.selectedNote.InternalText,
          ExternalText: this.selectedNote.ExternalText,
          UserOwner: this.selectedNote.UserOwner,
          UserCreator: this.selectedNote.UserCreator,
          UserAssigned: this.selectedNote.UserAssigned,
          Priority: this.selectedNote.Priority,
          CaptureDate: this.selectedNote.CaptureDate,
          ActionDate: this.selectedNote.ActionDate,
          ActivityDate: this.selectedNote.ActivityDate,
          InstructionResponseId: this.selectedResponse.Id,
          InstructionText: this.instructionText,
          ResolutionText: '',
          InternalInstructionNote: this.selectedNote.InternalInstructionNote,
          MutliObjects: _data.MutliObjects,
          InstructionCaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          ResolutionCaptureDate: null,
          IsVisibleUserOwner: false,
          IsVisibleUserCreator: false,
          IsVisibleUserAssigned: true
        };
        console.log('Status update: ', this.selectedNote);
        console.log('Note to be saved: ', noteToSave);
        this.notesService.putNoteInboxStatus(this.selectedNote).subscribe({ next: (_next) => { } });
        this.notesService.putNote(noteToSave).subscribe({ next: (_next) => { } });
      });
      this.getAllInboxNotes();
      this.instructionText = '';
      this.selectedResponseId = 0;
      this.loadingService.hideOverlay();
      // }
    }
  } // end onInstructionSave()

  onSaveMultiInvoice() {
    this.getAllInboxNotes();
    this.showClientMultiInvoiceNote = false;
  } // end onSaveMultiInvoice()

  onNoteResponseSelect() {
    if (this.selectedResponseId) {
      this.selectedResponse = Object.assign({}, DEFAULT_INSTRUCTION_RESPONSES);
      this.selectedResponse = this.instructionResponses.filter(x => x.Id === Number(this.selectedResponseId))[0];
    }
    // console.log('SELECTED: ', this.selectedResponseId, this.selectedResponse);
  } // end onNoteResponseSelect()

  onInvoicePreview(data: NotesObject) {
    this.loadingService.showOverlay();
    this.selectedTab = 'invoice';
    const invoiceId: number = data.ObjectId;
    this.selectedNotes = [];
    this.selectedNotesHandle = [];
    if (invoiceId && data.ObjectTypeDescription !== 'Multiple Invoices') {
      let _note = [];
      if (this.isPracticeManager) {
        this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
          invoiceId, 4).subscribe({
            next: (_next) => {
              _note = _next;
            }
          });
      } else if (!this.isPracticeManager) {
        this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID,
          invoiceId, 4).subscribe({
            next: (_next) => {
              _note = _next;
            }
          });
      }
      if (!this.isPracticeManager) {
        this.selectedNotes = _note.filter(x => x.QuestionText || x.ExternalText);
      } else {
        this.selectedNotes = _note;
      }
      this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
      // this.showProformaInvoice = true;
      this.invoiceDetailService.getInvoiceDetails(invoiceId).subscribe({
        next: (_invoice_detail) => {
          this.invoiceDetails = _invoice_detail;
        }
      });
      this.getAllQuotedFees(this.invoiceDetails.ServiceId);
      this.selectedInvoiceId = invoiceId;
      if (this.invoiceDetails) {
        const desc = this.invoiceDetails.InvoiceParties.split(' // ');
        if (desc.length > 0) {
          this.invoicePlaintiff = desc[0];
        }
        if (desc.length > 1) {
          this.invoiceDefendant = desc[1];
        }
      }

      this.selectedNotes.forEach(_notes => {
        this.selectedNotesHandle.push({
          data: _notes,
          state: {
            isVisible: true,
            isSelected: false,
            isOpen: false
          }
        });
      });
      this.showProformaInvoice = true;
    } else if (invoiceId && data.ObjectTypeDescription === 'Multiple Invoices') {
      this.multiInvoiceNote = [];
      this.notesService.getNotesObjects(data.MutliObjects).subscribe({
        next: (_next) => {
          const _multiObject = _next;
          this.multiInvoiceNote = _multiObject.filter(x => x.AdvocateStatus === 'Active');
        }
      });
      this.selectedNote = data;
      // console.log('SELECTED DATA: ', this.selectedNote);
      this.objectType = 'Multiple Invoices';
      this.showClientMultiInvoiceNote = true;
    }
    this.loadingService.hideOverlay();
  } // end onInvoicePreview()

  onNoteDetailView(data: NotesObject) {
    // this.loadingService.showOverlay();
    this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
    this.noteDetailData = data;
    this.onLawfirmSummary(data.ObjectId);
    this.objectType = 'contact';
    this.onShowNoteDetail = true;
    this.loadingService.hideOverlay();
  } // end onNoteDetailView()

  onLawfirmSummary(lawfirm: number) {
    this.loadingService.showOverlay();
    this.lawfirmSummaryData = Object.assign({}, DEFAUL_LAWFIRM_SUMMARY_OVERVIEW);
    this.reportsService.getLawfirmSummaryOverview(lawfirm).subscribe({
      next: (_next) => {
        this.lawfirmSummaryData = _next;
      }
    });
    this.loadingService.hideOverlay();
  } // end onLawfirmSummary()

  getMultiObjectNotes(advocateId: number, multiObjectId: number) {
    this.multipleObjectNotes = [];
    this.notesService.getMultipleObjectNotes(advocateId, multiObjectId).subscribe({
      next: (_next) => {
        this.multipleObjectNotes = _next;
      }
    });
  } // end getMultiObjectNotes()

  onShowNoteDetailModalDismiss() {
    this.onShowNoteDetail = false;
  } // end onShowNoteDetailModalDismiss()

  closeAdvocateNoteDetail() {
    this.showAdvocateNoteDetail = false;
  } // end closeAdvocateNoteDetail()

  closeClientMultiInvoiceNote() {
    this.showClientMultiInvoiceNote = false;
  } // end closeClientMultiInvoiceNote()

  // getClientMessageStatus(profileType: string) {
  //   this.clientInboxMessages = [];
  //   this.clientInboxMessages =  this.sentMessagesService.getClientMessageInbox(profileType).toPromise();
  // } // end getClientMessageStatus()

  getAllQuotedFees(serviceId: number) {
    this.feesQuoted = [];
    this.quotationService.getActualFeesQuoted(serviceId).subscribe({
      next: (_next) => {
        this.feesQuoted = _next;
      }
    });
  } // end getAllQuotedFees()
} // end AdvocateMessagesComponent{}
