<!-- <input
  type="text"
  placeholder="Select or start typing"
  (blur)="onBlur()"
  (focus)="onFocus($event)"
  (input)="onInput($event)"
  [(ngModel)]="displayValue" required
  [title]="displayValue"
  (keyup)="validate()"
  maxlength="750">#end input -->
  <div class="badge">
    <input
    class="form-control"
    [class.has-error]="isValidText"
    class="categotyText"
    type="text"
    placeholder="Select or start typing"
    (blur)="onBlur()"
    (focus)="onFocus()"
    (input)="onInput($event)"
    [(ngModel)]="displayValue"
    maxlength="3000"
    [title]="displayValue"
    (keyup)="validate()"
    (keyup.backspace)="onKeydown($event)">
    <!--#end input -->
    {{numberOfCharactersLeft}}
    <i hidden class="fa fa-angle-down fa-lg input-arrow" 
    (click)="toggleDropdown()"></i>
    <!--#end .fa -->
  </div>
<div
  class="datalist"
  [class.show]="isActive"
  *ngIf="type === types.category"><!--# .datalist -->
  <div
    *ngFor="let item of categoryList"
    class="datalist-item"
    [class.hide]="!item.state.isVisible"
    [class.selected]="item.state.isSelected"
    (click)="onSelect(item)">
    <span class="list-item-icon">
      <i [class]="iconClass"></i><!--# i -->
    </span>
    {{item.data.Category}}
  </div><!--#end .datalist-item -->
</div><!--#end .datalist -->
<div
  class="datalist"
  [class.show]="isActive"
  *ngIf="type === types.subCategory">
  <div
    class="datalist-item"
    (click)="createNewFeeTemplate()">
    <span class="list-item-icon">
      <i [class]="iconClass"></i>
    </span><em>Create new fee template</em>
  </div><!--#end .datalist-item -->
  <div
    *ngFor="let item of categoryList"
    class="datalist-item"
    [class.hide]="!item.state.isVisible"
    [class.selected]="item.state.isSelected"
    (click)="onSelect(item)">
    <span class="list-item-icon">
      <i [class]="iconClass"></i>
    </span>
    {{item.data.Category}} - {{item.data.SubCategory}} - {{item.data.RateType}}
  </div><!--#end .datalist-item -->
</div><!--#end .datalist -->

<app-edit-fee-template-modal
  [isVisible]="showFeeTemplateModal"
  [feeTemplate]="activeFeeTemplate"
  (closeModal)="onCloseModal()"
  (saveFeeTemplate)="onSaveFeeTemplate($event)"></app-edit-fee-template-modal>