<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-header">
                <h2>{{title}}</h2>
                <button
                    class="modal-dismiss"
                    (click)="onDismiss()">
                    <i class="fa fa-close"></i>
                </button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="form-group">
                        <label for="advocate-name">Secretary Name <span title="required field"> *</span> </label>
                        <input
                            type="text"
                            class="form-control"
                            [(ngModel)]="workingSecretary.FullName"
                            placeholder="Enter Secretary name"
                            (click)="$event.target.select()"
                            autocomplete="nocxua">
                    </div>                    
                    <div class="form-group">
                        <label for="phone">Phone<span title="required field"> *</span></label>
                        <input
                            type="text"
                            class="form-control"
                            (blur)="onPhoneNumberBlur()"
                            [(ngModel)]="workingSecretary.Phone"
                            placeholder="Enter Phone number"
                            (click)="$event.target.select()"
                            autocomplete="nocxua">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group">
                        <label for="email">Email<span title="required field"> *</span></label>
                        <input
                            type="text"
                            class="form-control"
                            [class.has.error]="hasEmail"
                            id="email"
                            [(ngModel)]="workingSecretary.Email"
                            placeholder="Enter Email address"
                            (click)="$event.target.select()"
                            autocomplete="nocxua">
                    </div>
                    <div class="form-group">
                        <label for="cell">Cell</label>
                        <input
                            type="text"
                            class="form-control"
                            id="cell"
                            [(ngModel)]="workingSecretary.Cell"
                            placeholder="Enter Cell number"
                            (click)="$event.target.select()"
                            autocomplete="nocxua">
                    </div>
                </div>

            </div>

            <div class="modal-footer">
                <button
                class="btn btn-danger"
                (click)="onDismiss()">Cancel</button>

                <button
                    *ngIf="this.workingSecretary && this.workingSecretary.Id === 0"
                    class="btn btn-success"
                    (click)="save()">Save</button>

                <button
                    *ngIf="this.workingSecretary && this.workingSecretary.Id"
                    [class.disabled]="!canActivateSecretary"
                    [disabled]="!canActivateSecretary"
                    class="btn btn-info"
                    (click)="onActivateUser()">Activate User</button>
                
                <button
                    *ngIf="this.workingSecretary && this.workingSecretary.Id"
                    class="btn btn-success"
                    (click)="update()">Update</button>
            </div>
        </div>
    </div>
</div>