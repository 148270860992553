import {
  Component,
  OnInit
} from '@angular/core';
import {
  SearchService,
  SearchPayload
} from '../../services/search/search.service';
import { ActivatedRoute } from '@angular/router';
import {
  SnackbarsService,
  SnackType
} from '../../services/messaging/snackbars/snackbars.service';

export interface ResultItem {
  Category: string;
  SearchScore: number;
  Results: any;
} // end ResultItem{}

export interface Filter {
  lawFirms: boolean;
  matters: boolean;
  invoices: boolean;
} // end Filter{}

@Component({
  selector: 'app-advocate-search',
  templateUrl: './advocate-search.component.html',
  styleUrls: ['./advocate-search.component.scss']
})
export class AdvocateSearchComponent implements OnInit {

  searchTerm: string;
  filters: Filter = {
    lawFirms: true,
    matters: true,
    invoices: true,
  };
  searchResults: ResultItem[] = [];
  filteredSearchResults: ResultItem[] = [];
  sortOptions: string[] = [
    'relevance',
    'type',
    'description'
    // add more by simply extending array
  ];
  sortBy: string = 'relevance';
  sortAscending: boolean = false;
  isLoading: boolean = false;

  get totalMatters(): number {
    if (!this.searchResults.length) {
      return 0;
    }

    let total = 0;
    this.searchResults.forEach(item => {
      if (item.Category === 'Service') {
        total++;
      }
    });

    return total;
  } // end totalMatters()

  get totalLawfirms(): number {
    if (!this.searchResults.length) {
      return 0;
    }

    let total = 0;
    this.searchResults.forEach(item  => {
      if (item.Category === 'Law Firm') {
        total++;
      }
    });

    return total;
  } // end totalLawfirms()

  get totalInvoices(): number {
    if (!this.searchResults.length) {
      return 0;
    }

    let total = 0;
    this.searchResults.forEach(item => {
      if (item.Category === 'Invoice') {
        total++;
      }
    });

    return total;
  } // end totalInvoices()

  private checkboxCheckHandle: number;

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private snackbarsService: SnackbarsService
  ) {
    document.title = route.snapshot.data.title;
  } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  onKeyDown(event) {
    switch (event.key) {
      case 'Enter':
        this.onSearch();
        break;
    }
  } // end onKeyDown()

  onCheckboxClick() {
    if (this.checkboxCheckHandle) {
      clearTimeout(this.checkboxCheckHandle);
    }

    // Delay handling the click a little to allow for the
    // click to be registered.
    setTimeout(_ => {
      this.filter();
      this.sort();
    }, 100);
  } // end onCheckboxClick()

  toggleChecbox(type: string) {
    switch(type) {
      case 'matters':
        this.filters.matters = !this.filters.matters;
        break;

      case 'lawFirms':
        this.filters.lawFirms = !this.filters.lawFirms;
        break;

      case 'invoices':
        this.filters.invoices = !this.filters.invoices;
        break;
    }
    this.onCheckboxClick();
  } // end toggleChecbox()

  toggleSortDirection() {
    this.sortAscending = !this.sortAscending;
    this.sort();
  } // end toggleSortDirection()

  clearSearch() {
    this.filteredSearchResults = [];
  } // end clearSearch()

  filter(event = null) {
    if (!this.searchResults) {
      return;
    }

    // this.filteredSearchResults = this.searchResults;
    this.filteredSearchResults = [];
    this.searchResults.forEach(item => {
      if (this.filters.matters && item.Category === 'Service') {
        this.filteredSearchResults.push(item);
      } else if (this.filters.lawFirms && item.Category === 'Law Firm') {
        this.filteredSearchResults.push(item);
      } else if (this.filters.invoices && item.Category === 'Invoice') {
        this.filteredSearchResults.push(item);
      }
    });
  } // end filter()

  sort() {
    if (!this.filteredSearchResults) {
      return;
    }

    switch (this.sortBy) {
      case 'relevance':
        this.filteredSearchResults = this.sortAscending
          ? this.filteredSearchResults.sort((a, b) => {
            return a.SearchScore - b.SearchScore;
          })
          : this.filteredSearchResults.sort((a, b) => {
            return b.SearchScore - a.SearchScore;
          });
        break;

      case 'type':
        this.filteredSearchResults = this.sortAscending
        ? this.filteredSearchResults.sort((a, b) => {
          return a.Category.localeCompare(b.Category);
        })
        : this.filteredSearchResults.sort((a, b) => {
          return b.Category.localeCompare(a.Category);
        });
        break;

      case 'description':
        break;

      case 'created':
        break;
    }
  } // end sort()

  onSearch() {
    const payload: SearchPayload = {
      includeFacets: false,
      orderBy: '',
      page: 1,
      pageSize: 40,
      queryType: 'simple',
      searchMode: 'any',
      //text: '/.*' + this.searchTerm + '.*/'
      //text: this.searchTerm + '*'
      text: this.searchTerm 
    };

    // Fetch results
    if (this.searchTerm) {
      this.isLoading = true;
      this.searchService.search(payload).subscribe({ next: 
        (results) => {
          this.searchResults = results;
        },
        error: (error) => {
          // Something went wrong
          const msg = 'Search request error!';
          console.error(msg, error);
          this.snackbarsService.dismiss().make({
            label: msg,
            action: null,
            type: SnackType.ERROR
          }).show();
        },
        complete: () => {
          // On complete
          this.isLoading = false;
          this.filter();
          this.sort();
        }
      });
    } else {
      this.searchResults = [];
    }
  } // end onSearch()
} // end AdvocateSearchComponent{}
