<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body">
        <a>
        <img src={{images}} alt="'Sample'">
        </a>
      </div> <!-- #end .modal-body -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->
