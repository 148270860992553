import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdvocateDetailStatement } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import {
  AddLawfirmAttorneyService,
  PaymentAllocationRequest,
  DEFAULT_ALLOCATION_REQUEST } from '../../../services/addlawfirm/add-lawfirm-attorney.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import * as moment from 'moment';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

@Component({
  selector: 'app-unallocated-transaction',
  templateUrl: './unallocated-transaction.component.html',
  styleUrls: ['./unallocated-transaction.component.scss']
})
export class UnallocatedTransactionComponent implements OnInit {
  title = 'Unallocated Transaction';

  @Input() SelectedTransaction: AdvocateDetailStatement;
  @Input() isVisible: boolean;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirm: EventEmitter<any> = new EventEmitter<any>();

  allocateData: PaymentAllocationRequest = Object.assign({}, DEFAULT_ALLOCATION_REQUEST);
  paymentResolutionText: string;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private loadingService: LoadingService,
    private cpipe: UsercurrencyPipe,
    private userProfileService: UserProfileService,
    private addLawfirmService: AddLawfirmAttorneyService,
  ) { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  onDismiss() {
    this.paymentResolutionText = '';
    this.allocateData = Object.assign({}, DEFAULT_ALLOCATION_REQUEST);
    this.cancel.emit();
  } // end onDismiss()

  onConfirm() {
    this.loadingService.showOverlay();
    this.allocateData.AdvocateId = this.SelectedTransaction.ServiceProviderId;
    this.allocateData.AdvocateName = this.SelectedTransaction.ProviderFullName;
    this.allocateData.PaymentAmount = this.TransformText(this.SelectedTransaction.Paid.toString());
    this.allocateData.TransactionAmount = this.TransformText(this.SelectedTransaction.Total.toString());
    this.allocateData.PaymentDate = moment(this.SelectedTransaction.InvoiceDate).format('YYYY-MM-DD');
    this.allocateData.PaymentDescription = this.SelectedTransaction.ServiceDescription;
    this.allocateData.PaymentResolution = this.paymentResolutionText;
    if (this.userProfileService.userProfile.isImpersonator) {
      this.allocateData.LawfirmId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.allocateData.LawfirmName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.allocateData.LawfirmId = this.userProfileService.userProfile.serviceProviderID;
      this.allocateData.LawfirmName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    //  this.addLawfirmService.postPaymentResolution(this.allocateData).toPromise();
    this.confirm.emit(this.allocateData);

    this.paymentResolutionText = '';
    this.allocateData = Object.assign({}, DEFAULT_ALLOCATION_REQUEST);
    this.loadingService.hideOverlay();
  } // end onConfirm()

  TransformText(amount: string): string {
    let value = '';
    if (this.userProfileService.userProfile.isImpersonator) {
      value = this.cpipe.transform(amount,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      value = this.cpipe.transform(amount,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
    return value;
  } // end TransformText()

} // end UnallocatedTransactionComponent{}
