<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">

<!-- #start search -->
<div class="row contact-search input-group">
    <input type="search" #inputCheckbox class="form-control" placeholder="Search for Advocate" [(ngModel)]="searchTerm"
        (keyup)="performSearch()">
    <div class="input-group-append">
        <i *ngIf="!searchTerm" class="far fa-search"></i>
        <i *ngIf="searchTerm" (click)="clearSearch()" class="far fa-close"></i>
    </div>
</div> <!-- #end .row reconcile-search input-group -->

<div class="row m-0">
    <div class="table-responsive" style="max-height: 400px; overflow-y: auto;">
        <table class="table table-striped">
            <tbody>
                <tr>
                    <th scope="col" (click)="toggleSort('advocate-name')">Advocate Name
                        <span><i class="fa fa-sort" [class.fa.sort-down]="sortAdvocateName"></i></span>
                    </th>
                    <th scope="col" (click)="toggleSort('user-name')">User Name
                        <span><i class="fa fa-sort" [class.fa.sort-down]="sortAdvocateUserName"></i></span>
                    </th>
                    <th scope="col" (click)="toggleSort('cell')">Cell
                        <span><i class="fa fa-sort" [class.fa.sort-down]="sortCell"></i></span>
                    </th>
                    <th scope="col" (click)="toggleSort('email')">Email
                        <span><i class="fa fa-sort" [class.fa.sort-down]="sortEmail"></i></span>
                    </th>
                    <th scope="col" (click)="toggleSort('bar-society')">Bar Society
                        <span><i class="fa fa-sort" [class.fa.sort-down]="sortBar"></i></span>
                    </th>
                    <th scope="col" (click)="toggleSort('admission-date')">Admission Date
                        <span><i class="fa fa-sort" [class.fa.sort-down]="sortAdmissionDate"></i></span>
                    </th>
                    <!-- <th scope="col" (click)="toggleSort('postal-address')">Postal Address
                        <span><i class="fa fa-sort" [class.fa.sort-down]="sortPostalAddress"></i></span>
                    </th> -->
                    <th scope="col" (click)="toggleSort('street-address')">Street Address
                        <span><i class="fa fa-sort" [class.fa.sort-down]="sortStreetAddress"></i></span>
                    </th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
                <tr class="tr" *ngFor="let advocate of advocatesHandle" [class.active]="row == advocate.data.Id">
                    <td *ngIf="advocate.state.isVisible" style="width: 20%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.FullName }}</td>
                    <td *ngIf="advocate.state.isVisible" style="width: 10%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.UserName }}</td>
                    <td *ngIf="advocate.state.isVisible" style="width: 10%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.Cell }}</td>
                    <td *ngIf="advocate.state.isVisible" style="width: 10%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.Email }}</td>
                    <td *ngIf="advocate.state.isVisible" style="width: 10%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.RegionalBarName }}</td>
                    <td *ngIf="advocate.state.isVisible" style="width: 10%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.AdmissionYear && advocate.data.AdmissionMonth ? advocate.data.AdmissionYear + '-' + advocate.data.AdmissionMonth : '' }}</td>
                    <!-- <td *ngIf="advocate.state.isVisible" style="width: 10%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.PostalAddress }}</td> -->
                    <td *ngIf="advocate.state.isVisible" style="width: 16%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.StreetAddress }}</td>
                    <td *ngIf="advocate.state.isVisible" style="width: 2%;"><i class="fal fa-eye"
                            style="color: #8ABD37;"></i></td>
                    <td *ngIf="advocate.state.isVisible" (click)="onEditAdvocate(advocate.data)" class="clickable"
                        style="width: 2%;"><i class="fal fa-edit" style="color: #E5C100;"></i></td>
                    <td *ngIf="advocate.state.isVisible && advocate.data.RegionalBarName == null"
                        style="width: 2%; border: none;"></td>
                    <td *ngIf="advocate.state.isVisible && advocate.data.RegionalBarName != '-' " style="width: 2%;"
                        class="clickable" (click)="onDeleteAdvPopPromptShow(advocate.data.Id, advocate.data.FullName, advocate.data.RegionalBarId)">
                        <i class="fa fa-trash" style="color: #cf624f;"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<button class="btn btn-success" (click)="addNewContact()">Add new Contact</button>

<app-add-new-advocate *ngIf="showAddEditAdvocate" [isVisible]="showAddEditAdvocate"
    [selectedAdvocate]="selectedAdvocate" (closeModal)="onCloseModal($event)"
    (onSaveAdvocate)="onSaveAdvocate($event)"></app-add-new-advocate>

<app-prompt-unsaved-changes-modal title="Remove Advocate from Bar Society"
    message="You are about to remove {{AdvocateFullName}} from Bar Society. Do you wish to proceed?"
    [isVisible]="showDeleteAdvPopPrompt" btnTextCancel="Cancel" btnTextContinue="Delete"
    (cancel)="onHideDeleteAdvPopPrompt()" (continue)="onDeleteAdvocate()"></app-prompt-unsaved-changes-modal>