import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { FeeTariffs } from '../../../services/advocate-overview-service/advocate-overview-service.service';

@Component({
  selector: 'app-fee-tariffs',
  templateUrl: './fee-tariffs.component.html',
  styleUrls: ['./fee-tariffs.component.scss']
})
export class FeeTariffsComponent implements OnInit {

  title = 'Average Fee Tariffs';

  @Input() isVisible: boolean;
  @Input() feeTariffs: FeeTariffs[];
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private userProfileService: UserProfileService,
  ) { } // end constructure()

  ngOnInit(): void {
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()
} // end FeeTariffsComponent {}
