<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-header">
                <h2>{{title}}</h2>
            </div>

            <div class="modal-body form">
                <div class="row m-0">
                    <div class="form-group">
                        <label for="question1">Apart from the account did you request payment of these fees either telephonically or in writing:</label>
                        <select
                            class="form-control"
                            id="question1"
                            [(ngModel)]="reportToBarPaymentRequestedQuestion"
                            (change)="onPaymentRequestedQuestio()">
                            <option value="null" selected="selected">Select an option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="question2">Has the Attorney intimated any dispute in respect of these fees?</label>
                        <select
                            class="form-control"
                            id="question2"
                            [(ngModel)]="reportToBarDisputeQuestion"
                            (change)="onDisputeQuestion()">
                            <option value="null" selected="selected">Select an option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button 
                    class="btn btn-danger"                     
                    (click)="onCancel()">Cancel</button>

                <button 
                    class="btn btn-success" 
                    [class.disabled]="!canSave"
                    [disabled]="!canSave"
                    (click)="onSave()">Proceed</button>
            </div>

        </div>
    </div>
</div>