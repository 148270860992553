<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>

<div class="container-fluid">
  <div class="NewUser-container">
    <form>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="auxcon-username"
            >Auxcon User Name
            <span title="Required field" style="color: red"> *</span></label
          >
          <input
            type="text"
            class="form-control"
            id="auxcon-username"
            [(ngModel)]="auxconUserName"
            (blur)="onBlurAuxconUserName()"
            autocomplete="off"
            name="auxconUserName"
          />
          <span *ngIf="isValidAttUserName" class="text-danger"
            >Please enter User name.</span
          >
        </div>
        <div class="form-group col-md-6">
          <label for="job-title"
            >Job Title
            <span title="Required field" style="color: red"> *</span></label
          >
          <input
            type="text"
            class="form-control"
            id="job-title"
            [(ngModel)]="jobTitle"
            (blur)="onBlurJobTitle()"
            autocomplete="off"
            name="jobTitle"
          />
          <span *ngIf="isValidJobTitle" class="text-danger"
            >Please enter a valid Job Title</span
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="firstName"
            >First Name
            <span title="Required field" style="color: red"> *</span></label
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="firstName"
            autocomplete="off"
            id="firstName"
            (blur)="onBlurFirstName()"
            name="firstName"
          />
          <span *ngIf="isValidFirstName" class="text-danger"
            >Please enter a valid First Name</span
          >
        </div>
        <div class="form-group col-md-6">
          <label for="last-name"
            >Last Name
            <span title="Required field" style="color: red"> *</span></label
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="lastName"
            name="lastName"
            (blur)="onBlurLastName()"
            id="lastName"
            autocomplete="off"
          />
          <span *ngIf="isValidLastName" class="text-danger"
            >Please enter a valid Last Name</span
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="emailaddress"
            >Email Address<span title="Required field" style="color: red">
              *</span
            ></label
          >
          <input
            type="email"
            class="form-control"
            name="emailAddress"
            [(ngModel)]="emailAddress"
            autocomplete="off"
            (blur)="onBlurEmail()"
            id="emailaddress"
          />
          <span *ngIf="isValidAttEmail" class="text-danger"
            >Please enter a valid Email Address</span
          >
        </div>
        <div class="form-group col-md-6">
          <label for="mobile-number"
            >Mobile Number<span title="Required field" style="color: red">
              *</span
            ></label
          >
          <input
            type="text"
            name="mobileNumber"
            class="form-control"
            [(ngModel)]="mobileNumber"
            (blur)="onBlurMobileNumber()"
            autocomplete="off"
            id="mobile-number"
          />
          <span *ngIf="isValidMobileNumber" class="text-danger"
            >Please enter a valid MObile NUmber</span
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="serviceProviderID"
            >Service Provider Id<span title="Required field" style="color: red">
              *</span
            ></label
          >
          <input
            type="email"
            class="form-control"
            name="serviceProviderId"
            [(ngModel)]="serviceProviderId"
            (blur)="onBlurServiceProvider()"
            autocomplete="off"
            id="serviceProviderID"
          />
          <span *ngIf="isValidServiceProviderID" class="text-danger"
            >Please enter a valid Service Provider Id</span
          >
        </div>
        <div class="form-group col-md-6">
          <label for="radio"
            >Add free Templates
            <span title="Required field" style="color: red"> *</span></label
          >
          <div class="switch-field">
            <input
              type="radio"
              id="radio-one"
              name="switch-one"
              [(ngModel)]="addFeeTemplatesInstring"
              value="true"
              checked
            />
            <label for="radio-one">Yes</label>
            <input
              type="radio"
              id="radio-two"
              name="switch-one"
              [(ngModel)]="addFeeTemplatesInstring"
              value="false"
            />
            <label for="radio-two">No</label>
          </div>

          <!-- <div class="form-check">
            <input
              type="radio"
              class="form-check-input"
              id="radio1"
              [(ngModel)]="addFeeTemplates"
              name="optradio"
            />
            <label class="form-check-label" for="radio1">Yes</label>
          </div> -->
        </div>
      </div>

      <div class="form-group">
        <button
          class="btn btn-success btn-lg float-right"
          type="submit"
          (click)="AddNewUser()"
        >
          Submit
        </button>
        <button
          class="btn btn-danger btn-lg float-right"
          type="cancel"
          style="margin-right: 15px"
          (click)="cancelNewUser()"
        >
          Cancel
        </button>
      </div>

      <!-- <div class="form-row">
        <div class="form-group col-md-6">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
        <div class="form-group col-md-6">
           <button type="cancel" class="btn btn-primary">Cancel</button>
        </div>
      </div> -->
    </form>
  </div>
  <!--#end .container-fluid -->
</div>

<app-alert
  title="VALUE CAN NOT BE EMPTY"
  message="Please provide Auxcon User Name "
  [isVisible]="showUserErrorPromptAUname"
  (cancel)="onHideAlert()"
  (confirm)="onHideAlert()"
>
</app-alert>
<app-alert
  title="VALUE CAN NOT BE EMPTY"
  message="Please provide Job Title"
  [isVisible]="showUserErrorPromptJobTitle"
  (cancel)="onHideAlert()"
  (confirm)="onHideAlert()"
>
</app-alert>
<app-alert
  title="VALUE CAN NOT BE EMPTY"
  message="Please provide First Name"
  [isVisible]="showUserErrorPromptFirstName"
  (cancel)="onHideAlert()"
  (confirm)="onHideAlert()"
>
</app-alert>
<app-alert
  title="VALUE CAN NOT BE EMPTY"
  message="Please provide Last Name"
  [isVisible]="showUserErrorPromptLastName"
  (cancel)="onHideAlert()"
  (confirm)="onHideAlert()"
>
</app-alert>
<app-alert
  title="VALUE CAN NOT BE EMPTY"
  message="Please provide Email Address"
  [isVisible]="showUserErrorPromptEmailID"
  (cancel)="onHideAlert()"
  (confirm)="onHideAlert()"
>
</app-alert>
<app-alert
  title="VALUE CAN NOT BE EMPTY"
  message="Please provide Mobile Number"
  [isVisible]="showUserErrorPromptMobileNumber"
  (cancel)="onHideAlert()"
  (confirm)="onHideAlert()"
>
</app-alert>
<app-alert
  title="VALUE CAN NOT BE EMPTY"
  message="Please provide Service Provider Id"
  [isVisible]="showUserErrorPromptServiceProviderId"
  (cancel)="onHideAlert()"
  (confirm)="onHideAlert()"
>
</app-alert>
<app-alert
  title="VALUE CAN NOT BE EMPTY"
  message="Please select free Templates Yes or No"
  [isVisible]="showUserErrorPromptYesorNo"
  (cancel)="onHideAlert()"
  (confirm)="onHideAlert()"
>
</app-alert>
