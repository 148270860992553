import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable, retry, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface OnBoardingRegister {
  FirstName: string;
  LastName: string;
  IDNumber: string;
  Gender: string;
  Ethincity: string;
  AdmissionDate: Date;
  AdmissionDay : number;
  AdmissionMonth : number;
  AdmissionYear : number;
  Seniority: string;
  ContactNumber: string;
  Email: string;
  BarSocietyID: number;
  ChamberID: number;
  TenantID: string;
  AccountType: string;
  Domain: string;
  ContactTypeID : number;
}
export interface DateParameters {
  AdmissionDay: number;
  AdmissionMonth: number;
  AdmissionYear: number;
}

export interface OnBoarding_HelperDTO {
  EmailAddress: string;
  AccountType: string; 
  TenantID: number;
}

export interface RegionalBarHandler {
  data: OnBoardingRegister;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  }  
}

export interface ChambersHandler {
  data: OnBoardingRegister;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  }  
}


@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  private httpClient: HttpClient;
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private handler: HttpBackend,
  ) { this.httpClient = new HttpClient(handler); }
    
  getBarSociety(): Observable<any> {
    return this.http.get(this.api.endpoints.onboarding +
      '/BarSociety', this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getBarSociety()

  getChambers(barId: number): Observable<any> {
    return this.http.get(this.api.endpoints.onboarding +
      '/Chambers?barId=' + barId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getChambers()

  verifyDuplicateEmail(email: string): Observable<any> {
    return this.http.get(this.api.endpoints.onboarding +
      '/subscribe/VerifyDuplicateEmail?email=' + email, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } 

  verifyDuplicateEmailPopup(email: string): Observable<any> {
    return this.http.get(this.api.endpoints.onboarding +
      '/subscribe/VerifyDuplicateEmail?email=' + email).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400 && error.error === 'Email is already exist') {
          // Rethrow the error so it can be caught in the component
           return throwError(() => 'Email is already exist');
        }
        // For other errors, you might want to handle them differently
        return throwError(() => new Error('An unexpected error occurred'));
      })
    );
  } // end verifyDuplicateEmailPopup()

  onSaveRegister(preRegistrations: OnBoardingRegister): Observable<OnBoardingRegister> {
    return this.http.post<any>(
      this.api.endpoints.onboarding +
      '/subscribe/SavePreRegistrationDetails',preRegistrations,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } 

  inOfficeSaveRegister(preRegistrations: OnBoardingRegister): Observable<OnBoardingRegister> {
    return this.http.post<any>(
      this.api.endpoints.onboarding +
      '/subscribe/InOfficePreRegistrationDetails',preRegistrations,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  }  // end inOfficeSaveRegister()

  createAccount(PreRegistrationID: string): Observable<any> {
    return this.http.post<any>(
      this.api.endpoints.onboarding +
      '/subscribe/CreateAccount?PreRegistrationID=' + PreRegistrationID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  }

  upgradeAdHoc(): Observable<any> {
    return this.http.post<any> (
      this.api.endpoints.onboarding + '/subscribe/UpgradeAdHoc?ServiceProviderId=' + this.api.serviceProviderID, this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    )
  } // end upgradeAdHoc()
}