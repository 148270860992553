import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ReportsService, IndustryReport } from '../../../services/reports/reports.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { delay } from 'q';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { HttpClient } from '@angular/common/http';
import { AdvocateDetailStatementService } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { ApiService } from '../../../services/api.service';

const FIELD_LAWFIRM = 'law-firm';
const FIELD_AVARAGE_DAYS = 'avarage-days';
const FIELD_PAID_VS_INVOICED = 'paid-vs-invoice';
const FIELD_TOTAL_PAID = 'total-paid';


interface IndustryDetailsHandle {
  data: IndustryReport;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean
  };
}
@Component({
  selector: 'app-industry-reports',
  templateUrl: './industry-reports.component.html',
  styleUrls: ['./industry-reports.component.scss']
})
export class IndustryReportsComponent implements OnInit, OnDestroy {

  industryReportHandle: IndustryDetailsHandle[] = [];
  industryData: IndustryReport[];
  searchItems: IndustryReport[];
  subscription: Subscription;
  searchTerm: string;
  isBusyLoading = false;

  previousSortBy: string;
  sortBy: string;
  sortAscending: boolean;
  sortLawfirm: boolean;
  sortAvarageDays: boolean;
  sortPaidVsInvoiced: boolean;
  sortTotalPaid: boolean;
  isProfileTypeAdHoc: boolean;
  isBarSocietyTypeOther: boolean;
  exportLawfirmOverview: any[] = [];
  excelURL: string;
  gcbTooltipList: IndustryReport[];
  showTooltip: boolean = false;
  
  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  @Output() searchClient: EventEmitter<any> = new EventEmitter<any>();

  // Is Practice Manager
  get isPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER) &&
      Boolean(this.userProfileService.userProfile.serviceProviderID ===
        this.userProfileService.selectedUserProfile.serviceProviderID);
  }  // end isPracticeManager()

  constructor(
    private router: Router,
    private api: ApiService,
    private http: HttpClient,
    private reportsService: ReportsService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private advocateDetailStatementService: AdvocateDetailStatementService
  ) { 
    this.excelURL = this.api.endpoints.auxconReportUtils + '/AuxconReportUtils';
  } // end constructor()

  // get isConsumerUser(): Boolean {
  //   return JSON.parse(sessionStorage.getItem('userProfile')).profileType == 'Consumer' ? true : false;
  // }
  // get isBarSocietyTypeOther(): Boolean {
  //   return (JSON.parse(sessionStorage.getItem('userProfile')).regionalBarName == 'Other' || JSON.parse(sessionStorage.getItem('userProfile')).regionalBarName == null) 
  //   || (JSON.parse(sessionStorage.getItem('advocateUserProfil'))[0].regionalBarName != ''|| JSON.parse(sessionStorage.getItem('advocateUserProfil'))[0].regionalBarName != null)  ? true : false; 
  // }
  ngOnInit() {
    if (JSON.parse(sessionStorage.getItem('userProfile')).profileType == 'Ad-hoc') {
      this.isProfileTypeAdHoc = true;
      this.isBarSocietyTypeOther = true;
    }
    else if (JSON.parse(sessionStorage.getItem('userProfile')).profileType == 'Practice Manager') {
      this.isProfileTypeAdHoc = false;
      this.isBarSocietyTypeOther = true;
    }
    else {
      if (JSON.parse(sessionStorage.getItem('advocateUserProfil'))) {
        if (JSON.parse(sessionStorage.getItem('advocateUserProfil')).regionalBarName) {
          this.isProfileTypeAdHoc = false;
          this.isBarSocietyTypeOther = false;
        }
        else {
          if ((JSON.parse(sessionStorage.getItem('advocateUserProfil'))[0])) {
            if ((JSON.parse(sessionStorage.getItem('advocateUserProfil'))[0].regionalBarName)) {
              this.isProfileTypeAdHoc = false;
              this.isBarSocietyTypeOther = true;
            }
            else {
              this.isProfileTypeAdHoc = false;
              this.isBarSocietyTypeOther = false;
            }
          }
          else {
            this.isProfileTypeAdHoc = false;
            this.isBarSocietyTypeOther = false;
          }
        }
      }
      else {
        if (JSON.parse(sessionStorage.getItem('userProfile')).regionalBarName == 'Other'
          || JSON.parse(sessionStorage.getItem('userProfile')).regionalBarName == 'Tshwane Society of Advocates'
          || JSON.parse(sessionStorage.getItem('userProfile')).regionalBarName == 'Pan Africa Bar Association of South Africa'
          || JSON.parse(sessionStorage.getItem('userProfile')).regionalBarName == null) {
          this.isProfileTypeAdHoc = false;
          this.isBarSocietyTypeOther = false;
        }
        else {
          this.isProfileTypeAdHoc = false;
          this.isBarSocietyTypeOther = true;
        }
      }
    }
    this.fetchData();
  } // end ngOnInit()

  ngOnDestroy() {
    // this.subscription.unsubscrib

  } // end ngOnDestroy()

  fetchData() {
    // console.log('INDUSTRY REPORT PARAMETERS: ');
    // this.subscription = this.reportsService.getAverageDaysParameters().subscribe(parameter => {
    //   if (parameter === null) {
    //   }
    this.loadingService.showOverlay();
    this.isBusyLoading = true;
    this.reportsService.getIndustryReports(0, 50, moment().subtract(36, 'months').startOf('month').format('YYYY-MM-DD')).subscribe({
      next: (response) => {
        this.industryData = response;
        // console.log('AVERAGE DAYS PAY DATA: ', this.industryData);
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
        this.isBusyLoading = false;
      },
      complete: () => {
        // On Complete
        this.initIndustryData();
        this.loadingService.hideOverlay();
        this.isBusyLoading = false;
      }
    });
    // });
  } // end fetchData()

  onGcbListingTooltip(lawfirmid: number) {
    if (!lawfirmid)
      return;
    this.reportsService.getAllGcbTooltipList(lawfirmid).subscribe({
      next: (response) => {
        this.gcbTooltipList = response;
        this.showTooltip = this.gcbTooltipList && this.gcbTooltipList.length > 0;
      },
      error: (error) => {
        this.gcbTooltipList = null;
        this.showTooltip = false;
      },
      complete: () => {
      }
    });
  }

  onMouseLeave() {
    this.showTooltip = false;
    this.gcbTooltipList = null; 
  } 
  
  toggleSort(sortBy: string) {
    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }
    switch (this.sortBy) {
      case FIELD_LAWFIRM:
        this.industryReportHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortLawfirm = true;
            this.sortAvarageDays = false;
            this.sortPaidVsInvoiced = false;
            this.sortTotalPaid = false;

            return a.data.Client.toUpperCase().localeCompare(b.data.Client.toUpperCase());
          }
          this.sortLawfirm = false;
          return b.data.Client.toUpperCase().localeCompare(a.data.Client.toUpperCase());
        });
        break;

      case FIELD_AVARAGE_DAYS:
        this.industryReportHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortLawfirm = false;
            this.sortAvarageDays = true;
            this.sortPaidVsInvoiced = false;
            this.sortTotalPaid = false;

            return a.data.AvgDaysToPayment.toUpperCase().localeCompare(b.data.AvgDaysToPayment.toUpperCase());
          }
          this.sortAvarageDays = false;
          return b.data.AvgDaysToPayment.toUpperCase().localeCompare(a.data.AvgDaysToPayment.toUpperCase());
        });
        break;
      case FIELD_PAID_VS_INVOICED:
        this.industryReportHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortLawfirm = false;
            this.sortAvarageDays = false;
            this.sortPaidVsInvoiced = true;
            this.sortTotalPaid = false;

            return a.data.PaidVsInvoiced - b.data.PaidVsInvoiced;
          }
          this.sortPaidVsInvoiced = false;
          return b.data.PaidVsInvoiced - a.data.PaidVsInvoiced;
        });
        break;
      case FIELD_TOTAL_PAID:
        this.industryReportHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortLawfirm = false;
            this.sortAvarageDays = false;
            this.sortPaidVsInvoiced = false;
            this.sortTotalPaid = true;

            return a.data.NoOfInvoices - b.data.NoOfInvoices;
          }
          this.sortTotalPaid = false;
          return b.data.NoOfInvoices - a.data.NoOfInvoices;
        });
        break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleSort()

  fetchDataBySearch() {
    this.loadingService.showOverlay();
    this.isBusyLoading = true;
    this.reportsService.getIndustryReportsBySearch(this.searchTerm, null).subscribe({
      next: (response) => {
        delay(50000);
        console.log('SEARCH ITEMS: ', this.searchTerm, ' RESPONSE: ', response);
        this.searchItems = response;
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
        this.isBusyLoading = false;
      },
      complete: () => {
        // On Complete
        this.initSearchIndustryData();
        this.loadingService.hideOverlay();
        this.isBusyLoading = false;
      }
    });
  } // end fetchData()

  initSearchIndustryData() {
    console.log('AAAAAAA: ', this.searchItems);
    if (this.searchItems) {
      while (this.searchItems.length > 0) {
        const industry = this.searchItems.pop();
        if (this.industryData.find(x => x.ClientId === industry.ClientId)) {

        } else {
          this.industryReportHandle.push({
            data: industry,
            state: {
              isVisible: true,
              isSelected: false,
              isOpen: false
            }
          });
        }
      }
    }
  } // end initSearchIndustryData()

  initIndustryData() {
    this.industryReportHandle = [];
    if (this.industryData) {
      this.industryData.forEach(data => {
        this.industryReportHandle.push({
          data: data,
          state: {
            isVisible: true,
            isSelected: false,
            isOpen: false
          }
        });
      });
    }
  } // end initIndustryData()

  onKeyDown(event) {
    switch (event.key) {
      case 'Enter':
        this.searchClient.emit(this.searchTerm);
        this.initIndustryData();
        this.filterIndustryData();
        break;
    }
  } // end onKeyDown()

  filterIndustryData() {
    let showing = 0;
    if (this.searchTerm) {
      this.industryReportHandle.forEach(data => {
        if (
          !(data.data.Client.match(new RegExp(this.searchTerm, 'gi')))
        ) {
          data.state.isVisible = false;
        } else {
          data.state.isVisible = true;
          showing++;
        }
      });
    } else {
      this.industryReportHandle.forEach(data => {
        data.state.isVisible = true;
      });
    }
    if (showing < 105) {
      // this.fetchDataBySearch();
    }
  } // end filterIndustryData()

  onGeneratePaymentTrends() {
    this.loadingService.showOverlay();
    this.reportsService.getGCBPaymentTrends().subscribe({ next: (data) => {
      // On next
      console.log('GCB PAYMENT TRENDS DATA: ', data);
        data.forEach(_data => {
          const exportData = {
            "Law Firm Name": _data.LawFirmName,
            "Advocate Name": _data.AdvocateName,
            "Bar Society": _data.BarSociety,
            "Request sent to Bar Society": _data.RequestSentToBarSociety,
            "Letter sent to firm": _data.LetterSentToFirm,
            "GCB Listing Date": _data.ListingRequestSentToGCB,
            "LPC Complaint Sent": _data.LPCReportStatusDate,
            "Payment date": _data.PaymentDate,
            "Days until payment": _data.DaysUntilPayment,
          };
          this.exportLawfirmOverview.push(exportData);
        });
      },
      error: (error) => {
        // On error
        console.log('Error exporting data: ', error);
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.http.post(this.excelURL, this.exportLawfirmOverview, {
          responseType: 'arraybuffer'
        }).subscribe({ next: (response) => this.advocateDetailStatementService.downloadExcelFile(response,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
          , error: (error) => {
            // On error
            this.loadingService.hideOverlay();
          },
          complete: () => {
            // On complete
            this.loadingService.hideOverlay();
          }
        });
      }
    })
  } //  end onGeneratePaymentTrends()
} // end IndustryReportsComponent{}
