import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { SubscriptionBillingService } from '../../../services/subscription-billing/SubscriptionBilling.service';
import { LENGTH_LONG } from "../../../services/messaging/toast/toast.service";

@Component({
  selector: 'app-subscriptions-plans',
  templateUrl: './subscriptions-plans.component.html',
  styleUrls: ['./subscriptions-plans.component.scss']
})

export class SubscriptionsPlansComponent implements OnInit {
  subscriptionPlanServices: any = [];
  subscriptionAddOnServices: any = [];
  contactSubscriptionHistory: any = [];

  selectedServicesForCancel: any = [];
  selectedServicesForSave: any = [];
  selectedServicesIds: any = [];

  isOnViewMoreInfo: boolean = false;
  isShowCancelPopup: boolean = false;
  isShowRedirectPopup: boolean = false;

  displayName: string;
  viewMoreDescription: string;

  activeTab: number = 0;
  VATPercentage: number = 0.00;
  totalGrossAmount: number = 0.00;
  totalVATAmount: number = 0.00;

  constructor(
    private subscriptionBillingService: SubscriptionBillingService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService
  ) { }

  amountFormat(Value): string {
    return Value.toFixed(2);
  }

  ngOnInit() {
    this.getVATDetails();
    this.getsubscriptionPlansList();
  }

  getVATDetails() {
    this.subscriptionBillingService.getVATDetails().subscribe({
      next: (response) => {
        this.VATPercentage = response[0].Value.split('%')[0];
      },
      error: (error) => { },
      complete: () => { }
    })
  }

  getsubscriptionPlansList() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: "Getting data", action: null,
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    this.subscriptionBillingService.getSubscriptionsDetails().subscribe({
      next: (response) => {
        if (response) {

          if (response.subscriptionPlans)
            this.subscriptionPlanServices = response.subscriptionPlans.filter(x => x.ProductType.toLowerCase() == 'plan')[0];

          if (response.subscriptionPlans) {
            let subscriptionAddOnServices = response.subscriptionPlans.filter(x => x.ProductType.toLowerCase() == 'addon');
            subscriptionAddOnServices.forEach(element => {
              this.subscriptionAddOnServices.push({
                data: element,
                state: { isSelected: false }
              })
            });
          }

          if (response.contactSubscriptionHistory) {
            this.contactSubscriptionHistory = response.contactSubscriptionHistory;
            this.contactSubscriptionHistory.forEach((element) => {
              let res = this.subscriptionAddOnServices.filter(x => x.data.SubscriptionPlanID === element.SubscriptionPlanID);
              if (res.length > 0) {
                if (res[0].data) {
                  const index = this.subscriptionAddOnServices.findIndex(item => item.data.SubscriptionPlanID === element.SubscriptionPlanID);
                  if (index !== -1)
                    this.subscriptionAddOnServices[index].state.isSelected = true;
                  this.SelectedAddOnPlan(res[0].data);
                }
              }
            });
          }

          this.calculateTotalValue();
        }
      },
      error: (error) => {
        snack.label = error;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
      },
      complete: () => {
        snack.label = 'Successfully loaded';
        snack.type = SnackType.SUCCESS;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    })
  }

  SelectedAddOnPlan(data: any, isCheck: boolean = false) {
    if (!data) return;

    if (!this.selectedServicesForSave.includes(data)) {
      this.selectedServicesForSave.push(data);
      this.selectedServicesIds.push(data.SubscriptionPlanID);
      const selectedServicesIndex: number = this.selectedServicesForCancel.indexOf(data);

      if (selectedServicesIndex !== -1)
        this.selectedServicesForCancel.splice(selectedServicesIndex, 1);
    }
    else {
      this.selectedServicesForCancel.push(data);
      const selectedServicesIndex: number = this.selectedServicesForSave.indexOf(data);
      const selectedServicesIdsIndex: number = this.selectedServicesIds.indexOf(data.SubscriptionPlanID);

      if (selectedServicesIndex !== -1)
        this.selectedServicesForSave.splice(selectedServicesIndex, 1);

      if (selectedServicesIdsIndex !== -1)
        this.selectedServicesIds.splice(selectedServicesIdsIndex, 1);
    }

    if (data.Code == "Collections_Service") {
      let Plan = this.subscriptionAddOnServices.filter(item => item.data.Code === "AdHoc_Service");
      if (Plan && Plan[0] && Plan[0].data) {
        const index1 = this.subscriptionAddOnServices.findIndex(item => item.data.Code === "AdHoc_Service");
        if (index1 !== -1) {
          this.subscriptionAddOnServices[index1].state.isSelected = false;

          this.selectedServicesForCancel.push(Plan[0].data);

          const selectedServicesIdsIndex: number = this.selectedServicesIds.indexOf(Plan[0].data.SubscriptionPlanID);
          if (selectedServicesIdsIndex !== -1)
            this.selectedServicesIds.splice(selectedServicesIdsIndex, 1);

          const selectedSaveServicesIndex: number = this.selectedServicesForSave.indexOf(Plan[0].data);
          if (selectedSaveServicesIndex !== -1)
            this.selectedServicesForSave.splice(selectedSaveServicesIndex, 1);
        }
      }
      const index2 = this.subscriptionAddOnServices.findIndex(item => item.data.Code === "Collections_Service");
      if (index2 !== -1)
        this.subscriptionAddOnServices[index2].state.isSelected = true;
    }
    else if (data.Code == "AdHoc_Service") {
      let Plan = this.subscriptionAddOnServices.filter(item => item.data.Code === "Collections_Service");
      if (Plan && Plan[0] && Plan[0].data) {
        const index1 = this.subscriptionAddOnServices.findIndex(item => item.data.Code === "Collections_Service");
        if (index1 !== -1) {
          this.subscriptionAddOnServices[index1].state.isSelected = false;

          this.selectedServicesForCancel.push(Plan[0].data);

          const selectedServicesIdsIndex: number = this.selectedServicesIds.indexOf(Plan[0].data.SubscriptionPlanID);
          if (selectedServicesIdsIndex !== -1)
            this.selectedServicesIds.splice(selectedServicesIdsIndex, 1);

          const selectedSaveServicesIndex: number = this.selectedServicesForSave.indexOf(Plan[0].data);
          if (selectedSaveServicesIndex !== -1)
            this.selectedServicesForSave.splice(selectedSaveServicesIndex, 1);
        }
        const index2 = this.subscriptionAddOnServices.findIndex(item => item.data.Code === "AdHoc_Service");
        if (index2 !== -1)
          this.subscriptionAddOnServices[index2].state.isSelected = true;
      }
    }

    this.calculateTotalValue();
  }

  SaveSubscriptionDetails(isDetailsSave: boolean) {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: "Please wait...",
      action: null,
    };
    let data = {
      selectedServicesForSave: this.selectedServicesForSave,
      selectedServicesForCancel: this.selectedServicesForCancel
    }
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    this.subscriptionBillingService.saveSubscriptionsPlansDetails(data).subscribe({
      next: (response: any) => {
        if (response) {
          if (response.URL !== "") {
            localStorage.setItem("ContactSubscriptionPaymentsID", response.ContactSubscriptionPaymentsID);
            this.isShowRedirectPopup = true;
            window.location.href = response.URL;
          }
        }
      },
      error: (error) => {
        snack.label = error,
          snack.type = SnackType.ERROR,
          this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        snack.label = "Details saved successfully";
        snack.type = SnackType.SUCCESS;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    })
  }

  CancelSubscription(isCancel: boolean) {
    this.isShowCancelPopup = isCancel ? true : false;
  }

  onViewMoreInfo(subscriptionPlanID: number) {
    this.isOnViewMoreInfo = true;
    const subscriptionPlan = this.subscriptionAddOnServices.find(x => x.data.SubscriptionPlanID === subscriptionPlanID).data;
    if (subscriptionPlan) {
      this.displayName = subscriptionPlan.DisplayName;
      this.viewMoreDescription = subscriptionPlan.ViewMoreDescription
    }
  }

  onViewMoreInfoDismiss() {
    this.isOnViewMoreInfo = false;
  }

  calculateTotalValue() {
    this.totalGrossAmount = Number(this.amountFormat(this.subscriptionPlanServices.Value));
    if (this.selectedServicesForSave.length > 0) {
      for (let item of this.selectedServicesForSave) {
        this.totalGrossAmount += item.ValueType === 'Amount' ? item.Value : 0.00;
        this.totalVATAmount = (this.VATPercentage / 100) * this.totalGrossAmount;
      }
    }
    else
      this.totalVATAmount = (this.VATPercentage / 100) * this.totalGrossAmount;
  }
}