import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { TAG_ADD_CLIENT_MODAL } from '../../../services/tags/tags.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { OtherClients, DEFAULT_OTHERCLIENTS_TEMPLATE, OtherClientsService } from '../../../services/other-clients/other-clients.service';

const TITLE_NEW_CLIENT = 'Add new Client';
const TITLE_UP_CLIENT = 'Edit Client';
const SAVE_BUTTON_TITLE = 'SAVE';
const UPDATE_BUTTON_TITLE = 'UPDATE';

@Component({
  selector: 'app-add-new-client',
  templateUrl: './add-new-client.component.html',
  styleUrls: ['./add-new-client.component.scss']
})
export class AddNewClientComponent implements OnInit, OnChanges {

  title = TITLE_NEW_CLIENT;
  btnSave = SAVE_BUTTON_TITLE;
  @Input() isVisible: boolean;
  @Input() clients: OtherClients;
  @Output() clientsChange: EventEmitter<OtherClients> = new EventEmitter<OtherClients>();
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() saveClients: EventEmitter<OtherClients> = new EventEmitter<OtherClients>();

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney'
  };

  get isAttorney(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
  } // end profileType()

  promptTitle: string;
  message: string;
  showSaveContactModelPrompt: boolean;

  constructor(
    private userProfileService: UserProfileService,
    private util: UtilitiesService,
    private otherClientService: OtherClientsService
  ) { }

  ngOnInit() {

    if (this.clients) {
      if (this.clients.ContactID > 0) {
        this.title = TITLE_UP_CLIENT;
        this.btnSave = UPDATE_BUTTON_TITLE;
      } else {
        this.title = TITLE_NEW_CLIENT;
        this.btnSave = SAVE_BUTTON_TITLE;
      }
    } else {
      this.title = TITLE_NEW_CLIENT;
      this.btnSave = SAVE_BUTTON_TITLE;
    }
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (this.clients) {
      if (this.clients.ContactID > 0) {
        this.title = TITLE_UP_CLIENT;
        this.btnSave = UPDATE_BUTTON_TITLE;
      } else {
        this.title = TITLE_NEW_CLIENT;
        this.btnSave = SAVE_BUTTON_TITLE;
      }
    } else {
      this.title = TITLE_NEW_CLIENT;
      this.btnSave = SAVE_BUTTON_TITLE;
    }
  } // end ngOnChanges()

  get hasFirstName(): Boolean {
    return !this.util.hasTextValue(this.clients.FullName);
  } // end hasFirstName()

  get hasPhoneNumberError(): boolean {
    return !this.util.isValidPhoneNumber(this.clients.MobileNumber);
  } // end hasCellNumberError()

  get hasContactEmailError(): boolean {
    return !this.util.isValidEmail(this.clients.EmailAddress);
  } // end hasContactEmailError()

  get isSaveable(): boolean {
    return Boolean(this.util.isValidEmail(this.clients.EmailAddress) &&
      this.util.isValidPhoneNumber(this.clients.MobileNumber) &&
      this.util.hasTextValue(this.clients.FullName));
  } // end isSaveable()

  dismiss() {
    this.clients = Object.assign({}, DEFAULT_OTHERCLIENTS_TEMPLATE);
    this.closeModal.emit(TAG_ADD_CLIENT_MODAL);
  } // end dismiss()

  onCheckPhoneNumber() {
    this.otherClientService.searchContacts(this.clients.MobileNumber)
    .subscribe(cell_phone_results => {
      // On next
      // Check if the cell phone number exist
      if (cell_phone_results.length > 0) {
        this.promptTitle = 'Cell number already in use.';
        this.message = 'The Cell number entered: ' +
          this.clients.MobileNumber +
          ' already exist on our system, please enter a different Cell number.';
        this.showSaveContactModelPrompt = true;
        this.clients.MobileNumber = '';
      }
    });
  } // onCheckPhoneNumber()

  onCheckEmailAddress() {
    this.otherClientService.searchContacts(this.clients.EmailAddress)
    .subscribe(email_address_results => {
      // On next
      // Check if the cell phone number exist
      if (email_address_results.length > 0) {
        this.promptTitle = 'Email address already in use.';
        this.message = 'The email address entered: ' +
          this.clients.EmailAddress +
          ' already exist on our system, please use another email.';
        this.showSaveContactModelPrompt = true;
        this.clients.EmailAddress = '';
      }
    });
  } // onCheckEmailAddress()

  onHideSaveContactModelPrompt() {
    this.showSaveContactModelPrompt = false;
  } // end onHideSaveContactModelPrompt()

  save() {
    this.saveClients.emit(this.clients);
    this.dismiss();
  } // end save()
}
