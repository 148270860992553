import {
    Component,
    OnInit,
    EventEmitter,
    Input,
    Output,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { ProcessPaymentService } from '../../../services/process-payment/process-payment.service';
import * as moment from 'moment';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
// import { } from '@angular/core/testing';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { InvoiceDetails, InvoiceDetailsService } from '../../../services/invoice-details/invoice-details.service';
import { DEFAULT_ACTIVITY_LOGS, ActivityLogs, ActivityLogsService } from '../../../services/activity-logs/activity-logs.service';
import { WriteOffUnderPaymentModelComponent } from '../write-off-under-payment-model/write-off-under-payment-model.component';


@Component({
    selector: 'app-invoices-processed-bank-transaction',
    templateUrl: './invoices-processed-bank-transaction.component.html',
    styleUrls: ['./invoices-processed-bank-transaction.component.scss']
})
export class InvoicesProcessedBankTransactionComponent implements OnInit, OnChanges {

    showProcessedModel = false;

    totalAmount = 0;

    promptInvoices = false;
    promptPaidInvoices = false;
    bankTransaction: any = [];
    showProformaInvoice: boolean;
    selectedInvoiceId = 0;

    invoices: any = [];
    invoicesDate: Date;

    // for invoice preview
    invoicePlaintiff: string;
    invoiceDefendant: string;
    invoiceDetails: InvoiceDetails;

    profile: any;
    // Activity logs
    activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

    @Output() refreshPage = new EventEmitter<string>();
    @Input() selectedTransaction: any;

    @Input() onShow: EventEmitter<any> = new EventEmitter<any>();



    constructor(
        private loadingService: LoadingService,
        private snackbarsService: SnackbarsService,
        private userProfileService: UserProfileService,
        private activityLogService: ActivityLogsService,
        private invoiceDetailService: InvoiceDetailsService,
        private processPaymentService: ProcessPaymentService,
    ) {
    }

    get currencySymbol(): string {
        if (this.userProfileService.userProfile.isImpersonator) {
            return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
        } else {
            return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
        }
    } // end currencySymbol()

    ngOnInit() {
        this.profile = this.userProfileService.userProfile;
        this.showProcessedModel = false;
        this.onShow.subscribe((response) => {
            if (response) {
                this.bankTransaction = response;
                this.getInvoices(response);
            } else {
                this.promptInvoices = false;
                this.promptPaidInvoices = false;
            }
        });
    } // end ngOnInit()

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedTransaction && changes.selectedTransaction.currentValue) {
            console.log('SELECTED TRANSACTION: ', this.selectedTransaction);
        }
    }

    onViewInvoice(invoiceId: number) {
        if (invoiceId > 0) {
            this.invoiceDetailService.getInvoiceDetails(invoiceId).subscribe({
                next: (_invoice_detail) => {
                    this.invoiceDetails = _invoice_detail;
                    this.selectedInvoiceId = invoiceId;
                    if (this.invoiceDetails) {
                        const desc = this.invoiceDetails.InvoiceParties.split(' // ');
                        if (desc.length > 0) {
                            this.invoicePlaintiff = desc[0];
                        }
                        if (desc.length > 1) {
                            this.invoiceDefendant = desc[1];
                        }
                    }
                    this.showProformaInvoice = true;
                }
            });
        }
    } // end onViewInvoice()

    closeModal(value: string) {
        switch (value) {
            case 'invoice':
                this.showProformaInvoice = false;
                break;
        }
    } // end closeModal()


    getDateFormet(value: Date): string {
        return moment(value).format('DD/MM/YYYY');
    }

    numberConversion(value: number): string {
        return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    moveInvoices() {
        this.showProcessedModel = true;
    } // end moveInvoices()

    onHideProcessedModel() {
        this.showProcessedModel = false;
    } // end onHideProcessedModel()

    deleteAllocateToLawfirm(line: number) {
        // Log activity Login
        const currentDate = new Date();
        this.activityLog.Action = 'Remove Bank transaction from proccessed';
        this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (Proccessed-bank-transaction)';
        if (this.userProfileService.userProfile.isImpersonator) {
            this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
            this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'Transactions';
        this.activityLog.JsonData = JSON.stringify(line);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_activity) => { } });

        this.processPaymentService.removeTransactionLines(line).subscribe({ next: (_remove) => { } });
    } // end deleteAllocateToLawfirm()

    onMoveTransaction() {
        if (this.processPaymentService.selectedprocessedTab && this.processPaymentService.selectedprocessedTab === 'person') {
            this.loadingService.showOverlay();

            // Log activity Login
            const currentDate = new Date();
            this.activityLog.Action = 'Remove personal Bank transaction from proccessed';
            this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
            this.activityLog.LoggedApp = 'Web Application (Proccessed-bank-transaction)';
            if (this.userProfileService.userProfile.isImpersonator) {
                this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
            } else {
                this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
            }
            this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
            this.activityLog.ActionTable = 'Transactions';
            this.activityLog.JsonData = JSON.stringify(this.bankTransaction);
            this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_activity) => { } });

            this.processPaymentService.deleteTransactionLines(this.bankTransaction.Id).subscribe({
                next: (_remove) => {
                },
                error: (error) => {
                    // On error
                    this.loadingService.hideOverlay();
                },
                complete: () => {
                    this.loadingService.hideOverlay();
                    let todel = null;
                    this.processPaymentService.deleteProcessedBankTransactions(this.bankTransaction.Id).subscribe({
                        next: (_delete) => {
                            todel = _delete;
                            this.showProcessedModel = false;
                            this.processPaymentService.selectedTab = 'newBankTransactions';
                            this.refreshPage.emit(todel);
                        }
                    });
                }
            });
            // this.loadingService.hideOverlay();
        } else if (this.processPaymentService.selectedprocessedTab &&
            this.processPaymentService.selectedprocessedTab === 'refered-to-client') {
            this.loadingService.showOverlay();
            // Log activity Login
            const currentDate = new Date();
            this.activityLog.Action = 'Remove refered to a client Bank transaction from proccessed';
            this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
            this.activityLog.LoggedApp = 'Web Application (Proccessed-bank-transaction)';
            if (this.userProfileService.userProfile.isImpersonator) {
                this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
            } else {
                this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
            }
            this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
            this.activityLog.ActionTable = 'Transactions';
            this.activityLog.JsonData = JSON.stringify(this.bankTransaction);
            this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_activity) => { } });

            this.processPaymentService.deleteTransactionLines(this.bankTransaction.Id).subscribe({
                next: (_delete) => {

                },
                error: (error) => {
                    // On error
                    this.loadingService.hideOverlay();
                },
                complete: () => {
                    // On complete
                    let todel = null;
                    this.processPaymentService.deleteProcessedBankTransactions(this.bankTransaction.Id).subscribe({
                        next: (_todelete) => {
                            todel = _todelete;
                            this.showProcessedModel = false;
                            this.processPaymentService.selectedTab = 'newBankTransactions';
                            this.refreshPage.emit(todel);
                        }
                    });
                }
            });

            // this.loadingService.hideOverlay();
        } else {
            let deletedId: any;
            const snack: Snack = {
                label: 'Move New Bank Transaction',
                action: null
            };
            this.loadingService.showOverlay();
            // try {
            if (this.bankTransaction.BankTransactionLines && this.bankTransaction.BankTransactionLines.length > 0) {
                this.bankTransaction.BankTransactionLines.forEach(line => {
                    if (line.AssignedToLawFirm) {
                        this.deleteAllocateToLawfirm(line.Id);
                    } else if (line.LawFirm === 'Write-Off') {
                        this.processPaymentService.deleteWriteOffLines(line.InvoiceId).subscribe({ next: (_delete) => { } });
                    }
                });
            }
            this.showProcessedModel = false;
            this.processPaymentService.deleteProcessedBankTransactions(this.bankTransaction.Id).subscribe({
                next: (_deleted) => {
                    deletedId = _deleted;
                },
                error: (error) => {
                    if (error !== '' || error !== null || error !== undefined)
                        snack.label = error;
                    else
                        snack.label = 'Something went wrong';
                    // On error
                    this.loadingService.hideOverlay();
                    snack.action = null;
                    snack.type = SnackType.ERROR;
                    this.snackbarsService.dismiss().make(snack).show();
                },
                complete: () => {
                    // On complete
                    this.processPaymentService.selectedTab = 'newBankTransactions';
                    this.refreshPage.emit(deletedId);
                }
            });
            // } catch (error) {
        }
    } // end onMoveTransaction()

    getInvoices(bank: any) {
        this.totalAmount = 0;
        this.invoicesDate = bank.Date;
        if (bank.BankTransactionLines != null) {
            if (bank.BankTransactionLines.length > 0) {
                this.promptInvoices = false;
                this.promptPaidInvoices = true;
                this.invoices = bank.BankTransactionLines;
            } else {
                this.promptInvoices = true;
                this.promptPaidInvoices = false;
            }
        } else {
            this.promptInvoices = true;
            this.promptPaidInvoices = false;
        }

        for (const value of this.invoices) {
            this.totalAmount = this.totalAmount + value.Paid;
        }
    } // end getInvoices()

} // end InvoicesProcessedBankTransactionComponent{}
