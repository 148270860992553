import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { catchError, Observable } from 'rxjs';

export interface Terms {
  userId: number;
  acceptedDate: string;
}

export const DEFAULT_TERMS: Terms = {
  userId: 0,
  acceptedDate: ''
}

@Injectable({
  providedIn: 'root'
})
export class TermsService {

  constructor(
    private http: HttpClient,
    private api: ApiService,
  ) { } // end constructor()

  onPostTerms(terms: Terms): Observable<any> {
    return this.http.post<any> (
      this.api.endpoints.terms + '/terms-and-conditions/accept',
      terms, this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end onPostTerms{}
} // end TermsService{}
