import { Injectable } from '@angular/core';
import { LIST_NAME_LAW_FIRMS } from '../lists/lists.service';

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {
  private db!: IDBDatabase;
  private dbReady: Promise<void>;

  constructor() {
    // Increment the version number if you're making changes to the database schema
    this.dbReady = new Promise((resolve, reject) => {
      this.initDB(resolve, reject);
    });
  } 

  private initDB(resolve, reject) {
    // Increment the version number here if you have added a new object store 
    // after the database was initially created.
    const request = window.indexedDB.open(LIST_NAME_LAW_FIRMS, 1); 

    request.onerror = (event: Event) => {
      // console.error("IndexedDB error:", event);
      reject(event);
    };

    request.onsuccess = (event: Event) => {
      // console.log("IndexedDB opened successfully");
      this.db = (event.target as IDBRequest).result as IDBDatabase;
      resolve();
    };

    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      // console.log("IndexedDB upgrade needed");
      const db = (event.target as IDBRequest).result as IDBDatabase;
      const objectStoreName = LIST_NAME_LAW_FIRMS; // Replace with your object store name
      if (!db.objectStoreNames.contains(objectStoreName)) {
        db.createObjectStore(objectStoreName, { keyPath: "id" });
      }
      resolve(); 
    };
  }

  addData(storeName: string, data: any): Promise<void> {
    return this.dbReady.then(() => {
      return new Promise((resolve, reject) => {
        const transaction = this.db.transaction([storeName], "readwrite");
        const store = transaction.objectStore(storeName);
        const request = store.put(data);
        
        // console.log('Attempting to add data to IndexedDB: ', data);
        request.onsuccess = () => resolve();
        request.onerror = (event: Event) => {
          const target = event.target as IDBRequest;
          reject(target.error);
        };

        request.onerror = (event: Event) => {
          const target = event.target as IDBRequest;
          // console.error('IndexedDB request error:', target.error);
          reject(target.error);
      };
      });
    });
  }

  getData(storeName: string, key: any): Promise<any> {
    return this.dbReady.then(() => {
      return new Promise((resolve, reject) => {
        // console.log('Attempting to get data from IndexedDB');
        const transaction = this.db.transaction([storeName]);
        const store = transaction.objectStore(storeName);
        const request = store.get(key);

        request.onsuccess = () => resolve(request.result);
        request.onerror = (event: Event) => {
          const target = event.target as IDBRequest;
          reject(target.error);
        };
      });
    });
  }
}
