<div class="row m-0">
  <!-- #start clients below target -->
  <div class="col-md-100" style="width: 46%;" (click)="onWidget('invoicedTarget')">
    <div class="col widget-container">

      <div class="row  widget3-header text-white" [ngStyle]="{'background-color': advocatesBelowTargetPercentageColor}">
        <div class="col-11 header-text"><b>{{numberOfAdvocates}} of your {{totalNumberOfAdvocates}} advocates </b> were more
          than 20% below target for their <b>invoicing</b> the previous month.</div>
        <div *ngIf="advocatesBelowTargetPercentageColor !== '#e5c100'" class="col-1 fa-icon">
          <i [class.fa-badge-check]="advocatesBelowTargetPercentageColor==='#8abd37'" title="Healthy" *ngIf="advocatesBelowTargetPercentageColor==='#8abd37'"
            class="fal"></i>
          <i [class.fa-exclamation-triangle]="advocatesBelowTargetPercentageColor==='#eb9e02'" title="Caution"
            *ngIf="advocatesBelowTargetPercentageColor==='#eb9e02'" class="fal"></i>
          <i [class.fa-exclamation-circle]="advocatesBelowTargetPercentageColor==='#cf624f'" title="Danger"
            *ngIf="advocatesBelowTargetPercentageColor==='#cf624f'" class="fal"></i>
        </div>
        <div *ngIf="advocatesBelowTargetPercentageColor == '#e5c100'" class="col-1 fa-icon">
          <img alt="neutral" src="./../../../../assets/images/icons/icon-neutral.svg" title="Neutral">
        </div>
      </div>
      <!-- #end .row .widget1-container -->
      <div class="row widget1-body">
        <div class="col customer-col mr-10px">
          <!-- <div class="row header">Advocates that are more than 20% below their invoicing target this month</div> -->
          <!-- <div
            class="row"
            *ngFor="let advocate of advoctesBelowTarget"
            style="padding-bottom: 0px !important; padding-top: 5px !important; font-size: 11px;">
            <div class="col-8 deptor" [title]="advocate.data.advocate">{{advocate.data.advocate}}</div>
            <div class="col-4 text-right" style="color: #cf624f;">{{advocate.data.invoicedBelowTarget}}%</div>
          </div> -->
          <table>
            <thead>
              <tr>
                <th style="width: 45%"></th>
                <th style="width: 20%">Invoiced previous month</th>
                <td></td>
                <th style="width: 20%">Target</th>
                <th style="width: 15%" class="text-right">Difference</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let advocate of advoctesBelowTarget">
                <td style="width: 45%" [title]="advocate.data.advocate"><u>{{advocate.data.Advocate}}</u></td>
                <td style="width: 20%">{{advocate.data.TotalInvoicedLastMonth | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td></td>
                <td style="width: 20%">{{advocate.data.InvoiceTarget | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td style="width: 15%" class="text-right" style="color: #cf624f;">{{advocate.data.InvoicedBelowTarget}}%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- #end .col .customer-col .mr-10px -->
      </div> 
      <!-- #end .row .widget1-body -->
      <div class="row col-md-4 offset-md-9 text-right">
        <u>View more ></u>
      </div>
    </div> 
    <!-- #end .col .widget-container -->
  </div> 
  <!-- #end .col-md-100 -->
  
  <!-- #start clients below target -->
  <div class="col-md-100" style="width: 46%;" (click)="onWidget('collectedTarget')">
    <div class="col widget-container">

      <div class="row  widget3-header text-white" [ngStyle]="{'background-color': advocatesBelowCollectionTargetPercentageColor}">
        <div class="col-11 header-text"><b>{{c_numberOfAdvocates}} of your {{totalNumberOfAdvocates}} advocates </b> were more
          than 20% below target for their <b>collection</b> previous month.</div>
        <div *ngIf="advocatesBelowCollectionTargetPercentageColor !== '#e5c100'" class="col-1 fa-icon">
          <i [class.fa-badge-check]="advocatesBelowCollectionTargetPercentageColor==='#8abd37'" title="Healthy"
            *ngIf="advocatesBelowCollectionTargetPercentageColor==='#8abd37'" class="fal"></i>
          <i [class.fa-exclamation-triangle]="advocatesBelowCollectionTargetPercentageColor==='#eb9e02'" title="Caution"
            *ngIf="advocatesBelowCollectionTargetPercentageColor==='#eb9e02'" class="fal"></i>
          <i [class.fa-exclamation-circle]="advocatesBelowCollectionTargetPercentageColor==='#cf624f'" title="Danger"
            *ngIf="advocatesBelowCollectionTargetPercentageColor==='#cf624f'" class="fal"></i>
        </div>
        <div *ngIf="advocatesBelowCollectionTargetPercentageColor == '#e5c100'" class="col-1 fa-icon">
          <img alt="neutral" src="./../../../../assets/images/icons/icon-neutral.svg" title="Neutral">
        </div>
      </div>
      <!-- #end .row .widget1-container -->
      <div class="row widget1-body">
        <div class="col customer-col mr-10px">
          <!-- <div class="row header">Advocates that are more than 20% below their collections target this month</div> -->
          <!-- <div
            class="row"
            *ngFor="let advocate of advoctesBelowTarget"
            style="padding-bottom: 0px !important; padding-top: 5px !important; font-size: 11px;">
            <div class="col-8 deptor" [title]="advocate.data.advocate">{{advocate.data.advocate}}</div>
            <div class="col-4 text-right" style="color: #cf624f;">{{advocate.data.invoicedBelowTarget}}%</div>
          </div> -->
          <table>
            <thead>
              <tr>
                <th style="width: 45%"></th>
                <th style="width: 20%">Collected previous month</th>
                <td></td>
                <th style="width: 20%">Target</th>
                <th style="width: 15%" class="text-right">Difference</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let advocate of advoctesBelowCollectionsTarget">
                <td style="width: 45%" [title]="advocate.data.advocate"><u>{{advocate.data.Advocate}}</u></td>
                <td style="width: 20%">{{advocate.data.TotalCollectedLastMonth | currency: currencySymbol: currencySymbol:'1.2-2'}}
                </td>
                <td></td>
                <td style="width: 20%">{{advocate.data.CollectionTarget | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td style="width: 15%" class="text-right" style="color: #cf624f;">{{advocate.data.CollectedBelowTarget}}%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- #end .col .customer-col .mr-10px -->
      </div> 
      <!-- #end .row .widget1-body -->
      <div class="row col-md-4 offset-md-9 text-right">
        <u>View more ></u>
      </div>
    </div> 
    <!-- #end .col .widget-container -->
  </div> 
  <!-- #end .col-md-100 -->

</div> 
<!-- #end .row m-0 -->
<div class="row m-0">
  <!-- #start clients below target -->
  <div class="col-md-100" style="width: 46%;" (click)="onWidget('advocateReview')">
    <div class="col widget-container">

      <div class="row  widget3-header text-white"
        [ngStyle]="{'background-color': advocatesDueForReviewPercentageColor}">
        <div class="col-11 header-text"><b>{{meeting_numberOfAdvocates}} of your {{totalNumberOfAdvocates}} advocates </b> are due for a review in the next
          7 days. Please schedule meetings with them to review their practice.</div>
        <div *ngIf="advocatesDueForReviewPercentageColor !== '#e5c100'" class="col-1 fa-icon">
          <i [class.fa-badge-check]="advocatesDueForReviewPercentageColor==='#8abd37'" title="Healthy"
            *ngIf="advocatesDueForReviewPercentageColor==='#8abd37'" class="fal"></i>
          <i [class.fa-exclamation-triangle]="advocatesDueForReviewPercentageColor==='#eb9e02'" title="Caution"
            *ngIf="advocatesDueForReviewPercentageColor==='#eb9e02'" class="fal"></i>
          <i [class.fa-exclamation-circle]="advocatesDueForReviewPercentageColor==='#cf624f'" title="Danger"
            *ngIf="advocatesDueForReviewPercentageColor==='#cf624f'" class="fal"></i>
        </div>
        <div *ngIf="advocatesDueForReviewPercentageColor == '#e5c100'" class="col-1 fa-icon">
          <img alt="neutral" src="./../../../../assets/images/icons/icon-neutral.svg" title="Neutral">
        </div>
      </div>
      <!-- #end .row .widget1-container -->
      <div class="row widget1-body">
        <div class="col customer-col mr-10px">
          <!-- <div class="row header">Advocates that are due for their review this week</div> -->
          <!-- <div
            class="row"
            *ngFor="let advocate of advoctesBelowTarget"
            style="padding-bottom: 0px !important; padding-top: 5px !important; font-size: 11px;">
            <div class="col-8 deptor" [title]="advocate.data.advocate">{{advocate.data.advocate}}</div>
            <div class="col-4 text-right" style="color: #cf624f;">{{advocate.data.invoicedBelowTarget}}%</div>
          </div> -->
          <table>
            <thead>
              <tr>
                <th style="width: 45%"></th>
                <th style="width: 20%">Last meeting date</th>
                <td></td>
                <th style="width: 20%">On target</th>
                <th style="width: 15%" class="text-right">Date Signed</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let advocate of advoctesDueforReview">
                <td style="width: 45%" [title]="advocate.data.advocate"><u>{{advocate.data.Advocate}}</u></td>
                <td style="width: 20%">{{advocate.data.LastMeetingDate | date:'yyyy-MM-dd'}}
                </td>
                <td></td>
                <td style="width: 20%" [ngStyle]="{'color': getCollectedTargetColor(advocate.data)}">{{advocate.data.CollectedThisMonthPercentage}}%</td>
                <td style="width: 15%" class="text-right" [ngStyle]="{'color': getAgeColor(advocate.data)}">{{advocate.data.AdvocateAge | date:'yyyy-MM-dd'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- #end .col .customer-col .mr-10px -->
      </div> 
      <!-- #end .row .widget1-body -->
      <div class="row col-md-4 offset-md-9 text-right">
        <u>View more ></u>
      </div>
    </div> 
    <!-- #end .col .widget-container -->
  </div> 
  <!-- #end .col-md-100 -->
  
  <!-- #start clients below target -->
  <div class="col-md-100" style="width: 46%;" (click)="onWidget('invoicingAdvocate')">
    <div class="col widget-container"> 

      <div class="row  widget3-header text-white" [ngStyle]="{'background-color': advocatesExceedingCollectedPercentageColor}">
        <div class="col-11 header-text"><b>{{col_numberOfAdvocates}} of your {{totalNumberOfAdvocates}} advocates </b> exceeded their collections target
          the previous month.</div>
        <div *ngIf="advocatesExceedingCollectedPercentageColor !== '#e5c100'" class="col-1 fa-icon">
          <i [class.fa-badge-check]="advocatesExceedingCollectedPercentageColor==='#8abd37'" title="Healthy"
            *ngIf="advocatesExceedingCollectedPercentageColor==='#8abd37'" class="fal"></i>
          <i [class.fa-exclamation-triangle]="advocatesExceedingCollectedPercentageColor==='#eb9e02'" title="Caution"
            *ngIf="advocatesExceedingCollectedPercentageColor==='#eb9e02'" class="fal"></i>
          <i [class.fa-exclamation-circle]="advocatesExceedingCollectedPercentageColor==='#cf624f'" title="Danger"
            *ngIf="advocatesExceedingCollectedPercentageColor==='#cf624f'" class="fal"></i>
        </div>
        <div *ngIf="advocatesExceedingCollectedPercentageColor == '#e5c100'" class="col-1 fa-icon">
          <img alt="neutral" src="./../../../../assets/images/icons/icon-neutral.svg" title="Neutral">
        </div>
      </div>
      <!-- #end .row .widget1-container -->
      <div class="row widget1-body">
        <div class="col customer-col mr-10px">
          <!-- <div class="row header">Advocates that are more than 20% below their invoicing target this month</div> -->
          <!-- <div
            class="row"
            *ngFor="let advocate of advoctesBelowTarget"
            style="padding-bottom: 0px !important; padding-top: 5px !important; font-size: 11px;">
            <div class="col-8 deptor" [title]="advocate.data.advocate">{{advocate.data.advocate}}</div>
            <div class="col-4 text-right" style="color: #cf624f;">{{advocate.data.invoicedBelowTarget}}%</div>
          </div> -->
          <table>
            <thead>
              <tr>
                <th style="width: 42%"></th>
                <!-- <th></th> -->
                <th style="width: 20%">Invoiced</th>
                <th></th>
                <th></th>
                <th style="width: 20%">Collected</th>
                <td></td>
                <td></td>
                <!-- <td></td> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let advocate of advoctesAboveInvoicedCollected">
                <td style="width: 44%" [title]="advocate.data.advocate"><u>{{advocate.data.Advocate}}</u></td>
                <!-- <td></td> -->
                <td style="width: 20%">{{advocate.data.TotalInvoicedLastMonth | currency: currencySymbol: currencySymbol:'1.2-2'}}
                </td>
                <td style="color: #8abd37; width: 10%; text-align: left;">{{advocate.data.InvoiceAboveTarget}}%</td>
                <td></td>
                <td style="width: 20%">{{advocate.data.TotalCollectedLastMonth | currency: currencySymbol: currencySymbol:'1.2-2'}}
                </td>
                <td style="color: #8abd37; width: 10%; text-align: left;">{{advocate.data.CollectedAboveTarget}}%</td>
                <!-- <td></td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <!-- #end .col .customer-col .mr-10px -->
      </div> 
      <!-- #end .row .widget1-body -->
      <div class="row col-md-4 offset-md-9 text-right">
        <u>View more ></u>
      </div>
    </div> 
    <!-- #end .col .widget-container -->
  </div> 
  <!-- #end .col-md-100 -->

</div> 
<!-- #end .row m-0 -->
