<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">

<div class="container-fluid">
    <h2 class="page-title">Admin</h2>

    <div class="process-body">
        <div class="row">
            <div class="col-sm-2">
                <a (click)="onClick('contact-management')">
                    <i class="fal fa-address-book"></i>
                    <p>Contact Management</p>
                </a>
            </div>
            <div class="col-sm-2">
                <a *ngIf="isPracticeManager" (click)="onClick('collection-report')">
                    <i class="fal fa-hand-receiving"></i>
                    <p>Collections</p>
                </a>
            </div>
            <div class="col-sm-2">
                <a *ngIf="isPracticeManager" (click)="onClick('invite')">
                    <i class="fal fa-user"></i>
                    <p>Invite</p>
                </a>
            </div>
            <div class="col-sm-2">
                <!-- <a (click)="onClick(contact-management)">
                    <i class="far fa-address-book"></i>
                    <p>Contact Management</p>
                </a> -->
            </div>
            <div class="col-sm-2">
                <!-- <a (click)="onClick(contact-management)">
                    <i class="far fa-address-book"></i>
                    <p>Contact Management</p>
                </a> -->
            </div>
            <div class="col-sm-2">
                <!-- <a (click)="onClick(contact-management)">
                    <i class="far fa-address-book"></i>
                    <p>Contact Management</p>
                </a> -->
            </div>
        </div>
    </div>
</div>
<app-invite
    *ngIf="showInviteScreen"
    [isVisible]="showInviteScreen"
    (closeModal)="onHideInviteScreen()"></app-invite>