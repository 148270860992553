import { Injectable, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { UserProfile } from './user-profile/user-profile.service';
import {
  RATE_TYPE_PER_KILOMETER,
  RATE_TYPE_HOURLY,
  RATE_TYPE_ONCE_OFF,
  RATE_TYPE_DAILY,
  RATE_TYPE_DISBURSEMENT,
  RATE_TYPE_NON_BILLABLE,
  RATE_TYPE_PER_PAGE
} from './fee-items/fee-items.service';

export interface PhoneValidationOptions {
  code?: string;
}

export const DEFAULT_PHONE_VALIDATION_OPTIONS = {
  code: 'ZAR'
};

@Injectable()
export class UtilitiesService {

  private now: number;
  private lastTime: number;
  private isOutlookCalendarEntry = new Subject<boolean>();

  constructor() { } // end constructor()

  setOutlookCalendarEntryData(data: boolean) {
    this.isOutlookCalendarEntry.next(data);
  }

  getOutlookCalendarEntryData() {
    return this.isOutlookCalendarEntry.asObservable();
  }

  getRateFromRateType(userProfile: UserProfile, rateType: string): number {
    let rate: number = 0;

    switch (rateType) {
      case RATE_TYPE_PER_KILOMETER:
        rate = userProfile.billingDetails.defaultPerKilometerRate;
        break;

      case RATE_TYPE_HOURLY:
        rate = userProfile.billingDetails.defaultHourlyRate;
        break;

      case RATE_TYPE_ONCE_OFF:
        break;

      case RATE_TYPE_DAILY:
        rate = userProfile.billingDetails.defaultDailyRate;
        break;

      case RATE_TYPE_DISBURSEMENT:
        break;

      case RATE_TYPE_NON_BILLABLE:
        break;

      case RATE_TYPE_PER_PAGE:
        break;
    }

    return rate;
  } // end getRateFromRateType()

  /**
   * Transforms an ISO formatted date string into a format that will work with
   * input elements of type 'date'.
   * @param {string} date The given date string (in ISO format).
   * @return {string} Returns an html friendly date string for use in input
   * elements of type 'date'.
   */

  isInvalidFromToDate(fromDate: string, toDate: string): boolean {
    if (fromDate !== null && toDate !== null) {
      const _fromd = new Date(fromDate);
      const _tod = new Date(toDate);

      const fromdate = moment(_fromd.toDateString());
      const todate = moment(_tod.toDateString());
      if (fromdate.isSameOrAfter(todate)) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  parseDate(date: string): string {
    if (date) {
      return date.replace(/T[\d]+:[\d]+:[\d.]+Z*/gi, '');
    }

    return new Date().toISOString().replace(/T[\d]+:[\d]+:[\d.]+Z/gi, '');
  } // end parseDate()

  isInArray(search, array) {
    let found = false;
    array.forEach(a => {
      if (search === a) found = true;
    });
    return found;
  } // end isInArray()

  /**
   * Returns the key that matches the given value of a given object.
   * @param {object} obj The object to be inpsected.
   * @param {*} value The lookup value.
   * @returns {string} Returns the key that matches the given value of a
   * given object.
   */
  getKeyByValue(obj: object, value: any): string {
    // Polyfill for older environments that do not natively support Object.keys.
    // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
    if (!Object.keys) {
      Object.keys = (function () {
        'use strict';
        var hasOwnProperty = Object.prototype.hasOwnProperty,
          hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
          dontEnums = [
            'toString',
            'toLocaleString',
            'valueOf',
            'hasOwnProperty',
            'isPrototypeOf',
            'propertyIsEnumerable',
            'constructor'
          ],
          dontEnumsLength = dontEnums.length;

        return function (obj) {
          if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
            // throw new TypeError('Object.keys called on non-object');
          }

          var result = [], prop, i;

          for (prop in obj) {
            if (hasOwnProperty.call(obj, prop)) {
              result.push(prop);
            }
          }

          if (hasDontEnumBug) {
            for (i = 0; i < dontEnumsLength; i++) {
              if (hasOwnProperty.call(obj, dontEnums[i])) {
                result.push(dontEnums[i]);
              }
            }
          }
          return result;
        };
      }());
    }

    // Clean but slow implementation. Has O(n) complexity so for large datasets
    // an alternative is strongly advised if possible, otherwise a better 
    // implementation should be sought if possible. The speed of this function 
    // is contingent on the implementation of the Object.keys spec by the 
    // browser.
    return Object.keys(obj).find(key => obj[key] === value);
  } // end getKeyByValue()

  /**
   * Clamps a value between a minimum and maximum value.
   * @param {number} value The value to be clamped.
   * @param {number} min The minimum value.
   * @param {number} max The maximum value.
   * @returns {number} Returns a value where min <= value <= max.
   */
  numberClamp(value: number, min: number, max: number): number {
    let result: number = value;

    if (value > max) {
      result = max;
    }

    if (value < min) {
      result = min;
    }

    return result;
  } // end mathClamp()

  /**
   * Checks whether a given value is alpha-numeric i.e either an alphabetical \
   * letter (a-z, A-Z) or a number (0-9)
   * @param {*} value The value to be checked.
   * @returns {boolean} Returns true if the given value is alpha-numeric or
   * false otherwise.
   */
  isAlphNumeric(value: any): boolean {
    return value.match(/[\w\d]+/gi) !== null;
  } // end isAlphNumeric()

  debounce(delay: number, fn: any, params: any[] = []) {
    this.now = new Date().getTime();
    let lapsedTime;

    if (this.lastTime) {
      lapsedTime = this.now - this.lastTime;
      if (lapsedTime > delay) {
        // Object.call(fn, params);
      }
    }

    this.lastTime = this.now;
  } // end debounce()

  throttle(delay: number, fn: any, params: any = []) {

  } // end throttle()

  format(el) {

  } // end format()

  /**
   * Formats a currency input.
   * @param el The input element.
   */
  formatCurrencyInput(el) {
    if (!el) {
      return;
    }

    if (!el.value) {
      el.value = 'R 0.00';
    }

    this.moveCaretToBeginning(el);
  } // end formatCurrencyInput()

  formatPhoneNumberInput(el) {
    // (AB) xxx-xxxx
    if (el.value) {
      let num = el.value;
      let formattedNum = '';
      let i;
      for (i = 0; i < num.length; i++) {
        switch (i) {
          case 0:
            if (num.charAt(i) !== '(') {
              formattedNum += '(';
            }
            break;

          case 3: if (num.charAt(3) !== ')') {
            formattedNum += ') ';
          }
            break;

          case 8: if (num.charAt(8) !== '-') {
            formattedNum += '-';
          }
        }
        formattedNum += num.charAt(i);
      }

      if (num.length > 13) {
        num = num.substr(0, 13);
      }

      el.value = formattedNum;
      this.moveCaretToBeginning(el);
    }
  } // end formatPhoneNumberInput()

  /**
   * Moves the input cursor to the end i.e after the last
   * character of the text.
   * @param {HTMLElement} el The input element.
   */
  moveCaretToEnd(el) {
    const pos = el.value ? el.value.length : 0;
    this.setCaretPosition(el, pos);
  } // end moveCaretToEnd()

  /**
   * Moves the input cursor to the beginning i.e before the first
   * character of the text.
   * @param {HTMLElement} el The input element.
   */
  moveCaretToBeginning(el) {
    const pos = el.value ? el.value.length : 0;
    this.setCaretPosition(el, pos);
  } // end moveCaretToBeginning()

  /**
   * Set the cursor position of the given control to the given position.
   * @param ctrl The HTML input control
   * @param {number} pos The new caret position
   * Credits: http://blog.vishalon.net/index.php/javascript-getting-and-setting-caret-position-in-textarea/
   */
  setCaretPosition(ctrl, pos: number) {
    // Modern browsers
    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(pos, pos);

      // IE8 and below
    } else if (ctrl.createTextRange) {
      var range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  } // end setCaretPosition()

  objectCopy(source) {
    return JSON.parse(JSON.stringify(source));
  } // end objectCopy()

  objectIsSame(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  } // end objectIsSame()

  regexEscape(text: string): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|]/g, '\\$&');
  } // end regexEscape()

  // isValidEmail(email: string): boolean {
  //   return /^[a-zA-Z0-9]+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(email);
  // } // end isValidEmail()

  isValidEmail(email: string): boolean {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }

  areValidEmails(emails: string): boolean {
    let isValid: boolean = true;
    const emailList = emails.split(/[;]\s*/);
    emailList.forEach(email => {
      if (!this.isValidEmail(email.replace(/ /g, ''))) {
        isValid = false;
      }
    });
    return isValid;
  } // end areValidEmails()

  isValidPhoneNumber(phoneNumber: string, options = DEFAULT_PHONE_VALIDATION_OPTIONS): boolean {
    if (!phoneNumber) {
      return false;
    }

    let minLength = 9;
    let maxLength = 10;
    if (options.code) {
      switch (options.code) {
        case 'ZAR':
          minLength = 9;
          maxLength = 10;
          break;

        case 'BWP':
          minLength = 7;
          maxLength = 10;
          break;
      }
    }
    phoneNumber = phoneNumber.replace(/\s/g, '');
    return /^[\+\d\s-]+$/.test(phoneNumber) && phoneNumber.length >= minLength && phoneNumber.length <= maxLength;
  } // end isValidPhoneNumber()

  isValidTextOnly(text: string): boolean {
    return /^[\w\s]+$/.test(text);
  } // end isValidTextOnly()

  isValidName(text: string): boolean {
    return /^[a-zA-Z]+[\w\s\d-\.]+$/.test(text) && Boolean(text) && Boolean(text.length);
  } // end isValidText()

  isValidText(text: string): boolean {
    return /^[a-zA-Z]+[\w\s\d-\.]+$/.test(text) && Boolean(text) && Boolean(text.length);
  } // end isValidText()

  hasTextValue(text: string): boolean {
    return Boolean(text) && Boolean(text.length >= 1);
  } // end isValidText()
} // end UtilitiesService{}
