import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { jqxChartComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import {
  OverDueSummary,
  OutstandingBookSummary,
  DEFAULT_OUTSTANDING_BOOK_SUMMARY
} from '../../../services/practice-manager-dashoboard-service/practice-manager-dashboard.service';
import {
  PracticeAgeAnalysis,
  PracticeAdvocateHealthService
} from '../../../services/practice-advocate-health/practice-advocate-health.service';
import { Router } from '@angular/router';
import {
  PATH_PRACTICE_MANAGER_REPORTS
} from '../../../services/appdata/app-config.service';

enum WidgetType {
  InvoicedTarget = 'invoicedTarget',
  CollectedTarget = 'collectedTarget',
  AdvocateReview = 'advocateReview',
  InvoicingAdvocate = 'invoicingAdvocate',
  AgeAnalysis = 'ageAnalysis'
}

export enum ReportType {
  BusinessReports = 'businessReports',
  FinancialReports = 'financialReports',
  LiveStatement = 'liveStatement',
  PracticeManagementReports = 'practiceManagementReports',
  IndustryReports = 'industryReport',
  MyAdvocates = 'myAdvocates'
} // end ReportType{}

export enum ReportName {
  AdvocateSummary = 'advocateSummary',
  AdvocateDetail = 'advocateDetail',
  AgeAnalysis = 'ageAnalysis',
  PracticeAllCustomer = 'practiceAllCustomer',
  PracticeByLawfirm = 'practiceByLawfirm'
} // end ReportName{}

@Component({
  selector: 'app-practice-m-summary',
  templateUrl: './practice-m-summary.component.html',
  styleUrls: ['./practice-m-summary.component.scss']
})
export class PracticeMSummaryComponent implements OnInit, OnChanges {

  isLoading = true;
  lawfirmOne: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);
  lawfirmTwo: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);
  lawfirmThree: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);
  lawfirmFour: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);

  averageDayColor = '';
  agingCurrentTotal = 0;
  aging30Total = 0;
  aging60Total = 0;
  aging90Total = 0;
  aging98Total = 0;
  agingCurrentPercentage = 0;
  aging30Percentage = 0;
  aging60Percentage = 0;
  aging90Percentage = 0;
  aging98Percentage = 0;
  agePercentageColor = '';

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  get numberOfAdvocates(): number {
    return this.userProfileService.userProfile.impersonates.filter(practiceManager => practiceManager.serviceProviderID !==
      this.userProfileService.userProfile.serviceProviderID).length;
  } // end numberOfAdvocates()

  @Input() practiceOverdueSummary: OverDueSummary;
  @Input() practiceAgeAnalysis: PracticeAgeAnalysis;

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private practiceAdvocateHealthService: PracticeAdvocateHealthService
  ) { } // end constructor()

  ngOnInit() {
    // this.getAgeAnalysis();
  } // end ngOninit()

  ngOnChanges(changes: SimpleChanges) {
    if (this.practiceOverdueSummary) {      
      if (this.practiceOverdueSummary.outstandingBookSummary) {
        this.lawfirmOne = this.practiceOverdueSummary.outstandingBookSummary[0];
        this.lawfirmTwo = this.practiceOverdueSummary.outstandingBookSummary[1];
        this.lawfirmThree = this.practiceOverdueSummary.outstandingBookSummary[2];
        this.lawfirmFour = this.practiceOverdueSummary.outstandingBookSummary[3];
      }
    }

    if (this.practiceAgeAnalysis) {
      this.agingCurrentPercentage = this.practiceAgeAnalysis.AgingCurrentPercentage;
      this.aging30Percentage = this.practiceAgeAnalysis.Aging30Percentage;
      this.aging60Percentage = this.practiceAgeAnalysis.Aging60Percentage;
      this.aging90Percentage = this.practiceAgeAnalysis.Aging90Percentage;
      this.aging98Percentage = this.practiceAgeAnalysis.Aging98Percentage;

        this.agingCurrentTotal = this.practiceAgeAnalysis.FrequencyTotals[0].AgingCurrentTotal;
        this.aging30Total = this.practiceAgeAnalysis.FrequencyTotals[0].Aging30Total;
        this.aging60Total = this.practiceAgeAnalysis.FrequencyTotals[0].Aging60Total;
        this.aging90Total = this.practiceAgeAnalysis.FrequencyTotals[0].Aging90Total;
        this.aging98Total = this.practiceAgeAnalysis.FrequencyTotals[0].Aging98Total;
      this.getAgeAnalysis();
      this.isLoading = false;
    }
  } // end ngOnChanges()

  getAgeAnalysis() {
    if (!this.aging98Percentage) {
      this.agePercentageColor = '#8abd37';
    } else if (this.aging98Percentage <= 25) {
      this.agePercentageColor = '#8abd37';
    } else if (this.aging98Percentage >= 26 && this.aging98Percentage <= 49) {
      this.agePercentageColor = '#e5c100';
    } else if (this.aging98Percentage >= 50 && this.aging98Percentage <= 75) {
      this.agePercentageColor = '#eb9e02';
    } else if (this.aging98Percentage >= 76) {
      this.agePercentageColor = '#cf624f';
    }
  } // end getAgeAnalysis()

  onAgeAnalysisClick() {
    if (this.userProfileService.userProfile.serviceProviderID === 29723) {

    } else {
      this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
      this.practiceAdvocateHealthService.selectedReportName = ReportName.AgeAnalysis;
      this.practiceAdvocateHealthService.reportSortType = WidgetType.AgeAnalysis;
      this.router.navigate([PATH_PRACTICE_MANAGER_REPORTS]);
    }
  } // end onAgeAnalysisClick()

  getBarHeight(value: number): string {
    return (value * 70 / 100) + 'px';
  } // end getBarHeight()

  getBarTextColor(value: number): string {
    const height = (value * 70 / 100);
    if (height < 17) {
      return '#000';
    }
    return '#fff';
  } // end getBarTextColor()

  getBarTextTopMargin(value: number): string {
    const height = (value * 70 / 100);
    if (height < 17) {
      return '-20px';
    }
    return ((height / 2) - 9) + 'px';
  } // end getBarTextTopMargin()

  onLawfirmOverdueClick() {
    // if (this.userProfileService.userProfile.serviceProviderID === 29723) {

    // } else {
    this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
    this.practiceAdvocateHealthService.selectedReportName = ReportName.PracticeByLawfirm;
    this.router.navigate([PATH_PRACTICE_MANAGER_REPORTS]);
    // }
  } // end onLawfirmOverdueClick()

} // end PracticeSummaryComponent{}
