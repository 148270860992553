import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-small-checkbox',
  templateUrl: './small-checkbox.component.html',
  styleUrls: ['./small-checkbox.component.scss']
})
export class SmallCheckboxComponent implements OnInit {

  @Input() checked: boolean;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

} // end SmallCheckboxComponent{}
