import { Component, OnInit } from '@angular/core';
import { ICrumb, AdvocateDetailStatementService } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { PATH_ATTORNEY_DASHBOARD } from '../../../services/appdata/app-config.service';

@Component({
  selector: 'app-all-advocates',
  templateUrl: './all-advocates.component.html',
  styleUrls: ['./all-advocates.component.scss']
})
export class AllAdvocatesComponent implements OnInit {
  _defaultTab: string;
  crumbs: ICrumb[];

  constructor(
    private advocateDetailStatementService: AdvocateDetailStatementService
  ) { } // end constructor()

  ngOnInit() {
    this.crumbs = [
      {
        label: 'Live Statement',
        link: PATH_ATTORNEY_DASHBOARD
      },
      {
        label: 'All Advocates Report'
      }
    ];
    if (this.advocateDetailStatementService.selectedFirmTab) {
      this._defaultTab = this.advocateDetailStatementService.selectedFirmTab;
    }
  } // end ngOnInit()

} // end AllAdvocatesComponent{}
