import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UserProfileService } from '../../../../services/user-profile/user-profile.service';

@Component({

    selector: 'app-age-analysis-tooltip',
    templateUrl: './age-analysis-tooltip.component.html',
    styleUrls: ['./age-analysis-tooltip.component.scss']
})
export class AgeAnalysisToolTipComponent implements OnInit {

    tooltipData: any = [];
    @Input() _tooltipData: any;
    @Input() age: string;

    @Output() onToolTipClick = new EventEmitter<string>();

    get currencySymbol(): string {
        if (this.userProfileService.userProfile.isImpersonator) {
            return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
        } else {
            return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
        }
    } // end currencySymbol()

    constructor(
        private userProfileService: UserProfileService
    ) { } // end constructor()

    ngOnInit() {
        this.tooltipData = this._tooltipData;
    }

    onViewAllCustomerReport(age: string) {
        this.onToolTipClick.emit(age);
    }

    getBottomRow(index: number, length: number): string {
        if (length > 0 && index == length - 1) {
            return "20px";
        }
        return "0px";
    }

    numberConversion(value: number): string {
        return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
}