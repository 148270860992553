<div class="popup-menu card" [class.hide]="!isVisible">
  <ul class="menu-list">
    <li
      *ngIf="options.openMatter"
      class="menu-item clickable"
      (click)="onOpenMatter()">Open matter</li>
    <li
      *ngIf="options.previewInvoice && isInOutbox"
      class="menu-item clickable"
      (click)="onPreviewInvoice()">Preview invoice</li>
    <li
      *ngIf="options.previewMessage"
      class="menu-item clickable"
      (click)="onPreviewMessage()">Preview message</li>
    <li
      class="menu-item clickable"
      (click)="onViewQuotation()">View Proforma</li> <!-- #removed this: "*ngIf="options.viewQuotation" so that Quotation will appear always-->
    <li
      *ngIf="options.deleteMatter"
      class="menu-item clickable"
      (click)="onDeleteMatter()">Delete matter</li>
  </ul>
</div>
