import { Component, OnInit, ViewChild, Input, AfterViewInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { InvoiceDetails, InvoiceDetailsService, DEFAULT_INVOICE_DETAILS } from '../../../services/invoice-details/invoice-details.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { InvoiceDocumentService } from '../../../services/invoice-document/invoice-document.service';
import { ApiService } from '../../../services/api.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import {
  NotesObject,
  Notes,
  NotesService,
  NoteCategory,
  DEFAULT_NOTES_OBJECT,
  NotesObjectHandle,
  InstructionResponses,
} from '../../../services/notes/notes.service';
import * as moment from 'moment';
import { QuotationFeeItem } from '../../../services/quotation/quotation.service';

@Component({
  selector: 'app-invoice-preview-modal',
  templateUrl: './invoice-preview-modal.component.html',
  styleUrls: ['./invoice-preview-modal.component.scss']
})
export class InvoicePreviewModalComponent implements AfterViewInit, OnInit, OnChanges {

  invoice: InvoiceDetails;
  reportUrl: string;

  activeTab: string;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  // Notes
  onShowNoteDetail: boolean;
  noteCategory: NoteCategory[] = [];
  noteDetailData: NotesObject;
  invoicePlaintiff: string;
  invoiceDefendant: string;
  objectType: string;
  searchTerm: string;

  // Advocate Note View
  showAdvocateNoteDetail: boolean;
  selectedNote: NotesObject;
  instructionResponses: InstructionResponses[] = [];

  showRemoveWriteOffPrompt: boolean;
  writeoffMessage = '';
  showCreditNotePrompt: boolean;
  creditNoteMessage = 'You are about to remove the credit note. Do you wish to proceed?';
  showContainDisbursementPrompt: boolean;

  _fileName;
  @ViewChild('pdfViewer') public pdfViewer;
  @Input() notesHandle: NotesObjectHandle[];
  @Input() selectedTab: string;
  @Input() invoiceDetails: InvoiceDetails;
  @Input() invoiceId: number;
  @Input() plaintiff: string;
  @Input() defendant: string;
  @Input() isVisible: boolean;
  @Input() feesQuoted: QuotationFeeItem[];
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveNote: EventEmitter<any> = new EventEmitter<any>();

  isShowAddCreditModel = false;
  isShowWriteOffModel = false;
  isShowSendCreditNoteModel = false;
  showTransactionLinkedPrompt: boolean;


  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  // Is Practice Manager
  get isPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  }  // end isPracticeManager()

  get isAdvocateProfile(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ADVOCATE);
  } // isAdvocateProfile()

  get actualFeesTotal(): number {
    let total = 0;
    if (this.feesQuoted) {
      this.feesQuoted.forEach(data => {
        total += data.Total;
      });
      return total;
    }
  } // end actualFeesTotal()

  constructor(
    private api: ApiService,
    private notesService: NotesService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private invoiceDetailService: InvoiceDetailsService,
    private invoiceDocumentService: InvoiceDocumentService
  ) { } // end constructor()

  ngAfterViewInit() {
    if (this.activeTab === 'invoice') {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.invoiceDocument +
      '?invoiceID=' + this.invoiceId;

    this.invoiceDocumentService.getInvoiceDocument(this.reportUrl).subscribe({
      next: (invoiceDoc) => {
      this.pdfViewer.name = 'Invoice.pdf';
      this.pdfViewer.pdfSrc = invoiceDoc;
      this.pdfViewer.refresh();
    },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
      }
    });
    }
  } // end ngAfterViewInit()

  ngOnInit() {
    if (this.selectedTab) {
      this.activeTab = this.selectedTab;
    } else {
      this.activeTab = 'invoice';
    }
  } // end ngOnInit()
  
  onPromptRemoveWriteOff(data: InvoiceDetails) {
    this.writeoffMessage = "You are about to remove the write off amount of invoice " + data.InvoiceNo + ". Do you wish to proceed?"
    this.showRemoveWriteOffPrompt = true;
  } // end onPromptRemoveWriteOff()

  onRemoveWriteOff() {
    this.loadingService.showOverlay();
    this.invoiceDetailService.removeWriteOff(this.invoiceDetails.Id).subscribe({ next:(data) => {},
      complete: () => {
        // complete
        this.showRemoveWriteOffPrompt = false;
        this.closeModal.emit();
        this.loadingService.hideOverlay();
      }
    });
  } // end onRemoveWriteOff()

  onCreditNote() {
    this.loadingService.showOverlay();
    this.invoiceDetailService.removeCreditNote(this.invoiceDetails.Id).subscribe({ next: (data) => {},
    complete: () => {
      this.showCreditNotePrompt = false;
      this.closeModal.emit();
      this.loadingService.hideOverlay();
    }
  })
  } // end onCreditNote();
  


  onHideRemoveWriteOffPrompt() {
    this.showRemoveWriteOffPrompt = false;
  } // end onHideRemoveWriteOffPrompt()
  onPromptRemoveCreditNote(data: InvoiceDetails) {
    this.writeoffMessage = "You are about to remove the Credit Note amount of invoice " + data.InvoiceNo + ". Do you wish to proceed?"
    this.showCreditNotePrompt = true;
  } // end onPromptRemoveCreditNote()

  noteUserLogger(note: NotesObject): string {
    let user = '';
    if (note.Personal) {
      user = note.UserCreatorFullName;
    } else if (!note.Personal && note.Category === 'Instruction') {
      user = note.UserCreatorFullName;
    } else if (!note.Personal && note.InstructionResponseId > 0) {
      user = note.UserCreatorFullName;
    } else {
      user = note.UserOwnerFullName;
    }
    return user;
  } // noteUserLogger()

  onHideCreditNotePrompt() {
    this.showCreditNotePrompt = false;
  } // end onHideCreditNotePrompt()

  pmLatestNote(data: NotesObject): string {
    let q_text = '';

      if (data && data.Subject == 'Instruction from PM') {
        q_text = data.Subject;
      } else if (data && data.ResponseText) {
        q_text = data.ResponseText + ' ' + data.InstructionText;
      } else if (data && data.QuestionText) {
        q_text = data.InternalText + ' ' + data.QuestionText;
      } else if (data && data.InternalText) {
        q_text = data.InternalText;
      } else if (data && data.Subject && data.Subject != 'Instruction from PM') {
        q_text = data.Subject + ' ' + data.InternalText;
      }
      
      return q_text;
  } // end pmLatestNote()

  ngOnChanges(changes: SimpleChanges) {
    if (this.invoiceDetails) {
      const desc = this.invoiceDetails.InvoiceParties.split(' // ');
      if (desc.length > 0) {
        this.plaintiff = desc[0];
      }
      if (desc.length > 1) {
        this.defendant = desc[1];
      }
    }
    // this.updateUIComponent();
  } // end ngOnChanges(0)

  setActiveTab(tab: string) {
    this.activeTab = tab;
    if (this.activeTab === 'invoice') {
      this.loadingService.showOverlay();
      this.reportUrl = this.api.endpoints.invoiceDocument +
        '?invoiceID=' + this.invoiceId;

      this.invoiceDocumentService.getInvoiceDocument(this.reportUrl).subscribe({
        next: (invoiceDoc) => {
        this.pdfViewer.name = 'Invoice.pdf';
        this.pdfViewer.pdfSrc = invoiceDoc;
        this.pdfViewer.refresh();
      },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
        }
      });
    } else {
    }
    // this.updateUIComponent();
  } // end setActiveTab()

  onHideTransactionLinkedPrompt() {
    this.showTransactionLinkedPrompt = false;
  } // end onHideTransactionLinkedPrompt()

  onRemovePaymentTransaction() {
    this.showTransactionLinkedPrompt = false;
    this.loadingService.showOverlay();
    this.invoiceDetailService.removeTransactionInvoice(this.invoiceDetails.Id).subscribe({next: (_inv) => {}});
    this.isShowAddCreditModel = true;
    this.loadingService.hideOverlay();
  } // end onRemovePaymentTransaction()

  @Input()
  set fileName(fileName: string) {
    this._fileName = fileName;
  }
  get fileName() {
    return this._fileName;
  }

  dismiss() {
    this.closeModal.emit('invoice');
  } // end dismiss()

  openModel(value) {
    switch (value) {
      case 'add-credit':
        let haveDisbursement = Boolean(this.invoiceDetails.InvoiceLines.filter(a => a.Type === 'Disbursement').length > 0);
        if (this.invoiceDetails.TotalPaidAmount === this.invoiceDetails.InvoiceTotal) {
          this.showTransactionLinkedPrompt = true;
        } if (this.invoiceDetails.TotalPaidAmount + this.invoiceDetails.TotalWriteOffAmount === this.invoiceDetails.InvoiceTotal) {
          this.showTransactionLinkedPrompt = true;
        } else if (haveDisbursement === true) {
          this.showContainDisbursementPrompt = true;
        }else if (this.invoiceDetails.TotalCreditAmount === 0) {
          this.isShowAddCreditModel = true;
        }
        break;
      case 'write-off':
        if (this.invoiceDetails.OutstandingBalance > 0) {
          this.isShowWriteOffModel = true;
        }
        break;
      case 'send-credit':
          this.isShowSendCreditNoteModel = true;
        break;
    }
  }

  onHideContainDisbursementPrompt() {
    this.showContainDisbursementPrompt = false;
  } // end onHideContainDisbursementPrompt()

  closeModel(value) {
    this.invoiceDetailService.getInvoiceDetails(this.invoiceId).subscribe({next: (_invoice_detail) => {
      
      this.invoiceDetails = _invoice_detail;
    },
    error: (error) => {
      // On error
    },
    complete: () => {
      // On complete
      switch (value) {
        case 'add-credit':
          this.isShowAddCreditModel = false;
          if (this.invoiceDetails.CreditNoteId > 0) {
            this.isShowSendCreditNoteModel = true;
          }
        break;
        case 'write-off':
          this.isShowWriteOffModel = false;
        break;
        case 'send-credit':
          this.isShowSendCreditNoteModel = false;
        break;
        case 'advocate-note':
          this.showAdvocateNoteDetail = false;
        break;
      }
    }
  });
  } // end closeModel()

  performSearch() {
    if (this.searchTerm) {
      this.notesHandle.forEach(note => {
        if (
          !(note.data.ObjectTypeDescription.match(new RegExp(this.searchTerm, 'gi'))) &&
          !(note.data.Subject.match(new RegExp(this.searchTerm, 'gi')))// &&
          // !(note.data.ExternalText.match(new RegExp(this.searchTerm, 'gi')))
          // !(note.data.InstructionText.match(new RegExp(this.searchTerm, 'gi')))
        ) {
          note.state.isVisible = false;
        } else {
          note.state.isVisible = true;
        }
      });
    } else {
      this.notesHandle.forEach(note => {
        note.state.isVisible = true;
      });
    }
    // this.updateUIComponent();
  } // end performSearch()

  onNoteDetailView(data: NotesObject) {
    // this.notesHandle = [];
    this.loadingService.showOverlay();
    this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
    this.noteDetailData = data;
    if (data.UserCreatorStatus === false) {
      data.UserCreatorStatus = true;
      this.notesService.putNoteInboxStatus(data).subscribe({next: (_next) => {}});
      this.notesHandle.forEach(x => {
        if (x.data === data) {
          x.data.UserCreatorStatus = true;
        }
      });
    }
    // if (data.QuestionId > 0) {
      this.instructionResponses = [];
      if (this.isPracticeManager) {
        this.notesService.getPMInstructionResponses().subscribe({next: (_next) => {
          this.instructionResponses = _next;
        }});
      } else {
      this.notesService.getInstructionResponses(data.QuestionId).subscribe({next: (_next) => {
        this.instructionResponses = _next;
      }});
      // }
    }
    this.getNoteCategories();
    this.objectType = 'invoice';
    this.invoicePlaintiff = this.plaintiff;
    this.invoiceDefendant = this.defendant;
    this.onShowNoteDetail = true;
    this.loadingService.hideOverlay();
  } // end onNoteDetailView()

  onNoteAdvDetailView(data: NotesObject) {
    // this.notesHandle = [];
    this.instructionResponses = [];
    this.loadingService.showOverlay();
    this.selectedNote = Object.assign({}, DEFAULT_NOTES_OBJECT);
    this.selectedNote = data;
    if (data.UserAssignedStatus === false) {
      data.UserAssignedStatus = true;
       this.notesService.putNoteInboxStatus(data).subscribe({next: (_next) => {}});
      this.notesHandle.forEach(_note => {
        if (_note.data === data) {
          _note.data.UserAssignedStatus = true;
        }
      });
    }
    this.notesService.getInstructionResponses(this.selectedNote.QuestionId)
    .subscribe({next: (_next) => {
      this.instructionResponses = _next;
    }});
    this.objectType = 'invoice';
    this.showAdvocateNoteDetail = true;
    this.loadingService.hideOverlay();
  } // end onNoteAdvDetailView()

  onAddNewNote() {
    this.loadingService.showOverlay();
    //#region clear note
    const clear_note = {
      Id: 0,
      ObjectTypeId: 0,
      ObjectName: '',
      Category: '',
      ObjectId: 0,
      ObjectTypeDescription: '',
      Subject: '',
      NoteAbout: '',
      InvoiceNoteMatter: '',
      NoteTypeId: 0,
      NoteTypeDescription: '',
      InternalText: '',
      ExternalText: '',
      UserOwner: 0,
      UserOwnerFullName: '',
      UserCreator: 0,
      UserCreatorFullName: '',
      UserAssigned: 0,
      UserAssignedFullName: '',
      Priority: 0,
      PriorityText: '',
      CaptureDate: '',
      ActionDate: '',
      ActivityDate: '',
      InstructionResponseId: 0,
      ResponseText: '',
      InstructionText: '',
      UserAssignedStatus: false,
      UserCreatorStatus: false,
      UserOwnerStatus: false,
      QuestionId: 0,
      QuestionText: '',
      ResolutionText: '',
      Personal: false,
      InternalInstructionNote: '',
      MutliObjects: 0,
      InstructionCaptureDate: '',
      ResolutionCaptureDate: '',
      NoteInsertedLevel: 0,
      IsVisibleUserAssigned: false,
      IsVisibleUserCreator: false,
      IsVisibleUserOwner: false
    };
    //#endregion clear note
    this.noteDetailData = Object.assign({}, clear_note);

    // this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
    // this.invoiceDetails =  this.invoiceDetailService.getInvoiceDetails(this.invoiceId).toPromise();
    // if (this.invoiceDetails) {
    //   const desc = this.invoiceDetails.InvoiceParties.split(' // ');
    //   if (desc.length > 0) {
        this.invoicePlaintiff = this.plaintiff;
    //   }
    //   if (desc.length > 1) {
        this.invoiceDefendant = this.defendant;
    //   }
      this.noteDetailData.ObjectId = this.invoiceDetails.Id;
      this.noteDetailData.ObjectTypeDescription = this.invoiceDetails.InvoiceNo;
      this.noteDetailData.CaptureDate = moment().toString();
    // }
    this.getNoteCategories();
    this.instructionResponses = [];
    this.notesService.getPMInstructionResponses().subscribe({next: (_next) => {
      this.instructionResponses = _next;
    }});
    this.objectType = 'invoice';
    this.onShowNoteDetail = true;
    this.loadingService.hideOverlay();
  } // end onAddNewNote()

  onInstructionIssued(note: Notes) {
    note.NoteTypeId = 306;
    this.loadingService.showOverlay();
    this.notesService.postInvoiceInstructionNote(note).subscribe(
      added_note => {
        // On next
      },
      error => {
        // On Error
        this.loadingService.hideOverlay();
      },
      () => {
        // On complete
        this.loadingService.hideOverlay();
        this.onShowNoteDetail = false;
        this.dismiss();
      });
  } // end onInstructionIssued();

  onNoteSave(note: Notes) {
    this.loadingService.showOverlay();
    this.notesService.postNote(note).subscribe({
      next: (added_note) => {
        // On next
      },
      error: (error) => {
        // On Error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.notesHandle = [];
        let notes = [];
        if (this.isPracticeManager) {
         this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
          this.invoiceDetails.Id, 4).subscribe({next: (_next) => {
            notes = _next;
          }});
        } else if (!this.isPracticeManager) {
          this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID,
            this.invoiceDetails.Id, 4).subscribe({next: (_next) => {
              notes = _next;
            }});
        }
        notes.forEach(_notes => {
          this.notesHandle.push({
            data: _notes,
            state: {
              isVisible: true,
              isSelected: false,
              isOpen: false
            }
          });
        });
        this.loadingService.hideOverlay();
        this.onShowNoteDetail = false;
        // this.updateUIComponent();
      }
    });
  } // end onNoteSave()

  getNoteCategories() {
    this.notesService.getNoteCategory().subscribe({next: (_next) => {
      this.noteCategory = _next;
      this.noteCategory = this.noteCategory.filter(c => c.ObjectTypeId === 4);
      this.noteCategory.sort((a, b) => {
        return a.Category.toUpperCase().localeCompare(b.Category.toUpperCase());
      });
    }});
  } // end getNoteCategories()

  onShowNoteDetailModalDismiss() {
    this.onShowNoteDetail = false;
  } // end onShowNoteDetailModalDismiss()

  updateUIComponent() {
    this.notesHandle.sort((a, b) => {
      return a.data.ObjectTypeDescription.toUpperCase().localeCompare(b.data.ObjectTypeDescription.toUpperCase());
    });
    $(function () {
      // Function Created By: Wiseman
      // Website: http://auxcon.co.za
      function groupTable($rows, startIndex, total) {
        if (total === 0) {
          return;
        }
        var i, currentIndex = startIndex, count = 1, lst = [];
        var tds = $rows.find('td:eq(' + currentIndex + ')');
        var ctrl = $(tds[0]);
        lst.push($rows[0]);
        for (i = 1; i <= tds.length; i++) {
          if (ctrl.text() === $(tds[i]).text()) {
            count++;
            $(tds[i]).addClass('deleted');
            lst.push($rows[i]);
          } else {
            if (count > 1) {
              ctrl.attr('rowspan', count);
              groupTable($(lst), startIndex + 1, total - 1);
            }
            count = 1;
            lst = [];
            ctrl = $(tds[i]);
            lst.push($rows[i]);
          }
        }
      }
      groupTable($('#conversationTable tr:has(td)'), 0, 1);
      $('#conversationTable .deleted').remove();
    });
  } // end updateUIComponent()

  getUnReadMessageFont(value: boolean): string {
    if (value === false) {
      return 'Bold';
    }
  } // end getUnReadMessageFont()

  OnAddResolution(note: Notes) {
    this.loadingService.showOverlay();
    this.notesService.putNote(note).subscribe({
      next: (updatedNote) => {
        // On next
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On compolete
        this.notesHandle = [];
        let notes = [];
        if (this.isPracticeManager) {
         this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
          this.invoiceDetails.Id, 4).subscribe({next: (_next) => {
            notes = _next;
          },
          complete: () => {
            notes.forEach(_notes => {
              this.notesHandle.push({
                data: _notes,
                state: {
                  isVisible: true,
                  isSelected: false,
                  isOpen: false
                }
              });
            });
            this.saveNote.emit();
            this.showAdvocateNoteDetail = false;
            this.onShowNoteDetail = false;
            this.ngAfterViewInit()
            this.loadingService.hideOverlay();
          }
        });
        } else if (!this.isPracticeManager) {
          this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID,
            this.invoiceDetails.Id, 4).subscribe({next: (_next) => {
              notes = _next;
            },
            complete: () => {
              notes.forEach(_notes => {
                this.notesHandle.push({
                  data: _notes,
                  state: {
                    isVisible: true,
                    isSelected: false,
                    isOpen: false
                  }
                });
              });
              this.saveNote.emit();
              this.showAdvocateNoteDetail = false;
              this.onShowNoteDetail = false;
              this.ngAfterViewInit()
              this.loadingService.hideOverlay();
            }
          });
        }        
      }
    });
  } // end OnAddResolution()
} // end InvoicePreviewModalComponent{}
