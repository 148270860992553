import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvocateDetailLineItem,
         AdvocateDetailLineItemHandle,
         AdvocateDetailStatementService,
         DEFAULT_DISPUTE_STATUSES, DisputeStatuses } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-invoice-line-dispute',
  templateUrl: './invoice-line-dispute.component.html',
  styleUrls: ['./invoice-line-dispute.component.scss']
})
export class InvoiceLineDisputeComponent implements OnInit {
  title: string = 'Invoice line Queries & Disputes';
  invoiceLineDisputeList: any;
  invoiceLineStatus: string = '';
  invoiceLineStatusNote: string = '';
  workingDisputStatues: DisputeStatuses = Object.assign({}, DEFAULT_DISPUTE_STATUSES);

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.disputStatues, this.workingDisputStatues);
  } // end isDirty()

  @Input() isVisible: boolean;
  @Input() disputStatues: DisputeStatuses;
  @Input() selectedDetailDataHandle: AdvocateDetailLineItemHandle;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatedStatus: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private advocateDetailService: AdvocateDetailStatementService
  ) { } // end constructor()

  ngOnInit(): void {
    this.workingDisputStatues = Object.assign({}, DEFAULT_DISPUTE_STATUSES);
    this.invoiceLineDisputeList = [
      { label: 'Incorrect page numbers', value: 'Incorrect page numbers' },
      { label: 'Time spent', value: 'Time spent' },
      { label: 'Use the number of hours instead of a daily fee', value: 'Use the number of hours instead of a daily fee' },
      { label: 'Charged a day fee on another invoice for work done on the same day', value: 'Charged a day fee on another invoice for work done on the same day' },
      { label: 'Require documents for work done', value: 'Require documents for work done' },
      { label: 'Date discrepancy', value: 'Date discrepancy' },
    ];

    this.workingDisputStatues = Object.assign({}, this.disputStatues);
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onLineDispute() {
    this.selectedDetailDataHandle.data.DisputeNote = this.disputStatues.Note;
    this.selectedDetailDataHandle.data.DisputeStatus = this.disputStatues.Status;
    this.selectedDetailDataHandle.data.DisputeDescription = this.disputStatues.Description;
    this.selectedDetailDataHandle.data.DisputeSource = "InvoiceLine"
    this.selectedDetailDataHandle.state.updated = true;
    this.updatedStatus.emit(this.selectedDetailDataHandle);
  } // end onLineDispute()
} // end InvoiceLineDisputeComponent()
