<div class="container-fluid">
  <h2 class="page-title">{{title | titlecase}}</h2>
</div> <!-- #end .container-fluid -->

<div class="row row-nav" (click)="onPageClick($event)">
  <nav class="navbar-tabs">
    <a class="tab" [class.active]="activeTab === 'view-as-table'" (click)="setActiveTab('view-as-table')">View as table</a>
    <!-- <a
      class="tab"
      [class.active]="activeTab === 'view-as-graph'"
      (click)="setActiveTab('view-as-graph')">View as graph</a> -->
  </nav> <!-- #end nav -->
</div> <!-- #end .row.row-nav -->

<div class="row" (click)="onPageClick($event)">

  <div class="column-display-area">
    <div class="display-options">
      <!-- Search column -->
      <span class="column-search">
        <button class="btn btn-text">
          <!-- (click)="filterStatement()" -->
          <i class="fa fa-search"></i>
        </button> <!-- #end .btn btn-text -->
        <input type="search" placeholder="Search current matters"
          >
          <!-- (click)="clearSearch" -->
          <!-- (keyup)="filterMatters()" -->
          <!-- [(ngModel)]="searchValue" -->
      </span> <!-- #end .column-search -->

      <span class="column-order-by">
        <label for="sort-by">Sort by:</label>
        <select id="sort-by" [(ngModel)]="orderBy">
          <option value="" disabled selected hidden>Filter options</option>
          <option *ngFor="let option of orderingOptions" [value]="option">
            {{option | titlecase}}
          </option>
        </select> <!-- #end select -->
        <button class="btn btn-toggle">
          <!-- (click)="toggleAscending()" -->
          <i class="fa" [class.fa-sort-amount-desc]="!sortAscending" [class.fa-sort-amount-asc]="sortAscending"></i>
        </button> <!-- #end .btn btn-toggle -->
      </span> <!-- #end .column-order-by-->
    </div> <!-- #end .display-options -->

    <div class="well well-fluid">
      <div class="table" [class.hide]="activeTab !== 'view-as-table'">

        <div class="table-head">
          <div class="table-row">

            <span class="column-name">
              Advocate name
            </span> <!-- #end .column-name -->

            <span class="column-email">
              Advocate email
            </span> <!-- #end .column-email -->

            <span class="column-cellular-number">
              Cellular number
            </span> <!-- #end .column-cellular-number -->

            <span class="column-current-matters">
              Current matters
            </span> <!-- #end .column-current-matters -->

            <span class="column-total-outstanding">
              Total outstanding
            </span> <!-- #end .column-total-outstanding -->

            <span class="column-actions">

            </span> <!-- #end .column-actions -->

          </div> <!-- #end .table-row -->
        </div> <!-- #end .table-head -->

        <div class="table-body">
          <div class="table-row" *ngFor="let advocate of data">

            <span class="column-name">
              {{advocate.ProviderName}}
            </span>

            <span class="column-email">
              {{advocate.Email}}
            </span>

            <span class="column-cellular-number">
              {{advocate.Phone}}
            </span>

            <span class="column-current-matters">
              {{advocate.CurrentMatters}}
            </span>

            <span class="column-total-outstanding">
              {{advocate.TotalOutstanding | currency:'R '}}
            </span>

            <span class="column-action">
              <span class="btn-group">
                <span class="clickable" (click)="viewReport(advocate)">
                  View report
                </span>
                <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupMenu(advocate)"></i>
              </span> <!-- #end .btn-group -->
            </span> <!-- #end column-action -->

          </div> <!-- #end .table-row -->

          <div class="table-row">

            <span class="column-name"></span>

            <span class="column-email"></span>

            <span class="column-cellular-number"></span>

            <span class="column-current-matters" style="font-weight: bold">Total Outstanding</span>

            <span class="column-total-outstanding" style="font-weight: bold">{{grandTotal | currency:'R '}}</span>

            <span class="column-action">
              <span class="btn-group">
                <!-- (click)="viewReport(advocate.id)" -->
                <span class="clickable">View report</span>
                <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupMenu()"></i>
              </span>
              <!--#end .btn-group -->
            </span>

          </div> <!-- #end .table-row -->

        </div> <!-- #end .table-body -->

      </div> <!-- #end .table -->
    </div> <!-- #end .well well-fluid-->
  </div>
</div>