<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>Law Firm Profile</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">

        <div class="row">
          <!-- Law firm process -->
          <div class="form-group">
            <label for="lawfirm-process">Law Firm Process <span title="Required field"> *</span></label>
            <textarea
              class="form-control"
              style="height: 90px;"
              id="lawfirm-process"
              [(ngModel)]="workingContactProcess.lawFirmProcess"></textarea>
          </div>
        </div> <!-- #end .row -->
        <div class="row">
          <!-- Contact Person -->
          <div class="form-control" style="flex: 1 !important; border-width: 0 !important; padding-left: 0 !important;">
            <label for="contact-person">Contact Person</label>
            <input
              class="form-control"
              id="contact-person"
              [(ngModel)]="workingContactProcess.contactPersonName">
          </div>
        <!-- </div> #end .row -->
        <!-- <div class="row"> -->
          <!-- Contact Person Email -->
          <div class="form-control" style="flex: 1 !important; border-width: 0 !important; padding-right: 0 !important;">
            <label for="contact-person-email">Contact Person Email</label>
            <input
              class="form-control"
              type="email"
              id="contact-person-email"
              [(ngModel)]="workingContactProcess.contactPersonEmail">
          </div>
        </div> <!-- #end .row -->
        <div class="row">
          <!-- Contact Person Phone Number -->
          <div class="form-control" style="flex: 1 !important; border-width: 0 !important; padding-left: 0 !important;">
            <label for="contact-person-phone-number">Contact Person Phone Number</label>
            <input
              class="form-control"
              type="tel"
              id="contact-person-phone-number"
              [(ngModel)]="workingContactProcess.contactPersonPhone">
          </div>
          <div class="form-control" style="flex: 1 !important; border-width: 0 !important; padding-right: 0 !important;">
            <label for="plaintiffDefendant">Plaintiff or Defendant?</label>
            <select class="form-control" id="plaintiffDefendant" [(ngModel)]="workingContactProcess.plaintiffDefendant">
              <option hidden [value]="null">Select</option>
              <option *ngFor="let plaintiffdefendant of plaintiff"
                [selected]="plaintiffdefendant.value === workingContactProcess.plaintiffDefendant">
                {{plaintiffdefendant.label}}</option>
            </select>
          </div>
        </div> <!-- #end .row -->

        <div class="row">
          <!-- Firm have system -->
          <div class="form-control" style="flex: 1 !important; border-width: 0 !important; padding-left: 0 !important;">
            <label for="hasSystem">Does the firm have a system</label>
            <select
              class="form-control"
              id="hasSystem"
              [(ngModel)]="workingContactProcess.haveSystem">
              <option hidden [value]="null">Select Yes/No</option>
              <option *ngFor="let hassystem of hasSystem" [value]="hassystem.value" [selected]="hassystem.value === workingContactProcess.haveSystem">{{hassystem.label}}</option>
            </select>
          </div>
        <!-- </div> #end .row -->
        <!-- <div class="row"> -->
          <!-- Are they able to provide status feedback -->
          <div class="form-control" style="flex: 1 !important; border-width: 0 !important; padding-right: 0 !important;">
            <label for="abletoprovideFeedback">Are they able to provide status feedback?</label>
            <select 
              class="form-control"
              id="abletoprovideFeedback"
              [(ngModel)]="workingContactProcess.ableToProviceStatusFeedback">
              <option hidden [value]="null">Select</option>
              <option 
                *ngFor="let abletoptovideFeedback of ableToPorvideFeedback"
                  [value]="abletoptovideFeedback.value"
                  [selected]="abletoptovideFeedback.value === workingContactProcess.ableToProviceStatusFeedback">
                {{abletoptovideFeedback.label}}</option>
            </select>
          </div>
        </div> <!--#end .row -->

        <div class="row" *ngIf="workingContactProcess.haveSystem">
          <div class="form-control" style="flex: 1 !important; border-width: 0 !important; padding-left: 0 !important;">
            <label for="systemName">System</label>
            <select
              class="form-control"
              id="systemName"
              [(ngModel)]="workingContactProcess.system">
              <option hidden [value]="null">Select</option>
              <option
                *ngFor="let system of systemName"
                [value]="system.value"
                [selected]="system.value === workingContactProcess.system">
              {{system.label}}</option>
            </select>
          </div>

          <div class="form-control" style="flex: 1 !important; border-width: 0 !important; padding-right: 0 !important;">
            <label for="system-name" *ngIf="showSystemTxt" >Enter System Name</label>
            <input
            *ngIf="showSystemTxt" 
              class="form-control"
              type="text"
              placeholder="Enter system name"
              id="system-name"
              [(ngModel)]="otherSystemName">
          </div>
        </div> <!-- #end .row -->

        <div class="row">
          <!-- Are they willing to provide status feedback -->
          <div class="form-control" style="flex: 1 !important; border-width: 0 !important; padding-left: 0 !important;">
            <label for="_willingtoprovideFeedback">Are they willing to provide status feedback?</label>
            <select
              class="form-control"
              id="_willingtoprovideFeedback"
              [(ngModel)]="workingContactProcess.willingToProviceStatusFeedback">
              <option hidden [value]="null">Select</option>
              <option
                *ngFor="let willingtoprovideFeedback of willingToProvideFeedback"
                [value]="willingtoprovideFeedback.value"
                [selected]="willingtoprovideFeedback.value === workingContactProcess.willingToProviceStatusFeedback">
              {{willingtoprovideFeedback.label}}</option>
            </select>
          </div>
        <!-- </div> #end .row -->
        <!-- <div class="row"> -->
          <!-- Do we receive status feedback -->
          <div class="form-control" style="flex: 1 !important; border-width: 0 !important; padding-right: 0 !important;">
            <label for="receivedfeedback">Do we receive status feedback?</label>
            <select
              class="form-control"
              id="receivedfeedback"
              [(ngModel)]="workingContactProcess.receiveStatusFeedback">
              <option hidden [value]="null">Select</option>
              <option
                *ngFor="let receivefeedback of receiveFeedback"
                [value]="receivefeedback.value"
                [selected]="receivefeedback.value === workingContactProcess.receiveStatusFeedback">
              {{receivefeedback.label}}</option>
            </select>
          </div>
        </div> <!--#end .row -->
        <!-- <div class="row">
          Plaintiff of Defendant
          
        </div> #end .row -->

      </div> <!-- #end .modal-body .form -->

      <div class="modal-footer">
        <button class="btn btn-danger" (click)="dismiss()">Cancel</button>
        <button
          class="btn btn-success"
          (click)="onAddFirmProcess()"
          [class.disabled]="!isSaveable"
          [disabled]="!isSaveable">{{btnSaveText}}</button>
      </div> <!-- #end .modal-footer -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .moda-content -->
</div> <!-- #end .modal -->
