import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable()
export class ExcelServiceService {

  constructor() { } // end constructor()

  static toExportFileName (excelFileName: string): string {
    return `${excelFileName}_export_${new Date()}.xlsx`;
  } // end toExportFileName()

  public exportAsExcelFile (json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: {'data': worksheet}, SheetNames: ['data']};
    XLSX.writeFile(workbook, ExcelServiceService.toExportFileName(excelFileName));
  } // end exportAsExcelFile()
} // end ExcelServiceService{}
