import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../services/messaging/toast/toast.service';
import { AddTeamMemberService, Assistant, DEFAULT_ASSISTANT } from '../../services/addteam/add-team-member.service';
import { SnackbarsService, SnackType, Snack } from '../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../services/utilities.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';


interface AssistantHandle {
  data: Assistant;
  state: {
    isVisible: boolean;
  };
}

@Component({
  selector: 'app-advocate-edit-my-team',
  templateUrl: './advocate-edit-my-team.component.html',
  styleUrls: ['./advocate-edit-my-team.component.scss']
})
export class AdvocateEditMyTeamComponent implements OnInit {
  title = 'EDIT MY TEAM';

  showAddTeamMember = false;
  teamMembers: Assistant[];
  activeTeamMembers: Assistant;
  assistantHandles: AssistantHandle[] = [];

  constructor(
    private util: UtilitiesService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private addTeamMemberService: AddTeamMemberService,
    ) { }

  ngOnInit() {
    this.getTeamMember();
  } // end ngOnInit()

  getTeamMember() {
    this.teamMembers = [];
    const snack: Snack = {
      label: 'Loading team member',
      action: null
    };

    this.loadingService.showOverlay();
    this.addTeamMemberService
      .getAssistant()
      .subscribe({ next: 
        (team) => {
          this.teamMembers = team;
        },
        error: (error) => {
          // On error
          snack.label = 'Error loading added team members';
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack, 5000).show();
        },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
          this.buildingAssistantHandleList();
        }
      });
  } // end getTeamMember()

  buildingAssistantHandleList() {
    this.assistantHandles = [];
    this.teamMembers.forEach(element => {
      // if (element.Role === 1) {
      //   element.RoleName = 'Practice Assistant';
      // } else if (element.Role === 2) {
      //   element.RoleName = 'Admin Assistant';
      // }
      element.Status = 'Confirmed';
      this.assistantHandles.push({
        data: element,
        state: {
          isVisible: true
        }
      });
    });
  } // end buildingAssistantHandleList()

  onEditTeams(team: Assistant) {
    let fullname = team.FullName
      ? team.FullName.split(' ')
      : [];

    if (fullname.length) {
      if (fullname.length === 2) {
        team.FirstName = fullname[0];
        team.LastName = fullname[1];
      } else if (fullname.length > 2) {
        // team.LastName = fullname[0];
        team.FirstName = fullname[1];
        team.LastName = fullname[2];
      }
    }
    this.activeTeamMembers = team;
    this.showAddTeamMember = true;
  }

  onAddNewTeamMember() {
    this.activeTeamMembers = Object.assign({}, DEFAULT_ASSISTANT);
    this.showAddTeamMember = true;
  } // end onAddNewTeamMember()

  onCloseModal() {
    this.showAddTeamMember = false;
  } // end onCloseModal()

}
