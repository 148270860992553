import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { CollectionDebtorProgressHandle } from '../../../services/notes/notes.service';

@Component({
  selector: 'app-debtor-progress',
  templateUrl: './debtor-progress.component.html',
  styleUrls: ['./debtor-progress.component.scss']
})
export class DebtorProgressComponent implements OnInit {

  title = 'Debtor Progress Report';

  @Input() isVisible: boolean;
  @Input() collectionDebtorProgressHandle: CollectionDebtorProgressHandle[];
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private userProfileService: UserProfileService
  ) { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

} // end DebtorProgressComponent {}
