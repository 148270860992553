<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div class="container-fluid">
  <div class="frame">
    <div class="tile">
      <!-- Invoice details -->
      <div class="well">
        <h2 class="well-title">Invoice details</h2>
        <div class="well-row">
          <span class="well-row-cell">Invoice amount</span>
          <span>{{invoice.InvoiceTotal | currency:'R '}}</span>
        </div> <!-- #end .well-row -->
        <div class="well-row">
          <span class="well-row-cell">Invoice date</span>
          <span>{{invoice.InvoiceDate | date:'yyyy-MM-dd'}}</span>
        </div> <!-- #end .well-row -->
        <!-- <div class="well-row">
          <span class="well-row-cell">Invoice Terms</span>
          <span>{{invoiceTerms}}</span>
        </div> #end .well-row -->
        <!-- <div class="well-row" hidden=true>
          <span class="well-row-cell" hidden=true>Payment date</span>
          <span hidden=true>{{invoice.StatementDate | date:'yyyy-MM-dd'}}</span>
        </div> #end .well-row -->
        <!-- <div class="well-row" hidden=true>
          <span class="well-row-cell" hidden=true>Total payment amount</span>
          <span hidden=true>{{invoice.Paid | currency:'R '}}</span>
        </div> #end .well-row -->
        <!-- <div class="well-row" [class.text-danger]="invoice.Due">
          <span class="well-row-cell">Amount outstanding</span>
          <span>{{invoice.Due | currency:'R '}}</span>
        </div> #end .well-row -->
      </div> <!-- #end .well -->

      <!-- Matter details -->
      <div class="well">
        <h2 class="well-title">Invoice details</h2>
        <div class="well-row">
          <span class="well-row-cell">Client name</span>
          <span>{{invoice.ClientName}}</span>
        </div><!-- #end .well-row -->
        <div class="well-row">
          <span class="well-row-cell">Reference</span>
          <span>{{invoice.InvoiceReference}}</span>
        </div><!-- #end .well-row -->
        <div class="well-row">
          <span class="well-row-cell">Plaintiff</span>
          <span>{{plaintiff}}</span>
        </div><!-- #end .well-row -->
        <div class="well-row">
          <span class="well-row-cell">Defendant</span>
          <span>{{defendant}}</span>
        </div><!-- #end .well-row -->
      </div> <!-- #end .well -->

    </div> <!-- #end .tile-->

    <div class="tile">
      <!-- Invoice PDF -->
      <div style="width:100%; height:700px;">
        <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" [openFile]="true" [viewBookmark]="true" [download]="true"
          [downloadFileName]="fileName"></ng2-pdfjs-viewer>
      </div>
    </div> <!-- #end .tile-->

  </div> <!-- #end .frame -->

  <!-- <div class="well">
    <div class="btn-group">
      <span
        class="clickable"
        (click)="resendInvoice()">Resend invoice</span>
        <i class="fa fa-angle-down fa-lg clickable"></i>
    </div>  #end .btn-group 
  </div> #end .well -->

</div> <!-- #end .container-fluid -->
