import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatterQuotationDTO, QuotationService } from '../../../services/quotation/quotation.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { ActivityLogsService } from '../../../services/activity-logs/activity-logs.service';

@Component({
  selector: 'app-add-quotation-accept-reject-reason',
  templateUrl: './add-quotation-accept-reject-reason.component.html',
  styleUrls: ['./add-quotation-accept-reject-reason.component.scss']
})
export class AddQuotationAcceptRejectReasonComponent implements OnInit, OnChanges {

  title = '';
  quotationAcceptRejectNote = '';
  showUnsavedChangesPrompt: boolean;

  @Input() isVisible: boolean;
  @Input() matterQuotation: MatterQuotationDTO;
  @Input() manualAcceptReject: string;
  @Output() closeModal: EventEmitter<string> = new EventEmitter();
  @Output() saveAcceptRejectReason: EventEmitter<MatterQuotationDTO> = new EventEmitter<MatterQuotationDTO>();

  workingMatterQuotation: MatterQuotationDTO;

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private quotationService: QuotationService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
  ) { } // end constructor()

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.matterQuotation, this.workingMatterQuotation);
  } // end isDirty()

  ngOnInit() {
    // setTimeout(() => {
      this.workingMatterQuotation = Object.assign({}, this.matterQuotation);
    // }, 4000);
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (this.matterQuotation) {
      this.title = 'Add a reason for Accepting / Rejecting Quotation for: ' + this.matterQuotation.Description;
    }
  } // end ngOnChanges()

  onSaveNote() {
    this.saveAcceptRejectReason.emit(this.workingMatterQuotation);
    this.matterQuotation = Object.assign(this.matterQuotation, this.workingMatterQuotation);
    this.onHideUnsavedChangesPrompt();
  } // end onSaveNote()

  dismiss() {
    if (this.isDirty) {
      this.showUnsavedChangesPrompt = true;
    } else {
      this.title = '';
      this.closeModal.emit('quotation-note');
    }
  } // end dismiss()

  onCancel() {
    this.dismiss();
  } // end onCancel()

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // onHideUnsavedChangesPrompt()

  leavePage() {
    this.workingMatterQuotation = Object.assign({}, this.matterQuotation);
    this.closeModal.emit('quotation-note');
    this.onHideUnsavedChangesPrompt();
  } // end leavePage()
} // end AddQuotationAcceptRejectReasonComponent{}
