import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { ProcessPaymentService, ProofOfPayment, DEFAULT_PROOF_OF_PAYMENT } from '../../../services/process-payment/process-payment.service';
import * as moment from 'moment';
declare var jQuery: any;
// import { TypeaheadMatch } from 'ngx-bootstrap';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { UsercurrencyDirective } from '../../../directive/usercurrency.directive';
// import { timingSafeEqual } from 'crypto';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { ContactsService } from '../../../services/contacts/contacts.service';
import {
  ActivityLogs,
  DEFAULT_ACTIVITY_LOGS,
  ActivityLogsService
} from '../../../services/activity-logs/activity-logs.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

const TAG_NEW_PROOF_OF_PAYMENT = 'Add Proof of Payment';
const TAG_EDIT_PROOF_OF_PAYMENT = 'Edit Proof of Payment';
const TAG_BTN_NEW_PROOF_OF_PAYMENT = 'SAVE PROOF OF PAYMENT';
const TAG_BTN_EDIT_PROOF_OF_PAYMENT = 'UPDATE PROOF OF PAYMENT';

@Component({
  selector: 'app-add-proof-of-payments',
  templateUrl: './add-proof-of-payment.component.html',
  styleUrls: ['./add-proof-of-payment.component.scss']
})
export class AddProofOfPaymentsComponent implements OnInit, OnChanges, OnDestroy {

  title: string = TAG_NEW_PROOF_OF_PAYMENT;
  btnAddEditPOP: string = TAG_BTN_NEW_PROOF_OF_PAYMENT;
  workingProof: ProofOfPayment; // = Object.assign({}, DEFAULT_PROOF_OF_PAYMENT);
  attachmentFile: File;
  isReadOnly = true;
  attachmentName = '';
  attorney: any;
  formData: FormData = new FormData();
  proofAmount = '';

  AllSelectedFirm: any = [];
  SelectedFirm: any = [];
  lawFirms: any = [];
  allLawFirms: any = [];

  showUnsavedChangesPrompt: boolean;
  showDeletePopPrompt: boolean;
  bsValue = new Date();
  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  @Input() pattern: string | RegExp;
  @Input() proof: ProofOfPayment;
  @Output() refreshPage = new EventEmitter<any>();
  @ViewChild('inputAmount') focusAmount: ElementRef;
  @ViewChild('inputAttachmentFile') inputAttachmentFile: ElementRef;
  @ViewChild('inputUploadFile') inputUploadFile: ElementRef;

  amontPattern = '^[0-9]+(\.[0-9]{1,2})?';

  constructor(
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    private loadingService: LoadingService,
    private contactsService: ContactsService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private currencyDirective: UsercurrencyDirective,
    private processPaymentService: ProcessPaymentService,
    ) {
  } // end constructor()

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  get isAddOrUpdatePOP(): boolean {
    return Boolean(this.proof.Id > 0);
  } // end isAddOrUpdatePOP()

  get isSavable(): boolean {
    return Boolean(this.workingProof.Date)
    && Boolean(this.workingProof.LawFirm) && Boolean(this.workingProof.Reference)
    && Boolean(this.workingProof.Amount > 0) && Boolean(this.workingProof.Description);
  } // end isSavable()

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.proof, this.workingProof);
  } // end isDirty()

  ngOnInit() {
    if (this.proof && this.proof.Id > 0) {
      this.bsValue = moment(this.proof.Date).toDate();
    } else {
      this.proof.Date = moment(this.bsValue).format('YYYY-MM-DDTHH:mm:ss');
    }
    this.getAttorney();
    this.initProofOfPayment();
  } // end ngOnInit()

  onDeleteAttachment() {
    if (this.workingProof.HasAttachment) {
      this.loadingService.showOverlay();

      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Delete Proof of payment document';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Add-proof-of-payment)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'ProofOfPayment';
      this.activityLog.JsonData = JSON.stringify(this.workingProof);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (data) => {}});

      this.processPaymentService.removeProofOfPaymentDocument(this.workingProof).subscribe({ next: (_data) => {
        this.formData.delete('ProofDocument');
        this.inputAttachmentFile.nativeElement.value = '';
        this.inputUploadFile.nativeElement.value = '';
        this.workingProof.HasAttachment = false;
        this.onHideDeletePopPrompt();
        this.proof = this.util.objectCopy(this.workingProof);
        this.refreshPage.emit(this.workingProof.Id);
      }});
      // this.loadingService.hideOverlay();
    }
  } // end onDeleteAttachment()

  onDeletePopPromptShow() {
    this.showDeletePopPrompt = true;
  }

  onHideDeletePopPrompt() {
    this.showDeletePopPrompt = false;
  } // end onHideDeletePopPrompt()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.proof && changes.proof.currentValue) {
      if (this.proof) {
        if (this.proof.Id) {
          this.title = TAG_EDIT_PROOF_OF_PAYMENT;
          this.btnAddEditPOP = TAG_BTN_EDIT_PROOF_OF_PAYMENT;
        } else {
          this.title = TAG_NEW_PROOF_OF_PAYMENT;
          this.btnAddEditPOP = TAG_BTN_NEW_PROOF_OF_PAYMENT;
        }
      } else {
        this.title = TAG_NEW_PROOF_OF_PAYMENT;
        this.btnAddEditPOP = TAG_BTN_NEW_PROOF_OF_PAYMENT;
      }
      // this.initProofOfPayment();
    }
  } // end ngOnChanges()

  ngOnDestroy() {
    this.clearScreen();
  } // end ngOnDestroy()

  onDateValueChange(event: Date) {
    if (event) {
      this.bsValue = event;
      this.workingProof.Date = moment(event).format('YYYY-MM-DDTHH:mm:ss'); //.toDateString();
    }
  } // end onDateValueChange()

  getAttorney() {
    this.loadingService.showOverlay();
    this.contactsService.getLawFirmsByServiceProvider().subscribe({ next: (on_next) => {
      // On next
      this.lawFirms = on_next;
      this.lawFirms.sort((a, b) => {
        return a.FullName.toUpperCase().localeCompare(b.FullName.toUpperCase());
      });
      this.loadingService.hideOverlay();
    }})
  }

  attachmentChange(e) {
    this.attachmentFile = e.target.files[0];
    this.workingProof.Attachment = this.attachmentFile.name;
  }

  promptAttachment() {
    jQuery('#attachmentUpload').val(null);
    jQuery('#attachmentUpload').trigger('click');
  }

  onChangeAttorney(event: TypeaheadMatch): void {

    this.workingProof.LawFirm = event.item.FullName;
    this.workingProof.LawFirmId = event.item.ContactID;

  }

  onSelect(event: TypeaheadMatch): void {
    this.workingProof.LawFirm = event.item.FullName;
  }

  addProofOfPayment() {
    const snack: Snack = {
      label: 'Add Proof of Payment',
      action: null
    };
    this.loadingService.showOverlay();
    try {

      if (this.attachmentFile) {
        this.formData.append('ProofDocument', this.attachmentFile);
      }

      if (this.workingProof.Date !== undefined
        && this.workingProof.Amount !== 0 && this.workingProof.Reference !== undefined
        && this.workingProof.LawFirmId !== undefined) {

        // this.workingProof.amount = Number.parseFloat(this.workingProofAmount);
        this.workingProof.Date = moment(this.workingProof.Date).format('YYYY-MM-DD');
        this.workingProof.Allocated = '';
        this.proof.ProofOfPaymentLines = [];

        // Log activity Login
        const currentDate = new Date();
        this.activityLog.Action = 'Add Proof of payment document';
        this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (Add-proof-of-payment)';
        if (this.userProfileService.userProfile.isImpersonator) {
          this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
          this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'ProofOfPayment';
        this.activityLog.JsonData = JSON.stringify(this.workingProof);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_data) => {}});

        //const paymentProofId =  
        this.processPaymentService.postProofOfPayment(this.workingProof).subscribe({ next: (_data) => {
          let paymentProofId = _data;
          if (this.attachmentFile && paymentProofId) {
            this.processPaymentService.postProofDocumentAttachment(paymentProofId, this.formData).subscribe({ next: (_data) => {
              // On next
            },
            error: (error) => {
              // On error
            },
            complete: () => {
              // On complete
              this.proof = this.util.objectCopy(this.workingProof);
              this.refreshPage.emit(paymentProofId);
              this.closeModel(paymentProofId);
              snack.label = 'Added Proof of Payment.';
              snack.type = SnackType.SUCCESS;
              this.loadingService.hideOverlay();
              this.snackbarsService.dismiss().make(snack).show();
            }
          });
          } else {
            this.proof = this.util.objectCopy(this.workingProof);
              this.refreshPage.emit(paymentProofId);
              this.closeModel(paymentProofId);
              snack.label = 'Added Proof of Payment.';
              snack.type = SnackType.SUCCESS;
              this.loadingService.hideOverlay();
              this.snackbarsService.dismiss().make(snack).show();
          }         
        }});
      }
    } catch (e) {
      snack.label = 'Something went wrong';
      snack.action = null;
      snack.type = SnackType.ERROR;
      this.loadingService.hideOverlay();
      this.snackbarsService.dismiss().make(snack).show();
    }
  } // end addProofOfPayment()

  updateProofOfPayment() {
    const snack: Snack = {
      label: 'Update Proof of Payment',
      action: null
    };
    this.loadingService.showOverlay();
    try {
      if (this.workingProof.Id) {

        if (this.attachmentFile) {
          this.formData.append('ProofDocument', this.attachmentFile);
        }

        if (this.workingProof.Date !== undefined
          && this.workingProof.Amount !== 0 && this.workingProof.Reference !== undefined
          && this.workingProof.LawFirmId !== undefined) {

          // this.workingProof.amount = Number.parseFloat(this.workingProofAmount);
          this.workingProof.Date = moment(this.workingProof.Date).format('YYYY-MM-DD');
          this.workingProof.Allocated = '';
          this.proof.ProofOfPaymentLines = [];

          // Log activity Login
          const currentDate = new Date();
          this.activityLog.Action = 'Edit Proof of payment document';
          this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
          this.activityLog.LoggedApp = 'Web Application (Add-proof-of-payment)';
          if (this.userProfileService.userProfile.isImpersonator) {
            this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
          } else {
            this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
          }
          this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
          this.activityLog.ActionTable = 'ProofOfPayment';
          this.activityLog.JsonData = JSON.stringify(this.workingProof);
          this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_data) => {}});

          // const paymentProofId =  
          this.processPaymentService.putProofOfPayment(this.workingProof).subscribe({ next: (_data) => {
            let paymentProofId = _data;
            if (this.attachmentFile && this.workingProof.Id) {
               this.processPaymentService.postProofDocumentAttachment(this.workingProof.Id, this.formData).subscribe({ next: (_data) => {
                 // On next
               },
               error: (error) => {
                 // On error
               },
               complete: () => {
                 // On complete
                this.proof = this.util.objectCopy(this.workingProof);
                this.refreshPage.emit(this.workingProof);
                this.closeModel(paymentProofId);
                snack.label = 'Updated Proof of Payment.';
                snack.type = SnackType.SUCCESS;
                this.loadingService.hideOverlay();
                this.snackbarsService.dismiss().make(snack).show();
               }
              });
            } else {
              this.proof = this.util.objectCopy(this.workingProof);
            this.refreshPage.emit(this.workingProof);
            this.closeModel(paymentProofId);
            snack.label = 'Updated Proof of Payment.';
            snack.type = SnackType.SUCCESS;
            this.loadingService.hideOverlay();
            this.snackbarsService.dismiss().make(snack).show();
            }            
          }});
        }
      }
    } catch (e) {
      snack.label = 'Something went wrong';
      snack.action = null;
      snack.type = SnackType.ERROR;
      this.loadingService.hideOverlay();
      this.snackbarsService.dismiss().make(snack).show();
    }
  } // end updateProofOfPayment()



  closeModel(value: string) {
    this.refreshPage.emit(this.workingProof);
  } // end closeModel()

  getDateFormet(value: Date): string {
    return moment(value).format('DD/MM/YY');
  }

  // numberConversion(value: number): string {
  //   return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  // }


  // formatNumber(e) {
  //   var value = e.target.value;
  //   let prefix: string = value.toString().split('.')[0];
  //   let suffix: string = value.toString().split('.')[1];
  //   try {
  //     if (suffix.length > 2) {
  //       let suffix_subed = suffix.substr(0, 2);
  //       this.proofAmount = prefix + '.' + suffix_subed;
  //     }
  //   } catch (error) {
  //   }
  // }



  onInput(event) {
    if (event.inputType === 'deleteContentBackward') {
      this.SelectedFirm = this.lawFirms;
      this.AllSelectedFirm = this.allLawFirms;
    }
  }

  onAmountFocus(event) {
    this.util.format(event.target.value);
  } // end onAmountFocus()

  onBlurAmountChange() {
    // check if Advocate user or Assistant user
    // this.focusAmount.nativeElement.value =
    //   this.focusAmount.nativeElement.value.toFixed(2).replace(/[^0-9]/, ' ');
    // if (!this.focusAmount.nativeElement.value.startsWith('R')) {
      if (this.userProfileService.userProfile.isImpersonator) {
        this.focusAmount.nativeElement.value = this.cpipe.transform(this.focusAmount.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
      } else {
        this.focusAmount.nativeElement.value = this.cpipe.transform(this.focusAmount.nativeElement.value,
          this.userProfileService.userProfile.currencyDetails.symbol, 2);
      }
    // }
  } // end onBlurAmountChange()

  onClickAmount() {
    // check if Advocate user or Assistant user
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusAmount.nativeElement.value = this.cpipe.parse(this.focusAmount.nativeElement.value,
      this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.focusAmount.nativeElement.value = this.cpipe.parse(this.focusAmount.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onClickAmount()

  initProofOfPayment() {
    this.workingProof = this.util.objectCopy(this.proof);
  } // end initProofOfPayment()

  clearScreen() {
    this.title = TAG_NEW_PROOF_OF_PAYMENT;
    this.btnAddEditPOP = TAG_BTN_NEW_PROOF_OF_PAYMENT;
    this.workingProof = Object.assign({}, DEFAULT_PROOF_OF_PAYMENT);
    this.proof = Object.assign({}, DEFAULT_PROOF_OF_PAYMENT);
  } // end clearScreen()

  onShowUnsavedChangesPrompt(value: string) {
    if (this.isDirty) {
      this.showUnsavedChangesPrompt = true;
    } else {
      this.dismiss(value);
    }
  } // end onShowUnsavedChangesPrompt()

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end onShowUnsavedChangesPrompt()

  dismiss(value: string) {
    this.refreshPage.emit(value);
    this.clearScreen();
    this.onHideUnsavedChangesPrompt();
  } // end dismiss()
}

