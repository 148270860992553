import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { PATH_COLLECTION_REPORT, PATH_CONTACT_MANAGEMENT } from '../../services/appdata/app-config.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {


  //#region Invite Screen
    showInviteScreen: boolean;
  //#endregion Invite Screen

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

   // Is Practice Manager
   get isPracticeManager(): boolean {
    // console.log('SELECTED TAB ON : ', this.userProfileService.currentOrRequestedTab === 'quotation');
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER &&
      Boolean(this.userProfileService.userProfile.serviceProviderID ===
        this.userProfileService.selectedUserProfile.serviceProviderID));
  } // end isPracticeManager()
  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService
  ) { } // end constructor()

  ngOnInit(): void {
  } // end ngOnInit()

  onClick(adminTab: string): void {
    this.loadingService.showOverlay();
    switch(adminTab) {
      case 'contact-management':
        this.router.navigate([PATH_CONTACT_MANAGEMENT]);
        this.loadingService.hideOverlay();
      break;

      case 'collection-report':
        this.router.navigate([PATH_COLLECTION_REPORT]);
        this.loadingService.hideOverlay();
      break;

      case 'invite':
        this.showInviteScreen = true;
        this.loadingService.hideOverlay();
      break;
    }
  } // end onClick()

  onHideInviteScreen() {
    this.showInviteScreen = false;
  } // end onHideInviteScreen()

} // end AdminComponent{}
