<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">
            <div class="modal-header">
                <h2>{{title}}</h2>
                <button
                    class="modal-dismiss"
                    (click)="onDismiss()">
                    <i class="fa fa-close"></i>
                </button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="form-group">
                        <label for="advocate">Search for name</label>
                        <input 
                            [(ngModel)]="selectedAdvocate && selectedAdvocate.FullName" 
                            [typeahead]="advocateList"
                            typeaheadOptionField="FullName"
                            class="form-control"
                            placeholder="Select or start typing"
                            (typeaheadOnSelect)="onSelectContact($event)" 
                            [typeaheadOptionsLimit]="50" 
                            typeaheadScrollable=true
                            typeaheadOptionsInScrollableView="20" 
                            [typeaheadMinLength]="0">
                    </div>
                </div>

                <div class="form-group">
                    <label>Can View Financials &nbsp;&nbsp;</label>
                    <div class="radio junior">
                        <label class="junior">
                          <input
                            type="radio"
                            name="junior"
                            (click)="toggleViewFinancials()"
                            [checked]="canViewFinancials"> Yes
                          </label>
                        <label>
                          <input
                            type="radio"
                            (click)="toggleViewFinancials()"
                            [checked]="!canViewFinancials"
                            name="junior">No
                          </label>
                      </div>
                </div>
            </div>

            <div class="modal-footer">
                <button
                    class="btn btn-danger"
                    (click)="onDismiss()">Cancel</button>

                <button              
                    class="btn btn-success"
                    (click)="save()">Assign</button>
            </div>
        </div>
    </div>
</div>
<!-- [class.disabled]="!canSaveFirm"
[disabled]="!canSaveFirm" -->