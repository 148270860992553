import {
  Component,
  OnInit
} from '@angular/core';
import { DashboardDataService } from '../../../services/dashboard-data/dashboard-data.service';
import { Router } from '@angular/router';
import { PATH_REPORTS } from '../../../services/appdata/app-config.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { ApiService } from '../../../services/api.service';
import { UtilitiesService } from '../../../services/utilities.service';


@Component({
  selector: 'app-dashboard-overview',
  templateUrl: './dashboard-overview.component.html',
  styleUrls: ['./dashboard-overview.component.scss']
})
export class DashboardOverviewComponent implements OnInit {
  averagePaymentDays = 0;
  averageDayColor = '';
  agePercentage = 0;
  agePercentageColor = '';
  outstandingAmount = 0;
  workSummary: string;
  workSummaryColor = '';
  
  vat = 0;
  provisionalTax = 0;
  ptd = 0;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  get isRegisteredforVAT(): boolean {
    if (this.userProfileService.userProfile.isImpersonator) {
    return Boolean(this.util.hasTextValue(this.userProfileService.selectedUserProfile.personalDetails.vatNumber));
    } else {
      return Boolean(this.util.hasTextValue(this.userProfileService.userProfile.personalDetails.vatNumber));
    }
  } // end isRegisteredForVAT()

  constructor(
    private router: Router,
    private api: ApiService,
    private util: UtilitiesService,
    private dashboardDataService: DashboardDataService,
    private userProfileService: UserProfileService,
  ) { } // end constructor()

  ngOnInit() {
    if(this.api.serviceProviderID && this.api.serviceProviderID !== undefined) {
      this.getAveragePaymentDays();
      this.getDashboardAgeAnalysis();
      this.getDashboardCurrentMonthlyData();
      this.getTaxLiabilityData();
    }
  }

  getAveragePaymentDays(): void {
    this.dashboardDataService.getAveragePaymentDays().subscribe(response => {
      if (response && response.length > 0) {
        this.averagePaymentDays = response[0].averageDaysToPayment;
      } else {
        this.averagePaymentDays = 0;
      }

      if (this.averagePaymentDays < 90) {
        this.averageDayColor = '#8abd37';
      } else if (this.averagePaymentDays >= 90 && this.averagePaymentDays < 120) {
        this.averageDayColor = '#e5c100';
      } else if (this.averagePaymentDays >= 120 && this.averagePaymentDays < 150) {
        this.averageDayColor = '#eb9e02';
      } else if (this.averagePaymentDays >= 150) {
        this.averageDayColor = '#cf624f';
      }
    }, error => {

    });
  }

  getDashboardAgeAnalysis(): void {

    this.dashboardDataService.getAgeAnalysis().subscribe(response => {
      if (response) {
        this.agePercentage = response.aging98Percentage;
        for (const item of response.frequencyTotals) {
          this.outstandingAmount += item.totalOutstanding;
        }
      } else {
        this.agePercentage = 0;
        this.outstandingAmount = 0;
      }

      if (this.agePercentage <= 25) {
        this.agePercentageColor = '#8abd37';
      } else if (this.agePercentage >= 26 && this.agePercentage <= 49) {
        this.agePercentageColor = '#e5c100';
      } else if (this.agePercentage >= 50 && this.agePercentage <= 75) {
        this.agePercentageColor = '#eb9e02';
      } else if (this.agePercentage >= 76) {
        this.agePercentageColor = '#cf624f';
      }

    });
  }

  getDashboardCurrentMonthlyData(): void {
    this.dashboardDataService.getCurrentMonthly().subscribe(response => {
      if (response) {

        let thisTotalExcl = 0;
        let lastTotalExcl = 0;
        let last2TotalExcl = 0;
        this.workSummary = response.WorkSummary;

        last2TotalExcl =  response.WorkSummaryDecimal -
                          response.ThisTotalExclDecimal -
                          response.LastTotalExclDecimal -
                          response.Last2TotalExclDecimal;

        lastTotalExcl = response.WorkSummaryDecimal -
                        response.ThisTotalExclDecimal -
                        response.LastTotalExclDecimal;

        thisTotalExcl = response.WorkSummaryDecimal -
                        response.ThisTotalExclDecimal;

        if (last2TotalExcl > 0 ) {
          this.workSummaryColor = '#cf624f';
        } else if (lastTotalExcl > 0 ) {
          this.workSummaryColor = '#eb9e02';
        } else if (thisTotalExcl > 0 ) {
          this.workSummaryColor = '#e5c100';
        } else {
          this.workSummaryColor = '#8abd37';
        }
      }
    });
  }

  getTaxLiabilityData() {
    this.dashboardDataService.getTaxLiabilityData().subscribe({ next: (data) => {
      // On next
      this.vat = data.taxAmount;
      this.provisionalTax = data.provisionalTaxAmount;
      this.ptd = data.provisonal_Tax;
    }})
  }

  numberConversion(value: number): string {
    return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  onItemClick(reportName): void {
    this.router.navigate([PATH_REPORTS + '/' + reportName]);
  }

}

