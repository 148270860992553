<!-- Invoices available to add to this proof of payment -->
<div *ngIf="promptInvoices" class="row unpaid-container">
    <div class="row unpaid-header">
        Invoices available to add to bank transaction
    </div>

    <div class="row unpaid-actions">
        <div class=" col-sm-2 m-auto">Actions</div>
        <div class=" col-sm-4 m-auto pl-0" [class.active]="showActions">
            <a [class.active]="showActions" (click)="promptShowProofOfPaymentError()"><i class="fal fa-file-plus"></i>
                <u>Add selected invoices</u>
            </a>
        </div>

        <div class=" col-sm-6 m-auto pl-0">
          
        </div>
    </div>

    <div class="row unpaid-search input-group">
        <input type="search" class="form-control" tabindex="-1" placeholder="Search invoices" [(ngModel)]="searchTerm"
            (keyup)="performSearch()">
        <div class="input-group-append">
            <i *ngIf="!searchTerm" class="far fa-search"></i>
            <i *ngIf="searchTerm" (click)="clearSearch()" class="fa fa-close"></i>
        </div>
    </div>
    <div class="row unpaid-body">
        <div class="table-responsive" style="max-height: 200px;">
            <table class="table ">
                <tbody>
                    <tr>
                        <th scope="col">
                            <div class="checkbox">
                                <input type="checkbox" #inputCheckbox (change)="toggleSelectAll()">
                            </div>
                        </th>
                        <th scope="col" style="min-width: 79px;" (click)="toggleSort('date')">Date
                            <span style="float:right;"><i class="fa fa-sort" [class.fa-sort-down]="sortDate"></i></span>
                        </th>
                        <th scope="col" (click)="toggleSort('invoice')">Invoice
                            <span style="float:right;"><i class="fa fa-sort"
                                    [class.fa-sort-down]="sortInvoice"></i></span></th>
                        <th *ngIf="!isAttorney && !isLawfirm" scope="col" (click)="toggleSort('lawfirm')">Law Firm
                            <span style="float:right;"><i class="fa fa-sort"
                                    [class.fa-sort-down]="sortLawfirm"></i></span></th>
                        <th *ngIf="isAttorney || isLawfirm" scope="col" (click)="toggleSort('lawfirm')">Client
                            <span style="float:right;"><i class="fa fa-sort"
                                    [class.fa-sort-down]="sortLawfirm"></i></span></th>
                        <th scope="col" (click)="toggleSort('matter')">Matter
                            <span style="float:right;"><i class="fa fa-sort"
                                    [class.fa-sort-down]="sortMatter"></i></span></th>
                        <th scope="col" (click)="toggleSort('reference')">Reference
                            <span style="float:right;"><i class="fa fa-sort"
                                    [class.fa-sort-down]="sortReference"></i></span></th>
                        <th scope="col" (click)="toggleSort('amount')" style="min-width: 79px;" class="text-right">
                            Amount
                            <span style="float:right;"><i class="fa fa-sort"
                                    [class.fa-sort-down]="sortAmount"></i></span></th>
                        <th scope="col" class="text-right">Paid</th>
                    </tr>
                    <tr *ngFor="let invoice of availableInvoiceHandle">
                        <td *ngIf="invoice.state.isVisible" scope="row">
                            <!-- <div class="checkbox">
                                <input type="checkbox" (change)="onChangeCheckBox(invoice.data, $event.target.checked)"
                                    id="u{{invoice.data.InvoiceId}}">
                                <label for="u{{invoice.data.InvoiceId}}"></label>
                            </div> -->
                            <app-small-checkbox [(checked)]="invoice.state.isSelected"
                                (click)="toggleCheckbox(invoice)"></app-small-checkbox>
                        </td>
                        <td *ngIf="invoice.state.isVisible" style="min-width: 79px;"
                            [title]="invoice.data.Date | date:'yyyy-MM-dd'" (click)="toggleCheckbox(invoice)">
                            {{invoice.data.Date | date:'yyyy-MM-dd'}}</td>
                        <td *ngIf="invoice.state.isVisible" [title]="invoice.data.InvoiceNo"><u class="clickable"
                                (click)="onViewInvoice(invoice.data.InvoiceId)">{{invoice.data.InvoiceNo}}</u></td>
                        <td *ngIf="invoice.state.isVisible" [title]="invoice.data.Client"
                            (click)="toggleCheckbox(invoice)" class="text-truncate">{{invoice.data.Client}}</td>
                        <td *ngIf="invoice.state.isVisible" [title]="invoice.data.Matter"
                            (click)="toggleCheckbox(invoice)" class="text-truncate">{{invoice.data.Matter}}</td>
                        <td *ngIf="invoice.state.isVisible" [title]="invoice.data.Reference"
                            (click)="toggleCheckbox(invoice)" class="text-truncate">{{invoice.data.Reference}}</td>
                        <td *ngIf="invoice.state.isVisible" style="min-width: 79px;"
                            [title]="invoice.data.Amount | currency:currencySymbol" (click)="toggleCheckbox(invoice)"
                            class="text-right">{{invoice.data.Amount | currency:currencySymbol}}</td>
                        <td *ngIf="invoice.state.isVisible" [title]="invoice.data.Paid | currency:currencySymbol"
                            (click)="toggleCheckbox(invoice)" class="text-right">
                            {{invoice.data.Paid | currency:currencySymbol}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- <app-proforma-invoice
    #proformaInvoice
    title="Invoice"
    popId="0"
    [invoiceId]="selectedInvoiceId"
    documentType="Invoice"
    matter="null"
    [isVisible]="showProformaInvoice"
    (closeModal)="closeModal('proforma-invoice')"></app-proforma-invoice> -->
<!-- 
<app-invoice-preview-modal *ngIf="showProformaInvoice" #invoicePreview [plaintiff]="invoicePlaintiff"
    [defendant]="invoiceDefendant" [invoiceId]="selectedInvoiceId" [invoiceDetails]="invoiceDetails"
    [isVisible]="showProformaInvoice" (closeModal)="closeModal('proforma-invoice')"></app-invoice-preview-modal> -->
<app-invoice-preview-modal 
    *ngIf="showProformaInvoice" 
    #invoicePreview 
    [notesHandle]="notesHandle"
    [plaintiff]="invoicePlaintiff" 
    [selectedTab]="selectedTab" 
    [defendant]="invoiceDefendant"
    [invoiceId]="selectedInvoiceId" 
    [invoiceDetails]="invoiceDetails" 
    [feesQuoted]="feesQuoted"
    [isVisible]="showProformaInvoice"
    (closeModal)="closeModal('proforma-invoice')">
</app-invoice-preview-modal>

<app-prompt-unsaved-changes-modal title="Invoice(s) linked to Proof of Payment"
    message="This invoice is linked to a Proof of Payment not recognised by the system. By proceeding with allocation the Proof of Payment will be discarded and your transaction will be linked to the invoice. Do you wish to proceed? "
    [isVisible]="showLinkedToPOPPrompt" btnTextContinue="Proceed" btnTextCancel="Resolve"
    (cancel)="onHideLinkedToPOPPrompt()" (continue)="onProofOfPaymentDelete()"></app-prompt-unsaved-changes-modal>

