<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onCancel()">
          <i class="fa fa-close"></i>
        </button>
      </div>
      <!--#end .modal-header -->

      <div class="modal-body">
        <aside>
          <i class="fa fa-exclamation-triangle fa-lg text-warning"></i>
        </aside>
        <div class="message">
          <p>{{message}}</p>
        </div>
      </div>
      <!--#end .modal-body -->

      <div class="modal-footer">
        <button class="btn btn-danger" (click)="onCancel()">{{btnTextCancel}}</button>
        <span></span>
        <button class="btn btn-success" (click)="onConfirm()">{{btnTextConfirm}}</button>
      </div>
      <!--#end .modal-footer -->

    </div>
    <!--#end .modal-dialog -->
  </div>
  <!--#end .modal-content -->
</div>
<!--#end .modal -->