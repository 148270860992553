import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  PracticeWidgetSummary, OutstandingBookSummary, DEFAULT_OUTSTANDING_BOOK_SUMMARY
} from '../../../services/practice-manager-dashoboard-service/practice-manager-dashboard.service';
import {
  UserProfileService
} from '../../../services/user-profile/user-profile.service';
import {
  AdvocateOverviewWidget, OverdueByFirm
} from '../../../services/advocate-overview-service/advocate-overview-service.service';
import { DashboardDataService } from '../../../services/dashboard-data/dashboard-data.service';
// import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { UtilitiesService } from '../../../services/utilities.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';

@Component({
  selector: 'app-advocate-overview-dashboard',
  templateUrl: './advocate-overview-dashboard.component.html',
  styleUrls: ['./advocate-overview-dashboard.component.scss']
})
export class AdvocateOverviewDashboardComponent implements OnInit, OnChanges {

  workSummary: string;
  workSummaryColor = '';
  // Invoice Target Percentage
  invoiceTarget = 0;
  collectedTarget = 0;
  invoicedThisMonth = 0;
  collectedThisMonth = 0;
  totalCollactable = 0;
  @Input() invoicedData: any;
  @Input() collectedData: any;
  @Input() widgetData: AdvocateOverviewWidget;

  lawfirmOne: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);
  lawfirmTwo: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);
  lawfirmThree: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);
  lawfirmFour: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);
  allFirms: OutstandingBookSummary = Object.assign({}, DEFAULT_OUTSTANDING_BOOK_SUMMARY);

  get getInvoicedColor(): string {
    if (this.widgetData.InvoicedTarget >= 0 && this.widgetData.InvoicedTarget <= 25) {
      return '#8abd37';
    } else if (this.widgetData.InvoicedTarget >= 26 && this.widgetData.InvoicedTarget <= 50) {
      return '#e5c100';
    } else if (this.widgetData.InvoicedTarget >= 51 && this.widgetData.InvoicedTarget <= 75) {
      return '#eb9e02';
    } else if (this.widgetData.InvoicedTarget >= 76) {
      return '#cf624f';
    }
  } // end getInvoicedColor()

  get getCollectedColor(): string {
    if (this.widgetData.CollectionTarget >= 0 && this.widgetData.CollectionTarget <= 25) {
      return '#8abd37';
    } else if (this.widgetData.CollectionTarget >= 26 && this.widgetData.CollectionTarget <= 50) {
      return '#e5c100';
    } else if (this.widgetData.CollectionTarget >= 51 && this.widgetData.CollectionTarget <= 75) {
      return '#eb9e02';
    } else if (this.widgetData.CollectionTarget >= 76) {
      return '#cf624f';
    }
  } // end getCollectedColor()

  get getTotalCollectableColor(): string {
    if (this.collectedTarget >= 0 && this.collectedTarget <= 25) {
      return '#cf624f';
    } else if (this.collectedTarget >= 26 && this.collectedTarget <= 50) {
      return '#eb9e02';
    } else if (this.collectedTarget >= 51 && this.collectedTarget <= 75) {
      return '#e5c100';
    } else if (this.collectedTarget >= 76) {
      return '#8abd37';
    }
  } // end getInvoicingAdvocateColor()

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  @Input() overdueByFirm: OverdueByFirm[];
  constructor(
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    private userProfileService: UserProfileService,
    private dashboardDataService: DashboardDataService,
  ) { } // end constructor()

  get getTotalInvoicedColor(): string {
    if (this.invoiceTarget >= 0 && this.invoiceTarget <= 25) {
      return '#8abd37';
    } else if (this.invoiceTarget >= 26 && this.invoiceTarget <= 50) {
      return '#e5c100';
    } else if (this.invoiceTarget >= 51 && this.invoiceTarget <= 75) {
      return '#eb9e02';
    } else if (this.invoiceTarget >= 76) {
      return '#cf624f';
    }
  } // end getAdvocateReviewColor()

  ngOnInit() {
    // Law firm one
    if (this.overdueByFirm.length > 0) {
      this.lawfirmOne.Due = this.overdueByFirm[0].TotalOutstanding;
    }

    // Law firm two
    if (this.overdueByFirm.length > 1) {
      this.lawfirmTwo.Due = this.overdueByFirm[1].TotalOutstanding;
    }

    // Law firm three
    if (this.overdueByFirm.length > 2) {
      this.lawfirmThree.Due = this.overdueByFirm[2].TotalOutstanding;
    }

    // Law firm four
    if (this.overdueByFirm.length > 3) {
      this.lawfirmFour.Due = this.overdueByFirm[3].TotalOutstanding;
    }

    let totalDue = 0;
    const totalDisplayedFirms = Number(this.lawfirmOne.Due) + Number(this.lawfirmTwo.Due) +
      Number(this.lawfirmThree.Due) + Number(this.lawfirmFour.Due);
    this.overdueByFirm.forEach(_due => {
      totalDue = totalDue + _due.TotalOutstanding;
    });
    this.totalCollactable = totalDue;
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (this.invoicedData) {
      this.getInvoiceTargetData(this.invoicedData.monthly);
    }
    if (this.collectedData) {
      this.getCollectedTargetData(this.collectedData.monthly);
    }
  } // end ngOnChanges()

  getInvoiceTargetData(monthly: any) {
    this.invoicedThisMonth = monthly.invoicedThismonthValue;
    const target = this.cpipe.parse(monthly.invoicedMonthlyTarget, this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    this.invoiceTarget = Math.abs(Math.round(Number(monthly.invoicedThismonthValue) / Number(target.replace(/\s/g, '')) * 100));
    if (!this.invoiceTarget) {
      this.invoiceTarget = 0;
    }
  } // end getInvoiceTargetData()

  getCollectedTargetData(monthly: any) {
    this.collectedThisMonth = monthly.receiptThismonthValue;
    const target = this.cpipe.parse(monthly.receiptMonthlyTarget, this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    this.collectedTarget = Math.abs(Math.round(Number(monthly.receiptThismonthValue) / Number(target.replace(/\s/g, '')) * 100));
    if (!this.collectedTarget) {
      this.collectedTarget = 0;
    }
  } // end getCollectedTargetData()

  getDashboardCurrentMonthlyData(): void {
    this.dashboardDataService.getCurrentMonthly().subscribe({
      next: (response) => {
        if (response) {

          let thisTotalExcl = 0;
          let lastTotalExcl = 0;
          let last2TotalExcl = 0;
  
          this.workSummary = response.workSummary;
  
          last2TotalExcl = response.workSummaryDecimal -
            response.thisTotalExclDecimal -
            response.lastTotalExclDecimal -
            response.last2TotalExclDecimal;
  
          lastTotalExcl = response.workSummaryDecimal -
            response.thisTotalExclDecimal -
            response.lastTotalExclDecimal;
  
          thisTotalExcl = response.workSummaryDecimal -
            response.thisTotalExclDecimal;
  
          if (last2TotalExcl > 0) {
            this.workSummaryColor = '#cf624f';
          } else if (lastTotalExcl > 0) {
            this.workSummaryColor = '#eb9e02';
          } else if (thisTotalExcl > 0) {
            this.workSummaryColor = '#e5c100';
          } else {
            this.workSummaryColor = '#8abd37';
          }
        }
      }
    });
      
      
      
    //   response => {
    //   if (response) {

    //     let thisTotalExcl = 0;
    //     let lastTotalExcl = 0;
    //     let last2TotalExcl = 0;

    //     this.workSummary = response.workSummary;

    //     last2TotalExcl = response.workSummaryDecimal -
    //       response.thisTotalExclDecimal -
    //       response.lastTotalExclDecimal -
    //       response.last2TotalExclDecimal;

    //     lastTotalExcl = response.workSummaryDecimal -
    //       response.thisTotalExclDecimal -
    //       response.lastTotalExclDecimal;

    //     thisTotalExcl = response.workSummaryDecimal -
    //       response.thisTotalExclDecimal;

    //     if (last2TotalExcl > 0) {
    //       this.workSummaryColor = '#cf624f';
    //     } else if (lastTotalExcl > 0) {
    //       this.workSummaryColor = '#eb9e02';
    //     } else if (thisTotalExcl > 0) {
    //       this.workSummaryColor = '#e5c100';
    //     } else {
    //       this.workSummaryColor = '#8abd37';
    //     }
    //   }
    // }, error => {
    // });
  }

} // end AdvocateOverviewDashboard{}
