import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TAG_CREATE_RECIPIENT_MODAL } from '../../../services/tags/tags.service';
import { UtilitiesService } from '../../../services/utilities.service';

const TITLE_ENTER_RECIPIENTS = 'Forward Message';

@Component({
  selector: 'app-create-recipient-modal',
  templateUrl: './create-recipient-modal.component.html',
  styleUrls: ['./create-recipient-modal.component.scss']
})
export class CreateRecipientModalComponent implements OnInit, OnChanges {

  @Input() isVisible: boolean;
  @Input() _title: string;
  @Input() _btnText: string;
  // @Input() recipients: string;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() saveRecipients: EventEmitter<any> = new EventEmitter();

  title: string = TITLE_ENTER_RECIPIENTS;
  btnText: string = TITLE_ENTER_RECIPIENTS;
  recipients = '';

  constructor(
    private util: UtilitiesService
  ) {
    this.reset();
  } // end constructor()

  ngOnInit() {
    if (this._title) {
      this.title = this._title;
    } else {
      this.title = TITLE_ENTER_RECIPIENTS;
    }
    if (this._btnText) {
      this.btnText = this._btnText;
    } else {
      this.btnText = TITLE_ENTER_RECIPIENTS;
    }
  } // end ngOnInti()

  get hasContactEmailError(): boolean {
    return !this.util.areValidEmails(this.recipients);
  } // end hasContactEmailError()

  get isSaveable(): Boolean {
    return this.util.areValidEmails(this.recipients);
  } // end isSaveable()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.feeTemplate &&
        changes.feeTemplate.previousValue !== changes.feeTemplate.currentValue) {
      // this.model = Object.assign(this.model, changes.feeTemplate.currentValue);

      // this.title = TITLE_ENTER_RECIPIENTS;
    }
  } // end ngOnChanges{}

  private reset() {
    // this.title = TITLE_ENTER_RECIPIENTS;
    this._title = '';
    this._btnText = '';
    this.recipients = '';
  } // end reset()

  onDescriptionChange(description: string) {

  } // end onDescriptionChange()

  dismiss() {
    this.reset();
    this.closeModal.emit(TAG_CREATE_RECIPIENT_MODAL);
  } // end dismiss()

  /**
   * Saves the fee template.
   */
  save() {
    this.saveRecipients.emit(this.recipients);
    this.dismiss();
  } // end save()

  validate() {

  } // end validate()

} // end CreateRecipientModalComponent{}
