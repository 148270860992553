import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { catchError, Observable, retry } from 'rxjs';
import { ApiService } from '../api.service';
import { map } from 'rxjs/operators';

export interface ManageBlackListRequest {
  LawfirmBlackListID: number;
  LawfirmID: number;
  ReportingBarId: string;
  ReportingDate: Date;
  DemandDate: Date;
  ReportingAdvocateID: number;
  HasConfirmedPopiCompliance: boolean;
  UserName: string;
  FullName: string;
  Cell: string;
  Email: string;
  FaxNumber: string;
  VatNumber: string;
  PostalAddress: string;
  StreetAddress: string;
  ChamberId: Number;
  BarSocietyName: string;
}

export const MANAGE_BLACKLIST_REQUEST: ManageBlackListRequest = {
  LawfirmBlackListID: 0,
  LawfirmID: 0,
  ReportingBarId: '',
  ReportingDate: new Date(),
  DemandDate: new Date(),
  ReportingAdvocateID: 0,
  HasConfirmedPopiCompliance: false,
  UserName: '',
  FullName: '',
  Cell: '',
  Email: '',
  FaxNumber: '',
  VatNumber: '',
  PostalAddress: '',
  StreetAddress: '',
  ChamberId: 0,
  BarSocietyName: '',
};
export interface DateParameters {
  AdmissionDay: number;
  AdmissionMonth: number;
  AdmissionYear: number;
}
export interface ExistingAdvocateList {
  Id: number;
  FullName: string;
}
export interface LPCStatusDrpList {
  Id: number;
  FullName: string;
}
export const DEFAULT_REGIONALBAR: ExistingAdvocateList = {
  Id: 0,
  FullName: '',
};
export interface AdvocateHandle {
  data: ManageBlackListRequest;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  }
}
export interface LPCStatusHandler {
  data: ManageBlackListRequest;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  }
}
export interface AddLawfirm {
  AttorneyName: string;
  LawfirmName: string;
  EmailAddress: string;
  ContactNumber: string;
} // end AddLawfirm{}

@Injectable({
  providedIn: 'root'
})
export class ManageBlackListService {
  private httpClient: HttpClient;
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private handler: HttpBackend,
  ) { this.httpClient = new HttpClient(handler); }

  getManageBlackListLawfirms(pageIndex : Number, searchItem : string): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.manageBlackList + '?pageIndex=' + pageIndex + '&searchTerm=' + searchItem,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  getAttachedDocumentFile(lawfirmBlacklistId: number, isGCBDeleteRequest : boolean) {
    return this.httpClient.get(
      this.api.endpoints.getBlackListFile + '?lawfirmBlacklistId=' + lawfirmBlacklistId + '&isGCBDeleteRequest=' + isGCBDeleteRequest)
      .pipe(
        retry(0),
      catchError(this.api.handleError)
      );
  }

  getLawfirmAdvocates(isGCBUserBar: boolean): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.getLawfirmAdvocates + '?isGCBUserBar=' + isGCBUserBar + '&serviceProviderId=' + this.api.serviceProviderID,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  onSave(requestData: ManageBlackListRequest): Observable<any> {
    return this.http.post<any>(
      this.api.endpoints.saveBlackListHandler, requestData,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  updateLPCdetails(requestData: any): Observable<any> {
    return this.http.put<any>(
      this.api.endpoints.updateLPCdetails, requestData,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  getbyLawfirmApprovedReqList(lawfirmId: number, status: string): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.getbyLawfirmApprovedReq + '?lawfirmId=' + lawfirmId + '&Status=' + status,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  getbyLawfirmLPCReqList(lawfirmId: number): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.getbyLawfirmLPCReqList + '?lawfirmId=' + lawfirmId,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }
  
  onDeleteLawfirmListingsItem(formData: FormData, lawfirmBlacklistId: number, gcbDeleteNotes: string, isLPC: boolean = false) {
    return this.http.request('POST',     
    this.api.endpoints.deleteLowfirmListingsItem + '?lawfirmBlacklistId=' + lawfirmBlacklistId + "&gcbDeleteNotes=" + gcbDeleteNotes + '&contactId=' + this.api.serviceProviderID + "&isLPC=" + isLPC,
      {
        body: formData
      });
  }

  onBlackListFileUpload(formData: FormData, lawfirmBlackListID: number): Observable<any> {
    return this.http.request('POST', 
    this.api.endpoints.blackListDocumentUpload + '?LawfirmBlackListID=' + lawfirmBlackListID,
      {
        body: formData
      });
  }

  getBlackListUploadedDocumentFile(filePath: string) {
    return this.http.get(
      this.api.endpoints.get_blackList_File + '?filePath=' + filePath, {
      responseType: 'blob'
    }).pipe(map(
      (res) => {
        return new Blob([res], { type: 'application/pdf' });
      }));
  }

  downloadLPCDocument(docType: string, lawfirmBlackListID: number) {
    return this.http.get(
      this.api.endpoints.download_LPC_document + '?docType=' + docType + '&lawfirmBlackListID=' + lawfirmBlackListID, {
      responseType: 'blob'
    }).pipe(map(
      (res) => {
        return new Blob([res], { type: 'application/pdf' });
      }));
  }

  getPendingBlackListAdvocate(isSocietyBar: boolean) {
    return this.http.get<any>(
      this.api.endpoints.gcb_pending_Advocate + '?isSocietyBar=' + isSocietyBar + '&serviceProviderId=' + this.api.serviceProviderID,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }
  onApproved_Rejected(encryptedQuery: string, isEmailReq: boolean = false): Observable<any> {
    return this.httpClient.post<any>(
      this.api.endpoints.gcb_approved_Reject + '?encryptedQuery=' + encryptedQuery + '&isEmailReq=' + isEmailReq,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  onApproved_RejectedGCBDeleteRequest(encryptedQuery: string, fileName:string , approvedRejectedByID : number): Observable<any> {
    return this.httpClient.post<any>(
      this.api.endpoints.gcb_approved_Reject_Delete_Request + '?encryptedQuery=' + encryptedQuery + '&fileName=' + fileName + '&approvedRejectedByID=' + approvedRejectedByID,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  onDeleteAdvocate(Id: number, ChamberId: Number, ReportingBar : number, isGCBUser : boolean) {
    return this.http.delete<any>(
      this.api.endpoints.deleteAdvocate + '?Id=' + Id + '&chamberId=' + ChamberId + '&ReportingBarID=' + ReportingBar + '&isGCBUser=' + isGCBUser,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  getExistingAdvocatesList(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.get_Existing_Advocate_List,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  getLPCStatusList(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.get_LPC_Status_List,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }
  
  getEditLPCDetails(lpcId: number ): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.get_LPC_edit_details + '?lpcId=' + lpcId,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  LinkExstAdvocate(selectedExstAdvocateId: number, CurrentRegionalBar: string): Observable<any> {
    return this.http.post<any>(
      this.api.endpoints.LinkExstAdvocate + '?selectedExstAdvocateId=' + selectedExstAdvocateId + '&CurrentRegionalBar=' + CurrentRegionalBar,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  submitNewLawfirmRequest(addLawfirm: AddLawfirm): Observable<AddLawfirm> {
    const postableLawfirmAttorney = {
      AttorneyName: addLawfirm.AttorneyName,
      LawfirmName: addLawfirm.LawfirmName,
      EmailAddress: addLawfirm.EmailAddress,
      ContactNumber: addLawfirm.ContactNumber
    };
    return this.http.post<any>(
      this.api.endpoints.barSocietyNewLawfirmrequest,postableLawfirmAttorney,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  } // end addLawfirmAttorney{}

}
