<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div>
      <!--#end .modal-header -->

      <div class="modal-body form">

        <!-- Comments -->
        <div class="form-group">
          <label for="comments">Comments</label>
          <textarea
            type="text"
            [id]="comments"
            [(ngModel)]="comments"
            placeholder="Reason for deleting the matter"
            (keyup)="validate()"></textarea>
            <p
              class="comment-length-indicator"
              [class.text-danger]="exceedsMaxCommentLength">{{numberOfCharactersLeft}}</p>
        </div>

      </div>
      <!--#end .modal-body -->

      <div class="modal-footer">
        <button
          class="btn btn-danger"
          [class.disabled]="!isDeleteable"
          (click)="confirm()"
          [disabled]="!isDeleteable">Delete matter</button>
      </div>
      <!--#end .modal-footer -->

    </div>
    <!--#end .modal-dialog -->
  </div>
  <!--#end .modal-content -->
</div>
<!--#end .modal -->