<div class="modal" *ngIf="isVisible" (click)="onModalClick($event)">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}&nbsp;</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button><!--#end button -->
      </div><!--#end .modal-header -->

      <div class="modal-body">
        <ng-fullcalendar
          #ucDatepicker
          [options]="options"
          (dayClick)="onDayClick($event.detail)"></ng-fullcalendar><!--#end ng-fullcalendar -->
      </div><!--#end .modal-body -->

      <div class="modal-footer"></div><!--#end .modal-footer -->

    </div><!--#end .modal-dialog -->
  </div><!--#end .modal-content -->
</div><!--#end .modal -->