<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <button class="modal-dismiss" (click)="onCancel()">
          <i class="fa fa-close"></i>
        </button>
        <h4>{{title}}</h4>
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">
        <div class="row first-pane">
          <div class="left-column">
            <h5 *ngIf="objectType === 'contact'">Law firm details</h5>
            <div class="form-group">
              <strong ><label class="primary-font" for="subject">{{objectType === 'contact' ? 'Contact Name' : 'Invoice number'}}</label></strong>
              <label class="primary-font">{{noteDetailData.ObjectTypeDescription}}</label> 
            </div>
            <div class="form-group" *ngIf="noteDetailData.Subject">
              <strong ><label class="primary-font" for="subject">Subject:</label>
              <label class="primary-font">{{noteDetailData.Subject}}</label> </strong>
            </div>
            <div class="form-group">
              <strong ><label class="primary-font" for="subject">Note Date:</label></strong>
              <label class="primary-font">{{noteDetailData.CaptureDate | date:'yyyy-MM-dd'}}</label> 
            </div>
            <div class="form-group" *ngIf="objectType === 'invoice'">
              <strong ><label class="primary-font" for="subject">Client name:</label></strong>
              <label class="primary-font">{{invoiceDetails.ClientName}}</label> 
            </div>
            <div class="form-group" *ngIf="objectType === 'invoice'">
              <strong ><label class="primary-font" for="subject">Reference:</label></strong>
              <label class="primary-font">{{invoiceDetails.InvoiceReference}}</label> 
            </div>
            <div class="form-group" *ngIf="objectType === 'invoice'">
              <strong ><label class="primary-font" for="subject">Plaintiff:</label></strong>
              <label class="primary-font">{{plaintiff}}</label> 
            </div>
            <div class="form-group" *ngIf="objectType === 'invoice'">
              <strong ><label class="primary-font" for="subject">Defendant:</label></strong>
              <label class="primary-font">{{defendant}}</label> 
            </div>
          </div>
          <!-- Contact side bar -->
          <div class="right-column" *ngIf="objectType === 'contact'">
            <div class="container">
              <h5>Law firm detail summary</h5>
              <div class="row">
                <div class="form-group">
                  <label class="label"><b>Last invoice date:</b></label>
                </div>
                <div class="form-group">
                  <label class="right-text">{{lawfirmSummaryData.lastInvoiceDate | date:'yyyy-MM-dd'}}</label>
                </div>
              </div>
              <div class="row" style="justify-content: space-between;" *ngIf="lawfirmSummaryData">
                <div class="form-group">
                  <label class="label"><b>Last payment date:</b></label>
                </div>
                <div class="form-group">
                  <label class="right-text">{{lawfirmSummaryData.lastTransactionDate | date:'yyyy-MM-dd'}}</label>
                </div>
              </div>
              <div class="row" style="justify-content: space-between;" *ngIf="lawfirmSummaryData">
                <div class="form-group">
                  <label class="label"><b>Last payment amount:</b></label>
                </div>
                <div class="form-group">
                  <label class="right-text">{{lawfirmSummaryData.amount | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                </div>
              </div>
              <div class="row" style="justify-content: space-between;" *ngIf="lawfirmSummaryData">
                <div class="form-group">
                  <label class="label"><b>12 Months payment amount:</b></label>
                </div>
                <div class="form-group">
                  <label class="right-text">{{lawfirmSummaryData.totalPaidYear | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                </div>
              </div>
              <div class="row" style="justify-content: space-between;" *ngIf="lawfirmSummaryData">
                <div class="form-group">
                  <label class="label"><b>Average monthly payment:</b></label>
                </div>
                <div class="form-group">
                  <label class="right-text">{{lawfirmSummaryData.totalAverage | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                </div>
              </div>
              <div class="row" style="justify-content: space-between;" *ngIf="lawfirmSummaryData">
                <div class="form-group">
                  <!-- TO DO: MAKE Description and Amount field Bold and red -->
                  <label class="red-text label"><b>Partial paid invoices:</b></label>
                </div>
                <div class="form-group">
                  <label class="red-text right-text"><b>{{lawfirmSummaryData.partialPaidInvoices}}</b></label>
                </div>
              </div>
              <div class="row" style="justify-content: space-between;" *ngIf="lawfirmSummaryData">
                <div class="form-group">
                  <label class="red-text label"><b>Unallocated:</b></label>
                </div>
                <div class="form-group">
                  <label
                    class="red-text right-text"><b>{{lawfirmSummaryData.unAllocated | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></label>
                </div>
              </div>
            </div>
          </div>
          <!-- Invoice side bar -->
          <div class="right-column" *ngIf="objectType === 'invoice'">
            <div class="container">
              <h5>Invoice details</h5>
              <div class="row">
                <div class="form-group">
                  <label class="label"><b>Invoice amount:</b></label>
                </div>
                <div class="form-group">
                  <label class="right-text">{{invoiceDetails.InvoiceTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                </div>
              </div>
              <div class="row" style="justify-content: space-between;">
                <div class="form-group">
                  <label class="label"><b>Invoice date:</b></label>
                </div>
                <div class="form-group">
                  <label class="right-text">{{invoiceDetails.InvoiceDate | date:'yyyy-MM-dd'}}</label>
                </div>
              </div>
              <div class="row" style="justify-content: space-between;">
                <div class="form-group">
                  <label class="label"><b>Payment term:</b></label>
                </div>
                <div class="form-group">
                  <label class="right-text">{{invoiceDetails.Terms}}</label>
                </div>
              </div>
              <div class="row" style="justify-content: space-between;">
                <div class="form-group">
                  <label class="label"><b>Total Payment amount:</b></label>
                </div>
                <div class="form-group">
                  <label class="right-text">{{invoiceDetails.TotalPaidAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                </div>
              </div>
              <div class="row" style="justify-content: space-between;">
                <div class="form-group">
                  <label class="label"><b>Total Credits:</b></label>
                </div>
                <div class="form-group">
                  <label class="right-text">{{invoiceDetails.TotalCreditAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                </div>
              </div>
              <div class="row" style="justify-content: space-between;">
                <div class="form-group">
                  <!-- TO DO: MAKE Description and Amount field Bold and red -->
                  <label class="red-text label"><b>Written Off:</b></label>
                </div>
                <div class="form-group">
                  <label class="red-text right-text"><b>{{invoiceDetails.TotalWriteOffAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></label>
                </div>
              </div>
              <div class="row" style="justify-content: space-between;">
                <div class="form-group">
                  <label class="red-text label"><b>Amount outstanding:</b></label>
                </div>
                <div class="form-group">
                  <label
                    class="red-text right-text"><b>{{invoiceDetails.OutstandingBalance | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></label>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- #end .row -->
        
        <!-- Chat -->
        <div class="panel-body">
          <ul class="chat" *ngIf="noteDetailData && noteDetailData.Id > 0">
            <li class="left clearfix" *ngIf="noteDetailData && noteDetailData.UserCreator === noteDetailData.UserOwner 
            && (!noteDetailData.InternalText || noteDetailData.Category === 'Practice manager status note')">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{noteDetailData.UserOwnerFullName}}</strong> <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Practice Manager's  note ({{noteDetailData.CaptureDate | date:'yyyy-MM-dd'}})
                  </small>
                </div>
                <p>{{noteDetailData.Category}} {{noteDetailData.ExternalText}} {{noteDetailData.InternalText}}</p>
              </div>
            </li>
            <li class="left clearfix" *ngIf="noteDetailData && noteDetailData.ExternalText" hidden>
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{noteDetailData.UserAssignedFullName}}</strong> <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Advocate's Instruction ({{noteDetailData.CaptureDate | date:'yyyy-MM-dd'}})
                  </small>
                </div>
                <p>{{noteDetailData.ExternalText}}</p>
              </div>
            </li>
            <!-- Kristoff's Notes-->
            <li class="left clearfix" *ngIf="noteDetailData && noteDetailData.UserCreator === noteDetailData.UserOwner && userProfileService.userProfile.serviceProviderID === 29723">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{noteUserLogger(noteDetailData)}}</strong> <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Personal note ({{noteDetailData.CaptureDate | date:'yyyy-MM-dd'}})
                  </small>
                </div>
                <p *ngIf="noteDetailData && noteDetailData.Subject === 'Instruction from PM'">{{noteDetailData.Subject}} {{noteDetailData.InstructionText}}</p>
                <p>{{noteDetailData.QuestionText}} {{noteDetailData.InternalText}}</p>
              </div>
            </li>
            <li class="left clearfix" *ngIf="noteDetailData && noteDetailData.InstructionResponseId === 0
                                    && noteDetailData.IsVisibleUserOwner === true  && noteDetailData && noteDetailData.Subject != 'Instruction from PM'">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{noteUserLogger(noteDetailData)}}</strong> <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Collector or personal note ({{noteDetailData.CaptureDate | date:'yyyy-MM-dd'}})
                  </small>
                </div>
                <p *ngIf="noteDetailData && noteDetailData.Subject === 'Instruction from PM'">{{noteDetailData.Subject}} {{noteDetailData.InstructionText}}</p>
                <p *ngIf="noteDetailData && noteDetailData.Subject === 'History - Instruction'">{{noteDetailData.Subject}} {{noteDetailData.InternalText}}</p>
                <p>{{noteDetailData.QuestionText}} {{noteDetailData.InternalText}}</p>
              </div>
            </li>
        
            <li class="left clearfix" *ngIf="noteDetailData && noteDetailData.InstructionResponseId > 0 || noteDetailData && noteDetailData.Subject === 'Instruction from PM'">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{noteDetailData.UserCreatorFullName}}</strong> <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Practice Manager's Instruction ({{noteDetailData.InstructionCaptureDate | date:'yyyy-MM-dd'}})
                  </small>
                </div>
                <p>{{noteDetailData.ResponseText}} {{noteDetailData.InstructionText}}</p>
              </div>
            </li>

            <!-- <li class="left clearfix" *ngIf="noteDetailData && noteDetailData.ResponseText">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{noteDetailData.UserAssignedFullName}}</strong> <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Advocate ({{noteDetailData.InstructionCaptureDate | date:'yyyy-MM-dd'}})
                  </small>
                </div>
                <p>{{noteDetailData.ResponseText}} {{noteDetailData.InstructionText}}</p>
              </div>
            </li> -->
            <!-- <li class="left clearfix" *ngIf="noteDetailData && noteDetailData.InstructionResponseId && noteDetailData.ResolutionText">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{noteDetailData.UserOwnerFullName}}</strong> <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Practice Manager ({{noteDetailData.ResolutionCaptureDate | date:'yyyy-MM-dd'}})
                  </small>
                </div>
                <p>{{noteDetailData.ResolutionText}}</p>
              </div>
            </li> -->
          </ul> <!-- #end .chat -->
        </div> <!-- #end .panel-body -->
      </div> <!-- #end .modal-body -->

      <!-- Level One Note -->
      <div class="modal-footer" *ngIf="noteDetailData && noteDetailData.NoteInsertedLevel === 1 && isPracticeManager">
        <div class="well well-fluid">
          <div class="row" style="padding-top: 0; padding-bottom: 0;" *ngIf="noteDetailData && noteDetailData.QuestionId > 0">
            <div class="form-control" style="border:0; background-color: transparent;">
              <div class="control-group" style="width: 100%;">
                <label style="width: 20%;" for="question">Instruction Question:</label>&nbsp;
                <input
                  style="width: 78%;"
                  readonly
                  [title]="noteDetailData.QuestionText"
                  [value]="noteDetailData.QuestionText">
              </div>
            </div>
          </div>
          <div class="row" style="padding-top: 0; padding-bottom: 0;" *ngIf="noteDetailData && noteDetailData.QuestionId > 0 && noteDetailData.ResponseText">
            <div class="form-control" style="border:0; background-color: transparent;">
              <div class="control-group" style="width: 100%;">
                <label style="width: 20%;" for="response-text">Response Text:</label>&nbsp;
                <input
                  style="width: 78%;"
                  readonly
                  [value]="noteDetailData.ResponseText">
              </div>
            </div>
          </div>
          <div class="row" style="padding-top: 0; padding-bottom: 0;" *ngIf="noteDetailData && !noteDetailData.ResponseText && noteDetailData.NoteInsertedLevel === 1">
            <div class="form-control" style="border:0; background-color: transparent;">
              <div class="control-group">
                <label for="category">Category</label>&nbsp;
                <input
                  readonly
                  [title]="noteDetailData.Category"
                  [value]="noteDetailData.Category">
              </div>
              <div class="control-group">
                <label for="sub-category">Note Detail</label>&nbsp;
                <input
                  readonly
                  [title]="noteDetailData.Subject"
                  [value]="noteDetailData.Subject" >
              </div>
              <div class="control-group date-control" *ngIf="noteDetailData && noteDetailData.ActionDate">
                <label for="date-to">Attribute</label>&nbsp;
                <span>{{noteDetailData.ActionDate | date:'yyyy-MM-dd'}}</span>
              </div>
              <div class="control-group" *ngIf="collectorChecked === true">
                <label for="action">Action</label>
                <input
                  [(ngModel)]="selectedResponseText" 
                  [title]="selectedResponseText"
                  [typeahead]="instructionResponses" 
                  typeaheadOptionField="Response"
                  [typeaheadSingleWords]="false" 
                  placeholder="Select or start typing" 
                  (input)="onInputResponse($event)"
                  (typeaheadOnSelect)="onNoteResponseSelect($event)" 
                  [typeaheadOptionsLimit]="50" 
                  typeaheadScrollable=true
                  typeaheadOptionsInScrollableView="10" 
                  [typeaheadMinLength]="0" 
                  [dropup]="false">
              </div>
            </div>
          </div>
    
          <div class="row" style="padding-top: 0; padding-bottom: 0;"
          *ngIf="noteDetailData && !noteDetailData.ResponseText && noteDetailData.NoteInsertedLevel === 1">
            <div class="form-control" style="border:0; background-color: transparent;">
              <div class="control-group" style="width: 87%;">
                <label for="message">Message</label>&nbsp;
                <textarea
                  style="width: 100%;"
                  *ngIf="advocateChecked === true && noteDetailData.InstructionResponseId === 0 && !noteDetailData.ResponseText"
                  class="input-control"
                  placeholder="Add Message to request an instruction from Advocate"
                  [(ngModel)]="internalExternalText"></textarea>

                  <textarea
                  style="width: 100%;"
                  *ngIf="collectorChecked === true"
                  class="input-control"
                  placeholder="Add an Instruction Message"
                  [(ngModel)]="instructionText"></textarea>
              </div>
              <!-- </div>
                  <div class="form-control"> -->
              <div class="option-groups" style="margin-right: 5px;" *ngIf="noteDetailData ">
                <!-- <div class="visibility-group">
                  <div class="text-center">
                    <label for="visibility">Visibility</label>
                  </div>
                  <div class="text-center">
                    <i class="far fa-question-circle"></i>
                  </div>
                </div> -->
                <div class="visibility-group">
                  <div class="">
                    <div class="control-group">
                      <label for="table">Respond to Collector </label>&nbsp;
                      <app-small-radiobox
                        [(checked)]="collectorChecked" 
                        (click)="toggleColAdvCheckbox('collector')">
                      </app-small-radiobox>
                    </div>
                  </div>
                  <div class="">
                    <div class="control-group">
                      <label for="table">Request Instruction </label>&nbsp;
                      <app-small-radiobox
                        [(checked)]="advocateChecked" 
                        (click)="toggleColAdvCheckbox('advocate')">
                      </app-small-radiobox>
                    </div>
                  </div>                  
                </div>
              </div>
              <div class="form-control" style="width: auto;display: grid;">
                <button
                  (click)="onCancel()"
                  style="height: auto; margin-bottom: 5px;"
                  class="btn btn-danger">Cancel</button>
                <button
                  style="height: auto;" 
                  *ngIf="collectorChecked === true" 
                  (click)="onSaveInstructionResponce()" 
                  class="btn btn-success">Save</button>
                <button 
                  style="height: auto;" 
                  *ngIf="advocateChecked === true" 
                  (click)="onForwardInstructionRequest()" 
                  class="btn btn-success">Save</button>
                </div>
            </div>
          </div>
        </div>
      </div> <!-- #end .modal-footer -->

      <div class="modal-footer" *ngIf="!noteDetailData.UserOwnerFullName || noteDetailData.InstructionResponseId > 0 && noteDetailData.ResponseText && noteDetailData.NoteInsertedLevel === 1">
        <div class="well well-fluid">
          <div class="row" style="padding-top: 0; padding-bottom: 0;" *ngIf="noteDetailData.InstructionResponseId === 0 && !noteDetailData.ResponseText">
            <div class="row" style="padding-top: 0; padding-bottom: 0; width: 100%;" *ngIf="collectorNewChecked === true">
              <!-- <div class="control-group"> -->
                <label for="action">Action</label>
                <input
                  style="width: 80%;"
                  [(ngModel)]="selectedResponseText" 
                  [title]="selectedResponseText"
                  [typeahead]="instructionResponses" 
                  typeaheadOptionField="Response"
                  [typeaheadSingleWords]="false" 
                  placeholder="Select or start typing" 
                  (input)="onInputResponse($event)"
                  (typeaheadOnSelect)="onNoteResponseSelect($event)" 
                  [typeaheadOptionsLimit]="50" 
                  typeaheadScrollable=true
                  typeaheadOptionsInScrollableView="10" 
                  [typeaheadMinLength]="0" 
                  [dropup]="false">
              <!-- </div> -->
            </div>
            <div class="form-control" style="border:0; background-color: transparent;" *ngIf="advocateNewChecked === true">
              <div class="control-group">
                <label for="category">Note Type</label>&nbsp;
                <input
                  [(ngModel)]="selectedNoteCategoryText"
                  [typeahead]="noteCategory"
                  typeaheadOptionField="Category"
                  [typeaheadSingleWords]="false"
                  placeholder="Select or start typing"
                  (input)="onInputCategory($event)"
                  (typeaheadOnSelect)="onNoteCategorySelect($event)"
                  [typeaheadOptionsLimit]="50"
                  typeaheadScrollable=true
                  typeaheadOptionsInScrollableView="5"
                  [typeaheadMinLength]="0"
                  [dropup]="false">
              </div>
              <div class="control-group">
                <label for="sub-category">Note Detail</label>&nbsp;
                <input
                  [(ngModel)]="selectedNoteSubCategoryText" 
                  [typeahead]="noteSubCategoryList" 
                  typeaheadOptionField="Name"
                  [typeaheadSingleWords]="false" 
                  placeholder="Select or start typing" 
                  (input)="onInputSubCategory($event)"
                  (typeaheadOnSelect)="onNoteSubCategorySelect($event)"
                  [typeaheadOptionsLimit]="50" 
                  typeaheadScrollable=true 
                  typeaheadOptionsInScrollableView="5" 
                  [typeaheadMinLength]="0"
                  [dropup]="false">
              </div>
              <div class="control-group date-control" *ngIf="selectedNoteSubCategory && selectedNoteSubCategory.Diary">
                <label for="date-to">Attribute</label>&nbsp;
                <input
                  readonly 
                  type="text" 
                  id="date-to" 
                  class="input-date" 
                  #_dateTo="bsDatepicker" 
                  bsDatepicker
                  placeholder="2019-10-01" 
                  placement="top" 
                  (bsValueChange)="onDateValueChange($event)"
                  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
                <i class="fa fa-calendar" [attr.aria-expanded]="_dateTo.isOpen"></i>
              </div>
            </div>
          </div>
          <div class="row" style="padding-top: 0; padding-bottom: 0;" *ngIf="selectedNoteSubCategory && selectedNoteSubCategory.InstructionQuestion && noteDetailData.InstructionResponseId === 0 && !noteDetailData.ResponseText">
            <div class="form-control" style="border:0; background-color: transparent;">
              <div class="control-group" style="width: 100%;">
                <label style="width: 20%;" for="question">Instruction Question:</label>&nbsp;
                <input
                  style="width: 78%;"
                  readonly
                  [title]="selectedNoteSubCategory.InstructionQuestion"
                  [ngModel]="selectedNoteSubCategory.InstructionQuestion">
              </div>
            </div>
          </div>
          <div class="row" style="padding-top: 0; padding-bottom: 0;"
          *ngIf="(noteDetailData.InstructionResponseId === 0 && !noteDetailData.ResponseText && isInternalChecked)">
              <div class="form-control" style="border:0; background-color: transparent;">
                <div class="control-group" style="width: 100%;">
                  <label style="width: 98px;" for="message">Personal Note</label>&nbsp;
                <textarea
                  style="width: 100%; height: 28px;"
                  class="input-control"
                  placeholder="Add Note Message"
                  [(ngModel)]="internalText"></textarea>   
                </div>
              </div>         
          </div>
          <div class="row" style="padding-top: 0; padding-bottom: 0;"
          *ngIf="(noteDetailData.InstructionResponseId === 0 && !noteDetailData.ResponseText) ||
              (noteDetailData.InstructionResponseId > 0 && noteDetailData.ResponseText && !noteDetailData.ResolutionText)">
            <div class="form-control" style="border:0; background-color: transparent;">
              <div class="control-group" style="width: 87%;">
                <label for="message">Message</label>&nbsp;
                <textarea
                  style="width: 100%;"
                  *ngIf="noteDetailData.InstructionResponseId === 0 && !noteDetailData.ResponseText"
                  class="input-control"
                  placeholder="Add Note Message"
                  [(ngModel)]="internalExternalText"></textarea>
                <textarea
                  style="width: 100%;"
                  *ngIf="noteDetailData.InstructionResponseId > 0 && noteDetailData.ResponseText && !noteDetailData.ResolutionText"
                  class="input-control"
                  placeholder="Add Note Message"
                  [(ngModel)]="resolutionText"></textarea>
              </div>
              <div class="option-groups" style="margin-right: 5px;" *ngIf="noteDetailData && noteDetailData.InstructionResponseId === 0">
                <!-- <div class="visibility-group">
                  <div class="text-center">
                    <label for="visibility">Visibility</label>
                  </div>
                  <div class="text-center">
                    <i class="far fa-question-circle"></i>
                  </div>
                </div> -->
                <!-- <div class="visibility-group">
                  <div class="">
                    <div class="control-group">
                      <label for="table">Internal </label>&nbsp;
                      <app-small-radiobox [(checked)]="isInternalChecked" (click)="toggleIntExtCheckbox('internal')">
                      </app-small-radiobox>
                    </div>
                  </div>
                </div> -->
                <div class="option-groups" style="margin-right: 5px;" *ngIf="noteDetailData ">
                  <!-- <div class="visibility-group">
                    <div class="text-center">
                      <label for="visibility">Visibility</label>
                    </div>
                    <div class="text-center">
                      <i class="far fa-question-circle"></i>
                    </div>
                  </div> -->
                  <div class="visibility-group">
                    <div class="">
                      <div class="control-group" style="line-height: 1 !important">
                        <label for="table">Issue an Instruction </label>&nbsp;
                        <app-small-radiobox
                          [(checked)]="collectorNewChecked" 
                          (click)="toggleNewColAdvCheckbox('collector')">
                        </app-small-radiobox>
                      </div>
                    </div>
                    <div class="">
                      <div class="control-group" style="line-height: 1 !important">
                        <label for="table">Request Instruction </label>&nbsp;
                        <app-small-radiobox
                          [(checked)]="advocateNewChecked" 
                          (click)="toggleNewColAdvCheckbox('advocate')">
                        </app-small-radiobox>
                      </div>
                    </div>    
                    <div class="" *ngIf="collectorNewChecked === true && selectedTab !== '98'">
                      <div class="control-group" style="line-height: 1 !important">
                        <label for="table">Alert Collector? </label>&nbsp;
                        <app-small-radiobox
                          [(checked)]="!alertCollector" 
                          (click)="toggleSendToCollection()">
                        </app-small-radiobox>
                      </div>
                    </div>                
                  </div>
                </div>
              </div>
              <div class="form-control" style="width: auto;display: grid;">
                <button
                  (click)="onCancel()"
                  style="height: auto; margin-bottom: 5px;"
                  class="btn btn-danger">Cancel</button>
                <!-- <button
                  style="height: auto;" 
                  *ngIf="collectorChecked === true" 
                  (click)="onSaveInstructionResponce()" 
                  class="btn btn-success">Save</button> -->
                  <button 
                    style="height: auto;" 
                    *ngIf="(!noteDetailData || noteDetailData.Id === 0) && advocateNewChecked === true" 
                    (click)="onAddNote()" 
                    class="btn btn-success">ADD NOTE</button>
                  <button 
                    style="height: auto;" 
                    *ngIf="(noteDetailData && noteDetailData.Id > 0 && !noteDetailData.ResolutionText)  && advocateNewChecked === true" 
                    (click)="onResolutionSave()" 
                    class="btn btn-success">ADD R NOTE</button>
                  <button 
                    style="height: auto;" 
                    *ngIf="collectorNewChecked === true" 
                    (click)="onIssueAnInstructionRequest()" 
                    class="btn btn-success">Save Instr.</button>
                </div>
            </div>
          </div>
        </div>
      </div> <!-- #end .modal-footer -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->