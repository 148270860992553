import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  SimpleChanges,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener
} from '@angular/core';
import { QuotationFeeItem, MatterQuotationDTO, DEFAULT_QUOTATION_FEE_ITEM } from '../../../services/quotation/quotation.service';
import { UserProfile, UserProfileService } from '../../../services/user-profile/user-profile.service';
import { SubCategory, DEFAULT_SUB_CATEGORY } from '../../../services/sub-categories/sub-categories.service';
import {
  DEFAULT_QUANTITY,
  RATE_TYPE_PER_KILOMETER,
  RATE_TYPE_HOURLY,
  RATE_TYPE_ONCE_OFF,
  RATE_TYPE_DAILY,
  RATE_TYPE_DISBURSEMENT,
  RATE_TYPE_NON_BILLABLE,
  RATE_TYPE_PER_PAGE,
  DEFAULT_RATE_TYPE,
  DEFAULT_RATE,
  RATE_TYPE_ADD_DEPOSIT
} from '../../../services/fee-items/fee-items.service';
import { ActivityLogs, DEFAULT_ACTIVITY_LOGS, ActivityLogsService } from '../../../services/activity-logs/activity-logs.service';
import { ApiService } from '../../../services/api.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack } from '../../../services/messaging/snackbars/snackbars.service';
import { UsercurrencyDirective } from '../../../directive/usercurrency.directive';
import * as moment from 'moment';

@Component({
  selector: 'app-quotation-matter-fees',
  templateUrl: './quotation-matter-fees.component.html',
  styleUrls: ['./quotation-matter-fees.component.scss']
})
export class QuotationMatterFeesComponent implements OnInit, OnChanges {

  templateTypes: any;
  selectedTemplate = '';

  // @Input() briefDocuments: BriefDocuments[];
  @Input() id: number;
  @Input() workingQuotationFees: QuotationFeeItem[];
  @Input() workingActualQuotationFees: QuotationFeeItem[];
  @Input() subCategories: SubCategory[];
  @Input() userProfile: UserProfile;
  @Input() workingMatterQuotation: MatterQuotationDTO;
  // @Input() documentTypes: DocumentTypes[];
  @Output() selectedTab: EventEmitter<string> = new EventEmitter<string>();
  @Output() workingFeesChange: EventEmitter<QuotationFeeItem[]> = new EventEmitter<QuotationFeeItem[]>();
  @Output() workingActualFeesChange: EventEmitter<QuotationFeeItem[]> = new EventEmitter<QuotationFeeItem[]>();
  @Output() feeDelete: EventEmitter<QuotationFeeItem> = new EventEmitter<QuotationFeeItem>();
  @Output() feeActualDelete: EventEmitter<QuotationFeeItem> = new EventEmitter<QuotationFeeItem>();
  @Output() workingMatterChange: EventEmitter<MatterQuotationDTO> = new EventEmitter<MatterQuotationDTO>();
  @ViewChild('rateinput') RateInput: ElementRef;
  @ViewChild('inputDiscount') DiscountInput: ElementRef;
  @ViewChild('inputActualDiscount') ActualDiscountInput: ElementRef;
  @ViewChild('inputPaymentReceived') inputPaymentReceived: ElementRef;
  @ViewChild('inputAttachmentFile') inputAttachmentFile: ElementRef;
  @ViewChild('inputUploadFile') inputUploadFile: ElementRef;

  activeTab = '';
  rateTypes = [
    RATE_TYPE_PER_KILOMETER,
    RATE_TYPE_HOURLY,
    RATE_TYPE_ONCE_OFF,
    RATE_TYPE_DAILY,
    RATE_TYPE_DISBURSEMENT,
    RATE_TYPE_NON_BILLABLE,
    RATE_TYPE_PER_PAGE,
    RATE_TYPE_ADD_DEPOSIT
  ];

  currentFeesTotal = 0.00;
  currentActualFeesTotal = 0.00;
  currentFeesDiscount = 0;

  useDiscountPercentage: boolean;
  currentFeesGrandTotal = 0.00;

  invoiceNumber = 'AUX000000';
  invoiceAmount = 0.00;
  totalPayments = 0.00;
  totalOutstanding = 0.00;
  showDeleteFeeModal: boolean;
  quantityReadOnly: boolean;
  showAttorneyErrorPrompt: boolean;
  downloadLink = this.api.apiURL + '/MatterDocument?briefId=';

  selectedFee: QuotationFeeItem;
  selectedSubCategory: SubCategory;
  // workingFees: Fee[];

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  private _currentFeesTotal = 0.00;
  private previousFees: QuotationFeeItem[];
  // private currency: CurrencyPipe = new CurrencyPipe('en-US');
  private feesReady: boolean;
  private matterReady: boolean;
  private hasError = false;

  //#region Documents Declaration
  // showUploadDocumentErrorPrompt: boolean;
  // selectedDocumentType = 0;
  // selectedFile: any;
  // showMatterDocumentDialog: boolean;
  // documentTitle: string;
  // selectedBriefId: number;
  // selectedServiceId: number;
  // dropzoneActive = false;
  // showUploadModalDialog: boolean;
  // documentName = '';
  // attachmentFile: File;
  // fileList: File[] = [];
  // fileListHandle: FileDocHandle[] = [];
  // attachment: string;
  // showDeleteDocumentPrompt: boolean;
  // selectedDocumentToDelete: string;
  // showdocumentUploadErrorPrompt: boolean;
  // selectedDocomentType: DocumentTypes = Object.assign({}, DEFAULT_DOCOMENT_TYPE);
  // toDeleteDocumentTypes: BriefDocuments = Object.assign({}, DEFAULT_BRIEF_DOCUMENT);
  // toEditDocumentTypes: BriefDocuments = Object.assign({}, DEFAULT_BRIEF_DOCUMENT);
  //#endregion Documents Declaration

  // edit document type for document
  showEditUploadModalDialog: boolean;

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  // Is Internal Attorney
  get isAttorney(): boolean {
    let attorneyProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      attorneyProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.ATTORNEY);
    } else {
      attorneyProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
    }
    return attorneyProfile;
  } // end isAttorney()

  // Is Internal Lawfirm
  get isLawfirm(): boolean {
    let lawfirmProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      lawfirmProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
    } else {
      lawfirmProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
    }
    return lawfirmProfile;
  } // end isLawfirm()

  get totalHoursWorked(): number {
    let total = 0;
    this.workingQuotationFees.forEach(data => {
      if (data.RateType === 'Hourly') {
        total += data.Quantity;
      }
    });
    return total;
  } // end totalHoursWorked()

  get totalActualHoursWorked(): number {
    let total = 0;
    this.workingActualQuotationFees.forEach(data => {
      if (data.RateType === 'Hourly') {
        total += data.Quantity;
      }
    });
    return total;
  } // end totalActualHoursWorked()

  hasRateError(fee: QuotationFeeItem): boolean {
    if (fee.Rate.toString().startsWith('-')) {
      this.hasError = true;
      return true;
    }
    this.hasError = false;
    return false;
  } // end hasRateError()

  get hasAnErrorOnRate(): boolean {
    return Boolean(this.hasError);
  }

  get quotationFeeReadOnly(): Boolean {
    return this.workingMatterQuotation.QuotationLinkId > 0;
  } // end quotationFeeReadOnly()

  constructor(
    private api: ApiService,
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    // private matterService: MattersService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    public userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private currencyDirective: UsercurrencyDirective,
  ) {
    this.feesReady = false;
    this.matterReady = false;
    this.showDeleteFeeModal = false;
    this.quantityReadOnly = true;
    this.useDiscountPercentage = false;
  } // end constructor()

  parseQuotationLineDate(serviceDate: string): Date {
    if (serviceDate) {
      return new Date(serviceDate);
    } else {
      return null;
    }
  } // end parseQuotationLineDate()

  //#region  Documents
  dropzoneState($event: boolean) {
    // this.dropzoneActive = $event;
  } // end dropzoneState()

  onHideUploadModalDialog() {
    // this.showUploadModalDialog = false;
  } // end onHideUploadModalDialog()
  //#endregion Documents

  ngOnInit() {
    this.templateTypes = [
      { id: 'Template one', name: 'Full invoice' },
      { id: 'Template two', name: 'Merits settled, Quantum pp' },
      { id: 'Template three', name: 'POC & pre-trial' },
      { id: 'Template four', name: 'Pre hearing pre-trial' },
    ];

    // if (this.workingMatterQuotation.QuotationLinkId > 0) {
    //   this.activeTab = 'actual-fees';
    //   this.selectedTab.emit(this.activeTab);
    // } else {
      this.activeTab = 'quotation-fees';
      this.selectedTab.emit(this.activeTab);
    // }
  } // end ngOnInit()
    //#endregion Default Templates for selected users

    //#region Documents Methods
  fileAttachmentChange(file) {
    // if (this.workingMatter && this.workingMatter.ServiceID) {
    //   if (this.getExtension(file.target.files[0].name) === 'pdf' || this.getExtension(file.target.files[0].name) === 'docx') {
    //     this.attachmentFile = file.target.files[0];
    //     this.attachment = this.attachmentFile.name;
    //     this.documentName = 'Add document to matter (' + this.workingMatter.Description + ')';
    //     this.showUploadModalDialog = true;
    //   } else {
    //     this.showdocumentUploadErrorPrompt = true;
    //   }
    // } else {
    //   this.inputAttachmentFile.nativeElement.value = '';
    //   this.inputUploadFile.nativeElement.valie = '';
    //   this.showUploadDocumentErrorPrompt = true;
    // }
  } // end fileAttachmentChange()

  handleDrop(file: File) {
    // if (this.workingMatter && this.workingMatter.ServiceID) {
    //   if (this.getExtension(file[0].name) === 'pdf' || this.getExtension(file[0].name) === 'docx') {
    //     this.attachmentFile = file[0];
    //     this.attachment = this.attachmentFile.name;
    //     this.documentName = 'Add document to matter ( ' +
    //       this.workingMatter.Description + ')';
    //     this.showUploadModalDialog = true;
    //     // }
    //   } else {
    //     this.showdocumentUploadErrorPrompt = true;
    //   }
    // } else {
    //   this.inputAttachmentFile.nativeElement.value = '';
    //   this.inputUploadFile.nativeElement.valie = '';
    //   this.showUploadDocumentErrorPrompt = true;
    // }
  } // end handleDrop()

  onHidedocumentUploadErrorPrompt() {
    // this.inputAttachmentFile.nativeElement.value = '';
    // this.inputUploadFile.nativeElement.value = '';
    // this.attachment = '';
    // this.showdocumentUploadErrorPrompt = false;
  } // end onHidedocumentUploadErrorPrompt()

  onUploadFile(file) {
    // this.briefDocuments.push(file);
    // this.inputAttachmentFile.nativeElement.value = '';
    // this.inputUploadFile.nativeElement.value = '';
    // this.attachment = '';
    // this.showUploadModalDialog = false;
  } // end onUploadFile()

  // onFileRemove(fileType: string, file: BriefDocuments) {
    // this.selectedDocumentToDelete = 'Are you sure you want to remove "' +
    //   fileType + '" from matter (' + this.workingMatter.Description + ')?';
    // this.toDeleteDocumentTypes = file;
    // this.showDeleteDocumentPrompt = true;
  // } // end onFileRemove()

  // onFileEdit(fileType: string, file: BriefDocuments) {
    // this.toEditDocumentTypes = file;
    // this.documentName = 'Edit document type for ( ' +
    //   file.DocumentPath + ')';
    // this.selectedDocumentType = this.documentTypes.filter(type => type.DocumentType === file.DocumentType)[0].ID;
    // this.showEditUploadModalDialog = true;
  // } // end onFileEdit()

  onHideEditUploadModalDialog() {
    this.showEditUploadModalDialog = false;
  } // end onHideEditUploadModalDialog()

  onEditUploadFile(event) {
    // Log activity Login
    // const currentDate = new Date();
    // this.activityLog.Action = 'Edit matter document';
    // this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    // this.activityLog.LoggedApp = 'Web Application (Advocate-matter(matter-fees-table))';
    // if (this.userProfileService.userProfile.isImpersonator) {
    //   this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
    //   this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    // } else {
    //   this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
    //   this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    // }
    // this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    // this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    // this.activityLog.ActionTable = 'BriefService';
    // this.activityLog.JsonData = JSON.stringify(this.toDeleteDocumentTypes);
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();

    // const updateDoc =  this.matterService.updateBriefDocumentType(event.BriefId, event).toPromise();
    // this.briefDocuments = this.briefDocuments.filter(s => s.BriefId !== updateDoc.BriefId);
    // this.briefDocuments.push(updateDoc);
    // this.showEditUploadModalDialog = false;
    // this.loadingService.hideOverlay();
  } // end onEditUploadFile()

  // onViewDocument(file: BriefDocuments) {
  //   this.selectedFile = file;
  //   this.documentTitle = file.DocumentType;
  //   this.selectedServiceId = this.workingMatter.ServiceID;
  //   this.selectedBriefId = file.BriefId;
  //   this.showMatterDocumentDialog = true;
  // } // end onViewDocument()

  onDocClose() {
    // this.showMatterDocumentDialog = false;
  } // end onDocClose()

  onHideDeleteDocumentPrompt() {
    // this.showDeleteDocumentPrompt = false;
  } // end onHideDeleteDocumentPrompt()

  onConfirmDeleteDocumentPrompt() {
    // this.loadingService.showOverlay();

    // // Log activity Login
    // const currentDate = new Date();
    // this.activityLog.Action = 'Delete matter document';
    // this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    // this.activityLog.LoggedApp = 'Web Application (Advocate-matter(matter-fees-table))';
    // if (this.userProfileService.userProfile.isImpersonator) {
    //   this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
    //   this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    // } else {
    //   this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
    //   this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    // }
    // this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    // this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    // this.activityLog.ActionTable = 'BriefService';
    // this.activityLog.JsonData = JSON.stringify(this.toDeleteDocumentTypes);
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();

    // const deleted =  this.matterService.deleteDocument(this.toDeleteDocumentTypes.BriefId).toPromise();
    // this.briefDocuments = this.briefDocuments.filter(s => s.BriefId !== deleted);
    // this.showDeleteDocumentPrompt = false;
    // this.loadingService.hideOverlay();
  } // end onConfirmDeleteDocumentPrompt()

  getExtension(filename) {
    return filename.toString().split('.')[1];
  } // end getExtension()

  onHideUploadDocumentErrorPrompt() {
    // this.showUploadDocumentErrorPrompt = false;
  } // end onHideUploadDocumentErrorPrompt()

  onConfirmUploadDocumentErrorPrompt() {
    // this.showUploadDocumentErrorPrompt = false;
  } // end onConfirmUploadDocumentErrorPrompt()

  onHideAttorneyErrorPrompt() {
    this.showAttorneyErrorPrompt = false;
  } // end onHideAttorneyErrorPrompt()
   //#endregion Default Templates for selected users

   ngOnChanges(changes: SimpleChanges) {
    if (changes.workingQuotationFees && changes.workingQuotationFees.currentValue) {
      this.feesReady = true;
      this.workingQuotationFees.sort((a, b) => a.LineDate.toUpperCase().localeCompare(b.LineDate.toUpperCase()));
      this.initFeeDescriptions();
      this.calculateCurrentFeesTotal();
    }
    if (changes.workingActualQuotationFees && changes.workingActualQuotationFees.currentValue) {
      this.feesReady = true;
      this.workingActualQuotationFees.sort((a, b) => a.LineDate.toUpperCase().localeCompare(b.LineDate.toUpperCase()));
      this.initActualFeeDescriptions();
      this.calculateActualFeesTotal();
    }

    if (changes.workingMatter && changes.workingMatter.currentValue) {
      this.matterReady = true;
      this.initFeeDescriptions();
    }
  } // end ngOnChanges()


  onHourlyRateChange(fee: QuotationFeeItem, event) {
    if (this.userProfileService.userProfile.isImpersonator) {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onHourlyRateChange()

  onHourlyRateClicked(fee: QuotationFeeItem, event) {
    if (this.userProfileService.userProfile.isImpersonator) {
      event.target.value = this.cpipe.parse(event.target.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      event.target.value = this.cpipe.parse(event.target.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onHourlyRateClicked()

  onDiscountInputChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.DiscountInput.nativeElement.value = this.cpipe.transform(this.DiscountInput.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.DiscountInput.nativeElement.value = this.cpipe.transform(this.DiscountInput.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onDiscountInputChange()

  onActualDiscountInputChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.ActualDiscountInput.nativeElement.value = this.cpipe.transform(this.ActualDiscountInput.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.ActualDiscountInput.nativeElement.value = this.cpipe.transform(this.ActualDiscountInput.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onActualDiscountInputChange()

  onPaymentReceivedInputChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.inputPaymentReceived.nativeElement.value = this.cpipe.transform(this.inputPaymentReceived.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.inputPaymentReceived.nativeElement.value = this.cpipe.transform(this.inputPaymentReceived.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onPaymentReceivedInputChange()

  onDiscountInputClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.DiscountInput.nativeElement.value = this.cpipe.parse(this.DiscountInput.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.DiscountInput.nativeElement.value = this.cpipe.parse(this.DiscountInput.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onHourlyRateClicked()

  onActualDiscountInputClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.ActualDiscountInput.nativeElement.value = this.cpipe.parse(this.ActualDiscountInput.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.ActualDiscountInput.nativeElement.value = this.cpipe.parse(this.ActualDiscountInput.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onActualDiscountInputClicked()

  onPaymentReceivedInputClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.inputPaymentReceived.nativeElement.value = this.cpipe.parse(this.inputPaymentReceived.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.inputPaymentReceived.nativeElement.value = this.cpipe.parse(this.inputPaymentReceived.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onPaymentReceivedInputClicked()

  initFeeDescriptions() {
    if (this.feesReady && this.matterReady) {
      this.workingQuotationFees.map(fee => {
        if (!fee.FurtherDescription) {
          fee.FurtherDescription = fee.Subject
            .replace(/^[\w\s/\d.]+-\s/gi, '');
          fee.FurtherDescription = fee.FurtherDescription
            .replace(/[\s]+-[\s]+[\w]+$/gi, '')
            .replace(' - ' + this.workingMatterQuotation.Status, '')
            .replace(' - ' + fee.MatterQuotationStatus, '')
            .trim();
        }
      });
    }
  } // end initFeeDescriptions()

  initActualFeeDescriptions() {
    if (this.feesReady && this.matterReady) {
      this.workingActualQuotationFees.map(fee => {
        if (!fee.FurtherDescription) {
          fee.FurtherDescription = fee.Subject
            .replace(/^[\w\s/\d.]+-\s/gi, '');
          fee.FurtherDescription = fee.FurtherDescription
            .replace(/[\s]+-[\s]+[\w]+$/gi, '')
            .replace(' - ' + this.workingMatterQuotation.Status, '')
            .replace(' - ' + fee.MatterQuotationStatus, '')
            .trim();
        }
      });
    }
  } // end initActualFeeDescriptions()

  setActiveTab(tab: string) {
    this.activeTab = tab;
    this.selectedTab.emit(this.activeTab);
  } // end setActiveTab()

  getFeeDescription(subject: string): string {
    let feeDescription = '';
    const startIndex = subject.indexOf(' -');
    const endIndex = subject.indexOf(' -', startIndex);
    feeDescription = subject.substr(startIndex, (endIndex - startIndex));
    return feeDescription;
  } // end getFeeDescription()

  addAnotherFee() {
    if (this.workingMatterQuotation.ClientRefNo !== '' &&
    this.workingMatterQuotation.LawfirmId > 0) {
      const rateType: string = DEFAULT_RATE_TYPE;
      let rate: number = DEFAULT_RATE;
      let qty: number = DEFAULT_QUANTITY;

      switch (rateType) {
        case RATE_TYPE_PER_KILOMETER:
          rate = this.workingMatterQuotation && this.workingMatterQuotation.PerKilometerRate
            ? this.workingMatterQuotation.PerKilometerRate
            : this.userProfile.billingDetails.defaultPerKilometerRate;
          qty = 1;
          this.quantityReadOnly = false;
          break;

        case RATE_TYPE_HOURLY:
          rate = this.workingMatterQuotation && this.workingMatterQuotation.HourlyRate
            ? this.workingMatterQuotation.HourlyRate
            : this.userProfile.billingDetails.defaultHourlyRate;
          qty = 1;
          this.quantityReadOnly = false;
          break;

        case RATE_TYPE_DAILY:
          rate = this.workingMatterQuotation && this.workingMatterQuotation.DailyRate
            ? this.workingMatterQuotation.DailyRate
            : this.userProfile.billingDetails.defaultDailyRate;
          qty = 1;
          this.quantityReadOnly = false;
          break;

        case RATE_TYPE_ONCE_OFF:
          qty = 1;
          this.quantityReadOnly = true;
          break;
      }

      const fee = Object.assign({}, DEFAULT_QUOTATION_FEE_ITEM);
      fee.LineDate = moment().toJSON();
      fee.Rate = rate;
      fee.Total = rate * qty;
      fee.Quantity = qty;
      fee.LineDate = moment().toJSON();
      fee.MatterQuotationStatus = this.workingMatterQuotation.Status;
      fee.RateType = rateType;
      // fee.CanEdit = true;

      // Reset the selectedSubCategory
      this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);

      this.workingQuotationFees.push(fee);
      // this.workingFeesChange.emit(this.workingQuotationFees);

      this.calculateCurrentFeesTotal();
    } else {
      this.showAttorneyErrorPrompt = true;
    }
  } // end addAnotherFee()

  addAnotherActualFee() {
    if (this.workingMatterQuotation.ClientRefNo !== '' &&
    this.workingMatterQuotation.LawfirmId > 0 && this.workingMatterQuotation.PlaintiffFullName !== '') {
      const rateType: string = DEFAULT_RATE_TYPE;
      let rate: number = DEFAULT_RATE;
      let qty: number = DEFAULT_QUANTITY;

      switch (rateType) {
        case RATE_TYPE_PER_KILOMETER:
          rate = this.workingMatterQuotation && this.workingMatterQuotation.PerKilometerRate
            ? this.workingMatterQuotation.PerKilometerRate
            : this.userProfile.billingDetails.defaultPerKilometerRate;
          qty = 1;
          this.quantityReadOnly = false;
          break;

        case RATE_TYPE_HOURLY:
          rate = this.workingMatterQuotation && this.workingMatterQuotation.HourlyRate
            ? this.workingMatterQuotation.HourlyRate
            : this.userProfile.billingDetails.defaultHourlyRate;
          qty = 1;
          this.quantityReadOnly = false;
          break;

        case RATE_TYPE_DAILY:
          rate = this.workingMatterQuotation && this.workingMatterQuotation.DailyRate
            ? this.workingMatterQuotation.DailyRate
            : this.userProfile.billingDetails.defaultDailyRate;
          qty = 1;
          this.quantityReadOnly = false;
          break;

        case RATE_TYPE_ONCE_OFF:
          qty = 1;
          this.quantityReadOnly = true;
          break;
      }

      const fee = Object.assign({}, DEFAULT_QUOTATION_FEE_ITEM);
      fee.LineDate = moment().toJSON();
      fee.Rate = rate;
      fee.Total = rate * qty;
      fee.Quantity = qty;
      fee.LineDate = moment().toJSON();
      fee.MatterQuotationStatus = this.workingMatterQuotation.Status;
      fee.RateType = rateType;
      // fee.CanEdit = true;

      // Reset the selectedSubCategory
      this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);

      this.workingActualQuotationFees.push(fee);
      // this.workingFeesChange.emit(this.workingActualQuotationFees);

      this.calculateActualFeesTotal();
    } else {
      this.showAttorneyErrorPrompt = true;
    }
  } // end addAnotherActualFee()

  promptDeleteFee(fee: QuotationFeeItem) {
    this.selectedFee = fee;
    this.openModal('delete-fee-modal');
  } // end deleteFee()

  /**
   * Removes a fee from the collection of fees assocciated with the loaded
   * matter.
   * @param {Fee} deletableFee The fee to be deleted.
   */
  deleteFee(deletableFee: QuotationFeeItem) {
    // TODO: Delete the fee
    if (this.activeTab === 'quotation-fees') {
      this.previousFees = this.util.objectCopy(this.workingQuotationFees);
      this.workingQuotationFees = this.workingQuotationFees.filter(fee => {
        return !this.util.objectIsSame(deletableFee, fee);
      });

      if (deletableFee.FeeItemId) {
        this.feeDelete.emit(deletableFee);
      } else {
        this.workingFeesChange.emit(this.workingQuotationFees);
      }

      this.calculateCurrentFeesTotal();
    } else if (this.activeTab === 'actual-fees') {
        this.previousFees = this.util.objectCopy(this.workingActualQuotationFees);
        this.workingActualQuotationFees = this.workingActualQuotationFees.filter(fee => {
          return !this.util.objectIsSame(deletableFee, fee);
        });

        if (deletableFee.FeeItemId) {
          this.feeActualDelete.emit(deletableFee);
        } else {
          this.workingActualFeesChange.emit(this.workingActualQuotationFees);
        }
      this.calculateActualFeesTotal();
    }
  } // end deleteFee()

  /**
   * Reverses the most recent fee deletion.
   * @param {MatterFeesTableComponent} thisObj The calling object instance.
   */
  undoDelete(thisObj) {
    if (thisObj.previousFees) {
      this.snackbarsService.snackbarComponent.dismiss();
      thisObj.fees = thisObj.previousFees;
      thisObj.calculateCurrentFeesTotal();
      const snack: Snack = {
        label: 'Fee restored',
        action: null
      };
      this.snackbarsService.snackbarComponent.make(snack).show();
    }
  } // end undoDelete()


  /**
   * Opens a modal of the given name.
   * @param {string} modal The name of the modal to be opened.
   */
  openModal(modal: string) {
    switch (modal) {
      case 'delete-fee-modal':
        this.showDeleteFeeModal = true;
        break;
    }
  } // end openModal()

  deleteQuotationFee(deletableFee: QuotationFeeItem) {
    this.previousFees = this.util.objectCopy(this.workingQuotationFees);
    this.workingQuotationFees = this.workingQuotationFees.filter(fee => {
      return !this.util.objectIsSame(deletableFee, fee);
    });
    this.workingFeesChange.emit(this.workingQuotationFees);

    if (deletableFee.FeeItemId) {
      this.feeDelete.emit(deletableFee);
    }
    this.calculateCurrentFeesTotal();
  } // end deleteQuotationFee()

  /**
   * Closes a modal of the given name.
   * @param {string} modal The name of the modal to be closed.
   */
  closeModal(modal: string) {
    switch (modal) {
      case 'delete-fee-modal':
        this.showDeleteFeeModal = false;
        break;
    }
  } // end closeModal()

  /**
   * Called when the discount value changes.
   */
  onDiscountChange() {
    this.calculateCurrentFeesTotal();
  } // end onDiscountChange()

  onActualDiscountChange() {
    this.calculateActualFeesTotal();
  } // end onActualDiscountChange()

  onPaymentReceivedChange() {
    this.calculateCurrentFeesTotal();
  } // end onPaymentReceivedChange()

  /**
   * Toggles the useDiscountPercentage flag between true and false.
   */
  toggleUseDiscountPercentage() {
    this.useDiscountPercentage = !this.useDiscountPercentage;
    let discount;

    if (this.useDiscountPercentage) {
      discount = (this.currentFeesDiscount / this.currentFeesTotal) * 100;
    } else {
      discount = (this.currentFeesDiscount / 100) * this.currentFeesTotal;
    }
    this.currentFeesDiscount = discount;
    this.onDiscountChange();
  } // end toggleUseDiscountPercentage()

  /**
   * Returns a displayable date string, suitbale for use with inputs of type
   * calendar, from a given date string.
   * @param {string} date The date string;
   */
  parseDate(date: string) {
    return this.util.parseDate(date);
  } // end parseDate()
  onValueChange(fee: QuotationFeeItem, value: Date): void {
    fee.LineDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
  }

  /**
   * Parses through a fee subject and gets the fee description.
   * @param {Fee} fee The fee item object to be parsed.
   * @returns {string} Returns the fee description string.
   */
  getDescription(fee: QuotationFeeItem): string {
    const subject = fee.Subject
      .replace(/^[\w\s/\d.]+-\s/gi, '')
      .replace(/[\s]+-[\s]+[\w]+$/gi, '')
      .replace(' - ' + this.workingMatterQuotation.Status, '')
      .replace(' - ' + fee.MatterQuotationStatus, '').trim();

    return subject;
  } // end getDescription()

  /**
   * Culculates the total for a given fee based on the fee rate and quantitiy.
   * @param {number} rate The fee rate.
   * @param {number} qty The fee quantity.
   * @returns {string} Returns the calculated fee total as a currency
   * formatted string.
   */
  calculateFeeTotal(fee: QuotationFeeItem) {
    fee.Total = (fee.Rate * fee.Quantity);
    this.calculateCurrentFeesTotal();
  } // end calculateFeeTotal()

  calculateActualFeeTotal(fee: QuotationFeeItem) {
    fee.Total = (fee.Rate * fee.Quantity);
    this.calculateActualFeesTotal();
  } // end calculateActualFeeTotal()

  /**
   * Auto select all text on input
   * @param $event(inputId)
   */
  selectAllContent($event) {
    $event.target.select();
  } // end selectAllContent($event)

  /**
   * Calculates the sum of fee totals.
   */
  calculateCurrentFeesTotal() {
    let total = 0;
    this.workingQuotationFees.forEach(fee => {
      total += fee.Total;
    });

    this.currentFeesTotal = total;
    this.calculateCurrentFeesGrandTotal();
  } // end calculateCurrentFeesTotal()

  calculateActualFeesTotal() {
    let total = 0;
    this.workingActualQuotationFees.forEach(fee => {
      total += fee.Total;
    });

    this.currentActualFeesTotal = total;
    this.calculateActualFeesGrandTotal();
  } // end calculateActualFeesTotal()

  /**
   * Calculates the sum of fee totals taking into account the discount rate.
   */
  calculateCurrentFeesGrandTotal() {
    if (this.workingMatterQuotation.Discount) {

    } else {
      this.workingMatterQuotation.Discount = 0;
    }
      this.workingMatterQuotation.Total = this.currentFeesTotal - this.workingMatterQuotation.Discount;
  } // end calculateCurrentFeesGrandTotal()

  calculateActualFeesGrandTotal() {
    if (this.workingMatterQuotation.ActualDiscount) {

    } else {
      this.workingMatterQuotation.ActualDiscount = 0;
    }
    this.workingMatterQuotation.ActualTotal = this.currentActualFeesTotal - this.workingMatterQuotation.ActualDiscount;
  } // end calculateActualFeesGrandTotal()

  /**
   * Called when the date of the given fee is changed.
   * @param {Fee} fee The updated fee.
   * @param event The change event.
   */
  // onDateChange(fee: Fee, event) {
  //   fee.Date = moment(event.target.value).toJSON();
  //   this.workingFeesChange.emit(this.workingFees);
  // } // end onDateChange()

  onFeeDescriptionSelect(subCategory: SubCategory, fee: QuotationFeeItem) {
    this.selectedSubCategory = Object.assign({}, subCategory);

    fee.SubCategoryID = this.selectedSubCategory.SubCategoryId;
    fee.FurtherDescription = this.selectedSubCategory.SubCategory;
    fee.RateType = this.selectedSubCategory.RateType;
    // fee.Rate =
    //   this.util.getRateFromRateType(this.userProfile, fee.RateType);
    this.onRateTypeChange(fee);
    this.onRateChange(fee);
    // this.workingFeesChange.emit(this.workingQuotationFees);
    this.calculateFeeTotal(fee);
  } // end onFeeDescriptionSelect()

  onActualFeeDescriptionSelect(subCategory: SubCategory, fee: QuotationFeeItem) {
    this.selectedSubCategory = Object.assign({}, subCategory);

    fee.SubCategoryID = this.selectedSubCategory.SubCategoryId;
    fee.FurtherDescription = this.selectedSubCategory.SubCategory;
    fee.RateType = this.selectedSubCategory.RateType;
    // fee.Rate =
    //   this.util.getRateFromRateType(this.userProfile, fee.RateType);
    this.onRateTypeChange(fee);
    this.onRateChange(fee);
    // this.workingFeesChange.emit(this.workingActualQuotationFees);
    this.calculateActualFeeTotal(fee);
  } // end onActualFeeDescriptionSelect()

  /**
   * Called when the fee description input.
   * @param event The input event.
   */
  onFeeDescriptionInput(event, fee: QuotationFeeItem) {
    if (this.selectedSubCategory) {
      if (event && event.inputType && event.inputType.localeCompare('deleteContentBackward') === 0) {
        // Assuming a SubCategory has been selected
        if (fee.SubCategoryID) {
          this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
          this.selectedSubCategory.SubCategoryId = 0;
          fee.SubCategoryID = 0;
          fee.FurtherDescription = event.target.value;
        }
      } else {
        const description = this.selectedSubCategory.SubCategory;
        this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
        this.selectedSubCategory.SubCategory = description;
        fee.FurtherDescription = event.target.value;
      }
    } else {
      // If not backspace
      this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
      this.selectedSubCategory.SubCategoryId = 0;
      fee.FurtherDescription = event.target.value;
      fee.SubCategoryID = 0;
    }
    // this.workingFeesChange.emit(this.workingQuotationFees);
  } // end onFeeDescriptionInput()

  onFeeActualDescriptionInput(event, fee: QuotationFeeItem) {
    if (this.selectedSubCategory) {
      if (event && event.inputType && event.inputType.localeCompare('deleteContentBackward') === 0) {
        // Assuming a SubCategory has been selected
        if (fee.SubCategoryID) {
          this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
          this.selectedSubCategory.SubCategoryId = 0;
          fee.SubCategoryID = 0;
          fee.FurtherDescription = event.target.value;
        }
      } else {
        const description = this.selectedSubCategory.SubCategory;
        this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
        this.selectedSubCategory.SubCategory = description;
        fee.FurtherDescription = event.target.value;
      }
    } else {
      // If not backspace
      this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
      this.selectedSubCategory.SubCategoryId = 0;
      fee.FurtherDescription = event.target.value;
      fee.SubCategoryID = 0;
    }
    // this.workingFeesChange.emit(this.workingActualQuotationFees);
  } // end onFeeDescriptionInput()

  /**
   * Called when the ratetype of a fee is changed.
   * @param {Fee} fee The just updated fee.
   */
  onRateTypeChange(fee: QuotationFeeItem) {
    fee.Quantity = DEFAULT_QUANTITY;
    switch (fee.RateType) {
      case RATE_TYPE_PER_KILOMETER:
        fee.Rate = this.workingMatterQuotation.PerKilometerRate
          ? this.workingMatterQuotation.PerKilometerRate
          : this.userProfile.billingDetails.defaultPerKilometerRate;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;

      case RATE_TYPE_HOURLY:
        fee.Rate = this.workingMatterQuotation.HourlyRate
          ? this.workingMatterQuotation.HourlyRate
          : this.userProfile.billingDetails.defaultHourlyRate;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;

      case RATE_TYPE_ONCE_OFF:
        fee.Rate = 1.00;
        fee.Quantity = 1;
        this.quantityReadOnly = true;
        break;

      case RATE_TYPE_DAILY:
        fee.Rate = this.workingMatterQuotation.DailyRate
          ? this.workingMatterQuotation.DailyRate
          : this.userProfile.billingDetails.defaultDailyRate;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;

      case RATE_TYPE_DISBURSEMENT:
        fee.Quantity = 1;
        fee.Rate = 1.00;
        this.quantityReadOnly = true;
        break;

      case RATE_TYPE_NON_BILLABLE:
        fee.Rate = 0;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;

      case RATE_TYPE_PER_PAGE:
        fee.Rate = 1.00;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;
    }
    this.calculateFeeTotal(fee);
  } // end onRateTypeChange()

  onActualRateTypeChange(fee: QuotationFeeItem) {
    fee.Quantity = DEFAULT_QUANTITY;
    switch (fee.RateType) {
      case RATE_TYPE_PER_KILOMETER:
        fee.Rate = this.workingMatterQuotation.PerKilometerRate
          ? this.workingMatterQuotation.PerKilometerRate
          : this.userProfile.billingDetails.defaultPerKilometerRate;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;

      case RATE_TYPE_HOURLY:
        fee.Rate = this.workingMatterQuotation.HourlyRate
          ? this.workingMatterQuotation.HourlyRate
          : this.userProfile.billingDetails.defaultHourlyRate;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;

      case RATE_TYPE_ONCE_OFF:
        fee.Rate = 1.00;
        fee.Quantity = 1;
        this.quantityReadOnly = true;
        break;

      case RATE_TYPE_DAILY:
        fee.Rate = this.workingMatterQuotation.DailyRate
          ? this.workingMatterQuotation.DailyRate
          : this.userProfile.billingDetails.defaultDailyRate;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;

      case RATE_TYPE_DISBURSEMENT:
        fee.Quantity = 1;
        fee.Rate = 1.00;
        this.quantityReadOnly = true;
        break;

      case RATE_TYPE_NON_BILLABLE:
        fee.Rate = 0;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;

      case RATE_TYPE_PER_PAGE:
        fee.Rate = 1.00;
        fee.Quantity = 1;
        this.quantityReadOnly = false;
        break;
    }
    this.calculateActualFeeTotal(fee);
  } // end onActualRateTypeChange()

  onRateFocus(event, fee = null) {
    this.util.formatCurrencyInput(event.target);

    if (fee) {
      this.calculateFeeTotal(fee);
    }
  } // end onRateFocus()

  onActualRateFocus(event, fee = null) {
    this.util.formatCurrencyInput(event.target);

    if (fee) {
      this.calculateActualFeeTotal(fee);
    }
  } // end onActualRateFocus()

  onRateChange(event, fee: QuotationFeeItem = null) {
    this.util.formatCurrencyInput(event.target);

    if (fee) {
      this.calculateFeeTotal(fee);
    }
  } // end onRateChange()

  onActualRateChange(event, fee: QuotationFeeItem = null) {
    this.util.formatCurrencyInput(event.target);

    if (fee) {
      this.calculateActualFeeTotal(fee);
    }
  } // end onActualRateChange()

  canChangeRateType(fee: QuotationFeeItem): boolean {
    return Boolean(!fee.SubCategoryID);
  } // end canChangeRateType()

  onQuantityFocus(event) {
    // this.util.formatCurrencyInput(event.target);
  } // end onQuantityFocus()

  /**
   * Sets the IsVatable checkbox checked property.
   * @param event The mouse event object.
   * @param value The new vatable value.
   */
  setIsVatable(event, value) {
    event.target.checked = value;
  } // end setIsVatable()

  /**
   * Toggles the vatability of the given fee.
   * @param {Fee} fee The vatable fee.
   */
  toggleIsVatable(fee: QuotationFeeItem) {
    if (fee.RateType === 'Disbursement') {
      fee.IsVatable = !fee.IsVatable;
    }
  } // end toggleIsVatable()
} // end QuotationMatterFeesComponent{}
