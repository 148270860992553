import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-add-other-payment-terms',
  templateUrl: './add-other-payment-terms.component.html',
  styleUrls: ['./add-other-payment-terms.component.scss']
})
export class AddOtherPaymentTermsComponent implements OnInit {
  title = 'Other Payment Term';
  paymentTerms = 0;
  _paymentTerms = 0;

  @Input() isVisible: boolean;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() savePaymentTerms: EventEmitter<number> = new EventEmitter<number>();

  constructor() { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  onCancel() {
    this.paymentTerms = 0;
    this.closeModal.emit();
  } // end onCancel()

  dismiss() {
    this.onCancel();
  } // end dismiss()

  onSave() {
    if (this.paymentTerms > 0) {
      this.savePaymentTerms.emit(this.paymentTerms);
    }
  } // end onSave()

} // end AddOtherPaymentTermsComponent {}

