<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>GCB Listings for {{currentLaufirmNme}}</h2>
        <button class="modal-dismiss" (click)="onDismiss(false)">
          <i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i>
        </button>
      </div><!--#end .modal-header -->
      <nav class="navbar-tabs" *ngIf="showTab">
        <button [class.active]="tabView === 'manage-gcb-reports'" (click)="changeTabView('manage-gcb-reports')">MANAGE
          GCB REPORTS </button>
        <button [class.active]="tabView === 'add-new-gcb-listings'" (click)="changeTabView('add-new-gcb-listings')">ADD
          NEW GCB LISTINGS </button>
      </nav>

      <div *ngIf="tabView === 'manage-gcb-reports'">
        <div class="reconcile-table">
          <div class="table-responsive" style="min-height:382px; max-height: 382px;">
            <table class="table table-striped">
              <tbody>
                <tr style="position: sticky; top: -1px; z-index: 2; background-color:#dee2e6;">
                  <td><b>Bar Society</b></td>
                  <td><b>Reporting Advocate</b></td>
                  <td class="vl"><b>Date Reported</b></td>
                  <td style="width: 10%;"> </td>
                  <td class="vl" style="width: 15%; text-align: center"><b>Remove</b></td>
                </tr>
                <tr class="accordion-toggle parent" *ngFor="let pendingLawfirm of manageGCBReportData"
                  data-role="expander">
                  <td class="inbox-date-column clickable" scope="row" style="cursor: default;">
                    <span>{{pendingLawfirm.RegionalBarName ? pendingLawfirm.RegionalBarName :
                      'Not available'}} </span>
                  </td>
                  <td class="inbox-date-column clickable {{isGCBUser?'':'vl'}}" scope="row" style="cursor: default;">
                    <span>{{pendingLawfirm.ReportingAdvocateName ? pendingLawfirm.ReportingAdvocateName :
                      'Not available'}} </span>
                  </td>
                  <td class="inbox-date-column clickable vl" scope="row" style="cursor: default;">
                    {{pendingLawfirm.ReportingDate | date: "yyyy-MM-dd"}}
                  </td>
                  <td (click)="downloadBlackListPDF(pendingLawfirm.FilePath)" style="cursor: pointer;">
                    <i *ngIf="pendingLawfirm.FilePath" class="fal fa-paperclip fa-1x pt-1 " aria-hidden="true"></i>
                  </td>
                  <!-- <td *ngIf="pendingLawfirm.GCBDeleteStatus == 'Pending'" class="inbox-date-column clickable vl"
                    style="text-align: center;">
                    <i *ngIf="!showTab || (reportingBarId == pendingLawfirm.ReportingBarId)"
                      class="fa fa-paper-plane fa-lg tool-tip" style="color: #17A2B8;" aria-hidden="true"
                      (click)="onDeletePopPromptShow(pendingLawfirm.LawfirmBlackListID)">  
                       <div class="tooltiptextt" style="font-size: 10px;">
                        <p>Resend Remove Request</p>
                      </div>                    
                    </i>
                  </td> -->
                  <td *ngIf="pendingLawfirm.GCBDeleteStatus == 'Pending' && isGCBUser" class="inbox-date-column clickable vl"
                    style="text-align: center;">
                    <i *ngIf="!showTab || (reportingBarId == pendingLawfirm.ReportingBarId)"
                      class="fa fa-trash fa-lg tool-tip" style="color: #80808061" aria-hidden="true">
                      <div class="tooltiptextt" style="font-size: 11px;">
                        <p>Request already sent to GCB</p>
                      </div>
                    </i>
                  </td>
                  <td *ngIf="pendingLawfirm.GCBDeleteStatus != 'Pending' || !isGCBUser" class="inbox-date-column clickable vl"
                    style="text-align: center;">
                    <i *ngIf="(!showTab || (reportingBarId == pendingLawfirm.ReportingBarId))"
                      class="fa fa-trash fa-lg tool-tip" style="color: #cf624f;" aria-hidden="true"
                      (click)="onDeletePopPromptShow(pendingLawfirm.LawfirmBlackListID)">
                      <div *ngIf="isGCBUser" class="tooltiptextt" style="font-size: 11px;">
                        <p>Send Remove Request</p>
                      </div>
                    </i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div *ngIf="tabView === 'add-new-gcb-listings'">
        <!-- Reporting Bar -->
        <div class="form-group">
          <label class="ml" for="aging-name">Reporting Bar<span class="text-danger"> *</span></label>
          <div class="input-group">
            <input class="form-control" disabled type="text" [(ngModel)]="reportingBarFullName" autocomplete="off">
          </div>
        </div>

        <!-- Reporting Advocate -->
        <div class="form-group">
          <div class="col-md-12 pl-0 pr-0">
            <label class="ml">Reporting Advocate<span class="text-danger"> *</span></label>
            <div class="input-group">
              <input class="form-control" [(ngModel)]="reportingAdvocateName" [typeahead]="advocateList"
                typeaheadOptionField="ReportingAdvocateName" [typeaheadSingleWords]="false" (input)="onInput($event)"
                class="categotyText" placeholder="Select or start typing" (typeaheadOnSelect)="onSelect($event)"
                [typeaheadOptionsLimit]="100" typeaheadScrollable=true typeaheadOptionsInScrollableView="10"
                [typeaheadMinLength]="0" type="advocateList" class="form-control" [advocateList]="advocateList"
                [(selectedAdvocate)]="selectedAdvocate" (select)="onModelChange('advocateList', $event)"
                (blur)="onBlurAdvocateName()" (focus)="onFocusAdvocateName()">
              <div class="input-group-append" style="cursor: pointer;" (click)="addNewAdvocate()">
                <span class="input-group-text"><i class="fa fa-plus-circle fa-lg" style="color: #8abd37;"></i></span>
              </div>
            </div>
            <span *ngIf="isAdvocateNameSelected" class="text-danger">Please select Advocate.</span>
          </div>
        </div>

        <!-- Date -->
        <div class="form-group">
          <div class="col-md-12 pl-0 pr-0">
            <label class="ml">Report Date<span class="text-danger"> *</span></label>
            <div class="input-group">
              <input class="form-control input-date" name="date" style="margin: 0 !important;" type="text"
                id="date-from" #_dateFrom="bsDatepicker" bsDatepicker [bsValue]="bsValue"
                [(ngModel)]="blackListLawfirm.ReportingDate" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                placement="right" autocomplete="off" required>
              <div class="input-group-append" style="cursor: pointer;" (click)="_dateFrom.toggle()"
                [attr.aria-expanded]="_dateFrom.isOpen">
                <span class="input-group-text"><i class="fa fa-calendar"></i></span>
              </div>
            </div>
          </div>
        </div>

        <!-- File Upload -->
        <div class="form-group">
          <div class="col-md-12 pl-0 pr-0">
            <label class="ml">Supporting Documentation<span class="text-danger"> * </span>
              <i class="fa fa-info-circle tool-tip">
                <div class="tooltiptext">
                  <p>Attach Letter of Demand, Statements, Invoices, or any other relevant documentation</p>
                </div>
              </i>
            </label>
            <div class="col-md-12 row p-0">
              <div class="input-group col-md-6">
                <input class="form-control custom-file" #inputAttachmentFile type="text" name="attachmentText"
                  (click)="promptAttachment()" autocomplete="off" class="form-control" [(ngModel)]="attachment">
                <input #inputUploadFile type="file" accept=".pdf" name="fileUpload" id="attachmentUpload"
                  class="custom-file-input" (change)="attachmentChange($event)" style="display: none;">
              </div>
              <div class="input-group col-sm-6 pl-0 pr-0">
                <button class="btn btn-success form-control browse-file" (click)="promptAttachment()">Browse to
                  file</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Date -->
        <div class="form-group">
          <div class="col-md- pl-0 pr-0">
            <label class="ml">Demand Date<span class="text-danger"> *</span></label>
            <div class="input-group">
              <input class="form-control input-date" name="date" style="margin: 0 !important;" type="text"
                id="date-from" #_dateDemandDate="bsDatepicker" bsDatepicker [bsValueDemandDate]="bsValueDemandDate"
                [(ngModel)]="blackListLawfirm.DemandDate" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                placement="right" autocomplete="off" required>
              <div class="input-group-append" style="cursor: pointer;" (click)="_dateDemandDate.toggle()"
                [attr.aria-expanded]="_dateDemandDate.isOpen" [(ngModel)]="blackListLawfirm.DemandDate">
                <span class="input-group-text"><i class="fa fa-calendar"></i></span>
              </div>
            </div>
          </div>
        </div>

        <div class="row pl-2" style="padding-top: 0; padding-bottom: 0px;">
          <div class="col-sm-6">
            <div>
              <input #inputCheckSelectAll type="checkbox" [(ngModel)]="blackListLawfirm.HasConfirmedPopiCompliance">
              <span class="pl-4">Confirm POPI Compliance</span>
            </div>
          </div>
          <div class="col-sm-6"></div>
        </div>
        <!--         
        <div class="form-group">
          <div class="col-md-12 pl-0 pr-0">
            <label class="ml">Demand Date<span class="text-danger"> *</span></label>
            <div class="col-md-12 row p-0">
              <div class="input-group col-md-6">
                <div class="input-group">
                  <input class="form-control input-date" name="date" style="margin: 0 !important;" type="text"
                    id="date-from" #_dateFrom="bsDatepicker" bsDatepicker [bsValue]="bsValue"
                    [(ngModel)]="blackListLawfirm.ReportingDate" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                    placement="right" autocomplete="off" required>
                  <div class="input-group-append" style="cursor: pointer;" (click)="_dateFrom.toggle()"
                    [attr.aria-expanded]="_dateFrom.isOpen">
                    <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                  </div>
                </div>
              </div>
              <div class="input-group col-sm-6 pl-4 pr-0">
                <input #inputCheckSelectAll type="checkbox" [(ngModel)]="blackListLawfirm.HasConfirmedPopiCompliance">
                <span class="pl-3" style="margin-top: 6px;">Confirm POPI Compliance</span>
              </div>
            </div>
          </div>
        </div> -->

        <div class="row p-1">
          <div class="col-sm-12">
            <p style="font-size: 9px">Please take note that this action will report the Attorney to the LPC for possible
              investigation of
              misconduct for non-payment. In terms of to Rule 7.7.9 of the GCB rules “the Bar Council shall publish, for
              the information of members, details of attorneys on the list of defaulters in such a way as to ensure the
              confidentiality of the information and shall inform the Secretary of the GCB and the Local Law Society
              accordingly.</p>
          </div>
        </div>

        <div *ngIf="isMandatory">
          <div class="col-md-12 pl-0 pr-0" style="text-align: center;">
            <span class="text-danger">Please fill the mandatory field.</span>
          </div>
        </div>
      </div>
      <!--#end .modal-body -->

      <div class="modal-footer" *ngIf="tabView === 'add-new-gcb-listings'">
        <button class="btn btn-danger" (click)="onDismiss(false)">Cancel</button>
        <button class="btn btn-success" (click)="onSave()">
          <p style="margin: -5px;">Submit Request</p>
        </button>
      </div>

    </div><!--#end .modal-dialog -->
  </div><!--#end .modal-content-->
</div>

<div class="modal" *ngIf="viewAttachedPdf">
  <div class="modal-content" style="width: 50%; height: 90%;">
    <div class="modal-dialog">
      <div class="modal-header" style="margin-top: 7%;">
        <h2>Attorney Demand</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->
      <div class="modal-body form">
        <div style="width: 100%; height:550px; max-height: 570px;">
          <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" [openFile]="true" [viewBookmark]="true"
            [download]="true" [downloadFileName]="fileName"></ng2-pdfjs-viewer>
        </div>
      </div> <!-- #end .modal-body .form -->
    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->

<div class="modal" *ngIf="showDeletePopPrompt">
  <div class="modal-content" style="margin: 8.75rem auto auto !important; min-height:50%; max-height: 100%;">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>Delete defaulters listing</h2>
        <button class="modal-dismiss" (click)="onHideDeletePopPrompt()">
          <i class="fa fa-close"></i>
        </button>
      </div><!--#end .modal-header -->

      <!-- Notes-->
      <div class="modal-body form" style="min-height: 200px;">
        <div class="m-0">

          <div class="form-group" [ngClass]="{'has-error': notesInvalid}">
            <div class="col-sm-12 pl-0 pr-0">
              <label>Reason <span title="Required field"> *</span></label>
              <div class="input-group">
                <textarea type="text" name="Notes" [(ngModel)]="gcbDeleteNotes" placeholder="Enter your reason here"
                  class="form-control" autocomplete="off" rows="5" maxlength="500" required></textarea>
              </div>
              <span *ngIf="notesInvalid" class="text-danger">Reason is required.</span>
            </div>
          </div>

          <!-- File Upload -->
          <div class="form-group">
            <div class="col-md-12 pl-0 pr-0 pt-3">
              <label>Attach document
                <i class="fa fa-info-circle tool-tip">
                  <div class="tooltiptext">
                    <p>Attach any related documents in regard to the removal.</p>
                  </div>
                </i>
              </label>
              <div class="col-md-12 row p-0">
                <div class="input-group col-md-6">
                  <input class="form-control custom-file" #inputAttachmentFile type="text" class="form-control"
                    [(ngModel)]="gcbDeleteAttachment" (click)="promptGCBDeleteAttachment()" autocomplete="off">
                  <input #inputUploadFile type="file" class="custom-file-input" id="gcbDeleteAttachmentUpload"
                    style="display: none;" accept=".pdf" (change)="gcbDeleteAttachmentChange($event)">
                </div>
                <div class="input-group col-sm-6 pl-0 pr-0">
                  <button class="btn btn-success form-control browse-file" (click)="promptGCBDeleteAttachment()">Browse
                    to file</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!--#end .modal-body -->

      <div class="modal-footer">
        <button class="btn btn-danger" (click)="onHideDeletePopPrompt()">Cancel</button>
        <button class="btn btn-success" (click)="onDeleteLawfirmListingsItem()">Proceed</button>
      </div><!-- #end .modal-footer -->

    </div><!--#end .modal-dialog -->
  </div><!--#end .modal-content -->
</div><!--#end .modal -->


<app-request-new-advocate-gcb-modal [isVisible]="showNewAdvocateModal" [showAdvocateListTab]="showAdvocateListTab"
  [CurrentRegionalBar]="regionalBarName" [currentLawfirmId]="currentLawfirmId" (closeModal)="closeAdvocateModal()"
  (isDirectCloseAdvocateModal)="isDirectCloseAdvocateModal()"
  (selectedExstAdvocateName)="selectedExstAdvocateName($event)"></app-request-new-advocate-gcb-modal>