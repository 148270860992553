import { Component, OnInit } from '@angular/core';
import { OtherClients, OtherClientsService, DEFAULT_OTHERCLIENTS_TEMPLATE } from '../../services/other-clients/other-clients.service';
import { SnackbarsService, Snack, SnackType } from '../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { ICrumb } from '../../services/advocate-detail-statement/advocate-detail-statement.service';

interface OtherClientHandle {
  data: OtherClients;
  state: {
    isVisible: boolean;
  };
}

@Component({
  selector: 'app-add-edit-clients',
  templateUrl: './add-edit-clients.component.html',
  styleUrls: ['./add-edit-clients.component.scss']
})
export class AddEditClientsComponent implements OnInit {

  title = 'ADD OR EDIT CLIENTS';
  crumbs: ICrumb[];
  showAddClientModal = false;
  clients: OtherClients[];
  activeClient: OtherClients;
  clientHandles: OtherClientHandle[] = [];
  searchTerm: string;

  orderingOptions: string[] = [
    'client-name',
    'email'
  ];

  sortClientName: boolean;
  sortEmail: boolean;

  sortBy: string;
  orderBy: string;
  sortAscending: boolean;
  previousSortBy: string;

  constructor(
    private otherClientService: OtherClientsService,
    private snackbarService: SnackbarsService,
    private loadingService: LoadingService
  ) { } // end constructor()

  ngOnInit() {
    this.crumbs = [
      {
        label: 'ADD OR EDIT CLIENTS'
      }
    ];
    this.getClients();
  } // end ngOnInit()

  toggleSort(sortBy: string) {
    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }

    switch (this.sortBy) {
      case 'client-name':
        this.sortClientName = true;
        this.sortEmail = false;
        this.clientHandles.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.FullName.toUpperCase().localeCompare(b.data.FullName.toUpperCase());
          }
          return b.data.FullName.toUpperCase().localeCompare(a.data.FullName.toUpperCase());
        });
      break;

      case 'email':
        this.sortClientName = false;
        this.sortEmail = true;
        this.clientHandles.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.EmailAddress.toUpperCase().localeCompare(b.data.EmailAddress.toUpperCase());
          }
          return b.data.EmailAddress.toUpperCase().localeCompare(a.data.EmailAddress.toUpperCase());
        });
      break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleSort()

  orderClients() {

    switch (this.orderBy) {
      case 'client-name':
        this.clientHandles.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.FullName.toUpperCase().localeCompare(b.data.FullName.toUpperCase());
          }
          return b.data.FullName.toUpperCase().localeCompare(a.data.FullName.toUpperCase());
        });
      break;

      case 'email':
        this.clientHandles.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.EmailAddress.toUpperCase().localeCompare(b.data.EmailAddress.toUpperCase());
          }
          return b.data.EmailAddress.toUpperCase().localeCompare(a.data.EmailAddress.toUpperCase());
        });
      break;
    }
    this.previousSortBy = this.orderBy;
  } // end toggleSort()

  toggleAscending() {
    this.sortAscending = !this.sortAscending;
    this.orderClients();
  } // end toggleAscending()

  onAddNewClient() {
    this.activeClient = Object.assign({}, DEFAULT_OTHERCLIENTS_TEMPLATE);
    this.showAddClientModal = true;
  } // end onAddNewClient()

  onCloseModal() {
    this.showAddClientModal = false;
  } // end onCloseModal()

  onBlur() {

  } // end onBlur()

  onSaveOtherClients(client: OtherClients) {
    this.loadingService.showOverlay();

    console.log(client);

    const isNewClient: boolean = client.ContactID < 1;
    const snack: Snack = {
      label: isNewClient
        ? 'Creating new Client...'
        : 'Saving Client...',
        action: null
    };
    this.snackbarService.snackbarComponent.make(snack, 5000).show();

    if (!isNewClient) {
      // Update an existing client
      client.Flag = true;
      this.otherClientService.updateOtherClient(client)
      .subscribe({ next: (next) => {
        // On next
        this.getClients();
      },
      error: (error) => {
        snack.label = 'Error updating client.';
        snack.type = SnackType.ERROR;
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        snack.label = 'Client updated.';
        snack.type = SnackType.SUCCESS;
        this.loadingService.hideOverlay();
      }
    });
    } else {
      client.Flag = true;
      this.otherClientService.createClient(client)
      .subscribe({ next: (created_client) => {
        // On next
      },
      error: (error) => {
        snack.label = 'Error on updating client';
        snack.type = SnackType.ERROR;
        this.snackbarService.dismiss().make(snack, 5000).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        snack.label = 'Client created';
        snack.type = SnackType.SUCCESS;
        this.snackbarService.dismiss().make(snack, 5000).show();
        this.getClients();
      }
    });
    }
  } // end onSaveOtherClients()

  onSearchClient(event) {
    console.log(this.searchTerm);
    if (this.searchTerm) {
      this.clientHandles.forEach(client => {
        if (
          // !(client.data.EmailAddress.match(new RegExp(this.searchTerm, 'gi'))) &&
          !(client.data.FullName.match(new RegExp(this.searchTerm, 'gi')))
          // !(client.data.IDNumber.match(new RegExp(this.searchTerm, 'gi'))) &&
          // !(client.data.MobileNumber.match(new RegExp(this.searchTerm, 'gi'))) &&
          // !(client.data.VATNumber.match(new RegExp(this.searchTerm, 'gi')))
        ) {
          client.state.isVisible = false;
        } else {
          client.state.isVisible = true;
        }
      });
    } else {
      this.clientHandles.forEach(client => {
        client.state.isVisible = true;
      });
    }
  } // end onSearchClient()

  clearSearch() {
    this.searchTerm = null;
    this.clientHandles.forEach(client => {
      client.state.isVisible = true;
    });
  }

  getClients() {
    this.loadingService.showOverlay();
    this.clients = [];

    const snack: Snack = {
      label: 'Loading clients.',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack, 5000).show();

    this.otherClientService.getOtherClients()
    .subscribe({ next: (client) => {
      // On next
      this.clients = client;
      this.clientHandles = [];
      this.clients.forEach(clien => {
        if (clien.Flag) {
          this.clientHandles.push({
            data: clien,
            state: {
              isVisible: true
            }
          });
        }
      });
    },
    error: (error) => {
      // On error
      snack.label = 'Error loading clients.';
      snack.type = SnackType.ERROR;
      this.snackbarService.dismiss().make(snack, 5000).show();
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.loadingService.hideOverlay();
    }
  });
  } // end getClients()

  onEditClient(client: OtherClients) {
    this.activeClient = client;
    this.showAddClientModal = true;
  } // end onEditClient()

  onDeleteClient(client: OtherClients) {
    this.loadingService.showOverlay();
    this.otherClientService.DeleteClient(client.Id).subscribe({next: (del) => {
      // On next
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.getClients();
      this.loadingService.hideOverlay();
    }
  });
  } // end onDeleteClient()

} // end AddEditClientsComponent{}
