<div class="container-fluid">
  <div class="process-body row">

    <div class="left-block col-sm-8">
      <div class="block-title">Team Notes</div>

      <div class="row m-0">
        <div class="table-responsive">

          <table class="table table-striped">
            <tbody>
              <tr>
                <th scope="col" class="w10">Note Date</th>
                <th scope="col" class="w15">Note By</th>
                <th scope="col" class="w10">Role</th>
                <th scope="col" class="w20">Subject</th>
                <th scope="col" class="w45">Note</th>
              </tr>
              <tr class="tr" *ngFor="let data of stickyNoteHandle">
                <td class="w10" scope="row" *ngIf="data.state.isVisible">{{data.data.CaptureDate | date:'yyyy-MM-dd'}}</td>
                <td class="w15" scope="row" *ngIf="data.state.isVisible">{{data.data.NoteLoggedBy}}</td>
                <td class="w10" scope="row" *ngIf="data.state.isVisible">{{data.data.NoteLoggedByProfileType}}</td>
                <td class="w20" scope="row" *ngIf="data.state.isVisible">{{data.data.NoteSubject}}</td>
                <td class="w45" scope="row" *ngIf="data.state.isVisible">{{data.data.Note}}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div> <!-- #end .row .m-0 -->
      <div class="row">
        <div class="row">
          <div class="form-group">
            <label for="category">Category</label>
            <input
              id="subject"
              class="input"
              placeholder="Select or start typing"
              [(ngModel)]="selectedNoteCategoryText"
              [typeahead]="stickyNoteCategory"
              typeaheadOptionField="Category"
              (input)="onCategoryInput($event)"
              style="width: 100% !important;"
              placeholder="Select or start typing"
              (typeaheadOnSelect)="onSelectCategory($event)"
              [typeaheadOptionsLimit]="50"
              typeaheadScrollable=true
              typeaheadOptionsInScrollableView="8"
              [typeaheadMinLength]="0"
              (focus)="$event.target.select()">
              <!-- <select
                id="category"
                [(ngModel)]="selectedNoteCategory.Id"
                (change)="onSelectCategory($event)">
                <option value=0 disabled selected hidden>Select Category</option>
                <option
                  *ngFor="let category of stickyNoteCategory"
                  [value]="category.Id">{{category.Category}}</option>
              </select> -->
          </div>
          <div class="form-group">
            <label for="sub-category">Subcategory</label>
            <input
              id="subcategory"
              placeholder="Select or start typing"
              [(ngModel)]="selectedNoteSubCategoryText"
              [typeahead]="stickyNoteTypes"
              typeaheadOptionField="Name"
              (input)="onSubcategoryInput($event)"
              style="width: 100% !important;"
              placeholder="Select or start typing"
              (typeaheadOnSelect)="onSelectSubCategory($event)"
              [typeaheadOptionsLimit]="50"
              typeaheadScrollable=true
              typeaheadOptionsInScrollableView="8"
              [typeaheadMinLength]="0"
              (focus)="$event.target.select()">
            <!-- <select
              id="sub-category"
              (change)="onSelectSubCategory($event)"
              [(ngModel)]="selectedNoteType.Id">
              <option value=0 disabled selected hidden>Select Subcategory</option>
              <option
                *ngFor="let sub of stickyNoteTypes"
                [value]="sub.Id">{{sub.Name}}</option>
            </select> -->
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <label for="subject">Note</label>
            <textarea
              id="subject"
              class="input"
              placeholder="Note subject"
              [(ngModel)]="noteToSave.Note"></textarea>
          </div>
        </div>
        <div class="row">
          <button
            class="btn btn-success"
            [class.disabled]="!isSaveable"
            [disabled]="!isSaveable"
            (click)="onNoteSave()">Save note</button>
          </div>
      </div>
    </div> <!-- #end .left-block col-sm-6 -->

    <div class="right-block col-sm-4">
      <div class="single-note-pane" *ngIf="latestNote">
        <div class="block-title">Latest Team Note</div>
        <div class="form-group">
          <label><b>Note by</b></label>
          <label>{{latestNote.NoteLoggedBy}} <small>({{latestNote.NoteLoggedByProfileType}})</small></label>
        </div>
        <div class="form-group">
          <label><b>Subject</b></label>
          <label>{{latestNote.NoteSubject}}</label>
        </div>
        <div class="form-group">
          <label><b>Captured Date</b></label>
          <label>{{latestNote.CaptureDate | date:'yyyy-MM-dd'}}</label>
        </div>
        <div class="form-group">
          <textarea readonly>{{latestNote.Note}}</textarea>
        </div>
      </div>
    </div> <!-- #end .right-block .col-sm-6 -->

  </div> <!-- #end .process-body .row -->
</div> <!-- #end .container-fluid -->