
<div class="panel-area">
    <div class="row">
        <div class="form-group">
            <label for="view-dashboard">Hide/Show Dashboard</label>
            <div class="view-dashboard">
              <i
                class="fa fa-toggle-on active"
                id="view-dashboard"
                *ngIf="dashboardstatus == true"
                (click)="changeStatus();"></i>
              <i
                class="fa fa-toggle-on fa-rotate-180 inactive"
                id="view-dashboard"
                *ngIf="dashboardstatus == false"
                (click)="changeStatus();"></i>
            </div>
        </div>

      <div class="form-group">
          <label for="view-dashboard">Manual Transactions </label>
          <div class="view-dashboard">
            <i
              class="fa fa-toggle-on active"
              id="view-dashboard"
              *ngIf="workingProfileSettings.ManualTransaction == true"
              (click)="changeManualTransactionsStatus();"></i>
            <i
              class="fa fa-toggle-on fa-rotate-180 inactive"
              id="view-dashboard"
              *ngIf="workingProfileSettings.ManualTransaction == false"
              (click)="changeManualTransactionsStatus();"></i>
          </div>      
      </div>

      <div class="form-group">
          <label for="view-dashboard">BCC </label>
          <div class="input-group" style="margin-left: 10px;">
          <app-checkbox
          [(checked)]="workingProfileSettings.BCC"
          (click)="toggleSelectbCC()"></app-checkbox>
          </div>
        </div>

        <div class="form-group" *ngIf="workingProfileSettings.BCC">
          <label for="view-dashboard" *ngIf="workingProfileSettings.BCC">Email address</label>
          <input
            *ngIf="workingProfileSettings.BCC"
            type="text"
            class="form-control"
            [(ngModel)]="workingProfileSettings.BCCEmail">
      </div>

      <div class="form-group" *ngIf="!internalAssistant && isPracticeManager">
        <label style="vertical-align: middle; margin-top: -7px;" for="practice-manager">Active/Inactive</label>
        <div class="view-dashboard">
          <i
          class="fa fa-toggle-on active" 
          id="view-dashboard" 
          *ngIf="workingProfileSettings.Active === true"
          (click)="changeSubscriptionStatus()"></i>
          <i
          class="fa fa-toggle-on fa-rotate-180 inactive" 
          id="view-dashboard"
          *ngIf="workingProfileSettings.Active === false" 
          (click)="changeSubscriptionStatus()"></i>          
        </div>
      </div>
      <div class="form-group" *ngIf="isAdvocate || internalAssistant"></div>
      <div class="form-group" *ngIf="workingProfileSettings.BCC === false"></div>
      <div class="form-group"></div>
  </div>
</div>

<div class="panel-area">
    <div class="row">
      <div class="table-responsive" style="max-height: 400px; padding-bottom: 20px;">
        <table class="table table-striped">
          <tbody>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
            <tr class="tr table-row" *ngFor="let teams of assistantHandles" [class.hide]="!teams.state.isVisible">
              <td scope="row">{{teams.data.FullName}}</td>
              <td scope="row">{{teams.data.Email}}</td>
              <td scope="row">{{teams.data.RoleName}}</td>
              <td scope="row">{{teams.data.Status}}</td>
              <td scope="row">
                <a title="Edit team member" (click)="onEditTeams(teams.data)">
                  <i class="fal fa-pencil" style="font-size: 14px !important;"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <span class="clickable" (click)="onAddNewTeamMember()">
                  <i class="fa fa-plus-circle fa-lg auxcon-green"></i>
                  <u class="auxcon-green">ADD A TEAM MEMBER</u>
                </span>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
</div>

<app-prompt-unsaved-changes-modal
  [title]="popTitle"
  [message]="popupMessage"
  [isVisible]="showProfileActivationPrompt" 
  (cancel)="onHideProfileActivationPrompt()"
  (continue)="onProfileActivateOrDeactivate()" 
  btnTextCancel="Cancel" 
  btnTextContinue="Yes, Proceed">
</app-prompt-unsaved-changes-modal>

<app-add-team-member-modal
  [isVisible]="showAddTeamMember"
  [teamMember]="activeTeamMembers"
  (closeModal)="onCloseModal()"></app-add-team-member-modal>