import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import {
  UserSubscription,
  DEFAULT_USER_SUBSCRIPTION,
  AdvocateOverviewServiceService,
  PracticeManagerDTO,
  DEFAULT_PRACTICE_MANAGER_DTO
} from '../../../services/advocate-overview-service/advocate-overview-service.service';
import {
  UserProfileService,
  UserProfile
} from '../../../services/user-profile/user-profile.service';
import * as moment from 'moment';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
// import { TypeaheadMatch } from 'ngx-bootstrap';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  subscriptionRenewal: any;
  subscriptionTerm: any;
  subscriptionIncrease: any;

  popTitle = '';
  popupMessage = '';
  practiceMChangeTitle = '';
  practiceMChangeMessage = '';
  showPracticeMChangePrompt: boolean;
  showProfileActivationPrompt: boolean;
  practiceManagers: PracticeManagerDTO[] = [];
  selectPracticeManagerText: string;
  selectPracticeManager: PracticeManagerDTO = Object.assign({}, DEFAULT_PRACTICE_MANAGER_DTO);

  private profile: UserProfile;
  @Input() workerUserSubscription: UserSubscription;
  @Input() userSubscription: UserSubscription;
  @Output() subscriptionStatusUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onchangePM: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputReconAmount') focusReconAmount: ElementRef;
  @ViewChild('inputeInvoicingAmount') focusInvoicingAmount: ElementRef;
  @ViewChild('inputeCollectionsAmount') focusCollectionsAmount: ElementRef;
  @ViewChild('inputMonthlySiteAmount') focusMonthlySiteAmount: ElementRef;
  @ViewChild('inputeExpenseAmount') focusExpenseManagementAmount: ElementRef;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  get loggedInUserProfile(): number {
    return this.userProfileService.userProfile.serviceProviderID;
  } // end loggedInUserProfile()

  // minMode: BsDatepickerViewMode = 'month';
  // bsConfig: Partial<BsDatepickerConfig>;

  constructor(
    private cpipe: UsercurrencyPipe,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private advocateOverviewService: AdvocateOverviewServiceService
  ) { } // end constructor()

  ngOnInit() {
    // this.bsConfig = Object.assign({}, {
    //   minMode: this.minMode
    // });
    // this.profile = this.userProfileService.selectedUserProfile;
    // if (this.profile) {
    //   this.userSubscription.SignedDate = this.profile.settings.signedDate;
    //   this.userSubscription.SubscriptionRenewal = this.profile.settings.SubscriptionRenewal;
    //   this.userSubscription.SubscriptionTerm = this.profile.settings.SubscriptionTerm;
    //   this.userSubscription.SubscriptionIncrease = this.profile.settings.SubscriptionIncrease;
    //   this.userSubscription.DebitOrder = this.profile.settings.DebitOrder;
    //   this.userSubscription.AuxconBaseSystem = this.profile.settings.AuxconBaseSystem;
    //   this.userSubscription.AuxconInvoicing = this.profile.settings.AuxconInvoicing;
    //   this.userSubscription.AuxconReconcilliations = this.profile.settings.AuxconReconcilliations;
    //   this.userSubscription.AuxconCollections = this.profile.settings.AuxconCollections;
    //   this.userSubscription.PremiumInvoicing = this.profile.settings.PremiumInvoicing;
    //   this.userSubscription.PremiumCollections = this.profile.settings.PremiumCollections;
    // }

    this.subscriptionRenewal = [
      { label: 'Annually', value: 'Annually' },
      { label: 'Six Months', value: 'Six Months' },
      { label: 'Three years', value: 'Three years' },
    ];

    this.subscriptionTerm = [
      { label: '12 Months', value: '12 Months' },
      { label: '6 Months', value: '6 Months' },
      { label: '36 Months', value: '36 Months' },
    ];

    this.subscriptionIncrease = [
      { label: '8.5% p/a', value: '8.5% p/a' }
    ];
    this.selectPracticeManagerText = this.workerUserSubscription.PracticeManager;
    this.getPracticeManagerList();
  } // end ngOnInit()

  parseDate(serviceDate: string): Date {
    if (serviceDate) {
      return new Date(serviceDate);
    } else {
      return new Date('1000-01-01');
    }
  } // end parseDate()

  parseDebitOrderDate(serviceDate: string): string {
    if (serviceDate) {
      return moment(serviceDate).format('Do');;
    } else {
      return null;
    }
  } // end parseDebitOrderDate()

  changeStatus() {
    if (this.workerUserSubscription.SubscriptionStatus) {
      this.popTitle = 'Deactivate Profile';
      this.popupMessage = 'You are about to deactivate ' + this.userProfileService.selectedUserProfile.personalDetails.fullName +
      ' profile. Are you sure you want to deactivate this profile?';
      this.showProfileActivationPrompt = true;
    } else {
      this.popTitle = 'Activate Profile';
      this.popupMessage = 'You are about to activate ' + this.userProfileService.selectedUserProfile.personalDetails.fullName +
      ' profile. Are you sure you want to activate this profile?';
      this.showProfileActivationPrompt = true;
    }
  } // end changeStatus()

  onHideProfileActivationPrompt() {
    this.showProfileActivationPrompt = false;
  } // end onHideProfileActivationPrompt()

  onProfileActivateOrDeactivate() {
    this.workerUserSubscription.SubscriptionStatus = !this.workerUserSubscription.SubscriptionStatus;
    this.subscriptionStatusUpdate.emit(this.workerUserSubscription);
    this.showProfileActivationPrompt = false;
  } // end onProfileActivateOrDeactivate()

  ontoggleDebitYes() {
    this.workerUserSubscription.DebitOrder = true;
  } // end ontoggleDebitYes()

  ontoggleDebitNo() {
    this.workerUserSubscription.DebitOrder = false;
  } // end ontoggleDebitNo()

  onDateValueChange(value: Date): void {
    this.workerUserSubscription.SignedDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
  } // end onDateValueChange()
  onDateStatementRunValueChange(value: Date): void {
    this.workerUserSubscription.StatementRunDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
  } // end onDateStatementRunValueChange()

  onDebitDateChange(value: Date): void {
    this.workerUserSubscription.DebitDate = moment(value.toDateString()).format('Do');
  } // end onDebitDateChange()

  onMonthlySiteAmountChange() {
    this.focusMonthlySiteAmount.nativeElement.value =
      this.cpipe.transform(this.focusMonthlySiteAmount.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    if (this.workerUserSubscription.MonthlySiteAmount) {
      this.workerUserSubscription.AuxconMonthlySiteRental = true;
    } else {
      this.workerUserSubscription.AuxconMonthlySiteRental = false;
    }
  } // end onMonthlySiteAmountChange()

  onMonthlySiteAmountClicked() {
    this.focusMonthlySiteAmount.nativeElement.value =
      this.cpipe.parse(this.focusMonthlySiteAmount.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
  } // end onMonthlySiteAmountClicked()

  onReconAmountChange() {
    this.focusReconAmount.nativeElement.value =
      this.cpipe.transform(this.focusReconAmount.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    if (this.workerUserSubscription.ReconciliationAmount) {
      this.workerUserSubscription.AuxconReconcilliations = true;
    } else {
      this.workerUserSubscription.AuxconReconcilliations = false;
    }
  } // end onReconAmountChange()

  onReconAmountClicked() {
    this.focusReconAmount.nativeElement.value =
      this.cpipe.parse(this.focusReconAmount.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
  } // end onReconAmountClicked()

  onExpenseManagementAmountChange() {
    this.focusExpenseManagementAmount.nativeElement.value =
      this.cpipe.transform(this.focusExpenseManagementAmount.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    if (this.workerUserSubscription.ExpenseAmount) {
      this.workerUserSubscription.AuxconExpenseManagement = true;
    } else {
      this.workerUserSubscription.AuxconExpenseManagement = false;
    }
  } // end onExpenseManagementAmountChange()

  onExpenseManagementAmountClicked() {
    this.focusExpenseManagementAmount.nativeElement.value =
      this.cpipe.parse(this.focusExpenseManagementAmount.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
  } // end onExpenseManagementAmountClicked()

  onInvoicingAmountChange() {
    this.focusInvoicingAmount.nativeElement.value =
      this.cpipe.transform(this.focusInvoicingAmount.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    if (this.workerUserSubscription.InvoicingAmount) {
      this.workerUserSubscription.AuxconInvoicing = true;
    } else {
      this.workerUserSubscription.AuxconInvoicing = false;
    }
  } // end onInvoicingAmountChange()

  onInvoicingAmountClicked() {
    this.focusInvoicingAmount.nativeElement.value =
      this.cpipe.parse(this.focusInvoicingAmount.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
  } // end onInvoicingAmountClicked()

  onCollectionsAmountChange() {
    this.focusCollectionsAmount.nativeElement.value =
      this.cpipe.transform(this.focusCollectionsAmount.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    if (this.workerUserSubscription.CollectionsAmount) {
      this.workerUserSubscription.AuxconCollections = true;
    } else {
      this.workerUserSubscription.AuxconCollections = false;
    }
  } // end onCollectionsAmountChange()

  onCollectionsAmountClicked() {
    this.focusCollectionsAmount.nativeElement.value =
      this.cpipe.parse(this.focusCollectionsAmount.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
  } // end onCollectionsAmountClicked()

  onAuxconMonthlySiteRentalChange() {
    if (this.workerUserSubscription.AuxconMonthlySiteRental === false) {
      this.workerUserSubscription.MonthlySiteAmount = 0;
    }
  } // end onAuxconMonthlySiteRentalChange()

  onAuxconReconciliationChange() {
    if (this.workerUserSubscription.AuxconReconcilliations === false) {
      this.workerUserSubscription.ReconciliationAmount = 0;
    }
  } // end onAuxconReconciliationChange()

  onAuxconInvoicingChange() {
    if (this.workerUserSubscription.AuxconInvoicing === false) {
      this.workerUserSubscription.InvoicingAmount = 0;
    }
  } // onAuxconInvoicingChange()

  onAuxconExpenseManagementChange() {
    if (this.workerUserSubscription.AuxconExpenseManagement === false) {
      this.workerUserSubscription.ExpenseAmount = 0;
    }
  } // end onAuxconExpenseManagementChange()

  onAuxconCollectionsChange() {
    if (this.workerUserSubscription.AuxconCollections === false) {
      this.workerUserSubscription.CollectionsAmount = 0;
    }
  } // end onAuxconCollectionsChange()

  getPracticeManagerList() {
    this.loadingService.showOverlay();
    this.practiceManagers = [];
    this.advocateOverviewService.getPracticeManagers()
    .subscribe({
      next: (pm) => {
        this.practiceManagers = pm;
      },
      error: (error) => {
        // On error
      this.loadingService.hideOverlay();
      },
      complete: () => {
         // On complete
      this.practiceManagers = this.practiceManagers.filter(s => s.PracticeManagerName !== this.workerUserSubscription.PracticeManager);
      this.loadingService.hideOverlay();
      }
    });
    
    // pm => {
    //   // On next
    //   this.practiceManagers = pm;
    // },
    // error => {
    //   // On error
    //   this.loadingService.hideOverlay();
    // },
    // () => {
    //   // On complete
    //   this.practiceManagers = this.practiceManagers.filter(s => s.PracticeManagerName !== this.workerUserSubscription.PracticeManager);
    //   this.loadingService.hideOverlay();
    // });
  } // end getPracticeManagerList()

  onPMSelect(value: TypeaheadMatch): void {
    this.selectPracticeManager = Object.assign({}, DEFAULT_PRACTICE_MANAGER_DTO);
    this.selectPracticeManager = value.item;
    this.selectPracticeManagerText = value.item.PracticeManagerName;
    this.practiceMChangeTitle = 'Change Practice Manager';
    this.practiceMChangeMessage = 'You are about to change Practice Manager on '
    + this.userProfileService.selectedUserProfile.personalDetails.fullName +
    '. Are you sure you want to proceed?';
    this.showPracticeMChangePrompt = true;
  } // end onPMSelect()

  onPMInput(event) {

  } // end onPMInput()

  onHidePracticeMChangePrompt() {
    this.selectPracticeManagerText = this.workerUserSubscription.PracticeManager;
    this.selectPracticeManager = Object.assign({}, DEFAULT_PRACTICE_MANAGER_DTO);
    this.showPracticeMChangePrompt = false;
  } // end onHidePracticeMChangePrompt()

  onPracticeManangerChange() {
    this.selectPracticeManager.ContactId = this.userProfileService.selectedUserProfile.serviceProviderID;
    this.onchangePM.emit(this.selectPracticeManager);
    this.selectPracticeManager = Object.assign({}, DEFAULT_PRACTICE_MANAGER_DTO);
    this.showPracticeMChangePrompt = false;
  } // end onPracticeManangerChange()
} // end SubscriptionComponent{}
