<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div class="container-fluid">
  <!-- <h1 class="page-title">{{title}}</h1> -->
  <div class="form">
  
    <div class="form-group">
      <label for="receipients">Send invoice to</label>
      <input
        type="email"
        class="form-control"
        [ngModelOptions]="{updateOn: 'blur'}"
        [class.has-error]="hasContactEmailError"
        id="receipients"
        placeholder="Receipient email"
        [(ngModel)]="message.Recipients"
        (click)="$event.target.select()">
        <span
        *ngIf="hasContactEmailError"
        class="text-danger">Please enter a valid email address.</span>
    </div><!--#end .form-group -->
  
    <div class="form-group">
      <label for="subject">Email subject</label>
      <input
        readonly
        type="text"
        [id]="'subject'"
        placeholder="Subject"
        [(ngModel)]="message.Subject">
    </div><!--#end .form-group -->
  
    <div class="form-group">
      <label for="body">Email body</label>
      <iframe
        id="body"
        contenteditable="true"
        sandbox="allow-same-origin allow-scripts"
        srcdoc={{messageBodyWithoutStyle}}
        class="input"></iframe>
    </div><!--#end .form-group -->
  
    <div class="form-group">
      <label for="attachments">Attachments</label>
      <div class="input">
      <!-- (click)="onViewInvoice(message.SourceId)" -->
      <!-- View invoice -->
        <a
          [href]="downloadLink"
          target="_blank">Invoice; </a>
          <span *ngFor="let file of briefDocuments">
            <span *ngIf="file.Status" [title]="file.DocumentPath">
              <i class="far fa-times-circle auxcon-red" (click)="removeAnotherDocument(file)"></i>
              <a [href]="briefDownloadLink + file.BriefId" target="_blank">
              {{file.DocumentType}}; </a></span>
          </span>
          <span class="add-attachment" (click)="addAnotherDocument()">
            <i class="fa fa-plus-circle auxcon-green"></i>
            add an attachment
          </span>
      </div>
    </div><!--#end .form-group -->
  
    <div class="form-group padding">
      <app-checkbox
        [(checked)]="message.RolloverService"
        (click)="toggleKeepActiveInCurrentMatters()">
      </app-checkbox>&nbsp;
      <span
        class="clickable"
        (click)="toggleKeepActiveInCurrentMatters()">
        Keep active in current matters</span>
    </div>
  
  </div><!--#end .form -->

  <div class="row">
    <!-- <button
      class="btn btn-success"
      (click)="editMatter()">Edit matter details or fees</button> -->
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <button
      class="btn btn-success"
      [class.disabled]="!isSaveable"
      [disabled]="!isSaveable"
      (click)="sendInvoice()">Send invoice</button>
  </div>
</div><!--#end container-fluid -->
<!-- <app-prompt-leave-page 
  [isVisible]="showLeavePagePrompt" 
  (cancel)="onHideLeavePagePrompt()"
  (continue)="onDismiss()" 
  (confirm)="onDismiss()"></app-prompt-leave-page> -->
  <app-prompt-unsaved-changes-modal
    message="Are you sure you want to leave this page? Your invoice has not been sent."
    [isVisible]="showLeavePagePrompt"
    (cancel)="onHideLeavePagePrompt()"
    (continue)="onDismiss()"></app-prompt-unsaved-changes-modal>

    <app-files-to-be-sent
      [isVisible]="showDocumentsToAddDialog"
      (cancel)="onHideDocumentsToAddDialog()"
      (selectedDoc)="onAddDocument($event)"
      [serviceId]="id"
      [briefDocuments]="briefDocuments"
      [documentTypes]="documentTypes"></app-files-to-be-sent>