<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="modal" *ngIf="isVisible">
 <div class="modal-content">
  <div class="modal-dialog">

    <div class="modal-header">
      <button class="modal-dismiss" (click)="onCancel()">
        <i class="fa fa-close"></i>
      </button>
      <h4>{{title}}</h4>
    </div> <!-- #end .modal-header -->

    <div class="modal-body form">
      <div class="row first-pane">
        <div class="left-column">
          <h5 *ngIf="objectType === 'contact'">Law firm details</h5>
          <div class="form-group">
            <strong><label class="primary-font"
                for="subject">{{objectType === 'contact' ? 'Contact Name' : 'Invoice number'}}</label></strong>
            <label class="primary-font">{{selectedNote.ObjectTypeDescription}}</label>
          </div>
          <div class="form-group" *ngIf="selectedNote.Subject">
            <strong><label class="primary-font" for="subject">Subject:</label>
              <label class="primary-font">{{selectedNote.Subject}}</label> </strong>
          </div>
          <div class="form-group">
            <strong><label class="primary-font" for="subject">Note Date:</label></strong>
            <label class="primary-font">{{selectedNote.CaptureDate | date:'yyyy-MM-dd'}}</label>
          </div>
          <div class="form-group" *ngIf="objectType === 'invoice'">
            <strong><label class="primary-font" for="subject">Client name:</label></strong>
            <label class="primary-font">{{invoiceDetails.ClientName}}</label>
          </div>
          <div class="form-group" *ngIf="objectType === 'invoice'">
            <strong><label class="primary-font" for="subject">Reference:</label></strong>
            <label class="primary-font">{{invoiceDetails.InvoiceReference}}</label>
          </div>
          <div class="form-group" *ngIf="objectType === 'invoice'">
            <strong><label class="primary-font" for="subject">Plaintiff:</label></strong>
            <label class="primary-font">{{plaintiff}}</label>
          </div>
          <div class="form-group" *ngIf="objectType === 'invoice'">
            <strong><label class="primary-font" for="subject">Defendant:</label></strong>
            <label class="primary-font">{{defendant}}</label>
          </div>
        </div>
        <!-- Contact side bar -->
        <div class="right-column" *ngIf="objectType === 'contact'">
          <div class="container">
            <h5>Law firm detail summary</h5>
            <div class="row">
              <div class="form-group">
                <label class="label"><b>Last invoice date:</b></label>
              </div>
              <div class="form-group">
                <label class="right-text">{{lawfirmSummaryData.lastInvoiceDate | date:'yyyy-MM-dd'}}</label>
              </div>
            </div>
            <div class="row" style="justify-content: space-between;" *ngIf="lawfirmSummaryData">
              <div class="form-group">
                <label class="label"><b>Last payment date:</b></label>
              </div>
              <div class="form-group">
                <label class="right-text">{{lawfirmSummaryData.lastTransactionDate | date:'yyyy-MM-dd'}}</label>
              </div>
            </div>
            <div class="row" style="justify-content: space-between;" *ngIf="lawfirmSummaryData">
              <div class="form-group">
                <label class="label"><b>Last payment amount:</b></label>
              </div>
              <div class="form-group">
                <label
                  class="right-text">{{lawfirmSummaryData.amount | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
              </div>
            </div>
            <div class="row" style="justify-content: space-between;" *ngIf="lawfirmSummaryData">
              <div class="form-group">
                <label class="label"><b>12 Months payment amount:</b></label>
              </div>
              <div class="form-group">
                <label
                  class="right-text">{{lawfirmSummaryData.totalPaidYear | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
              </div>
            </div>
            <div class="row" style="justify-content: space-between;" *ngIf="lawfirmSummaryData">
              <div class="form-group">
                <label class="label"><b>Average monthly payment:</b></label>
              </div>
              <div class="form-group">
                <label
                  class="right-text">{{lawfirmSummaryData.totalAverage | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
              </div>
            </div>
            <div class="row" style="justify-content: space-between;" *ngIf="lawfirmSummaryData">
              <div class="form-group">
                <!-- TO DO: MAKE Description and Amount field Bold and red -->
                <label class="red-text label"><b>Partial paid invoices:</b></label>
              </div>
              <div class="form-group">
                <label class="red-text right-text"><b>{{lawfirmSummaryData.partialPaidInvoices}}</b></label>
              </div>
            </div>
            <div class="row" style="justify-content: space-between;" *ngIf="lawfirmSummaryData">
              <div class="form-group">
                <label class="red-text label"><b>Unallocated:</b></label>
              </div>
              <div class="form-group">
                <label
                  class="red-text right-text"><b>{{lawfirmSummaryData.unAllocated | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></label>
              </div>
            </div>
          </div>
        </div>
        <!-- Invoice side bar -->
        <div class="right-column" *ngIf="objectType === 'invoice'">
          <div class="container">
            <h5>Invoice details</h5>
            <div class="row">
              <div class="form-group">
                <label class="label"><b>Invoice amount:</b></label>
              </div>
              <div class="form-group">
                <label
                  class="right-text">{{invoiceDetails.InvoiceTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
              </div>
            </div>
            <div class="row" style="justify-content: space-between;">
              <div class="form-group">
                <label class="label"><b>Invoice date:</b></label>
              </div>
              <div class="form-group">
                <label class="right-text">{{invoiceDetails.InvoiceDate | date:'yyyy-MM-dd'}}</label>
              </div>
            </div>
            <div class="row" style="justify-content: space-between;">
              <div class="form-group">
                <label class="label"><b>Payment term:</b></label>
              </div>
              <div class="form-group">
                <label class="right-text">{{invoiceDetails.Terms}} Days</label>
              </div>
            </div>
            <div class="row" style="justify-content: space-between;">
              <div class="form-group">
                <label class="label"><b>Total Payment amount:</b></label>
              </div>
              <div class="form-group">
                <label
                  class="right-text">{{invoiceDetails.TotalPaidAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
              </div>
            </div>
            <div class="row" style="justify-content: space-between;">
              <div class="form-group">
                <label class="label"><b>Total Credits:</b></label>
              </div>
              <div class="form-group">
                <label
                  class="right-text">{{invoiceDetails.TotalCreditAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
              </div>
            </div>
            <div class="row" style="justify-content: space-between;">
              <div class="form-group">
                <!-- TO DO: MAKE Description and Amount field Bold and red -->
                <label class="red-text label"><b>Written Off:</b></label>
              </div>
              <div class="form-group">
                <label
                  class="red-text right-text"><b>{{invoiceDetails.TotalWriteOffAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></label>
              </div>
            </div>
            <div class="row" style="justify-content: space-between;">
              <div class="form-group">
                <label class="red-text label"><b>Amount outstanding:</b></label>
              </div>
              <div class="form-group">
                <label
                  class="red-text right-text"><b>{{invoiceDetails.OutstandingBalance | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></label>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- #end .row -->

      <div class="panel-body">
        <!-- <ul class="chat">
          <li class="left clearfix">
            <span style="margin-left: 7px;" class="chart-img pull-left">
            <i class="far fa-question-circle"></i>
            </span>
            <div class="chat-body clearfix">
              <div class="header">
                <strong class="primary-font">{{selectedNote.UserCreatorFullName}}</strong> <small class="text-muted">
                  <span class="glyphicon glyphicon-time">Practice Manager ({{selectedNote.CaptureDate | date:'yyyy-MM-dd'}})</span>
                </small>
              </div>
              <p>{{selectedNote.QuestionId > 0 ? 'Request for instruction': 'IN RE:'}}
                  {{selectedNote.ObjectTypeDescription}} {{selectedNote.Category}}</p>
            </div>
          </li>
          
          <li class="left clearfix">
            <span style="margin-left: 7px;" class="chart-img pull-left">
              <i class="fas fa-user-circle"></i>
            </span>
            <div class="chat-body clearfix">
              <div class="header">
                <strong class="primary-font">{{selectedNote.UserCreatorFullName}}</strong> <small class="text-muted">
                  <span class="glyphicon glyphicon-time">Practice Manager Comment
                    ({{selectedNote.CaptureDate | date:'yyyy-MM-dd'}})</span>
                </small>
              </div>
              <p>{{selectedNote.ExternalText}}</p>
              <p *ngIf="selectedNote.QuestionId > 0"><strong>Instruction question: </strong>{{selectedNote.QuestionText}}</p>
            </div>
          </li>

          <li class="left clearfix" *ngIf="selectedNote.InstructionResponseId > 0">
            <span style="margin-left: 7px;" class="chart-img pull-left">
              <i class="fas fa-user-circle"></i>
            </span>
            <div class="chat-body clearfix">
              <div class="header">
                <strong class="primary-font">{{selectedNote.UserAssignedFullName}}</strong> <small class="text-muted">
                  <span class="glyphicon glyphicon-time">Advocate
                    ({{selectedNote.InstructionCaptureDate | date:'yyyy-MM-dd'}})</span>
                </small>
              </div>
              <p>{{selectedNote.ExternalText}}</p>
              <p *ngIf="selectedNote.QuestionId > 0">{{selectedNote.ResponseText}} {{selectedNote.InstructionText}}</p>
            </div>
          </li>

          <li class="left clearfix" *ngIf="selectedNote.InstructionResponseId > 0 && selectedNote.ResolutionText">
            <span style="margin-left: 7px;" class="chart-img pull-left">
              <i class="fas fa-user-circle"></i>
            </span>
            <div class="chat-body clearfix">
              <div class="header">
                <strong class="primary-font">{{selectedNote.UserCreatorFullName}}</strong> <small class="text-muted">
                  <span class="glyphicon glyphicon-time">Practice Manager
                    ({{selectedNote.ResolutionCaptureDate | date:'yyyy-MM-dd'}})</span>
                </small>
              </div>
              <p>{{selectedNote.ResolutionText}}</p>
            </div>
          </li>
        </ul> -->
        <div class="row" style="border-bottom: 1px dotted #B3A9A9;">
          <div class="control-group">
            <i class="far fa-question-circle"></i>              
            <label class="question-text"> <strong>{{selectedNote.QuestionId > 0 ? 'Request for instruction': 'IN RE:'}}<u> {{selectedNote.ObjectTypeDescription}}</u> {{selectedNote.Category}}</strong></label>            
          </div>
        </div>
        <div class="row" style="border-bottom: 1px dotted #B3A9A9;">
          <div class="control-group">             
              <label class="question-text"> <strong style="width: 98%;">Practice Manager Comment:</strong> {{selectedNote.ExternalText}}</label>            
          </div>
          <div class="control-group" *ngIf="selectedNote.QuestionId > 0">             
              <label class="question-text"> <strong>Instruction question:</strong> {{selectedNote.QuestionText}}</label>            
          </div>
        </div>
        <div class="row" style="border-bottom: 1px dotted #B3A9A9;" *ngIf="selectedNote.InstructionResponseId > 0">
          <div class="control-group">
            <i class="fas fa-user-circle"></i>
              <label class="question-text"><strong>You:</strong> {{selectedNote.ResponseText}} {{selectedNote.InstructionText}}</label>
          </div>
        </div>
        <div class="row" style="border-bottom: 1px dotted #B3A9A9;" *ngIf="selectedNote.InstructionResponseId > 0">
          <div class="control-group" *ngIf="selectedNote.InstructionResponseId > 0 && selectedNote.ResolutionText">
            <i class="fas fa-user-circle"></i>
              <label class="question-text"><strong>Practice Manager:</strong> {{selectedNote.ResolutionText}}</label>
          </div>
        </div>
        <div class="row" style="padding-top: 7px; padding-bottom: 0;" *ngIf="selectedNote.InstructionResponseId === 0 && selectedNote.QuestionId > 0">
          <div class="control-group">
            <label for="action">Action</label>
            <input
              [(ngModel)]="selectedResponseText" 
              [typeahead]="instructionResponses" 
              typeaheadOptionField="Response"
              [typeaheadSingleWords]="false" 
              placeholder="Select or start typing" 
              (input)="onInputResponse($event)"
              (typeaheadOnSelect)="onNoteResponseSelect($event)" 
              [typeaheadOptionsLimit]="50" 
              typeaheadScrollable=true
              typeaheadOptionsInScrollableView="5" 
              [typeaheadMinLength]="0" 
              [dropup]="false">
          </div>
        </div>
        <div class="row" style="padding-top: 0; padding-bottom: 0;" *ngIf="selectedNote.InstructionResponseId === 0 && selectedNote.QuestionId > 0">
          <div class="control-group">
            <label for="action">Comment</label>
            <textarea
              class="input-control"
              [(ngModel)]="instructionText"></textarea>
              <button
                class="btn btn-success"
                (click)="onNoteSave()"
                [class.disabled]="!isSaveable"
                [disabled]="!isSaveable">SEND</button>
          </div>
        </div>
      </div> <!-- #end .well well-fluid -->
    </div> <!-- #end .modal-body .form -->

  </div> <!-- #end .modal-dialog -->
 </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->
