import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
  ApiService
} from '../api.service';
import * as moment from 'moment';


export interface InvoiceDetails {
  Id: number;
  InvoiceDate: string;
  InvoiceNo: string;
  InvoiceTotal: number;
  InvoiceVAT: number;
  InvoiceTotalExcl: number;
  InvoiceNote: string;
  InvoiceReference: string;
  SupplierName: string;
  SupplierFax: string;
  SupplierPhone: string;
  SupplierEmail: string;
  SupplierVATNo: string;
  SupplierStreetAddress: string;
  SupplierCell: string;
  ServiceId: number;
  Terms: number;
  InvoiceBank: string;
  InvoiceParties: string;
  ClientName: string;
  ClientStreetAddress: string;
  ClientVATNo: any;
  ClientContactDetailsAfr: string;
  ClientContactDetailsEng: string;
  InternalRefNo: string;
  InvoiceLines: InvoiceLine[];
  PayableBy30: any;
  PayableBy60: any;
  PayableBy: any;
  CreditNoteId: number;
  TotalPaidAmount: number;
  TotalCreditAmount: number;
  TotalWriteOffAmount: number;
  OutstandingBalance: number;
}

export interface InvoiceLine {
  Order: number;
  Date: string;
  Description: string;
  Type: string;
  Quantity: number;
  Total: number;
  Rate: number;
  Group: string;
  CalendarItemId: number;
}

export const DEFAULT_INVOICE_DETAILS = {
  Id: 0,
  InvoiceDate: moment().toJSON(),
  InvoiceNo: '',
  InvoiceTotal: 0,
  InvoiceVAT: 0,
  InvoiceTotalExcl: 0,
  InvoiceNote: '',
  InvoiceReference: '',
  SupplierName: '',
  SupplierFax: '',
  SupplierPhone: '',
  SupplierEmail: '',
  SupplierVATNo: '',
  SupplierStreetAddress: '',
  SupplierCell: '',
  ServiceId: 0,
  InvoiceBank: '',
  InvoiceParties: '',
  ClientName: '',
  ClientStreetAddress: '',
  ClientVATNo: null,
  ClientContactDetailsAfr: '',
  ClientContactDetailsEng: '',
  InternalRefNo: '',
  Terms: 0,
  InvoiceLines: [
    {
      Order: 0,
      Date: '',
      Description: '',
      Type: '',
      Quantity: 0,
      Total: 0,
      Rate: 0,
      Group: '',
      CalendarItemId: 0
    }
  ],
  PayableBy30: null,
  PayableBy60: null,
  PayableBy: null,
  CreditNoteId: 0,
  TotalPaidAmount: 0,
  TotalCreditAmount: 0,
  TotalWriteOffAmount: 0,
  OutstandingBalance: 0

};

@Injectable()
export class InvoiceDetailsService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  /**
   * GET: Get Invoice Details for an Invoice.
   * @param {number} id The Invoice ID value.
   * @returns {Observable<InvoiceDetails>} Returns a Invoice Detail record
   * including contact details (supplier and client), invoice totals and
   * invoice line details.
   */
  getInvoiceDetails(id: number): Observable<any> {
    return this.http
      .get<any>(this.api.endpoints.invoiceDetails + '/' + id, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getInvoiceDetails()

  getQuotationDetails(id: number): Observable<any> {
    return this.http
      .get<any>(this.api.endpoints.invoiceDetails +
        '/QuotationDetails?id=' + id, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getQuotationDetails()

  removeTransactionInvoice(invoiceId: number): Observable<any> {
    return this.http.delete(this.api.endpoints.invoiceDetails +
      '/RemoveTransactionsInvoice?InvoiceId=' + invoiceId +
      '&ServiceProvider=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  removeWriteOff(invoiceId: number): Observable<any> {
    return this.http.delete(this.api.endpoints.invoiceDetails +
      '/RemoveWriteOff?InvoiceId=' + invoiceId,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end removeWriteOff()

  removeCreditNote(invoiceId: number): Observable<any> {
    return this.http.delete(this.api.endpoints.invoiceDetails +
      '/RemoveCreditNote?InvoiceId=' + invoiceId,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end removeCreditNote()

  /**
   * PUT: Update Invoice Details for an Invoice before Invoice creation.
   * This will allow updating of invoice data in preparation for invoice
   * document creation, including:
   * - Change invoice date
   * - Omit fees from invoice after invoice date
   * - Change due date
   * @param {number} id The Invoice ID value.
   * @param {InvoiceDetails} invoice The invoice detail object.
   */
  updateInvoiceDetails(
    id: number,
    invoiceDetails: InvoiceDetails
  ): Observable<any> {
    return this.http
      .put(this.api.endpoints.invoiceDetails + '?id=' + id +
        '&invoiceNote=' + '' +
        '&invoiceDate=' +
        invoiceDetails.InvoiceDate, invoiceDetails, this.api.httpOptions).pipe(
          retry(3),
          catchError(this.api.handleError)
        );
  } // end updateInvoiceDetails()

  updateInvoiceDetailsDate(
    id: number,
    invoiceDetails: InvoiceDetails
  ): Observable<any> {
    return this.http
      .put(this.api.endpoints.invoiceDetails + '/SaveInvoiceDate?id=' +
        id + '&invoiceDate=' + invoiceDetails.InvoiceDate, invoiceDetails, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end updateInvoiceDetailsDate()

  sendInvoiceToAdvocate(invoiceDetails: InvoiceDetails, emailFromName: string, advocateFullName: string, emailTo: string): Observable<any> {
    return this.http.put(this.api.endpoints.pendingInvoice +
      '?invoiceID=' + invoiceDetails.Id + '&toEmail=' + emailTo + '&emailFrom=' + emailFromName +
      '&advocateName=' + advocateFullName,
      invoiceDetails, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end sendInvoiceToAdvocate()
} // end InvoiceDetailsService{}
