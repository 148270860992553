import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContactsService } from '../../services/contacts/contacts.service';
import { LIST_NAME_LAW_FIRMS } from '../../services/lists/lists.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { DEFAULT_NOTES_OBJECT, InstructionResponses
    , MultiInvoiceNote
    , MultiObjectNote, NoteCategory
    , NotesObject, 
    NotesService} from '../../services/notes/notes.service';
  import { lastValueFrom } from 'rxjs';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { DEFAULT_LOADINGOVERLAY_OPTIONS
       , LoadingOverlayModalOptions } from '../../components/modals/loading-overlay-modal/loading-overlay-modal.component';
import { PATH_PRACTICE_MANAGER_DASHBOARD } from '../../services/appdata/app-config.service';

@Component({
  selector: 'app-ad-hoc-instruction',
  templateUrl: './ad-hoc-instruction.component.html',
  styleUrls: ['./ad-hoc-instruction.component.scss']
})
export class AdHocInstructionComponent implements OnInit {

  adHocRequestId: string = '';
  objectLinkId: number = 0;
  debtorId: number;
  lawfirmName: string;
  objectType: string;
  adHocRequest: any;
  noteDetailData: NotesObject;
  isAdHocCollections = false;
  onShowMultiInvoiceNote: boolean;
  noteCategory: NoteCategory[] = [];
  multiInvoiceNote: MultiInvoiceNote[] = [];
  instructionResponses: InstructionResponses[] = [];
  isAdHocRequest: boolean;
  showSavedInstructionPrompt: boolean;
  onShowCancel = false;
  onShowSuccess = false;

  profileTypes = {
      ADVOCATE: 'Advocate',
      ASSISTANT: 'Assistant',
      ATTORNEY: 'Attorney',
      PRACTICE_MANAGER: 'Practice Manager',
      EXTERNAL_ATTORNEY: 'Attorney (External)',
      LAWFIRM: 'Lawfirm',
      EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  options: LoadingOverlayModalOptions = Object.assign({}, DEFAULT_LOADINGOVERLAY_OPTIONS);

  constructor(
    private router: Router,
    private notesService: NotesService,
    private loadingService: LoadingService,
    private contactService: ContactsService,
    private userProfileService: UserProfileService
  ) { } // end constructor()

 ngOnInit(): void {
   // this.userProfileService.adHocInstruction = true;
   this.AdHocInInit();
  } // end ngOnInit()
  
  async AdHocInInit() {
    this.loadingService.showOverlay();
    let currentRoute = this.router.url.split('/');
    this.adHocRequestId = currentRoute[currentRoute.length - 1];
    this.multiInvoiceNote = [];
    this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
    
    // Get AdHocRequest
    await lastValueFrom(this.notesService.getAdHocRequest(this.adHocRequestId)).then((_data) => {
      // On next
      this.adHocRequest = _data;
    });
    // get AdHocInvoices
    if (this.adHocRequest) {
      await lastValueFrom(this.notesService.getAdHocInvoices(this.adHocRequestId)).then((_invoices) => {
        // next
        if (_invoices) {
          this.objectLinkId = _invoices[0].ObjectLinkId;
          _invoices.forEach(invoice_element => {        
              const invoice: MultiInvoiceNote = {
                Id: 0,
                InvoiceId: invoice_element.InvoiceId,
                InvoiceDate: invoice_element.InvoiceDate,
                InvoiceNo: invoice_element.InvoiceNo,
                InvoiceTotal: invoice_element.InvoiceTotal,
                Due: invoice_element.Due,
                Paid: invoice_element.Paid,
                Aging: invoice_element.Aging,
                Attorney: invoice_element.Attorney,
                AttorneyRef: invoice_element.AttorneyRef,
                Matter: invoice_element.Matter,
                AdvocateStatus: invoice_element.AdvocateStatus,
                PracticeManagerStatus: invoice_element.PracticeManagerStatus,
                AdHocCollections: invoice_element.AdHocRequestId,
                ObjectLinkId: invoice_element.ObjectLinkId
            };
            this.multiInvoiceNote.push(invoice);
          });
        }
      
      });

      // get notes for adHoc
      await lastValueFrom(this.notesService.getAdHocRequestNote(this.objectLinkId)).then((_notes) => {
        // next
        this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
        this.noteDetailData.Id = _notes.Id;
        this.noteDetailData.ObjectId = _notes.ObjectId;
        this.noteDetailData.UserAssigned = _notes.UserAssigned;
        this.noteDetailData.UserOwner = _notes.UserOwner;
        this.noteDetailData.UserCreator = _notes.UserCreator;    
        this.noteDetailData.UserAssignedFullName = _notes.UserAssignedFullName; 
        this.noteDetailData.InternalText = _notes.InstructionText; 
        this.noteDetailData.MutliObjects = _notes.MutliObjects; 
      });

      // this.isAdHocRequest = true;
      // this.instructionResponses = [];
      this.notesService.getPMInstructionResponses().subscribe({next: (_next) => {
          this.instructionResponses  = _next;
          // this.instructionResponses  = _next.filter(c => c.Id === 174)
      }});
      this.loadingService.hideOverlay();
      this.isAdHocCollections = true;
      this.onShowMultiInvoiceNote = true;
    } else {
      this.onShowCancel = true;
      this.loadingService.hideOverlay();
    }
  } // end AdHocInInit()

  onHideSavedInstructionPrompt() {
    this.showSavedInstructionPrompt = false;
  } // end onHideSavedInstructionPrompt()

  onShowMultiInvoiceNoteModalDismiss() {
    this.onShowMultiInvoiceNote = false;
} // end onShowMultiInvoiceNoteModalDismiss()

onSaveMultiNote(note: MultiObjectNote) {
  this.loadingService.showOverlay();
   this.notesService.postMultipeNote(note).subscribe({next: (_save) => {},
  complete: () =>{
    // this.getApiData();
  this.onShowMultiInvoiceNote = false;
  }});
  // this.getApiData();
  this.onShowMultiInvoiceNote = false;
  this.loadingService.hideOverlay();
} // end onSaveMultiNote()

onSaveInstructionOnMultiInvoice(note: MultiObjectNote) {
  this.options.message = 'Sending an Instruction...';
  this.loadingService.showOverlay(this.options);
  note.AdHocId = this.adHocRequestId;
  if (this.isAdHocCollections == true) {
      this.notesService.approveAdHocInstructionNote(note).subscribe({next: (_save) => {},
          complete: () => {

          // this.getApiData();
          this.onShowSuccess = true;
          this.onShowMultiInvoiceNote = false;
          this.loadingService.hideOverlay();
          // console.log('PM INSTRUCTIO NOTE SAVED');
            setTimeout(() => {
              this.onShowSuccess = false;
            }, 3000);
          }
      });
  } else {
      note.Lawfirm = 'Instrcution';
      this.notesService.postInstructionOnMultipeNote(note).subscribe({next: (_save) => {},
          complete: () => {

          // this.getApiData();
          
            this.onShowMultiInvoiceNote = false;
            this.showSavedInstructionPrompt = true;
            setTimeout( () => {
              this.showSavedInstructionPrompt = false;
            }, 10000);
            this.loadingService.hideOverlay();
            
            // this.router.navigate([PATH_PRACTICE_MANAGER_DASHBOARD]);
          }
      });
  }
  //  complete: () => {this.getApiData();
  //   this.onShowMultiInvoiceNote = false;}

} // end onSaveInstructionOnMultiInvoice()

} // end AdHocInstructionComponent{}
