import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

export interface ActivityLogs {
  LoggedUserId: number;
  LoggedUserName: string;
  LoggedForUserId: number;
  LoggedForUserName: string;
  LoggedApp: string;
  Action: string;
  JsonData: string;
  ActionTable: string;
  ActionTimeStamp: string;
} // end ActivityLogs()

export const DEFAULT_ACTIVITY_LOGS: ActivityLogs = {
  LoggedUserId: 0,
  LoggedUserName: '',
  LoggedForUserId: 0,
  LoggedForUserName: '',
  LoggedApp: '',
  Action: '',
  JsonData: '',
  ActionTable: '',
  ActionTimeStamp: ''
}; // end DEFAULT_ACTIVITY_LOGS{}

@Injectable()
export class ActivityLogsService {

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { } // end constructor()

  addActivityLog(activityLog: ActivityLogs): Observable<any> {
    return this.http.post<any>(this.api.endpoints.activityLogs
      , activityLog)
      .pipe(
        retry(1),
        catchError(this.api.handleError)
      );
  } // end addActivityLog()
} // end ActivityLogsService{}
