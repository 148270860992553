<h2 class="chart-summary-heading">{{summaryTitle}}
    <i class="far fa-info-circle tooltipp">
      <div class="bottom" style="text-align: center">
        <span class="fa fa-info-circle" style="font-size: 25px; padding-top: 9px; padding-bottom: 5px;"></span>
        <h4><strong>Please note</strong></h4>
        <p>These figures exclude VAT and any disbursement</p>
        <i></i>
      </div>
    </i>
</h2>
<div class="chart-summary-container">
  <nav class="chart-summary-nav">
    <a class="tab" [class.active]="activeTab === 'monthly'" (click)="onTabClick('monthly')">Previous months</a>
    <a class="tab" [class.active]="activeTab === 'yearly'" (click)="onTabClick('yearly')">Previous years</a>
  </nav>
  <div class="well">
    <!-- Previous months chart -->
    <!-- <div *ngIf="!dataMonthly">
      <p>We don't have any current "collection", data</p>
    </div> -->
    <div
      [class.hide]="activeTab === 'yearly'"
      class="chart chart-previous-months"
      [ngSwitch]="summaryTitle">
      <p>Your monthly target:&nbsp;{{(collectedMonthlyTarget)}}</p>
      <p>Your monthly record:&nbsp;{{(collectedMonthlyRecord)}}</p>
      <jqxChart
        #myChart
        width="auto"
        [height]="200"
        [showBorderLine]="false"
        [title]="''"
        [description]="''"
        [enableAnimations]="true"
        [showLegend]="false"
        [padding]="padding"
        [source]="receiptData"
        [xAxis]="xAxis"
        [valueAxis]="valueAxis"
        [seriesGroups]="seriesGroups"
        [colorScheme]="'scheme01'"
        [backgroundColor]="'#F5F6F6'"></jqxChart>
    </div>
    <!--#end .chart-->

    <!-- Previous months chart -->
    <!-- <div *ngIf="!dataYearly">
      <p>We don't have any current "collection", data</p>
    </div> -->
    <div
      [class.hide]="activeTab === 'monthly'"
      class="chart chart-previous-years"
      [ngSwitch]="summaryTitle">
      <p>Your yearly target:&nbsp;{{(collectedYearlyTarget)}}</p>
      <p>Your yearly record:&nbsp;{{(collectedYearlyRecord)}}</p>
      <jqxChart
        #myChart
        width="auto"
        [height]="200"
        [showBorderLine]="false"
        [title]="''"
        [description]="''"
        [enableAnimations]="true"
        [showLegend]="false"
        [padding]="padding"
        [source]="receiptDataYearly"
        [xAxis]="xAxisYearly"
        [valueAxis]="valueAxisYearly"
        [seriesGroups]="seriesGroupsYearly"
        [colorScheme]="'scheme01'"
        [backgroundColor]="'#F5F6F6'"></jqxChart>
    </div>
    <!--#end .chart-->
  </div>
  <!--#end .well-->
</div>
<!--#end .chart-container -->