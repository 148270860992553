import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-import-error-message',
  templateUrl: './import-error-message.component.html',
  styleUrls: ['./import-error-message.component.scss']
})
export class ImportErrorMessageComponent implements OnInit {

  @Input() isVisible: boolean;
  @Input() title: string;
  @Input() fromDate: string;
  @Input() toDate: string;
  @Input() btnTextCancel: string;
  @Input() btnTextConfirm: string;
  @Input() btnTextContinue: string;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();

  constructor() { } // end constructor()

  ngOnInit() {
    if (!this.title) {
      this.title = 'No duplicates';
    }

    // if (!this.message) {
    //   this.message =
    //     'No duplicates found';
    // }

    if (!this.btnTextCancel) {
      this.btnTextCancel = 'Stay';
    }

    if (!this.btnTextConfirm) {
      this.btnTextConfirm = 'Confirm';
    }

    if (!this.btnTextContinue) {
      this.btnTextContinue = 'Leave';
    }
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onConfirm() {
    this.confirm.emit();
  } // end onConfirm()

  onContinue() {
    this.continue.emit();
  } // end onContinue()

} // end ImportErrorMessageComponent{}
