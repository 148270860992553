import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PATH_SUBSCRIPTION_PROMPT } from '../../services/appdata/app-config.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let NextBillingDate = localStorage.getItem("nextBillingDate");
    let Status = localStorage.getItem("status");
    
    if (Status !== 'undefined' && Status !== null && NextBillingDate !== 'undefined' && NextBillingDate !== null) {
      if (Status === "Free Trial") {
        if (moment(NextBillingDate).format('YYYY-MM-DD') >= moment(new Date()).format('YYYY-MM-DD'))
          return true;
        else if (moment(NextBillingDate).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD'))
          this.router.navigate([PATH_SUBSCRIPTION_PROMPT]);
      }
      else if (Status === "Active") {
        if (moment(NextBillingDate).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD'))
          this.router.navigate([PATH_SUBSCRIPTION_PROMPT]);
      }
      else if (Status === "Cancelled")
        this.router.navigate([PATH_SUBSCRIPTION_PROMPT]);
    }
  }
}