import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LiveStatementHandle } from '../../tables/live-statement/live-statement.component';

@Component({
  selector: 'app-live-statement-popup-menu',
  templateUrl: './live-statement-popup-menu.component.html',
  styleUrls: ['./live-statement-popup-menu.component.scss']
})
export class LiveStatementPopupMenuComponent implements OnInit {

  @Input() isVisible: boolean;
  @Input() statement: LiveStatementHandle;
  @Output() dismiss: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewStatement: EventEmitter<any> = new EventEmitter();

  constructor() { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  onDismiss() {
    this.dismiss.emit(this.statement);
  }

  onViewStatement() {
    this.viewStatement.emit(this.statement.data);
    this.onDismiss();
  }

} // end LiveStatementPopupMenuComponent{}
