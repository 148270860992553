<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">

<!-- #start search -->
<div class="display-options" style="padding: 11px 0px 0px 0px;">
    <span class="column-search">
        <button class="btn btn-text" (click)="performSearch()">
            <i class="fa fa-search"></i>
        </button>
        <input type="search" placeholder="Search for Advocate" (keyup)="performSearch()" [(ngModel)]="searchTerm"
            style="padding-left: 30px !important;" autofocus>
    </span>
</div>

<div class="row m-0">
    <div class="table-responsive" style="max-height: 400px; overflow-y: auto;">
        <table class="table table-striped">
            <tbody>
                <tr style="position: sticky; top: -1px; z-index: 2;">
                    <th *ngIf="isGCBUserBar" (click)="toggleSort('regional-bar')">Regional Bar
                        <i class="fa fa-sort" [class.fa-sort-down]="sortAdvocateName"></i>
                    </th>
                    <th scope="col" (click)="toggleSort('advocate-name')">Advocate Name
                        <i class="fa fa-sort" [class.fa-sort-down]="sortAdvocateName"></i>
                    </th>
                    <th scope="col" (click)="toggleSort('user-name')">User Name
                        <i class="fa fa-sort" [class.fa-sort-down]="sortAdvocateUserName"></i>
                    </th>
                    <th scope="col" (click)="toggleSort('cell')">Cell
                        <i class="fa fa-sort" [class.fa-sort-down]="sortCell"></i>
                    </th>
                    <th scope="col" (click)="toggleSort('email')">Email
                        <i class="fa fa-sort" [class.fa-sort-down]="sortEmail"></i>
                    </th>
                    <!-- <th scope="col" (click)="toggleSort('fax')">Fax Number
                        <i class="fa fa-sort" [class.fa-sort-down]="sortFax"></i>
                    </th> -->
                    <th scope="col" (click)="toggleSort('vat')">VAT Number
                        <i class="fa fa-sort" [class.fa-sort-down]="sortVat"></i>
                    </th>
                    <th scope="col" (click)="toggleSort('postal-address')">Postal Address
                        <i class="fa fa-sort" [class.fa-sort-down]="sortPostalAddress"></i>
                    </th>
                    <th scope="col" (click)="toggleSort('street-address')">Street Address
                        <i class="fa fa-sort" [class.fa-sort-down]="sortStreetAddress"></i>
                    </th>
                    <th scope="col"></th>
                </tr>
                <tr class="tr" *ngFor="let advocate of advocatesHandle" [class.active]="row == advocate.data.Id">
                    <td *ngIf="advocate.state.isVisible && isGCBUserBar" style="width: 20%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.BarSocietyName }}</td>
                        <td *ngIf="advocate.state.isVisible" style="width: 20%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.FullName }}</td>
                    <td *ngIf="advocate.state.isVisible" style="width: 10%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.UserName }}</td>
                    <td *ngIf="advocate.state.isVisible" style="width: 10%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.Cell }}</td>
                    <td *ngIf="advocate.state.isVisible" style="width: 10%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.Email }}</td>
                    <!-- <td *ngIf="advocate.state.isVisible" style="width: 10%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.FaxNumber }}</td> -->
                    <td *ngIf="advocate.state.isVisible" style="width: 10%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.VatNumber }}</td>
                    <td *ngIf="advocate.state.isVisible" style="width: 10%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.PostalAddress }}</td>
                    <td *ngIf="advocate.state.isVisible" style="width: 16%;"
                        (click)="onAdvocateRowClick(advocate.data)">{{ advocate.data.StreetAddress }}</td>
                    <td *ngIf="advocate.state.isVisible" style="width: 2%;" class="clickable"
                        (click)="onDeleteAdvPopPromptShow(advocate.data.ReportingAdvocateId, advocate.data.ChamberId, advocate.data.FullName)">
                        <i class="fa fa-trash" style="color: #cf624f;"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<button class="btn btn-success" (click)="addNewAdvocate()">Add Advocate</button>

<app-request-new-advocate-gcb-modal [isVisible]="showNewAdvocateModal" [showAdvocateListTab]="showAdvocateListTab"
    [CurrentRegionalBar]="regionalBarName" [RegionalBarList]="RegionalBarList" [isGCBUserBar]="isGCBUserBar" 
    (closeModal)="closeAdvocateModal()" (isDirectCloseAdvocateModal)="isDirectCloseAdvocateModal()"></app-request-new-advocate-gcb-modal>

<app-prompt-unsaved-changes-modal title="Remove Advocate"
    message="{{RemoveAdvocateMsg}}"    
    [isVisible]="showDeleteAdvPopPrompt" btnTextCancel="Cancel" btnTextContinue="Delete"
    (cancel)="onHideDeleteAdvPopPrompt()" (continue)="onDeleteAdvocate()">
</app-prompt-unsaved-changes-modal>