<div class="panel-area">
  <div class="row">    
    <div class="form-group">
      <label for="hourly-rate">Hourly rate<span title="Required field"> *</span></label>
      <input 
        *ngIf="!internalAssistant" 
        type="text" 
        class="input" 
        id="hourly-rate" 
        placeholder="Hourly rate"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingFinancialDetails.HourlyRate | currency: currencySymbol: currencySymbol: '1.2-2'"
        (ngModelChange)="workingFinancialDetails.HourlyRate=$event"
        (ngModelChange)="workingFinancialDetails.DailyRate=$event * 10" (blur)="onHourlyRateChange()"
        (focus)="onHourlyRateClicked()" 
        (focus)="$event.target.select()" 
        pattern="[P-R\s]*[0-9,.]+" autocomplete="off"
        #inputHourly
        [ngClass]="{'invalid-input': !workingFinancialDetails.HourlyRate}">
      <input 
        *ngIf="internalAssistant" 
        readonly type="text" 
        class="input" 
        id="hourly-rate" 
        placeholder="Hourly rate"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingFinancialDetails.HourlyRate | currency: currencySymbol: currencySymbol: '1.2-2'"
        pattern="[P-R\s]*[0-9,.]+" autocomplete="off" (focus)="$event.target.select()" #inputHourly>
    </div>
    <div class="form-group">
      <label for="daily-rate">Daily rate<span title="Required field"> *</span></label>
      <input *ngIf="!internalAssistant" type="text" id="daily-rate" class="input" placeholder="Daily rate"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingFinancialDetails.DailyRate | currency: currencySymbol: currencySymbol: '1.2-2'"
        (ngModelChange)="workingFinancialDetails.DailyRate=$event" pattern="[P-R\s]*[0-9,.]+"
        (blur)="onDailyRateChange()" (focus)="onDailyRateClicked()" (focus)="$event.target.select()" autocomplete="off"
        #inputDaily
        [ngClass]="{'invalid-input': !workingFinancialDetails.HourlyRate}">
      <input *ngIf="internalAssistant" readonly type="text" id="daily-rate" class="input" placeholder="Daily rate"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingFinancialDetails.DailyRate | currency: currencySymbol: currencySymbol: '1.2-2'"
        pattern="[P-R\s]*[0-9,.]+" autocomplete="off" #inputDaily>
    </div>
    <div class="form-group">
      <label for="income-target">Income target</label>
      <input *ngIf="!internalAssistant" type="text" class="input" id="income-target" placeholder="Income target"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingFinancialDetails.IncomeTarget | currency: currencySymbol: currencySymbol: '1.2-2'"
        (ngModelChange)="workingFinancialDetails.IncomeTarget=$event" pattern="[P-R\s]*[0-9,.]+"
        (blur)="onIncomeTargetChange()" (focus)="onIncomeTargetClicked()" (focus)="$event.target.select()"
        autocomplete="off" #inputIncomeTarget>
      <input *ngIf="internalAssistant" readonly type="text" class="input" id="income-target" placeholder="Income target"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingFinancialDetails.IncomeTarget | currency: currencySymbol: currencySymbol: '1.2-2'"
        pattern="[P-R\s]*[0-9,.]+" autocomplete="off" #inputIncomeTarget>
    </div>
    <div class="form-group">
      <label for="fee-target">Fee target</label>
      <input *ngIf="!internalAssistant" type="text" class="input" id="fee-target" placeholder="Fee target"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingFinancialDetails.FeeTarget | currency: currencySymbol: currencySymbol: '1.2-2'"
        (ngModelChange)="workingFinancialDetails.FeeTarget=$event" pattern="[P-R\s]*[0-9,.]+"
        (blur)="onFeeTargetChange()" (focus)="onFeeTargetClicked()" (focus)="$event.target.select()" autocomplete="off"
        #inputFeeTarget>
      <input *ngIf="internalAssistant" readonly type="text" class="input" id="fee-target" placeholder="Fee target"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingFinancialDetails.FeeTarget | currency: currencySymbol: currencySymbol: '1.2-2'"
        pattern="[P-R\s]*[0-9,.]+" autocomplete="off" #inputFeeTarget>
    </div>
    <div class="form-group">
      <label for="daily-rate">Default kilometer rate<span title="Required field"> *</span></label>
      <input *ngIf="!internalAssistant" type="text" id="daily-rate" class="input" placeholder="Daily rate"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingFinancialDetails.DefaultPerKilometerRate | currency: currencySymbol: currencySymbol: '1.2-2'"
        (ngModelChange)="workingFinancialDetails.DefaultPerKilometerRate=$event" pattern="[P-R\s]*[0-9,.]+"
        (blur)="onDefaultPerKilometerRateChange()" (focus)="onDefaultPerKilometerRateClicked()" (focus)="$event.target.select()" autocomplete="off"
        #inputPerKilometerRate
        [ngClass]="{'invalid-input': !workingFinancialDetails.HourlyRate}">
      <input *ngIf="internalAssistant" readonly type="text" id="daily-rate" class="input" placeholder="Daily rate"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="workingFinancialDetails.DefaultPerKilometerRate | currency: currencySymbol: currencySymbol: '1.2-2'"
        pattern="[P-R\s]*[0-9,.]+" autocomplete="off" #inputPerKilometerRate>
    </div>    
  </div>
  <div class="row">
    <div class="form-group">
      <label for="practice-type">Payment terms<span class="required-field" title="Required field if registered for VAT"> *</span></label>
      <select *ngIf="!internalAssistant" id="practice-type" [(ngModel)]="workingFinancialDetails.PaymentTerms"
              [ngClass]="{'red-border': workingFinancialDetails?.PaymentTerms == null}">
          <option *ngFor="let age of paymentTerms" [value]="age.id"
              [selected]="age.id === workingFinancialDetails.PaymentTerms">{{age.name}}</option>
      </select>
      <input *ngIf="internalAssistant" readonly class="input" id="practice-type"
        [(ngModel)]="workingFinancialDetails.PaymentTerms">
    </div>
    <div class="form-group radio-group">
      <label for="vat-registered">VAT registered</label>
      <div class="radio junior">
        <label class="junior">
          <input type="radio" name="junior" (click)="toggleVatReg()"
            [checked]="workingFinancialDetails?.IsVatRegistered">Yes
        </label>
        <label>
          <input type="radio" (click)="toggleVatReg()" [checked]="!workingFinancialDetails?.IsVatRegistered"
            name="senior">No
        </label>
      </div>
    </div>
    <div class="form-group">
      <label for="vat-number" *ngIf="workingFinancialDetails?.IsVatRegistered === true">VAT number</label>
      <input
        required
        [ngClass]="{'zero-value': (!workingFinancialDetails.VATNumber)}" 
        *ngIf="!internalAssistant && workingFinancialDetails?.IsVatRegistered === true" 
        type="text"
        id="vat-number"
        class="input" 
        placeholder="VAT number"
        [(ngModel)]="workingFinancialDetails.VATNumber">
      <input *ngIf="internalAssistant && workingFinancialDetails?.IsVatRegistered === true" readonly type="text"
        id="vat-number" class="input" placeholder="VAT number" [(ngModel)]="workingFinancialDetails.VATNumber">
    </div>
    <div class="form-group">
      <label *ngIf="workingFinancialDetails?.IsVatRegistered === true" for="vat-registration-date">VAT Registration Date</label>
      <input *ngIf="workingFinancialDetails?.IsVatRegistered === true"
        id="vat-registration-date"
        type="text"
        bsDatepicker
        id="vat-registration-date"
        class="input"
        [bsValue]="parseVATDate(workingFinancialDetails.VATRegistrationDate)"
        (ngModel)="workingFinancialDetails.VATRegistrationDate=$event.target.value"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
        (bsValueChange)="onDateVATRegChange($event)"
        placement="left">
    </div>
    <div class="form-group">
      <label *ngIf="workingFinancialDetails?.IsVatRegistered === true" for="last-vat-period-end-date">Last VAT period end Date <span class="required-field" title="Required field if registered for VAT"> *</span></label>
      <input *ngIf="workingFinancialDetails?.IsVatRegistered === true"
        id="last-vat-period-end-date"
        type="text"
        required
        bsDatepicker
        id="last-vat-period-end-date"
        class="input"
        [bsValue]="onLastVATPeriodEndDateParse(workingFinancialDetails.LastVATPeriodEndDate)"
        (ngModel)="workingFinancialDetails.LastVATPeriodEndDate=$event.target.value"
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
        (bsValueChange)="onLastVATPeriodEndDateChange($event)"
        placement="right">
    </div>
    <!-- <div class="form-group" *ngIf="workingFinancialDetails.IsVatRegistered === false">
     
    </div> -->
</div>

  <div class="row">    
    <!-- <div class="form-group">
      <label for="view-dashboard">Enable VAT Settings </label>
      <div class="view-dashboard">
        <i
          class="fa fa-toggle-on active"
          id="view-dashboard"
          *ngIf="vatSettingsStatus == true"
          (click)="changeVATSettingsStatus();"></i>
        <i
          class="fa fa-toggle-on fa-rotate-180 inactive"
          id="view-dashboard"
          *ngIf="vatSettingsStatus == false"
          (click)="changeVATSettingsStatus();"></i>
      </div>
  </div> -->    

    <div class="form-group" *ngIf="workingFinancialDetails?.IsVatRegistered === true">
      <label *ngIf="workingFinancialDetails?.IsVatRegistered === true" for="practice-type">VAT based on:<span class="required-field" title="Required field if registered for VAT"> *</span></label>
      <select 
        *ngIf="workingFinancialDetails?.IsVatRegistered === true" 
        id="practice-type" 
        #vatSelect="ngModel"
        [class.border-red]="(checkInitialValidation || vatSelect.touched) && vatSelect.invalid"
        [(ngModel)]="workingFinancialDetails.VatBasedOn"
        required>
        <option value="" disabled>Select VAT type</option>
        <option *ngFor="let vat of vatBasedOn" [value]="vat.id"
          [selected]="vat.id === workingFinancialDetails.VatBasedOn">{{vat.name}}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="tax-value">Effective Tax Rate<span class="required-field" title="Required field if registered for VAT"> *</span></label>
      <div class="percent">
      <input
            *ngIf="workingFinancialDetails"
            style="width: 95% !important;"
            type="number"
            id="tax-value"
            class="input"
            placeholder="Effective Tax Rate"
            [(ngModel)]="workingFinancialDetails.TaxValue">
        </div>
    </div>    

    <div class="form-group checkboxes">
      <label for="report-to-auditors" *ngIf="workingFinancialDetails?.IsVatRegistered === true">Report to Auditors</label>
      <label class="vvts" *ngIf="workingFinancialDetails?.IsVatRegistered === true">
        <input type="checkbox" [(ngModel)]="workingFinancialDetails.ReportToAuditors"> <span>Report to Auditors</span>
      </label>
    </div>

    <div class="form-group">
      <label for="secretary-name" *ngIf="workingFinancialDetails.ReportToAuditors === true">Auditor Name<span title="Required field"> *</span></label>
      <input *ngIf="!internalAssistant && workingFinancialDetails.ReportToAuditors === true" type="text" id="secretary-name" class="input" placeholder="Auditor name"
        [(ngModel)]="workingFinancialDetails.AuditorName">
      <input *ngIf="internalAssistant && workingFinancialDetails.ReportToAuditors === true" readonly type="text" id="secretary-name" class="input" placeholder="Auditor name"
        [(ngModel)]="workingFinancialDetails.AuditorName">
    </div>

    <div class="form-group" *ngIf="workingFinancialDetails.ReportToAuditors === true">
      <label for="secretary-name" *ngIf="workingFinancialDetails.ReportToAuditors === true">Auditor Email<span title="Required field"> *</span></label>
      <input *ngIf="!internalAssistant && workingFinancialDetails.ReportToAuditors === true" type="text" id="secretary-name" class="input"
        placeholder="Auditor email address" [(ngModel)]="workingFinancialDetails.AuditorEmail">
      <input *ngIf="internalAssistant && workingFinancialDetails.ReportToAuditors === true" readonly type="text" id="secretary-name" class="input"
        placeholder="Auditor email address" [(ngModel)]="workingFinancialDetails.AuditorEmail">
    </div>    
    

   <div class="form-group" *ngIf="workingFinancialDetails.ReportToAuditors === false">
    <label for="maaa-number">MAAA Number</label>
    <input
      type="text"
      id="maaa-number"
      class="input" 
      placeholder="MAAA Number"
      [(ngModel)]="workingFinancialDetails.MAAA">
  </div>
  </div> 

  <div class="row" style="border: 1px #dee2e6;" *ngIf="workingFinancialDetails.ReportToAuditors === true">
    <div class="form-group">
      <label for="secretary-name">Auditor Tel<span title="Required field"> *</span></label>
      <input *ngIf="!internalAssistant" type="text" id="secretary-name" class="input" placeholder="Auditor phone number"
        [(ngModel)]="workingFinancialDetails.AuditorPhone">
      <input *ngIf="internalAssistant" readonly type="text" id="secretary-name" class="input"
        placeholder="Auditor phone number" [(ngModel)]="workingFinancialDetails.AuditorPhone">
    </div>
    <div class="form-group checkboxes">
      <label for="report-to-auditors">VAT Report</label>
      <label class="vvts">
        <input type="checkbox" [(ngModel)]="workingFinancialDetails.VATReport"> <span>VAT Report</span>
      </label>
    </div>

    <div class="form-group checkboxes">
      <label for="report-to-auditors">Invoice History Report</label>
      <label class="vvts">
        <input type="checkbox" [(ngModel)]="workingFinancialDetails.InvoiceHistoryReport"> <span>Invoice History
          Report</span>
      </label>
    </div>

    <div class="form-group checkboxes">
      <label for="report-to-auditors">Bank Statement</label>
      <label class="vvts">
        <input type="checkbox" [(ngModel)]="workingFinancialDetails.BankStatement"> <span>Bank Statement</span>
      </label>
    </div>
    <!-- <div class="form-group" *ngIf="workingFinancialDetails.IsVatRegistered === true">
      
    </div> -->

    <div class="form-group" *ngIf="workingFinancialDetails.ReportToAuditors === true">
      <label for="maaa-number">MAAA Number</label>
      <input
        type="text"
        id="maaa-number"
        class="input" 
        placeholder="MAAA Number"
        [(ngModel)]="workingFinancialDetails.MAAA">
    </div>
    <!-- <div class="form-group" *ngIf="workingFinancialDetails.ReportToAuditors === true">
      <label for="view-dashboard">Manual Transactions </label>
      <div class="view-dashboard">
        <i
          class="fa fa-toggle-on active"
          id="view-dashboard"
          *ngIf="workingFinancialDetails.ManualTransaction == true"
          (click)="changeManualTransactionsStatus();"></i>
        <i
          class="fa fa-toggle-on fa-rotate-180 inactive"
          id="view-dashboard"
          *ngIf="workingFinancialDetails.ManualTransaction == false"
          (click)="changeManualTransactionsStatus();"></i>
      </div>      
   </div>     -->
  </div>

  <div class="row">
    <div class="form-group" *ngIf="isAdvocate">
        <label for="starting-invoice-number" *ngIf="isAdvocate">Next invoice no.<span class="required-field" title="Required field if registered for VAT"> *</span></label>
        <input
          class="form-control"
          *ngIf="isAdvocate"
          type="text"
          id="starting-invoice-number"
          [(ngModel)]="workingFinancialDetails.NextInvoiceNumber"
          autocomplete="nocxua"
          readonly>
    </div> 
    <div class="form-group" *ngIf="isAdvocate" style="align-content: end;">
      <button *ngIf="isAdvocate"
        class="btn btn-info"
        (click)="FeeTariffsView()">Average Fee Tariffs</button>    
    </div>
    <div class="form-group">
      <label for="collections-financial-status" *ngIf="!internalAssistant && isPracticeManager">Financial Status</label>
      <select id="collections-financial-status" *ngIf="!internalAssistant && isPracticeManager" [(ngModel)]="workingFinancialDetails.FinancialStatus">
        <option *ngFor="let data of financialStatus" [value]="data.value"
          [selected]="data.value === workingFinancialDetails.FinancialStatus">{{data.value}}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="!isAdvocate"></div>
    <div class="form-group" *ngIf="!isAdvocate"></div>
    <div class="form-group"></div>
    <div class="form-group">
      <!-- <label for="secretary-permission-type">Secretary permission type</label>
      <input readonly type="text" id="secretary-name" class="input"
        [ngModel]="workingFinancialDetails.PermissionType === undefined ? '' : workingFinancialDetails.PermissionType"> -->
    </div>       
  </div>
</div>

<div class="panel-area">
  <div class="row" style="padding-right: 0 !important;">
    <div class="table-responsive">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="col" class="w10">Name</th>
            <th scope="col" class="w10">Bank Name</th>
            <th scope="col" class="w10">Account number</th>
            <th scope="col" class="w14">Account type</th>
            <th scope="col" class="w10">Branch Code</th>
            <th scope="col" class="w10">Primary</th>
            <th scope="col" class="w10">Has access</th>
            <th scope="col" class="w10">Last Transaction Date</th>
            <th scope="col" class="w10">Statement Reminder Day</th>
            <th scope="col" class="w2">Active</th>
            <th scope="col" class="w2"></th>
          </tr>
          <tr class="tr" *ngFor="let bank of bankAccounts">
            <td scope="row" class="w10">{{bank.BankName}}</td>
            <td scope="row" class="w10">{{bank.Bank}}</td>
            <td scope="row" class="w1w104">{{bank.AccountNumber}}</td>
            <td scope="row" class="w14">{{bank.AccountType}}</td>
            <td scope="row" class="w10">{{bank.BranchCode}}</td>
            <td scope="row" class="w10">{{bank.SPAccType}}</td>
            <td scope="row" class="w10">{{bank.PracticeManagerAccess === true ? 'Yes' : 'No'}}
            </td>
            <td scope="row" class="w10">{{bank.LastStatementSent | date:'yyyy-MM-dd'}}</td>
            <td scope="row" class="w10">{{bank.StatementReminderDay == 0 || bank.StatementReminderDay == null ?
              '' : bank.StatementReminderDay}}</td>
              <td scope="row" class="w2">{{bank.Enabled}}</td>
            <td scope="row" class="w2">
              <i class="fa fa-pencil clickable" (click)="onEditBankAccount(bank)"></i>
            </td>
            <!-- <td scope="row" class="w2">
              <i class="fa fa-trash-o clickable" *ngIf="bank.SPAccType !=='Primary'" (click)="onDeleteBankAccount(bank)"
                style="font-size: 14px; color: rgb(207, 98, 79);" aria-hidden="true"></i>
            </td> -->
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="3">
              <span class="column-add-bank-account">
                <span class="clickable" (click)="onAddNewBankAccount()">
                  <i class="fa fa-plus-circle auxcon-green"></i>
                  <u class="auxcon-green">Add a business bank account</u>
                </span>
              </span>
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </div>
    <!-- <div class="form-group">
      <label for="bank-name">Bank Name</label>
      <input
        type="text" 
        id="bank-name" 
        class="input" 
        placeholder="Bank Name"
        [(ngModel)]="workingFinancialDetails.BankName">
    </div>
    <div class="form-group">
      <label for="account-number">Account number</label>
      <input 
        type="text" 
        id="account-number" 
        class="input" 
        placeholder="Account number"
        [(ngModel)]="workingFinancialDetails.AccountNumber">
    </div>
    <div class="form-group">
      <label for="account-type">Account type</label>
      <select
        id="account-type"
        [(ngModel)]="workingFinancialDetails.Accounttype">
        <option
          *ngFor="let accountType of accountTypes"
          [value]="accountType.value"
          [selected]="accountType.value === workingFinancialDetails.Accounttype">{{accountType.label}}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="branch-code">Branch Code</label>
      <input
        type="text"
        id="branch-code"
        class="input"
        placeholder="Branch Code"
        [(ngModel)]="workingFinancialDetails.BranchCode">
    </div>
    <div class="form-group radio-group">
      <label for="seniority">Have access</label>
      <div class="radio junior">
        <label class="junior">
          <input
            type="radio"
            (click)="toggleHaveAccess()"
            [checked]="workingFinancialDetails.HaveBankAccess === true"
            name="yes">Yes</label>
        <label>
          <input
            type="radio"
            (click)="toggleHaveAccess()"
            [checked]="workingFinancialDetails.HaveBankAccess === false"
            name="no">No</label>
      </div>
    </div> -->
  </div>
</div>


<!-- <button
*ngIf="!internalAssistant"
class="btn btn-success save-advocate-details"
(click)="onSave()">SAVE FINANCIAL DETAILS</button> -->

<app-add-edit-bank-account
  [isVisible]="showAddEditBankAccount"
  [addEditButtonText]="addEditButtonText"
  [bankAccountHandle]="selectedBankAccountHandle"
  [bankAccounts]="bankAccounts"
  (closeModal)="closeModal($event)"
  (saveBankAccount)="onBankAccountSave($event)">
</app-add-edit-bank-account>

<app-prompt-unsaved-changes-modal title="Banking detail change"
  message="Are you sure you want to make this account your primary account? Primary account details appear on invoices and statements."
  [isVisible]="showErrorMessagePrompt" btnTextContinue="Ok" btnTextCancel="Cancel" (cancel)="onHideErrorMessagePrompt()"
  (continue)="onUpdateClick()"></app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal title="Banking detail change"
  message="You are about to remove your secondary bank account, do you wish to proceed?"
  [isVisible]="showErrorDeletePrompt" btnTextContinue="Cancel" btnTextCancel="Proceed" (cancel)="onDeleteClick()"
  (continue)="onHideErrorDeletePrompt()"></app-prompt-unsaved-changes-modal>

  <app-fee-tariffs
  *ngIf="showFeeTariffsModal"
  [isVisible]="showFeeTariffsModal"
  [feeTariffs]="feeTariffs"
  (cancel)="onFeeTariffsModalHide()"
></app-fee-tariffs>

<app-prompt-unsaved-changes-modal title="VAT Calculation on WIP Matters"
  message="Please be advised that all Work in Progress invoices will be automatically updated to include VAT. If there are any invoices that should not be updated, kindly reach out to your Practice Manager for assistance. Would you like to proceed?"
  [isVisible]="showWorkInProgressPrompt"
  btnTextContinue="Proceed"
  btnTextCancel="Cancel"
  (cancel)="onHideWorkInProgressPrompt()"
  (continue)="onWorkInProgressClick()"></app-prompt-unsaved-changes-modal>