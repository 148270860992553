import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LawFirm } from '../../../services/lists/lists.service';
import { ArbitrationSplit, DEFAULT_ARBITRATION_SPLIT, Matter } from '../../../services/matters/matters.service';
import { IndexedDBService } from '../../../services/indexed-db/indexed-db.service';
import { LIST_NAME_LAW_FIRMS } from '../../reconcile-bank-transaction/invoices-added-bank-transaction/invoices-added-bank-transaction.component';
import { AttorneysService } from '../../../services/attorneys/attorneys.service';
import { ClientContact } from '../../../services/client-contacts/client-contacts.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { InvoicesService } from '../../../services/invoices/invoices.service';

@Component({
  selector: 'app-add-arbitration',
  templateUrl: './add-arbitration.component.html',
  styleUrls: ['./add-arbitration.component.scss']
})
export class AddArbitrationComponent implements OnInit {

  title: string = 'Add other Law Firm';
  isLawfirmRatingLoading: boolean;
  lawfirmRatingsData: any;
  avaragePaymentRating: number;
  lawfirmRatingLabel: string;
  onShowFirmPaymentProfile: boolean;
  attorneys: ClientContact[];
  arbitration: ArbitrationSplit = DEFAULT_ARBITRATION_SPLIT;

  @Input() lawFirms: LawFirm[];
  @Input() isVisible: boolean;
  @Input() workingMatter: Matter;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() arbitrationSaved: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private util: UtilitiesService,
    private invoiceService: InvoicesService,
    private loadingServices: LoadingService,
    private attorneysService: AttorneysService
  ) { } // end constructor()

  ngOnInit(): void {
  } // end ngOnInit()

  onModelChange(type: string, contact: any) {
    switch (type) {
      case 'law-firms':
        if (contact.ContactID) {
          this.isLawfirmRatingLoading = true;
          this.attorneysService.getLawfirmRating(contact.ContactID).subscribe({next: 
            (avarage_rating) => {
              if (avarage_rating) {
                this.lawfirmRatingsData = avarage_rating;
                this.avaragePaymentRating = avarage_rating.AverageDaysToPay;
                if (avarage_rating.AverageDaysToPayment === 'Not Applicable') {
                  this.lawfirmRatingLabel = 'No data available.';
                } else {
                  this.lawfirmRatingLabel = 'On avarage pay within ' + this.avaragePaymentRating +  ' days';
                }
                // this.lawfirmRatingLabel = 'On avarage pay within ' + this.avaragePaymentRating + ' days';
              } else {
                this.lawfirmRatingLabel = 'No data available.';
              }
            },
            error: (error) => {
              // On error
              console.log('ERROR getLawfirmRating: ', error)
              this.isLawfirmRatingLoading = false;
              this.lawfirmRatingLabel = 'No data available.';
            },
            complete: () => {
              // On complete              
              if (this.lawfirmRatingsData && this.lawfirmRatingsData.WorkedOnFirmPastThreeMonths === false) {
                if (
                  // this.lawfirmRatingsData && this.lawfirmRatingsData.AverageDaysToPay > 150 ||
                  this.lawfirmRatingsData && this.lawfirmRatingsData.InLegal > 0 ||
                  this.lawfirmRatingsData && this.lawfirmRatingsData.LPCComplaints > 0 ||
                  this.lawfirmRatingsData && this.lawfirmRatingsData.GCBListing > 0) {
                    this.onShowFirmPaymentProfile = true;
                  }
              }
              this.isLawfirmRatingLoading = false;
            }
          });
          this.attorneysService.getAttorneysByLawFirms(contact.ContactID).subscribe({ next:
            (contacts) => {
              this.attorneys = contacts;
              this.sortAttorneysList();
            },
            error: (error) => {
              const msg = 'Error loading Attorney list.';
              console.error(msg, error);

            },
            complete: () => {
              if (contact.FullName) {
                 // Reset attorneys field
                this.workingMatter.ClientContactID = 0;
                this.workingMatter.NewClientDetail = '';
            }
          }
        });
        } else {
          contact.FullName = '';
          contact.ContactID = 0;
        }
        break;

      case 'attorneys':
        //this.workingMatter.NewClientDetail = contact.FullName;
        // this.workingMatter.DefendantFullName = contact.FullName;
        this.workingMatter.ClientContactID = contact.ContactID;
        // this.matterAttorneyChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        break;

      case 'attorney-reference':
        this.workingMatter.ClientRefNo = contact.FullName;
        contact.FullName = '';
        contact.ContactID = 0;
        break;

      case 'instructions':
        this.workingMatter.Instructions = contact.FullName;
        contact.FullName = '';
        contact.ContactID = 0;
        break;
    }
    //this.workingMatterChange.emit(this.workingMatter);
  } // end onModelChange()

  sortAttorneysList() {
    if (this.attorneys.length > 0) {
      this.attorneys.sort((a, b) => {
        if (a && a.ClientFullName !== undefined && b && b.ClientFullName !== undefined) {
        return a.ContactFullName.toUpperCase().localeCompare(a.ContactFullName.toUpperCase());
        }
      });
    }
  } // end sortAttorneysList()

  onHideFirmPaymentProfile() {
    this.onShowFirmPaymentProfile = false;
  } // end onHideFirmPaymentProfile()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onContinue() {
    this.loadingServices.showOverlay();
    this.arbitration = this.util.objectCopy(DEFAULT_ARBITRATION_SPLIT);
    this.arbitration = {
      Id: 0,
      ServiceId: this.workingMatter.ServiceID,
      Services: this.workingMatter,
      SecondaryAttorneyId: this.workingMatter.ClientContactID,
      SecondaryFirmId: this.workingMatter.ContactID,
      SecondaryReference: this.workingMatter.ClientRefNo,
      SplitPercentage: Number(this.workingMatter.ArbitrationPercentage)
    };
    console.log('ARBITRATION: ', this.arbitration);
    this.invoiceService.updateArbitrationInvoice(this.arbitration).subscribe({ next: (onNext) => {
      // On next
      }, 
      error: (error) => {
        // On error
        this.loadingServices.hideOverlay();
      },
      complete: () => {
        // complete
        this.arbitrationSaved.emit();
        this.loadingServices.hideOverlay();
      }
    });
  } // end onContinue()

} // end AssAebitrationComponent{}
