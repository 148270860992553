<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">

<div id="ReportPage" class="container-fluid">

  <div class="column-report-debtor badge" style="margin-right: 67px;">
    <label for="" class="debtor-label">Advocates:</label>
    <input
      [(ngModel)]="advocateName"
      [typeahead]="advocateData"
      typeaheadOptionField="FullName"
      [typeaheadSingleWords]="false" 
      class="form-control" 
      placeholder="Select or start typing"
      [typeaheadOptionsLimit]="100" 
      typeaheadScrollable="true" 
      typeaheadOptionsInScrollableView="20"
      [typeaheadMinLength]="0" 
      (typeaheadOnSelect)="onAdvocateSelect($event)" />
  </div>&nbsp;&nbsp;&nbsp;
  <!--#end .column-report-type -->

  <div class="column-report-debtor badge" *ngIf="!isExternalAttorney">
    <label for="" class="debtor-label">Attorneys:</label>
    <input
      [(ngModel)]="attorneyName"
      [typeahead]="attorneyData"
      typeaheadOptionField="FullName"
      [typeaheadSingleWords]="false" 
      class="form-control" 
      placeholder="Select or start typing"
      [typeaheadOptionsLimit]="100" 
      typeaheadScrollable="true" 
      typeaheadOptionsInScrollableView="20"
      [typeaheadMinLength]="0" 
      (typeaheadOnSelect)="onAttorneySelect($event)" />
  </div>
  <!--#end .column-report-type -->

<div class="container-fluid" style="margin-top:0">
  <div class="individual-customer-container">
    <div class="individual-customer-well">
      <nav class="individual-customer-nav">
        <a class="tab" *ngIf="isHeadOfState" [class.active]="activeTab === 'new-invoices'" (click)="setActiveTab('new-invoices')">New Invoices</a>
        <a class="tab" *ngIf="!isHeadOfState" [class.active]="activeTab === 'new-invoices'" (click)="setActiveTab('new-invoices')">New Invoices</a>
        <a class="tab" [class.active]="activeTab === 'view-all'" (click)="setActiveTab('view-all')">All Outstanding Invoices</a>
        <a class="tab" [class.active]="activeTab === 'view-0-days'" *ngIf="!isExternalAttorney" (click)="setActiveTab('view-0-days')">Current Invoices</a>
        <a class="tab" [class.active]="activeTab === 'view-30-days'" *ngIf="!isExternalAttorney" (click)="setActiveTab('view-30-days')">30 days</a>
        <a class="tab" [class.active]="activeTab === 'view-60-days'" *ngIf="!isExternalAttorney" (click)="setActiveTab('view-60-days')">60 days</a>
        <a class="tab" [class.active]="activeTab === 'view-90-days'" *ngIf="!isExternalAttorney" (click)="setActiveTab('view-90-days')">90 days</a>
        <a class="tab" [class.active]="activeTab === 'view-98-days'" *ngIf="!isExternalAttorney" (click)="setActiveTab('view-98-days')">Overdue Invoices</a>
        <a class="tab" [class.active]="activeTab === 'paid'" *ngIf="!isExternalAttorney" (click)="setActiveTab('paid')">Paid</a>
        <a class="tab" [class.active]="activeTab === 'unallocated'" *ngIf="!isExternalAttorney" (click)="setActiveTab('unallocated')">Unallocated
          <i *ngIf="count != 0" class="fas fa-circle"><b>{{count}}</b></i>
        </a>
      </nav>
    </div>
  </div>
  <div class="row column-display-row">
    <div class="text-left" style="width: 100%;">   
      <div class="column-display-area">
        <div class="display-options">
          <span class="column-statement-from-date" *ngIf="activeTab === 'paid'">
            <label for="date" class="from-to-date">From&nbsp;</label>
            <input
              name="fromDate"
              readonly
              class="input-date"
              #_fromDate="bsDatepicker"
              bsDatepicker
              placeholder="2010-01-01"
              placement="right"
              [(ngModel)]="statementParameters.fromDate"
              [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
              (bsValueChange)="onStatementFromDateChange($event)">
          </span>
          <span class="column-statement-to-date" *ngIf="activeTab === 'paid'">
            <label for="date" class="from-to-date">To&nbsp;</label>
            <input
              name="toDate"
              readonly
              class="input-date"
              #_fromDate="bsDatepicker"
              bsDatepicker
              placeholder="2010-01-01"
              placement="right"
              [(ngModel)]="statementParameters.toDate"
              [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
              (bsValueChange)="onStatementToDateChange($event)">
              <a title="Click to download Reconciliation History Report" class="fas fa-arrow-alt-circle-down" [href]="downloadLink" target="_blank" *ngIf="activeTab === 'paid' && downloadLink" (click)="onDateFilterClicked()"></a>
              <a title="Click to download Reconciliation History Report" class="fas fa-arrow-alt-circle-down" *ngIf="activeTab === 'paid' && !downloadLink" (click)="onDateErrorShowClicked()"></a>
          </span>
          <!-- Search column -->
          <span class="column-search col-fs-3" [class.hide]="activeTab === 'paid'">
            <!-- (click)="filterStatement()" -->
            <!-- <button class="btn btn-text">
              <i class="fa fa-search"></i>
            </button> #end .btn btn-text -->
            <!-- (click)="clearSearch" -->
            <input type="text" placeholder="Search" (keyup)="filterStatement()"
              [(ngModel)]="searchValue">
            <!-- (keyup)="filterMatters()" -->
            <!-- [(ngModel)]="searchValue" -->
          </span> <!-- #end .column-search -->
      
          <span class="column-order-by col-fs-4" [class.hide]="activeTab === 'paid'">
            <label for="sort-by">Sort by:</label>
            <select id="sort-by" (change)="orderStatement()" [(ngModel)]="orderBy">
              <!-- <option value="" selected> Filter options </option> -->
              <option value="" disabled selected hidden> Filter options </option>
              <option *ngFor="let option of orderingOptions" [value]="option">
                {{option | titlecase}}
              </option>
            </select> <!-- #end select -->
            <button class="btn btn-toggle" (click)="toggleAscending()">
              <i class="fa" [class.fa-sort-amount-desc]="!sortAscending" [class.fa-sort-amount-asc]="sortAscending"></i>
            </button> <!-- #end .btn btn-toggle -->
          </span> <!-- #end .column-order-by-->
         
          
            <!-- Search column -->
            <span class="column-search-paid" [class.hide]="activeTab !== 'paid'">
              <!-- <button hidden class="btn btn-text btn-paid">
                <i class="fa fa-search"></i>
              </button> -->
              <input type="search" placeholder="Search" (keyup)="filterStatement()" [(ngModel)]="searchValue">
            </span> <!-- #end .column-search -->
        </div> <!-- #end .display-options -->
    </div>
  </div>

  <!-- Start All Invoices tab -->
  <div *ngIf="isHeadOfState" class="table-responsive" [class.hide]="activeTab !== 'new-invoices'">
    <table class="table table-striped" style="border: 0;">
      <tbody class="tbody">
        <tr class="table-light">
          <th class="w-7 align-bottom" (click)="toggleSort('date')">Date <i class="fa fa-sort"
              [class.fa-sort-down]="sortDate"></i></th>
          <th class="w-7 align-bottom" (click)="toggleSort('advocate')">Advocate <i class="fa fa-sort"
              [class.fa-sort-down]="sortAdvocate"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('matter')">Matter <i class="fa fa-sort"
              [class.fa-sort-down]="sortMatter"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('reference')">Reference <i class="fa fa-sort"
              [class.fa-sort-down]="sortReference"></i></th>
          <th class="w-7 align-bottom" (click)="toggleSort('attorney')">Att <i class="fa fa-sort"
              [class.fa-sort-down]="sortAttorney"></i></th>
          <th class="w-10 align-bottom text-left" (click)="toggleSort('invoice-no')">Inv No. <i class="fa fa-sort"
              [class.fa-sort-down]="sortInvoiceNo"></i></th>
              <th class="w-3 align-bottom text-left"></th>
          <th class="w-5 align-bottom text-left" (click)="toggleSort('aging')">Terms <i class="fa fa-sort"
            [class.fa-sort-down]="sortAging"></i></th>
          <th class="w-5 align-bottom text-left" style="width: 9% !important;" (click)="toggleSort('days-outstanding')">Days Outstanding <i
            class="fa fa-sort" [class.fa-sort-down]="sortDaysOutstanding"></i></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('due')"><b>Due <i class="fa fa-sort"
            [class.fa-sort-down]="sortDue"></i></b></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('paid')">Paid <i class="fa fa-sort"
            [class.fa-sort-down]="sortPaid"></i></th>
          <th class="w-10 align-bottom text-left" (click)="toggleSort('total')">Invoice Total <i class="fa fa-sort"
            [class.fa-sort-down]="sortAmount"></i></th>
          <th class="w-3 align-bottom text-left"></th>
          <th class="w-3 align-bottom text-left"></th>
          <th class="w-3 align-bottom text-left"></th>
          <!-- New column for the "V" dropdown button -->
          <th class="w-3 align-bottom text-left">Details</th>
        </tr>
        <ng-container *ngFor="let line of advocateStatementHandle">
          <tr *ngIf="line.state.isVisible" class="accordion-toggle parent" [id]=line.data.InvoiceId [attr.data-target]="'#' + line.data.InvoiceId" data-role="expander">
            <td [title]="line.data.InvoiceDate | date:'yyyy-MM-dd'">{{line.data.InvoiceDate | date:'yyyy-MM-dd'}}</td>
            <td [title]="line.data.ProviderName">{{line.data.ProviderName}}</td>
            <td [title]="line.data.ServiceDescription">{{line.data.ServiceDescription}}</td>
            <td>
              <u class="column-invoice-number" (click)="onReferenceEdit(line.data)">
                <a title="Click to edit Reference">{{line.data.Reference}}</a>
              </u>
            </td>
            <td>
              <u class="column-invoice-number" (click)="onAttorneyEdit(line.data)">
                <a title="Click to edit Attorney">{{line.data.AttorneyName}}</a>
              </u>
            </td>
            <td class="text-left">
              <u class="column-invoice-number">
                <a [title]="line.data.InvoiceNo" (click)="openInvoice(line.data)">{{line.data.InvoiceNo}}</a>
              </u>
            </td>            
            <td class="text-center" (click)="onViewBriefDocuments(line.data)">
              <span class="column-doc" title="Documents">
                <i *ngIf="line.data.HasBrief" class="fal fa-paperclip" style="font-size: 14px !important;"></i>
              </span>
            </td>
            <td class="text-left">{{line.data.PaymentTerms}}</td>
            <td class="text-left">{{line.data.TotalDaysOverdue}}</td>
            <td class="text-left" [title]="line.data.Total | currency: currencySymbol"><b>{{line.data.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></td>
            <td class="text-left" style="cursor: pointer;" (click)="openPaymentDetailModal(line.data)">
              <u>{{line.data.Paid | currency: currencySymbol: currencySymbol:'1.2-2'}}</u>
            </td>
            <td class="text-left">{{line.data.Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
            <td class="text-left" title="Query report" (click)="onViewQueryReport(line.data)">
              <i class="fal fa-file" aria-hidden="true" style="font-size: 15px;"></i>
            </td>
            <td class="text-left" title="Status update">
              <i (click)="onSetStatus(line.data)">
                <img src="../assets/images/icons/edit-icon.png" alt="Edit" style="width: 15px;">
              </i>
            </td>
            <td class="text-left" title="Dispute / Query">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </td>
            <!-- Dropdown button for expanding/collapsing the detailed table -->
            <td class="text-center">
              <button class="detailed-button" (click)="loadInvoiceDetails(line.data.InvoiceId)">
                Details
              </button>
            </td>
          </tr>
        </ng-container>
        <tr class="table-light">
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th><b>Total</b></th>
          <th class="text-left due-text-color"><b>{{totalDueAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalPaymentAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalInvoiceAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th></th>
          <th></th>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Start All Invoices tab -->
  <div *ngIf="!isHeadOfState" class="table-responsive" [class.hide]="activeTab !== 'new-invoices'">
    <table class="table table-striped" style="border: 0;">
      <tbody class="tbody">
        <tr class="table-light">
          <th class="w-7 align-bottom" (click)="toggleSort('date')">Date <i class="fa fa-sort"
              [class.fa-sort-down]="sortDate"></i></th>
          <th class="w-7 align-bottom" (click)="toggleSort('advocate')">Advocate <i class="fa fa-sort"
              [class.fa-sort-down]="sortAdvocate"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('matter')">Matter <i class="fa fa-sort"
              [class.fa-sort-down]="sortMatter"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('reference')">Reference <i class="fa fa-sort"
              [class.fa-sort-down]="sortReference"></i></th>
          <th class="w-7 align-bottom" (click)="toggleSort('attorney')">Att <i class="fa fa-sort"
              [class.fa-sort-down]="sortAttorney"></i></th>
          <th class="w-10 align-bottom text-left" (click)="toggleSort('invoice-no')">Inv No. <i class="fa fa-sort"
              [class.fa-sort-down]="sortInvoiceNo"></i></th>
              <th class="w-3 align-bottom text-left"></th>
          <th class="w-5 align-bottom text-left" (click)="toggleSort('aging')">Terms <i class="fa fa-sort"
            [class.fa-sort-down]="sortAging"></i></th>
          <th class="w-5 align-bottom text-left" style="width: 9% !important;" (click)="toggleSort('days-outstanding')">Days Outstanding <i
            class="fa fa-sort" [class.fa-sort-down]="sortDaysOutstanding"></i></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('due')"><b>Due <i class="fa fa-sort"
            [class.fa-sort-down]="sortDue"></i></b></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('paid')">Paid <i class="fa fa-sort"
            [class.fa-sort-down]="sortPaid"></i></th>
          <th class="w-10 align-bottom text-left" (click)="toggleSort('total')">Invoice Total <i class="fa fa-sort"
            [class.fa-sort-down]="sortAmount"></i></th>
          <th class="w-3 align-bottom text-left"></th>
          <th class="w-3 align-bottom text-left"></th>
          <th class="w-3 align-bottom text-left"></th>
          <!-- New column for the "V" dropdown button -->
          <th class="w-3 align-bottom text-left">Details</th>
        </tr>
        <ng-container *ngFor="let line of advocateStatementHandle">
          <tr *ngIf="line.state.isVisible" class="accordion-toggle parent" [id]=line.data.InvoiceId [attr.data-target]="'#' + line.data.InvoiceId" data-role="expander">
            <td [title]="line.data.InvoiceDate | date:'yyyy-MM-dd'">{{line.data.InvoiceDate | date:'yyyy-MM-dd'}}</td>
            <td [title]="line.data.ProviderName">{{line.data.ProviderName}}</td>
            <td [title]="line.data.ServiceDescription">{{line.data.ServiceDescription}}</td>
            <td>
              <u class="column-invoice-number" (click)="onReferenceEdit(line.data)">
                <a title="Click to edit Reference">{{line.data.Reference}}</a>
              </u>
            </td>
            <td>
              <u class="column-invoice-number" (click)="onAttorneyEdit(line.data)">
                <a title="Click to edit Attorney">{{line.data.AttorneyName}}</a>
              </u>
            </td>
            <td class="text-left">
              <u class="column-invoice-number">
                <a [title]="line.data.InvoiceNo" (click)="openInvoice(line.data)">{{line.data.InvoiceNo}}</a>
              </u>
            </td>            
            <td class="text-center" (click)="onViewBriefDocuments(line.data)">
              <span class="column-doc" title="Documents">
                <i *ngIf="line.data.HasBrief" class="fal fa-paperclip" style="font-size: 14px !important;"></i>
              </span>
            </td>
            <td class="text-left">{{line.data.PaymentTerms}}</td>
            <td class="text-left">{{line.data.TotalDaysOverdue}}</td>
            <td class="text-left" [title]="line.data.Total | currency: currencySymbol"><b>{{line.data.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></td>
            <td class="text-left" style="cursor: pointer;" (click)="openPaymentDetailModal(line.data)">
              <u>{{line.data.Paid | currency: currencySymbol: currencySymbol:'1.2-2'}}</u>
            </td>
            <td class="text-left">{{line.data.Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
            <td class="text-left" title="Query report" (click)="onViewQueryReport(line.data)">
              <i class="fal fa-file" aria-hidden="true" style="font-size: 15px;"></i>
            </td>
            <td class="text-left" title="Status update">
              <i (click)="onSetStatus(line.data)">
                <img src="../assets/images/icons/edit-icon.png" alt="Edit" style="width: 15px;">
              </i>
            </td>
            <td class="text-left" title="Dispute / Query">
              <i class="fa fa-question-circle grey" *ngIf="line.data.DisputStatusId > 0" (click)="onInvoiceDisputeUpdateShow(line.data)" aria-hidden="true"></i>
              <i class="fa fa-question-circle normal" *ngIf="line.data.DisputStatusId === 0 || line.data.DisputStatusId === null" aria-hidden="true" (click)="onInvoiceDisputeUpdateShow(line.data)"></i>
            </td>
            <!-- Dropdown button for expanding/collapsing the detailed table -->
            <td class="text-center">
              <button class="detailed-button" (click)="loadInvoiceDetails(line.data.InvoiceId)">
                Details
              </button>
            </td>
          </tr>
        </ng-container>
        <tr class="table-light">
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th><b>Total</b></th>
          <th class="text-left due-text-color"><b>{{totalDueAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalPaymentAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalInvoiceAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th></th>
          <th></th>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Start All Invoices tab -->
  <div class="table-responsive" [class.hide]="activeTab !== 'view-all'">
    <table class="table table-striped" style="border: 0;">
      <tbody class="tbody">
        <tr class="table-light">
          <th class="w-7 align-bottom" (click)="toggleSort('date')">Date <i class="fa fa-sort"
              [class.fa-sort-down]="sortDate"></i></th>
          <th class="w-7 align-bottom" (click)="toggleSort('advocate')">Advocate <i class="fa fa-sort"
              [class.fa-sort-down]="sortAdvocate"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('matter')">Matter <i class="fa fa-sort"
              [class.fa-sort-down]="sortMatter"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('reference')">Reference <i class="fa fa-sort"
              [class.fa-sort-down]="sortReference"></i></th>
          <th class="w-7 align-bottom" (click)="toggleSort('attorney')">Att <i class="fa fa-sort"
              [class.fa-sort-down]="sortAttorney"></i></th>
          <th class="w-10 align-bottom text-left" (click)="toggleSort('invoice-no')">Inv No. <i class="fa fa-sort"
              [class.fa-sort-down]="sortInvoiceNo"></i></th>
          <th class="w-3 align-bottom text-left"></th>
          <th class="w-5 align-bottom text-left" (click)="toggleSort('aging')">Terms <i class="fa fa-sort"
              [class.fa-sort-down]="sortAging"></i></th>
          <th class="w-5 align-bottom text-left" style="width: 9% !important;" (click)="toggleSort('days-outstanding')">Days Outstanding <i
              class="fa fa-sort" [class.fa-sort-down]="sortDaysOutstanding"></i></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('due')"><b>Due <i class="fa fa-sort"
              [class.fa-sort-down]="sortDue"></i></b></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('paid')">Paid <i class="fa fa-sort"
              [class.fa-sort-down]="sortPaid"></i></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('total')">Invoice Total <i class="fa fa-sort"
              [class.fa-sort-down]="sortAmount"></i></th>
          <th class="w-3 align-bottom text-left"></th>
          <th class="w-3 align-bottom text-left"></th>
          <th class="w-3 align-bottom text-left"></th>
          <!-- New column for the "V" dropdown button -->
          <th class="w-3 align-bottom text-left">Details</th>
        </tr>
        <ng-container *ngFor="let line of advocateStatementHandle">
          <tr *ngIf="line.state.isVisible" class="accordion-toggle parent" [id]=line.data.InvoiceId [attr.data-target]="'#' + line.data.InvoiceId" data-role="expander">
            <td [title]="line.data.InvoiceDate | date:'yyyy-MM-dd'">{{line.data.InvoiceDate | date:'yyyy-MM-dd'}}</td>
            <td [title]="line.data.ProviderName">{{line.data.ProviderName}}</td>
            <td [title]="line.data.ServiceDescription">{{line.data.ServiceDescription}}</td>
            <td>
              <u class="column-invoice-number" (click)="onReferenceEdit(line.data)">
                <a title="Click to edit Reference">{{line.data.Reference}}</a>
              </u>
            </td>
            <td>
              <u class="column-invoice-number" (click)="onAttorneyEdit(line.data)">
                <a title="Click to edit Attorney">{{line.data.AttorneyName}}</a>
              </u>
            </td>
            <td class="text-left">
              <u class="column-invoice-number">
                <a [title]="line.data.InvoiceNo" (click)="openInvoice(line.data)">{{line.data.InvoiceNo}}</a>
              </u>
            </td>
            <td class="text-center" (click)="onViewBriefDocuments(line.data)">
              <span class="column-doc" title="Documents">
                <i *ngIf="line.data.HasBrief" class="fal fa-paperclip" style="font-size: 14px !important;"></i>
              </span>
            </td>
            <td class="text-left">{{line.data.PaymentTerms}}</td>
            <td class="text-left">{{line.data.TotalDaysOverdue}}</td>
            <td class="text-left" [title]="line.data.Total | currency: currencySymbol"><b>{{line.data.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></td>
            <td class="text-left" style="cursor: pointer;" (click)="openPaymentDetailModal(line.data)">
              <u>{{line.data.Paid | currency: currencySymbol: currencySymbol:'1.2-2'}}</u>
            </td>
            <td class="text-left">{{line.data.Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
            <td class="text-left" title="Query report" (click)="onViewQueryReport(line.data)">
              <i class="fal fa-file" aria-hidden="true" style="font-size: 15px;"></i>
            </td>
            <td class="text-left" title="Status update">
              <i  (click)="onSetStatus(line.data)">
                <img src="../assets/images/icons/edit-icon.png" alt="Edit" style="width: 15px;">
              </i>
            </td>
            <td class="text-left" title="Dispute / Query">
              <i class="fa fa-question-circle grey" *ngIf="line.data.DisputStatusId > 0" (click)="onInvoiceDisputeUpdateShow(line.data)" aria-hidden="true"></i>
              <i class="fa fa-question-circle normal" *ngIf="line.data.DisputStatusId === 0 || line.data.DisputStatusId === null" aria-hidden="true" (click)="onInvoiceDisputeUpdateShow(line.data)"></i>
            </td>
            <!-- Dropdown button for expanding/collapsing the detailed table -->
            <td class="text-center">
              <button class="detailed-button" (click)="loadInvoiceDetails(line.data.InvoiceId)">
                Details
              </button>
            </td>
          </tr>
        </ng-container>
        <tr class="table-light">
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th><b>Total</b></th>
          <th class="text-left due-text-color"><b>{{totalDueAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalPaymentAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalInvoiceAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th></th>
          <th></th>
        </tr>
      </tbody>
    </table>
  </div>
  
  
  <!-- #End All Invoice tab -->
  <!-- Start Current days tab -->
  <div class="table-responsive" [class.hide]="activeTab !== 'view-0-days'">
    <table class="table table-striped " style="border: 0;">
      <tbody class="tbody">
        <tr class="table-light">
          <th class="w-7 align-bottom" (click)="toggleSort('date')">Date <i class="fa fa-sort"
              [class.fa-sort-down]="sortDate"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('advocate')">Advocate <i class="fa fa-sort"
              [class.fa-sort-down]="sortAdvocate"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('matter')">Matter <i class="fa fa-sort"
              [class.fa-sort-down]="sortMatter"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('reference')">Reference <i class="fa fa-sort"
              [class.fa-sort-down]="sortReference"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('attorney')">Att <i class="fa fa-sort"
              [class.fa-sort-down]="sortAttorney"></i></th>
          <th class="w-10 align-bottom text-left" (click)="toggleSort('invoice-no')">Inv No. <i class="fa fa-sort"
              [class.fa-sort-down]="sortInvoiceNo"></i></th>
          <th class="w-3 align-bottom text-left"></th>
          <th class="w-5 align-bottom text-left" (click)="toggleSort('aging')">Terms <i class="fa fa-sort"
              [class.fa-sort-down]="sortAging"></i></th>
          <th class="w-5 align-bottom text-left" style="width: 9% !important;" (click)="toggleSort('days-outstanding')">Days
            Outstanding <i class="fa fa-sort" [class.fa-sort-down]="sortDaysOutstanding"></i></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('due')"><b>Due <i class="fa fa-sort"
                [class.fa-sort-down]="sortDue"></i></b></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('paid')">Paid <i class="fa fa-sort"
              [class.fa-sort-down]="sortPaid"></i></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('total')">Invoice Total <i class="fa fa-sort"
              [class.fa-sort-down]="sortAmount"></i></th>
        </tr>


        <tr></tr>
        <tr *ngFor="let line of advocateStatementHandle"
            [class.hide]="!line.state.isVisible" role="tabpanel" class="tab-pane tab-fade">
          <td [title]="line.data.InvoiceDate | date:'yyyy-MM-dd'">{{line.data.InvoiceDate | date:'yyyy-MM-dd'}}</td>
          <td [title]="line.data.ProviderName">{{line.data.ProviderName}}</td>
          <td [title]="line.data.ServiceDescription">{{line.data.ServiceDescription}}</td>
          <td>
            <u class="column-invoice-number" (click)="onReferenceEdit(line.data)">
              <a title="Click to edit Reference">{{line.data.Reference}}
              </a>
            </u>
          </td>
          <td>
            <u class="column-invoice-number" (click)="onAttorneyEdit(line.data)">
              <a title="Click to edit Attorney">{{line.data.AttorneyName}}
              </a>
            </u>
          </td>
          <td class="text-left"><u class="column-invoice-number"><a
                [title]="line.data.InvoiceNo"
            (click)="openInvoice(line.data)">{{line.data.InvoiceNo}}</a></u></td>
            <td class="text-center" (click)="onViewBriefDocuments(line.data)">
            <span class="column-doc" title="Documents" style="cursor: pointer;">
              <i *ngIf="line.data.HasBrief" class="fal fa-paperclip" style="font-size:  14px !important;"></i>
            </span>
          </td>
          <td class="text-left">
            {{line.data.PaymentTerms}}</td>
          <td class="text-left">
            {{line.data.TotalDaysOverdue}}</td>
          <td class="text-left" [title]="line.data.Total | currency: currencySymbol"><b>{{line.data.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></td>
          <td class="text-left" style="cursor: pointer;" (click)="openPaymentDetailModal(line.data)">
            <u>{{line.data.Paid | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></td>
          <td class="text-left">{{line.data.Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
          <!-- <td></td> -->
          <!-- <td class="text-center"><u>Add Action</u></td> -->
        </tr>
        <tr class="table-light">
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th><b>Total</b></th>
          <th class="text-left due-text-color"><b>{{totalDueAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalPaymentAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalInvoiceAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <!-- <th></th>
                    <th></th> -->
        </tr>
      </tbody>
    </table>
  </div>
  <!-- #End Current days tab -->
  <!-- Start 30 days tab -->
  <div class="table-responsive" [class.hide]="activeTab !== 'view-30-days'">
    <table class="table table-striped " style="border: 0;">
      <tbody class="tbody">
        <tr class="table-light">
          <th class="w-7 align-bottom" (click)="toggleSort('date')">Date <i class="fa fa-sort"
              [class.fa-sort-down]="sortDate"></i></th>
              <th class="w-10 align-bottom" (click)="toggleSort('advocate')">Advocate <i class="fa fa-sort"
                  [class.fa-sort-down]="sortAdvocate"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('matter')">Matter <i class="fa fa-sort"
              [class.fa-sort-down]="sortMatter"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('reference')">Reference <i class="fa fa-sort"
              [class.fa-sort-down]="sortReference"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('attorney')">Att <i class="fa fa-sort"
              [class.fa-sort-down]="sortAttorney"></i></th>
          <th class="w-10 align-bottom text-left" (click)="toggleSort('invoice-no')">Inv No. <i class="fa fa-sort"
              [class.fa-sort-down]="sortInvoiceNo"></i></th>
              <th class="w-3 align-bottom text-left"></th>
          <th class="w-5 align-bottom text-left" (click)="toggleSort('aging')">Terms <i class="fa fa-sort"
              [class.fa-sort-down]="sortAging"></i></th>
          <th class="w-5 align-bottom text-left" style="width: 9% !important;" (click)="toggleSort('days-outstanding')">Days Outstanding <i
              class="fa fa-sort" [class.fa-sort-down]="sortDaysOutstanding"></i></th>
          <th class="w-10 align-bottom text-left" (click)="toggleSort('due')"><b>Due <i class="fa fa-sort"
              [class.fa-sort-down]="sortDue"></i></b></th>
          <th class="w-10 align-bottom text-left" (click)="toggleSort('paid')">Paid <i class="fa fa-sort"
              [class.fa-sort-down]="sortPaid"></i></th>
          <th class="w-10 align-bottom text-left" (click)="toggleSort('total')">Invoice Total <i class="fa fa-sort"
              [class.fa-sort-down]="sortAmount"></i></th>
        </tr>


        <tr></tr>
        <tr *ngFor="let line of advocateStatementHandle"
            [class.hide]="!line.state.isVisible" role="tabpanel" class="tab-pane tab-fade">
          <td [title]="line.data.InvoiceDate | date:'yyyy-MM-dd'">{{line.data.InvoiceDate | date:'yyyy-MM-dd'}}</td>
          <td [title]="line.data.ProviderName">{{line.data.ProviderName}}</td>
          <td [title]="line.data.ServiceDescription">{{line.data.ServiceDescription}}</td>
          <td>
            <u class="column-invoice-number" (click)="onReferenceEdit(line.data)">
              <a title="Click to edit Reference">{{line.data.Reference}}
              </a>
            </u>
          </td>
          <td>
            <u class="column-invoice-number" (click)="onAttorneyEdit(line.data)">
              <a title="Click to edit Attorney">{{line.data.AttorneyName}}
              </a>
            </u>
          </td>
          <td class="text-left"><u class="column-invoice-number"><a
                [title]="line.data.InvoiceNo"
            (click)="openInvoice(line.data)">{{line.data.InvoiceNo}}</a></u></td>
            <td class="text-center" (click)="onViewBriefDocuments(line.data)">
            <span class="column-doc" title="Documents" style="cursor: pointer;">
              <i *ngIf="line.data.HasBrief" class="fal fa-paperclip" style="font-size:  14px !important;"></i>
            </span>
          </td>
          <td class="text-left">
            {{line.data.PaymentTerms}}</td>
          <td class="text-left">
            {{line.data.TotalDaysOverdue}}</td>
          <td class="text-left" [title]="line.data.Total | currency: currencySymbol"><b>{{line.data.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></td>
          <td class="text-left" style="cursor: pointer;" (click)="openPaymentDetailModal(line.data)">
            <u>{{line.data.Paid | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></td>
          <td class="text-left">{{line.data.Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
          <!-- <td></td> -->
          <!-- <td class="text-center"><u>Add Action</u></td> -->
        </tr>
        <tr class="table-light">
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th><b>Total</b></th>
          <th class="text-left due-text-color"><b>{{totalDueAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalPaymentAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalInvoiceAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <!-- <th></th>
                    <th></th> -->
        </tr>
      </tbody>
      <!-- <tfoot>
        <tr>
          <td colspan="4"> -->
            <!-- <div class="btn-group">
              <span class="clickable" (click)="generateReport()">Generate Excel Report</span>
              <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupMenu()"></i>
            </div> -->
          <!-- </td>
        </tr>
      </tfoot> -->
    </table>
  </div>
  <!-- #End 30 days tab -->
  <!-- Start 60 days tab -->
  <div class="table-responsive" [class.hide]="activeTab !== 'view-60-days'">
    <table class="table table-striped " style="border: 0;">
      <tbody class="tbody">
        <tr class="table-light">
          <th class="w-7 align-bottom" (click)="toggleSort('date')">Date <i class="fa fa-sort"
              [class.fa-sort-down]="sortDate"></i></th>
              <th class="w-12 align-bottom" (click)="toggleSort('advocate')">Advocate <i class="fa fa-sort"
                  [class.fa-sort-down]="sortAdvocate"></i></th>
          <th class="w-12 align-bottom" (click)="toggleSort('matter')">Matter <i class="fa fa-sort"
              [class.fa-sort-down]="sortMatter"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('reference')">Reference <i class="fa fa-sort"
              [class.fa-sort-down]="sortReference"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('attorney')">Att <i class="fa fa-sort"
              [class.fa-sort-down]="sortAttorney"></i></th>
          <th class="w-4 align-bottom text-left" (click)="toggleSort('invoice-no')">Inv No. <i class="fa fa-sort"
              [class.fa-sort-down]="sortInvoiceNo"></i></th>
              <th class="w-3 align-bottom text-left"></th>
          <th class="w-5 align-bottom text-left" (click)="toggleSort('aging')">Terms <i class="fa fa-sort"
              [class.fa-sort-down]="sortAging"></i></th>
          <th class="w-5 align-bottom text-left" style="width: 9% !important;" (click)="toggleSort('days-outstanding')">Days Outstanding <i
              class="fa fa-sort" [class.fa-sort-down]="sortDaysOutstanding"></i></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('due')"><b>Due <i class="fa fa-sort"
              [class.fa-sort-down]="sortDue"></i></b></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('paid')">Paid <i class="fa fa-sort"
              [class.fa-sort-down]="sortPaid"></i></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('total')">Invoice Total <i class="fa fa-sort"
              [class.fa-sort-down]="sortAmount"></i></th>
        </tr>


        <tr></tr>
        <tr *ngFor="let line of advocateStatementHandle"
            [class.hide]="!line.state.isVisible" role="tabpanel" class="tab-pane tab-fade">
          <td [title]="line.data.InvoiceDate | date:'yyyy-MM-dd'">{{line.data.InvoiceDate | date:'yyyy-MM-dd'}}</td>
          <td [title]="line.data.ProviderName">{{line.data.ProviderName}}</td>
          <td [title]="line.data.ServiceDescription">{{line.data.ServiceDescription}}</td>
          <td>
            <u class="column-invoice-number" (click)="onReferenceEdit(line.data)">
              <a title="Click to edit Reference">{{line.data.Reference}}
              </a>
            </u>
          </td>
          <td>
            <u class="column-invoice-number" (click)="onAttorneyEdit(line.data)">
              <a title="Click to edit Attorney">{{line.data.AttorneyName}}
              </a>
            </u>
          </td>
          <td class="text-left"><u class="column-invoice-number"><a
                [title]="line.data.InvoiceNo"
            (click)="openInvoice(line.data)">{{line.data.InvoiceNo}}</a></u></td>
            <td class="text-center" (click)="onViewBriefDocuments(line.data)">
            <span class="column-doc" title="Documents" style="cursor: pointer;">
              <i *ngIf="line.data.HasBrief" class="fal fa-paperclip" style="font-size:  14px !important;"></i>
            </span>
          </td>
          <td class="text-left">
            {{line.data.PaymentTerms}}</td>
          <td class="text-left">
            {{line.data.TotalDaysOverdue}}</td>
          <td class="text-left" [title]="line.data.Total | currency: currencySymbol"><b>{{line.data.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></td>
          <td class="text-left" style="cursor: pointer;" (click)="openPaymentDetailModal(line.data)">
            <u>{{line.data.Paid | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></td>
          <td class="text-left">{{line.data.Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
          <!-- <td></td> -->
          <!-- <td class="text-center"><u>Add Action</u></td> -->
        </tr>
        <tr class="table-light">
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th><b>Total</b></th>
          <th class="text-left due-text-color"><b>{{totalDueAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalPaymentAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalInvoiceAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <!-- <th></th>
                    <th></th> -->
        </tr>
      </tbody>
      <!-- <tfoot>
        <tr>
          <td colspan="4"> -->
            <!-- <div class="btn-group">
              <span class="clickable" (click)="generateReport()">Generate Excel Report</span>
              <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupMenu()"></i>
            </div> -->
          <!-- </td>
        </tr>
      </tfoot> -->
    </table>
  </div>
  <!-- #End 60 days tab -->
  <!-- Start 90 days tab -->
  <div class="table-responsive" [class.hide]="activeTab !== 'view-90-days'">
    <table class="table table-striped " style="border: 0;">
      <tbody class="tbody">
        <tr class="table-light">
          <th class="w-7 align-bottom" (click)="toggleSort('date')">Date <i class="fa fa-sort"
              [class.fa-sort-down]="sortDate"></i></th>
              <th class="w-10 align-bottom" (click)="toggleSort('advocate')">Advocate <i class="fa fa-sort"
                  [class.fa-sort-down]="sortAdvocate"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('matter')">Matter <i class="fa fa-sort"
              [class.fa-sort-down]="sortMatter"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('reference')">Reference <i class="fa fa-sort"
              [class.fa-sort-down]="sortReference"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('attorney')">Att <i class="fa fa-sort"
              [class.fa-sort-down]="sortAttorney"></i></th>
          <th class="w-10 align-bottom text-left" (click)="toggleSort('invoice-no')">Inv No. <i class="fa fa-sort"
              [class.fa-sort-down]="sortInvoiceNo"></i></th>
              <th class="w-3 align-bottom text-left"></th>
          <th class="w-5 align-bottom text-left" (click)="toggleSort('aging')">Terms <i class="fa fa-sort"
              [class.fa-sort-down]="sortAging"></i></th>
          <th class="w-5 align-bottom text-left" style="width: 9% !important;" (click)="toggleSort('days-outstanding')">Days Outstanding <i
              class="fa fa-sort" [class.fa-sort-down]="sortDaysOutstanding"></i></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('due')"><b>Due <i class="fa fa-sort"
              [class.fa-sort-down]="sortDue"></i></b></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('paid')">Paid <i class="fa fa-sort"
              [class.fa-sort-down]="sortPaid"></i></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('total')">Invoice Total <i class="fa fa-sort"
              [class.fa-sort-down]="sortAmount"></i></th>
        </tr>


        <tr></tr>
        <tr *ngFor="let line of advocateStatementHandle"
            [class.hide]="!line.state.isVisible" role="tabpanel" class="tab-pane tab-fade">
          <td [title]="line.data.InvoiceDate | date:'yyyy-MM-dd'">{{line.data.InvoiceDate | date:'yyyy-MM-dd'}}</td>
          <td [title]="line.data.ProviderName">{{line.data.ProviderName}}</td>
          <td [title]="line.data.ServiceDescription">{{line.data.ServiceDescription}}</td>
          <td>
            <u class="column-invoice-number" (click)="onReferenceEdit(line.data)">
              <a title="Click to edit Reference">{{line.data.Reference}}
              </a>
            </u>
          </td>
          <td>
            <u class="column-invoice-number" (click)="onAttorneyEdit(line.data)">
              <a title="Click to edit Attorney">{{line.data.AttorneyName}}
              </a>
            </u>
          </td>
          <td class="text-left"><u class="column-invoice-number"><a
                [title]="line.data.InvoiceNo"
            (click)="openInvoice(line.data)">{{line.data.InvoiceNo}}</a></u></td>
            <td class="text-center" (click)="onViewBriefDocuments(line.data)">
            <span class="column-doc" title="Documents" style="cursor: pointer;">
              <i *ngIf="line.data.HasBrief" class="fal fa-paperclip" style="font-size:  14px !important;"></i>
            </span>
          </td>
          <td class="text-left">
            {{line.data.PaymentTerms}}</td>
          <td class="text-left">
            {{line.data.TotalDaysOverdue}}</td>
          <td class="text-left" [title]="line.data.Total | currency: currencySymbol"><b>{{line.data.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></td>
          <td class="text-left" style="cursor: pointer;" (click)="openPaymentDetailModal(line.data)">
            <u>{{line.data.Paid | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></td>
          <td class="text-left">{{line.data.Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
          <!-- <td></td> -->
          <!-- <td class="text-center"><u>Add Action</u></td> -->
        </tr>
        <tr class="table-light">
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th><b>Total</b></th>
          <th class="text-left due-text-color"><b>{{totalDueAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalPaymentAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalInvoiceAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <!-- <th></th>
                    <th></th> -->
        </tr>
      </tbody>
      <!-- <tfoot>
        <tr>
          <td colspan="4"> -->
            <!-- <div class="btn-group">
              <span class="clickable" (click)="generateReport()">Generate Excel Report</span>
              <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupMenu()"></i>
            </div> -->
          <!-- </td>
        </tr>
      </tfoot> -->
    </table>
  </div>
  <!-- #End 90 days tab -->
  <!-- Start Overdue tab -->
  <div class="table-responsive" [class.hide]="activeTab !== 'view-98-days'">
    <table class="table table-striped " style="border: 0;">
      <tbody class="tbody">
        <tr class="table-light">
          <th class="w-7 align-bottom" (click)="toggleSort('date')">Date <i class="fa fa-sort"
              [class.fa-sort-down]="sortDate"></i></th>
              <th class="w-10 align-bottom" (click)="toggleSort('advocate')">Advocate <i class="fa fa-sort"
                  [class.fa-sort-down]="sortAdvocate"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('matter')">Matter <i class="fa fa-sort"
              [class.fa-sort-down]="sortMatter"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('reference')">Reference <i class="fa fa-sort"
              [class.fa-sort-down]="sortReference"></i></th>
          <th class="w-10 align-bottom" (click)="toggleSort('attorney')">Att <i class="fa fa-sort"
              [class.fa-sort-down]="sortAttorney"></i></th>
          <th class="w-10 align-bottom text-left" (click)="toggleSort('invoice-no')">Inv No. <i class="fa fa-sort"
              [class.fa-sort-down]="sortInvoiceNo"></i></th>
              <th class="w-3 align-bottom text-left"></th>
          <th class="w-5 align-bottom text-left" (click)="toggleSort('aging')">Terms <i class="fa fa-sort"
              [class.fa-sort-down]="sortAging"></i></th>
          <th class="w-5 align-bottom text-left" style="width: 9% !important;" (click)="toggleSort('days-outstanding')">Days Outstanding <i
              class="fa fa-sort" [class.fa-sort-down]="sortDaysOutstanding"></i></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('due')"><b>Due <i class="fa fa-sort"
              [class.fa-sort-down]="sortDue"></i></b></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('paid')">Paid <i class="fa fa-sort"
              [class.fa-sort-down]="sortPaid"></i></th>
          <th class="w-9 align-bottom text-left" (click)="toggleSort('total')">Invoice Total <i class="fa fa-sort"
              [class.fa-sort-down]="sortAmount"></i></th>
        </tr>


        <tr></tr>
        <tr *ngFor="let line of advocateStatementHandle"
            [class.hide]="!line.state.isVisible" role="tabpanel" class="tab-pane tab-fade">
          <td [title]="line.data.InvoiceDate | date:'yyyy-MM-dd'">{{line.data.InvoiceDate | date:'yyyy-MM-dd'}}</td>
          <td [title]="line.data.ProviderName">{{line.data.ProviderName}}</td>
          <td [title]="line.data.ServiceDescription">{{line.data.ServiceDescription}}</td>
          <td>
            <u class="column-invoice-number" (click)="onReferenceEdit(line.data)">
              <a title="Click to edit Reference">{{line.data.Reference}}
              </a>
            </u>
          </td>
          <td>
            <u class="column-invoice-number" (click)="onAttorneyEdit(line.data)">
              <a title="Click to edit Attorney">{{line.data.AttorneyName}}
              </a>
            </u>
          </td>
          <td class="text-left"><u class="column-invoice-number"><a
                [title]="line.data.InvoiceNo"
            (click)="openInvoice(line.data)">{{line.data.InvoiceNo}}</a></u></td>
            <td class="text-center" (click)="onViewBriefDocuments(line.data)">
            <span class="column-doc" title="Documents" style="cursor: pointer;">
              <i *ngIf="line.data.HasBrief" class="fal fa-paperclip" style="font-size:  14px !important;"></i>
            </span>
          </td>
          <td class="text-left">
            {{line.data.PaymentTerms}}</td>
          <td class="text-left">
            {{line.data.TotalDaysOverdue}}</td>
          <td class="text-left" [title]="line.data.Total | currency: currencySymbol"><b>{{line.data.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></td>
          <td class="text-left" style="cursor: pointer;" (click)="openPaymentDetailModal(line.data)">
            <u>{{line.data.Paid | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></td>
          <td class="text-left">{{line.data.Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
          <!-- <td></td> -->
          <!-- <td class="text-center"><u>Add Action</u></td> -->
        </tr>
        <tr class="table-light">
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th><b>Total</b></th>
          <th class="text-left due-text-color"><b>{{totalDueAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalPaymentAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-left"><b>{{totalInvoiceAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <!-- <th></th>
                    <th></th> -->
        </tr>
      </tbody>
      <!-- <tfoot>
        <tr>
          <td colspan="4"> -->
            <!-- <div class="btn-group">
              <span class="clickable" (click)="generateReport()">Generate Excel Report</span>
              <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupMenu()"></i>
            </div> -->
          <!-- </td>
        </tr>
      </tfoot> -->
    </table>
  </div>
  <!-- #End Overdue tab -->

  <!-- Paid Statement -->
  <div class="table-responsive" [class.hide]="activeTab !== 'paid'">
    <span [class.hide]="viewTransactionData" style="text-align: center; align-content: center;">
      <h3>No transaction found</h3>
    </span>
    <as-split direction="horizontal" style="overflow: hidden;" [class.hide]="!viewTransactionData" >
      <as-split-area size="37" style="overflow: hidden;">
        <!-- <h4>Transactions</h4> -->
        <table class="table table-responsive table-hover table-striped">
              <thead>
                <tr>
                  <th class="w-7 align-bottom clickable" (click)="toggleTransSort('trans-date')">Pmt Date <i class="fa fa-sort" [class.fa-sort-down]="sortTDate"></i></th>
                  <th class="w-5 align-bottom clickable" (click)="toggleTransSort('trans-advocate')">Advocate <i class="fa fa-sort" [class.fa-sort-down]="sortTAdvocate"></i></th>
                  <th class="w-10 align-bottom clickable" (click)="toggleTransSort('trans-description')">Description <i class="fa fa-sort" [class.fa-sort-down]="sortTDescription"></i></th>
                  <th class="w-5 align-bottom clickable" (click)="toggleTransSort('trans-amount')">Amount <i class="fa fa-sort" [class.fa-sort-down]="sortTAmount"></i></th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  class="tr"
                  *ngFor="let line of lawfirmPaidStatementHandle"
                  [class.active]="row == line.data.Id"
                  [class.hide]="!line.state.isVisible"
                  scope="row">
                  <td (click)="selectedRow(line.data)">{{line.data.TransactionDate | date:'yyyy-MM-dd'}}</td>
                  <td (click)="selectedRow(line.data)">{{removeAdvocateTitle(line.data.AdvocateName)}}</td>
                  <td (click)="selectedRow(line.data)">{{line.data.TransactionDescription}}</td>
                  <td (click)="selectedRow(line.data)">{{line.data.TransactionAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                </tr>
                <tr class="table-light">
                  <th></th>
                  <th></th>
                  <th><b>Total</b></th>
                  <th><b>{{totalPaidTransactionAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
                </tr>
              </tbody>
            </table>
      </as-split-area>
      <as-split-area size="63" style="overflow: hidden;">
        <!-- <h4>Paid Invoices</h4> -->
        <table *ngIf="selectedPaidStatement" class="table table-responsive table-hover table-striped">
              <thead>
                <tr>
                  <th class="w-10 align-bottom clickable" (click)="toggleInvSort('inv-date')">Inv Date <i class="fa fa-sort" [class.fa-sort-down]="sortInvDate"></i></th>
                  <th class="w-19 align-bottom clickable" (click)="toggleInvSort('inv-matter')">Matter <i class="fa fa-sort" [class.fa-sort-down]="sortInvMatter"></i></th>
                  <th class="w-10 align-bottom clickable" (click)="toggleInvSort('inv-reference')">Reference <i class="fa fa-sort" [class.fa-sort-down]="sortInvRef"></i></th>
                  <th class="w-10 align-bottom clickable" (click)="toggleInvSort('inv-attorney')">Attorney <i class="fa fa-sort" [class.fa-sort-down]="sortInvAttorney"></i></th>
                  <th class="w-10 align-bottom clickable" (click)="toggleInvSort('inv-invoice-no')">Inv. No <i class="fa fa-sort" [class.fa-sort-down]="sortInvNo"></i></th>
                  <th class="w-10 align-bottom clickable" (click)="toggleInvSort('inv-due')">Due <i class="fa fa-sort" [class.fa-sort-down]="sortInvDue"></i></th>
                  <th class="w-10 align-bottom clickable" (click)="toggleInvSort('inv-paid')">Paid <i class="fa fa-sort" [class.fa-sort-down]="sortInvPaid"></i></th>
                  <th class="w-10 align-bottom clickable" (click)="toggleInvSort('inv-total')">Total <i class="fa fa-sort" [class.fa-sort-down]="sortInvTotal"></i></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let lines of selectedPaidStatement">
                    <td [title]="lines.InvoiceDate | date:'yyyy-MM-dd'">{{lines.InvoiceDate | date:'yyyy-MM-dd'}}</td>
                    <td [title]="lines.ServiceDescription">{{lines.ServiceDescription}}</td>
                    <td>{{lines.Reference}}</td>
                    <td [title]="lines.AttorneyName" [ngStyle]="{'color': getCellColor(lines)}">{{lines.AttorneyName}}</td>
                    <td class="text-left"><u class="column-invoice-number"><a [title]="lines.InvoiceNo"
                          (click)="openInvoice(lines)">{{lines.InvoiceNo}}</a></u></td>
                    <td class="text-left" [title]="lines.Total | currency: currencySymbol">
                      <b>{{lines.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></td>
                    <td class="text-left">
                      {{lines.Paid | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                    <td class="text-left">{{lines.Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                </tr>
                <tr class="table-light">
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th><b>Total</b></th>
                  <th class="text-left due-text-color"><b>{{totalInvoiceDue | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
                  <th class="text-left"><b>{{totalInvoicePaid | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
                  <th class="text-left"><b>{{totalInvoiceTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
                  <!-- <th></th>
                                    <th></th> -->
                </tr>
              </tbody>
            </table>
      </as-split-area>
    </as-split>
  </div> <!-- #end .table-responsive -->
  <!-- #end Paid Statement -->

  <!-- Unallocated tab -->
  <div class="table-responsive" [class.hide]="activeTab !== 'unallocated'">
    <table class="table table-striped " style="border: 0;">
      <tbody class="tbody">
        <tr class="table-light">
          <th class="w-5 align-bottom" (click)="toggleSort('date')">Date <i class="fa fa-sort"
              [class.fa-sort-down]="sortDate"></i></th>
          <th class="w-19 align-bottom" (click)="toggleSort('matter')">Advocate Name <i class="fa fa-sort"
              [class.fa-sort-down]="sortMatter"></i></th>
          <th class="w-25 align-bottom" (click)="toggleSort('matter')">Description <i class="fa fa-sort"
              [class.fa-sort-down]="sortMatter"></i></th>
          <th class="w-10 align-bottom text-right" (click)="toggleSort('transaction-amount')">Transaction Amount <i class="fa fa-sort"
              [class.fa-sort-down]="sortTransactionAmount"></i></th>
          <th class="w-10 align-bottom text-right" (click)="toggleSort('paid')">Unallocated Amount <i class="fa fa-sort"
              [class.fa-sort-down]="sortPaid"></i></th>
          <th class="w-2 align-bottom"></th>
        </tr>


        <tr></tr>
        <tr *ngFor="let line of advocateStatementHandle"
            [class.hide]="!line.state.isVisible" role="tabpanel" class="tab-pane tab-fade">
          <td [title]="line.data.InvoiceDate | date:'yyyy-MM-dd'">{{line.data.InvoiceDate | date:'yyyy-MM-dd'}}</td>
          <td [title]="line.data.ProviderName">{{line.data.ProviderName}}</td>
          <td [title]="line.data.ServiceDescription">{{line.data.ServiceDescription}}</td>
          <td class="text-right">{{line.data.Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
          <td class="text-right">{{line.data.Paid | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
          <td class="text-center" (click)="onShowUnallocatedTransactionModal(line.data)">
            <i class="fal fa-clipboard-list" style="font-size: 16px; cursor: pointer;"></i>
          </td>
          <!-- <td></td> -->
          <!-- <td class="text-center"><u>Add Action</u></td> -->
        </tr>
        <tr class="table-light">
          <th></th>
          <th></th>
          <th><b>Total</b></th>
          <th class="text-right"><b>{{totalTransactionAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th class="text-right"><b>{{totalUnallocatedAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></th>
          <th></th>
          <!-- <th></th>
                    <th></th> -->
        </tr>
      </tbody>
    
      <!-- <tfoot>
        <tr>
          <td colspan="4"> -->
            <!-- <div class="btn-group">
              <span class="clickable" (click)="generateReport()">Generate Excel Report</span>
              <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupMenu()"></i>
            </div> -->
          <!-- </td>
        </tr>
      </tfoot> -->
    </table>
  </div>
  <!-- #End Unallocated tab -->
</div>

</div>


<app-payment-detail-modal
confirmText="OK"
[isVisible]="showPaymentDetailModal"
[data]="paymentDetails"
[selectedInvoice]="selectedInvoice"
(closeModal)="closePaymentDetailModal()"
(confirm)="savePaymentDetail($event)"></app-payment-detail-modal>

  <app-live-statement-invoice-preview 
    *ngIf="showProformaInvoice" 
    #proformaInvoice 
    [plaintiff]="invoicePlaintiff"
    [defendant]="invoiceDefendant" 
    [invoiceId]="selectedInvoiceId" 
    [invoiceDetails]="invoiceDetails"
    [isVisible]="showProformaInvoice" 
    (closeModal)="closeModal('invoice')"></app-live-statement-invoice-preview>

  <app-attonery-brief-preview-modal 
    *ngIf="showBriefDocumentModal"
    [isVisible]="showBriefDocumentModal"
    [briefDocumentHandle]="briefDocumentHandle"
    (closeModal)="closeModal('bried-document')"></app-attonery-brief-preview-modal>

  <app-prompt-unsaved-changes-modal 
    title="Legacy Invoice"
    message="This invoice was captured from the Advocate's legacy system and is not available on Auxcon. Kindly contact Auxcon on 012 343 9124 or auxilium@auxcon.co.za to obtain a copy of the original invoice."
    [isVisible]="showHistoricalPrompt"
    btnTextContinue="Ok"
    btnTextCancel="Cancel"
    (cancel)="onHideHistoricalPrompt()"
    (continue)="onHideHistoricalPrompt()"></app-prompt-unsaved-changes-modal>
  <app-prompt-unsaved-changes-modal 
    title="Date not selected"
    message="You have not selected a From and To date. Please select a date range to generate the Reconciliation History Report."
    [isVisible]="showReconHistoricalPrompt"
    btnTextContinue="Ok"
    btnTextCancel="Cancel"
    (cancel)="onHideReconHistoricalPrompt()"
    (continue)="onHideReconHistoricalPrompt()"></app-prompt-unsaved-changes-modal>
  <app-unallocated-transaction
    [SelectedTransaction]="SelectedTransaction"
    [isVisible]="showUnallocatedModal"
    (cancel)="onHideUnallocatedModal()"
    (confirm)="onSendUnallocatedRequest($event)"></app-unallocated-transaction>

  <app-update-reference-modal
    *ngIf="showEditReferenceModal"
    [isVisible]="showEditReferenceModal"
    [referenceData]="referenceData"
    (closeModal)="closeModal('edit-reference')"
    (saveReference)="onSaveReference($event)"></app-update-reference-modal>
  
  <app-attorney-update-modal
    *ngIf="showEditAttorneyModal"
    [isVisible]="showEditAttorneyModal"
    [attorneys]="attorneys"
    [selectedAttorneyData]="referenceData"
    (closeModal)="closeModal('edit-attorney')"
    (saveAttorney)="onSaveReference($event)"></app-attorney-update-modal>
  
  <app-invoice-line-detail-modal
  *ngIf="onShowInvoiceLineDetailModal"
  [isVisible]="onShowInvoiceLineDetailModal"
  [details]="details"
  [invoiceDetailDataHandle]="invoiceDetailDataHandle"
  (cancel)="closeModal('invoice-line-detail')"
  (updatedStatus)="onInvoiceLineDetailUpdate()"
  ></app-invoice-line-detail-modal>

<app-state-invoice-status
  *ngIf="onShowStateInvoiceStatus"
  [isVisible]="onShowStateInvoiceStatus"
  [advocateDetailStatement]="selectedAdvocateDetailStatement"
  (cancel)="closeModal('state-invoice-status')"
  (updatedStatus)="onInvoiceStatusUpdate($event)"
  ></app-state-invoice-status>

<app-invoice-dispute
  *ngIf="onShowInvoiceDispute"
  [isVisible]="onShowInvoiceDispute"
  [advocateDetailStatement]="selectedAdvocateDetailStatement"
  [disputStatues]="disputStatues"
  (cancel)="closeModal('invoice-dispute')"
  (updatedStatus)="onInvoiceDisputeUpdate($event)"
></app-invoice-dispute>

<app-invoice-line-dispute
*ngIf="onShowInvoiceLinesDispite"
[isVisible]="onShowInvoiceLinesDispite"
[advocateLineItems]="advocateLineItems"
[disputStatues]="disputStatues"
(cancel)="closeModal('line-item-dispute')"
(updatedStatus)="onInvoiceStatusUpdate()"
></app-invoice-line-dispute>

<app-query-report-progress
  *ngIf="onShowQueryReportProgress"
  [isVisible]="onShowQueryReportProgress"
  [queryReport]="queryReport"
  (cancel)="closeModal('query-report-progress')"
  ></app-query-report-progress>