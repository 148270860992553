import { Component, OnInit } from '@angular/core';
import { Contacts, ContactsHandle, ContactsPagination, ContactsService, DEFAULT_CONTACTS, DEFAULT_CONTACTS_PAGINATION } from '../../../services/contacts/contacts.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { ICrumb } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { PATH_AUXCON_ADMIN } from '../../../services/appdata/app-config.service';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-contact-management',
  templateUrl: './contact-management.component.html',
  styleUrls: ['./contact-management.component.scss']
})
export class ContactManagementComponent implements OnInit {

  //#region lawfirm
  sortLawfirmBy: string;
  previousSortLawfirmBy: string;
  sortLawfirmAscending: boolean;
  sortLawfirmName: boolean;
  sortLawfirmPhone: boolean;
  sortLawfirmEmail: boolean;
  sortLawfirmFax: boolean;
  sortLawfirmVat: boolean;
  sortLawfirmPostalAddress: boolean;
  sortLawfirmStreetAddress: boolean;
  lawfirmSearchTerm: string;
  lawfirms: Contacts[] = [];
  lawfirmsHandle: ContactsHandle[] = [];
  selectedLawfirmsToMerge: Contacts[] = [];
  mergeMessage: string;
  showMergeContactsPrompt: boolean;
  selectedLawfirm: Contacts = DEFAULT_CONTACTS;
  isManageBlacklistUser : boolean = true;
  showAddEditLawfirm: boolean;
  contactsPagination: ContactsPagination = DEFAULT_CONTACTS_PAGINATION;

  page: number = 1;
  count: number = 0;
  tableSize: number = 50;
  tableSizes: any = [3, 6, 9, 12];
  //#endregion lawfirm

  //#region attorney
  showAddNewAttorney: boolean;
  showEditAttorney: boolean;
  sortAttorneyBy: string;
  previousSortAttorneyBy: string;
  sortAttorneyAscending: boolean;
  sortAttorneyName: boolean;
  // sortAttorneyPhone: boolean;
  sortAttorneyEmail: boolean;
  sortAttorneyCell: boolean;
  attorneySearchTerm: string;
  selectedAttorney: Contacts = DEFAULT_CONTACTS;
  //#endregion attorney

  row = 0;
  crumbs: ICrumb[];
  activeTab: string;
  attorney: Contacts[] = [];
  attorneyHandle: ContactsHandle[] = [];

  get canAddAttorney(): boolean {
    return Boolean(this.row > 0);
  } // end canAddAttorney()

  get canSaveMergeLawFirms(): boolean {
    return Boolean(this.selectedLawfirmsToMerge.length > 1);
  } // end canSaveMergeLawFirms()

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private contactService: ContactsService,
  ) { } // end constructor()

  ngOnInit(): void {
    this.selectedLawfirmsToMerge = [];
    this.crumbs = [
      {
        label: 'Admin',
        link: PATH_AUXCON_ADMIN
      },
      {
        label: 'Contact Management'
      }
    ];
    if (!this.activeTab) {
      this.activeTab = 'law-firms';
    }
    this.getAllLawfirms();
  } // end ngOnInit()

  getAllLawfirms() {
    this.lawfirms = [];
    this.lawfirmsHandle = [];
    
    this.loadingService.showOverlay();
    this.contactService.getLawfirmByPage(this.page, this.tableSize, 6).subscribe({ next: (data) => {
      // On next
      this.contactsPagination = DEFAULT_CONTACTS_PAGINATION;
      this.contactsPagination = data
      // console.log('RETURNED DATA: ', data);
      //this.lawfirms = data;
      // if (this.lawfirms) {
      //   this.lawfirms.forEach(firm => {
      //     this.lawfirmsHandle.push({
      //       data: firm,
      //       state: {
      //         isVisible: true,
      //         isSelected: false,
      //         isOpen: false
      //       }
      //     });
      //   });
      // }
      // this.loadingService.hideOverlay();
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay()
    },
     complete: () => {
       // On complete
       this.loadingService.hideOverlay();
     }
  });
  } // end getAllLawfirms()

  onTableDataChange(event: number) {
    //this.tableSize = event.target.value;
    this.page = event;
    this.getAllLawfirms();
  }

  togglePanel(tab: string) {
    this.contactsPagination = DEFAULT_CONTACTS_PAGINATION;
    this.selectedLawfirmsToMerge = [];
    this.page = 1;
    this.tableSize = 50;
    this.lawfirmSearchTerm = '';
    this.attorneySearchTerm = '';
    switch(tab) {
      case 'law-firms':
        this.getAllLawfirms();
        this.activeTab = 'law-firms';
      break;
      case 'advocates':
        this.activeTab = 'advocates';
      break;
      case 'secretary':
        this.activeTab = 'secretary';
      break;
    }
  } // end togglePanel()

  performLawfirmSearch() {
    if (this.lawfirmSearchTerm) {
      this.loadingService.showOverlay();
      this.contactsPagination = this.util.objectCopy({TotalCount: 0,
        PageSize: 0,
        CurrentPage: 0,
        TotalPages: 0,
        HasNext: false,
        HasPrevious: false,
        results: [] = []});
      this.contactService.searchForLawfirm(this.lawfirmSearchTerm).subscribe({ next: (data) => {
          // On next
            // console.log('BEFORE DATA: ', this.contactsPagination);
          this.contactsPagination = data;
          // console.log('LOADED DATA: ', this.contactsPagination);
        },
        error: (error) => {
          // On next
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
        }
      });
    //   this.lawfirmsHandle.forEach(firm => {
    //     if (
    //       !(firm.data.FullName.match(new RegExp(this.lawfirmSearchTerm, 'gi')))
    //     ) {
    //       firm.state.isVisible = false;
    //     } else {
    //       firm.state.isVisible = true;
    //     }
    //   });
    // } else {
    //   this.lawfirmsHandle.forEach(firm => {
    //     firm.state.isVisible = true;
    //   });
    }
  } // end performLawfirmSearch()

  clearLawfirmSearch() {
    this.lawfirmSearchTerm = '';
    this.getAllLawfirms();
  } // clearLawfirmSearch()

  toggleSortLawfirm(sortBy: string) {
    this.sortLawfirmBy = sortBy;
    if (this.sortLawfirmBy === this.previousSortLawfirmBy) {
      this.sortLawfirmAscending = !this.sortLawfirmAscending;
    }

    switch(this.sortLawfirmBy) {
      case 'firm-name':
        this.lawfirmsHandle.sort((a, b) => {
          if (this.sortLawfirmAscending) {
            this.sortLawfirmName = true;
            this.sortLawfirmPhone = false;
            this.sortLawfirmEmail = false;
            this.sortLawfirmFax = false;
            this.sortLawfirmVat = false;
            this.sortLawfirmPostalAddress = false;
            this.sortLawfirmStreetAddress = false;
            return a.data.FullName.toUpperCase().localeCompare(b.data.FullName.toUpperCase());
          }
          this.sortLawfirmName = false;
          return b.data.FullName.toUpperCase().localeCompare(a.data.FullName.toUpperCase());
        });
      break;

      case 'firm-phone':
        this.lawfirmsHandle.sort((a, b) => {
          if (this.sortLawfirmAscending) {
            this.sortLawfirmName = false;
            this.sortLawfirmPhone = true;
            this.sortLawfirmEmail = false;
            this.sortLawfirmFax = false;
            this.sortLawfirmVat = false;
            this.sortLawfirmPostalAddress = false;
            this.sortLawfirmStreetAddress = false;
            return a.data.Phone.toUpperCase().localeCompare(b.data.Phone.toUpperCase());
          }
          this.sortLawfirmPhone = false;
          return b.data.Phone.toUpperCase().localeCompare(a.data.Phone.toUpperCase());
        });
      break;
        
      case 'firm-email':
        this.lawfirmsHandle.sort((a, b) => {
          if (this.sortLawfirmAscending) {
            this.sortLawfirmName = false;
            this.sortLawfirmPhone = false;
            this.sortLawfirmEmail = true;
            this.sortLawfirmFax = false;
            this.sortLawfirmVat = false;
            this.sortLawfirmPostalAddress = false;
            this.sortLawfirmStreetAddress = false;
            return a.data.Email.toUpperCase().localeCompare(b.data.Email.toUpperCase());
          }
          this.sortLawfirmEmail = false;
          return b.data.Email.toUpperCase().localeCompare(a.data.Email.toUpperCase());
        });
      break;

      case 'firm-fax':
        this.lawfirmsHandle.sort((a, b) => {
          if (this.sortLawfirmAscending) {
            this.sortLawfirmName = false;
            this.sortLawfirmPhone = false;
            this.sortLawfirmEmail = false;
            this.sortLawfirmFax = true;
            this.sortLawfirmVat = false;
            this.sortLawfirmPostalAddress = false;
            this.sortLawfirmStreetAddress = false;
            return a.data.Fax.toUpperCase().localeCompare(b.data.Fax.toUpperCase());
          }
          this.sortLawfirmFax = false;
          return b.data.Fax.toUpperCase().localeCompare(a.data.Fax.toUpperCase());
        });
      break;

      case 'firm-vat':
        this.lawfirmsHandle.sort((a, b) => {
          if (this.sortLawfirmAscending) {
            this.sortLawfirmName = false;
            this.sortLawfirmPhone = false;
            this.sortLawfirmEmail = false;
            this.sortLawfirmFax = false;
            this.sortLawfirmVat = true;
            this.sortLawfirmPostalAddress = false;
            this.sortLawfirmStreetAddress = false;
            return a.data.Vatno.toUpperCase().localeCompare(b.data.Vatno.toUpperCase());
          }
          this.sortLawfirmVat = false;
          return b.data.Vatno.toUpperCase().localeCompare(a.data.Vatno.toUpperCase());
        });
      break;

      case 'firm-postal-address':
        this.lawfirmsHandle.sort((a, b) => {
          if (this.sortLawfirmAscending) {
            this.sortLawfirmName = false;
            this.sortLawfirmPhone = false;
            this.sortLawfirmEmail = false;
            this.sortLawfirmFax = false;
            this.sortLawfirmVat = false;
            this.sortLawfirmPostalAddress = true;
            this.sortLawfirmStreetAddress = false;
            return a.data.PostalAddress.toUpperCase().localeCompare(b.data.PostalAddress.toUpperCase());
          }
          this.sortLawfirmVat = false;
          return b.data.PostalAddress.toUpperCase().localeCompare(a.data.PostalAddress.toUpperCase());
        });
      break;

      case 'firm-street-address':
        this.lawfirmsHandle.sort((a, b) => {
          if (this.sortLawfirmAscending) {
            this.sortLawfirmName = false;
            this.sortLawfirmPhone = false;
            this.sortLawfirmEmail = false;
            this.sortLawfirmFax = false;
            this.sortLawfirmVat = false;
            this.sortLawfirmPostalAddress = false;
            this.sortLawfirmStreetAddress = true;
            return a.data.StreetAddress.toUpperCase().localeCompare(b.data.StreetAddress.toUpperCase());
          }
          this.sortLawfirmStreetAddress = false;
          return b.data.StreetAddress.toUpperCase().localeCompare(a.data.StreetAddress.toUpperCase());
        });
      break;
    }
    this.previousSortLawfirmBy = this.sortLawfirmBy;
  } // end toggleSortLawfirm()

  onLawfirmRowClick(firm: Contacts) {
    this.loadingService.showOverlay();
    this.attorney = [];
    this.attorneyHandle = [];
    this.selectedLawfirm = DEFAULT_CONTACTS;
    this.row = 0;
    if (firm) {
      this.row = firm.Id;
      this.selectedLawfirm = firm;
      this.contactService.getAttorneyByLawfirm(firm.Id).subscribe({ next: (data) => {
        // On next
        this.attorney = data;
        if (this.attorney) {
          this.attorney.forEach(_data => {
            this.attorneyHandle.push({
              data: _data,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          });
        }
        // console.log('SELECTED ATTORNEY LIST: ', data);
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
      }
    });
    }
  } // end onLawfirmRowClick()

  performAttorneySearch() {
    if (this.attorneySearchTerm) {
      this.attorneyHandle.forEach(firm => {
        if (
          !(firm.data.FullName.match(new RegExp(this.attorneySearchTerm, 'gi')))
        ) {
          firm.state.isVisible = false;
        } else {
          firm.state.isVisible = true;
        }
      });
    } else {
      this.attorneyHandle.forEach(firm => {
        firm.state.isVisible = true;
      });
    }
  } // end performAttorneySearch()

  clearAttorneySearch() {
    this.attorneySearchTerm = '';
    this.performAttorneySearch();
  } // clearAttorneySearch()

  toggleSortAttorney(sortBy: string) {
    this.sortAttorneyBy = sortBy;
    if (this.sortAttorneyBy === this.previousSortAttorneyBy) {
      this.sortAttorneyAscending = !this.sortAttorneyAscending;
    }

    switch(this.sortAttorneyBy) {
      case 'attorney-name':
        this.attorneyHandle.sort((a, b) => {
          if (this.sortAttorneyAscending) {
            this.sortAttorneyName = true;
            // this.sortAttorneyPhone = false;
            this.sortAttorneyCell = false;
            this.sortAttorneyEmail = false;
            return a.data.FullName.toUpperCase().localeCompare(b.data.FullName.toUpperCase());
          }
          this.sortAttorneyName = false;
          return b.data.FullName.toUpperCase().localeCompare(a.data.FullName.toUpperCase());
        });
      break;

      // case 'attorney-phone':
      //   this.attorneyHandle.sort((a, b) => {
      //     if (this.sortAttorneyAscending) {
      //       this.sortAttorneyName = false;
      //       this.sortAttorneyPhone = true;
      //       this.sortAttorneyCell = false;
      //       this.sortAttorneyEmail = false;
      //       return a.data.Phone.toUpperCase().localeCompare(b.data.Phone.toUpperCase());
      //     }
      //     this.sortAttorneyPhone = false;
      //     return b.data.Phone.toUpperCase().localeCompare(a.data.Phone.toUpperCase());
      //   });
      // break;
        
      case 'attorney-cell':
        this.attorneyHandle.sort((a, b) => {
          if (this.sortAttorneyAscending) {
            this.sortAttorneyName = false;
            // this.sortAttorneyPhone = false;
            this.sortAttorneyCell = true;
            this.sortAttorneyEmail = false;
            return a.data.Cell.toUpperCase().localeCompare(b.data.Cell.toUpperCase());
          }
          this.sortAttorneyCell = false;
          return b.data.Cell.toUpperCase().localeCompare(a.data.Cell.toUpperCase());
        });
      break;

      case 'attorney-email':
        this.attorneyHandle.sort((a, b) => {
          if (this.sortAttorneyAscending) {
            this.sortAttorneyName = false;
            // this.sortAttorneyPhone = false;
            this.sortAttorneyCell = false;
            this.sortAttorneyEmail = true;
            return a.data.Fax.toUpperCase().localeCompare(b.data.Fax.toUpperCase());
          }
          this.sortAttorneyEmail = false;
          return b.data.Fax.toUpperCase().localeCompare(a.data.Fax.toUpperCase());
        });
      break;
    }
    this.previousSortAttorneyBy = this.sortAttorneyBy;
  } // end toggleSortAttorney()

  addNewAttorney() {
    console.log('SELECTED FIRM: ', this.selectedLawfirm);
    if (this.selectedLawfirm) {
      this.showAddNewAttorney = true;
    }
  } // end addNewAttorney()

  addNewContact() {
    this.selectedLawfirm = {
      AccountHolder: '',
      AccountNumber: '',
      AccountType: '',
      BranchCode: '',
      Cell: '',
      CollectThreshold: '',
      ContactContactType: 0,
      Locality: '',
      ContactLocality: 0,
      DefaultDailyRate: 0,
      DefaultHourlyRate: 0,
      DefaultPerKilometerRate: 0,
      Docex: '',
      Email: '',
      Fax: '',
      FeeTarget: 0,
      FullName : '',
      Id: 0,
      Identity: '',
      Image: '',
      IncomeTarget: 0,
      LastStatementDownload: '',
      Level: '',
      OfficeRoom: '',
      Phone: '',
      PostalAddress: '',
      SecurityLinks: '',
      Speciality: '',
      Status: '',
      StreetAddress: '',
      Suburb: '',
      ToCollect: 0,
      UserName: '',
      Vatno: '',
      WebSite: '',
      ZipCode: '',
      IsSelected: false,
      AdmissionDay : null,
      AdmissionMonth : null,
      AdmissionYear : null,
      AdmissionDate : null,
      AdmissionDateStr : '',
      RegionalBarId : 0,
      RegionalBarName : '',
      ChamberID: 0,
      TenantID: '',
      Domain: '',
      ContactTypeID: 2,
      Ethincity: 'African',
      Gender: 'Male',
      Chamber: ''
      
    }
    this.showAddEditLawfirm = true;
  } // end addNewContact()

  onEditLawFirm(data: Contacts) {
    this.selectedLawfirm = data;
    this.showAddEditLawfirm = true;
  } // end onEditLawFirm()

  onCloseModal(event: string) {
    switch(event) {
      case 'add-new-attorney':
        this.showAddNewAttorney = false;
      break;

      case 'edit-attorney':
        this.showEditAttorney = false;
      break;

      case 'law-firm':
        this.showAddEditLawfirm = false;
      break;
    }
  } // end onCloseModal()

  onAttorneySave(data: Contacts) {
    this.onLawfirmRowClick(data);
    this.showAddNewAttorney = false;
  } // end onAttorneySave()

  onEditAttorney(data: Contacts) {
    this.selectedAttorney = DEFAULT_CONTACTS;
    this.selectedAttorney = data;
    this.showEditAttorney = true;
  } // end onEditAttorney()

  onAttorneyUpdate(data: Contacts) {
    this.onLawfirmRowClick(data);
    this.showEditAttorney = false;
  } // end onAttorneyUpdate() 

  onSaveNewLawfirm(event) {
    this.getAllLawfirms();
    this.showAddEditLawfirm = false;
  } // end onSaveNewLawfirm()

  toggleCheckbox(data: Contacts) {
    data.IsSelected = !data.IsSelected;
    if (this.contactsPagination.results.find(_data => _data.IsSelected === true && _data == data)) {
      this.selectedLawfirmsToMerge.push(data);

      if (this.selectedLawfirmsToMerge.length > 1) {
        this.mergeMessage = 'You are about to merge ' + this.selectedLawfirmsToMerge[0].FullName +
        ' with ' + this.selectedLawfirmsToMerge[1].FullName + ', ' + this.selectedLawfirmsToMerge[1].FullName +
        ' will no longer be available.';
        this.showMergeContactsPrompt = true;
      }
    }
  } // end toggleCheckbox()

  onHideMergeContactsPrompt() {
    this.selectedLawfirmsToMerge = [];
    this.lawfirmsHandle.forEach(x => {
      if (x.state.isSelected === true) {
        x.state.isSelected = false;
      }
    });
    this.showMergeContactsPrompt = false;
  } // end onHideMergeContactsPrompt()

  onMergeLawfirms() {
    if (this.selectedLawfirmsToMerge.length > 1) {
      this.mergeMessage = 'You are about to merge ' + this.selectedLawfirmsToMerge[0].FullName +
      ' with ' + this.selectedLawfirmsToMerge[1].FullName + ', ' + this.selectedLawfirmsToMerge[1].FullName +
      ' will no longer be available.';
      this.showMergeContactsPrompt = true;
    }
  } // end onMergeLawfirms()

  continueMerge() {
    if (this.selectedLawfirmsToMerge.length > 1) {
    this.loadingService.showOverlay();
    this.contactService.mergeLawfirms(this.selectedLawfirmsToMerge[1].Id, this.selectedLawfirmsToMerge[0].Id).subscribe({ next: (data) => {
      // On next
      },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.getAllLawfirms();
          this.selectedLawfirmsToMerge = [];
          this.showMergeContactsPrompt = false;
        }
      });
    }
  } // end continueMerge()
} // end ContactManagement{}
