import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FromCalendarItemsService } from '../../services/from-calendar-items/from-calendar-items.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { LENGTH_LONG, Snack, SnackType, SnackbarsService } from '../../services/messaging/snackbars/snackbars.service';
import { environment } from '../../../environments/environment'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-outlook-sync',
  templateUrl: './outlook-sync.component.html',
  styleUrls: ['./outlook-sync.component.scss']
})

export class OutlookSyncComponent implements OnInit {
  selectedCard: number | null = null;
  showUnLinkPopPrompt: boolean;
  CardName: string = "";
  errorMessage: string = "";
  Scope: string = "";
  openedWindow: any;
  temp: any;
  isCardDisabled: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private fromCalendarItemsService: FromCalendarItemsService,
    private cdr: ChangeDetectorRef,
    private router: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.GetContactGraphPermission();
    this.router.queryParams.subscribe(params => {
      if (params['Code'] === 'CalendarSetup') {
        this.isCardDisabled = true;
      }
    });
    this.fromCalendarItemsService.getAuthCode((authCode) => {
      if (authCode) {
        this.selectedCard = this.temp;
        this.cdr.detectChanges();
        localStorage.setItem("isOutlookCalendarIntegrated", "true");
      }
    });
  }

  GetContactGraphPermission() {
    this.loadingService.showOverlay();
    this.fromCalendarItemsService.getContactGraphPermission().subscribe({
      next: (data) => {
        if (data) {
          if (data.status === "Success" && !data.errorMessage) {
            this.checkPermission(data.contactGraphPermission.Scope.split(','));
          } else if (data.status === "Error") {
            this.errorMessage = "Your access is expired, Please re-sync again.";
          }
          else {
            this.selectedCard = null;
          }
        }
      },
      error: (error) => {
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.loadingService.hideOverlay();
      }
    })
  }

  checkPermission(permissions: any) {
    if (permissions.includes('Calendars.ReadWrite') && permissions.includes('Mail.Send'))
      this.selectedCard = 3;
    else if (permissions.includes('Mail.Send'))
      this.selectedCard = 2;
    else if (permissions.includes('Calendars.ReadWrite'))
      this.selectedCard = 1;
    else
      this.selectedCard = null;
  }

  selectCard(cardNumber: number, message: string) {
    this.temp = cardNumber;
    this.selectedCard = cardNumber;
    if (this.errorMessage === '' && message !== '')
      this.showUnLinkPopPrompt = true;
    else if ((this.errorMessage && message === '') || (this.errorMessage === '' && message === '')) {
      this.Scope = cardNumber === 1 ? 'Calendars.ReadWrite,Calendars.ReadWrite.Shared' : cardNumber === 2 ? 'Mail.Send,Mail.Send.Shared' : 'Calendars.ReadWrite,Calendars.ReadWrite.Shared,Mail.Send,Mail.Send.Shared'
      localStorage.setItem("Scopes", this.Scope);
      const width = 500, height = 600;
      const left = (window.innerWidth - width) / 2, top = (window.innerHeight - height) / 2;
      this.openedWindow = window.open(
        'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?'
        + 'client_id=' + environment.office365ClientId
        + '&scope=email%20profile%20openid%20offline_access%20User%2eReadWrite%20' + (cardNumber === 1 ? 'Calendars%2eReadWrite%20Calendars%2eReadWrite%2eShared' : cardNumber === 2 ? 'Mail%2eSend%20Mail%2eSend%2eShared' : 'Calendars%2eReadWrite%20Calendars%2eReadWrite%2eShared%20Mail%2eSend%20Mail%2eSend%2eShared')
        + '&redirect_uri=' + environment.outlookCalendarRedirectURL
        + '&response_type=code'
        + '&response_mode=query'
        + '&prompt=select_account', '_blank', `width=${width}, height=${height}, left=${left}, top=${top}`);        
      this.selectedCard = null;
    }

  }

  onHideUnLinkPopPrompt() {
    this.showUnLinkPopPrompt = false;
  }

  onDeleteContactGraphPermission() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: "Please wait...",
      action: null,
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    this.fromCalendarItemsService.onDeleteContactGraphPermission().subscribe({
      next: (response) => {
        localStorage.setItem("isOutlookCalendarIntegrated", "false");
        const outlookCalendarRefreshToken = sessionStorage.getItem("outlookCalendarRefreshToken");
        if (outlookCalendarRefreshToken) {
          sessionStorage.setItem("outlookCalendarRefreshToken", null);
        }
        this.onHideUnLinkPopPrompt();
        this.selectedCard = null;
      },
      error: (error) => {
        snack.label = "An error occurred while processing your request.";
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        snack.label = "DeLink Successfully.";
        snack.type = SnackType.SUCCESS;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    })
  }


}