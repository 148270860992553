<div class="row m-0" *ngIf="isDataLoaded===true">
    <div class="table-responsive">
        <table class="table text-center" style="border: 0;">
            <thead class="thead">
                <tr>
                    <th scope="col" class="w-13 box-container"></th>
                    <th scope="col" class="text-white box-container w-15">
                        <div class="bg-current box-child" [ngStyle]="{'height': getBarHeight(agingCurrentPercentage) }">
                            <p
                                [ngStyle]="{'margin-top': getBarTextTopMargin(agingCurrentPercentage) , 'color': getBarTextColor(agingCurrentPercentage)}">
                                {{agingCurrentPercentage}}%</p>
                        </div>
                    </th>
                    <th scope="col" class="text-white box-container w-15">
                        <div class="bg-30 box-child" [ngStyle]="{'height': getBarHeight(aging30Percentage) }">
                            <p
                                [ngStyle]="{'margin-top': getBarTextTopMargin(aging30Percentage) , 'color': getBarTextColor(aging30Percentage)}">
                                {{aging30Percentage}}%</p>
                        </div>
                    </th>
                    <th scope="col" class="text-white box-container w-15">
                        <div class="bg-60 box-child" [ngStyle]="{'height': getBarHeight(aging60Percentage) }">
                            <p
                                [ngStyle]="{'margin-top': getBarTextTopMargin(aging60Percentage) , 'color': getBarTextColor(aging60Percentage)}">
                                {{aging60Percentage}}%</p>
                        </div>
                    </th>
                    <th scope="col" class="text-white box-container w-15">
                        <div class=" bg-90 box-child" [ngStyle]="{'height': getBarHeight(aging90Percentage) }">
                            <p
                                [ngStyle]="{'margin-top': getBarTextTopMargin(aging90Percentage) , 'color': getBarTextColor(aging90Percentage)}">
                                {{aging90Percentage}}%</p>
                        </div>
                    </th>
                    <th scope="col" class="text-white box-container w-15">
                        <div class="bg-98 box-child" [ngStyle]="{'height': getBarHeight(aging98Percentage) }">
                            <p
                                [ngStyle]="{'margin-top': getBarTextTopMargin(aging98Percentage) , 'color': getBarTextColor(aging98Percentage)}">
                                {{aging98Percentage}}%</p>
                        </div>
                    </th>
                    <th scope="col" class="box-container"></th>
                </tr>
            </thead>
        </table>
    </div>

    <div class="table-responsive">
        <table class="table table-bordered text-right">
            <thead class="thead">
                <tr>
                    <th scope="col" class="bg-overdue w-13">Days</th>
                    <th scope="col" class="bg-current text-white w-15" style="border-color:#8abd37 !important">Current
                    </th>
                    <th scope="col" class="bg-30 text-white w-15" style="border-color:#e5c100 !important">30</th>
                    <th scope="col" class="bg-60 text-white w-15" style="border-color:#eb9e02 !important">60</th>
                    <th scope="col" class="bg-90 text-white w-15" style="border-color:#DC6D2A !important">90</th>
                    <th scope="col" class="bg-98 text-white w-15" style="border-color:#cf624f !important">Overdue</th>
                    <th scope="col" class="bg-total">Total</th>
                </tr>
            </thead>
            <tbody class="tbody">
                <tr *ngFor="let i of data;">
                    <td class="w-13" scope="row">{{i.type}}</td>
                    <td class="w-15">{{i.agingCurrentTotal | currency: currencySymbol: currencySymbol:'1.2-2' }}</td>
                    <td class="w-15">{{i.aging30Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                    <td class="w-15">{{i.aging60Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                    <td class="w-15">{{i.aging90Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                    <td class="w-15 text-color">{{i.aging98Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                    <td>{{i.totalOutstanding | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                </tr>
                <tr scope="row">
                    <td>Total</td>
                    <td>
                        <div class="tool-tip">
                            <b class="cursor-pointer" (click)="receiveToolTipAge('current')">{{agingCurrentTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                            <app-age-analysis-tooltip class="tooltiptext" (onToolTipClick)="receiveToolTipAge($event)"
                                *ngIf="renderTooltipOverlay === true" [_tooltipData]="_currentTooltipData"
                                [age]="'current'">
                            </app-age-analysis-tooltip>
                        </div>
                    </td>
                    <td>
                        <div class="tool-tip">
                            <b class="cursor-pointer" (click)="receiveToolTipAge('30')">{{aging30Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                            <app-age-analysis-tooltip class="tooltiptext" (onToolTipClick)="receiveToolTipAge($event)"
                                *ngIf="renderTooltipOverlay === true" [_tooltipData]="_30TooltipData" [age]="'30'">
                            </app-age-analysis-tooltip>
                        </div>
                    </td>
                    <td>
                        <div class="tool-tip">
                            <b class="cursor-pointer" (click)="receiveToolTipAge('60')">{{aging60Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                            <app-age-analysis-tooltip class="tooltiptext" (onToolTipClick)="receiveToolTipAge($event)"
                                *ngIf="renderTooltipOverlay === true" [_tooltipData]="_60TooltipData" [age]="'60'">
                            </app-age-analysis-tooltip>
                        </div>
                    </td>
                    <td>
                        <div class="tool-tip">
                            <b class="cursor-pointer" (click)="receiveToolTipAge('90')">{{aging90Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                            <app-age-analysis-tooltip class="tooltiptext" (onToolTipClick)="receiveToolTipAge($event)"
                                *ngIf="renderTooltipOverlay === true" [_tooltipData]="_90TooltipData" [age]="'90'">
                            </app-age-analysis-tooltip>
                        </div>
                    </td>
                    <td>
                        <div class="tool-tip text-color">
                            <b class="cursor-pointer" (click)="receiveToolTipAge('98')">{{aging98Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                            <app-age-analysis-tooltip class="tooltiptext" (onToolTipClick)="receiveToolTipAge($event)"
                                *ngIf="renderTooltipOverlay === true" [_tooltipData]="_98TooltipData" [age]="'98'">
                            </app-age-analysis-tooltip>
                        </div>
                    </td>
                    <td>
                        <div class="tool-tip ">
                            <b class="cursor-pointer" (click)="receiveToolTipAge('all')">{{totalOutstanding | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                            <app-age-analysis-tooltip class="tooltiptext alltooltiptext" (onToolTipClick)="receiveToolTipAge($event)"
                                *ngIf="renderTooltipOverlay === true" [_tooltipData]="_allTooltipData" [age]="'all'">
                            </app-age-analysis-tooltip>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>