import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { BriefDocumentHandle } from '../../../services/matters/matters.service';

@Component({
  selector: 'app-attonery-brief-preview-modal',
  templateUrl: './attonery-brief-preview-modal.component.html',
  styleUrls: ['./attonery-brief-preview-modal.component.scss']
})
export class AttoneryBriefPreviewModalComponent implements OnInit {

  title = 'Documents attached to invoice';
  // showDocumentToggle = 'Show all documents attached to matter';
  showAllBrief = false;
  @Input() isVisible: boolean;
  @Input() briefDocumentHandle: BriefDocumentHandle[];
  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();

  downloadLink = this.api.apiURL + '/MatterDocument?briefId=';
  constructor(
    private api: ApiService
  ) { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  dismiss() {
    this.closeModal.emit('close-brief-preview');
  } // end dismiss()

  confirm() {
    this.closeModal.emit('close-brief-preview');
  } // end confirm()

  getExtension(filename) {
    return filename.toString().split('.')[1];
  } // end getExtension()

} // end AttorneyBriefPreviewModalComponent{}
