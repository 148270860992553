import { Component, OnInit, Input } from '@angular/core';
import {
  UserProfileService,
  UserProfile
} from '../../../services/user-profile/user-profile.service';
import {
  OverviewContactDetails,
  DEFAULT_OVERVIEW_CONTACT_DETAILS,
  AdvocateOverviewServiceService
} from '../../../services/advocate-overview-service/advocate-overview-service.service';
import {
  BarSociety,
  ChambersService,
  Chamber,
  AdvocateChambers,
  DEFAULT_ADVOCATE_CHAMBERS
} from '../../../services/chambers/chambers.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit {

  private profile: UserProfile;
  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  meetingType: any;
  meetingFequency: any;
  chambers: Chamber[] = [];
  @Input() barSociety: BarSociety[];
  @Input() workingAdvocateChambers: AdvocateChambers;
  @Input() workingOverviewContactDetails: OverviewContactDetails;

  get internalAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator) &&
      !Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY)
      && Boolean(this.userProfileService.userProfile.serviceProviderID === 9091 ||
        this.userProfileService.userProfile.serviceProviderID === 29096 ||
        this.userProfileService.userProfile.serviceProviderID === 21647);
  } // end internalAssistant()

  get isPracticeManager(): boolean {
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  } // end isPracticeManager()

  constructor(
    private loadingService: LoadingService,
    private chambersService: ChambersService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private advocateOverviewService: AdvocateOverviewServiceService,
  ) { } // end constructor()

   ngOnInit() {
    if (this.workingAdvocateChambers && this.workingAdvocateChambers.BarSocietyId) {
      this.chambersService.getChambers(this.workingAdvocateChambers.BarSocietyId).subscribe({
        next: (v) => {
          this.chambers = v;
        },
        error: (error) => {

        },
        complete: () => {

        }
      });
    }
    // else {
    //   this.workingAdvocateChambers = Object.assign({}, DEFAULT_ADVOCATE_CHAMBERS);
    // }

    this.meetingType = [
      { label: 'Email', value: 'Email' },
      { label: 'Gmeet', value: 'Gmeet' },
      { label: 'In Person', value: 'In Person' },
      { label: 'None', value: 'None' },
      { label: 'Reports Only', value: 'Reports Only' },
      { label: 'Telephonic', value: 'Telephonic' },
    ];
    this.meetingFequency = [
      { label: 'By Month', value: 'By Month' },
      { label: 'Monthly', value: 'Monthly' },
      { label: 'None', value: 'None' },
      { label: 'Quarterly', value: 'Quarterly' },
    ];
    this.profile = this.userProfileService.selectedUserProfile;
    // this.loadingService.hideOverlay();
  } // end ngOnInit()

  parseDate(serviceDate: string): Date {
    if (serviceDate) {
      return new Date(serviceDate);
    } else {
      return new Date('1000-01-01');
    }
  } // end parseDate()

  onSelectedBar(event) {
    const bar = this.barSociety.filter(s => s.Id === Number(event))[0];
    this.workingAdvocateChambers.BarSocietyId = bar.Id;
    this.workingAdvocateChambers.BarSociety = bar.Name;
    this.chambersService.getChambers(event).subscribe({
      next: (data) => {
        this.chambers = data;
      },
      error: (error) => {
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.loadingService.hideOverlay();
      }
    })
      // data => {
      //   // On next
      //   this.chambers = data;
      // },
      // error => {
      //   // On error
      //   this.loadingService.hideOverlay();
      // },
      // () => {
      //   // On complete
      //   this.loadingService.hideOverlay();
      // });
  } // end onSelectedBar()

  onModelChange(type: string, bar: any) {
    switch (type) {
      case 'bar-society':
        if (this.workingAdvocateChambers.BarSocietyId) {
          this.chambersService.getChambers(this.workingAdvocateChambers.BarSocietyId).subscribe({
            next: (data) => {
              this.chambers = data;
            },
            error: (error) => {
              this.loadingService.hideOverlay();
            },
            complete: () => {
              this.loadingService.hideOverlay();
            }
          });
          
          // (
          //   data => {
          //     // On next
          //     this.chambers = data;
          //   },
          //   error => {
          //     // On error
          //   },
          //   () => {
          //     // On complete
          //     this.loadingService.hideOverlay();
          //   });
        }
      break;
    }
  } // end onModelChange()

  onSelectBarSociety(event: TypeaheadMatch): void {
    this.loadingService.showOverlay();
    this.workingAdvocateChambers.BarSocietyId = event.item.Id;
    this.workingAdvocateChambers.BarSociety = event.item.Name;
    this.chambersService.getChambers(event.item.Id).subscribe({
      next: (data) => {
        this.chambers = data;
      },
      error: (error) => {
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.loadingService.hideOverlay();
      }
    });
  } // end onSelectBarSociety()

  onSelectChamber(event: TypeaheadMatch): void {
    this.workingAdvocateChambers.ChamberId = event.item.Id;
    this.workingAdvocateChambers.Chamber = event.item.ChamberName;
    this.workingAdvocateChambers.Address = event.item.Address;
    this.workingAdvocateChambers.Email = event.item.Email;
    this.workingAdvocateChambers.Phone = event.item.Phone;
  } // end onSelectChamber()
} // end ngOnInit{}
