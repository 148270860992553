<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end modal-header -->

      <div class="modal-body form">

        <div class="page card">
          <div class="details-section">
            <p class="preview-title">{{previewTitle}}</p>
          </div>

          <div class="contact-details details-section">
            <div class="contact-details-column column-left">
              <p class="supplier-name">{{invoiceDetails.SupplierName}}</p>
              <!-- <p>{{invoiceDetails.SupplierStreetAddress}}</p> -->
              <textarea
                class="form-control-address"
                type="text"
                id="address"
                [(ngModel)]="invoiceDetails.SupplierStreetAddress"
                readonly></textarea>
            </div>

            <div class="contact-details-column column-right">
              <p>
                <span class="heading">Invoice Date:</span>
                <span>
                  <input 
                    type="text" 
                    bsDatepicker 
                    [value]="parseDate(invoiceDetails.InvoiceDate) | date:'yyyy-MM-dd'" 
                    (ngModel)="invoiceDetails.InvoiceDate=$event.target.value"
                    (bsValueChange)="onDateChange(invoiceDetails, $event)" 
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                    [class.disabled]="invoiceDetails.InvoiceNo !== 'AUX000000' && invoiceDetails.InvoiceNo !== null && invoiceDetails.InvoiceDate !== null"
                    [disabled]="true"></span>
              </p>
              <p
                *ngIf="hasInvoiceDateLessThanError" 
                class="text-danger">The invoice date cannot be before the last line date.</p>
              <p
                *ngIf="isFutureDated" 
                class="text-danger">The system does not allow future dating of Invoices.</p>
              <p><span class="heading">Invoice No:</span><span>{{invoiceDetails.InvoiceNo}}</span></p>
              <br>
              <p><span class="heading">Cell:</span><span>{{invoiceDetails.SupplierCell}}</span></p>
              <p><span class="heading">Email:</span><span>{{invoiceDetails.SupplierEmail}}</span></p>
              <p><span class="heading">Tel:</span><span>{{invoiceDetails.SupplierPhone}}</span></p>
              <p><span class="heading">Fax:</span><span>{{invoiceDetails.SupplierFax}}</span></p>
              <p><span class="heading">VAT No:</span> <span>{{invoiceDetails.SupplierVATNo}}</span></p>
            </div>
          </div><!--#end .details-section -->

          <div class="client-details details-section">
            <div class="client-details-column column-left">
              <p class="heading">Invoice To:</p>
              <!-- <p>{{invoiceDetails.ClientName}}</p> -->
              <p [innerHTML]="formatContactDetails(invoiceDetails.ClientContactDetailsEng)"></p>
              <p>&nbsp;</p>
              <p class="heading">Client Address</p>
              <textarea
                class="form-control-address-client"
                type="text"
                id="address"
                [(ngModel)]="invoiceDetails.ClientStreetAddress"
                readonly></textarea>
              <p>&nbsp;</p>
              <p class="heading">VAT No: {{invoiceDetails.ClientVATNo}}</p>
            </div>
            <div class="client-details-column column-right">
              <p><span class="heading">IN RE:</span><textarea wrap="soft" [title]="invoiceDetails.InvoiceParties" readonly>{{invoiceDetails.InvoiceParties}}</textarea></p>
              <p><span class="heading">REFERENCE:</span><input [title]="invoiceDetails.InvoiceReference" [value]="invoiceDetails.InvoiceReference" readonly></p>
            </div><!--#end .client-details-column -->
          </div><!--#end .client-details.details-section -->

          <div class="details-section details-section-solid">
            <div class="table">
              <div class="table-head">
                <div class="table-row">
                  <span class="column-type">Item</span>
                  <span class="column-date">Date</span>
                  <span class="column-description">Description</span>
                  <span class="column-quantity">Hours/Qty</span>
                  <span class="column-rate">Rate</span>
                  <span class="column-amount">Amount</span>
                </div>
              </div><!--#end .table-head -->
              <div class="table-body">
                <div *ngFor="let line of invoiceDetails.InvoiceLines; index as i" class="table-row">
                  <span class="column-type">{{i + 1}}</span>
                  <span class="column-date">{{line.Date | date:'yyyy/MM/dd'}}</span>
                  <span
                    class="column-description"
                    [title]="line.Description">{{line.Description}}</span>
                  <span class="column-quantity">{{line.Quantity | number:'1.0-2'}}</span>
                  <span class="column-rate">{{line.Rate | currency:currencySymbol}}</span>
                  <span class="column-amount">{{line.Total | currency:currencySymbol}}</span>
                </div>
              </div><!--#end .table-body -->
            </div><!--#end .table -->
          </div><!--#end .details-section -->

          <div class="details-section summary-details">
            <div class="details-section-column column-left">
              <div class="form-group">
                <label for="notes">Notes / Memorandum</label>
                <textarea
                  #notes
                  id="notes"
                  placeholder=""
                  [(ngModel)]="invoiceDetails.InvoiceNote"
                  [autofocus]
                  [disabled]="true"></textarea>
              </div><!--#end .form-group -->
            </div>

            <div class="details-section-column"></div>

            <div class="details-section-column column-right">
              <p>&nbsp;</p>
              <div class="invoice-line-summary">

                <div class="line-summary-row">
                  <span class="line-summary-label">Sub-Total:</span>
                  <span class="line-summary-value">
                    {{invoiceDetails.InvoiceTotalExcl | currency:currencySymbol}}
                  </span>
                </div><!--#end .line-summary-row -->
              
                <div class="line-summary-row">
                  <span class="line-summary-label">VAT:</span>
                  <span class="line-summary-value">
                    {{invoiceDetails.InvoiceVAT | currency:currencySymbol}}
                  </span>
                </div><!--#end .line-summary-row -->
              
                <div class="line-summary-row">
                  <span class="line-summary-label">Total:</span>
                  <span class="line-summary-value">
                    {{invoiceDetails.InvoiceTotal | currency:currencySymbol}}
                  </span>
                </div><!--#end .line-summary-row -->
              
              </div><!--#end .invoice-line-summary -->
            </div>
          </div><!--#end .details-section -->

        </div><!--#end .page -->

      </div> <!-- #end modal-body form-->
    </div> <!-- #end modal-dialog-->
  </div> <!-- #end modal-content-->
</div> <!-- #end modal-->
