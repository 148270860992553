<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <button class="modal-dismiss" (click)="onCancel()">
          <i class="fa fa-close"></i>
        </button>
        <h4>{{title}}</h4>
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">
        <div class="row first-pane">
          <div class="table-responsive" style="max-height: 200px;">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <th class="w-9" scope="col">Invoice Date</th>
                  <th class="w-8" scope="col">Invoice No</th>
                  <th class="w-20" scope="col">Matter</th>
                  <th class="w-15" scope="col">Law firm</th>
                  <th class="w-20" scope="col">Attorney Ref.</th>
                  <th class="w-9" scope="col">Due</th>
                  <th class="w-9" scope="col">Paid</th>
                  <th class="w-9" scope="col">Invoice Total</th>
                  <th class="w-1" scope="col"></th>
                </tr>
                <tr *ngFor="let data of multiInvoiceNote">
                  <td class="w-9" [ngStyle]="{'color': getRemovedDataColor(data)}" scope="row">{{data.InvoiceDate | date:'yyyy-MM-dd'}}</td>
                  <td class="w-8" [ngStyle]="{'color': getRemovedDataColor(data)}" class="clickable" (click)="onViewInvoice(data)" scope="row"><u><a>{{data.InvoiceNo}}</a></u></td>
                  <td class="w-20" [ngStyle]="{'color': getRemovedDataColor(data)}" scope="row">{{data.Matter}}</td>
                  <td class="w-15" [ngStyle]="{'color': getRemovedDataColor(data)}" scope="row">{{data.Attorney}}</td>
                  <td class="w-20" [ngStyle]="{'color': getRemovedDataColor(data)}" scope="row">{{data.AttorneyRef}}</td>
                  <td class="w-9" [ngStyle]="{'color': getRemovedDataColor(data)}" scope="row">{{data.Due | currency:currencySymbol}}</td>
                  <td class="w-9" [ngStyle]="{'color': getRemovedDataColor(data)}" scope="row">{{data.Paid | currency:currencySymbol}}</td>
                  <td class="w-9" [ngStyle]="{'color': getRemovedDataColor(data)}" scope="row">{{data.InvoiceTotal | currency:currencySymbol}}</td>
                  <td class="w-1" scope="row">
                    <i *ngIf="(data.PracticeManagerStatus === 'Active' && data.AdvocateStatus === 'Removed') || noteDetailData.Id === 0" class="far fa-minus-circle clickable" (click)="onInvoiceRemove(data)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> <!-- #end .row .first-pane -->

        <!-- Chat Conversation -->
        <div class="panel-body">
          <ul class="chat">
            <li class="left clearfix" *ngIf="noteDetailData && noteDetailData.InternalText">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header" *ngIf="!isAdHocCollections">
                  <strong class="primary-font">{{noteDetailData.UserOwnerFullName}}</strong>
                  <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Collector note
                  </small>
                </div>
                <div class="header" *ngIf="isAdHocCollections">
                  <strong class="primary-font">{{noteDetailData.UserAssignedFullName}}</strong>
                  <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Advocate note
                  </small>
                </div>
                <p>{{noteDetailData.QuestionText}} {{noteDetailData.ExternalText}}</p>
                <p>{{noteDetailData.InternalText}}</p>
              </div>
            </li>
            <!-- <li class="left clearfix" *ngIf="noteDetailData && noteDetailData.InternalText">
                      <span style="margin-left: 7px;" class="chart-img pull-left">
                        <i class="fas fa-user-circle"></i>
                      </span>
                      <div class="chat-body clearfix">
                        <div class="header">
                          <strong class="primary-font">{{noteDetailData.UserCreatorFullName}}</strong> <small class="text-muted">
                            <span class="glyphicon glyphicon-time"></span> Collector
                          </small>
                        </div>
                        <p>{{noteDetailData.InternalText}}</p>
                      </div>
                    </li> -->
            <li class="left clearfix" *ngIf="noteDetailData && (noteDetailData.ExternalText || noteDetailData.QuestionId > 0)">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{noteDetailData.UserCreatorFullName}}</strong> <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Practice Manager
                  </small>
                </div>

              </div>
            </li>
            <li class="left clearfix" *ngIf="noteDetailData && noteDetailData.ResponseText">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{noteDetailData.UserAssignedFullName}}</strong> <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Advocate
                  </small>
                </div>
                <p>{{noteDetailData.ResponseText}} {{noteDetailData.InstructionText}}</p>
              </div>
            </li>
            <li class="left clearfix"
              *ngIf="noteDetailData && noteDetailData.InstructionResponseId && noteDetailData.ResolutionText">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{noteDetailData.UserOwnerFullName}}</strong> <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Practice Manager
                  </small>
                </div>
                <p>{{noteDetailData.ResolutionText}}</p>
              </div>
            </li>
          </ul> <!-- #end .chat -->
        </div> <!-- #end .panel-body -->
      </div> <!-- #end .modal-body .form -->

      <div class="modal-footer"
        *ngIf="!noteDetailData.UserOwnerFullName || noteDetailData.InstructionResponseId > 0 && noteDetailData.ResponseText || isAdHocRequest === true">
        <div class="well well-fluid">
          <div class="row" style="padding-top: 0; padding-bottom: 0;"
            *ngIf="noteDetailData.InstructionResponseId === 0 && !noteDetailData.ResponseText || isAdHocRequest === true">
            <div class="row" style="padding-top: 0; padding-bottom: 0; width: 100%;" *ngIf="collectorNewChecked === true || isAdHocRequest === true">
              <!-- <div class="control-group"> -->
                <label for="action">Action</label>
                <input
                  style="width: 80%;"
                  [(ngModel)]="selectedResponseText"
                  [title]="selectedResponseText"
                  [typeahead]="instructionResponses"
                  typeaheadOptionField="Response"
                  [typeaheadSingleWords]="false"
                  placeholder="Select or start typing"
                  (input)="onInputResponse($event)"
                  (typeaheadOnSelect)="onNoteResponseSelect($event)"
                  [typeaheadOptionsLimit]="50"
                  typeaheadScrollable=true
                  typeaheadOptionsInScrollableView="10"
                  [typeaheadMinLength]="0"
                  [dropup]="false">
                  <i class="fa fa-question-circle hover-text" style="color: #0066ff !important; font-size: 21px;" aria-hidden="true">
                    <span class="tooltip-text left">Select an action on how you would like the Practice Manager to proceed. You can also include further information.</span>
                </i>
              <!-- </div> -->
            </div>
            <div class="form-control" style="border:0; background-color: transparent;" *ngIf="advocateNewChecked === true && !isAdHocRequest === true">
              <div class="control-group">
                <label for="category">Note Type</label>&nbsp;
                <input
                  [(ngModel)]="selectedNoteCategoryText"
                  [typeahead]="noteCategory"
                  typeaheadOptionField="Category"
                  [typeaheadSingleWords]="false"
                  placeholder="Select or start typing"
                  (input)="onInputCategory($event)"
                  (typeaheadOnSelect)="onNoteCategorySelect($event)"
                  [typeaheadOptionsLimit]="50"
                  typeaheadScrollable=true
                  typeaheadOptionsInScrollableView="5"
                  [typeaheadMinLength]="0"
                  [dropup]="false">
              </div>
              <div class="control-group">
                <label for="sub-category">Note Detail</label>&nbsp;
                <input
                  [(ngModel)]="selectedNoteSubCategoryText"
                  [typeahead]="noteSubCategoryList"
                  typeaheadOptionField="Name"
                  [typeaheadSingleWords]="false"
                  placeholder="Select or start typing"
                  (input)="onInputSubCategory($event)"
                  (typeaheadOnSelect)="onNoteSubCategorySelect($event)"
                  [typeaheadOptionsLimit]="50"
                  typeaheadScrollable=true
                  typeaheadOptionsInScrollableView="5"
                  [typeaheadMinLength]="0"
                  [dropup]="false">
              </div>
              <div class="control-group date-control" *ngIf="selectedNoteSubCategory && selectedNoteSubCategory.Diary">
                <label for="date-to">Attribute</label>&nbsp;
                <input
                  readonly
                  type="text"
                  id="date-to"
                  class="input-date"
                  #_dateTo="bsDatepicker"
                  bsDatepicker
                  placeholder="2019-10-01"
                  placement="top"
                  (bsValueChange)="onDateValueChange($event)"
                  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
                <i class="fa fa-calendar" [attr.aria-expanded]="_dateTo.isOpen"></i>
              </div>
            </div>
          </div>
          <div class="row" style="padding-top: 0; padding-bottom: 0;"
            *ngIf="selectedNoteSubCategory && selectedNoteSubCategory.InstructionQuestion && noteDetailData.InstructionResponseId === 0 && !noteDetailData.ResponseText">
            <div class="form-control" style="border:0; background-color: transparent;">
              <div class="control-group" style="width: 100%;">
                <label style="width: 20%;" for="question">Instruction Question:</label>&nbsp;
                <input style="width: 78%;" readonly [ngModel]="selectedNoteSubCategory.InstructionQuestion">
                <!-- <label>{{selectedNoteSubCategory.InstructionQuestion}}</label> -->
              </div>
            </div>
          </div>
          <!-- </div>
                    <div class="well well-fluid"> -->
          <div class="row" style="padding-top: 0; padding-bottom: 0;"
            *ngIf="(noteDetailData.InstructionResponseId === 0 && !noteDetailData.ResponseText && isInternalChecked)">
            <div class="form-control" style="border:0; background-color: transparent;">
              <div class="control-group" style="width: 100%;">
                <label style="width: 98px;" for="message">Personal Note</label>&nbsp;
                <!-- <textarea style="width: 100%; height: 28px;" class="input-control" placeholder="Add Note Message"
                  [(ngModel)]="internalText"></textarea> -->

                  <textarea
                  style="width: 100%;"
                  *ngIf="advocateChecked === true && noteDetailData.InstructionResponseId === 0 && !noteDetailData.ResponseText"
                  class="input-control"
                  placeholder="Add Message to request an instruction from Advocate"
                  [(ngModel)]="internalText"></textarea>

                  <textarea
                  style="width: 100%;"
                  *ngIf="noteDetailData.QuestionId > 0 && collectorChecked === true"
                  class="input-control"
                  placeholder="Add an Instruction Message"
                  [(ngModel)]="instructionText"></textarea>
              </div>
            </div>
          </div>
          <div class="row" style="padding-top: 0; padding-bottom: 0;"
            *ngIf="(noteDetailData.InstructionResponseId === 0 && !noteDetailData.ResponseText) ||
                    (noteDetailData.InstructionResponseId > 0 && noteDetailData.ResponseText && !noteDetailData.ResolutionText)">
            <div class="form-control" style="border:0; background-color: transparent;">
              <div class="control-group" style="width: 90%;">
                <label for="message">Message</label>&nbsp;
                <textarea
                  style="width: 100%;"
                  *ngIf="noteDetailData.InstructionResponseId === 0 && !noteDetailData.ResponseText"
                  class="input-control"
                  placeholder="Add Note Message"
                  [(ngModel)]="internalExternalText"></textarea>
                <textarea
                  style="width: 100%;"
                  *ngIf="noteDetailData.InstructionResponseId > 0 && noteDetailData.ResponseText && !noteDetailData.ResolutionText"
                  class="input-control"
                  placeholder="Add Note Message"
                  [(ngModel)]="resolutionText"></textarea>
              </div>
              <!-- </div>
                        <div class="form-control"> -->
              <div class="option-groups" style="margin-right: 5px;"
                *ngIf="noteDetailData && noteDetailData.InstructionResponseId === 0">
                <div class="visibility-group">
                  <div class="">
                    <div class="control-group" style="white-space: nowrap;">
                      <label for="table">Issue an Instruction </label>&nbsp;
                      <app-small-radiobox
                        [(checked)]="collectorNewChecked"
                        (click)="toggleNewColAdvCheckbox('collector')">
                      </app-small-radiobox>
                    </div>
                  </div>
                  <div class="">
                    <div class="control-group" style="white-space: nowrap;">
                      <label for="table">Request Instruction </label>&nbsp;
                      <app-small-radiobox
                        [(checked)]="advocateNewChecked"
                        (click)="toggleNewColAdvCheckbox('advocate')">
                      </app-small-radiobox>
                    </div>
                  </div>
                </div>
                <!-- <div class="visibility-group">
                  <div class="text-center">
                    <label for="visibility">Visibility</label>
                  </div>
                  <div class="text-center">
                    <i class="far fa-question-circle"></i>
                  </div>
                </div>
                <div class="visibility-group">
                  <div class="">
                    <div class="control-group">
                      <label for="table">Internal </label>&nbsp;
                      <app-small-radiobox [(checked)]="isInternalChecked" (click)="toggleIntExtCheckbox('internal')">
                      </app-small-radiobox>
                    </div>
                  </div>
                </div> -->
              </div>
              <!-- <div class="form-control"> -->
                <div class="form-control" style="width: auto;display: grid;">
                  <button
                    (click)="onCancel()"
                    style="height: auto; margin-bottom: 5px;"
                    class="btn btn-danger">Cancel</button>
                    <button
                      style="height: auto;"
                      *ngIf="(!noteDetailData || noteDetailData.Id === 0) && advocateNewChecked === true"
                      (click)="onAddNote()"
                      class="btn btn-success">ADD NOTE</button>
                    <button
                      style="height: auto;"
                      *ngIf="(noteDetailData && noteDetailData.Id > 0 && !noteDetailData.ResolutionText)  && advocateNewChecked === true"
                      (click)="onResolutionSave()"
                      class="btn btn-success">ADD R NOTE</button>
                    <button
                      style="height: auto;"
                      [class.disabled]="!isSaveable"
                      [disabled]="!isSaveable"
                      *ngIf="collectorNewChecked === true"
                      (click)="onIssueAnInstructionRequest()"
                      class="btn btn-success">{{InstructionRequestButtonText}}</button>
                  </div>
              <!-- <button style="height: auto;" *ngIf="!noteDetailData || noteDetailData.Id === 0" (click)="onAddNote()"
                class="btn btn-success">ADD NOTE</button>
              <button style="height: auto;" *ngIf="noteDetailData && noteDetailData.Id > 0 && !noteDetailData.ResolutionText"
                (click)="onResolutionSave()" class="btn btn-success">ADD NOTE</button> -->
            </div>
          </div>
        </div>
      </div> <!-- #end .modal-footer -->

    </div> <!-- #end .modal-dialog-->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->

<app-invoice-preview-modal
  *ngIf="showProformaInvoice"
  #invoicePreview
  [selectedTab]="selectedTab"
  [notesHandle]="selectedNotesHandle"
  [plaintiff]="invoicePlaintiff"
  [selectedTab]="selectedTab"
  [defendant]="invoiceDefendant"
  [invoiceId]="selectedInvoiceId"
  [invoiceDetails]="invoiceDetails"
  [isVisible]="showProformaInvoice"
  (closeModal)="closeModal('invoice')">
</app-invoice-preview-modal>
