import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { catchError, retry } from 'rxjs/operators';

export interface Advocates {
  AdvocateId: number;
  Advocate: string;
  City: string;
  State: string;
  Identity: string;
  UserName: string;
  Level: string;
  CellPhone: string;
  WorkPhone: string;
  Fax: string;
  Email: string;
  VATNo: string;
  OfficeRoom: string;
  StreetAddress: string;
  PostalAddress: string;
}

export const DEFAULT_ADVOCATES: Advocates = {
  AdvocateId: 0,
  Advocate: '',
  City: '',
  State: '',
  Identity: '',
  UserName: '',
  Level: '',
  CellPhone: '',
  WorkPhone: '',
  Fax: '',
  Email: '',
  VATNo: '',
  OfficeRoom: '',
  StreetAddress: '',
  PostalAddress: '',
};

@Injectable()
export class AdvocatesService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  getAdvocates(): Observable<any> {
    return this.http.get(this.api.endpoints.advocate,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getAdvocates()
} // end AdvocatesService{}
