<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">
            <div class="modal-header">
                <h2>{{title}}</h2>
                <button
                    class="modal-dismiss"
                    (click)="onDismiss()">
                    <i class="fa fa-close"></i>
                </button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="form-group">
                        <label for="firm-name">Law Firm Name <span title="required field"> *</span> </label>
                        <input
                            type="text"
                            class="form-control"
                            [class.has.error]="hasFirmName"
                            id="firm-name"
                            [(ngModel)]="workingLawfirm.FullName"
                            placeholder="Enter Law Firm name"
                            (click)="$event.target.select()"
                            autocomplete="nocxua"
                            [disabled] = "!isManageBlacklistUser ? 'disabled' : null" >
                        <span  
                            *ngIf="hasFirmName"
                            class="text-danger">Please enter a valid Firm Name</span>
                    </div>
                    <div class="form-group">
                        <label for="locality">Locality<span title="required field"> *</span></label>
                        <app-datalist-localities
                            type="localities"
                            class="form-control"
                            [contactLocalities]="contactLocalities"
                            [(selectedLocality)]="selectedLocality"
                            [(isManageBlacklistUser)]="isManageBlacklistUser"
                            (select)="onModelChange('localities', $event)">
                        </app-datalist-localities>
                    </div>
                    <div class="form-group">
                        <label for="phone">Phone<span title="required field"> *</span></label>
                        <input
                            type="text"
                            class="form-control"
                            [class.has.error]="hasPhone"
                            id="phone"
                            (blur)="onPhoneNumberBlur()"
                            [(ngModel)]="workingLawfirm.Phone"
                            placeholder="Enter Phone number"
                            (click)="$event.target.select()"
                            autocomplete="nocxua"
                            [disabled] = "!isManageBlacklistUser ? 'disabled' : null">
                    </div>
                </div>

                <div class="row">
                    <div class="form-group">
                        <label for="email">Email<span title="required field"> *</span></label>
                        <input
                            type="text"
                            class="form-control"
                            [class.has.error]="hasEmail"
                            id="email"
                            [(ngModel)]="workingLawfirm.Email"
                            placeholder="Enter Email address"
                            (click)="$event.target.select()"
                            autocomplete="nocxua"
                            [disabled] = "!isManageBlacklistUser ? 'disabled' : null">
                    </div>
                </div>
                
                <div class="row">
                    <div class="form-group">
                        <label for="cell">Cell</label>
                        <input
                            type="text"
                            class="form-control"
                            id="cell"
                            [(ngModel)]="workingLawfirm.Cell"
                            placeholder="Enter Cell number"
                            (click)="$event.target.select()"
                            autocomplete="nocxua"
                            [disabled] = "!isManageBlacklistUser ? 'disabled' : null">
                    </div>
                    <div class="form-group">
                        <label for="fax">Fax</label>
                        <input
                            type="text"
                            class="form-control"
                            id="fax"
                            [(ngModel)]="workingLawfirm.Fax"
                            placeholder="Enter Fax number"
                            (click)="$event.target.select()"
                            autocomplete="nocxua"
                            [disabled] = "!isManageBlacklistUser ? 'disabled' : null">
                    </div>
                    <div class="form-group">
                        <label for="vat">VAT</label>
                        <input
                            type="text"
                            class="form-control"
                            id="vat"
                            [(ngModel)]="workingLawfirm.Vatno"
                            placeholder="Enter VAT number"
                            (click)="$event.target.select()"
                            autocomplete="nocxua"
                            [disabled] = "!isManageBlacklistUser ? 'disabled' : null">
                    </div>
                </div>

                <div class="row">
                    <div class="form-group">
                        <label for="postal-address">Postal Address</label>
                        <textarea
                            type="text"
                            id="postal-address"
                            class="address-form"
                            placeholder="Law Firm Postal Address"
                            [(ngModel)]="workingLawfirm.PostalAddress"
                            [disabled] = "!isManageBlacklistUser ? 'disabled' : null"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="street-address">Street Address<span title="required field"> *</span></label>
                        <textarea
                            type="text"
                            id="street-address"
                            class="address-form"
                            placeholder="Law Firm Street Address"
                            [(ngModel)]="workingLawfirm.StreetAddress"
                            [disabled] = "!isManageBlacklistUser ? 'disabled' : null"></textarea>
                    </div>
                </div>

            </div>

            <div class="modal-footer">
                <button
                class="btn btn-danger"
                (click)="onDismiss()">Cancel</button>

                <button
                    *ngIf="this.selectedLawfirm && this.selectedLawfirm.Id === 0"
                    [class.disabled]="!canSaveFirm"
                    [disabled]="!canSaveFirm"
                    class="btn btn-success"
                    (click)="save()">Save</button>
                
                <button
                    *ngIf="this.selectedLawfirm && this.selectedLawfirm.Id && isManageBlacklistUser"
                    class="btn btn-success"
                    (click)="update()">Update</button>
            </div>

        </div>
    </div>
</div>

<app-prompt-unsaved-changes-modal
  title="Contact already exist"
  [message]="message"
  [isVisible]="showPhoneNumberAlreadyExists"
  (cancel)="onHidePhoneNumberAlreadyExists()"
  btnTextCancel="Cancel"
  btnTextContinue="Ok"
  (continue)="continue()"></app-prompt-unsaved-changes-modal>
