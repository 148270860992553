<div *ngIf="promptInvoices && !selectedTransaction.ExcludedFlag" class="row invoices-container">
    <div class="row invoices-header">
       
    </div>
    <div class="row invoices-body">
        <div class="text-center p-50">
            <b style="color:#cf624f; font-weight:900;">No auto-matched invoices were found for this Bank transaction.
            </b>
        </div>
    </div>

    <div class="row invoices-footer">
      

        <div class="col-sm-5 offset-sm-7 p-0">
            <button *ngIf="profile.serviceProviderID !== 29723" class="form-control cursor btn" (click)="moveInvoices()">Remove from Processed</button>
        </div>
    </div>
</div>
<div *ngIf="promptInvoices && selectedTransaction.ExcludedFlag" class="row invoices-container">
    <div class="row invoices-header">
       <b>Reason for Payment Exclusion</b>
    </div>
    <div class="row invoices-body">
        <div class="text-center p-50" style="width: 97%">
            <table class="table-responsive">
                <tbody>
                    <!-- <tr> -->
                        <!-- <th style="text-align: left; width: 20%;">Date</th> -->
                        <!-- <th style="text-align: left; width: 80%;">Reason</th> -->
                    <!-- </tr> -->
                    <tr>
                        <!-- <td style="text-align: left; width: 20%">{{selectedTransaction.Date | date:'yyyy-MM-dd'}}</td> -->
                        <td style="text-align: left; width: 80%">{{selectedTransaction.Reason}}</td>
                    </tr>
                </tbody>
            </table>
            <!-- <b *ngIf="!selectedTransaction.ExcludedFlag" style="color:#cf624f; font-weight:900;">No auto-matched invoices were found for this Bank transaction.
            </b>
            <b *ngIf="selectedTransaction.ExcludedFlag" style="color:#cf624f; font-weight:900;">Reason for Exclusion:   ""</b>
            <br> -->
        </div>
    </div>

    <div class="row invoices-footer">
      

        <div class="col-sm-5 offset-sm-7 p-0">
            <button *ngIf="profile.serviceProviderID !== 29723" class="form-control cursor btn" (click)="moveInvoices()">Remove from Processed</button>
        </div>
    </div>
</div>

<!-- Save Proof of Payment -->
<div *ngIf="promptPaidInvoices" class="row invoices-container">
    <div class="row invoices-header">
        Invoices added to bank transaction “{{bankTransaction.TransactionDescription}}”
    </div>
    <div class="row invoices-body">
        <div class="table-responsive">
            <table class="table ">
                <tbody>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Invoice</th>
                        <th scope="col">Law Firm</th>
                        <th scope="col">Matter</th>
                        <th scope="col">Reference</th>
                        <th scope="col" style="width: 5%;"></th>
                        <th scope="col" class="text-right">Amount</th>
                        <th scope="col" class="text-right ">Paid</th>
                    </tr>
                    <tr *ngFor="let invoice of invoices">
                        <td [title]="invoice.InvoiceDate | date:'yyyy-MM-dd'">{{invoice.InvoiceDate | date:'yyyy-MM-dd'}}</td>
                        <td><u [title]="invoice.Invoice" class="clickable" (click)="onViewInvoice(invoice.InvoiceId)">{{invoice.Invoice}}</u></td>
                        <td [title]="invoice.LawFirm" class="text-truncate">{{invoice.LawFirm}}</td>
                        <td [title]="invoice.Matter" class="text-truncate">{{invoice.Matter}}</td>
                        <td [title]="invoice.Reference" class="text-truncate">{{invoice.Reference}}</td>
                        <td style="width: 5%;">
                            <a *ngIf="invoice.AdHocCollection === true" title="Ad-Hoc assistance requested">
                                <i class="fa fa-money" style="font-size: 14px !important;"></i>
                            </a>
                        </td>
                        <td [title]="invoice.Amount | currency:currencySymbol" class="text-right">{{invoice.Amount | currency:currencySymbol}}</td>
                        <td [title]="invoice.Paid | currency:currencySymbol" class="text-right">{{invoice.Paid | currency:currencySymbol}}</td>
                    </tr>
                    <tr>
                        <th scope="col" colspan="3">{{bankTransaction.Category}}</th>
                        <th scope="col"></th>
                        <th scope="col"> </th>
                        <th scope="col"></th>
                        <th scope="col" style="font-weight: 900;">Total:</th>
                        <th scope="col" colspan="2" class="text-right">{{totalAmount | currency:currencySymbol}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row invoices-footer pr-0">
        <div class="col-sm-5 offset-sm-7 p-0">
            <button *ngIf="profile.serviceProviderID !== 29723" class="form-control btn" (click)="moveInvoices()">Remove from Processed</button>
        </div>
    </div>
</div>

<app-prompt-unsaved-changes-modal
    title="Remove from processed"
    message="Are you sure you want to move this transaction back to “New Bank Transactions”"
    [isVisible]="showProcessedModel"
    btnTextCancel="Cancel"
    btnTextContinue="YES, MOVE"
    (cancel)="onHideProcessedModel()"
    (continue)="onMoveTransaction()">
</app-prompt-unsaved-changes-modal>

<!-- <app-proforma-invoice
    #proformaInvoice
    title="Invoice" 
    popId="0" 
    [invoiceId]="selectedInvoiceId" 
    documentType="Invoice"
    matter="null" 
    [isVisible]="showProformaInvoice" 
    (closeModal)="closeModal('proforma-invoice')">
</app-proforma-invoice> -->

<app-invoice-preview-modal 
    *ngIf="showProformaInvoice" 
    #invoicePreview 
    [plaintiff]="invoicePlaintiff"
    [defendant]="invoiceDefendant" 
    [invoiceId]="selectedInvoiceId" 
    [invoiceDetails]="invoiceDetails"
    [isVisible]="showProformaInvoice" 
    (closeModal)="closeModal('invoice')"></app-invoice-preview-modal>