<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-header">
                <h2>{{title}}</h2>
                <button class="modal-dismiss" (click)="onCancel()">
                    <i class="fa fa-close"></i>
                </button>
            </div>

            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <select name="bank-accounts" id="bank-account" class="form-control" [(ngModel)]="selectedBankAccountHandle.AccountNo" (change)="onChangeBankAccount(selectedBankAccountHandle)">
                            <option *ngFor="let account of bankAccountHandle" [value]="account.data.AccountNumber">
                                {{ account.data.Bank }} - {{ account.data.AccountNumber }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn btn-danger" (click)="onCanel()">Cancel</button>
                <button class="btn btn-success" (click)="onContinue()">Ok</button>
            </div>

        </div>
    </div>
</div>