import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from '../../services/navigation/navigation.service';

@Injectable()
export class NavigationGuard implements CanActivate {

  constructor(
    private navigation: NavigationService
  ) {} // end constructor()

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.navigation.canNavigate;
  } // end canActivate()
} // end NavigationGuard{}
