import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import { AdvocateDetailLineItem, AdvocateDetailLineItemHandle, AdvocateDetailStatement,
  AdvocateDetailStatementService,
  DEFAULT_ADVOCATE_DETAIL_LINE_ITEM,
  DEFAULT_ADVOCATE_DETAIL_STATEMENT,
  DEFAULT_DISPUTE_STATUSES,
  DisputeStatuses,
  LawfirmPaidStatement,
  LawfirmPaidStatementHandle,
  QueryReport} from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PATH_ADVOCATE_DETAIL, PATH_UNPAID_INVOICES } from '../../../services/appdata/app-config.service';
import { PaymentDetailService, PaymentDetail } from '../../../services/payment-detail/payment-detail.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Snack, SnackbarsService, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { ExcelServiceService } from '../../../services/excel-service/excel-service.service';
import * as moment from 'moment';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
// import { TypeaheadMatch } from 'ngx-bootstrap';
import { ApiService } from '../../../services/api.service';
import { AllocatedToLawfirm, AllocatedToLawfirmHandle } from '../../../services/reports/reports.service';
import { HttpClient } from '@angular/common/http';
import { InvoiceDetails, InvoiceDetailsService } from '../../../services/invoice-details/invoice-details.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { BriefDocuments, BriefDocumentHandle, MattersService } from '../../../services/matters/matters.service';
import { AttorneysService } from '../../../services/attorneys/attorneys.service';
import { ClientContact } from '../../../services/client-contacts/client-contacts.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { IndexedDBService } from '../../../services/indexed-db/indexed-db.service';

const FIELD_DATE          = 'date';
const FIELD_INVOICE_NO    = 'invoice-no';
const FIELD_REFERENCE     = 'reference';
const FIELD_DAYS_OUTSTANDING = 'days-outstanding';
const FIELD_MATTER        = 'matter';
const FIELD_ATTORENY      = 'attorney';
const FIELD_ADVOCATE      = 'advocate';
const FIELD_TOTAL         = 'total';
const FIELD_PAID          = 'paid';
const FIELD_DUE           = 'due';
const FIELD_AGING = 'aging';
const FIELD_TRANSACTION_AMOUNT  = 'transaction-amount';

// Transactions
const FIELD_TRANS_DATE        = 'trans-date';
const FIELD_TRANS_ADVOCATE    = 'trans-advocate';
const FIELD_TRANS_DESCRIPTION = 'trans-description';
const FIELD_TRANS_AMOUNT      = 'trans-amount';

// Invoices
const FIELD_INV_DATE          = 'inv-date';
const FIELD_INV_MATTER          = 'inv-matter';
const FIELD_INV_REFERENCE          = 'inv-reference';
const FIELD_INV_ATTORNEY          = 'inv-attorney';
const FIELD_INV_INVOICE_NO          = 'inv-invoice-no';
const FIELD_INV_DUE          = 'inv-due';
const FIELD_INV_PAID          = 'inv-paid';
const FIELD_INV_TOTAL          = 'inv-total';

interface AdvocateStatementHandle {
  data: AdvocateDetailStatement;
  state: {
    isVisible: boolean;
    isExpanded: boolean;
  };
}

interface AdvocateExcelStatement {
  LawFirm: string;
  Advocate: string;
  InvoiceDate: string;
  InvoiceNo: string;
  Matter: string;
  LawFirm_Reference: string;
  Total: string;
  Paid: string;
  Due: string;
  Aging: number;
} // end AdvocateExcelStatement{}

interface StatementDateParameters {
  fromDate: string;
  toDate: string;
}

const DEFAULT_STATEMENT_PARAMETERS: StatementDateParameters = {
  fromDate: '',
  toDate: ''
};

const DEFAUL_ADVOCATE_EXCEL_STATEMENT:  AdvocateExcelStatement = {
  LawFirm: '',
  Advocate: '',
  InvoiceDate: '',
  InvoiceNo: '',
  Matter: '',
  LawFirm_Reference: '',
  Total: '',
  Paid: '',
  Due: '',
  Aging: 0
}; // end DEFAUL_ADVOCATE_EXCEL_STATEMENT{}

const formatter = new Intl.NumberFormat('en-ZA', {
  style: 'currency',
  currency: 'ZAR',
  minimumFractionDigits: 2
});

interface Advocate {
  FullName: string;
  ContactID: number;
}

@Component({
  selector: 'app-advocate-detail-table',
  templateUrl: './advocate-detail-table.component.html',
  styleUrls: ['./advocate-detail-table.component.scss']
})
export class AdvocateDetailTableComponent implements OnInit, OnChanges, OnDestroy {

  lines: AdvocateDetailStatement[];
  selectedInvoiceStatement: AdvocateDetailStatement[];
  selectedPaidStatement: AdvocateDetailStatement[];
  activeTab: string;
  showPaymentDetailModal: boolean;
  selectedInvoice: AdvocateDetailStatement;
  noPayment: boolean;
  sortBy: string;
  previousSortBy: string;
  sortAscending: boolean;
  searchValue: string;
  exportStatement: any[] = [];
  advocateExcelStatement: AdvocateExcelStatement[] = [];
  advExcelStatement: AdvocateExcelStatement;
  allocatedToLawFirmHandle: AllocatedToLawfirmHandle[] = [];
  // UnallocatedTransactionModal
  SelectedTransaction: AdvocateDetailStatement = Object.assign({}, DEFAULT_ADVOCATE_DETAIL_STATEMENT);
  showUnallocatedModal: boolean;

  // Attorneys
  attorneys: ClientContact[] = [];
  showEditAttorneyModal: boolean;
  row: number;
  count: number;

  // Edit referece
  referenceData: AdvocateDetailStatement = Object.assign({}, DEFAULT_ADVOCATE_DETAIL_STATEMENT);
  selectedAdvocateDetailStatement: AdvocateDetailStatement = Object.assign({}, DEFAULT_ADVOCATE_DETAIL_STATEMENT);
  showEditReferenceModal: boolean;

  statementParameters: StatementDateParameters = Object.assign({}, DEFAULT_STATEMENT_PARAMETERS);
  details: AdvocateDetailLineItem[] = [];
  invoiceDetailDataHandle: AdvocateDetailLineItemHandle[] = [];
  onShowInvoiceLineDetailModal: boolean;

  @Input() line: AdvocateDetailStatement[];
  @Input() attorneyDetailStatement: AdvocateDetailStatement[];
  @Input() allocatedToLawFirm: AllocatedToLawfirm[];
  @Input() lawfirmPaidStatement: LawfirmPaidStatement[];
  @Input() btnGenerate: string;
  @Output() selectedAdv: EventEmitter<string> = new EventEmitter<string>();
  advocateStatementHandle: AdvocateStatementHandle[] = [];
  escaletedQuesriesHandle: AdvocateStatementHandle[] = [];
  lawfirmPaidStatementHandle: LawfirmPaidStatementHandle[] = [];

  paymentDetails: PaymentDetail[];

  orderBy = '';
  orderingOptions: string[] = [
   'date',
  'invoice-no',
  'reference',
  'matter',
  'attorney',
  'advocate',
  'total',
  'paid',
  'due',
  'aging'
  ];

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)',
    BAR_SOCIETY: 'Bar Society - User',
    GCB: 'GCB - User',
    ADHOC: 'Ad-hoc',
    HEAD_OF_STATE: 'Head of State'
  };

  sortDate: boolean;
  sortAdvocate: boolean;
  sortMatter: boolean;
  sortAttorney: boolean;
  sortInvoiceNo: boolean;
  sortAging: boolean;
  sortDaysOutstanding: boolean;
  sortDue: boolean;
  sortPaid: boolean;
  sortReference: boolean;
  sortAmount: boolean;
  sortTransactionAmount: boolean;

  sortTDate: boolean;
  sortTAdvocate: boolean;
  sortTDescription: boolean;
  sortTAmount: boolean;

  sortInvDate: boolean;
  sortInvMatter: boolean;
  sortInvRef: boolean;
  sortInvAttorney: boolean;
  sortInvNo: boolean;
  sortInvDue: boolean;
  sortInvPaid: boolean;
  sortInvTotal: boolean;

  showProformaInvoice: boolean;
  selectedInvoiceId = 0;
  advocateData: any = [];
  advocateName: string;

  attorneyData: any = [];
  attorneyName: string = 'All';
  attorneyId: number = 0;

  lawfirmId: number;
  advocateId: number;
  excelURL: string;

  // for invoice preview
  invoicePlaintiff: string;
  invoiceDefendant: string;
  invoiceDetails: InvoiceDetails;

  showHistoricalPrompt: boolean;

  // Brief and other documents
  briefDocuments: BriefDocuments[] = [];
  briefDocumentHandle: BriefDocumentHandle[] = [];
  showBriefDocumentModal: boolean;
  onShowInvoiceDispute: boolean;
  advocateLineItems: AdvocateDetailLineItem = Object.assign([], DEFAULT_ADVOCATE_DETAIL_LINE_ITEM)

  totalInvoiceDue: number;
  totalInvoicePaid: number;
  totalInvoiceTotal: number;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  canDownloadReport = false;
  showReconHistoricalPrompt: boolean;
  onShowStateInvoiceStatus: boolean;
  advocateDetailStatement: AdvocateDetailStatement[];
  onShowInvoiceLinesDispite:  boolean;
  disputStatues: DisputeStatuses = Object.assign({}, DEFAULT_DISPUTE_STATUSES);

  onShowQueryReportProgress: boolean;
  queryReport: QueryReport[] = [];

  // Is Internal Lawfirm
  get isLawfirm(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM ||
      this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_LAWFIRM);
  } // end isLawfirm()

  // Is External Attorney
  get isExternalAttorney(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY);
    // return false;
  } // end isExternalAttorney()

  // Is Internal Lawfirm
  get isInternalLawfirm(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
    // return false;
  } // end isInternalLawfirm()

  get isAssistantToLawfirm(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator)
      && Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
  } // end isAssistantToLawfirm()

  get isHeadOfState(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.HEAD_OF_STATE)
  } // end isHeadOfState()

  downloadLink: string;

  constructor(
    private router: Router,
    private api: ApiService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private cpipe: UsercurrencyPipe,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private paymentDetailService: PaymentDetailService,
    private invoiceDetailService: InvoiceDetailsService,
    private advocateDetailService: AdvocateDetailStatementService,
  ) {
    this.excelURL = this.api.endpoints.auxconReportUtils + '/AuxconReportUtils';
  } // end constructor()

  get totalInvoiceAmount(): number {
    if (!this.advocateStatementHandle) {
      return 0;
    }
    let total = 0;

    this.advocateStatementHandle.forEach(item => {
      if (item.state.isVisible) {
      total += item.data.Total;
      }
    });

    return total;
  } // end totalInvoiceAmount()

  get viewTransactionData(): boolean {
    return Boolean(this.lawfirmPaidStatementHandle.length > 0);
  } // viewTransactionData()

  get totalPaymentAmount(): number {
    if (!this.advocateStatementHandle) {
      return 0;
    }
    let total = 0;

    this.advocateStatementHandle.forEach(item => {
      if (item.state.isVisible) {
      total += item.data.Paid;
      }
    });

    return total;
  } // end totalPaymentAmount()

  get totalPaidTransactionAmount(): number {
    if (!this.lawfirmPaidStatementHandle) {
      return 0;
    }

    let total = 0;

    this.lawfirmPaidStatementHandle.forEach(item => {
      if (item.state.isVisible) {
       total += item.data.TransactionAmount;
      }
    });

    return total;
  } // end totalPaidTransactionAmount()

  get totalDueAmount(): number {
    if (!this.advocateStatementHandle) {
      return 0;
    }
    let total = 0;

    this.advocateStatementHandle.forEach(item => {
      if (item.state.isVisible) {
      total += item.data.Due;
      }
    });

    return total;
  } // end totalDueAmount()

  get totalTransactionAmount(): number {
    if (!this.allocatedToLawFirm) {
      return 0;
    }
    let total = 0;

    this.allocatedToLawFirm.forEach(item => {
      total += item.transactionAmount;
    });

    return total;
  } // end totalTransactionAmount()

  get totalUnallocatedAmount(): number {
    if (!this.allocatedToLawFirm) {
      return 0;
    }
    let total = 0;

    this.allocatedToLawFirm.forEach(item => {
      total += item.amount;
    });

    return total;
  } // end totalUnallocatedAmount()

 onDateFilterClicked() {
    this.canDownloadReport = true;
    if (this.statementParameters.fromDate && this.statementParameters.toDate) {
      if (this.advocateId > 0) {
        this.downloadLink = this.api.endpoints.reconciliationHistoryByLawfirmReport +
          '&fromDate=' + new Date(this.statementParameters.fromDate).toDateString() +
          '&toDate=' + new Date(this.statementParameters.toDate).toDateString() +
          '&ServiceProviderId=' + this.advocateId +
          '&LawFirmId=' + this.lawfirmId;
      } else {
        this.downloadLink = this.api.endpoints.lawfirmReconciliationHistoryByLawfirmReport +
          '&fromDate=' + new Date(this.statementParameters.fromDate).toDateString() +
          '&toDate=' + new Date(this.statementParameters.toDate).toDateString() +
          '&LawFirmId=' + this.lawfirmId;
      }
    }
  } // end onDateFilterClicked()

  onDateErrorShowClicked() {
    this.showReconHistoricalPrompt = true;
  } // end onDateErrorShowClicked()

  selectedRow(data) {
    this.row = 0;
    this.totalInvoiceDue = 0;
    this.totalInvoicePaid = 0;
    this.totalInvoiceTotal = 0;
    this.selectedPaidStatement = [];
    if (data) {
      this.row = data.Id;
      this.selectedPaidStatement = data.LawfirmStatementDTO;

      this.selectedPaidStatement.forEach(_data => {
        this.totalInvoiceDue += _data.Due;
        this.totalInvoicePaid += _data.Paid;
        this.totalInvoiceTotal += _data.Total;
      });
    }
    // console.log('TOTALS: ', this.totalInvoiceDue, this.totalInvoicePaid, this.totalInvoiceTotal);
  } // end selectedRow()

  async ngOnInit() {
    // this.loadingService.showOverlay();
    // this.advocateDetailStatement = [];
    this.lawfirmId = +this.route.snapshot.paramMap.get('id');
    this.advocateId = +this.route.snapshot.paramMap.get('advocateId');
    this.getAdvocateList();

    // Step 1: Retrieve the stored data from sessionStorage
    // let storedData = JSON.parse(sessionStorage.getItem('advocateDetailStatement'));
    
    // await lastValueFrom(this.advocateDetailService.getLawfirmStatement(this.api.serviceProviderID)).then((data) => {
    //   console.log('LOADED DATA: ', data);
    //   this.advocateDetailStatement = data;
    //       // console.log('ATTORNEY DASHOBOARD DATA VVPI: ', data);
    //     });

    // Check if this is not External Attorney, and if it is not an external Attorney return the data.
    if(!this.isExternalAttorney) {
      this.getAttorneyList();
    }
    // this.loadingService.hideOverlay();
  } // end ngOnInit()

  removeAdvocateTitle(name: string): string {
    return name.replace('Advocate', '');
  } // end removeAdvocateTitle()

  loadInvoiceDetails(InvoiceId: number) {
    this.loadingService.showOverlay();
    this.details = [];
    this.invoiceDetailDataHandle = [];
    this.advocateDetailService.getInvoiceLineDetail(InvoiceId).subscribe({ next: (next) => {
      // On next
      this.details = next;
    },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.details.forEach(_data => {
          this.invoiceDetailDataHandle.push({
            data: _data,
            state: {
              isVisible: true,
              isSelected: false,
              updated: false
            }
          });
        });
        this.onShowInvoiceLineDetailModal = true;
        this.loadingService.hideOverlay();
      }
    });
  } // end loadInvoiceDetails()

  getAdvocateList(): void {
    const adv = {
      ContactID: 0,
      ContactTypeID: null,
      FullName: 'All'
    };
    this.advocateDetailService.getAdvocateList().subscribe({ next: (advocate) => {
      if (advocate) {
        this.advocateData = advocate;        
        this.advocateData.sort((a, b) => {
          if (a.FullName !== undefined && b.FullName !== undefined) {
            return a.FullName.toUpperCase().localeCompare(b.FullName.toUpperCase());
          }
        });
      }
    },
    error: (error) => {
      // On error
      // this.loadingService.hideOverlay();
    },
    complete: () => {
      // On Complete
      if(this.advocateDetailService.selectedAttorneyId > 0) {
        this.advocateName = 'All';
      } else {
        if (this.advocateId == null || this.advocateId == 0) {
          this.setActiveTab(this.advocateDetailService.activeTab);
          this.advocateName = 'All';
          this.attorneyName = 'All';
        } else {
          const _scope = this;
          const currentAdvocate = this.advocateData.filter(function (f) {
            return f.ContactID === _scope.advocateId;
          })[0];
          if (currentAdvocate && currentAdvocate.FullName) {
            this.advocateName = currentAdvocate.FullName;
            this.advocateId = currentAdvocate.Id;
          }
        }
        this.advocateData.unshift(adv);
      }
      // this.loadingService.hideOverlay();'view-all'
    }
  });
  } // end getAdvocateList()

  getAttorneyList(): void {
    const Att = {
      ContactID: 0,
      ContactTypeID: null,
      FullName: 'All'
    };
  
    this.advocateDetailService.getAttorneyList(this.advocateId).subscribe({ next: (advocate) => {
      if (advocate) {
        this.attorneyData = advocate;
        this.attorneyData.sort((a, b) => {
          if (a.FullName !== undefined && b.FullName !== undefined) {
            return a.FullName.toUpperCase().localeCompare(b.FullName.toUpperCase());
          }
        });
      }
    },
    error: (error) => {
      // On error
      // this.loadingService.hideOverlay();
    },
    complete: () => {
      // On Complete
      if (this.attorneyId == null) {
        this.attorneyName = '';
      } else {
        const _scope = this;
        const currentAttorney = this.attorneyData.filter(function (f) {
          return f.ContactID === _scope.attorneyId;
        })[0];
        if (currentAttorney && currentAttorney.FullName) {
          this.attorneyName = currentAttorney.FullName;
        }
      }
      this.attorneyData.unshift(Att);
      this.setActiveTab('view-all');
      // this.loadingService.hideOverlay();
    }
  });
  } // end getAttorneyList()

  onAdvocateSelect(event: TypeaheadMatch): void {
    // console.log('selected event: ', event);
    this.advocateDetailService.selectedAttorneyId = 0;
    this.attorneyName = 'All';
    this.statementParameters = Object.assign({}, DEFAULT_STATEMENT_PARAMETERS);
    this.advocateDetailService.activeTab = this.activeTab;
    if (event.item.FullName === 'All') {
      // this.router.navigate([PATH_UNPAID_INVOICES, this.api.serviceProviderID]);
      this.router.navigate([PATH_ADVOCATE_DETAIL, this.api.serviceProviderID, event.item.ContactID]);
      this.selectedAdv.emit(event.item.ContactID);
      // location.reload();
      this.advocateId = event.item.ContactID
      this.getAttorneyList();
      // this.getAttorneyList();
    } else {
      this.router.navigate([PATH_ADVOCATE_DETAIL, this.api.serviceProviderID, event.item.ContactID]);
      this.selectedAdv.emit(event.item.ContactID);
      this.advocateId = event.item.ContactID
      this.getAttorneyList();
    }
  } // end onAdvocateSelect()

  onAttorneySelect(event: TypeaheadMatch): void {
    if (event.item.FullName === 'All') {
      this.advocateDetailService.selectedAttorneyId = 0;
        this.advocateStatementHandle.forEach(statement => {
          statement.state.isVisible = true;
        });
      } else {
      this.advocateStatementHandle.forEach(x => {
        if (x.data.AttorneyName === event.item.FullName) {
          x.state.isVisible = true;
        } else {
          x.state.isVisible = false;
        }
      });

      // console.log('SELECTED ATTORNEY: ', event.item.FullName, this.advocateStatementHandle);
    }
    // this.statementParameters = Object.assign({}, DEFAULT_STATEMENT_PARAMETERS);
    // this.advocateDetailService.activeTab = this.activeTab;
    // if (event.item.FullName === 'All') {
    //   this.router.navigate([PATH_UNPAID_INVOICES, this.api.serviceProviderID]);
    // } else {
    //   this.router.navigate([PATH_ADVOCATE_DETAIL, this.api.serviceProviderID, event.item.ContactID]);
    //   this.selectedAdv.emit(event.item.ContactID);
    // }
  } // end onAttorneySelect()

  onSetStatus(data: any) {
    this.selectedAdvocateDetailStatement = Object.assign({}, DEFAULT_ADVOCATE_DETAIL_STATEMENT);
    this.selectedAdvocateDetailStatement = data;
    this.onShowStateInvoiceStatus = true;
  } // end onSetStatus()

  onLineItemClicked(data: any) {
    this.disputStatues = Object.assign({}, DEFAULT_DISPUTE_STATUSES);
    this.disputStatues.Id = data.DisputeStatusId;
    this.disputStatues.Status = data.DisputeStatus;
    this.disputStatues.Source = data.DisputeSource;
    this.disputStatues.SourceId = data.Id;
    this.disputStatues.Note = data.DisputeNote;
    this.disputStatues.Description = data.DisputeDescription;
    this.advocateLineItems = Object.assign([], DEFAULT_ADVOCATE_DETAIL_LINE_ITEM)
    this.advocateLineItems = data;
    this.onShowInvoiceLinesDispite = true;
  } // end onLineItemClicked()

  async onInvoiceStatusUpdate(event: AdvocateDetailStatement) {
    this.loadingService.showOverlay();
    if(this.activeTab === 'new-invoices') {
      this.clearFilter();
      if (this.line) {
        this.lines = this.line.filter(zerodays => zerodays.InvoiceStatusGroup !== 0);
        if (this.lines) {
          this.lines = this.lines.filter(d => d.Due > 0);
          this.lines.forEach(statement => {
            if (statement.AttorneyName === null) {
              statement.AttorneyName = '-';
            }
            if(statement.InvoiceStatus === null || statement.InvoiceStatus === undefined) {
              statement.InvoiceStatus = 'Click to set Status';
            }
            if (statement.InvoiceId === event.InvoiceId) {
              // Replace the entire data with the new event data
              statement = event;
              statement.InvoiceStatusGroup = 0;
              console.log('UPDATED STATEMENT: ', statement);
            }
            this.advocateStatementHandle.push({
              data: statement,
              state: {
                isVisible: true,
                isExpanded: false
              }
            });
          });
        }
      }
    }

    if (this.advocateId > 0) {
      this.router.navigate([PATH_ADVOCATE_DETAIL, this.api.serviceProviderID, this.advocateId]);
      this.onShowInvoiceDispute = false;
      this.onShowStateInvoiceStatus = false;
      this.loadingService.hideOverlay();
    } else {
      this.statementParameters = Object.assign({}, DEFAULT_STATEMENT_PARAMETERS);
      this.onShowInvoiceDispute = false;
      this.onShowStateInvoiceStatus = false;
      this.loadingService.hideOverlay();
    }
    if (this.advocateDetailService.activeTab) {
      this.setActiveTab(this.advocateDetailService.activeTab);
    } else {
      this.setActiveTab('view-all');
    }
  } // end onInvoiceStatusUpdate()

  async onInvoiceDisputeUpdate(event: AdvocateDetailStatement) {
    if (this.advocateId > 0) {
      this.router.navigate([PATH_ADVOCATE_DETAIL, this.api.serviceProviderID, this.advocateId]);
      this.onShowInvoiceDispute = false;
      this.onShowStateInvoiceStatus = false;
      this.loadingService.hideOverlay();
    } else {
      this.statementParameters = Object.assign({}, DEFAULT_STATEMENT_PARAMETERS);
      this.onShowInvoiceDispute = false;
      this.onShowStateInvoiceStatus = false;
      this.loadingService.hideOverlay();
    }
    if (this.advocateDetailService.activeTab) {
      this.setActiveTab(this.advocateDetailService.activeTab);
    } else {
      this.setActiveTab('view-all');
    }
  } // end onInvoiceDisputeUpdate()

  clearFilter() {
    this.statementParameters = Object.assign({}, DEFAULT_STATEMENT_PARAMETERS);
  } // end clearFilter()

  ngOnDestroy() {
    // this.advocateDetailService.activeTab = 'view-all';
  }

  onHideUnallocatedModal() {
    this.showUnallocatedModal = false;
  } // end onHideUnallocatedModal()

  onSendUnallocatedRequest(event) {
    // console.log('ADDED DATA: ', event);
    this.showUnallocatedModal = false;
  } // end onSendUnallocatedRequest()

  onShowUnallocatedTransactionModal(line: AdvocateDetailStatement) {
    if (line) {
      this.SelectedTransaction = line;
      // console.log('SELECTED TRANSACTIONS: ', this.SelectedTransaction);
      this.showUnallocatedModal = true;
    }
  } // end onShowUnallocatedTransactionModal()

  ngOnChanges(changes: SimpleChanges) {
    if (this.line) {
      this.paymentStatuses(this.advocateStatementHandle);
      this.setActiveTab(this.advocateDetailService.activeTab);
    }
    // console.log('selected tab: ', this.advocateDetailService.activeTab);
    if (this.advocateDetailService.activeTab) {
      this.setActiveTab(this.advocateDetailService.activeTab);
    } else {
      this.setActiveTab('view-all');
    }

    // Unallocated Transactions count indicator
    if (this.allocatedToLawFirm && this.allocatedToLawFirm.length > 0) {
      this.count = this.allocatedToLawFirm.length;
    } else {
      this.count = 0;
    }
    // this.downloadLink = this.api.endpoints.reconciliationHistoryByLawfirmReport +
    //   '&fromDate=' + this.statementParameters.fromDate +
    //   '&toDate=' + this.statementParameters.toDate +
    //   '&ServiceProviderId=' + this.advocateId +
    //   '&LawFirmId=' + this.lawfirmId;
  } // end ngOnChanges()

  onPageClick(event) {

  } // end onPageClick()

  openPaymentDetailModal(invoiceStatement: AdvocateDetailStatement) {
    this.paymentDetails = [];
    this.loadingService.showOverlay();

    if (invoiceStatement.Paid > 0) {

      const snack: Snack = {
        label: 'Loading payment details..',
        action: null
      };
      this.snackbarsService.snackbarComponent.make(snack).show();

      // Fetch payment details for selected invoice.
      this.paymentDetailService.getPaymentDetail(invoiceStatement.InvoiceId)
      .subscribe({ next: (invoice_detail) => {
        this.paymentDetails = invoice_detail;
        this.selectedInvoice = invoiceStatement;
        this.showPaymentDetailModal = true;
        this.noPayment = true;
      },
      error: (error) => {
        // On error
        snack.label = 'An error loading payment details.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        snack.label = 'Payment details loaded';
        snack.type = SnackType.SUCCESS;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    });
    } else {
      this.loadingService.hideOverlay();
      this.noPayment = false;
      if (invoiceStatement) {
        const p_detail: PaymentDetail = {
          InvoiceAmount: 0,
          InvoiceNo: invoiceStatement.InvoiceNo,
          Amount: 0,
          Outstanding: invoiceStatement.Due,
          PaymentDate: '',
          Reference: '',
          Split: '',
          Status: 'No Payment',
          TransactionAmount: 0
        };
        this.selectedInvoice = invoiceStatement;
        this.paymentDetails.push(p_detail);
        this.showPaymentDetailModal = true;
      }
  }
    // this.selectedInvoiceStatement = [];
    // this.lines.forEach(line => {
    //   if (line.InvoiceNo === invoiceStatement.InvoiceNo) {
    //     this.selectedInvoiceStatement.push(line);
    //   }
    // });
  } // end openPaymentDetailModal()

  closePaymentDetailModal() {
    this.showPaymentDetailModal = false;
  } // end closePaymentDetailModal()

  savePaymentDetail(statement) {

  } // end savePaymentDetail()

  onReferenceEdit(data: AdvocateDetailStatement) {
    this.referenceData = Object.assign({}, DEFAULT_ADVOCATE_DETAIL_STATEMENT);
    // console.log('IS LAWFIRM ASSISTANT: ', this.isAssistantToLawfirm);
    if ((this.isLawfirm || this.isAssistantToLawfirm) && data) {
      this.referenceData = data;
      this.showEditReferenceModal = true;
    }
  } // end onReferenceEdit()

  onSaveReference(data: AdvocateDetailStatement) {
    this.line = this.line.filter(s => s.ServiceId !== data.ServiceId);
    this.line.push(data);
    this.setActiveTab(this.activeTab);
    this.loadingService.hideOverlay();
    this.showEditReferenceModal = false;
  } // end onSaveReference()

  onAttorneyEdit(statement: AdvocateDetailStatement) {
    this.referenceData = Object.assign({}, DEFAULT_ADVOCATE_DETAIL_STATEMENT);
    // console.log('SSSSS: ', this.isLawfirm, statement);
    if ((this.isLawfirm || this.isAssistantToLawfirm) && statement) {
      this.loadingService.showOverlay();
      this.referenceData = statement;
      if (this.isLawfirm) {
      // this.attorneys =  this.attorneysService.
      // getAttorneysByLawFirms(this.userProfileService.userProfile.serviceProviderID).toPromise();
      } else if (this.isAssistantToLawfirm) {
        // this.attorneys =  this.attorneysService.
          // getAttorneysByLawFirms(this.userProfileService.selectedUserProfile.serviceProviderID).toPromise();
      }
      this.showEditAttorneyModal = true;
      this.loadingService.hideOverlay();
    }
  } // end onAttorneyEdit()

  openInvoice(invoiceStatement: AdvocateDetailStatement) {
    if (invoiceStatement && invoiceStatement.InvoiceId) {
      if (invoiceStatement.InvoiceNo.startsWith('AUX') || invoiceStatement.LawFirm === 'Chueu Attorneys - Pretoria' ||
    (this.isHeadOfState || this.api.serviceProviderID === 3648)) {
        if (invoiceStatement.InvoiceId) {
          // this.invoiceDetails =  this.invoiceDetailService.getInvoiceDetails(invoiceStatement.InvoiceId).toPromise();
          this.invoiceDetailService.getInvoiceDetails(invoiceStatement.InvoiceId).subscribe({ next: (_getInvoice) => {
            // On Next
            this.invoiceDetails = _getInvoice;
          },
          complete: () => {

            this.selectedInvoiceId = invoiceStatement.InvoiceId;
              if (this.invoiceDetails) {
                const desc = this.invoiceDetails.InvoiceParties.split(' // ');
                if (desc.length > 0) {
                  this.invoicePlaintiff = desc[0];
                }
                if (desc.length > 1) {
                  this.invoiceDefendant = desc[1];
                }
              }
          this.showProformaInvoice = true;
         }
       });
          
        //   this.selectedInvoiceId = invoiceStatement.InvoiceId;
        //     if (this.invoiceDetails) {
        //       const desc = this.invoiceDetails.InvoiceParties.split(' // ');
        //       if (desc.length > 0) {
        //         this.invoicePlaintiff = desc[0];
        //       }
        //       if (desc.length > 1) {
        //         this.invoiceDefendant = desc[1];
        //       }
        //     }
        // this.showProformaInvoice = true;
        }
      } else {
        this.showHistoricalPrompt = true;
      }
    }
  } // end openInvoice()

  closeModal(value: string) {
    switch (value) {
      case 'invoice':
        this.showProformaInvoice = false;
      break;
      case 'bried-document':
        this.showBriefDocumentModal = false;
      break;
      case 'edit-reference':
        this.showEditReferenceModal = false;
      break;
      case 'edit-attorney':
        this.showEditAttorneyModal = false;
      break;
      case 'state-invoice-status':
        this.onShowStateInvoiceStatus = false;
      break;

      case 'invoice-dispute':
        this.onShowInvoiceDispute = false;
      break;

      case 'line-item-dispute':
        this.onShowInvoiceLinesDispite = false;
      break;

      case 'invoice-line-detail':
        this.onShowInvoiceLineDetailModal = false;
      break;

      case 'query-report-progress':
        this.onShowQueryReportProgress = false;
      break;
    }
  } // end closeModal()

  onViewQueryReport(data: AdvocateDetailStatement) {
    console.log('SELECTED ADVOCATE: ', data);
    this.queryReport = [];
    this.loadingService.showOverlay();
      this.advocateDetailService.getQueryReportByAdvocateId(data.ServiceProviderId, data.InvoiceId).subscribe({ next:  (data) => {
        // On next
        this.queryReport = data;
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
        complete: () => {
          // On complete
          this.onShowQueryReportProgress = true;
          this.loadingService.hideOverlay();
        }
      });
  } // end onViewQueryReport()

  onInvoiceDisputeUpdateShow(data: AdvocateDetailStatement) {
    this.selectedAdvocateDetailStatement = Object.assign({}, DEFAULT_ADVOCATE_DETAIL_STATEMENT);
    this.selectedAdvocateDetailStatement = data;
    this.disputStatues = Object.assign({}, DEFAULT_DISPUTE_STATUSES);
    this.disputStatues.Note = this.selectedAdvocateDetailStatement.DisputeNoteStatus;
    this.disputStatues.Status = this.selectedAdvocateDetailStatement.DisputeStatus;
    this.disputStatues.Id = this.selectedAdvocateDetailStatement.DisputStatusId ? this.selectedAdvocateDetailStatement.DisputStatusId : 0;
    this.disputStatues.SourceId = this.selectedAdvocateDetailStatement.InvoiceId;
    this.disputStatues.Source = 'Invoice';
    this.onShowInvoiceDispute = true;
  } // end onInvoiceDisputeUpdateShow()

  setActiveTab(tab: string) {
    this.advocateStatementHandle = [];
    this.lines = [];
    this.activeTab = tab;
    this.paymentStatuses(this.advocateStatementHandle);
    this.clearSearch();

    if (this.activeTab === 'view-all') {
      this.clearFilter();
      this.attorneyName = 'All';
      if (this.line) {
        this.lines = this.line;
        if (this.lines) {
          this.lines = this.lines.filter(d => d.Due > 0);
          // if(this.advocateDetailService.selectedAttorneyId > 0) {
          //   const myLines = this.lines.filter(d => d.AttorneyId === this.advocateDetailService.selectedAttorneyId );
          //   this.attorneyName = myLines[0].AttorneyName;
          // }
          this.lines.forEach(statement => {
            if (statement.AttorneyName === null) {
              statement.AttorneyName = '-';
            }
            if(statement.InvoiceStatus === null || statement.InvoiceStatus === undefined) {
              statement.InvoiceStatus = 'Click to set Status';
            }
            // Ensure this.detailed is an array of AdvocateDetailLineItem
            // statement.Details = this.detailed.map(detail => ({
            //   LineDate: detail.LineDate,
            //   Description: detail.Description,
            //   LineType: detail.LineType,
            //   Quantity: detail.Quantity,
            //   Total: detail.Total,
            //   Rate: detail.Rate
            // }));
            this.advocateStatementHandle.push({
              data: statement,
              state: {
                isVisible: !this.advocateDetailService.selectedAttorneyId ? true : false,
                isExpanded: false
              }
            });
          });
          if(this.advocateDetailService.selectedAttorneyId > 0) {
            // let _newAdvocates = [];
            // let Advocates = [];
            // if(this.advocateData.length > 0)
            // {
            //   Advocates = this.advocateData;
            // }
            const myLines = this.lines.filter(d => d.AttorneyId === this.advocateDetailService.selectedAttorneyId );
            this.attorneyName = myLines[0].AttorneyName;
            this.advocateStatementHandle.forEach(x => {
            //   if (x && x.data && x.data.ServiceProviderId && Advocates.length > 0) {
             
            //     const existingAdvocates = Advocates.filter(x => x.ContactID === x.data.ServiceProviderId);
            //     existingAdvocates.forEach(advocate => {
            //       if (!_newAdvocates.some(existing => existing.ContactID === advocate.ContactID)) {
            //       _newAdvocates.push(advocate);
            //     }
            //   });
            // }
              
              if (x.data.AttorneyId === this.advocateDetailService.selectedAttorneyId) {
                x.state.isVisible = true;
              } else {
                x.state.isVisible = false;
              }
            });
            // const adv = {
            //   ContactID: 0,
            //   ContactTypeID: null,
            //   FullName: 'All'
            // };
            // const distinctServiceProviderIds = Array.from(
            //   new Set(this.advocateStatementHandle.filter(c => c.state.isVisible === true).map(x => x.data.ServiceProviderId))
            // );
            // const filteredAdvocateData = this.advocateData.filter(advocate => 
            //   distinctServiceProviderIds.some(id => id === advocate.ContactID)
            // );
            // filteredAdvocateData.push(adv);
            // this.advocateData = Object.assign({}, filteredAdvocateData);
            //   console.log('ADVOCATESss: ', filteredAdvocateData, this.advocateData);
          }
        }
      }
    } else if (this.activeTab === 'new-invoices') {

      if(this.isHeadOfState) {
        this.clearFilter();
      if (this.line) {
        this.lines = this.line;
        if (this.lines) {
          let now_lines = this.line.filter(zerodays => zerodays.InvoiceStatusGroup !== 0);
          this.lines = this.lines.filter(d => d.Due > 0 && d.InvoiceStatus === "Escalated");
          this.lines.push(...now_lines);
          this.lines.forEach(statement => {
            if (statement.AttorneyName === null) {
              statement.AttorneyName = '-';
            }
            if(statement.InvoiceStatus === null || statement.InvoiceStatus === undefined) {
              statement.InvoiceStatus = 'Click to set Status';
            }
            this.advocateStatementHandle.push({
              data: statement,
              state: {
                isVisible: true,
                isExpanded: false
              }
            });
          });
        }
      }
        this.escaletedQuesriesHandle = this.advocateStatementHandle.filter(x => x.data.InvoiceStatus === "Escalated");
        // console.log('NEW ESCALETED DATA: ', this.advocateStatementHandle);
      } else {
        this.clearFilter();
        if (this.line) {
          this.lines = this.line.filter(zerodays => zerodays.InvoiceStatusGroup !== 0);
          if (this.lines) {
            this.lines = this.lines.filter(d => d.Due > 0);
            this.lines.forEach(statement => {
              if (statement.AttorneyName === null) {
                statement.AttorneyName = '-';
              }
              if(statement.InvoiceStatus === null || statement.InvoiceStatus === undefined) {
                statement.InvoiceStatus = 'Click to set Status';
              }
              
              this.advocateStatementHandle.push({
                data: statement,
                state: {
                  isVisible: true,
                  isExpanded: false
                }
              });
            });
          }
        }
      }
    } else if (this.activeTab === 'view-0-days') {
      this.clearFilter();
      if (this.line) {
        this.lines = this.line.filter(zerodays => zerodays.ZeroDays !== 0);
        if (this.lines) {
          this.lines = this.lines.filter(d => d.Due > 0);
          this.lines.forEach(statement => {
            if (statement.AttorneyName === null) {
              statement.AttorneyName = '-';
            }
            
            this.advocateStatementHandle.push({
              data: statement,
              state: {
                isVisible: true,
                isExpanded: false
              }
            });
          });
        }
      }
    } else if (this.activeTab === 'view-30-days') {
      this.clearFilter();
      if (this.line) {
        this.lines = this.line.filter(zerodays => zerodays.ThirtyDays !== 0);
        if (this.lines) {
          this.lines = this.lines.filter(d => d.Due > 0);
          this.lines.forEach(statement => {
            if (statement.AttorneyName === null) {
              statement.AttorneyName = '-';
            }
            this.advocateStatementHandle.push({
              data: statement,
              state: {
                isVisible: true,
                isExpanded: false
              }
            });
          });
        }
      }
    } else if (this.activeTab === 'view-60-days') {
      this.clearFilter();
      if (this.line) {
        this.lines = this.line.filter(zerodays => zerodays.SixtyDays !== 0);
        if (this.lines) {
          this.lines = this.lines.filter(d => d.Due > 0);
          this.lines.forEach(statement => {
            if (statement.AttorneyName === null) {
              statement.AttorneyName = '-';
            }
            this.advocateStatementHandle.push({
              data: statement,
              state: {
                isVisible: true,
                isExpanded: false
              }
            });
          });
        }
      }
    } else if (this.activeTab === 'view-90-days') {
      this.clearFilter();
      if (this.line) {
        this.lines = this.line.filter(zerodays => zerodays.NinetyDays !== 0);
        if (this.lines) {
          this.lines = this.lines.filter(d => d.Due > 0);
          this.lines.forEach(statement => {
            if (statement.AttorneyName === null) {
              statement.AttorneyName = '-';
            }
            this.advocateStatementHandle.push({
              data: statement,
              state: {
                isVisible: true,
                isExpanded: false
              }
            });
          });
        }
      }
    } else if (this.activeTab === 'view-98-days') {
      this.clearFilter();
      if (this.line) {
        this.lines = this.line.filter(zerodays => zerodays.NinetyEightDays !== 0);
        if (this.lines) {
          this.lines = this.lines.filter(d => d.Due > 0);
          this.lines.forEach(statement => {
            if (statement.AttorneyName === null) {
              statement.AttorneyName = '-';
            }
            this.advocateStatementHandle.push({
              data: statement,
              state: {
                isVisible: true,
                isExpanded: false
              }
            });
          });
        }
      }
    } else if (this.activeTab === 'paid') {
      this.lawfirmPaidStatementHandle = [];
      if (this.lawfirmPaidStatement) {
        this.lawfirmPaidStatement.forEach(statement => {
          this.lawfirmPaidStatementHandle.push({
            data: statement,
            state: {
              isVisible: true,
              isSelected: false,
              isOpen: false
            }
          });
        });
      }
      if (this.lawfirmPaidStatementHandle.length > 0) {
        this.lawfirmPaidStatementHandle.sort((a, b) => {
          return b.data.TransactionDate.toUpperCase().localeCompare(a.data.TransactionDate.toUpperCase());
        });
        this.selectedRow(this.lawfirmPaidStatementHandle[0].data);
      } else {
        this.selectedRow(null);
      }
    } else if (this.activeTab === 'unallocated') {
      this.clearFilter();
      this.allocatedToLawFirm.forEach(statement => {
        if (statement) {
          const lData: AdvocateDetailStatement = {
            AttorneyName: '',
            AttorneyId: 0,
            Due: 0,
            Email: '',
            Fax: '',
            InvoiceDate: statement.transactionDate,
            InvoiceId: 0,
            InvoiceNo: '',
            LawFirm: statement.lawFirmFullName,
            NinetyDays: 0,
            NinetyEightDays: 0,
            NormalDays: 0,
            Paid: statement.amount,
            PaymentStatus: 'Paid',
            Phone: '',
            ProviderFullName: statement.advocateFullName,
            ProviderName: statement.advocateFullName,
            Total: statement.transactionAmount,
            ServiceProviderId: statement.serviceProviderId,
            ProviderAddress: '',
            Reference: '',
            ServiceDescription: statement.description,
            SixtyDays: 0,
            StatementDate: '',
            ThirtyDays: 0,
            TotalDaysOverdue: 0,
            ZeroDays: 0,
            HasBrief: 0,
            ServiceId: 0,
            PaymentTerms: '',
            InvoiceStatus: '',
            InvoiceStatusGroup: 0,
            DisputeStatus: '',
            DisputStatusId: 0,
            DisputeNoteStatus: '',
            InvoiceEscaletedStatusGroup: 0
          };
          this.advocateStatementHandle.push({
            data: lData,
            state: {
              isVisible: true,
              isExpanded: false
            }
          });
        }
      });
    }
    this.advocateStatementHandle = this.advocateStatementHandle
    .sort((a, b) => a.data.InvoiceDate.toUpperCase().localeCompare(b.data.InvoiceDate.toUpperCase()));

  } // end setActiveTab()

  paymentStatuses(line: AdvocateStatementHandle[]) {
    this.advocateStatementHandle = [];
    if (line) {
      line.forEach(advocateInvoice => {

        if (advocateInvoice.data.PaymentStatus === 'None') {

          if (advocateInvoice.data.ZeroDays !== 0) {

            advocateInvoice.data.PaymentStatus = '0 days';
            this.advocateStatementHandle.push(advocateInvoice);

          } else if (advocateInvoice.data.ThirtyDays !== 0) {

            advocateInvoice.data.PaymentStatus = '30 days';
            this.advocateStatementHandle.push(advocateInvoice);

          } else if (advocateInvoice.data.SixtyDays !== 0) {

            advocateInvoice.data.PaymentStatus = '60 days';
            this.advocateStatementHandle.push(advocateInvoice);

          } else if (advocateInvoice.data.NinetyDays !== 0) {

            advocateInvoice.data.PaymentStatus = '90 days';
            this.advocateStatementHandle.push(advocateInvoice);

          } else if (advocateInvoice.data.NinetyEightDays !== 0) {

            advocateInvoice.data.PaymentStatus = '98 days';
            this.advocateStatementHandle.push(advocateInvoice);

          }
        } else {
          this.advocateStatementHandle.push(advocateInvoice);
        }
      });
    }
  } // end paymentStatuses()

  toggleTransSort(sortBy: string) {
    this.sortBy = sortBy;

    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }

    switch (this.sortBy) {
      case FIELD_TRANS_DATE:
        this.sortTDate = true;
        this.sortTAdvocate = false;
        this.sortTDescription = false;
        this.sortTAmount = false;

        this.lawfirmPaidStatementHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.TransactionDate.toUpperCase().localeCompare(b.data.TransactionDate.toUpperCase());
          }
          this.sortTDate = false;
          return b.data.TransactionDate.toUpperCase().localeCompare(a.data.TransactionDate.toUpperCase());
        });
      break;

      case FIELD_TRANS_ADVOCATE:
        this.sortTDate = false;
        this.sortTAdvocate = true;
        this.sortTDescription = false;
        this.sortTAmount = false;

        this.lawfirmPaidStatementHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.AdvocateName.toUpperCase().localeCompare(b.data.AdvocateName.toUpperCase());
          }
          this.sortTAdvocate = false;
          return b.data.AdvocateName.toUpperCase().localeCompare(a.data.AdvocateName.toUpperCase());
        });
      break;

      case FIELD_TRANS_DESCRIPTION:
        this.sortTDate = false;
        this.sortTAdvocate = false;
        this.sortTDescription = true;
        this.sortTAmount = false;

        this.lawfirmPaidStatementHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.TransactionDescription.toUpperCase().localeCompare(b.data.TransactionDescription.toUpperCase());
          }
          this.sortTDescription = false;
          return b.data.TransactionDescription.toUpperCase().localeCompare(a.data.TransactionDescription.toUpperCase());
        });
      break;

      case FIELD_TRANS_AMOUNT:
        this.sortTDate = false;
        this.sortTAdvocate = false;
        this.sortTDescription = false;
        this.sortTAmount = true;

        this.lawfirmPaidStatementHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.TransactionAmount - b.data.TransactionAmount;
          }
          this.sortTAmount = false;
          return b.data.TransactionAmount - a.data.TransactionAmount;
        });
      break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleTransSort()

  toggleInvSort(sortBy: string) {
    this.sortBy = sortBy;

    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }

    switch (this.sortBy) {
      case FIELD_INV_DATE:
        this.sortInvDate = true;
        this.sortInvMatter = false;
        this.sortInvRef = false;
        this.sortInvAttorney = false;
        this.sortInvNo = false;
        this.sortInvDue = false;
        this.sortInvPaid = false;
        this.sortInvTotal = false;
        this.selectedPaidStatement.sort((a, b) => {
          if (this.sortAscending) {
            return a.InvoiceDate.toUpperCase().localeCompare(b.InvoiceDate.toUpperCase());
          }
          this.sortInvDate = false;
          return b.InvoiceDate.toUpperCase().localeCompare(a.InvoiceDate.toUpperCase());
        });
      break;

      case FIELD_INV_MATTER:
        this.sortInvDate = false;
        this.sortInvMatter = true;
        this.sortInvRef = false;
        this.sortInvAttorney = false;
        this.sortInvNo = false;
        this.sortInvDue = false;
        this.sortInvPaid = false;
        this.sortInvTotal = false;
        this.selectedPaidStatement.sort((a, b) => {
          if (this.sortAscending) {
            return a.ServiceDescription.toUpperCase().localeCompare(b.ServiceDescription.toUpperCase());
          }
          this.sortInvMatter = false;
          return b.ServiceDescription.toUpperCase().localeCompare(a.ServiceDescription.toUpperCase());
        });
      break;

      case FIELD_INV_REFERENCE:
        this.sortInvDate = false;
        this.sortInvMatter = false;
        this.sortInvRef = true;
        this.sortInvAttorney = false;
        this.sortInvNo = false;
        this.sortInvDue = false;
        this.sortInvPaid = false;
        this.sortInvTotal = false;
        this.selectedPaidStatement.sort((a, b) => {
          if (this.sortAscending) {
            return a.Reference.toUpperCase().localeCompare(b.Reference.toUpperCase());
          }
          this.sortInvRef = false;
          return b.Reference.toUpperCase().localeCompare(a.Reference.toUpperCase());
        });
      break;

      case FIELD_INV_ATTORNEY:
        this.sortInvDate = false;
        this.sortInvMatter = false;
        this.sortInvRef = false;
        this.sortInvAttorney = true;
        this.sortInvNo = false;
        this.sortInvDue = false;
        this.sortInvPaid = false;
        this.sortInvTotal = false;
        this.selectedPaidStatement.sort((a, b) => {
          if (this.sortAscending) {
            return a.AttorneyName.toUpperCase().localeCompare(b.AttorneyName.toUpperCase());
          }
          this.sortInvAttorney = false;
          return b.AttorneyName.toUpperCase().localeCompare(a.AttorneyName.toUpperCase());
        });
      break;

      case FIELD_INV_INVOICE_NO:
        this.sortInvDate = false;
        this.sortInvMatter = false;
        this.sortInvRef = false;
        this.sortInvAttorney = false;
        this.sortInvNo = true;
        this.sortInvDue = false;
        this.sortInvPaid = false;
        this.sortInvTotal = false;
        this.selectedPaidStatement.sort((a, b) => {
          if (this.sortAscending) {
            return a.InvoiceNo.toUpperCase().localeCompare(b.InvoiceNo.toUpperCase());
          }
          this.sortInvNo = false;
          return b.InvoiceNo.toUpperCase().localeCompare(a.InvoiceNo.toUpperCase());
        });
      break;

      case FIELD_INV_DUE:
        this.sortInvDate = false;
        this.sortInvMatter = false;
        this.sortInvRef = false;
        this.sortInvAttorney = false;
        this.sortInvNo = false;
        this.sortInvDue = true;
        this.sortInvPaid = false;
        this.sortInvTotal = false;
        this.selectedPaidStatement.sort((a, b) => {
          if (this.sortAscending) {
            return a.Due - b.Due;
          }
          this.sortInvDue = false;
          return b.Due - a.Due;
        });
      break;

      case FIELD_INV_PAID:
        this.sortInvDate = false;
        this.sortInvMatter = false;
        this.sortInvRef = false;
        this.sortInvAttorney = false;
        this.sortInvNo = false;
        this.sortInvDue = false;
        this.sortInvPaid = true;
        this.sortInvTotal = false;
        this.selectedPaidStatement.sort((a, b) => {
          if (this.sortAscending) {
            return a.Paid - b.Paid;
          }
          this.sortInvPaid = false;
          return b.Paid - a.Paid;
        });
      break;

      case FIELD_INV_TOTAL:
        this.sortInvDate = false;
        this.sortInvMatter = false;
        this.sortInvRef = false;
        this.sortInvAttorney = false;
        this.sortInvNo = false;
        this.sortInvDue = false;
        this.sortInvPaid = false;
        this.sortInvTotal = true;
        this.selectedPaidStatement.sort((a, b) => {
          if (this.sortAscending) {
            return a.Total - b.Total;
          }
          this.sortInvTotal = false;
          return b.Total - a.Total;
        });
      break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleInvSort()

  toggleSort(sortBy: string) {
    this.sortBy = sortBy;

    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }

    switch (this.sortBy) {

      case FIELD_DATE:
        this.sortDate = true;
        this.sortAdvocate = false;
        this.sortMatter = false;
        this.sortAttorney = false;
        this.sortInvoiceNo = false;
        this.sortAging = false;
        this.sortDaysOutstanding = false;
        this.sortDue = false;
        this.sortPaid = false;
        this.sortAmount = false;
        this.sortReference = false;
        this.sortTransactionAmount = false;
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.InvoiceDate.toUpperCase().localeCompare(b.data.InvoiceDate.toUpperCase());
        }
        this.sortDate = false;
        return b.data.InvoiceDate.toUpperCase().localeCompare(a.data.InvoiceDate.toUpperCase());
      });
      break;

      case FIELD_ADVOCATE:
        this.sortDate = false;
        this.sortAdvocate = true;
        this.sortMatter = false;
        this.sortAttorney = false;
        this.sortInvoiceNo = false;
        this.sortAging = false;
        this.sortDaysOutstanding = false;
        this.sortDue = false;
        this.sortPaid = false;
        this.sortAmount = false;
        this.sortReference = false;
        this.sortTransactionAmount = false;
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.ProviderName.toUpperCase().localeCompare(b.data.ProviderName.toUpperCase());
        }
        this.sortAdvocate = false;
        return b.data.ProviderName.toUpperCase().localeCompare(a.data.ProviderName.toUpperCase());
      });
      break;

      case FIELD_INVOICE_NO:
        this.sortDate = false;
        this.sortAdvocate = false;
        this.sortMatter = false;
        this.sortAttorney = false;
        this.sortInvoiceNo = true;
        this.sortAging = false;
        this.sortDaysOutstanding = false;
        this.sortDue = false;
        this.sortPaid = false;
        this.sortAmount = false;
        this.sortReference = false;
        this.sortTransactionAmount = false;
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.InvoiceNo.toUpperCase().localeCompare(b.data.InvoiceNo.toUpperCase());
        }
        this.sortInvoiceNo = false;
        return b.data.InvoiceNo.toUpperCase().localeCompare(a.data.InvoiceNo.toUpperCase());
      });
      break;

      case FIELD_DAYS_OUTSTANDING:
        this.sortDate = false;
        this.sortAdvocate = false;
        this.sortMatter = false;
        this.sortAttorney = false;
        this.sortInvoiceNo = false;
        this.sortAging = false;
        this.sortDaysOutstanding = true;
        this.sortDue = false;
        this.sortPaid = false;
        this.sortAmount = false;
        this.sortReference = false;
        this.sortTransactionAmount = false;
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Reference.toUpperCase().localeCompare(b.data.Reference.toUpperCase());
        }
        this.sortDaysOutstanding = false;
        return b.data.Reference.toUpperCase().localeCompare(a.data.Reference.toUpperCase());
      });
      break;

      case FIELD_MATTER:
        this.sortDate = false;
        this.sortAdvocate = false;
        this.sortMatter = true;
        this.sortAttorney = false;
        this.sortInvoiceNo = false;
        this.sortAging = false;
        this.sortDaysOutstanding = false;
        this.sortDue = false;
        this.sortPaid = false;
        this.sortAmount = false;
        this.sortReference = false;
        this.sortTransactionAmount = false;
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.ServiceDescription.toUpperCase().localeCompare(b.data.ServiceDescription.toUpperCase());
        }
        this.sortMatter = false;
        return b.data.ServiceDescription.toUpperCase().localeCompare(a.data.ServiceDescription.toUpperCase());
      });
      break;

      case FIELD_ATTORENY:
        this.sortDate = false;
        this.sortAdvocate = false;
        this.sortMatter = false;
        this.sortAttorney = true;
        this.sortInvoiceNo = false;
        this.sortAging = false;
        this.sortDaysOutstanding = false;
        this.sortDue = false;
        this.sortPaid = false;
        this.sortAmount = false;
        this.sortReference = false;
        this.sortTransactionAmount = false;
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.AttorneyName.toUpperCase().localeCompare(b.data.AttorneyName.toUpperCase());
        }
        this.sortAttorney = false;
        return b.data.AttorneyName.toUpperCase().localeCompare(a.data.AttorneyName.toUpperCase());
      });
      break;

      case FIELD_REFERENCE:
        this.sortDate = false;
        this.sortAdvocate = false;
        this.sortMatter = false;
        this.sortAttorney = false;
        this.sortInvoiceNo = false;
        this.sortAging = false;
        this.sortDaysOutstanding = false;
        this.sortDue = false;
        this.sortPaid = false;
        this.sortAmount = false;
        this.sortReference = true;
        this.sortTransactionAmount = false;
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Reference.toUpperCase().localeCompare(b.data.Reference.toUpperCase());
        }
        this.sortReference = false;
        return b.data.Reference.toUpperCase().localeCompare(a.data.Reference.toUpperCase());
      });
      break;

      case FIELD_TOTAL:
        this.sortDate = false;
        this.sortAdvocate = false;
        this.sortMatter = false;
        this.sortAttorney = false;
        this.sortInvoiceNo = false;
        this.sortAging = false;
        this.sortDaysOutstanding = false;
        this.sortDue = false;
        this.sortPaid = false;
        this.sortAmount = true;
        this.sortReference = false;
        this.sortTransactionAmount = false;
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Total - b.data.Total;
        }
        this.sortAmount = false;
        return b.data.Total - a.data.Total;
      });
      break;

      case FIELD_TRANSACTION_AMOUNT:
        this.sortDate = false;
        this.sortAdvocate = false;
        this.sortMatter = false;
        this.sortAttorney = false;
        this.sortInvoiceNo = false;
        this.sortAging = false;
        this.sortDaysOutstanding = false;
        this.sortDue = false;
        this.sortPaid = false;
        this.sortAmount = false;
        this.sortReference = false;
        this.sortTransactionAmount = true;
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Total - b.data.Total;
        }
        this.sortTransactionAmount = false;
        return b.data.Total - a.data.Total;
      });
      break;

      case FIELD_PAID:
        this.sortDate = false;
        this.sortAdvocate = false;
        this.sortMatter = false;
        this.sortAttorney = false;
        this.sortInvoiceNo = false;
        this.sortAging = false;
        this.sortDaysOutstanding = false;
        this.sortDue = false;
        this.sortPaid = true;
        this.sortAmount = false;
        this.sortReference = true;
        this.sortTransactionAmount = false;
        this.advocateStatementHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.Paid - b.data.Paid;
          }
          this.sortPaid = false;
          return b.data.Paid - a.data.Paid;
        });
      break;

      case FIELD_DUE:
        this.sortDate = false;
        this.sortAdvocate = false;
        this.sortMatter = false;
        this.sortAttorney = false;
        this.sortInvoiceNo = false;
        this.sortAging = false;
        this.sortDaysOutstanding = false;
        this.sortDue = true;
        this.sortPaid = false;
        this.sortAmount = false;
        this.sortReference = true;
        this.sortTransactionAmount = false;
        this.advocateStatementHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.Due - b.data.Due;
          }
          this.sortDue = false;
          return b.data.Due - a.data.Due;
        });
      break;

      case FIELD_AGING:
        this.sortDate = false;
        this.sortAdvocate = false;
        this.sortMatter = false;
        this.sortAttorney = false;
        this.sortInvoiceNo = false;
        this.sortAging = true;
        this.sortDaysOutstanding = false;
        this.sortDue = false;
        this.sortPaid = false;
        this.sortAmount = false;
        this.sortReference = true;
        this.sortTransactionAmount = false;
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.PaymentTerms.toUpperCase().localeCompare(b.data.PaymentTerms.toUpperCase());
        }
        this.sortAging = false;
        return b.data.PaymentTerms.toUpperCase().localeCompare(a.data.PaymentTerms.toUpperCase());
      });
      break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleSort()

  orderStatement() {
    switch (this.orderBy) {

      case FIELD_DATE:
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.InvoiceDate.toUpperCase().localeCompare(b.data.InvoiceDate.toUpperCase());
        }
        return b.data.InvoiceDate.toUpperCase().localeCompare(a.data.InvoiceDate.toUpperCase());
      });
      break;

      case FIELD_INVOICE_NO:
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.InvoiceNo.toUpperCase().localeCompare(b.data.InvoiceNo.toUpperCase());
        }
        return b.data.InvoiceNo.toUpperCase().localeCompare(a.data.InvoiceNo.toUpperCase());
      });
      break;

      case FIELD_REFERENCE:
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Reference.toUpperCase().localeCompare(b.data.Reference.toUpperCase());
        }
        return b.data.Reference.toUpperCase().localeCompare(a.data.Reference.toUpperCase());
      });
      break;

      case FIELD_MATTER:
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.ServiceDescription.toUpperCase().localeCompare(b.data.ServiceDescription.toUpperCase());
        }
        return b.data.ServiceDescription.toUpperCase().localeCompare(a.data.ServiceDescription.toUpperCase());
      });
      break;

      // case FIELD_ATTORENY:
      // this.advocateStatementHandle.sort((a, b) => {
      //   if (this.sortAscending) {
      //     return a.data.AttorneyName.toUpperCase().localeCompare(b.data.AttorneyName.toUpperCase());
      //   }
      //   return b.data.AttorneyName.toUpperCase().localeCompare(a.data.AttorneyName.toUpperCase());
      // });
      // break;

      case FIELD_ADVOCATE:
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.ProviderName.toUpperCase().localeCompare(b.data.ProviderName.toUpperCase());
        }
        return b.data.ProviderName.toUpperCase().localeCompare(a.data.ProviderName.toUpperCase());
      });
      break;

      case FIELD_TOTAL:
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.Total - b.data.Total;
        }
        return b.data.Total - a.data.Total;
      });
      break;

      case FIELD_PAID:
        this.advocateStatementHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.Paid - b.data.Paid;
          }
          return b.data.Paid - a.data.Paid;
        });
      break;

      case FIELD_DUE:
        this.advocateStatementHandle.sort((a, b) => {
          if (this.sortAscending) {
            return a.data.Due - b.data.Due;
          }
          return b.data.Due - a.data.Due;
        });
      break;

      case FIELD_AGING:
      this.advocateStatementHandle.sort((a, b) => {
        if (this.sortAscending) {
          return a.data.PaymentStatus.toUpperCase().localeCompare(b.data.PaymentStatus.toUpperCase());
        }
        return b.data.PaymentStatus.toUpperCase().localeCompare(a.data.PaymentStatus.toUpperCase());
      });
      break;
    }
  } // end orderStatement()

  toggleAscending() {
    this.sortAscending = !this.sortAscending;
    this.orderStatement();
  } // end toggleAscending()

  filterStatement() {
    if (this.activeTab === 'paid') {
      if (this.searchValue) {
        this.lawfirmPaidStatementHandle.forEach(statement => {
          if (
            !(statement.data.AdvocateName.match(new RegExp(this.searchValue, 'gi'))) &&
            !(statement.data.TransactionDescription.match(new RegExp(this.searchValue, 'gi'))) &&
            !(statement.data.LawfirmStatementDTO
              .find(_data => Boolean(_data.ServiceDescription.match(new RegExp(this.searchValue, 'gi'))))) &&
            !(statement.data.LawfirmStatementDTO
              .find(_data => Boolean(_data.Reference.match(new RegExp(this.searchValue, 'gi'))))) &&
            !(statement.data.LawfirmStatementDTO
              .find(_data => Boolean(_data.InvoiceNo.match(new RegExp(this.searchValue, 'gi')))))
          ) {
            statement.state.isVisible = false;
          } else {
            statement.state.isVisible = true;
          }
        });
      } else {
        this.lawfirmPaidStatementHandle.forEach(statement => {
          statement.state.isVisible = true;
        });
        this.lawfirmPaidStatementHandle.sort((a, b) => {
          return b.data.TransactionDate.toUpperCase().localeCompare(a.data.TransactionDate.toUpperCase());
        });
        if (this.lawfirmPaidStatementHandle) {
          this.selectedRow(this.lawfirmPaidStatementHandle[0].data);
        }
      }
      if (this.lawfirmPaidStatementHandle.filter(statement => statement.state.isVisible === true).length === 1) {
        this.selectedRow(this.lawfirmPaidStatementHandle.filter(statement => statement.state.isVisible === true)[0].data);
      }
    } else {
      if (this.searchValue) {
        this.advocateStatementHandle.forEach(statement => {
          if (
            !(statement.data.ServiceDescription.match(new RegExp(this.searchValue, 'gi'))) &&
            ! (statement.data.InvoiceNo.match(new RegExp(this.searchValue, 'gi'))) &&
            ! (statement.data.AttorneyName.match(new RegExp(this.searchValue, 'gi'))) &&
            !(statement.data.Reference.match(new RegExp(this.searchValue, 'gi'))) &&
            ! (statement.data.ProviderFullName.match(new RegExp(this.searchValue, 'gi')))
            // ! (statement.data.AttorneyName.match(new RegExp(this.searchValue, 'gi')))
          ) {
            statement.state.isVisible = false;
          } else {
          statement.state.isVisible = true;
          }
        });
      } else {
        this.advocateStatementHandle.forEach(statement => {
          statement.state.isVisible = true;
        });
      }
    }
  } // end filterStatement()

  clearSearch() {
    this.searchValue = null;
    this.advocateStatementHandle.forEach(statement => {
      statement.state.isVisible = true;
    });
  } // end clearSearch()

  convertToCurrency(amount: string): string {
    let amountValue = '';
    if (this.userProfileService.userProfile.isImpersonator) {
      amountValue = this.cpipe.transform(amount,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      amountValue = this.cpipe.transform(amount,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
    return amountValue;
  } // end convertToCurrency()

  generateReport() {
    this.loadingService.showOverlay();
    this.exportStatement = [];
    // Bind data
    this.advocateStatementHandle.forEach(data => {
      if (data.state.isVisible) {
        const exportDataModal = {
          'Advocate Name': data.data.ProviderFullName,
          'Invoice Date': moment(data.data.InvoiceDate).format('YYYY-MM-DD'),
          'Invoice Number': data.data.InvoiceNo,
          'Matter Description': data.data.ServiceDescription,
          'Reference': data.data.Reference,
          'Attorney Name': data.data.AttorneyName,
          'Due Amount': this.convertToCurrency(data.data.Due.toString()),
          'Paid Amount': this.convertToCurrency(data.data.Paid.toString()),
          'Total Amount': this.convertToCurrency(data.data.Total.toString()),
          'Terms': data.data.PaymentTerms,
          'Total Days Outstanding': data.data.TotalDaysOverdue,
        };
        this.exportStatement.push(exportDataModal);
      }
    });
    // Call api to generate Excel report
    this.http.post(this.excelURL, this.exportStatement, {
      responseType: 'arraybuffer'
    }).subscribe({ next: (response) => this.advocateDetailService
      .downloadExcelFile(response, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
      }
    });
  } // end generateReport()

  togglePopupMenu() {

  } // end togglePopupMenu()

  getCellColor(data: AdvocateDetailStatement): string {
    if (data.AttorneyName === '-') {
      return 'transparent';
    }
  } // end getCellColor()

  onViewBriefDocuments(data: AdvocateDetailStatement) {
    if (data.HasBrief) {
      this.briefDocumentHandle = [];

      this.loadingService.showOverlay();
      // this.briefDocuments =  this.mattersService.getAttorneyDocumentList(data.ServiceProviderId, data.ServiceId).toPromise();
      this.briefDocuments.forEach(doc => {
        this.briefDocumentHandle.push({
          data: doc,
          state: {
            isVisible: doc.Status,
            isSelected: false,
            isOpen: false
          }
        });
      });
      this.showBriefDocumentModal = true;
    }
    this.loadingService.hideOverlay();
  } // end onViewBriefDocuments()

  onHideHistoricalPrompt() {
    this.showHistoricalPrompt = false;
  } // end onHideHistoricalPrompt()

  onStatementFromDateChange(value: Date): void {
    if (value) {
      this.statementParameters.fromDate = moment(value).format('YYYY-MM-DD');
      // this.statementParameters.toDate = moment(value).format('YYYY-MM-DD');
      if (this.statementParameters.fromDate && this.statementParameters.toDate) {
        this.lawfirmPaidStatementHandle.forEach(statement => {
          if (moment(statement.data.TransactionDate).format('YYYY-MM-DD') >=
            moment(this.statementParameters.fromDate).format('YYYY-MM-DD') &&
            moment(statement.data.TransactionDate).format('YYYY-MM-DD') <=
            moment(this.statementParameters.toDate).format('YYYY-MM-DD')) {
            statement.state.isVisible = true;
          } else {
            statement.state.isVisible = false;
          }
        });

        this.downloadLink = this.api.endpoints.reconciliationHistoryByLawfirmReport +
          '&fromDate=' + new Date(this.statementParameters.fromDate).toDateString() +
          '&toDate=' + new Date(this.statementParameters.toDate).toDateString() +
          '&ServiceProviderId=' + this.advocateId +
          '&LawFirmId=' + this.lawfirmId;
      }
    }
  } // end onStatementFromDateChange()

  onStatementToDateChange(value: Date): void {
    if (value) {
      this.statementParameters.toDate = moment(value).format('YYYY-MM-DD');
      if (this.statementParameters.fromDate && this.statementParameters.toDate) {
        this.lawfirmPaidStatementHandle.forEach(statement => {
          if (moment(statement.data.TransactionDate).format('YYYY-MM-DD') >=
            moment(this.statementParameters.fromDate).format('YYYY-MM-DD') &&
            moment(statement.data.TransactionDate).format('YYYY-MM-DD') <=
            moment(this.statementParameters.toDate).format('YYYY-MM-DD')) {
            statement.state.isVisible = true;
          } else {
            statement.state.isVisible = false;
          }
        });

      this.downloadLink = this.api.endpoints.reconciliationHistoryByLawfirmReport +
        '&fromDate=' + new Date(this.statementParameters.fromDate).toDateString() +
        '&toDate=' + new Date(this.statementParameters.toDate).toDateString() +
        '&ServiceProviderId=' + this.advocateId +
        '&LawFirmId=' + this.lawfirmId;
      }
    }
  } // end onStatementToDateChange()

  onHideReconHistoricalPrompt() {
    this.showReconHistoricalPrompt = false;
  } // end onHideReconHistoricalPrompt()
} // end AdvocateDetailTableComponent{}
