import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AttorneysService } from '../../../services/attorneys/attorneys.service';
import { DEFAULT_MATTER, Matter, MattersService } from '../../../services/matters/matters.service';
import { UserProfile, UserProfileService } from '../../../services/user-profile/user-profile.service';
import { LawFirm, ListsService } from '../../../services/lists/lists.service';
import { Plaintiff, PlaintiffsService } from '../../../services/plaintiffs/plaintiffs.service';
import { DefendantsService } from '../../../services/defendants/defendants.service';
import { PATH_DASHBOARD } from '../../../services/appdata/app-config.service';
import { FromCalendarItemsService } from '../../../services/from-calendar-items/from-calendar-items.service';
import { UsercurrencyPipe } from '../../../../app/pipe/usercurrency.pipe';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { IndexedDBService } from '../../../services/indexed-db/indexed-db.service';

@Component({
  selector: 'app-add-new-matter',
  templateUrl: './add-new-matter.component.html',
  styleUrls: ['./add-new-matter.component.scss']
})

export class AddNewMatterComponent implements OnInit {

  @Input() isVisible: boolean;
  @Input() EventDetails: any;
  @Output() closeNewMatterModal: EventEmitter<any> = new EventEmitter();
  @Output() matterDefendantsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterPlaintiffsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterOtherClientsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterAttorneyChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('inputHourly') focusHourly: ElementRef;
  @ViewChild('inputDaily') focusDaily: ElementRef;
  @ViewChild('inputPerKilometer') focusPerKilometer: ElementRef;

  lawfirmRatingsData: any;
  avaragePaymentRating: any;
  paymentTerms: any;
  matter: Matter = Object.assign({}, DEFAULT_MATTER);
  attorneyRefs: string[] = [];
  instructions: string[] = [];

  _matters: Matter[];
  lawFirms: LawFirm[] = [];
  attorneys: string[] = [];
  plaintiffs: Plaintiff[] = [];
  defendants: string[] = [];
  showMatterExistsPrompt: boolean = false;
  userProfile: UserProfile;
  NewMatterModel = {
    Date: '',
    Hourlyrate: '',
    Dailyrate: '',
    PerkilometerRate: '',
    PaymentTerm: 0,
    AttorneyReference: '',
    Instructions: '',
    DateOfAppearance: '',
    CaseNumber: ''
  }
  showUnsavedChangesPrompt: boolean = false;
  DefaultPaymentTerm: number;

  get isTheStateAttorney(): boolean {
    const state = '^The State Attorney';
    return new RegExp(state, 'i').test(this.matter.InternalRefNo);
  }

  get isSaveable(): boolean {
    return Boolean(this.matter.InternalRefNo)
      && Boolean(this.matter.PlaintiffFullName)
      && Boolean(this.NewMatterModel.Hourlyrate)
      && Boolean(this.NewMatterModel.Dailyrate)
      && Boolean(this.NewMatterModel.PaymentTerm)
      && Boolean(this.NewMatterModel.AttorneyReference)
  }

  get currencySymbol(): string {
    return this.userProfileService.userProfile.isImpersonator ? this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ' : this.userProfileService.userProfile.currencyDetails.symbol + ' ';
  }

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  constructor(
    private router: Router,
    private attorneysService: AttorneysService,
    private userProfileService: UserProfileService,
    private mattersService: MattersService,
    private listsService: ListsService,
    private plaintiffsService: PlaintiffsService,
    private defendantsService: DefendantsService,
    private fromCalendarItemsService: FromCalendarItemsService,
    private cpipe: UsercurrencyPipe,
    private snackbarsService: SnackbarsService,
    private loadingService: LoadingService,
    private indexedDbService: IndexedDBService) {
  }

  ngOnInit() {
    this.paymentTerms = [
      { id: 0, name: '0 Days' },
      { id: 30, name: '30 Days' },
      { id: 60, name: '60 Days' },
      { id: 90, name: '90 Days' },
      { id: 97, name: '97 Days' },
      { id: 98, name: '98 Days' },
      { id: 99, name: 'Contingency' }
    ];
    
    if (this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER) {
      this.NewMatterModel.PaymentTerm = +this.userProfileService.selectedUserProfile.settings.aging;
      this.NewMatterModel.Hourlyrate = this.userProfileService.selectedUserProfile.billingDetails.defaultHourlyRate ? this.userProfileService.selectedUserProfile.billingDetails.defaultHourlyRate.toString() : "0";
      this.NewMatterModel.Dailyrate = this.userProfileService.selectedUserProfile.billingDetails.defaultDailyRate ? this.userProfileService.selectedUserProfile.billingDetails.defaultDailyRate.toString() : "0";
      this.NewMatterModel.PerkilometerRate = this.userProfileService.selectedUserProfile.billingDetails.defaultPerKilometerRate ? this.userProfileService.selectedUserProfile.billingDetails.defaultPerKilometerRate.toString() : "0";
    }
    else {
      this.NewMatterModel.PaymentTerm = +this.userProfileService.userProfile.settings.aging;
      this.NewMatterModel.Hourlyrate = this.userProfileService.userProfile.billingDetails.defaultHourlyRate ? this.userProfileService.userProfile.billingDetails.defaultHourlyRate.toString() : "0";
      this.NewMatterModel.Dailyrate = this.userProfileService.userProfile.billingDetails.defaultDailyRate ? this.userProfileService.userProfile.billingDetails.defaultDailyRate.toString() : "0";
      this.NewMatterModel.PerkilometerRate = this.userProfileService.userProfile.billingDetails.defaultPerKilometerRate ? this.userProfileService.userProfile.billingDetails.defaultPerKilometerRate.toString() : "0";
    }

    this.indexedDbService.getData("lawFirms", 'lawFirmsData').then((data) => {
      if (data)
        this.lawFirms = data.data;
      else
        this.lawFirms = [];
    }).catch((error) => {
      this.lawFirms = [];
    });

    this.mattersService.getMatters().subscribe({
      next: (matters) => {
        this.matter = matters;
        this._matters = matters;
        this.listsService.buildListsFromMatter(matters).subscribe(matter => {
          this.attorneys = matter.attorneys;
          this.attorneyRefs = matter.attorneyRefs;
          this.instructions = matter.instructions;
        });
        this.plaintiffsService.getPlaintiffs().subscribe(plaintiffs => {
          plaintiffs.sort((a, b) => {
            return a.FullName.toUpperCase().localeCompare(b.FullName.toUpperCase());
          });
          this.plaintiffs = plaintiffs;
        });
        this.defendantsService.getDefendants().subscribe(defendants => {
          defendants.sort((a, b) => {
            return a.FullName.toUpperCase().localeCompare(b.FullName.toUpperCase());
          });
          this.defendants = defendants;
        });
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
    this.userProfile = this.userProfileService.userProfile.isImpersonator ? this.userProfileService.selectedUserProfile : this.userProfileService.userProfile;
  }

  onDone() {
    const snack: Snack = {
      label: 'Saving matter...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, 5000).show();
    this.loadingService.showOverlay();
    let model = {
      ServiceProviderID: this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER ? this.userProfileService.selectedUserProfile.serviceProviderID : this.userProfileService.userProfile.serviceProviderID,
      Date: moment(this.EventDetails.Date).format("yyyy-MM-DD"),
      Lawfirms: this.matter.ContactID,
      Attorneys: this.matter.ClientContactID,
      Plaintiffs: this.matter.PlaintiffFullName,
      PlaintiffContactID: this.matter.PlaintiffContactID,
      Defendants: this.matter.DefendantFullName,
      DefendantContactID: this.matter.DefendantContactID,
      HourlyRate: this.NewMatterModel.Hourlyrate,
      DailyRate: this.NewMatterModel.Dailyrate,
      PerkilometerRate: this.NewMatterModel.PerkilometerRate,
      PaymentTerm: this.NewMatterModel.PaymentTerm,
      AttorneyReference: this.NewMatterModel.AttorneyReference,
      Instructions: this.NewMatterModel.Instructions,
      DateOfAppearance: this.NewMatterModel.DateOfAppearance,
      CaseNumber: this.NewMatterModel.CaseNumber
    }
    this.fromCalendarItemsService.saveNewMatter(model).subscribe({
      next: (res) => {
        let selectedMatter: Matter = res;
        this.onClose(selectedMatter, false);
      },
      error: (error) => {
        const snack: Snack = {
          label: 'Somthing went wrong',
          type: SnackType.ERROR,
          action: null
        };
        this.snackbarsService.snackbarComponent.make(snack, 5000).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        const snack: Snack = {
          label: 'Matter saved',
          action: null
        };
        this.snackbarsService.snackbarComponent.make(snack, 5000).show();
        this.loadingService.hideOverlay();
      }
    });
  }

  onClose(event: any, isCloseClick: boolean) {
    if (isCloseClick)
      this.onShowUnsavedChangesPrompt();
    else {
      this.reset();
      this.closeNewMatterModal.emit(event);
    }
  }

  onDateChange(event) {
    this.NewMatterModel.DateOfAppearance = moment(event).format("yyyy-MM-DD");
  }

  onModelChange(type: string, contact: any) {
    switch (type) {
      case 'law-firms':
        if (contact.ContactID) {
          this.attorneysService.getLawfirmRating(contact.ContactID).subscribe({
            next: (avarage_rating) => {
              if (avarage_rating) {
                this.lawfirmRatingsData = avarage_rating;
                this.avaragePaymentRating = avarage_rating.AverageDaysToPay;
              }
            },
            error: (error) => {
            },
            complete: () => {
            }
          });
          this.attorneysService.getAttorneysByLawFirms(contact.ContactID).subscribe({
            next: (contacts) => {
              this.attorneys = contacts;
            },
            error: (error) => {
            },
            complete: () => {
              if (contact.FullName) {
                this.matter.ClientContactID = 0;
                this.matter.NewClientDetail = '';
              }
            }
          });
        } else {
          contact.FullName = '';
          contact.ContactID = 0;
        }
        if (this.isTheStateAttorney && this.userProfileService.userProfile.isImpersonator)
          this.matter.MAAA = this.userProfileService.selectedUserProfile.settings.maaa;
        else if (this.isTheStateAttorney && !this.userProfileService.userProfile.isImpersonator)
          this.matter.MAAA = this.userProfile.settings.maaa;
        break;
      case 'attorneys':
        this.matter.ClientContactID = contact.ContactID;
        this.matterAttorneyChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        break;
      case 'plaintiffs':
        this.matter.PlaintiffContactID = contact.ContactID;
        this.matterPlaintiffsChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        break;
      case 'defendants':
        this.matter.DefendantContactID = contact.ContactID;
        this.matterDefendantsChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        break;
      case 'attorney-reference':
        this.matter.ClientRefNo = contact.FullName;
        contact.FullName = '';
        contact.ContactID = 0;
        break;
      case 'instructions':
        this.matter.Instructions = contact.FullName;
        contact.FullName = '';
        contact.ContactID = 0;
        break;
    }
  }

  onReferenceChangeCheck() {
    const ref_matter = this._matters.filter(a => a.ClientRefNo === this.NewMatterModel.AttorneyReference);
    if (ref_matter.length > 0) {
      this.showMatterExistsPrompt = true;
    }
  }

  onHideMatterExistsPrompt() {
    this.showMatterExistsPrompt = false;
  }

  navigateToDashboard() {
    this.matter = Object.assign({}, this.matter)
    this.router.navigate([PATH_DASHBOARD]);
    this.onHideMatterExistsPrompt();
  }

  onHourlyRateChange() {
    this.NewMatterModel.Hourlyrate = this.focusHourly.nativeElement.value;
    if (this.NewMatterModel.Hourlyrate) {
      this.NewMatterModel.Dailyrate = "";
      this.NewMatterModel.Dailyrate += this.NewMatterModel.Hourlyrate + "0";
      this.focusDaily.nativeElement.value = "";
      this.focusDaily.nativeElement.value += this.NewMatterModel.Hourlyrate + "0"
    }
    else
      this.NewMatterModel.Dailyrate = this.userProfileService.userProfile.billingDetails.defaultDailyRate.toString();

    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusHourly.nativeElement.value =
        this.cpipe.transform(this.focusHourly.nativeElement.value,
          this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.focusHourly.nativeElement.value =
        this.cpipe.transform(this.focusHourly.nativeElement.value,
          this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  }

  onHourlyRateClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusHourly.nativeElement.value =
        this.cpipe.parse(this.focusHourly.nativeElement.value,
          this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusHourly.nativeElement.value =
        this.cpipe.parse(this.focusHourly.nativeElement.value,
          this.userProfileService.userProfile.currencyDetails.symbol);
    }
  }

  onDailyRateChange() {
    this.NewMatterModel.Dailyrate = this.focusDaily.nativeElement.value;
    if (!this.focusDaily.nativeElement.value.startsWith('R')) {
      if (this.userProfileService.userProfile.isImpersonator) {
        this.focusDaily.nativeElement.value =
          this.cpipe.transform(this.focusDaily.nativeElement.value,
            this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
      } else {
        this.focusDaily.nativeElement.value =
          this.cpipe.transform(this.focusDaily.nativeElement.value,
            this.userProfileService.userProfile.currencyDetails.symbol, 2);
      }
    }
  }

  onDailyRateClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusDaily.nativeElement.value =
        this.cpipe.parse(this.focusDaily.nativeElement.value,
          this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusDaily.nativeElement.value =
        this.cpipe.parse(this.focusDaily.nativeElement.value,
          this.userProfileService.userProfile.currencyDetails.symbol);
    }
  }

  onPerkilometerRateChange() {
    this.NewMatterModel.PerkilometerRate = this.focusPerKilometer.nativeElement.value;
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusPerKilometer.nativeElement.value =
        this.cpipe.transform(this.focusPerKilometer.nativeElement.value,
          this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.focusPerKilometer.nativeElement.value =
        this.cpipe.transform(this.focusPerKilometer.nativeElement.value,
          this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  }

  onPerkilometerRateClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusPerKilometer.nativeElement.value =
        this.cpipe.parse(this.focusPerKilometer.nativeElement.value,
          this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusPerKilometer.nativeElement.value =
        this.cpipe.parse(this.focusPerKilometer.nativeElement.value,
          this.userProfileService.userProfile.currencyDetails.symbol);
    }
  }

  onShowUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = true;
  }

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  }

  dismiss() {
    this.showUnsavedChangesPrompt = false;
    this.reset();
    this.closeNewMatterModal.emit();
  }

  onSave() { this.onHideUnsavedChangesPrompt(); }

  reset() {
    this.matter.InternalRefNo = "";
    this.matter.NewClientDetail = "";
    this.matter.PlaintiffFullName = "";
    this.matter.DefendantFullName = "";

    if (this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER) {
      this.NewMatterModel = {
        Date: '',
        Hourlyrate: this.userProfileService.selectedUserProfile.billingDetails.defaultHourlyRate ? this.userProfileService.selectedUserProfile.billingDetails.defaultHourlyRate.toString() : "0",
        Dailyrate: this.userProfileService.selectedUserProfile.billingDetails.defaultDailyRate ? this.userProfileService.selectedUserProfile.billingDetails.defaultDailyRate.toString() : "0",
        PerkilometerRate: this.userProfileService.selectedUserProfile.billingDetails.defaultPerKilometerRate ? this.userProfileService.selectedUserProfile.billingDetails.defaultPerKilometerRate.toString() : "0",
        PaymentTerm: +this.userProfileService.selectedUserProfile.settings.aging,
        AttorneyReference: '',
        Instructions: '',
        DateOfAppearance: '',
        CaseNumber: ''
      }
    }
    else {
      this.NewMatterModel = {
        Date: '',
        Hourlyrate: this.userProfileService.userProfile.billingDetails.defaultHourlyRate ? this.userProfileService.userProfile.billingDetails.defaultHourlyRate.toString() : "0",
        Dailyrate: this.userProfileService.userProfile.billingDetails.defaultDailyRate ? this.userProfileService.userProfile.billingDetails.defaultDailyRate.toString() : "0",
        PerkilometerRate: this.userProfileService.userProfile.billingDetails.defaultPerKilometerRate ? this.userProfileService.userProfile.billingDetails.defaultPerKilometerRate.toString() : "0",
        PaymentTerm: +this.userProfileService.userProfile.settings.aging,
        AttorneyReference: '',
        Instructions: '',
        DateOfAppearance: '',
        CaseNumber: ''
      }
    }
  }
}
