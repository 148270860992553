<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
      <div class="modal-dialog">
  
        <div class="modal-header">
          <h2>{{title}}</h2>
          <button class="modal-dismiss" (click)="onCancel()">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!--#end .modal-header -->
  
        <div class="modal-body">
          <div class="container-fluid">
            <div class="col-md-3" class="form-group" >
                <label>Status Update</label>
                  <div class="input-group">
                    <select
                      *ngIf="wordingDisputStatues.Status !== 'Invoice date amendments' && wordingDisputStatues.Status !== 'Reference amendments'"
                        class="form-control"
                        id="bank-name"
                        [(ngModel)]="disputStatues.Status">
                        <option [value]="null">Select Status</option>
                        <option
                            *ngFor="let status of invoiceLineDisputeList"
                            [value]="status.value"
                            [selected]="status.value === disputStatues.Status">
                            {{status.label}}</option>
                    </select>

                    <input
                    *ngIf="wordingDisputStatues.Status === 'Invoice date amendments' || wordingDisputStatues.Status === 'Reference amendments'"
                          class="form-control"
                          type="text"
                          id="invoice-status-note"
                          [(ngModel)]="disputStatues.Status"
                          autocomplete="nocxua"
                          readonly>
                  </div>
               </div>

               <div class="form-group">
                <label for="id-number">Note:</label>
                <textarea
                  *ngIf="wordingDisputStatues.Status !== 'Invoice date amendments' && wordingDisputStatues.Status !== 'Reference amendments'"
                  class="form-control"
                  type="text"
                  id="invoice-status-note"
                  [(ngModel)]="disputStatues.Note"
                  autocomplete="nocxua"></textarea>

                  <input
                  *ngIf="wordingDisputStatues.Status === 'Invoice date amendments' || wordingDisputStatues.Status === 'Reference amendments'"
                        class="form-control"
                        type="text"
                        id="invoice-status-note"
                        [(ngModel)]="disputStatues.Note"
                        autocomplete="nocxua"
                        readonly>
              </div>
          </div> <!-- #end .container-fluid -->
        </div>
        <!--#end .modal-body -->
  
        <div class="modal-footer">
          <button class="btn btn-danger" (click)="onCancel()">Cancel</button>
          <button
          [class.disabled]="!isDirty"
          [disabled]="!isDirty"
          class="btn btn-success"
          (click)="onInvoiceDispute()">Dispute</button>
          <!-- <span></span> -->
          <!-- <span></span>
          <button class="btn btn-info" (click)="onConfirm()">{{btnTextConfirm}}</button> -->
        </div>
        <!--#end .modal-footer -->
  
      </div>
      <!--#end .modal-dialog -->
    </div>
    <!--#end .modal-content -->
  </div>
  <!--#end .modal -->