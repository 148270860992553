import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-live-statement-footer-popup-menu',
  templateUrl: './live-statement-footer-popup-menu.component.html',
  styleUrls: ['./live-statement-footer-popup-menu.component.scss']
})
export class LiveStatementFooterPopupMenuComponent implements OnInit {

  @Input() isVisible: boolean;
  @Output() dismiss: EventEmitter<any> = new EventEmitter();
  @Output() ongenerateExcel: EventEmitter<any> = new EventEmitter();

  constructor() { } // end constructor()

  ngOnInit() {
    this.isVisible = false;
  } // end ngOnInit()

  onDismiss() {
    this.dismiss.emit();
  }

  onGenerateExcelClicked() {
    this.ongenerateExcel.emit('generate-excel');
    this.onDismiss();
  } // end onGenerateExcelClicked()

} // end LiveStatementFooterPopupMenuComponent{}
