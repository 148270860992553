<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button
          class="modal-dismiss"
          (click)="onCancel()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body">

        <div class="row">
          <div class="form-group">
            <label for="invoice-matter"><b>Matter</b></label>
            <!-- <label
              class="form-control"
              id="invoice-matter">{{individualCustomerData.matter}}</label> -->
              <input
                class="form-group"
                readonly
                class="form-control"
                id="invoice-matter"
                [title]="individualCustomerData.matter"
                [ngModel]="individualCustomerData.matter">
          </div>
          <div class="form-group">
            <label for="invoice-number"><b>Invoice Number</b></label>
            <label
              class="form-control"
              id="invoice-number">{{individualCustomerData.invoiceNumber}}</label>
          </div>
        </div> <!-- #end .row -->

        <div class="row">
          <div class="form-group">
            <label for="amount-due"><b>Amount Due</b></label>
            <label
              class="form-control"
              id="amount-due">{{individualCustomerData.due | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
          </div>
          <div class="form-group">
            <label for="date-due"><b>Due Date</b></label>
            <input 
              readonly
              type="text" 
              id="date-due" 
              class="form-control"
              bsDatepicker 
              [bsValue]="individualCustomerData.discountingDueDate"
              (ngModel)="individualCustomerData.discountingDueDate=$event.target.value" 
              (bsValueChange)="onDateValueChange($event)"
              [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}" 
              placement="right">
          </div>
        </div> <!-- #end .row -->

      </div> <!-- #end .modal-body -->

      <div class="modal-footer">
        <button
          style="width: 25%;"
          class="btn btn-danger"
          (click)="onCancel()">Cancel</button>
        <button
          style="width: 25%;"
          class="btn btn-success"
          [class.disabled]="!isSaveable"
          [disabled]="!isSaveable"
          (click)="onSave()">Save</button>
      </div> <!-- #end .modal-footer -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->