<div class="overview-container">
  <!-- <h2 class="chart-summary-heading">overview</h2> -->
  <div class="overview-summary overview-summary-top-row">
    <div class="overview-child-block overview-child-block-right">
      <div class="overview-child-head" [ngStyle]="{'background-color': getTotalInvoicedColor}">
        Invoiced Targets
      </div>
      <div class="overview-child-body">
        <h1 class="average-days overview-title" [ngStyle]="{'color': getTotalInvoicedColor}">{{invoiceTarget}}%
        </h1><!-- {{averagePaymentDays}} -->
        <p>Below monthly target</p>
      </div>
    </div>
    <div class="overview-child-block">
      <div class="overview-child-head" [ngStyle]="{'background-color': getTotalCollectableColor}">
        Collection Targets
      </div>
      <div class="overview-child-body">
        <h1 class="projected-collect overview-title" [ngStyle]="{'color': getTotalCollectableColor}">
          {{collectedTarget}}%</h1>
        <p>Below monthly target</p>
      </div>
    </div>
  </div>
  <div class="overview-summary">
    <div class="overview-child-block overview-child-block-right">
      <div class="overview-child-head" [ngStyle]="{'background-color': getTotalInvoicedColor}">
        Total Invoiced
      </div>
      <div class="overview-child-body">
        <h1 class="projected-collect overview-title" [ngStyle]="{'color': getTotalInvoicedColor}">
          {{invoicedThisMonth | currency: currencySymbol}}</h1>
        <p><u>This month</u></p>
      </div>
    </div>
    <div class="overview-child-block">
      <div class="overview-child-head" [ngStyle]="{'background-color': getTotalCollectableColor}">
        Total Collectable
      </div>
      <div class="overview-child-body">
        <h1 class="action-needed-color overview-title" [ngStyle]="{'color': getTotalCollectableColor}">
          {{totalCollactable | currency: currencySymbol}}</h1>
        <p><u>Collectable Amount</u></p>
      </div>
    </div>
  </div>
</div>