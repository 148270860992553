import { Injectable } from '@angular/core';
import { SnackbarComponent } from '../../../components/messaging/snackbar/snackbar.component';

export const LENGTH_SHORT = 3500;
export const LENGTH_LONG  = 5000;

export interface Snack {
  label: string;
  action: {
    label: string;
    handler: any;
    params: any;
  };
  type?: SnackType;
} // end Snack{}

export enum SnackType {
  SUCCESS = 'snackbar-success',
  ERROR = 'snackbar-danger',
  DEFAULT = 'snackbar-default',
  INFO = 'snackbar-info',
  WARNING = 'snackbar-warning',
  PRIMARY = 'snackbar-primary'
} // end SnackType{}

@Injectable()
export class SnackbarsService {
  queue: Snack[];
  snackbarComponent: SnackbarComponent;
  constructor() { } // end constructor()

  setSnackbarComponent(snackbarComponent: SnackbarComponent) {
    this.snackbarComponent = snackbarComponent;
  } // end setSnackbarComponent{}

  make(snack: Snack, duration: number = LENGTH_LONG): SnackbarsService {
    if (this.snackbarComponent) {
      this.snackbarComponent.make(snack, duration);
    }
    return this;
  } // end make()

  show(): SnackbarsService {
    if (this.snackbarComponent) {
      this.snackbarComponent.show();
    }
    return this;
  } // end show()

  dismiss(thisObj: any = null): SnackbarsService {
    if (this.snackbarComponent) {
      this.snackbarComponent.dismiss(thisObj);
    }
    return this;
  } // end dismiss()

} // end SnackbarsService{}
