import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { FileUploadService } from '../../../services/file-upload/file-upload.service';
import { MattersService, Matter, BriefDocuments } from '../../../services/matters/matters.service';
import { DocumentTypes } from '../../../services/fee-items/fee-items.service';

@Component({
  selector: 'app-upload-file-type-edit-modal',
  templateUrl: './upload-file-type-edit-modal.component.html',
  styleUrls: ['./upload-file-type-edit-modal.component.scss']
})
export class UploadFileTypeEditModalComponent implements OnInit {

  formData: FormData = new FormData();

  @Input() isVisible: boolean;
  @Input() title: string;
  @Input() btnTextCancel: string;
  @Input() btnTextAddFile: string;
  @Input() selectedDocumentType: number;
  @Input() documentTypes: DocumentTypes[];
  @Input() attachmentFile: File;
  @Input() workingMatter: Matter;
  @Input() selectedBriefDocuments: BriefDocuments;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() upload: EventEmitter<any> = new EventEmitter<any>();

  get canAddFile(): boolean {
    return Boolean(this.selectedDocumentType > 0);
  } // end canAddFile()

  constructor(
    private matterService: MattersService,
    private loadingService: LoadingService,
    private fileUploadService: FileUploadService
  ) { }

  ngOnInit() {
    if (!this.title) {
      this.title = 'Add document to matter';
    }

    if (!this.btnTextCancel) {
      this.btnTextCancel = 'Cancel';
    }

    if (!this.btnTextAddFile) {
      this.btnTextAddFile = 'Add document';
    }
  } // end ngOnInit()

  onCancel() {
    this.selectedDocumentType = 0;
    this.cancel.emit();
  } // end onCancel()

  onAddFile() {
    if (this.selectedDocumentType > 0) {
      this.loadingService.showOverlay();
      this.selectedBriefDocuments.DocumentTypeID = this.selectedDocumentType;
      this.upload.emit(this.selectedBriefDocuments);
    }
  } // end onAddFile()
}
