<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div class="page-nav">
  <nav class="overview">
    <a class="tab" *ngIf="!internalAssistant" [class.active]="tabOptions === 'Summary'" (click)="toggleTab('Summary')">Summary</a>
    <a class="tab" [class.active]="tabOptions === 'Details'" (click)="toggleTab('Details')">Details</a>
    <a class="tab" [class.active]="tabOptions === 'FinancialDetails'" (click)="toggleTab('FinancialDetails')">Financial Details</a>
    <a class="tab" [class.active]="tabOptions === 'ContactDetails'" (click)="toggleTab('ContactDetails')">Contact Details</a>
    <a class="tab" *ngIf="!internalAssistant" [class.active]="tabOptions === 'Subscription'" (click)="toggleTab('Subscription')">Subscription</a>
    <a class="tab" [class.active]="tabOptions === 'ProfileSettings'" (click)="toggleTab('ProfileSettings')">Profile Settings</a>
    <a class="tab" [class.active]="tabOptions === 'Communication'" (click)="toggleTab('Communication')">Communication</a>
    <a class="tab" [class.active]="tabOptions === 'ProfileInformation'" (click)="toggleTab('ProfileInformation')">Important Profile Info.</a>
    <a class="btn btn-success tabButton">
      <i class="fas fa-user-lock"></i>
      Working on behalf of {{userFirstName}}
    </a>
  </nav>
  <div *ngIf="tabOptions === 'Summary' && !internalAssistant" class="content-area summary">
    <div class="row">
      <app-advocate-overview-dashboard
      style="width: 32%;"
      [widgetData]="widgetData"
      [invoicedData]="invoicedData"
      [collectedData]="collectedData"
      [overdueByFirm]="overdueByFirm"></app-advocate-overview-dashboard>
      <app-overview-invoiced-chart
        style="width: 32%;"
        summaryTitle="Invoiced"
        summaryDescription="Invoiced matters"
        [dataMonthly]="invoicedData"></app-overview-invoiced-chart>
      <app-overview-collected-chart
        style="width: 32%;"
        summaryTitle="Collected"
        summaryDescription="Collected matters"
        [dataMonthly]="collectedData"></app-overview-collected-chart>
      </div>
      <div class="row" style="width:100%;margin-top:3px;">
        <app-overview-summary
        [overdueByFirm]="overdueByFirm"></app-overview-summary>
      </div>
  </div>
  <div *ngIf="tabOptions === 'Details'" class="content-area details">
    <div class="row" style="display: block !important">
      <app-personal-details
      *ngIf="tabOptions === 'Details' && !isLoadingData"
      [(workingDetails)]="workingDetails"   
      [(details)]="details"   
      (isValidateAdmissionDate)="isValidateAdmissionDate($event)"></app-personal-details>
    </div>
    <div class="row" style="width:100%; margin-top:1px !important; display: inline-table;">
      <button
        *ngIf="!internalAssistant"
        class="btn btn-success save-advocate-details save"
        [class.disabled]="!isPersonalDetailsSaveable"
        [disabled]="!isPersonalDetailsSaveable"   
        (click)="onSaveAdvocateDetails()">SAVE ADVOCATE DETAILS</button>
    </div>
  </div>
  <div *ngIf="tabOptions === 'FinancialDetails'" class="content-area financial-details">
    <div class="row" style="display: block;">
      <app-financial-details
      *ngIf="tabOptions === 'FinancialDetails' && !isLoadingData"
      [(workingFinancialDetails)]="workingFinancialDetails"></app-financial-details>
    </div>
    <div class="row" style="width:100%; margin-top:1px !important; display: inline-table;">
      <button
        *ngIf="!internalAssistant"
        class="btn btn-success save-advocate-details save"
        [class.disabled]="!isFinancialDetailsSaveable"
        [disabled]="!isFinancialDetailsSaveable"
        (click)="onFinancialDetailsSave()">SAVE FINANCIAL DETAILS</button>
    </div>
  </div>
  <div *ngIf="tabOptions === 'ContactDetails'" class="content-area summary">
    <div class="row">
      <app-contact-details
      *ngIf="tabOptions === 'ContactDetails' && !isLoadingData"
      [barSociety]="barSociety"
      [(workingAdvocateChambers)]="workingAdvocateChambers"
      [(workingOverviewContactDetails)]="workingOverviewContactDetails"></app-contact-details>
    </div>
    <div class="row" style="width:100%; margin-top:1px !important; display: inline-table;">
      <button
        *ngIf="!internalAssistant"
        class="btn btn-success save-advocate-details save"
        [class.disabled]="!isContactDetailsSaveable"
        [disabled]="!isContactDetailsSaveable"
        (click)="onContactDetailSave()">SAVE CONTACT DETAILS</button>
    </div>
  </div>
  <div *ngIf="tabOptions === 'Subscription' && !internalAssistant" class="content-area subscription">
    <div class="row">
      <app-subscription
       *ngIf="tabOptions === 'Subscription' && !isLoadingData"
      [(workerUserSubscription)]="workerUserSubscription"
      [(userSubscription)]="userSubscription"      
      (onchangePM)="onPMChange($event)"></app-subscription>
    </div>
    <div class="row" style="width:100%; margin-top:1px !important; display: inline-table;">
    <button
      class="btn btn-success save-advocate-details save"
      [class.disabled]="!isSubscriptionSaveable"
      [disabled]="!isSubscriptionSaveable"
      (click)="onSubscriptionSave()">SAVE SUBSCRIPTION DETAILS</button>
    </div>
  </div>
  <div *ngIf="tabOptions === 'ProfileSettings'" class="content-area communication">
    <div class="row" style="display: block;" *ngIf="!internalAssistant">
      <app-profile-settings
      [(workingProfileSettings)]="workingProfileSettings"
      (subscriptionStatusUpdate)="onSubscriptionStatusUpdate($event)"></app-profile-settings>
    </div>
    <div class="row" style="width:100%; margin-top:1px !important; display: inline-table;">
      <button
        *ngIf="!internalAssistant"
        class="btn btn-success save-advocate-details save"
        [class.disabled]="!isProfileSettingsDirty"
        [disabled]="!isProfileSettingsDirty"   
        (click)="onSaveProfileSettings()">SAVE ADVOCATE DETAILS</button>
    </div>
  </div>
  <div *ngIf="tabOptions === 'Communication'" class="content-area communication">
    <div class="row" style="display: block;">
      <app-conversation></app-conversation>
    </div>
  </div>
  <div *ngIf="tabOptions === 'ProfileInformation' && !isLoadingProfileInfo" class="content-area communication">
    <div class="row" style="display: block;">
      <app-advocate-info
      *ngIf="!isLoadingProfileInfo"
      [(workingStickyNote)]="workingStickyNote"
      [contactNoteActionLogsSTO]="contactNoteActionLogsSTO"
      (saveNote)="onStickyNoteSave($event)"></app-advocate-info>
    </div>
  </div>
</div>

<app-prompt-unsaved-changes-modal
  message="You have unsaved changes. Are you sure you want to leave this tab?"
  [isVisible]="showUnsavedChangesPrompt"
  (cancel)="onHideUnsavedChangesPrompt()"
  (continue)="leavePage()">
</app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal
  message="You have unsaved changes. Are you sure you want to leave the page?"
  [isVisible]="showUnsavedPageChangesPrompt"
  (cancel)="onHideUnsavedPageChangesPrompt()"
  (continue)="leaveUnSavePage()">
</app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal
  title="VAT Number"
  message="No VAT number inserted. Please enter a valid VAT number."
  [isVisible]="showVATFunctionalityErrorPrompt"
  (cancel)="onHideVATFunctionalityErrorPrompt()"
  (continue)="leaveIgnorePage()"
  btnTextCancel="Cancel"
  btnTextContinue="OK">
</app-prompt-unsaved-changes-modal>