<div class="container-fluid">
  <div class="process-body row">
    <div class="left-block col-sm-6">
      <div class="block-title">Law firms</div>

      <!-- #start search -->
      <div class="row reconcile-search input-group">
        <input
          type="search"
          #inputCheckbox
          class="form-control"
          placeholder="Search for Law firm"
          [(ngModel)]="searchTerm"
          (keyup)="performSearch()">
          <div class="input-group-append">
            <i *ngIf="!searchTerm" class="far fa-search"></i>
            <i *ngIf="searchTerm" (click)="clearSearch()" class="far fa-close"></i>
          </div>
      </div> <!-- #end .row reconcile-search input-group -->

      <div class="row m-0">
        <div class="table-responsive" style="max-height: 400px; overflow-y: auto;">

          <table class="table table-striped">
            <tbody>
              <tr>
                <th (click)="toggleSort('law-firm')" style="width: 45%" scope="col"> Law firm
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortLawfirm"></i></span>
                </th>
                <th (click)="toggleSort('outstanding')" scope="col"> Outstanding
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortTotalOutstanding"></i></span>
                </th>
                <th (click)="toggleSort('advocate-number')" scope="col"> Adv
                  <span><i class="fa fa-sort" [class.fa-sort-down]="sortAdvocateCount"></i></span>
                </th>
                <th scope="col">
                  <!-- <span style="float: right; min-width: 80px;"><i class="fa fa-sort" [class.fa-sort-down]="s"></i> -->
                </th>
              </tr>
              <tr class="tr" *ngFor="let firm of debtorTotalOutstandingHandle" [class.active]="row == firm.data.ClientId">
                <td style="width: 45%" scope="row" *ngIf="firm.state.isVisible" (click)="onRowClick(firm.data)">{{firm.data.Debtor}}</td>
                <td *ngIf="firm.state.isVisible" (click)="onRowClick(firm.data)">{{firm.data.Outstanding | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                <td *ngIf="firm.state.isVisible" (click)="onRowClick(firm.data)">{{firm.data.AdvocateCount}}</td>
                <td *ngIf="firm.state.isVisible" (click)="onRowClick(firm.data)">
                  <div class="">
                    <div class="" style="min-width: 140px">
                      <div class="border-rounded-right"
                        [ngStyle]="{'background-color':  '#8abd37', 'width': getBarWidth(firm.data.FirmPercentage)}">
                        <span class="margin-left"> {{firm.data.FirmPercentage}}%</span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table> <!-- #end .table .table-striped -->

        </div> <!-- #end .table-responsive --> 
      </div> <!-- #end .row m-0 -->
    </div> <!-- #end .left-block col-sm-6 -->
    <div class="right-block col-sm-6">
      <app-practice-firm-detail
      *ngIf="advocateDetailHandle"
      [otherPracticeManagers]="otherPracticeManagers"
      [advocateDetailHandle]="advocateDetailHandle"></app-practice-firm-detail>
    </div> <!-- #end .right-block col-sm-6 -->
  </div> <!-- #end .process-body row -->
</div> <!-- #end .container-fluid -->