<div *ngIf="isVisible" class="modal">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-body">
                <div *ngIf="isSuccessMessage" class="row">
                    <div class="col-sm-12">
                        <h3 class="titleCancel">{{title}}</h3>
                        <div class="row icon-success">
                            <i class="fal fa-check-circle fa-6x"></i>
                        </div>
                        <div class="message">
                            <span>{{message}}</span>
                        </div>
                    </div>
                </div>

                <div *ngIf="!isSuccessMessage" class="row">
                    <div class="col-sm-12">
                        <h3 class="titleCancel">{{title}}</h3>
                        <div class="row icon-danger">
                            <i class="fal fa-times-circle fa-6x"></i>
                        </div>
                        <div class="message">
                            <span>{{message}}</span>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>