<div class="container card">
  <div class="brand">
    <img [src]="imageLogo.path" [alt]="imageLogo.alt">
  </div>
  <app-circular-indicator
    [isVisible]="isAuthenticated"
    message="Signing out..."></app-circular-indicator>
  <div *ngIf="!isAuthenticated">
    <h2>You have successfully signed out.</h2>
    <p>To sign back in, click the button below.</p>
  </div>
  <a
    *ngIf="!isAuthenticated"
    class="btn btn-primary btn-lg"
    href="https://www.auxcon.co.za/">Sign in</a>
</div>