import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class AttorneysService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  /**
   * GET: Returns the full list of attorneys.
   */
  getAttorneysInFirms(): Observable<any> {
    return this.http.get(this.api.endpoints.attorneysInFirms, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getgetAttorneysInFirms()

  /**
   * GET: returns a full list of Attorneys by the given Firm ID.
   * @param {number} firmId The Firm ID (ContactID)
   * @returns {Observable<*>} Returns an Observable list of Attorneys by the
   * given Firm ID.
   */
  getAttorneysByLawFirms(firmId: number): Observable<any> {
    return this.http.get(this.api.endpoints.attorneysByFirms +
      '?firmId=' + firmId, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getAttorneysByLawFirms()

  getLawfirmRating(lawfirmID: number): Observable<any> {
    return this.http.get(this.api.endpoints.lawfirmRatings +
      '?LawFirmID=' + lawfirmID + '&AdvocateId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getLawfirmRating()
} // end AttorneysService{}
