<div class="row row-nav">
  <nav class="navbar-tabs">
    <a class="tab"
      [class.active]="activeTab === 'new-invoices'"
      (click)="setActiveTab('new-invoices')">New Invoices
      <i class="fas fa-circle brief-doc-counter"><b>{{newInvoicesAdvocateCount}}</b></i>
    </a>
    <a *ngIf="isHeadOfState"
      class="tab"
      [class.active]="activeTab === 'escalation'"
      (click)="setActiveTab('escalation')">New Invoices & Escalation
      <i class="fas fa-circle brief-doc-counter"><b>{{escaletedQuesriesCount}}</b></i>
    </a>
    <a *ngIf="isHeadOfState"
      class="tab"
      [class.active]="activeTab === 'report-queries'"
      (click)="setActiveTab('report-queries')">Reports & Queries
    </a>
    <a class="tab"
      [class.active]="activeTab === 'view-as-table'"
      (click)="setActiveTab('view-as-table')">Processed Invoices</a>
    <a *ngIf="isHeadOfState"
      class="tab"
      [class.active]="activeTab === 'attorney'"
      (click)="setActiveTab('attorney')">All Attorneys</a>
  </nav><!--#end .navbar-tabs -->
</div><!--#end .navbar-tabs -->

<div class="row" (click)="onPageClick($event)" *ngIf="activeTab === 'view-as-table'">

  <div class="column-display-area">
    <div class="display-options">
      <!-- Search column -->
      <span class="column-search">
        <!-- (click)="filterStatement()" -->
        <button class="btn btn-text">
          <i class="fa fa-search"></i>
        </button> <!-- #end .btn btn-text -->
        <!-- (click)="clearSearch" -->
        <input
          type="search"
          placeholder="Search Advocates"
          (click)="clearSearch()"
          (keyup)="filterStatements()"
          [(ngModel)]="searchValue">
        <!-- (keyup)="filterMatters()" -->
          <!-- [(ngModel)]="searchValue" -->
      </span> <!-- #end .column-search -->

      <span class="column-order-by">
        <label for="sort-by">Sort by:</label>
        <select
          id="sort-by"
          (change)="orderLiveStatement()"
          [(ngModel)]="orderBy">
          <!-- <option value="" selected> Filter options </option> -->
          <option value="" disabled  selected hidden> Filter options </option>
          <option *ngFor="let option of orderingOptions" [value]="option">
            {{option | titlecase}}
          </option>
        </select> <!-- #end select -->
        <button class="btn btn-toggle"
          (click)="toggleAscending()">
          <i
            class="fa"
            [class.fa-sort-amount-desc]="!sortAscending"
            [class.fa-sort-amount-asc]="sortAscending"></i>
        </button> <!-- #end .btn btn-toggle -->
      </span> <!-- #end .column-order-by-->
    </div> <!-- #end .display-options -->

    <div class="well well-fluid">
      <div class="table"
        [class.hide]="activeTab !== 'view-as-table'">

        <div
          class="table-head">
          <div class="table-row">

            <span class="column-name clickable" (click)="toggleSort('advocate')">
              Advocate name
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-name -->

            <span class="column-email clickable" (click)="toggleSort('email')">
              Advocate email
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-email -->

            <span class="column-cellular-number clickable" (click)="toggleSort('cell')">
              Cellular number
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-cellular-number -->

            <span class="column-current-matters clickable" (click)="toggleSort('matters')">
              Invoices
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-current-matters -->

            <span class="column-total-outstanding clickable" (click)="toggleSort('outstanding')">
              Total outstanding &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-total-outstanding -->

            <span class="column-actions">

            </span> <!-- #end .column-actions -->

          </div> <!-- #end .table-row -->
        </div> <!-- #end .table-head -->

        <div class="table-body" *ngIf="liveStatementHandle">
          <div
            class="table-row"
            *ngFor="let advocate of liveStatementHandle"
            [class.hide]="!advocate.state.isVisible">

            <span class="column-name clickable" [title]="advocate.data.ProviderName" (click)="viewReport(advocate.data)">
              <u>
              {{advocate.data.ProviderName}}
              </u>
            </span>

            <span class="column-email" [title]="advocate.data.Email.replace('mailto:', '')">
              <a [href]="advocate.data.Email" target="_top">{{advocate.data.Email.replace('mailto:', '')}}</a>
              <!-- {{advocate.data.Email}} -->
            </span>

            <span class="column-cellular-number" [title]="advocate.data.Phone">
              {{advocate.data.Phone}}
            </span>

            <span class="column-current-matters" [title]="advocate.data.CurrentMatters">
              {{advocate.data.CurrentMatters}}
            </span>

            <span class="column-total-outstanding" [title]="advocate.data.TotalOutstanding | currency: currencySymbol: currencySymbol:'1.2-2'">
              {{advocate.data.TotalOutstanding | currency: currencySymbol: currencySymbol:'1.2-2'}}
            </span>

            <span class="column-outstanding-percentage">
              <span class="border-rounded-right" [ngStyle]="{'background-color':  getBackgroundColor('all'), 'width': getBarWidth(advocate.data.OutstandingPercentage)}">
              </span>
              <span class="margin-left">{{advocate.data.OutstandingPercentage}}%</span>
            </span>

            <span class="column-action">
              <span class="btn-group">
                <span class="clickable" (click)="viewReport(advocate.data)">
                  View detail
                </span>
                <i class="fa fa-angle-down fa-lg clickable"
                (click)="togglePopupMenu(advocate)"></i>
              </span> <!-- #end .btn-group -->
              <app-live-statement-popup-menu
                #popupMenu
                [isVisible]="advocate.state.showDropdown"
                [statement]="advocate"
                (viewStatement)="onStatementView($event)"
                (dismiss)="onDismissMenu($event)"></app-live-statement-popup-menu>
            </span> <!-- #end column-action -->

          </div> <!-- #end .table-row -->

          <div class="table-row">

            <span class="column-name">
            </span>
            
            <span class="column-email"></span>
            
            <span class="column-cellular-number"></span>
            
            <span class="column-current-matters" style="font-weight: bold !important;">Total Outstanding</span>
            
            <span class="column-total-outstanding" style="font-weight: bold !important;">{{grandTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
            <span class="column-outstanding-percentage"></span>
            <span class="column-action">
              <span class="btn-group" *ngIf="!isLoading">
                <span class="clickable" (click)="viewAllAdvocates()">View All</span>
                <i class="fa fa-angle-up fa-lg clickable" (click)="toggleListStatementMenu()"></i>
              </span>
              <!--#end .btn-group -->
              <div *ngIf="isLoading">
                <img src="../../../../assets/images/logos/spinner.gif" height="20" alt="spinner">
              </div>
              <div *ngIf="!isLoading">
                <app-live-statement-footer-popup-menu
                  #popupMenu
                  [isVisible]="showLiveStatementPopupMenu"
                  (dismiss)="onDismissLiveStatementMenu()"
                  (ongenerateExcel)="onGenerateExcel($event)">
                </app-live-statement-footer-popup-menu>
              </div>

              <!-- <app-live-statement-footer-popup-menu
                #popupMenu
                [isVisible]="showLiveStatementPopupMenu"
                (dismiss)="onDismissLiveStatementMenu()"
                (ongenerateExcel)="onGenerateExcel($event)"></app-live-statement-footer-popup-menu> -->
            </span>
            
          </div> <!-- #end .table-row -->

        </div> <!-- #end .table-body -->

      </div> <!-- #end .table -->
    </div> <!-- #end .well well-fluid-->
  </div>
</div>

<div class="row" (click)="onPageClick($event)" *ngIf="activeTab === 'report-queries'">

  <div class="column-display-area">

    <div class="well well-fluid">
      <div class="table"
        [class.hide]="activeTab !== 'report-queries'">

        <div
          class="table-head">
          <div class="second-table-row ">

            <span class="column-name clickable">
              Advocate
            </span> <!-- #end .column-name -->

            <span class="column-email clickable">
              InvoiceNo
            </span> <!-- #end .column-email -->

            <span class="column-cellular-number clickable">
              Reference
            </span> <!-- #end .column-cellular-number -->

            <span class="column-current-matters clickable">
              Attorney
            </span> <!-- #end .column-current-matters -->

            <span class="column-total-outstanding clickable">
              PartyDetails
            </span> <!-- #end .column-total-outstanding -->

            <span class="column-total-outstanding clickable">
              Type
            </span> <!-- #end .column-total-outstanding -->

            <span class="column-actions">
              RequestDate
            </span> <!-- #end .column-actions -->

            <span class="column-actions">
              ResolvedDate
            </span> <!-- #end .column-actions -->

            <span class="column-actions">
              Note
            </span> <!-- #end .column-actions -->

          </div> <!-- #end .table-row -->
        </div> <!-- #end .table-head -->

        <div class="table-body">
          <div
            class="second-table-row"
            *ngFor="let advocate of queryReport">

            <span class="column-name clickable" [title]="advocate.Advocate">
              {{advocate.Advocate}}
            </span>

            <span class="column-email">{{advocate.InvoiceNo}}
            </span>

            <span class="column-cellular-number" [title]="advocate.Reference">
              {{advocate.Reference}}
            </span>

            <span class="column-current-matters" [title]="advocate.Attorney">
              {{advocate.Attorney}}
            </span>

            <span class="column-total-outstanding" [title]="advocate.PartyDetails">
              {{advocate.PartyDetails}}
            </span>

            <span class="column-outstanding-percentage">
              <span class="margin-left">{{advocate.Type}}</span>
            </span>

            <span class="column-outstanding-percentage">
              <span class="margin-left">{{advocate.RequestDate | date:'yyyy-MM-dd'}}</span>
            </span>

            <span class="column-outstanding-percentage">
              <span class="margin-left">{{advocate.ResolvedDate | date:'yyyy-MM-dd'}}</span>
            </span>

            <span class="column-outstanding-percentage">
              <span class="margin-left">{{advocate.Note}}</span>
            </span>

          </div> <!-- #end .table-row -->

        </div> <!-- #end .table-body -->

      </div> <!-- #end .table -->
    </div> <!-- #end .well well-fluid-->
  </div>
</div>

<div class="row" (click)="onPageClick($event)" *ngIf="activeTab === 'attorney'">

  <div class="column-display-area">
    <div class="display-options">
      <!-- Search column -->
      <span class="column-search">
        <!-- (click)="filterStatement()" -->
        <button class="btn btn-text">
          <i class="fa fa-search"></i>
        </button> <!-- #end .btn btn-text -->
        <!-- (click)="clearSearch" -->
        <input
          type="search"
          placeholder="Search Advocates"
          (click)="clearSearch()"
          (keyup)="filterAttorneyStatements()"
          [(ngModel)]="searchValue">
        <!-- (keyup)="filterMatters()" -->
          <!-- [(ngModel)]="searchValue" -->
      </span> <!-- #end .column-search -->

      <span class="column-order-by">
        <label for="sort-by">Sort by:</label>
        <select
          id="sort-by"
          (change)="orderAttorneyStatement()"
          [(ngModel)]="orderBy">
          <!-- <option value="" selected> Filter options </option> -->
          <option value="" disabled  selected hidden> Filter options </option>
          <option *ngFor="let option of orderingOptions" [value]="option">
            {{option | titlecase}}
          </option>
        </select> <!-- #end select -->
        <button class="btn btn-toggle"
          (click)="toggleAttorneyAscending()">
          <i
            class="fa"
            [class.fa-sort-amount-desc]="!sortAttorneyAscending"
            [class.fa-sort-amount-asc]="sortAttorneyAscending"></i>
        </button> <!-- #end .btn btn-toggle -->
      </span> <!-- #end .column-order-by-->
    </div> <!-- #end .display-options -->

    <div class="well well-fluid">
      <div class="table"
        [class.hide]="activeTab !== 'attorney'">

        <div
          class="table-head">
          <div class="table-row">

            <span class="column-name clickable" (click)="toggleAttorneySort('advocate')">
              Attorney name
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-name -->

            <span class="column-email clickable" (click)="toggleAttorneySort('email')">
              Attorney email
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-email -->

            <span class="column-cellular-number clickable" (click)="toggleAttorneySort('cell')">
              Cellular number
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-cellular-number -->

            <span class="column-current-matters clickable" (click)="toggleAttorneySort('matters')">
              Invoices
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-current-matters -->

            <span class="column-total-outstanding clickable" (click)="toggleAttorneySort('outstanding')">
              Total outstanding &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-total-outstanding -->

            <span class="column-actions">

            </span> <!-- #end .column-actions -->

          </div> <!-- #end .table-row -->
        </div> <!-- #end .table-head -->

        <div class="table-body" *ngIf="advocateDetailStatementHandle">
          <div
            class="table-row"
            *ngFor="let advocate of advocateDetailStatementHandle"
            [class.hide]="!advocate.state.isVisible">

            <span class="column-name clickable" [title]="advocate.data.ProviderName" (click)="viewAttorneyReport(advocate.data)">
              <u>
              {{advocate.data.ProviderName}}
              </u>
            </span>

            <span class="column-email" [title]="advocate.data.Email.replace('mailto:', '')">
              <a [href]="advocate.data.Email" target="_top">{{advocate.data.Email.replace('mailto:', '')}}</a>
              <!-- {{advocate.data.Email}} -->
            </span>

            <span class="column-cellular-number" [title]="advocate.data.Phone">
              {{advocate.data.Phone}}
            </span>

            <span class="column-current-matters" [title]="advocate.data.CurrentMatters">
              {{advocate.data.CurrentMatters}}
            </span>

            <span class="column-total-outstanding" [title]="advocate.data.TotalOutstanding | currency: currencySymbol: currencySymbol:'1.2-2'">
              {{advocate.data.TotalOutstanding | currency: currencySymbol: currencySymbol:'1.2-2'}}
            </span>

            <span class="column-outstanding-percentage">
              <span class="border-rounded-right" [ngStyle]="{'background-color':  getBackgroundColor('all'), 'width': getBarWidth(advocate.data.OutstandingPercentage)}">
              </span>
              <span class="margin-left">{{advocate.data.OutstandingPercentage}}%</span>
            </span>

            <span class="column-action">
              <span class="btn-group">
                <span class="clickable" (click)="viewAttorneyReport(advocate.data)">
                  View detail
                </span>
                <i class="fa fa-angle-down fa-lg clickable"
                (click)="togglePopupMenu(advocate)"></i>
              </span> <!-- #end .btn-group -->
              <app-live-statement-popup-menu
                #popupMenu
                [isVisible]="advocate.state.showDropdown"
                [statement]="advocate"
                (viewStatement)="onStatementView($event)"
                (dismiss)="onDismissMenu($event)"></app-live-statement-popup-menu>
            </span> <!-- #end column-action -->

          </div> <!-- #end .table-row -->

          <div class="table-row">

            <span class="column-name"></span>
            
            <span class="column-email"></span>
            
            <span class="column-cellular-number"></span>
            
            <span class="column-current-matters" style="font-weight: bold !important;">Total Outstanding</span>
            
            <span class="column-total-outstanding" style="font-weight: bold !important;">{{grandTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
            <span class="column-outstanding-percentage"></span>
            <span class="column-action">
              <span class="btn-group">
                <span class="clickable" (click)="viewAllAdvocates()">View All</span>
                <i class="fa fa-angle-up fa-lg clickable" (click)="toggleListStatementMenu()"></i>
              </span>
              <!--#end .btn-group -->

              <app-live-statement-footer-popup-menu
                #popupMenu
                [isVisible]="showLiveStatementPopupMenu"
                (dismiss)="onDismissLiveStatementMenu()"
                (ongenerateExcel)="onGenerateExcel($event)"></app-live-statement-footer-popup-menu>
            </span>
            
          </div> <!-- #end .table-row -->

        </div> <!-- #end .table-body -->

      </div> <!-- #end .table -->
    </div> <!-- #end .well well-fluid-->
  </div>
</div>

<!-- New Invocies Tab -->
<div class="row" (click)="onPageClick($event)" *ngIf="activeTab === 'new-invoices'">

  <div class="column-display-area">
    <div class="display-options">
      <!-- Search column -->
      <span class="column-search">
        <!-- (click)="filterStatement()" -->
        <button class="btn btn-text">
          <i class="fa fa-search"></i>
        </button> <!-- #end .btn btn-text -->
        <!-- (click)="clearSearch" -->
        <input
          type="search"
          placeholder="Search Advocates"
          (click)="clearSearch()"
          (keyup)="filterStatements()"
          [(ngModel)]="searchValue">
        <!-- (keyup)="filterMatters()" -->
          <!-- [(ngModel)]="searchValue" -->
      </span> <!-- #end .column-search -->

      <span class="column-order-by">
        <label for="sort-by">Sort by:</label>
        <select
          id="sort-by"
          (change)="orderLiveStatement()"
          [(ngModel)]="orderBy">
          <!-- <option value="" selected> Filter options </option> -->
          <option value="" disabled  selected hidden> Filter options </option>
          <option *ngFor="let option of orderingOptions" [value]="option">
            {{option | titlecase}}
          </option>
        </select> <!-- #end select -->
        <button class="btn btn-toggle"
          (click)="toggleAscending()">
          <i
            class="fa"
            [class.fa-sort-amount-desc]="!sortAscending"
            [class.fa-sort-amount-asc]="sortAscending"></i>
        </button> <!-- #end .btn btn-toggle -->
      </span> <!-- #end .column-order-by-->
    </div> <!-- #end .display-options -->

    <div class="well well-fluid">
      <div class="table"
        [class.hide]="activeTab !== 'new-invoices'">

        <div
          class="table-head">
          <div class="table-row">

            <span class="column-name clickable" (click)="toggleSort('advocate')">
              Advocate name
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-name -->

            <span class="column-email clickable" (click)="toggleSort('email')">
              Advocate email
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-email -->

            <span class="column-cellular-number clickable" (click)="toggleSort('cell')">
              Cellular number
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-cellular-number -->

            <span class="column-current-matters clickable" (click)="toggleSort('matters')">
              Invoices
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-current-matters -->

            <span class="column-total-outstanding clickable" (click)="toggleSort('outstanding')">
              Total outstanding &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-total-outstanding -->

            <span class="column-actions">

            </span> <!-- #end .column-actions -->

          </div> <!-- #end .table-row -->
        </div> <!-- #end .table-head -->

        <div class="table-body" *ngIf="newInvoicesHandle">
          <div
            class="table-row"
            *ngFor="let advocate of newInvoicesHandle"
            [class.hide]="!advocate.state.isVisible">

            <span class="column-name clickable" [title]="advocate.data.ProviderName" (click)="viewReport(advocate.data)">
              <u>
              {{advocate.data.ProviderName}}
              </u>
            </span>

            <span class="column-email" [title]="advocate.data.Email.replace('mailto:', '')">
              <a [href]="advocate.data.Email" target="_top">{{advocate.data.Email.replace('mailto:', '')}}</a>
              <!-- {{advocate.data.Email}} -->
            </span>

            <span class="column-cellular-number" [title]="advocate.data.Phone">
              {{advocate.data.Phone}}
            </span>

            <span class="column-current-matters" [title]="advocate.data.CurrentMatters">
              {{advocate.data.CurrentMatters}}
            </span>

            <span class="column-total-outstanding" [title]="advocate.data.TotalOutstanding | currency: currencySymbol: currencySymbol:'1.2-2'">
              {{advocate.data.TotalOutstanding | currency: currencySymbol: currencySymbol:'1.2-2'}}
            </span>

            <span class="column-outstanding-percentage">
              <span class="border-rounded-right" [ngStyle]="{'background-color':  getBackgroundColor('all'), 'width': getBarWidth(advocate.data.OutstandingPercentage)}">
              </span>
              <span class="margin-left">{{advocate.data.OutstandingPercentage}}%</span>
            </span>

            <span class="column-action">
              <span class="btn-group">
                <span class="clickable" (click)="viewReport(advocate.data)">
                  View detail
                </span>
                <i class="fa fa-angle-down fa-lg clickable"
                (click)="togglePopupMenu(advocate)"></i>
              </span> <!-- #end .btn-group -->
              <app-live-statement-popup-menu
                #popupMenu
                [isVisible]="advocate.state.showDropdown"
                [statement]="advocate"
                (viewStatement)="onStatementView($event)"
                (dismiss)="onDismissMenu($event)"></app-live-statement-popup-menu>
            </span> <!-- #end column-action -->

          </div> <!-- #end .table-row -->

          <div class="table-row">

            <span class="column-name"></span>
            
            <span class="column-email"></span>
            
            <span class="column-cellular-number"></span>
            
            <span class="column-current-matters" style="font-weight: bold !important;">Total Outstanding</span>
            
            <span class="column-total-outstanding" style="font-weight: bold !important;">{{newInvoicesGrandTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
            <span class="column-outstanding-percentage"></span>
            <span class="column-action">
              <span class="btn-group">
                <span class="clickable" (click)="viewReportAll()">View report</span>
                <i class="fa fa-angle-up fa-lg clickable" (click)="toggleListStatementMenu()"></i>
              </span>
              <!--#end .btn-group -->

              <app-live-statement-footer-popup-menu
                #popupMenu
                [isVisible]="showLiveStatementPopupMenu"
                (dismiss)="onDismissLiveStatementMenu()"
                (ongenerateExcel)="onGenerateExcel($event)"></app-live-statement-footer-popup-menu>
            </span>
            
          </div> <!-- #end .table-row -->

        </div> <!-- #end .table-body -->

      </div> <!-- #end .table -->
    </div> <!-- #end .well well-fluid-->
  </div>
</div>

<!-- Escaleted Queries -->
<div class="row" (click)="onPageClick($event)" *ngIf="activeTab === 'escalation'">

  <div class="column-display-area">
    <div class="display-options">
      <!-- Search column -->
      <span class="column-search">
        <!-- (click)="filterStatement()" -->
        <button class="btn btn-text">
          <i class="fa fa-search"></i>
        </button> <!-- #end .btn btn-text -->
        <!-- (click)="clearSearch" -->
        <input
          type="search"
          placeholder="Search Advocates"
          (click)="clearSearch()"
          (keyup)="filterStatements()"
          [(ngModel)]="searchValue">
        <!-- (keyup)="filterMatters()" -->
          <!-- [(ngModel)]="searchValue" -->
      </span> <!-- #end .column-search -->

      <span class="column-order-by">
        <label for="sort-by">Sort by:</label>
        <select
          id="sort-by"
          (change)="orderLiveStatement()"
          [(ngModel)]="orderBy">
          <!-- <option value="" selected> Filter options </option> -->
          <option value="" disabled  selected hidden> Filter options </option>
          <option *ngFor="let option of orderingOptions" [value]="option">
            {{option | titlecase}}
          </option>
        </select> <!-- #end select -->
        <button class="btn btn-toggle"
          (click)="toggleAscending()">
          <i
            class="fa"
            [class.fa-sort-amount-desc]="!sortAscending"
            [class.fa-sort-amount-asc]="sortAscending"></i>
        </button> <!-- #end .btn btn-toggle -->
      </span> <!-- #end .column-order-by-->
    </div> <!-- #end .display-options -->

    <div class="well well-fluid">
      <div class="table"
        [class.hide]="activeTab !== 'escalation'">

        <div
          class="table-head">
          <div class="table-row">

            <span class="column-name clickable" (click)="toggleSort('advocate')">
              Advocate name
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-name -->

            <span class="column-email clickable" (click)="toggleSort('email')">
              Advocate email
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-email -->

            <span class="column-cellular-number clickable" (click)="toggleSort('cell')">
              Cellular number
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-cellular-number -->

            <span class="column-current-matters clickable" (click)="toggleSort('matters')">
              Invoices
              &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-current-matters -->

            <span class="column-total-outstanding clickable" (click)="toggleSort('outstanding')">
              Total outstanding &nbsp;
              <i class="fa fa-sort"></i>
            </span> <!-- #end .column-total-outstanding -->

            <span class="column-actions">

            </span> <!-- #end .column-actions -->

          </div> <!-- #end .table-row -->
        </div> <!-- #end .table-head -->

        <div class="table-body" *ngIf="escalectedQueriesHandle">
          <div
            class="table-row"
            *ngFor="let advocate of escalectedQueriesHandle"
            [class.hide]="!advocate.state.isVisible">

            <span class="column-name clickable" [title]="advocate.data.ProviderName" (click)="viewReport(advocate.data)">
              <u>
              {{advocate.data.ProviderName}}
              </u>
            </span>

            <span class="column-email" [title]="advocate.data.Email.replace('mailto:', '')">
              <a [href]="advocate.data.Email" target="_top">{{advocate.data.Email.replace('mailto:', '')}}</a>
              <!-- {{advocate.data.Email}} -->
            </span>

            <span class="column-cellular-number" [title]="advocate.data.Phone">
              {{advocate.data.Phone}}
            </span>

            <span class="column-current-matters" [title]="advocate.data.CurrentMatters">
              {{advocate.data.CurrentMatters}}
            </span>

            <span class="column-total-outstanding" [title]="advocate.data.TotalOutstanding | currency: currencySymbol: currencySymbol:'1.2-2'">
              {{advocate.data.TotalOutstanding | currency: currencySymbol: currencySymbol:'1.2-2'}}
            </span>

            <span class="column-outstanding-percentage">
              <span class="border-rounded-right" [ngStyle]="{'background-color':  getBackgroundColor('all'), 'width': getBarWidth(advocate.data.OutstandingPercentage)}">
              </span>
              <span class="margin-left">{{advocate.data.OutstandingPercentage}}%</span>
            </span>

            <span class="column-action">
              <span class="btn-group">
                <span class="clickable" (click)="viewReport(advocate.data)">
                  View detail
                </span>
                <i class="fa fa-angle-down fa-lg clickable"
                (click)="togglePopupMenu(advocate)"></i>
              </span> <!-- #end .btn-group -->
              <app-live-statement-popup-menu
                #popupMenu
                [isVisible]="advocate.state.showDropdown"
                [statement]="advocate"
                (viewStatement)="onStatementView($event)"
                (dismiss)="onDismissMenu($event)"></app-live-statement-popup-menu>
            </span> <!-- #end column-action -->

          </div> <!-- #end .table-row -->

          <div class="table-row">

            <span class="column-name"></span>
            
            <span class="column-email"></span>
            
            <span class="column-cellular-number"></span>
            
            <span class="column-current-matters" style="font-weight: bold !important;">Total Outstanding</span>
            
            <span class="column-total-outstanding" style="font-weight: bold !important;">{{escalationGrandTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
            <span class="column-outstanding-percentage"></span>
            <span class="column-action">
              <span class="btn-group">
                <span class="clickable" (click)="viewReportAll()">View report</span>
                <i class="fa fa-angle-up fa-lg clickable" (click)="toggleListStatementMenu()"></i>
              </span>
              <!--#end .btn-group -->

              <app-live-statement-footer-popup-menu
                #popupMenu
                [isVisible]="showLiveStatementPopupMenu"
                (dismiss)="onDismissLiveStatementMenu()"
                (ongenerateExcel)="onGenerateExcel($event)"></app-live-statement-footer-popup-menu>
            </span>
            
          </div> <!-- #end .table-row -->

        </div> <!-- #end .table-body -->

      </div> <!-- #end .table -->
    </div> <!-- #end .well well-fluid-->
  </div>
</div>

<app-advocate-statement-modal
  #advocateStatement
  [advocateDetail]="selectedStatement"
  [isVisible]="showAdvocateStatement"
  (closeModal)="closeModal('advocate-statement')"></app-advocate-statement-modal>