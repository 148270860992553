<form name="form" #f="ngForm" novalidate>
    <div class="modal">
        <div class="modal-content">
            <div class="modal-dialog">

                <div class="modal-header">
                    <h1>{{title}}</h1>
                    <button class="modal-dismiss" (click)="onCancel()">
                        <i class="fal fa-times-circle"></i>
                    </button>
                </div>

                <div class="modal-body form">
                    <div class="row m-0 pl-0 pb-0">
                        <div class="custom-file">
                            <label for="data-source">Data source: </label>
                            <select class="transaction-date" id="data-source" [(ngModel)]="dataSource">
                                <option value="" selected="selected">Select data source</option>
                                <option value="Excel Sheet">Excel Sheet</option>
                                <option value="PDF Invoices">PDF Invoices</option>
                                <option value="Word Invoices">Word Invoices</option>
                                <option value="Excel Invoices">Excel Invoices</option>
                            </select>
                        </div>
                        <div class="custom-file">
                            <label for="take-on-type">Take on type: </label>
                            <select class="transaction-date" id="take-on-type" [(ngModel)]="documentType">
                                <option value="" selected="selected">Select take on type</option>
                                <option value="All Invoices">All Invoices</option>
                                <option value="Financial Year">Financial Year</option>
                                <option value="Outstanding Invoices">Outstanding Invoices</option>
                                <option value="The State Attorney Invoices ONLY">The State Attorney Invoices ONLY</option>
                            </select>
                        </div>
                            
                        <div class="custom-file">
                            <label for="date" style="padding-right: 10px; width: 54%;" class="fromTo-date">Last reconciliation date: </label>
                            <input
                                type="text"
                                name="fromDate"
                                class="input-date"
                                #_fromDate="bsDatepicker"
                                bsDatepicker
                                placeholder="2010-01-01"
                                placement="bottom"
                                [value]="reconDate | date:'yyyy-MM-dd'"
                                (ngModel)="reconDate=$event.target.value"
                                (bsValueChange)="onValueStartChange($event)"
                                [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">                           
                        </div>
                    </div>
                    <div class="row m-0 pl-0 pb-0" style="max-height: 200px; overflow-y: scroll;">
                        <input type="file" style="height: 55px" (change)="selectFiles($event)" multiple />

                        <div *ngIf="progressInfos.length > 0">
                            <div *ngFor="let progressInfo of progressInfos">
                                <span>{{ progressInfo.fileName }} - {{ progressInfo.value }}%</span>
                                <progress [value]="progressInfo.value" max="100"></progress>
                            </div>
                        </div>
                              
                        <div *ngIf="message">
                            <span>{{ message }}</span>
                        </div>                                
                    </div>
                </div>
                <!--#end .modal-body -->

                <div class="modal-footer" style="justify-content: space-between !important;">
                    <div style="width: 50%; margin-left: 2px !important;">
                        <label for="last-transaction">Disable prompt message: </label>
                        <app-small-checkbox [(checked)]="disableThePopup" (click)="toggleCheckbox()"></app-small-checkbox>
                    </div>
                   
                    <button
                        style="margin-right: 2px !important;"
                        class="btn btn-success" (click)="uploadFiles()"
                        [disabled]="!selectedFiles || selectedFiles.length === 0">Upload</button>
                </div>

            </div>
        </div>
    </div>
</form>