<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="container-fluid" (click)="onPageClick($event)">
  <app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
  <div class="messages-container">
    <div class="column-tabs">
      <!-- <div class="tab" [class.active]="activeTab === 'inbox'" (click)="setActiveTab(tabs.inbox)">Inbox<i
          title="Unread messages" *ngIf="inboxMessageCounter != 0"
          class="fas fa-circle message-icon"><b>{{inboxMessageCounter}}</b></i>
      </div> -->
      <!--#end .tab -->
      <div class="tab" [class.active]="activeTab  === 'sent-items'" (click)="setActiveTab(tabs.sentItems)">Sent items
      </div><!--#end .tab -->
    </div><!--#end .column-tabs -->


    <div class="column-display-area">
      <!-- Sent Items messages search and filter -->
      <div class="display-options" *ngIf="activeTab === tabs.sentItems">
        <span class="column-search">
          <button class="btn btn-text">
            <i class="fa fa-search"></i>
          </button>
          <input class="input-search" type="search" placeholder="Search messages"
            (input)="this.searchItems.next($event)" [(ngModel)]="searchTerm">
        </span>
        <!-- <span></span> -->
        <span class="column-order-by">
          <label for="sort-by">Sort by:</label>
          <select id="sort-by" data-placeholder="'Another option'" placeholder="Filter" (change)="orderMessages()"
            [(ngModel)]="orderBy">
            <!-- <option value="" disabled selected="selected">Filter option</option> -->
            <option value="" disabled selected hidden> Filter options </option>
            <option *ngFor="let option of orderingOptions" [value]="option">
              {{option | titlecase}}
            </option>
          </select>
          <button class="btn btn-toggle" (click)="toggleAscending()">
            <i class="fa" [class.fa-sort-amount-desc]="!sortAscending" [class.fa-sort-amount-asc]="sortAscending"></i>
          </button> <!--#end .btn.btn-toggle -->
          
        </span> <!--#end .column-order-by -->
       
        <button class="btn mt-2" (click)="refreshList()" style="color: #646464; font-weight: 100 !important">
          <i class="fa fa-refresh"></i> Refresh
        </button>        
      </div><!--#end .display-options -->

      <!-- Inbox Items messages search and filter -->
      <div class="display-options" *ngIf="activeTab === tabs.inbox">
        <span class="column-search">
          <button class="btn btn-text" (click)="filterSentMessages()">
            <i class="fa fa-search"></i>
          </button>
          <input class="input-search" type="search" placeholder="Search messages" (keyup)="onInboxSearch()"
            [(ngModel)]="searchInboxTerm">
        </span>
        <!-- <span></span> -->
        <span class="column-order-by">
          <label for="sort-by">Sort by:</label>
          <select id="sort-by" data-placeholder="'Another option'" placeholder="Filter" (change)="orderMessages()"
            [(ngModel)]="orderBy">
            <!-- <option value="" disabled selected="selected">Filter option</option> -->
            <option value="" disabled selected hidden> Filter options </option>
            <option *ngFor="let option of orderingOptions" [value]="option">
              {{option | titlecase}}
            </option>
          </select>
          <button class="btn btn-toggle" (click)="toggleAscending()">
            <i class="fa" [class.fa-sort-amount-desc]="!sortAscending" [class.fa-sort-amount-asc]="sortAscending"></i>
          </button> <!--#end .btn.btn-toggle -->
        </span> <!--#end .column-order-by -->
      </div><!--#end .display-options -->

      <div class="table">
        <div class="table-head" [class.hide]="activeTab !== tabs.sentItems">
          <div class="table-row">

            <span class="column-date clickable" (click)="toggleSort('date')">
              Date
              <i class="fa fa-sort" [class.fa-sort-down]="sortDate"></i>
            </span>

            <span class="column-to clickable" (click)="toggleSort('to')">
              To
              <i class="fa fa-sort" [class.fa-sort-down]="sortTo"></i>
            </span>

            <span class="column-message-type">Type</span>

            <span class="column-doc"></span>

            <span class="column-invoice-number clickable" (click)="toggleSort('invoiceNo')">
              Invoice No.
              <i class="fa fa-sort" [class.fa-sort-down]="sortInvoiceNo"></i>
            </span>

            <span class="column-subject clickable" (click)="toggleSort('subject')">
              Subject
              <i class="fa fa-sort" [class.fa-sort-down]="sortSubject"></i>
            </span>

            <span class="column-status">Status</span>
            <span class="column-check" style="text-align: center !important;">
              <div class="btn-group group-menu" dropdown>
                <button
                  id="button-dropup"
                  dropdownToggle
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  style="font-weight: bold !important;"
                  aria-controls="dropdown-dropup">Multi-Action<span class="caret"></span>
                </button>
                <ul id="dropdown-dropup" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-dropup">
                  <li role="menuitem" *ngIf="selectedInvoices"><a style="width: 120px; text-align: center;" (click)="onForwardMultipleInvoices()" class="dropdown-item">Forward Invoices</a></li>
                  <li role="menuitem" *ngIf="selectedInvoices"><a style="width: 120px; text-align: center;" (click)="onResendMultipleInvoices()" class="dropdown-item">Resend Invoices</a></li>                  
                </ul>
              </div>
            </span>
          </div><!--#end .table-row -->
        </div><!--#end .table-head -->

        <div class="table-body" [class.hide]="activeTab !== tabs.sentItems" *ngIf="activeButtonNotes">
          <app-circular-indicator [isVisible]="isLoading"></app-circular-indicator>
          <div *ngFor="let message of mainSentItemData" class="table-row">
            <span class="column-date" [title]="message.data.dateDelivered | date:'yyyy/MM/dd'">
              {{message.data.dateDelivered | date:'yyyy-MM-dd'}}
            </span>
            <span class="column-to" [title]="message.data.email">
              {{message.data.email}}
            </span>
            <span class="column-message-type" [title]="message.data.source">
              {{message.data.source}}
            </span>
            <span class="column-doc" title="Documents" (click)="onViewBriefDocuments(message)">
              <i *ngIf="message.data.otherDocuments" class="fal fa-paperclip" style="font-size: 14px !important;"></i>
            </span>
            <span class="column-invoice-number" (click)="onViewInvoice(message.data)">
              {{message.data.invoiceNo}}
            </span>
            <span class="column-subject" [title]="message.data.subject">
              {{message.data.subject}}
            </span>            
            <span class="column-status"> <!-- (click)="onShowInvoiceNoteDetail(message.data)" -->
              <img src="../../../../assets/images/logos/spinner.gif" *ngIf="message.data.status === 'Sending'"
            height="20" alt="spinner">
              {{message.data.status | titlecase}}
            </span>
            <span class="column-check">
              <app-checkbox
              *ngIf="message.data.source === 'Invoice' && message.data.status !== 'historical'"
              [(checked)]="message.state.isSelected"
              (click)="toggleChecbox(message)"></app-checkbox>
            </span>

            <span class="column-action">
              <span class="btn-group" *ngIf="message.data.source === 'Invoice' || message.data.source === 'CreditNote'">
                <a [ngStyle]="{'width': message.data.status !== 'Sending' ? 'auto' : '115px','text-align': 'left'}" 
                (click)="onViewInvoice(message.data)"> View invoice</a>
                <i *ngIf="message.data.status != 'Sending'" class="fa fa-angle-down clickable"
                  (click)="toggleMenu(message)"></i>              
              </span>

              <span class="btn-group" *ngIf="message.data.source === 'Statement'">
                <a
                  (click)="onViewInvoice(message.data)">
                  View statemt &nbsp; &nbsp; &nbsp; &nbsp;</a>
              </span>

              <span class="btn-group" *ngIf="message.data.source === 'Notes'">
                <a [ngStyle]="{'width': message.data.status !== 'Sending' ? 'auto' : '115px','text-align': 'left'}" 
                  (click)="onViewInvoice(message.data)"> View notes</a>
                <i *ngIf="message.data.status != 'Sending'" class="fa">&nbsp; &nbsp; &nbsp;</i>
              </span>

              <span class="btn-group" *ngIf="message.data.source === 'Quotation'">
                <a [ngStyle]="{'width': message.data.status !== 'Sending' ? 'auto' : '115px','text-align': 'left'}" 
                  (click)="onViewQuotation(message.data)"> View quote</a>
                <i *ngIf="message.data.status != 'Sending'" class="fa">&nbsp; &nbsp; &nbsp;</i>
              </span>

              <app-sent-message-popup-menu #popupMenu [isVisible]="message.state.showDropdown" [message]="message"
                (dismiss)="onDismissMenu($event)" (forwardInvoice)="onForwardInvoice($event)"
                (resendInvoice)="onResendInvoice($event)" (editInvoice)="onEditInvoice($event)"
                (continueWithMatter)="onContinueWithMatter($event)">
              </app-sent-message-popup-menu>
            </span><!--#end .column-action -->
          </div> <!--#end .table-row -->
        </div><!--#end .table-body -->
        <div *ngIf="displayListCount > 0" style="float: left; padding: 10px; font-weight: 700; font-size: 12px;">
          {{ displayListCount + " out of " + displaySentItemTotalCount }}
        </div>
        <div *ngIf="activeTab === tabs.sentItems && currentSentItemCount < displaySentItemTotalCount"
          style="float: right;">
          <button class="btnLoadMoreEnable" (click)="LoadMoreSentItems()">
            <a class="fa fa-arrow-down fa-lg" style="margin-right: 5px;color:#8abd37"></a>
            <u class="auxcon-green" style="text-transform:uppercase;color:#8abd37;font-size:12px;font-weight:700;">Load
              More</u>
          </button>
        </div>

        <div class="responsive-table" [class.hide]="activeTab !== tabs.inbox">
          <table class="table table-striped" id="detail_table" style="padding: 0 !important;" cellspacing="0">
            <tbody>
              <tr>
                <td>Date <i class="fa fa-sort" [class.fa-sort-down]="sortInvoiceNo"></i></td>
                <td>From <i class="fa fa-sort" [class.fa-sort-down]="sortInvoiceNo"></i></td>
                <td>IN RE <i class="fa fa-sort" [class.fa-sort-down]="sortInvoiceNo"></i></td>
                <td>Category <i class="fa fa-sort" [class.fa-sort-down]="sortInvoiceNo"></i></td>
                <td>Subcategory <i class="fa fa-sort" [class.fa-sort-down]="sortInvoiceNo"></i></td>
                <td>Subject <i class="fa fa-sort" [class.fa-sort-down]="sortInvoiceNo"></i></td>
                <td></td>
                <td></td>
              </tr>
              <ng-container *ngFor="let inbox of notesHandle">
                <tr class="accordion-toggle parent" [id]=inbox.data.Id [attr.data-target]="'#' + inbox.data.Id"
                  data-role="expander">
                  <td *ngIf="inbox.state.isVisible" class="inbox-date-column clickable" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserAssignedStatus)}">
                    {{inbox.data.CaptureDate | date:'yyyy-MM-dd'}}</td>
                  <td *ngIf="inbox.state.isVisible" class="inbox-from-user-column clickable" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserAssignedStatus)}">
                    {{inbox.data.UserCreatorFullName}}</td>
                  <td *ngIf="inbox.state.isVisible" class="inbox-reference-column clickable" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserAssignedStatus)}">
                    {{inbox.data.ObjectTypeDescription}}</td>
                  <td *ngIf="inbox.state.isVisible" class="inbox-category-column clickable" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserAssignedStatus)}">
                    {{inbox.data.Category}}</td>
                  <td *ngIf="inbox.state.isVisible" class="inbox-subcategory-column clickable" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserAssignedStatus)}">
                    {{inbox.data.Subject}}</td>
                  <td *ngIf="inbox.state.isVisible" class="inbox-subject-column clickable" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserAssignedStatus)}">
                    {{inbox.data.QuestionText}} {{inbox.data.ExternalText}}</td>
                  <td *ngIf="inbox.state.isVisible" class="inbox-message-icon-column clickable" scope="row"
                    (click)="onRowClickExpand(inbox)"
                    [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserAssignedStatus)}">
                    <i *ngIf="inbox.data.UserAssignedStatus === false" title="unread message"
                      class="fa fa-envelope"></i>
                    <i *ngIf="inbox.data.UserAssignedStatus === true" class="fa fa-envelope-open"></i>
                  </td>
                  <td *ngIf="inbox.state.isVisible">
                    <span class="inbox-message-close-open clickable" data-toggle="collapse"
                      (click)="onRowClickExpand(inbox)"
                      [ngStyle]="{'font-weight': getUnReadMessageFont(inbox.data.UserAssignedStatus)}">
                      <label class="clickable" *ngIf="!inbox.state.isOpen">Open</label>
                      <label class="clickable" *ngIf="inbox.state.isOpen">Close</label>
                      <i *ngIf="!inbox.state.isOpen" class="far fa-chevron-down"></i>
                      <i *ngIf="inbox.state.isOpen" class="far fa-chevron-up"></i>
                    </span>
                  </td>
                </tr>
                <tr *ngIf="inbox.state.isOpen" [id]="inbox.data.Id" class="accordion-body collapse reveal"
                  [class]="'child-' + inbox.data.Id" aria-expanded="true">
                  <td colspan="7">
                    <div class="row" style="padding-top: 0; padding-bottom: 0;">
                      <div class="form-group">
                        <i class="far fa-question-circle" *ngIf="inbox.data.ObjectName !== 'New Invoice'"></i>
                        <strong *ngIf="inbox.data.ObjectName === 'Invoice'"><label class="clickable-link clickable">
                            {{inbox.data.QuestionId === null ? 'IN RE' : 'Request for instruction on'}} <a
                              (click)="onInvoicePreview(inbox.data)">{{inbox.data.ObjectTypeDescription}}</a>
                            for {{inbox.data.InvoiceNoteMatter}} - {{inbox.data.NoteAbout}}</label></strong>
                        <strong *ngIf="inbox.data.ObjectName === 'Contact'"><label> {{inbox.data.QuestionId === null ?
                            'IN RE' : 'Request for instruction'}} {{inbox.data.ObjectTypeDescription}}
                            {{inbox.data.Category}}</label></strong>
                      </div>
                    </div>
                    <div class="row" *ngIf="inbox.data.QuestionId > 0" style="padding-top: 0; padding-bottom: 0;">
                      <div class="form-group">
                        <label><strong>Instruction question:</strong> {{inbox.data.QuestionText}}</label>
                      </div>
                    </div>
                    <div class="row" *ngIf="inbox.data.ExternalText" style="padding-top: 0; padding-bottom: 0;">
                      <div class="form-group">
                        <label *ngIf="inbox.data.ObjectName !== 'New Invoice'"><strong>Practice Manager Comment:
                          </strong>{{inbox.data.ExternalText}}</label>
                        <label class="clickable-link clickable"
                          *ngIf="inbox.data.ObjectName === 'New Invoice'"><strong>IN RE: </strong>
                          <a (click)="onViewInboxInvoice(inbox.data)">{{inbox.data.ObjectTypeDescription}}</a>
                          {{inbox.data.InvoiceNoteMatter}}</label>
                      </div>
                    </div>
                    <div class="row" style="padding-top: 0; padding-bottom: 0;"
                      *ngIf="inbox.data.InstructionResponseId > 0">
                      <div class="form-group">
                        <label><strong>You:</strong> {{inbox.data.ResponseText === null ? '' : inbox.data.ResponseText}}
                          {{ inbox.data.InstructionText}}</label>
                      </div>
                    </div>
                    <div class="row" style="padding-top: 0; padding-bottom: 0;"
                      *ngIf="inbox.data.InstructionResponseId > 0 && inbox.data.ResolutionText">
                      <div class="form-group">
                        <label><strong>Practice Manager:</strong> {{inbox.data.ResolutionText}}</label>
                      </div>
                    </div>
                    <div class="row" *ngIf="inbox.data.InstructionResponseId === 0 && inbox.data.QuestionId > 0">
                      <div class="control-group" style="padding-top: 0; padding-bottom: 0;">
                        <label for="action">Action</label>
                        <select class="form-control" id="action" (change)="onNoteResponseSelect()"
                          [(ngModel)]="selectedResponseId">
                          <option value=0 disabled selected hidden>Select action</option>
                          <option *ngFor="let action of instructionResponses" [value]="action.Id">
                            {{action.Response}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row" *ngIf="inbox.data.InstructionResponseId === 0 && inbox.data.QuestionId > 0">
                      <div class="control-group">
                        <label for="action">Comment</label>
                        <textarea class="input-control" [(ngModel)]="instructionText"></textarea>
                        <button class="btn btn-success" (click)="onInstructionSave()" [class.disabled]="!isSaveable"
                          [disabled]="!isSaveable">SEND</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div> <!-- #end .responsive-table -->

      </div><!--#end .table -->
    </div><!--#end .column-display-area -->
  </div><!--#end .messages-container -->
</div><!--#end .container-fluid -->
<app-create-recipient-modal
  *ngIf="showRecipientsModal"
  [isVisible]="showRecipientsModal"
  (closeModal)="onCloseModal()"
  (saveRecipients)="onForwardMessage($event)"
  (saveRecipientsMultipleMessage)="onForwardMultipleInvoiceMessage($event)"></app-create-recipient-modal>

  <app-create-recipient-modal
  *ngIf="forwardMultipleInvoices"
  [isVisible]="forwardMultipleInvoices"
  (closeModal)="onForwardMultipleInvoiceMessageCloseModal()"
  (saveRecipients)="onForwardMultipleInvoiceMessage($event)"></app-create-recipient-modal>

<app-create-recipient-modal *ngIf="showRecipientsModal" [isVisible]="showRecipientsModal" (closeModal)="onCloseModal()"
  (saveRecipients)="onForwardMessage($event)"></app-create-recipient-modal>

<app-invoice-preview-modal *ngIf="showProformaInvoice" #invoicePreview [selectedTab]="selectedTab"
  [notesHandle]="selectedNotesHandle" [plaintiff]="invoicePlaintiff" [selectedTab]="selectedTab"
  [defendant]="invoiceDefendant" [invoiceId]="selectedInvoiceId" [invoiceDetails]="invoiceDetails"
  [feesQuoted]="feesQuoted" [isVisible]="showProformaInvoice" (closeModal)="closeModal('invoice')">
</app-invoice-preview-modal>

<app-quotation-preview-modal *ngIf="showQuotation" #quotationPreview [plaintiff]="invoicePlaintiff"
  [defendant]="invoiceDefendant" [quotationId]="selectedInvoiceId" [quotationDetails]="invoiceDetails"
  [isVisible]="showQuotation" (closeModal)="closeModal('quotation')">
</app-quotation-preview-modal>

<app-lawfirm-invoice-preview *ngIf="showInboxInvoice" #invoicePreview [plaintiff]="invoicePlaintiff"
  [defendant]="invoiceDefendant" [invoiceId]="selectedInvoiceId" [invoiceDetails]="invoiceDetails"
  [isVisible]="showInboxInvoice" (closeModal)="closeModal('inbox-invoice')">
</app-lawfirm-invoice-preview>

<app-client-statement-modal *ngIf="showStatement" #statement [title]="documentTitle" [isVisible]="showStatement"
  [messageId]="messageId" (closeModal)="closeModal('statement')"></app-client-statement-modal>

<app-brief-preview-modal *ngIf="showBriefDocumentModal" [isVisible]="showBriefDocumentModal"
  [briefDocumentHandle]="briefDocumentHandle" (closeModal)="closeModal('bried-document')"></app-brief-preview-modal>

<app-client-note-modal *ngIf="showInvoiceNoteDetail" [isVisible]="showInvoiceNoteDetail" [notes]="notes"
  (closeModal)="closeModal('client-note')"></app-client-note-modal>

<app-advocate-note-modal *ngIf="showAdvocateNoteDetail" [isVisible]="showAdvocateNoteDetail" [objectType]="objectType"
  [plaintiff]="invoicePlaintiff" [defendant]="invoiceDefendant" [invoiceDetails]="invoiceDetails"
  [selectedNote]="selectedNote" [instructionResponses]="instructionResponses"
  (closemodal)="closeAdvocateNoteDetail()"></app-advocate-note-modal>

<app-client-multiple-invoice-note *ngIf="showClientMultiInvoiceNote" [isVisible]="showClientMultiInvoiceNote"
  [objectType]="objectType" [selectedNote]="selectedNote" [multiInvoiceNote]="multiInvoiceNote"
  [multipleObjectNotes]="multipleObjectNotes" [instructionResponses]="instructionResponses"
  (saveMultiNote)="onSaveMultiInvoice()"
  (closemodal)="closeClientMultiInvoiceNote()"></app-client-multiple-invoice-note>

<app-note-detail-modal *ngIf="onShowNoteDetail" [isVisible]="onShowNoteDetail" [objectType]="objectType"
  [noteDetailData]="noteDetailData" [lawfirmSummaryData]="lawfirmSummaryData" [noteCategory]="noteCategory"
  (closemodal)="onShowNoteDetailModalDismiss()" (saveNote)="onNoteSave($event)" (updateNote)="OnAddResolution($event)">
</app-note-detail-modal>