import { Component, OnInit } from "@angular/core";
import { LoadingService } from "../../services/messaging/loading/loading.service";
import {
  SnackbarsService,
  Snack,
  SnackType,
} from "../../services/messaging/snackbars/snackbars.service";
import {
  UserProfileService,
  UserProfile,
  AddNewUser,
  UserOnboardingMinimum,
  PersonalDetailsOnboard,
  ContactDetailsMinimum,
  OptionalProcessing,
} from "../../services/user-profile/user-profile.service";
import { UtilitiesService } from "../../services/utilities.service";
import {
  ToastService,
  LENGTH_LONG,
} from "../../services/messaging/toast/toast.service";
import { ICrumb } from "../../services/advocate-detail-statement/advocate-detail-statement.service";
const TITLE_PREFIX = "Add New User";

@Component({
  selector: "app-create-new-user",
  templateUrl: "./create-new-user.component.html",
  styleUrls: ["./create-new-user.component.scss"],
})
export class CreateNewUserComponent implements OnInit {
  title: string = TITLE_PREFIX;
  //title = "Edit fee templates";
  addNewUser: AddNewUser = {
    FirstName: "",
    LastName: "",
    AuxconUserName: "",
    EmailAddress: "",
    JobTitle: "",
    MobileNumber: "",
    ServiceProviderId: null,
    AddFeeTemplates: false,
  };
  contactdetails: ContactDetailsMinimum = {
    EmailAddress: "",
    MobileNumber: "",
  };
  optionalProcessing: OptionalProcessing = {
    AddTemplates: false,
  };

  persnolDetails: PersonalDetailsOnboard = {
    JobTitle: "",
    FirstName: "",
    LastName: "",
    ServiceProviderID: 0,
    AuxconUserName: "",
  };
  userOnbording: UserOnboardingMinimum = {
    isAlreadyOfficeUser : false,
    PersonalDetailsOnboard: this.persnolDetails,
    ContactDetailsMinimum: this.contactdetails,
    OptionalProcessing: this.optionalProcessing,
  };

  firstName: string;
  lastName: string;
  auxconUserName: string;
  emailAddress: string;
  jobTitle: string;
  mobileNumber: string;
  serviceProviderId: number;
  addFeeTemplates: boolean;
  addFeeTemplatesInstring: string;
  isValidAttUserName;
  isValidJobTitle = false;
  isValidFirstName = false;
  isValidLastName = false;
  isValidAttEmail = false;
  isValidMobileNumber = false;
  isValidServiceProviderID = false;
  showUserErrorPromptAUname = false;
  showUserErrorPromptJobTitle = false;
  showUserErrorPromptFirstName = false;
  showUserErrorPromptLastName = false;
  showUserErrorPromptEmailID = false;
  showUserErrorPromptMobileNumber = false;
  showUserErrorPromptServiceProviderId = false;
  showUserErrorPromptYesorNo = false;
  crumbs: ICrumb[];
  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService
  ) {
    // this.isSubmittable = false;
  } // end constructor()

  ngOnInit() {
    this.crumbs = [
      {
        label: "Add | New User",
      },
    ];
    this.addFeeTemplatesInstring = "false";
  } // ngOnInit()

  AddNewUser() {
    
    //Validations
    if (this.auxconUserName == "" || this.auxconUserName == undefined) {
      this.showUserErrorPromptAUname = true;
      return;
    } else if (this.jobTitle == "" || this.jobTitle == undefined) {
      this.showUserErrorPromptJobTitle = true;
      return;
    } else if (this.firstName == "" || this.firstName == undefined) {
      this.showUserErrorPromptFirstName = true;
      return;
    } else if (this.lastName == "" || this.lastName == undefined) {
      this.showUserErrorPromptLastName = true;
      return;
    } else if (this.emailAddress == "" || this.emailAddress == undefined) {
      this.showUserErrorPromptEmailID = true;
      return;
    } else if (this.mobileNumber == "" || this.mobileNumber == undefined) {
      this.showUserErrorPromptMobileNumber = true;
      return;
    } else if (
      this.serviceProviderId == null ||
      this.serviceProviderId == undefined
    ) {
      this.showUserErrorPromptServiceProviderId = true;
      return;
    } else if (
      this.addFeeTemplatesInstring == "" ||
      this.addFeeTemplatesInstring == undefined
    ) {
      this.showUserErrorPromptYesorNo = true;
      return;
    }

    if (this.addFeeTemplatesInstring == "true") this.addFeeTemplates == true;
    else this.addFeeTemplates == false;

    this.loadingService.showOverlay();
    const snack: Snack = {
      label: "Submitting New User",
      action: null,
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    
    this.addNewUser = {
      FirstName: this.firstName,
      LastName: this.lastName,
      AuxconUserName: this.auxconUserName,
      EmailAddress: this.emailAddress,
      JobTitle: this.jobTitle,
      MobileNumber: this.mobileNumber,
      ServiceProviderId: this.serviceProviderId,
      AddFeeTemplates: this.addFeeTemplates,
    };

    this.contactdetails = {
      EmailAddress: this.emailAddress,
      MobileNumber: this.mobileNumber,
    };
    this.persnolDetails = {
      JobTitle: this.jobTitle,
      FirstName: this.firstName,
      LastName: this.lastName,
      ServiceProviderID: this.serviceProviderId,
      AuxconUserName: this.auxconUserName,
    };
    this.optionalProcessing = {
      AddTemplates: this.addFeeTemplatesInstring == "true" ? true : false,
    };
    this.userOnbording = {
      isAlreadyOfficeUser : false,
      PersonalDetailsOnboard: this.persnolDetails,
      ContactDetailsMinimum: this.contactdetails,
      OptionalProcessing: this.optionalProcessing,
    };

    this.userProfileService.createNewUser(this.userOnbording,null).subscribe(
      (profile) => {
        
      },
      (error) => {
        this.loadingService.hideOverlay();
        snack.label =
          "Something went wrong, please contact auxcon for your request";
        snack.action = null;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        console.error("Something went wrong: ", error);
      },
      () => {
        snack.label = "New user created successfully";
        this.loadingService.hideOverlay();
        this.snackbarsService.dismiss().make(snack).show();
        this.cancelNewUser();
      }
    );
  }

  onBlurJobTitle() {
    if (this.jobTitle == "" || this.jobTitle == undefined)
      this.isValidJobTitle = true;
    else this.isValidJobTitle = false;
  }
  onBlurAuxconUserName() {
    
    if (this.auxconUserName == "" || this.auxconUserName == undefined)
      this.isValidAttUserName = true;
    else this.isValidAttUserName = false;
  }
  onBlurFirstName() {
    if (this.firstName == "" || this.firstName == undefined)
      this.isValidFirstName = true;
    else this.isValidFirstName = false;
  }
  onBlurLastName() {
    if (this.lastName == "" || this.lastName == undefined)
      this.isValidLastName = true;
    else this.isValidLastName = false;
  }
  onBlurEmail() {
    if (!this.util.isValidEmail(this.emailAddress)) {
      this.isValidAttEmail = true;
    } else {
      this.isValidAttEmail = false;
    }
  }
  onBlurMobileNumber() {
    if (this.mobileNumber == "" || this.mobileNumber == undefined)
      this.isValidMobileNumber = true;
    else this.isValidMobileNumber = false;
  }
  onBlurServiceProvider() {
    if (
      this.serviceProviderId == null ||
      this.serviceProviderId == undefined ||
      isNaN(this.serviceProviderId)
    )
      this.isValidServiceProviderID = true;
    else this.isValidServiceProviderID = false;
  }

  cancelNewUser() {
    this.firstName = null;
    this.lastName = null;
    this.serviceProviderId = null;
    this.emailAddress = null;
    this.auxconUserName = null;
    this.mobileNumber = null;
    this.jobTitle = null;
    this.addFeeTemplatesInstring = "false";
  }

  onHideAlert() {
    this.showUserErrorPromptAUname = false;
    this.showUserErrorPromptJobTitle = false;
    this.showUserErrorPromptFirstName = false;
    this.showUserErrorPromptLastName = false;
    this.showUserErrorPromptEmailID = false;
    this.showUserErrorPromptMobileNumber = false;
    this.showUserErrorPromptServiceProviderId = false;
    this.showUserErrorPromptYesorNo = false;
  }
}
