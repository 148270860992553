import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Category, DEFAULT_CATEGORY } from '../../../services/categories/categories.service';
import { SubCategory, DEFAULT_SUB_CATEGORY } from '../../../services/sub-categories/sub-categories.service';
import { QuotationFeeItem, DEFAULT_QUOTATION_FEE_ITEM } from '../../../services/quotation/quotation.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { FeeTemplateService, DEFAULT_FEE_TEMPLATE } from '../../../services/fee-template/fee-template.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

const MIN_COMMENT_LENGTH = 1;
const MAX_COMMENT_LENGTH = 3000;

export interface CategoryHandle {
  data: any;
  state: {
    isActive: boolean;
    isSelected: boolean;
    isVisible: boolean;
  };
}

@Component({
  selector: 'app-datalist-quotation-category',
  templateUrl: './datalist-quotation-category.component.html',
  styleUrls: ['./datalist-quotation-category.component.scss']
})
export class DatalistQuotationCategoryComponent implements OnInit, OnChanges {

  types: any = {
    category: 'category',
    subCategory: 'sub-category'
  };
  categoryList: CategoryHandle[] = [];
  isActive: boolean;
  iconClass: string;
  showFeeTemplateModal = false;
  activeFeeTemplate: QuotationFeeItem;
  numberOfCharactersLeft = 750;
  displayValue: string;

  private timeoutId;
  private blurDebounceTime = 300;
  exceedsMaxCommentLength = false;
  private feeTemplateDescription: string;

  @Input() type: string;
  @Input() category: Category | SubCategory;
  @Input() value: string;
  @Input() list: Array<Category | SubCategory>;
  @Output() categoryChange: EventEmitter<Category | SubCategory>
    = new EventEmitter<Category | SubCategory>();
  @Output() input: EventEmitter<any> = new EventEmitter<any>();

  get isValidText(): Boolean {
    return !this.utilityService.hasTextValue(this.displayValue);
  } // end isValidText()

  constructor(
    private utilityService: UtilitiesService,
    private snackbarsService: SnackbarsService,
    private feeTemplateService: FeeTemplateService,
    private userProfileService: UserProfileService,
  ) { } // end constructor()

  ngOnInit() {
    if (this.value) {
      this.displayValue = this.value;
      this.validate();
    }
  } // end ngOnInit()

  onKeydown(event) {
    this.value = event.target.value;
    this.validate();
  } // end onKeydown()

  validate() {
    let isOk = true;
    this.numberOfCharactersLeft =
    (this.value) ? MAX_COMMENT_LENGTH - this.value.length : MAX_COMMENT_LENGTH;

    if (this.value.length < MIN_COMMENT_LENGTH) {
      isOk = false;
    }

    if (this.value.length > MAX_COMMENT_LENGTH) {
      this.exceedsMaxCommentLength = true;
      isOk = false;
    } else {
      this.exceedsMaxCommentLength = false;
    }
  } // end validate()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.type && !changes.type.currentValue) {
      this.type = this.types.categories;
    }

    if (changes.category && !changes.category.currentValue) {
      if (this.type) {
        if (this.type.localeCompare('category') === 0) {
          this.category = Object.assign({}, DEFAULT_CATEGORY);
        }

        if (this.type.localeCompare('sub-category') === 0) {
          this.category = Object.assign({}, DEFAULT_SUB_CATEGORY);
          const cat = this.category as SubCategory;
          this.filterCategoryList(cat.SubCategory);
        }

        if (!this.value && this.category) {
          if (this.type === this.types.subCategory) {
            this.displayValue = this.category.SubCategory;
          } else {
            this.displayValue = this.category.Category;
          }
        }
      }
    }

    if (changes.value && changes.value.currentValue) {
      this.validate();
      this.displayValue = this.value;
    }

    if (changes.list && changes.list.currentValue) {
      this.validate();
      this.buildCategoryList();
    }
  } // end ngOnChanges()

  toggleDropdown() {
    this.isActive = !this.isActive;
  } // end toggleDropdown()

  onSelect(category: CategoryHandle) {
    this.category = Object.assign({}, category.data);
    this.categoryChange.emit(this.category);
  } // end onSelect()

  onBlur() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.hideList();
    }, this.blurDebounceTime);
  } // end onBlur()

  onFocus() {
    this.showList();
  } // end onFocus()

  onInput(event) {
    this.feeTemplateDescription = event.target.value;
    this.filterCategoryList(this.feeTemplateDescription);
    this.input.emit(event);
  } // end onInput()

  hideList() {
    this.isActive = false;
  } // end hideList()

  showList() {
    this.isActive = true;
  } // end showList()

  private buildCategoryList() {
    this.categoryList = [];

    this.list.map(item => {
      this.categoryList.push({
        data: item,
        state: {
          isActive: false,
          isSelected: false,
          isVisible: true
        }
      });
    });
  } // end buildCategoryList()

  private filterCategoryList(searchTerm: string) {
    searchTerm = this.utilityService.regexEscape(searchTerm);
    if (this.category && this.categoryList) {
      this.categoryList.forEach(item => {
        item.state.isVisible = this.type === this.types.subCategory
          ? Boolean(
            item.data.Category.match(
              new RegExp(searchTerm, 'gi')
            )
          ) ||
          Boolean(
            item.data.SubCategory.match(
              new RegExp(searchTerm, 'gi')
            )
          ) ||
          Boolean(
            item.data.RateType.match(
              new RegExp(searchTerm, 'gi')
            )
          )
          : Boolean(
            item.data.Category.match(
              new RegExp(searchTerm, 'gi')
            )
          );
      });
    }
  } // end filterCategoryList()

  /**
   * This will only open when user edit quotation fee template.
   */
  // createNewFeeTemplate() {
  //   this.activeFeeTemplate = Object.assign({}, DEFAULT_QUOTATION_FEE_ITEM);
  //   this.activeFeeTemplate.su
  // } // end createNewFeeTemplate()
} // end DatalistQoutationCategoryComponent{}
