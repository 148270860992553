<div class="container-fluid">
  <h2 class="page-title">{{title | titlecase}}</h2>
</div> <!-- #end .container-fluid-->


<div class="container-fluid container-flex" *ngIf="data">
  <span class="segment">
    <span class="segment-head" (click)="ageAnalysisClicked('view-98-days')">98 days</span>
    <span class="segment-data"
      (click)="ageAnalysisClicked('view-98-days')">{{data.NinetyEightDays | currency:'R '}}</span>
  </span>

  <span class="segment">
    <span class="segment-head" (click)="ageAnalysisClicked('view-90-days')">90 days</span>
    <span class="segment-data" (click)="ageAnalysisClicked('view-90-days')">{{data.NinetyDays | currency:'R '}}</span>
  </span>

  <span class="segment">
    <span class="segment-head" (click)="ageAnalysisClicked('view-60-days')">60 days</span>
    <span class="segment-data" (click)="ageAnalysisClicked('view-60-days')">{{data.SixtyDays | currency:'R '}}</span>
  </span>

  <span class="segment">
    <span class="segment-head" (click)="ageAnalysisClicked('view-30-days')">30 days</span>
    <span class="segment-data" (click)="ageAnalysisClicked('view-30-days')">{{data.ThirtyDays | currency:'R '}}</span>
  </span>

  <span class="segment">
    <span class="segment-head" (click)="ageAnalysisClicked('view-0-days')">Current</span>
    <span class="segment-data" (click)="ageAnalysisClicked('view-0-days')">{{data.ZeroDays | currency:'R '}}</span>
  </span>
</div> <!-- #end .container-fluid-->