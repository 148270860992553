<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div class="container-fluid">
    <!-- <h2 class="page-title">MANAGE PROOF OF PAYMENTS</h2> -->

    <div class="process-body row">
        <div class="left-block col-sm-6">
            <div class="block-title">Proof of Payments</div>
            <div class="block-action-bar row">
                <div class=" col-sm-1 w-13">Actions</div>
                <div class=" col-sm-4 w-28 active "><a (click)="openModel()"><i class="fal fa-file-plus"></i><u>Add
                            Proof of
                            Payment</u></a></div>
                <div class=" col-sm-4" [class.active]="showAttachmentActions">
                    <input type="file" accept="" name="fileUpload" id="addAttachmentUpload" class="custom-file-input"
                        (change)="attachmentChange($event)" style="display: none">
                    <a (click)="promptAttachment()"><i class="fal fa-paperclip"></i><u>Add Attachment to
                            selected</u></a></div>
                <div class=" col-sm-3" [class.active]="showDeleteActions"><a (click)="onClickDelete()"><i
                            class="fal fa-trash"></i><u>Delete selected</u></a></div>
            </div>

            <div class="row reconcile-search input-group">
                <input type="search"
                #inputCheckbox 
                class="form-control" placeholder="Search" 
                [(ngModel)]="searchTerm" 
                (keyup)="performSearch()">
                <div class="input-group-append">
                    <i *ngIf="!searchTerm" class="far fa-search"></i>
                    <i *ngIf="searchTerm" (click)="clearSearch()" class="far fa-close"></i>
                </div>
            </div>

            <div class="row m-0">
                <div class="table-responsive" style="max-height: 400px;">
                    <table class="table table-striped ">

                        <tbody>
                            <tr>
                                <th scope="col">
                                    <div class="checkbox">
                                        <input
                                            type="checkbox"
                                            (change)="toggleSelectAll()">
                                    </div>
                                </th>
                                <th
                                scope="col">Edit</th>
                                <th
                                scope="col"
                                (click)="toggleSort('date')">Date
                            <span style="float: right; min-width: 80px;"><i class="fa fa-sort" [class.fa-sort-down]="sortDate"></i></span></th>
                                <th
                                scope="col"
                                (click)="toggleSort('comments')">Comments
                            <span style="float: right;"><i class="fa fa-sort" [class.fa-sort-down]="sortComments"></i></span></th>
                                <th
                                scope="col"
                                (click)="toggleSort('lawfirm')">Law Firm
                            <span style="float: right;"><i class="fa fa-sort" [class.fa-sort-down]="sortLawfirm"></i></span></th>
                                <th
                                scope="col"
                                (click)="toggleSort('reference')">Payment Reference
                            <span style="float: right;"><i class="fa fa-sort" [class.fa-sort-down]="sortReference"></i></span></th>
                                <th
                                scope="col"
                                (click)="toggleSort('amount')" class="text-right">Amount
                            <span style="float: right; min-width: 80px;"><i class="fa fa-sort" [class.fa-sort-down]="sortAmount"></i></span></th>
                                <th
                                scope="col"
                                (click)="toggleSort('allocated')" class="text-center">Allocated
                            <span style="float: right;"><i class="fa fa-sort" [class.fa-sort-down]="sortAllocated"></i></span></th>
                                <th scope="col"></th>
                            </tr>
                            <tr class="tr" [class.active]="row == proof.data.Id" *ngFor="let proof of addedProofOfPayment">
                                <td *ngIf="proof.state.isVisible" scope="row">
                                    <!-- <div class="checkbox">
                                        <input type="checkbox" tabindex="-1"
                                            (change)="onChangeCheckBox(proof.Id, $event.target.checked)"
                                            id="p{{proof.Id}}">
                                        <label for="p{{proof.Id}}"></label>
                                    </div> -->
                                    <app-small-checkbox
                                        [(checked)]="proof.state.isSelected"
                                        (click)="toggleCheckbox(proof)"></app-small-checkbox>
                                </td>
                                <td *ngIf="proof.state.isVisible" (click)="onEditPOP(proof.data)">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                </td>
                                <td *ngIf="proof.state.isVisible" style="min-width: 80px;" (click)="onRowClick(proof.data)">{{getDateFormet(proof.data.Date)}}</td>
                                <td *ngIf="proof.state.isVisible" class="text-truncate" (click)="onRowClick(proof.data)">{{proof.data.Description}}</td>
                                <td *ngIf="proof.state.isVisible" class="text-truncate" (click)="onRowClick(proof.data)">{{proof.data.LawFirm}}</td>
                                <td *ngIf="proof.state.isVisible" (click)="onRowClick(proof.data)">{{proof.data.Reference}}</td>
                                <td *ngIf="proof.state.isVisible" style="min-width: 80px;" class="text-right" (click)="onRowClick(proof.data)">
                                    R{{numberConversion(proof.data.Amount)}}</td>
                                <td *ngIf="proof.state.isVisible" class="text-center" (click)="onRowClick(proof.data)"><u
                                        [ngStyle]="{'color': getAllocatedColor(proof.data.Allocated)}">{{proof.data.Allocated}}</u>
                                </td>
                                <td *ngIf="proof.state.isVisible"
                                        (click)="viewProofOfPayment(proof.data)">
                                        <a *ngIf="proof.data.HasAttachment === true">
                                            <i class="fal fa-paperclip"
                                            style="font-size: 16px !important;">
                                        </i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="right-block col-sm-6">

            <app-invoices-added-proof-of-payment
            [onShow]="promptInvoicesEvent"
            (refreshPage)="onCloseModel($event)"></app-invoices-added-proof-of-payment>



        </div>
    </div>
    <!-- <div class="row m-0 process-footer">
        <div class="col-sm-4 p-0 pr-10"><button class="form-control btn btn-danger">CANCEL</button></div>
        <div class="col-sm-4 p-0 pl-5 pr-5"><button class="form-control btn btn-primary">SAVE & CLOSE</button></div>
        <div class="col-sm-4 p-0 pl-10"><button class="form-control btn btn-success">SEND SATEMENTS</button></div>
    </div> -->
    <!--#end .modal-header -->
    <app-add-proof-of-payments
        *ngIf="isVisible"
        [proof]="proof"
        (refreshPage)="onCloseModel($event)"></app-add-proof-of-payments>

</div>
<app-proforma-invoice
    #proformaInvoice
    title="Proof Of Payment"
    [popId]="popId"
    invoice="0"
    documentType="ProofOfPayment"
    matter="null"
    [isVisible]="showProofOfPaymentDoc"
    (closeModal)="closeModal()"></app-proforma-invoice>

<app-delete-confirm-model
    title="Delete proof of payment(s)"
    message="Are you sure you want to delete this proof of payment?"
    [isVisible]="showDeleteProofOfPayments"
    *ngIf="showDeleteProofOfPayments"
    confirmText="Yes"
    (cancel)="onHideDeleteProofOfPayments()"
    (confirm)="onDeletedProofOfPayments()"></app-delete-confirm-model>