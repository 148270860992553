import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  ReportsService,
  LawfirmSummaryOverview,
  DEFAUL_LAWFIRM_SUMMARY_OVERVIEW,
  ContactProcess,
  DEFAULT_CONTACT_PROCESS,
  InLegalFirm,
  DEFAULT_IN_LEGAL,
  LPCFirm,
  DEFAULT_LPC_FIRM
} from '../../../services/reports/reports.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Router } from '@angular/router';
import { PATH_AGE_ANALYSIS_INDIVIDUAL_CUSTOMER } from '../../../services/appdata/app-config.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { Snack, SnackbarsService, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { ApiService } from '../../../services/api.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import {
  AdvocateDetailStatementService,
  ClientContactLawfirm
} from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import {
  NotesObject,
  NotesService,
  DEFAULT_NOTES_OBJECT,
  NoteCategory,
  Notes,
  NotesObjectHandle,
  ClientContactStatus,
  DEFAULT_CLIENT_CONTACT_STATUS,
  CollectionsDocumentDTO,
  CollectionsDocumentDTOHandle,
  InstructionResponses,
  CollectionDebtorProgress,
  CollectionDebtorProgressHandle,
  DEFAULT_INSTRUCTION_RESPONSES,
  DEFAULT_NOTE
} from '../../../services/notes/notes.service';
import { LIST_NAME_LAW_FIRMS } from '../../../services/lists/lists.service';
import { ClientLetterOfDemand, DEFAULT_CLIENT_LETTER_OF_DEMAND, DocumentServiceService } from '../../../services/documents/document-service.service';
import { DocumentTypes } from '../../../services/fee-items/fee-items.service';
import { delay, lastValueFrom } from 'rxjs';
import { error } from 'console';
import { DEFAULT_ALL_CUSTOMER_REPORT } from '../individual-customer-report/individual-customer-report.component';
import { ActivityLogs, 
         ActivityLogsService, 
         DEFAULT_ACTIVITY_LOGS } from '../../../services/activity-logs/activity-logs.service';
import { IndexedDBService } from '../../../services/indexed-db/indexed-db.service';

const DEFAULT_DEBTOR: any = {
  advocateId: 0,
  advocateName: '',
  clientId: 0,
  debtor: '',
  invoiceCount: 0,
  isFavourite: false,
  isInLegal: false,
  isLPC: false,
  latestComments: '',
  notesDetail: null,
  outstanding: 0,
  outstandingPercentage: 0,
  type: 0
};

@Component({
  selector: 'app-all-customers-report',
  templateUrl: './all-customers-report.component.html',
  styleUrls: ['./all-customers-report.component.scss']
})

export class AllCustomersReportComponent implements OnInit {

  apiData: any = [];
  tabData: any = [];
  lawfirmSummaryData: LawfirmSummaryOverview;
  contactProcess: ContactProcess;
  tooltipData: any = [];
  showTooltipOverlay = false;
  totalOutstanding = 0;
  showAddLawfirmProcess: boolean;
  selectedLawfirm = 0;
  onShowHandOverLawfirm: boolean;
  inLegalFirm: InLegalFirm;
  lPCFirm: LPCFirm;
  onShowLPCDialog: boolean;
  lPComplaintMessage = '';
  exportLawfirmOverview: any[] = [];
  _advocateChecked = false;
  excelURL: string;
  lawfirmId: ClientContactLawfirm[] = [];
  instructionResponses: InstructionResponses[] = [];
  selectedResponse: InstructionResponses;
  selectedResponseText = '';

  // notes
  notes: NotesObject[] = [];
  showInvoiceNoteDetail: boolean;
  objectType: string;
  showIsFavouriteStatusChangePrompt: boolean;
  isFavouriteMessageText = '';
  isFavouriteStatus: boolean;
  selectedDebtor: any;
  clientContactStatus: ClientContactStatus;

  // Note detail or Add new note
  onShowNoteDetail: boolean;
  selectedLawfirmSummaryData: LawfirmSummaryOverview;
  noteDetailData: NotesObject;
  noteCategory: NoteCategory[] = [];

  onShowCollectionDocumentList: boolean;
  collectionsDocument: CollectionsDocumentDTOHandle[];
  collectionsDocumentList: CollectionsDocumentDTO[];

  // LOD
  documentTabView: string;
  onShowLODDialog: boolean;
  reportToBarMessage: string;
  onShowReportToBarDialog: boolean;
  lodPromptMessage: string;
  documentTypes: DocumentTypes[] = [];
  selectedLODFirm: any;
  clientLetterOfDemand: any;
  clientLetterOfDemandId = 0;
  selectedNote: Notes;
  showSuccessMessage:  boolean;
  successMessage: string;
  onShowLodInstructionDialog: boolean;
  LodInstructionPromptMessage: string;
  reportToBarPaymentRequestedQuestion: string;
  reportToBarDisputeQuestion: string;
  onShowReportToBarQuestions: boolean;

  selectedFirmToBeRemovedFromGCB: any;
  removeGCBListingMessage: string = '';
  showRemoveGCBListingChangePrompt: boolean;
   // Activity logs
   activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  @Input() activeTab: string;
  @Output() onRowClick = new EventEmitter<string>();

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  onShowDebtorProgressReport: boolean;
  collectionDebtorProgress: CollectionDebtorProgress[];
  collectionDebtorProgressHandle: CollectionDebtorProgressHandle[];

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  // Check if the user is in Bar Society
  get IsInBarSociety(): boolean {
    let isInBar = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      isInBar = Boolean(this.userProfileService.selectedUserProfile.regionalBarId && this.userProfileService.selectedUserProfile.regionalBarId > 0 && this.userProfileService.selectedUserProfile.regionalBarId !== 13);
    } else {
      isInBar = Boolean(this.userProfileService.userProfile.regionalBarId && this.userProfileService.userProfile.regionalBarId > 0 && this.userProfileService.userProfile.regionalBarId !== 13);
    }
    return isInBar;
  } // end IsInBarSociety()

  // Is Practice Manager
  get isPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER); // &&
      // Boolean(this.api.serviceProviderID ===
      //   this.api.serviceProviderID));
  }  // end isPracticeManager()

  constructor(
    private router: Router,
    private api: ApiService,
    private http: HttpClient,
    private cpipe: UsercurrencyPipe,
    private notesService: NotesService,
    private reportsService: ReportsService,
    private loadingService: LoadingService,
    private snackBarService: SnackbarsService,
    private indexedDbService: IndexedDBService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private documentServices: DocumentServiceService,
    private documentsService: DocumentServiceService,
    private advocateDetailStatementService: AdvocateDetailStatementService
  ) {
    this.excelURL = this.api.endpoints.auxconReportUtils + '/AuxconReportUtils';
  } // end constructor()

  onAddLawfirmProcessPrompt(clientId: number) {
    this.contactProcess = Object.assign({}, DEFAULT_CONTACT_PROCESS);
    this.reportsService.getContactProcess(clientId).subscribe({ next: (response) => {
      this.contactProcess = response;
    },
      error: (error) => {
        // On error
      },
      complete: () => {
        // On complete
        this.selectedLawfirm = clientId;
        this.showAddLawfirmProcess = true;
    }
  });
  } // end onAddLawfirmProcessPrompt()

  onAddLawfirmProcessClose() {
    this.showAddLawfirmProcess = false;
  } // end onAddLawfirmProcessClose()

  ngOnInit(): void {
    if (this.reportsService.allCustomerActiveTab) {
      this.activeTab = this.reportsService.allCustomerActiveTab;
    } else if (this.activeTab == null || this.activeTab === undefined) {
      this.activeTab = 'all';
    }
    this.getApiData();
  } // end ngOnInit()

  onViewCollectionDocument(client) {
    // console.log('SELECTED CLIENT: ', client);
    this.clientLetterOfDemand = Object.assign({}, DEFAULT_CLIENT_LETTER_OF_DEMAND);
    this.clientLetterOfDemand = client;
    this.clientLetterOfDemandId = 0;
    let clientId = client.clientId
    this.clientLetterOfDemandId = client.lodId;
    this.loadingService.showOverlay();
      this.collectionsDocumentList = [];
      this.collectionsDocument = [];
      this.notesService.getCollectionDocumentList(this.api.serviceProviderID, clientId)
      .subscribe({ next: (docs) => {
        // On next
        this.collectionsDocumentList = docs;
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: async () => {
        // On complete
        this.collectionsDocumentList.forEach(documents => {
          this.collectionsDocument.push({
            data: documents,
            state: {
              isOpen: false,
              isSelected: false,
              isVisible: true
            }
          });
        });
        this.documentTypes = [];
        await lastValueFrom(this.documentsService.getDocumentTypes()).then((docType) => {
          this.documentTypes = docType;
        });
        this.documentTabView = 'collections';
        this.onShowCollectionDocumentList = true;
        this.loadingService.hideOverlay();
      }
    })
  } // end onViewCollectionDocument()

  onHideCollectionDocumentList() {
    this.clientLetterOfDemand = Object.assign({}, DEFAULT_CLIENT_LETTER_OF_DEMAND);
    this.onShowCollectionDocumentList = false;
    sessionStorage.removeItem('allCustomerReportData');
    this.getApiData();
  } // end onHideCollectionDocumentList()


  onTabClick(tab: string) {
    this.activeTab = tab;
    this.switchTab();
  } // end onTabClick()

  getApiData(): void {
    this.loadingService.showOverlay();
    if (!JSON.parse(sessionStorage.getItem('allCustomerReportData'))) {
    this.reportsService.getAllCustomersReport().subscribe({ next: (response) => {
      if (response) {
        if (this.api.serviceProviderID === 29723) {
          this.activeTab = '98';
          response.allTotals = response.allTotals.filter(x => x.handOverTo === 'Du Bruyn & Morkel Attorneys');
          response.currentTotals = [];
          response.ninetyDaysTotals = [];
          response.sixtyDaysTotals = [];
          response.thirtyDaysTotals = [];
          response.ninetyEightDaysTotals = response.ninetyEightDaysTotals.filter(x => x.handOverTo === 'Du Bruyn & Morkel Attorneys');
          this.apiData = response;
        } else {
          this.apiData = response;
        }
      }
    },
      error: (error) => {
        // Error
      },
      complete: () => {
        this.switchTab();
        this.loadingService.hideOverlay();
        sessionStorage.setItem('allCustomerReportData', JSON.stringify(this.apiData));
      }
    });
    } else {
      let response = JSON.parse(sessionStorage.getItem('allCustomerReportData'));
      if (this.api.serviceProviderID === 29723) {
        this.activeTab = '98';
        response.allTotals = response.allTotals.filter(x => x.handOverTo === 'Du Bruyn & Morkel Attorneys');
        response.currentTotals = [];
        response.ninetyDaysTotals = [];
        response.sixtyDaysTotals = [];
        response.thirtyDaysTotals = [];
        response.ninetyEightDaysTotals = response.ninetyEightDaysTotals.filter(x => x.handOverTo === 'Du Bruyn & Morkel Attorneys');
        this.apiData = response;
      } else {
        this.apiData = response;
      }
      this.switchTab();
      this.loadingService.hideOverlay();
    }
  } // end getApiData()

  switchTab(): void {

    this.tabData = [];
    switch (this.activeTab) {
      case 'all':
        this.filterTabData('allTotals');
        break;
      case 'current':
        this.filterTabData('currentTotals');
        break;
      case '30':
        this.filterTabData('thirtyDaysTotals');
        break;
      case '60':
        this.filterTabData('sixtyDaysTotals');
        break;
      case '90':
        this.filterTabData('ninetyDaysTotals');
        break;
      case '98':
        this.filterTabData('ninetyEightDaysTotals');
        break;
    }
  } // end switchTab()

  filterTabData(key: string): void {
    this.tabData = this.apiData[key].sort((a, b) => b.outstanding - a.outstanding);
    this.totalOutstanding = 0;
    for (const item of this.tabData) {
      this.totalOutstanding += item.outstanding;
    }
  } // end filterTabData()

  onInLegalChange(data: any) {
    if (data.isInLegal === false) {
    this.inLegalFirm = Object.assign({}, DEFAULT_IN_LEGAL);
    this.inLegalFirm = data;
    this.onShowHandOverLawfirm = true;
    } else {
    this.loadingService.showOverlay();
    data.isInLegal = !data.isInLegal;
    const snack: Snack = {
      label: 'Update inLegal status.',
      action: null
    };
    this.snackBarService.make(snack, 5000).show();
    this.reportsService.putInLegalStatus(this.api.serviceProviderID, data.clientId, 0, data.isInLegal).subscribe({ next: (response) => {
      // On next
    }, error: (error) => {
      // On Error
      snack.label = 'Something went wrong, please contact development department.';
      snack.type = SnackType.ERROR;
      this.snackBarService.dismiss().make(snack, 5000).show();
      this.loadingService.hideOverlay();
    }, complete: () => {
      // On complete
        snack.label = 'In Legal status updated.';
        snack.type = SnackType.SUCCESS;
        this.snackBarService.dismiss().make(snack, 5000).show();
        // this.loadingService.hideOverlay();
        sessionStorage.removeItem('allCustomerReportData');
        this.getApiData();
    }
  });
    }
  } // end onInLegalChange()

  onHideReportToBarDialog() {
    this.onShowReportToBarDialog = false;
  } // end onHideReportToBarDialog()

  onLPODialogContinue() {
    this.onShowLODDialog = false;
  } // end onLPODialogContinue()

  onHideLODDialog() {
    this.onShowLODDialog = false;
  } // end onHideLODDialog()

  onConfirmReportToBarDialog() {

  } // end onConfirmReportToBarDialog()

  onHideReportToBarQuestions() {
    this.onShowReportToBarQuestions = false;
  } // end onHideReportToBarQuestions()

  onSaveReportToBarQuestions(event) {
    this.loadingService.showOverlay();
    let pmId = 0
    if (this.isPracticeManager)
      {
        pmId = this.userProfileService.userProfile.serviceProviderID;
      }
    this.documentServices.PostReportToBar(this.api.serviceProviderID, pmId, this.selectedLODFirm.clientId, event.reportToBarPaymentRequestedQuestion, event.reportToBarDisputeQuestion).subscribe({ next: (_next) => {
      // On next
      // this.loadingService.hideOverlay();
      // this.onShowReportToBarQuestions = false;
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // on complete
      this.loadingService.hideOverlay();
      this.onShowReportToBarQuestions = false;
      sessionStorage.removeItem('allCustomerReportData');
      this.getApiData();
    }
  });
  } // end onSaveReportToBarQuestions()

  onReportToBarDialogContinue() {
    this.reportToBarPaymentRequestedQuestion = '';
    this.reportToBarDisputeQuestion = '';
    this.onShowReportToBarQuestions = true;
    this.onShowReportToBarDialog = false;    
  } // end onReportToBarDialogContinue()

  async onConfirmLODDialog(event) {
    // if cancel is pressed
    // console.log('WHAT WE  HAVE ON EVENT: ', this.selectedLODFirm);
    // if (event === 'Cancel') {
    //   this.onShowLODDialog = false;
    // } else  {
      this.documentTypes = [];
      this.onShowLODDialog = false;
      await lastValueFrom(this.documentsService.getDocumentTypes()).then((docType) => {
        this.documentTypes = docType;
        this.documentTabView = 'other-documents';
        this.onShowCollectionDocumentList = true;
      });

      if (this.selectedLODFirm.lodId) {

      } else {
        this.clientLetterOfDemand = Object.assign({}, DEFAULT_CLIENT_LETTER_OF_DEMAND);
        this.clientLetterOfDemand.clientId = this.selectedLODFirm.clientId;
        this.clientLetterOfDemand.AdvocateId = this.api.serviceProviderID;
      }
    // }
  } // end onConfirmLODDialog()

  onHideLodInstructionDialog() {
    this.onShowLodInstructionDialog = false;
  } // end onHideLodInstructionDialog()

  onLODDialogContinue() {
    let sortedLastInstruction = null;
    let lastInstruction = null;
    if (this.selectedLODFirm.notesDetail) {
      sortedLastInstruction = this.selectedLODFirm.notesDetail.sort((a, b) => a.Id = b.Id);
      lastInstruction = sortedLastInstruction[sortedLastInstruction.length - 1];
    }
    
    // First check if the letter of demand instruction request has already been sent
    if (lastInstruction && lastInstruction.instructionResponseId === 144) {
      this.LodInstructionPromptMessage = 'An instruction to proceed with all letter of demand on ' + this.selectedLODFirm.debtor + ' has already been sent. Should you wish to follow up on the status please contact Auxcon on 012 343 9124.';
      this.onShowLodInstructionDialog = true;
      this.onShowLODDialog = false;
    } else {
      this.onShowLODDialog = false;
      this.loadingService.showOverlay();
      
      this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);

      let lawFirms = [];
      this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData').then((data) => {
        if (data) {
          console.log('NG INIT LAW FIRM DATA: ', data);
          lawFirms = data.data; // Assuming your data is stored under the 'data' key
        } else {
          console.log('NG INIT LAW FIRM NO DATA: ', lawFirms);
          lawFirms = []; // Or any default value you wish to set
        }
      }).catch((error) => {
        console.error('Error retrieving law firms from IndexedDB', error);
        lawFirms = []; // Setting a default value in case of an error
      });
      const firmDetail = lawFirms.filter(x => x.ContactID === this.selectedLODFirm.clientId)[0];
      
      this.noteDetailData.ObjectId = this.selectedLODFirm.clientId;
      if (firmDetail)
        this.noteDetailData.ObjectTypeDescription = firmDetail.FullName;
      this.noteDetailData.CaptureDate = moment().toString();
      this.onLawfirmSummary(this.selectedLODFirm.clientId);
      this.getNoteCategories();
      this.instructionResponses = [];
      this.notesService.getPMInstructionResponses().subscribe({next: (_instruction) => {
          if (_instruction) {
            this.instructionResponses = _instruction.filter(s => s.Id === 144);
            // console.log('INSTRUCTIONS: ', _instruction);

            this.selectedNote = Object.assign({}, DEFAULT_NOTE);
            this.selectedNote.ObjectId = this.noteDetailData.ObjectId;
            this.selectedNote.Subject = 'Instruction from PM'
            this.selectedNote.NoteTypeId = 0;
            this.selectedNote.IsVisibleUserOwner = false;
            this.selectedNote.InstructionText = '';
            this.selectedNote.UserAssigned = this.api.serviceProviderID;
            this.selectedNote.UserOwner = this.api.serviceProviderID;
            this.selectedNote.UserCreator = this.api.serviceProviderID;
            this.selectedNote.Priority = 1;
            this.selectedNote.CaptureDate = moment().format('YYYY-MM-DD HH:mm:ss');
            this.selectedNote.InstructionCaptureDate = moment().format('YYYY-MM-DD HH:mm:ss');
            this.selectedNote.InstructionResponseId = this.instructionResponses[0].Id;
          }
        },
        error: (error) => {
          this.selectedLODFirm = Object.assign({}, DEFAULT_ALL_CUSTOMER_REPORT);
        },
        complete: async () => {
          // On complete
          this.onInstructionIssued(this.selectedNote);
          this.objectType = 'contact';
          this.successMessage = 'Your instruction to proceed with a letter of demand on ' + this.selectedLODFirm.debtor + ' has been sent.' ;
          this.loadingService.hideOverlay();
          this.showSuccessMessage = true;
          // await delay(5000);
          setTimeout(() => {
            this.showSuccessMessage = false
            this.selectedLODFirm = Object.assign({}, DEFAULT_ALL_CUSTOMER_REPORT);
            this.loadingService.showOverlay();
          }, 5000);
        }
      });
    }
  } // end onLODDialogContinue()

  removeBarListing(data: any) {
    this.showRemoveGCBListingChangePrompt = false;
    this.selectedFirmToBeRemovedFromGCB = data;
    this.removeGCBListingMessage = 'You are about to remove the GCB listing on ' +  data.debtor + ' Do you wish to proceed?'
    this.showRemoveGCBListingChangePrompt = true;
  } // end removeBarListing()

  onHideRemoveGCBListingChangePrompt() {
    this.showRemoveGCBListingChangePrompt = false;
  } // end onHideRemoveGCBListingChangePrompt();

    onRemoveGCBListingChangeContinue() {
      // Log activity Login
      this.showRemoveGCBListingChangePrompt = false;
      // console.log('SELECTED FIRM TO BE REMOVED: ', this.selectedFirmToBeRemovedFromGCB);
      this,this.loadingService.showOverlay();
      const currentDate = new Date();
      this.activityLog.Action = 'Remove Law firm from GCB listing';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'All Customer report';
      if (this.userProfileService.userProfile.isImpersonator) {
          this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
          this.activityLog.LoggedForUserName =
          this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
          this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'ClientLetterOfDemand';
      this.activityLog.JsonData = JSON.stringify(this.selectedFirmToBeRemovedFromGCB);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

      this.documentsService.removeGCBListing(this.selectedFirmToBeRemovedFromGCB.clientId, this.userProfileService.selectedUserProfile.serviceProviderID).subscribe({ next: (data) => {
        // On next
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        sessionStorage.removeItem('allCustomerReportData');
        this.getApiData();
      }
    });
  } // end onRemoveGCBListingChangeContinue()

  onLODhange(data: any) {
    //  console.log('SELECTED LAW FIRM TO UPLOAD THE DOCUMENT: ', data);
    let barSocietyId = 0
    let regionalBar = '';
    //#region Get Bar Society name and Bar Society Id
    if (this.userProfileService.userProfile.isImpersonator) {
      barSocietyId = this.userProfileService.selectedUserProfile.regionalBarId;
    } else {
      barSocietyId = this.userProfileService.userProfile.regionalBarId;
    }
    if (this.userProfileService.userProfile.isImpersonator) {
      regionalBar = this.userProfileService.selectedUserProfile.regionalBarName;
    } else {
      regionalBar = this.userProfileService.userProfile.regionalBarName;
    }
    //#endregion Get Bar Society name and Bar Society Id

    this.selectedLODFirm = Object.assign({}, DEFAULT_ALL_CUSTOMER_REPORT);
    this.selectedLODFirm = data;
    this.clientLetterOfDemandId = data.lodId;
    if (data.isReportedToBar === false || barSocietyId !== 5) {
      if (data.isLODDocumentSent === true || barSocietyId !== 5) {
        // console.log('WHAT WE HAVE HERE: ', this.userProfileService.selectedUserProfile.regionalBarName);
        this.reportToBarMessage = 'You are about to report ' + data.debtor + ' to ' + regionalBar +
                                  '. Do you wish to proceed?';
        this.onShowReportToBarDialog = true;
      } else {
        this.lodPromptMessage = 'According to Rule 7.7.3 of the Code of Conduct of your Bar a letter of demand should be sent to the firm. Select proceed should you wish to send an instruction to Auxcon to proceed with a letter of demand or select upload to attach your letter of demand.';
        this.onShowLODDialog = true;
      }
    }  
  } // end onLODhange()

  onLPCChange(data: any) {
    if (data.isLPC == false) {
      this.lPCFirm = Object.assign({}, DEFAULT_LPC_FIRM);
      this.lPCFirm.advocateId = this.api.serviceProviderID;
      this.lPCFirm.clientId = data.clientId;
      this.lPCFirm.canCollect = false;
      this.lPCFirm.canCOllectReason = 'LPC complaint';
      data.canCollect = true;
      this.lPComplaintMessage = 'You are about to submit an LPC complaint request, do you wish to proceed?';
      this.onShowLPCDialog = true;
    } else {
      this.lPCFirm = Object.assign({}, DEFAULT_LPC_FIRM);
      this.lPCFirm.advocateId = this.api.serviceProviderID;
      this.lPCFirm.clientId = data.clientId;
      this.lPCFirm.canCollect = true;
      this.lPCFirm.canCOllectReason = '';
      data.canCollect = true;
      this.onLPCWithdrew();
    }   
  } // end onLPCChange()

  onLPCWithdrew() {
    this.loadingService.showOverlay();
    this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
    this.selectedResponse = Object.assign({}, DEFAULT_INSTRUCTION_RESPONSES);
    this._advocateChecked = true;
    const firmDetail = JSON.parse(localStorage.getItem(LIST_NAME_LAW_FIRMS)).filter(x => x.ContactID === this.lPCFirm.clientId)[0];
    this.noteDetailData.ObjectId = this.lPCFirm.clientId;
    this.noteDetailData.ObjectTypeDescription = firmDetail.FullName;
    this.noteDetailData.CaptureDate = moment().toString();
    this.onLawfirmSummary(this.lPCFirm.clientId);
    this.getNoteCategories();
    this.instructionResponses = [];
    this.notesService.getPMInstructionResponses().subscribe({next: (_instruction) => {
        this.instructionResponses = _instruction;        
      },
      error: (error) => {
      },
      complete: () => {
        this.selectedResponse = this.instructionResponses.filter(x => x.Id === 172)[0];
        this.selectedResponseText = this.selectedResponse.Responses;
        // On complete
        this.objectType = 'contact';
        this.onShowNoteDetail = true;
        this.loadingService.hideOverlay();
      }
    });
  }

  onHideLPCDialog() {
    this.onShowLPCDialog = false;
  } // end onHideLPCDialog()

  onLPCDialogContinue() {
    this.loadingService.showOverlay();
    const noteToSave: Notes = {
      Id: 0,
      ActionDate: null,
      CaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      ActivityDate: null,
      ExternalText: '',
      InstructionResponseId: 145,
      InstructionText: '',
      InternalText: '',
      NoteTypeId: 294,
      ObjectId: this.lPCFirm.clientId,
      Priority: 1,
      Subject: 'Instruction from PM',
      UserAssigned: this.lPCFirm.advocateId,
      UserCreator: this.api.serviceProviderID,
      UserOwner: this.api.serviceProviderID,
      ResolutionText: '',
      MutliObjects: null,
      InternalInstructionNote: '',// this.noteDetailData.InternalInstructionNote,
      InstructionCaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      ResolutionCaptureDate: null,

      IsVisibleUserOwner: true,
      IsVisibleUserCreator: true,
      IsVisibleUserAssigned: false
    };
    // this.noteDetailData.UserCreatorStatus = true;
    // this.noteDetailData.UserOwnerStatus = false;
    // this.noteDetailData.UserAssignedStatus = true;
    // this.notesService.putNoteInboxStatus(this.noteDetailData).subscribe({ next: (_data) => {}});
    this.onInstructionIssued(noteToSave);
    this.onShowLPCDialog = false;
  } // end onLPCDialogContinue()

  onIsFavouriteChange(data: any) {
    this.selectedDebtor = Object.assign({}, DEFAULT_DEBTOR);
    this.selectedDebtor = data;
    this.isFavouriteStatus = !data.isFavourite;
    if (this.isFavouriteStatus === true) {
      this.isFavouriteMessageText = 'You are about to change the status on ' + data.debtor +
        ' to favourite. Do you wish to proceed?';
    } else {
      this.isFavouriteMessageText = 'You are about to change favourite status on ' + data.debtor +
        ' to not favourite. Do you wish to proceed?';
    }
    this.showIsFavouriteStatusChangePrompt = true;
  } // end onIsFavouriteChange()

  onHideIsFavouriteStatusChangePrompt() {
    this.selectedDebtor = Object.assign({}, DEFAULT_DEBTOR);
    this.isFavouriteMessageText = '';
    this.isFavouriteStatus = false;
    this.showIsFavouriteStatusChangePrompt = false;
  } // end onHideIsFavouriteStatusChangePrompt()

  onIsFavouriteStatusChangeContinue () {
    this.loadingService.showOverlay();
    sessionStorage.removeItem('allCustomerReportData');
    this.clientContactStatus = Object.assign({}, DEFAULT_CLIENT_CONTACT_STATUS);
    this.notesService.getClientContactStatus(this.api.serviceProviderID
      , this.selectedDebtor.clientId).subscribe({next: (_next) => {
        this.clientContactStatus = _next;
        if (this.clientContactStatus && this.clientContactStatus.ServiceProviderId > 0 && this.clientContactStatus.ClientId > 0) {
              this.clientContactStatus.IsFavourite = this.isFavouriteStatus;
              this.notesService.putClientContactStatus(this.clientContactStatus)
            .subscribe({ next: (_results) =>{
              // On next
            },
            error: (error) => {
              // On error
              this.loadingService.hideOverlay();
            },
            complete: () => {
              // On Complete
              this.tabData.forEach(firm => {
                if (firm.clientId == this.clientContactStatus.ClientId  && firm.type === '98') {
                  firm.isFavourite = this.isFavouriteStatus;
                }
              });
              sessionStorage.setItem('allCustomerReportData', JSON.stringify(this.apiData));
              this.showIsFavouriteStatusChangePrompt = false;
              this.loadingService.hideOverlay();
          }
        });
        } else {
          const data: ClientContactStatus = {
            Id: 0,
            ClientId: this.selectedDebtor.clientId,
            ServiceProviderId: this.api.serviceProviderID,
            IsFavourite: this.isFavouriteStatus,
            CanCollect: true,
            CanCollectReason: ''
          };
          this.notesService.putClientContactStatus(data)
            .subscribe({ next: (_results) =>{
              // On next
            },
            error: (error) => {
              // On error
              this.loadingService.hideOverlay();
            },
            complete: () => {
              // On Complete
              this.tabData.forEach(firm => {
                if (firm.clientId == data.ClientId && firm.type === '98') {
                  firm.isFavourite = this.isFavouriteStatus;
                }
              });
              sessionStorage.setItem('allCustomerReportData', JSON.stringify(this.apiData));
              this.showIsFavouriteStatusChangePrompt = false;
              this.loadingService.hideOverlay();
          }
        });
        }
      }});
  } // end onIsFavouriteStatusChangeContinue()

  onShowHandOverLawfirmDismiss() {
    this.onShowHandOverLawfirm = false;
  } // end onShowHandOverLawfirmDismiss()

  onLegalChangeConfirm(event) {
    this.loadingService.showOverlay();
    this.inLegalFirm.isInLegal = !this.inLegalFirm.isInLegal;
    const snack: Snack = {
      label: 'Update inLegal status.',
      action: null
    };
    this.snackBarService.make(snack, 5000).show();
    this.reportsService.putInLegalStatus(this.api.serviceProviderID, this.inLegalFirm.clientId, event, this.inLegalFirm.isInLegal)
    .subscribe({ next: (response) => {
      // On next
    }, error: (error) => {
      // On Error
      snack.label = 'Something went wrong, please contact development department.';
      snack.type = SnackType.ERROR;
      this.snackBarService.dismiss().make(snack, 5000).show();
      this.loadingService.hideOverlay();
    }, complete: () => {
      // On complete
        snack.label = 'In Legal status updated.';
        snack.type = SnackType.SUCCESS;
        this.snackBarService.dismiss().make(snack, 5000).show();
        this.onShowHandOverLawfirm = false;
        sessionStorage.removeItem('allCustomerReportData');
        this.getApiData();
        // this.loadingService.hideOverlay();
    }
  });
  } // end onLegalChangeConfirm()

  numberConversion(value: number): string {
    return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  } // end numberConversion()

  getBarWidth(value: number): string {
    let width = '0.95';
    if (value >= 1) {
      width = (value * 0.95).toString();
    }
    return width + '%';
  } // end getBarWidth()

  onLawfirmSummaryHover(lawfirm: number) {
    this.lawfirmSummaryData = Object.assign({}, DEFAUL_LAWFIRM_SUMMARY_OVERVIEW);
    this.showTooltipOverlay = true;
    this.reportsService.getLawfirmSummaryOverview(lawfirm).subscribe({ next: (response) => {
      this.lawfirmSummaryData = response;
    },
    error: (error) => {
      // On error
    },
    complete: () => {
      // On Complete
      this.showTooltipOverlay = false;
    }
  });
  } // end onLawfirmSummaryHover()

  onContactProcessHover(lawfirmId: number) {
    this.showTooltipOverlay = true;
    this.contactProcess = Object.assign({}, DEFAULT_CONTACT_PROCESS);
    this.reportsService.getContactProcess(lawfirmId).subscribe({ next: (response) => {
      this.contactProcess = response;
    },
    error: (error) => {
      // On error
    },
    complete: () => {
      // On complete
      this.showTooltipOverlay = false;
    }
  });
  } // end onContactProcessHover()

  onHover(i: number, activeTab: string): void {
    let type = 0;
    if (activeTab === 'all') {
      type = null;
    } else if (activeTab === 'current') {
      type = +0;
    } else if (activeTab === '30') {
      type = +30;
    } else if (activeTab === '60') {
      type = +60;
    } else if (activeTab === '90') {
      type = +90;
    } else if (activeTab === '98') {
      type = +98;
    }
    this.showTooltipOverlay = true;
    this.tooltipData = [];
    this.reportsService.getByIndividualCustomer(i).subscribe({ next: (response) => {
      if (response) {
        if (type !== null) {
        response = response.filter(a => a.due > 0 && a.daysOverdue === type);
        } else {
          response = response.filter(a => a.due > 0);
        }
         response.sort((a, b) => {
           return a.invoiceDate.toUpperCase().localeCompare(b.invoiceDate.toUpperCase());
        });
        if (response.length > 5) {
          this.tooltipData = response.slice(0, 5);
        } else {
          this.tooltipData = response;
        }
      }
    },
      error: (error) => {
        // Error
      },
      complete: () => {
        this.showTooltipOverlay = false;
      }
    });
  } // end onHover()

  getBackgroundColor(type: string): string {
    if (type.toLowerCase() === 'all') {
      return '#646464';
    } else if (type.toLowerCase() === '0') {
      return '#8abd37';
    } else if (type.toLowerCase() === '30') {
      return '#e5c100';
    } else if (type.toLowerCase() === '60') {
      return '#eb9e02';
    } else if (type.toLowerCase() === '90') {
      return '#ff8000';
    } else if (type.toLowerCase() === '98') {
      return '#cf624f';
    }
  } // end getBackgroundColor()

  getBottomRow(index: number, length: number): string {
    if (length > 0 && index === length - 1) {
      return '20px';
    }
    return '0px';
  } // end getBottomRow()

  onViewCustomerReport(debtorID: string) {
    this.reportsService.individualCustomerFrom = 'all-customer-report';
    this.reportsService.individualCustomerActiveTab = this.activeTab;
    this.router.navigate([PATH_AGE_ANALYSIS_INDIVIDUAL_CUSTOMER + '/' + debtorID]);
    // this.onRowClick.emit(debtorID);
  } // end onViewCustomerReport()

  convertToCurrency(amount: string): string {
    let amountValue = '';
    if (this.userProfileService.userProfile.isImpersonator) {
      amountValue = this.cpipe.transform(amount,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      amountValue = this.cpipe.transform(amount,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
    return amountValue;
  } // end convertToCurrency()

  onGenerateExcelReport() {
    this.loadingService.showOverlay();
    let lawfirmId: string[] = [];
    this.exportLawfirmOverview = [];
    this.tabData.forEach(data => {
      lawfirmId.push('&LawfirmIdList=' + data.clientId);
    });
    this.reportsService.getLawfirmSummaryOverviewList(this.api.serviceProviderID, lawfirmId.join('').toString())
    .subscribe({ next: (dataToExport) => {
      dataToExport.forEach(data => {
        // console.log('DATA: ', data);
        const lawfirmFullName = data.lawfirmFullName || '';
        const lastInvoiceDate = data.lastInvoiceDate ? moment(data.lastInvoiceDate).format('YYYY-MM-DD') : '';
        const lastTransactionDate = data.lastTransactionDate ? moment(data.lastTransactionDate).format('YYYY-MM-DD') : '';
        const amount = data.amount || 0;
        const totalPaidYear = data.totalPaidYear || 0;
        const totalAverage = data.totalAverage || 0;

        const exportData = {
          'Law firm': lawfirmFullName,
          'Outstanding amount': this.convertToCurrency(this.tabData.filter(a => a.clientId === data.lawfirmId)[0]?.outstanding?.toString() || '0'),
          'Law firm overview': 'Last invoice date: ' + lastInvoiceDate + '\n' +
            'Last payment date: ' + lastTransactionDate + '\n' +
            'Last payment amount: ' + this.convertToCurrency(amount.toString()) + '\n' +
            '12 Months payment amount: ' + this.convertToCurrency(totalPaidYear.toString()) + '\n' +
            'Average monthly payment: ' + this.convertToCurrency(totalAverage.toString()),
          'Feedback': ''
        };
        // const exportData = {
        //   'Law firm': data.lawfirmFullName,
        //   'Outstanding amount': this.convertToCurrency(this.tabData.filter(a => a.clientId === data.lawfirmId)[0].outstanding.toString()),
        //   'Law firm overview': 'Last invoice date: ' + moment(data.lastInvoiceDate).format('YYYY-MM-DD') + '\n' +
        //   'Last payment date: ' + moment(data.lastTransactionDate).format('YYYY-MM-DD') + '\n' +
        //   'Last payment amount: ' + this.convertToCurrency(data.amount.toString()) + '\n' +
        //   '12 Months payment amount: ' + this.convertToCurrency(data.totalPaidYear.toString()) + '\n' +
        //   'Average monthly payment: ' + this.convertToCurrency(data.totalAverage.toString()),
        //   'Feedback': ''
        // };
        this.exportLawfirmOverview.push(exportData);
      });
    },
    error: (error) => {
      // On error
      console.log('ERROR: ', error);
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.http.post(this.excelURL, this.exportLawfirmOverview, {
          responseType: 'arraybuffer'
        }).subscribe({ next: (response) => this.advocateDetailStatementService.downloadExcelFile(response,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
          , error: (error) => {
            // On error
            this.loadingService.hideOverlay();
          },
          complete: () => {
            // On complete
            this.loadingService.hideOverlay();
          }
        });
    }
  });
  } // end onGenerateExcelReport()

  pmLatestNote(data: any[]): string {
    let q_text = '';
    if (data && data.length > 0) {
      data.sort((a, b) => {
        return a.captureDate.toUpperCase().localeCompare(b.captureDate.toUpperCase());
      })
      if (data[data.length - 1].instructionResponseId > 0) {
        q_text = data[data.length - 1].responseText + ' ' + data[data.length - 1].instructionText;
      } else if (data[data.length - 1].internalText + ' ' + data[data.length - 1].subject) {
        q_text = data[data.length - 1].internalText + ' ' + data[data.length - 1].subject;
      } else if (data[data.length - 1].responseText + ' ' + data[data.length - 1].subject) {
        q_text = data[data.length - 1].responseText + ' ' + data[data.length - 1].instructionText;
      } else if (data[data.length - 1].questionText + ' ' + data[data.length - 1].subject) {
        q_text = data[data.length - 1].questionText + ' ' + data[data.length - 1].questionText;
      } else if (data[data.length - 1].externalText + ' ' + data[data.length - 1].subject) {
        q_text = data[data.length - 1].externalText + ' ' + data[data.length - 1].subject;
      } else if (data[data.length - 1].resolutionText + ' ' + data[data.length - 1].subject) {
        q_text = data[data.length - 1].resolutionText + ' ' + data[data.length - 1].subject;
      }
      
      return q_text;
    }
  } // end pmLatestNote()

  clientLatestNote(data: any[]): string {
    let q_text = '';
    let e_text = '';
    if (data && data.length > 0) {
      if (data[data.length - 1].questionText) {
        q_text = data[data.length - 1].questionText + ' ';
      }
      if (data[data.length - 1].externalText) {
        e_text = data[data.length - 1].externalText;
      }
      return q_text + e_text;
    }
  } // end clientLatestNote()

  onPMNoteDetailOpen(client: any) {
    this.selectedLODFirm = client;
    let clientId = client.clientId;
    this.notes = [];
    if (clientId) {
    this.loadingService.showOverlay();
        // On next
        if (this.isPracticeManager) {
        this.notesService.getNotes(this.api.serviceProviderID,
          clientId, 2).subscribe({next: (_next) => {
            this.notes = _next;
          }});
        } else if (!this.isPracticeManager) {
          this.notesService.getNotes(this.api.serviceProviderID,
            clientId, 2).subscribe({next: (_next) => {
              this.notes = _next;
            }});
        }
      // },
      // error => {
      //   // On error
        this.loadingService.hideOverlay();
      // },
      // () => {
        // On complete
        if (this.isPracticeManager) {
        this.showInvoiceNoteDetail = true;
        } else if (!this.isPracticeManager && this.notes && this.notes.length > 0) {
          this.showInvoiceNoteDetail = true;
        }
        this.loadingService.hideOverlay();
      // });
    }
  } // end onPMNoteDetailOpen()

  async onPMAddNewNoteDetailOpen(clientId: number) {
    this.loadingService.showOverlay();
    // console.log('CHECKING DATA: ', noteDetailData);
    this.noteDetailData = Object.assign({}, DEFAULT_NOTES_OBJECT);
    // const firmDetail = JSON.parse(localStorage.getItem(LIST_NAME_LAW_FIRMS)).filter(x => x.ContactID === clientId)[0];
    let firmDetail = null;
    const storedData = await this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData');
    if (storedData && storedData.data) {
      firmDetail = storedData.data.filter(x => x.ContactID === clientId)[0];
    } else {
      firmDetail = null; // or any default value
    }
    this.noteDetailData.ObjectId = clientId;
    if (firmDetail)
      this.noteDetailData.ObjectTypeDescription = firmDetail.FullName;
    this.noteDetailData.CaptureDate = moment().toString();
    this.onLawfirmSummary(clientId);
    this.getNoteCategories();
    this.instructionResponses = [];
    this.notesService.getPMInstructionResponses().subscribe({next: (_instruction) => {
        if (_instruction) {
          if (this.userProfileService.selectedUserProfile.regionalBarId !== 5) {
            this.instructionResponses = _instruction.filter(x => x.Id !== 175);
          } else {
            this.instructionResponses = _instruction;
          }
        }
      },
      error: (error) => {
      },
      complete: () => {
        // On complete
        this.objectType = 'contact';
        this.onShowNoteDetail = true;
        this.loadingService.hideOverlay();
      }
    });
  } // end onPMAddNewNoteDetailOpen()

  onLawfirmSummary(lawfirm: number) {
    this.selectedLawfirmSummaryData = Object.assign({}, DEFAUL_LAWFIRM_SUMMARY_OVERVIEW);
    this.reportsService.getLawfirmSummaryOverview(lawfirm).subscribe({next: (_next) => {
      this.selectedLawfirmSummaryData  = _next;
    }});
  } // end onLawfirmSummary()

  getNoteCategories() {
    this.notesService.getNoteCategory().subscribe({next: (_next) => {
      this.noteCategory  = _next;
      // Show only categories for Contact ObjectType.
      this.noteCategory = this.noteCategory.filter(x => x.ObjectTypeId === 2);
      this.noteCategory.sort((a, b) => {
        return a.Category.toUpperCase().localeCompare(b.Category.toUpperCase());
      });
      if (this.api.serviceProviderID === 29723) {
        this.noteCategory = this.noteCategory.filter(a => a.Category === 'Legal Files');
      }
    }});
  } // end getNoteCategories()

  closeInvoiceNoteModal(value: string) {
    // console.log('CLOSING INVOICE NOTE MODAL: ', value);
    if (value === 'yes') {
      sessionStorage.removeItem('allCustomerReportData');
      this.getApiData();
    }
    this.showInvoiceNoteDetail = false;
  } // end closeInvoiceNoteModal()

  onShowNoteDetailModalDismiss() {
    this.onShowNoteDetail = false;
  } // end onShowNoteDetailModalDismiss()

  onNoteSave(note: Notes) {
    this.loadingService.showOverlay();
    this.notesService.postNote(note).subscribe({
      next: (added_note) => {
        // On next
        sessionStorage.removeItem('allCustomerReportData');
      },
      error: (error) => {
        // On Error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
        sessionStorage.removeItem('allCustomerReportData');     
        this.getApiData();
        this.onShowNoteDetail = false;
      }
    });
  } // end onNoteSave()

  onInstructionIssued(note: Notes) {
    note.NoteTypeId = 294;
    this.loadingService.showOverlay();
    this.notesService.postInstructionNote(note).subscribe({
      next: (added_note) => {
        // On next
        sessionStorage.removeItem('allCustomerReportData');
      },
      error: (error) => {
        // On Error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        // console.log('INSTRU TO SAVE: ', note);
        this.loadingService.hideOverlay();
        this.getApiData();
        this.onShowNoteDetail = false;
      }
    });
  } // end onInstructionIssued();

  getDebtorProgressReport(ClientId: number) {
    this.loadingService.showOverlay();
    this.collectionDebtorProgress = [];
    this.collectionDebtorProgressHandle = [];
    this.notesService.getDebtorProgressReport(ClientId).subscribe({next: (_next) => {
      this.collectionDebtorProgress = _next;
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      if (this.collectionDebtorProgress) {
        this.collectionDebtorProgress.forEach(_data => {
          this.collectionDebtorProgressHandle.push({
            data: _data,
            state: {
              isOpen: false,
              isSelected: false,
              isVisible: true
            }
          });
        });
      }
      this.onShowDebtorProgressReport = true;
      this.loadingService.hideOverlay();
    }
  });
  } // end getDebtorProgressReport()

  onHideDebtorProgressReport() {
    this.collectionDebtorProgress = [];
    this.collectionDebtorProgressHandle = [];
    this.onShowDebtorProgressReport = false;
  } // end onHideDebtorProgressReport()

  getInLegalBackgroungColor(value: any): string {
    if (value.isInLegal === true && this.activeTab === '98') {
      return '#f6d6d5';
    } else if (value.isLPC === true && this.activeTab === '98') {
        return '#f8f1d3';
    } else if (value.isFavourite === true && this.activeTab === '98' && this.isPracticeManager) {
      return '#e9f4d7';
    } else if (value.isReportedToBar === true && this.activeTab === '98') {
      return '#e6e6ff';
    }
  } // end getInLegalBackgroundColor()

  OnAddResolution(note: Notes) {
    this.loadingService.showOverlay();
    // this.notesService.putNote(note).subscribe(
    //   updatedNote => {
    //     // On next
    //   },
    //   error => {
    //     // On error
    //     this.loadingService.hideOverlay();
    //   },
    //   () => {
    //     // On compolete
    //     this.getApiData();
    //     this.onShowNoteDetail = false;
    //     this.loadingService.hideOverlay();
    // });
  } // end OnAddResolution()

  getUnReadMessageFont(value: any[]): string {
    if (value.find(s => s.userCreatorStatus === false)) {
      return 'Bold';
    }
  } // #end getUnReadMessageFont()

  getActionReminder(data: any[]): boolean {
    const _data = data[data.length - 1];
    const activityDate = moment(_data.actionDate).format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');
    let hasRemider = false;
    if (_data.questionId > 0 && _data.instructionResponseId === 0
      && new Date(activityDate) <= new Date(today)
    ) {
      hasRemider = true;
    }
    return hasRemider;
  } // end getActionReminder()

  onClientNoteSave(note: Notes) {
    // console.log('SELECTED INSTRUCTION: ', note);
    if (note.InstructionResponseId === 150) {
      this.showInvoiceNoteDetail = false;
      this.onConfirmLODDialog('refresh');
    } else {
    sessionStorage.removeItem('allCustomerReportData');
    this.getApiData();
    this.showInvoiceNoteDetail = false;
    }
  } // end onClientNoteSave()

  getAdvUnReadMessageFont(noteDetail: any[]) {
    if (noteDetail && noteDetail.find(x => x.userAssignedStatus === false)) {
      return 'Bold';
    }
  }
} // end AllCustomersReportComponent()
