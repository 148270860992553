import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import {
  QuotationNote,
  DEFAULT_QUOTATION_NOTE,
  QuotationSummary,
  DEFAULT_QUOTATION_SUMMARY,
  MatterQuotationDTO,
  QuotationService
} from '../../../services/quotation/quotation.service';
import {
  ActivityLogs,
  DEFAULT_ACTIVITY_LOGS,
  ActivityLogsService
} from '../../../services/activity-logs/activity-logs.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import * as moment from 'moment';

@Component({
  selector: 'app-add-actual-quotation-note',
  templateUrl: './add-actual-quotation-note.component.html',
  styleUrls: ['./add-actual-quotation-note.component.scss']
})
export class AddActualQuotationNoteComponent implements OnInit, OnChanges {

  notes: string;
  quotationNoteTitle = '';
  showUnsavedChangesPrompt: boolean;
  quotationNote: QuotationNote = Object.assign({}, DEFAULT_QUOTATION_NOTE);
  workingQuotation: QuotationSummary = Object.assign({}, DEFAULT_QUOTATION_SUMMARY);

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  @Input() isVisible: boolean;
  @Input() quotationSummary: QuotationSummary;
  @Input() workingMatterQuotation: MatterQuotationDTO;
  @Output() closeModal: EventEmitter<string> = new EventEmitter();

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.quotationSummary, this.workingQuotation);
  } // end isDirty()

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private quotationService: QuotationService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.workingQuotation = Object.assign({}, this.quotationSummary);
    }, 1000);
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (this.workingMatterQuotation) {
      this.quotationNoteTitle = 'Add Quotation Note For: ' + this.workingMatterQuotation.Description;
    }
  } // end ngOnChanges()

  onSaveNote() {
    const snack: Snack = {
      label: 'Saving quotation note...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();
    this.loadingService.showOverlay();

    this.quotationNote.Notes = this.workingQuotation.Notes;
    this.quotationNote.Id = this.workingQuotation.Id;

    this.quotationService.updateActualQuotationNote(this.quotationNote)
      .subscribe({
        next: (note) => {
        // On next
        this.quotationSummary = Object.assign(this.quotationSummary, this.workingQuotation);
      },
      error: (error) => {
        // On error

        const currectDate = new Date();
        this.activityLog.Action = 'Save Quotation note';
        this.activityLog.ActionTimeStamp = moment(currectDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (Advocate-quotation)';
        if (this.userProfileService.userProfile.isImpersonator) {
          this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
          this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'Quotation - Failed: ' + error.Message;
        this.activityLog.JsonData = JSON.stringify(this.quotationNote);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});

        snack.label = 'Error saving quotation note.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        // Log this.activity
        const currectDate = new Date();
        this.activityLog.Action = 'Save Quotation note';
        this.activityLog.ActionTimeStamp = moment(currectDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (Advocate-quotation)';
        if (this.userProfileService.userProfile.isImpersonator) {
          this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
          this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'Quotation - Success';
        this.activityLog.JsonData = JSON.stringify(this.quotationNote);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});
        snack.label = 'Quotation note saved';
        snack.type = SnackType.SUCCESS;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
        this.dismiss();
      }
    });
  } // end onSaveNote()

  dismiss() {
    if (this.isDirty) {
      this.showUnsavedChangesPrompt = true;
    } else {
      this.notes = '';
      this.quotationNote = null;
      this.closeModal.emit('add-quotation-note');
    }
  } // end dismiss()

  onCancel() {
    this.dismiss();
  }

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end onHideUnsavedChangesPrompt()

  leavePage() {
    this.workingQuotation = Object.assign(this.workingQuotation, this.quotationSummary);
    this.closeModal.emit('add-quotation-note');
    this.onHideUnsavedChangesPrompt();
  } // end leavePage()
}
