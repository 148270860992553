import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import * as moment from 'moment';
import {
  AccountPlateformBankTransactionLog, AccountPlateformBankTransactions, AdvocateAccountPlateformBankTransactionFilter,
  DEFAULT_ACCOUNTING_TRANSACTION_FILTER, AdvocateDetailStatementService, ICrumb
} from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { PATH_PROCESS } from '../../../services/appdata/app-config.service';
import { ProcessPaymentService } from '../../../services/process-payment/process-payment.service';
@Component({
  selector: 'app-plateform-bank-transactions',
  templateUrl: './plateform-bank-transactions.component.html',
  styleUrls: ['./plateform-bank-transactions.component.scss']
})
export class PlateformBankTransactionsComponent implements OnInit {

  accountingTransactions: AccountPlateformBankTransactions[] = [];
  tempAccountingTransactions: AccountPlateformBankTransactions[] = [];
  accountingTransactionLog: AccountPlateformBankTransactionLog;
  accountingTransactionFilters: AdvocateAccountPlateformBankTransactionFilter = Object.assign({}, DEFAULT_ACCOUNTING_TRANSACTION_FILTER);

  constructor(
    private loadingService: LoadingService,
    private processPaymentService: ProcessPaymentService,
    private advocateDetailService: AdvocateDetailStatementService, 
    ) { } // end constructor()

  SearchDateRange: string;
  SearchDescription: string;
  crumbs: ICrumb[];
  searchTerm: string
  row = 0;
  sortBy: string;
  sortAscending: boolean;
  isMoreThenPageSizeRecord: boolean = true;
  previousSortBy: string;
  transactionDateAscending: boolean;
  transactionDescriptionAscending: boolean;
  transactionAccountNumberAscending: boolean;
  debitAscending: boolean;
  creditAscending: boolean;
  allocationAscending: boolean;
  transactionStatusAscending: boolean;
  syncDateAscending: boolean;
  pageSize: string = '50';
  selectedTransactionId: string = '';
  pageRecordSize: number = 50;
  orderBy: string = 'All';
  currentPage: number = 1;
  skipRecords: number = 0;
  displayTransactionCount: string = '';
  statusOptions: string[] = [
    'All',
    'Pending',
    'Queued',
    'InProgress',
    'Success',
    'Error'
  ];
  PageOptions: string[] = [
    '50',
    '100',
    '150',
    '200',
    '250',
    '300'
  ];

  today = new Date();
  ngOnInit(): void {
    this.loadingService.showOverlay();
    this.crumbs = [
      {
        label: 'PROCESS',
        link: PATH_PROCESS
      },
      {
        label: 'PLATFORM BANK TRANSACTIONS'
      }
    ];
    this.getTransactionRecord();
  }

  updateSageTransactions() {
    this.loadingService.showOverlay();
    this.processPaymentService.UpdateSageTransactions().subscribe({ next: (next) => {
      // On next
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
      }
    });
  } // end updateSageTransactions()

  getTransactionRecord() {
    this.loadingService.showOverlay();
    let dateFilter = '';
    if (this.SearchDateRange) {
      let startDate = moment(this.SearchDateRange[0]).format('YYYY-MM-DD HH:mm:ss');
      let endDate = moment(this.SearchDateRange[1]).format('YYYY-MM-DD HH:mm:ss');
      dateFilter = startDate + ',' + endDate;
    }
    this.accountingTransactions = [];
    this.tempAccountingTransactions = [];
    this.selectedTransactionId = '';
    this.accountingTransactionFilters.DateFilter = dateFilter;
    this.accountingTransactionFilters.StatusFilter = this.orderBy;
    this.accountingTransactionFilters.DescriptionOrAccountNumberFilter = this.SearchDescription;
    this.accountingTransactionFilters.PageSize = this.pageRecordSize;
    this.advocateDetailService.getAccountBankTransactions(this.accountingTransactionFilters).subscribe({
      next: (transactions) => {
        let transactionRecord = transactions.AccountBankTransactions;
        this.displayTransactionCount = transactions.DisplayCount;
        transactionRecord.forEach((item) => {
          item.TransactionLogs = [];
          this.accountingTransactions.push(item);
          this.tempAccountingTransactions.push(item);
        })
        if (this.accountingTransactions.length < Number(this.pageSize)) {   
          this.isMoreThenPageSizeRecord = false; 
        }
        if (this.accountingTransactions.length > 0) {
          this.skipRecords = this.accountingTransactions.length;
          this.row = this.accountingTransactions[0].Id;
          this.selectedRow(this.accountingTransactions[0]);
        }
        this.loadingService.hideOverlay();
      }
    });

  }
  performSearch() {
    //Apply Transaction Serching here
  }

  clearSearch() {
    this.orderBy = 'All';
    this.SearchDescription = undefined;
    this.SearchDateRange = undefined;
    this.accountingTransactions = this.tempAccountingTransactions;
  }

  selectedRow(transaction: any) {
    this.loadingService.showOverlay();
    this.row = transaction.Id;
    this.selectedTransactionId = transaction.Id;
    if (transaction.TransactionLogs.length === 0) {
      this.advocateDetailService.getAccountBankTransactionLogs(transaction.Id).subscribe({
        next: (transactionLog) => {
          this.accountingTransactionLog = transactionLog;
          this.accountingTransactionLog.MsgLogs.forEach((element) => {
            if (element.ErrorLogMsg) {
              let errorMessage = element.ErrorLogMsg.split('|||');
              element.ErrorLogMsg = errorMessage[0] + '.';
              element.ErrorMessage = errorMessage[1];
            }
            else
              element.ErrorLogMsg = '';
          });
          transaction.TransactionLogs = this.accountingTransactionLog;
          this.loadingService.hideOverlay();
        }
      });
    }
    else {

      this.accountingTransactionLog = transaction.TransactionLogs;
      this.loadingService.hideOverlay();
    }
  }

  getErrorColor(value: string) {
    if (value === 'Error') {
      return '#cf624f';
    }
  }

  toggleSort(sortBy: string) {

    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }
    switch (this.sortBy) {
      case 'transaction-date':
        this.accountingTransactions.sort((a, b) => {
          if (this.sortAscending) {
            this.transactionDateAscending = true;
            this.transactionDescriptionAscending = false;
            this.transactionAccountNumberAscending = false;
            this.debitAscending = false;
            this.creditAscending = false;
            this.allocationAscending = false;
            this.transactionStatusAscending = false;
            this.syncDateAscending = false;
            return a.TransactionDate.toUpperCase().localeCompare(b.TransactionDate.toUpperCase());
          }
          this.transactionDateAscending = false;
          return b.TransactionDate.toUpperCase().localeCompare(a.TransactionDate.toUpperCase());
        });
        break;
      case 'description':
        this.accountingTransactions.sort((a, b) => {
          if (this.sortAscending) {
            this.transactionDateAscending = false;
            this.transactionDescriptionAscending = true;
            this.debitAscending = false;
            this.creditAscending = false;
            this.allocationAscending = false;
            this.transactionAccountNumberAscending = false;
            this.transactionStatusAscending = false;
            this.syncDateAscending = false;
            return a.Description.toUpperCase().localeCompare(b.Description.toUpperCase());
          }
          this.transactionDescriptionAscending = false;
          return b.Description.toUpperCase().localeCompare(a.Description.toUpperCase());
        });
        break;
      case 'account-number':
        this.accountingTransactions.sort((a, b) => {
          if (this.sortAscending) {
            this.transactionDateAscending = false;
            this.transactionAccountNumberAscending = true;
            this.transactionDescriptionAscending = false;
            this.debitAscending = false;
            this.creditAscending = false;
            this.allocationAscending = false;
            this.transactionStatusAscending = false;
            this.syncDateAscending = false;
            return a.AccountNumber.toUpperCase().localeCompare(b.AccountNumber.toUpperCase());
          }
          this.transactionAccountNumberAscending = false;
          return b.AccountNumber.toUpperCase().localeCompare(a.AccountNumber.toUpperCase());
        });
        break;
      case 'debit':
        this.accountingTransactions.sort((a, b) => {
          if (this.sortAscending) {
            this.transactionDateAscending = false;
            this.transactionDescriptionAscending = false;
            this.transactionAccountNumberAscending = false;
            this.debitAscending = true;
            this.creditAscending = false;
            this.allocationAscending = false;
            this.transactionStatusAscending = false;
            this.syncDateAscending = false;
            return Number(a.Debit) - Number(b.Debit);
          }
          this.debitAscending = false;
          return Number(b.Debit) - Number(a.Debit);
        });
        break;
      case 'credit':
        this.accountingTransactions.sort((a, b) => {
          if (this.sortAscending) {
            this.transactionDateAscending = false;
            this.transactionAccountNumberAscending = false;
            this.transactionDescriptionAscending = false;
            this.debitAscending = false;
            this.creditAscending = true;
            this.allocationAscending = false;
            this.transactionStatusAscending = false;
            this.syncDateAscending = false;
            return Number(a.Credit) - Number(b.Credit);
          }
          this.creditAscending = false;
          return Number(b.Credit) - Number(a.Credit);
        });
        break;
      case 'allocated':
        this.accountingTransactions.sort((a, b) => {
          if (this.sortAscending) {
            this.transactionDateAscending = false;
            this.transactionDescriptionAscending = false;
            this.transactionAccountNumberAscending = false;
            this.debitAscending = false;
            this.creditAscending = false;
            this.allocationAscending = true;
            this.transactionStatusAscending = false;
            this.syncDateAscending = false;
            return a.Processed.toUpperCase().localeCompare(b.Processed.toUpperCase());
          }
          this.allocationAscending = false;
          return b.Processed.toUpperCase().localeCompare(a.Processed.toUpperCase());
        });
        break;
      case 'transaction-status':
        this.accountingTransactions.sort((a, b) => {
          if (this.sortAscending) {
            this.transactionDateAscending = false;
            this.transactionAccountNumberAscending = false;
            this.transactionDescriptionAscending = false;
            this.debitAscending = false;
            this.creditAscending = false;
            this.allocationAscending = false;
            this.transactionStatusAscending = true;
            this.syncDateAscending = false;
            return a.TransactionStatus.toUpperCase().localeCompare(b.TransactionStatus.toUpperCase());
          }
          this.transactionStatusAscending = false;
          return b.TransactionStatus.toUpperCase().localeCompare(a.TransactionStatus.toUpperCase());
        });
        break;
      case 'sync-date':
        this.accountingTransactions.sort((a, b) => {
          if (this.sortAscending) {
            this.transactionDateAscending = false;
            this.transactionDescriptionAscending = false;
            this.transactionAccountNumberAscending = false;
            this.debitAscending = false;
            this.creditAscending = false;
            this.allocationAscending = false;
            this.transactionStatusAscending = false;
            this.syncDateAscending = true;
            return a.SysncDate.toUpperCase().localeCompare(b.SysncDate.toUpperCase());
          }
          this.syncDateAscending = false;
          return b.SysncDate.toUpperCase().localeCompare(a.SysncDate.toUpperCase());
        });
        break;
    }
    this.previousSortBy = this.sortBy;
  }

  SearchStatusTransactions() {
    if (this.orderBy != 'Select Status') {
      this.accountingTransactions = this.tempAccountingTransactions;
      if (this.orderBy) {
        this.accountingTransactions = Object.values(this.accountingTransactions).filter(x => x.TransactionStatus.toLocaleLowerCase() == this.orderBy.toLocaleLowerCase());
      }
    }

  }

  SearchTransactions() {
    // this.accountingTransactions = [];
    // this.accountingTransactions = Object.assign({}, this.tempAccountingTransactions);
    // if (this.orderBy) {
    //   if (this.orderBy != 'All') {
    //     this.accountingTransactions = Object.values(this.accountingTransactions).filter(x => x.TransactionStatus.toLocaleLowerCase() == this.orderBy.toLocaleLowerCase());
    //   } else {
    //     this.accountingTransactions = this.tempAccountingTransactions;
    //   }
    // }
    // if (this.SearchDescription) {
    //   this.accountingTransactions = Object.values(this.accountingTransactions).filter(x => x.Description.match(new RegExp(this.SearchDescription, 'gi')) || x.AccountNumber.match(new RegExp(this.SearchDescription, 'gi')));
    // }
    // if (this.SearchDateRange) {
    //   let startDate = new Date(this.SearchDateRange[0]);
    //   let endDate = new Date(this.SearchDateRange[1]);
    //   if (startDate && endDate) {
    //     this.accountingTransactions = Object.values(this.accountingTransactions).filter(x => new Date(x.TransactionDate) >= startDate && new Date(x.TransactionDate) <= endDate);
    //   }
    // }
    // if (!this.orderBy && !this.SearchDescription && !this.SearchDateRange) {
    //   this.accountingTransactions = this.tempAccountingTransactions;
    // }
    this.accountingTransactionLog = null;
    this.getTransactionRecord();
  }

  LoadMoreData() {
    this.currentPage = this.currentPage + 1;
    this.pageRecordSize = Number(this.pageSize) * this.currentPage;
    this.getTransactionRecord();
  }

  onOptionsSelected(event: any) {
    this.pageRecordSize = Number(this.pageSize) * this.currentPage;
    this.getTransactionRecord();
  }
}
