import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-invoice-modal',
  templateUrl: './invoice-modal.component.html',
  styleUrls: ['./invoice-modal.component.scss']
})
export class InvoiceModalComponent implements OnInit {

  constructor() { }
  @Input() isVisible: boolean;
  ngOnInit() {
  }

}
