import { Component, Inject, OnInit } from '@angular/core';
import {
  PracticeAdvocateHealthService,
  AdvocateHealth,
  AdvocateHealthHandle,
  AdvocatePracticeSummaryHandle,
  AdvocatePracticeSummary,
  PracticeAgeAnalysis,
  DEFAULT_PRACTICE_AGE_ANALYSIS,
  DebtorTotalOutstanding,
  DebtorTotalOutstandingHandle
} from '../../../services/practice-advocate-health/practice-advocate-health.service';
import * as moment from 'moment';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Router } from '@angular/router';
import {
  PATH_PRACTICE_ALL_CUSTOMER
} from '../../../services/appdata/app-config.service';
import { ICrumb } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { ReportParameters } from '../../../services/reports/reports.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import * as $ from 'jquery';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

export enum ReportType {
  BusinessReports = 'businessReports',
  FinancialReports = 'financialReports',
  LiveStatement = 'liveStatement',
  PracticeManagementReports = 'practiceManagementReports',
  IndustryReports = 'industryReport',
  MyAdvocates = 'myAdvocates'
} // end ReportType{}

enum WidgetType {
  InvoicedTarget = 'invoicedTarget',
  CollectedTarget = 'collectedTarget',
  AdvocateReview = 'advocateReview',
  InvoicingAdvocate = 'invoicingAdvocate',
  AgeAnalysis = 'ageAnalysis',
  avarageDaysToPayReport = 'avarageDaysToPayReport'
}

 export enum ReportName {
   AdvocateSummary = 'advocateSummary',
   AdvocateDetail = 'advocateDetail',
   AgeAnalysis = 'ageAnalysis',
   PracticeAllCustomer = 'practiceAllCustomer',
   PracticeByLawfirm = 'practiceByLawfirm',
   avarageDaysToPayReport = 'avarageDaysToPayReport'
 } // end ReportName{}

export const DEFAULT_REPORT_PARAMETERS = {
  fromDate: '',
  toDate: '',
  lawFirmId: 0,
  fromMonth: '1',
  toMonth: '1',
  fromYear: '2013',
  toYear: '2013',
  serviceProviderId: 0,
  serviceId: 0,
  searchText: '',
  format: ''
};

@Component({
  selector: 'app-practice-manager-report',
  templateUrl: './practice-manager-report.component.html',
  styleUrls: ['./practice-manager-report.component.scss']
})
export class PracticeManagerReportComponent implements OnInit {
  title = 'Reports';
  reportType: ReportType; // = ReportType.FinancialReports;
  reportName: ReportName; // = ReportName.FinancialSummaryViewReport;
  _reportName = '';
  activeTab: string;

  crumbs: ICrumb[];

  advocateHealth: AdvocateHealth[] = [];
  advocateHealthHandle: AdvocateHealthHandle[] = [];
  advocatePracticeSummaryHandle: AdvocatePracticeSummaryHandle[] = [];
  adovatePracticeSummary: AdvocatePracticeSummary[] = [];
  debtorTotalOutstanding: DebtorTotalOutstanding[] = [];
  debtorTotalOutstandingHandle: DebtorTotalOutstandingHandle[] = [];
  // Practice Managers Age Analysis
  practiceAgeAnalysis: PracticeAgeAnalysis = Object.assign({}, DEFAULT_PRACTICE_AGE_ANALYSIS);

  reportParameters: ReportParameters = {
    fromDate: '',
    toDate: '',
    lawFirmId: 0,
    fromMonth: '1',
    toMonth: '1',
    fromYear: '2013',
    toYear: '2013',
    serviceProviderId: 0,
    serviceId: 0,
    searchText: '',
    format: 'pdf'
  };

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private router: Router,
    private authService: MsalService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private practiceAdvocateHealthService: PracticeAdvocateHealthService
  ) { } // end constructor()

  ngOnInit() {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
    let activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount) {
    } else {
      if (this.msalGuardConfig.authRequest){
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  if (this.userProfileService.refreshSelectedProfile) {
      location.reload();
    }
    this.loadingService.showOverlay();
    this.userProfileService.isInPMDashboardScreen = true;
    if (this.userProfileService.userProfile.serviceProviderID === 29723) {
      this.reportType = ReportType.MyAdvocates;
      this.reportName = ReportName.PracticeByLawfirm;
      this.viewReport();
    } else {
    if (this.practiceAdvocateHealthService.selectedReportType === ReportType.MyAdvocates
        && this.practiceAdvocateHealthService.selectedReportName === ReportName.AdvocateDetail) {
      if (this.practiceAdvocateHealthService.reportSortType === WidgetType.InvoicedTarget
        || this.practiceAdvocateHealthService.reportSortType === WidgetType.CollectedTarget
        || this.practiceAdvocateHealthService.reportSortType === WidgetType.AdvocateReview
        || this.practiceAdvocateHealthService.reportSortType === WidgetType.InvoicingAdvocate) {
        this.reportType = ReportType.MyAdvocates;
        this.reportName = ReportName.AdvocateDetail;
        this.viewReport();
      }
    } else if (this.practiceAdvocateHealthService.selectedReportType === ReportType.MyAdvocates
            && this.practiceAdvocateHealthService.selectedReportName === ReportName.AgeAnalysis
          && this.practiceAdvocateHealthService.reportSortType === WidgetType.AgeAnalysis) {
          this.reportType = ReportType.MyAdvocates;
          this.reportName = ReportName.AgeAnalysis;
          this.viewReport();
      } else if (this.practiceAdvocateHealthService.selectedReportType === ReportType.MyAdvocates
        && this.practiceAdvocateHealthService.selectedReportName === ReportName.AdvocateSummary) {
        this.reportType = ReportType.MyAdvocates;
        this.reportName = ReportName.AdvocateSummary;
        this.viewReport();
      } else if (this.practiceAdvocateHealthService.selectedReportType === ReportType.MyAdvocates
            && this.practiceAdvocateHealthService.selectedReportName === ReportName.PracticeByLawfirm) {
        this.reportType = ReportType.MyAdvocates;
        this.reportName = ReportName.PracticeByLawfirm;
        this.viewReport();
      } else if (this.practiceAdvocateHealthService.selectedReportType === ReportType.MyAdvocates
        && this.practiceAdvocateHealthService.selectedReportName === ReportName.AdvocateDetail) {
        this.reportType = ReportType.MyAdvocates;
        this.reportName = ReportName.AdvocateDetail;
        this.viewReport();
      } else {
        this.reportType = ReportType.MyAdvocates;
        this.reportName = ReportName.AdvocateSummary;
        this.viewReport();
      }
    }
  } // end ngOnInit()

  togglePanel(panel: string) {
    this._reportName = '';
    switch (panel) {
      case 'myAdvocates':
        this.reportType = ReportType.MyAdvocates;
        this.reportName = ReportName.AdvocateSummary;
        this.viewReport();
      break;
      case 'businessReports':

      break;
      case 'financialReports':

      break;
      case 'industryReport':
        this.reportType = ReportType.IndustryReports;
        this.reportName = ReportName.avarageDaysToPayReport;
        this._reportName = this.reportName;
        this.reportParameters.fromDate = moment().subtract(36, 'months').startOf('month').format('YYYY-MM-DD');
        this.viewReport();
        this.crumbs = [
          {
            label: 'Reports'
            // link: PATH_REPORTS
          },
          {
            label: 'Industry Reports'
            // link: PATH_REPORTS
          },
          {
            label: 'Average Days to Pay Report'
          }
        ];
      break;
    }
  } // end togglePanel()

  viewReport() {
    switch (this.reportName) {
      case 'advocateSummary':
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Advocate Summary'
          }
        ];
        this.getPracticeAdvocateSummary();
        this._reportName = this.reportName;
        this.reportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportName = ReportName.AdvocateSummary;
      break;
      case 'advocateDetail':
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Advocate Detail'
          }
        ];
        this.getPracticeAdvocateHealth();
        this._reportName = this.reportName;
        this.reportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportName = ReportName.AdvocateDetail;
      break;
      case 'ageAnalysis':
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Age Analysis'
          }
        ];
        this.getPracticeAgeAnalysis();
        this._reportName = this.reportName;
        this.reportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportName = ReportName.AgeAnalysis;
      break;
      case 'practiceAllCustomer':
        this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportName = ReportName.PracticeAllCustomer;
        this.activeTab = this.practiceAdvocateHealthService.allCustomerActiveTab;
        this.router.navigate([PATH_PRACTICE_ALL_CUSTOMER]);
        this.reportType = ReportType.MyAdvocates;
      break;
      case 'practiceByLawfirm':
        this.activeTab = this.practiceAdvocateHealthService.allCustomerActiveTab;
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Overdue invoices per law firm'
          }
        ];
        this.getPracticeByLawfirmReport();
        this._reportName = this.reportName;
        this.reportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportName = ReportName.PracticeByLawfirm;
        break;
      default:
      break;
    }
  } // end viewReport()

  onReportClicked(event: string) {
        this.reportType = ReportType.MyAdvocates;
        this.reportName = ReportName.AdvocateDetail;
        this.viewReport();
  } // end onReportClicked()

  getPracticeByLawfirmReport() {
    this.debtorTotalOutstandingHandle = [];
    this.debtorTotalOutstanding = [];
    this.loadingService.showOverlay();
    this.practiceAdvocateHealthService.getPracticeByLawfirmOverdue().subscribe({ next: (_data) => {
      // On next
      this.debtorTotalOutstanding = _data;
      this.debtorTotalOutstanding.forEach(debtor => {
        this.debtorTotalOutstandingHandle.push({
          data: debtor,
          state: {
            isVisible: true,
            isSelected: false,
            isOpen: false
          }
        });
      });
      this.loadingService.hideOverlay();
    }});
  } // end getPracticeByLawfirmReport()

  onAllCustomerReport(tab: string) {
    this.practiceAdvocateHealthService.reportSortType = ReportName.PracticeAllCustomer;
    this.practiceAdvocateHealthService.allCustomerActiveTab = tab;
    this.router.navigate([PATH_PRACTICE_ALL_CUSTOMER]);
  } // end onAllCustomerReport()

  getPracticeAgeAnalysis() {
    // this.practiceAgeAnalysis = [];
    this.loadingService.showOverlay();
    this.practiceAdvocateHealthService.getPracticeAgeAnalysis().subscribe({next: (_next) => {
      // On next
      this.practiceAgeAnalysis = _next;
    }});
    this.loadingService.hideOverlay();
  } // end getPracticeAgeAnalysis()

  getPracticeAdvocateSummary() {
    this.adovatePracticeSummary = [];
    this.loadingService.showOverlay();
    this.practiceAdvocateHealthService.getAdvocatePracticeSummary().subscribe({ next: (_data) => {
      // On next
      this.adovatePracticeSummary = _data;
      this.adovatePracticeSummary.forEach(adv => {
        adv.Age = moment().diff(moment(adv.AdvocateAge), 'year');
        adv.CollectedThisMonthPercentage = Math.abs(Math.round(adv.TotalCollectedThisMonth * 100 / adv.CollectionTarget));
      });
      this.loadingService.hideOverlay();
    }});
  } // end getPracticeAdvocateSummary()

  getPracticeAdvocateHealth() {
    this.advocateHealth = [];
    this.advocateHealthHandle = [];
    this.loadingService.showOverlay();
    this.practiceAdvocateHealthService.getAdvocateHealthData().subscribe({ next: (_data) => {
      // On next
      this.advocateHealth = _data;
    },
    error: (error) => {
      // On error
    },
    complete: () => {
      // On complete
      if (this.advocateHealth) {
        this.advocateHealth.forEach(advocate => {
          const invoiceColor = (advocate.Total_Invoiced_120 / (advocate.FeeTarget * 3) * 100) - 100;
          const collectedColor = (advocate.Paid120Days / (advocate.IncomeTarget * 3) * 100) - 100;
          const collectedThisMonthColor = (advocate.CollectedLastMonth / (advocate.IncomeTarget) * 100) - 100;
          const lastmeeting = moment().diff(moment(advocate.LastMeetingDate), 'week');
          const lastinvoiced = moment().diff(moment(advocate.Last_Invoice_Created), 'week');
          const laststatement = moment().diff(moment(advocate.Last_Statement_Date), 'week');
          const lasttransaction = moment(advocate.Last_Statement_Date).diff(moment(advocate.Last_Transaction), 'week');
          if (advocate.LastMeetingDate) {} else {advocate.LastMeetingDate = '1901-01-01'; }
          if (advocate.Last_Invoice_Created) {} else {advocate.Last_Invoice_Created = '1901-01-01'; }
          if (advocate.Last_Transaction) {} else {advocate.Last_Transaction = '1901-01-01'; }
          if (advocate.Last_Statement_Date) {} else {advocate.Last_Statement_Date = '1901-01-01'; }
          // if (invoiceColor > 0) {
            this.advocateHealthHandle.push({
              data: advocate,
              state: {
                isVisible: true,
                isSelected: false,
                  isOpen: false,
                  collectedColor: Math.sign(collectedColor) === 1 ? '' : '#cf624f',
                  invoicedColor: Math.sign(invoiceColor) === 1 ? '' : '#cf624f',
                  calculateLastMeetingDate: lastmeeting,
                  calculateLastInvoiceDate: lastinvoiced,
                  calculateLastStatementDate: laststatement,
                  calculateLastTransactionDate: lasttransaction,
                  collectedThisMonthColor: Math.sign(collectedThisMonthColor) === 1 ? '' : '#cf624f'
                }
              });
              // }
              console.log('DATA_REPORT: ', this.advocateHealthHandle)
        });
      }
      this.loadingService.hideOverlay();
    }
  });
  } // end getPracticeAdvocaHealth()

} // end PracticeManagerReportComponent{}
