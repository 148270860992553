<a class="cursor" (click)="onViewAllCustomerReport(age)">
    <div class="container">
        <div class="row">
            <div class="col-md-6 text-left"></div>
            <div class="col-md-2 text-center font-weight-bold">Invoices</div>
            <div class="col-md-4 text-right font-weight-bold">Amount</div>
        </div>
        <div class="row" *ngFor="let customer of tooltipData; let i = index"
            [ngStyle]="{'margin-bottom': getBottomRow(i, tooltipData.length)}">
            <div class="col-md-6 text-left">{{customer.debtor}}</div>
            <div class="col-md-2 text-center">{{customer.invoiceCount}}</div>
            <div class="col-md-4 text-right">{{customer.outstanding | currency: currencySymbol: currencySymbol:'1.2-2'}}
            </div>
        </div>
        <div *ngIf="age == '98'" style="position: absolute;bottom:3px;left:0;right:0">Click to open full
            overdue customers report</div>
        <div *ngIf="age != '98'" style="position: absolute;bottom:3px;left:0;right:0">Click to open full
            {{age}} days customers report</div>
    </div>
</a>

