import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { InvoiceDetails } from '../../../services/invoice-details/invoice-details.service';
import { DEFAULT_WRITEOFF_TRANSACTIONS_LINE,
  ProcessPaymentService,
  WriteOffTransactionsLines } from '../../../services/process-payment/process-payment.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import * as moment from 'moment';
import { lastValueFrom } from 'rxjs';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-write-off-multiple-invoices',
  templateUrl: './write-off-multiple-invoices.component.html',
  styleUrls: ['./write-off-multiple-invoices.component.scss']
})
export class WriteOffMultipleInvoicesComponent implements OnInit {

  title = 'Write-off Multiple Invoices';
  writeOffNotes: string;

  @Input() isVisible: boolean;
  @Input() toWriteOffTransactionLine: InvoiceDetails[];
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveWriteOff: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputCheckbox') inputCheckbox: ElementRef;

  get currencySymbol(): string {
      if (this.userProfileService.userProfile.isImpersonator) {
          return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
      } else {
          return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
      }
  } // end currencySymbol()

  get hasNote() {
    return this.util.hasTextValue(this.writeOffNotes);
  } // end hasNote()
  
  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private processPaymentService: ProcessPaymentService
  ) { } // end constructor()
  
  
  ngOnInit(): void {
  } // end ngOnInit()

  onDismiss() {
    this.closeModal.emit();
  } // end onDismiss()

  async onConfirmWriteOff() {
    this.loadingService.showOverlay();
    for await (const write_off of this.toWriteOffTransactionLine) {
      let _write_off = Object.assign({}, DEFAULT_WRITEOFF_TRANSACTIONS_LINE);
      _write_off.Id = 0;
      _write_off.TransactionDate = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
      _write_off.InvoiceType = "WriteOff";
      _write_off.Description = this.writeOffNotes;
      _write_off.Amount = write_off.OutstandingBalance;
      _write_off.ServiceInvoice_Transaction = write_off.Id;
      _write_off.Contact_Transaction = 0;
      await lastValueFrom(this.processPaymentService.putWriteOffBankTransactions(_write_off)).then((data) =>{});
    }
    this.saveWriteOff.emit();
    this.loadingService.hideOverlay();
  } // end onConfirmWriteOff()

  onWriteOffNotesBlur() {

  } // end onWriteOffNotesBlur()

  onInvoiceRemove(data) {
    this.toWriteOffTransactionLine = this.toWriteOffTransactionLine.filter(x => x != data);
  } // end onInvoiceRemove()

} // end WriteOffMultipleInvoicesComponent{}
