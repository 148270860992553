import { Component, Input, OnInit } from '@angular/core';
import { ReportParameters, ReportsService } from '../../../services/reports/reports.service';
// import { Subscription } from 'rxjs';

import * as moment from 'moment';
import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Axis from 'd3-axis';
import * as d3Array from 'd3-array';
import { lastValueFrom } from 'rxjs';

declare var google: any;
@Component({
  selector: 'app-tax-liability-widget',
  templateUrl: './tax-liability-widget.component.html',
  styleUrls: ['./tax-liability-widget.component.scss']
})
export class TaxLiabilityWidgetComponent implements OnInit {

  reportParameters: ReportParameters = {
    fromDate: '',
    toDate: '',
    lawFirmId: 0,
    fromMonth: '1',
    toMonth: '1',
    fromYear: '2013',
    toYear: '2013',
    serviceProviderId: 0,
    serviceId: 0,
    searchText: '',
    format: 'pdf'
  };

  @Input() graphWidth: number;
  @Input() graphHeight: number;
  @Input() graphMargin: any;

  activeTab: string = 'graph';
  totalVATAmount: string = '';
  totalProvisionalTax: string = '';
  totalPTP: string = '';
  ratio: '30';
  chartData: any = [];

  constructor(
    private reportService: ReportsService
  ) { } // end constructor()

  async ngOnInit() {
    await lastValueFrom(this.reportService.getTaxPeriod()).then((data) => {
      if (data) {
        this.reportParameters.fromDate = moment(data.fromDate).startOf('month').format('YYYY-MM-DD');
        this.reportParameters.toDate = moment(data.fromDate).add(5, 'months').endOf('month').format('YYYY-MM-DD');
      }
    });
    this.fetchProvisionalTaxData();
  } // end ngOnInit()

  ngOnDestroy() {
    // d3.selectAll('.toolTip').remove();
    // d3.selectAll('#timeScale1 > *').remove();
    // d3.selectAll('#timeScalev > *').remove();
  } // end ngOnDestroy()


  drawChart() {
    let data = this.chartData;
    let values = [];
    values.push(['Month', 'VAT', 'Provisional Tax', 'Provisional Tax (PTD)']);
    data.forEach(element => {
      var temp = [];
      temp.push(element["month"]);
      temp.push(element["taxAmount"]);
      temp.push(element["provisionalTaxAmount"]);
      temp.push(element["provisonal_Tax"]);
      values.push(temp);
    });

    const GoogleChartData = google.visualization.arrayToDataTable(values);
    const options: any = {
      tooltip: { trigger: 'none' },
      backgroundColor: { fill: 'transparent' },
      enableInteractivity: false,
      theme: 'material',
      colors: ['#5e97d8', '#8abd37', '#eb9e02'],
      width: this.graphWidth,
      height: this.graphHeight - 30,
      vAxis: { format: 'R', textStyle: { fontSize: 10 } },
      hAxis: { textStyle: { fontSize: 10 } },
      legend: { position: 'none' },
      axes: { x: { 0: { side: 'bottom', label: '' } } }
    };
    var chart = new google.visualization.ColumnChart(document.getElementById('TaxLiabilityChart'));
    chart.draw(GoogleChartData, options);
  }

  fetchProvisionalTaxData(): void {
    this.reportService.getProvisionalTax(this.reportParameters.fromDate, this.reportParameters.toDate).subscribe({
      next: (respose) => {
        this.loadGraph(respose, this.reportParameters.fromDate, this.reportParameters.toDate);
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  } // ed fetchProvisionalTaxData()


  // draw graph
  loadGraph(graphData, startDate, endDate): void {
    if (graphData.length == 0) {
      this.totalVATAmount = this.totalProvisionalTax = '';
      this.totalPTP = '';
    }

    var dateStart = moment(startDate);
    var dateEnd = moment(endDate);
    var timeValues = [];

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      timeValues.push({ year: dateStart.format('YYYY'), month: dateStart.format('M') });
      dateStart.add(1, 'month');
    }

    let totalVATAmount = 0;
    let totalProvisionalTax = 0;
    let totalPTP = 0;

    this.chartData = [];
    timeValues.map(t => {
      const obj = graphData.find(x => x.year == t.year && x.month == t.month);
      if (obj) {

        this.chartData.push({
          month: `${moment().month(t.month - 1).format('MMM')}' ${t.year.toString().substr(-2)}`,
          taxAmount: obj.taxAmount,
          provisionalTaxAmount: obj.provisionalTaxAmount,
          provisonal_Tax: obj.provisonal_Tax,
          currencySymbol: obj.currencySymbol
        });

        totalVATAmount += obj.taxAmount;
        totalProvisionalTax += obj.provisionalTaxAmount;
        totalPTP += obj.provisonal_Tax;
      }
      else {
        this.chartData.push({
          month: `${moment().month(t.month - 1).format('MMM')}' ${t.year.toString().substr(-2)}`,
          taxAmount: 0,
          provisionalTaxAmount: 0,
          provisonal_Tax: 0,
          currencySymbol: graphData.length > 0 ? graphData[0].currencySymbol : ''
        });
      }
    });

    if (graphData.length > 0) {
      this.totalVATAmount = `${graphData[0].currencySymbol}${this.numberConversion(totalVATAmount.toFixed(2))}`;
      this.totalProvisionalTax = `${graphData[0].currencySymbol}${this.numberConversion(totalProvisionalTax.toFixed(2))}`;
      this.totalPTP = `${graphData[0].currencySymbol}${this.numberConversion(totalPTP.toFixed(2))}`;
    }

    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(() => { this.drawChart() });
  }

  numberConversion(x): string {
    var parts = (+x).toFixed(2).split('.');
    return parts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
} // end TaxLiabilityWidgetComponent{}
