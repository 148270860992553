import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

export interface LoadingOverlayModalOptions {
  message?: string;
}

export const DEFAULT_LOADINGOVERLAY_OPTIONS: LoadingOverlayModalOptions = {
  message: ''
};

@Component({
  selector: 'app-loading-overlay-modal',
  templateUrl: './loading-overlay-modal.component.html',
  styleUrls: ['./loading-overlay-modal.component.scss']
})
export class LoadingOverlayModalComponent implements OnInit {

  isVisible: boolean = false;
  message = 'Loading...';
  constructor(
    private loadingService: LoadingService
  ) {
    loadingService.assign(this);
  } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  show(options: LoadingOverlayModalOptions = null) {
    if (options) {
      if (options.message) {
        this.message = options.message;
      }
    }
    this.isVisible = true;
  } // end show()

  hide() {
    this.isVisible = false;
    this.message = 'Loading...';
  } // end hide()
} // end LoadingOverlayModalComponent{}
