<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
      <div class="modal-dialog">
  
        <div class="modal-header">
          <h2>{{title}}</h2>
          <button class="modal-dismiss" (click)="onCancel()">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!--#end .modal-header -->
  
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row" style="margin: 0px 27px 0 0 !important; padding: 0 !important; display: block; text-align: right !important;">
              <i class="far fa-info-circle tooltip" style="float: right; text-align: right;">
                <div class="bottom" style="text-align: center">
                 <span class="fa fa-info-circle" style="font-size: 20px; padding-top: 9px; padding-bottom: 5px;"></span>
                 <!-- <h4><strong>Note</strong></h4> -->
                 <p>An email will be sent to counsel containing your query / dispute. you will be notified as soon as they accept or reject same, the status will also update accordingly.</p>
                 <i></i>
                </div> <!-- #end .bottom -->
              </i>
            </div>
            
            <div class="table-responsive" style="max-height: 500px; overflow-x: auto !important;">
            <table class="table table-striped">
                <thead>
                    <tr>
                      <th>Line Date</th>
                      <th>Description</th>
                      <th>Line Type</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Rate</th>
                      <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let detail of invoiceDetailDataHandle">
                      <td>{{ detail.data.LineDate | date:'yyyy-MM-dd' }}</td>
                      <td>{{ detail.data.Description }}</td>
                      <td>{{ detail.data.LineType }}</td>
                      <td>{{ detail.data.Quantity }}</td>
                      <td>{{ detail.data.Total | currency: currencySymbol: currencySymbol:'1.2-2' }}</td>
                      <td>{{ detail.data.Rate | currency: currencySymbol: currencySymbol:'1.2-2' }}</td>
                      <td title="Dispute / Query">
                        <i class="fa fa-question-circle grey" *ngIf="detail.data.DisputeStatusId > 0" aria-hidden="true" (click)="onLineItemClicked(detail)"></i>
                        <i class="fa fa-question-circle normal" *ngIf="detail.data.DisputeStatusId === 0 || detail.data.DisputeStatusId === null" aria-hidden="true" (click)="onLineItemClicked(detail)"></i>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div> <!-- #end .container-fluid -->           
        </div>
        <!--#end .modal-body -->
  
        <div class="modal-footer">          
          <div class="row" style="margin: 0 !important; padding: 0 !important;">
            <button class="btn btn-danger" (click)="onCancel()">Cancel</button>
            <button
              class="btn btn-success"
              (click)="onLineDispute()">Submit</button>
          </div>
          <!-- <span></span> -->
          <!-- <span></span>
          <button class="btn btn-info" (click)="onConfirm()">{{btnTextConfirm}}</button> -->
        </div>
        <!--#end .modal-footer -->
  
      </div>
      <!--#end .modal-dialog -->
    </div>
    <!--#end .modal-content -->
  </div>
  <!--#end .modal -->

  <app-invoice-line-dispute
    *ngIf="onShowInvoiceLinesDispite"
    [isVisible]="onShowInvoiceLinesDispite"
    [selectedDetailDataHandle]="selectedDetailDataHandle"
    [disputStatues]="disputStatues"
    (cancel)="closeModal('line-item-dispute')"
    (updatedStatus)="onInvoiceStatusUpdate($event)"
    ></app-invoice-line-dispute>