import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { retry, catchError } from 'rxjs/operators';
import * as moment from 'moment';

export interface Fee {
  FeeItemID:	number;
  Subject:	string;
  Date: string;
  RateType: string;
  SubCategoryID: number;
  ServiceID: number;
  Rate: number;
  Total: number;
  Quantity: number;
  IsVatable:	boolean;
  LineCounter: number;
  FurtherDescription:	string;
  ServiceProviderID: number;
  CanEdit:	boolean;
  MatterStatus:	string;
  Edited: boolean;
  CaptureSource: string;
  CanContinueToEditCalendarItems: boolean;
}

export const DEFAULT_FEE: Fee =  {
  FeeItemID: 0,
  Subject: '',
  Date: moment().toJSON(),
  RateType: 'Hourly',
  SubCategoryID: 0,
  ServiceID: 0,
  Rate: 0,
  Total: 0,
  Quantity: 1,
  IsVatable: false,
  LineCounter: 0,
  FurtherDescription: '',
  ServiceProviderID: 0,
  CanEdit: false,
  MatterStatus: 'Capture Fees',
  Edited: false,
  CaptureSource: '',
  CanContinueToEditCalendarItems: false
};

export interface DocumentTypes {
  ID: number;
  DocumentType: string;
  Description: string;
}

export const DEFAULT_DOCOMENT_TYPE: DocumentTypes = {
  ID: 0,
  DocumentType: '',
  Description: ''
};

export const RATE_TYPE_PER_KILOMETER  = 'Per Kilometer';
export const RATE_TYPE_HOURLY         = 'Hourly';
export const RATE_TYPE_ONCE_OFF       = 'Once Off';
export const RATE_TYPE_DAILY          = 'Daily';
export const RATE_TYPE_DISBURSEMENT   = 'Disbursement';
export const RATE_TYPE_NON_BILLABLE   = 'Non Billable';
export const RATE_TYPE_PER_PAGE       = 'Per Page';
export const RATE_TYPE_NO_CHARGE      = 'No Charge';
export const RATE_TYPE_ADD_DEPOSIT    = 'Add Deposit';
export const DEFAULT_RATE_TYPE        = RATE_TYPE_HOURLY;
export const DEFAULT_RATE             = 1200;
export const DEFAULT_QUANTITY         = 0;

@Injectable()
export class FeeItemsService {
  fees: Fee[] = [];
  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  /**
   * GET: returns FeeItem data by Service (Matter)
   * @param serviceID Identifier of Service (Matter) for which to return fees
   * @returns {Observable<*>} Returns a collection of Fee Items for a given Service (Matter)
   * Depending on the UX for this rendered data, this should appear in a
   * Fee Detail screen.
   */
  getFees(serviceID: number): Observable<any> {
    return this.http.get(this.api.endpoints.feeItems +
      '?ServiceProviderID=' + this.api.serviceProviderID +
      '&ServiceID=' + serviceID, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getFees()

/**
* GET: returns InvoiceLine item data by Service (Matter)
* @param serviceID Identifier of Service (Matter) for which to return invoiceLine item
* @returns {Observable<*>} Returns InvoiceLine item for a given Service (Matter)
*/

getPaymentReceived(serviceID: number): Observable<any> {
  return this.http.get(this.api.endpoints.paymentReceived +
    '?ServiceProviderID=' + this.api.serviceProviderID +
    '&ServiceID=' + serviceID, this.api.httpOptions)
    .pipe(
      catchError(this.api.handleError)
    );
}  // end getPaymentReceived()

  /**
  * GET: returns InvoiceLine item data by Service (Matter)
  * @param serviceID Identifier of Service (Matter) for which to return invoiceLine item
  * @returns {Observable<*>} Returns InvoiceLine item for a given Service (Matter)
  */

  getDiscount(serviceID: number): Observable<any> {
    return this.http.get(this.api.endpoints.feeItems + '/Discount' +
      '?ServiceProviderID=' + this.api.serviceProviderID +
      '&ServiceID=' + serviceID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }  // end getDiscount()

  /**
   * PUT: updates the given FeeItem.
   * This object contains data for the FeeItem being captured.
   * This data originates from Fee Detail.
   * @param {Fee} fee The FeeItem object.
   * @returns {Observable<*>} Returns an Observable.
   */
  updateFee(fee: Fee): Observable<any> {
    return this.http.put(this.api.endpoints.feeItems +
      '?ServiceProviderID=' + this.api.serviceProviderID +
      '&actionId=' + fee.FeeItemID, fee, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end updateFee()

  updateFees(fees: Fee[]): Observable<any> {
    return this.http.put(this.api.endpoints.feeItemList +
      '?ServiceProviderID=' + this.api.serviceProviderID,
      {feeItemList: fees}, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end updateFees()

  /**
   * POST: creates a new FeeItem.
   * This object contains data for the FeeItem being captured.
   * This data originates Fee Detail (of a Service).
   * @param {Fee} fee The FeeItem object.
   * @returns {Observable} Returns an observable.
   */
  createFee(fee: Fee): Observable<any> {
    fee.ServiceProviderID = this.api.serviceProviderID;
    return this.http.post(this.api.endpoints.feeItems +
      '?ServiceProviderID=' + this.api.serviceProviderID, fee, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end createFee()

  /**
   * DELETE: Deletes the given FeeItem.
   * This object contains data for the FeeItem being captured.
   * This will delete fee item and related data (e.g. invoice lines).
   * @param feeID The FeeItem ID.
   */
  deleteFee(feeID: number): Observable<{}> {
    return this.http.delete(this.api.endpoints.feeItems +
      '/' + feeID, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end deleteFee()

  deleteHistoricalFee(feeID: number): Observable<{}> {
    return this.http.delete(this.api.endpoints.takeOnData +
      '/' + feeID, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end deleteHistoricalFee()

  getDocumentType(): Observable<any> {
    return this.http.get(this.api.endpoints.documentType,
      this.api.httpOptions).pipe(
        catchError(this.api.handleError)
      );
  } // end getDocumentType{}

} // end FeeItemsService{}
