import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef } from '@angular/core';
import {
  MatterQuotationDTO
} from '../../../services/quotation/quotation.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { AttorneysService } from '../../../services/attorneys/attorneys.service';
import { UserProfileService, UserProfile } from '../../../services/user-profile/user-profile.service';
import { UsercurrencyDirective } from '../../../directive/usercurrency.directive';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { LawFirm, LIST_NAME_LAW_FIRMS } from '../../../services/lists/lists.service';
import { ClientContact } from '../../../services/client-contacts/client-contacts.service';
import { Plaintiff } from '../../../services/plaintiffs/plaintiffs.service';
import { OtherClients } from '../../../services/other-clients/other-clients.service';
import { Defendant } from '../../../services/defendants/defendants.service';
import * as moment from 'moment';
import { IndexedDBService } from '../../../services/indexed-db/indexed-db.service';

@Component({
  selector: 'app-matter-quotation-details',
  templateUrl: './matter-quotation-details.component.html',
  styleUrls: ['./matter-quotation-details.component.scss']
})
export class MatterQuotationDetailsComponent implements OnInit {

  bsValue = new Date();
  plaintiff: string;
  defendant: string;
  avaragePaymentRating: number;
  lawfirmRatingLabel: string;
  isLawfirmRatingLoading: boolean;

  onShowPaymentTermsModal: boolean;

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };
  isLoading: boolean;
  images: any;
  paymentTerms: any;

  // @Input() isLoading: boolean;
  @Input() workingMatterQuotation: MatterQuotationDTO;
  @Input() matterPlaintiffs: string;
  @Input() matterDefendants: string;
  @Input() lawFirms: LawFirm[];
  @Input() attorneys: ClientContact[];
  @Input() plaintiffs: Plaintiff[];
  @Input() otherClients: OtherClients[];
  @Input() defendants: Defendant[];
  @Input() attorneyRefs: string[];
  @Input() instructions: string[];
  @Input() matters: MatterQuotationDTO[];
  @Input() userProfile: UserProfile;
  @Output() workingMatterChange: EventEmitter<MatterQuotationDTO> = new EventEmitter<MatterQuotationDTO>();
  @Output() matterPlaintiffsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterOtherClientsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterDefendantsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterLawfirmChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterAttorneyChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterDetailsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() addPlaintiff: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('paymentTermsRef') paymentTermsRef: ElementRef;
  @ViewChild('inputHourly') focusHourly: ElementRef;
  @ViewChild('inputDaily') focusDaily: ElementRef;
  @ViewChild('inputPerKilometer') focusPerKilometer: ElementRef;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  // Is Internal Attorney
  get isAttorney(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
  } // end isAttorney()

  // Is Internal isAttorneyAssistant
  get isAttorneyAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator) &&
      Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.ATTORNEY);
  } // end isAttorneyAssistant()

  // Is Internal isLawfirmAssistant
  get isLawfirmAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator) &&
      Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
  } // end isLawfirmAssistant()

  // Is Internal Lawfirm
  get isLawfirm(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
  } // end isLawfirm()

  constructor(
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private indexedDbService: IndexedDBService,
    private attorneysService: AttorneysService,
    private userProfileService: UserProfileService,
    private currencyDirective: UsercurrencyDirective,
  ) {
    // this.isLoading = true;
    this.images = {
      spinner: {
        path: '../../../../assets/images/logos/spinner.gif',
        label: 'Auxcon spinner'
      } // spinner{}
    };
   } // end constructor()

  ngOnInit() {
    this.paymentTerms = [
      { id: 0, name: '0 Days' },
      { id: 30, name: '30 Days' },
      { id: 60, name: '60 Days' },
      { id: 90, name: '90 Days' },
      { id: 98, name: '98 Days' },
      { id: 101, name: 'Other' }
    ];

    if (!this.lawFirms) {
      // if (localStorage.getItem(LIST_NAME_LAW_FIRMS)) {
      //   this.lawFirms = JSON.parse(localStorage.getItem(LIST_NAME_LAW_FIRMS));
      // }
      this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData').then((data) => {
        if (data) {
            this.lawFirms = data.data; // Assuming your data is stored under the 'data' key
        } else {
            this.lawFirms = []; // Or any default value you wish to set
        }
    }).catch((error) => {
        console.error('Error retrieving law firms from IndexedDB', error);
        this.lawFirms = []; // Setting a default value in case of an error
    });
    }

    // Get Attorneys
    if (this.workingMatterQuotation && this.workingMatterQuotation.MatterQuotationId) {
       this.attorneysService.getAttorneysByLawFirms(this.workingMatterQuotation.LawfirmId).subscribe({
         next: (_attorney) => {
          this.attorneys = _attorney;
         }
       });
    }

    if (this.workingMatterQuotation && this.workingMatterQuotation.QuotationId > 0) {
      this.bsValue = moment(this.workingMatterQuotation.MatterQuotationDate).toDate();
      let terms = this.paymentTerms.filter(s => s.Id === this.workingMatterQuotation.PaymentTerms);
      if (terms) {
        this.paymentTerms.push({ id: this.workingMatterQuotation.PaymentTerms, name: this.workingMatterQuotation.PaymentTerms + ' Days' });
      }
    } else {
      this.workingMatterQuotation.MatterQuotationDate = moment(this.bsValue).format('YYYY-MM-DDTHH:mm:ss');
    }
  } // end ngOnInit()

  onSavePaymentTerms(event) {
    this.workingMatterQuotation.PaymentTerms = event;
    this.paymentTerms.push({id: event, name: event + ' Days' })
    this.onShowPaymentTermsModal = false;
  } // end onSavePaymentTerms()

  onPaymentTermsModalClose() {
    this.onShowPaymentTermsModal = false;
  } // end onPaymentTermsModalClose()

  onPaymentTermsSelect(item) {
    if (Number(item.target.value) === 101) {
      this.onShowPaymentTermsModal = true;
    }
  } // end onPaymentTermsSelect()

  parseServiceDate(serviceDate: string): Date {
    if (serviceDate) {
      return new Date(serviceDate);
    } else {
      return null;
    }
  } // end parseServiceDate()

  onDateValueChange(value: Date): void {
    this.bsValue = value;
    this.workingMatterQuotation.MatterQuotationDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
  } // end onDateValueChange()

  //#region Currency Input change
  onHourlyRateChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusHourly.nativeElement.value = this.cpipe.transform(this.focusHourly.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.focusHourly.nativeElement.value =
      this.cpipe.transform(this.focusHourly.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onHourlyRateChange()

  onHourlyRateClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusHourly.nativeElement.value =
      this.cpipe.parse(this.focusHourly.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusHourly.nativeElement.value =
      this.cpipe.parse(this.focusHourly.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onHourlyRateClicked()

  onDailyRateChange() {
    if (!this.focusDaily.nativeElement.value.startsWith('R')) {
      if (this.userProfileService.userProfile.isImpersonator) {
        this.focusDaily.nativeElement.value =
        this.cpipe.transform(this.focusDaily.nativeElement.value,
          this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
      } else {
        this.focusDaily.nativeElement.value =
        this.cpipe.transform(this.focusDaily.nativeElement.value,
          this.userProfileService.userProfile.currencyDetails.symbol, 2);
      }
    }
  } // end onDailyRateChange()

  onDailyRateClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusDaily.nativeElement.value =
      this.cpipe.parse(this.focusDaily.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusDaily.nativeElement.value =
      this.cpipe.parse(this.focusDaily.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onDailyRateClicked()

  onPerkilometerRateChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusPerKilometer.nativeElement.value =
      this.cpipe.transform(this.focusPerKilometer.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      this.focusPerKilometer.nativeElement.value =
      this.cpipe.transform(this.focusPerKilometer.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onPerkilometerRateChange()

  onPerkilometerRateClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusPerKilometer.nativeElement.value =
      this.cpipe.parse(this.focusPerKilometer.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusPerKilometer.nativeElement.value =
      this.cpipe.parse(this.focusPerKilometer.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onPerkilometerRateClicked()
  //#endregion Currency Input change

  onModelChange(type: string, contact: any) {
    switch (type) {
      case 'law-firms':
        // Re-build attorneys list
        if (this.workingMatterQuotation.LawfirmId) {
          this.isLawfirmRatingLoading = true;
          this.attorneysService.getLawfirmRating(this.workingMatterQuotation.LawfirmId).subscribe({
            next: (avarage_rating) => {
              if (avarage_rating) {
                this.avaragePaymentRating = avarage_rating.IntAvgDays;
                if (avarage_rating.AverageDaysToPayment === 'Not Applicable') {
                  this.lawfirmRatingLabel = 'No data available.';
                } else {
                  this.lawfirmRatingLabel = 'On avarage pay within ' + this.avaragePaymentRating +  ' days';
                }
                // this.lawfirmRatingLabel = 'On avarage pay within ' + this.avaragePaymentRating + ' days';
              } else {
                this.lawfirmRatingLabel = 'No data available.';
              }
            },
            error: (error) => {
              // On error
              this.isLawfirmRatingLoading = false;
              this.lawfirmRatingLabel = 'No data available.';
            },
            complete: () => {
              // On complete
              this.isLawfirmRatingLoading = false;
            }
          });
          this.attorneysService.getAttorneysByLawFirms(this.workingMatterQuotation.LawfirmId).subscribe({
            next: (contacts) => {
              this.attorneys = contacts;
              this.sortAttorneysList();
            },
            error: (error) => {
              const msg = 'Error loading Attorney list.';
              console.error(msg, error);

            },
            complete: () => {
              if (contact.FullName) {
                 // Reset attorneys field
                this.workingMatterQuotation.AttorneyId = 0;
                this.workingMatterQuotation.AttorneyName = '';
            }
          }
        });
        }
        contact.FullName = '';
        contact.ContactID = 0;
        break;

      case 'attorneys':
        // this.workingMatter.NewClientDetail = contact.FullName;
        // this.workingMatter.DefendantFullName = contact.FullName;
        this.workingMatterQuotation.AttorneyId = contact.ContactID;
        this.matterAttorneyChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        break;

      case 'plaintiffs':
        // if (contact.target.value) {
        // this.workingMatter.PlaintiffFullName = contact.FullName;
        this.workingMatterQuotation.PlaintiffContactId = contact.ContactID;
        // }
        this.matterPlaintiffsChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        break;
      case 'other-clients':
        this.workingMatterQuotation.PlaintiffContactId = contact.ContactID;
        // }
        this.matterOtherClientsChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        this.workingMatterQuotation.PlaintiffContactId = 0;
        break;

      case 'defendants':
        // if (contact.target.value) {
        // this.workingMatter.DefendantFullName = contact.FullName;
        this.workingMatterQuotation.DefendantContactId = contact.ContactID;
        // }
        this.matterDefendantsChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        break;

      case 'attorney-reference':
        this.workingMatterQuotation.ClientRefNo = contact.FullName;
        contact.FullName = '';
        contact.ContactID = 0;
        break;

      case 'instructions':
        this.workingMatterQuotation.Instructions = contact.FullName;
        contact.FullName = '';
        contact.ContactID = 0;
        break;
    }
    // this.matterChange.emit(this.workingMatter);
  } // end onModelChange()

  sortAttorneysList() {
    if (this.attorneys.length > 0) {
      this.attorneys.sort((a, b) => {
        if (a && a.ClientFullName !== undefined && b && b.ClientFullName !== undefined) {
          return a.ContactFullName.toUpperCase().localeCompare(a.ContactFullName.toUpperCase());
        }
      });
    }
  } // end sortAttorneysList()

} // end MatterQuotationDetailsComponent{}
