import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingService } from '../../../../services/messaging/loading/loading.service';
import {
  PracticeAdvocateHealthService,
  PracticeAgeAnalysis,
  DEFAULT_PRACTICE_AGE_ANALYSIS,
  AdvocatePracticeSummary,
  AdvocatePracticeSummaryHandle,
  AdvocateHealthHandle,
  AdvocateHealth,
  DebtorTotalOutstanding
} from '../../../../services/practice-advocate-health/practice-advocate-health.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { PATH_PRACTICE_MANAGER_REPORTS } from '../../../../services/appdata/app-config.service';
import { ICrumb } from '../../../../services/advocate-detail-statement/advocate-detail-statement.service';

export enum ReportType {
  BusinessReports = 'businessReports',
  FinancialReports = 'financialReports',
  LiveStatement = 'liveStatement',
  PracticeManagementReports = 'practiceManagementReports',
  IndustryReports = 'industryReport',
  MyAdvocates = 'myAdvocates'
} // end ReportType{}

enum WidgetType {
  InvoicedTarget = 'invoicedTarget',
  CollectedTarget = 'collectedTarget',
  AdvocateReview = 'advocateReview',
  InvoicingAdvocate = 'invoicingAdvocate',
  AgeAnalysis = 'ageAnalysis'
}

export enum ReportName {
  AdvocateSummary = 'advocateSummary',
  AdvocateDetail = 'advocateDetail',
  AgeAnalysis = 'ageAnalysis',
  PracticeAllCustomer = 'practiceAllCustomer',
  PracticeByLawfirm = 'practiceByLawfirm',
  avarageDaysToPayReport = 'avarageDaysToPayReport'
} // end ReportName{}

export const DEFAULT_REPORT_PARAMETERS = {
  fromDate: '',
  toDate: '',
  lawFirmId: 0,
  fromMonth: '1',
  toMonth: '1',
  fromYear: '2013',
  toYear: '2013',
  serviceProviderId: 0,
  serviceId: 0,
};

@Component({
  selector: 'app-practice-all-customer',
  templateUrl: './practice-all-customer.component.html',
  styleUrls: ['./practice-all-customer.component.scss']
})
export class PracticeAllCustomerComponent implements OnInit, OnDestroy {
  title = 'Reports';
  reportType: ReportType; // = ReportType.FinancialReports;
  reportName: ReportName; // = ReportName.FinancialSummaryViewReport;
  _reportName = '';
  activeTab: string;

  crumbs: ICrumb[];

  advocateHealth: AdvocateHealth[] = [];
  advocateHealthHandle: AdvocateHealthHandle[] = [];
  advocatePracticeSummaryHandle: AdvocatePracticeSummaryHandle[] = [];
  adovatePracticeSummary: AdvocatePracticeSummary[] = [];

  debtorTotalOutstanding: DebtorTotalOutstanding[] = [];

  // Practice Managers Age Analysis
  practiceAgeAnalysis: PracticeAgeAnalysis = Object.assign({}, DEFAULT_PRACTICE_AGE_ANALYSIS);

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private practiceAdvocateHealthService: PracticeAdvocateHealthService
  ) { } // end constructor()

  ngOnInit() {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
    // if (this.practiceAdvocateHealthService.reportSortType === WidgetType.InvoicedTarget
    //   || this.practiceAdvocateHealthService.reportSortType === WidgetType.CollectedTarget
    //   || this.practiceAdvocateHealthService.reportSortType === WidgetType.AdvocateReview
    //   || this.practiceAdvocateHealthService.reportSortType === WidgetType.InvoicingAdvocate) {
    //   this.reportType = ReportType.MyAdvocates;
    //   this.reportName = ReportName.AdvocateDetail;
    //   this.viewReport();
    // } else if (this.practiceAdvocateHealthService.reportSortType === WidgetType.AgeAnalysis) {
    //   this.reportType = ReportType.MyAdvocates;
    //   this.reportName = ReportName.AgeAnalysis;
    //   this.viewReport();
    // } else {
    //   this.reportType = ReportType.MyAdvocates;
    //   this.reportName = ReportName.PracticeAllCustomer;
    this.reportName = ReportName.PracticeAllCustomer;
      this.viewReport();
    // }
  } // end ngOnInit()

  togglePanel(panel: string) {
    this._reportName = '';
    switch (panel) {
      case 'myAdvocates':
        this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportName = ReportName.AdvocateSummary;
        this.router.navigate([PATH_PRACTICE_MANAGER_REPORTS]);
      break;
      case 'businessReports':

        break;
      case 'financialReports':

      break;
      case 'industryReport':
        this.reportType = ReportType.IndustryReports;
        this.reportName = ReportName.avarageDaysToPayReport;
        this._reportName = this.reportName;
        // this.viewReport();
        this.crumbs = [
          {
            label: 'Reports'
            // link: PATH_REPORTS
          },
          {
            label: 'Industry Reports'
            // link: PATH_REPORTS
          },
          {
            label: 'Average Days to Pay Report'
          }
        ];
      break;
    }
  } // end togglePanel()

  ngOnDestroy() {
    if (this._reportName === 'practiceAllCustomer') {
      this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
      this.practiceAdvocateHealthService.selectedReportName = ReportName.AgeAnalysis;
      this.practiceAdvocateHealthService.reportSortType = WidgetType.AgeAnalysis;
    }
  } // end ngOnDestroy()

  viewReport() {
    switch (this.reportName) {
      case 'advocateSummary':
        this._reportName = this.reportName;
        this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportName = ReportName.AdvocateSummary;
        this.router.navigate([PATH_PRACTICE_MANAGER_REPORTS]);
      break;
      case 'advocateDetail':
        this._reportName = this.reportName;
        this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportName = ReportName.AdvocateDetail;
        this.practiceAdvocateHealthService.reportSortType = WidgetType.InvoicingAdvocate;
        this.router.navigate([PATH_PRACTICE_MANAGER_REPORTS]);
      break;
      case 'ageAnalysis':
        this._reportName = this.reportName;
        this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportName = ReportName.AgeAnalysis;
        this.router.navigate([PATH_PRACTICE_MANAGER_REPORTS]);
      break;
      case 'practiceAllCustomer':
        this.activeTab = this.practiceAdvocateHealthService.allCustomerActiveTab;
        this._reportName = this.reportName;
        this.crumbs = [
          {
            label: 'Reports'
          },
          {
            label: 'Age Analysis',
            link: PATH_PRACTICE_MANAGER_REPORTS
          },
          {
            label: 'All Customer Report'
          }
        ];
      break;
      case 'practiceByLawfirm':
        this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
        this.practiceAdvocateHealthService.selectedReportName = ReportName.PracticeByLawfirm;
        this.activeTab = this.practiceAdvocateHealthService.allCustomerActiveTab;
        // this.getPracticeByLawfirmReport();
        this._reportName = this.reportName;
        this.router.navigate([PATH_PRACTICE_MANAGER_REPORTS]);
      break;
    }
  } // end viewReport()

  getPracticeAdvocateHealth() {
    this.advocateHealthHandle = [];
    this.loadingService.showOverlay();
    this.practiceAdvocateHealthService.getAdvocateHealthData().subscribe({ next: (_data) => {
      // On next
      this.advocateHealth = _data;
      if (this.advocateHealth) {
        this.advocateHealth.forEach(advocate => {
          const invoiceColor = (advocate.Total_Invoiced_120 / (advocate.FeeTarget * 3) * 100) - 100;
          const collectedColor = (advocate.Paid120Days / (advocate.IncomeTarget * 3) * 100) - 100;
          const collectedThisMonthColor = (advocate.CollectedLastMonth / (advocate.IncomeTarget) * 100) - 100;
          const lastmeeting = moment().diff(moment(advocate.LastMeetingDate), 'week');
          const lastinvoiced = moment().diff(moment(advocate.Last_Invoice_Created), 'week');
          const laststatement = moment().diff(moment(advocate.Last_Statement_Date), 'week');
          const lasttransaction = moment(advocate.Last_Statement_Date).diff(moment(advocate.Last_Transaction), 'week');
          // if (invoiceColor > 0) {
          this.advocateHealthHandle.push({
            data: advocate,
            state: {
              isVisible: true,
              isSelected: false,
              isOpen: false,
              collectedColor: Math.sign(collectedColor) === 1 ? '' : '#cf624f',
              invoicedColor: Math.sign(invoiceColor) === 1 ? '' : '#cf624f',
              calculateLastMeetingDate: lastmeeting,
              calculateLastInvoiceDate: lastinvoiced,
              calculateLastStatementDate: laststatement,
              calculateLastTransactionDate: lasttransaction,
              collectedThisMonthColor: Math.sign(collectedThisMonthColor) === 1 ? '' : '#cf624f'
            }
          });
          // }
        });
        this.loadingService.hideOverlay();
      }
    }});
  } // end getPracticeAdvocaHealth()
} // end PracticeAllCustomerComponent()
