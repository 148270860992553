<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-header">
                <h2>{{title}}</h2>
                <button class="modal-dismiss" (click)="onDismiss()">
                    <i class="fa fa-close"></i>
                </button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="table-responsive">
                        <table class="table table-striped " style="border: 0;">
                            <tbody>
                                <tr>
                                    <th scope="col">Invoice Date</th>
                                    <th scope="col">Invoice No</th>
                                    <th scope="col">Invoice Amount</th>
                                    <th scope="col">Paid</th>
                                    <th scope="col">Outstanding</th>
                                    <th scope="col" class="write-off-color">Write-Off</th>
                                    <th scope="col"></th>
                                </tr>
                                <tr *ngFor="let data of toWriteOffTransactionLine">
                                    <td>{{data.InvoiceDate | date:'yyyy-MM-dd'}}</td>
                                    <td>{{data.InvoiceNo}}</td>
                                    <td>{{data.InvoiceTotal | currency:currencySymbol}}</td>
                                    <td>{{data.TotalPaidAmount | currency:currencySymbol}}</td>
                                    <td>{{data.OutstandingBalance | currency:currencySymbol}}</td>
                                    <td class="write-off-color">{{data.OutstandingBalance | currency:currencySymbol}}</td>
                                    <td>
                                        <i class="far fa-minus-circle clickable" (click)="onInvoiceRemove(data)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group">
                        <label for="">Notes <span title="Required field"> *</span>&nbsp;</label>
                            <textarea 
                                #Notes="ngModel" 
                                [ngStyle]="{'border-color' : isNotes == false ? '#cf624f':'#e7e4e4'}"
                                (blur)="onWriteOffNotesBlur()" 
                                name="Notes" 
                                [(ngModel)]="writeOffNotes" 
                                class="form-control"
                                autocomplete="off" 
                                required></textarea>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn btn-danger" (click)="onDismiss()">Cancel</button>
                <button
                    class="btn btn-success" 
                    [class.disabled]="!hasNote"
                    [disabled]="!hasNote"
                    (click)="onConfirmWriteOff()">Add Write-off</button>
            </div>

        </div>
    </div>
</div>