
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatterQuotationHandle } from '../../../services/quotation/quotation.service';

@Component({
  selector: 'app-single-quotations-popup-menu',
  templateUrl: './single-quotations-popup-menu.component.html',
  styleUrls: ['./single-quotations-popup-menu.component.scss']
})
export class SingleQuotationsPopupMenuComponent implements OnInit {

  @Input() isVisible: boolean;
  @Input() type: string;
  @Input() serviceID: number;
  @Input() matter: MatterQuotationHandle;

  @Output() deleteMatterQuotation: EventEmitter<any> = new EventEmitter();
  @Output() previewInvoice: EventEmitter<any> = new EventEmitter();
  @Output() previewMessage: EventEmitter<any> = new EventEmitter();
  @Output() openMatter: EventEmitter<any> = new EventEmitter();
  @Output() moveToAccepted: EventEmitter<any> = new EventEmitter();
  @Output() moveToRejected: EventEmitter<any> = new EventEmitter();
  @Output() dismiss: EventEmitter<MatterQuotationHandle> = new EventEmitter();

  options: any = {
    openMatterQuotation: true,
    previewQuotation: true,
    previewMessage: true,
    deleteQuotation: true,
  };

  constructor() { } // end constructor()

  ngOnInit() {
    this.options.deleteMatter = true;
    this.options.previewQuotation = (this.type !== 'New');
    this.options.previewMessage = (this.type === 'Revise');
    this.options.previewSent = (this.type === 'Sent');
    this.options.openMatterQuotation = (this.type === 'Outbox');
  } // end ngOnInit()

  onDismiss() {
    this.dismiss.emit(this.matter);
  } // end dismiss()

  onDeleteQuotation() {
    this.deleteMatterQuotation.emit(this.matter.data);
    this.onDismiss();
  } // end onDeleteQuotation()

  get isInOutbox(): boolean {
    return Boolean(this.matter.data.Status === 'Outbox');
  } // end isInOutbox()

  get canAccept(): boolean {
    return Boolean(this.matter.data.Status === 'Sent' && this.matter.data.QuotationStateStatus === 'Pending');
  } // end canAccept()

  onPreviewQuotation() {
    this.previewInvoice.emit(this.matter.data);
    this.onDismiss();
  } // end onPreviewQuotation()

  onPreviewMessage() {
    this.previewMessage.emit(this.matter.data);
    this.onDismiss();
  } // end onPreviewMessage()

  onOpenQuotation() {
    this.openMatter.emit(this.matter.data);
    this.onDismiss();
  } // end onOpenQuotation()

  onMoveToAccepted() {
    this.moveToAccepted.emit(this.matter.data);
    this.onDismiss();
  } // end onMoveToAccepted()

  onMoveToRejected() {
    this.moveToRejected.emit(this.matter.data);
    this.onDismiss();
  } // end onMoveToRejected()
} // end SingleQuotationPopupMenuComponent{}
