import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  ApiService
} from '../api.service';
import { catchError, retry } from 'rxjs/operators';

export interface AddLawfirm {
  AttorneyName: string;
  LawfirmName: string;
  EmailAddress: string;
  ContactNumber: string;
} // end AddLawfirm{}

export interface AddLawfirmAdvocate {  
  LawfirmName: string;
  EmailAddress: string;
  ContactNumber: string;
  Cell : string;
  RegionalBar : string;
  AdmissionDay : number;
  AdmissionMonth : number;
  AdmissionYear : number;
  //AdmissionDate:Date;   
  //AdmissionDateStr:string;   
  ReportingAdvocateId: number;
} // end AddLawfirmAdvocate{}

export interface PaymentAllocationRequest {
  LawfirmId: number;
  LawfirmName: string;
  AdvocateId: number;
  AdvocateName: string;
  PaymentDate: string;
  PaymentDescription: string;
  PaymentAmount: string;
  TransactionAmount: string;
  PaymentResolution: string;
} // end PaymentAllocationRequest{}

export const DEFAULT_ALLOCATION_REQUEST: PaymentAllocationRequest = {
  LawfirmId: 0,
  LawfirmName: '',
  AdvocateId: 0,
  AdvocateName: '',
  PaymentDate: '',
  PaymentDescription: '',
  PaymentAmount: '',
  TransactionAmount: '',
  PaymentResolution: ''
}; // end PaymentAllocationRequest{}


@Injectable()
export class AddLawfirmAttorneyService {
  // private addLawfirm: AddLawfirm;
  constructor(
    private http: HttpClient,
    private api: ApiService
  ) {

  } // end constructor()

  /**
   * POST send New Lawfirm or Attorney details to auxcon team via api
   * @param {AddLawfirm} addLawfirm The new lawfirm or attorney.
   */
    addLawfirmAttorney(addLawfirm: AddLawfirm): Observable<AddLawfirm> {
      const postableLawfirmAttorney = {
        AttorneyName: addLawfirm.AttorneyName,
        LawfirmName: addLawfirm.LawfirmName,
        EmailAddress: addLawfirm.EmailAddress,
        ContactNumber: addLawfirm.ContactNumber
      };
      return this.http.post<any>(
        this.api.endpoints.addLawFirmAttorney,
        postableLawfirmAttorney,
        this.api.httpOptions
      ).pipe(
        retry(0),
        catchError(this.api.handleError)
      );
    } // end addLawfirmAttorney{}

    addLawfirmAdvocate(addLawfirmAdvocate: AddLawfirmAdvocate): Observable<AddLawfirmAdvocate> {
      const postableLawfirmAdvocate = {
        LawfirmName: addLawfirmAdvocate.LawfirmName,
        EmailAddress: addLawfirmAdvocate.EmailAddress,
        ContactNumber: addLawfirmAdvocate.ContactNumber,
        Cell: addLawfirmAdvocate.Cell,
        RegionalBar: addLawfirmAdvocate.RegionalBar,
        AdmissionDay : addLawfirmAdvocate.AdmissionDay,
        AdmissionMonth : addLawfirmAdvocate.AdmissionMonth,
        AdmissionYear : addLawfirmAdvocate.AdmissionYear,
        //AdmissiondateStr: addLawfirmAdvocate.AdmissionDateStr,
      };
      return this.http.post<any>(
        this.api.endpoints.addLawFirmAdvocate,
        postableLawfirmAdvocate,
        this.api.httpOptions
      ).pipe(
        retry(0),
        catchError(this.api.handleError)
      );
    } // end addLawfirmAdvocate{}

    postPaymentResolution(payment: PaymentAllocationRequest) {
      return this.http.post<PaymentAllocationRequest>(
        this.api.endpoints.paymentResolution,
        payment, this.api.httpOptions
      ).pipe(
        catchError(this.api.handleError)
      );
    } // end postPaymentResolution{}
}
