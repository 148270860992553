import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatterQuotationDTO } from '../../../services/quotation/quotation.service';
import { Plaintiff, DEFAULT_PLAINTIFF_TEMPLATE, PlaintiffsService } from '../../../services/plaintiffs/plaintiffs.service';
import { SnackbarsService, SnackType, Snack } from '../../../services/messaging/snackbars/snackbars.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { TAG_ADD_PLAINTIFF_MODAL, TAG_ADD_ATTORNEY_FIRM_MODAL } from '../../../services/tags/tags.service';

@Component({
  selector: 'app-datalist-quotation-plaintiffs',
  templateUrl: './datalist-quotation-plaintiffs.component.html',
  styleUrls: ['./datalist-quotation-plaintiffs.component.scss']
})
export class DatalistQuotationPlaintiffsComponent implements OnInit, OnChanges {

  types = {
    OTHER_CLIENTS: 'other-clients',
    LAW_FIRMS: 'law-firms',
    ATTORNEYS: 'attorneys',
    PLAINTIFFS: 'plaintiffs',
    DEFENDANTS: 'defendants'
  };

  customSelected: string;
  plaintiffs_list: any[];
  defendant_list: any[];
  attorneyList: any[];
  private selectedItem: any;

  showNewLawFirmModal: boolean;
  showNewAttorneyModal: boolean;
  showLawfirmErrorPrompt: boolean;
  showAttorneyErrorPrompt: boolean;

  showAddPlaintiffModal = false;
  addNewbuttonClicked = false;

  plaintiffDefendant: Plaintiff = Object.assign({}, DEFAULT_PLAINTIFF_TEMPLATE);

  @Input() defendantsList: Array<any>;
  @Input() plaintiffsList: Array<any>;
  @Input() matter: MatterQuotationDTO;
  @Input() type: string;
  @Input() iconClass: string;
  @Input() required: boolean;
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private snackbarsService: SnackbarsService,
    private plaintiffsService: PlaintiffsService,
  ) { } // end constructor()

  ngOnInit() {
    if (this.plaintiffsList) {
      if (this.plaintiffsList.length === 0) {
        this.plaintiffs_list = this.plaintiffsList;
      }
    }

    if (this.defendantsList) {
      if (this.defendantsList.length === 0) {
        this.defendant_list = this.defendantsList;
      }
    }
  } // end ngOnInit(

  get canAddPlaintiff(): boolean {
    return !Boolean(this.matter.PlaintiffFullName);
  } // end get canAddPlaintiff()

  get canAddDefendant(): boolean {
    return !Boolean(this.matter.DefendantFullName);
  } // end get canAddDefendant()

  ngOnChanges(changes: SimpleChanges) {
    if (this.plaintiffsList) {
      this.plaintiffs_list = this.plaintiffsList;
    }

    if (this.defendantsList) {
      this.defendant_list = this.defendantsList;
    }
    if (changes.matter && changes.matter.currentValue) {
    }

    if (changes.matter && changes.matter.currentValue) {
    }
  } // end ngOnChanges()

  onHideLawfirmErrorPrompt() {
    this.matter.InternalRefNo = '';
    this.showLawfirmErrorPrompt = false;
  } // end onHideLawfirmErrorPrompt()

  onHideAttorneyErrorPrompt() {
    this.matter.NewClientDetail = '';
    this.showAttorneyErrorPrompt = false;
  } // end onHideAttorneyErrorPrompt()

  onSelect(event: TypeaheadMatch): void {
    switch (this.type) {
      case this.types.PLAINTIFFS:
        this.selectedItem = event.item;
        this.customSelected = event.item.FullName;
        this.matter.PlaintiffFullName = event.item.FullName;
        this.matter.PlaintiffContactId = event.item.ContactID;
        this.select.emit(this.selectedItem);
        // this.canAddLawfirm = false;
        break;

      case this.types.DEFENDANTS:
        this.selectedItem = event.item;
        this.customSelected = event.item.FullName;
        this.matter.DefendantFullName = event.item.FullName;
        this.matter.DefendantContactId = event.item.ContactID;
        this.select.emit(this.selectedItem);
        break;
    }
  } // end onSelect{}

  onInput(event) {
    if (this.type === this.types.PLAINTIFFS) {
      if (event.inputType === 'deleteContentBackward') {
        this.selectedItem = null;
        this.matter.PlaintiffContactId = 0;
        // this.plaintiffs_list = [];
      }
    } else if (this.type === this.types.DEFENDANTS) {
      if (event.inputType === 'deleteContentBackward') {
        this.selectedItem = null;
        this.matter.DefendantContactId = 0;
      }
    }
  } // end onInput()

  addNewPlaintiffsDefendants(type: string) {
    switch (type) {
      case this.types.PLAINTIFFS:
        this.plaintiffDefendant.FullName = this.matter.PlaintiffFullName;
        this.showAddPlaintiffModal = true;
        break;
      case this.types.DEFENDANTS:
        this.plaintiffDefendant.FullName = this.matter.DefendantFullName;
        this.showAddPlaintiffModal = true;
    }
  } // end addNewLawfirmAttorney()

  onCloseModal(modal: string) {
    this.addNewbuttonClicked = false;
    switch (modal) {
      case TAG_ADD_PLAINTIFF_MODAL:
        this.showAddPlaintiffModal = false;
        break;

      case TAG_ADD_ATTORNEY_FIRM_MODAL:
        this.showNewAttorneyModal = false;
        break;
    }
  } // end onCloseModal()

  onSavePlaintiffDefendant(plaintiffDefendant) {
    if (plaintiffDefendant.FullName === undefined) {
      this.plaintiffDefendant = {
        FullName: plaintiffDefendant,
        ContactID: 0
      };
    }

    const isInList = this.plaintiffsList.find((item) => {
      return item.FullName.localeCompare(this.plaintiffDefendant.FullName) === 0;
    });
    if (!isInList) {
      const snack: Snack = {
        label: 'Saving new Plaintiff and Defendant...',
        action: null
      };
      this.snackbarsService.make(snack).show();
      this.plaintiffsService.createPlaintiff(this.plaintiffDefendant).subscribe({
        next: (plaintiff) => {
          this.plaintiffDefendant = plaintiff;
        },
        error: (error) => {
          const msg = 'Error saving new Plaintiff and Defendant.';
          console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack, 5000).show();
        },
        complete: () => {
          snack.label = 'Plaintiff and Defendant created.';
          snack.type = null;
          this.snackbarsService.dismiss().make(snack, 5000).show();
          this.plaintiffsList.push(this.plaintiffDefendant);
        }
      });
      //   plaintiff => {
      //     this.plaintiffDefendant = plaintiff;
      //   },
      //   error => {
      //     const msg = 'Error saving new Plaintiff and Defendant.';
      //     console.error(msg, error);
      //     snack.label = msg;
      //     snack.type = SnackType.ERROR;
      //     this.snackbarsService.dismiss().make(snack, 5000).show();
      //   },
      //   () => {
      //     snack.label = 'Plaintiff and Defendant created.';
      //     snack.type = null;
      //     this.snackbarsService.dismiss().make(snack, 5000).show();
      //     this.plaintiffsList.push(this.plaintiffDefendant);
      //   }
      // );
    } else {
      this.plaintiffDefendant = isInList;
    }
  } // end onSavePlaintiffDefendant()
} // end DatalistQuotationPlaintiffsComponent{}
