import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-send-invoice-to',
  templateUrl: './send-invoice-to.component.html',
  styleUrls: ['./send-invoice-to.component.scss']
})
export class SendInvoiceToComponent implements OnInit {

  @Input() isVisible: boolean;
  @Output() isAdvocate: EventEmitter<any> = new EventEmitter<any>();
  @Output() dismiss: EventEmitter<any> = new EventEmitter<any>();

  options: any = {
    sentToAdvocate: true
  };
  constructor() { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  /**
   * Emit a dismiss event to the parent component.
   */
  onDismiss() {
    this.dismiss.emit(true);
  } // end onDismiss()

  onSendToYourSelf() {
    this.isAdvocate.emit(false);
  } // end onSendToYourSelf()

} // end SendInvoiceToComponent{}
