<div class="row m-0">
  <div class="row m-0">
    <!-- #start Days overdue -->
    <div class="col-md-6">
      <div class="col  widget-container" (click)="onViewMoreReport('ageAnalysis')" style="height: 304px !important;">
        <div class="row  widget3-header text-white" [ngStyle]="{'background-color': agePercentageColor}">
          <div class="col-11 header-text">{{aging98Percentage}}% of your {{advocateName}}'s book is overdue
          </div>
          <div *ngIf="agePercentageColor !== '#e5c100'" class="col-1 fa-icon">
            <i [class.fa-badge-check]="agePercentageColor==='#8abd37'" title="Healthy"
              *ngIf="agePercentageColor==='#8abd37'" class="fal"></i>
            <i [class.fa-exclamation-triangle]="agePercentageColor==='#eb9e02'" title="Caution"
              *ngIf="agePercentageColor==='#eb9e02'" class="fal"></i>
            <i [class.fa-exclamation-circle]="agePercentageColor==='#cf624f'" title="Danger"
              *ngIf="agePercentageColor==='#cf624f'" class="fal"></i>
          </div>
          <div *ngIf="agePercentageColor == '#e5c100'" class="col-1 fa-icon">
            <img alt="neutral" src="./../../../../assets/images/icons/icon-neutral.svg" title="Neutral">
          </div>
        </div>
  
        <div class="row widget3-body">
  
          <div class="table-responsive">
            <table class="table text-center" style="border: 0;">
              <thead class="thead">
                <tr>
                  <th scope="col" class="w-10 box-container"></th>
                  <th scope="col" class="text-white box-container w-18">
                    <div class="bg-current box-child" [ngStyle]="{'height': getBarHeight(agingCurrentPercentage) }">
                      <p
                        [ngStyle]="{'margin-top': getBarTextTopMargin(agingCurrentPercentage) , 'color': getBarTextColor(agingCurrentPercentage)}">
                        {{agingCurrentPercentage}}%</p>
                    </div>
                  </th>
                  <th scope="col" class="text-white box-container w-18">
                    <div class="bg-30 box-child" [ngStyle]="{'height': getBarHeight(aging30Percentage) }">
                      <p
                        [ngStyle]="{'margin-top': getBarTextTopMargin(aging30Percentage) , 'color': getBarTextColor(aging30Percentage)}">
                        {{aging30Percentage}}%</p>
                    </div>
                  </th>
                  <th scope="col" class="text-white box-container w-18">
                    <div class="bg-60 box-child" [ngStyle]="{'height': getBarHeight(aging60Percentage) }">
                      <p
                        [ngStyle]="{'margin-top': getBarTextTopMargin(aging60Percentage) , 'color': getBarTextColor(aging60Percentage)}">
                        {{aging60Percentage}}%</p>
                    </div>
                  </th>
                  <th scope="col" class="text-white box-container w-18">
                    <div class=" bg-90 box-child" [ngStyle]="{'height': getBarHeight(aging90Percentage) }">
                      <p
                        [ngStyle]="{'margin-top': getBarTextTopMargin(aging90Percentage) , 'color': getBarTextColor(aging90Percentage)}">
                        {{aging90Percentage}}%</p>
                    </div>
                  </th>
                  <th scope="col" class="text-white box-container w-18">
                    <div class="bg-98 box-child" [ngStyle]="{'height': getBarHeight(aging98Percentage) }">
                      <p
                        [ngStyle]="{'margin-top': getBarTextTopMargin(aging98Percentage) , 'color': getBarTextColor(aging98Percentage)}">
                        {{aging98Percentage}}%</p>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
  
          <div class="table-responsive">
            <table class="table text-right mb-0" style="border: 0;">
              <thead class="thead">
                <tr>
                  <th scope="col" class="w-10" style="border-color:#f5f6f6 !important">Days
                  </th>
                  <th scope="col" class="bg-current text-white w-18" style="border-color:#b9b9b9 !important">
                    Current
                  </th>
                  <th scope="col" class="bg-30 text-white w-18" style="border-color:#888888 !important">30
                  </th>
                  <th scope="col" class="bg-60 text-white w-18" style="border-color:#7a7a7a !important">60
                  </th>
                  <th scope="col" class="bg-90 text-white w-18" style="border-color:#646464 !important">90
                  </th>
                  <th scope="col" class="bg-98 text-white w-18" style="border-color:#cf624f !important">
                    Overdue</th>
                </tr>
              </thead>
              <tbody class="tbody">
                <tr scope="">
                  <td class="w-11" scope="row"><b>Total</b></td>
                  <td class="w-17">
                    <b>{{agingCurrentTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                  </td>
                  <td class="w-17">
                    <b>{{aging30Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                  </td>
                  <td class="w-17">
                    <b>{{aging60Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                  </td>
                  <td class="w-17">
                    <b>{{aging90Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</b>
                  </td>
                  <td class="w-17 text-color">
                    <b><u>{{aging98Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></b>
                  </td>
  
                </tr>
              </tbody>
            </table>
          </div>
  
  
        </div>
  
        <div class="row col-md-2 offset-md-10 text-right">
          <u>View more ></u></div>
      </div>
    </div>
    <!-- #end Days overdue -->
  
  
    <!--#start -->
    <div class="col-md-6">
      <div class="chart-summary-container" *ngIf="isLoading">
        <app-circular-indicator [isVisible]="isLoading"></app-circular-indicator>
      </div>
      <div *ngIf="!isLoading" class="col widget-container" style="height: 304px !important;" (click)="onLawfirmOverdueClick()">
        <div class="row  widget2-header text-white" [ngStyle]="{'background-color': agePercentageColor}">
          <div class="col-11 header-text">Law firm {{lawfirmOne.Lawfirm}} is responsible for
            {{67}}% of {{advocateName}}'s overdue amounts.</div>
          <div *ngIf="agePercentageColor !== '#e5c100'" class="col-1 fa-icon">
            <i [class.fa-badge-check]="agePercentageColor==='#8abd37'" title="Healthy"
              *ngIf="agePercentageColor==='#8abd37'" class="fal"></i>
            <i [class.fa-exclamation-triangle]="agePercentageColor==='#eb9e02'" title="Caution"
              *ngIf="agePercentageColor==='#eb9e02'" class="fal"></i>
            <i [class.fa-exclamation-circle]="agePercentageColor==='#cf624f'" title="Danger"
              *ngIf="agePercentageColor==='#cf624f'" class="fal"></i>
          </div> <!-- #end .col-1 fa-icon -->
          <div *ngIf="agePercentageColor == '#e5c100'" class="col-1 fa-icon">
            <img alt="neutral" src="./../../../../assets/images/icons/icon-neutral.svg" title="Neutral">
          </div> <!-- #end .col-1 fa-icon -->
        </div> <!-- #end .row  .widget3-header text-white -->
        <div class="row widget4-body" style="min-height: 200px;">
          <div class="circle5">{{allFirms.Lawfirm}} <br />
            <b>{{allFirms.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b> <br /> <small>Overdue</small>
          </div>
          <div class="circle1">{{lawfirmThree.Lawfirm}} <br />
            <b>{{lawfirmThree.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b> <br /> <small>Overdue</small>
          </div>
          <div class="circle2">{{lawfirmFour.Lawfirm}} <br />
            <b>{{lawfirmFour.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b> <br /> <small>Overdue</small>
          </div>
          <div class="circle3">{{lawfirmTwo.Lawfirm}} <br />
            <b>{{lawfirmTwo.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b> <br /> <small>Overdue</small>
          </div>
          <div class="circle4">{{lawfirmOne.Lawfirm}} <br />
            <b>{{lawfirmOne.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</b> <br /> <small>Overdue</small>
          </div>
        </div>
    
        <div class="row col-md-2 offset-md-10 text-right">
          <!-- <u>View more ></u> -->
        </div> <!-- #end row col-md-2 offset-md-10 text-right -->
    
      </div> <!-- #end .col .widget-container -->
    </div> <!-- #end .col-md-6 -->
    <!--#end -->
  </div>
</div>