<div *ngIf="promptInvoices" class="row invoices-container">
    <div class="row invoices-header">
        Invoices added to bank transaction “{{selectedBankTransaction.TransactionDescription}}”
    </div>
    <div class="row invoices-body">
        <div class="text-center p-50">
            <b style="color:#cf624f; font-weight:900;"> No auto-matched invoices were found for this Bank transaction.
            </b>
            <br>Allocate invoices, add credit notes or allocate to law firm below.
        </div>
    </div>

    <div class="row invoices-footer">
        <div *ngIf="!isLawfirm && !isAttorney" class="col-sm-3 p-0" style="min-width: 30% !important;">
            <button class="form-control  btn" (click)="allocateInvoices()">Allocate balance to Firm</button>
        </div>
        <div *ngIf="isAttorney || isLawfirm" class="col-sm-3 p-0" style="min-width: 30% !important;">
            <button class="form-control  btn" (click)="allocateToClientInvoices()">Allocate balance to Client</button>
        </div>

        <div *ngIf="!isLawfirm && !isAttorney" class="col-sm-3 p-0" style="min-width: 30% !important;">
            <button class="form-control  btn" (click)="allocateToAdvocate()" [disabled]="!canAllocateButton">Allocate to Advocate</button>
        </div>
        <div *ngIf="isAttorney || isLawfirm" class="col-sm-3 p-0" style="min-width: 30% !important;">
            <button class="form-control  btn" (click)="allocateToAdvocate()">Allocate to Firm</button>
        </div>

        <div class="col-sm-3 p-0" style="min-width: 30% !important;">
            <button class="form-control cursor btn" disabled>Process transaction</button>
        </div>
    </div>
</div>



<!-- Save Proof of Payment -->
<div *ngIf="promptPaidInvoices" class="row invoices-container">
    <div class="row invoices-header">
        Invoices added to bank transaction “{{selectedBankTransaction.TransactionDescription}}”
    </div>

    <div class="row invoices-actions">
        <div class=" col-sm-1">Actions</div>
        <div class=" col-sm-3" [class.active]="showActions">
            <a (click)="onRemoveInvoices()"><i class="fal fa-file-times"></i><u>Remove selected</u>
            </a>
        </div>
        <div class=" col-sm-7 active">
            <a (click)="onViewWriteOffUnderPayment()"><i class="fal fa-file-times"></i><u>Write-off balance due on
                    invoices</u>
            </a>
        </div>
    </div>

    <div class="row invoices-body">
        <div class="table-responsive">
            <table id="invoicesTable" class="table ">
                <tbody>
                    <tr>
                        <th scope="col">
                            <div class="checkbox">
                                <input #inputCheckbox type="checkbox" (change)="toggleSelectAll()">
                            </div>
                        </th>
                        <th scope="col">Date</th>
                        <th scope="col">Invoice</th>
                        <th scope="col" *ngIf="!isLawfirm && !isAttorney">Law Firm</th>
                        <th scope="col" *ngIf="isAttorney || isLawfirm">Client</th>
                        <th scope="col">Matter</th>
                        <th scope="col">Reference</th>
                        <th scope="col" class="text-right">Due</th>
                        <th scope="col" class="text-right pr-3 ">Paid</th>
                    </tr>
                </tbody>
                <tbody style="border-top: 0;" *ngFor="let invoice of addedInvoiceToTransaction">
                    <tr>
                        <td scope="row">
                            <!-- <div class="checkbox">
                                <input type="checkbox" tabindex="-1"
                                    (change)="onChangeCheckBox(invoice.data, $event.target.checked)"
                                    id="i{{invoice.data.InvoiceId}}">
                                <label for="i{{invoice.data.InvoiceId}}"></label>
                            </div> -->
                            <app-small-checkbox [(checked)]="invoice.state.isSelected"
                                (click)="toggleCheckbox(invoice)"></app-small-checkbox>
                        </td>
                        <td [title]="invoice.data.Date | date:'yyyy-MM-dd'">{{invoice.data.Date | date:'yyyy-MM-dd'}}
                        </td>
                        <td [title]="invoice.data.InvoiceNo"><u class="clickable"
                                (click)="onViewInvoice(invoice.data.InvoiceId)">{{invoice.data.InvoiceNo}}</u></td>
                        <td [title]="invoice.data.Client" class="text-truncate">{{invoice.data.Client}}</td>
                        <td [title]="invoice.data.Matter" class="text-truncate">{{invoice.data.Matter}}</td>
                        <td [title]="invoice.data.Reference" class="text-truncate">{{invoice.data.Reference}}</td>
                        <td [title]="invoice.data.Amount | currency:currencySymbol" class="text-right">
                            {{invoice.data.Amount | currency:currencySymbol}}</td>
                        <td class="text-right paid">
                            <input
                                [ngStyle]="{'border-color' : invoice.data.Paid === '0' || invoice.data.Paid === 0 ? '#cf624f':'#e7e4e4'}"
                                class="form-control"
                                [title]="invoice.data.Paid | currency: currencySymbol: currencySymbol:'1.2-2'"
                                type="text" id="amount"
                                [ngModel]="invoice.data.Paid | currency: currencySymbol: currencySymbol:'1.2-2'"
                                [ngModelOptions]="{updateOn: 'blur'}" (ngModelChange)="invoice.data.Paid=$event"
                                (ngModelChange)="onTotalsCalculateChange('edit')"
                                (blur)="onAmountChange(invoice.data, $event)"
                                (focus)="onAmountClicked(invoice.data, $event)" (focus)="$event.target.select()"
                                pattern="[P-R\s]*[0-9,.]+" autocomplete="off" #inputAmount>
                        </td>
                        <app-prompt-unsaved-changes-modal
                            message="Make sure the amount paid is not more than invoice due amount."
                            [isVisible]="showErrorMessagePrompt" btnTextCancel="OK" btnTextContinue="Cancel"
                            (cancel)="onHideErrorMessagePrompt()" (continue)="onHideErrorMessagePrompt()">
                        </app-prompt-unsaved-changes-modal>
                        <br>
                    </tr>
                    <tr *ngIf="invoice.writeOff.isWriteOff">
                        <td scope="row" class="text-right"></td>
                        <td [title]="invoice.writeOff.Date | date:'yyyy-MM-dd'">
                            {{invoice.writeOff.Date | date:'yyyy-MM-dd'}}
                        </td>
                        <td><u class="">Write-Off</u></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td [title]="invoice.writeOff.Amount | currency:currencySymbol" class="text-right">
                            - {{invoice.writeOff.Amount | currency:currencySymbol}}</td>
                        <td class="text-right paid">

                        </td>
                    </tr>
                </tbody>
                <tbody style="border-top: 0;">
                    <tr *ngIf="allocateRow">
                        <td scope="col">
                            <div class="checkbox">
                                <input type="checkbox" tabindex="-1" style="margin-right: -5px;"
                                    (change)="onChangeCheckBox(0, $event.target.checked)" id="a{{allocateID}}">
                                <label for="a{{allocateID}}"></label>
                            </div>
                        </td>
                        <td scope="col">{{invoicesDate | date:'yyyy-MM-dd'}}</td>
                        <td scope="col"></td>
                        <td scope="col" colspan="2">
                            <div class="form-group">
                                <div class="input-group">
                                    <input [disabled]="hasInvoicesAllocated || isAllocatedToAdvocate"
                                        [(ngModel)]="lawFirmName" name="lawFirm" [typeahead]="lawFirms"
                                        typeaheadOptionField="FullName" [typeaheadSingleWords]="false"
                                        (input)="onInput($event)" class="form-control"
                                        placeholder="Select or start typing"
                                        (typeaheadOnSelect)="onChangeLawFirm($event)" [typeaheadOptionsLimit]="50"
                                        typeaheadScrollable=true typeaheadOptionsInScrollableView="10"
                                        [typeaheadMinLength]="0" container="body" autocomplete="off" [dropup]="true"
                                        required>
                                </div>
                            </div>
                        </td>

                        <td scope="col" colspan="2">Allocated to Law Firm</td>

                        <td scope="col" class="text-right paid pr-3">
                            {{allocateAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                    </tr>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col" style="font-weight: 900;">
                            {{totalAmountLeft | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                        <th scope="col" style="font-weight: 900;">Left to allocate</th>
                        <th scope="col"></th>
                        <th scope="col" style="font-weight: 900;">Total:</th>
                        <th scope="col" colspan="2" class="text-right paid">{{totalAmount | currency:currencySymbol}}
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row invoices-footer pr-0" *ngIf="showZeroErrorPaid">
        <span *ngIf="hasAnError" class="text-danger">Make sure the amount paid is not more than transaction
            amount.</span>
        <span *ngIf="showZeroErrorPaid" class="text-danger">You cannot have R0.00 amount paid, please remove the
            invoice(s) or
            update the paid amount.</span>
    </div>
    <div class="row invoices-footer pr-0">
        <!-- <div class="col-sm-3 p-0" style="min-width: 27% !important;">
            <button class="form-control btn" (click)="allocateInvoices()" style=" display: none"
                [class.display-block]="allocateButton">Allocate balance to Firm</button>
        </div>

        <div class="col-sm-3 offset-sm-1 p-0" style="min-width: 27% !important;">
            <button class="form-control btn" (click)="allocateToAdvocate()" style=" display: none"
                [class.display-block]="allocateButton">Allocate balance to Adv</button>
        </div> -->

        <div *ngIf="!isLawfirm && !isAttorney" class="col-sm-3 p-0" style="min-width: 30% !important;">
            <button class="form-control  btn" (click)="allocateInvoices()" style=" display: none"
                [class.display-block]="allocateButton">Allocate balance to Firm</button>
        </div>
        <div *ngIf="isAttorney || isLawfirm" class="col-sm-3 p-0" style="min-width: 30% !important;">
            <button class="form-control  btn" (click)="allocateToClientInvoices()" style=" display: none"
                [class.display-block]="allocateButton">Allocate balance to Client</button>
        </div>

        <div *ngIf="!isLawfirm && !isAttorney" class="col-sm-3 p-0" style="min-width: 30% !important;">
            <button class="form-control  btn" (click)="allocateToAdvocate()" style=" display: none"
                [class.display-block]="allocateButton">Allocate to Advocate</button>
        </div>
        <div *ngIf="isAttorney || isLawfirm" class="col-sm-3 p-0" style="min-width: 30% !important;">
            <button class="form-control  btn" (click)="allocateToAdvocate()" style=" display: none"
                [class.display-block]="allocateButton">Allocate to Firm</button>
        </div>

        <div class="col-sm-3 p-0" style="min-width: 30% !important;">
            <button class="form-control btn" (click)="saveInvoices()" [disabled]="!saveButton">Process
                transaction</button>
        </div>
    </div>
</div>
<app-available-invoices-bank-transaction *ngIf="!showAvailableInvoiceList" [isAttorney]="isAttorney"
    [isLawfirm]="isLawfirm" [showInvoices]="promptAvailableInvoicesEvent"
    [addedInvoiceToTransaction]="addedInvoiceToTransaction" [(selectedBankTransaction)]="selectedBankTransaction"
    [putInvoices]="pushAvailableInvoices" (promptAvailableInvoices)="onPaidInvoices($event)">
</app-available-invoices-bank-transaction>

<app-delete-confirm-model title="Selected Invoices Remove" message="Invoices must be Remove from predefined list."
    [isVisible]="showRemoveInvoices" (cancel)="onHideRemoveInvoices()" (confirm)="onRemoveInvoices()">
</app-delete-confirm-model>

<!-- <app-proforma-invoice 
    #proformaInvoice 
    title="Invoice" 
    popId="0" 
    [invoiceId]="selectedInvoiceId" 
    documentType="Invoice"
    matter="null" 
    [isVisible]="showProformaInvoice" 
    (closeModal)="closeModal('proforma-invoice')">
</app-proforma-invoice> -->
<app-invoice-preview-modal
    *ngIf="showProformaInvoice" 
    #invoicePreview 
    [notesHandle]="notesHandle"
    [plaintiff]="invoicePlaintiff" 
    [selectedTab]="selectedTab" 
    [defendant]="invoiceDefendant"
    [invoiceId]="selectedInvoiceId" 
    [invoiceDetails]="invoiceDetails" 
    [isVisible]="showProformaInvoice"
    (closeModal)="closeModal('proforma-invoice')">
</app-invoice-preview-modal>

<app-prompt-unsaved-changes-modal title="Send Statement of Account(s)"
    message="Do you wish to send out your statement of account(s)?" [isVisible]="showSendStatementPrompt"
    btnTextContinue="Proceed" btnTextCancel="Cancel" (cancel)="onHideSendStatementPrompt()"
    (continue)="onSendStatement()"></app-prompt-unsaved-changes-modal>


<app-write-off-under-payment-model
    [(addedWriteOffUnderpayment)]="addedInvoiceToTransaction"
    (refreshPage)="closeModal($event)" 
    *ngIf="showWriteOffUnderPayment"></app-write-off-under-payment-model
    >
<app-unallocated-categories
    [isVisible]="onShowUnAllocatedCategory"
    *ngIf="onShowUnAllocatedCategory"
    (cancel)="onUnAllocatedCategoryHide()"
    (confirm)="OnUnAllocatedCategoryConfirm($event)"></app-unallocated-categories>