<div class="container-fluid">

    <div class=" border text-center customers-container ">

        <div class="w-16">
            <div class="customers-nav" (click)="onTabClick('all')">
                <a class="all" [class.active]="activeTab === 'all'">All</a>
            </div>
        </div>
        <div class="w-16">
            <div class="customers-nav" (click)="onTabClick('current')">
                <a class="current" [class.active]="activeTab === 'current'"> Current</a>
            </div>
        </div>
        <div class="w-16">
            <div class="customers-nav" (click)="onTabClick('30')">
                <a class="thirty" [class.active]="activeTab === '30'"> 30 Days</a>
            </div>
        </div>
        <div class="w-16">
            <div class="customers-nav" (click)="onTabClick('60')">
                <a class="sixty" [class.active]="activeTab === '60'"> 60 Days</a>
            </div>
        </div>
        <div class="w-16">
            <div class="customers-nav" (click)="onTabClick('90')">
                <a class="ninety" [class.active]="activeTab === '90'"> 90 Days</a>
            </div>
        </div>
        <div class="w-16">
            <div class="customers-nav" (click)="onTabClick('98')">
                <a class="ninetyEight" [class.active]="activeTab === '98'"> Overdue
                </a>
            </div>
        </div>
    </div>

    <div class="row"></div>


    <div class="table-responsive">
        <table class="table table-striped" style="border: 0;">
            <!-- <thead class="thead"> -->
                <tbody class="tbody"> 
                <tr>
                    <th class="w-21">Debtor</th> <!-- Advocate & PM Debtor -->
                    <th class="w-5" *ngIf="isPracticeManager"></th>
                    <th class="w-2" *ngIf="isPracticeManager"></th>
                    <th class="w-2" *ngIf="isPracticeManager"></th>
                    <th class="w-2" *ngIf="isPracticeManager"></th>
                    <th class="w-2" *ngIf="isPracticeManager"></th>
                    <th class="w-21" *ngIf="isPracticeManager">Latest Note</th>
                    <!-- <th class="w-21" *ngIf="isPracticeManager && activeTab !== '98'"></th> -->
                    <th class="w-30" *ngIf="!isPracticeManager">Latest Note</th> <!-- Advocate Latest Note -->
                    <!-- <th class="w-30" *ngIf="!isPracticeManager && activeTab !== '98'"></th> -->
                    <th class="w-9 text-right">Outstanding</th> <!-- Advocate & PM Outstanding -->
                    <th class="w-38" *ngIf="!isPracticeManager"></th> <!-- Advocate & PM Graph -->
                    <th class="w-33" *ngIf="isPracticeManager"></th>
                    <th class="w-2" *ngIf="!isPracticeManager"></th>
                    <th class="w-2" *ngIf="activeTab === '98' && !isPracticeManager"></th>
                    <th class="w-7 text-right" *ngIf="activeTab === '98'">Report to Bar Society</th>
                    <th class="w-3 text-right" *ngIf="isPracticeManager && activeTab === '98'">LPC</th>
                    <th class="w-3 text-right" *ngIf="isPracticeManager && activeTab === '98'">Is Fav.</th>
                    <th class="w-3 text-right" *ngIf="isPracticeManager && activeTab === '98'">In Legal</th>
                    <th class="w-3 text-right" *ngIf="isPracticeManager && activeTab !== '98'"></th>
                </tr>
            <!-- </thead>
            <tbody class="tbody"> -->
                <tr *ngFor="let i of tabData;" [ngStyle]="{'background-color': getInLegalBackgroungColor(i)}">
                    <td class="w-21" *ngIf="!isPracticeManager">
                        <div (mouseenter)="onHover(i.clientId, activeTab)" class="tool-tip"><u><a (click)="onViewCustomerReport(i.clientId)">{{i.debtor}}</a></u>
                            <div class="tooltiptext">
                                <div class="container mt-5" *ngIf="showTooltipOverlay">
                                    <img src="../../../../../assets/images/logos/spinner.gif" height="50" alt="spinner">
                                </div>
                                <div class="container" *ngIf="showTooltipOverlay===false">
                                    <div class="row">
                                        <div class="col-md-8 text-left"><b>Matter</b></div>
                                        <div class="col-md-4 text-right"><b>Amount due</b></div>
                                    </div>
                                    <div class="row" *ngFor="let customer of tooltipData; let i = index" [ngStyle]="{'margin-bottom': getBottomRow(i, tooltipData.length)}">
                                        <div class="col-md-8 text-left">{{customer.matter}}</div>
                                        <div class="col-md-4 text-right">{{customer.due | currency: currencySymbol: currencySymbol:'1.2-2'}}
                                        </div>
                                    </div>
                                    <div style="position: absolute;bottom:3px;left:0;right:0">Click to open full
                                        outstanding invoice report</div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="w-21" *ngIf="isPracticeManager">
                        <div (mouseenter)="onContactProcessHover(i.clientId)" class="tool-tip"><u><a (click)="onViewCustomerReport(i.clientId)">{{i.debtor}}</a></u>
                            <div class="tooltiptext">
                                <div class="container mt-5" *ngIf="showTooltipOverlay">
                                    <img src="../../../../../assets/images/logos/spinner.gif" height="50" alt="spinner">
                                </div>
                                <div class="container" *ngIf="showTooltipOverlay===false">
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="contactProcess">
                                        <div class="form-group">
                                            <label><b>Law Firm Process</b></label>
                                        </div>
                                        <div class="form-group" style="line-height: 1.3; text-align: left;">
                                            <label>{{contactProcess.lawFirmProcess}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="contactProcess">
                                        <div class="form-group">
                                            <label><b>Contact Person</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{contactProcess.contactPersonName}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="contactProcess">
                                        <div class="form-group">
                                            <label><b>Email</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{contactProcess.contactPersonEmail}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="contactProcess">
                                        <div class="form-group">
                                            <label><b>Phone Number</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{contactProcess.contactPersonPhone}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="contactProcess">
                                        <div class="form-group">
                                            <label><b>Does the firm have system</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{contactProcess.haveSystem == true ? 'Yes' : 'No'}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="contactProcess">
                                        <div class="form-group">
                                            <label><b>System Name</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{contactProcess.system}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="contactProcess">
                                        <div class="form-group">
                                            <label><b>Are they able to provide status feedback?</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{contactProcess.ableToProviceStatusFeedback == true ? 'Yes' : 'No'}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="contactProcess">
                                        <div class="form-group">
                                            <label><b>Are they willing to provide feedback?</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{contactProcess.willingToProviceStatusFeedback}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="contactProcess">
                                        <div class="form-group">
                                            <label><b>Do we receive feedback?</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{contactProcess.receiveStatusFeedback}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="contactProcess">
                                        <div class="form-group">
                                            <label><b>Plaintiff or Defendant?</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{contactProcess.plaintiffDefendant}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isPracticeManager">
                        <a *ngIf="i.adHocCollections === true" title="Ad-Hoc assistance requested">
                            <i class="fa fa-money" style="font-size: 14px !important;"></i>
                        </a>
                    </td>
                    <td class="w-2" *ngIf="isPracticeManager">
                        <a *ngIf="isPracticeManager && (i.collectionsDocuments === true || i.isLODDocumentSent === true)"
                            title="Upload Documents"
                            class="clickable" (click)="onViewCollectionDocument(i)">
                            <i class="fa fa-paperclip" style="font-size: 14px !important;"></i>
                        </a>
                        <a *ngIf="isPracticeManager && (i.collectionsDocuments === false && i.isLODDocumentSent === false) && activeTab === '98'"
                            title="Upload Documents"
                            class="clickable" (click)="onViewCollectionDocument(i)">
                            <i class="fa fa-plus-circle" style="font-size: 14px !important; color: #5cb85c !important;"></i>
                        </a>
                    </td>
                    <td class="w-2 text-center" *ngIf="isPracticeManager">
                        <a *ngIf="isPracticeManager"
                            title="View Debtor Progress Report"
                            class="clickable" (click)="getDebtorProgressReport(i.clientId)">
                            <i class="fal fa-file" style="font-size: 14px !important;"></i>
                        </a>
                    </td>
                    <td class="w-2 text-center" *ngIf="isPracticeManager">
                        <a *ngIf="isPracticeManager"
                            title="Add/Edit Law firm details"
                            class="clickable" (click)="onAddLawfirmProcessPrompt(i.clientId)">
                            <i class="fal fa-pencil" style="font-size: 14px !important;"></i>
                        </a>
                    </td>
                    <td class="w-2 text-center" *ngIf="isPracticeManager">
                        <a *ngIf="i.latestComments"
                            (click)="onViewCustomerReport(i.clientId)"
                            title="You have an invoice note"
                            class="clickable">
                            <i class="fal fa-file-invoice" style="font-size: 14px !important;"></i>
                        </a>
                    </td>
                    <td 
                        class="w-21 latest-note auxcon-green" 
                        *ngIf="isPracticeManager && !pmLatestNote(i.notesDetail)" 
                        (click)="onPMAddNewNoteDetailOpen(i.clientId)"><u>Add new note</u></td>
                    <!-- <td class="w-21 latest-note auxcon-green" *ngIf="isPracticeManager && activeTab !== '98'"></td> -->
                    <!-- <td class="w-30 latest-note auxcon-green" *ngIf="!isPracticeManager && activeTab !== '98'"></td> -->
                    <td
                        class="w-21 latest-note"
                        *ngIf="isPracticeManager && pmLatestNote(i.notesDetail)"
                        [ngStyle]="{'font-weight': getUnReadMessageFont(i.notesDetail)}"
                        (click)="onPMNoteDetailOpen(i)">
                        <!-- <i *ngIf="getActionReminder(i.notesDetail)" class="fal fa-clock reminder" title="a reminder"></i> -->
                        <u style="color: blue;">{{pmLatestNote(i.notesDetail)}}</u>                    
                    </td>
                    <td
                        class="w-30"
                        *ngIf="!isPracticeManager"></td>
                    <!-- <td
                        class="w-30 latest-note"
                        [ngStyle]="{'font-weight': getAdvUnReadMessageFont(i.notesDetail)}"
                        (click)="onPMNoteDetailOpen(i.clientId)"
                        *ngIf="!isPracticeManager"><u style="color: blue;">{{clientLatestNote(i.notesDetail)}}</u></td> --> <!-- Hidding all notes from Advocate side -->
                    <!-- <td class="w-9 text-right" *ngIf="!isPracticeManager ">{{i.outstanding  | currency: currencySymbol: currencySymbol:'1.2-2'}}</td> -->
                    <td class="w-9 text-right">
                        <div (mouseenter)="onLawfirmSummaryHover(i.clientId)" class="tool-tip"><a>{{i.outstanding  | currency: currencySymbol: currencySymbol:'1.2-2'}}</a>
                            <div class="tooltiptext">
                                <div class="container mt-5" *ngIf="showTooltipOverlay">
                                    <img src="../../../../../assets/images/logos/spinner.gif" height="50" alt="spinner">
                                </div>
                                <div class="container" *ngIf="showTooltipOverlay===false">
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="lawfirmSummaryData">
                                        <div class="form-group">
                                            <label><b>Last invoice date:</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{lawfirmSummaryData.lastInvoiceDate | date:'yyyy-MM-dd'}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="lawfirmSummaryData">
                                        <div class="form-group">
                                            <label><b>Last payment date:</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{lawfirmSummaryData.lastTransactionDate | date:'yyyy-MM-dd'}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="lawfirmSummaryData">
                                        <div class="form-group">
                                            <label><b>Last payment amount:</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{lawfirmSummaryData.amount | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="lawfirmSummaryData">
                                        <div class="form-group">
                                            <label><b>12 Months payment amount:</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{lawfirmSummaryData.totalPaidYear | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="lawfirmSummaryData">
                                        <div class="form-group">
                                            <label><b>Average monthly payment:</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{lawfirmSummaryData.totalAverage | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="lawfirmSummaryData">
                                        <div class="form-group">
                                            <!-- TO DO: MAKE Description and Amount field Bold and red -->
                                            <label class="red-text"><b>Partial paid invoices:</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label class="red-text"><b>{{lawfirmSummaryData.partialPaidInvoices}}</b></label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="lawfirmSummaryData">
                                        <div class="form-group">
                                            <label class="red-text"><b>Unallocated:</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label class="red-text"><b>{{lawfirmSummaryData.unAllocated | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></label>
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: space-between; line-height: 0.8;" *ngIf="lawfirmSummaryData && isPracticeManager">
                                        <div class="form-group">
                                            <label><b>Total due across:</b></label>
                                        </div>
                                        <div class="form-group">
                                            <label>{{lawfirmSummaryData.totalOutstandingInvoices | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        
                                        <div class="col-md-8 text-left"><b></b></div>
                                        <div class="col-md-4 text-right"><b></b></div>
                                        <div class="col-md-4 text-right"><b></b></div>
                                        <div class="col-md-4 text-right"><b></b></div>
                                        <div class="col-md-4 text-right"><b></b></div>
                                        <div class="col-md-4 text-right"><b></b></div>
                                    </div> -->
                                    <!-- <div class="row" *ngIf="lawfirmSummaryData"
                                        [ngStyle]="{'margin-bottom': getBottomRow(i, tooltipData.length)}">
                                        <div class="col-md-8 text-left"></div>
                                        <div class="col-md-8 text-left"></div>
                                        <div class="col-md-4 text-right"></div>
                                        <div class="col-md-4 text-right"></div>
                                        <div class="col-md-4 text-right"></div>
                                        <div class="col-md-4 text-right"></div>
                                        <div class="col-md-4 text-right"></div>
                                    </div> -->
                                    <!-- <div style="position: absolute;bottom:3px;left:0;right:0">Click to open full
                                        outstanding invoice report</div> -->
                                </div>
                            </div>
                        </div>
                    </td>
                    <th class="w-38">
                        <div class="">
                            <div class="border-rounded-right"
                                [ngStyle]="{'background-color':  getBackgroundColor(i.type), 'width': getBarWidth(i.outstandingPercentage)}">
                                <span class="margin-left"> {{i.outstandingPercentage}}%</span>
                            </div>
                        </div>
                    </th>
                    <td class="w-7" *ngIf="isPracticeManager && activeTab !== '98'"></td>
                    <td class="w-2 text-center" *ngIf="!isPracticeManager">
                        <a *ngIf="isPracticeManager"
                            title="Add/Edit Law firm details"
                            class="clickable" (click)="onAddLawfirmProcessPrompt(i.clientId)">
                            <i class="fal fa-pencil" style="font-size: 14px !important;"></i>
                        </a>
                    </td>
                    <td class="w-2" *ngIf="!isPracticeManager && activeTab === '98'">
                        <a *ngIf="!isPracticeManager && (i.isLODDocumentSent === true)"
                            title="Upload Documents"
                            class="clickable" (click)="onViewCollectionDocument(i)">
                            <i class="fa fa-paperclip" style="font-size: 14px !important;"></i>
                        </a>
                        <a *ngIf="!isPracticeManager && (i.isLODDocumentSent === false) && activeTab === '98'"
                            title="Upload Documents"
                            class="clickable" (click)="onViewCollectionDocument(i)">
                            <i class="fa fa-plus-circle" style="font-size: 14px !important; color: #5cb85c !important;"></i>
                        </a>
                    </td>
                    <td class="w-7 text-right" *ngIf="!isPracticeManager && activeTab === '98'">
                        <div class="isInLegal">
                            <i
                              class="fa fa-toggle-on active"
                              id="in-legal"
                              *ngIf="IsInBarSociety === true && i.isReportedToBar === true"
                              (click)="onLODhange(i)"></i>
                            <i
                              class="fa fa-toggle-on fa-rotate-180 inactive"
                              id="in-legal"
                              *ngIf="IsInBarSociety === true && i.isReportedToBar === false"
                              (click)="onLODhange(i)"></i>
                            <i
                              class="fa fa-toggle-on fa-rotate-180 no-access"
                              title="Not linked to any Bar Society. Please contact Auxcon for assistance."
                              id="in-legal"
                              *ngIf="IsInBarSociety === false"></i>
                        </div>
                    </td>
                    <td class="w-7 text-right" *ngIf="isPracticeManager && activeTab === '98'">
                        <div class="isInLegal">
                            <i
                              class="fa fa-toggle-on active"
                              id="in-legal"
                              *ngIf="IsInBarSociety === true && i.isReportedToBar === true"
                              (click)="removeBarListing(i)"></i>
                            <i
                              class="fa fa-toggle-on fa-rotate-180 inactive"
                              id="in-legal"
                              *ngIf="IsInBarSociety === true && i.isReportedToBar === false"
                              (click)="onLODhange(i)"></i>
                            <i
                              class="fa fa-toggle-on fa-rotate-180 no-access"
                              title="Not linked to Bar Society, please contact Auxcon."
                              id="in-legal"
                              *ngIf="IsInBarSociety === false"></i>
                        </div>
                    </td>
                    <td class="w-3 text-right" *ngIf="isPracticeManager && activeTab === '98'">
                        <div class="isInLegal">
                            <i
                              class="fa fa-toggle-on active"
                              id="in-legal"
                              *ngIf="i.isLPC === true"
                              (click)="onLPCChange(i)"></i>
                            <i
                              class="fa fa-toggle-on fa-rotate-180 inactive"
                              id="in-legal"
                              *ngIf="i.isLPC === false"
                              (click)="onLPCChange(i)"></i>
                        </div>
                    </td>
                    <td class="w-3 text-right" *ngIf="isPracticeManager && activeTab === '98'">
                        <div class="isInLegal">
                            <i
                              class="fa fa-toggle-on active"
                              id="in-legal"
                              *ngIf="i.isFavourite === false"
                              (click)="onIsFavouriteChange(i)"></i>
                            <i
                              class="fa fa-toggle-on fa-rotate-180 inactive"
                              id="in-legal"
                              *ngIf="i.isFavourite === true"
                              (click)="onIsFavouriteChange(i)"></i>
                        </div>
                    </td>
                    <td class="w-3 text-right" *ngIf="isPracticeManager && activeTab === '98'">
                        <div class="isInLegal">
                            <i
                              class="fa fa-toggle-on active"
                              id="in-legal"
                              *ngIf="i.isInLegal === true"
                              (click)="onInLegalChange(i)"></i>
                            <i
                              class="fa fa-toggle-on fa-rotate-180 inactive"
                              id="in-legal"
                              *ngIf="i.isInLegal === false"
                              (click)="onInLegalChange(i)"></i>
                        </div>
                    </td>
                </tr>
                <tr class="table-light">
                    <th><u>Total</u></th>
                    <td ></td>
                    <td *ngIf="isPracticeManager"></td>
                    <td *ngIf="isPracticeManager"></td>
                    <td *ngIf="isPracticeManager"></td>
                    <td *ngIf="isPracticeManager"></td>
                    <td *ngIf="isPracticeManager"></td>
                    <th class="text-right">{{totalOutstanding  | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                    <td *ngIf="isPracticeManager && activeTab === '98'"></td>
                    <td colspan="3"><button
                         *ngIf="isPracticeManager"
                         style="float: right;"
                         class="btn btn-success"
                         (click)="onGenerateExcelReport()">Generate Excel</button></td>
                </tr>
            </tbody>
        </table>
    </div>

</div>

<app-add-law-firm-process
*ngIf="showAddLawfirmProcess"
 [isVisible]="showAddLawfirmProcess"
 [contactProcess]="contactProcess"
 [selectedLawfirm]="selectedLawfirm"
 (closeModal)="onAddLawfirmProcessClose()"></app-add-law-firm-process>

 <app-hand-over-to-lawfirm
    [isVisible]="onShowHandOverLawfirm"
    (cancel)="onShowHandOverLawfirmDismiss()"
    (continue)="onLegalChangeConfirm($event)"></app-hand-over-to-lawfirm>

<app-client-note-modal
    *ngIf="showInvoiceNoteDetail"
    [isVisible]="showInvoiceNoteDetail"
    [selectedTab]="activeTab"
    [notes]="notes"
    (closeModal)="closeInvoiceNoteModal($event)"
    (saveNote)="onClientNoteSave($event)">
</app-client-note-modal>
<app-note-detail-modal
    *ngIf="onShowNoteDetail"
    [selectedTab]="activeTab"
    [isVisible]="onShowNoteDetail"
    [instructionResponses]="instructionResponses"
    [objectType]="objectType"
    [_advocateChecked]="_advocateChecked"
    [selectedResponse]="selectedResponse"
    [selectedResponseText]="selectedResponseText"
    [noteDetailData]="noteDetailData"
    [lawfirmSummaryData]="selectedLawfirmSummaryData"
    [noteCategory]="noteCategory"
    (closemodal)="onShowNoteDetailModalDismiss()"
    (saveNote)="onNoteSave($event)"
    (issueInstruction)="onInstructionIssued($event)"
    (updateNote)="OnAddResolution($event)"></app-note-detail-modal>

    <app-prompt-unsaved-changes-modal
    *ngIf="showIsFavouriteStatusChangePrompt"
    title="Change Favourite Status"
    [message]="isFavouriteMessageText"
    [isVisible]="showIsFavouriteStatusChangePrompt"
    btnTextCancel="No, Cancel"
    btnTextContinue="Yes, Proceed"
    (cancel)="onHideIsFavouriteStatusChangePrompt()"
    (continue)="onIsFavouriteStatusChangeContinue()"></app-prompt-unsaved-changes-modal>

<app-collection-documents
    *ngIf="onShowCollectionDocumentList"
    [isVisible]="onShowCollectionDocumentList"
    [tabView]="documentTabView"
    [documentTypes]="documentTypes"
    [clientLetterOfDemandId]="clientLetterOfDemandId"
    [clientLetterOfDemand]="clientLetterOfDemand"
    [collectionsDocument]="collectionsDocument"
    (closeModal)="onHideCollectionDocumentList()"></app-collection-documents>

    <app-debtor-progress
    *ngIf="onShowDebtorProgressReport"
    [isVisible]="onShowDebtorProgressReport"
    (cancel)="onHideDebtorProgressReport()"
    [collectionDebtorProgressHandle]="collectionDebtorProgressHandle"></app-debtor-progress>

<app-prompt-unsaved-changes-modal
    *ngIf="onShowLPCDialog"
    title="LPC Complaint"
    [message]="lPComplaintMessage"
    [isVisible]="onShowLPCDialog"
    btnTextCancel="No, Cancel"
    btnTextContinue="Yes, Proceed"
    (cancel)="onHideLPCDialog()"
    (continue)="onLPCDialogContinue()"></app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal
    *ngIf="onShowReportToBarDialog"
    title="REPORT TO THE BAR SOCIETY"
    [message]="reportToBarMessage"
    [isVisible]="onShowReportToBarDialog"
    btnTextCancel="Cancel"
    btnTextContinue="Yes, Proceed"
    (cancel)="onHideReportToBarDialog()"
    (continue)="onReportToBarDialogContinue()"></app-prompt-unsaved-changes-modal>

<app-report-to-gcb-questions
    *ngIf="onShowReportToBarQuestions"
    [isVisible]="onShowReportToBarQuestions"
    [(reportToBarPaymentRequestedQuestion)]="reportToBarPaymentRequestedQuestion"
    [(reportToBarDisputeQuestion)]="reportToBarDisputeQuestion"
    (cancel)="onHideReportToBarQuestions()"
    (save)="onSaveReportToBarQuestions($event)"></app-report-to-gcb-questions>

<app-prompt-message-ok-confirm
    *ngIf="onShowLODDialog"
    title="LOD TO BE SEND TO FIRM"
    [message]="lodPromptMessage"
    [isVisible]="onShowLODDialog"
    btnTextConfirm="Upload"
    btnTextContinue="Yes, Proceed"
    (cancel)="onHideLODDialog($event)"
    (confirm)="onConfirmLODDialog('confirm')"
    (continue)="onLODDialogContinue()"></app-prompt-message-ok-confirm>

<app-prompt-unsaved-changes-modal
    *ngIf="showRemoveGCBListingChangePrompt"
    title="GCB Listing Removal"
    [message]="removeGCBListingMessage"
    [isVisible]="showRemoveGCBListingChangePrompt"
    btnTextCancel="No, Cancel"
    btnTextContinue="Yes, Proceed"
    (cancel)="onHideRemoveGCBListingChangePrompt()"
    (continue)="onRemoveGCBListingChangeContinue()"></app-prompt-unsaved-changes-modal>

<app-success-or-error
    *ngIf="showSuccessMessage"
    [isVisible]="showSuccessMessage"
    title=" "
    isSuccessMessage="true"
    [message]="successMessage"></app-success-or-error>

<app-prompt-unsaved-changes-modal
    *ngIf="onShowLodInstructionDialog"
    title="LOD INSTRUCTION HAS BEEN SENT TO AUXCON"
    [message]="LodInstructionPromptMessage"
    [isVisible]="onShowLodInstructionDialog"
    btnTextCancel="Cancel"
    btnTextContinue="OK"
    (cancel)="onHideLodInstructionDialog()"
    (continue)="onHideLodInstructionDialog()"></app-prompt-unsaved-changes-modal>