<div class="container-fluid pageWrapper p-0" *ngIf="subscriptionAddOnServices.length > 0">
    <h2 class="page-title">SUBSCRIPTIONS & BILLING</h2>
    <div class="tab-container">
        <div class="tab-buttons">
            <button [class.active]="activeTab === 0">Subscriptions</button>
            <!-- <button [class.active]="activeTab === 1">Partner Add-Ons</button>
            <button [class.active]="activeTab === 2">Billing</button> -->
        </div>
        <div class="tab-content" [style]="activeTab === 0 ? 'border-radius: 0px 10px 10px 10px !important' : ''">
            <div *ngIf="activeTab === 0">
                <div class="col-lg-12 p-0 mb-4">
                    <img src="../../../../assets/images/icons/check_mark.png" alt="check_mark" class="mr-3" width="40"
                        style="float: left;" />
                    <h5><strong>Auxcon Core Software Subscription</strong></h5>
                    <p style="font-size: 14px;">Your are subscribed to Auxcon Core Software at
                        R{{amountFormat(subscriptionPlanServices.Value)}} {{subscriptionPlanServices.Description1}}
                        {{subscriptionPlanServices.Description2}}</p>
                </div>
                <div class="col-lg-12 p-0">
                    <h4 class="mt-3"><strong>Add Auxcon Services (Optional)</strong></h4>
                    <div class="row p-0">
                        <div class="col-lg-12 subscription-plans mt-3">
                            <div *ngFor="let items of subscriptionAddOnServices">
                                <div
                                    class="card subscription-plan-card {{selectedServicesIds.includes(items.data.SubscriptionPlanID) ? 'selected':''}}">
                                    <div class="row p-0">
                                        <div class="col-lg-12 p-0">
                                            <div class="CardHeading">
                                                <div class="card-heading">
                                                    <h3>{{items.data.DisplayName}}</h3>
                                                </div>
                                                <div class="card-subHeading">
                                                    <p>
                                                        <span *ngIf="items.data.ValueType === 'Amount'">
                                                            {{items.data.Value ? "R" + amountFormat(items.data.Value) :
                                                            ""}}
                                                        </span>
                                                        <span *ngIf="items.data.ValueType === 'Percentage'">
                                                            {{amountFormat(items.data.Value)}}%
                                                        </span>
                                                        {{items.data.Description1}} {{items.data.Description2}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="text-center" style="font-size: 25px;">
                                                <i class="{{items.data.Icon}}"></i>
                                            </div>
                                            <div class="subscription-card-body pr-4"
                                                [innerHTML]="items.data.Description3">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style="bottom: 0;position: absolute;padding: 0px 35px 10px 35px">
                                        <div class="col-md-12 subscription-card-footer">
                                            <div class="d-flex justify-content-center">
                                                <label class="include-checkbox">
                                                    <input type="checkbox" [checked]="items.state.isSelected"
                                                        (click)="SelectedAddOnPlan(items.data, true)">
                                                    <div>
                                                        <svg class="include-check" viewBox="-2 -2 35 35"
                                                            aria-hidden="true">
                                                            <polyline points="7.57 15.87 12.62 21.07 23.43 9.93" />
                                                        </svg>
                                                    </div>
                                                    <div>Include</div>
                                                </label>
                                            </div>
                                            <a style="cursor: pointer;"
                                                (click)="onViewMoreInfo(items.data.SubscriptionPlanID)">View More
                                                Information</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row p-0 pt-5">
                    <div class="col-md-12 text-right">
                        <h4 style="font-weight: 600;">Total amount payable now : R{{ amountFormat(totalGrossAmount +
                            totalVATAmount) }}</h4>
                        <h5>R{{ totalGrossAmount > 0 ? amountFormat(totalGrossAmount) : '0.00' }} p/m + {{ VATPercentage
                            +
                            "%" }}
                            (R{{ totalVATAmount > 0 ? amountFormat(totalVATAmount) : '0.00' }})
                        </h5>
                    </div>
                </div>
                <div class="row p-0">
                    <div class="col-lg-6">
                        <button class="btn btn-danger RadiusedButton" (click)="CancelSubscription(true)">CANCEL
                            SUBSCRIPTION</button>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button class="btn btn-success RadiusedButton"
                            (click)="SaveSubscriptionDetails(true)">PROCEED</button>
                    </div>
                </div>
            </div>
            <div *ngIf="activeTab === 1">
                Partner Add-Ons
            </div>
            <div *ngIf="activeTab === 2">
                Billing
            </div>
        </div>
    </div>
    <div class="modal" *ngIf="isOnViewMoreInfo">
        <div class="modal-content" style="width: 830px;">
            <div class="modal-dialog">
                <div class="modal-header">
                    <button class="modal-dismiss" (click)="onViewMoreInfoDismiss()">
                        <i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i>
                    </button>
                    <h2>{{displayName}}</h2>
                </div>
                <div class="modal-body">
                    <div class="container-fluid" style="padding: 0px;">
                        <div class="row p-0">
                            <div class="col-md-8 col-md-8 p-0 pt-4 pb-4" style="border-right: 3px solid #fff;">
                                <div style="font-size: 15px;" [innerHTML]="viewMoreDescription"></div>
                            </div>
                            <div class="col-md-4 col-md-4 p-4" style="border-left: 3px solid #fff; font-size: 15px;">
                                <h4 style="text-align: center;"><strong>Contact Us</strong></h4>
                                <span>We're here to assist you and answer any questions you may have.</span>
                                <br><br><span>Please feel free to reach out to us using the following contact
                                    options:</span>
                                <br><br><span>Phone<br><b>0612314070</b></span>
                                <br><br><span>Email<br><b><a
                                            href="mailto:info@auxcon.co.za">info@auxcon.co.za</a></b></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" *ngIf="isShowCancelPopup">
        <div class="modal-content">
            <div class="modal-dialog">
                <div class="modal-header">
                    <button class="modal-dismiss" (click)="CancelSubscription(false)">
                        <i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i>
                    </button>
                    <h2>Cancel Subscription</h2>
                </div>
                <div class="modal-body">
                    <div class="container-fluid pb-3">
                        <div class="row">
                            <i class="fal fa-exclamation-triangle"></i>
                        </div>
                        <div class="message text-left" style="font-size: 15px;">
                            <span>We are sorry to learn that you have decided to cancel your Auxcon subscription. Your
                                satisfaction is important to us, and we regret not being able to meet your expectations
                                this time.</span>
                            <br /><br />
                            <span>Please contact our office to process your cancellation request:</span>
                            <br><br><span>Phone<br /><b>0612314070</b></span>
                            <br><br><span>Email<br /><b><a
                                        href="mailto:info@auxcon.co.za">info@auxcon.co.za</a></b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" *ngIf="isShowRedirectPopup">
        <div class="modal-content">
            <div class="modal-dialog">
                <div class="modal-header">
                    <h2>Please Wait...</h2>
                </div>
                <div class="modal-body">
                    <div class="container-fluid pb-3">
                        <div class="row">
                            <i class="fal fa-exclamation-triangle"></i>
                        </div>
                        <div class="message">
                            <span>Please wait while we redirect you to the PayFast payment gateway.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>