import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import {
  PracticeAgeAnalysis,
  PracticeAdvocateHealthService
} from '../../../services/practice-advocate-health/practice-advocate-health.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

@Component({
  selector: 'app-practice-age-analysis',
  templateUrl: './practice-age-analysis.component.html',
  styleUrls: ['./practice-age-analysis.component.scss']
})
export class PracticeAgeAnalysisComponent implements OnInit, OnChanges {

  isDataLoaded = false;
  agingCurrentTotal = 0;
  aging30Total = 0;
  aging60Total = 0;
  aging90Total = 0;
  aging98Total = 0;
  agingCurrentPercentage = 0;
  aging30Percentage = 0;
  aging60Percentage = 0;
  aging90Percentage = 0;
  aging98Percentage = 0;
  renderTooltipOverlay = true;
  data: any = [];
  totalOutstanding = 0;
  _currentTooltipData: any = [];
  _30TooltipData: any = [];
  _60TooltipData: any = [];
  _90TooltipData: any = [];
  _98TooltipData: any = [];
  _allTooltipData: any = [];
  apiData: any = [];

  @Input() practiceAgeAnalysis: PracticeAgeAnalysis;
  @Output() selectedCustomers: EventEmitter<string> = new EventEmitter<string>();

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private practiceAdvocateHealthService: PracticeAdvocateHealthService
  ) { } // end constructor()

  ngOnInit() {

  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (this.practiceAgeAnalysis) {
     this.getAgeAnalysisData();
      this.getTooltipData();
    }
  } // end ngOnChanges()

  getAgeAnalysisData() {
    this.agingCurrentPercentage = this.practiceAgeAnalysis.AgingCurrentPercentage;
    this.aging30Percentage = this.practiceAgeAnalysis.Aging30Percentage;
    this.aging60Percentage = this.practiceAgeAnalysis.Aging60Percentage;
    this.aging90Percentage = this.practiceAgeAnalysis.Aging90Percentage;
    this.aging98Percentage = this.practiceAgeAnalysis.Aging98Percentage;

    this.agingCurrentTotal = this.practiceAgeAnalysis.FrequencyTotals[0].AgingCurrentTotal;
    this.aging30Total = this.practiceAgeAnalysis.FrequencyTotals[0].Aging30Total;
    this.aging60Total = this.practiceAgeAnalysis.FrequencyTotals[0].Aging60Total;
    this.aging90Total = this.practiceAgeAnalysis.FrequencyTotals[0].Aging90Total;
    this.aging98Total = this.practiceAgeAnalysis.FrequencyTotals[0].Aging98Total;
    this.totalOutstanding = this.practiceAgeAnalysis.FrequencyTotals[0].TotalOutstanding;
  } // end getAgeAnalysisData()

  getTooltipData(): void {
    this.renderTooltipOverlay = false;
    this.practiceAdvocateHealthService.getPracticeAllCustomer().subscribe({ next: (response) => {
      this.apiData = response;

      this._currentTooltipData = this.setTooltipData('CurrentTotals');
      this._30TooltipData = this.setTooltipData('ThirtyDaysTotals');
      this._60TooltipData = this.setTooltipData('SixtyDaysTotals');
      this._90TooltipData = this.setTooltipData('NinetyDaysTotals');
      this._98TooltipData = this.setTooltipData('NinetyEightDaysTotals');
      this._allTooltipData = this.setTooltipData('AllTotals');
    },
      error: (error) => {
        // Error
      },
      complete: () => {
        this.loadingService.hideOverlay();
        this.renderTooltipOverlay = true;
      }
    });
  } // end getTooltipData()

  setTooltipData(key: string): any {
    this.apiData[key].sort((a, b) => b.Outstanding - a.Outstanding);
    if (this.apiData[key].length > 10) {
      return this.apiData[key].slice(0, 10);
    } else {
      return this.apiData[key];
    }
  }

  getBarTextColor(value: number): string {
    const height = (value * 150 / 100);
    if (height < 17) {
      return '#000';
    }
    return '#fff';
  } // end getBarTextColor()

  getBarHeight(value: number): string {
    return (value * 150 / 100) + 'px';
  } // end getBarHeight()

  getBarTextTopMargin(value: number): string {
    const height = (value * 150 / 100);
    if (height < 17) {
      return '-20px';
    }
    return ((height / 2) - 9) + 'px';
  } // end getTextTopMargin()

  getBottomRow(index: number, length: number): string {
    if (length > 0 && index === length - 1) {
      return '20px';
    }
    return '0px';
  } // end getBottomRow()

  receiveToolTipAge(tab: string) {
    this.selectedCustomers.emit(tab);
  } // end receiveToolTipAge()
} // end PracticeAgeAnalysisComponent{}
