import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AdvocateDetailStatement,
  ICrumb,
  AdvocateDetailStatementService,
  LawfirmPaidStatement} from '../../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { AllocatedToLawfirm, ReportsService } from '../../../../services/reports/reports.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../../services/messaging/snackbars/snackbars.service';
import { UserProfileService } from '../../../../services/user-profile/user-profile.service';
import {
  PATH_ATTORNEY_DASHBOARD,
  PATH_REPORTS,
  PATH_ATTORNEY_ALL_CUSTOMER,
  PATH_ADVOCATE_DETAIL,
  PATH_ATTORNEY_AGE_ANALYSIS} from '../../../../services/appdata/app-config.service';

@Component({
  selector: 'app-attorney-per-customer',
  templateUrl: './attorney-per-customer.component.html',
  styleUrls: ['./attorney-per-customer.component.scss']
})
export class AttorneyPerCustomerComponent implements OnInit, OnDestroy {

  private lawfirmId: number;
  private advocateId: number;
  advocateDetailStatement: AdvocateDetailStatement[];
  advocateDetail: AdvocateDetailStatement;
  crumbs: ICrumb[];
  showReportTable: boolean;
  generateButton = 'Generate Excel Statement';

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    LAWFIRM: 'Lawfirm'
  };

  allocatedToLawFirm: AllocatedToLawfirm[] = [];
  lawfirmPaidStatement: LawfirmPaidStatement[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private advocateDetailService: AdvocateDetailStatementService,
    private loadingService: LoadingService,
    private snackbarService: SnackbarsService,
    private reportService: ReportsService,
    private userProfileService: UserProfileService,
    private advocateDetailStatementService: AdvocateDetailStatementService
  ) { } // end constructor()

  ngOnInit() {
    // this.loadingService.showOverlay();
    this.advocateDetailStatementService.selectedFirmTab = 'all';
    this.lawfirmId = +this.route.snapshot.paramMap.get('id');
    this.advocateId = +this.route.snapshot.paramMap.get('advocateId');

    this.advocateDetailService.lawfirmId = this.lawfirmId;
    this.advocateDetailService.advocateId = this.advocateId;
    // if (this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM) {
      this.crumbs = [
        {
          label: 'Live Statement',
          link: PATH_ATTORNEY_DASHBOARD
        }
      ];
    // }
    // } else {
    //   this.crumbs = [
    //     {
    //       label: 'Live Statement',
    //       link: PATH_REPORTS
    //     }
    //   ];
    // }

    const snack: Snack = {
      label: 'loading Advocate Details..',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack).show();
    if (this.lawfirmId && this.advocateId) {
      this.getAllocatedToLawfirm();

      this.lawfirmPaidStatement = JSON.parse(localStorage.getItem('lawfirmPaidStatement')).filter(s => s.AdvocateId === this.advocateId);
      // Outstanding invoices
      this.advocateDetailStatement = JSON.parse(sessionStorage.getItem('advocateDetailStatement'))
      .filter(s => s.ServiceProviderId === this.advocateId);
      if (this.advocateDetailStatement) {

      } else {
        // this.advocateDetailStatement =  this.advocateDetailService
        // .getAdvocateDetailStatement(this.lawfirmId, this.advocateId).toPromise();
      }

      // this.advocateDetailService.getAdvocateDetailStatement(this.lawfirmId, this.advocateId)
      //   .subscribe(advocate_detail => {
      //     this.advocateDetailStatement = advocate_detail;
          // this.advocateDetailStatement.forEach(element => {
          //   this.crumbs.push({
          //     label: element.ProviderFullName,
          //     link: PATH_ADVOCATE_DETAIL
          //   });
          // });
          // if (id) {
      this.advocateDetail = this.advocateDetailStatement.filter(e => e.ServiceProviderId === this.advocateId)[0];
          this.crumbs.push(
            {
              label: 'Age Analysis',
              link: PATH_ATTORNEY_AGE_ANALYSIS
            },
            {
            label: 'All Advocates Report',
            link: PATH_ATTORNEY_ALL_CUSTOMER
          });
          this.crumbs.push({
            label: this.advocateDetail.ProviderFullName
          });
          // this.advocateDetailService.activeTab = 'view-all';
          // this.showReportTable = false;
          // this.loadingService.hideOverlay();
        // },
        //   error => {
        //     // On Error
        //     snack.label = 'Error Loading Advocate detail.';
        //     snack.type = SnackType.ERROR;
        //     this.snackbarService.dismiss().make(snack).show();
        //     this.loadingService.hideOverlay();
        //   },
        //   () => {
            // on complete
          //   this.loadingService.hideOverlay();
          // });
    // } else {
    //   this.showReportTable = true;
    //   this.crumbs.push({
    //     label: 'Advocate statement'
    //   });
    }
    // this.loadingService.hideOverlay();
  } // end ngOnInit()

  getAllocatedToLawfirm() {
    this.allocatedToLawFirm = [];
    if (this.advocateId) {
      this.advocateDetailService.getAllocated_To_Lawfirm(this.advocateId).subscribe({ next: (data) => {
        // On next
        this.allocatedToLawFirm = data;
      }});
    }
  } // end getAllocatedToLawfirm()

  getAdvocateStatement(advocateNo) {
    this.loadingService.showOverlay();
    this.advocateDetailService.lawfirmId = this.lawfirmId;
    this.advocateDetailService.advocateId = advocateNo;
    if (this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM) {
      this.crumbs = [
        {
          label: 'Analytics',
          link: PATH_ATTORNEY_DASHBOARD
        }
      ];
    } else {
      this.crumbs = [
        {
          label: 'Analytics',
          link: PATH_REPORTS
        }
      ];
    }

    const snack: Snack = {
      label: 'loading Advocate Details..',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack).show();
    if (this.lawfirmId && advocateNo) {
      // Bank transaction and paid invoices
      this.lawfirmPaidStatement = JSON.parse(localStorage.getItem('lawfirmPaidStatement')).filter(s => s.AdvocateId === advocateNo);
      this.advocateDetailStatement = JSON.parse(sessionStorage.getItem('advocateDetailStatement'))
        .filter(s => s.ServiceProviderId === advocateNo);
      // this.advocateDetailService.getAdvocateDetailStatement(this.lawfirmId, advocateNo)
      //   .subscribe(advocate_detail => {
      //     this.advocateDetailStatement = advocate_detail.filter(paid => paid.Due > 0);
          // this.advocateDetailStatement.forEach(element => {
          //   this.crumbs.push({
          //     label: element.providerFullName,
          //     link: PATH_ADVOCATE_DETAIL
          //   });
          // });
          // if (id) {
    this.advocateDetail = this.advocateDetailStatement.filter(e => e.ServiceProviderId === advocateNo)[0];
      this.crumbs.push({
        label: 'Advocate statement',
        link: PATH_ADVOCATE_DETAIL
      });
      this.crumbs.push({
        label: this.advocateDetail.ProviderFullName
      });
          // this.advocateDetailService.activeTab = 'view-all';
        //   this.showReportTable = false;
        //   this.loadingService.hideOverlay();
        // },
          // error => {
          //   // On Error
          //   snack.label = 'Error Loading Advocate detail.';
          //   snack.type = SnackType.ERROR;
          //   this.snackbarService.dismiss().make(snack).show();
          //   this.loadingService.hideOverlay();
          // },
          // () => {
            // on complete
            // });
    } else {
      this.showReportTable = true;
      this.crumbs.push({
        label: 'Advocate statement'
      });
    }
    this.loadingService.hideOverlay();
  } // end getAdvocateStatement()

  ngOnDestroy() {
    this.reportService.selectedReport = 'liveStatement';
  } // end ngOnDestroy()
} // end AttorneyPerCustomer{}
