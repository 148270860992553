<div class="row  widget3-header widget3-header1 text-white">
    <div class="col-11 header-text">Tax Liability
        <i class="col-1 far fa-info-circle tooltipp" style="font-size: 18px; float: right; padding-right: 5px;">
            <div class="bottom" style="text-align:start;">
                <h3 style="font-size: 1.1rem !important;"><strong>Disclaimer:</strong></h3>
                <p>The Tax Liability information contained on this site is provided for awareness purposes only, is dependent on your input and does not constitute a formal tax calculation that can be used to complete your tax returns. You should not rely upon the information that has been provided as a basis for making any business, legal or any other decisions</p>
                <i></i>
            </div>
        </i>
    </div>
    <!-- <div class="col-1 fa-icon"><i title="Healthy" class="fal fa-badge-check"></i></div> -->
</div>
<div class="row widget3-body" style="width: 100% !important;margin: 0 !important;">
    <div class="invoiced-collected-container">
        <div class="well p-0" style="background-color:#f5f6f6 !important; height: 0;">
            <div class="row p-0 m-0">
                <div id="TaxLiabilityChart"></div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-3 text-right">
                    <div
                        style="display: inline-block; height: 15px; width: 15px; background: #5e97d8; border-radius: 2px;">
                    </div>
                    <span class="ml-2" style="vertical-align: top;">VAT</span>
                </div>
                <div class="col-md-3 text-center">
                    <div
                        style="display: inline-block; height: 15px; width: 15px; background: #8abd37; border-radius: 2px;">
                    </div>
                    <span class="ml-2" style="vertical-align: top;">Provisional Tax</span>
                </div>
                <div class="col-md-3">
                    <div
                        style="display: inline-block; height: 15px; width: 15px; background: #eb9e02; border-radius: 2px;">
                    </div>
                    <span class="ml-2" style="vertical-align: top;">PTD</span>
                </div>
            </div>
            <div class="row col-md-2 offset-md-10 text-right">
                <u>View more ></u>
            </div>
        </div>
    </div>
</div>