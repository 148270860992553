export const environment = {
  production: true,
  // For Msal Local
  // redirectUrl: 'http://localhost:4200/',
  redirectUrl: 'https://web.auxconlegal.com/',  // Live
  apiBaseUrl: 'https://api.auxconlegal.com/api', // Live
  outlookCalendarRedirectURL: 'https://web.auxconlegal.com/outlook-handler',

  clientId: '69e4f833-2491-4767-848c-6904199aab11',
  tenantId: '0efd385a-0222-4a23-908a-d3ece8d29f8e',
  tenantName: 'rosemarieauxconco.onmicrosoft.com',  
  office365ClientId: 'c9c13ef1-0eb4-49b6-b0e4-b21a9605dbfd'
};
