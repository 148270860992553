import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import {
  DEFAULT_INVITE_USERS_STAGING,
  EntityContacts,
  InviteService,
  InviteUsers_Staging,
} from '../../../services/invite/invite.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { delay } from 'rxjs';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  title = 'Invite user to Auxcon';

  onSuccessShow: boolean = false;
  isSuccessMessage: boolean;
  messageTitle: string;
  inviteMessage: string;

  entityTypes: any[] = [];
  userTypes: any[] = [];
  selectedEntity = '';
  inviteUsers_Staging: InviteUsers_Staging = DEFAULT_INVITE_USERS_STAGING;
  entityContact: EntityContacts[] = [];

  @Input() isVisible: boolean;
  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private util: UtilitiesService,
    private inviteService: InviteService,
    private loadingService: LoadingService
  ) { } // end constructor {}

  get canSendInvite(): boolean {
    // console.log('DATA FILLING: ', this.inviteUsers_Staging);
    return Boolean(this.util.hasTextValue(this.inviteUsers_Staging.EntityType)) &&
      //  Boolean(this.util.hasTextValue(this.inviteUsers_Staging.EntityReference)) &&
      Boolean(this.util.isValidEmail(this.inviteUsers_Staging.EmailAddress));
  } // end canSendInvite()

  ngOnInit(): void {
    this.inviteUsers_Staging = {
      EmailAddress: '',
      AccountType: '',
      TenantID: '',
      Domain: '',
      EntityReference: '',
      EntityType: '',
      UserFirstName: '',
      UserLastName: '',
      UserType: '',
      Status: '',
      StatusError: '',
      LastModifiedOn: '',
      IsClosed: false
    };
    // console.log('ON OPENING DATA: ', this.inviteUsers_Staging);
    this.entityTypes = [
      { id: 1, name: 'Regional Bar' },
      { id: 2, name: 'GCB' },
      { id: 3, name: 'Ad-hoc' }
    ];

    this.userTypes = [
      { id: 1, name: 'Advocate', value: 'Ad-Hoc Advocate' },
      { id: 2, name: 'Consumer', value: 'Ad-Hoc Consumer' }
    ]
  } // end ngOnInit()

  dismiss() {
    this.selectedEntity = '';
    this.entityTypes = [];
    this.entityContact = [];
    this.inviteUsers_Staging = {
      EmailAddress: '',
      AccountType: '',
      TenantID: '',
      Domain: '',
      EntityReference: '',
      EntityType: '',
      UserFirstName: '',
      UserLastName: '',
      UserType: '',
      Status: '',
      StatusError: '',
      LastModifiedOn: '',
      IsClosed: false
    };
    this.closeModal.emit('close-invite');

  } // end dismiss()

  onSelectedEntityType(event) {
    this.inviteUsers_Staging = this.util.objectCopy(DEFAULT_INVITE_USERS_STAGING);
    this.entityContact = [];
    this.loadingService.showOverlay();
    this.selectedEntity = event.target.value;
    this.inviteUsers_Staging.EntityType = event.target.value;

    if (event.target.value === 'Regional Bar') {


      // get regional bar 
      this.inviteService.getEntity(18).subscribe({
        next: (data) => {
          // On next
          let res = [];
          data.forEach(element => {
            if (element.FullName != "Other") {
              res.push(element);
            }
          });
          this.entityContact = res;
          this.entityContact.sort((a, b) => {
            return a.FullName.toUpperCase().localeCompare(b.FullName.toUpperCase());
          });
        },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
        }
      });

    } else if (event.target.value === 'GCB') {

      this.inviteService.getEntity(24).subscribe({
        next: (data) => {
          // On next
          this.entityContact = data;
        },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
        }
      });

    } else if (event.target.value === 'Ad-hoc') {
      this.loadingService.hideOverlay();
    } else {
      this.loadingService.hideOverlay();
    }
  } // end onSelectedEntityType()

  onSelectedEntity(event) {
  } // end onSelectedEntity()

  onSendInvite() {
    if (this.selectedEntity === 'GCB') {
      this.inviteUsers_Staging.EntityReference = this.entityContact[0].ContactID.toString();
    } else if (this.selectedEntity === 'Regional Bar') {
      let EntityName = this.inviteUsers_Staging.EntityReference;
      this.inviteUsers_Staging.EntityReference = this.entityContact.filter(x => x.FullName == EntityName)[0].ContactID.toString()
    }
    this.loadingService.showOverlay();

    this.inviteService.sendInvite(this.inviteUsers_Staging)
      .subscribe({
        next: (data) => {
          if (data) {
            this.messageTitle = data.isError === true ? "Warning" : "Success";
            this.inviteMessage = data.ErrorMessage;
            this.onSuccessShow = true;
            this.isSuccessMessage = data.isError === true ? false : true;
          }
        },
        error: (error) => {
          this.loadingService.hideOverlay();
        },
        complete: async () => {
          this.loadingService.hideOverlay();
          setTimeout(
            (del) => {
              this.inviteUsers_Staging = DEFAULT_INVITE_USERS_STAGING;
              this.onSuccessShow = false;
              this.closeModal.emit('close-invite');
            }, 5000
          );
        }
      });
  } // end onSendInvite()

  onSuccessMessageHide() {
    this.onSuccessShow = false;
  } // end onSuccessHide()
} // end InviteComponent {}
