import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvocateDetailStatement,
         AdvocateDetailStatementService, 
         DEFAULT_DISPUTE_STATUSES, 
         DisputeStatuses} from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-invoice-dispute',
  templateUrl: './invoice-dispute.component.html',
  styleUrls: ['./invoice-dispute.component.scss']
})
export class InvoiceDisputeComponent implements OnInit {
  title: string = 'Invoice Queries & Dispute';
  invoiceLineDisputeList: any;
  invoiceDisputeStatus: string = '';
  invoiceStatusNote: string = '';
  wordingDisputStatues: DisputeStatuses = Object.assign({}, DEFAULT_DISPUTE_STATUSES);

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.disputStatues, this.wordingDisputStatues);
  } // end isDirty()

  @Input() isVisible: boolean;
  @Input() disputStatues: DisputeStatuses;
  @Input() advocateDetailStatement: AdvocateDetailStatement;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatedStatus: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private advocateDetailService: AdvocateDetailStatementService
  ) { } // end constructor()

  ngOnInit(): void {
    this.wordingDisputStatues = Object.assign({}, DEFAULT_DISPUTE_STATUSES);
    this.invoiceLineDisputeList = [
      { label: 'Amend reference number ', value: 'Amend reference number ' },
      { label: 'Change Attorney', value: 'Change Attorney' },
      { label: 'Invoice not drafted in accordance with the State Attorney invoice template', value: 'Invoice not drafted in accordance with the State Attorney invoice template' },
      { label: 'Removal request of some items on the invoice ', value: 'Removal request of some items on the invoice ' },
      { label: 'Require updated CSD report ', value: 'Require updated CSD report ' },
    ];
    this.wordingDisputStatues = Object.assign({}, this.disputStatues);
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onInvoiceDispute() {
    this.loadingService.showOverlay();
    let updatedId = 0
    // this.disputStatues = Object.assign({}, DEFAULT_DISPUTE_STATUSES);
    // this.disputStatues.Source = "Invoice";
    // this.disputStatues.SourceId = this.advocateDetailStatement.InvoiceId;
    // this.disputStatues.Status = this.invoiceDisputeStatus;
    // this.disputStatues.Note = this.invoiceStatusNote;
    this.advocateDetailService.updateDisputeStatus(this.disputStatues).subscribe({ next: (next) => {
      // On next
      this.disputStatues.Id = next.Id;
    },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.advocateDetailStatement.DisputeNoteStatus = this.disputStatues.Note;
        this.advocateDetailStatement.DisputeStatus = this.disputStatues.Status;
        this.advocateDetailStatement.InvoiceStatus = this.disputStatues.Status;
        this.advocateDetailStatement.DisputStatusId = this.disputStatues.Id;
        console.log('After saving data: ', this.advocateDetailStatement);
        this.updatedStatus.emit(this.advocateDetailStatement);
      }
    });
  } // end onInvoiceDispute()

} // end InvoiceDisputeComponent()
