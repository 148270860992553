<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div class="container-fluid">
    <!-- <h2 class="page-title">RECONCILE BANK TRANSACTIONS</h2> -->
    <div class="reconcile-body row">
        <div class="left-block col-sm-6">
            <div class="block-title">
                <nav class="bank-nav">
                    <a class="tab" *ngIf="profile.serviceProviderID !== 29723" ng-disabled="!newBankTransactionClick" [class.active]="transactionsType === 'newBankTransactions'"
                        (click)="togglePanel('newBankTransactions')">New Bank Transactions<i *ngIf="count != 0"
                            class="fas fa-circle"><b>{{count}}</b></i></a>
                    <a class="tab" [class.active]="transactionsType === 'processedBankTransactions'"
                        (click)="togglePanel('processedBankTransactions')">Processed Bank Transactions</a>
                </nav>
            </div>

            <app-new-bank-transactions
                *ngIf="transactionsType == 'newBankTransactions'"
                [(bankTransactionsHandle)]="bankTransactionsHandle"
                [(newBankTransactions)]="newBankTransactions"
                [(workingNewBankTransactions)]="workingNewBankTransactions"
                (promptInvoicesEvent)="showInvoices($event)"
                (tranCount)="onTranCount($event)"
                (selectedTransaction)="getPossibleMatches($event)"
                (onShow)="getNewBankTransactions()"></app-new-bank-transactions>
            <app-processed-bank-transactions
                *ngIf="transactionsType == 'processedBankTransactions'"
                (refreshPage)="onadded($event)"
                (promptInvoicesEvent)="showProcessedInvoices($event)"
                [onShow]="refresInvoices">
            </app-processed-bank-transactions>

        </div>
        <div class="right-block col-sm-6">
            <app-invoices-added-bank-transaction
                *ngIf="transactionsType == 'newBankTransactions'"
                [(selectedBankTransaction)] ="selectedBankTransaction"
                [isAttorney]="isAttorney"
                [isLawfirm]="isLawfirm"
                [onShow]="promptInvoices"
                (refreshPage)="onadded($event)">
            </app-invoices-added-bank-transaction>
            <app-invoices-processed-bank-transaction
                *ngIf="transactionsType == 'processedBankTransactions'"
                [selectedTransaction]="selectedTransaction"
                [onShow]="promptProcessedInvoices"
                (refreshPage)="onProcessed($event)">
            </app-invoices-processed-bank-transaction>
        </div>
    </div>
    <!-- <div class="row m-0 reconcile-footer">
        <div class="col-sm-4 p-0 pr-10"><button class="form-control btn btn-danger">CANCEL</button></div>
        <div class="col-sm-4 p-0 pl-5 pr-5"><button class="form-control btn btn-primary">SAVE & CLOSE</button></div>
        <div class="col-sm-4 p-0 pl-10"><button class="form-control btn btn-success"
                (click)="onClick('send-bank-statements')">SEND SATEMENTS</button></div>
    </div> -->

    <div class="well footer-buttons">
        <button
            class="btn btn-danger"
            (click)="onCancelClicked()">CANCEL</button>
            <!-- <button
            class="btn btn-primary">SAVE & CLOSE</button> -->
            <button
            class="btn btn-success"
            (click)="sendStatementToClient()">SEND STATEMENT</button>
    </div> <!-- .well .footer-buttons -->

</div>

<app-prompt-unsaved-changes-modal 
    title="Unresolved Payment indication(s)"
    message="You have unresolved Indication(s). Do you wish to proceed with this statement run or resolve the indication(s)?"
    [isVisible]="showErrorMessagePrompt"
    btnTextContinue="Proceed"
    btnTextCancel="Resolve"
    (cancel)="onHideErrorMessagePrompt()"
    (continue)="onClick('send-bank-statements')"></app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal 
    title="Revised transaction(s)"
    message="You have revised transactions linked to invoice(s), if you proceed the invoice(s) will be unlinked from the transactions. Do you wish to proceed?"
    [isVisible]="showRevisedPrompt"
    btnTextContinue="Proceed"
    btnTextCancel="Resolve"
    (cancel)="onHideRevisedPrompt()"
    (continue)="onClick('send-bank-statements')"></app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal 
    *ngIf="showUnsavedChangesPrompt"
    message="You have unsaved changes. Are you sure you want to leave the page?"
    [isVisible]="showUnsavedChangesPrompt" 
    (cancel)="onHideUnsavedChangesPrompt()"
    (continue)="leavePage()"></app-prompt-unsaved-changes-modal>