import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import {
    ProcessPaymentService,
    BankTransaction,
    ProcessedTransactionLine,
    TransactionLinesAdded,
    DEFAULT_TRANSACTIONS_LINE_ADDED,
    SendStatement,
    DEFAULT_SEND_STATEMENT,
    ManualBankTransaction,
    DEFAULT_BANK_TRANSACTION} from '../../../services/process-payment/process-payment.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { AddedInvoiceToTransaction } from '../invoices-added-bank-transaction/invoices-added-bank-transaction.component';
// import { } from 'q';
import { ContactsService } from '../../../services/contacts/contacts.service';
import { PATH_SEND_STATEMENT } from '../../../services/appdata/app-config.service';
import { Router } from '@angular/router';
import {
    DEFAULT_ACTIVITY_LOGS,
    ActivityLogs,
    ActivityLogsService
} from '../../../services/activity-logs/activity-logs.service';
import { lastValueFrom } from 'rxjs';
import { FaStackComponent } from '@fortawesome/angular-fontawesome';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { ApiService } from '../../../services/api.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { BankAccounts, BankAccountsService, DEFAULT_BANK_ACCOUNT } from '../../../services/bank-accounts/bank-accounts.service';
import { firstValueFrom } from 'rxjs';

export interface BankTransactionHandle {
    data: BankTransaction;
    state: {
        isVisible: boolean;
        isSelected: boolean;
        isOpen: boolean;
    };
}

export interface BankAccountHandle {
    data: BankAccounts;
    state: {
      isVisible: boolean;
      isSelected: boolean;
      isOpen: boolean;
    };
  }

@Component({
    selector: 'app-new-bank-transactions',
    templateUrl: './new-bank-transactions.component.html',
    styleUrls: ['./new-bank-transactions.component.scss']
})
export class NewBankTransactionsComponent implements OnInit, OnChanges {

    searchTerm: any = '';
    bankTransactions: BankTransaction[];
    // bankTransactionsHandle: BankTransactionHandle[] = [];
    selectedBankTransactionLines: any = [];
    _toProcessTransactionLines: ProcessedTransactionLine[] = [];
    showTransactions = true;
    promptAllocateModel = false;
    allocatedType = '';
    allocatedTitle = '';
    selectAll: boolean;
    showTransactionsErrorPrompt: boolean;
    showDeleteActions: boolean;
    showProofOfPaymentDoc = false;
    popId: number;
    lastStatementDate: SendStatement;
    count = 0;
    showSendStatementPrompt: boolean;

    bankTransactionSelected: BankTransaction[] = [];
    bankTransactionSelectedID: BankTransaction[] = [];
    editTransaction: BankTransaction = Object.assign({}, DEFAULT_BANK_TRANSACTION);
    onShoEditTransactionModal: boolean;
    title: string;
    // showActions = false;
    row = 0;
    sortBy: string;
    sortAscending: boolean;
    previousSortBy: string;

    sortDate: boolean;
    sortDescription: boolean;
    sortAmount: boolean;
    sortAllocated: boolean;
    sortAccountNumber: boolean;
    lawFirms: any = [];
    showErrorMessagePrompt: boolean;

    // for Child component:
    addedInvoiceToTransaction: AddedInvoiceToTransaction[];
    _selectedInvoice: TransactionLinesAdded = Object.assign({}, DEFAULT_TRANSACTIONS_LINE_ADDED);

    // Activity logs
    activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

    
    postNewBankTransactions: ManualBankTransaction[];

    bankAccountHandle: BankAccountHandle[] = [];
    selectedBankAccountHandle: BankAccounts = Object.assign({}, DEFAULT_BANK_ACCOUNT);

    onShowBankAccountModal: boolean;

    @Input() newBankTransactions: BankTransaction[];
    @Input() workingNewBankTransactions: BankTransaction[];
    @Input() bankTransactionsHandle: BankTransactionHandle[];
    @Output() promptInvoicesEvent = new EventEmitter<any>();
    @Output() onShow: EventEmitter<any> = new EventEmitter<any>();
    @Output() tranCount: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedTransaction: EventEmitter<number> = new EventEmitter<number>();
    @ViewChild('inputCheckbox') inputCheckbox: ElementRef;

    get currencySymbol(): string {
        if (this.userProfileService.userProfile.isImpersonator) {
            return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
        } else {
            return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
        }
    } // end currencySymbol()

    get accountNo(): string {
        if (this.userProfileService.userProfile.isImpersonator) {
            return this.userProfileService.selectedUserProfile.bankDetails.accountNumber;
        } else {
            return this.userProfileService.userProfile.bankDetails.accountNumber;
        }
    } // end accountNo()

    get isManualTransactions(): boolean {
        if(this.userProfileService.userProfile.isImpersonator) {
            return Boolean(this.userProfileService.selectedUserProfile.settings.manualTransaction);
        } else {
            return Boolean(this.userProfileService.userProfile.settings.manualTransaction);
        }
    } // end isManualTransactions()

    get canSaveManualTransaction(): boolean {
        return Boolean(this.workingNewBankTransactions.length > 0) &&
        !Boolean(this.workingNewBankTransactions && this.workingNewBankTransactions.find(x => x.TransactionDescription.length === 0)) &&
        !Boolean(this.workingNewBankTransactions && this.workingNewBankTransactions.find(x => x.Amount === 0));
    } // end canSaveManualTransaction()

    get showActions(): boolean {
        return Boolean(this.bankTransactionsHandle.find(trans => trans.state.isSelected === true));
    } // end showActions()

    constructor(
        private router: Router,
        private api: ApiService,
        private util: UtilitiesService,
        private cpipe: UsercurrencyPipe,
        private loadingService: LoadingService,
        private contactService: ContactsService,
        private snackbarsService: SnackbarsService,
        private userProfileService: UserProfileService,
        private activityLogService: ActivityLogsService,
        private bankAccountsService: BankAccountsService,
        private processPaymentService: ProcessPaymentService,
        ) {
    } // end constructor()

    ngOnInit() {     
        if(this.isManualTransactions) {
            this.getBankAccounts();
        }   
        // let resp = '';
        // this.onShow.subscribe((response) => {
        //     if (response) {
        //         resp = response;
        //         this.getNewBankTransactions(this.searchTerm);
        //         this.promptInvoicesEvent.emit();
        //         this.row = 0;

        //     }
        // });
        // // this.loadingService.showOverlay();
        // if (resp !== null) {
        //     this.getNewBankTransactions(this.searchTerm);
        // }
    } // end ngOnInit()

    onHideBankAccountModal(event: BankAccounts) {        
        this.onShowBankAccountModal = false
    } // end onHideBankAccountModal()

    onSelectedBankAccountModal(event) {
        this.selectedBankAccountHandle = event;
        this.workingNewBankTransactions[0].AccountNo = this.selectedBankAccountHandle.AccountNumber;
        this.onShowBankAccountModal = false
    } // end onSelectedBankAccountModal()

    getBankAccounts() {
        // this.loadingService.showOverlay();
        this.bankAccountHandle = [];
        this.bankAccountsService.getBankAccounts().subscribe({ next: (accounts) => {
          accounts.forEach(acc => {
            this.bankAccountHandle.push({
              data: acc,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          });
        },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
         complete: () => {
          // On complete
          this.bankAccountHandle.sort((a, b) => {
            return a.data.SPAccType.toUpperCase().localeCompare(b.data.SPAccType.toUpperCase());
          });
          this.loadingService.hideOverlay();
        }
      });
      } // end getBankAccounts(0)

    onHideErrorMessagePrompt() {
        this.showErrorMessagePrompt = false;
    } // end onHideErrorMessagePrompt()

    ngOnChanges(changes: SimpleChanges) {
        // if (changes.bankTransactionsHandle && changes.bankTransactionsHandle.previousValue) {
        //     this.bankTransactionsHandle.forEach(bank => {
        //         if (bank.state.isSelected) {
        //             this.showActions = true;
        //         }
        //     });
        // }
    } // end ngOnChanges()

    toggleSort(sortBy: string) {
        this.sortBy = sortBy;
        if (this.sortBy === this.previousSortBy) {
            this.sortAscending = !this.sortAscending;
        }

        switch (this.sortBy) {
            case 'date':
                this.bankTransactionsHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortAccountNumber = false;
                        this.sortDate = true;
                        this.sortDescription = false;
                        this.sortAmount = false;
                        this.sortAllocated = false;
                        return a.data.Date.toUpperCase().localeCompare(b.data.Date.toUpperCase());
                    }
                    this.sortDate = false;
                    return b.data.Date.toUpperCase().localeCompare(a.data.Date.toUpperCase());
                });
            break;
            case 'account-number':
                this.bankTransactionsHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortAccountNumber = true;
                        this.sortDate = false;
                        this.sortDescription = false;
                        this.sortAmount = false;
                        this.sortAllocated = false;
                        return a.data.AccountNo.toUpperCase().localeCompare(b.data.AccountNo.toUpperCase());
                    }
                    this.sortAccountNumber = false;
                    return b.data.AccountNo.toUpperCase().localeCompare(a.data.AccountNo.toUpperCase());
                });
            break;
            case 'description':
                this.bankTransactionsHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortAccountNumber = false;
                        this.sortDate = false;
                        this.sortDescription = true;
                        this.sortAmount = false;
                        this.sortAllocated = false;
                        return a.data.TransactionDescription.toUpperCase().localeCompare(b.data.TransactionDescription.toUpperCase());
                    }
                    this.sortDescription = false;
                    return b.data.TransactionDescription.toUpperCase().localeCompare(a.data.TransactionDescription.toUpperCase());
                });
            break;
            case 'amount':
                this.bankTransactionsHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortAccountNumber = false;
                        this.sortDate = false;
                        this.sortDescription = false;
                        this.sortAmount = true;
                        this.sortAllocated = false;
                        return a.data.Amount - b.data.Amount;
                    }
                    this.sortAmount = false;
                    return b.data.Amount - a.data.Amount;
                });
            break;
            case 'allocated':
                this.bankTransactionsHandle.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortAccountNumber = false;
                        this.sortDate = false;
                        this.sortDescription = false;
                        this.sortAmount = false;
                        this.sortAllocated = true;
                        return a.data.Allocated.toUpperCase().localeCompare(b.data.Allocated.toUpperCase());
                    }
                    this.sortAllocated = false;
                    return b.data.Allocated.toUpperCase().localeCompare(a.data.Allocated.toUpperCase());
                });
            break;
        }
        this.previousSortBy = this.sortBy;
    } // end toggleSort()


    // getNewBankTransactions(data: any) {
    //     this.loadingService.showOverlay();
    //      this.processPaymentService.getBankTransactions(data).subscribe(transactions => {
    //         // On next
    //         this.bankTransactions = transactions;
    //         if (this.bankTransactions != null) {
    //             this.showTransactions = true;
    //             this.bankTransactions = this.bankTransactions.filter(trans => trans.IsDuplicate === false);
    //             this.tranCount.emit(this.bankTransactions.length);
    //         } else {
    //             this.showTransactions = false;
    //             this.tranCount.emit(0);
    //         }
    //     },
    //     error => {
    //         // On error
    //         this.loadingService.hideOverlay();
    //     },
    //     () => {
    //         // On complete
    //         this.initBankTransactions();
    //     });
    // } // end getNewBankTransactions()

    initBankTransactions() {
        this.bankTransactionsHandle = [];
        if (this.bankTransactions) {
            this.bankTransactions.forEach(transaction => {
                if (transaction.BankTransactionLines && transaction.BankTransactionLines.length > 0) {
                    //  this.processPaymentService.putBankTransactions(transaction.BankTransactionLines).toPromise();
                }
                this.bankTransactionsHandle.push({
                    data: transaction,
                    state: {
                        isVisible: true,
                        isSelected: false,
                        isOpen: false
                    }
                });
            });
        }
        // this.loadingService.hideOverlay();
    } // end initBankTransactions()

    saveTransactions() {
        let _postNewBankTransactions: any = new Array()
        this.loadingService.showOverlay();
        this.workingNewBankTransactions.forEach(a => {
            let bankTransaction = {
                Id: 0,
                DatePosted: moment(a.Date).format('YYYY-MM-DD HH:mm:ss'),
                Name: a.TransactionDescription,
                Amount: a.Amount,
                BankStatementId: 0,
                FitId: a.FitId,
                CheckNum: 0,
                Type: a.Type,
                Processed: a.Processed,
                Reason: a.Reason,
                PersonalFlag: false, 
                ExcludedFlag: false,
                ReferredFlag: false,
                DuplicateTransactionId: 0,
                PossibleCancelledCheque: false,
                HasProofOfPayment: a.HasProofOfPayment,
                POPDocumentPath: a.POPDocumentPath,
                IsRevised: a.IsReferred,
                AdHocCollection: false,
                Category: a.AccountNo,
            };
            _postNewBankTransactions.push(bankTransaction);
        });
        this.processPaymentService.postManualTransactions(_postNewBankTransactions).subscribe({ next: (onnext) => {
            console.log('SAVED MANUAL BANK TRANSACTIONS: ', onnext);
        },
        error: (error) => {
            console.log('ERROR SAVING MANUAL BANK TRANSACTIONS: ', error);
            this.loadingService.hideOverlay();
        },
        complete: () => {
            this.newBankTransactions = this.util.objectCopy(this.workingNewBankTransactions)
            location.reload();
            this.loadingService.hideOverlay();
        }
    })
    } // end saveTransactions()

    addAnotherTransaction() {   
        // console.log('CHECK ONE TWO THREE: ', this.bankAccountHandle);
        let accountNumber = this.accountNo;
        if (!this.workingNewBankTransactions) {
            this.workingNewBankTransactions = [];
            this.onShowBankAccountModal = true;
        }
         if(this.workingNewBankTransactions.length === 0 && this.bankAccountHandle.length > 1){
            this.onShowBankAccountModal = true;
         }

         if(this.bankAccountHandle.length > 1 && this.selectedBankAccountHandle.AccountNumber) {
            accountNumber = this.selectedBankAccountHandle.AccountNumber;
         }

        let bankTransaction: BankTransaction = {
            AccountNo: accountNumber,
            Date: moment().toJSON(),
            TransactionDescription: '',
            Amount: 0,
            Allocated: 'None',
            Type: '',
            FitId: '',
            CheckNum: '',
            Processed: false,
            BankStatementId: 0,
            HasAttachment: false,
            IsDuplicate: false,
            IsReferred: false,
            ProofOfPaymentId: '',
            HasProofOfPayment: false,
            POPDocumentPath: '',
            Reason: '',
            ExcludedFlag: false,
            IsRevised: false,
            BankTransactionLines: [],
            Id: 0,
            ProofOfPayment: [],
            AdHocCollection: false,
            Category: ''
        };
        this.workingNewBankTransactions.push(bankTransaction);
    } // end addAnotherTransaction();

    onAmountChange(bank: BankTransaction, event) {
        if (this.userProfileService.userProfile.isImpersonator) {
          event.target.value = this.cpipe.transform(event.target.value,
            this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
        } else {
          event.target.value = this.cpipe.transform(event.target.value,
            this.userProfileService.userProfile.currencyDetails.symbol, 2);
        }
      } // end onAmountChange()

      onAmountClicked(bank: BankTransaction, event) {
          if (this.userProfileService.userProfile.isImpersonator) {
            event.target.value = this.cpipe.parse(event.target.value,
              this.userProfileService.selectedUserProfile.currencyDetails.symbol);
            } else {
            event.target.value = this.cpipe.parse(event.target.value,
              this.userProfileService.userProfile.currencyDetails.symbol);
          }
      } // end onAmountClicked()

      parseTransactionDate(transactionDate: string): Date {
        if (transactionDate) {
          return new Date(transactionDate);
        } else {
          return null;
        }
      } // end parseTransactionDate()

      onValueChange(transaction: BankTransaction, value: Date): void {
        transaction.Date = moment(value).format('YYYY-MM-DDTHH:mm:ss');
      } // end onValueChange()

      onTransactionRemove(transaction: BankTransaction) {
        const updated_transactions = this.workingNewBankTransactions.filter(x => x !== transaction);
        this.workingNewBankTransactions = updated_transactions;
      } // end onTransactionRemove()

    onKeyDown($event) {
        switch ($event.key) {
            case 'Enter':
                // this.getNewBankTransactions(this.searchTerm);
            break;
        }
    } // end onKeyDown()

    performSearch() {
        if (this.searchTerm) {
            this.bankTransactionsHandle.forEach(trans => {
                if (
                    !(trans.data.Date.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(trans.data.TransactionDescription.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(trans.data.AccountNo.match(new RegExp(this.searchTerm, 'gi'))) &&
                    !(trans.data.Amount.toString().match(new RegExp(this.searchTerm, 'gi')))
                ) {
                    trans.state.isVisible = false;
                } else {
                    trans.state.isVisible = true;
                }
            });
        } else {
            this.bankTransactionsHandle.forEach(trans => {
                trans.state.isVisible = true;
            });
        }
    } // end performSearch()

    clearSearch() {
        this.searchTerm = '';
        this.performSearch();
    } // end clearSearch()

    getAllocatedColor(value: string): string {
        if (value === 'None') {
            return '#cf624f';
        } else if (value === 'Fully') {
            return '#8abd37';
        } else if (value === 'Partial') {
            return '#e5c100';
        } else if (value === 'Revised') {
            return '#cf624f';
        }
    }

    getRevisedAllocatedColor(value: string): string {
        if (value === 'Revised') {
            return '#cf624f';
        }
    } // end getRevisedAllocatedColor()

    getRevisedAllocatedText(value: string): string {
        if (value === 'Revised') {
            return 'Bold';
        }
    } // end getRevisedAllocatedText()

    onHideTransactionsErrorPrompt() {
        this.showTransactionsErrorPrompt = false;
    } // end onHideTransactionsErrorPrompt()


    // onChangeCheckBox(data: any, isChecked: boolean) {
        // var obj = {
        //     Id: data.Id
        // };

        // if (isChecked) {
        //     this.bankTransactionSelected.push(data);
        //     this.bankTransactionSelectedID.push(obj);
        // } else {
        //     let index = this.bankTransactionSelected.indexOf(data);
        //     this.bankTransactionSelected.splice(index, 1);

        //     let i = this.bankTransactionSelectedID.indexOf(obj.Id);
        //     this.bankTransactionSelectedID.splice(i, 1);
        // }

        // if (this.bankTransactionSelected.length > 0) {
        //     this.showActions = true;
        // } else {
        //     this.showActions = false;
        // }

    // }

    toggleCheckbox(transaction: BankTransactionHandle) {
        transaction.state.isSelected = !transaction.state.isSelected;
        if (this.bankTransactionsHandle.filter(trans => trans.state.isSelected === true).length > 0) {
            this.inputCheckbox.nativeElement.checked = false;
            this.showDeleteActions = true;
        } else {
            // this.inputCheckbox.nativeElement.checked = true;
            this.showDeleteActions = false;
        }
    } // end toggleCheckbox()

    toggleSelectAll() {
        // this.selectAll = !this.selectAll;
        this.bankTransactionsHandle.forEach(transactions => {
            if (transactions.state.isVisible) {
                transactions.state.isSelected = this.inputCheckbox.nativeElement.checked;
                this.showDeleteActions = this.inputCheckbox.nativeElement.checked;
            }
        });
    } // end toggleSelectAll()

    async  onAllocateClick(value: string) {
        if (this.showActions) {
            this.allocatedType = value;
            this.allocatedTitle = '';
            this.bankTransactionsHandle.forEach(trans => {
                if (trans.state.isSelected === true) {
                    this.bankTransactionSelectedID.push(trans.data);
                }
            });
            var count = this.bankTransactionSelectedID.length;
            switch (value) {
                case 'Exclude':
                    this.allocatedTitle = 'Exclude ' + count + ' transactions';
                    this.promptAllocateModel = true;
                    break;
                case 'LawFirm':
                    this.allocatedTitle = 'Allocate ' + count + ' transactions to law firm';
                    this.getLawFirms();
                    this.promptAllocateModel = true;
                    break;
                case 'ReferToClient':
                    this.allocatedTitle = 'Refer ' + count + ' transactions to client';
                    this.promptAllocateModel = true;
                    break;
                case 'Personal':
                    this.loadingService.showOverlay();
                     this.processPaymentService.putPersonalBankTransaction(this.bankTransactionSelectedID).subscribe({next: (_next) => {},
                        complete: () => {
                            this.selectAll = false;
                            this.onHideallocate();
                            this.onShow.emit();
                            this.canSendStatement();
                        }
                    });
                    break;
                case 'Delete':
                    await this.processTransactions();
                    // for (let id of this.bankTransactionSelectedID) {
                    //     if (id.BankTransactionLines && id.BankTransactionLines.find(a => a.TransactionLinkId > 0)) {
                    //         this.showErrorMessagePrompt = true;
                    //     } else {
                    //         this.loadingService.showOverlay();
                    //         try {
                    //             // Log activity Login
                    //             const currentDate = new Date();
                    //             this.activityLog.Action = 'Delete bank transaction';
                    //             this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
                    //             this.activityLog.LoggedApp = 'Web Application (Import-bank-statement)';
                    //             if (this.userProfileService.userProfile.isImpersonator) {
                    //                 this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                    //                 this.activityLog.LoggedForUserName =
                    //                 this.userProfileService.selectedUserProfile.personalDetails.fullName;
                    //             } else {
                    //                 this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                    //                 this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
                    //             }
                    //             this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
                    //             this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
                    //             this.activityLog.ActionTable = 'BankTransaction';
                    //             this.activityLog.JsonData = JSON.stringify(id);
                    //             this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});
                    //             this.processPaymentService.deleteBankTransactions(id.Id).subscribe({next: (_delete) => {},
                    //                 complete: ()=> {                                    
                    //                 }
                    //             });                            
                    //         } catch (error) {
                    //             this.loadingService.hideOverlay();
                    //         }
                    //     }
                    // } 
                        this.selectAll = false;
                        this.onHideallocate();
                        this.onShow.emit();
                        this.onRowClick(0);                   
                    // this.loadingService.hideOverlay();
                    break;
                case 'process':
                    this.loadingService.showOverlay();
                    this.saveInvoices();
                break;
            }

        }
    }

    async processTransactions() {
        for (let id of this.bankTransactionSelectedID) {
            if (id.BankTransactionLines && id.BankTransactionLines.find(a => a.TransactionLinkId > 0)) {
                this.showErrorMessagePrompt = true;
            } else {
                this.loadingService.showOverlay();
                try {
                    // Log activity Login
                    const currentDate = new Date();
                    this.activityLog.Action = 'Delete bank transaction';
                    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
                    this.activityLog.LoggedApp = 'Web Application (Import-bank-statement)';
                    if (this.userProfileService.userProfile.isImpersonator) {
                        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                        this.activityLog.LoggedForUserName =
                        this.userProfileService.selectedUserProfile.personalDetails.fullName;
                    } else {
                        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
                    }
                    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
                    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
                    this.activityLog.ActionTable = 'BankTransaction';
                    this.activityLog.JsonData = JSON.stringify(id);
    
                    await firstValueFrom(this.activityLogService.addActivityLog(this.activityLog));
                    await firstValueFrom(this.processPaymentService.deleteBankTransactions(id.Id));
                } catch (error) {
                    console.error('Error processing transaction:', error);
                } finally {
                    this.loadingService.hideOverlay();
                }
            }
        }
    }

    canSendStatement() {
        this.lastStatementDate = Object.assign({}, DEFAULT_SEND_STATEMENT);
        this.processPaymentService.getLastStatementDate().subscribe({next: (_next) => {
            this.lastStatementDate = _next;
        },
        error: (error) => {

        },
        complete: () => {
            this.processPaymentService.getBankTransactionCount().subscribe({next: (_count) => {
                this.count = _count;
            },
            complete: () => {
                // On complete
                if (moment().diff(this.lastStatementDate.LastStatementSent, 'days') >= 13 && this.count === 0) {
                    this.showSendStatementPrompt = true;
                }            
            }
        });
    
        }
    });
    } // end canSendStatement()

    saveInvoices() {
        let data: any = [];
        // this.loadingService.showOverlay();
        this.bankTransactionsHandle.forEach(transaction => {
            if (transaction.state.isSelected) {
                if (transaction.data.BankTransactionLines.length > 0 && transaction.data.Allocated === 'Fully') {
                    // transaction.data.BankTransactionLines[0].Paid = transaction.data.Amount;
                    // transaction.data.BankTransactionLines[0].LawFirmId = 0;
                    // transaction.data.BankTransactionLines[0].Id = 0;
                    transaction.data.BankTransactionLines.forEach(_trans => {
                        const trans_length = transaction.data.BankTransactionLines.length;
                        _trans.Paid = transaction.data.Amount / trans_length;
                    _trans.LawFirmId = 0;
                    _trans.Id = 0;
                    });
                    if (data && data.length > 0) {
                        if (data.filter(da => da.TransactionDescription === transaction.data.TransactionDescription)) {
                        } else {
                            data.push(transaction.data);
                        }
                    } else {
                        data.push(transaction.data);
                    }
                } else {
                    // this.loadingService.hideOverlay();
                    this.showTransactionsErrorPrompt = true;
                }
            }
        });
        this.processPaymentService.putMultipleBankTransactions(data).subscribe({ next: (trans) => {
            // On next
        },
            error: (error) => {
                // On error
                this.loadingService.hideOverlay();
            },
            complete: () => {
                // On complete
                // setTimeout(() => {
                    this.onShow.emit();
                    // this.loadingService.hideOverlay();
                // }, 3000);
            }
        });
    } // end saveInvoices()

    onHideallocate() {
        this.bankTransactionSelectedID = [];
        this.bankTransactionSelected = [];
        this.searchTerm = '';
        // this.showActions = false;
        this.inputCheckbox.nativeElement.checked = false;
        // this.clearSearch();
        // this.getNewBankTransactions(this.searchTerm);/
    }

    onHideAllocateModel() {
        this.promptAllocateModel = false;
    }

    async onAllocateModel($event) {
        const currentDate = new Date();
        this.promptAllocateModel = false;
        switch ($event.type) {
            case 'Exclude':
                this.loadingService.showOverlay();

                // Log activity Login
                this.activityLog.Action = 'Exclude bank transaction';
                this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
                this.activityLog.LoggedApp = 'Web Application (Import-bank-statement)';
                if (this.userProfileService.userProfile.isImpersonator) {
                    this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                    this.activityLog.LoggedForUserName =
                        this.userProfileService.selectedUserProfile.personalDetails.fullName;
                } else {
                    this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                    this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
                }
                this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
                this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
                this.activityLog.ActionTable = 'BankTransaction';
                this.activityLog.JsonData = JSON.stringify(this.bankTransactionSelectedID);
                this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});
                
                this.bankTransactionSelectedID.forEach(trans => {                    
                    if (trans.BankTransactionLines != null && trans.BankTransactionLines.length > 0) {
                        trans.BankTransactionLines.forEach(v => {
                            this.processPaymentService.removeTransactionLines(v.TransactionId).subscribe({next: (_remove) => {}});
                        });
                    } else {
                        // console.log('SELECTED TRANSACTION: ', trans);
                    }
                });
                // if(this.bankTransactionSelectedID.BankTransactionLines.length > 0) {
                //     this.bankTransactionSelectedID.BankTransactionLines.forEach(c => {
                //         this.processPaymentService.removeTransactionLines(c).subscribe({next: (_remove) => {}});
                //     });
                // }
                 this.processPaymentService.putExcludeBankTransaction($event.Reason, this.bankTransactionSelectedID).subscribe({next: (_
                    ) => {
                     // On next
                 },
                 error: (error) => {
                     // On Error
                 },
                 complete: () => {
                     this.onShow.emit();
                     this.canSendStatement();
                 }
                });
                break;
            case 'LawFirm':
                this.loadingService.showOverlay();
                let data = [];
                this.bankTransactionSelectedID.forEach(trans => {
                    const obj = {
                        'Id': 0,
                        'Invoice': null,
                        'InvoiceId': null,
                        'LawFirm': $event.lawFirm,
                        'LawFirmId': $event.lawFirmId,
                        'Matter': null,
                        'Reference': '',
                        'Amount': 0,
                        'Paid': trans.Amount,
                        'AssignedToLawFirm': true
                    };
                    if (trans.BankTransactionLines === null)
                        trans.BankTransactionLines = [];
                    trans.BankTransactionLines.push(obj);
                    this.bankTransactionsHandle.forEach(transaction => {
                        if (transaction.data.Id === trans.Id) {
                            transaction.state.isVisible = false;
                        }
                    });
                    // console.log('BANK TRANSACTION: ', obj);
                    data.push(obj);
                });

                // this.bankTransactionSelectedID.forEach(trans  => {

                // Log activity Login
                this.activityLog.Action = 'Allocated bank transaction to law firm';
                this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
                this.activityLog.LoggedApp = 'Web Application (Import-bank-statement)';
                if (this.userProfileService.userProfile.isImpersonator) {
                    this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                    this.activityLog.LoggedForUserName =
                        this.userProfileService.selectedUserProfile.personalDetails.fullName;
                } else {
                    this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                    this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
                }
                this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
                this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
                this.activityLog.ActionTable = 'BankTransaction';
                this.activityLog.JsonData = JSON.stringify(this.bankTransactionSelectedID);
                this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

                for (const trans of this.bankTransactionSelectedID) {
                    trans.Category = $event.Category;
                    await lastValueFrom(this.processPaymentService.putBankTransactions(trans)).then((_tr) => {});
                    //  this.processPaymentService.putBankTransactions(trans).subscribe({next: (_tr) => {},
                    
                    // complete: () => {
                    //     this.bankTransactionSelectedID = [];
                    //     this.onShow.emit();
                    //     this.canSendStatement();
                    //     //this.processPaymentService.putLawFirmBankTransaction($event.lawFirmId, this.bankTransactionSelectedID).toPromise();
                    // }});
                }
                this.bankTransactionSelectedID = [];
                        this.onShow.emit();
                        this.canSendStatement();
                break;
            case 'ReferToClient':
                this.loadingService.showOverlay();
                this.bankTransactionSelectedID = [];
                // this.bankTransactionsHandle.forEach(trans => {
                for (const trans of this.bankTransactionsHandle) {
                if (trans.state.isSelected) {
                        this.bankTransactionSelectedID.push(trans.data);
                        trans.state.isVisible = false;
                    }
                }

                // Log activity Login
                this.activityLog.Action = 'Refer bank transaction to a client';
                this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
                this.activityLog.LoggedApp = 'Web Application (Import-bank-statement)';
                if (this.userProfileService.userProfile.isImpersonator) {
                    this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                    this.activityLog.LoggedForUserName =
                        this.userProfileService.selectedUserProfile.personalDetails.fullName;
                } else {
                    this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                    this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
                }
                this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
                this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
                this.activityLog.ActionTable = 'BankTransaction';
                this.activityLog.JsonData = JSON.stringify(this.bankTransactionSelectedID);
                this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

                 this.processPaymentService.putReferToClient($event.Reason, this.bankTransactionSelectedID).subscribe({next: (_next) => {},
                complete: () => {
                    this.bankTransactionSelectedID = [];
                    this.onShow.emit();
                    this.canSendStatement();
                }});
                break;
        }
        this.onHideallocate();
    } // end onAllocateModel()

    viewProofOfPayment(val: any) {
        if (val && val.ProofOfPaymentId > 0) {
        this.popId = val.ProofOfPaymentId;
            this.showProofOfPaymentDoc = true;
        }
    } // end viewProofOfPayment()

    allocateToLawfirm() {
    //   var transaction =  this.bankTransactions.filter(trans => trans.Id === this.bankTransactionSelectedID)
    }

    onRowClick(val: any) {
        // this.addedInvoiceToTransaction = [];
        this.row = 0;
        if (val.Id !== 0) {
            this.row = val.Id;
            this.selectedTransaction.emit(val);
            // this.promptInvoicesEvent.emit(val);
        } else {
            this.selectedTransaction.emit(0);
        }
    }

    closeModal() {
        // this.popId = 0;
        this.showProofOfPaymentDoc = false;
    } // end closeModal()

    getLawFirms() {
        this.loadingService.showOverlay();
        this.contactService.getLawFirmsByServiceProvider().subscribe({ next: (lawfirms) => {
            this.lawFirms = lawfirms;
        },
        error: (error) => {
            // on Error
            this.loadingService.hideOverlay();
        },
        complete: () => {
            // On complete
            this.loadingService.hideOverlay();
        }
    });
    } // end getLawFirms()

    onSendStatement() {
        this.router.navigate([PATH_SEND_STATEMENT]);
    } // end onSendStatement()

    onHideSendStatementPrompt() {
        this.showSendStatementPrompt = false;
    } // end onHideSendStatementPrompt()

    viewEditTransaction(val: BankTransaction) {
        this.editTransaction = Object.assign({}, val);
        console.log('SELECTED EDIT TRANSACTION: ', this.editTransaction);
        this.title = 'Edit ' + this.editTransaction.TransactionDescription;
        this.onShoEditTransactionModal = true;
    } // end viewEditTransaction()

    onHideEditTransactionModal() {
        this.editTransaction = Object.assign({}, DEFAULT_BANK_TRANSACTION);
        this.onShoEditTransactionModal = false;
    } // end onHideEditTransactionModal()

    onSaveEditTransactionModal(event: any) {
        this.bankTransactionsHandle.forEach(x => {
            if(x.data.Id === event.Id) {
                x.data = event;
            }
        });
        this.onShoEditTransactionModal = false;
    } // end onSaveEditTransactionModal()

} // end NewBankTransactionsComponent{}

