import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilitiesService } from '../../../services/utilities.service';
import { Contacts, ContactsService, DEFAULT_CONTACTS } from '../../../services/contacts/contacts.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

@Component({
  selector: 'app-edit-existing-attorney',
  templateUrl: './edit-existing-attorney.component.html',
  styleUrls: ['./edit-existing-attorney.component.scss']
})
export class EditExistingAttorneyComponent implements OnInit {

  title = 'Add new Attorney';
  workingSelectedAttorney: Contacts = DEFAULT_CONTACTS;
  @Input() isVisible: boolean;
  @Input() selectedLawfirm: Contacts;
  @Input() selectedAttorney: Contacts;
  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSaveAttorney: EventEmitter<Contacts> = new EventEmitter<Contacts>();

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.selectedAttorney, this.workingSelectedAttorney);
  } // end isDirty()

  get isSaveable() {
    return this.isDirty &&
    Boolean(this.workingSelectedAttorney && this.workingSelectedAttorney.FullName) &&
    Boolean(this.workingSelectedAttorney && this.workingSelectedAttorney.Email) &&
    Boolean(this.workingSelectedAttorney && this.workingSelectedAttorney.Cell);
  } // end isSaveable()

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private contactsService: ContactsService
  ) { } // end constructor()

  ngOnInit(): void {
    if (this.selectedAttorney && this.selectedLawfirm) {
      this.title = 'Edit ' + this.selectedAttorney.FullName + ' working for ' + this.selectedLawfirm.FullName;
      this.workingSelectedAttorney = this.util.objectCopy(this.selectedAttorney);
    }
  } // end ngOnInit()

  dismiss() {
    this.closeModal.emit('edit-attorney');
  } // dismiss()

  onEditAttorney() {
    this.loadingService.showOverlay();
    this.contactsService.PutAttorneyInFirm(this.workingSelectedAttorney).subscribe({ next: (data) => {
      // On next
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.onSaveAttorney.emit(this.selectedLawfirm);
      this.loadingService.hideOverlay();
    }
  });
  } // end onEditAttorney()

} // end EditExistingAttorneyComponent{}
