import { Component, OnInit, ViewChild, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { UserProfileService, UserProfile } from '../../../services/user-profile/user-profile.service';
import { SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { ContactsService, Contact } from '../../../services/contacts/contacts.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import {
      LiveStatementService,
      StatementAdvocateSummary,
      StatementByAdvocate } from '../../../services/live-statement/live-statement.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { PracticeSummaryComponent } from '../../../components/reports/practice-summary/practice-summary.component';
import {
      IndividualCustomerReportComponent
          } from '../../../components/reports/individual-customer-report/individual-customer-report.component';
import {
      CustomersReportPaymentRatingComponent
          } from '../../../components/reports/customer-report-payment-rating/customer-report-payment-rating.component';
import { AllCustomersReportComponent } from '../../../components/reports/all-customers-report/all-customers-report.component';
import { AgeAnalysisReportComponent } from '../../../components/reports/age-analysis/age-analysis.component';
import {
      InvoicedCollectedSummaryComponent
          } from '../../../components/reports/invoiced-collected-summary/invoiced-collected-summary.component';
import {
      DEFAULT_LOADINGOVERLAY_OPTIONS,
      LoadingOverlayModalOptions } from '../../../components/modals/loading-overlay-modal/loading-overlay-modal.component';
import { AgeAnalysisData } from '../../../services/age-analysis/age-analysis.service';
import { ICrumb } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
// import { TypeaheadMatch } from 'ngx-bootstrap';
import { PracticeManagementParameters, ReportParameters, ReportsService } from '../../../services/reports/reports.service';
import {
  PATH_REPORTS,
  PATH_CUSTOMER_PAYMENT_RATING,
  PATH_REPORT_ALLCUSTOMER_REPORT,
  PATH_REPORT_AGE_ANALYSIS,
  PATH_REPORT_INVOICE_COLLECTED,
  PATH_THE_INDIVIDUAL_CUSTOMER} from '../../../services/appdata/app-config.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

export enum ReportType {
  BusinessReports = 'businessReports',
  FinancialReports = 'financialReports',
  LiveStatement = 'liveStatement',
  PracticeManagementReports = 'practiceManagementReports',
  IndustryReports = 'industryReport'
} // end ReportType{}

export enum ReportName {
  CalendarAction = 'calendarAction',
  DeletedInvoice = 'deletedInvoice',
  Disbursement = 'disbursement',
  FinancialSummaryViewReport = 'financialSummaryViewReport',
  InvoicePaid = 'invoicePaid',
  InvoiceHistory = 'invoiceHistory',
  InvoiceHistoryByLawfirm = 'invoiceHistoryByLawfirm',
  Outstanding = 'outstanding',
  Recon = 'recon',
  WorkInProgress = 'workInProgress',
  ChooseAReport = 'null',
  StatementOfAccount = 'statementOfAccount',
  LiveStatement = 'liveStatement',
  InvoicedCollectedMonthlySummary = 'invoicedCollectedMonthlySummary',
  AgeAnalysis = 'ageAnalysis',
  AllCustomersReport = 'allCustomersReport',
  CustomerReportPaymentRating = 'customerReportPaymentRating',
  IndividualCustomerReport = 'individualCustomerReport',
  PracticeSummary = 'practiceSummary',
  monthlyMeeting = 'monthlyMeeting',
  practiceAssessment = 'practiceAssessment',
} // end ReportName{}

export const DEFAULT_REPORT_PARAMETERS = {
  fromDate: '',
  toDate: '',
  lawFirmId: 0,
  fromMonth: '1',
  toMonth: '1',
  fromYear: '2013',
  toYear: '2013',
  serviceProviderId: 0,
  serviceId: 0,
  searchText: '',
  format: 'pdf'
};

interface Debtor {
  FullName: string;
  ContactID: number;
}

@Component({
  selector: 'app-report-age-analysis',
  templateUrl: './report-age-analysis.component.html',
  styleUrls: ['./report-age-analysis.component.scss']
})
export class ReportAgeAnalysisComponent implements OnInit, OnChanges, OnDestroy {
  title = 'Reports';
  crumbs: ICrumb[];
  reportUrl: string;
  debtorData: any = [];
  showFinancialReports: boolean;
  showBusinessReports: boolean;
  showLiveStatementReports: boolean;
  _debtorId: number;
  _defaultTab: string;
  debtorName: string;
  showDebtorErrorPrompt = false;
  selectedButton: any = [
    {
      name: ''
    }
  ];

  userProfile: UserProfile;
  reportType: ReportType; // = ReportType.FinancialReports;
  reportName: ReportName; // = ReportName.FinancialSummaryViewReport;
  _reportName = '';

  practiceManagementParameters: PracticeManagementParameters = {
    fromDate: '',
    toDate: ''
  };
  reportParameters: ReportParameters = {
    fromDate: '',
    toDate: '',
    lawFirmId: 0,
    fromMonth: '1',
    toMonth: '1',
    fromYear: '2013',
    toYear: '2013',
    serviceProviderId: 0,
    serviceId: 0,
    searchText: '',
    format: 'pdf'
  };

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  lawFirms: Contact[];
  allLawFirms: Contact[];
  SelectedFirm: Contact[];
  AllSelectedFirm: Contact[];
  selectedLawfirm: string;
  toogleright = true;
  showErrorMessageModelPrompt: boolean;
  errorMessage: string;
  erroMessageTitle: string;

  ageAnalysisData: AgeAnalysisData;
  statementAdvocateSummary: StatementAdvocateSummary;
  statementByAdvocate: StatementByAdvocate[];
  id: number;
  canViewPracticeAndFinancials = false;
  options: LoadingOverlayModalOptions = Object.assign({}, DEFAULT_LOADINGOVERLAY_OPTIONS);


  @ViewChild('InvoicedCollectedSummaryComponent') invoicedCollectedSummaryReport: InvoicedCollectedSummaryComponent;
  @ViewChild('AgeAnalysisReportComponent') ageAnalysisReportComponent: AgeAnalysisReportComponent;
  @ViewChild('AllCustomersReportComponent') allCustomersReportComponent: AllCustomersReportComponent;
  @ViewChild('CustomersReportPaymentRatingComponent') customersReportPaymentRatingComponent: CustomersReportPaymentRatingComponent;
  @ViewChild('IndividualCustomerReportComponent') individualCustomerReportComponent: IndividualCustomerReportComponent;
  @ViewChild('PracticeSummaryComponent') practiceSummaryComponent: PracticeSummaryComponent;


  @ViewChild('pdfViewer') public pdfViewer;
  reportA = '';

  get isAssistantToLawfirm(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator)
      && Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
  } // end isAssistantToLawfirm()

  // Is Internal Attorney
  get isAttorney(): boolean {
    let attorneyProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      attorneyProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.ATTORNEY);
    } else {
      attorneyProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
    }
    return attorneyProfile;
  } // end isAttorney()

  // Is Internal Lawfirm
  get isLawfirm(): boolean {
    let lawfirmProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      lawfirmProfile = Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
    } else {
      lawfirmProfile = Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
    }
    return lawfirmProfile;
  } // end isLawfirm()

  // Is External Attorney
  get isExternalAttorney(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY);
    // return false;
  } // end isExternalAttorney()

  // Is Internal Lawfirm
  get isInternalLawfirm(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
    // return false;
  } // end isInternalLawfirm()

  get canViewFinanctialReport(): boolean {
    if (this.userProfileService.userProfile.isImpersonator) {
      if (this.userProfileService.selectedUserProfile.permissions.view_Report_Financials) {
        this.canViewPracticeAndFinancials = true;
      } else {
        this.canViewPracticeAndFinancials = false;
      }
    } else {
      if (this.userProfileService.userProfile.permissions.view_Report_Financials) {
        this.canViewPracticeAndFinancials = true;
      } else {
        this.canViewPracticeAndFinancials = false;
      }
    }
    return this.canViewPracticeAndFinancials;
  } // end canViewFinanctialReport()

  get canViewPractiseManagement(): boolean {
    return false;
  } // end canViewPractiseManagement()

  get canDisplayFinancialReport(): boolean {
    if (this.userProfile.isImpersonator) {
      return Boolean(this.userProfileService.selectedUserProfile.permissions.view_Report_Financials);
    } else {
      return Boolean(this.userProfile.permissions.view_Report_Financials);
    }
  } // end showFinancialReports()

  get AuxiliumOhnaPermissions(): boolean {
    return Boolean( this.userProfileService.userProfile.serviceProviderID== 27829 && this.userProfileService.selectedUserProfile.serviceProviderID == 9243);
  } // end AuxiliumOhnaPermissions()

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private userProfileService: UserProfileService,
    private snackbarsService: SnackbarsService,
    private reportsService: ReportsService,
    private contactsService: ContactsService,
    private loadingService: LoadingService,
    private liveStatementService: LiveStatementService,
    private utilityService: UtilitiesService
  ) {
    document.title = route.snapshot.data.title;
   } // end constructor()

  ngOnInit() {
    this.reportType = ReportType.PracticeManagementReports;
    this.reportName = ReportName.AgeAnalysis;
    this._reportName = this.reportName;
    this.viewReport();
    this.crumbs = [
      {
        label: 'Reports',
        link: PATH_REPORTS
      },
      {
        label: 'My Practice',
        link: PATH_REPORTS
      },
      {
        label: 'Age Analysis'
      }
    ];
  } // end ngOnInit()

  get isInvalidFromToYear(): boolean {
    return Boolean(this.reportParameters.fromYear > this.reportParameters.toYear);
  } // end isInvalidFromToYear()

  get isInvalidFromToDate(): boolean {
    return this.utilityService.isInvalidFromToDate(this.reportParameters.fromDate, this.reportParameters.toDate);
  } // end isInvalidFromToDate()

  get isInvalidPracticeManagementFromToDate(): boolean {
    return this.utilityService.isInvalidFromToDate(this.practiceManagementParameters.fromDate, this.practiceManagementParameters.toDate);
  }

  get isInvalidSelectedLawfirm(): boolean {
    return !Boolean(this.selectedLawfirm !== null);
  } // end isInvalidFromToDate()

  get isSubmitable(): boolean {
    return Boolean(!this.isInvalidFromToDate && !this.isInvalidSelectedLawfirm);
  } // end isSubmitable()

  onInput(event) {
    if (event.inputType === 'deleteContentBackward') {
      this.SelectedFirm = this.lawFirms;
      this.AllSelectedFirm = this.allLawFirms;
    }
  } // end onInput()
  onFocus() {
  } // end onInput()

  onSelect(event: TypeaheadMatch): void {
    this.selectedLawfirm = event.item.FullName;
    if (event.item.ContactID && event.item.ContactID !== 0) {
      this.statementOfAccountReportSubmit(event.item.ContactID);
    } else if (this.selectedLawfirm.trim() === 'All') {
      this.statementOfAccountReportSubmit(event.item.ContactID);
    }
  } // end onSelect




  onLawfirmSelect(event: TypeaheadMatch): void {
    this.selectedLawfirm = event.item.FullName;
    if (event.item.ContactID && event.item.ContactID !== 0) {
      this.reportParameters.lawFirmId = event.item.ContactID;
    }
  } // end onLawfirmSelect

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reportParameters && changes.reportParameters.currentValue) {

    }
  } // end ngOnChanges()

  ngOnDestroy() {
    this.reportsService.selectedReport = null;
    this.snackbarsService.dismiss();
  } // end ngOnDestroy()

  togglePanel(panel: string) {

    this._reportName = '';
    switch (panel) {
      case 'financialReports':
        this.router.navigate([PATH_REPORTS]);
        this.reportsService.selectedReportType = ReportType.FinancialReports;
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Financial Report',
            // link: null
          }
        ];
        this.showFinancialReports = !this.showFinancialReports;
        this.showLiveStatementReports = false;
        this.reportName = ReportName.FinancialSummaryViewReport;
        this.reportType = ReportType.FinancialReports;
        this.crumbs.push({
          label: 'Financial Summary View Report'
        });
        break;

      case 'businessReports':
        this.router.navigate([PATH_REPORTS]);
        this.reportsService.selectedReportType = ReportType.BusinessReports;
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Business Report',
            // link: null
          }
        ];
        this.showBusinessReports = !this.showBusinessReports;
        this.showLiveStatementReports = false;
        this.reportName = ReportName.StatementOfAccount;
        this.reportType = ReportType.BusinessReports;
        this.crumbs.push({
          label: 'Statement of Account Report'
        });
        break;

      case 'liveStatement':
        // this.showLiveStatementReports = true;
        this.showBusinessReports = false;
        this.reportName = ReportName.LiveStatement;
        this.reportsService.selectedReport = ReportName.LiveStatement;
        this.reportType = ReportType.LiveStatement;
        break;
      case 'practiceManagementReports':
        this.router.navigate([PATH_REPORTS]);
        this.reportsService.selectedReportType = ReportType.PracticeManagementReports;
        this.crumbs.push({
          label: 'My Practice',
          link: null
        });
        const id = this.route.snapshot.paramMap.get('id');
        if (id === 'age-analysis') {
          this.reportName = ReportName.AgeAnalysis;
        } else if (id === 'customer-payment-rating') {
          this.reportName = ReportName.CustomerReportPaymentRating;
        } else {
          this.crumbs.push({
            label: 'Practice Summary',
            link: null
          });
          this.reportName = ReportName.PracticeSummary;
        }
        this._reportName = this.reportName;
        this.reportType = ReportType.PracticeManagementReports;
        break;
      case 'industryReport':
        this.router.navigate([PATH_REPORTS]);
        this.reportsService.selectedReportType = ReportType.IndustryReports;
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Business Report',
            // link: null
          }
        ];
        this.showBusinessReports = !this.showBusinessReports;
        this.showLiveStatementReports = false;
        this.reportName = ReportName.StatementOfAccount;
        this.reportType = ReportType.BusinessReports;
        this.crumbs.push({
          label: 'Statement of Account Report'
        });
        break;
    }

    this.viewReport();
  } // end togglePanel()

  toggleDropright() {
    switch (this.reportName) {
      case 'invoicePaid':
        if (document.getElementById('vatReport').style.right &&
          document.getElementById('vatReport').style.right === '-14%') {
          document.getElementById('vatReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('vatReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'invoiceHistory':
        if (document.getElementById('invoiceHistoryReport').style.right &&
          document.getElementById('invoiceHistoryReport').style.right === '-14%') {
          document.getElementById('invoiceHistoryReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('invoiceHistoryReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'invoiceHistoryByLawfirm':
        if (document.getElementById('invoiceHistoryByLawfirmReport').style.right &&
          document.getElementById('invoiceHistoryByLawfirmReport').style.right === '-14%') {
          document.getElementById('invoiceHistoryByLawfirmReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('invoiceHistoryByLawfirmReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'disbursement':
        if (document.getElementById('disbursementReport').style.right &&
          document.getElementById('disbursementReport').style.right === '-14%') {
          document.getElementById('disbursementReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('disbursementReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'recon':
        if (document.getElementById('reconciliationHistoryReport').style.right &&
          document.getElementById('reconciliationHistoryReport').style.right === '-14%') {
          document.getElementById('reconciliationHistoryReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('reconciliationHistoryReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'calendarAction':
        if (document.getElementById('calendarActionReport').style.right &&
          document.getElementById('calendarActionReport').style.right === '-14%') {
          document.getElementById('calendarActionReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('calendarActionReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'deletedInvoice':
        if (document.getElementById('financialReport').style.right &&
          document.getElementById('financialReport').style.right === '-14%') {
          document.getElementById('financialReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('financialReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'financialSummaryViewReport':
        if (document.getElementById('financialReport').style.right &&
          document.getElementById('financialReport').style.right === '-14%') {
          document.getElementById('financialReport').style.right = '6px';
          this.toogleright = true;
        } else {
          document.getElementById('financialReport').style.right = '-14%';
          this.toogleright = false;
        }
        break;

      case 'statementOfAccount':
        // if (document.getElementById('statementOfAccountReport').style.right &&
        //   document.getElementById('statementOfAccountReport').style.right === '-14%') {
        //   document.getElementById('statementOfAccountReport').style.right = '6px';
        //   this.toogleright = true;
        // } else {
        //   document.getElementById('statementOfAccountReport').style.right = '-14%';
        //   this.toogleright = false;
        // }
        break;


    }
  } // end toggleDropright()

  viewReport() {
    this._defaultTab = null;
    this._debtorId = null;
    this.reportParameters.fromDate = null;
    this.reportParameters.toDate = null;
    this.selectedLawfirm = null;
    this.toogleright = true;
    this.reportParameters = Object.assign({}, DEFAULT_REPORT_PARAMETERS);

    switch (this.reportName) {
      case 'invoicePaid':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Financial Report',
            // link: null
          },
          {
            label: 'VAT on Invoice Paid Report'
          }
        ];
        this._reportName = this.reportName;
        break;

      case 'liveStatement':
        this._reportName = this.reportName;
        this.showLiveStatementReports = true;
        break;

      case 'invoiceHistory':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Financial Report',
            // link: null
          },
          {
            label: 'Invoice History Report'
          }
        ];
        this._reportName = this.reportName;
        break;

      case 'invoiceHistoryByLawfirm':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Financial Report',
            // link: null
          },
          {
            label: 'Invoice History By Law Firm Report'
          }
        ];
        this._reportName = this.reportName;
        break;

      case 'disbursement':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Financial Report',
            // link: null
          },
          {
            label: '98 Day Disbursement Report'
          }
        ];
        this._reportName = this.reportName;
        break;

      case 'recon':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Financial Report',
            // link: null
          },
          {
            label: 'Reconciliation History Report'
          }
        ];
        this._reportName = this.reportName;
        break;

      case 'workInProgress':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Business Report',
            // link: null
          },
          {
            label: 'Work In Progress Report'
          }
        ];
        this._reportName = this.reportName;
        this.loadingService.showOverlay();
        this.reportUrl = this.api.endpoints.workInProgressReport +
          '=' + this.api.serviceProviderID;
        this.reportsService
          .getReports(this.reportUrl)
          .subscribe(res => {
            this.pdfViewer.name = 'name.pdf';
            this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
          });
          // ,
          //   () => { // error
          //   },
          //   () => {
          //     // complete
              this.loadingService.hideOverlay();
            // });
        this.selectedButton.name = 'workInProgress';
        break;

      case 'calendarAction':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Business Report',
            // link: null
          },
          {
            label: 'Calendar Action Report'
          }
        ];
        this._reportName = this.reportName;
        break;

      case 'deletedInvoice':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Business Report',
            // link: null
          },
          {
            label: 'Deleted Invoice Report'
          }
        ];
        this._reportName = this.reportName;
        this.loadingService.showOverlay();
        this.reportUrl = this.api.endpoints.deletedInvoiceReport +
          '=' + this.api.serviceProviderID;
        this.reportsService
          .getReports(this.reportUrl)
          .subscribe(res => {
            this.pdfViewer.name = 'name.pdf';
            this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
          });
          // ,
          //   () => {
          //     // error
          //   },
          //   () => {
          //     // complete
              this.loadingService.hideOverlay();
            // });
        this.selectedButton.name = 'deletedInvoice';
        break;

      case 'monthlyMeeting':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Financial Report',
            // link: null
          },
          {
            label: 'Monthly Meeting Report'
          }
        ];
        this._reportName = this.reportName;
        const _tod = new Date();
        const _fromd = new Date(_tod.getFullYear(), _tod.getMonth() - 12, _tod.getDate());
        this.reportParameters.fromDate = _fromd.toDateString();
        this.reportParameters.toDate = _tod.toDateString();
        this.monthlyMeetingReportSubmit();
        break;

      case 'practiceAssessment':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Financial Report',
            // link: null
          },
          {
            label: 'Practice Assessment Report'
          }
        ];
        this._reportName = this.reportName;
        this.loadingService.showOverlay();
        this.reportUrl = this.api.endpoints.practiceAssessmentReport +
          '=' + this.api.serviceProviderID;
        this.reportsService
          .getReports(this.reportUrl)
          .subscribe(res => {
            this.pdfViewer.name = 'name.pdf';
            this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
          // }
          // ,
          //   () => {
          //     // error
          //   },
          //   () => {
          //     // complete
            });
            this.loadingService.hideOverlay();
        this.selectedButton.name = 'practiceAssessment';
        break;

      case 'outstanding':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Business Report',
            // link: null
          },
          {
            label: 'Outstanding Invoice Report'
          }
        ];
        this._reportName = this.reportName;
        this.loadingService.showOverlay();
        this.reportUrl = this.api.endpoints.outstandingInvoiceReport +
          '=' + this.api.serviceProviderID;
        this.reportsService
          .getReports(this.reportUrl)
          .subscribe(res => {
            this.pdfViewer.name = 'name.pdf';
            this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
          // },
          //   () => {
          //     // Error
          //   },
          //   () => {
          //     // complete
            });
            this.loadingService.hideOverlay();
        this.selectedButton.name = 'outstanding';
        break;

      case 'financialSummaryViewReport':
        this._reportName = this.reportName;
        break;

      case 'statementOfAccount':
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'Business Report',
            // link: null
          },
          {
            label: 'Statement of Account Report'
          }
        ];
        this.selectedButton.name = 'statementOfAccount';
        this._reportName = this.reportName;
        break;

      case 'invoicedCollectedMonthlySummary':
        this.router.navigate([PATH_REPORT_INVOICE_COLLECTED]);
        break;

      case 'ageAnalysis':
        this.router.navigate([PATH_REPORT_AGE_ANALYSIS]);
        break;

      case 'allCustomersReport':
        this.router.navigate([PATH_REPORT_ALLCUSTOMER_REPORT]);
        break;

      case 'customerReportPaymentRating':
        this.router.navigate([PATH_CUSTOMER_PAYMENT_RATING]);
        break;

      case 'individualCustomerReport':
        this.reportsService.setIndividualCustomerParameters(null);
        this._reportName = this.reportName;
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'My Practice',
            // link: null
          }
        ];
        this.getDebtorList();
        break;

      case 'practiceSummary':
        this.showLiveStatementReports = false;
        this.crumbs = [
          {
            label: 'Reports',
            // link: PATH_REPORTS
          },
          {
            label: 'My Practice',
            // link: null
          },
          {
            label: 'Practice Summary',
            // link: PATH_REPORTS
          }
        ];
        this._reportName = this.reportName;
        break;

      default:
        break;
    }
  } // end viewReport()

  statementOfAccountReportSubmit(lawFirmId) {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.statementOfAccountsReport +
      '=' + this.api.serviceProviderID +
      '&lawFirmId=' + lawFirmId;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe(res => {
        this.pdfViewer.name = 'name.pdf';
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
      // },
      //   () => {
      //     // Error
      //   },
      //   () => {
        });
        this.loadingService.hideOverlay();
  } // end statementOfAccountReportSubmit()

  onClickViewPracticeManagementReport() {
    if (this.isInvalidPracticeManagementFromToDate === false) {
      this.reportsService.setPracticeManagementParameters(this.practiceManagementParameters);
    } else {
      this.erroMessageTitle = 'An error occurred';
      this.errorMessage = 'From Date cannot be greater than To Date.';
      this.showErrorMessageModelPrompt = true;
    }
  } // end onClickViewPracticeManagementReport()

  financialReportSubmit() {
    this.loadingService.showOverlay();
    // document.getElementById('financialReport').style.width = '0';

    this.reportUrl = this.api.endpoints.financialSummaryViewReport +
      '=' + this.api.serviceProviderID +
      '&fromYear=' + this.reportParameters.fromYear +
      '&fromMonth=' + this.reportParameters.fromMonth +
      '&toYear=' + this.reportParameters.toYear +
      '&toMonth=' + this.reportParameters.toMonth;

    this.reportsService
      .getReports(this.reportUrl)
      .subscribe(res => {
        this.pdfViewer.name = 'name.pdf';
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
      // },
      //   () => {
      //     // Error
      //   },
      //   () => {
      //     // Complete
        });
        this.loadingService.hideOverlay();
  } // end financialReportSubmit()

  calendarActionReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.calendarActionReport +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe(res => {
        this.pdfViewer.name = 'name.pdf';
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
      // },
      //   () => {
      //     // error
      //   },
      //   () => {
      //     // complete
        });
        this.loadingService.hideOverlay();
  } // end calendarActionReportSubmit()

  reconciliationHistoryReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.reconciliationHistoryReport +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe(res => {
        this.pdfViewer.name = 'name.pdf';
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
      // },
      //   () => {
      //     // Error
      //   },
      //   () => {
      //     // Complete
        });
        this.loadingService.hideOverlay();
  } // end reconciliationHistoryReportSubmit()

  disbursementReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.disbursementReport + '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe(res => {
        this.pdfViewer.name = 'name.pdf';
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
      // },
      //   () => {
      //     // Error
      //   },
      //   () => {
      //     // Complete
        });
        this.loadingService.hideOverlay();
  } // end disbursementReportSubmit()

  vatReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.vatOnPaidInvoicesReport +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe(res => {
        this.pdfViewer.name = 'name.pdf';
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
      // },
      //   () => {
      //     // Error
      //   },
      //   () => {
      //     // Complete
        });
        this.loadingService.hideOverlay();
  } // end vatReportSubmit()

  invoiceHistoryReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.invoiceHistoryReport +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe(res => {
        this.pdfViewer.name = 'name.pdf';
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
      // },
      //   () => {
      //     // Error
      //   },
      //   () => {
      //     // Complete
        });
        this.loadingService.hideOverlay();
  } // end invoiceHistoryReportSubmit()

  monthlyMeetingReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.monthlyMeetingReport +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe(res => {
        this.pdfViewer.name = 'name.pdf';
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
      // },
      //   () => {
      //     // error
      //   },
      //   () => {
      //     // complete
        });
        this.loadingService.hideOverlay();
    this.selectedButton.name = 'monthlyMeeting';
  } // end monthlyMeetingReportSubmit()

  invoiceHistoryByLawfirmReportSubmit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.invoiceHistoryByLawFirm +
      '=' + this.api.serviceProviderID +
      '&fromDate=' + this.reportParameters.fromDate +
      '&toDate=' + this.reportParameters.toDate +
      '&lawFirmId=' + this.reportParameters.lawFirmId;
    this.reportsService
      .getReports(this.reportUrl)
      .subscribe(res => {
        this.pdfViewer.name = 'name.pdf';
        this.pdfViewer.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
      // },
      //   () => {
      //     // Error
      //   },
      //   () => {
      //     // Complete
        });
        this.loadingService.hideOverlay();
  } // end invoiceHistoryByLawfirmReportSubmit()

  onPracticeManagementFromDateValueChange(value: Date): void {
    if (value) {
      this.practiceManagementParameters.fromDate = moment(value).format('YYYY-MM-DD');
    }
    this.practiceManagementParameters.toDate = this.reportParameters.toDate;
    // if (this.isInvalidPracticeManagementFromToDate === false) {
    //   this.reportsService.setPracticeManagementParameters(this.practiceManagementParameters);
    // }
  }

  onPracticeManagementToDateValueChange(value: Date): void {
    if (value) {
      this.practiceManagementParameters.toDate = moment(value).format('YYYY-MM-DD');
    }
    this.practiceManagementParameters.fromDate = this.reportParameters.fromDate;
    if (this.isInvalidPracticeManagementFromToDate === false) {
      this.reportsService.setPracticeManagementParameters(this.practiceManagementParameters);
    } else {
      this.erroMessageTitle = 'An error occurred';
      this.errorMessage = 'From Date cannot be greater than To Date.';
      this.showErrorMessageModelPrompt = true;
    }
  }

  onFromDateValueChange(value: Date): void {
    // TODO: Implement onDateChange
    this.reportParameters.fromDate = value.toDateString();
  } // end onFromDateValueChange()

  onToDateValueChange(value: Date): void {
    // TODO: Implement onDateChange
    this.reportParameters.toDate = value.toDateString();
    if (this.reportParameters.fromDate !== null && this.reportParameters.toDate !== null &&
      !this.utilityService.isInvalidFromToDate(this.reportParameters.fromDate, this.reportParameters.toDate)) {
      if (this.reportName === 'calendarAction') {
        this.calendarActionReportSubmit();
      } else if (this.reportName === 'invoiceHistory') {
        this.invoiceHistoryReportSubmit();
      } else if (this.reportName === 'recon') {
        this.reconciliationHistoryReportSubmit();
      } else if (this.reportName === 'invoicePaid') {
        this.vatReportSubmit();
      } else if (this.reportName === 'disbursement') {
        this.disbursementReportSubmit();
      } else if (this.reportName === 'monthlyMeeting') {
        this.monthlyMeetingReportSubmit();
      } else if (this.reportName === 'invoiceHistoryByLawfirm' || this.selectedLawfirm.trim() === 'All') {
        if (this.reportParameters.lawFirmId > 0) {
          this.invoiceHistoryByLawfirmReportSubmit();
        } else {
          this.erroMessageTitle = 'An error occurred';
          this.errorMessage = 'Please select a Law Firm from predefined list.';
          this.showErrorMessageModelPrompt = true;
        }
      }
    } else {
      this.erroMessageTitle = 'An error occurred';
      this.errorMessage = 'From Date cannot be greater than To Date.';
      this.showErrorMessageModelPrompt = true;
    }
  } // end onToDateValueChange()

  onClickViewReport() {
    if (this.reportParameters.fromDate !== null && this.reportParameters.toDate !== null &&
      !this.utilityService.isInvalidFromToDate(this.reportParameters.fromDate, this.reportParameters.toDate)) {
      if (this.reportName === 'calendarAction') {
        this.calendarActionReportSubmit();
      } else if (this.reportName === 'invoiceHistory') {
        this.invoiceHistoryReportSubmit();
      } else if (this.reportName === 'recon') {
        this.reconciliationHistoryReportSubmit();
      } else if (this.reportName === 'invoicePaid') {
        this.vatReportSubmit();
      } else if (this.reportName === 'disbursement') {
        this.disbursementReportSubmit();
      } else if (this.reportName === 'monthlyMeeting') {
        this.monthlyMeetingReportSubmit();
      } else if (this.reportName === 'invoiceHistoryByLawfirm') {
        if (this.reportParameters.lawFirmId > 0 || this.selectedLawfirm.trim() === 'All') {
          this.invoiceHistoryByLawfirmReportSubmit();
        } else {
          this.erroMessageTitle = 'An error occurred';
          this.errorMessage = 'Please select a Law Firm from predefined list.';
          this.showErrorMessageModelPrompt = true;
        }
      }
    } else {
      this.erroMessageTitle = 'An error occurred';
      this.errorMessage = 'From Date cannot be greater than To Date.';
      this.showErrorMessageModelPrompt = true;
    }
  } // end onClickViewReport()

  onHideErrorMessageModelPrompt() {
    this.showErrorMessageModelPrompt = false;
  } // end onHideErrorMessageModelPrompt()

  selectedToMonth(event) {
    if (this.reportParameters.fromYear < this.reportParameters.toYear ||
      this.reportParameters.fromYear === this.reportParameters.toYear &&
      this.reportParameters.fromMonth < this.reportParameters.toMonth) {
      this.financialReportSubmit();
    } else {
      this.erroMessageTitle = 'An error occurred';
      this.errorMessage = 'From Date cannot be greater than To Date.';
      this.showErrorMessageModelPrompt = true;
    }
  } // end selectedToMonth()

  receiveDebtorID($event) {
    this.reportName = ReportName.IndividualCustomerReport;
    this._reportName = this.reportName;
    this.viewReport();
    this._debtorId = $event;
  }

  receiveAllCustomer($event) {

    this.reportName = ReportName.AllCustomersReport;
    this._reportName = this.reportName;
    this.viewReport();
    this._defaultTab = $event;
  }

  receiveCustomerReport($event) {

    if ($event === ReportName.CustomerReportPaymentRating) {
      this.reportName = ReportName.CustomerReportPaymentRating;
    } else if ($event === ReportName.AgeAnalysis) {
      this.reportName = ReportName.AgeAnalysis;
    } else if ($event === ReportName.InvoicedCollectedMonthlySummary) {
      this.reportName = ReportName.InvoicedCollectedMonthlySummary;
    }
    this._reportName = this.reportName;
    this.viewReport();

  }

  getDebtorList(): void {
    this.reportsService.getDistinctCustomerList().subscribe({ next: (response) => {
      if (response) {
        this.debtorData = response;
        this.debtorData.sort((a, b) => {
          if (a.fullName !== undefined && b.fullName !== undefined) {
            return a.fullName.toUpperCase().localeCompare(b.fullName.toUpperCase());
          }
        });
      }
    },
     error: (error) => {
        // Error
      },
      complete: () => {
        if (this._debtorId == null) {
          // this._debtorId = this.debtorData[0].contactID;
          this.debtorName = '';
        } else {
          const _scope = this;
          const currentDebtor = this.debtorData.filter(function (f) {
            return f.contactID === _scope._debtorId;
          })[0];
          this.debtorName = currentDebtor.fullName;
        }
        this.reportsService.setIndividualCustomerParameters(this._debtorId);
      }
    });
  }

  onDebtorSelect(event: TypeaheadMatch): void {
    if (event) {
      this.reportsService.individualCustomerFrom = 'the-individual-customer';
      this.router.navigate([PATH_THE_INDIVIDUAL_CUSTOMER + '/' + event.item.contactID]);
    } else {
      this.reportsService.setIndividualCustomerParameters(null);
    }
  }

  onDebtorBlur() {
    setTimeout(() => {
      const _scope = this;
      const debtors = this.debtorData.filter(function (f) {
        return f.fullName === _scope.debtorName;
      });
      if (debtors.length === 0) {
        this.debtorName = '';
        this.showDebtorErrorPrompt = true;
        this.reportsService.setIndividualCustomerParameters(null);
      }
    }, 300);
  }

  onHideDebtorErrorPrompt() {
    this.showDebtorErrorPrompt = false;
  }

} // end ReportAgeAnalysisComponent{}
