import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Fee } from '../../../../services/fee-items/fee-items.service';
import { FromCalendarItem } from '../../../../services/from-calendar-items/from-calendar-items.service';

@Component({
  selector: 'app-delete-fee-modal',
  templateUrl: './delete-fee-modal.component.html',
  styleUrls: ['./delete-fee-modal.component.scss']
})
export class DeleteFeeModalComponent implements OnInit {

  @Input() isVisible: boolean;
  @Input() fee: Fee | FromCalendarItem;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() confirm: EventEmitter<Fee | FromCalendarItem> = new EventEmitter();
  
  title: string = 'Delete Fee from Matter';

  constructor() { } // end constructor()

  ngOnInit() {
  } // ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()
  
  onConfirm() {
    // do some stuff
    this.dismiss();
    this.confirm.emit(this.fee);
  } // end onConfirm()

  dismiss() {
    this.cancel.emit();
  } // end dismiss()

} // end DeleteFeeModalComponent{}
