<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-header">
                <h2>{{title}}</h2>
                <button class="modal-dismiss" (click)="onCancel()">
                    <i class="fa fa-close"></i>
                </button>
            </div>

            <div class="modal-body">
                <div class="table-responsive">
                    <table class="table table-striped" style="border:0">
                        <tr>
                            <th>Date Of Admission</th>
                            <!-- <th>Candidate In Group</th> -->
                            <th>Average</th>
                            <th>Highest</th>
                            <th>Lowest</th>
                        </tr>
                        <tbody class="tbody">
                            <tr *ngFor="let data of feeTariffs">
                                <td>{{ data.DateOfAdmission }}</td>
                                <!-- <td>{{ data.CandidateInGroup }}</td> -->
                                <td>{{ data.Average | currency: currencySymbol: currencySymbol:'1.2-2' }}</td>
                                <td>{{ data.Highest | currency: currencySymbol: currencySymbol:'1.2-2' }}</td>
                                <td>{{ data.Lowest | currency: currencySymbol: currencySymbol:'1.2-2' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn btn-danger" (click)="onCancel()">Cancel</button>
                <button class="btn btn-success" (click)="onCancel()">Ok</button>
            </div>

        </div>
    </div>
</div>
