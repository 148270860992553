import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TAG_EDIT_FEE_TEMPLATE_MODAL } from '../../../services/tags/tags.service';
import { UserProfile } from '../../../services/user-profile/user-profile.service';
import { UtilitiesService } from '../../../services/utilities.service';
import {
  FeeTemplate,
  categories,
  rateTypes,
  AVAILABILITIES,
  DEFAULT_FEE_TEMPLATE
 } from '../../../services/fee-template/fee-template.service';

const TITLE_NEW_FEE_TEMPLATE = 'Add new fee template';
const TITLE_EDIT_FEE_TEMPLATE = 'Edit fee template';

@Component({
  selector: 'app-edit-fee-template-modal',
  templateUrl: './edit-fee-template-modal.component.html',
  styleUrls: ['./edit-fee-template-modal.component.scss']
})
export class EditFeeTemplateComponent implements OnInit, OnChanges {

  @Input() isVisible: boolean;
  @Input() feeTemplate: FeeTemplate;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() saveFeeTemplate: EventEmitter<any> = new EventEmitter();

  title: string = TITLE_NEW_FEE_TEMPLATE;
  categories: string[] = categories;
  rateTypes: string[] = rateTypes;
  availabilities: string[] = AVAILABILITIES;
  workingFeeTemplate: FeeTemplate;
  showUnsavedChangesPrompt: boolean;

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.workingFeeTemplate, this.feeTemplate);
  } // end isDirty()

  get hasDescriptionError(): boolean {
    return !this.util.hasTextValue(this.workingFeeTemplate.SubCategory);
  } // end hasFirmNameError()

  get hasCategoryError(): boolean {
    return !this.util.hasTextValue(this.workingFeeTemplate.Category);
  } // end hasFirmNameError()

  get hasRateTypeError(): boolean {
    return !this.util.hasTextValue(this.workingFeeTemplate.RateType);
  } // end hasFirmNameError()

  get isAbleToSave(): boolean {
    return this.util.hasTextValue(this.workingFeeTemplate.SubCategory)
      && this.util.hasTextValue(this.workingFeeTemplate.Category)
      && this.util.hasTextValue(this.workingFeeTemplate.RateType);
  }

  constructor(
    private util: UtilitiesService
  ) {
    this.reset();
  } // end constructor()

  ngOnInit() {} // ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.feeTemplate &&
        changes.feeTemplate.previousValue !== changes.feeTemplate.currentValue) {
      this.workingFeeTemplate = this.util.objectCopy(this.feeTemplate);
    }

    if (this.workingFeeTemplate.SubCategoryId) {
      this.title = TITLE_EDIT_FEE_TEMPLATE;
    } else {
      this.title = TITLE_NEW_FEE_TEMPLATE;
    }
  } // end ngOnChanges{}

  private reset() {
    this.title = TITLE_NEW_FEE_TEMPLATE;
    this.workingFeeTemplate = this.util.objectCopy(DEFAULT_FEE_TEMPLATE);
  } // end reset()

  onDescriptionChange(description: string) {

  } // end onDescriptionChange()

  onDismiss() {
    // if isDirty
    if (this.isDirty) {
      // prompt to save changes
      this.showUnsavedChangesPrompt = true;
    } else {
      this.dismiss();
    }
  } // end onDismiss()

  dismiss() {
    this.onHideUnsavedChangesPrompt();
    this.reset();
    this.workingFeeTemplate = this.util.objectCopy(this.feeTemplate);
    this.closeModal.emit(TAG_EDIT_FEE_TEMPLATE_MODAL);
  } // end dismiss()

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end onHideUnsavedChangesPrompt()

  /**
   * Saves the fee template.
   */
  save() {
    this.saveFeeTemplate.emit(this.workingFeeTemplate);
    this.dismiss();
  } // end save()

  validate() {

  } // end validate()
} // end EditFeeTemplateComponent{}
