import { Component, Inject, OnInit } from '@angular/core';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { Snack, SnackbarsService, SnackType } from '../../services/messaging/snackbars/snackbars.service';
import {
  PracticeManagerDashboardService,
  PracticeWidgetSummary,
  DEFAULT_PRACTICE_WIDGET_SUMMARY,
  OutstandingBookSummary,
  PracticeOverDueSummary,
  DEFAULT_PRACTICE_OVERDUE_SUMMARY,
  DEFAUL_OVERDUE_SUMMARY,
  OverDueSummary
} from '../../services/practice-manager-dashoboard-service/practice-manager-dashboard.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import {
  PracticeAdvocateHealthService,
  PracticeAgeAnalysis,
  DEFAULT_PRACTICE_AGE_ANALYSIS
} from '../../services/practice-advocate-health/practice-advocate-health.service';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { ApiService } from '../../services/api.service';

export enum ReportType {
  BusinessReports = 'businessReports',
  FinancialReports = 'financialReports',
  LiveStatement = 'liveStatement',
  PracticeManagementReports = 'practiceManagementReports',
  IndustryReports = 'industryReport',
  MyAdvocates = 'myAdvocates'
} // end ReportType{}

enum WidgetType {
  InvoicedTarget = 'invoicedTarget',
  CollectedTarget = 'collectedTarget',
  AdvocateReview = 'advocateReview',
  InvoicingAdvocate = 'invoicingAdvocate',
  AgeAnalysis = 'ageAnalysis'
}
export enum ReportName {
  AdvocateSummary = 'advocateSummary',
  AdvocateDetail = 'advocateDetail',
  AgeAnalysis = 'ageAnalysis',
  PracticeAllCustomer = 'practiceAllCustomer',
  PracticeByLawfirm = 'practiceByLawfirm'
} // end ReportName{}

@Component({
  selector: 'app-practice-m-dashboard',
  templateUrl: './practice-m-dashboard.component.html',
  styleUrls: ['./practice-m-dashboard.component.scss']
})
export class PracticeMDashboardComponent implements OnInit {
  // invoicedData: any;
  // collectedData: any;
  // invoicedYearlyData: any;
  // collectedYearlyData: any;
  invMonthly: any;
  invYearly: any;
  collMonthly: any;
  collYearly: any;
  // widgetData: PracticeWidgetSummary = Object.assign({}, DEFAULT_PRACTICE_WIDGET_SUMMARY);
  // outstandingBookSummary: OutstandingBookSummary[] = [];
  practiceOverdueSummary: OverDueSummary = Object.assign({}, DEFAUL_OVERDUE_SUMMARY);
  advocateId: any[] = [];

  // Practice Managers Age Analysis
  practiceAgeAnalysis: PracticeAgeAnalysis = Object.assign({}, DEFAULT_PRACTICE_AGE_ANALYSIS);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private api: ApiService,
    private authService: MsalService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private snackbarsService: SnackbarsService,
    private dashboardService: PracticeManagerDashboardService,
    private practiceAdvocateHealthService: PracticeAdvocateHealthService
  ) { } // end contructor()

  ngOnInit() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount) {
    } else {
      if (this.msalGuardConfig.authRequest){
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  if (this.userProfileService.refreshSelectedProfile) {
      location.reload();
    }
    this.loadingService.showOverlay();
    this.practiceAdvocateHealthService.reportSortType = '';
    this.practiceAdvocateHealthService.selectedReportName = ReportName.AdvocateSummary;
    this.practiceAdvocateHealthService.selectedReportType = ReportType.MyAdvocates;
    this.userProfileService.isInPMDashboardScreen = true;

    if (this.api.serviceProviderID && this.api.serviceProviderID != undefined) {
      // this.getPracticeWidgetData();
      this.getPracticeOverdueSummary();
      // this.getInvoicedMonthlyDashboard();
      // this.getInvoicedYearlyDashboard();
      // this.getCollectedMonthlyDashboard();
      // this.getCollectedYearlyDashboard();
      this.getPracticeAgeAnalysis();
    }
     this.loadingService.hideOverlay();
  } // end ngOninit()

  // getPracticeWidgetData() {
  //   // this.loadingService.showOverlay();
  //   this.dashboardService.getPracticeWidget().subscribe({
  //     next: (_widget) => {
  //       // On next
  //       this.widgetData = _widget;
  //       sessionStorage.setItem('widgetData', JSON.stringify(this.widgetData));
  //     }
  //   })
  //   // this.loadingService.hideOverlay();
  // } // end getPracticeWidgetData()

  getPracticeOverdueSummary() {
    // this.loadingService.showOverlay();
    this.dashboardService.getPracticedueOverSummary().subscribe({
      next: (_next) => {
        this.practiceOverdueSummary = _next;
        sessionStorage.setItem('practiceOverdueSummary', JSON.stringify(this.practiceOverdueSummary));
      }
    });
    // this.loadingService.hideOverlay();
  } // end getPracticeOverdueSummary()

  getPracticeAgeAnalysis() {
    // this.loadingService.showOverlay();
    this.practiceAdvocateHealthService.getPracticeAgeAnalysis().subscribe({
      next: (ageAnalysis) => {
        // On next
        this.practiceAgeAnalysis = ageAnalysis;
        if (this.practiceAgeAnalysis) {
          sessionStorage.setItem('practiceAgeAnalysis', JSON.stringify(this.practiceAgeAnalysis));
        }
      }
    });
    // this.loadingService.hideOverlay();
  } // end getPracticeAgeAnalysis()

  // getInvoicedMonthlyDashboard() {
  //   const snack: Snack = {
  //     label: 'Loading Dashboard data.',
  //     action: null
  //   };
  //   // this.loadingService.showOverlay();

  //   this.snackbarsService.make(snack, 5000).show();
  //   this.dashboardService.getInvoicedMonthly().subscribe({
  //     next: (dashboard) => {
  //       this.invMonthly = dashboard;
  //       for (const property in this.invMonthly) {
  //         if (!this.invMonthly[property]) {
  //           this.invMonthly[property] = '';
  //         }
  //       }
  //       const msg = 'Dashboard data loaded.';
  //       snack.label = msg;
  //       this.snackbarsService.dismiss().make(snack).show();
  //     },
  //     error: (error) => {
  //       // on Error
  //       const msg = 'Error loading dashboard.';
  //       snack.label = msg;
  //       snack.type = SnackType.ERROR;
  //       // this.loadingService.hideOverlay();
  //     },
  //     complete: () => {
  //       // On Complete
  //       this.dashboardService.getInvoicedData(this.invMonthly).subscribe(data => {
  //         this.invoicedData = data;
  //         sessionStorage.setItem('invoicedData', JSON.stringify(this.invoicedData));
  //         // this.loadingService.hideOverlay();
  //       });
  //     }
  //   });
  // } // end getInvoicedMonthlyDashboard()

  // getInvoicedYearlyDashboard() {
  //   const snack: Snack = {
  //     label: 'Loading Dashboard data.',
  //     action: null
  //   };
  //   // this.loadingService.showOverlay();

  //   this.snackbarsService.make(snack, 5000).show();
  //   this.dashboardService.getInvoicedYearly().subscribe({
  //     next: (dashboard) => {
  //       this.invYearly = dashboard;
  //       for (const property in this.invYearly) {
  //         if (!this.invYearly[property]) {
  //           this.invYearly[property] = '';
  //         }
  //       }
  //       const msg = 'Dashboard data loaded.';
  //       snack.label = msg;
  //       this.snackbarsService.dismiss().make(snack).show();
  //     },
  //     error: (error) => {
  //       // on Error
  //       const msg = 'Error loading dashboard.';
  //       snack.label = msg;
  //       snack.type = SnackType.ERROR;
  //       // this.loadingService.hideOverlay();
  //     },
  //     complete: () => {
  //       // On Complete
  //       this.dashboardService.getInvoicedYearlyData(this.invYearly).subscribe(data => {
  //         this.invoicedYearlyData = data;
  //         sessionStorage.setItem('invoicedYearlyData', JSON.stringify(this.invoicedYearlyData));
  //         // this.loadingService.hideOverlay();
  //       });
  //     }
  //   });
  // } // end getInvoicedMonthlyDashboard()

  // getCollectedMonthlyDashboard() {
  //   const snack: Snack = {
  //     label: 'Loading Dashboard data.',
  //     action: null
  //   };
  //   // this.loadingService.showOverlay();

  //   this.snackbarsService.make(snack, 5000).show();
  //   this.dashboardService.getCollectedMonthly().subscribe({
  //     next: (dashboard) => {
  //       this.collMonthly = dashboard;
  //       for (const property in this.collMonthly) {
  //         if (!this.collMonthly[property]) {
  //           this.collMonthly[property] = '';
  //         }
  //       }
  //       const msg = 'Dashboard data loaded.';
  //       snack.label = msg;
  //       this.snackbarsService.dismiss().make(snack).show();
  //     },
  //     error: (error) => {
  //       // on Error
  //       const msg = 'Error loading dashboard.';
  //       snack.label = msg;
  //       snack.type = SnackType.ERROR;
  //       // this.loadingService.hideOverlay();
  //     },
  //     complete: () => {
  //       // On Complete
  //       this.dashboardService.getCollectedData(this.collMonthly).subscribe(data => {
  //         this.collectedData = data;
  //         sessionStorage.setItem('collectedData', JSON.stringify(this.collectedData));
  //         // this.loadingService.hideOverlay();
  //       });
  //     }
  //   });
  // } // end getCollectedMonthlyDashboard()

  // getCollectedYearlyDashboard() {
  //   const snack: Snack = {
  //     label: 'Loading Dashboard data.',
  //     action: null
  //   };
  //   // this.loadingService.showOverlay();

  //   this.snackbarsService.make(snack, 5000).show();
  //   this.dashboardService.getCollectedYearly().subscribe({
  //     next: (dashboard) => {
  //       this.collYearly = dashboard;
  //       for (const property in this.collYearly) {
  //         if (!this.collYearly[property]) {
  //           this.collYearly[property] = '';
  //         }
  //       }
  //       const msg = 'Dashboard data loaded.';
  //       snack.label = msg;
  //       this.snackbarsService.dismiss().make(snack).show();
  //     },
  //     error: (error) => {
  //       // on Error
  //       const msg = 'Error loading dashboard.';
  //       snack.label = msg;
  //       snack.type = SnackType.ERROR;
  //       // this.loadingService.hideOverlay();
  //     },
  //     complete: () => {
  //       // On Complete
  //       this.dashboardService.getCollectedYearlyData(this.collYearly).subscribe(data => {
  //         this.collectedYearlyData = data;
  //         sessionStorage.setItem('collectedYearlyData', JSON.stringify(this.collectedYearlyData));
  //         // this.loadingService.hideOverlay();
  //       });
  //     }
  //   });
  // } // end getCollectedYearlyDashboard()
} // end PracticeMDashboardComponent{}
