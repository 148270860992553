import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProfileSettings } from '../../../services/advocate-overview-service/advocate-overview-service.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { AddTeamMemberService, Assistant, DEFAULT_ASSISTANT } from '../../../services/addteam/add-team-member.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

interface AssistantHandle {
  data: Assistant;
  state: {
    isVisible: boolean;
  };
}

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit {
  popTitle = '';
  popupMessage = '';
  dashboardstatus: boolean;
  showProfileActivationPrompt: boolean;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  // Teams
  showAddTeamMember = false;
  teamMembers: Assistant[];
  activeTeamMembers: Assistant;
  assistantHandles: AssistantHandle[] = [];

  @Input() workingProfileSettings: ProfileSettings;
  @Output() subscriptionStatusUpdate: EventEmitter<any> = new EventEmitter<any>();

  get internalAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile &&
      this.userProfileService.userProfile.isImpersonator) &&
      !Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY)
      && Boolean(this.userProfileService.userProfile.serviceProviderID === 9091 ||
        this.userProfileService.userProfile.serviceProviderID === 29096 ||
        this.userProfileService.userProfile.serviceProviderID === 21647);
  } // end internalAssistant()

  get isPracticeManager(): boolean {
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  } // end isPracticeManager()

  get isAdvocate(): boolean {
    if (!this.userProfileService.userProfile)
      return false;
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ADVOCATE);
  } // end isPracticeManager()
  
  constructor(
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private addTeamMemberService: AddTeamMemberService
  ) { } // end constructor{}

  ngOnInit(): void {
    this.getTeamMember();
    this.dashboardstatus = this.workingProfileSettings.ShowDashboard;
  } // end ngOnInit()

  getTeamMember() {
    this.teamMembers = [];
    this.loadingService.showOverlay();
    this.addTeamMemberService
      .getAssistant()
      .subscribe({ next: 
        (team) => {
          this.teamMembers = team;
        },
        error: (error) => {
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.loadingService.hideOverlay();
          this.buildingAssistantHandleList();
        }
      });
  } // end getTeamMember()

  buildingAssistantHandleList() {
    this.assistantHandles = [];
    this.teamMembers.forEach(element => {
      element.Status = 'Confirmed';
      this.assistantHandles.push({
        data: element,
        state: {
          isVisible: true
        }
      });
    });
  } // end buildingAssistantHandleList()

  onEditTeams(team: Assistant) {
    let fullname = team.FullName
      ? team.FullName.split(' ')
      : [];

    if (fullname.length) {
      if (fullname.length === 2) {
        team.FirstName = fullname[0];
        team.LastName = fullname[1];
      } else if (fullname.length > 2) {
        // team.LastName = fullname[0];
        team.FirstName = fullname[1];
        team.LastName = fullname[2];
      }
    }
    this.activeTeamMembers = team;
    this.showAddTeamMember = true;
  } // end onEditTeams()

  onAddNewTeamMember() {
    this.activeTeamMembers = Object.assign({}, DEFAULT_ASSISTANT);
    this.showAddTeamMember = true;
  } // end onAddNewTeamMember()

  onCloseModal() {
    this.showAddTeamMember = false;
  } // end onCloseModal()

  changeStatus() {
    if (this.dashboardstatus) {
      this.dashboardstatus = false;
      // this.workingProfileSettings.ShowDashboard = false;
    } else {
      this.dashboardstatus = true;
      // this.workingProfileSettings.ShowDashboard = true;
    }
  } // end changeStatus()

  changeActiveStatus() {
    this.workingProfileSettings.ShowDashboard = !this.workingProfileSettings.ShowDashboard;
  } // end changeActiveStatus()

  changeSubscriptionStatus() {
    if (this.workingProfileSettings.Active) {
      this.popTitle = 'Deactivate Profile';
      this.popupMessage = 'You are about to deactivate ' + this.userProfileService.selectedUserProfile.personalDetails.fullName +
      ' profile. Are you sure you want to deactivate this profile?';
      this.showProfileActivationPrompt = true;
    } else {
      this.popTitle = 'Activate Profile';
      this.popupMessage = 'You are about to activate ' + this.userProfileService.selectedUserProfile.personalDetails.fullName +
      ' profile. Are you sure you want to activate this profile?';
      this.showProfileActivationPrompt = true;
    }
  } // end changeSubscriptionStatus()

  changeManualTransactionsStatus() {
    this.workingProfileSettings.ManualTransaction = !this.workingProfileSettings.ManualTransaction;
  } // end changeManualTransactionsStatus()

  toggleSelectbCC(){
    this.workingProfileSettings.BCC = !this.workingProfileSettings.BCC;
   } // end toggleSelectbCC()

   onHideProfileActivationPrompt() {
    this.showProfileActivationPrompt = false;
  } // end onHideProfileActivationPrompt()

  onProfileActivateOrDeactivate() {
    this.workingProfileSettings.Active = !this.workingProfileSettings.Active  ;
    console.log('PROFILE SETTINGS TO BE SAVED: ', this.workingProfileSettings);
    this.subscriptionStatusUpdate.emit(this.workingProfileSettings);
    this.showProfileActivationPrompt = false;
  } // end onProfileActivateOrDeactivate()
} // end ProfileSettingsComponent()
