import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
// import { TypeaheadMatch } from 'ngx-bootstrap';

interface Contact {
  FullName: string;
  ContactId: number;
}

const DEFAUL_HANDOVER_CONTACT: Contact = {
  FullName: '',
  ContactId: 0
};

@Component({
  selector: 'app-hand-over-to-lawfirm',
  templateUrl: './hand-over-to-lawfirm.component.html',
  styleUrls: ['./hand-over-to-lawfirm.component.scss']
})
export class HandOverToLawfirmComponent implements OnInit {

  AllSelectedFirm: Contact[] = [
    { FullName: 'Coombe Inc', ContactId: 1536 },
    { FullName: 'Du Bruyn & Morkel Attorneys', ContactId: 14569 },
    { FullName: 'Robbertse Zaayman Attorneys', ContactId: 2980 },
    { FullName: 'Other', ContactId: 28984 },
  ];
  selectedLawfirm: Contact;
  selectedFirm: string;
  title = 'In legal at: ';

  @Input() isVisible: boolean;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();

  get isSaveable(): boolean {
    return Boolean(this.selectedLawfirm && this.selectedLawfirm.ContactId > 0);
  }
  constructor() { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  onCancel() {
    this.selectedFirm = '';
    this.selectedLawfirm = Object.assign({}, DEFAUL_HANDOVER_CONTACT);
    this.cancel.emit();
  } // end onCancel()

  onContinue() {
    this.continue.emit(this.selectedLawfirm.ContactId);
    this.selectedFirm = '';
    this.selectedLawfirm = Object.assign({}, DEFAUL_HANDOVER_CONTACT);
  } // end onContinue()

  onLawfirmSelect(event: TypeaheadMatch) {
    this.selectedLawfirm = Object.assign({}, DEFAUL_HANDOVER_CONTACT);
    this.selectedFirm = event.item.FullName;
    this.selectedLawfirm.FullName = event.item.FullName;
    this.selectedLawfirm.ContactId = event.item.ContactId;
  } // end onLawfirmSelect()

} // end HandOverToLawfirmComponent{}
