<div class="container-fluid" style="margin-top:0">
  <!-- <div class="row"> -->
  <div class="display-options">
    <label for="search">Search: </label>
    <span class="column-search form">
      <button class="btn btn-text">
        <i class="fa fa-search"></i>
      </button>
      <input type="search" (keyup)="filterIndustryData()" [(ngModel)]="searchTerm" placeholder="Search for a Law Firm">

    </span> <!-- #end .column-search form -->
    <span class="payment-trends">
      <button *ngIf="isPracticeManager" style="height: 30px;" class="btn btn-success"
        (click)="onGeneratePaymentTrends()">GCB Payment Trends</button>
    </span>
  </div> <!-- #end .display-options -->

  <div class="table-responsive" *ngIf="!isBusyLoading" style="height: 400px;">
    <table class="table table-striped" *ngIf="!isBusyLoading" style="border: 0;">
      <thead class="thead">
        <tr>
          <th rowspan="2" (click)="toggleSort('law-firm')">Law Firm/Client
            <!-- <i class="fa fa-sort" [class.fa-sort-down]="sortLawfirm"></i> -->
          </th>
          <th rowspan="2" (click)="toggleSort('advocates-in-legal')">Advocates in-legal
            <!-- <i class="fa fa-sort" [class.fa-sort-down]="sortAvarageDays"></i> -->
          </th>
          <th rowspan="2" (click)="toggleSort('advocates-in-lpc')">LPC Complaints
            <!-- <i class="fa fa-sort" [class.fa-sort-down]="sortAvarageDays"></i> -->
          </th>
          <th *ngIf="isBarSocietyTypeOther" rowspan="2" (click)="toggleSort('advocates-in-gcb')">GCB Listings
            <!-- <i class="fa fa-sort" [class.fa-sort-down]="sortAvarageDays"></i> -->
          </th>
          <th rowspan="2" (click)="toggleSort('avarage-days')">Average Days to Payment
            <!-- <i class="fa fa-sort" [class.fa-sort-down]="sortAvarageDays"></i> -->
          </th>
          <th rowspan="2" (click)="toggleSort('paid-vs-invoice')">% Paid vs Invoiced
            <!-- <i class="fa fa-sort" [class.fa-sort-down]="sortPaidVsInvoiced"></i> -->
          </th>
          <th rowspan="2" (click)="toggleSort('total-paid')">Total Invoices Paid
            <!-- <i class="fa fa-sort" [class.fa-sort-down]="sortTotalPaid"></i> -->
          </th>
          <th colspan="5" style="text-align:center;">Invoiced due in Days
          </th>
        </tr>
        <tr width="25%">
          <th>0</th>
          <th>30</th>
          <th>60</th>
          <th>90</th>
          <th>98+</th>
        </tr>
        <!-- <tr></tr> -->
      </thead>
      <tbody class="tbody">
        <tr *ngFor="let lawfirm of industryReportHandle;" [class.hide]="!lawfirm.state.isVisible" role="tabpanel"
          class="tab-pane tab-fade t_body">
          <td width="25%">
            {{lawfirm.data.Client}}
            <i class="fa fa-info-circle fa-lg tool-tip" *ngIf="lawfirm.data.LawfirmLocalitie" style="color: #0ba3c7;">
              <div class="tooltiptext text-left">
                <strong>Locality</strong>
                <br />
                <p class="mt-3" style="margin: 0">{{lawfirm.data.LawfirmLocalitie}}</p>
              </div>
            </i>
          </td>
          <td width="15%">
            <span
              [ngClass]="lawfirm.data.AdvocateCount == 0 ? 'badge badge-pill badge-white' : lawfirm.data.AdvocateCount > 1  ? 'badge badge-pill badge-danger'  :  'badge badge-pill badge-warning'"
              style="padding: 5px 12px;"> {{lawfirm.data.AdvocateCount}} Records </span>
          </td>
          <td width="15%">
            <span
              [ngClass]="lawfirm.data.LPCCount == 0 ? 'badge badge-pill badge-white' : lawfirm.data.LPCCount > 1  ? 'badge badge-pill badge-danger'  :  'badge badge-pill badge-warning'"
              style="padding: 5px 12px;"> {{lawfirm.data.LPCCount}} Reports </span>
          </td>
          <td width="10%" *ngIf="isBarSocietyTypeOther">
            <span
              [ngClass]="lawfirm.data.GCBCount == 0 ? 'badge badge-pill badge-white' : lawfirm.data.GCBCount > 1  ? 'badge badge-pill badge-danger'  :  'badge badge-pill badge-warning'"
              style="padding: 5px 12px;"> {{lawfirm.data.GCBCount}} Reports </span>
            &nbsp;
            <i *ngIf="lawfirm.data.GCBCount > 0" class="fa fa-info-circle fa-lg tool-tip"
              (mouseenter)="onGcbListingTooltip(lawfirm.data.ClientId)" (mouseleave)="onMouseLeave()"
              style="color: #0ba3c7;">
              <div class="tooltiptext text-left" style="min-width: 330px; left: -75px;">
                <spna *ngIf="!showTooltip">Loading...</spna>
                <span *ngIf="showTooltip">
                  <strong>Listing dates</strong>
                  <br />
                  <p class="mt-3" *ngFor="let i of gcbTooltipList" style="margin: 0; line-height: 15px;">
                    {{ i.DateWithRegionalBar }}
                  </p>
                </span>
              </div>
            </i>
          </td>
          <td [ngClass]="{'tableBlur': isProfileTypeAdHoc}" width="15%">{{lawfirm.data.AvgDaysToPayment}} Days</td>
          <td [ngClass]="{'tableBlur': isProfileTypeAdHoc}" width="15%">{{lawfirm.data.PaidVsInvoiced}}%</td>
          <td [ngClass]="{'tableBlur': isProfileTypeAdHoc}" width="15%">{{lawfirm.data.NoOfInvoices}}</td>
          <td [ngClass]="{'tableBlur': isProfileTypeAdHoc}" width="5%">{{lawfirm.data.InvDue0}}</td>
          <td [ngClass]="{'tableBlur': isProfileTypeAdHoc}" width="5%">{{lawfirm.data.InvDue30}}</td>
          <td [ngClass]="{'tableBlur': isProfileTypeAdHoc}" width="5%">{{lawfirm.data.InvDue60}}</td>
          <td [ngClass]="{'tableBlur': isProfileTypeAdHoc}" width="5%">{{lawfirm.data.InvDue90}}</td>
          <td [ngClass]="{'tableBlur': isProfileTypeAdHoc}" width="5%">{{lawfirm.data.InvDue98}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div> <!-- #end .row -->