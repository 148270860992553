<form name="form" #f="ngForm" novalidate *ngIf="isVisible">
    <div class="modal">
        <div class="modal-content">
            <div class="modal-dialog">

                <div class="modal-header">
                    <h2>Import Monthly Billing</h2>
                    <button
                        class="modal-dismiss"
                        (click)="closeModal()">
                        <i class="fal fa-times-circle"></i>
                    </button>
                </div>

                <div class="modal-body form">                    
                    <div class="row m-0 pl-0 pb-0">
                        <div class="col-sm-9 pl-0 pr-5">
                            <div class="custom-file" style="padding-top: 5px;">
                                <input
                                    #inputAttachmentFile
                                    type="text"
                                    name="attachmentText"
                                    (click)="promptAttachment()"
                                    autocomplete="off"
                                    class="form-control"
                                    [(ngModel)]="attachment"
                                    required>
                                <input
                                    #inputUploadFile
                                    type="file"
                                    accept=".csv"
                                    name="fileUpload"
                                    id="attachmentUpload"
                                    class="custom-file-input"
                                    (change)="attachmentChange($event)"
                                    style="display: none;">
                            </div>
                        </div>
                        <div class="col-sm-3 pl-5 pr-5">
                            <button
                                class="btn btn-success form-control browse-file"
                                (click)="promptAttachment()">Browse to file</button>
                        </div>
                    </div>
                    <div class="col-sm m-0 pb-0">
                        <label class="text-danger" for="">{{message}}</label>
                    </div>
                </div>

                <div class="modal-footer">
                    <button
                        class="btn btn-success"
                        [class.disabled]="!isValidCSVFile"
                        [disabled]="!isValidCSVFile"
                        (click)="importData()">Import Data</button>
                </div>

            </div>
        </div>
    </div>
</form>
