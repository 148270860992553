import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-prompt-unsaved-changes-modal',
  templateUrl: './prompt-unsaved-changes-modal.component.html',
  styleUrls: ['./prompt-unsaved-changes-modal.component.scss']
})
export class PromptUnsavedChangesModalComponent implements OnInit {

  @Input() isVisible: boolean;
  @Input() title: string;
  @Input() message: string;
  @Input() btnTextCancel: string;
  @Input() btnTextConfirm: string;
  @Input() btnTextContinue: string;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();

  constructor() { } // end constructor()

  ngOnInit() {
    if (!this.title) {
      this.title = 'You have unsaved changes';
    }

    if (!this.message) {
      this.message =
        'You have unsaved changes. Are you sure you want to leave?';
    }

    if (!this.btnTextCancel) {
      this.btnTextCancel = 'Stay';
    }

    if (!this.btnTextConfirm) {
      this.btnTextConfirm = 'Confirm';
    }

    if (!this.btnTextContinue) {
      this.btnTextContinue = 'Leave';
    }
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onConfirm() {
    this.confirm.emit();
  } // end onConfirm()

  onContinue() {
    this.continue.emit();
  } // end onContinue()
} // end PromptUnsavedChangesModalComponent{}
