import { Injectable } from '@angular/core';
import {
  NumberFormatStyle,
  getLocaleNumberFormat
} from '@angular/common';
import { Observable ,  of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import {
  CHART_COLOR_BLUE,
  CHART_COLOR_GREEN,
  CHART_COLOR_GREY,
  CHART_COLOR_RED,
  CHART_COLOR_WHITE,
  CHART_COLOR_YELLOW
} from '../appdata/app-config.service';
import { ApiService } from '../api.service';
import { catchError } from 'rxjs/operators';
import { SnackbarsService, Snack, SnackType } from '../messaging/snackbars/snackbars.service';
import { UtilitiesService } from '../utilities.service';

const DEFAULT_MIN_TICK_VALUE = 0;
const DEFAULT_MAX_TICK_VALUE = 30000000;
const CHART_BAR_THICKNESS = 25;
const CHART_BAR_PERCENTAGE = 25;
const BASE_FONT_COLOR = '#4a4a4a';

export interface CurrentMonthly {
  ServiceProviderId: number;
  WorkMaxValue: number;
  WorkOptimalRangeStartValue: number;
  WorkOptimalRangeEndValue: number;
  WorkSummary: string;
  WorkScore: number;
  SymbolValue: string;
  ThisDescription: string;
  LastDescription: string;
  Last2Description: string;
  ThisTotalExcl: string;
  LastTotalExcl: string;
  Last2TotalExcl: string;
}

export interface CurrentYearly {
  ServiceProviderId: number;
  WorkMaxValue: number;
  WorkOptimalRangeStartValue: number;
  WorkOptimalRangeEndValue: number;
  SymbolValue: string;
  ThisDescription: string;
  LastDescription: string;
  Last2Description: string;
  ThisTotalExcl: string;
  LastTotalExcl: string;
  Last2TotalExcl: string;
}

export interface InvoicedMonthly {
  ServiceProviderId: number;
  InvoicedMaxValue: number;
  InvoicedOptimalRangeStartValue: number;
  InvoicedOptimalRangeEndValue: number;
  WorkSummary: string;
  WorkScore: number;
  SymbolValue: string;
  ThisDescription: string;
  LastDescription: string;
  Last2Description: string;
  ThisTotalExcl: string;
  LastTotalExcl: string;
  Last2TotalExcl: string;
  Target: string;
  Record: string;
}

export interface InvoicedYearly {
  ServiceProviderId: number;
  InvoicedMaxValue: number;
  InvoicedOptimalRangeStartValue: number;
  InvoicedOptimalRangeEndValue: number;
  SymbolValue: string;
  ThisDescription: string;
  LastDescription: string;
  Last2Description: string;
  ThisTotalExcl: string;
  LastTotalExcl: string;
  Last2TotalExcl: string;
  Target: string;
  Record: string;
}

export interface CollectedMonthly {
  ServiceProviderId: number;
  MaxValue: number;
  OptimalRangeStartValue: number;
  OptimalRangeEndValue: number;
  SymbolValue: string;
  ThisDescription: string;
  LastDescription: string;
  Last2Description: string;
  ThisTotalExcl: string;
  LastTotalExcl: string;
  Last2TotalExcl: string;
  Target: string;
  Record: string;
}

export interface CollectedYearly {
  ServiceProviderId: number;
  MaxValue: number;
  OptimalRangeStartValue: number;
  OptimalRangeEndValue: number;
  SymbolValue: string;
  ThisDescription: string;
  LastDescription: string;
  Last2Description: string;
  ThisTotalExcl: string;
  LastTotalExcl: string;
  Last2TotalExcl: string;
  Target: string;
  Record: string;
}

@Injectable()
export class DashboardNewService {

  currentMattersMonthlyData: any = {
    monthly: {
      currentTotalLabel: '',
      currentThismonthLabel: '',
      currentLastMonthLabel: '',
      currentlast2MonthLast: '',

      currentTotalValue: 0,
      currentThismonthValue: 0,
      currentLastMonthValue: 0,
      currentMonthBeforValue: 0,

      currentRangeStart: 0,
      currentRangeEnd: 0,
      currentMaxValue: 0,
      currentScore: 0,
      currentSummary: ''
    }
  };

  currentMattersYearlyData: any = {
    yearly: {
      thisYearcurrentLabel: '',
      lastYearcurrentLabel: '',
      last2YearscurrentLabel: '',

      thisYearcurrentTotalValue: 0,
      lastYearcurrentValue: 0,
      last2YearscurrentValue: 0,

      thisYearcurrentRangeStart: 0,
      thisYearcurrentRangeEnd: 0,
      thisYearcurrentMaxValue: 0,
      thisYearcurrentScore: 0
    }
  };

  invoicedMonthlyData: any = {
    monthly: {
      invoicedThismonthLabel: '',
      invoicedLastMonthLabel: '',
      invoicedMonthBeforeLast: '',

      // invoicedTotalValue: 0,
      invoicedThismonthValue: 0,
      invoicedLastMonthValue: 0,
      invoicedMonthBeforValue: 0,

      invoicedRangeStart: 0,
      invoicedRangeEnd: 0,
      invoicedMaxValue: 0,
      // invoicedScore: 0,
      invoicedMonthlyTarget: '',
      invoicedMonthlyRecord: ''
    }
  };

  invoicedYearlyData: any = {
    yearly: {
      thisYearInvoicedLabel: '',
      lastYearInvoicedLabel: '',
      last2YearsInvoicedLabel: '',

      thisYearInvoicedTotalValue: 0,
      lastYearInvoicedValue: 0,
      last2YearsInvoicedValue: 0,

      thisYearInvoicedRangeStart: 0,
      thisYearInvoicedRangeEnd: 0,
      thisYearInvoicedMaxValue: 0,
      thisYearInvoicedScore: 0,
      invoicedYearlyTarget: '',
      invoicedYearlyRecord: ''
    }
  };

  collectedMonthlyData: any = {
    monthly: {
      receiptThismonthLabel: '',
      receiptLastMonthLabel: '',
      receiptMonthBeforeLast: '',

      // receiptTotalValue: 0,
      receiptThismonthValue: 0,
      receiptLastMonthValue: 0,
      receiptMonthBeforValue: 0,

      receiptRangeStart: 0,
      receiptRangeEnd: 0,
      receiptMaxValue: 0,
      // receiptScore: 0,
      receiptMonthlyTarget: '',
      receiptMonthlyRecord: ''
    }
  };

  collectedYearlyData: any = {
    yearly: {
      receiptThisYearLabel: '',
      receiptLastYearLabel: '',
      receiptYearBeforeLast: '',

      receiptTotalYearValue: 0,
      receiptThisYearValue: 0,
      receiptLastYearValue: 0,
      receiptYearBeforValue: 0,

      receiptYearRangeStart: 0,
      receiptYearRangeEnd: 0,
      receiptYearMaxValue: 0,
      receiptYearScore: 0,
      receiptYearlyTarget: '',
      receiptYearlyRecord: '',
    }
  };

  currentMonthly: CurrentMonthly; // = this.util.objectCopy(DEFAULT_DASHBOARD_DATA);
  currentYearly: CurrentYearly;
  invoicedMonthly: InvoicedMonthly;
  invoicedYearly: InvoicedYearly;
  collectedMonthly: CollectedMonthly;
  collectedYearly: CollectedYearly;

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private snackbarsService: SnackbarsService,
    private util: UtilitiesService
  ) {
    // Since dashboard data can offered to be slightly out of date,
    // we employ eventual consistency by caching the latest data in the local
    // storeage for faster loading.
    // if (localStorage) {
    //   const dashboardData = localStorage.getItem('dashboardData');
    //   if (dashboardData) {
    //     this.dashboardData = JSON.parse(dashboardData);

    //     for (let x in this.dashboardData) {
    //       if (this.dashboardData.hasOwnProperty(x) &&
    //       typeof this.dashboardData[x] === typeof null) {
    //         this.dashboardData[x] = '';
    //       }
    //     }
    //   } else {
    //     const snack: Snack = {
    //       label: 'Initializing Dashboard data...',
    //       action: null
    //     };
    //     this.snackbarsService.make(snack, 5000).show();

    //     this.getDashboardData().subscribe(
    //       dashboard => {
    //         localStorage.setItem('dashboardData', JSON.stringify(dashboard));
    //         // const msg = 'Dashboard data loaded.';
    //         // snack.label = msg;
    //         // this.snackbarsService.dismiss().make(snack, 5000).show();
    //       },
    //       error => {
    //         const msg = 'Error loading dashboard data.';
    //         console.error(msg, error);
    //         // snack.label = msg;
    //         // snack.type = SnackType.ERROR;
    //         // this.snackbarsService.dismiss().make(snack, 5000).show();
    //       },
    //       () => { }
    //     );
    //   }
    // }
  } // end constructor()

  getCurrentMonthly(): Observable<any> {
    return this.http.get(this.api.endpoints.dashboardDataCurrentMonthly +
      '?id=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getCurrentMonthly()

  getCurrentYearly(): Observable<any> {
    return this.http.get(this.api.endpoints.dashboardDataCurrentYearly +
      '?id=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getCurrentYearly()

  getInvoicedMonthly(): Observable<any> {
    return this.http.get(this.api.endpoints.dashboardDataInvoicedMonthly +
      '?id=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getInvoicedMonthly()

  getInvoicedYearly(): Observable<any> {
    return this.http.get(this.api.endpoints.dashboardDataInvoicedYearly +
      '?id=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getInvoicedYearly()

  getCollectedMonthly(): Observable<any> {
    return this.http.get(this.api.endpoints.dashboardDataCollectedMonthly +
      '?id=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getCollectedMonthly()

  getCollectedYearly(): Observable<any> {
    return this.http.get(this.api.endpoints.dashboardDataCollectedYearly +
      '?id=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getCollectedYearly()

  getCurrentMattersData(currentMonthly: CurrentMonthly): Observable<any> {
    const currentMattersValues = {
      monthly: [
        currentMonthly
          .ThisTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        currentMonthly
          .LastTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        currentMonthly
          .Last2TotalExcl
          .replace(/[A-Z\s,]/gi, '')
      ]
      // ,
      // yearly: [
      //   // currentYearly
      //   //   .thisYearCurrentMatterTotalExcl
      //   //   .replace(/[A-Z\s,]/gi, ''),
      //   // currentYearly
      //   //   .lastYearCurrentMatterTotalExcl
      //   //   .replace(/[A-Z\s,]/gi, ''),
      //   // currentYearly
      //   //   .last2YearCurrentMatterTotalExcl
      //   //   .replace(/[A-Z\s,]/gi, '')
      // ]
    };
    const currentMattersColors = {
      monthly: this.getBarChartColors(currentMattersValues.monthly) // ,
      // yearly: this.getBarChartColors(currentMattersValues.yearly)
    };
    this.currentMattersMonthlyData = {
      monthly: {
        currentTotalLabel: 'Total',
        currentThismonthLabel: currentMonthly.ThisDescription,
        currentLastMonthLabel: currentMonthly.LastDescription,
        currentlast2MonthLast: currentMonthly.Last2Description,

        currentTotalValue: currentMonthly.WorkScore,
        currentThismonthValue: currentMonthly
          .ThisTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        currentLastMonthValue: currentMonthly
          .LastTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        currentMonthBeforValue: currentMonthly
          .Last2TotalExcl
          .replace(/[A-Z\s,]/gi, ''),

        currentRangeStart: currentMonthly.WorkOptimalRangeStartValue,
        currentRangeEnd: currentMonthly.WorkOptimalRangeEndValue,
        currentMaxValue: currentMonthly.WorkMaxValue,
        currentScore: currentMonthly.WorkScore,
        currentSummary: currentMonthly.WorkSummary,
      }
      // },
      // yearly: {
      //   thisYearcurrentLabel: currentYearly.thisDescription,
      //   lastYearcurrentLabel: currentYearly.lastDescription,
      //   last2YearscurrentLabel: currentYearly.last2Description,

      //   thisYearcurrentTotalValue: currentYearly
      //     .thisYearCurrentMatterTotalExcl
      //     .replace(/[A-Z\s,]/gi, ''),
      //   lastYearcurrentValue: currentYearly
      //     .lastYearCurrentMatterTotalExcl
      //     .replace(/[A-Z\s,]/gi, ''),
      //   last2YearscurrentValue: currentYearly
      //     .last2YearCurrentMatterTotalExcl
      //     .replace(/[A-Z\s,]/gi, ''),

      //   thisYearcurrentRangeStart: currentYearly.workOptimalRangeStartValue,
      //   thisYearcurrentRangeEnd: currentYearly.workOptimalRangeEndValue,
      //   thisYearcurrentMaxValue: currentYearly.workMaxValue,
      //   // thisYearcurrentScore: dashboardData.workScore
      // }
    };
    return of(this.currentMattersMonthlyData);
  } // end getCurrentMattersData()

  getCurrentYearlyMattersData(currentYearly: CurrentYearly): Observable<any> {
    const currentMattersValues = {
      // monthly: [
      //   currentMonthly
      //     .thisTotalExcl
      //     .replace(/[A-Z\s,]/gi, ''),
      //   currentMonthly
      //     .lastTotalExcl
      //     .replace(/[A-Z\s,]/gi, ''),
      //   currentMonthly
      //     .last2TotalExcl
      //     .replace(/[A-Z\s,]/gi, '')
      // ]
      // ,
      yearly: [
        currentYearly
          .ThisTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        currentYearly
          .LastTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        currentYearly
          .LastTotalExcl
          .replace(/[A-Z\s,]/gi, '')
      ]
    };
    const currentMattersColors = {
      // monthly: this.getBarChartColors(currentMattersValues.monthly)//,
      yearly: this.getBarChartColors(currentMattersValues.yearly)
    };
    this.currentMattersYearlyData = {
      // monthly: {
      //   currentTotalLabel: 'Total',
      //   currentThismonthLabel: currentMonthly.thisDescription,
      //   currentLastMonthLabel: currentMonthly.lastDescription,
      //   currentlast2MonthLast: currentMonthly.last2Description,

      //   currentTotalValue: currentMonthly.workScore,
      //   currentThismonthValue: currentMonthly
      //     .thisTotalExcl
      //     .replace(/[A-Z\s,]/gi, ''),
      //   currentLastMonthValue: currentMonthly
      //     .lastTotalExcl
      //     .replace(/[A-Z\s,]/gi, ''),
      //   currentMonthBeforValue: currentMonthly
      //     .last2TotalExcl
      //     .replace(/[A-Z\s,]/gi, ''),

      //   currentRangeStart: currentMonthly.workOptimalRangeStartValue,
      //   currentRangeEnd: currentMonthly.workOptimalRangeEndValue,
      //   currentMaxValue: currentMonthly.workMaxValue,
      //   currentScore: currentMonthly.workScore
      // }
      // },
      yearly: {
        thisYearcurrentLabel: currentYearly.ThisDescription,
        lastYearcurrentLabel: currentYearly.LastDescription,
        last2YearscurrentLabel: currentYearly.Last2Description,

        thisYearcurrentTotalValue: currentYearly
          .ThisTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        lastYearcurrentValue: currentYearly
          .LastTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        last2YearscurrentValue: currentYearly
          .Last2TotalExcl
          .replace(/[A-Z\s,]/gi, ''),

        thisYearcurrentRangeStart: currentYearly.WorkOptimalRangeStartValue,
        thisYearcurrentRangeEnd: currentYearly.WorkOptimalRangeEndValue,
        thisYearcurrentMaxValue: currentYearly.WorkMaxValue,
        // thisYearcurrentScore: currentYearly.Sc.workScore
      }
    };
    return of(this.currentMattersYearlyData);
  } // end getCurrentYearlyMattersData()

  getInvoicedData(invoicedMonthly: InvoicedMonthly): Observable<any> {
    const invoicedValues = {
      monthly: [
        invoicedMonthly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        invoicedMonthly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        invoicedMonthly.Last2TotalExcl.replace(/[A-Z\s,]/gi, '')
      ]
      // ,
      // yearly: [
      //   dashboardData.thisYearInvoicedSummary.replace(/[A-Z\s,]/gi, ''),
      //   dashboardData.lastYearInvoicedSummary.replace(/[A-Z\s,]/gi, ''),
      //   dashboardData.last2YearInvoicedSummary.replace(/[A-Z\s,]/gi, '')
      // ]
    };

    // const invoicedMonthlyTarget =
    //   invoicedMonthly.target.replace(/[A-Z\s,]/gi, '');
    // const invoicedMonthlyRecord =
    //   invoicedMonthly.record.replace(/[A-Z\s,]/gi, '');

    // const invoicedYearlyRecord =
    //   invoicedMonthly.invoicedYearlyRecord.replace(/[A-Z\s,]/gi, '');

    const invoicedColors = {
      monthly: this.getBarChartColors(invoicedValues.monthly,
        invoicedMonthly.InvoicedMaxValue)
      //   ,
      // yearly: this.getBarChartColors(invoicedValues.yearly,
      //   invoicedYearlyRecord)
    };

    this.invoicedMonthlyData = {
      monthly: {
        invoicedThismonthLabel: invoicedMonthly.ThisDescription,
        invoicedLastMonthLabel: invoicedMonthly.LastDescription,
        invoicedMonthBeforeLast: invoicedMonthly.Last2Description,

        // invoicedTotalValue: invoicedMonthly.workScore,
        invoicedThismonthValue: invoicedMonthly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        invoicedLastMonthValue: invoicedMonthly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        invoicedMonthBeforValue: invoicedMonthly.Last2TotalExcl.replace(/[A-Z\s,]/gi, ''),

        invoicedRangeStart: invoicedMonthly.InvoicedOptimalRangeStartValue,
        invoicedRangeEnd: invoicedMonthly.InvoicedOptimalRangeEndValue,
        invoicedMaxValue: invoicedMonthly.InvoicedMaxValue,
        // invoicedScore: invoicedMonthly.invoicedMaxValue,
        invoicedMonthlyTarget: invoicedMonthly.Target,
        invoicedMonthlyRecord: invoicedMonthly.Record
      }
      // ,
      // yearly: {
      //   thisYearInvoicedLabel: dashboardData.thisFinancialYearDescription,
      //   lastYearInvoicedLabel: dashboardData.lastFinancialYearDescription,
      //   last2YearsInvoicedLabel: dashboardData.last2FinancialYearDescription,

      //   thisYearInvoicedTotalValue: dashboardData
      //     .thisYearInvoicedSummary
      //     .replace(/[A-Z\s,]/gi, ''),
      //   lastYearInvoicedValue: dashboardData
      //     .lastYearInvoicedSummary
      //     .replace(/[A-Z\s,]/gi, ''),
      //   last2YearsInvoicedValue: dashboardData
      //     .last2YearInvoicedSummary
      //     .replace(/[A-Z\s,]/gi, ''),

      //   thisYearInvoicedRangeStart: dashboardData.yearlyWorkStartValue,
      //   thisYearInvoicedRangeEnd: dashboardData.yearlyWorkEndValue,
      //   thisYearInvoicedMaxValue: dashboardData.yearlyWorkMaxValue,
      //   InvoicedYearlyTarget: dashboardData.invoicedMonthlyTarget,
      //   InvoicedYearlyRecord: dashboardData.invoicedYearlyRecord
      //   // thisYearcurrentScore: dashboardData.workScore
      // }
    };
    return of(this.invoicedMonthlyData);
  } // end getInvoicedData()

  getInvoicedYearlyData(invoicedYearly: InvoicedYearly): Observable<any> {
    const invoicedValues = {
      // monthly: [
      //   invoicedMonthly.thisTotalExcl.replace(/[A-Z\s,]/gi, ''),
      //   invoicedMonthly.lastTotalExcl.replace(/[A-Z\s,]/gi, ''),
      //   invoicedMonthly.last2TotalExcl.replace(/[A-Z\s,]/gi, '')
      // ]
      // ,
      yearly: [
        invoicedYearly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        invoicedYearly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        invoicedYearly.Last2TotalExcl.replace(/[A-Z\s,]/gi, '')
      ]
    };

    // const invoicedMonthlyTarget =
    //   invoicedMonthly.target.replace(/[A-Z\s,]/gi, '');
    // const invoicedMonthlyRecord =
    //   invoicedMonthly.record.replace(/[A-Z\s,]/gi, '');

    const invoicedYearlyRecord =
      invoicedYearly.Record.replace(/[A-Z\s,]/gi, '');

    const invoicedColors = {
      // monthly: this.getBarChartColors(invoicedValues.monthly,
        // invoicedMonthly.invoicedMaxValue)
      //   ,
      yearly: this.getBarChartColors(invoicedValues.yearly,
       invoicedYearlyRecord)
    };

    this.invoicedYearlyData = {
      yearly: {
        thisYearInvoicedLabel: invoicedYearly.ThisDescription,
        lastYearInvoicedLabel: invoicedYearly.LastDescription,
        last2YearsInvoicedLabel: invoicedYearly.Last2Description,

        thisYearInvoicedTotalValue: invoicedYearly
          .ThisTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        lastYearInvoicedValue: invoicedYearly
          .LastTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        last2YearsInvoicedValue: invoicedYearly
          .Last2TotalExcl
          .replace(/[A-Z\s,]/gi, ''),

        thisYearInvoicedRangeStart: invoicedYearly.InvoicedOptimalRangeStartValue,
        thisYearInvoicedRangeEnd: invoicedYearly.InvoicedOptimalRangeEndValue,
        thisYearInvoicedMaxValue: invoicedYearly.InvoicedMaxValue,
        InvoicedYearlyTarget: invoicedYearly.Target,
        InvoicedYearlyRecord: invoicedYearly.Record
        // thisYearcurrentScore: dashboardData.workScore
      }
    };
    return of(this.invoicedYearlyData);
  } // end getInvoicedYearlyData()

  getCollectedData(collectedMonthly: CollectedMonthly): Observable<any> {
    const collectedValues = {
      monthly: [
        collectedMonthly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        collectedMonthly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        collectedMonthly.Last2TotalExcl.replace(/[A-Z\s,]/gi, '')
      ]
      // ,
      // yearly: [
      //   dashboardData.thisYearCollectedSummary.replace(/[A-Z\s,]/gi, ''),
      //   dashboardData.lastYearCollectedSummary.replace(/[A-Z\s,]/gi, ''),
      //   dashboardData.last2YearCollectedSummary.replace(/[A-Z\s,]/gi, '')
      // ]
    };

    const collectedMonthlyTarget =
      collectedMonthly.Target.replace(/[A-Z\s,]/gi, '');
    const collectedMonthlyRecord =
      collectedMonthly.Record.replace(/[A-Z\s,]/gi, '');

    // const collectedYearlyRecord =
    //   collectedMonthly.collectedYearlyRecord.replace(/[A-Z\s,]/gi, '');

    const collectedColors = {
      monthly: this.getBarChartColors(collectedValues.monthly,
        collectedMonthlyRecord)
      //   ,
      // yearly: this.getBarChartColors(collectedValues.yearly,
      //   collectedYearlyRecord)
    };

    this.collectedMonthlyData = {
      monthly: {
        receiptThismonthLabel: collectedMonthly.ThisDescription,
        receiptLastMonthLabel: collectedMonthly.LastDescription,
        receiptMonthBeforeLast: collectedMonthly.Last2Description,

        // receiptTotalValue: collectedMonthly.collectedScore,
        receiptThismonthValue: collectedMonthly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        receiptLastMonthValue: collectedMonthly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        receiptMonthBeforValue: collectedMonthly.Last2TotalExcl.replace(/[A-Z\s,]/gi, ''),

        receiptRangeStart: collectedMonthly.OptimalRangeStartValue,
        receiptRangeEnd: collectedMonthly.OptimalRangeEndValue,
        receiptMaxValue: collectedMonthly.MaxValue,
        // receiptScore: collectedMonthly.workScore,
        receiptMonthlyTarget: collectedMonthly.Target,
        receiptMonthlyRecord: collectedMonthly.Record
      }
      // ,
      // yearly: {
      //   receiptThisYearLabel: dashboardData.thisFinancialYearDescription,
      //   receiptLastYearLabel: dashboardData.lastFinancialYearDescription,
      //   receiptYearBeforeLast: dashboardData.last2FinancialYearDescription,

      //   // receiptTotalYearValue: dashboardData.collectedScore,
      //   receiptThisYearValue: dashboardData.thisYearCollectedSummary.replace(/[A-Z\s,]/gi, ''),
      //   receiptLastYearValue: dashboardData.lastYearCollectedSummary.replace(/[A-Z\s,]/gi, ''),
      //   receiptYearBeforValue: dashboardData.last2YearCollectedSummary.replace(/[A-Z\s,]/gi, ''),

      //   receiptYearRangeStart: dashboardData.yearlyCollectedStartValue,
      //   receiptYearRangeEnd: dashboardData.yearlyCollectedEndValue,
      //   receiptYearMaxValue: dashboardData.yearlyCollectedMaxValue,
      //   // receiptYearScore: dashboardData.collectedScore,
      //   receiptYearlyTarget: dashboardData.collectedYearlyTarget,
      //   receiptYearlyRecord: dashboardData.collectedYearlyRecord
      // }
    };
    return of(this.collectedMonthlyData);
  } // end getCollectedData()

  getCollectedYearlyData(collectedYearly: CollectedYearly): Observable<any> {
    const collectedValues = {
      yearly: [
        collectedYearly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        collectedYearly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        collectedYearly.Last2TotalExcl.replace(/[A-Z\s,]/gi, '')
      ]
    };

    const collectedYearlyTarget =
      collectedYearly.Target.replace(/[A-Z\s,]/gi, '');
    const collectedYearlyRecord =
      collectedYearly.Record.replace(/[A-Z\s,]/gi, '');

    // const collectedYearlyRecord =
    //   collectedMonthly.collectedYearlyRecord.replace(/[A-Z\s,]/gi, '');

    const collectedColors = {
      yearly: this.getBarChartColors(collectedValues.yearly,
        collectedYearlyRecord)
    };

    this.collectedYearlyData = {
      yearly: {
        receiptThisYearLabel: collectedYearly.ThisDescription,
        receiptLastYearLabel: collectedYearly.LastDescription,
        receiptYearBeforeLast: collectedYearly.Last2Description,

        // receiptTotalYearValue: dashboardData.collectedScore,
        receiptThisYearValue: collectedYearly.ThisTotalExcl.replace(/[A-Z\s,]/gi, ''),
        receiptLastYearValue: collectedYearly.LastTotalExcl.replace(/[A-Z\s,]/gi, ''),
        receiptYearBeforValue: collectedYearly.Last2TotalExcl.replace(/[A-Z\s,]/gi, ''),

        receiptYearRangeStart: collectedYearly.OptimalRangeStartValue,
        receiptYearRangeEnd: collectedYearly.OptimalRangeEndValue,
        receiptYearMaxValue: collectedYearly.MaxValue,
        // receiptYearScore: dashboardData.collectedScore,
        receiptYearlyTarget: collectedYearly.Target,
        receiptYearlyRecord: collectedYearly.Record
      }
    };
    return of(this.collectedYearlyData);
  } // end getCollectedData()

  // getData(): Observable<any> {
  //   return of(this.dashboardData);
  // } // end getData()

  // formatChartCurrency(label, index, labels) {
  //   const value: NumberFormatStyle = label;
  //   return 'P ' + (value / 1000) + 'k';
  // } // end formatCurrency()

  // formatChartDataLabel(label, index, labels) {
  //   const num =
  //     Number.parseFloat(label)
  //       .toLocaleString('en-US', {
  //         minimumFractionDigits: 2
  //       });
  //   return ('P' + num.replace(/,/gi, ' '));
  //   // return (num);
  // } // end formatChartDataLabel()

  /**
   * Returns an array of HEX color values based on values of the given array.
   * The colors assigned are either, GREEN, BLUE or RED where:
   * - GREEN: Values that match or surpass a calculated threshold.
   * - YELLOW: Values that fall slightly below a calculated threshold.
   * - RED: Values that fall greatly below a calculated threshold.
   * @param chartValues An array of chart values.
   * @param targetValue The target value to be used in color calculations.
   */
  getBarChartColors(
    chartValues: Array<any>,
    targetValue: any = null
  ): Array<string> {
    const barChartColors = [];

    const threshold = targetValue
      ? Math.round(targetValue as number)
      : Math.round(Math.max(...chartValues as number[]));

    chartValues.forEach(value => {
      const roundedValue = Math.round(value);

      if (roundedValue >= threshold) {
        barChartColors.push(CHART_COLOR_GREEN);

      } else if (roundedValue < threshold &&
        roundedValue >= (1 / 2) * threshold) {
        barChartColors.push(CHART_COLOR_YELLOW);

      } else if (roundedValue < (1 / 2) * threshold) {
        barChartColors.push(CHART_COLOR_RED);

      }
    });

    return barChartColors;
  } // end getBarChartColors()

  /**
   * Returns the number of ticks to render for a chart based on the
   * given chart values. This function returns tick values that will
   * produce the most even spacing between ticks.
   * @param {Array<*>} chartValues An array of chart values.
   * @return {number} Returns the number of ticks to render for a chart.
   */
  getMaxTicks(chartValues: Array<number>, maxValue: number = null): number {
    let maxTick = DEFAULT_MAX_TICK_VALUE;
    if (!maxValue) {
      maxValue = Math.max(...chartValues);
    }
    const baseTickValue = DEFAULT_MAX_TICK_VALUE;

    if (maxValue < (baseTickValue / 8)) {
      maxTick = (baseTickValue / 8);
    } else if (maxValue < (baseTickValue / 4)) {
      maxTick = (baseTickValue / 4);
    } else if (maxValue < (baseTickValue / 2)) {
      maxTick = (baseTickValue / 2);
    } else if (maxValue < baseTickValue) {
      maxTick = baseTickValue;
    } else if (maxValue > baseTickValue) {
      maxTick = (baseTickValue * 2);
    } else if (maxValue > baseTickValue * 2) {
      maxTick = (baseTickValue * 4);
    } else if (maxValue > baseTickValue * 4) {
      maxTick = (baseTickValue * 8);
    }

    return maxTick;
  } // end getMaxTicks()

} // end DashboardDataService{}
