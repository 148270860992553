import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bar-payment-ratings',
  templateUrl: './bar-payment-ratings.component.html',
  styleUrls: ['./bar-payment-ratings.component.scss']
})
export class BarPaymentRatingsComponent implements OnInit {

  title = 'Law Firm Payment Profile ';
  message = '';


  @Input() isVisible: boolean;
  @Input() lawfirmRatingsData: any;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirm: EventEmitter<any> = new EventEmitter<any>();

  constructor() { } // end Constructor()

  ngOnInit(): void {
    this.message = 'Kindly take note of ' + this.lawfirmRatingsData.Debtor + '\' payment profile:';
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onConfirm() {
    this.confirm.emit();
  } // end onConfirm()

} // end BarPaymentRatingsComponent{}
