import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';

export interface AgeAnalysisData {
    NormalDay: number;
    ZeroDays: number;
    ThirtyDays: number;
    SixtyDays: number;
    NinetyDays: number;
    NinetyEightDays: number;
    InvoiceStatusGroup: number;
    Total: number;
} // end AgeAnalysisData{}

export const DEFAULT_AGE_ANALYSIS_DATA = {
    NormalDay: 0,
    ZeroDays: 0,
    ThirtyDays: 0,
    SixtyDays: 0,
    NinetyDays: 0,
    NinetyEightDays: 0,
    InvoiceStatusGroup: 0,
    Total: 0
}; // end DEFAULT_AGE_ANALYSIS_DATA{}

@Injectable()
export class AgeAnalysisService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { }
}
