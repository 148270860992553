import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserProfileService } from '../../../../services/user-profile/user-profile.service';

@Component({
  selector: 'app-age-analysis-attorney-tooltip',
  templateUrl: './age-analysis-attorney-tooltip.component.html',
  styleUrls: ['./age-analysis-attorney-tooltip.component.scss']
})
export class AgeAnalysisAttorneyTooltipComponent implements OnInit {

  tooltipData: any = [];
  @Input() _tooltipData: any;
  @Input() age: string;

  @Output() onToolTipClick = new EventEmitter<string>();

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private userProfileService: UserProfileService
  ) { } // end constructor()

  ngOnInit() {
    this.tooltipData = this._tooltipData;
  } // end ngOnInit()

  onViewAllCustomerReport(age: string) {
    this.onToolTipClick.emit(age);
  } // end onViewAllCustomerReport()

  getBottomRow(index: number, length: number): string {
    if (length > 0 && index === length - 1) {
      return '20px';
    }
    return '0px';
  } // end getBottomRow()

  numberConversion(value: number): string {
    return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  } // end numberConversion()
} // end AgeAnalysisAttorneyTooltipComponent{}
