<!-- Invoices Added to proof of payment -->
<!-- Allocate balance to law firm -->
<div *ngIf="promptInvoices" class="row invoices-container">
    <div class="row invoices-header">
        Invoices added to proof of payment “{{proofOfPayment.Reference}}”
    </div>
    <div class="row invoices-body">
        <div class="text-center p-50">
            <b style="color:#cf624f; font-weight:900;">No auto-matched invoices were found for this proof of payment.
            </b>
            <br>Allocate invoices, add credit notes or allocate to law firm below.
        </div>
    </div>

    <div class="row invoices-footer">
        <div class="col-sm-5 p-0">
            <button class="form-control  btn" (click)="allocateInvoices()">Allocate balance to law firm</button>
        </div>

        <div class="col-sm-5 offset-sm-2 p-0">
            <button class="form-control cursor btn" disabled>Save Proof of Payment</button>
        </div>
    </div>
</div>


<!-- Save Proof of Payment -->
<div *ngIf="promptPaidInvoices" class="row invoices-container">
    <div class="row invoices-header">
        Invoices added to proof of payment “{{proofOfPayment.Reference}}”
    </div>

    <div class="row invoices-actions">
        <div class=" col-sm-1">Actions</div>
        <div class=" col-sm-4" [class.active]="showActions">
            <a (click)="onRemoveInvoices()"><i class="fal fa-file-times"></i><u>Remove selected</u>
            </a>
        </div>
    </div>

    <div class="row invoices-body">
        <div class="table-responsive">
            <table class="table ">
                <tbody>
                    <tr>
                        <th scope="col" class="column-check-box">
                            <div class="checkbox">
                                <input
                                    type="checkbox"
                                    (change)="toggleSelectAll()">
                            </div>
                        </th>
                        <th scope="col" style="min-width: 79px;">Date</th>
                        <th scope="col">Invoice</th>
                        <th scope="col">Law Firm</th>
                        <th scope="col">Matter</th>
                        <th scope="col">Reference</th>
                        <th scope="col" style="min-width: 79px;" class="text-right">Amount</th>
                        <th scope="col" class="text-right pr-3">Paid</th>
                    </tr>
                    <tr *ngFor="let invoices of initPopLineHandle">
                        <td scope="row">
                            <!-- <div class="checkbox">
                                <input type="checkbox" tabindex="-1"
                                    (change)="onChangeCheckBox(invoices.data, $event.target.checked)"
                                    id="i{{invoices.data.InvoiceId}}">
                                <label for="i{{invoices.data.InvoiceId}}"></label>
                            </div> -->
                            <app-small-checkbox
                                [(checked)]="invoices.state.isSelected"
                                (click)="toggleCheckbox(invoices)"></app-small-checkbox>
                        </td>
                        <td style="min-width: 79px;" (click)="toggleCheckbox(invoices)">{{invoices.data.InvoiceDate | date:'yyyy-MM-dd'}}</td>
                        <td><u class="clickable" (click)="onViewInvoice(invoices.data.InvoiceId)">{{invoices.data.Invoice}}</u></td>
                        <td class="text-truncate" (click)="toggleCheckbox(invoices)">{{lawFirmName}}</td>
                        <td class="text-truncate" (click)="toggleCheckbox(invoices)">{{invoices.data.Matter}}</td>
                        <td class="text-truncate" (click)="toggleCheckbox(invoices)">{{invoices.data.Reference}}</td>
                        <td style="min-width: 79px;" class="text-right" (click)="toggleCheckbox(invoices)">{{invoices.data.Amount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                        <td class="text-right paid">
                            <!-- <input type="text"
                                name="amount"
                                [pattern]="amontPattern"
                                (focus)="onFocus($event,invoices.Id)"
                                (focusout)="onFocusOut($event,invoices.Id)"
                                [(ngModel)]="invoices.Paid"
                                (blur)="onAmountChange()"
                                class="form-control"
                                autocomplete="off" required> -->
                                <input
                                    class="form-control"
                                    [ngStyle]="{'border-color' : invoices.data.Paid === '0' || invoices.data.Paid === 0 ? '#cf624f':'#e7e4e4'}"
                                    type="text"
                                    id="amount"
                                    [ngModel]="invoices.data.Paid | currency: currencySymbol: currencySymbol:'1.2-2'"
                                    [ngModelOptions]="{updateOn: 'blur'}"
                                    (ngModelChange)="invoices.data.Paid=$event"
                                    (ngModelChange)="onTotalsCalculateChange('edit')"
                                    (blur)="onAmountChange(invoices.data, $event)"
                                    (focus)="onAmountClicked(invoices.data, $event)"
                                    (focus)="$event.target.select()"
                                    pattern="[P-R\s]*[0-9,.]+"
                                    autocomplete="off"
                                    #inputAmount>
                        </td>
                        <app-prompt-unsaved-changes-modal
                            message="Make sure the amount paid is not more than invoice due amount."
                            [isVisible]="showErrorMessagePrompt"
                            btnTextCancel="OK"
                            btnTextContinue="Cancel"
                            (cancel)="onHideErrorMessagePrompt()"
                            (continue)="onHideErrorMessagePrompt()"></app-prompt-unsaved-changes-modal>
                    </tr>
                    <tr *ngIf="allocateRow">
                        <td scope="row">
                            <div class="checkbox">
                                <input type="checkbox"
                                [(ngModel)]="inputlawfirmCheckbox"
                                 tabindex="-1"
                                    (change)="onLawfirmCheckBoxChange(allocateID, $event.target.checked)" id="a{{allocateID}}">
                                <label style="right: 16px;" for="a{{allocateID}}"></label>
                            </div>
                        </td>
                        <td scope="col">{{invoicesDate | date:'yyyy-MM-dd'}}</td>
                        <td scope="col"></td>
                        <td scope="col" class="text-truncate">
                            {{lawFirmName}}
                        </td>
                        <td scope="col"></td>
                        <td scope="col"></td>
                        <td scope="col"></td>
                        <td style="min-width: 96px;" scope="col" class="text-right paid pr-3">{{allocateAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                    </tr>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col" style="min-width: 96px;" colspan="2" style="font-weight: 900;">{{totalAmountLeft | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                        <th scope="col" style="font-weight: 900;">Left to indicate</th>
                        <th scope="col"></th>
                        <th scope="col" style="font-weight: 900;">Total:</th>
                        <th scope="col" style="min-width: 96px;" colspan="2" class="text-right paid">{{totalAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row invoices-footer">
        <span *ngIf="hasAnError" class="text-danger">Make sure the amount paid is not more than proof of payment amount.</span>
        <span *ngIf="showZeroErrorPaid" class="text-danger">You cannot have R0.00 amount paid, please remove the invoice(s) or
            update the paid amount.</span>
    </div>

    <div class="row invoices-footer">

        <div class="col-sm-5 p-0">
            <button
                class="form-control btn"
                (click)="allocateInvoices()"
                style=" display: none"
                [class.display-block]="allocateButton">Allocate balance to law firm</button>
        </div>

        <div class="col-sm-5 offset-sm-2 p-0">
            <button
                class="form-control btn"
                (click)="saveInvoices()"
                [disabled]="!saveButton">Save Proof of Payment</button>
        </div>
    </div>
</div>
<app-unpaid-invoices-proof-of-payment
    *ngIf="unPaidInvoiceHandles"
    [showUnPaid]="showUnPaidInvoices"
    [(proofOfPayment)]="proofOfPayment"
    [(_unPaidInvoiceHandles)]="unPaidInvoiceHandles"
    (promptPaidInvoices)="onPaidInvoices($event)"></app-unpaid-invoices-proof-of-payment>

<app-delete-confirm-model
    title="Selected Invoices Remove"
    message="Proof of Payments Invoices must be Remove from predefined list."
    [isVisible]="showRemoveInvoices"
    (cancel)="onHideRemoveInvoices()"
    (confirm)="onRemoveInvoices()"></app-delete-confirm-model>

    <app-prompt-unsaved-changes-modal
        message="Are you sure you want to leave this screen? You have unsaved changes."
        [isVisible]="showUnsavedChangesPrompt"
        (cancel)="onHideUnsavedChangesPrompt()"
        (continue)="continue()"
        (confirm)="onHideUnsavedChangesPrompt()"></app-prompt-unsaved-changes-modal>

        <!-- <app-proforma-invoice
            #proformaInvoice
            title="Invoice" 
            popId="0" 
            [invoiceId]="selectedInvoiceId" 
            documentType="Invoice"
            matter="null" 
            [isVisible]="showProformaInvoice" 
            (closeModal)="closeModal('proforma-invoice')">
        </app-proforma-invoice> -->
<app-invoice-preview-modal
    *ngIf="showProformaInvoice" 
    #invoicePreview 
    [notesHandle]="notesHandle"
    [plaintiff]="invoicePlaintiff" 
    [selectedTab]="selectedTab" 
    [defendant]="invoiceDefendant"
    [invoiceId]="selectedInvoiceId" 
    [invoiceDetails]="invoiceDetails" 
    [isVisible]="showProformaInvoice"
    (closeModal)="closeModal('proforma-invoice')">
</app-invoice-preview-modal>