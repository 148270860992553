<form name="form" #f="ngForm" novalidate>
  <div class="modal">
    <div class="modal-content">
      <div class="modal-dialog">

        <div class="modal-header">
          <h2>{{title}} {{invoiceDetails.OutstandingBalance | currency: currencySymbol: currencySymbol:'1.2-2' }} for
            invoice | INV {{invoiceDetails.InvoiceNo}}</h2>
          <button class="modal-dismiss" (click)="dismiss()">
            <i class="fal fa-times-circle"></i>
          </button>
        </div>
        <!--#end .modal-header -->

        <div class="modal-body form">

          <div class="row m-0">
            <div class="col-sm-4 pl-0 pr-5">
              <label for="">Invoice Amount</label>
              <br /><label
                for="">{{invoiceDetails.InvoiceTotal | currency: currencySymbol: currencySymbol:'1.2-2' }}</label>

            </div>


            <div class="col-sm-4 pl-5 pr-0">
              <label for="">Payment & Credits</label>
              <br /> <label for="">{{paymentAndCredits | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>

            </div>
            <div class="col-sm-4 pl-5 pr-0" style="color: #cf624f;">
              <label for="">Amount Outstanding</label>
              <br /><label
                for="">{{invoiceDetails.OutstandingBalance | currency: currencySymbol: currencySymbol:'1.2-2' }}</label>

            </div>
          </div>
          <div class="row m-0">
            <div class="col-sm-12 pl-0 pr-0">
              <label for="">Notes <span title="Required field"> *</span>&nbsp;</label>
              <div class="input-group">
                <textarea #Notes="ngModel" [ngStyle]="{'border-color' : isNotes == false ? '#cf624f':'#e7e4e4'}"
                  (blur)="onWriteOffNotesBlur()" name="Notes" [(ngModel)]="writeOff.Notes" class="form-control"
                  autocomplete="off" required></textarea>
              </div>
            </div>
          </div>
        </div>
        <!--#end .modal-body -->

        <div class="modal-footer">
          <button class="btn btn-danger" (click)="dismiss()">CANCEL</button>
          <button class="btn btn-success" (click)="onAddWriteOff()" [class.disabled]="!f.form.valid"
            [disabled]="!f.form.valid">ADD
            Write-Off</button>
        </div>
        <!--#end .modal-footer -->

      </div>
      <!--#end .modal-dialog -->
    </div>
    <!--#end .modal-content -->
  </div>
  <!--#end .modal -->
</form>