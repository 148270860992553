import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ReportsService } from '../../../services/reports/reports.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Subscription } from 'rxjs';

import * as moment from 'moment';
import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Axis from 'd3-axis';
import * as d3Array from 'd3-array';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { DashboardDataService } from '../../../services/dashboard-data/dashboard-data.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
declare var google: any;

@Component({
  selector: 'app-tax-liability',
  templateUrl: './tax-liability.component.html',
  styleUrls: ['./tax-liability.component.scss']
})


export class TaxLiabilityComponent implements OnInit {

  @Input() graphWidth: number;
  @Input() graphHeight: number;
  @Input() graphMargin: any;
  subscription: Subscription;

  activeTab: string = 'graph';
  totalVATAmount = 0;
  totalProvisionalTax = 0;
  totalPTP = 0;
  ratio: '30';
  chartData: any = [];

  currentPreriodFromDate = '';
  currentPreriodToDate = '';
  lastStatementDate = '';
  lastTransactionDate = '';
  vatNo = '';

  pdfSafeUrl: SafeResourceUrl | undefined;
  pdfUrlVAT = 'https://auxconstoreprd.blob.core.windows.net/tax-and-vat/VAT Guide for Advocates - LA Final.pdf';
  pdfUrlTAX = 'https://auxconstoreprd.blob.core.windows.net/tax-and-vat/Income Tax Obligations for Advocates - LA Final.pdf';

  get isRegisteredforVAT(): boolean {
    if (this.userProfileService.userProfile.isImpersonator) {
      return Boolean(this.util.hasTextValue(this.userProfileService.selectedUserProfile.personalDetails.vatNumber));
    } else {
      return Boolean(this.util.hasTextValue(this.userProfileService.userProfile.personalDetails.vatNumber));
    }
  } // end isRegisteredForVAT()

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private util: UtilitiesService,
    private sanitizer: DomSanitizer,
    private reportService: ReportsService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private dashboardDataService: DashboardDataService
  ) { } // end constructor()

  ngOnInit(): void {
    this.getTaxLiabilityData();
    this.fetchProvisionalTaxData();
    this.reportService.getTaxationDates().subscribe({next: (data) => {
      // On next
      this.currentPreriodFromDate = data.currentPreriodFromDate;
      this.currentPreriodToDate = data.currentPreriodToDate;
      this.lastStatementDate = data.lastStatementDate;
      this.lastTransactionDate = data.lastTransactionDate;
      this.vatNo = data.vatNo;
    }});
  } // end ngOnInit()

  getTaxLiabilityData() {
    this.dashboardDataService.getTaxLiabilityData().subscribe({ next: (data) => {
      // On next
      this.totalVATAmount = data.taxAmount;
      this.totalProvisionalTax = data.provisionalTaxAmount;
      this.totalPTP = data.provisonal_Tax;
      // this.vat = data.taxAmount;
      // this.provisionalTax = data.provisionalTaxAmount;
      // this.ptd = data.provisonal_Tax;
    }})
  }

  drawChart() {
    let data = this.chartData;
    let values = [];
    values.push([
      'Month',
      'VAT', { 'type': 'string', 'role': 'tooltip', 'p': { 'html': true } },
      'Provisional Tax', { 'type': 'string', 'role': 'tooltip', 'p': { 'html': true } },
      'Provisional Tax (PTD)', { 'type': 'string', 'role': 'tooltip', 'p': { 'html': true } }
    ]);
    data.forEach(element => {
      var temp = [];
      temp.push(element["month"]);
      temp.push(element["taxAmount"]);
      temp.push(this.createCustomTooltip(element["currencySymbol"], 'VAT', element["MonthForTooltip"], element["taxAmount"]));
      temp.push(element["provisionalTaxAmount"]);
      temp.push(this.createCustomTooltip(element["currencySymbol"], 'Provisional Tax', element["MonthForTooltip"], element["provisionalTaxAmount"]));
      temp.push(element["provisonal_Tax"]);
      temp.push(this.createCustomTooltip(element["currencySymbol"], 'PTD', element["MonthForTooltip"], element["provisonal_Tax"]));
      values.push(temp);
    });

    const GoogleChartData = google.visualization.arrayToDataTable(values);
    const options: any = {
      tooltip: { isHtml: true },
      theme: 'material',
      colors: ['#5e97d8', '#8abd37', '#eb9e02'],
      vAxis: { format: 'R' },
      legend: { position: 'none' },
      width: this.graphWidth + 350,
      height: this.graphHeight,
      axes: { x: { 0: { side: 'bottom', label: '' } } }
    };
    var chart = new google.visualization.ColumnChart(document.getElementById('TaxLiabilityChart'));
    chart.draw(GoogleChartData, options);
  }

  createCustomTooltip(currencySymbol, FieldName, MonthName, Amount) {
    return '<div style="white-space: nowrap; padding: 5px;">'
      + FieldName + ' amount for <b>' + MonthName + '</b><br />' +
      '<table style="width: 100%;"><tr><td>Amount:</td><th style="text-align: right;">' + currencySymbol + this.numberConversion(Amount) + '</th></tr></table></div>';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onTabClick(tab: string) {
    console.log('SELECTED TAB: ', tab);
    this.activeTab = tab;
    if (this.activeTab === 'vat') {
      this.pdfSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrlVAT);
    } else if (this.activeTab === 'tax') {
      this.pdfSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrlTAX);
    }
  }

  fetchProvisionalTaxData(): void {
    this.loadingService.showOverlay();
    this.subscription = this.reportService.getPracticeManagementParameters().subscribe({
      next: (parameter) => {
        if (parameter === null) {
          return;
        }
        this.reportService.getProvisionalTax(parameter.fromDate, parameter.toDate).subscribe({
          next: (respose) => {
            this.loadGraph(respose, parameter.fromDate, parameter.toDate);
          },
          error: (error) => {
            this.loadingService.hideOverlay();
          },
          complete: () => {
            this.loadingService.hideOverlay();
          }
        });
      },
      error: (error) => {
      }
    });
  }

  loadGraph(graphData, startDate, endDate): void {
    this.loadingService.showOverlay();

    // if (graphData.length == 0) {
    //   this.totalVATAmount = this.totalProvisionalTax = '';
    //   this.totalPTP = '';
    // }

    var dateStart = moment(startDate);
    var dateEnd = moment(endDate);
    var timeValues = [];

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      timeValues.push({ year: dateStart.format('YYYY'), month: dateStart.format('M') });
      dateStart.add(1, 'month');
    }

    let totalVATAmount = 0;
    let totalProvisionalTax = 0;
    let totalPTP = 0;

    this.chartData = [];
    timeValues.map(t => {
      const obj = graphData.find(x => x.year == t.year && x.month == t.month);
      if (obj) {
        this.chartData.push({
          month: `${moment().month(t.month - 1).format('MMM')}' ${t.year.toString().substr(-2)}`,
          MonthForTooltip: `${moment().month(t.month - 1).format('MMMM')} ${t.year.toString()}`,
          taxAmount: obj.taxAmount,
          provisionalTaxAmount: obj.provisionalTaxAmount,
          provisonal_Tax: obj.provisonal_Tax,
          currencySymbol: obj.currencySymbol
        });

        // totalVATAmount += obj.taxAmount;
        // totalProvisionalTax += obj.provisionalTaxAmount;
        // totalPTP += obj.provisonal_Tax;
      }
      else {
        this.chartData.push({
          month: `${moment().month(t.month - 1).format('MMM')}' ${t.year.toString().substr(-2)}`,
          MonthForTooltip: `${moment().month(t.month - 1).format('MMM')} ${t.year.toString().substr(-2)}`,
          taxAmount: 0,
          provisionalTaxAmount: 0,
          provisonal_Tax: 0,
          currencySymbol: graphData.length > 0 ? graphData[0].currencySymbol : ''
        });
      }
    });

    // if (graphData.length > 0) {
    //   this.totalVATAmount = `${graphData[0].currencySymbol}${this.numberConversion(totalVATAmount.toFixed(2))}`;
    //   this.totalProvisionalTax = `${graphData[0].currencySymbol}${this.numberConversion(totalProvisionalTax.toFixed(2))}`;
    //   this.totalPTP = `${graphData[0].currencySymbol}${this.numberConversion(totalPTP.toFixed(2))}`;
    // }

    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(() => { this.drawChart() });
    this.loadingService.hideOverlay();
  }

  numberConversion(x): string {
    var parts = (+x).toFixed(2).split('.');
    return parts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}