import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CurrencyMaskModule } from 'ng2-currency-mask';
// import { FullCalendarModule } from 'ng-fullcalendar';
// import { DxDateBoxModule } from 'devextreme-angular';
// import { TooltipModule  } from 'ngx-bootstrap';

import { UserProfileService } from './services/user-profile/user-profile.service';
import { UserSageValidationService } from './services/user-sage-validation/user-sage-validation.service';
import { AppConfigService } from './services/appdata/app-config.service';
import { MattersService } from './services/matters/matters.service';
import { DashboardDataService } from './services/dashboard-data/dashboard-data.service';
import { DashboardNewService } from './services/dashboard-new/dashboard-new.service';
import { PracticeManagerDashboardService } from './services/practice-manager-dashoboard-service/practice-manager-dashboard.service';
import { ModalStackService } from './services/modal-stack/modal-stack.service';
import { ToastService } from './services/messaging/toast/toast.service';
import { EmailService } from './services/messaging/email/email.service';
import { ApiService } from './services/api.service';

import { ComponentTitleDirective } from "./directives/component-title/component-title.directive";
import { ComponentDataDirective } from "./directives/component-data/component-data.directive";
import { ComponentIdDirective } from "./directives/component-id/component-id.directive";
import { ComponentIsVisibleDirective } from "./directives/component-is-visibile/component-is-visible.directive";

import { AppComponent } from './app.component';
import { AdvocateDashboardComponent } from './pages/advocate-dashboard/advocate-dashboard.component';
import { AdvocateCalendarComponent } from './pages/advocate-calendar/advocate-calendar.component';
import { AdvocateReportsComponent } from './pages/advocate-reports/advocate-reports.component';
import { AdvocateSearchComponent } from './pages/advocate-search/advocate-search.component';
import { AdvocateMessagesComponent } from './pages/advocate-messages/advocate-messages.component';
import { AdvocateMattersComponent } from './pages/advocate-matters/advocate-matters.component';
import { AdvocateProcessComponent } from './pages/advocate-process/advocate-process.component';
import { AppNavbarComponent } from './components/navbars/app-navbar/app-navbar.component';
import { LoginNavbarComponent } from './components/navbars/login-navbar/login-navbar.component';
import { AdvocateLandingComponent } from './pages/advocate-landing/advocate-landing.component';
import { SnackbarComponent } from './components/messaging/snackbar/snackbar.component';
import { ContactAuxconModalComponent } from './components/modals/contact-auxcon-modal/contact-auxcon-modal.component';
import { ChartSummaryComponent } from './components/infographics/chart-summary/chart-summary.component';
import { ProfileModalComponent } from './components/modals/profile-modal/profile-modal/profile-modal.component';
import {
  BriefsAndMattersTableComponent
} from './components/tables/briefs-and-matters-table/briefs-and-matters-table/briefs-and-matters-table.component';
import {
  CircularIndicatorComponent
} from './components/progress-indicators/circular-indicator/circular-indicator/circular-indicator.component';
import { NewLawFirmModalComponent } from './components/modals/new-law-firm-modal/new-law-firm-modal/new-law-firm-modal.component';
import { NewAttorneyModalComponent } from './components/modals/new-attorney-modal/new-attorney-modal/new-attorney-modal.component';
import { DeleteMatterModalComponent } from './components/modals/delete-matter-modal/delete-matter-modal/delete-matter-modal.component';
import { SingleMatterPopupMenuComponent } from './components/navs/single-matter-popup-menu/single-matter-popup-menu.component';
import { AdvocateInvoiceEmailComponent } from './pages/advocate-invoice-email/advocate-invoice-email.component';
import { MatterDetailsComponent } from './components/tables/matter-details/matter-details/matter-details.component';
import { FeeItemsService } from './services/fee-items/fee-items.service';
import { MatterFeesTableComponent } from './components/tables/matter-fees-table/matter-fees-table/matter-fees-table.component';
import { UtilitiesService } from './services/utilities.service';
import { SnackbarsService } from './services/messaging/snackbars/snackbars.service';
import { DeleteFeeModalComponent } from './components/modals/delete-fee-modal/delete-fee-modal/delete-fee-modal.component';
import { FromCalendarItemsService } from './services/from-calendar-items/from-calendar-items.service';
import {
  CreateFeeTemplateModalComponent
} from './components/modals/create-fee-template-modal/create-fee-template-modal/create-fee-template-modal.component';
import { ToastComponent } from './components/messaging/toast/toast.component';
import { DatalistComponent } from './components/inputs/datalist/datalist.component';
import { ListsService } from './services/lists/lists.service';
import { BriefsService } from './services/briefs/briefs.service';
import { MultipleMattersPopupMenuComponent } from './components/navs/multiple-matters-popup-menu/multiple-matters-popup-menu.component';
import { SearchService } from './services/search/search.service';
import { SearchResultsTableRowComponent } from './components/tables/rows/search-results-table-row/search-results-table-row.component';
import { SentMessagesService } from './services/sent-messages/sent-messages.service';
import { SentMessagePopupMenuComponent } from './components/navs/sent-message-popup-menu/sent-message-popup-menu.component';
import { AppNavigationService } from './services/app-navigation/app-navigation.service';
import { AdvocateInvoiceReviewComponent } from './pages/advocate-invoice-review/advocate-invoice-review.component';
import { InvoiceDetailsService } from './services/invoice-details/invoice-details.service';
import { DialogModalComponent } from './components/modals/dialog-modal/dialog-modal.component';
import { FullscreenModalComponent } from './components/modals/fullscreen-modal/fullscreen-modal.component';
import { EditFeeTemplateComponent } from './components/modals/edit-fee-template-modal/edit-fee-template-modal.component';
import { FeeTemplateService } from './services/fee-template/fee-template.service';
import { RegistrationModalComponent } from './components/modals/registration-modal/registration-modal.component';
import { AdvocateEditFeeTemplatesComponent } from './pages/advocate-edit-fee-templates/advocate-edit-fee-templates.component';
import { CheckboxComponent } from './components/inputs/checkbox/checkbox.component';
import { ContactsService } from './services/contacts/contacts.service';
import { AdvocateSignOutComponent } from './pages/advocate-sign-out/advocate-sign-out.component';
import { AdvocateSignInComponent } from './pages/advocate-sign-in/advocate-sign-in.component';
import { MessagesService } from './services/messaging/messages/messages.service';
import { PageNotFoundComponent } from './pages/error/page-not-found/page-not-found.component';
import { InternalServerErrorComponent } from './pages/error/internal-server-error/internal-server-error.component';
import { InvoiceDocumentService } from './services/invoice-document/invoice-document.service';
import { DatalistContactComponent } from './components/inputs/datalist-contact/datalist-contact.component';
import { ClientContactsService } from './services/client-contacts/client-contacts.service';
import { AttorneysService } from './services/attorneys/attorneys.service';
import {
  DeleteMultipleMatterModalComponent
} from './components/modals/delete-multiple-matter-modal/delete-multiple-matter-modal.component';
import { CreateRecipientModalComponent } from './components/modals/create-recipient-modal/create-recipient-modal.component';
import { ReportViewerComponent } from './report-viewer/report-viewer.component';
import { AddFeeCalendarModalComponent } from './components/modals/add-fee-calendar-modal/add-fee-calendar-modal.component';
import { CategoriesService } from './services/categories/categories.service';
import { SubCategoriesService } from './services/sub-categories/sub-categories.service';
import { DatalistMatterComponent } from './components/inputs/datalist-matter/datalist-matter.component';
import {
  AddLawfirmAttorneyService
} from './services/addlawfirm/add-lawfirm-attorney.service';
import { DatalistCategoryComponent } from './components/inputs/datalist-category/datalist-category.component';
import { AdvocateProcessInvoicesComponent } from './pages/advocate-process-invoices/advocate-process-invoices.component';
import { PlaintiffsService } from './services/plaintiffs/plaintiffs.service';
import { DefendantsService } from './services/defendants/defendants.service';
import { ProformaInvoiceComponent } from './components/modals/proforma-invoice/proforma-invoice.component';
import { ReportsService } from './services/reports/reports.service';
import { DataService } from './services/messaging/data/data.service';
import { InvoiceModalComponent } from './components/modals/invoice-modal/invoice-modal.component';
import { MultipleInvoicesTableComponent } from './components/tables/multiple-invoices-table/multiple-invoices-table.component';
import { SingleInvoicePopupMenuComponent } from './components/navs/single-invoice-popup-menu/single-invoice-popup-menu.component';
import { InvoiceMessageService } from './services/invoice-message/invoice-message.service';
import { LoadingOverlayModalComponent } from './components/modals/loading-overlay-modal/loading-overlay-modal.component';
import { DatePickerComponent } from './components/inputs/date-picker/date-picker.component';
import { LoadingService } from './services/messaging/loading/loading.service';
import { AddMultipleFeeModalComponent } from './components/modals/add-multiple-fee-modal/add-multiple-fee-modal.component';
import {
  AddPlaintiffdefendantModalComponent
} from './components/modals/add-plaintiffdefendant-modal/add-plaintiffdefendant-modal.component';
import { DatePickerModalComponent } from './components/modals/date-picker-modal/date-picker-modal.component';
import { InvoiceReviewModalComponent } from './components/modals/invoice-review-modal/invoice-review-modal.component';
import { MultipleFeesService } from './services/multiple-fees/multiple-fees.service';
import {
  PromptUnsavedChangesModalComponent
} from './components/modals/prompt-unsaved-changes-modal/prompt-unsaved-changes-modal.component';

import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { jqxChartComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxchart";

import { AuthenticationGuard } from './common/guards/authentication-guard';
import { ChartsSummaryComponent } from './components/infographics/charts-summary/charts-summary.component';
import { InvoicedChartComponent } from './components/infographics/invoiced-chart/invoiced-chart.component';
import { ReceiptChartComponent } from './components/infographics/receipt-chart/receipt-chart.component';
import { DatabaseService } from './services/storage/database.service';
import { SaveInvoiceModalComponent } from './components/modals/save-invoice-modal/save-invoice-modal.component';
import { PromptLeavePageComponent } from './components/modals/prompt-leave-page/prompt-leave-page.component';
import { NavigationGuard } from './common/guards/navigation.guard';
import { NavigationService } from './services/navigation/navigation.service';
import { AlertComponent } from './components/modals/alert/alert.component';
import { AdvocateSignInLoadingComponent } from './pages/advocate-sign-in-loading/advocate-sign-in-loading.component';
import { AuthService } from './services/auth/auth.service';
//  import { TokenInterceptor } from './interceptors/auth/token.interceptor';
import { NetworkConnectivityService } from './services/network-connectivity/network-connectivity.service';
import { PromptNoFeesComponent } from './components/modals/prompt-no-fees/prompt-no-fees.component';
import { OnlineStatusModalComponent } from './components/modals/online-status-modal/online-status-modal.component';
import { UsercurrencyPipe } from './pipe/usercurrency.pipe';
import { UsercurrencyDirective } from './directive/usercurrency.directive';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { DatalistLawfirmComponent } from './components/inputs/datalist-lawfirm/datalist-lawfirm.component';
import { DatalistPlaintiffsComponent } from './components/inputs/datalist-plaintiffs/datalist-plaintiffs.component';
import { AdvocateEditMyTeamComponent } from './pages/advocate-edit-my-team/advocate-edit-my-team.component';
import { AddTeamMemberModalComponent } from './components/modals/add-team-member-modal/add-team-member-modal.component';
import { AddTeamMemberService } from './services/addteam/add-team-member.service';
import { AddNewClientComponent } from './components/modals/add-new-client/add-new-client.component';
import { AttorneyDashboardComponent } from './pages/attorney-dashboard/attorney-dashboard.component';
import { LiveStatementComponent } from './components/tables/live-statement/live-statement.component';
import { LiveStatementService } from './services/live-statement/live-statement.service';
import { AdvocateDetailStatementService } from './services/advocate-detail-statement/advocate-detail-statement.service';
import { AdvocateDetailComponent } from './pages/analytics/advocate-detail/advocate-detail.component';
import { BreadcrumbComponent } from './components/navs/breadcrumb/breadcrumb.component';
import { AdvocateDetailTableComponent } from './components/tables/advocate-detail-table/advocate-detail-table.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { UnpaidInvoicesComponent } from './pages/analytics/unpaid-invoices/unpaid-invoices.component';
import { InvoiceComponent } from './pages/analytics/invoice/invoice.component';
import { MatterComponent } from './pages/analytics/matter/matter.component';
import {
  AdvocateDetailReportTableComponent
} from './components/tables/advocate-detail-report-table/advocate-detail-report-table.component';
import { PaymentDetailModalComponent } from './components/modals/payment-detail-modal/payment-detail-modal.component';
import { PaymentDetailService } from './services/payment-detail/payment-detail.service';
import { InvoicePreviewComponent } from './pages/advocate-messages/invoice-preview/invoice-preview.component';
import { AddEditClientsComponent } from './pages/add-edit-clients/add-edit-clients.component';
import { OtherClientsService } from './services/other-clients/other-clients.service';
import { AgeAnalysisComponent } from './components/infographics/age-analysis/age-analysis.component';
import { InvoicedCollectedSummaryComponent } from './components/reports/invoiced-collected-summary/invoiced-collected-summary.component';
import { DashboardOverviewComponent } from './components/infographics/dashboard-overview/dashboard-overview.component';
import { AgeAnalysisReportComponent } from './components/reports/age-analysis/age-analysis.component';
import { AllCustomersReportComponent } from './components/reports/all-customers-report/all-customers-report.component';
import {
  CustomersReportPaymentRatingComponent
} from './components/reports/customer-report-payment-rating/customer-report-payment-rating.component';
import { AdvocateInvoicePreviewComponent } from './pages/advocate-invoice-preview/advocate-invoice-preview.component';
import { IndividualCustomerReportComponent } from './components/reports/individual-customer-report/individual-customer-report.component';
import { AddInvoiceNoteComponent } from './components/modals/add-invoice-note/add-invoice-note.component';
import { AgeAnalysisToolTipComponent } from './components/reports/age-analysis/age-analysis-tooltip/age-analysis-tooltip.component';
import { PracticeSummaryComponent } from './components/reports/practice-summary/practice-summary.component';
import { InvoicedCollectedWidgetComponent } from './components/reports/invoiced-collected-widget/invoiced-collected-widget.component';
import { LiveStatementPopupMenuComponent } from './components/navs/live-statement-popup-menu/live-statement-popup-menu.component';
import {
  LiveStatementFooterPopupMenuComponent
} from './components/navs/live-statement-footer-popup-menu/live-statement-footer-popup-menu.component';
import { PromptJoinAuxconComponent } from './components/modals/prompt-join-auxcon/prompt-join-auxcon.component';
import { AdvocateStatementModalComponent } from './components/modals/advocate-statement-modal/advocate-statement-modal.component';
import { PromptSaveContactModalComponent } from './components/modals/prompt-save-contact-modal/prompt-save-contact-modal.component';
import { ExcelServiceService } from './services/excel-service/excel-service.service';
import { InvoicesService } from './services/invoices/invoices.service';

import { ProcessPaymentService } from "./services/process-payment/process-payment.service";
import { ProofOfPaymentsComponent } from "./components/proof-of-payments/proof-of-payment/proof-of-payment.component";
import { AddProofOfPaymentsComponent } from "./components/proof-of-payments/add-proof-of-payment/add-proof-of-payment.component";
import { ManageProofOfPaymentsComponent } from "./pages/advocate-process/manage-proof-of-payments/manage-proof-of-payments.component";
import { InvoicesAddedProofOfPaymentsComponent } from "./components/proof-of-payments/invoices-added-proof-of-payment/invoices-added-proof-of-payment.component";
import { UnpaidInvoicesProofOfPaymentsComponent } from "./components/proof-of-payments/unpaid-invoices-proof-of-payment/unpaid-invoices-proof-of-payment.component";
import { ReconcileBankTransactionsComponent } from "./pages/advocate-process/reconcile-bank-transactions/reconcile-bank-transactions.component";
import { NewBankTransactionsComponent } from "./components/reconcile-bank-transaction/new-bank-transactions/new-bank-transactions.component";
import { ProcessedBankTransactionsComponent } from "./components/reconcile-bank-transaction/processed-bank-transactions/processed-bank-transactions.component";
import { ReconcileBankAllocateModelComponent } from "./components/reconcile-bank-transaction/reconcile-bank-allocate-model/reconcile-bank-allocate-model.component";
import { InvoicesAddedBankTransactionComponent } from "./components/reconcile-bank-transaction/invoices-added-bank-transaction/invoices-added-bank-transaction.component";
import { InvoicesProcessedBankTransactionComponent } from "./components/reconcile-bank-transaction/invoices-processed-bank-transaction/invoices-processed-bank-transaction.component";
import { MoveConfirmModelComponent } from "./components/reconcile-bank-transaction/move-confirm-model/move-confirm-model.component";
import { AvailableInvoicesBankTransactionComponent } from "./components/reconcile-bank-transaction/available-invoices-bank-transaction/available-invoices-bank-transaction.component";
import { ImportBankStatementsComponent } from "./pages/advocate-process/import-bank-statements/import-bank-statements.component";
import { SendBankStatementsComponent } from "./pages/advocate-process/send-bank-statements/send-bank-statements.component";
import { BankAccountsService } from "./services/bank-accounts/bank-accounts.service";

import {
  CustomerReportInvoicePreviewComponent
} from './components/reports/individual-customer-report/customer-report-invoice-preview/customer-report-invoice-preview.component';
import { ReportPaymentRatingComponent } from './pages/advocate-reports/report-payment-rating/report-payment-rating.component';
import {
  ReportIndividualCustomerComponent
} from './pages/advocate-reports/report-payment-rating/report-individual-customer/report-individual-customer.component';
import { ReportAgeAnalysisComponent } from './pages/advocate-reports/report-age-analysis/report-age-analysis.component';
import {
  ReportCustomerInvoicePreviewComponent
} from './pages/advocate-reports/report-payment-rating/report-individual-customer/report-customer-invoice-preview/report-customer-invoice-preview.component';
import {
  ReportAllCustomersComponent
} from './pages/advocate-reports/report-age-analysis/report-all-customers/report-all-customers.component';
import {
  AgeAnalysisIndividualCustomerComponent
} from './pages/advocate-reports/report-age-analysis/report-all-customers/age-analysis-individual-customer/age-analysis-individual-customer.component';
import {
  AgeAnalysisInvoiceComponent
} from './pages/advocate-reports/report-age-analysis/report-all-customers/age-analysis-individual-customer/age-analysis-invoice/age-analysis-invoice.component';
import { ReportInvoiceCollectedComponent } from './pages/advocate-reports/report-invoice-collected/report-invoice-collected.component';
import {
  ReportAllcustomerReportComponent
} from './pages/advocate-reports/report-allcustomer-report/report-allcustomer-report.component';
import {
  PracticeAllCustomersReportComponent
} from './components/reports/practice-all-customers-report/practice-all-customers-report.component';
import {
  RIndividualCustomerComponent
} from './pages/advocate-reports/report-allcustomer-report/r-individual-customer/r-individual-customer.component';
import {
  RIndividualInvoiceComponent
} from './pages/advocate-reports/report-allcustomer-report/r-individual-customer/r-individual-invoice/r-individual-invoice.component';
import {
  TheIndividualCustomerComponent
} from './pages/advocate-reports/the-individual-customer/the-individual-customer.component';
import {
  TheInvoicePreviewComponent
} from './pages/advocate-reports/the-individual-customer/the-invoice-preview/the-invoice-preview.component';
import { SearchedInvoicePreviewComponent } from './pages/advocate-search/searched-invoice-preview/searched-invoice-preview.component';
import { DeleteConfirmModelComponent } from './components/modals/delete-confirm-model/delete-confirm-model.component';
import { ImageViewerComponent } from './components/modals/image-viewer/image-viewer.component';
import { IndustryReportsComponent } from './components/reports/industry-reports/industry-reports.component';
import { SmallCheckboxComponent } from './components/inputs/small-checkbox/small-checkbox.component';
import { SendInvoiceToComponent } from './components/navs/send-invoice-to/send-invoice-to.component';
import { SuccessAlertComponent } from './components/modals/success-alert/success-alert.component';
import { AddStatementRecipientComponent } from './components/modals/add-statement-recipient/add-statement-recipient.component';
import { ManageBankAccountsComponent } from './pages/advocate-process/manage-bank-accounts/manage-bank-accounts.component';
import { AddEditBankAccountComponent } from './components/modals/add-edit-bank-account/add-edit-bank-account.component';
import {
  ReconInvoicePreviewComponent
} from './pages/advocate-process/reconcile-bank-transactions/recon-invoice-preview/recon-invoice-preview.component';
import { LawfirmAgeAnalysisComponent } from './components/live-statement/lawfirm-age-analysis/lawfirm-age-analysis.component';
import {
  LawfirmAgeAnalysisTooltipComponent
} from './components/live-statement/lawfirm-age-analysis/lawfirm-age-analysis-tooltip/lawfirm-age-analysis-tooltip.component';
import { LawFirmAllCustomersComponent } from './components/live-statement/law-firm-all-customers/law-firm-all-customers.component';
import { AllAdvocatesComponent } from './pages/attorney-dashboard/all-advocates/all-advocates.component';
import { ImportErrorMessageComponent } from './components/modals/import-error-message/import-error-message.component';
import { PracticeMDashboardComponent } from './pages/practice-m-dashboard/practice-m-dashboard.component';
import {
  PracticeManagerDashboardOverviewComponent
} from './components/infographics/practice-manager-dashboard-overview/practice-manager-dashboard-overview.component';
import {
  PracticeManagerInvoicedChartComponent
} from './components/infographics/practice-manager-invoiced-chart/practice-manager-invoiced-chart.component';
import {
  PracticeManagerReceiptChartComponent
} from './components/infographics/practice-manager-receipt-chart/practice-manager-receipt-chart.component';
import { PracticeMSummaryComponent } from './components/practice-management/practice-m-summary/practice-m-summary.component';
import { PracticeOverSummaryComponent } from './components/practice-management/practice-over-summary/practice-over-summary.component';
import { PracticeAdvocateDetailComponent } from './pages/advocate-reports/practice-advocate-detail/practice-advocate-detail.component';
import { PracticeAdvocateHealthService } from './services/practice-advocate-health/practice-advocate-health.service';
import {
  PracticeAdvocateHealthTableComponent
} from './components/practice-management/practice-advocate-health-table/practice-advocate-health-table.component';
import { PracticeManagerReportComponent } from './pages/advocate-reports/practice-manager-report/practice-manager-report.component';
import {
  PracticeAdvocateSummaryComponent
} from './components/practice-management/practice-advocate-summary/practice-advocate-summary.component';
import { PracticeAgeAnalysisComponent } from './components/practice-management/practice-age-analysis/practice-age-analysis.component';
import {
  PracticeAllCustomerComponent
} from './pages/advocate-reports/practice-manager-report/practice-all-customer/practice-all-customer.component';
import {
  PracticeAllCustomerReportComponent
} from './components/practice-management/practice-all-customer-report/practice-all-customer-report.component';
import {
  PracticeAgeAnalysisTooltipComponent
} from './components/practice-management/practice-age-analysis/practice-age-analysis-tooltip/practice-age-analysis-tooltip.component';
import { PracticeByLawfirmComponent } from './components/practice-management/practice-by-lawfirm/practice-by-lawfirm.component';
import {
  PracticeFirmDetailComponent
} from './components/practice-management/practice-by-lawfirm/practice-firm-detail/practice-firm-detail.component';
import { InvoicePreviewModalComponent } from './components/modals/invoice-preview-modal/invoice-preview-modal.component';
import { ClientStatementModalComponent } from './components/modals/client-statement-modal/client-statement-modal.component';
import { FileDropDirective } from './file-drop.directive';
import { FileUploadService } from './services/file-upload/file-upload.service';
import { UploadFileTypeModalComponent } from './components/modals/upload-file-type-modal/upload-file-type-modal.component';
import { ClientMatterDocumentsComponent } from './components/modals/client-matter-documents/client-matter-documents.component';
import { FilesToBeSentComponent } from './components/modals/files-to-be-sent/files-to-be-sent.component';
import { AttorneyAgeAnalysisComponent } from './pages/attorney-dashboard/attorney-age-analysis/attorney-age-analysis.component';
import { AgeAnalysisAttorneyComponent } from './components/live-statement/age-analysis-attorney/age-analysis-attorney.component';
import {
  AgeAnalysisAttorneyTooltipComponent
} from './components/live-statement/age-analysis-attorney/age-analysis-attorney-tooltip/age-analysis-attorney-tooltip.component';
import { AttorneyAllCustomerComponent } from './pages/attorney-dashboard/attorney-all-customer/attorney-all-customer.component';
import { AllCustomerAttorneyComponent } from './components/live-statement/all-customer-attorney/all-customer-attorney.component';
import {
  AttorneyPerCustomerComponent
} from './pages/attorney-dashboard/attorney-all-customer/attorney-per-customer/attorney-per-customer.component';
import { BriefPreviewModalComponent } from './components/modals/brief-preview-modal/brief-preview-modal.component';
import { UploadFileTypeEditModalComponent } from './components/modals/upload-file-type-edit-modal/upload-file-type-edit-modal.component';
import { ActivityLogsService } from './services/activity-logs/activity-logs.service';
import {
  AttoneryBriefPreviewModalComponent
} from './components/modals/attonery-brief-preview-modal/attonery-brief-preview-modal.component';
import { UnallocatedTransactionComponent } from './components/modals/unallocated-transaction/unallocated-transaction.component';
import { UpdateReferenceModalComponent } from './components/modals/update-reference-modal/update-reference-modal.component';
import { AttorneyUpdateModalComponent } from './components/modals/attorney-update-modal/attorney-update-modal.component';
import { AngularSplitModule } from 'angular-split';
import {
  WriteOffUnderPaymentModelComponent
} from './components/reconcile-bank-transaction/write-off-under-payment-model/write-off-under-payment-model.component';
import { AddCreditModalComponent } from './components/modals/add-credit-modal/add-credit-modal.component';
import { WriteOffOutstandingModalComponent } from './components/modals/write-off-outstanding-modal/write-off-outstanding-modal.component';
import { SendCreditNoteModalComponent } from './components/modals/send-credit-note-modal/send-credit-note-modal.component';
import { AddLawFirmProcessComponent } from './components/modals/add-law-firm-process/add-law-firm-process.component';
import { ClientNoteModalComponent } from './components/modals/client-note-modal/client-note-modal.component';
import { SmallRadioboxComponent } from './components/inputs/small-radiobox/small-radiobox.component';
import { HandOverToLawfirmComponent } from './components/modals/hand-over-to-lawfirm/hand-over-to-lawfirm.component';
import { NotesService } from './services/notes/notes.service';
import { NoteDetailModalComponent } from './components/modals/note-detail-modal/note-detail-modal.component';
import { AdvocateNoteModalComponent } from './components/modals/advocate-note-modal/advocate-note-modal.component';
import { PractiseManagerMessagesComponent } from './pages/practise-manager-messages/practise-manager-messages.component';
import { InvoiceNoteModalComponent } from './components/modals/note-detail-modal/invoice-note-modal/invoice-note-modal.component';
import { DatalistCategoryMultilineComponent } from './components/inputs/datalist-category-multiline/datalist-category-multiline.component';
import { MultipleInvoiceNoteComponent } from './components/modals/multiple-invoice-note/multiple-invoice-note.component';
import {
  LiveStatementInvoicePreviewComponent
} from './components/modals/live-statement-invoice-preview/live-statement-invoice-preview.component';
import {
  ClientMultipleInvoiceNoteComponent
} from './components/modals/client-multiple-invoice-note/client-multiple-invoice-note.component';
import { AdvocateOverviewComponent } from './pages/advocate-overview/advocate-overview.component';
import {
  AdvocateOverviewDashboardComponent
} from './components/infographics/advocate-overview-dashboard/advocate-overview-dashboard.component';
import { AdvocateOverviewServiceService } from './services/advocate-overview-service/advocate-overview-service.service';
import { OverviewInvoicedChartComponent } from './components/infographics/overview-invoiced-chart/overview-invoiced-chart.component';
import { OverviewCollectedChartComponent } from './components/infographics/overview-collected-chart/overview-collected-chart.component';
import { OverviewSummaryComponent } from './components/infographics/overview-summary/overview-summary.component';
import { PersonalDetailsComponent } from './components/advocate-overview/personal-details/personal-details.component';
import { FinancialDetailsComponent } from './components/advocate-overview/financial-details/financial-details.component';
import { ContactDetailsComponent } from './components/advocate-overview/contact-details/contact-details.component';
import { SubscriptionComponent } from './components/advocate-overview/subscription/subscription.component';
import { AddRelationModalComponent } from './components/modals/add-relation-modal/add-relation-modal.component';
import { AdvocatesService } from './services/advocates/advocates.service';
import { ChambersService } from './services/chambers/chambers.service';
import { DatalistChambersComponent } from './components/inputs/datalist-chambers/datalist-chambers.component';
import { ConversationComponent } from './components/advocate-overview/conversation/conversation.component';
import { StickyNoteService } from './services/sticky-note/sticky-note.service';
import { QuotationService } from './services/quotation/quotation.service';
import { SingleQuotationsPopupMenuComponent } from './components/navs/single-quotations-popup-menu/single-quotations-popup-menu.component';
import { AdvocateQuotationComponent } from './pages/advocate-quotation/advocate-quotation.component';
import { MatterQuotationDetailsComponent } from './components/quotation/matter-quotation-details/matter-quotation-details.component';
import { DatalistQuotationLawfirmComponent } from './components/inputs/datalist-quotation-lawfirm/datalist-quotation-lawfirm.component';
import { DatalistQuotationPlaintiffsComponent } from './components/inputs/datalist-quotation-plaintiffs/datalist-quotation-plaintiffs.component';
import { QuotationMatterFeesComponent } from './components/quotation/quotation-matter-fees/quotation-matter-fees.component';
import { DatalistQuotationCategoryComponent } from './components/inputs/datalist-quotation-category/datalist-quotation-category.component';
import { AdvocateQuotationPreviewComponent } from './pages/advocate-quotation-preview/advocate-quotation-preview.component';
import { AddQuotationNoteComponent } from './components/modals/add-quotation-note/add-quotation-note.component';
import { AdvocateInfoComponent } from './components/advocate-overview/advocate-info/advocate-info.component';
import { SubscriptionSummaryComponent } from './components/tables/subscription-summary/subscription-summary.component';
import { CancelledSubscriptionComponent } from './pages/error/cancelled-subscription/cancelled-subscription.component';
import { LawfirmInvoicePreviewComponent } from './components/modals/lawfirm-invoice-preview/lawfirm-invoice-preview.component';
import { AdvocateQuotationEmailComponent } from './pages/advocate-quotation-email/advocate-quotation-email.component';
import { DeleteQuotationModalComponent } from './components/modals/delete-quotation-modal/delete-quotation-modal.component';
import { AddActualQuotationNoteComponent } from './components/modals/add-actual-quotation-note/add-actual-quotation-note.component';
import { DeleteMultipleQuotationModalComponent } from './components/modals/delete-multiple-quotation-modal/delete-multiple-quotation-modal.component';
import { QuotationPreviewModalComponent } from './components/modals/quotation-preview-modal/quotation-preview-modal.component';
import { AddQuotationAcceptRejectReasonComponent } from './components/modals/add-quotation-accept-reject-reason/add-quotation-accept-reject-reason.component';
import { DiscountingModalComponent } from './components/modals/discounting-modal/discounting-modal.component';
import { CollectionDocumentsComponent } from './components/modals/collection-documents/collection-documents.component';
import { DebtorProgressComponent } from './components/modals/debtor-progress/debtor-progress.component';
import { AddOtherPaymentTermsComponent } from './components/modals/add-other-payment-terms/add-other-payment-terms.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { CreateNewUserComponent } from './pages/create-new-user/create-new-user.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { AmazingTimePickerModule } from '@jonijnm/amazing-time-picker';
import { ImportHistoricalComponent } from './components/modals/import-historical/import-historical.component';
import { MultipleHistoricalPopupMenuComponent } from './components/navs/multiple-historical-popup-menu/multiple-historical-popup-menu.component';
import { HistoricalDetailsComponent } from './components/tables/historical-details/historical-details.component';
import { HistoricalFeeTableComponent } from './components/tables/historical-fee-table/historical-fee-table.component';
import { ImportAuxconDataComponent } from './components/modals/import-auxcon-data/import-auxcon-data.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ContactManagementComponent } from './pages/admin/contact-management/contact-management.component';
import { ManageMeetingDateComponent } from './components/modals/manage-meeting-date/manage-meeting-date.component';
import { AddNewAttorneyComponent } from './components/modals/add-new-attorney/add-new-attorney.component';
import { EditExistingAttorneyComponent } from './components/modals/edit-existing-attorney/edit-existing-attorney.component';
import { AddNewLawFirmComponent } from './components/modals/add-new-law-firm/add-new-law-firm.component';
import { DatalistLocalitiesComponent } from './components/inputs/datalist-localities/datalist-localities.component';
import { AdvocatesComponent } from './components/contact-management/advocates/advocates.component';
import { SecretaryComponent } from './components/contact-management/secretary/secretary.component';
import { AddNewAdvocateComponent } from './components/modals/add-new-advocate/add-new-advocate.component';
import { AssignAdvocateToSecretaryComponent } from './components/modals/assign-advocate-to-secretary/assign-advocate-to-secretary.component';
import { AddNewSecretaryComponent } from './components/modals/add-new-secretary/add-new-secretary.component';
import { DatalistAdvocatesComponent } from './components/inputs/datalist-advocates/datalist-advocates.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { WriteOffMultipleInvoicesComponent } from './components/modals/write-off-multiple-invoices/write-off-multiple-invoices.component';
import { CollectionsReportComponent } from './pages/admin/collections-report/collections-report.component';
import { FeeTariffsComponent } from './components/modals/fee-tariffs/fee-tariffs.component';
import { PlateformBankTransactionsComponent } from './pages/advocate-process/plateform-bank-transactions/plateform-bank-transactions.component';
import { UserSageValidationComponent } from './pages/user-sage-validation/user-sage-validation.component';
import { AdvocateTaxComponent } from './pages/advocate-reports/advocate-tax/advocate-tax.component';
import { TaxLiabilityComponent } from './components/reports/tax-liability/tax-liability.component';
import { TaxLiabilityWidgetComponent } from './components/reports/tax-liability-widget/tax-liability-widget.component';
import { AdHocInstructionComponent } from './pages/ad-hoc-instruction/ad-hoc-instruction.component';
//import { WriteOffMultipleInvoicesComponent } from './components/modals/write-off-multiple-invoices/write-off-multiple-invoices.component';
import { AddBlackListModalComponent } from './components/modals/add-BlackList-modal/add-BlackList-modal.component';
import { ManageBlackListService } from './services/manage-BlackList/manage-BlackList.service';
import { ManageBlackListComponent } from './pages/manage-BlackList/manage-BlackList.component';
import { GCBResponseHandlerComponent } from './pages/gcb-response-handler/gcb-response-handler.component';

import { InviteComponent } from './components/modals/invite/invite.component';
import { InviteService } from './services/invite/invite.service';
import { UserSingupComponent } from './pages/user-singup/user-singup.component';
import { SuccessOrErrorComponent } from './components/modals/success-or-error/success-or-error.component';
import { BlacklistPdfViewerComponent } from './pages/blacklist-pdf-viewer/blacklist-pdf-viewer.component';
import { AdvocateListComponent } from './components/manage-blacklist/advocate-list/advocate-list.component';
import { RequestNewAdvocateGcbModalComponent } from './components/modals/request-new-advocate-gcb-modal/request-new-advocate-gcb-modal.component';
import { DocumentTypesModalComponent } from './components/modals/document-types-modal/document-types-modal.component';
import { DocumentServiceService } from './services/documents/document-service.service';
import { PromptMessageOkConfirmComponent } from './components/modals/prompt-message-ok-confirm/prompt-message-ok-confirm.component';
import { ReportToGcbQuestionsComponent } from './components/modals/report-to-gcb-questions/report-to-gcb-questions.component';
import { AddLPCBlacklistModalComponent } from './components/modals/add-lpc-blacklist-modal/add-lpc-blacklist-modal.component';
import { EditLpcBlacklistModalComponent } from './components/modals/edit-lpc-blacklist-modal/edit-lpc-blacklist-modal.component';
import { BarsocietyNewLawfirmRequestModalComponent } from './components/modals/barsociety-new-lawfirm-request-modal/barsociety-new-lawfirm-request-modal.component';
import { BarPaymentRatingsComponent } from './components/modals/bar-payment-ratings/bar-payment-ratings.component';
import { OnboardingRegisterComponent } from './components/onboarding/onboarding-register/onboarding-register.component';
import { OnboardingHeaderComponent } from './components/onboarding/onboarding-header/onboarding-header.component';
import { OnboardingResponseHandlerComponent } from './components/onboarding/onboarding-response-handler/onboarding-response-handler.component';
import { SubscriptionBillingComponent } from './components/subscriptions/subscription-billing/subscription-billing.component';
import { SubscriptionsPlansComponent } from './components/subscriptions/subscriptions-plans/subscriptions-plans.component';
import { PromptSubscriptionComponent } from './components/modals/prompt-subscription/prompt-subscription.component';
import { PaymentResponseHandlerComponent } from './components/subscriptions/payment-response-handler/payment-response-handler.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { UnallocatedCategoriesComponent } from './components/modals/unallocated-categories/unallocated-categories.component';
import { OutlookHandlerComponent } from './components/outlook-calendar/outlook-handler/outlook-handler.component';
import { AddNewMatterComponent } from './components/modals/add-new-matter/add-new-matter.component';
import { OutlookSyncComponent } from './components/outlook-sync/outlook-sync.component';
import { BankAccountModalComponent } from './components/modals/bank-account-modal/bank-account-modal.component';
import { ProfileSettingsComponent } from './components/advocate-overview/profile-settings/profile-settings.component';
import { AddArbitrationComponent } from './components/modals/add-arbitration/add-arbitration.component';
import { AdvocateArbitrationInvoicesComponent } from './pages/advocate-arbitration-invoices/advocate-arbitration-invoices.component';
import { StateInvoiceStatusComponent } from './components/modals/state-invoice-status/state-invoice-status.component';
import { InvoiceLineDisputeComponent } from './components/modals/invoice-line-dispute/invoice-line-dispute.component';
import { InvoiceDisputeComponent } from './components/modals/invoice-dispute/invoice-dispute.component';
import { InvoiceLineDetailModalComponent } from './components/modals/invoice-line-detail-modal/invoice-line-detail-modal.component';
import { QueryReportProgressComponent } from './components/modals/query-report-progress/query-report-progress.component';
import { UploadHistoricalDataModalComponent } from './components/modals/upload-historical-data-modal/upload-historical-data-modal.component';
import { AdHocRegistrationComponent } from './components/onboarding/ad-hoc-registration/ad-hoc-registration.component';
import { EditTransactionComponent } from './components/modals/edit-transaction/edit-transaction.component';
import { TermsAndConditionsComponent } from './components/modals/terms-and-conditions/terms-and-conditions.component';
import { EditMyProfileComponent } from './pages/edit-my-profile/edit-my-profile.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
])

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal


export function loggerCallback(logLevel: LogLevel, message: string) {

}
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: `https://login.microsoftonline.com/common/`,
      redirectUri: environment.redirectUrl
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
      //storeAuthStateInCookie: true,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string> | null>();
  protectedResourceMap.set(`${environment.apiBaseUrl}/Notes/*`, null);
  protectedResourceMap.set(`${environment.apiBaseUrl}/Onboarding/*`, null);
  protectedResourceMap.set(`${environment.apiBaseUrl}/edit-my-profile/*`, null);
  protectedResourceMap.set(`${environment.apiBaseUrl}/*`, [`${environment.clientId}/.default`]);
  
  // protectedResourceMap.set(`${environment.apiBaseUrl}/Notes/*`, []);
  // protectedResourceMap.set('http://localhost:3821/api/Notes/AdHocRequest?Id=b78bfc5e-ee66-454c-f309-08db7159b469', []);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [`${environment.clientId}/.default`]
      // scopes: [`${environment.clientId}/.default`, '/Notes/*']
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AdvocateDashboardComponent,
    AdvocateCalendarComponent,
    AdvocateReportsComponent,
    AdvocateSearchComponent,
    AdvocateMessagesComponent,
    AdvocateMattersComponent,
    AdvocateProcessComponent,
    AdvocateLandingComponent,
    AppNavbarComponent,
    LoginNavbarComponent,
    SnackbarComponent,
    ContactAuxconModalComponent,
    ChartSummaryComponent,
    ComponentTitleDirective,
    ComponentDataDirective,
    ComponentIdDirective,
    ProfileModalComponent,
    ComponentIsVisibleDirective,
    BriefsAndMattersTableComponent,
    CircularIndicatorComponent,
    NewLawFirmModalComponent,
    NewAttorneyModalComponent,
    DeleteMatterModalComponent,
    SingleMatterPopupMenuComponent,
    AdvocateInvoiceEmailComponent,
    MatterDetailsComponent,
    MatterFeesTableComponent,
    DeleteFeeModalComponent,
    CreateFeeTemplateModalComponent,
    ToastComponent,
    DatalistComponent,
    MultipleMattersPopupMenuComponent,
    SearchResultsTableRowComponent,
    SentMessagePopupMenuComponent,
    AdvocateInvoiceReviewComponent,
    DialogModalComponent,
    FullscreenModalComponent,
    EditFeeTemplateComponent,
    RegistrationModalComponent,
    AdvocateEditFeeTemplatesComponent,
    CheckboxComponent,
    AdvocateSignOutComponent,
    AdvocateSignInComponent,
    PageNotFoundComponent,
    InternalServerErrorComponent,
    DatalistContactComponent,
    DatalistChambersComponent,
    // CreateRecipientModalComponent,
    ReportViewerComponent,
    DeleteMultipleMatterModalComponent,
    CreateRecipientModalComponent,
    AddFeeCalendarModalComponent,
    DatalistMatterComponent,
    DatalistCategoryComponent,
    ProformaInvoiceComponent,
    AdvocateProcessInvoicesComponent,
    InvoiceModalComponent,
    MultipleInvoicesTableComponent,
    SingleInvoicePopupMenuComponent,
    AddMultipleFeeModalComponent,
    AddPlaintiffdefendantModalComponent,
    LoadingOverlayModalComponent,
    DatePickerComponent,
    AddMultipleFeeModalComponent,
    DatePickerModalComponent,
    InvoiceReviewModalComponent,
    PromptUnsavedChangesModalComponent,
    jqxChartComponent,
    ChartsSummaryComponent,
    InvoicedChartComponent,
    ReceiptChartComponent,
    SaveInvoiceModalComponent,
    PromptLeavePageComponent,
    AlertComponent,
    AdvocateSignInLoadingComponent,
    PromptNoFeesComponent,
    OnlineStatusModalComponent,
    UsercurrencyPipe,
    UsercurrencyDirective,
    DatalistLawfirmComponent,
    DatalistPlaintiffsComponent,
    AdvocateEditMyTeamComponent,
    AddTeamMemberModalComponent,
    AddNewClientComponent,
    AgeAnalysisComponent,
    AttorneyDashboardComponent,
    LiveStatementComponent,
    AdvocateDetailComponent,
    BreadcrumbComponent,
    AdvocateDetailTableComponent,
    ContactDetailsComponent,
    AnalyticsComponent,
    UnpaidInvoicesComponent,
    InvoiceComponent,
    MatterComponent,
    AdvocateDetailReportTableComponent,
    PaymentDetailModalComponent,
    InvoicePreviewComponent,
    AddEditClientsComponent,
    InvoicedCollectedSummaryComponent,
    DashboardOverviewComponent,
    AgeAnalysisReportComponent,
    AllCustomersReportComponent,
    CustomersReportPaymentRatingComponent,
    AdvocateInvoicePreviewComponent,
    IndividualCustomerReportComponent,
    AddInvoiceNoteComponent,
    AgeAnalysisToolTipComponent,
    PracticeSummaryComponent,
    LiveStatementPopupMenuComponent,
    LiveStatementFooterPopupMenuComponent,
    PromptJoinAuxconComponent,
    AdvocateStatementModalComponent,
    PromptSaveContactModalComponent,
    InvoicedCollectedWidgetComponent,
    ProofOfPaymentsComponent,
    ManageProofOfPaymentsComponent,
    ReconcileBankTransactionsComponent,
    NewBankTransactionsComponent,
    ProcessedBankTransactionsComponent,
    ReconcileBankAllocateModelComponent,
    InvoicesAddedBankTransactionComponent,
    InvoicesProcessedBankTransactionComponent,
    MoveConfirmModelComponent,
    AvailableInvoicesBankTransactionComponent,
    ImportBankStatementsComponent,
    SendBankStatementsComponent,
    CustomerReportInvoicePreviewComponent,
    ReportPaymentRatingComponent,
    ReportIndividualCustomerComponent,
    ReportCustomerInvoicePreviewComponent,
    ReportAgeAnalysisComponent,
    ReportAllCustomersComponent,
    AgeAnalysisIndividualCustomerComponent,
    AgeAnalysisInvoiceComponent,
    ReportInvoiceCollectedComponent,
    ReportAllcustomerReportComponent,
    PracticeAllCustomersReportComponent,
    RIndividualCustomerComponent,
    RIndividualInvoiceComponent,
    TheIndividualCustomerComponent,
    TheInvoicePreviewComponent,
    AddProofOfPaymentsComponent,
    InvoicesAddedProofOfPaymentsComponent,
    UnpaidInvoicesProofOfPaymentsComponent,
    SearchedInvoicePreviewComponent,
    DeleteConfirmModelComponent,
    ImageViewerComponent,
    IndustryReportsComponent,
    SmallCheckboxComponent,
    SendInvoiceToComponent,
    SuccessAlertComponent,
    AddStatementRecipientComponent,
    ManageBankAccountsComponent,
    AddEditBankAccountComponent,
    ReconInvoicePreviewComponent,
    LawfirmAgeAnalysisComponent,
    LawfirmAgeAnalysisTooltipComponent,
    LawFirmAllCustomersComponent,
    AllAdvocatesComponent,
    ImportErrorMessageComponent,
    PracticeMDashboardComponent,
    PracticeManagerDashboardOverviewComponent,
    PracticeManagerInvoicedChartComponent,
    PracticeManagerReceiptChartComponent,
    PracticeMSummaryComponent,
    PracticeOverSummaryComponent,
    PracticeAdvocateDetailComponent,
    PracticeAdvocateHealthTableComponent,
    PracticeManagerReportComponent,
    PracticeAdvocateSummaryComponent,
    PracticeAgeAnalysisComponent,
    PracticeAllCustomerComponent,
    PracticeAllCustomerReportComponent,
    PracticeAgeAnalysisTooltipComponent,
    PracticeByLawfirmComponent,
    PracticeFirmDetailComponent,
    InvoicePreviewModalComponent,
    ClientStatementModalComponent,
    FileDropDirective,
    UploadFileTypeModalComponent,
    ClientMatterDocumentsComponent,
    FilesToBeSentComponent,
    AttorneyAgeAnalysisComponent,
    AgeAnalysisAttorneyComponent,
    AgeAnalysisAttorneyTooltipComponent,
    AttorneyAllCustomerComponent,
    AllCustomerAttorneyComponent,
    AttorneyPerCustomerComponent,
    BriefPreviewModalComponent,
    UploadFileTypeEditModalComponent,
    AttoneryBriefPreviewModalComponent,
    UnallocatedTransactionComponent,
    UpdateReferenceModalComponent,
    AttorneyUpdateModalComponent,
    WriteOffUnderPaymentModelComponent,
    AddCreditModalComponent,
    WriteOffOutstandingModalComponent,
    SendCreditNoteModalComponent,
    AddLawFirmProcessComponent,
    ClientNoteModalComponent,
    SmallRadioboxComponent,
    HandOverToLawfirmComponent,
    NoteDetailModalComponent,
    AdvocateNoteModalComponent,
    PractiseManagerMessagesComponent,
    InvoiceNoteModalComponent,
    DatalistCategoryMultilineComponent,
    MultipleInvoiceNoteComponent,
    LiveStatementInvoicePreviewComponent,
    ClientMultipleInvoiceNoteComponent,
    AdvocateOverviewComponent,
    AdvocateOverviewDashboardComponent,
    OverviewInvoicedChartComponent,
    OverviewCollectedChartComponent,
    OverviewSummaryComponent,
    PersonalDetailsComponent,
    FinancialDetailsComponent,
    ContactDetailsComponent,
    SubscriptionComponent,
    AddRelationModalComponent,
    ConversationComponent,
    SingleQuotationsPopupMenuComponent,
    AdvocateQuotationComponent,
    MatterQuotationDetailsComponent,
    DatalistQuotationLawfirmComponent,
    DatalistQuotationPlaintiffsComponent,
    QuotationMatterFeesComponent,
    DatalistQuotationCategoryComponent,
    AdvocateQuotationPreviewComponent,
    AddQuotationNoteComponent,
    AdvocateInfoComponent,
    SubscriptionSummaryComponent,
    CancelledSubscriptionComponent,
    LawfirmInvoicePreviewComponent,
    AdvocateQuotationEmailComponent,
    DeleteQuotationModalComponent,
    AddActualQuotationNoteComponent,
    DeleteMultipleQuotationModalComponent,
    QuotationPreviewModalComponent,
    AddQuotationAcceptRejectReasonComponent,
    DiscountingModalComponent,
    CollectionDocumentsComponent,
    DebtorProgressComponent,
    AddOtherPaymentTermsComponent,
    CreateNewUserComponent,
    ImportHistoricalComponent,
    MultipleHistoricalPopupMenuComponent,
    HistoricalDetailsComponent,
    HistoricalFeeTableComponent,
    ImportAuxconDataComponent,
    AdminComponent,
    ContactManagementComponent,
    ManageMeetingDateComponent,
    AddNewAttorneyComponent,
    EditExistingAttorneyComponent,
    AddNewLawFirmComponent,
    DatalistLocalitiesComponent,
    AdvocatesComponent,
    SecretaryComponent,
    AddNewAdvocateComponent,
    AssignAdvocateToSecretaryComponent,
    AddNewSecretaryComponent,
    DatalistAdvocatesComponent,
    PlateformBankTransactionsComponent,
    UserSageValidationComponent,
    WriteOffMultipleInvoicesComponent,
    CollectionsReportComponent,
    FeeTariffsComponent,
    ManageBlackListComponent,
    AddBlackListModalComponent,
    GCBResponseHandlerComponent,
    InviteComponent,
    UserSingupComponent,
    SuccessOrErrorComponent,
    BlacklistPdfViewerComponent,
    AdvocateListComponent,
    RequestNewAdvocateGcbModalComponent,
    AdvocateTaxComponent,
    TaxLiabilityComponent,
    TaxLiabilityWidgetComponent,
    DocumentTypesModalComponent,
    PromptMessageOkConfirmComponent,
    ReportToGcbQuestionsComponent,
    AddLPCBlacklistModalComponent,
    EditLpcBlacklistModalComponent,
    BarsocietyNewLawfirmRequestModalComponent,
    BarPaymentRatingsComponent,
    OnboardingRegisterComponent,
    OnboardingHeaderComponent,
    OnboardingResponseHandlerComponent,
    SubscriptionBillingComponent,
    SubscriptionsPlansComponent,
    PromptSubscriptionComponent,
    PaymentResponseHandlerComponent,
    AdHocInstructionComponent,
    UnallocatedCategoriesComponent,
    OutlookHandlerComponent,
    AddNewMatterComponent,
    OutlookSyncComponent,
    BankAccountModalComponent,
    ProfileSettingsComponent,
    AddArbitrationComponent,
    AdvocateArbitrationInvoicesComponent,
    StateInvoiceStatusComponent,
    InvoiceLineDisputeComponent,
    InvoiceDisputeComponent,
    InvoiceLineDetailModalComponent,
    QueryReportProgressComponent,
    UploadHistoricalDataModalComponent,
    AdHocRegistrationComponent,
    EditTransactionComponent,
    TermsAndConditionsComponent,
    EditMyProfileComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    GoogleMapsModule,
    // AmazingTimePickerModule,
    AmazingTimePickerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    // AngularFontAwesomeModule,
    CurrencyMaskModule,
    // FullCalendarModule,
    FullCalendarModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    // DxDateBoxModule,
    PdfJsViewerModule,
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    AngularSplitModule.forRoot(),
    FontAwesomeModule,
    MsalModule,
    // MsalModule.forRoot(new PublicClientApplication({
    //   auth: {
    //     clientId: environment.clientId, // Application (client) ID from the app registration
    //     //authority: `https://login.microsoftonline.com/${environment.tenantId}/`, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
    //     authority: `https://login.microsoftonline.com/common/`, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
    //     redirectUri: environment.redirectUrl// This is your redirect URI
    //   },
    //   cache: {
    //     cacheLocation: 'localStorage',
    //     storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    //   }
    // }), null, null),
    NgxPaginationModule
  ],
  providers: [
    UsercurrencyPipe,
    UsercurrencyDirective,
    AppConfigService,
    AppNavigationService,
    DashboardDataService,
    DashboardNewService,
    PracticeManagerDashboardService,
    MattersService,
    BriefsService,
    FeeItemsService,
    ModalStackService,
    ToastService,
    UserProfileService,
    EmailService,
    UtilitiesService,
    SnackbarsService,
    FromCalendarItemsService,
    ListsService,
    SearchService,
    SentMessagesService,
    InvoiceDetailsService,
    InvoiceDocumentService,
    InvoiceMessageService,
    FeeTemplateService,
    ContactsService,
    ClientContactsService,
    AttorneysService,
    ChambersService,
    ApiService,
    MessagesService,
    CategoriesService,
    SubCategoriesService,
    AddLawfirmAttorneyService,
    PlaintiffsService,
    DefendantsService,
    ReportsService,
    ExcelServiceService,
    InvoicesService,
    AdvocatesService,
    NotesService,
    AdvocateOverviewServiceService,
    ProcessPaymentService,
    AuthenticationGuard,
    NavigationGuard,
    DataService,
    LoadingService,
    MultipleFeesService,
    DatabaseService,
    NetworkConnectivityService,
    NavigationService,
    AddTeamMemberService,
    UserSageValidationService,
    LiveStatementService,
    AdvocateDetailStatementService,
    PaymentDetailService,
    OtherClientsService,
    BankAccountsService,
    PracticeAdvocateHealthService,
    FileUploadService,
    ActivityLogsService,
    StickyNoteService,
    QuotationService,
    InviteService,
    AuthService,
    ManageBlackListService,
    DocumentServiceService,
    AuthService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptor,
    //   multi: true
    // },   
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
