import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { ReportsService } from '../../../services/reports/reports.service';
import { DashboardDataService } from '../../../services/dashboard-data/dashboard-data.service';

import {
    InvoicedCollectedWidgetComponent
} from '../invoiced-collected-widget/invoiced-collected-widget.component';
import { Router } from '@angular/router';
import {
        PATH_CUSTOMER_PAYMENT_RATING,
        PATH_REPORTS,
        PATH_REPORT_AGE_ANALYSIS,
        PATH_REPORT_INVOICE_COLLECTED, 
        PATH_REPORT_TAX_LIABILITY} from '../../../services/appdata/app-config.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';



@Component({
    selector: 'app-practice-summary',
    templateUrl: './practice-summary.component.html',
    styleUrls: ['./practice-summary.component.scss']
})
export class PracticeSummaryComponent implements OnInit {

    isDataLoaded = false;
    averagePaymentDays = 0;
    averageDayColor = '';
    worstCustomers: any = [];
    bestCustomers: any = [];

    agingCurrentTotal = 0;
    aging30Total = 0;
    aging60Total = 0;
    aging90Total = 0;
    aging98Total = 0;
    agingCurrentPercentage = 0;
    aging30Percentage = 0;
    aging60Percentage = 0;
    aging90Percentage = 0;
    aging98Percentage = 0;
    agePercentageColor = '';
    showSampleModalDialog = false;


    @Output() onViewMoreClick = new EventEmitter<string>();

    @ViewChild('InvoicedCollectedWidgetComponent') invoicedCollectedWidgetComponent: InvoicedCollectedWidgetComponent;

    get currencySymbol(): string {
        if (this.userProfileService.userProfile.isImpersonator) {
            return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
        } else {
            return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
        }
    } // end currencySymbol()

    constructor(
        private dashboardDataService: DashboardDataService,
        private loadingService: LoadingService,
        private reportsService: ReportsService,
        private userProfileService: UserProfileService,
        private router: Router,
    ) { } // end constructor()



    ngOnInit(): void {

        this.isDataLoaded = false;
        this.loadingService.showOverlay();
        const _this = this;
       // Promise.all([
            this.getAveragePaymentDays()//, 
            this.getBestWorstCustomers()//, 
            this.getAgeAnalysisReport()//]).then(function () {
            //console.log('PROMISE CALLED');
            _this.loadingService.hideOverlay();
            _this.isDataLoaded = true;
        // }).catch(function (error) {

        //     _this.loadingService.hideOverlay();
        //     _this.isDataLoaded = true;
        // });
    } // end ngOnInit()

    onImageShow() {
        this.showSampleModalDialog = true;
    } // end onImageShow()

    onHideSampleModalDialog() {
        this.showSampleModalDialog = false;
    } // end onHideSampleModalDialog()

    getAveragePaymentDays(): Promise<any> {
        const _this = this;
        return new Promise(function (resolve, reject) {
            _this.dashboardDataService.getAveragePaymentDays().subscribe({ next: (response) => {
                if (response.length > 0) {
                    _this.averagePaymentDays = response[0].averageDaysToPayment;
                } else {
                    _this.averagePaymentDays = 0;
                }

                if (_this.averagePaymentDays < 90) {
                    _this.averageDayColor = '#8abd37';
                } else if (_this.averagePaymentDays >= 90 && _this.averagePaymentDays < 120) {
                    _this.averageDayColor = '#e5c100';
                } else if (_this.averagePaymentDays >= 120 && _this.averagePaymentDays < 150) {
                     _this.averageDayColor = '#eb9e02';
                } else if (_this.averagePaymentDays >= 150) {
                    _this.averageDayColor = '#cf624f';
                }

                // resolve();
            }, error: (error) => {
                console.log('AGE PAYMENT DAYS ERROR: ', error)
                reject(error);
            }
        });
        });

    } // end getAveragePaymentDays()

    getBestWorstCustomers(): Promise<any> {
        const _this = this;
        return new Promise(function (resolve, reject) {
            _this.reportsService.getCustomersPaymentRating().subscribe({ next: response => {
                _this.bestCustomers = response.map(a => Object.assign({}, a));
                _this.bestCustomers = _this.bestCustomers.filter(function (f) {
                    return f.bestRanking > 0;
                });
                _this.bestCustomers.sort((a, b) => a.bestRanking - b.bestRanking);
                if (_this.bestCustomers.length > 5) {
                    _this.bestCustomers = _this.bestCustomers.slice(0, 5);
                }

                _this.worstCustomers = response.map(a => Object.assign({}, a));
                _this.worstCustomers = _this.worstCustomers.filter(function (f) {
                    return f.worstRanking > 0 && f.intAvgDays > 98;
                });
                _this.worstCustomers.sort((a, b) => a.worstRanking - b.worstRanking);
                if (_this.worstCustomers.length > 5) {
                    _this.worstCustomers = _this.worstCustomers.slice(0, 5);
                }

                // resolve();
            },
                error: (error) => {
                    console.log('BEST WORST CISTOMER ERROR: ', error)
                    reject(error);
                }
            });
        });
    } // end getBestWorstCustomers()

    getAgeAnalysisReport(): Promise<any> {
        const _this = this;
        return new Promise(function (resolve, reject) {
            _this.reportsService.getAgeAnalysis().subscribe({ next: (response) => {
                if (response) {
                    _this.agingCurrentPercentage = response.agingCurrentPercentage;
                    _this.aging30Percentage = response.aging30Percentage;
                    _this.aging60Percentage = response.aging60Percentage;
                    _this.aging90Percentage = response.aging90Percentage;
                    _this.aging98Percentage = response.aging98Percentage;

                    for (const item of response.frequencyTotals) {
                        _this.aging30Total += item.aging30Total;
                        _this.aging60Total += item.aging60Total;
                        _this.aging90Total += item.aging90Total;
                        _this.aging98Total += item.aging98Total;
                        _this.agingCurrentTotal += item.agingCurrentTotal;
                    }
                }

                if (_this.aging98Percentage <= 25) {
                    _this.agePercentageColor = '#8abd37';
                  } else if (_this.aging98Percentage >= 26 && _this.aging98Percentage <= 49) {
                    _this.agePercentageColor = '#e5c100';
                  } else if (_this.aging98Percentage >= 50 && _this.aging98Percentage <= 75) {
                    _this.agePercentageColor = '#eb9e02';
                  } else if (_this.aging98Percentage >= 76) {
                    _this.agePercentageColor = '#cf624f';
                  }
                // resolve();
            }, error: (error) => {
                reject(error);
            }
        });
        });

    } // end getAgeAnalysisReport()




    numberConversion(value: number): string {
        return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    } // end numberConversion()

    getDays(value: any): string {
        if (!isNaN(value)) {
            return value + ' Days';
        }
        return value;
    } // end getDays()

    getBarHeight(value: number): string {
        return (value * 70 / 100) + 'px';
    } // end getBarHeight()

    getBarTextColor(value: number): string {
        const height = (value * 70 / 100);
        if (height < 17) {
            return '#000';
        }
        return '#fff';
    } // end getBarTextColor()

    getBarTextTopMargin(value: number): string {
        const height = (value * 70 / 100);
        if (height < 17) {
            return '-20px';
        }
        return ((height / 2) - 9) + 'px';
    } // end getBarTextTopMargin()

    onViewMoreReport(reportName: string) {
        if (reportName === 'customerReportPaymentRating') {
            this.router.navigate([PATH_CUSTOMER_PAYMENT_RATING]);
        } else if (reportName === 'invoicedCollectedMonthlySummary') {
            this.router.navigate([PATH_REPORT_INVOICE_COLLECTED]);
        } else if (reportName === 'ageAnalysis') {
            this.router.navigate([PATH_REPORT_AGE_ANALYSIS]);
        } 
        else if (reportName === 'taxLiability') {
            console.log('CAN NAVIGATE TO THE REPORT: ', reportName);
            this.router.navigate([PATH_REPORTS   + '/' + 'report-tax-liability']);
        }
    } // end onViewMoreReport()

} // end PracticeSummaryComponent{}
