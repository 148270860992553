<div *ngIf="isVisible" class="modal">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-header">
                <h2>Update Last Meeting Date</h2>
            </div> <!-- #end .modal-header -->

            <div class="modal-body">
                <div class="container-fluid">

                    <form class="form" autocomplete="off">
                        <div class="form-group" style="margin-top:35px;">
                            <input type="text" id="text" bsDatepicker [value]="AdvocateDetails.LastMeetingDate | date:'yyyy-MM-dd'"
                                (ngModel)="AdvocateDetails.LastMeetingDate=$event.target.value"
                                (bsValueChange)="onDateValueChange($event)"
                                [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}" placement="right">
                        </div>
                        <!--#end .form-group -->
                    </form>

                </div> <!-- #end .container-fluid -->
            </div> <!-- #end .modal-body -->

            <div class="modal-footer">
                <button class="btn btn-danger" (click)="onCancel()">Cancel</button>
                <!-- <span></span> -->
                <button class="btn btn-success" (click)="onSave()">Save</button>
            </div>
            <!--#end .modal-footer -->

        </div> <!-- #end .modal-dialog -->
    </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->