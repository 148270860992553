<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<app-multiple-invoices-table [messageTemplateHandle]="messageTemplateHandle"
  (messageTemplateHandleChange)="onMessageTemplateHandleChange($event)"
  [isHistoricalData]="selectedMatterTab == 'take-on-data' ? true : false">
</app-multiple-invoices-table>

<div class="container-fluid m-0">
  <div class="row" style="padding: 10px;">
    <div class="col-md-12 p-0" style="display: flex; justify-content: space-between;">
      <button class="btn btn-danger" (click)="onCancel()">
        CANCEL
      </button>
      <button *ngIf="selectedMatterTab != 'take-on-data'" class="btn btn-success" [class.disabled]="!isSaveable"
        [disabled]="!isSaveable" (click)="onSendViaEmail()">
        SEND INVOICES
      </button>
    </div>
  </div>
</div>

<!--#end .row -->
<app-prompt-unsaved-changes-modal message="Are you sure you want to leave this page? Your invoices have not been sent."
  [isVisible]="showLeavePagePrompt" (cancel)="onHideLeavePagePrompt()"
  (continue)="onDismiss()"></app-prompt-unsaved-changes-modal>