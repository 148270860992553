<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-header">
                <h2>LPC Complaints for {{currentLaufirmNme}}</h2>
                <button class="modal-dismiss" (click)="onDismiss()">
                    <i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i>
                </button>
            </div><!--#end .modal-header -->
            <div>
                <div class="reconcile-table">
                    <div class="table-responsive" style="min-height:382px; max-height: 382px;">
                        <table class="table table-striped">
                            <tbody>
                                <tr style="position: sticky; top: -1px; z-index: 2; background-color:#dee2e6;">
                                    <td style="width: 22%;"><b>Reporting Advocate</b></td>
                                    <td class="vl" style="width: 9%;"><b>Date Reported</b></td>
                                    <td class="vl" style="width: 10%;"><b>LPC Reference</b> </td>
                                    <td class="vl" style="width: 10%;"><b>LPC Status</b> </td>
                                    <td class="vl" style="width: 10%;"><b>Due Date</b> </td>
                                    <td class="vl" style="width: 20%;"><b>Notes</b> </td>
                                    <td class="vl" style="width: 11%; text-align: center"><b>Documents</b></td>
                                    <td class="vl" style="width: 8%; text-align: center"><b>Action</b></td>
                                </tr>
                                <tr class="accordion-toggle parent" *ngFor="let pendingLawfirm of manageLPCReportData"
                                    data-role="expander">
                                    <td class="inbox-date-column clickable" scope="row">
                                        <span>{{pendingLawfirm.ReportingAdvocateName ?
                                            pendingLawfirm.ReportingAdvocateName :
                                            'Notavailable'}} </span>
                                    </td>
                                    <td class="inbox-date-column clickable vl" scope="row">
                                        {{pendingLawfirm.ReportingDate | date: "yyyy-MM-dd"}}
                                    </td>
                                    <td class="inbox-date-column clickable vl" scope="row">
                                        {{pendingLawfirm.LPCReferenceNo}}
                                    </td>
                                    <td class="inbox-date-column clickable vl" scope="row">
                                        {{pendingLawfirm.LPCStatusName}}
                                    </td>
                                    <td class="inbox-date-column clickable vl" scope="row">
                                        {{pendingLawfirm.DueDate | date: "yyyy-MM-dd"}}
                                    </td>
                                    <td class="inbox-date-column clickable vl" scope="row">
                                        {{pendingLawfirm.Notes}}
                                    </td>
                                    <td class="vl" style="cursor: pointer;">
                                        <i *ngIf="pendingLawfirm.FilePath" class="fal fa-paperclip fa-lg p-1 tool-tip"
                                            (click)="downloadLPCDocument('LOD', pendingLawfirm.LawfirmBlackListID)"
                                            aria-hidden="true">
                                            <div class="tooltiptext">
                                                <p>LOD</p>
                                            </div>
                                        </i>
                                        <i *ngIf="pendingLawfirm.FilePath" class="fal fa-paperclip fa-lg p-1 tool-tip"
                                            (click)="downloadLPCDocument('LPC_Complaint', pendingLawfirm.LawfirmBlackListID)"
                                            aria-hidden="true">
                                            <div class="tooltiptext">
                                                <p>LPC Complaint</p>
                                            </div>
                                        </i>
                                        <i *ngIf="pendingLawfirm.FilePath" class="fal fa-paperclip fa-lg p-1 tool-tip"
                                            (click)="downloadLPCDocument('Statement', pendingLawfirm.LawfirmBlackListID)"
                                            aria-hidden="true">
                                            <div class="tooltiptext">
                                                <p>Advocate Statement</p>
                                            </div>
                                        </i>
                                    </td>
                                    <td class="inbox-date-column clickable vl" style="text-align: center;">
                                        <i class="fa fa-edit fa-lg p-2" style="color: #E5C100;" aria-hidden="true"
                                            (click)="onEditLPC(pendingLawfirm.LawfirmBlackListID,pendingLawfirm.ReportingAdvocateName)"></i>
                                        <i class="fa fa-trash fa-lg p-2" style="color: #cf624f;" aria-hidden="true"
                                            (click)="onDeletePopPromptShow(pendingLawfirm.LawfirmBlackListID)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div><!--#end .modal-dialog -->
    </div><!--#end .modal-content-->
</div>

<div class="modal" *ngIf="viewAttachedPdf">
    <div class="modal-content" style="width: 50%; height: 90%;">
        <div class="modal-dialog">
            <div class="modal-header" style="margin-top: 2%;">
                <h2>{{LPCDocumentName}}</h2>
                <button class="modal-dismiss" (click)="dismissDownloadLPCDocument()">
                    <i class="fa fa-close"></i>
                </button>
            </div> <!-- #end .modal-header -->
            <div class="modal-body form">
                <div style="width: 100%; height:550px; max-height: 450px;">
                    <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" [openFile]="true" [viewBookmark]="true"
                        [download]="true" [downloadFileName]="fileName"></ng2-pdfjs-viewer>
                </div>
            </div> <!-- #end .modal-body .form -->
        </div> <!-- #end .modal-dialog -->
    </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->

<app-prompt-unsaved-changes-modal title="Delete LPC Complaint"
    message="You are about to delete an LPC complaint. Do you wish to proceed?" [isVisible]="showDeletePopPrompt"
    btnTextCancel="Cancel" btnTextContinue="Proceed" (cancel)="onHideDeletePopPrompt($event)"
    (continue)="onDeleteLawfirmListingsItem()">
</app-prompt-unsaved-changes-modal>

<app-edit-lpc-blacklist-modal [isVisible]="showEditLPCModal" [currentLawfirmID]="currentLawfirmID"
    [CurrentReportingAdvocateName]="CurrentReportingAdvocateName"
    (closeModal)="closeEditLPCModal($event)">
</app-edit-lpc-blacklist-modal>