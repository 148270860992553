import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Matter, BriefDocuments, MattersService } from '../../../services/matters/matters.service';
import { DocumentTypes } from '../../../services/fee-items/fee-items.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { FileUploadService } from '../../../services/file-upload/file-upload.service';
// import { } from '@angular/core/testing';
import {
  ActivityLogsService,
  DEFAULT_ACTIVITY_LOGS,
  ActivityLogs } from '../../../services/activity-logs/activity-logs.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import * as moment from 'moment';

@Component({
  selector: 'app-upload-file-type-modal',
  templateUrl: './upload-file-type-modal.component.html',
  styleUrls: ['./upload-file-type-modal.component.scss']
})
export class UploadFileTypeModalComponent implements OnInit {

  // Document types
  // documentTypes: any;
  selectedDocumentType = 0;
  formData: FormData = new FormData();

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  @Input() isVisible: boolean;
  @Input() title: string;
  @Input() btnTextCancel: string;
  @Input() btnTextAddFile: string;
  @Input() documentTypes: DocumentTypes[];
  @Input() attachmentFile: File;
  @Input() workingMatter: Matter;
  @Input() briefDocuments: BriefDocuments[];
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() upload: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private matterService: MattersService,
    private loadingService: LoadingService,
    private fileUploadService: FileUploadService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
  ) { } // end constructor()

  get canAddFile(): boolean {
    return Boolean(this.selectedDocumentType > 0);
  } // end canAddFile()

  ngOnInit() {
    if (!this.title) {
      this.title = 'Add document to matter';
    }

    if (!this.btnTextCancel) {
      this.btnTextCancel = 'Cancel';
    }

    if (!this.btnTextAddFile) {
      this.btnTextAddFile = 'Add document';
    }
  } // end ngOnInit()

  onCancel() {
    this.selectedDocumentType = 0;
    this.cancel.emit();
  } // end onCancel()

  onAddFile() {
    this.loadingService.showOverlay();
    let briefId = 0;
    this.formData = new FormData();
    this.formData.append('BriefDocument', this.attachmentFile);
    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Add matter document';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-matter(upload-file-type-modal))';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'BriefDocument';
    this.activityLog.JsonData = JSON.stringify(this.workingMatter);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});
    this.fileUploadService.postBriefDocumentAttachement(this.workingMatter.ServiceID, +this.selectedDocumentType, this.formData)
    .subscribe({ next: (doc) => {
      // On next
      briefId = doc;
    },
    error: (error) => {
      // On error
      this.formData.delete('BriedDocument');
      this.loadingService.hideOverlay();
    },
   complete: () => {
      // On complete
      this.selectedDocumentType = 0;
      this.loadingService.hideOverlay();
      // const docs =  
      this.matterService.getSingleDocument(this.workingMatter.ServiceID, briefId).subscribe({next: (docs) => {
        if (docs) {
          // this.briefDocuments = docs;
          this.upload.emit(docs);
         }
      }})
    }
  });
  } // end onAddFile()

} // end uploadFileTypeModalComponent{}
