<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <button class="modal-dismiss" (click)="onCancel()">
          <i class="fa fa-close"></i>
        </button>
        <h4>{{title}}</h4>
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">
        <div class="row first-pane">
          <div class="table-responsive" style="max-height: 200px;">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <th class="w-9" scope="col">Invoice Date</th>
                  <th class="w-8" scope="col">Invoice No</th>
                  <th class="w-20" scope="col">Matter</th>
                  <th class="w-15" scope="col">Attorney</th>
                  <th class="w-20" scope="col">Attorney Ref.</th>
                  <th class="w-9" scope="col">Due</th>
                  <th class="w-9" scope="col">Paid</th>
                  <th class="w-9" scope="col">Invoice Total</th>
                  <th class="w-1" scope="col"></th>
                </tr>
                <tr *ngFor="let data of multiInvoiceNote">
                  <td class="w-9" scope="row">{{data.InvoiceDate | date:'yyyy-MM-dd'}}</td>
                  <td class="w-8" scope="row" style="color: blue;" class="clickable" (click)="onViewInvoice(data)"><u><a>{{data.InvoiceNo}}</a></u></td>
                  <td class="w-20" scope="row">{{data.Matter}}</td>
                  <td class="w-15" scope="row">{{data.Attorney}}</td>
                  <td class="w-20" scope="row">{{data.AttorneyRef}}</td>
                  <td class="w-9" scope="row">{{data.Due | currency:currencySymbol}}</td>
                  <td class="w-9" scope="row">{{data.Paid | currency:currencySymbol}}</td>
                  <td class="w-9" scope="row">{{data.InvoiceTotal | currency:currencySymbol}}</td>
                  <td class="w-1" scope="row">
                    <i *ngIf="selectedNote.InstructionResponseId === 0" class="far fa-minus-circle clickable" (click)="onInvoiceRemove(data)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> <!-- #end .row .first-pane -->

        <!-- Chat Conversation -->
        <!-- <div class="panel-body">
          <ul class="chat">
            <li class="left clearfix" *ngIf="selectedNote && selectedNote.InternalText">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{selectedNote.UserCreatorFullName}}</strong> <small
                    class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Personal Note
                  </small>
                </div>
                <p>{{selectedNote.InternalText}}</p>
              </div>
            </li> -->
            <!-- <li class="left clearfix" *ngIf="noteDetailData && noteDetailData.InternalText">
                      <span style="margin-left: 7px;" class="chart-img pull-left">
                        <i class="fas fa-user-circle"></i>
                      </span>
                      <div class="chat-body clearfix">
                        <div class="header">
                          <strong class="primary-font">{{noteDetailData.UserCreatorFullName}}</strong> <small class="text-muted">
                            <span class="glyphicon glyphicon-time"></span> Collector
                          </small>
                        </div>
                        <p>{{noteDetailData.InternalText}}</p>
                      </div>
                    </li> -->
            <!-- <li class="left clearfix"
              *ngIf="selectedNote && (selectedNote.ExternalText || selectedNote.QuestionId > 0)">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{selectedNote.UserOwnerFullName}}</strong> <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Practice Manager
                  </small>
                </div>
                <p>{{selectedNote.QuestionText}} {{selectedNote.ExternalText}}</p>
              </div>
            </li>
            <li class="left clearfix" *ngIf="selectedNote && selectedNote.ResponseText">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{selectedNote.UserAssignedFullName}}</strong> <small
                    class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Advocate
                  </small>
                </div>
                <p>{{selectedNote.ResponseText}} {{selectedNote.InstructionText}}</p>
              </div>
            </li> -->
            <!-- <li class="left clearfix"
              *ngIf="selectedNote && selectedNote.InstructionResponseId && selectedNote.ResolutionText">
              <span style="margin-left: 7px;" class="chart-img pull-left">
                <i class="fas fa-user-circle"></i>
              </span>
              <div class="chat-body clearfix">
                <div class="header">
                  <strong class="primary-font">{{selectedNote.UserOwnerFullName}}</strong> <small class="text-muted">
                    <span class="glyphicon glyphicon-time"></span> Practice Manager
                  </small>
                </div>
                <p>{{selectedNote.ResolutionText}}</p>
              </div>
            </li>
          </ul>  -->
          <!-- #end .chat -->
        <!-- </div>  -->
        <!-- #end .panel-body -->

        <div class="well well-fluid">
          <div class="row" style="border-bottom: 1px dotted #B3A9A9;">
            <div class="control-group">
              <i class="far fa-question-circle"></i>
              <label class="question-text"> <strong>{{selectedNote.QuestionId > 0 ? 'Request for instruction': 'IN RE:'}}<u>
                    {{selectedNote.ObjectTypeDescription}}</u> {{selectedNote.Category}}</strong></label>
            </div>
          </div>
          <div class="row" style="border-bottom: 1px dotted #B3A9A9;">
            <div class="control-group">
              <label class="question-text"> <strong style="width: 98%;">Practice Manager Comment:</strong>
                {{selectedNote.ExternalText}}</label>
            </div>
            <div class="control-group" *ngIf="selectedNote.QuestionId > 0">
              <label class="question-text"> <strong>Instruction question:</strong> {{selectedNote.QuestionText}}</label>
            </div>
          </div>
          <div class="row" style="border-bottom: 1px dotted #B3A9A9;" *ngIf="selectedNote.InstructionResponseId > 0">
            <div class="control-group">
              <i class="fas fa-user-circle"></i>
              <label class="question-text"><strong>You:</strong> {{selectedNote.ResponseText}}
                {{selectedNote.InstructionText}}</label>
            </div>
          </div>
          <div class="row" style="border-bottom: 1px dotted #B3A9A9;" *ngIf="selectedNote.InstructionResponseId > 0">
            <div class="control-group" *ngIf="selectedNote.InstructionResponseId > 0 && selectedNote.ResolutionText">
              <i class="fas fa-user-circle"></i>
              <label class="question-text"><strong>Practice Manager:</strong> {{selectedNote.ResolutionText}}</label>
            </div>
          </div>
          <div class="row" style="padding-top: 7px; padding-bottom: 0;"
            *ngIf="selectedNote.InstructionResponseId === 0 && selectedNote.QuestionId > 0">
            <div class="control-group">
              <label for="action">Action</label>
              <input [(ngModel)]="selectedResponseText" [typeahead]="instructionResponses" typeaheadOptionField="Response"
                [typeaheadSingleWords]="false" placeholder="Select or start typing" (input)="onInputResponse($event)"
                (typeaheadOnSelect)="onNoteResponseSelect($event)" [typeaheadOptionsLimit]="50" typeaheadScrollable=true
                typeaheadOptionsInScrollableView="5" [typeaheadMinLength]="0" [dropup]="false">
            </div>
          </div>
          <div class="row" style="padding-top: 0; padding-bottom: 0;"
            *ngIf="selectedNote.InstructionResponseId === 0 && selectedNote.QuestionId > 0">
            <div class="control-group">
              <label for="action">Comment</label>
              <textarea class="input-control" [(ngModel)]="instructionText"></textarea>
              <button class="btn btn-success" (click)="onNoteSave()" [class.disabled]="!isSaveable"
                [disabled]="!isSaveable">SEND</button>
            </div>
          </div>
        </div> <!-- #end .well well-fluid -->
      </div> <!-- #end .modal-body .form -->

      <!-- <div class="modal-footer"
        *ngIf="!selectedNote.UserOwnerFullName || selectedNote.InstructionResponseId > 0 && selectedNote.ResponseText">
        <div class="well well-fluid">
          <div class="row" style="padding-top: 0; padding-bottom: 0;"
            *ngIf="selectedNote.InstructionResponseId === 0 && !selectedNote.ResponseText">
            <div class="form-control" style="border:0; background-color: transparent;">
              <div class="control-group">
                <label for="category">Note Type</label>&nbsp;
                <input [(ngModel)]="selectedNoteCategoryText" [typeahead]="noteCategory" typeaheadOptionField="Category"
                  [typeaheadSingleWords]="false" placeholder="Select or start typing" (input)="onInputCategory($event)"
                  (typeaheadOnSelect)="onNoteCategorySelect($event)" [typeaheadOptionsLimit]="50"
                  typeaheadScrollable=true typeaheadOptionsInScrollableView="5" [typeaheadMinLength]="0"
                  [dropup]="false">
              </div>
              <div class="control-group">
                <label for="sub-category">Note Detail</label>&nbsp;
                <input [(ngModel)]="selectedNoteSubCategoryText" [typeahead]="noteSubCategoryList"
                  typeaheadOptionField="Name" [typeaheadSingleWords]="false" placeholder="Select or start typing"
                  (input)="onInputSubCategory($event)" (typeaheadOnSelect)="onNoteSubCategorySelect($event)"
                  [typeaheadOptionsLimit]="50" typeaheadScrollable=true typeaheadOptionsInScrollableView="5"
                  [typeaheadMinLength]="0" [dropup]="false">
              </div>
              <div class="control-group date-control" *ngIf="selectedNoteSubCategory && selectedNoteSubCategory.Diary">
                <label for="date-to">Attribute</label>&nbsp;
                <input readonly type="text" id="date-to" class="input-date" #_dateTo="bsDatepicker" bsDatepicker
                  placeholder="2019-10-01" placement="top" (bsValueChange)="onDateValueChange($event)"
                  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
                <i class="fa fa-calendar" [attr.aria-expanded]="_dateTo.isOpen"></i>
              </div>
            </div>
          </div>
          <div class="row" style="padding-top: 0; padding-bottom: 0;"
            *ngIf="selectedNoteSubCategory && selectedNoteSubCategory.InstructionQuestion && selectedNote.InstructionResponseId === 0 && !selectedNote.ResponseText">
            <div class="form-control" style="border:0; background-color: transparent;">
              <div class="control-group" style="width: 100%;">
                <label style="width: 20%;" for="question">Instruction Question:</label>&nbsp;
                <input style="width: 78%;" readonly [ngModel]="selectedNoteSubCategory.InstructionQuestion"> -->
                <!-- <label>{{selectedNoteSubCategory.InstructionQuestion}}</label> -->
              <!-- </div>
            </div>
          </div> -->
          <!-- </div>
                    <div class="well well-fluid"> -->
          <!-- <div class="row" style="padding-top: 0; padding-bottom: 0;"
            *ngIf="(selectedNote.InstructionResponseId === 0 && !selectedNote.ResponseText && isInternalChecked)">
            <div class="form-control" style="border:0; background-color: transparent;">
              <div class="control-group" style="width: 100%;">
                <label style="width: 98px;" for="message">Personal Note</label>&nbsp;
                <textarea style="width: 100%; height: 28px;" class="input-control" placeholder="Add Note Message"
                  [(ngModel)]="internalText"></textarea>
              </div>
            </div>
          </div>
          <div class="row" style="padding-top: 0; padding-bottom: 0;"
            *ngIf="(selectedNote.InstructionResponseId === 0 && !selectedNote.ResponseText) ||
                    (selectedNote.InstructionResponseId > 0 && selectedNote.ResponseText && !selectedNote.ResolutionText)">
            <div class="form-control" style="border:0; background-color: transparent;">
              <div class="control-group" style="width: 87%;">
                <label for="message">Message</label>&nbsp;
                <textarea style="width: 100%;"
                  *ngIf="selectedNote.InstructionResponseId === 0 && !selectedNote.ResponseText"
                  class="input-control" placeholder="Add Note Message" [(ngModel)]="internalExternalText"></textarea>
                <textarea style="width: 100%;"
                  *ngIf="selectedNote.InstructionResponseId > 0 && selectedNote.ResponseText && !selectedNote.ResolutionText"
                  class="input-control" placeholder="Add Note Message" [(ngModel)]="resolutionText"></textarea>
              </div> -->
              <!-- </div>
                        <div class="form-control"> -->
              <!-- <div class="option-groups" style="margin-right: 5px;"
                *ngIf="selectedNote && selectedNote.InstructionResponseId === 0">
                <div class="visibility-group">
                  <div class="text-center">
                    <label for="visibility">Visibility</label>
                  </div>
                  <div class="text-center">
                    <i class="far fa-question-circle"></i>
                  </div>
                </div>
                <div class="visibility-group">
                  <div class="">
                    <div class="control-group">
                      <label for="table">Internal </label>&nbsp;
                      <app-small-radiobox [(checked)]="isInternalChecked" (click)="toggleIntExtCheckbox('internal')">
                      </app-small-radiobox>
                    </div>
                  </div> -->
                  <!-- <div class="">
                          <div class="control-group">
                            <label for="table">External </label>&nbsp;
                            <app-small-radiobox [(checked)]="isExternalChecked" (click)="toggleIntExtCheckbox('external')">
                            </app-small-radiobox>
                          </div>
                        </div> -->
                <!-- </div>
              </div> -->
              <!-- <div class="form-control"> -->
              <!-- <button style="height: auto;" *ngIf="!selectedNote || selectedNote.Id === 0" (click)="onAddNote()"
                class="btn btn-success">ADD NOTE</button>
              <button style="height: auto;"
                *ngIf="selectedNote && selectedNote.Id > 0 && !selectedNote.ResolutionText"
                (click)="onResolutionSave()" class="btn btn-success">ADD NOTE</button>
            </div>
          </div>
        </div>
      </div>  -->
      <!-- #end .modal-footer -->

    </div> <!-- #end .modal-dialog-->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->

<app-prompt-unsaved-changes-modal
  title="Remove Invoice"
  *ngIf="showRemoveInvoiceDialog"
  [message]="removeInvoiceMessage"
  [isVisible]="showRemoveInvoiceDialog"
  btnTextContinue="Proceed"
  btnTextCancel="Cancel"
  (cancel)="onHideRemoveInvoiceDialog()"
  (continue)="onRemoveInvoiceDialog()">
</app-prompt-unsaved-changes-modal>

<app-invoice-preview-modal
  *ngIf="showProformaInvoice" 
  #invoicePreview 
  [selectedTab]="selectedTab"
  [notesHandle]="selectedNotesHandle" 
  [plaintiff]="invoicePlaintiff" 
  [selectedTab]="selectedTab"
  [defendant]="invoiceDefendant" 
  [invoiceId]="selectedInvoiceId" 
  [invoiceDetails]="invoiceDetails"
  [isVisible]="showProformaInvoice" 
  (closeModal)="closeModal('invoice')">
</app-invoice-preview-modal>