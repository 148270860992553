<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="container-fluid" *ngIf="loadPage">
    <h1 class="page-title">EDIT PROFILE</h1>
    <div class="well">
  <div class="row">
<!-- First name -->
    <div class="form-group row">
      <label for="first-name">First name<span title="Required field"> *</span></label>
      <input
        type="text"
        class="form-control"
        [class.has-error]="hasFirstName"
        id="first-name"
        [(ngModel)]="userProfile.personalDetails.firstName"
        (click)="$event.target.select()"
        autocomplete="nocxua">
        <span
        *ngIf="hasFirstName"
        class="text-danger">Please enter a valid first name</span>
    </div>
  </div>

  <div class="row">
  <!-- Last name -->
  <div class="form-group row">
      <label for="first-name">Full name<span title="Required field"> *</span></label>
      <input
        type="text"
        class="form-control"
        [class.has-error]="hasFirstName"
        id="first-name"
        [(ngModel)]="userProfile.personalDetails.lastName"
        (click)="$event.target.select()"
        autocomplete="nocxua">
        <span
        *ngIf="hasFirstName"
        class="text-danger">Please enter a valid last name</span>
    </div>
  </div>

  <div class="row">
  <!-- ID number -->
    <div class="form-group row">
      <label for="id-number">ID number</label>
      <input
        class="form-control"
        type="text"
        id="id-number"
        [(ngModel)]="userProfile.personalDetails.idNumber"
        (click)="$event.target.select()"
        autocomplete="nocxua">
    </div>
  </div>

  <div class="row">
  <!-- Cell number -->
    <div class="form-group row">
      <label for="mobile-number">Cell number<span title="Required field"> *</span></label>
      <input
        class="form-control"
        [class.has-error]="hasCellNumberError"
        type="mobile-number"
        id="mobile-number"
        placeholder="Cell number e.g (xx) xxx-xxxx"
        (click)="$event.target.select()"
        (keyup)="onPhoneNumberInputChange($event)"
        (focus)="onPhoneNumberInputFocus($event)"
        [(ngModel)]="userProfile.contactDetails.mobileNumber"
        autocomplete="nocxua">
      <span
        *ngIf="hasCellNumberError"
        class="text-danger">Please enter a valid cell number</span>
    </div>
  </div>

  <div class="row">
  <!-- Email -->
    <div class="form-group row">
      <label for="email">Email<span title="Required field"> *</span></label>
      <input
        class="form-control"
        [class.has-error]="hasContactEmailError"
        type="email"
        id="email"
        [(ngModel)]="userProfile.contactDetails.emailAddress"
        (focus)="$event.target.select()">
      <span
      *ngIf="hasContactEmailError"
        class="text-danger">Please enter a valid email address.</span>
    </div>
  </div>

  <div class="row">
    <div class="form-group row" >
      <label>Ethnicity</label>
        <!-- <div class="input-group"> -->
          <select name="ethnicity" id="ethnicity" class="form-control" [(ngModel)]="userProfile.personalDetails.ethincity">
              <option value="" selected="selected">Select Ethnic Group</option>
              <option value="African">African</option>
              <option value="White">White</option>
              <option value="Coloured">Coloured</option>
              <option value="Indian / Asian">Indian / Asian</option>
          </select>
        <!-- </div> -->
    </div>
  </div>

  <div class="row">
    <div class="form-group row">
      <label>Gender</label>
        <!-- <div class="input-group"> -->
          <select name="gender" id="gender" class="form-control" [(ngModel)]="userProfile.personalDetails.gender">
              <option value="" selected="selected">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
          </select>
        <!-- </div> -->
    </div>
  </div>

  <div class="row">
  <!-- Speciality -->
    <div class="form-group row">
      <label for="speciality">Speciality</label>
      <select
        class="form-control"
        id="speciality"
        [title]="userProfile.personalDetails.speciality"
        [(ngModel)]="userProfile.personalDetails.speciality">
        <option [value]="null">Choose a speciality</option>
        <option
          *ngFor="let speciality of specialities"
          [value]="speciality.value">{{speciality.label}}</option>
      </select>
    </div>
  </div>

  <div class="row">
  <!-- Seniority -->
    <div class="form-group row">
      <label for="seniority">Seniority</label>
      <select
        class="form-control"
        id="seniority"
        [(ngModel)]="userProfile.personalDetails.professionalLevel">
        <option [value]="null">Choose a seniority</option>
        <option
          *ngFor="let lvl of professionalLevels"
          [value]="lvl.value"
          [selected]="lvl.value === userProfile.personalDetails.professionalLevel">{{lvl.label}}</option>
      </select>
    </div>
  </div>

  <div class="row">
  <!-- Vat number -->
    <div class="form-group row">
      <label for="vat-number">Vat number</label>
      <input
        class="form-control"
        type="text"
        id="vat-number"
        [(ngModel)]="userProfile.personalDetails.vatNumber"
        (click)="$event.target.select()"
        autocomplete="nocxua">
    </div>
  </div>

  <div class="row">
<!-- Fee target -->
  <div class="form-group row">
      <label for="fee-target">Fee target<span title="Required field"> *</span></label>
        <input
        required
        type="text"
        class="form-control"
        id="fee-target"
        [ngModel]="userProfile.billingDetails.feeTarget | currency: currencySymbol: currencySymbol:'1.2-2'"
        [ngModelOptions]="{updateOn: 'blur'}"
        (ngModelChange)="userProfile.billingDetails.feeTarget=$event"
        (blur)="onFeeTargetChange()"
        (focus)="onFeeTargetClicked()"
        (focus)="$event.target.select()"
        pattern="[R\P\s]*[0-9,.]+"
        #inputFeeTarget
        autocomplete="nocxua">
        <!-- TODO: Implement currency input mask -->
    </div>
  </div>

  <div class="row">
  <!-- Income target -->
    <div class="form-group row">
      <label for="income-target">Income target<span title="Required field"> *</span></label>
        <input
        required
        type="text"
        class="form-control"
        id="income-target"
        [ngModel]="userProfile.billingDetails.incomeTarget | currency: currencySymbol: currencySymbol:'1.2-2'"
        [ngModelOptions]="{updateOn: 'blur'}"
        (ngModelChange)="userProfile.billingDetails.incomeTarget=$event"
        (blur)="onIncomeTargetChange()"
        (focus)="onIncomeTargetClicked()"
        (focus)="$event.target.select()"
        pattern="[R\P\s]*[0-9,.]+"
        #inputIncomeTarget
        autocomplete="nocxua">
    </div>
  </div>

  <div class="row">
  <!-- Default hourly rate -->
    <div class="form-group row">
      <label for="default-hourly-rate">Default hourly rate<span title="Required field"> *</span></label>
        <input
        required
        type="text"
        class="form-control"
        id="idefault-hourly-rate"
        [ngModel]="userProfile.billingDetails.defaultHourlyRate | currency: currencySymbol: currencySymbol:'1.2-2'"
        [ngModelOptions]="{updateOn: 'blur'}"
        (ngModelChange)="userProfile.billingDetails.defaultHourlyRate=$event"
        (ngModelChange)="userProfile.billingDetails.defaultDailyRate=$event * 10"
        (blur)="onHourlyRateChange()"
        (focus)="onHourlyRateClicked()"
        (focus)="$event.target.select()"
        pattern="[R\P\s]*[0-9,.]+"
        #inputHourly
        autocomplete="nocxua">
      </div>
    </div>

    <div class="row">
  <!-- Default daily rate -->
    <div class="form-group row">
      <label for="default-daily-rate">Default daily rate<span title="Required field"> *</span></label>
      <input
      required
      type="text"
      class="form-control"
      id="default-daily-rate"
      [ngModel]="userProfile.billingDetails.defaultDailyRate | currency: currencySymbol: currencySymbol:'1.2-2'"
      [ngModelOptions]="{updateOn: 'blur'}"
      (ngModelChange)="userProfile.billingDetails.defaultDailyRate=$event"
      (blur)="onDailyRateChange()"
      (focus)="onDailyRateClicked()"
      (focus)="$event.target.select()"
      pattern="[R\P\s]*[0-9,.]+"
      #inputDaily
      autocomplete="nocxua">
    </div>
  </div>

  <div class="row">
      <!-- Default kilometer rate -->
      <div class="form-group row">
        <label for="default-kilometer-rate">Default kilometer rate</label>
        <input
        type="text"
        class="form-control"
        id="default-kilometer-rate"
        [ngModelOptions]="{updateOn: 'blur'}"
        [ngModel]="userProfile.billingDetails.defaultPerKilometerRate | currency: currencySymbol: currencySymbol:'1.2-2'"
        (ngModelChange)="userProfile.billingDetails.defaultPerKilometerRate=$event"
        (blur)="onPerkilometerRateChange()"
        (focus)="onPerkilometerRateClicked()"
        (focus)="$event.target.select()"
        pattern="[R\P\s]*[0-9,.]+"
        #inputPerKilometer
        autocomplete="nocxua">
      </div>
    </div>
  </div>

  <div class="modal-footer">

    <button
      class="btn btn-success btn-end"
      
      (click)="save()">Update profile</button>
  </div><!--#end .modal-footer -->
  <!-- [class.disabled]="!isSaveable"
      [disabled]="!isSaveable" -->

</div>
