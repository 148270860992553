import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-success-or-error',
  templateUrl: './success-or-error.component.html',
  styleUrls: ['./success-or-error.component.scss']
})
export class SuccessOrErrorComponent implements OnInit {

  @Input() title: string;
  @Input() isVisible: boolean;
  @Input() isSuccessMessage: boolean;
  @Input() message: string;
  @Output() cancel: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {

  } // end ngOnInit()
} // end SuccessOrErrorComponent()
