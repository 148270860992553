<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">
        <!-- Modal tabs -->
        <div class="row row-nav">
          <nav class="navbar-tabs">
            <a
              class="tab"
              [class.active]="activeTab === 'invoice'"
              (click)="setActiveTab('invoice')">INVOICE</a>
            <a
              *ngIf="feesQuoted && feesQuoted.length > 0 && isAdvocateProfile"
              class="tab"
              [class.active]="activeTab === 'actual-fees'"
              (click)="setActiveTab('actual-fees')">ACTUAL FEES</a>
            <a *ngIf="isPracticeManager"
              class="tab"
              [class.active]="activeTab === 'conversation'"
              (click)="setActiveTab('conversation')">CONVERSATION</a>
          </nav>
        </div> <!-- #end .row .row-nav -->

        <div class="container-fluid" *ngIf="activeTab === 'invoice'">
          <div class="frame">
            <div class="tile" style="margin-top: 43px;">
              <!-- Invoice details -->

              <div class="well">
                <h2 class="well-title">Invoice details</h2>
                <div class="well-row">
                  <span class="well-row-cell">Invoice amount</span>
                  <span>{{invoiceDetails.InvoiceTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row">
                  <span class="well-row-cell">Invoice date</span>
                  <span>{{invoiceDetails.InvoiceDate | date:'yyyy-MM-dd'}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row">
                  <span class="well-row-cell">Payment term</span>
                  <span>{{invoiceDetails.Terms}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row">
                  <span class="well-row-cell">Total Payment amount</span>
                  <span>{{invoiceDetails.TotalPaidAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row" *ngIf="invoiceDetails.TotalCreditAmount !== 0">
                  <span class="well-row-cell"><u>Total Credits</u></span>
                  <span><u>{{invoiceDetails.TotalCreditAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</u>&nbsp;
                    <i title="Credit Note" class="far fa-minus-circle clickable" (click)="onPromptRemoveCreditNote(invoiceDetails)"></i>
                  </span>
                </div>
                <div class="well-row" *ngIf="invoiceDetails.TotalWriteOffAmount !== 0">
                  <span class="well-row-cell"><u>Written Off</u></span>
                  <span><u>{{invoiceDetails.TotalWriteOffAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</u>&nbsp;
                    <i title="Remove Write-off" class="far fa-minus-circle clickable" (click)="onPromptRemoveWriteOff(invoiceDetails)"></i>
                  </span>
                </div>
                <div class="well-row" [ngStyle]="{'color' : invoiceDetails.OutstandingBalance !== 0 ? '#ff0000':''}">
                  <span class="well-row-cell"><u>Amount outstanding</u></span>
                  <span>{{invoiceDetails.OutstandingBalance | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
                </div>
              </div> <!-- #end .well -->

              <div class="well mb-10" *ngIf="invoiceDetails.TotalCreditAmount !== 0">
                <div class="well-row">
                  <button class="btn btn-success" (click)="openModel('send-credit')">ReSend Credit Note</button>
                </div>
              </div>
              <!-- Matter details -->
              <div class="well">
                <h2 class="well-title">Matter details</h2>

                <div class="well-row">
                  <span class="well-row-cell">Client name</span>
                  <span>{{invoiceDetails.ClientName}}</span>
                </div> <!-- #end .well-row -->

                <div class="well-row">
                  <span class="well-row-cell">Reference</span>
                  <span>{{invoiceDetails.InvoiceReference}}</span>
                </div> <!-- #end .well-row -->

                <div class="well-row">
                  <span class="well-row-cell">Plaintiff</span>
                  <span>{{plaintiff}}</span>
                </div> <!-- #end .well-row -->

                <div class="well-row">
                  <span class="well-row-cell">Defendant</span>
                  <span>{{defendant}}</span>
                </div> <!-- #end .well-row -->

              </div> <!-- #end .well -->


            </div> <!-- #end .tile -->

            <div class="tile" style="margin: 43px 0 0 12px;">

              <div class="well">
                <div class="row p-0 m-0" style="justify-content: flex-end;">

                  <ul>
                    <li><a (click)="openModel('add-credit')"
                        [ngStyle]="{'cursor' : invoiceDetails.TotalCreditAmount !== 0 ? 'no-drop':'pointer'}"><i
                          class="fal fa-backspace pr-2"></i>Add credit note</a>
                    </li>
                    <li><a (click)="openModel('write-off')"
                        [ngStyle]="{'cursor' : invoiceDetails.OutstandingBalance > 0 ? 'pointer':'no-drop'}"><i
                          class="fal fa-dollar-sign pr-2"></i>Write-off balance
                        due</a></li>
                  </ul>

                </div>
              </div>
              <!-- Invoice PDF -->
              <div style="width: 100%; height:700px;">
                <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" [openFile]="true" [viewBookmark]="true"
                  [download]="true" [downloadFileName]="fileName"></ng2-pdfjs-viewer>
              </div>
            </div> <!-- #end .tile -->

          </div> <!-- #end .frame -->
        </div> <!-- #end .container-fluid -->
        
        <div class="container-fluid" *ngIf="feesQuoted && feesQuoted.length > 0 && isAdvocateProfile && activeTab === 'actual-fees'">
          <div class="frame">
            <div class="tile" style="margin-top: 43px;">
              <!-- Invoice details -->
            
              <div class="well">
                <h2 class="well-title">Invoice details</h2>
                <div class="well-row">
                  <span class="well-row-cell">Invoice No</span>
                  <span>{{invoiceDetails.InvoiceNo}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row">
                  <span class="well-row-cell">Invoice amount</span>
                  <span>{{invoiceDetails.InvoiceTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row">
                  <span class="well-row-cell">Invoice date</span>
                  <span>{{invoiceDetails.InvoiceDate | date:'yyyy-MM-dd'}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row">
                  <span class="well-row-cell">Payment term</span>
                  <span>{{invoiceDetails.Terms}} Days</span>
                </div> <!-- #end .well-row -->
                <div class="well-row">
                  <span class="well-row-cell">Total Payment amount</span>
                  <span>{{invoiceDetails.TotalPaidAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row" *ngIf="invoiceDetails.TotalCreditAmount !== 0">
                  <span class="well-row-cell"><u>Total Credits</u></span>
                  <span><u>{{invoiceDetails.TotalCreditAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></span>
                </div>
                <div class="well-row" *ngIf="invoiceDetails.TotalWriteOffAmount !== 0">
                  <span class="well-row-cell"><u>Written Off</u></span>
                  <span><u>{{invoiceDetails.TotalWriteOffAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</u>&nbsp;
                    <i title="Remove Write-off" class="far fa-minus-circle clickable" (click)="onPromptRemoveWriteOff(invoiceDetails)"></i>
                  </span>
                  
                </div>
                <div class="well-row" *ngIf="invoiceDetails.TotalWriteOffAmount !== 0">
                  <span class="well-row-cell"><u>Written Off</u></span>
                  <span><u>{{invoiceDetails.TotalWriteOffAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></span>
                </div>
                <div class="well-row" [ngStyle]="{'color' : invoiceDetails.OutstandingBalance !== 0 ? '#ff0000':''}">
                  <span class="well-row-cell"><u>Amount outstanding</u></span>
                  <span>{{invoiceDetails.OutstandingBalance | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
                </div>
              </div> <!-- #end .well -->
            
              <div class="well mb-10" *ngIf="invoiceDetails.TotalCreditAmount !== 0">
                <div class="well-row">
                  <button class="btn btn-success" (click)="openModel('send-credit')">ReSend Credit Note</button>
                </div>
              </div>
              <!-- Matter details -->
              <div class="well">
                <h2 class="well-title">Matter details</h2>
            
                <div class="well-row">
                  <span class="well-row-cell">Client name</span>
                  <span>{{invoiceDetails.ClientName}}</span>
                </div> <!-- #end .well-row -->
            
                <div class="well-row">
                  <span class="well-row-cell">Reference</span>
                  <span>{{invoiceDetails.InvoiceReference}}</span>
                </div> <!-- #end .well-row -->
            
                <div class="well-row">
                  <span class="well-row-cell">Plaintiff</span>
                  <span>{{plaintiff}}</span>
                </div> <!-- #end .well-row -->
            
                <div class="well-row">
                  <span class="well-row-cell">Defendant</span>
                  <span>{{defendant}}</span>
                </div> <!-- #end .well-row -->
            
              </div> <!-- #end .well -->
            </div> <!-- #end .tile -->
            <div class="tile" style="margin: 43px 0 0 12px;">
              <div class="well well-fluid">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <tbody style="width: 100% !important;">
                      <tr>
                        <th style="width: 9%;">Line Date</th>
                        <th style="width: 53%;">Line Description</th>
                        <th style="width: 10%;">Line type</th>
                        <th style="width: 10%;">Quantity</th>
                        <th style="width: 10%;">Rate</th>
                        <th style="width: 10%;">Total</th>
                      </tr>
                      <tr *ngFor="let data of feesQuoted">
                        <td style="width: 9%;">{{data.LineDate | date:'yyyy-MM-dd'}}</td>
                        <td style="width: 53%;">{{data.Description}}</td>
                        <td style="width: 10%;">{{data.LineType}}</td>
                        <td style="width: 10%;">{{data.Quantity}}</td>
                        <td style="width: 10%;">{{data.Rate | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                        <td style="width: 10%;">{{data.Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>Total</th>
                      <th>{{actualFeesTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="container-fluid" *ngIf="activeTab === 'conversation'">
          <div class="frame">
            <div class="tile" style="margin-top: 43px;">
              <!-- Invoice details -->
            
              <div class="well">
                <h2 class="well-title">Invoice details</h2>
                <div class="well-row">
                  <span class="well-row-cell">Invoice No</span>
                  <span>{{invoiceDetails.InvoiceNo}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row">
                  <span class="well-row-cell">Invoice amount</span>
                  <span>{{invoiceDetails.InvoiceTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row">
                  <span class="well-row-cell">Invoice date</span>
                  <span>{{invoiceDetails.InvoiceDate | date:'yyyy-MM-dd'}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row">
                  <span class="well-row-cell">Payment term</span>
                  <span>{{invoiceDetails.Terms}} Days</span>
                </div> <!-- #end .well-row -->
                <div class="well-row">
                  <span class="well-row-cell">Total Payment amount</span>
                  <span>{{invoiceDetails.TotalPaidAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row" *ngIf="invoiceDetails.TotalCreditAmount !== 0">
                  <span class="well-row-cell"><u>Total Credits</u></span>
                  <span><u>{{invoiceDetails.TotalCreditAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></span>
                </div>
                <div class="well-row" *ngIf="invoiceDetails.TotalWriteOffAmount !== 0">
                  <span class="well-row-cell"><u>Written Off</u></span>
                  <span><u>{{invoiceDetails.TotalWriteOffAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></span>
                </div>
                <div class="well-row" [ngStyle]="{'color' : invoiceDetails.OutstandingBalance !== 0 ? '#ff0000':''}">
                  <span class="well-row-cell"><u>Amount outstanding</u></span>
                  <span>{{invoiceDetails.OutstandingBalance | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
                </div>
              </div> <!-- #end .well -->
            
              <div class="well mb-10" *ngIf="invoiceDetails.TotalCreditAmount !== 0">
                <div class="well-row">
                  <button class="btn btn-success" (click)="openModel('send-credit')">ReSend Credit Note</button>
                </div>
              </div>
              <!-- Matter details -->
              <div class="well">
                <h2 class="well-title">Matter details</h2>
            
                <div class="well-row">
                  <span class="well-row-cell">Client name</span>
                  <span>{{invoiceDetails.ClientName}}</span>
                </div> <!-- #end .well-row -->
            
                <div class="well-row">
                  <span class="well-row-cell">Reference</span>
                  <span>{{invoiceDetails.InvoiceReference}}</span>
                </div> <!-- #end .well-row -->
            
                <div class="well-row">
                  <span class="well-row-cell">Plaintiff</span>
                  <span>{{plaintiff}}</span>
                </div> <!-- #end .well-row -->
            
                <div class="well-row">
                  <span class="well-row-cell">Defendant</span>
                  <span>{{defendant}}</span>
                </div> <!-- #end .well-row -->
            
              </div> <!-- #end .well -->           
            </div> <!-- #end .tile -->

            <div class="tile" style="margin: 43px 0 0 12px;">
              <div class="well well-fluid">
                <div class="form-control">
                  <div class="control-group" style="width: 45%;">
                    <label for="search">Find</label> &nbsp;
                    <input
                      type="search"
                      placeholder="Search"
                      [(ngModel)]="searchTerm"
                      (keyup)="performSearch()">
                  </div>

                  <!-- From date -->
                  <div class="control-group date-control">
                    <label style="min-width: 58px;" for="date-from">From Date</label>
                    <input 
                      readonly
                      type="text"
                      id="date-from" 
                      class="input-date" 
                      #_dateFrom="bsDatepicker" 
                      bsDatepicker
                      placeholder="2019-10-01" 
                      placement="bottom" 
                      [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
                    <i class="fa fa-calendar" [attr.aria-expanded]="_dateFrom.isOpen"></i>
                  </div>

                  <!-- To date -->
                  <div class="control-group date-control">
                    <label style="min-width: 58px;" for="to-date">&nbsp; To Date</label>
                    <input 
                      readonly
                      type="text"
                      id="date-to"
                      class="input-date"
                      #_dateTo="bsDatepicker" 
                      bsDatepicker
                      placeholder="2019-10-01" 
                      placement="bottom" 
                      [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
                    <i class="fa fa-calendar" [attr.aria-expanded]="_dateTo.isOpen"></i>
                  </div>
                </div> <!-- #end .form-control -->

                <!-- Table notes data -->
                <div>
                
                  <!-- Table -->
                  <div class="table-responsive">
                    <table id="conversationTable" class="table table-striped">
                      <tr>
                        <!-- <th>Note About</th> -->
                        <th>Date</th>
                        <th>Time</th>
                        <th>User</th>
                        <th>Note Type</th>
                        <th>Note</th>
                      </tr>
                      <tr *ngFor="let data of notesHandle">
                        <!-- <td *ngIf="data.state.isVisible" style="width: 11%;">
                          <u>{{data.data.ObjectTypeDescription}}</u></td> -->
                        <td *ngIf="data.state.isVisible && isPracticeManager" [ngStyle]="{'font-weight': getUnReadMessageFont(data.data.UserCreatorStatus)}" style="width: 10%;">
                          {{data.data.CaptureDate | date:'yyyy-MM-dd'}}</td>
                        <td *ngIf="data.state.isVisible && !data.data.Personal && !isPracticeManager" [ngStyle]="{'font-weight': getUnReadMessageFont(data.data.UserAssignedStatus)}" style="width: 10%;">
                          {{data.data.CaptureDate | date:'yyyy-MM-dd'}}</td>
                        <td *ngIf="data.state.isVisible && isPracticeManager" [ngStyle]="{'font-weight': getUnReadMessageFont(data.data.UserCreatorStatus)}" style="width: 10%;">
                          {{data.data.CaptureDate | date:'h:mm:ss a'}}</td>
                        <td *ngIf="data.state.isVisible && !isPracticeManager && !data.data.Personal" [ngStyle]="{'font-weight': getUnReadMessageFont(data.data.UserAssignedStatus)}" style="width: 10%;">
                          {{data.data.CaptureDate | date:'h:mm:ss a'}}</td>
                        <td *ngIf="data.state.isVisible && isPracticeManager" [ngStyle]="{'font-weight': getUnReadMessageFont(data.data.UserCreatorStatus)}" style="width: 23%;">
                          {{noteUserLogger(data.data)}}</td>
                        <td *ngIf="data.state.isVisible && !isPracticeManager && !data.data.Personal" [ngStyle]="{'font-weight': getUnReadMessageFont(data.data.UserAssignedStatus)}" style="width: 23%;">
                          {{data.data.UserCreatorFullName}}</td>
                        <td *ngIf="data.state.isVisible && isPracticeManager" [ngStyle]="{'font-weight': getUnReadMessageFont(data.data.UserCreatorStatus)}" style="width: 15%;">
                          {{data.data.Category}}</td>
                        <td *ngIf="data.state.isVisible && !isPracticeManager && !data.data.Personal" [ngStyle]="{'font-weight': getUnReadMessageFont(data.data.UserAssignedStatus)}" style="width: 15%;">{{data.data.Subject}}
                        </td>
                        <td *ngIf="data.state.isVisible && data.data.InstructionResponseId === 0 && isPracticeManager" [ngStyle]="{'font-weight': getUnReadMessageFont(data.data.UserCreatorStatus)}" class="col-note"
                          (click)="onNoteDetailView(data.data)"><u>{{pmLatestNote(data.data)}}</u></td>
                        <td *ngIf="data.state.isVisible && (data.data.InstructionResponseId > 0 && data.data.Subject === 'Instruction from PM') && isPracticeManager" [ngStyle]="{'font-weight': getUnReadMessageFont(data.data.UserCreatorStatus)}" class="col-note"
                          (click)="onNoteDetailView(data.data)"><u>{{data.data.ResponseText}} {{data.data.InstructionText}}</u></td>
                          <td *ngIf="data.state.isVisible && data.data.InstructionResponseId > 0 && data.data.Subject !== 'Instruction from PM' && isPracticeManager" [ngStyle]="{'font-weight': getUnReadMessageFont(data.data.UserCreatorStatus)}" class="col-note"
                            (click)="onNoteDetailView(data.data)"><u>{{data.data.QuestionText}} {{data.data.ExternalText}} {{data.data.InstructionText}}</u></td>
                        <td *ngIf="data.state.isVisible && !data.data.Personal && !isPracticeManager" [ngStyle]="{'font-weight': getUnReadMessageFont(data.data.UserAssignedStatus)}" class="col-note"
                          (click)="onNoteAdvDetailView(data.data)"><u>{{data.data.InternalText}} {{data.data.ExternalText}}</u></td>
                      </tr>
                    </table>
                    <div class="footer" *ngIf="isPracticeManager">
                      <span class="column-add-another-note">
                        <span class="clickable" (click)="onAddNewNote()">
                          <i class="fa fa-plus-circle auxcon-green"></i>
                          <u class="auxcon-green">add note</u>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
          
              </div> <!-- #end .well .well-fluid -->
            </div> <!-- #end .tile -->
          </div> <!-- #end .frame -->          
        </div> <!-- #end .container-fluid -->
      </div> <!-- #end .modal-body .form -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->

<app-add-credit-modal *ngIf="isShowAddCreditModel" [invoiceDetails]="invoiceDetails" [invoiceId]="invoiceId"
  (closeModal)="closeModel('add-credit')">
</app-add-credit-modal>

<app-write-off-outstanding-modal 
  *ngIf="isShowWriteOffModel" 
  [invoiceDetails]="invoiceDetails" 
  [invoiceId]="invoiceId"
  (closeModal)="closeModel('write-off')">
</app-write-off-outstanding-modal>

<app-send-credit-note-modal *ngIf="isShowSendCreditNoteModel" [invoiceDetails]="invoiceDetails" [invoiceId]="invoiceId"
  (closeModal)="closeModel('send-credit')">
</app-send-credit-note-modal>

<app-prompt-unsaved-changes-modal
  title="PAYMENT LINKED TO INVOICE" 
  message="This invoice is linked to a payment. By proceeding with a credit note the payment will be unlinked and allocated to the Law Firm. Do you wish to proceed?"
  [isVisible]="showTransactionLinkedPrompt" 
  (cancel)="onHideTransactionLinkedPrompt()" 
  btnTextContinue="Proceed"
  btnTextCancel="Cancel" 
  (continue)="onRemovePaymentTransaction()">
</app-prompt-unsaved-changes-modal>
<app-note-detail-modal
  *ngIf="onShowNoteDetail" 
  [isVisible]="onShowNoteDetail" 
  [plaintiff]="invoicePlaintiff"
  [defendant]="invoiceDefendant" 
  [invoiceDetails]="invoiceDetails" 
  [instructionResponses]="instructionResponses"
  [objectType]="objectType"
  [noteDetailData]="noteDetailData" 
  [lawfirmSummaryData]="selectedLawfirmSummaryData" 
  [noteCategory]="noteCategory"
  (closemodal)="onShowNoteDetailModalDismiss()" 
  (saveNote)="onNoteSave($event)" 
  (issueInstruction)="onInstructionIssued($event)"
  (updateNote)="OnAddResolution($event)">
</app-note-detail-modal>
<app-advocate-note-modal
  *ngIf="showAdvocateNoteDetail"
  [isVisible]="showAdvocateNoteDetail"
  [objectType]="objectType"
  [plaintiff]="plaintiff"
  [defendant]="defendant" 
  [invoiceDetails]="invoiceDetails" 
  [selectedNote]="selectedNote"
  [instructionResponses]="instructionResponses"
  (closemodal)="closeModel('advocate-note')"
  (saveInstruction)="OnAddResolution($event)"></app-advocate-note-modal>

  <app-prompt-unsaved-changes-modal
    title="Remove Write-Off" 
    [message]="writeoffMessage"
    [isVisible]="showRemoveWriteOffPrompt" 
    (cancel)="onHideRemoveWriteOffPrompt()" 
    btnTextContinue="Proceed"
    btnTextCancel="Cancel" 
    (continue)="onRemoveWriteOff()">
  </app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal
    title="Remove Credit Note" 
    [message]="creditNoteMessage"
    [isVisible]="showCreditNotePrompt" 
    (cancel)="onHideCreditNotePrompt()" 
    btnTextContinue="Proceed"
    btnTextCancel="Cancel" 
    (continue)="onCreditNote()">
  </app-prompt-unsaved-changes-modal>
  <app-prompt-unsaved-changes-modal
    *ngIf="showContainDisbursementPrompt"
    title="Invoice contains disbursement" 
    message="Please take note that you are not able to credit an invoice that contains disbursements. Kindly contact Auxcon on 012 343 9124 for further assistance."
    [isVisible]="showContainDisbursementPrompt" 
    (cancel)="onHideContainDisbursementPrompt()" 
    btnTextContinue="Ok"
    btnTextCancel="Cancel" 
    (continue)="onHideContainDisbursementPrompt()">
</app-prompt-unsaved-changes-modal>