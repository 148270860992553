import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable, catchError, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

export interface DocumentsHandle {
  data: Documents;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

export interface ClientLetterOfDemandView {
  Id: number;
  LawfirmID: number;
  AdvocateId: number;
  ClientLODInstructionDate: string;
  ReportToBar: boolean;
  LODStatus: string;
  LODDocumentAttached: boolean;
  LODDocumentId: number;
  LODAttachmentDate: string;
  InstructionDate: string;
  LODDate: string;
  DocumentPath: string;
  DocumentTypeID: number;
  DocumentType: string;
}

export const DEFAULT_CLIENT_LETTER_OF_DEMAND_VIEW: ClientLetterOfDemandView = {
  Id: 0,
  LawfirmID: 0,
  AdvocateId: 0,
  ClientLODInstructionDate: '',
  ReportToBar: false,
  LODStatus: '',
  LODDocumentAttached: false,
  InstructionDate: '',
  LODDate: '',
  LODAttachmentDate: '',
  LODDocumentId: 0,
  DocumentPath: '',
  DocumentTypeID: 0,
  DocumentType: ''
};

export interface ClientLetterOfDemand {
  Id: number;
  LawfirmID: number;
  AdvocateId: number;
  ClientLODInstructionDate: string;
  ReportToBar: boolean;
  LODStatus: string;
  LODDocumentAttached: boolean;
  LODDocumentId: number;
  LODAttachmentDate: string;
  InstructionDate: string;
  LODDate: string;
}

export const DEFAULT_CLIENT_LETTER_OF_DEMAND: ClientLetterOfDemand = {
  Id: 0,
  LawfirmID: 0,
  AdvocateId: 0,
  ClientLODInstructionDate: '',
  ReportToBar: false,
  LODStatus: '',
  LODDocumentAttached: false,
  InstructionDate: '',
  LODDate: '',
  LODAttachmentDate: '',
  LODDocumentId: 0
};

export interface Documents {
  DocumentId: number;
  DocumentPath: string;
  Status: boolean;
  DocumentType: string;
  DocumentTypeID: number;
  DateInserted: string;
}

export const DEFAULT_DOCUMENT: Documents = {
  DocumentId: 0,
  DocumentPath: '',
  Status: false,
  DocumentType: '',
  DocumentTypeID: 0,
  DateInserted: ''
};

export interface HistoricalDocument {
  Id: number;
  DocumentName: string;
  DocumentType: string;
  OneDriveFileId: string;
  OneDriveFolderId: string;
  UploadDate: string;
  ContactId: number;
  FileSize: string;
  FileUrl: string;
}

export interface FolderData {
  FolderName: string;
  EarliestUploadDate: string;
}

@Injectable()
export class DocumentServiceService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  private apiUrl =  this.api.apiURL + '/DocumentManager';

  uploadDocuments(files: File[], documentType: string, transactionDate: string): Observable<any> {
    const formData = new FormData();
    formData.append('contactId', this.api.serviceProviderID.toString());
    formData.append('documentType', documentType);
    formData.append('transactionDate', transactionDate);
    
    files.forEach((file) => {
      formData.append('files', file);
    });
  
    // Debugging FormData
   // Type assertion to bypass the TypeScript error
for (let pair of (formData as any).entries()) {
  console.log(`${pair[0]}: ${pair[1]}`);
}
  
  return this.http.post(`${this.apiUrl}/upload`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / (event.total || 1));
            return { status: 'progress', percentage: progress };
          case HttpEventType.Response:
            return { status: 'complete', data: event.body };
          default:
            return { status: 'unknown', event: event };
        }
      })
    );
  } // end uploadDocuments()

  sendHistoricalInvoices(documentType: string, reconDate: string, containerName: string): Observable<any> {
    return this.http.put<any>(this.api.endpoints.documentManager +
      '/PutSendHistoricalInvoices?contactId=' + this.api.serviceProviderID + '&documentType=' + documentType +
      '&transactionDate=' + reconDate + '&containerName=' + containerName,
      this.api.httpOptions
      ).pipe(
        catchError(this.api.handleError)
    );
  } // end updateIsHistorical()

  updateIsHistorical(isHistoricalImported: boolean): Observable<any> {
    return this.http.put<any>(this.api.endpoints.documentManager +
      '/UpdateIsHostoricalImport?isHostoricalImport=' + isHistoricalImported + '&ServiceProviderId=' + this.api.serviceProviderID,
      this.api.httpOptions
      ).pipe(
        catchError(this.api.handleError)
    );
  } // end updateIsHistorical()

  getFolders(): Observable<FolderData[]> {
      return this.http.get<FolderData[]>(this.api.endpoints.documentManager +
        "/GetFolders?contactId=" + this.api.serviceProviderID, this.api.httpOptions
      )
    .pipe(
      catchError(this.api.handleError)
    );
  } // end getFolders()
  
  getFiles(containerName: string): Observable<any[]> {
    return this.http.get<any[]>(this.api.endpoints.documentManager +
      "/GetFiles?containerName=" + containerName + this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end getFiles()

  downloadFolder(containerName: string): Observable<Blob> {
    return this.http.get(this.api.endpoints.documentManager +
      "/DownloadFolder?containerName=" + containerName, {
      responseType: 'blob'
    });
  }

  getDocumentTypes(): Observable<any> {
    return this.http.get<any>(this.api.endpoints.documentType + 
      '/GenericDocumentTypes', this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getDocumentTypes()

  getClientLetterOfDemand(lodId:  number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.lod +
      '?Id=' + lodId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      )
  } // end getClientLetterOfDemand()

  postOtherDocumentAttachment(lawfirmId:  number, documenttypeId: number, file: FormData): Observable<any> {
    return this.http.request('POST', this.api.endpoints.lod +
    '/OtherDocumentsAttachment?AdvocateId=' + this.api.serviceProviderID +
    '&LawfirmID=' + lawfirmId + '&DocumentTypeID=' + documenttypeId,
    {
      body: file
    });
  } // end postOtherDocumentAttachment()

  PostReportToBar(advocateId: number, pmId: number, lawfirmId: number, requestedPayment: string, dispiteFees: string): Observable<any> {
    return this.http.request('POST', this.api.endpoints.lod +
    '/ReportToBar?AdvocateId=' + advocateId + '&PMId=' + pmId +
     '&LawfirmId=' + lawfirmId + '&requestedPayment=' + requestedPayment + '&dispiteFees=' + dispiteFees, this.api.httpOptions)
     .pipe( catchError(this.api.handleError)
     );
  } // end PostReportToBar()

  removeGCBListing(lawfimId: number, advocateId: number): Observable<any>{
    return this.http.put<any>(this.api.endpoints.lod + '/RemoveFromGCBListing?lawfirmId=' + lawfimId +
    '&advocateId=' + advocateId, this.api.httpOptions)
    .pipe(
      catchError(this.api.handleError)
    );
  } // end reportGCBListing()

} // end DocumentServiceService()
