<form name="form" #f="ngForm" novalidate>
    <div class="modal">
        <div class="modal-content">
            <div class="modal-dialog">

                <div class="modal-header">
                    <h2>{{title}}</h2>
                    <button class="modal-dismiss" (click)="onShowUnsavedChangesPrompt('close')">
                        <i class="fal fa-times-circle"></i>
                    </button>
                </div>
                <div class="modal-body form">
                    <div class="row">
                        Proof of Payment Details
                    </div>

                    <div class="row m-0">
                        <div class="col-sm-3 pl-5 pr-5">
                            <label for="">Payment Date</label>
                            <div class="input-group">

                                <input
                                    bsDatepicker
                                    type="text"
                                    id="text"
                                    [bsValue]="bsValue"
                                    (ngModel)="workingProof.Date=$event.target.value"
                                    (bsValueChange)="onDateValueChange($event)"
                                    class="form-control"
                                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                                    #_paymentDate="bsDatepicker"
                                    autocomplete="off"
                                    required>
                                <div class="input-group-append"
                                    (click)="_paymentDate.toggle()"
                                    [attr.aria-expanded]="_paymentDate.isOpen">
                                    <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 pl-5 pr-5">
                            <label for="">Payment Reference
                                <span class="fa fa-question-circle tool-tip">
                                    <span class="tooltiptext"><p>Please ensure that the reference entered in this field is identical to reference used on the Proof of Payment</p></span>
                                </span>
                            </label>
                            
                            <div class="input-group">
                                <input type="text" name="reference" [(ngModel)]="workingProof.Reference" autocomplete="off"
                                    class="form-control" required>
                            </div>
                        </div>
                        <div class="col-sm-3 pl-5 pr-5">
                            <label for="">Payment Amount</label>
                            <div class="input-group">
                                <input
                                    #inputAmount
                                    type="text"
                                    name="amount"
                                    (focus)="onAmountFocus($event)"
                                    [ngModel]="workingProof.Amount | currency: currencySymbol: currencySymbol:'1.2-2'"
                                    [ngModelOptions]="{updateOn: 'blur'}"
                                    (ngModelChange)="workingProof.Amount=$event"
                                    (blur)="onBlurAmountChange()"
                                    (click)="onClickAmount()"
                                    (click)="$event.target.select()"
                                    class="form-control"
                                    autocomplete="off"
                                    required
                                    pattern="[R\s]*[0-9,.]+">
                            </div>
                        </div>
                        <div class="col-sm-3 pl-5 pr-5">
                            <label for="">Law Firm</label>
                            <div class="input-group">
                                <input 
                                    [(ngModel)]="workingProof.LawFirm"
                                    name="lawFirm"
                                    [typeahead]="lawFirms"
                                    typeaheadOptionField="FullName"
                                    [typeaheadSingleWords]="false"
                                    (input)="onInput($event)" 
                                    class="form-control"
                                    placeholder="Select or start typing"
                                    (typeaheadOnSelect)="onChangeAttorney($event)"
                                    [typeaheadOptionsLimit]="500"
                                    typeaheadScrollable=true
                                    typeaheadOptionsInScrollableView="7"
                                    [typeaheadMinLength]="0"
                                    [dropup]="true"
                                    autocomplete="off"
                                    required>
                            </div>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-sm-12 pl-5 pr-5">
                                <label for="">Comments</label>
                                <div class="input-group">
                                    <input type="text" name="comments"  [(ngModel)]="workingProof.Description" class="form-control"
                                        autocomplete="off" >
                                </div>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-sm-9 pl-5 pr-5">
                            <label for="">Attachment</label>
                            <div class="custom-file badge">
                                <input
                                    #inputAttachmentFile
                                    type="text"
                                    name="attachmentText"
                                    (click)="promptAttachment()"
                                    autocomplete="off"
                                    class="form-control"
                                    [(ngModel)]="workingProof.Attachment" >
                                <input
                                    #inputUploadFile
                                    type="file"
                                    accept=""
                                    name="fileUpload"
                                    id="attachmentUpload"
                                    class="custom-file-input"
                                    (change)="attachmentChange($event)"
                                    style="display: none">
                                <i *ngIf="workingProof.HasAttachment" title="delete an attachment" (click)="onDeletePopPromptShow()" class="fa fa-minus-circle fa-lg"></i>
                            </div>
                        </div>
                        <div class="col-sm-3 pl-5 pr-5">
                            <button class="btn btn-success form-control browse-file" (click)="promptAttachment()">Browse
                                to file</button>
                        </div>
                    </div>
                </div>
                <!--#end .modal-body -->

                <div class="modal-footer">
                    <button
                        *ngIf="!isAddOrUpdatePOP"
                        class="btn btn-success"
                        [class.disabled]="!isSavable"
                        [disabled]="!isSavable"
                        (click)="addProofOfPayment()">SAVE PROOF OF PAYMENT</button>
                    <button
                        *ngIf="isAddOrUpdatePOP"
                        class="btn btn-success"
                        [class.disabled]="!isSavable"
                        [disabled]="!isSavable"
                        (click)="updateProofOfPayment()">UPDATE PROOF OF PAYMENT</button>
                </div>
                <!--#end .modal-footer  [disabled]="!f.form.valid"-->
            </div>
            <!--#end .modal-dialog -->
        </div>
        <!--#end .modal-content -->
    </div>
    <!--#end .modal -->
</form>
<app-prompt-unsaved-changes-modal
    message="Are you sure you want to leave this screen? You have unsaved changes."
    [isVisible]="showUnsavedChangesPrompt"
    (cancel)="onHideUnsavedChangesPrompt()"
    (continue)="dismiss('close')"
    (confirm)="onHideUnsavedChangesPrompt()"></app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal
    title="Delete Attachment"
    message="Are you sure you want to delete the proof of payment attachment?"
    [isVisible]="showDeletePopPrompt"
    btnTextCancel="Cancel"
    btnTextContinue="Proceed"
    (cancel)="onHideDeletePopPrompt()"
    (continue)="onDeleteAttachment()"></app-prompt-unsaved-changes-modal>