import { Component,
        OnInit,
        EventEmitter,
        Input,
        Output,
        OnChanges,
        SimpleChanges,
        ViewChild,
        ElementRef} from '@angular/core';
import {
        ProcessPaymentService,
        ProofOfPayment,
        UnPaidInvoices,
        POPLine,
        DEFAULT_POP_LINE} from '../../../services/process-payment/process-payment.service';
import * as moment from 'moment';
import {
        SnackbarsService,
        Snack,
        SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { UsercurrencyDirective } from '../../../directive/usercurrency.directive';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UtilitiesService } from '../../../services/utilities.service';
import {
    DEFAULT_ACTIVITY_LOGS,
    ActivityLogs,
    ActivityLogsService
} from '../../../services/activity-logs/activity-logs.service';
import {
    NotesObject, NotesObjectHandle, NotesService,
} from '../../../services/notes/notes.service';
import {
    InvoiceDetails, InvoiceDetailsService, DEFAULT_INVOICE_DETAILS,
} from '../../../services/invoice-details/invoice-details.service';

interface UnPaidInvoicesHandle {
    data: UnPaidInvoices;
    state: {
        isVisible: boolean;
        isSelected: boolean;
        isOpen: boolean
    };
}

interface POPLineHandle {
    data: POPLine;
    state: {
        isVisible: boolean;
        isSelected: boolean;
        isOpen: boolean;
    };
}

@Component({
    selector: 'app-invoices-added-proof-of-payment',
    templateUrl: './invoices-added-proof-of-payment.component.html',
    styleUrls: ['./invoices-added-proof-of-payment.component.scss']
})
export class InvoicesAddedProofOfPaymentsComponent implements OnInit, OnChanges {
    promptInvoices = false;
    promptPaidInvoices = false;
    showActions = false;
    initPopLineHandle: POPLineHandle[] = [];
    savedPopLineHandle: POPLineHandle[] = [];
    todeletePopLineHandle: POPLine[] = [];
    previousReference = '';


    invoices: POPLine[];
    _invoices: POPLine[];
    invoiceToDelete: POPLine = Object.assign({}, DEFAULT_POP_LINE);
    checkBoxSelected: any = [];
    totalAmount = 0;
    totalAmountLeft = 0;
    showRemoveInvoices = false;
    saveButton = false;
    allocateAmount = 0;
    allocateButton = false;
    allocateRow = false;
    allocateInvoice = '';
    allocateID: number;
    lawFirmName: string;
    invoicesReference: string;
    invoicesDate: Date;
    proofOfPayment: any = [];
    unPaidInvoices: UnPaidInvoices[] = [];
    unPaidInvoiceHandles: UnPaidInvoicesHandle[] = [];
    isFirmAllocate = false;
    selectAll = false;
    _showUnPaidInvoices = false;
    showUnsavedChangesPrompt: boolean;
    inputlawfirmCheckbox: boolean;
    showErrorMessagePrompt: boolean;
    showProformaInvoice: boolean;
    selectedInvoiceId = 0;

    // Invoice Note
    selectedTab: string;
    // notes
    notes: NotesObject[] = [];
    notesHandle: NotesObjectHandle[] = [];
    showInvoiceNoteDetail: boolean;
    objectType: string;
    onShowNoteDetail: boolean;
    noteDetailData: NotesObject;
    invoiceDetails: InvoiceDetails;
    invoicePlaintiff: string;
    invoiceDefendant: string;

    profileTypes = {
        ADVOCATE: 'Advocate',
        ASSISTANT: 'Assistant',
        ATTORNEY: 'Attorney',
        PRACTICE_MANAGER: 'Practice Manager',
        EXTERNAL_ATTORNEY: 'Attorney (External)',
        LAWFIRM: 'Lawfirm',
        EXTERNAL_LAWFIRM: 'Lawfirm (External)'
    };

    amontPattern = '^[0-9]+(\.[0-9]{1,2})?';
    @Input() pattern: string | RegExp;
    @Input() onShow: EventEmitter<ProofOfPayment> = new EventEmitter<ProofOfPayment>();
    @Output() refreshPage = new EventEmitter<any>();
    @ViewChild('inputAmount') inputAmount: ElementRef;

    public promptUnPaidInvoicesEvent: EventEmitter<ProofOfPayment> = new EventEmitter<ProofOfPayment>();

    // Activity logs
    activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

    get currencySymbol(): string {
        if (this.userProfileService.userProfile.isImpersonator) {
            return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
        } else {
            return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
        }
    } // end currencySymbol()

    get showZeroErrorPaid(): boolean {
        return Boolean(this.initPopLineHandle.filter(s => s.data.Paid === 0 || s.data.Paid.toString() === '0').length > 0);
    } // end showZeroErrorPaid()

    // Is Practice Manager
    get isPracticeManager(): boolean {
        return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
    }  // end isPracticeManager()

    constructor(
        private util: UtilitiesService,
        private cpipe: UsercurrencyPipe,
        private notesService: NotesService,
        private loadingService: LoadingService,
        private snackbarsService: SnackbarsService,
        private userProfileService: UserProfileService,
        private activityLogService: ActivityLogsService,
        private currencyDirective: UsercurrencyDirective,
        private invoiceDetailService: InvoiceDetailsService,
        private processPaymentService: ProcessPaymentService,
        ) {
    } // end constructor()

    get hasAnError(): boolean {
        return Boolean(this.totalAmount > this.proofOfPayment.Amount);
    } // end hasAnError()
    get showUnPaidInvoices(): boolean {
        return this._showUnPaidInvoices; // Boolean(this.proofOfPayment.Allocated !== 'Fully');
    } // end showUnPaidInvoices()

    ngOnInit() {
        this.onShow.subscribe((response) => {
            if (response) {
                // if (Boolean(!this.util.objectIsSame(this.savedPopLineHandle, this.initPopLineHandle))) {
                //     this.showUnsavedChangesPrompt = true;
                // } else {
                    this.getInvoices(response.Id);
                    this.proofOfPayment = response;
                    this.promptUnPaidInvoicesEvent.next(response);
                    this.getUnPaidInvoices(this.proofOfPayment.LawFirmId);
                    this.lawFirmName = response.LawFirm;
                    this.invoicesReference = response.Reference;
                    // this.invoicesDate = response.Date;
                // }
            } else {
                this.promptInvoices = false;
                this.promptPaidInvoices = false;
                // this.promptUnPaidInvoicesEvent.next();
                this.unPaidInvoiceHandles = [];
            }
            // this.allocateRow = false;
            // this.allocateButton = false;
        });
    } // end ngOnInit()

    onHideErrorMessagePrompt() {
        this.saveButton = false;
        this.showErrorMessagePrompt = false;
    } // end onHideErrorMessagePrompt()

    onViewInvoice(invoiceId: number) {
        this.loadingService.showOverlay();
        this.notes = [];
        this.notesHandle = [];
        this.selectedTab = 'invoice';
        if (invoiceId) {
            let _note = [];
            if (this.isPracticeManager) {
            this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID, invoiceId, 4).subscribe({ next: (_data) => {
                _note = _data;
            }});
            } else if (!this.isPracticeManager) {
                 this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID, invoiceId, 4).subscribe({ next: (_data) => {
                     _note = _data;
                 }});
            }
            if (!this.isPracticeManager) {
                this.notes = _note.filter(x => x.QuestionText);
            } else {
                this.notes = _note;
            }
            this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
            // this.showProformaInvoice = true;
            //this.invoiceDetails =  
            this.invoiceDetailService.getInvoiceDetails(invoiceId).subscribe({ next: (_data) => {
                this.invoiceDetails = _data;
                this.selectedInvoiceId = invoiceId;
                
                if (this.invoiceDetails) {
                    const desc = this.invoiceDetails.InvoiceParties.split(' // ');
                    if (desc.length > 0) {
                        this.invoicePlaintiff = desc[0];
                    }
                    if (desc.length > 1) {
                        this.invoiceDefendant = desc[1];
                    }
                }
            }});

            this.notes.forEach(_notes => {
                this.notesHandle.push({
                    data: _notes,
                    state: {
                        isVisible: true,
                        isSelected: false,
                        isOpen: false
                    }
                });
            });
        }
        this.showProformaInvoice = true;
        this.loadingService.hideOverlay();
    } // end onViewInvoice()

    closeModal(value: string) {
        switch (value) {
            case 'proforma-invoice':
                this.showProformaInvoice = false;
            break;
        }
    } // end closeModal()

    continue() {
        this.initPopLineHandle = [];
        this.initPopLineHandle = this.util.objectCopy(this.savedPopLineHandle);
        this.onShow.subscribe({ next: (response) => {
            if (response) {
                if (Boolean(!this.util.objectIsSame(this.savedPopLineHandle, this.initPopLineHandle))) {
                    this.showUnsavedChangesPrompt = true;
                } else {
                    this.getInvoices(response.Id);
                    this.proofOfPayment = response;
                    this.promptUnPaidInvoicesEvent.next(response);
                    this.getUnPaidInvoices(this.proofOfPayment.LawFirmId);
                    this.lawFirmName = response.LawFirm;
                    this.invoicesReference = response.Reference;
                    this.invoicesDate = response.Date;
                }
            } else {
                this.promptInvoices = false;
                this.promptPaidInvoices = false;
                this.promptUnPaidInvoicesEvent;
            }
            // this.allocateRow = false;
            // this.allocateButton = false;
        }});
        this.onHideUnsavedChangesPrompt();
    } // end continue()

    onTotalsCalculateChange(event) {
        let total = 0;
        this.initPopLineHandle.forEach(inv => {
            total += Number(inv.data.Paid);
        });

        if (this.isFirmAllocate === true) {
            total += Number(this.allocateAmount);
        }

        this.totalAmount =  +parseFloat(total.toString()).toFixed(2);
        this.totalAmountLeft = this.proofOfPayment.Amount - this.totalAmount;

        if (this.initPopLineHandle.length > 0) {
            if (!this.initPopLineHandle.find(invoice => Number(invoice.data.Paid) === 0)) {
                if (this.totalAmountLeft !== 0) {
                    if (Number(this.totalAmount) === Number(this.proofOfPayment.Amount)) {
                        this.allocateButton = false;
                        this.saveButton = true;
                        this._showUnPaidInvoices = false;
                    } else {
                        this.allocateButton = true;
                        this.saveButton = false;
                        this._showUnPaidInvoices = true;
                    }
                } else {
                    this._showUnPaidInvoices = false;
                    this.allocateButton = false;
                    this.saveButton = true;
                    // if (Boolean(this.util.objectIsSame(this.savedPopLineHandle, this.initPopLineHandle))) {
                    //     this.saveButton = false;
                    // }
                }
            } else {
                this.allocateButton = false;
                this.saveButton = false;
                this._showUnPaidInvoices = true;
                if (this.initPopLineHandle.filter(invoice => Number(invoice.data.Paid) === 0).length > 0) {
                    this._showUnPaidInvoices = true;
                } else {
                    this._showUnPaidInvoices = false;
                }
            }

            if (event === 'edit') {
                this.invoiceToDelete = this._invoices.filter(de => de.AssignedToLawFirm === true)[0];
                if (this.invoiceToDelete && this.invoiceToDelete.Id > 0) {
                let allocatedata: POPLine[] = [];
                    allocatedata.push(this.invoiceToDelete);
                    this.processPaymentService.deleteRemoveProofOfPaymentLines(allocatedata).subscribe({ next: (del) => {
                        // On Next
                    },
                    error: (error) => {
                        // On error
                    },
                    complete: () => {
                        // On complete
                        this.allocateID = 0;
                        this.lawFirmName = '';
                        this.invoicesReference = '';
                        this.allocateAmount = 0;
                        this.isFirmAllocate = false;
                        this.allocateRow = false;
                        this._showUnPaidInvoices = true;
                        this.promptUnPaidInvoicesEvent.next(this.proofOfPayment);
                        this.refreshPage.emit(this.proofOfPayment);
                        let _total: number = 0;
                        this.initPopLineHandle.forEach(inv => {
                            _total += Number(inv.data.Paid);
                        });

                        this.totalAmount = _total;
                        this.totalAmountLeft = this.proofOfPayment.Amount - this.totalAmount;
                        if (Number(this.totalAmount) === Number(this.proofOfPayment.Amount)) {
                            this.allocateButton = false;
                            this.saveButton = true;
                            this._showUnPaidInvoices = false;
                        } else {
                            this.allocateButton = true;
                            this.saveButton = false;
                            this._showUnPaidInvoices = true;
                        }
                    }
                });
                }
            }
        } else {
            this.allocateButton = false;
            this.saveButton = false;
            this._showUnPaidInvoices = true;
        }
        if (event === 'allocate-to-firm') {
            this.saveButton = true;
        }
    } // end onTotalsCalculateChange()

    onAmountChange(line: POPLine, event) {
        if (this.userProfileService.userProfile.isImpersonator) {
            event.target.value = this.cpipe.transform(event.target.value,
                this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
        } else {
            event.target.value = this.cpipe.transform(event.target.value,
                this.userProfileService.userProfile.currencyDetails.symbol, 2);
        }
        if (line.Paid > line.Amount) {
            this.showErrorMessagePrompt = true;
        }
    } // end onAmountChange()

    onAmountClicked(line: POPLine, event) {
        if (this.userProfileService.userProfile.isImpersonator) {
            event.target.value = this.cpipe.parse(event.target.value,
                this.userProfileService.selectedUserProfile.currencyDetails.symbol);
        } else {
            event.target.value = this.cpipe.parse(event.target.value,
                this.userProfileService.userProfile.currencyDetails.symbol);
        }
    } // end onAmountClicked()

    // onAmountChange() {
    //     for (const value of this.invoices) {
    //         this.totalAmount = this.totalAmount + value.Paid;
    //         this.totalAmountLeft = this.proofOfPayment.Amount - this.totalAmount;
    //         value.Paid = 'R' + this.numberConversion(value.Paid);
    //     }
    // } // end onAmountChange()

    ngOnChanges(changes: SimpleChanges) {
    } // end ngOnChanges()

    onHideUnsavedChangesPrompt() {
        this.showUnsavedChangesPrompt = false;
    } // end dismiss()

    getUnPaidInvoices(clientId: number) {

        // const res =  
        this.processPaymentService.getUnPaidInvoicesByClient(clientId).subscribe({ next: (_data) => {
            let res = _data;
            res.sort((a, b) => {
                return b.Date.toUpperCase().localeCompare(a.Date.toUpperCase());
            });
            this.unPaidInvoiceHandles = [];
            this.unPaidInvoices = res;
            this.unPaidInvoices.forEach(invoices => {
                if (invoices.Amount > invoices.Paid) {
                    this.unPaidInvoiceHandles.push({
                        data: invoices,
                        state: {
                            isVisible: true,
                            isSelected: false,
                            isOpen: false
                        }
                    });
                }
            });
        }});
        // this._unPaidInvoices = this.unPaidInvoices;
    } // end getUnPaidInvoices()

    // onChangeCheckBox(Invoice: any, isChecked: boolean) {
        // if (isChecked) {
        //     if (Invoice === 0) {
        //         this.allocateInvoice = Invoice;
        //     } else {
        //         this.checkBoxSelected.push(Invoice);
        //     }
        // } else {
        //     if (Invoice === 0) {
        //         this.allocateInvoice = '';
        //     } else {
        //         const index = this.checkBoxSelected.indexOf(Invoice);
        //         this.checkBoxSelected.splice(index, 1);
        //     }
        // }

        // if (this.checkBoxSelected.length > 0 || this.allocateInvoice === '0') {
        //     this.showActions = true;
        // } else {
        //     this.showActions = false;
        // }
    // } // end onChangeCheckBox()

    onLawfirmCheckBoxChange(Invoice: any, isChecked: boolean) {
        if (Invoice > 0) {
            this.checkBoxSelected.push(this._invoices.filter(inv => inv.Id === Invoice)[0]);
            this.showActions = true;
        } else {
            this.showActions = false;
        }
    } // end onLawfirmCheckBoxChange()

    getInvoices(popId: number) {
        const snack: Snack = {
            label: 'Loading invoices added to proof of payment...',
            action: null
        };
        this.snackbarsService.make(snack).show();
        this.invoices = [];
        this._invoices = [];
        this.clearAllocationToLawfirm();
        this.totalAmount = 0;
        this.allocateAmount = 0;
        this.loadingService.showOverlay();
        this.processPaymentService.getLinesByPopId(popId).subscribe({ next: (res) => {
            // On next
            if (res.length > 0) {
                this.promptInvoices = false;
                this.promptPaidInvoices = true;
            } else {
                this.promptInvoices = true;
                this.promptPaidInvoices = false;
            }
            this._invoices = res;
            this.invoices = res.map(a => Object.assign({}, a));
            for (const value of this.invoices) {
                if (value.Invoice == null || value.Invoice === '') {
                    const index = this.invoices.indexOf(value);
                    this.invoices.splice(index, 1);
                    this.allocateAmount = value.Paid;
                    this.allocateID = value.Id;
                    this.allocateRow = true;
                    // this.allocateButton = false;
                    this.totalAmount = value.Paid;
                    this.isFirmAllocate = true;
                }
            }
        },
        error: (error) => {
            // On error
            this.loadingService.hideOverlay();
            snack.label = 'An error occured trying to view invoices added, please try again.';
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
        },
        complete: () => {
            // On complete

            if (this.initPopLineHandle.length > 0) {
                this.onChangesInvoices();
            } else {
                this.initInvoices();
            }
            for (const value of this.invoices) {
                // let amount = 0;
                // this._invoices.forEach(inv => {
                //     if (inv.AssignedToLawFirm === true) {
                //         amount = inv.Paid;
                //     }
                // });
                this.totalAmount = this.totalAmount + value.Paid; // + amount;
                this.totalAmountLeft = this.proofOfPayment.Amount - this.totalAmount;
            }
            this.initialInvoiceToAllocate();
            this.onTotalsCalculateChange('changed');
            // this.getAllocateAmount();
            // this.showSaveButton();
            this.loadingService.hideOverlay();
            this.snackbarsService.dismiss();
        }
    });
    } // end getInvoices()

    initInvoices() {
        this.previousReference = this.proofOfPayment.Reference;
        this.initPopLineHandle = [];
        this.invoices.forEach(invoice => {
            this.initPopLineHandle.push({
                data: invoice,
                state: {
                    isVisible: true,
                    isSelected: false,
                    isOpen: false
                }
            });
        });
    } // end initInvoices()

    onChangesInvoices() {
        if (this.previousReference === this.proofOfPayment.Reference) {
            this.invoices.forEach(invoice => {
                const d = this.initPopLineHandle.filter(_invoice => _invoice.data.Id === invoice.Id)[0];
                if (d) {

                } else {
                    this.initPopLineHandle = [];
                    this.initPopLineHandle.push({
                        data: invoice,
                        state: {
                            isVisible: true,
                            isSelected: false,
                            isOpen: false
                        }
                    });
                }
            });
        } else {
            this.initInvoices();
        }
    } // end initInvoices()

    toggleSelectAll() {
        this.selectAll = !this.selectAll;
        this.initPopLineHandle.forEach(invoice => {
            invoice.state.isSelected = this.selectAll;
            this.showActions = this.selectAll;
        });

        if (this._invoices.filter(inv => inv.AssignedToLawFirm === true).length > 0) {
            this.checkBoxSelected.push(this._invoices.filter(inv => inv.AssignedToLawFirm === true)[0]);
            this.showActions = this.selectAll;
            this.inputlawfirmCheckbox = this.selectAll;
        }
    } // end toggleSelectAll()

    toggleCheckbox(invoices: POPLineHandle) {
        invoices.state.isSelected = !invoices.state.isSelected;
        if (this.initPopLineHandle.find(invoice => invoice.state.isSelected === true)) {
            this.showActions = true;
        } else {
            this.showActions = false;
        }
    } // end toggleCheckbox()

    getDateFormet(value: Date): string {
        return moment(value).format('DD/MM/YYYY');
    }

    numberConversion(value: number): string {
        return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    } // end numberConversion()

    formatNumber(paid: string) {
        return Number((paid.toString().split('R')[1]).toString().split(' ').join(''));
    } // end formatNumber()

    onFocus(e, id: number) {
        for (const j of this.invoices) {
            if (j.Id === id) {
                j.Paid = j.Paid;
            }
        }
    } // end onFocus()

    // onFocusOut(e, id: number) {
    //     this.totalAmount = 0;
    //     for (const j of this.invoices) {
    //         if (j.Id === id) {

    //             if (Number(j.Paid) < 0) {
    //                 j.Paid = 0;
    //             }
    //             this.totalAmount = this.totalAmount + Number(j.Paid);
    //             // j.Paid = j.Paid));
    //         } else {
    //             this.totalAmount = this.totalAmount + j.Paid;
    //         }
    //     }
    //     this.getAllocateAmount();
    //     this.showSaveButton();
    // } // end onFocusOut()

    showSaveButton() {
        // if (this.totalAmount > 0) {
        //     // this.saveButton = true;
        //     // this.allocateButton = true;
        //     if (this.invoices) {
        //         this.saveButton = Boolean(!this.invoices.find(invoice => invoice.Paid === 0));
        //     }
        //     if (this.totalAmount > this.proofOfPayment.Amount) {
        //         this.saveButton = false;
        //         this.allocateButton = false;
        //     } else if (this.totalAmount === this.proofOfPayment.Amount) {
        //         this.saveButton = true;
        //         this.allocateButton = false;
        //     }
        // } else {
        //     this.saveButton = false;
        //     this.allocateButton = true;
        // }
    } // end showSaveButton()

    getAllocateAmount() {
        this.allocateAmount = this.proofOfPayment.Amount - this.totalAmount;

        if (this.allocateAmount <= 0) {
            this.allocateRow = false;
            this.allocateAmount = 0;
        }
        // if (this.allocateRow) {
        //     this.totalAmount = this.totalAmount + this.allocateAmount;
        // }
    } // end getAllocateAmount()

    onPaidInvoices($event) {
        if ($event !== 0) {
            this.promptInvoices = false;
            this.getInvoices($event);
            this.promptPaidInvoices = true;
        }
    } // end onPaidInvoices()


    removeInvoices() {
        this.showRemoveInvoices = true;
    } // end removeInvoices()

    onHideRemoveInvoices() {
        this.showRemoveInvoices = false;
    } // end onHideRemoveInvoices()

    onRemoveInvoices() {
        const snack: Snack = {
            label: 'Remove Invoices',
            action: null
        };
        this.loadingService.showOverlay();
        try {

            // if (this.allocateInvoice === '0') {
            //     this.allocateRow = false;
            //     this.totalAmount = this.totalAmount - this.allocateAmount;
            //     if (this.allocateID !== 0) {
            //         const allocatedata = [];
            //         const obj = {
            //             'Id': this.allocateID,
            //             'Invoice': null,
            //             'InvoiceId': null,
            //             'Matter': null,
            //             'Reference': this.invoicesReference,
            //             'Amount': 0,
            //             'Paid': this.allocateAmount,
            //             'AssignedToLawFirm': true
            //         };
            //         allocatedata.push(obj);
            //          this.processPaymentService.deleteRemoveProofOfPaymentLines(allocatedata).toPromise();
            //     }
            //     this.allocateID = 0;
            //     this.allocateAmount = 0;
            //     this.getAllocateAmount();
            //     this.showSaveButton();
            // }
            // if (this.checkBoxSelected.length > 0) {
            //     const data = this.checkBoxSelected.map(a => Object.assign({}, a));
            // }
            // if (this.initPopLineHandle.filter(invoice => invoice.state.isSelected === true).length > 0) {
                // const data = this.checkBoxSelected.map(a => Object.assign({}, a));
                if (this.initPopLineHandle.filter(invoic => invoic.state.isSelected === true).length > 0) {

                    this.initPopLineHandle.filter(invoic => invoic.state.isSelected === true).forEach(_invoice => {
                        this.todeletePopLineHandle.push(_invoice.data);
                    });
                }

                if (this.checkBoxSelected.length > 0) {
                    const data = this.checkBoxSelected[0];
                    this.todeletePopLineHandle.push(data);
                }
                // for (const j of data) {
                //     if (!Number(j.Paid)) {
                //     j.Paid = this.formatNumber(j.Paid);
                //     }
                // }
            // Log activity Login
            const currentDate = new Date();
            this.activityLog.Action = 'Remove Proof of payment line';
            this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
            this.activityLog.LoggedApp = 'Web Application (Invoices-added-proof-of-payment)';
            if (this.userProfileService.userProfile.isImpersonator) {
                this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
            } else {
                this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
            }
            this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
            this.activityLog.ActionTable = 'ProofOfPayment';
            this.activityLog.JsonData = JSON.stringify(this.todeletePopLineHandle);
            this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_data) => {}});

                this.processPaymentService.deleteRemoveProofOfPaymentLines(this.todeletePopLineHandle).subscribe({ next: (invoice) => {

                },
                error: (error) => {
                  // On Error
                },
                complete: () => {
                    // On Completed
                    snack.label = 'Removed Selected Invoices.....';
                    this.todeletePopLineHandle.forEach(_data => {
                         this.proofOfPayment.ProofOfPaymentLines = this.proofOfPayment.ProofOfPaymentLines
                        .filter(payment => payment.Id !== _data.Id);
                    });
                    this.initPopLineHandle = this.initPopLineHandle.filter(invoice => invoice.state.isSelected === false);
                    this.initialInvoiceToAllocate();
                     this.onPutProofOfPayment();
                    // this.promptUnPaidInvoicesEvent.next(this.proofOfPayment);
                    // this.refreshPage.emit(this.proofOfPayment);
                    snack.type = SnackType.SUCCESS;
                    //  this.getInvoices(this.proofOfPayment.Id);
                    //  this.processPaymentService.getProofOfPayment().subscribe(pop => {
                    //     this.proofOfPayment = pop;
                    // });
                    this.selectAll = false;
                    this.inputlawfirmCheckbox = false;
                    this.todeletePopLineHandle = [];
                    this.getUnPaidInvoices(this.proofOfPayment.LawFirmId);
                    this.snackbarsService.dismiss().make(snack).show();
                }
            }); // .toPromise();
            // }
        } catch (error) {
            snack.label = 'Something went wrong' + error;
            snack.action = null;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
        }
        this.showRemoveInvoices = false;
        this.showActions = false;
        this.checkBoxSelected = [];
    } // end onRemoveInvoices()


    saveInvoices() {
        const snack: Snack = {
            label: 'Save Proof of Payment Line',
            action: null
        };
        const data = this.initPopLineHandle.map(a => Object.assign({}, a));
        this.initPopLineHandle.filter(sa => sa.state.isVisible === true).forEach(invoice => {
            this.todeletePopLineHandle.push(invoice.data);
        });
        try {
            const allocateValue = false;
            this.proofOfPayment.ProofOfPaymentLines = [];
            // for (const j of data) {
            //     j.Paid = this.formatNumber(j.Paid);
            // }



            if (this.allocateRow === true) {
                const obj = {
                    'Id': this.allocateID,
                    'Invoice': null,
                    'InvoiceId': null,
                    'Matter': null,
                    'Reference': this.invoicesReference,
                    'Amount': 0,
                    'Paid': this.allocateAmount,
                    'AssignedToLawFirm': true
                };
                // data.push(obj);
                this.todeletePopLineHandle.push(obj);
            }

            this.proofOfPayment.ProofOfPaymentLines = this.todeletePopLineHandle;
            this.onPutProofOfPayment();
            this.initialInvoiceToAllocate();
            snack.label = 'Changes saved successfully';
            snack.type = SnackType.SUCCESS;
            this.todeletePopLineHandle = [];
            this.selectAll = false;
            this.inputlawfirmCheckbox = false;
            this.promptInvoices = false;
            this.snackbarsService.dismiss().make(snack).show();
        } catch (error) {
            snack.label = 'Something went wrong';
            snack.action = null;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
        }
    } // end saveInvoices()


   onPutProofOfPayment() {

       // Log activity Login
       const currentDate = new Date();
       this.activityLog.Action = 'Save Proof of payment line';
       this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
       this.activityLog.LoggedApp = 'Web Application (Invoices-added-proof-of-payment)';
       if (this.userProfileService.userProfile.isImpersonator) {
           this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
           this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
       } else {
           this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
           this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
       }
       this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
       this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
       this.activityLog.ActionTable = 'ProofOfPayment';
       this.activityLog.JsonData = JSON.stringify(this.proofOfPayment);
       this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_data) => {}});

        this.processPaymentService.putProofOfPayment(this.proofOfPayment).subscribe({ next: (pop) => {
            this.allocateRow = false;
            this.getInvoices(this.proofOfPayment.Id);
            this.promptUnPaidInvoicesEvent.next(this.proofOfPayment);
            this.refreshPage.emit(this.proofOfPayment);
            this.proofOfPayment = pop[0];
        },
            error: (error) => {
                // On error
            },
            complete: () => {
                // On Complete
            }
        });
    } // end onPutProofofPayment()


   allocateInvoices() {
        this.allocateID = 0;
        this.allocateRow = true;
        this.getAllocateAmount();
        this.allocateButton = false;
        this.promptPaidInvoices = true;
        this.promptInvoices = false;
        this.isFirmAllocate = true;
        // this.saveButton = true;
        // this.showSaveButton();
        this.onTotalsCalculateChange('allocate-to-firm');
    } // end allocateInvoices()

    clearAllocationToLawfirm() {
        // this.allocateID = 0;
        // this.lawFirmName = '';
        // this.invoicesReference = '';
        // this.allocateAmount = 0;
        this.isFirmAllocate = false;
        this.allocateRow = false;
    } // end clearAllocationToLawfirm()

    initialInvoiceToAllocate() {
        this.savedPopLineHandle = this.util.objectCopy(this.initPopLineHandle);
    } // end initialInvoiceToAllocate()
} // end InvoicesAddedProofOfPaymentsComponent{}
