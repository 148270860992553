import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {
    NoteCategory,
    NoteTypes,
    MultiObjectNote,
    Notes,
    NotesObject,
    MultiInvoiceNote,
    NotesService,
    InstructionResponses,
    DEFAULT_INSTRUCTION_RESPONSES,
    DEFAULT_MULTI_INVOICE_NOTE,
    NotesObjectHandle,
  } from '../../../services/notes/notes.service';
import { SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
// import { TypeaheadMatch } from 'ngx-bootstrap';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import * as moment from 'moment';
import { InvoiceDetails, DEFAULT_INVOICE_DETAILS, InvoiceDetailsService } from '../../../services/invoice-details/invoice-details.service';
import { LawfirmSummaryOverview } from '../../../services/reports/reports.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-client-multiple-invoice-note',
  templateUrl: './client-multiple-invoice-note.component.html',
  styleUrls: ['./client-multiple-invoice-note.component.scss']
})
export class ClientMultipleInvoiceNoteComponent implements OnInit {

  title = 'Note detail for multiple invoices';
  isInternalChecked: boolean;
  isExternalChecked: boolean;
  selectedNoteCategoryText: string;
  selectedNoteSubCategoryText: string;
  selectedNoteCategory: NoteCategory;
  selectedNoteSubCategory: NoteTypes;
  selectedActionDate: string;
  internalExternalText: string;
  internalText: string;
  resolutionText: string;
  noteSubCategoryList: NoteTypes[] = [];

  multiObjectNote: MultiObjectNote;
  // selectedNote: Notes;

  selectedResponseText: string;
  instructionText: string;
  selectedResponse: InstructionResponses;

  showRemoveInvoiceDialog: boolean;
  removeInvoiceMessage: string;
  invoiceTobeRemoved: MultiInvoiceNote;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  // Invoice Note
  selectedTab: string;
  selectedInvoiceId = 0;
  // notes
  notes: NotesObject[] = [];
  showProformaInvoice: boolean;
  onShowNoteDetail: boolean;
  invoiceDetails: InvoiceDetails;
  invoicePlaintiff: string;
  invoiceDefendant: string;
  lawfirmSummaryData: LawfirmSummaryOverview;
  selectedNotesHandle: NotesObjectHandle[] = [];

  @Input() isVisible: boolean;
  @Input() objectType: string;
  @Input() lawfirmName: string;
  @Input() noteDetailData: NotesObject;
  @Input() noteCategory: NoteCategory[];
  @Input() selectedNote: NotesObject;
  @Input() multiInvoiceNote: MultiInvoiceNote[];
  @Input() multipleObjectNotes: NotesObject[];
  @Input() instructionResponses: InstructionResponses[];
  @Output() closemodal: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateNote: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveMultiNote: EventEmitter<any> = new EventEmitter<any>();

  get isExternalInternal(): boolean {
    let isExternal = false;
    if (this.noteDetailData && this.noteDetailData.PriorityText.length > 0) {
      isExternal = true;
    } else {
      isExternal = false;
    }
    return isExternal;
  } // end isExternalInternal()

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  get isSaveable(): boolean {
    return Boolean(this.selectedResponse && this.selectedResponse.Id > 0) &&
      Boolean(this.selectedResponseText);
  } // end isSaveable()

  // Is Practice Manager
  get isPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  }  // end isPracticeManager()

  constructor(
    private noteService: NotesService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private invoiceDetailService: InvoiceDetailsService
  ) { } // end constructor()

  ngOnInit() {
    if (this.lawfirmName) {
      this.title = 'Note detail on multiple invoices for ' + this.lawfirmName;
    }
  } // end ngOnInit()

  onCancel() {
    this.closemodal.emit();
  } // end onCancel()

  onHideRemoveInvoiceDialog() {
    this.showRemoveInvoiceDialog = false;
  } // end onHideRemoveInvoiceDialog()

  onRemoveInvoiceDialog() {
    this.loadingService.showOverlay();
    this.invoiceTobeRemoved.AdvocateStatus = 'Removed';
    //  this.noteService.putMultiInvoiceObject(this.invoiceTobeRemoved).toPromise();
    const invoices = this.multiInvoiceNote.filter(_data => _data !== this.invoiceTobeRemoved);
    this.multiInvoiceNote = invoices;
    this.showRemoveInvoiceDialog = false;
    this.loadingService.hideOverlay();
  } // end onRemoveInvoiceDialog()

  onNoteResponseSelect(event: TypeaheadMatch): void {
    if (event) {
      this.selectedResponse = Object.assign({}, DEFAULT_INSTRUCTION_RESPONSES);
      this.selectedResponse = event.item;
      this.selectedResponseText = event.item.Response;
    }
  } // end onNoteResponseSelect()

  onInvoiceRemove(data: MultiInvoiceNote) {
    this.invoiceTobeRemoved = Object.assign({}, DEFAULT_MULTI_INVOICE_NOTE);
    this.invoiceTobeRemoved = data;
    this.removeInvoiceMessage = 'Are you sure you want to remove Invoice: ' + data.InvoiceNo + '?';
    this.showRemoveInvoiceDialog = true;
  } // end onInvoiceRemove()
  onViewInvoice(note: MultiInvoiceNote) {
    if (note.InvoiceId) {
      this.loadingService.showOverlay();
      this.notes = [];
      this.selectedNotesHandle = [];
      this.selectedTab = 'invoice';
      if (note.InvoiceId) {
        let _note = [];
        if (this.isPracticeManager) {
          // _note =  this.noteService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
          //   note.InvoiceId, 4).toPromise();
        } else if (!this.isPracticeManager) {
          // _note =  this.noteService.getNotes(this.userProfileService.userProfile.serviceProviderID,
          //   note.InvoiceId, 4).toPromise();
        }
        if (!this.isPracticeManager) {
          this.notes = _note.filter(x => x.QuestionText);
        } else {
          this.notes = _note;
        }
        this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
        // this.showProformaInvoice = true;
        // this.invoiceDetails =  this.invoiceDetailService.getInvoiceDetails(note.InvoiceId).toPromise();
        this.selectedInvoiceId = note.InvoiceId;

        if (this.invoiceDetails) {
          const desc = this.invoiceDetails.InvoiceParties.split(' // ');
          if (desc.length > 0) {
            this.invoicePlaintiff = desc[0];
          }
          if (desc.length > 1) {
            this.invoiceDefendant = desc[1];
          }
        }

        this.notes.forEach(_notes => {
          this.selectedNotesHandle.push({
            data: _notes,
            state: {
              isVisible: true,
              isSelected: false,
              isOpen: false
            }
          });
        });
      }
      this.showProformaInvoice = true;
      this.loadingService.hideOverlay();
    }
  } // end onViewInvoice()

  closeModal() {
    this.showProformaInvoice = false;
  } // end closeModal()

  onNoteSave() {
    this.loadingService.showOverlay();
    this.multipleObjectNotes.forEach(_data => {
      if (this.multiInvoiceNote.find(s => s.InvoiceId === _data.ObjectId)) {
        this.selectedNote.Id = _data.Id;
        this.selectedNote.ObjectId = _data.ObjectId;
        this.selectedNote.Subject = _data.Subject;
        this.selectedNote.NoteTypeId = _data.NoteTypeId;
        this.selectedNote.UserOwnerStatus = false;
        this.selectedNote.UserAssignedStatus = true;

        const noteToSave: Notes = {
          Id: _data.Id,
          ObjectId: _data.ObjectId,
          Subject: _data.Subject,
          NoteTypeId: _data.NoteTypeId,
          InternalText: this.selectedNote.InternalText,
          ExternalText: this.selectedNote.ExternalText,
          UserOwner: this.selectedNote.UserOwner,
          UserCreator: this.selectedNote.UserCreator,
          UserAssigned: this.selectedNote.UserAssigned,
          Priority: this.selectedNote.Priority,
          CaptureDate: this.selectedNote.CaptureDate,
          ActionDate: this.selectedNote.ActionDate,
          ActivityDate: this.selectedNote.ActivityDate,
          InstructionResponseId: this.selectedResponse.Id,
          InstructionText: this.instructionText,
          ResolutionText: '',
          InternalInstructionNote: this.selectedNote.InternalInstructionNote,
          MutliObjects: this.selectedNote.MutliObjects,
          InstructionCaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          ResolutionCaptureDate: null,
          IsVisibleUserOwner: false,
          IsVisibleUserCreator: false,
          IsVisibleUserAssigned: true
        };
        //  this.noteService.putNoteInboxStatus(this.selectedNote).toPromise();
        //  this.noteService.putNote(noteToSave).toPromise();
      }
    });
    this.saveMultiNote.emit();
    this.loadingService.hideOverlay();
  } // end onNoteSave()

} // end ClientMultipleInvoiceNoteComponent{}
