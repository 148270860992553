import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DEFAULT_TERMS, Terms, TermsService } from '../../../services/terms/terms.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import * as moment from 'moment';
import { ApiService } from '../../../services/api.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  title: string = '';
  isAccepted: boolean = false;
  pdfUrl = 'https://auxconstoreprd.blob.core.windows.net/tax-and-vat/SAAS_AGREEMENT_AUXCON.pdf'; // We still need to upload live document
  pdfSafeUrl: SafeResourceUrl | undefined;
  terms: Terms = DEFAULT_TERMS;

  @Input() isVisible: boolean;
  @Output() onCloseModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() AcceptTerms: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    private api: ApiService,
    private sanitizer: DomSanitizer,
    private termsService: TermsService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService
  ) { } // end constructor()

  ngOnInit(): void {
    // Sanitize the PDF URL
    this.pdfSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl);
  } // end ngOnInit()

  onCancel() {
    this.onCloseModal.emit();
  } // end onCancel()

  accept(): void {
    this.isAccepted = false;
    this.loadingService.showOverlay();
    this.terms = Object.assign({}, DEFAULT_TERMS);
    this.terms.acceptedDate = moment(new Date).format('YYYY-MM-DDTHH:mm:ss.SSS');
    this.terms.userId = this.api.serviceProviderID;
    this.termsService.onPostTerms(this.terms).subscribe({ next: (onnext) => {
      // On next
    },
    error: (error) => {
      // On Error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      const profileJson = sessionStorage.getItem('userProfile');

          if (!profileJson) {
            console.error('No userProfile found in sessionStorage.');
            this.loadingService.hideOverlay();
          } else {
            try {
              // Parse the JSON string into an object
              const profile = JSON.parse(profileJson);

              // Ensure settings object exists
              if (!profile.settings) {
                console.log('Settings object not found. Initializing...');
                profile.settings = {};
              }

              // Update the termsAccepted field
              profile.settings.termsAccepted = true;
              this.userProfileService.userProfile.settings.termsAccepted === true;

              // Save the updated object back to sessionStorage
              sessionStorage.removeItem('userProfile');
              sessionStorage.setItem('userProfile', JSON.stringify(profile));
              console.log('Updated Profile:', profile);
            } catch (error) {
              console.error('Error parsing userProfile:', error);
            }
            this.loadingService.hideOverlay();
          }
      this.onCloseModal.emit();      
    }
  });
} // end accept()

} // end TermsAndConditionsComponent{}
