import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  ApiService,
  httpOptions
} from '../api.service';
import { catchError, retry } from 'rxjs/operators';

import { Observable } from 'rxjs';
// import { provideForRootGuard } from '@angular/router/src/router_module';

export interface SentMessage {
  lawFirm: string;
  id: number;
  dateProcessed: string;
  source: string;
  sourceId: number;
  description: string;
  subject: string;
  body: string;
  documentPath: string;
  serviceProviderId: number;
  invoiceNo: string;
  messageRecipientId: number;
  email: string;
  status: string;
  dateDelivered: string;
  internalRefNo: string;
  serviceId: number;
  otherDocuments: boolean;
}

export interface ClientInboxMessages {
  iD: number;
  advocate: string;
  lawfirmId: number;
  Lawfirm: string;
  AttorneyId: number;
  Attorney: string;
  dateProcessed: string;
  DateDelivered: string;
  documentPath: string;
  invoiceId: number;
  invoiceNo: string;
  source: string;
  subject: string;
  status: string;
  clientStatus: boolean;
  clientStatusId: number;
}

@Injectable()
export class SentMessagesService {

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  getSentMessages(): Observable<any> {
    return this.http.get(this.api.endpoints.sentMessages +
      '?ServiceProviderId=' + this.api.serviceProviderID
      , this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getSentMessages()

  getSentMessagesByPage(SearchTerm: string, skip: number, take: number): Observable<any> {
    return this.http.get(this.api.endpoints.sentMessagesByPage +
      '?ServiceProviderId=' + this.api.serviceProviderID +
      '&SearchTerm=' + SearchTerm +
      '&Skip=' + skip +
      '&Take=' + take
      , this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getSentMessagesByPage()

  getSentMessagesBySearch(searchTerm: string): Observable<any> {
    return this.http.get(this.api.endpoints.sentMessagesBySearch +
      '?ServiceProviderId=' + this.api.serviceProviderID +
      '&SearchTerm=' + searchTerm
      , this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getSentMessagesByPage()

  getClientMessageInbox(profileType: string): Observable<any> {
    return this.http.get(this.api.endpoints.clientMessageInbox +
      '?clientId=' + this.api.serviceProviderID + '&profileType=' + profileType,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end getClientMessageInbox()

  getSentMessagesBySource(
    invoiceId: number,
    source: string = null
  ): Observable<any> {
    return null;
  } // end getSentMessagesBySource()

  putSentMessage(
    message: SentMessage,
    status: string,
    recipients: string
  ): Observable<any> {
    return this.http.put(this.api.endpoints.sentMessageAction +
      '?MessageId=' + message.id +
      '&Status=' + status +
      '&Recipients=' + recipients, message, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end putSentMessage()
} // end SentMessagesService{}
