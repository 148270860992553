import { Injectable } from '@angular/core';

@Injectable()
export class DatabaseService {

  db;

  private _result;

  constructor() { } // end constructor()
} // end DatabaseService{}