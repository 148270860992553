import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {
  AdvocateOverviewWidget,
  DEFAULT_ADVOCATE_OVERVIEW_WIDGET,
  FinancialDetails,
  DEFAULT_FINANCIAL_DETAILS,
  AdvocateOverviewServiceService,
  ContactRelationship,
  OverdueByFirm,
  PersonalDetails,
  DEFAULT_PERSONAL_DETAILS,
  OverviewContactDetails,
  DEFAULT_OVERVIEW_CONTACT_DETAILS,
  UserSubscription,
  DEFAULT_USER_SUBSCRIPTION,
  ContactStickyNotes,
  DEFAULT_CONTACT_STICKY_NOTE,
  ContactStickyNoteLogs,
  DEFAULT_CONTACT_STICKY_NOTE_LOGS,
  ContactNoteActionLogsSTO,
  PracticeManagerDTO,
  ProfileSettings,
  DEFAULT_PROFILE_SETTINGS
} from '../../services/advocate-overview-service/advocate-overview-service.service';
import { ICrumb } from '../../services/advocate-detail-statement/advocate-detail-statement.service';
import { UserProfile, UserProfileService } from '../../services/user-profile/user-profile.service';
import { DashboardNewService } from '../../services/dashboard-new/dashboard-new.service';
import { ListsService } from '../../services/lists/lists.service';
import { SnackbarsService, SnackType, Snack } from '../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { BankAccounts, BankAccountsService } from '../../services/bank-accounts/bank-accounts.service';
import {
  BarSociety,
  ChambersService,
  DEFAULT_ADVOCATE_CHAMBERS,
  AdvocateChambers,
  Chamber
} from '../../services/chambers/chambers.service';
import {
  ActivatedRoute,
  Router,
  Event,
  NavigationStart,
} from '@angular/router';
import * as moment from 'moment';
import { UtilitiesService } from '../../services/utilities.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { PATH_PRACTICE_ADVOCATE_OVERVIEW, 
         PATH_PRACTICE_MANAGER_REPORTS } from '../../services/appdata/app-config.service';
import { ApiService } from '../../services/api.service';

export enum TabOptions {
  Summary = 'Summary',
  Details = 'Details',
  FinancialDetails = 'FinancialDetails',
  ContactDetails = 'ContactDetails',
  Subscription = 'Subscription',
  ProfileInformation = 'ProfileInformation',
  Communication = 'Communication',
  ProfileSettings = 'ProfileSettings'
} // end ReportType{}

@Component({
  selector: 'app-advocate-overview',
  templateUrl: './advocate-overview.component.html',
  styleUrls: ['./advocate-overview.component.scss']
})
export class AdvocateOverviewComponent implements OnInit, OnDestroy {

  invMonthly: any;
  invoicedData: any;
  collectedData: any;
  collMonthly: any;
  crumbs: ICrumb[];
  widgetData: AdvocateOverviewWidget = Object.assign({}, DEFAULT_ADVOCATE_OVERVIEW_WIDGET);
  tabOptions: TabOptions;
  selectedTab: string;
  userFirstName: string;
  profile: any;
  showUnsavedChangesPrompt: boolean;
  isLoadingData: boolean;
  isLoadingProfileInfo: boolean;
  showVATFunctionalityErrorPrompt: boolean;

  // financialDetails: FinancialDetails;
  contactRelationship: ContactRelationship[];
  financialDetails: FinancialDetails = Object.assign({}, DEFAULT_FINANCIAL_DETAILS);
  workingFinancialDetails: FinancialDetails;

  // Contacts details
  workingOverviewContactDetails: OverviewContactDetails;
  overviewContactDetails: OverviewContactDetails = Object.assign({}, DEFAULT_OVERVIEW_CONTACT_DETAILS);
  overdueByFirm: OverdueByFirm[] = [];
  workingAdvocateChambers: AdvocateChambers;
  advocateChambers: AdvocateChambers;

  // Personal Details
  details: PersonalDetails = Object.assign({}, DEFAULT_PERSONAL_DETAILS);
  workingDetails: PersonalDetails = Object.assign({}, DEFAULT_PERSONAL_DETAILS);

  // Information Sticky note
  contactNoteActionLogsSTO: ContactNoteActionLogsSTO[] = [];
  contactStickyNote: ContactStickyNotes = Object.assign({}, DEFAULT_CONTACT_STICKY_NOTE);
  workingStickyNote: ContactStickyNotes = Object.assign({}, DEFAULT_CONTACT_STICKY_NOTE);
  contactStickyNoteLogs: ContactStickyNoteLogs = Object.assign({}, DEFAULT_CONTACT_STICKY_NOTE_LOGS);

  // Subscription
  workerUserSubscription: UserSubscription;
  userSubscription: UserSubscription = Object.assign({}, DEFAULT_USER_SUBSCRIPTION);

  // Profile Settings
  profileSettings: ProfileSettings = Object.assign({}, DEFAULT_PROFILE_SETTINGS);
  workingProfileSettings: ProfileSettings = Object.assign({}, DEFAULT_PROFILE_SETTINGS);

  private _exitRoute: string;
  showUnsavedPageChangesPrompt: boolean;
  private _canIgnoreNavigationBlock: boolean;
  private _subscriptionNavigationStart: ISubscription;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  // Bar Society list
  barSociety: BarSociety[] = [];
  // advocateChambers: AdvocateChambers = Object.assign({}, DEFAULT_ADVOCATE_CHAMBERS);

  get internalAssistant(): boolean {
    return Boolean(this.profile && this.profile.isImpersonator) &&
      !Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.EXTERNAL_ATTORNEY)
      && Boolean(this.profile.serviceProviderID === 9091 || this.profile.serviceProviderID === 29096 ||
        this.profile.serviceProviderID === 21647 || this.profile.serviceProviderID === 29723);
  } // end internalAssistant()

  get isPersonalDetailDirty() {
    return !this.util.objectIsSame(this.workingDetails, this.details);
  } // end isPersonalDetailDirty()

  get isProfileSettingsDirty() {
    return !this.util.objectIsSame(this.workingProfileSettings, this.profileSettings);
  } // end isProfileSettingsDirty()

  get isFinancialDetailsDirty() {
    return !this.util.objectIsSame(this.workingFinancialDetails, this.financialDetails);
  } // end isFinancialDetailsDirty()

  get isContactDetailsDirty() {
    return !this.util.objectIsSame(this.workingOverviewContactDetails, this.overviewContactDetails) ||
      !this.util.objectIsSame(this.workingAdvocateChambers, this.advocateChambers);
  } // end isContactDetailsDirty()

  get isSubscriptionDirty() {
    return !this.util.objectIsSame(this.workerUserSubscription, this.userSubscription);
  } // end isSubscriptionDirty()

  get isStickyNoteDirty() {
    return !this.util.objectIsSame(this.contactStickyNote, this.workingStickyNote);
  } // end isStickyNoteDirty()

  get isPersonalDetailsSaveable(): boolean {
    return this.isPersonalDetailDirty && Boolean(this.workingDetails);
  } // end isPersonalDetailsSaveable()

  get isFinancialDetailsSaveable(): boolean {
    let canSave = false;
    if (this.workingFinancialDetails.IsVatRegistered) {
      canSave = Boolean(this.workingFinancialDetails.VatBasedOn)
    } else {
      canSave = true;
    }
    return this.isFinancialDetailsDirty && Boolean(this.workingFinancialDetails) && canSave;
  } // end isFinancialDetailsSaveable()

  get isContactDetailsSaveable(): boolean {
    return this.isContactDetailsDirty && Boolean(this.workingOverviewContactDetails);
  } // end isContactDetailsSaveable()

  get isSubscriptionSaveable(): boolean {
    return this.isSubscriptionDirty && Boolean(this.workerUserSubscription);
  } // end isSubscriptionSaveable()

  get isDirty(): boolean {
    let dirtyTab = false;
    if (this.tabOptions === TabOptions.Subscription) {
      dirtyTab = this.isSubscriptionDirty;
    } else if (this.tabOptions === TabOptions.Details) {
      dirtyTab = this.isPersonalDetailDirty;
    } else if (this.tabOptions === TabOptions.FinancialDetails) {
      dirtyTab = this.isFinancialDetailsDirty;
    } else if (this.tabOptions === TabOptions.ContactDetails) {
      dirtyTab = this.isContactDetailsDirty;
    } else if (this.tabOptions === TabOptions.ProfileInformation) {
      dirtyTab = this.isStickyNoteDirty;
    }
    return dirtyTab;
  } // end isDirty()

  selectedAdvocate: UserProfile;
  isValidateAdmissionMonth : boolean;
  isValidateAdmissionYear : boolean;
  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private util: UtilitiesService,
    private listsService: ListsService,
    private navigation: NavigationService,
    private loadingService: LoadingService,
    private chambersService: ChambersService,
    private snackbarsService: SnackbarsService,
    private dashboardService: DashboardNewService,
    private userProfileService: UserProfileService,
    private bankAccountsService: BankAccountsService,
    private advocateOverviewService: AdvocateOverviewServiceService,
  ) { } // end constrcutor()

  ngOnInit() {
    this.advocateOverviewService.getOverdueByFirm().subscribe({
      next: (_data) => {
        // On next
        this.overdueByFirm = _data;
        console.log('Advocate: ', this.overdueByFirm);
      }
    });
    this.profile = this.userProfileService.userProfile;
    // this.loadingService.showOverlay();
    this.crumbs = [
      {
        label: 'Advocates | ' + this.userProfileService.selectedUserProfile.personalDetails.fullName
      }
    ];

    this.userFirstName = this.userProfileService.selectedUserProfile.personalDetails.firstName;
    this.tabOptions = TabOptions.Summary;
    this.selectedTab = TabOptions.Summary;
    if (this.internalAssistant) {
      this.tabOptions = TabOptions.Communication;
    }

    this.widgetData.InvoicedTarget = 72;
    this.widgetData.CollectionTarget = 1.5;
    this.widgetData.TotalInvoiced = 32678;
    this.widgetData.TotalCollectable = 234568;

    this.getInvoicedMonthlyDashboard();
    this.getCollectedMonthlyDashboard();

    this.barSociety = [];
    this.chambersService.getBarSociety().subscribe({
      next: (_data) => {
        // On next
        this.barSociety = _data;
        this.loadingService.hideOverlay();
      }
    });

    this._subscriptionNavigationStart = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart && this.isDirty) {
        this._exitRoute = event.url;
        if (location.href.includes(PATH_PRACTICE_ADVOCATE_OVERVIEW)) {
          if (this.isDirty && !this._canIgnoreNavigationBlock) {
            this.navigation.canNavigate = false;
            this.showUnsavedPageChangesPrompt = true;
          } else {
            this.navigation.canNavigate = true;
          }
        }
      }
    });
  } // end ngOnInit()

  ngOnDestroy() {
    this._subscriptionNavigationStart.unsubscribe();
  } // end ngOnDestroy()

  onHideVATFunctionalityErrorPrompt() {
    this.showVATFunctionalityErrorPrompt = false;
  } // end onHideVATFunctionalityErrorPrompt()

  leaveIgnorePage() {
    this.showVATFunctionalityErrorPrompt = false;
  } // end leaveIgnorePage()

  isValidateAdmissionDate(eventData: { isValidAdmissionMonth: boolean, isValidAdmissionYear: boolean }) {
    this.isValidateAdmissionMonth = eventData.isValidAdmissionMonth;
    this.isValidateAdmissionYear = eventData.isValidAdmissionYear;
  }

  toggleTab(tab: string) {
    if (this.isPersonalDetailDirty && this.selectedTab === 'Details') {
      this.selectedTab = tab;
      this.showUnsavedChangesPrompt = true;
    } else if (this.isFinancialDetailsDirty && this.selectedTab === 'FinancialDetails') {
      this.selectedTab = tab;
      this.showUnsavedChangesPrompt = true;
    } else if (this.isContactDetailsDirty && this.selectedTab === 'ContactDetails') {
      this.selectedTab = tab;
      this.showUnsavedChangesPrompt = true;
    } else if (this.isSubscriptionDirty && this.selectedTab === 'Subscription') {
      this.selectedTab = tab;
      this.showUnsavedChangesPrompt = true;
    } else if (this.isProfileSettingsDirty && this.selectedTab === 'ProfileSettings') {
      this.selectedTab = tab;
      this.showUnsavedChangesPrompt = true;
    } else if(this.isStickyNoteDirty && this.selectedTab === 'ProfileInformation') {
      this.selectedTab = tab;
      this.showUnsavedChangesPrompt = true;
    } else {
      // console.log('TAB: ', tab)
      switch (tab) {
        case 'Summary':
          this.tabOptions = TabOptions.Summary;
          this.selectedTab = TabOptions.Summary;
        break;
        case 'Details':
          this.getPersonalDetails();
          this.tabOptions = TabOptions.Details;
          this.selectedTab = TabOptions.Details;
        break;
        case 'FinancialDetails':
          this.getFinancialDetails();
          this.tabOptions = TabOptions.FinancialDetails;
          this.selectedTab = TabOptions.FinancialDetails;
        break;
        case 'ContactDetails':
          this.getContactDetails();
          this.tabOptions = TabOptions.ContactDetails;
          this.selectedTab = TabOptions.ContactDetails;
        break;
        case 'Subscription':
          this.getSubscription();
          this.tabOptions = TabOptions.Subscription;
          this.selectedTab = TabOptions.Subscription;
        break;
        case 'ProfileSettings':
          this.getProfileSettings();
          this.tabOptions = TabOptions.ProfileSettings;
          this.selectedTab = TabOptions.ProfileSettings;
        break;
        case 'Communication':
          this.tabOptions = TabOptions.Communication;
          this.selectedTab = TabOptions.Communication;
        break;
        case 'ProfileInformation':
          this.getStickyNote();
          this.getNoteActionLogs();
          this.tabOptions = TabOptions.ProfileInformation;
          this.selectedTab = TabOptions.ProfileInformation;
        break;
      }
    }
  } // end toggleTab()

  onHideUnsavedPageChangesPrompt() {
    this.showUnsavedPageChangesPrompt = false;
  } // end onHideUnsavedPageChangesPrompt()

  leaveUnSavePage() {
    // console.log('ARE WE CALLED: ');
    this._canIgnoreNavigationBlock = true;
    this.onHideUnsavedPageChangesPrompt();
    setTimeout((thisObj) => {
      thisObj.router.navigate([this._exitRoute.replace('/', '')]);
    }, 300, this);
  } // end leaveUnSavePage()

  onHideUnsavedChangesPrompt() {
    this.selectedTab = this.tabOptions;
    this.showUnsavedChangesPrompt = false;
  } // end onHideUnsavedChangesPrompt()

  onPMChange(value: PracticeManagerDTO) {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Changing Practice Manager...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();
    this.advocateOverviewService.updatePracticeManager(value)
      .subscribe({
        next: (data) => {
          // On next
        },
        error: (error) => {
          // On error
          snack.label = 'Error changing practice manager.';
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          this.getSubscription();
          snack.label = 'Practice Manager changed successfully.';
          snack.type = SnackType.SUCCESS;
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.showOverlay();
        }
      });
  } // end onPMChange()

  leavePage() {
    if (!this.isPersonalDetailDirty) {
      this.workingDetails = this.util.objectCopy(this.details);
    } else if (!this.isFinancialDetailsDirty) {
      this.workingFinancialDetails = this.util.objectCopy(this.financialDetails);
    } else if (!this.isContactDetailsDirty) {
      this.workingOverviewContactDetails = this.util.objectCopy(this.overviewContactDetails);
      this.workingAdvocateChambers = this.util.objectCopy(this.advocateChambers);
    } else if (!this.isSubscriptionDirty) {
      this.workerUserSubscription = this.util.objectCopy(this.userSubscription);
    }
    switch (this.selectedTab) {
      case 'Summary':
        this.tabOptions = TabOptions.Summary;
        break;
      case 'Details':
        this.getPersonalDetails();
        this.tabOptions = TabOptions.Details;
        break;
      case 'FinancialDetails':
        this.getFinancialDetails();
        this.tabOptions = TabOptions.FinancialDetails;
        break;
      case 'ContactDetails':
        this.getContactDetails();
        this.tabOptions = TabOptions.ContactDetails;
        break;
      case 'Subscription':
        this.getSubscription();
        this.tabOptions = TabOptions.Subscription;
      break;
      case 'Subscription':
        this.getProfileSettings();
        this.tabOptions = TabOptions.ProfileSettings;
      break;
      case 'Communication':
        this.tabOptions = TabOptions.Communication;
        break;
      case 'ProfileInformation':
        this.getStickyNote();
        this.getNoteActionLogs();
        this.tabOptions = TabOptions.ProfileInformation;
        this.selectedTab = TabOptions.ProfileInformation;
        break;
    }
    this.showUnsavedChangesPrompt = false;
  } // end leavePage()

  getStickyNote() {
    this.isLoadingProfileInfo = true;
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Loading sticky note...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();
    this.advocateOverviewService.getContactStickyNote().subscribe({
      next: (note) => {
        // On next
        if (note) {
          this.contactStickyNote = note;
        }
      },
      error: (error) => {
        // On error
        snack.label = 'Error loading sticky note.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.isLoadingProfileInfo = false;
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.workingStickyNote = Object.assign({}, this.contactStickyNote);
        snack.label = 'Sticky note data loaded.';
        snack.type = SnackType.SUCCESS;
        this.snackbarsService.dismiss().make(snack).show();
        this.isLoadingProfileInfo = false;
        this.loadingService.hideOverlay();
      }
    });
  } // end getStickyNote()

  onStickyNoteSave(data: ContactStickyNotes) {
    if (data && data.Note) {
      this.loadingService.showOverlay();
      const snack: Snack = {
        label: 'Saving note...',
        action: null
      };
      this.snackbarsService.make(snack, 5000).show();

      // Action log
      const currentDate = new Date();
      data.ContactId = this.userProfileService.selectedUserProfile.serviceProviderID;
      data.ActionDate = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.contactStickyNoteLogs.LoggedBy = this.userProfileService.userProfile.serviceProviderID;
      this.contactStickyNoteLogs.ActionDateLog = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      if (this.contactStickyNote && this.contactStickyNote.Id) {
        this.contactStickyNoteLogs.ContactStickyNoteId = this.contactStickyNote.Id;
        this.contactStickyNoteLogs.PreviousObject = this.contactStickyNote.Note;
      }
      this.advocateOverviewService.addContactStickyNoteLogs(this.contactStickyNoteLogs).subscribe({ next: (_next) => { } });
      this.advocateOverviewService.updateContactStickyNote(data).subscribe({
        next: (note) => {
          // On next
          this.contactStickyNote = note;
        },
        error: (error) => {
          // On error
          snack.label = 'Error saving note.';
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On completed
          this.getNoteActionLogs();
          this.getStickyNote();
          snack.label = 'Note Saved.';
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        }
      });
    } else {

    }
  } // end onStickyNoteSave()

  onSubscriptionStatusUpdate(data: ProfileSettings) {
    const snack: Snack = {
      label: 'Update Subscription status...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();
    this.loadingService.showOverlay();
    this.advocateOverviewService.updateSubscriptionStatus(data.AdvocateId, data.Active).subscribe({
      next: (sub) => {
        // On next
        this.userSubscription = sub;
      },
      error: (error) => {
        // On error
        snack.label = 'Error updating status.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        snack.label = 'Subscription status updated.';
        this.snackbarsService.dismiss().make(snack).show();
        this.workerUserSubscription = this.util.objectCopy(this.userSubscription);
        this.navigation.canNavigate = true;
        this._canIgnoreNavigationBlock = true;
        this.getProfileSettings();

        const selectedUs: UserProfile = this.profile.impersonates
          .filter(s => s.serviceProviderID === this.userProfileService.userProfile.serviceProviderID);
        this.selectedAdvocate = selectedUs;

        this.api.serviceProviderID = this.selectedAdvocate.serviceProviderID;
        sessionStorage.setItem('advocateUserProfil', JSON.stringify(selectedUs));

        this.router.navigate([PATH_PRACTICE_MANAGER_REPORTS]);
        this.userProfileService.refreshSelectedProfile = true;
        this.loadingService.hideOverlay();
      }
    });
  } // end onSubscriptionStatusUpdate()

  getSubscription() {
    this.isLoadingData = true;
    this.loadingService.showOverlay();
    this.userSubscription = Object.assign({}, DEFAULT_USER_SUBSCRIPTION);
    this.advocateOverviewService.getSubscription().subscribe({
      next:
        (data) => {
          // On next
          if (data) {
            this.userSubscription = data;
          }
        },
      error: (error) => {
        // On error
        this.isLoadingData = false;
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.workerUserSubscription = this.util.objectCopy(this.userSubscription);
        this.isLoadingData = false;
        this.loadingService.hideOverlay();
      }
    });
  } // end getSubscription()

  getProfileSettings() {
    this.isLoadingData = true;
    this.loadingService.showOverlay();
    this.profileSettings = Object.assign({}, DEFAULT_PROFILE_SETTINGS);
    this.advocateOverviewService.getProfileSettings().subscribe({
      next:
        (data) => {
          // On next
          if (data) {
            this.profileSettings = data;
          }
        },
      error: (error) => {
        // On error
        this.isLoadingData = false;
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.workingProfileSettings = this.util.objectCopy(this.profileSettings);
        this.isLoadingData = false;
        this.loadingService.hideOverlay();
      }
    });
  } // end getProfileSettings()

  onSubscriptionSave() {
    this.isLoadingData = true;
    const snack: Snack = {
      label: 'Save subscription details...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();
    this.loadingService.showOverlay();
    if (this.workerUserSubscription.ContactId === 0) {
      this.workerUserSubscription.ContactId = this.profile.serviceProviderID;
    }
    
    if (this.workerUserSubscription.SignedDate) {
      this.workerUserSubscription.SignedDate = moment(this.workerUserSubscription.SignedDate).format('YYYY-MM-DD HH:mm:ss');
    }

    this.workerUserSubscription.DebitOrder = this.workerUserSubscription.DebitOrder === undefined || null ?
      false : this.workerUserSubscription.DebitOrder;
    this.workerUserSubscription.AuxconMonthlySiteRental = this.workerUserSubscription.AuxconMonthlySiteRental === undefined || null ?
      false : this.workerUserSubscription.AuxconMonthlySiteRental;
    this.workerUserSubscription.AuxconExpenseManagement = this.workerUserSubscription.AuxconExpenseManagement === undefined || null ?
      false : this.workerUserSubscription.AuxconExpenseManagement;
    this.workerUserSubscription.AdHocCollections = this.workerUserSubscription.AdHocCollections === undefined || null ?
      false : this.workerUserSubscription.AdHocCollections;
    this.workerUserSubscription.AuxconInvoicing = this.workerUserSubscription.AuxconInvoicing === undefined || null ?
      false : this.workerUserSubscription.AuxconInvoicing;
    this.workerUserSubscription.AuxconReconcilliations = this.workerUserSubscription.AuxconReconcilliations === undefined || null ?
      false : this.workerUserSubscription.AuxconReconcilliations;
    this.workerUserSubscription.AuxconCollections = this.workerUserSubscription.AuxconCollections === undefined || null ?
      false : this.workerUserSubscription.AuxconCollections;
    this.workerUserSubscription.PremiumInvoicing = this.workerUserSubscription.PremiumInvoicing === undefined || null ?
      false : this.workerUserSubscription.PremiumInvoicing;
    this.workerUserSubscription.PremiumCollections = this.workerUserSubscription.PremiumCollections === undefined || null ?
      false : this.workerUserSubscription.PremiumCollections;
    this.workerUserSubscription.PremiumCollections = this.workerUserSubscription.PremiumCollections === undefined || null ?
      false : this.workerUserSubscription.PremiumCollections;
    this.workerUserSubscription.EightFivePercent = this.workerUserSubscription.EightFivePercent === undefined || null ?
      false : this.workerUserSubscription.EightFivePercent;
    this.workerUserSubscription.TwelveMonths = this.workerUserSubscription.TwelveMonths === undefined || null ?
      false : this.workerUserSubscription.TwelveMonths;
    this.workerUserSubscription.GoLive = this.workerUserSubscription.GoLive === undefined || null ?
      false : this.workerUserSubscription.GoLive;

    if (this.workerUserSubscription.MonthlySiteAmount) {
      this.workerUserSubscription.MonthlySiteAmount =
        Number(parseFloat(this.workerUserSubscription.MonthlySiteAmount.toString()).toFixed(2));
    } else {
      this.workerUserSubscription.MonthlySiteAmount = 0;
    }

    if (this.workerUserSubscription.ReconciliationAmount) {
      this.workerUserSubscription.ReconciliationAmount =
        Number(parseFloat(this.workerUserSubscription.ReconciliationAmount.toString()).toFixed(2));
    } else {
      this.workerUserSubscription.ReconciliationAmount = 0;
    }

    if (this.workerUserSubscription.InvoicingAmount) {
      this.workerUserSubscription.InvoicingAmount =
        Number(parseFloat(this.workerUserSubscription.InvoicingAmount.toString()).toFixed(2));
    } else {
      this.workerUserSubscription.InvoicingAmount = 0;
    }

    if (this.workerUserSubscription.ExpenseAmount) {
      this.workerUserSubscription.ExpenseAmount = Number(parseFloat(this.workerUserSubscription.ExpenseAmount.toString()).toFixed(2));
    } else {
      this.workerUserSubscription.ExpenseAmount = 0;
    }

    if (this.workerUserSubscription.CollectionsAmount) {
      this.workerUserSubscription.CollectionsAmount =
        Number(parseFloat(this.workerUserSubscription.CollectionsAmount.toString()).toFixed(2));
    } else {
      this.workerUserSubscription.CollectionsAmount = 0;
    }
    // console.log('USER SUBSCRIPTION: ', this.workerUserSubscription);

    this.advocateOverviewService.putAddEditSubsription(this.workerUserSubscription).subscribe({
      next:
        (save_data) => {
          // On next
        },
      error: (error) => {
        // On error
        snack.label = 'Error saving subcription details.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        this.isLoadingData = false;
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.userSubscription = Object.assign({}, DEFAULT_USER_SUBSCRIPTION);
        this.advocateOverviewService.getSubscription().subscribe({
          next: (_data) => {
            // On next
            this.userSubscription = _data;
            this.workerUserSubscription = this.util.objectCopy(this.userSubscription);
            snack.label = 'Subscription details saved.';
            snack.type = SnackType.SUCCESS;
            this.navigation.canNavigate = true;
            this._canIgnoreNavigationBlock = true;
            this.snackbarsService.dismiss().make(snack).show();
            this.isLoadingData = false;
            this.loadingService.hideOverlay();
          }
        });
      }
    });
  } // end onSubscriptionSave()

  getContactDetails() {
    this.loadingService.showOverlay();
    this.getAdvocateChamber();
    this.overviewContactDetails = Object.assign({}, DEFAULT_OVERVIEW_CONTACT_DETAILS);
    this.advocateOverviewService.getContactDetails().subscribe({
      next:
        (data) => {
          // On next
          if (data) {
            this.overviewContactDetails = data;
          }
        },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.workingOverviewContactDetails = this.util.objectCopy(this.overviewContactDetails);
        this.loadingService.hideOverlay();
      }
    });
  } // end getContactDetails()

  getAdvocateChamber() {
    this.advocateChambers = Object.assign({}, DEFAULT_ADVOCATE_CHAMBERS);
    this.chambersService.getContactChamber().subscribe({
      next:
        (data) => {
          // On next
          if (data) {
            console.log('CHAMBERS: ', data);
            this.advocateChambers = data;
          }
        },
      error: (error) => {
        // On error
      },
      complete: () => {
        // On complete
        this.workingAdvocateChambers = this.util.objectCopy(this.advocateChambers);
      }
    });
  } // end getAdvocateChamber()

  onContactDetailSave() {
    this.isLoadingData = true;
    const snack: Snack = {
      label: 'Save contact details...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();
    this.workingAdvocateChambers.ContactId = this.userProfileService.selectedUserProfile.serviceProviderID;
    this.workingOverviewContactDetails.Id = this.userProfileService.selectedUserProfile.serviceProviderID;
    this.workingOverviewContactDetails.ChambersAddress = this.workingAdvocateChambers.Address;
    this.loadingService.showOverlay();
    // console.log('CHAMBERS: ', this.workingAdvocateChambers);
    this.chambersService.putAdvocateChambers(this.workingAdvocateChambers).subscribe({
      next:
        (chambers) => {
          // On next
           console.log('CHAMBER: ', chambers);
        },
      error: (error) => {
        // On error
        console.log('CHAMBER ERROR: ', error);
        snack.label = 'Error saving contact details..';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        this.isLoadingData = false;
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.advocateOverviewService.putContactDetails(this.workingOverviewContactDetails).subscribe({ next: (_next) => { } });
        this.overviewContactDetails = Object.assign({}, DEFAULT_OVERVIEW_CONTACT_DETAILS);
        this.advocateOverviewService.getContactDetails().subscribe({ next: (_data) => {
          // On next
          // console.log('RETURN: ', _data);
          this.overviewContactDetails = _data;
          this.workingAdvocateChambers = this.util.objectCopy(this.advocateChambers);
          this.workingOverviewContactDetails = this.util.objectCopy(this.overviewContactDetails);
          snack.label = 'Contact details saved..';
          snack.type = SnackType.SUCCESS;
          this.snackbarsService.dismiss().make(snack).show();
          this.isLoadingData = false;
          this.navigation.canNavigate = true;
          this._canIgnoreNavigationBlock = true;
          this.getContactDetails();
          this.loadingService.hideOverlay();
        }});
      }
    });
  } // end onContactDetailSave()

  getFinancialDetails() {
    // this._subscriptionNavigationStart.unsubscribe();
    this.loadingService.showOverlay();
    this.financialDetails = Object.assign({}, DEFAULT_FINANCIAL_DETAILS);
    this.advocateOverviewService.getFinancialDetails().subscribe({
      next:
        (data) => {
          // On next
          if (data) {
            this.financialDetails = data;
          }
        },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On next
        this.workingFinancialDetails = this.util.objectCopy(this.financialDetails);
        // this._subscriptionNavigationStart = this.router.events.subscribe((event: Event) => {
        //   if (event instanceof NavigationStart && this.isDirty) {
        //     this._exitRoute = event.url;
        //     if (location.href.includes(PATH_PRACTICE_ADVOCATE_OVERVIEW)) {
        //       if (this.isDirty && !this._canIgnoreNavigationBlock) {
        //         this.navigation.canNavigate = false;
        //         this.showUnsavedPageChangesPrompt = true;
        //       } else {
        //         this.navigation.canNavigate = true;
        //       }
        //     }
        //   }
        // });
        this.loadingService.hideOverlay();
      }
    });
  } // end getFinancialDetails()

  onFinancialDetailsSave() {
    if (this.workingFinancialDetails.IsVatRegistered && !this.workingFinancialDetails.VATNumber) {
      this.showVATFunctionalityErrorPrompt = true;
    } else {
      this.loadingService.showOverlay();
      this.isLoadingData = true;
      const snack: Snack = {
        label: 'Save financial details.',
        action: null
      };
      this.snackbarsService.make(snack, 5000).show();

      if (!this.workingFinancialDetails.IsVatRegistered) {
        this.workingFinancialDetails.VATNumber = null;
        this.workingFinancialDetails.ReportToAuditors = false;
        this.workingFinancialDetails.VATReport = false;
        this.workingFinancialDetails.InvoiceHistoryReport = false;
        this.workingFinancialDetails.BankStatement = false;
        this.workingFinancialDetails.AuditorName = '';
        this.workingFinancialDetails.AuditorEmail = '';
        this.workingFinancialDetails.AuditorPhone = '';
      }
      this.workingFinancialDetails.HourlyRate = Number(this.workingFinancialDetails.HourlyRate);
      this.workingFinancialDetails.DailyRate = Number(this.workingFinancialDetails.DailyRate);
      this.workingFinancialDetails.IncomeTarget = Number(this.workingFinancialDetails.IncomeTarget);
      this.workingFinancialDetails.FeeTarget = Number(this.workingFinancialDetails.FeeTarget);
      this.advocateOverviewService.putFinancialDetails(this.workingFinancialDetails).subscribe({
        next: (fdefails) => {
          // On next
        },
        error: (error) => {
          // On error
          snack.label = 'Error saving financial details.';
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack, 5000).show();
          this.isLoadingData = false;
          this.loadingService.hideOverlay();
        },
        complete: () => {
          this.financialDetails = Object.assign({}, DEFAULT_FINANCIAL_DETAILS);
          this.advocateOverviewService.getFinancialDetails().subscribe({
            next: (_data) => {
              // On next
              this.financialDetails = _data;
              this.workingFinancialDetails = this.util.objectCopy(this.financialDetails);
            }
          });
          snack.label = 'Financial details saved.';
          snack.type = SnackType.SUCCESS;
          this.snackbarsService.dismiss().make(snack).show();
          this.isLoadingData = false;
          this.navigation.canNavigate = true;
          this._canIgnoreNavigationBlock = true;
          this.loadingService.hideOverlay();
        }
      });
    }
  } // end onSave()

  getPersonalDetails() {
    // this._subscriptionNavigationStart.unsubscribe();
    this.loadingService.showOverlay();
    this.isLoadingData = true;
    this.advocateOverviewService.getPersonalDetails().subscribe({
      next:
        (data) => {
          // On next
          if (data) {
            this.details = data;
            const fullname = this.details.AdvocateFirstName;
            const name = this.details.AdvocateFirstName.trim().split(' ');
            this.details.AdvocateFirstName = name[0];
            this.details.AdvocateLastName = fullname.replace(name[0], '').trim();
          }
        },
      error: (error) => {
        // On error
        this.isLoadingData = false;
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.workingDetails = this.util.objectCopy(this.details);

        
        // this._subscriptionNavigationStart = this.router.events.subscribe((event: Event) => {
        //   if (event instanceof NavigationStart && this.isDirty) {
        //     this._exitRoute = event.url;
        //     if (location.href.includes(PATH_PRACTICE_ADVOCATE_OVERVIEW)) {
        //       if (this.isDirty && !this._canIgnoreNavigationBlock) {
        //         this.navigation.canNavigate = false;
        //         this.showUnsavedPageChangesPrompt = true;
        //       } else {
        //         this.navigation.canNavigate = true;
        //       }
        //     }
        //   }
        // });
        this.isLoadingData = false;
        this.loadingService.hideOverlay();
      }
    });
  } // end getPersonalDetails()

  getNoteActionLogs() {
    this.contactNoteActionLogsSTO = [];
    this.advocateOverviewService.getNoteActionLogs().subscribe({
      next:
        (note) => {
          // On next
          this.contactNoteActionLogsSTO = note;
        },
      error: (error) => {
        // On error
      },
      complete: () => {
        // On complete
      }
    });
  }

  onSaveAdvocateDetails() {
  if((this.isValidateAdmissionMonth == true && this.isValidateAdmissionYear == true) || 
  this.isValidateAdmissionMonth == true || this.isValidateAdmissionYear == true)
  return false;    

    const snack: Snack = {
      label: 'Saving advocate personal details',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();
    this.loadingService.showOverlay();
    // if (this.workingDetails.AdmissionDate) {
    //   this.workingDetails.AdmissionDate = moment(this.workingDetails.AdmissionDate).format('YYYY-MM-DD HH:mm:ss');
    // }
    // this.workingDetails.AdmissionDay =
    this.advocateOverviewService.putPersonalDetails(this.workingDetails).subscribe({
      next: (onDataSave) => {
        // On next
      },
      error: (error) => {
        // On error
        snack.label = 'Error saving advocate personal details.',
          snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.advocateOverviewService.getContactRelationships().subscribe({
          next: (_data) => {
            this.contactRelationship = _data;
          }
        });
        this.advocateOverviewService.getPersonalDetails().subscribe({
          next: (_data) => {
            // On next
            this.details = _data;
            const fullname = this.details.AdvocateFirstName;
            const name = this.details.AdvocateFirstName.trim().split(' ');
            this.details.AdvocateFirstName = name[0];
            this.details.AdvocateLastName = fullname.replace(name[0], '').trim();
            this.workingDetails = this.util.objectCopy(this.details);
          }
        });
        snack.label = 'Advocate personal details saved.';
        snack.type = SnackType.SUCCESS;
        this.navigation.canNavigate = true;
        this._canIgnoreNavigationBlock = true;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    });
  } // end onSaveAdvocateDetails();

  getInvoicedMonthlyDashboard() {
    const snack: Snack = {
      label: 'Loading Dashboard data.',
      action: null
    };

    this.snackbarsService.make(snack, 5000).show();
    this.dashboardService.getInvoicedMonthly().subscribe({
      next:
        (dashboard) => {
          this.invMonthly = dashboard;
          for (const property in this.invMonthly) {
            if (!this.invMonthly[property]) {
              this.invMonthly[property] = '';
            }
          }
          const msg = 'Dashboard data loaded.';
          snack.label = msg;
          this.snackbarsService.dismiss().make(snack).show();
        },
      error: (error) => {
        const msg = 'Error loading dashboard data.';
        snack.label = msg;
        snack.type = SnackType.ERROR;
        // this.snackbarsService.dismiss().make(snack).show();
      },
      complete: () => {
        this.dashboardService.getInvoicedData(this.invMonthly).subscribe(data => {
          console.log('INVOICED DATA: ', data)
          this.invoicedData = data;
        });
      }
    });
  } // end getInvoicedMonthlyDashboard()

  getCollectedMonthlyDashboard() {
    const snack: Snack = {
      label: 'Loading Dashboard data.',
      action: null
    };

    this.snackbarsService.make(snack, 5000).show();
    this.dashboardService.getCollectedMonthly().subscribe({
      next:
        (dashboard) => {
          this.collMonthly = dashboard;
          for (const property in this.collMonthly) {
            if (!this.collMonthly[property]) {
              this.collMonthly[property] = '';
            }
          }
          const msg = 'Dashboard data loaded.';
          snack.label = msg;
          this.snackbarsService.dismiss().make(snack).show();
        },
      error: (error) => {
        const msg = 'Error loading dashboard data.';
        snack.label = msg;
        snack.type = SnackType.ERROR;
        // this.snackbarsService.dismiss().make(snack).show();
      },
      complete: () => {
        this.dashboardService.getCollectedData(this.collMonthly).subscribe(data => {
          this.collectedData = data;
        });
      }
    });
  } // end getCollectedMonthlyDashboard()

  onSaveProfileSettings() {
    this.loadingService.showOverlay();
    this.advocateOverviewService.putProfileSettings(this.workingProfileSettings).subscribe({ next: (data) => {
      // on next
      },
      error: (error) => {
        // on error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.getPersonalDetails();
      }
    })
  } // end onSaveProfileSettings()

} // end AdvocateOverviewWidget{}
