import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdvocateDetailStatementService,
  AdvocateDetailStatement,
  ICrumb} from '../../services/advocate-detail-statement/advocate-detail-statement.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../services/messaging/snackbars/snackbars.service';
import { PATH_ANALYTICS, PATH_ADVOCATE_DETAIL, PATH_ATTORNEY_DASHBOARD } from '../../services/appdata/app-config.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  crumbs: ICrumb[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private advocateDetailService: AdvocateDetailStatementService,
    private loadingService: LoadingService,
    private snackbarService: SnackbarsService
  ) { } // end constructor()

  ngOnInit() {

    this.crumbs = [
      {
        label: 'Analytics'
      }
    ];
  } // end ngOnInit()

  openReport(reportName: string) {
      switch (reportName) {
      case 'advocate-statement':
        this.router.navigate([PATH_ADVOCATE_DETAIL]);
      break;
    }
  }
} // end AnalyticsComponent()
