<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">
      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onDismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <nav class="navbar-tabs">
          <button
            class="tab"
            [class.active]="tabView === 'personal'"
            (click)="changeTabView('personal')">personal</button>
          <button
            class="tab"
            [class.active]="tabView === 'contact'"
            (click)="changeTabView('contact')">contact</button>
          <button
            class="tab"
            *ngIf="!isAssistant"
            [class.active]="tabView === 'billing'"
            (click)="changeTabView('billing')">billing</button>
          <button
            class="tab"
            *ngIf="!isAssistant"
            [class.active]="tabView === 'banking'"
            (click)="changeTabView('banking')">banking</button>
          <button
            class="tab"
            [class.active]="tabView === 'settings'"
            (click)="changeTabView('settings')">settings</button>
        </nav>

        <div *ngIf="tabView === 'personal'">
          <div class="row">
            <!-- First name -->
            <div class="form-group" *ngIf="isAttorney">
              <label for="first-name">Law Firm name<span title="Required field"> *</span></label>
              <input
                type="text"
                class="form-control"
                [class.has-error]="hasFirstName"
                id="first-name"
                [(ngModel)]="workingUserProfile.personalDetails.fullName"
                (click)="$event.target.select()"
                autocomplete="nocxua">
                <span
                *ngIf="hasFirstName"
                class="text-danger">Please enter a valid first name</span>
            </div>
            <!-- First name -->
            <div class="form-group" *ngIf="!isAttorney">
              <label for="first-name">Full name<span title="Required field"> *</span></label>
              <input
                type="text"
                class="form-control"
                [class.has-error]="hasFirstName"
                id="first-name"
                [(ngModel)]="workingUserProfile.personalDetails.fullName"
                (click)="$event.target.select()"
                autocomplete="nocxua">
                <span
                *ngIf="hasFirstName"
                class="text-danger">Please enter a valid full name</span>
            </div>

            <div class="col-md-3" class="form-group" >
              <label>Ethnicity</label>
                <!-- <div class="input-group"> -->
                  <select name="ethnicity" id="ethnicity" class="form-control" [(ngModel)]="workingUserProfile.personalDetails.ethincity">
                      <option value="" selected="selected">Select Ethnic Group</option>
                      <option value="African">African</option>
                      <option value="White">White</option>
                      <option value="Coloured">Coloured</option>
                      <option value="Indian / Asian">Indian / Asian</option>
                  </select>
                <!-- </div> -->
            </div>
            <div class="col-md-3" class="form-group" >
              <label>Gender</label>
                <!-- <div class="input-group"> -->
                  <select name="gender" id="gender" class="form-control" [(ngModel)]="workingUserProfile.personalDetails.gender">
                      <option value="" selected="selected">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                  </select>
                <!-- </div> -->
            </div>

            <!-- Last name -->
            <!-- <div class="form-group" *ngIf="!isAttorney">
              <label for="last-name">Last name<span title="Required field"> *</span></label>
              <input
                type="text"
                class="form-control"
                [class.has-error]="hasLastName"
                id="last-name"
                [(ngModel)]="workingUserProfile.personalDetails.lastName"
                (click)="$event.target.select()"
                autocomplete="nocxua">
                <span
                *ngIf="hasLastName"
                class="text-danger">Please enter a valid last name</span>
            </div>-->
          </div> <!-- #end .row  -->

          <div class="row">

            <!-- ID number -->
            <div class="form-group" *ngIf="!isAttorney">
              <label for="id-number">ID number</label>
              <input
                class="form-control"
                type="text"
                id="id-number"
                [(ngModel)]="workingUserProfile.personalDetails.idNumber"
                (click)="$event.target.select()"
                autocomplete="nocxua">
            </div>
            <div class="form-group" *ngIf="isAttorney">
              <label for="id-number">Registration number</label>
              <input
                class="form-control"
                type="text"
                id="id-number"
                [(ngModel)]="workingUserProfile.personalDetails.idNumber"
                (click)="$event.target.select()"
                autocomplete="nocxua">
            </div>

            <!-- Speciality -->
            <div class="form-group" *ngIf="!isAssistant">
              <label for="speciality">Speciality</label>
              <select
                class="form-control"
                id="speciality"
                [title]="workingUserProfile.personalDetails.speciality"
                [(ngModel)]="workingUserProfile.personalDetails.speciality">
                <option [value]="null">Choose a speciality</option>
                <option
                  *ngFor="let speciality of specialities"
                  [value]="speciality.value">{{speciality.label}}</option>
              </select>
              <!-- <input
                class="form-control"
                type="text"
                id="speciality"
                [(ngModel)]="workingUserProfile.personalDetails.speciality"
                autocomplete="nocxua"> -->
            </div>

            <!-- Seniority -->
            <div class="form-group" *ngIf="!isAssistant && !isAttorney">
              <label for="seniority">Seniority</label>
              <select
                class="form-control"
                id="seniority"
                [(ngModel)]="workingUserProfile.personalDetails.professionalLevel">
                <option [value]="null">Choose a seniority</option>
                <option
                  *ngFor="let lvl of professionalLevels"
                  [value]="lvl.value"
                  [selected]="lvl.value === workingUserProfile.personalDetails.professionalLevel">{{lvl.label}}</option>
              </select>
            </div>

            <!-- Vat number -->
            <div class="form-group" *ngIf="!isAssistant">
              <label for="vat-number">Vat number</label>
              <input
                class="form-control"
                type="text"
                id="vat-number"
                [(ngModel)]="workingUserProfile.personalDetails.vatNumber"
                (click)="$event.target.select()"
                autocomplete="nocxua">
            </div>

          </div><!--#end .row -->

          <div class="row">

            <!-- Effective Tax Rate -->
            <div class="form-group">
              <label for="tax-value">Effective Tax Rate<span class="required-field" title="Required field if registered for VAT"> *</span></label>
              <div class="percent">
              <input style="width: 93% !important;"
                    type="number"
                    id="tax-value"
                    class="input"
                    placeholder="Effective Tax Rate"
                    [(ngModel)]="workingUserProfile.personalDetails.taxValue">
                </div>
            </div>

            <!-- Last VAT period end Date -->
              <div class="form-group" *ngIf="workingUserProfile.personalDetails.vatNumber">
                <label *ngIf="workingUserProfile.personalDetails.vatNumber" for="last-vat-period-end-date">Last VAT period end Date <span class="required-field" title="Required field if registered for VAT"> *</span></label>
                <input *ngIf="workingUserProfile.personalDetails.vatNumber"
                  id="last-vat-period-end-date"
                  type="text"
                  bsDatepicker
                  id="last-vat-period-end-date"
                  class="input"
                  [bsValue]="onLastVATPeriodEndDateParse(workingUserProfile.personalDetails.lastVATPeriodEndDate)"
                  (ngModel)="workingUserProfile.personalDetails.lastVATPeriodEndDate=$event.target.value"
                  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                  (bsValueChange)="onLastVATPeriodEndDateChange($event)"
                  placement="right">
              </div>

            <!-- VAT based on -->
            <div class="form-group" *ngIf="workingUserProfile.personalDetails.vatNumber">
              <label *ngIf="workingUserProfile.personalDetails.vatNumber" for="practice-type">VAT based on:<span class="required-field" title="Required field if registered for VAT"> *</span></label>
              <select *ngIf="workingUserProfile.personalDetails.vatNumber" id="practice-type" [(ngModel)]="workingUserProfile.personalDetails.vatBasedOn">
                <option *ngFor="let vat of vatBasedOn" [value]="vat.id"
                  [selected]="vat.id === workingUserProfile.personalDetails.vatBasedOn">{{vat.name}}</option>
              </select>
            </div>

          </div><!--#end .row -->

          <div class="row">

            <div class="form-group" *ngIf="!isAssistant">
              <label for="vat-number">MAAA Number</label>
              <input
                class="form-control"
                type="text"
                id="vat-number"
                [(ngModel)]="workingUserProfile.settings.maaa"
                (click)="$event.target.select()"
                autocomplete="nocxua">
            </div>

            <div class="form-group">
            </div>

            <div class="form-group">
            </div>

          </div><!--#end .row -->

        </div><!--#end perosnal view -->

        <div *ngIf="tabView === 'contact'">

          <div class="row">

            <!-- Phone number -->
            <div class="form-group">
              <label for="phone-number">Phone number<span title="Required field"> *</span></label>
              <input
              type="text"
                class="form-control"
                [class.has-error]="hasPhoneNumberError"
                id="phone-number"
                placeholder="Phone number e.g (xx) xxx-xxxx"
                (click)="$event.target.select()"
                (keyup)="onPhoneNumberInputChange($event)"
                (focus)="onPhoneNumberInputFocus($event)"
                [(ngModel)]="workingUserProfile.contactDetails.workPhone">
                <span
                *ngIf="hasPhoneNumberError"
                class="text-danger">Please enter a valid phone number</span>
            </div>

            <!-- Fax number -->
            <div class="form-group">
              <label for="fax-number">Fax number</label>
              <input
                type="text"
                class="form-control"
                [class.has-error]="hasFaxNumberError"
                id="fax-number"
                placeholder="Phone number e.g (xx) xxx-xxxx"
                (click)="$event.target.select()"
                (keyup)="onPhoneNumberInputChange($event)"
                (focus)="onPhoneNumberInputFocus($event)"
                [(ngModel)]="workingUserProfile.contactDetails.workFax">
                <span
                *ngIf="hasFaxNumberError"
                class="text-danger">Please enter a valid fax number</span>
            </div>

          </div><!--#end .row -->

          <div class="row">

            <!-- Cell number -->
            <div class="form-group">
              <label for="mobile-number">Cell number<span title="Required field"> *</span></label>
              <input
                class="form-control"
                [class.has-error]="hasCellNumberError"
                type="mobile-number"
                id="mobile-number"
                placeholder="Cell number e.g (xx) xxx-xxxx"
                (click)="$event.target.select()"
                (keyup)="onPhoneNumberInputChange($event)"
                (focus)="onPhoneNumberInputFocus($event)"
                [(ngModel)]="workingUserProfile.contactDetails.mobileNumber"
                autocomplete="nocxua">
              <span
                *ngIf="hasCellNumberError"
                class="text-danger">Please enter a valid cell number</span>
            </div>

            <!-- Email -->
            <div class="form-group">
              <label for="email">Email<span title="Required field"> *</span></label>
              <input
                class="form-control"
                [class.has-error]="hasContactEmailError"
                type="email"
                id="email"
                [(ngModel)]="workingUserProfile.contactDetails.emailAddress"
                (focus)="$event.target.select()">
              <span
              *ngIf="hasContactEmailError"
                class="text-danger">Please enter a valid email address.</span>
            </div>

          </div><!--#end .row-->
          <div class="address-group">
          <div class="row" style="padding-top:0; padding-bottom:0;">

            <!-- Email -->
            <div class="form-group" style="margin-bottom: 0 !important;">
              <label for="address">Address</label>

              <!-- <input
                (input)="onInputChanged(workingUserProfile.addresses && workingUserProfile.addresses.street1)"
                [(ngModel)]="workingUserProfile.addresses && workingUserProfile.addresses.street1"
                [typeahead]="places"
                typeaheadOptionField="description"
                [typeaheadSingleWords]="false"
                style="width: 100% !important;"
                placeholder="Select or start typing"
                (typeaheadOnSelect)="selectPlace($event)"
                [typeaheadOptionsLimit]="100"
                typeaheadScrollable=true
                typeaheadOptionsInScrollableView="20"
                [typeaheadMinLength]="0">-->

              <textarea
                class="form-control-address"
                type="text"
                id="address"
                [(ngModel)]="workingUserProfile.contactDetails.streetAddressFull"
                (click)="$event.target.select()"></textarea>
            </div>
           <!-- <div class="form-group" style="margin-bottom: 0 !important;">
              <label for="mobile-number">Building  name</label>
              <input
                class="form-control"
                type="text"
                id="street-name"
                [(ngModel)]="workingUserProfile.addresses && workingUserProfile.addresses.street2">
            </div>

          </div><#end .row-->
          <!-- <div *ngIf="workingUserProfile.addresses" class="row" style="padding-top:0; padding-bottom:0;"> -->
            <!-- Suburb -->
            <!-- <div class="form-group" style="margin-bottom: 0 !important;">
              <label for="mobile-number">Suburb</label>
              <input
                class="form-control"
                readonly
                type="text"
                id="street-name"
                [(ngModel)]="workingUserProfile.addresses.suburb">
            </div> -->
            <!-- City -->
            <!-- <div class="form-group" style="margin-bottom: 0 !important;">
              <label for="mobile-number">City</label>
              <input
                class="form-control"
                readonly
                type="text"
                id="street-name"
                [(ngModel)]="workingUserProfile.addresses.city">
            </div> -->
            <!-- Province -->
            <!-- <div class="form-group" style="margin-bottom: 0 !important;">
              <label for="mobile-number">Province</label>
              <input
                class="form-control"
                readonly
                type="text"
                id="street-name"
                [(ngModel)]="workingUserProfile.addresses.stateProvince">
            </div> -->
          </div> 
          <!-- #end .row -->
          <!-- <div *ngIf="workingUserProfile.addresses" class="row" style="padding-top:0; padding-bottom:0;"> -->
            <!-- Country -->
            <!-- <div class="form-group" style="margin-bottom: 0 !important;">
              <label for="mobile-number">Country</label>
              <input
                class="form-control"
                readonly
                type="text"
                id="street-name"
                [(ngModel)]="workingUserProfile.addresses.country">
            </div> -->
            <!-- Postal Code -->
            <!-- <div class="form-group" style="margin-bottom: 0 !important;">
              <label for="mobile-number">Postal Code</label>
              <input
                class="form-control"
                readonly
                type="text"
                id="street-name"
                [(ngModel)]="workingUserProfile.addresses.postalCode">
            </div>

          </div>  -->
          <!-- #end .row -->
           </div>

        </div>
        <!--#end contact view -->

        <div *ngIf="tabView === 'billing'">

          <div class="row">

            <!-- Fee target -->
            <div class="form-group">
              <label for="fee-target">Fee target<span title="Required field"> *</span></label>
              <!-- <input
                class="form-control"
                type="text"
                id="fee-target"
                currencyMask
                [options]="{ prefix: 'R '}"
                (click)="onCurrencyInputChange($event)"
                (keyup)="onCurrencyInputChange($event)"
                (focus)="onCurrencyInputFocus($event)"
                [(ngModel)]="workingUserProfile.billingDetails.feeTarget"
                autocomplete="nocxua"> -->
                <input
                required
                type="text"
                class="form-control"
                id="fee-target"
                [ngModel]="workingUserProfile.billingDetails.feeTarget | currency: currencySymbol: currencySymbol:'1.2-2'"
                [ngModelOptions]="{updateOn: 'blur'}"
                (ngModelChange)="workingUserProfile.billingDetails.feeTarget=$event"
                (blur)="onFeeTargetChange()"
                (focus)="onFeeTargetClicked()"
                (focus)="$event.target.select()"
                pattern="[R\P\s]*[0-9,.]+"
                #inputFeeTarget
                autocomplete="nocxua">
                <!-- TODO: Implement currency input mask -->
            </div>

            <!-- Income target -->
            <div class="form-group">
              <label for="income-target">Income target<span title="Required field"> *</span></label>
              <!-- <input
                class="form-control"
                type="text"
                id="income-target"
                currencyMask
                [options]="{ prefix: 'R '}"
                (click)="onCurrencyInputChange($event)"
                (keyup)="onCurrencyInputChange($event)"
                (focus)="onCurrencyInputFocus($event)"
                [(ngModel)]="workingUserProfile.billingDetails.incomeTarget"
                autocomplete="nocxua"> -->
                <input
                required
                type="text"
                class="form-control"
                id="income-target"
                [ngModel]="workingUserProfile.billingDetails.incomeTarget | currency: currencySymbol: currencySymbol:'1.2-2'"
                [ngModelOptions]="{updateOn: 'blur'}"
                (ngModelChange)="workingUserProfile.billingDetails.incomeTarget=$event"
                (blur)="onIncomeTargetChange()"
                (focus)="onIncomeTargetClicked()"
                (focus)="$event.target.select()"
                pattern="[R\P\s]*[0-9,.]+"
                #inputIncomeTarget
                autocomplete="nocxua">
            </div>

          </div><!--#end .row -->

          <div class="row">

            <!-- Default hourly rate -->
            <div class="form-group">
              <label for="default-hourly-rate">Default hourly rate<span title="Required field"> *</span></label>
              <!-- <input
                class="form-control"
                type="text"
                id="default-hourly-rate"
                currencyMask
                [options]="{ prefix: 'R '}"
                (click)="onCurrencyInputChange($event)"
                (keyup)="onCurrencyInputChange($event)"
                (focus)="onCurrencyInputFocus($event)"
                [(ngModel)]="workingUserProfile.billingDetails.defaultHourlyRate"
                autocomplete="nocxua"> -->
                <!-- AUTO DAILY RATE UPDATE -->
                <!-- (ngModelChange)="workingUserProfile.billingDetails.defaultDailyRate=$event * 10"  -->
                <input
                required
                type="text"
                class="form-control"
                id="idefault-hourly-rate"
                [ngModel]="workingUserProfile.billingDetails.defaultHourlyRate | currency: currencySymbol: currencySymbol:'1.2-2'"
                [ngModelOptions]="{updateOn: 'blur'}"
                (ngModelChange)="workingUserProfile.billingDetails.defaultHourlyRate=$event"
                (ngModelChange)="workingUserProfile.billingDetails.defaultDailyRate=$event * 10"
                (blur)="onHourlyRateChange()"
                (focus)="onHourlyRateClicked()"
                (focus)="$event.target.select()"
                pattern="[R\P\s]*[0-9,.]+"
                #inputHourly
                autocomplete="nocxua">
              </div>

            <!-- Default daily rate -->
            <div class="form-group">
              <label for="default-daily-rate">Default daily rate<span title="Required field"> *</span></label>
              <!-- <input
              class="form-control"
              type="text"
              id="default-daily-rate"
              currencyMask
              [options]="{ prefix: 'R '}"
              (click)="onCurrencyInputChange($event)"
              (keyup)="onCurrencyInputChange($event)"
              (focus)="onCurrencyInputFocus($event)"
              [(ngModel)]="workingUserProfile.billingDetails.defaultDailyRate"
              autocomplete="nocxua"> -->
              <input
              required
              type="text"
              class="form-control"
              id="default-daily-rate"
              [ngModel]="workingUserProfile.billingDetails.defaultDailyRate | currency: currencySymbol: currencySymbol:'1.2-2'"
              [ngModelOptions]="{updateOn: 'blur'}"
              (ngModelChange)="workingUserProfile.billingDetails.defaultDailyRate=$event"
              (blur)="onDailyRateChange()"
              (focus)="onDailyRateClicked()"
              (focus)="$event.target.select()"
              pattern="[R\P\s]*[0-9,.]+"
              #inputDaily
              autocomplete="nocxua">
            </div>

            <!-- Default kilometer rate -->
            <div class="form-group">
              <label for="default-kilometer-rate">Default kilometer rate</label>
              <!-- <input
              class="form-control"
              type="text"
              id="default-kilometer-rate"
              currencyMask
              [options]="{ prefix: 'R '}"
              (click)="onCurrencyInputChange($event)"
              (keyup)="onCurrencyInputChange($event)"
              (focus)="onCurrencyInputFocus($event)"
              [(ngModel)]="workingUserProfile.billingDetails.defaultPerKilometerRate"
              autocomplete="nocxua"> -->
              <input
              type="text"
              class="form-control"
              id="default-kilometer-rate"
              [ngModelOptions]="{updateOn: 'blur'}"
              [ngModel]="workingUserProfile.billingDetails.defaultPerKilometerRate | currency: currencySymbol: currencySymbol:'1.2-2'"
              (ngModelChange)="workingUserProfile.billingDetails.defaultPerKilometerRate=$event"
              (blur)="onPerkilometerRateChange()"
              (focus)="onPerkilometerRateClicked()"
              (focus)="$event.target.select()"
              pattern="[R\P\s]*[0-9,.]+"
              #inputPerKilometer
              autocomplete="nocxua">
            </div>

            <!-- Starting invoice number -->
            <div class="form-group">
              <label for="starting-invoice-number">Next invoice no.<span class="required-field" title="Required field if registered for VAT"> *</span></label>
              <input
                class="form-control"
                type="text"
                id="starting-invoice-number"
                [(ngModel)]="workingUserProfile.billingDetails.startingInvoiceNumber"
                autocomplete="nocxua"
                readonly>
            </div>

          </div><!--#end .row -->
          <div class="row">

            <div class="form-group">
              <button
                class="btn btn-info"
                (click)="FeeTariffsView()">Average Fee Tariffs</button>
              </div>

            <div class="form-group">
            </div>

            <div class="form-group">
            </div>

            <div class="form-group">
            </div>

          </div>

        </div><!--#end billing view -->

        <div *ngIf="tabView === 'banking'">

          <div class="row">

            <!-- Bank account no. -->
            <div class="form-group">
              <label for="bank-account-no">Bank account no.<span title="Required field"> *</span></label>
              <!-- <input
                *ngIf="workingUserProfile.bankDetails.accountNumber"
                readonly
                class="form-control"
                type="text"
                id="bank-account-no"
                [(ngModel)]="workingUserProfile.bankDetails.accountNumber"
                (click)="$event.target.select()"
                autocomplete="nocxua"> -->

              <input
                required
                class="form-control"
                type="text"
                id="bank-account-no"
                [(ngModel)]="workingUserProfile.bankDetails.accountNumber"
                (click)="$event.target.select()"
                autocomplete="nocxua">
            </div>

            <!-- Bank account holder -->
            <div class="form-group">
              <label for="bank-account-holder">Bank account holder<span title="Required field"> *</span></label>
              <input
                required
                class="form-control"
                type="text"
                id="bank-account-holder"
                [(ngModel)]="workingUserProfile.bankDetails.accountHolder"
                (click)="$event.target.select()"
                autocomplete="nocxua">
            </div>

            <!-- Branch code -->
            <div class="form-group">
              <label for="branch-code">Branch code<span title="Required field"> *</span></label>
              <input
                required
                class="form-control"
                type="number"
                id="branch-code"
                [(ngModel)]="workingUserProfile.bankDetails.branchCode"
                (click)="$event.target.select()">
            </div>

          </div><!--#end .row -->

          <div class="row">

            <!-- Bank name -->
            <div class="form-group">
              <label for="bank-name">Bank name<span title="Required field"> *</span></label>
              <select
                class="form-control"
                id="bank-name"
                [(ngModel)]="workingUserProfile.bankDetails.bankID">
                <option [value]="null">Choose a bank</option>
                <option
                  *ngFor="let bank of banks"
                  [value]="bank.id"
                  [selected]="bank.id === workingUserProfile.bankDetails.bankID">
                  {{bank.name}}</option>
              </select>
            </div>

            <!-- Account type -->
            <div class="form-group">
              <label for="account-type">Account type<span title="Required field"> *</span></label>
              <select
                class="form-control"
                id="account-type"
                [(ngModel)]="workingUserProfile.bankDetails.accountType">
                <option [value]="null">Choose an account type</option>
                <option
                  *ngFor="let type of accountTypes"
                  [value]="type.value"
                  [selected]="type.value === workingUserProfile.bankDetails.accountType">
                  {{type.label}}</option>
              </select>
            </div>

            <div class="form-group">
              <label></label>
              <button
                class="btn btn-primary"
                (click)="updateBankAccounts()">Update Bank Accounts</button>
            </div>

          </div><!--#end .row -->

        </div><!--#end banking view -->

        <div *ngIf="tabView === 'settings'">

          <div class="row">

            <!-- Hide/Show Dashboard -->
            <div class="form-group">
                <label for="view-dashboard">Hide/Show Dashboard</label>
                <div class="view-dashboard">
                  <i
                    class="fa fa-toggle-on active"
                    id="view-dashboard"
                    *ngIf="dashboardstatus == true"
                    (click)="changeStatus();"></i>
                  <i
                    class="fa fa-toggle-on fa-rotate-180 inactive"
                    id="view-dashboard"
                    *ngIf="dashboardstatus == false"
                    (click)="changeStatus();"></i>
                </div>
            </div>

            <!-- Aging -->
            <div class="form-group">
                <label for="aging-name">Aging<span title="Required field"> *</span></label>
                <select
                  style="height: 30px !important"
                  class="form-control"
                  id="aging-name"
                  [(ngModel)]="workingUserProfile.settings.aging">
                  <option hidden [value]="null">Choose aging</option>
                  <option *ngFor="let age of aging" [value]="age.id" [selected]="age.id === workingUserProfile.settings.aging">
                    {{age.name}}</option>
                </select>
            </div>

            <!-- Signed Date -->
            <div class="form-group">
              <label for="signed-date">Signed Date<span title="Required field"> *</span></label>
              <input
                readonly
                name="signed-date"
                id="signed-date"
                type="text"
                bsDatepicker
                [bsValue]="parseSignedDate(workingUserProfile.settings.signedDate)"
                (ngModel)="workingUserProfile.settings.signedDate=$event.taget.value"
                (bsValueChange)="onSignedDateChange($event)"
                [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                placement="left">
            </div> <!-- #end .form-group -->

          </div><!--#end .row -->

          <div class="row">
            <!-- Hide/Show ARFRA -->
            <div class="form-group">
              <label for="view-dashboard">ARFRA Member</label>
              <div class="view-dashboard">
                <i class="fa fa-toggle-on active" id="view-dashboard" *ngIf="isArfraMember == true" (click)="arfraMember();"></i>
                <i class="fa fa-toggle-on fa-rotate-180 inactive" id="view-dashboard" *ngIf="isArfraMember == false"
                  (click)="arfraMember();"></i>
              </div>


            </div>
            <!-- Future use -->
            <div class="form-group">
              <label for="view-dashboard">CC email address</label>
              <div class="input-group" style="margin-left: 10px;">
              <app-checkbox
              [(checked)]="workingUserProfile.settings.bcc"
              (click)="toggleSelectAll()"></app-checkbox>
              </div>
            </div>

            <div class="form-group">
              <label for="view-dashboard" *ngIf="workingUserProfile.settings.bcc">Email address</label>
              <input
                *ngIf="workingUserProfile.settings.bcc"
                type="text"
                class="form-control"
                [(ngModel)]="workingUserProfile.settings.bccEmail">
            </div>

            <div class="form-group">
              <label for="view-dashboard">Manual Transactions</label>
              <div class="view-dashboard">
                <i
                  class="fa fa-toggle-on active"
                  id="view-dashboard"
                  *ngIf="(workingUserProfile.settings.manualTransaction ?? false) === true"
                  (click)="changeManualTransactionsStatus();"></i>
                <i
                  class="fa fa-toggle-on fa-rotate-180 inactive"
                  id="view-dashboard"
                  *ngIf="(workingUserProfile.settings.manualTransaction ?? false) === false"
                  (click)="changeManualTransactionsStatus();"></i>
              </div>            
          </div>
            <!-- Future use -->
            <!-- <div class="form-group">
              <label for="admission-date">Admission date</label>
              <input
                type="text"
                bsDatepicker
                class="input"
                id="admission-date"
                placeholder="Admission date"
                [bsValue]="parseAdmissionDate(workingUserProfile.contactsExtension.admissionDate)"
                (ngModel)="workingUserProfile.contactsExtension.admissionDate=$event.taget.value"
                [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                (bsValueChange)="onDateValueChange($event)"
                placement="right">
            </div> -->
          </div><!--#end .row -->

            <div class="form-group row">
              <!-- admission Day -->
              <div class="form-group">
                  <label>Admission Day</label>
                  <div class="input-group">
                      <select name="admissionDay" id="admissionDay" class="input-date"
                          [(ngModel)]="checkAdmissionDay" (change)="onChangeAdmissionDay()">
                          <option value="0" selected="selected">Select Day</option>
                          <option *ngFor="let day of admissionDay" value="{{day}}">{{day}}</option>
                      </select>
                  </div>
              </div>

              <!-- admission Month-->
              <div class="form-group">
                  <label>Admission Month</label>
                  <div class="input-group">
                      <select name="fromMonth" id="fromMonth" class="input-date"
                          [(ngModel)]="checkAdmissionMonth" (change)="onChangeAdmissionMonth()">
                          <option value="0" selected="selected">Select Month</option>
                          <option value="1">January</option>
                          <option value="2">February</option>
                          <option value="3">March</option>
                          <option value="4">April</option>
                          <option value="5">May</option>
                          <option value="6">June</option>
                          <option value="7">July</option>
                          <option value="8">August</option>
                          <option value="9">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                      </select>
                      <span *ngIf="isValidAdmissionMonth" class="text-danger">Select month</span>
                  </div>
              </div>

              <!-- admission Year -->
              <div class="form-group">
                  <label>Admission Year</label>
                  <div class="input-group">
                      <select name="admissionYear" id="admissionYear" class="input-date"
                          [(ngModel)]="checkAdmissionYear" (change)="onChangeAdmissionYear()">
                          <option value="0" selected="selected">Select Year</option>
                          <option *ngFor="let year of admissionYear" value="{{year.year}}">{{year.year}}</option>
                      </select>
                      <span *ngIf="isValidAdmissionYear" class="text-danger">Select year</span>
                  </div>
              </div>
          </div>

        </div><!--#end settings view -->

      </div><!--#end .modal-body -->
      <div class="modal-footer">

        <button
          class="btn btn-success btn-end"
          [class.disabled]="!isSaveable"
          [disabled]="!isSaveable"
          (click)="save()">Save profile</button>
      </div><!--#end .modal-footer -->
    </div><!--#end .modal-dialog -->
  </div><!--#end .modal-content -->
</div><!--#end .modal -->

<app-prompt-unsaved-changes-modal
  [isVisible]="showUnsavedChangesPrompt"
  (cancel)="onHideUnsavedChangesPrompt()"
  (continue)="dismiss()"
  (confirm)="save()"></app-prompt-unsaved-changes-modal>

<app-fee-tariffs
  *ngIf="showFeeTariffsModal"
  [isVisible]="showFeeTariffsModal"
  [feeTariffs]="feeTariffs"
  (cancel)="onFeeTariffsModalHide()"
></app-fee-tariffs>
