import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TAG_IMAGE_VIEWER_MODAL } from '../../../services/tags/tags.service';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  images: any;
  @Input() isVisible: boolean;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.images = '../../../../assets/images/samples/actions.png';
   } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  dismiss() {
    this.closeModal.emit(TAG_IMAGE_VIEWER_MODAL);
  } // end dismiss()

} // end ImageViewerComponent{}
