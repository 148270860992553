<div class="container-fluid" style="margin-top:0;background-color: #f5f6f6;">
    <div class="row justify-content-center">
        <div class="col-lg-10">
            <nav class="navbar">
                <a class="navbar-brand">
                    <img src="{{images.logo.path}}" alt="Auxcon-logo">
                </a>
            </nav>
        </div>
    </div>
</div>