<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">
        <!-- Modal tabs -->
        <!-- <div class="row row-nav">
          <nav class="navbar-tabs">
            <a class="tab" [class.active]="activeTab === 'invoice'" (click)="setActiveTab('invoice')">INVOICE</a>
            <a *ngIf="feesQuoted && feesQuoted.length > 0 && isAdvocateProfile" class="tab"
              [class.active]="activeTab === 'actual-fees'" (click)="setActiveTab('actual-fees')">ACTUAL FEES</a>
            <a class="tab" [class.active]="activeTab === 'conversation'"
              (click)="setActiveTab('conversation')">CONVERSATION</a>
          </nav>
        </div> -->

        <div class="container-fluid">
          <div class="frame">
            <div class="tile" style="margin-top: 43px;">
              <!-- Invoice details -->

              <div class="well">
                <h2 class="well-title">Quotation details</h2>
                <div class="well-row">
                  <span class="well-row-cell">Quotation amount</span>
                  <span>{{quotationDetails.QuotationTotal | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row">
                  <span class="well-row-cell">Quotation date</span>
                  <span>{{quotationDetails.QuotationDate | date:'yyyy-MM-dd'}}</span>
                </div> <!-- #end .well-row -->
                <div class="well-row">
                  <span class="well-row-cell">Payment term</span>
                  <span>{{quotationDetails.Terms}}</span>
                </div> <!-- #end .well-row -->
                <!-- <div class="well-row">
                  <span class="well-row-cell">Total Payment amount</span>
                  <span>{{invoiceDetails.TotalPaidAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
                </div> -->
                <!-- <div class="well-row" *ngIf="invoiceDetails.TotalCreditAmount !== 0">
                  <span class="well-row-cell"><u>Total Credits</u></span>
                  <span><u>{{invoiceDetails.TotalCreditAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></span>
                </div> -->
                <!-- <div class="well-row" *ngIf="invoiceDetails.TotalWriteOffAmount !== 0">
                  <span class="well-row-cell"><u>Written Off</u></span>
                  <span><u>{{invoiceDetails.TotalWriteOffAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></span>
                </div> -->
                <!-- <div class="well-row" [ngStyle]="{'color' : invoiceDetails.OutstandingBalance !== 0 ? '#ff0000':''}">
                  <span class="well-row-cell"><u>Amount outstanding</u></span>
                  <span>{{invoiceDetails.OutstandingBalance | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
                </div> -->
              </div> <!-- #end .well -->

              <!-- <div class="well mb-10" *ngIf="invoiceDetails.TotalCreditAmount !== 0">
                <div class="well-row">
                  <button class="btn btn-success" (click)="openModel('send-credit')">ReSend Credit Note</button>
                </div>
              </div> -->
              <!-- Matter details -->
              <div class="well">
                <h2 class="well-title">Matter details</h2>

                <div class="well-row">
                  <span class="well-row-cell">Client name</span>
                  <span>{{quotationDetails.ClientName}}</span>
                </div> <!-- #end .well-row -->

                <div class="well-row">
                  <span class="well-row-cell">Reference</span>
                  <span>{{quotationDetails.InvoiceReference}}</span>
                </div> <!-- #end .well-row -->

                <div class="well-row">
                  <span class="well-row-cell">Plaintiff</span>
                  <span>{{plaintiff}}</span>
                </div> <!-- #end .well-row -->

                <div class="well-row">
                  <span class="well-row-cell">Defendant</span>
                  <span>{{defendant}}</span>
                </div> <!-- #end .well-row -->

              </div> <!-- #end .well -->


            </div> <!-- #end .tile -->

            <div class="tile" style="margin: 43px 0 0 12px;">

              <!-- <div class="well">
                <div class="row p-0 m-0" style="justify-content: flex-end;">

                  <ul>
                    <li><a (click)="openModel('add-credit')"
                        [ngStyle]="{'cursor' : invoiceDetails.TotalCreditAmount !== 0 ? 'no-drop':'pointer'}"><i
                          class="fal fa-backspace pr-2"></i>Add credit note</a>
                    </li>
                    <li><a (click)="openModel('write-off')"
                        [ngStyle]="{'cursor' : invoiceDetails.OutstandingBalance > 0 ? 'pointer':'no-drop'}"><i
                          class="fal fa-dollar-sign pr-2"></i>Write-off balance
                        due</a></li>
                  </ul>

                </div>
              </div> -->
              <!-- Invoice PDF -->
              <div style="width: 100%; height:700px;">
                <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false" [openFile]="true" [viewBookmark]="true"
                  [download]="true" [downloadFileName]="fileName"></ng2-pdfjs-viewer>
              </div>
            </div> <!-- #end .tile -->

          </div> <!-- #end .frame -->
        </div> <!-- #end .container-fluid -->
      </div> <!-- #end .modal-body .form -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->
