import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { StatementByAdvocate } from '../../../services/live-statement/live-statement.service';
import { TAG_ADVOCATE_STATEMENT_MODAL } from '../../../services/tags/tags.service';

@Component({
  selector: 'app-advocate-statement-modal',
  templateUrl: './advocate-statement-modal.component.html',
  styleUrls: ['./advocate-statement-modal.component.scss']
})
export class AdvocateStatementModalComponent implements OnInit, OnChanges {

  selectedLawfirmId: number;
  selectedAdvocateId: number;
  selectedFileName: string;

  @Input() advocateDetail: StatementByAdvocate;
  @Input() isVisible: boolean;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  constructor() { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.advocateDetail && changes.advocateDetail.currentValue) {
      this.selectedAdvocateId = this.advocateDetail.ServiceProviderId;
      this.selectedLawfirmId = this.advocateDetail.ClientId;
      this.selectedFileName = this.advocateDetail.ProviderName;
    }
  } // end ngOnChanges()

  dismiss() {
    this.closeModal.emit(TAG_ADVOCATE_STATEMENT_MODAL);
  }

} // end AdvocateStatementModalComponent{}
