import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges, OnChanges } from '@angular/core';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import * as moment from 'moment';

const TAG_WRITE_OFF_UNDERPAYMENT_ON_INVOICES = 'Write-off balance due on invoices';

export interface WriteOffLine {
  Notes: string;
  Date: Date;
}

@Component({
  selector: 'app-write-off-under-payment-model',
  templateUrl: './write-off-under-payment-model.component.html',
  styleUrls: ['./write-off-under-payment-model.component.scss']
})
export class WriteOffUnderPaymentModelComponent implements OnInit, OnChanges {

  title: string = TAG_WRITE_OFF_UNDERPAYMENT_ON_INVOICES;
  date = new Date();
  writeOff: WriteOffLine = {
    Notes: '',
    Date: this.date,
  };
  totalAmount = 0;
  invoices: any = [];
  @Input() addedWriteOffUnderpayment: any;
  @Output() refreshPage = new EventEmitter<any>();
  showErrorMessagePrompt = false;

  constructor(
    private userProfileService: UserProfileService,
    private cpipe: UsercurrencyPipe,
    private util: UtilitiesService
  ) {
  }
  ngOnInit() {
    // this.writeOff.Date = new Date();
  }

  ngOnChanges(changes: SimpleChanges) {

    this.invoices = [];
    if (changes.addedWriteOffUnderpayment && changes.addedWriteOffUnderpayment.currentValue) {
      let total = 0;
      this.addedWriteOffUnderpayment.forEach(inv => {
        inv.data['WriteOff'] = inv.data.Amount - inv.data.Paid;
        total += Number(inv.data['WriteOff']);
        this.invoices.push(inv);
      });
      //  this.invoices = this.addedWriteOffUnderpayment;
      this.totalAmount = +parseFloat(total.toString()).toFixed(2);
    }
  }

  dismiss(value: string) {
    this.refreshPage.emit(value);
  }

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  parseDate(date: string) {
    return this.util.parseDate(date);
  }

  onDateChange(value: Date): void {
    this.writeOff.Date = value;
  }

  onAmountChange(line: any, event) {

    // 
    if (this.userProfileService.userProfile.isImpersonator) {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
      event.target.value = this.cpipe.transform(event.target.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }


    if (line.WriteOff > (line.Amount - line.Paid)) {
      this.showErrorMessagePrompt = true;
    }

    this.onTotalsCalculateChange('changed');
  }

  onHideErrorMessagePrompt(line: any, event) {
    this.invoices.forEach(inv => {
      if (inv.data.InvoiceId === line.InvoiceId) {
        inv.data.WriteOff = (line.Amount - line.Paid);
      }
    });
    this.showErrorMessagePrompt = false;
  }

  onAmountClicked(line: any, event) {

    if (this.userProfileService.userProfile.isImpersonator) {
      event.target.value = this.cpipe.parse(event.target.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      event.target.value = this.cpipe.parse(event.target.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  }

  onTotalsCalculateChange(event) {
    let total = 0;
    this.invoices.forEach(inv => {
      total += Number(inv.data['WriteOff']);
    });
    this.totalAmount = +parseFloat(total.toString()).toFixed(2);
    this.invoices = this.invoices;
  }


  onWriteOffAmount() {
    // 
    var writeOffInvoices: any = [];
    this.invoices.forEach(inv => {
      writeOffInvoices.push({
        Amount: inv.data['WriteOff'],
        InvoiceId: inv.data.InvoiceId
      });
    });

    var writeOff = {
      date: moment(this.writeOff.Date).format('YYYY-MM-DDTHH:mm:ss.SSS'),
      note: this.writeOff.Notes,
      invoices: writeOffInvoices
    };

    localStorage.setItem('writeOff', JSON.stringify(writeOff));
    this.refreshPage.emit('Write-off');
  }

  isNotes = true;
  onWriteOffNotesBlur() {
    if (!this.writeOff.Notes) {
      this.isNotes = false;
    } else {
      this.isNotes = true;
    }
  }

  isDate: boolean = true;
  onWriteOffDateBlur() {
    if (!this.writeOff.Date) {
      this.isDate = false;
    } else {
      this.isDate = true;
    }
  }
}
