import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapsService {
    private googleMapsApiUrl = 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/autocomplete/json';
    private apiKey = 'AIzaSyDMqntZvgi2rYQJ6ywm5CFDbJ7GzlEjRek'; // Replace with your actual API key
    private googleMapsApiUrlDetails = 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json';
    //private apiKeyDetailes = 'AIzaSyDMqntZvgi2rYQJ6ywm5CFDbJ7GzlEjRek'; // Replace with your actual API key
    private headers: HttpHeaders;
    
    constructor(private http: HttpClient) {
      this.headers = new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
        // Add more headers as needed
      });
    }
  
    getPlacePredictions(inputValue: string): Observable<any> {
      // Set up the parameters for the API request
      const params = new HttpParams()
        .set('input', inputValue)
        .set('types', 'geocode')
        .set('components', 'country:ZA')
        .set('key', this.apiKey);
  
      // Make the API request and return an observable
      return this.http.get(this.googleMapsApiUrl, { params, headers: this.headers });
    } // end getPlacePredictions()

    getPlaceDetailsPredictions(place_Id: string): Observable<any> {
      // Set up the parameters for the API request
      const params = new HttpParams()
        .set('place_id', place_Id)
        .set('key', this.apiKey);
  
      // Make the API request and return an observable
      return this.http.get(this.googleMapsApiUrlDetails, { params, headers: this.headers });
    } // end getPlaceDetailsPredictions()
  }
