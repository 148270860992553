<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div class="container-fluid">
  <nav class="report-nav">
    <a class="tab" [class.active]="reportType === 'myAdvocates'" (click)="togglePanel('myAdvocates')">My Advocates</a>
    <!-- <a class="tab" [class.active]="reportType === 'businessReports'" (click)="togglePanel('businessReports')">Business Reports</a> -->
    <!-- <a class="tab" [class.active]="reportType === 'financialReports'" (click)="togglePanel('financialReports')">Financial Reports</a> -->
    <a class="tab" [class.active]="reportType === 'industryReport'" (click)="togglePanel('industryReport')">Industry Reports</a>
  </nav> <!-- #end .report-nav -->

  <div class="row reports-options" *ngIf="reportType === 'myAdvocates'">
    <div class="column-report-name">
      <label for="report-type" class="report-name">Report:</label>
      <select class="w-75" (change)="viewReport()" [(ngModel)]="reportName">
        <option *ngIf="userProfileService.userProfile.serviceProviderID !== 29723" value="advocateDetail">Advocate Detail</option>
        <option *ngIf="userProfileService.userProfile.serviceProviderID !== 29723" value="ageAnalysis">Age Analysis</option>
        <option *ngIf="userProfileService.userProfile.serviceProviderID !== 29723" value="advocateSummary">Advocate Summary</option>
        <option *ngIf="userProfileService.userProfile.serviceProviderID !== 29723" value="practiceAllCustomer">All Customer Report</option>
        <option value="practiceByLawfirm">Overdue invoices per law firm</option>
      </select>
    </div> <!-- #end .column-report-name -->
  </div> <!-- #end .row .reports-options -->

  <!-- <div class="flex-container" > -->
    <app-practice-over-summary
      *ngIf="_reportName === 'advocateSummary' && adovatePracticeSummary.length > 0"
      (widgetClick)="onReportClicked($event)"
      [adovatePracticeSummary]="adovatePracticeSummary"></app-practice-over-summary>
  <!-- </div> #end .flex-container -->
  <!-- <div style="width:100%;" > -->
    <app-practice-advocate-health-table
    *ngIf="_reportName === 'advocateDetail' && advocateHealthHandle.length > 0"
    [advocateHealthHandle]="advocateHealthHandle"></app-practice-advocate-health-table>
  <!-- </div>  -->
  <!-- #end .flex-container -->

  <!-- #start Age Analysis Report -->
  <app-practice-age-analysis
  *ngIf="_reportName === 'ageAnalysis'"
  (selectedCustomers)="onAllCustomerReport($event)"
  [practiceAgeAnalysis]="practiceAgeAnalysis"></app-practice-age-analysis>

  <!-- #start Practice by lawfirm -->
  <div style="width: 100%; margin-top: 3px;" *ngIf="_reportName === 'practiceByLawfirm'">
    <app-practice-by-lawfirm
    [debtorTotalOutstandingHandle]="debtorTotalOutstandingHandle"></app-practice-by-lawfirm>
  </div>

  <!-- Industry Reports -->
  <div style="width:100%; margin-top:3px; flex: none;"
    *ngIf="_reportName === 'avarageDaysToPayReport'">
    <app-industry-reports></app-industry-reports>
  </div>
</div> <!-- #end container-fluid -->
