<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-header">
                <h2>{{title}}</h2>
                <button class="modal-dismiss" (click)="onCancel()">
                    <i class="fa fa-close"></i>
                </button>
            </div>

            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="form-group">
                            <label for="law-firm">Law firms / Advocates <span title="Required field"> *</span></label>
                            <app-datalist-lawfirm
                                type="law-firms"
                                iconClass="fa fa-black-tie fa-lg"
                                [list]="lawFirms"
                                [(matter)]="workingMatter"
                                (select)="onModelChange('law-firms', $event)"></app-datalist-lawfirm>
                        </div>

                        <div class="form-group">
                            <label for="law-firm">Attorneys <span title="Required field"> *</span></label>
                            <app-datalist-lawfirm
                                type="attorneys"
                                iconClass="fa fa-black-tie fa-lg"
                                [list]="attorneys"
                                [matter]="workingMatter"
                                (select)="onModelChange('attorneys', $event)"></app-datalist-lawfirm>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group">
                            <label for="attorneyReference">Attorney reference <span title="Required field"> *</span></label>
                            <input
                              required
                              class="form-control" 
                              type="text"
                              placeholder="Attorney reference"
                              [(ngModel)]="workingMatter.ClientRefNo"
                              (input)="onModelChange('attorney-reference', {FullName: $event.target.value})"
                              (blur)="onReferenceChangeCheck()"
                              (click)="$event.target.select()">
                          </div>
                          <div class="form-group">
                            <label for="attorneyReference">Percentage due by firm <span title="Required field"> *</span>
                                    <i class="far fa-info-circle tooltip">
                                        <div class="bottom" style="text-align: center">
                                        <span class="fa fa-info-circle" style="font-size: 20px; padding-top: 9px; padding-bottom: 5px;"></span>
                                        <h4><strong>Percentage</strong></h4>
                                        <p>Use this field to specify the percentage of the total arbitration fees that the selected firm is responsible for. For example, if the firm is to cover 60% of the fees, enter "60". This percentage will be used to accurately calculate the firm's portion of the total costs.</p>
                                        <i></i>
                                        </div> <!-- #end .bottom -->
                                    </i> <!-- #end .far .fa-info-circle .tooltip -->
                                
                                
                                <!-- <i class="far fa-info-circle tooltipp auxcon-green">
                                    <div class="bottom" style="text-align: center; min-width: 332px;">
                                      <p>Use this field to specify the percentage of the total arbitration fees that the selected firm is responsible for. For example, if the firm is to cover 60% of the fees, enter "60". This percentage will be used to accurately calculate the firm's portion of the total costs.</p>
                                      <i></i>
                                     </div>
                                  </i> -->
                                </label>
                            <input
                              required
                              class="form-control" 
                              type="text"
                              placeholder="Attorney reference"
                              [(ngModel)]="workingMatter.ArbitrationPercentage"
                              (click)="$event.target.select()">
                          </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn btn-danger"
                        (click)="onCancel()">Cancel</button>
                <button class="btn btn-success"
                        (click)="onContinue()">Proceed</button>
            </div>

        </div>
    </div>
</div>

<app-bar-payment-ratings
  *ngIf="onShowFirmPaymentProfile"
    [isVisible]="onShowFirmPaymentProfile"
    [lawfirmRatingsData]="lawfirmRatingsData"
    (cancel)="onHideFirmPaymentProfile()"
    (confirm)="onHideFirmPaymentProfile()"></app-bar-payment-ratings>