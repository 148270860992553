import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import {
    ProcessPaymentService,
    TransactionLinesAdded,
    DEFAULT_TRANSACTIONS_LINE_ADDED,
    ProcessedTransactionLine,
    DEFAULT_PROCESSED_TRANSACTION_LINE,
    BankTransaction,
    DEFAULT_BANK_TRANSACTION,
    DEFAULT_SEND_STATEMENT,
    SendStatement,
    DEFAULT_WRITEOFF_TRANSACTIONS_LINE,
    WriteOffTransactionsLines
} from '../../../services/process-payment/process-payment.service';
import * as moment from 'moment';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
// import { TypeaheadMatch } from 'ngx-bootstrap';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UsercurrencyPipe } from '../../../pipe/usercurrency.pipe';
import { UsercurrencyDirective } from '../../../directive/usercurrency.directive';
import { UtilitiesService } from '../../../services/utilities.service';
import { DeleteFeeModalComponent } from '../../modals/delete-fee-modal/delete-fee-modal/delete-fee-modal.component';
import {
    TransactionLinesHandle,
    AvailableInvoicesBankTransactionComponent
} from '../available-invoices-bank-transaction/available-invoices-bank-transaction.component';
import { ContactsService } from '../../../services/contacts/contacts.service';
import { PATH_SEND_STATEMENT } from '../../../services/appdata/app-config.service';
import { Router } from '@angular/router';
import { OtherClientsService } from '../../../services/other-clients/other-clients.service';
import {
    DEFAULT_ACTIVITY_LOGS,
    ActivityLogs,
    ActivityLogsService
} from '../../../services/activity-logs/activity-logs.service';
import { } from 'q';
import {
    NotesObject,
    NotesObjectHandle,
    NotesService,
} from '../../../services/notes/notes.service';
import {
    InvoiceDetails,
    InvoiceDetailsService,
    DEFAULT_INVOICE_DETAILS,
} from '../../../../app/services/invoice-details/invoice-details.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { IndexedDBService } from '../../../services/indexed-db/indexed-db.service';
declare var jquery: JQuery;

export interface AddedInvoiceToTransaction {
    data: TransactionLinesAdded;
    state: {
        isVisible: boolean;
        isSelected: boolean;
        isOpen: boolean;
    };
    writeOff: {
        isWriteOff: boolean;
        Date: string;
        Amount: number;
        Description: string;
        InvoiceType: string;
    };
}

export const LIST_NAME_LAW_FIRMS = 'lawFirms';

@Component({
    selector: 'app-invoices-added-bank-transaction',
    templateUrl: './invoices-added-bank-transaction.component.html',
    styleUrls: ['./invoices-added-bank-transaction.component.scss']
})
export class InvoicesAddedBankTransactionComponent implements OnInit, OnChanges {


    totalAmount = 0;
    selectAll: boolean;
    promptInvoices = false;
    promptPaidInvoices = false;
    showActions = false;
    showRemoveInvoices = false;
    addedInvoiceToTransaction: AddedInvoiceToTransaction[] = [];
    lastStatementDate: SendStatement;
    count = 0;

    invoices: any = [];
    _invoices: any = [];
    _selectedInvoice: TransactionLinesAdded = Object.assign({}, DEFAULT_TRANSACTIONS_LINE_ADDED);
    toProcessTransaction: ProcessedTransactionLine = Object.assign({}, DEFAULT_PROCESSED_TRANSACTION_LINE);
    _toProcessTransactionLines: ProcessedTransactionLine[] = [];
    invoicesToDelete: TransactionLinesAdded[] = [];
    checkBoxSelected: any = [];
    bankTransaction: any = [];
    toWriteOffTransactionLine: WriteOffTransactionsLines = Object.assign({}, DEFAULT_WRITEOFF_TRANSACTIONS_LINE);
    _toWriteOffTransactionLine: WriteOffTransactionsLines[] = [];

    allocateAmount = 0;
    totalAmountLeft = 0;
    allocateButton = false;
    allocateRow = false;
    allocateInvoice = '';
    allocateID: number;


    invoicesReference: string;
    invoicesDate: Date;

    saveButton = false;

    lawFirmName: string;
    lawFirmId: number;
    AllSelectedFirm: any = [];
    SelectedFirm: any = [];
    lawFirms: any = [];
    allLawFirms: any = [];
    lawfirmsList: any = [];
    otherClientList: any = [];
    availableInvoiceHandle: TransactionLinesHandle[] = [];
    invoicesToBeAdded: TransactionLinesAdded[] = [];

    showErrorMessagePrompt: boolean;

    showProformaInvoice: boolean;
    selectedInvoiceId = 0;
    showSendStatementPrompt: boolean;
    isAllocatedToAdvocate = false;

    // Activity logs
    activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);
    showWriteOffUnderPayment: boolean;
    writeOffInvoices: any = [];

    // Invoice Note
    selectedTab: string;
    // notes
    notes: NotesObject[] = [];
    notesHandle: NotesObjectHandle[] = [];
    showInvoiceNoteDetail: boolean;
    objectType: string;
    onShowNoteDetail: boolean;
    noteDetailData: NotesObject;
    invoiceDetails: InvoiceDetails;
    invoicePlaintiff: string;
    invoiceDefendant: string;

    onShowUnAllocatedCategory: boolean;
    Category = '';

    profileTypes = {
        ADVOCATE: 'Advocate',
        ASSISTANT: 'Assistant',
        ATTORNEY: 'Attorney',
        PRACTICE_MANAGER: 'Practice Manager',
        EXTERNAL_ATTORNEY: 'Attorney (External)',
        LAWFIRM: 'Lawfirm',
        EXTERNAL_LAWFIRM: 'Lawfirm (External)'
    };

    @Input() pattern: string | RegExp;
    amontPattern = '^[0-9]+(\.[0-9]{1,2})?';
    @Input() selectedBankTransaction: BankTransaction;
    @Output() refreshPage = new EventEmitter<string>();
    @Input() isAttorney: boolean;
    @Input() isLawfirm: boolean;
    // @Input() availableInvoiceHandle: TransactionLinesHandle[];
    @Input() onShow: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('inputCheckbox') inputCheckbox: ElementRef;
    @ViewChild('AvailableInvoicesBankTransactionComponent') availableInvoicesBankTransaction: AvailableInvoicesBankTransactionComponent;


    public promptAvailableInvoicesEvent: EventEmitter<any> = new EventEmitter<any>();
    public pushAvailableInvoices: EventEmitter<any> = new EventEmitter<any>();

    get currencySymbol(): string {
        if (this.userProfileService.userProfile.isImpersonator) {
            return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
        } else {
            return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
        }
    } // end currencySymbol()

    // addedInvoiceToTransaction
    get canAllocateButton(): boolean {
        return Boolean(this.addedInvoiceToTransaction && this.addedInvoiceToTransaction.find(x => x.data.InvoiceId > 0));
    } // end canAllocateButton()
    get showZeroErrorPaid(): boolean {
        return Boolean(this.addedInvoiceToTransaction.filter(s => s.data.Paid === 0 || s.data.Paid.toString() === '0').length > 0);
    } // end showZeroErrorPaid()

    get hasInvoicesAllocated(): boolean {
        return Boolean(this.addedInvoiceToTransaction.filter(inv => inv.data.InvoiceId > 0).length > 0);
    } // end hasInvoicesAllocated()

    get showAvailableInvoiceList(): boolean {
        let canshow = false;
        if (this.addedInvoiceToTransaction.length > 0) {
            if (this.selectedBankTransaction.Allocated === 'Fully') {
                // if (this.totalAmountLeft) {
                if (this.totalAmountLeft === 0) {
                    canshow = true;
                } else {
                    canshow = false;
                }
                // }
            } else {
                if (this.totalAmountLeft === 0) {
                    canshow = true;
                } else {
                    canshow = false;
                }
            }
        } else {
            if (this.totalAmountLeft === 0) {
                canshow = true;
            } else {
                canshow = false;
            }
        }
        // return Boolean(this.selectedBankTransaction.Allocated === 'Fully') ||
        //         Boolean(this.totalAmountLeft === 0);
        return canshow;
    } // end showAvailableInvoiceList()

    get hasAnError(): boolean {
        return Boolean(this.totalAmountLeft < 0);
    } // end hasAnError()

    // Is Practice Manager
    get isPracticeManager(): boolean {
        return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
    }  // end isPracticeManager()

    constructor(
        private router: Router,
        private util: UtilitiesService,
        private cpipe: UsercurrencyPipe,
        private notesService: NotesService,
        private loadingService: LoadingService,
        private contactsService: ContactsService,
        private indexedDbService: IndexedDBService,
        private snackbarsService: SnackbarsService,
        private userProfileService: UserProfileService,
        private activityLogService: ActivityLogsService,
        private otherClientService: OtherClientsService,
        private currencyDirective: UsercurrencyDirective,
        private invoiceDetailService: InvoiceDetailsService,
        private processPaymentService: ProcessPaymentService,
    ) {
    } // end constructor()

    ngOnInit() {
        // this.onShow.subscribe((response) => {
        //     if (response) {
        //         this.totalAmount = 0;
        //         this.bankTransaction = response;
        // this.promptInvoices = true;
        //         this.promptPaidInvoices = false;
        //         this.totalAmount = 0;
        this.selectedBankTransaction = Object.assign({}, DEFAULT_BANK_TRANSACTION);
        //         const selected_nvoices: any = [];
        //         this.getAvailableInvoices(this.bankTransaction.Id);

        //         if (response.BankTransactionLines && response.BankTransactionLines.length > 0) {
        //             response.BankTransactionLines.forEach(trans => {
        //                 this._selectedInvoice.TransactionId = trans.TransactionId;
        //                 this._selectedInvoice.TransactionLinkId = trans.TransactionLinkId;
        //                 this._selectedInvoice.Amount = trans.Amount;
        //                 this._selectedInvoice.Client = trans.LawFirm;
        //                 this._selectedInvoice.InvoiceNo = trans.Invoice;
        //                 this._selectedInvoice.InvoiceId = trans.InvoiceId;
        //                 this._selectedInvoice.Matter = trans.Matter;
        //                 this._selectedInvoice.Paid = 0;
        //                 this._selectedInvoice.orderId = trans.Id;

        //                 if (response.Amount > this._selectedInvoice.Amount) {
        //                     this.totalAmountLeft = Number(response.Amount) - Number(trans.Amount);
        //                     this._selectedInvoice.Paid = Number(response.Amount) - Number(this.totalAmountLeft);
        //                     this.allocateButton = false;
        //                     this.saveButton = false;
        //                     // this._selectedInvoice.
        //                 } else if (response.BankTransactionLines.length === 1) {
        //                     this._selectedInvoice.Paid = response.Amount;
        //                 }
        //                 selected_nvoices.push(this._selectedInvoice);
        //                 this._selectedInvoice = Object.assign({}, DEFAULT_TRANSACTIONS_LINE_ADDED);
        //             });
        // this.onPaidInvoices(0);
        //         }

        //     } else {
        this.promptInvoices = false;
        this.promptPaidInvoices = false;
        //         this.promptAvailableInvoicesEvent.next();
        //     }
        //     this.initInvoiceAddedToTransaction();
        // });
        // this.lawfirmsList = JSON.parse(localStorage.getItem('lawFirms'));
        this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData').then((data) => {
            if (data) {
              console.log('NG INIT LAW FIRM DATA: ', data);
                this.lawfirmsList = data.data; // Assuming your data is stored under the 'data' key
            } else {
              console.log('NG INIT LAW FIRM NO DATA: ', this.lawfirmsList);
                this.lawfirmsList = []; // Or any default value you wish to set
            }
        }).catch((error) => {
            console.error('Error retrieving law firms from IndexedDB', error);
            this.lawfirmsList = []; // Setting a default value in case of an error
        });
    } // end ngOnInit()

    // getPossibleMatches(bankTransaction: BankTransaction) {
    //     this.selectedBankTransaction = Object.assign({}, bankTransaction);
    //     this.availableInvoiceHandle = [];
    //     this.loadingService.showOverlay();
    //     this.processPaymentService.getPossibleMatches(bankTransaction.Id)
    //         .subscribe(invoices => {
    //             invoices.AvailableInvoices.forEach(invoice => {
    //                 this.invoicesToBeAdded = invoice;
    //                 this.availableInvoiceHandle.push({
    //                     data: invoice,
    //                     state: {
    //                         isVisible: true,
    //                         isSelected: false,
    //                         isOpen: false
    //                     }
    //                 });
    //             });
    //         },
    //             error => {
    //                 // On Error
    //                 this.loadingService.hideOverlay();
    //             },
    //             () => {
    //                 // On complete
    //                 this.loadingService.hideOverlay();
    //             });
    // } // end getPossibleMatches()

    ngOnChanges(changes: SimpleChanges) {
        localStorage.setItem('writeOff', null);
        const selected_nvoices: any = [];
        if (this.selectedBankTransaction && this.selectedBankTransaction.Id > 0) {
            // this.getPossibleMatches(this.selectedBankTransaction);
        } else {
            this.selectedBankTransaction = Object.assign({}, DEFAULT_BANK_TRANSACTION);
        }
        // if (changes.availableInvoiceHandle && changes.availableInvoiceHandle.currentValue) {
        // this.totalAmount = 0;
        // this.promptInvoices = true;
        // this.promptPaidInvoices = false;
        // this.totalAmount = 0;
        this.invoices = [];
        // }


        if (changes.selectedBankTransaction && changes.selectedBankTransaction.currentValue) {
            this.invoices = [];
            this.addedInvoiceToTransaction = [];
            this._selectedInvoice = Object.assign({}, DEFAULT_TRANSACTIONS_LINE_ADDED);
            if (this.selectedBankTransaction.BankTransactionLines && this.selectedBankTransaction.BankTransactionLines.length > 0) {
                this.selectedBankTransaction.BankTransactionLines.forEach(trans => {
                    // trans = this.availableInvoiceHandle.filter(inv => inv.data.InvoiceId === trans.InvoiceId)[0].data;
                    // this.availableInvoiceHandle = this.availableInvoiceHandle.filter(c => c.data.InvoiceId !== trans.InvoiceId);
                    this._selectedInvoice.TransactionId = trans.TransactionId;
                    this._selectedInvoice.TransactionLinkId = trans.TransactionLinkId;
                    this._selectedInvoice.Amount = trans.Amount;
                    this._selectedInvoice.Client = trans.LawFirm;
                    this._selectedInvoice.InvoiceNo = trans.Invoice;
                    this._selectedInvoice.InvoiceId = trans.InvoiceId;
                    this._selectedInvoice.Matter = trans.Matter;
                    if (trans.TransactionId !== null) {
                        this._selectedInvoice.Paid = Number(trans.Paid);
                    }
                    if (this.selectedBankTransaction.ProofOfPaymentId !== null) {
                        this._selectedInvoice.Paid = trans.Paid; // ? trans.Paid : trans.Amount;
                    }
                    this._selectedInvoice.orderId = trans.Id;
                    this._selectedInvoice.Date = trans.InvoiceDate;
                    this._selectedInvoice.Reference = trans.Reference;
                    
                    if (this.selectedBankTransaction.Amount > this._selectedInvoice.Amount) {
                        this.totalAmountLeft = Number(this.selectedBankTransaction.Amount) - Number(trans.Amount);
                        // this._selectedInvoice.Paid = Number(this.selectedBankTransaction.Amount) - Number(this.totalAmountLeft);
                        this.allocateButton = false;
                        this.saveButton = false;
                        // this._selectedInvoice.
                    }
                    //  else
                    // if (this.selectedBankTransaction.BankTransactionLines.length === 1) {
                        //     this._selectedInvoice.Paid = this.selectedBankTransaction.Amount;
                        // }
                        selected_nvoices.push(this._selectedInvoice);
                        this._selectedInvoice = Object.assign({}, DEFAULT_TRANSACTIONS_LINE_ADDED);
                    });
                } else {
                    this.selectedBankTransaction.BankTransactionLines = [];
                }
            }
            if (changes.availableInvoiceHandle && changes.availableInvoiceHandle.currentValue) {
            }
            this.onPaidInvoices(selected_nvoices);
            this.onTotalsCalculateChange('change');
    } // end ngOnChanges()

    onViewInvoice(invoiceId: number) {
        this.loadingService.showOverlay();
        this.notes = [];
        this.notesHandle = [];
        this.selectedTab = 'invoice';
        if (invoiceId) {
            let _note = [];
            if (this.isPracticeManager) {
                this.notesService.getNotes(this.userProfileService.selectedUserProfile.serviceProviderID,
                    invoiceId, 4).subscribe({next: (_n_note) => { _note = _n_note; }});
            } else if (!this.isPracticeManager) {
                this.notesService.getNotes(this.userProfileService.userProfile.serviceProviderID,
                    invoiceId, 4).subscribe({next: (_n_note) => { _note = _n_note; }});
            }
            if (!this.isPracticeManager) {
                this.notes = _note.filter(x => x.QuestionText);
            } else {
                this.notes = _note;
            }
            this.invoiceDetails = Object.assign({}, DEFAULT_INVOICE_DETAILS);
            // this.showProformaInvoice = true;
            this.invoiceDetailService.getInvoiceDetails(invoiceId).subscribe({next: (_invoice_details) => {
                this.invoiceDetails = _invoice_details;
                this.selectedInvoiceId = invoiceId;
    
                if (this.invoiceDetails) {
                    const desc = this.invoiceDetails.InvoiceParties.split(' // ');
                    if (desc.length > 0) {
                        this.invoicePlaintiff = desc[0];
                    }
                    if (desc.length > 1) {
                        this.invoiceDefendant = desc[1];
                    }
                }
            }});

            this.notes.forEach(_notes => {
                this.notesHandle.push({
                    data: _notes,
                    state: {
                        isVisible: true,
                        isSelected: false,
                        isOpen: false
                    }
                });
            });
        }
        this.showProformaInvoice = true;
        this.loadingService.hideOverlay();
    } // end onViewInvoice()

    onHideErrorMessagePrompt() {
        this.saveButton = false;
        this.showErrorMessagePrompt = false;
    } // end onHideErrorMessagePrompt()

    onAmountChange(line: any, event) {

        if (this.writeOffInvoices) {
            this.addedInvoiceToTransaction.filter(inv => {
                if (inv.data.InvoiceId === line.InvoiceId) {
                    inv.writeOff = {
                        isWriteOff: false,
                        Date: "",
                        Amount: 0,
                        Description: "",
                        InvoiceType: "WriteOff"
                    };
                    this.writeOffInvoices.invoices = this.writeOffInvoices.invoices.filter(f => f.InvoiceId !== line.InvoiceId);
                   // this.onTotalsCalculateChange('changes');
                }
            });
        }
        localStorage.setItem("writeOff", JSON.stringify(this.writeOffInvoices));

        if (this.userProfileService.userProfile.isImpersonator) {
            event.target.value = this.cpipe.transform(event.target.value,
                this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
        } else {
            event.target.value = this.cpipe.transform(event.target.value,
                this.userProfileService.userProfile.currencyDetails.symbol, 2);
        }

        if (line.Paid > line.Amount) {
            this.showErrorMessagePrompt = true;
        }
        if (this.selectedBankTransaction.BankTransactionLines.filter(invoice => invoice.InvoiceId === null).length > 0) {
            this.selectedBankTransaction.BankTransactionLines =
                this.selectedBankTransaction.BankTransactionLines.filter(a => a.InvoiceId !== null);
            this.addedInvoiceToTransaction = this.addedInvoiceToTransaction.filter(a => a.data.InvoiceId !== null);

            this.onTotalsCalculateChange('changes');
        }
    } // end onAmountChange()

    onAmountClicked(line: any, event) {
        if (this.userProfileService.userProfile.isImpersonator) {
            event.target.value = this.cpipe.parse(event.target.value,
                this.userProfileService.selectedUserProfile.currencyDetails.symbol);
        } else {
            event.target.value = this.cpipe.parse(event.target.value,
                this.userProfileService.userProfile.currencyDetails.symbol);
        }
    } // end onAmountClicked()

    getDateFormet(value: Date): string {
        return moment(value).format('DD/MM/YYYY');
    }

    initInvoiceAddedToTransaction() {
        this.addedInvoiceToTransaction = [];
        this.writeOffInvoices = JSON.parse(localStorage.getItem('writeOff'));

        this.invoices.forEach(invoice => {
            var writeOffRow = {
                isWriteOff: false,
                Date: "",
                Amount: 0,
                Description: "",
                InvoiceType: "WriteOff"
            };
            if (this.writeOffInvoices) {
                var write = this.writeOffInvoices.invoices.filter(f => f.InvoiceId == invoice.InvoiceId);
                if (write.length > 0 && write[0].Amount > 0) {
                    writeOffRow = {
                        isWriteOff: true,
                        Date: this.writeOffInvoices.date,
                        Amount: write[0].Amount,
                        Description: this.writeOffInvoices.note,
                        InvoiceType: "WriteOff"
                    }
                }
            }
            this.addedInvoiceToTransaction.push({
                data: invoice,
                state: {
                    isVisible: true,
                    isSelected: false,
                    isOpen: false,
                },
                writeOff: writeOffRow
            });
        });
        this.onTotalsCalculateChange('changed');

    } // end initInvoiceAddedToTransation()

    numberConversion(value: number): string {
        return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }


    getAvailableInvoices(data: number) {
        let res = null;
        this.processPaymentService.getPossibleMatches(data).subscribe({next: (_next) => {
            res = _next;
            this.promptAvailableInvoicesEvent.next(res.AvailableInvoices);
        }});
    }

    getInvoices(bank: any) {
        this.totalAmount = 0;
        this.allocateRow = false;
        if (this.invoices.length === 0) {
            this._invoices = [];
        }
        if (bank.length > 0) {
            this.promptInvoices = false;
            this.promptPaidInvoices = true;
            for (const i of bank) {
                if (i.Paid > 0) {
                    // i.Amount = i.Amount - i.Paid;
                    i.Amount = i.Amount;
                } else {
                    i.Amount = i.Amount;
                }

                if (!i.TransactionId) {
                    if (this.selectedBankTransaction.ProofOfPaymentId === null) {
                        if (this.invoices.length === 0) {
                            if (i.Amount === this.selectedBankTransaction.Amount) {
                                i.Paid = this.selectedBankTransaction.Amount;
                            } else if (i.Amount > this.selectedBankTransaction.Amount) {
                                i.Paid = this.selectedBankTransaction.Amount;
                            } else if (i.Amount < this.selectedBankTransaction.Amount) {
                                i.Paid = i.Amount;
                            }
                        } else {
                            let t_count = 0;
                            this.invoices.forEach(inv => {
                                t_count += inv.Amount;
                            });
                            if (t_count + i.Amount < this.selectedBankTransaction.Amount) {
                                i.Paid = i.Amount;
                            } else if (this.selectedBankTransaction.Amount - (t_count) > 0) {
                                i.Paid = this.selectedBankTransaction.Amount - (t_count);
                            } else {
                                i.Paid = 0;
                            }
                        }
                    }
                }
                this.invoices.push(i);
                this.lawFirmName = i.Client;
            }
            this.initInvoiceAddedToTransaction();
            // this.invoices = this._invoices.map(a => Object.assign({}, a));
        } else {
            this.invoices = [];
            this.promptInvoices = true;
            this.promptPaidInvoices = false;
        }

        for (const value of this.invoices) {
            this.totalAmount = this.totalAmount + value.Paid;
            // value.Paid = 'R' + this.numberConversion(value.Paid);
        }
        this.getAllocateAmount();
        this.contactsService.getLawFirmsByServiceProvider().subscribe(lawfirms => {
            this.lawFirms = lawfirms;
        });
        if (this.selectedBankTransaction.Id === 0) {
            this.promptInvoices = false;
        }
        this.onTotalsCalculateChange('changed');
        console.log('ALLO: ', this.selectedBankTransaction);
    } // end getInvoices()

//    onTotalsCalculateChange(event) {
//         let total = 0;
    onTotalsCalculateChange(event) {

        let total: number = 0;

        this.addedInvoiceToTransaction.forEach(inv => {
            total += Number(inv.data.Paid);
        });

        if (this.allocateRow === true) {
            total += Number(this.allocateAmount);
        }

        this.totalAmount = +parseFloat(total.toString()).toFixed(2);
        this.totalAmountLeft = Number(this.selectedBankTransaction.Amount) - Number(this.totalAmount);

        if (this.addedInvoiceToTransaction.length > 0) {
            if (!this.addedInvoiceToTransaction.find(invoice => Number(invoice.data.Paid) === 0)) {
                if (this.totalAmountLeft !== 0) {
                    if (Number(this.totalAmount) === Number(this.selectedBankTransaction.Amount)) {
                        this.allocateButton = false;
                        this.saveButton = true;
                    } else if (Number(this.totalAmountLeft) < 0) {
                        this.allocateButton = false;
                        this.saveButton = false;
                    } else {
                        this.allocateButton = true;
                        this.saveButton = false;
                    }
                } else {
                    this.allocateButton = false;
                    this.saveButton = true;
                }
            } else {
                this.allocateButton = false;
                this.saveButton = false;
            }
        } else {
            this.allocateButton = false;
            this.saveButton = false;
        }

        if (event === 'allocate-to-firm') {
            if (this.allocateRow === true && this.lawFirmName === undefined) {
                this.saveButton = false;
            } else {
                this.saveButton = true;
            }
        }
    } // end onTotalsCalculateChange()

    saveInvoices() {
        this.loadingService.showOverlay();

        const snack: Snack = {
            label: 'Save Bank Transaction Line',
            action: null
        };
        this._toProcessTransactionLines = [];
        this._toWriteOffTransactionLine = [];
        this.addedInvoiceToTransaction.forEach(invoice => {
            this.toProcessTransaction.Id = 0;
            this.toProcessTransaction.TransactionId = invoice.data.TransactionId;
            this.toProcessTransaction.TransactionLinkId = invoice.data.TransactionLinkId;
            this.toProcessTransaction.Invoice = invoice.data.InvoiceNo;
            this.toProcessTransaction.InvoiceId = invoice.data.InvoiceId;
            this.toProcessTransaction.LawFirm = invoice.data.Client;
            this.toProcessTransaction.LawFirmId = 0;
            this.toProcessTransaction.Matter = invoice.data.Matter;
            this.toProcessTransaction.Reference = invoice.data.Reference;
            this.toProcessTransaction.Amount = invoice.data.Amount;
            this.toProcessTransaction.Paid = invoice.data.Paid;
            this.toProcessTransaction.AssignedToLawFirm = false;
            this._toProcessTransactionLines.push(this.toProcessTransaction);
            this.toProcessTransaction = Object.assign({}, DEFAULT_PROCESSED_TRANSACTION_LINE);

            if (invoice.writeOff.isWriteOff) {
                this.toWriteOffTransactionLine.Id = 0;
                this.toWriteOffTransactionLine.TransactionDate = invoice.writeOff.Date;
                this.toWriteOffTransactionLine.InvoiceType = invoice.writeOff.InvoiceType;
                this.toWriteOffTransactionLine.Description = invoice.writeOff.Description;
                this.toWriteOffTransactionLine.Amount = invoice.writeOff.Amount;
                this.toWriteOffTransactionLine.ServiceInvoice_Transaction = invoice.data.InvoiceId;
                this.toWriteOffTransactionLine.Contact_Transaction = 0;
                this._toWriteOffTransactionLine.push(this.toWriteOffTransactionLine);
                this.toWriteOffTransactionLine = Object.assign({}, DEFAULT_WRITEOFF_TRANSACTIONS_LINE);
            }
        });
        // var data = this.invoices.map(a => Object.assign({}, a));
        // this.addedInvoiceToTransaction.forEach(invoice => {
        //     data
        // })

        try {

            this.selectedBankTransaction.BankTransactionLines = [];
            // for (let j of data) {
            //     j.Paid = this.formatNumber(j.Paid);
            //     delete j.orderId;
            // }
            if (this.allocateRow === true) {
                const obj: ProcessedTransactionLine = {
                    Id: this.allocateID,
                    TransactionId: 0,
                    TransactionLinkId: 0,
                    Invoice: null,
                    InvoiceId: null,
                    LawFirm: this.lawFirmName,
                    LawFirmId: this.lawFirmId,
                    Matter: null,
                    Reference: '',
                    Amount: 0,
                    Paid: this.allocateAmount,
                    AssignedToLawFirm: true
                };
                this._toProcessTransactionLines.push(obj);
                // data.push(obj);
            }
            this.selectedBankTransaction.BankTransactionLines = this._toProcessTransactionLines;

            // Log activity Login
            const currentDate = new Date();
            this.activityLog.Action = 'Process Transactions';
            this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
            this.activityLog.LoggedApp = 'Web Application (Invoice-Added-bank-Transaction)';
            if (this.userProfileService.userProfile.isImpersonator) {
                this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
            } else {
                this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
            }
            this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
            this.activityLog.ActionTable = 'Transactions';
            this.activityLog.JsonData = JSON.stringify(this.selectedBankTransaction);
            this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

             this.processPaymentService.putBankTransactions(this.selectedBankTransaction).subscribe({next: (_next) => {
                 this.refreshPage.emit('');
             }});

             this._toWriteOffTransactionLine.forEach(line => {
                 this.processPaymentService.putWriteOffBankTransactions(line).subscribe({next: (_next) => {
                 }});
            });



            localStorage.setItem('writeOff', null);

            this.allocateRow = false;
    
            snack.label = 'Changes saved successfully';
            snack.type = SnackType.SUCCESS;
            this.snackbarsService.dismiss().make(snack).show();
            this.promptInvoices = false;
            this.promptPaidInvoices = false;
            this.availableInvoiceHandle = [];
            this.allocateAmount = 0;
            this.selectedBankTransaction = Object.assign({}, DEFAULT_BANK_TRANSACTION);
            this.addedInvoiceToTransaction = [];
            this.canSendStatement();
        } catch (error) {
            snack.label = 'Something went wrong';
            snack.action = null;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
        }

    }

    async canSendStatement() {
        this.lastStatementDate = Object.assign({}, DEFAULT_SEND_STATEMENT);
        await this.processPaymentService.getLastStatementDate().subscribe({next: (_next) => {
            this.lastStatementDate = _next;
        }});
        await this.processPaymentService.getBankTransactionCount().subscribe({next: (_next) => {
            this.count = _next;
        }});

        if (moment().diff(this.lastStatementDate.LastStatementSent, 'days') >= 13 && this.count === 0) {
            this.showSendStatementPrompt = true;
        }
    } // end canSendStatement()

    // showSaveButton() {
    // if (this.totalAmount >= 0) {
    //     this.saveButton = true;
    //     this.allocateButton = true;
    //     if (this.totalAmount > this.bankTransaction.Amount) {
    //         this.saveButton = false;
    //         this.allocateButton = false;
    //     } else if (this.totalAmount === this.bankTransaction.Amount) {
    //         if (this.allocateRow === true && this.lawFirmName === undefined) {
    //             this.saveButton = false;
    //         } else {
    //             this.saveButton = true;
    //         }
    //         this.allocateButton = false;
    //     }
    // } else {
    //     this.saveButton = false;
    //     this.allocateButton = true;
    // }
    // }

    getAllocateAmount() {
        this.allocateAmount = this.selectedBankTransaction.Amount - this.totalAmount;
        if (this.allocateAmount <= 0) {
            this.allocateRow = false;
            this.allocateAmount = 0;
        }
        if (this.allocateRow) {
            this.totalAmount = this.totalAmount + this.allocateAmount;
        }
    }

    allocateInvoices() {
        this.isAllocatedToAdvocate = false;
        this.loadingService.showOverlay();
        //     this.getLawFirms();
        this.allocateID = 0;
        this.allocateRow = true;
        this.lawFirmName = undefined;
        this.lawFirmId = 0;
        this.getAllocateAmount();
        this.allocateButton = false;
        this.promptPaidInvoices = true;
        this.promptInvoices = false;
        if (this.addedInvoiceToTransaction.length > 0) {
            this.invoiceDetailService.getInvoiceDetails(this.addedInvoiceToTransaction[0].data.InvoiceId).subscribe({ next: (data) => {
                // On next
                this.lawFirmName = data.ClientName; // this.addedInvoiceToTransaction[0].data.Client;
                this.lawFirmId = data.ClientId; // this.lawfirmsList.filter(firm => firm.FullName === this.lawFirmName)[0].ContactID;
                }
                , error: (error) => {
                    // On error
                    this.loadingService.hideOverlay();
                }, complete: () => {
                    // On complete
                    this.loadingService.hideOverlay();
                    this.onTotalsCalculateChange('allocate-to-firm');
                }
            });
        } else {
            this.loadingService.hideOverlay();
            this.onTotalsCalculateChange('allocate-to-firm');
        }
        // this.showSaveButton();
    }

    allocateToClientInvoices() {
        this.isAllocatedToAdvocate = false;
        this.loadingService.showOverlay();
        //     this.getLawFirms();
        this.allocateID = 0;
        this.allocateRow = true;
        this.lawFirmName = undefined;
        this.lawFirmId = 0;
        this.getAllocateAmount();
        this.allocateButton = false;
        this.promptPaidInvoices = true;
        this.promptInvoices = false;
        if (this.addedInvoiceToTransaction.length > 0) {
            this.lawFirmName = this.addedInvoiceToTransaction[0].data.Client;
            this.lawFirmId = this.addedInvoiceToTransaction[0].data.ClientId;
        } else {
           this.otherClientService.getOtherClients().subscribe({next: (_next) => {
            this.lawFirms  = _next;
           }});
            }
            this.otherClientService.getOtherClients().subscribe({next: (_next) => {
                this.lawFirms = _next;
            }});
        // }
        this.onTotalsCalculateChange('allocate-to-firm');
        this.loadingService.hideOverlay();
        // this.allocateToClientInvoices();
    }

    allocateToAdvocate() {
        this.isAllocatedToAdvocate = true;
        this.loadingService.showOverlay();
        //     this.getLawFirms();
        this.allocateID = 0;
        this.allocateRow = true;
        this.lawFirmName = undefined;
        this.lawFirmId = 0;
        this.getAllocateAmount();
        this.allocateButton = false;
        this.promptPaidInvoices = true;
        this.promptInvoices = false;
        // if (this.addedInvoiceToTransaction.length > 0) {
        if (this.userProfileService.userProfile.isImpersonator) {
            this.lawFirmName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
            this.lawFirmId = this.userProfileService.selectedUserProfile.serviceProviderID;
        } else {
            this.lawFirmName = this.userProfileService.userProfile.personalDetails.fullName;
            this.lawFirmId = this.userProfileService.userProfile.serviceProviderID;
        }
        // }
        this.onTotalsCalculateChange('allocate-to-firm');
        this.loadingService.hideOverlay();
        // this.allocateToAdvocate();
    }

    onChangeCheckBox(Invoice: any, isChecked: boolean) {
        if (isChecked) {
            if (Invoice === 0) {
                this.allocateInvoice = Invoice;
            } else {
                this.checkBoxSelected.push(Invoice);
            }
        } else {
            if (Invoice === 0) {
                this.allocateInvoice = '';
            } else {
                const index = this.checkBoxSelected.indexOf(Invoice);
                this.checkBoxSelected.splice(index, 1);
            }
        }

        if (this.checkBoxSelected.length > 0 || this.allocateInvoice === '0') {
            this.showActions = true;
        } else {
            this.showActions = false;
        }
    }

    toggleSelectAll() {
        // this.selectAll = !this.selectAll;
        this.addedInvoiceToTransaction.forEach(invoice => {
            if (invoice.state.isVisible) {
                invoice.state.isSelected = this.inputCheckbox.nativeElement.checked;
                this.showActions = this.inputCheckbox.nativeElement.checked;
            }
        });
    } // end toggleSelectAll()

    toggleCheckbox(invoice: AddedInvoiceToTransaction) {
        invoice.state.isSelected = !invoice.state.isSelected;
        if (this.addedInvoiceToTransaction.filter(inv => inv.state.isSelected === true).length > 0) {
            this.showActions = true;
            this.inputCheckbox.nativeElement.checked = false;
        } else {
            this.showActions = false;
            // this.inputCheckbox.nativeElement.checked = true;
        }

    } // end toggleCheckbox()

    onFocus(e, id: number) {
        for (const j of this.invoices) {
            if (j.InvoiceId === id) {
                j.Paid = this.formatNumber(j.Paid);
            }
        }
    }

    onFocusOut(e, id: number) {
        this.totalAmount = 0;
        for (const j of this.invoices) {
            if (j.InvoiceId === id) {

                if (Number(j.Paid) < 0) {
                    j.Paid = 0;
                }
                this.totalAmount = this.totalAmount + Number(j.Paid);
                j.Paid = 'R' + this.numberConversion(Number(j.Paid));
            } else {
                this.totalAmount = this.totalAmount + this.formatNumber(j.Paid);
            }
        }
        // this.onTotalsCalculateChange('change');
        this.getAllocateAmount();
        // this.showSaveButton();
    }

    formatNumber(paid: string) {
        return Number((paid.toString().split('R')[1]).toString().split(' ').join(''));
    }


    removeInvoices() {
        this.showRemoveInvoices = true;
    }

    onHideRemoveInvoices() {
        this.showRemoveInvoices = false;
    }

    onRemoveInvoices() {
        if (this.showActions) {
            const snack: Snack = {
                label: 'Remove Invoices',
                action: null
            };
            this.loadingService.showOverlay();
            try {
                if (Number(this.allocateInvoice) === 0) {
                    this.allocateRow = false;
                    this.totalAmount = this.totalAmount - this.allocateAmount;
                    this.allocateID = 0;
                    // this.selectedBankTransaction.Allocated = 'Partial';
                }
                // if (this.addedInvoiceToTransaction.filter(inv => inv.state.isSelected === true).length > 0) {
                for (const inv of this.addedInvoiceToTransaction) {
                    // this.addedInvoiceToTransaction.filter(invoice => invoice.state.isSelected === true).forEach(_invo => {
                    if (inv.state.isSelected) {
                        // for (let _invo of this.addedInvoiceToTransaction) {
                        if (inv.data.orderId > 0) {
                            // Log activity Login
                            const currentDate = new Date();
                            this.activityLog.Action = 'Remove invoice from a transaction';
                            this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
                            this.activityLog.LoggedApp = 'Web Application (Invoice-Added-bank-Transaction)';
                            if (this.userProfileService.userProfile.isImpersonator) {
                                this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                                this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
                            } else {
                                this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                                this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
                            }
                            this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
                            this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
                            this.activityLog.ActionTable = 'Transactions';
                            this.activityLog.JsonData = JSON.stringify(inv.data);
                            this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

                            this.deleteTransactionLine(inv.data.orderId);
                        }
                        inv.state.isSelected = false;
                        this.invoicesToDelete.push(inv.data);
                        this.addedInvoiceToTransaction = this.addedInvoiceToTransaction.filter(deleted => deleted.data !== inv.data);
                        this.invoices = this.invoices.filter(a_invoice => a_invoice !== inv.data);
                        this.selectedBankTransaction.BankTransactionLines =
                            this.selectedBankTransaction.BankTransactionLines.filter(line => line.InvoiceId !== inv.data.InvoiceId);

                        if (this.writeOffInvoices) {
                            this.writeOffInvoices.invoices = this.writeOffInvoices.invoices.filter(f => f.InvoiceId !== inv.data.InvoiceId);
                        }
                        localStorage.setItem('writeOff', JSON.stringify(this.writeOffInvoices));

                    }
                    this.pushAvailableInvoices.next(this.invoicesToDelete);
                }

                // if (this.checkBoxSelected.length > 0) {
                //     var data = this.checkBoxSelected.map(a => Object.assign({}, a));
                //     for (let j of data) {
                //         j.Paid = this.formatNumber(j.Paid);
                //         let index = this.invoices.indexOf(j);
                //         this.invoices.splice(index, 1);
                //     }
                //     this.pushAvailableInvoices.next(data);
                // }

                if (this.invoices.length === 0) {
                    this.totalAmount = 0;
                    this.promptInvoices = true;
                    this.promptPaidInvoices = false;
                }
                this.getAllocateAmount();
                // this.showSaveButton();
                this.onTotalsCalculateChange('deteted');
            } catch (error) {
                snack.label = 'Something went wrong';
                snack.action = null;
                snack.type = SnackType.ERROR;
                this.loadingService.hideOverlay();
                this.snackbarsService.dismiss().make(snack).show();
                // this.loadingService.hideOverlay();
            } finally {
                this.showRemoveInvoices = false;
                this.showActions = false;
                this.selectAll = false;
                this.checkBoxSelected = [];
                this.invoicesToDelete = [];
                this.inputCheckbox.nativeElement.checked = false;
                // if (this.selectedBankTransaction.BankTransactionLines.length > 0) {
                //     this.selectedBankTransaction.Allocated = 'Partial';
                // } else {
                this.selectedBankTransaction.Allocated = 'None'; // = Object.assign({}, this.selectedBankTransaction);
                // }
                if (this.selectedBankTransaction) {
                    // this.availableInvoicesBankTransaction.getPossiblMatches(this.selectedBankTransaction);
                }
                this.loadingService.hideOverlay();
            }
        }
    }

    deleteTransactionLine(line: number) {
        // Log activity Login
        const currentDate = new Date();
        this.activityLog.Action = 'Delete a transaction line';
        this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (Invoice-Added-bank-Transaction)';
        if (this.userProfileService.userProfile.isImpersonator) {
            this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
            this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'Transactions';
        this.activityLog.JsonData = JSON.stringify(line);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

         this.processPaymentService.removeTransactionLines(line).subscribe({next: (_remove) => {}});
    } // end deleteTransactionLine()

    onPaidInvoices($event) {
        if ($event !== 0) {
            this.getInvoices($event);
        }
    }

    getLawFirms() {
        this.contactsService.getLawFirmsByServiceProvider().subscribe({next: (_firms) => {
            this.lawFirms = _firms;
        }});
    }

    onInput(event) {
        if (event.inputType === 'deleteContentBackward') {
            this.SelectedFirm = this.lawFirms;
            this.AllSelectedFirm = this.allLawFirms;
            this.saveButton = false;
        }
    }

    onChangeLawFirm(event: TypeaheadMatch): void {
        this.lawFirmName = this.lawFirmName;
        this.lawFirmId = event.item.ContactID;
        this.Category = '';
        this.onShowUnAllocatedCategory = true;
        this.saveButton = true;
    }

    onUnAllocatedCategoryHide() {
        this.onShowUnAllocatedCategory = false;
    } // end onUnAllocatedCategoryHide();

    OnUnAllocatedCategoryConfirm(event) {
        this.Category = event;
        this.selectedBankTransaction.Category = event;
        this.onShowUnAllocatedCategory = false;
    } // end OnUnAllocatedCategoryConfirm()

    closeModal(value: string) {
        switch (value) {
            case 'proforma-invoice':
                this.showProformaInvoice = false;
                break;
            case 'Write-off':
                this.showWriteOffUnderPayment = false;
                this.initInvoiceAddedToTransaction();
                break;
        }
    } // end closeModal()

    onSendStatement() {
        this.router.navigate([PATH_SEND_STATEMENT]);
    } // end onSendStatement()

    onHideSendStatementPrompt() {
        this.showSendStatementPrompt = false;
    } // end onHideSendStatementPrompt()

    onViewWriteOffUnderPayment() {
        this.showWriteOffUnderPayment = true;
    }

} // end InvoicesAddedBankTransactionComponent{}
