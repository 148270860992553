<div *ngIf="onShowApprovedRejected" class="row">
    <div class="col-sm-12">
        <h3  *ngIf="statusApproved" class="titleHeader">Request Approved</h3>
        <h3  *ngIf="statusRejected" class="titleHeader">Request Rejected</h3>
        <div class="row icon-success">
            <i class="fal fa-check-circle fa-6x"></i>
        </div>
        <div *ngIf="statusApproved" class="statement">
            <div *ngIf="!isGCBDeleteReq">
                <span>You have approved the request from the {{currentBarName}} to <br /></span>
                <span class="mt-8"> add {{currentLawfirmName}} to the GCB defaulters List.</span>
            </div>     
            <div *ngIf="isGCBDeleteReq">
                <span>You have approved the request from the {{currentBarName}} to <br /></span>
                <span class="mt-8"> Delete {{currentLawfirmName}} to the GCB defaulters Listing.</span>
            </div>           
        </div>
        <div *ngIf="statusRejected" class="statement">
            <div *ngIf="!isGCBDeleteReq">
                <span>You have rejected the request from the {{currentBarName}} to <br /></span>
                <span class="pl-2"> add {{currentLawfirmName}} to the GCB defaulters list.</span>
            </div>
            <div *ngIf="isGCBDeleteReq">
                <span>You have rejected the request from the {{currentBarName}} to <br /></span>
                <span class="pl-2"> Delete {{currentLawfirmName}} to the GCB defaulters listing.</span>
            </div>
        </div>
    </div>
</div>
<div  *ngIf="onShowCancel" class="row">
    <div class="col-sm-12">
        <h3 class="titleCancel">Something went wrong</h3>
        <div class="row icon-danger">
            <i class="fal fa-times-circle fa-6x"></i>
        </div>
        <div class="statement">
            <span>{{cancelErrorMessage}}</span>                        
        </div>
    </div>
</div>