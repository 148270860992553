<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onDismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div><!--#end .modal-header -->

      <div class="modal-body form">
        <div class="row">
          <div class="form-group">
            <label for="description">Description<span title="Required field"> *</span></label>
            <input
              type="text"
              class="form-control"
              [class.has-error]="hasDescriptionError"
              placeholder="Enter description"
              [(ngModel)]="workingFeeTemplate.SubCategory"
              autofocus>
              <span 
                *ngIf="hasDescriptionError" 
                class="text-danger">Please enter a valid description.</span>
          </div><!--#end .form-group -->
        </div><!--#end .row -->

        <div class="row">
          <div class="form-group">
            <label for="category">Category<span title="Required field"> *</span></label>
            <select id="category" [(ngModel)]="workingFeeTemplate.Category" [class.has-error]="hasCategoryError">
              <option value="">Select category</option>
              <option
                *ngFor="let category of categories"
                [value]="category">{{category}}</option>
            </select>
            <span 
              *ngIf="hasCategoryError" 
              class="text-danger">Please select a category.</span>
          </div><!--#end .form-group -->
        </div><!--#end .row -->

        <div class="row">
          <div class="form-group">
            <label for="rate-type">Rate type<span title="Required field"> *</span></label>
            <select id="rate-type" [(ngModel)]="workingFeeTemplate.RateType" [class.has-error]="hasRateTypeError">
              <option value="">Select rate type</option>
              <option *ngFor="let type of rateTypes" [value]="type">{{type}}</option>
            </select>
            <span 
              *ngIf="hasRateTypeError" 
              class="text-danger">Please select a rate type.</span>
          </div><!--#end .form-group -->

          <!-- <div class="form-group">
            <label for="show-time-as">Show time as</label>
            <select id="show-time-as" [(ngModel)]="workingFeeTemplate.ShowTimeAs">
              <option value="">Select rate type</option>
              <option *ngFor="let status of availabilities" [value]="status">{{status}}</option>
            </select>
          </div> -->
          <!--#end .form-group -->

          <!-- <div class="form-group">
            <label for="default-rate">Default rate</label>
            <input
              type="text"
              id="default-rate"
              currencyMask
              [options]="{ prefix: 'R ' }"
              [(ngModel)]="workingModel.DefaultRate">
          </div> -->
          <!--#end .form-group -->

        </div><!--#end .row -->

      </div><!--#end .modal-body -->

      <div class="modal-footer">
        <button
          class="btn btn-success btn-end"
          [class.disabled]="!isAbleToSave"
          [disabled]="!isAbleToSave"
          (click)="save()">Save fee template
          </button>
      </div><!--#end .modal-footer -->

    </div><!--#end .modal-dialog -->
  </div><!--#end .modal-content -->
</div><!--#end .modal -->

<app-prompt-unsaved-changes-modal
  message="Are you sure you want to leave this screen? You have unsaved changes."
  [isVisible]="showUnsavedChangesPrompt" 
  (cancel)="onHideUnsavedChangesPrompt()"
  (continue)="dismiss()" 
  (confirm)="save()"></app-prompt-unsaved-changes-modal>