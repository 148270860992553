<div *ngIf="isShowFreeTrialPrompt">
    <div class="alertMessage" *ngIf="!isShowPaymentDuePrompt">
        <div class="col-md-12 text-center" style="font-weight: 600;">
            <span *ngIf="isShowUpgradeMessage">
                Your FREE {{ FreeTrialDays }} Day trial has expired. Complete your billing details to continue using the
                Auxcon system
            </span>
            <span *ngIf="!isShowUpgradeMessage">
                Your subscription has cancelled. Complete your billing details to continue using the Auxcon system
            </span>
        </div>
    </div>
    <div class="modal" *ngIf="!isShowPaymentDuePrompt"
        style="margin-top: 28px; background-color:rgba(255, 255, 255, 0.90);">
        <div class="modal-content">
            <div class="modal-dialog">
                <div class="modal-header">
                    <h2 *ngIf="isShowUpgradeMessage">{{ FreeTrialDays }} DAY FREE TRIAL HAS EXPIRED</h2>
                    <h2 *ngIf="!isShowUpgradeMessage">SUBSCRIPTION HAS CANCELLED</h2>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <i class="fal fa-exclamation-triangle"></i>
                        </div>
                        <div class="message">
                            <p *ngIf="isShowUpgradeMessage">
                                Please take note that your {{ FreeTrialDays }} day free trial has expired. Should
                                you wish to continue using the Auxcon platform, kindly select upgrade
                                and select a monthly subscription suitable for you.
                            </p>
                            <p *ngIf="!isShowUpgradeMessage">
                                Please take note that your subscription has cancelled. Should
                                you wish to continue using the Auxcon platform, kindly select upgrade
                                and select a monthly subscription suitable for you.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-danger" (click)="signOut()">CANCEL</button>
                    <button class="btn btn-success" (click)="UpgradePlan()">UPGRADE</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" *ngIf="isShowPaymentDuePrompt"
        style="background-color:rgba(255, 255, 255, 0.90);">
        <div class="modal-content">
            <div class="modal-dialog">
                <div class="modal-header">
                    <button class="modal-dismiss" (click)="signOut()">
                        <i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i>
                    </button>
                    <h2>Payment Due</h2>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <i class="fal fa-exclamation-triangle"></i>
                        </div>
                        <div class="message">
                            Your previous payment is due. kindly pay the amount of the payment to continue use the Auxcon platform.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>