import { Component, OnInit } from '@angular/core';
import { ICrumb } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { PATH_ATTORNEY_DASHBOARD, PATH_ATTORNEY_AGE_ANALYSIS } from '../../../services/appdata/app-config.service';

@Component({
  selector: 'app-attorney-all-customer',
  templateUrl: './attorney-all-customer.component.html',
  styleUrls: ['./attorney-all-customer.component.scss']
})
export class AttorneyAllCustomerComponent implements OnInit {

  crumbs: ICrumb[];

  constructor() { } // end constructor()

  ngOnInit() {
    this.crumbs = [
      {
        label: 'Live Statement',
        link: PATH_ATTORNEY_DASHBOARD
      },
      {
        label: 'Age Analysis',
        link: PATH_ATTORNEY_AGE_ANALYSIS
      },
      {
        label: 'All Advocates Report'
      }
    ];
  } // end ngOnInit()

} // end AttorneyAllCustomerComponent{}
