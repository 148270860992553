import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationStart } from '@angular/router';

import {
  MattersService,
  Matter,
  DEFAULT_MATTER,
  BriefDocuments,
  HistoricalData,
  DEFAULT_HISTORICAL_DATA
} from '../../services/matters/matters.service';

import {
  FeeItemsService,
  Fee,
  DocumentTypes,
} from '../../services/fee-items/fee-items.service';
import {
  UserProfileService,
  UserProfile
} from '../../services/user-profile/user-profile.service';
import {
  SnackbarsService,
  Snack,
  SnackType
} from '../../services/messaging/snackbars/snackbars.service';
import {
  LENGTH_LONG
} from '../../services/messaging/toast/toast.service';
import {
  ListsService,
  LawFirm,
  LIST_NAME_LAW_FIRMS
} from '../../services/lists/lists.service';
import {
  PATH_MATTERS,
  PATH_INVOICE_REVIEW,
  PATH_DASHBOARD,
  PATH_INVOICE_EMAIL,
  PATH_ADVOCATE_INVOICE_PREVIEW,
  PATH_ARBITRATION_INVOICES
} from '../../services/appdata/app-config.service';
import { DefendantsService } from '../../services/defendants/defendants.service';
import { PlaintiffsService, Plaintiff } from '../../services/plaintiffs/plaintiffs.service';
import { SubCategoriesService, SubCategory } from '../../services/sub-categories/sub-categories.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../services/utilities.service';
import { NavigationService } from '../../services/navigation/navigation.service';
// import { SubscriptionLike as ISubscription } from 'rxjs';
import { MatterDetailsComponent } from '../../components/tables/matter-details/matter-details/matter-details.component';
import { MatterFeesTableComponent } from '../../components/tables/matter-fees-table/matter-fees-table/matter-fees-table.component';
import { delay } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { OtherClients, OtherClientsService } from '../../services/other-clients/other-clients.service';
import { InvoiceDocumentService } from '../../services/invoice-document/invoice-document.service';
import { InvoiceDetailsService, InvoiceDetails, InvoiceLine } from '../../services/invoice-details/invoice-details.service';
import { AddInvoiceNoteComponent } from '../../components/modals/add-invoice-note/add-invoice-note.component';
import { InvoicesService, Invoice, DEFAULT_INVOICE, ArbitrationView } from '../../services/invoices/invoices.service';
import {
  LoadingOverlayModalOptions,
  DEFAULT_LOADINGOVERLAY_OPTIONS
} from '../../components/modals/loading-overlay-modal/loading-overlay-modal.component';
import { DEFAULT_ACTIVITY_LOGS, ActivityLogs, ActivityLogsService } from '../../services/activity-logs/activity-logs.service';
import * as moment from 'moment';
import { UsercurrencyPipe } from '../../pipe/usercurrency.pipe';
import { HttpClient } from '@angular/common/http';
import { AdvocateDetailStatementService } from '../../services/advocate-detail-statement/advocate-detail-statement.service';
import { lastValueFrom, SubscriptionLike as ISubscription } from 'rxjs';
import { QuotationFeeItem, QuotationService } from '../../services/quotation/quotation.service';
import { IndexedDBService } from '../../services/indexed-db/indexed-db.service';
import { DataService } from '../../services/messaging/data/data.service';
import { ArbitrationViewHandle } from '../advocate-arbitration-invoices/advocate-arbitration-invoices.component';

@Component({
  selector: 'app-advocate-matters',
  templateUrl: './advocate-matters.component.html',
  styleUrls: ['./advocate-matters.component.scss']
})
export class AdvocateMattersComponent implements OnInit, OnDestroy {

  @ViewChild(MatterDetailsComponent) matterDetails: MatterDetailsComponent;
  @ViewChild(MatterFeesTableComponent) matterFees: MatterFeesTableComponent;
  @ViewChild(AddInvoiceNoteComponent) addInvoiceNoteComponent: AddInvoiceNoteComponent;

  title = '';
  id: number;
  matterDetailsLoading: boolean;
  historicalDataDetailsLoading: boolean;
  matter: Matter = Object.assign({}, DEFAULT_MATTER);
  historical: HistoricalData = Object.assign({}, DEFAULT_HISTORICAL_DATA);
  workingHistorical: HistoricalData = Object.assign({}, DEFAULT_HISTORICAL_DATA);
  workingMatter: Matter = Object.assign({}, DEFAULT_MATTER);
  serviceDate: string;
  newMatterId: number;
  parsedDescription: any = {
    plaintiffs: '',
    defendants: '',
    otherClients: ''
  };
  lawFirms: LawFirm[] = [];
  attorneys: string[] = [];
  plaintiffs: Plaintiff[] = [];
  otherClients: OtherClients[] = [];
  defendants: string[] = [];
  attorneyRefs: string[] = [];
  instructions: string[] = [];
  fees: Fee[] = [];
  workingFees: Fee[] = [];
  historicalFees: Fee[] = [];
  workingHistoricalFees: Fee[] = [];
  subCategories: SubCategory[] = [];
  showDeleteMatterModal: boolean;
  showDeleteHistoricalModal: boolean;
  showUnsavedChangesPrompt: boolean;
  showAddInvoiceNoteModal: boolean;
  documentTypes: DocumentTypes[];
  briefDocuments: BriefDocuments[] = [];

  exportDataToExcel: any[] = [];

  invoiceDetails: InvoiceDetails;
  invoice: Invoice = Object.assign({}, DEFAULT_INVOICE);

  userProfile: UserProfile;
  options: LoadingOverlayModalOptions = Object.assign({}, DEFAULT_LOADINGOVERLAY_OPTIONS);
  isOnLoading = false;
  createInvNoInvDate = false;

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  //#region  Quotation fees
  showFeeTotalExceedQuotedPrompt: boolean;
  quotationFees: QuotationFeeItem[] = [];
  //#endregion Quotation fees

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  @ViewChild(MatterFeesTableComponent) matterFeeComponent: MatterFeesTableComponent;

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.matter, this.workingMatter) ||
      !this.util.objectIsSame(this.fees, this.workingFees);
  } // end isDirty()

  get isHistoricalDirty(): boolean {
    return !this.util.objectIsSame(this.historical, this.workingHistorical) ||
      !this.util.objectIsSame(this.historicalFees.sort(x => x.FeeItemID), this.workingHistoricalFees.sort(x => x.FeeItemID));
  } // end isHistoricalDirty()

  get isSaveable(): boolean {
    return this.isDirty &&
      Boolean(this.workingMatter.ContactID) &&
      !Boolean(this.workingFees.find(s => s.Rate.toString().startsWith('-'))) &&
      !Boolean(this.workingFees.find(s => s.Quantity.toString().startsWith('-'))) &&
      Boolean(this.workingMatter.PlaintiffFullName) &&
      Boolean(this.workingMatter.ClientRefNo) &&
      (Boolean(this.workingFees.length === 0) ? true : !Boolean(this.workingFees.find(fee => fee.FurtherDescription.length === 0)));
  } // end isSaveable()

  get isHistoricalSaveable(): boolean {
    return this.isHistoricalDirty &&
      Boolean(this.workingHistorical.ContactID) &&
      !Boolean(this.workingHistoricalFees.find(s => s.Rate.toString().startsWith('-'))) &&
      Boolean(this.workingHistorical.PlaintiffFullName) &&
      Boolean(this.workingHistorical.ClientRefNo) &&
      Boolean(this.workingHistorical.InvoiceNo) &&
      (Boolean(this.workingHistoricalFees.length === 0) ? true : !Boolean(this.workingHistoricalFees.find(fee => fee.FurtherDescription.length === 0)));
  } // end isHistoricalSaveable()

  // Is Internal Attorney
  get isAttorney(): boolean {
    let attorneyProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      attorneyProfile =
        Boolean(this.userProfileService.selectedUserProfile.profileType ===
          this.profileTypes.ATTORNEY);
    } else {
      attorneyProfile =
        Boolean(this.userProfileService.userProfile.profileType ===
          this.profileTypes.ATTORNEY);
    }
    return attorneyProfile;
  } // end isAttorney()

  // Is Internal Lawfirm
  get isLawfirm(): boolean {
    let lawfirmProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      lawfirmProfile =
        Boolean(this.userProfileService.selectedUserProfile.profileType ===
          this.profileTypes.LAWFIRM);
    } else {
      lawfirmProfile =
        Boolean(this.userProfileService.userProfile.profileType ===
          this.profileTypes.LAWFIRM);
    }
    return lawfirmProfile;
  } // end isLawfirm()

  get isDeletable(): boolean {
    return Boolean(this.matter.ServiceID);
  } // end isDeletable()

  get isHistoricalDeletable(): boolean {
    return Boolean(this.historical.ServiceID);
  } // end isHistoricalDeletable()

  get canAddNote(): boolean {
    return !Boolean(this.workingFees.length === 0) &&
      Boolean(this.workingMatter.ServiceID > 0);
  } // end canAddNote()

  get canAddHistoricalInvoiceNote(): boolean {
    return !Boolean(this.workingHistoricalFees.length === 0) &&
      Boolean(this.workingHistorical.ServiceID > 0);
  } // end canAddHistoricalInvoiceNote()

  get isGeneratable(): boolean {
    return this.hasFeesOnThisMatter &&
      !Boolean(this.isDirty) &&
      !Boolean(this.fees.find(s => s.Rate.toString().startsWith('-'))) &&
      !Boolean(this.fees.find(s => s.FurtherDescription.length === 0)) &&
      Boolean(this.matter.ContactID !== 0) &&
      Boolean(this.matter.PlaintiffFullName !== '') &&
      Boolean(this.matter.ClientRefNo !== '');
  } // end isGeneratable()

  get isHistoricalGeneratable(): boolean {
    return this.hasHistoricalFeesOnThisMatter &&
      !Boolean(this.isHistoricalDirty) &&
      !Boolean(this.historicalFees.find(s => s.Rate.toString().startsWith('-'))) &&
      !Boolean(this.historicalFees.find(s => s.FurtherDescription.length === 0)) &&
      Boolean(this.historical.ContactID !== 0) &&
      Boolean(this.historical.PlaintiffFullName !== '') &&
      Boolean(this.historical.ClientRefNo !== '');
  } // end isHistoricalGeneratable()

  get hasFeesOnThisMatter(): boolean {
    let hasFees = false;

    if (this.workingFees.length) {
      this.workingFees.forEach(fee => {
        if (fee.FeeItemID) {
          hasFees = true;
        }
      });
    }

    return hasFees;
  } // end hasFeesOnThisMatter()

  get hasHistoricalFeesOnThisMatter(): boolean {
    let hasFees = false;

    if (this.workingHistoricalFees.length) {
      this.workingHistoricalFees.forEach(fee => {
        if (fee.FeeItemID) {
          hasFees = true;
        }
      });
    }

    return hasFees;
  } // end hasHistoricalFeesOnThisMatter()

  get quotationTotal(): number {
    let total = 0;
    this.quotationFees.forEach(data => {
      total += data.Total;
    });
    return total;
  } // end quotationTotal()

  get workingFeesTotal(): number {
    let total = 0;
    this.workingFees.forEach(data => {
      total += data.Total;
    });
    return total;
  } // end workingFeesTotal()

  excelURL: string;

  activeScreen: string; // = 'take-on-data'; // Or matter-screen

  arbitrationView: ArbitrationView[] = [];
  arbitrationViewHandle: ArbitrationViewHandle[] = [];

  private _matters: Matter[];
  private _history: Array<any> = [];
  private _initialFeesState: Fee[];
  private _subscriptionNavigationStart: ISubscription;
  private _exitRoute: string;
  private _canIgnoreNavigationBlock: boolean;
  private _isReadyToSave: boolean;
  private _feesHoldingList: Fee[] = [];

  constructor(
    private router: Router,
    private api: ApiService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    private dataService: DataService,
    private listsService: ListsService,
    private navigation: NavigationService,
    private mattersService: MattersService,
    private loadingService: LoadingService,
    private invoiceService: InvoicesService,
    private feeItemsService: FeeItemsService,
    private indexedDbService: IndexedDBService,
    private snackbarsService: SnackbarsService,
    private quotationService: QuotationService,
    private defendantsService: DefendantsService,
    private plaintiffsService: PlaintiffsService,
    private userProfileService: UserProfileService,
    private otherClientService: OtherClientsService,
    private activityLogService: ActivityLogsService,
    private subCategoriesService: SubCategoriesService,
    private invoiceDetailService: InvoiceDetailsService,
    private invoiceDocumentService: InvoiceDocumentService,
    private advocateDetailStatementService: AdvocateDetailStatementService,
  ) {
    document.title = route.snapshot.data.title;
    this.showDeleteMatterModal = false;
    this.showDeleteHistoricalModal = false;
    this.matterDetailsLoading = true;
    this.historicalDataDetailsLoading = true;
    // this.listsService.buildLawFirmsList();
    this.excelURL = this.api.endpoints.auxconReportUtils + '/AuxconReportUtils';
  } // end constructor()

  ngOnInit() {
    this.options.message = 'Opening Matter';
    this.loadingService.showOverlay(this.options);
    this.isOnLoading = true;
    this._isReadyToSave = true;
    // this.lawFirms = JSON.parse(localStorage.getItem(LIST_NAME_LAW_FIRMS));  
    this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData').then((data) => {
      if (data) {
          this.lawFirms = data.data; // Assuming your data is stored under the 'data' key
      } else {
          this.lawFirms = []; // Or any default value you wish to set
      }
  }).catch((error) => {
      console.error('Error retrieving law firms from IndexedDB', error);
      this.lawFirms = []; // Setting a default value in case of an error
  });
    
    this.userProfileService.currentOrRequestedTab = localStorage.getItem('selectedMatterTab');
    if (this.userProfileService.currentOrRequestedTab) {
    this.activeScreen = this.userProfileService.currentOrRequestedTab;
    } else {
      this.activeScreen = 'current-matters';
    }

    this._subscriptionNavigationStart = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart && this.isDirty) {
        // if (event.url)
        this._exitRoute = event.url;
        if (location.href.includes(PATH_MATTERS)) {

          if (this.isDirty && !this._canIgnoreNavigationBlock) {
            this.navigation.canNavigate = false;
            this.showUnsavedChangesPrompt = true;
          } else if (this.isHistoricalDirty && !this._canIgnoreNavigationBlock) {
            this.navigation.canNavigate = false;
            this.showUnsavedChangesPrompt = true;
          } else {
            this.navigation.canNavigate = true;
          }
        }
      }
    });

    const snack: Snack = {
      label: 'Preparing matter...',
      action: null
    };
    
    this.feeItemsService.getDocumentType().subscribe({next: (_doc_type) => {
      this.documentTypes = _doc_type;
      if (this.documentTypes != undefined || this.documentTypes != null) {
        this.documentTypes.sort((a, b) => a.DocumentType.toUpperCase().localeCompare(b.DocumentType.toUpperCase()));
      }
    }});

    this.id = +this.route.snapshot.paramMap.get('id');
    // this.userProfile = JSON.parse(sessionStorage.getItem('userProfile'));

    if (this.id && this.activeScreen === 'take-on-data') {
      //#region  Get historical data
      this.loadingService.showOverlay();
      this.mattersService.getHistoricalById(this.id).subscribe({ next: (_matter) => {
        // On next
        if(_matter) {
          this.historical = _matter;
          if (this.id && this.historical) {
            this.title = this.historical.Description;
            if (!this.historical.HourlyRate) {
              this.historical.HourlyRate =
                this.userProfile.billingDetails.defaultHourlyRate;
            }
            if (!this.historical.DailyRate) {
              this.historical.DailyRate =
                this.userProfile.billingDetails.defaultDailyRate;
            }
            if (!this.historical.PerKilometerRate) {
              this.historical.PerKilometerRate =
                this.userProfile.billingDetails.defaultPerKilometerRate;
            }
        }
        this.workingHistorical = Object.assign({}, this.historical);
      }
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.loadingService.hideOverlay();
      this.historicalDataDetailsLoading = false
    }});
    //#endregion Get historical data
    } else {

    this.mattersService.getMatters().subscribe({
      next:
        (matters) => {
          this._matters = matters;

          if (this.id && this._matters) {
            this.matter = this._matters.find(matter => {
              return matter.ServiceID === this.id;
            });
            if (this.matter) {
              this.title = this.matter.Description;
              if (!this.matter.HourlyRate) {
                this.matter.HourlyRate =
                  this.userProfile.billingDetails.defaultHourlyRate;
              }
              if (!this.matter.DailyRate) {
                this.matter.DailyRate =
                  this.userProfile.billingDetails.defaultDailyRate;
              }
              if (!this.matter.PerKilometerRate) {
                this.matter.PerKilometerRate =
                  this.userProfile.billingDetails.defaultPerKilometerRate;
              }
              this.workingMatter = Object.assign({}, this.matter);
            }
          }

          // Load lists
          // this.lawFirms = JSON.parse(localStorage.getItem(LIST_NAME_LAW_FIRMS));
          // if (this.lawFirms.length === 0) {
          setTimeout(() => {
            // if (localStorage.getItem(LIST_NAME_LAW_FIRMS)) {
            //   this.lawFirms = JSON.parse(localStorage.getItem(LIST_NAME_LAW_FIRMS));
            // }
            this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData').then((data) => {
              if (data) {
                  this.lawFirms = data.data; // Assuming your data is stored under the 'data' key
              } else {
                  this.lawFirms = []; // Or any default value you wish to set
              }
          }).catch((error) => {
              console.error('Error retrieving law firms from IndexedDB', error);
              this.lawFirms = []; // Setting a default value in case of an error
          });
          }, 3000);
          // }
          if (this.id > 0) {
            this.invoiceService.getInvoice(this.id).subscribe(
              invoice => {
                this.invoice = invoice;
              });
            }
          this.plaintiffsService
            .getPlaintiffs()
            .subscribe(plaintiffs => {
              this.plaintiffs = plaintiffs;
              this.plaintiffs.sort((a, b) => {
                return a.FullName.toUpperCase().localeCompare(b.FullName.toUpperCase());
            });
          });
          this.otherClientService
            .getOtherClients()
            .subscribe(otherclients => {
              this.otherClients = otherclients.filter(a => a.Flag === true);
              this.sortOtherClients();
            });
          this.defendantsService
            .getDefendants()
            .subscribe(defendants => {
              defendants.sort((a, b) => {
                return a.FullName.toUpperCase().localeCompare(b.FullName.toUpperCase());
              });
              this.defendants = defendants;
            });

          if (this._matters) {
            this.listsService
              .buildListsFromMatter(this._matters)
              .subscribe(matter => {
                this.attorneys = matter.attorneys;
                this.attorneyRefs = matter.attorneyRefs;
                this.instructions = matter.instructions;
              });
          }
        },
      error: (error) => {
        this.loadingService.hideOverlay();
        // const msg = 'Error loading matters.';
        // snack.type = SnackType.ERROR;
        // this.snackbarsService.dismiss().make(snack, 5000).show();
      },
       complete: async () => {
        // Get Discount
        await lastValueFrom(this.feeItemsService.getDiscount(this.id)).then((discount) => {
          if (discount) {
            this.matter.Discount = discount.Total;
            this.workingMatter.Discount = discount.Total;
          }
        });

        // Get Payment Received data
        if (this.isAttorney || this.isLawfirm) {
          await lastValueFrom(this.feeItemsService.getPaymentReceived(this.id)).then((invoiceLines) => {
            if (invoiceLines) {
              this.matter.PaymentReceived = invoiceLines.Total;
              this.workingMatter.PaymentReceived = invoiceLines.Total;
            }
          });
        }
        this.matterDetailsLoading = false;
        this.workingMatter = Object.assign({}, this.matter);
      }
    });
  }

    if (!this.userProfile) {
      const profile = this.userProfileService.userProfile;
      if (profile.isImpersonator) {
        this.userProfile = this.userProfileService.selectedUserProfile;
      } else {
        this.userProfile = this.userProfileService.userProfile;
      }

    }

    if (this.id) {
      if (this.activeScreen === 'take-on-data') {
        this.mattersService.getHistoricalFeeData(this.id).subscribe({ next: (_data) => {
          // On next
          this.historicalFees = _data;
          this.workingHistoricalFees = this.util.objectCopy(this.historicalFees);
        }});
      } else {
      this.feeItemsService.getFees(this.id).subscribe(fees => {
        this.fees = fees;
        this.fees.sort((a, b) => {
          return a.Date.toUpperCase().localeCompare(b.Date.toUpperCase());
        });
        this.fees.forEach((_fee, index) => {
            _fee.LineCounter = index;
        });
        this._initialFeesState = this.util.objectCopy(this.fees);
        this.fees.sort((a, b) => {
          return a.Date.toUpperCase().localeCompare(b.Date.toUpperCase());
        });
        this.workingFees = this.util.objectCopy(this.fees);
      });
      // let docs = null;
       this.mattersService.getDocumentList(this.id).subscribe({next: (_doc) => {
         if (_doc) {
           this.briefDocuments = _doc;
         }
       }});
      }
      // Get quotation fee if matter was first created as quotation
      //#region Quotation fee get
      // this.quotationService.getServiceQuotationFees(this.id)
      // .subscribe(quotationFees => {
      //   // On next
      //   if (quotationFees) {
      //     this.quotationFees = quotationFees;
      //   }
      // }, error => {
      //   // On error
      // },
      // () => {
      //   // on complete
      // });
      //#endregion Quotation fee get
    } else {

      if (this.activeScreen === 'take-on-data') {
        this.title += 'Capture Historical Data';
        this.historical.ServiceDate =
          new Date().toISOString();
        this.historical.HourlyRate =
          this.userProfile.billingDetails.defaultHourlyRate;
        this.historical.DailyRate =
          this.userProfile.billingDetails.defaultDailyRate;
        this.historical.PerKilometerRate =
          this.userProfile.billingDetails.defaultPerKilometerRate;

        if (this.userProfileService.userProfile.isImpersonator) {
          this.historical.PaymentTerms = +this.userProfileService.selectedUserProfile.settings.aging;
        } else {
          this.historical.PaymentTerms = +this.userProfileService.userProfile.settings.aging;
        }
        this.workingHistorical = Object.assign({}, this.historical);
      } else {
        this.title += 'new matter';
        this.matter.ServiceDate =
          new Date().toISOString();
        this.matter.HourlyRate =
          this.userProfile.billingDetails.defaultHourlyRate;
        this.matter.DailyRate =
          this.userProfile.billingDetails.defaultDailyRate;
        this.matter.PerKilometerRate =
          this.userProfile.billingDetails.defaultPerKilometerRate;

        if (this.userProfileService.userProfile.isImpersonator) {
          this.matter.PaymentTerms = +this.userProfileService.selectedUserProfile.settings.aging;
        } else {
          this.matter.PaymentTerms = +this.userProfileService.userProfile.settings.aging;
        }
        this.workingMatter = Object.assign({}, this.matter);
      }
    }
    
    // Fetch Sub Categories
    this.subCategoriesService.getSubCategoryAll().subscribe({
      next:
        (subCategories) => {          
          this.subCategories = subCategories;
        },
      error: (error) => {
        this.loadingService.hideOverlay();
        const msg = 'Error loading sub categories.';
        console.error(msg, error);
        this.snackbarsService.dismiss().make({ label: msg, action: null, type: SnackType.ERROR }, 5000).show();
      },
      complete: () => {
        this.isOnLoading = false;
        this.loadingService.hideOverlay();
        this.subCategories.sort((a: SubCategory, b: SubCategory) => {
          return a.Category.localeCompare(b.Category) || a.SubCategory.localeCompare(b.SubCategory);
        });
      }
    });
  } // end ngOnInit()

  test(event) {
  }

  sortOtherClients() {
    this.otherClients.sort((a, b) => {
      return a.FullName.toUpperCase().localeCompare(b.FullName.toUpperCase());
    });
  } // end sortOtherClients()

  ngOnDestroy() {
    this._subscriptionNavigationStart.unsubscribe();
  } // end ngOnDestroy()

  closeModal(modal: string) {
    switch (modal) {
      case 'delete-matter-modal':
        this.showDeleteMatterModal = false;
        break;

      case 'delete-historical-modal':
        this.showDeleteHistoricalModal = false;
      break;

      case 'add-invoice-note':
        this.showAddInvoiceNoteModal = false;
      break;
    }
  } // end closeModal()

  onAddInvoiceNote() {
    this.loadingService.showOverlay();

    this.invoiceService.getInvoice(this.id).subscribe({
      next:
        (invoice) => {
          this.invoice = invoice;
        },
      error: (error) => {
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.loadingService.hideOverlay();
        this.showAddInvoiceNoteModal = true;
      }
    });

    // this.invoiceDetailService.getInvoiceDetails(this.workingMatter.InvoiceId)
    // .subscribe(details => {
    //   this.invoiceDetails = details;
    //   if (this.invoiceDetails.InvoiceDate === null) {
    //     const d = new Date();
    //     this.invoiceDetails.InvoiceDate = this.util.parseDate(d.toString());
    //   }
    // },
    // error => {
    //   // On error
    //   this.loadingService.hideOverlay();
    // },
    // () => {
    //   // On complete
    //   this.loadingService.hideOverlay();

    // });
  }

  promptDelete() {
    this.showDeleteMatterModal = true;
  } // end delete()

  promptDeleteHistorical() {
    this.showDeleteHistoricalModal = true;
  } // end promptDeleteHistorical()

  /**
   * Deletes the given matter.
   * @param {MatterHandle} matterHandle The matter handle for the
   * deleteable matter.
   */
  deleteMatter(matterHandle) {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Deleting matter ...',
      action: null
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Delete matter';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Services, Invoices';
    this.activityLog.JsonData = matterHandle;
    this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});

    this.mattersService.deleteMatter(
      matterHandle.matter.ServiceID,
      matterHandle.comments
    ).subscribe({
      next:
        (next) => { },
      error: (error) => {
        const msg = 'Error deleting matter.';
        console.error(msg, error);
        snack.label = msg;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        snack.label = 'Matter deleted.';
        this.snackbarsService.dismiss().make(snack).show();
        this._canIgnoreNavigationBlock = true;
        this.router.navigate([PATH_DASHBOARD]);
        this.loadingService.hideOverlay();
      },
    });
  } // end deleteMatter()

  /**
   * Deletes the given matter.
   * @param {HistoricalData} historicalHandle The matter handle for the
   * deleteable matter.
   */
   deleteHistorical(historicalHandle) {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Deleting matter ...',
      action: null
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Delete matter';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'Services, Invoices';
    this.activityLog.JsonData = historicalHandle;
    this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});

    this.mattersService.deleteMatter(
      historicalHandle.matter.ServiceID,
      historicalHandle.comments
    ).subscribe({
      next:
        (next) => { },
      error: (error) => {
        const msg = 'Error deleting matter.';
        console.error(msg, error);
        snack.label = msg;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        snack.label = 'Matter deleted.';
        this.snackbarsService.dismiss().make(snack).show();
        this._canIgnoreNavigationBlock = true;
        this.router.navigate([PATH_DASHBOARD]);
        this.loadingService.hideOverlay();
      },
    });
  } // end deleteHistorical()

  /**
   * Initiates invoice generation.
   */
  generateInvoice() {
    this.loadingService.showOverlay();
    if(this.matter.IsArbitrationSplit) {
      this.arbitrationView = [];
      this.arbitrationViewHandle = [];
      const state = Object.assign({}, {
        isVisible: true,
        isSelected: false,
        isOpen: false,
        isSaveable: false,
        isFutureDatedErrorVisible: false,
        isLineErrorVisible: false,
        isLoad: true,
        isGenerated: false,
        ErrorMessage: ''
      });
      this.loadingService.hideOverlay();
      this.invoiceService.getArbitrationViewData(this.id).subscribe({  next: (arbitration) => {
        // On next
        if(arbitration) {
          this.arbitrationView = arbitration;         
          arbitration.forEach(arb => {
            this.arbitrationViewHandle.push({
              data: arb,
              state: state
            });
          });             
        }
        },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: async () => {
          this.dataService.data = { arbitrationData: this.arbitrationViewHandle };
          this.router.navigate([PATH_ARBITRATION_INVOICES, this.matter.ServiceID]);
        }
      });
    } else {
      this.createInvNoInvDate = false;
      this.invoiceDocumentService
      .createInvoiceDocument(this.matter.InvoiceId, this.createInvNoInvDate)
      .subscribe({
        next:
          (next) => {
            // On next

          },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On Complete
          // Log activity Login
          const currentDate = new Date();
          this.activityLog.Action = 'Generate Invoice';
          this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
          this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
          if (this.userProfileService.userProfile.isImpersonator) {
            this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
          } else {
            this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
          }
          this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
          this.activityLog.ActionTable = 'Services, Invoices';
          this.activityLog.JsonData = JSON.stringify(this.matter);
          this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});          
            this.router.navigate([PATH_ADVOCATE_INVOICE_PREVIEW, this.matter.InvoiceId]);
            this.loadingService.hideOverlay();
        }
      });
    }
  } // end generateInvoive()

  generateHistoricalInvoice() {
    this.loadingService.showOverlay();
    this.createInvNoInvDate = true;
    this.invoiceDocumentService
      .createInvoiceDocument(this.historical.InvoiceId, this.createInvNoInvDate)
      .subscribe({
        next:
          (next) => {
            // On next

          },
        error: (error) => {
          // On error
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On Complete
          // Log activity Login
          const currentDate = new Date();
          this.activityLog.Action = 'Generate Invoice';
          this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
          this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
          if (this.userProfileService.userProfile.isImpersonator) {
            this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
          } else {
            this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
          }
          this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
          this.activityLog.ActionTable = 'Services, Invoices';
          this.activityLog.JsonData = JSON.stringify(this.matter);
          this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});
          this.router.navigate([PATH_ADVOCATE_INVOICE_PREVIEW, this.historical.InvoiceId]);
          this.loadingService.hideOverlay();
        }
      });
  } // end generateHistoricalInvoice()


  onHideFeeTotalExceedQuotedPrompt() {
    this.showFeeTotalExceedQuotedPrompt = false;
  } // end onHideFeeTotalExceedQuotedPrompt()

  ignoreAndSave() {
    this.showFeeTotalExceedQuotedPrompt = false;
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Saving matter...',
      action: null
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    // Save matter details
    this.workingMatter.Description = this.workingMatter.PlaintiffFullName;
    if (this.workingMatter.DefendantFullName) {
      this.workingMatter.Description += ' // ' + this.workingMatter.DefendantFullName;
    }

    // Save matter for this serviceprovider
    this.workingMatter.ServiceProviderID = this.api.serviceProviderID;
    if (this.workingMatter.ServiceID) {
      // Update the matter
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Update matter';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'Services, Invoices';
      this.activityLog.JsonData = JSON.stringify(this.workingMatter);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});

      this.mattersService
        .updateMatter(this.workingMatter.ServiceID, this.workingMatter)
        .subscribe({
          next: (matter) => {
            // On next
            this.workingMatter = Object.assign(this.workingMatter, matter);
          },
          error: (error) => {
            // On error
            this.loadingService.hideOverlay();
            const msg = 'Matter update failed.';
            console.error(msg, error);
            snack.label = msg;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
          },
          complete: () => {
            // On complete
            this.initWorkingMatter();
            snack.label = 'Matter saved.';
            this.snackbarsService.dismiss().make(snack).show();
            this.saveFeeItems();
          }
        });
    } else {
      // Create a new matter
      // if (!this.matter.ContactID) {
      //   snack.label = 'Law firm required.';
      //   this.snackbarsService.dismiss().make(snack).show();
      //   this.loadingService.hideOverlay();
      // } else if (!this.matter.Description) {
      //   snack.label = 'Plaintiff required . ';
      //   this.snackbarsService.dismiss().make(snack).show();
      //   this.loadingService.hideOverlay();
      // } else if (!this.matter.ClientRefNo) {
      //   snack.label = 'Attorney reference required.';
      //   this.snackbarsService.dismiss().make(snack).show();
      //   this.loadingService.hideOverlay();
      // } else {
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Create new Matter';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'Services, Invoices';
      this.activityLog.JsonData = JSON.stringify(this.workingMatter);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});
      this.mattersService
        .addMatter(this.workingMatter)
        .subscribe({
          next: (matter) => {
            // On next
            this.newMatterId = matter.ServiceID;
            this.workingMatter = this.util.objectCopy(matter);
          },
          error: (error) => {
            // On error
            this.loadingService.hideOverlay();
            const msg = 'Matter creation failed.';
            console.error(msg, error);
            snack.label = msg;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
          },
          complete: () => {
            // On complete
            snack.label = 'Matter created.';
            this.snackbarsService.dismiss().make(snack).show();
            this.saveFeeItems();
          }
        });
      // }
    } // end if (this.matter.ServiceID)
  } // end ignoreAndSave();
  /**
   * Saves the matter details and fees.
   */
  save() {
    // if (this.quotationFees && this.quotationFees.length > 0) {
    //   if (this.workingFeesTotal > this.quotationTotal) {
    //     this.showFeeTotalExceedQuotedPrompt = true;
    //     return;
    //   }
    // }
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Saving matter...',
      action: null
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    // Save matter details
    this.workingMatter.Description = this.workingMatter.PlaintiffFullName;
    if (this.workingMatter.DefendantFullName) {
      this.workingMatter.Description += ' // ' + this.workingMatter.DefendantFullName;
    }

    // Save matter for this serviceprovider
    this.workingMatter.ServiceProviderID = this.api.serviceProviderID;
    if (this.workingMatter.ServiceID) {
      // Update the matter
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Update matter';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'Services, Invoices';
      this.activityLog.JsonData = JSON.stringify(this.workingMatter);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});

      this.mattersService
        .updateMatter(this.workingMatter.ServiceID, this.workingMatter)
        .subscribe({
          next: (matter) => {
            // On next
            this.workingMatter = Object.assign(this.workingMatter, matter);
          },
          error: (error) => {
            // On error
            this.loadingService.hideOverlay();
            const msg = 'Matter update failed.';
            console.error(msg, error);
            snack.label = msg;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
          },
          complete: () => {
            // On complete
            this.initWorkingMatter();
            snack.label = 'Matter saved.';
            this.snackbarsService.dismiss().make(snack).show();
            this.saveFeeItems();
          }
        });
    } else {
      // Create a new matter
      // if (!this.matter.ContactID) {
      //   snack.label = 'Law firm required.';
      //   this.snackbarsService.dismiss().make(snack).show();
      //   this.loadingService.hideOverlay();
      // } else if (!this.matter.Description) {
      //   snack.label = 'Plaintiff required . ';
      //   this.snackbarsService.dismiss().make(snack).show();
      //   this.loadingService.hideOverlay();
      // } else if (!this.matter.ClientRefNo) {
      //   snack.label = 'Attorney reference required.';
      //   this.snackbarsService.dismiss().make(snack).show();
      //   this.loadingService.hideOverlay();
      // } else {
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Create new Matter';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'Services, Invoices';
      this.activityLog.JsonData = JSON.stringify(this.workingMatter);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});
      this.mattersService
        .addMatter(this.workingMatter)
        .subscribe({
          next: (matter) => {
            // On next
            this.newMatterId = matter.ServiceID;
            this.workingMatter = this.util.objectCopy(matter);
          },
          error: (error) => {
            // On error
            this.loadingService.hideOverlay();
            const msg = 'Matter creation failed.';
            console.error(msg, error);
            snack.label = msg;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
          },
          complete: () => {
            // On complete
            snack.label = 'Matter created.';
            this.snackbarsService.dismiss().make(snack).show();
            this.saveFeeItems();
          }
        });
      // }
    } // end if (this.matter.ServiceID)
  } // end save()

  saveHistorical() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Saving matter...',
      action: null
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    // Save matter details
    this.workingHistorical.Description = this.workingHistorical.PlaintiffFullName;
    if (this.workingHistorical.DefendantFullName) {
      this.workingHistorical.Description += ' // ' + this.workingHistorical.DefendantFullName;
    }

    this.workingHistorical.ServiceProviderID = this.api.serviceProviderID;
    if (this.workingHistorical.ServiceID) {
      // Update the matter
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Update Historical Data';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'Services, Invoices';
      this.activityLog.JsonData = JSON.stringify(this.workingHistorical);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});

      this.mattersService
        .updateHistoricalService(this.workingHistorical.ServiceID, this.workingHistorical)
        .subscribe({
          next: (matter) => {
            // On next
            this.workingHistorical = Object.assign(this.workingHistorical, matter);
          },
          error: (error) => {
            // On error
            this.loadingService.hideOverlay();
            const msg = 'Matter update failed.';
            console.error(msg, error);
            snack.label = msg;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
          },
          complete: () => {
            // On complete
            this.initWorkingHistorical();
            snack.label = 'Matter saved.';
            this.snackbarsService.dismiss().make(snack).show();
            this.saveHistoricalFeeItems();
          }
        });
    } else {
      // Create a new matter
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Create new Historical Data';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'Services, Invoices';
      this.activityLog.JsonData = JSON.stringify(this.workingMatter);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});
      this.mattersService
        .addHistoricalData(this.workingHistorical)
        .subscribe({
          next: (matter) => {
            // On next
            this.newMatterId = matter.ServiceID;
            this.workingHistorical = this.util.objectCopy(matter);
          },
          error: (error) => {
            // On error
            this.loadingService.hideOverlay();
            const msg = 'Matter creation failed.';
            console.error(msg, error);
            snack.label = msg;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
          },
          complete: () => {
            // On complete
            snack.label = 'Matter created.';
            this.snackbarsService.dismiss().make(snack).show();
            this.saveHistoricalFeeItems();
          }
        });
      // }
    }
  } // end saveHistorical()

  /**
   * Save fee items associated with the active matter.
   */

  saveFeeItems() {
    // Save fees affiliated with this matter

    this._isReadyToSave = false;
    if (this.workingFees.length > 0) {
      this._canIgnoreNavigationBlock = true;
      this.navigation.canNavigate = true;
      const snack: Snack = {
        label: 'Saving Fees...',
        action: null
      };
      this.snackbarsService.dismiss().make(snack, 5000).show();

      let savedFees = 0;
      const suffix = ' - ' + this.workingMatter.Status;

      this.workingFees.forEach((fee, index) => {
        if (fee.Subject && !fee.Subject.includes(suffix)) {
          fee.Subject += suffix;
        }
        fee.ServiceID = this.workingMatter.ServiceID;
        fee.ServiceProviderID = this.workingMatter.ServiceProviderID;
        fee.MatterStatus = this.workingMatter.Status;
        if (!fee.IsVatable) {
          fee.IsVatable = false; // ensures that this is set to a boolean
        }
      });

      this.workingFees.sort((a, b) => {
        return a.Date.toUpperCase().localeCompare(b.Date.toUpperCase());
      });

      // Add linecounter to each object
      this.workingFees.forEach((fee, index) => {
        fee.LineCounter = index;
        if (fee.Total < 0) {
          fee.LineCounter = index + 99;
        }
      });
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Add or edit fees';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'InvoiceLine';
      this.activityLog.JsonData = JSON.stringify(this.workingFees);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_next => {})})
      this.feeItemsService.updateFees(this.workingFees).subscribe({
        next:
          (savedFees) => {
            // this.workingFees = savedFees;
            this.feeItemsService.getFees(this.id).subscribe(fees => {
              this.fees = fees;
              this.fees.sort((a, b) => {
                return a.Date.toUpperCase().localeCompare(b.Date.toUpperCase());
              });
              this.fees.forEach((_fee, index) => {
                  _fee.LineCounter = index;
              });
              
              this._initialFeesState = this.util.objectCopy(this.fees);
              this.fees.sort((a, b) => {
                return a.Date.toUpperCase().localeCompare(b.Date.toUpperCase());
              });
              this.workingFees = this.util.objectCopy(this.fees);
            });
          },
        error: (error) => {
          const msg = 'Error saving fees.';
          console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        },
        complete: () => {
          this.initWorkingFee();
          this.fees.sort((a, b) => a.Date.toUpperCase().localeCompare(b.Date.toUpperCase()));
          snack.label = 'Matter fees saved.';
          this.snackbarsService.dismiss().make(snack).show();
          if (this.newMatterId) {
            this._canIgnoreNavigationBlock = true;
            this.router.navigate([PATH_MATTERS, this.newMatterId]);
          }
          this.loadingService.hideOverlay();
        }
      });
    } else {
      this.loadingService.hideOverlay();
      if (this.newMatterId) {
        this._canIgnoreNavigationBlock = true;
        this.router.navigate([PATH_MATTERS, this.newMatterId]);
      }
      this._isReadyToSave = true;
    } // end if (this.workingFees)
  } // end saveFeeItems()

  saveHistoricalFeeItems() {
    this._isReadyToSave = false;
    if (this.workingHistoricalFees.length > 0) {
      this._canIgnoreNavigationBlock = true;
      this.navigation.canNavigate = true;
      const snack: Snack = {
        label: 'Saving Fees...',
        action: null
      };
      this.snackbarsService.dismiss().make(snack, 5000).show();

      let savedFees = 0;
      const suffix = ' - ' + this.workingHistorical.Status;

      this.workingHistoricalFees.forEach((fee, index) => {
        if (fee.Subject && !fee.Subject.includes(suffix)) {
          fee.Subject += suffix;
        }
        fee.ServiceID = this.workingHistorical.ServiceID;
        fee.ServiceProviderID = this.workingHistorical.ServiceProviderID;
        fee.MatterStatus = this.workingHistorical.Status;
        if (!fee.IsVatable) {
          fee.IsVatable = false; // ensures that this is set to a boolean
        }
      });
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Add or edit historical fees';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'InvoiceLine';
      this.activityLog.JsonData = JSON.stringify(this.workingHistoricalFees);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (onnext) => {}});
      this.mattersService.updateHistoricalFees(this.workingHistoricalFees).subscribe({
        next:
          (savedFees) => {
            this.workingHistoricalFees = savedFees;
          },
        error: (error) => {
          const msg = 'Error saving fees.';
          console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        },
        complete: () => {
          this.initWorkingHistoricalFee();
          this.fees.sort((a, b) => a.Date.toUpperCase().localeCompare(b.Date.toUpperCase()));
          snack.label = 'Matter fees saved.';
          this.snackbarsService.dismiss().make(snack).show();
          if (this.newMatterId) {
            this._canIgnoreNavigationBlock = true;
            this.router.navigate([PATH_MATTERS, this.newMatterId]);
          }
          this.loadingService.hideOverlay();
        }
      });
    } else {
      this.loadingService.hideOverlay();
      if (this.newMatterId) {
        this._canIgnoreNavigationBlock = true;
        this.router.navigate([PATH_MATTERS, this.newMatterId]);
      }
      this._isReadyToSave = true;
    }
  } // end saveHistoricalFeeItems()

  /**
   * @param {Fee} deletableFee The fee to be deleteted.
   */
  onFeeDelete(deletableFee: Fee) {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Deleting fee...',
      action: null
    };
    this.snackbarsService.make(snack).show();
    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Delete fee';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'InvoiceLine';
    this.activityLog.JsonData = JSON.stringify(deletableFee);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});
    
    this.feeItemsService.deleteFee(deletableFee.FeeItemID).subscribe({
      next:
        (next) => {
          // On next
        },
      error: (error) => {
        const msg = 'Fee deletion failed.';
        console.error(msg, error);
        snack.label = msg;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        snack.label = 'Fee deleted';
        this.initWorkingMatter();
        this.initWorkingFee();
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    });
  } // end onFeeDelete()

  onHistoricalFeeDelete(deletableFee: Fee) {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Deleting fee...',
      action: null
    };
    this.snackbarsService.make(snack).show();
    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Delete fee';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'InvoiceLine';
    this.activityLog.JsonData = JSON.stringify(deletableFee);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (onNext) => {}});
    this.feeItemsService.deleteHistoricalFee(deletableFee.FeeItemID).subscribe({
      next:
        (next) => {
          // On next
        },
      error: (error) => {
        const msg = 'Fee deletion failed.';
        console.error(msg, error);
        snack.label = msg;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        snack.label = 'Fee deleted';
        this.initWorkingHistorical();
        this.initWorkingHistorical();
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    });
  } // end onHistoricalFeeDelete()

  initWorkingFee() {
    this.fees = this.util.objectCopy(this.workingFees.filter(fee => fee.FeeItemID !== 0));
  } // end initWorkingFee()

  initWorkingHistoricalFee() {
    this.historicalFees = this.util.objectCopy(this.workingHistoricalFees.filter(fee => fee.FeeItemID !== 0));
  } // end initWorkingHistoricalFee()

  initWorkingMatter() {
    this.matter = this.util.objectCopy(this.workingMatter);
  } // end initWorkingMatter()

  initWorkingHistorical() {
    this.historical = this.util.objectCopy(this.workingHistorical);
  } // end initWorkingMatter()

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end onHideUnsavedChangesPrompt()

  leavePage() {
    this._canIgnoreNavigationBlock = true;
    this.onHideUnsavedChangesPrompt();
    setTimeout((thisObj) => {
      thisObj.router.navigate([this._exitRoute.replace('/', '')]);
    }, 300, this);
  } // end leavePage()

  /** save for generate invoice
   * This was implemented to allow smooth generation of invoice while saving unsaved changes
   *  */
  //#region Save for generate invoice
  generateSave() {
    this.workingMatter.Description = this.workingMatter.PlaintiffFullName;
    if (this.workingMatter.DefendantFullName) {
      this.workingMatter.Description += ' // ' + this.workingMatter.DefendantFullName;
    }

    // Save matter for this serviceprovider
    this.workingMatter.ServiceProviderID = this.userProfile.serviceProviderID;
    if (this.workingMatter.ServiceID) {
      // Update the matter
      this.mattersService
        .updateMatter(this.workingMatter.ServiceID, this.workingMatter)
        .subscribe({
          next: (matter) => {
            // On next
            this.workingMatter = Object.assign(this.workingMatter, matter);
          },
          error: (error) => {
            // On error
          },
          complete: () => {
            // On complete
            this.GenerateSaveFeeItems();
          }
        });
    } else {
      this.mattersService
        .addMatter(this.workingMatter)
        .subscribe({
          next: (matter) => {
            // On next
            this.newMatterId = matter.ServiceID;
            this.workingMatter = this.util.objectCopy(matter);
          },
          error: (error) => {
            // On error
          },
          complete: () => {
            // On complete
            this.GenerateSaveFeeItems();
          }
        });
      // }
    } // end if (this.matter.ServiceID)
  } // end generateSave()

  GenerateSaveFeeItems() {
    // Save fees affiliated with this matter
    this._isReadyToSave = false;
    if (this.workingFees.length > 0) {
      this._canIgnoreNavigationBlock = true;
      this.navigation.canNavigate = true;

      let savedFees = 0;
      const suffix = ' - ' + this.workingMatter.Status;

      this.workingFees.forEach((fee, index) => {
        if (fee.Subject && !fee.Subject.includes(suffix)) {
          fee.Subject += suffix;
        }
        fee.ServiceID = this.workingMatter.ServiceID;
        fee.ServiceProviderID = this.workingMatter.ServiceProviderID;
        fee.MatterStatus = this.workingMatter.Status;
        if (!fee.IsVatable) {
          fee.IsVatable = false; // ensures that this is set to a boolean
        }
      });

      this.feeItemsService.updateFees(this.workingFees).subscribe({
        next:
          (savedFees) => {
            this.workingFees = savedFees;
          },
        error: (error) => {
        },
        complete: () => {
          this.initWorkingFee();
          this.fees.sort((a, b) => a.Date.toUpperCase().localeCompare(b.Date.toUpperCase()));
          if (this.newMatterId) {
            this._canIgnoreNavigationBlock = true;
            this.router.navigate([PATH_MATTERS, this.newMatterId]);
          }
        }
      });
    } else {
      if (this.newMatterId) {
        this._canIgnoreNavigationBlock = true;
        this.router.navigate([PATH_MATTERS, this.newMatterId]);
      }
      this._isReadyToSave = true;
    } // end if (this.workingFees)
  } // end GenerateSaveFeeItems()

  convertToCurrency(amount: string): string {
    let amountValue = '';
    if (this.userProfileService.userProfile.isImpersonator) {
      amountValue = this.cpipe.transform(amount,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      amountValue = this.cpipe.transform(amount,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
    return amountValue;
  } // end convertToCurrency()

  onGenerateInvoiceLineToExcel() {
    this.exportDataToExcel = [];
    this.loadingService.showOverlay();
    // First this get Invoice report
    this.invoiceService.getDisbmtInvoiceReport(this.workingMatter.InvoiceId).subscribe({
      next: (invoice: Blob) =>  {
        const url = window.URL.createObjectURL(invoice);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Bill of Cost ' + this.workingMatter.Description  + '.xlsx';
        link.click();
        window.URL.revokeObjectURL(url);
      }   ,    
        // },
        error: (error) => {
          console.error('Error downloading the file', error);
          // Handle error (e.g., show user-friendly message)
          this.loadingService.hideOverlay();
        },
        complete: () => {
          this.loadingService.hideOverlay();
        }
        // On next
        // let counter = 1;
        // invoice.forEach(data => {
          // if (data.LineGroup === 'Disbursements:') {
          //   const exportDataModel = {
          //     'DATE': moment(data.LineDate).format('YYYY-MM-DD'),
          //     'ITEM': counter++,
          //     'PARTICULARS': data.LineDescription,
          //     'FEE': '',
          //     'DISB': this.convertToCurrency(data.LineTotal.toString())
          //   };
          //   this.exportDataToExcel.push(exportDataModel);
          // } else {
          //   const exportDataModel = {
          //     'DATE': moment(data.LineDate).format('YYYY-MM-DD'),
          //     'ITEM': counter++,
          //     'PARTICULARS': data.LineDescription,
          //     'FEE': this.convertToCurrency(data.LineTotal.toString()),
          //     'DISB': ''
          //   };
          //   this.exportDataToExcel.push(exportDataModel);
          // }
        // });
        // this.http.post(this.excelURL, this.exportDataToExcel, {
        //   responseType: 'arraybuffer'
        // }).subscribe({
        //   next: (response) => this.advocateDetailStatementService.downloadExcelFile(response,
        //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        //   , error: (error) => {
        //     // On error
        //     this.loadingService.hideOverlay();
        //   },
        //   complete: () => {
        //     // On Complete
        //     this.loadingService.hideOverlay();
        //   }
        // });
      }
    );
  } // end onGenerateInvoiceLineToExcel()
  //#endregion
} // end AdvocateMattersComponent{}
