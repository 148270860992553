<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div class="container-fluid">
  <div class="form">

    <div class="form-group">
      <label for="receipients">Send invoice to</label>
      <input
        type="email"
        class="form-group"
        [ngModelOptions]="{updateOn: 'blur'}"
        [class.has-error]="hasContactEmailError"
        id="receipients"
        placeholder="Receipient email"
        [(ngModel)]="message.Recipients"
        (click)="$event.target.select()">
        <span
          *ngIf="hasContactEmailError"
          class="text-danger">Please enter a valid email address</span>
    </div> <!-- #end .form-group -->

    <div class="form-group">
      <label for="subject">Email subject</label>
      <input
        readonly
        type="text"
        id="subject"
        placeholder="Subject"
        [(ngModel)]="message.Subject">
    </div>

    <div class="form-group">
      <label for="body">Email body</label>
      <iframe
        id="body"
        contenteditable="true"
        sandbox="allow-same-origin, allow-scripts"
        srcdoc="{{messageBodyWithoutStyle}}"
        class="input"></iframe>
    </div>

    <div class="form-group">
      <label for="attachments">Attachments</label>
      <div class="input">
        <a
          [href]="downloadLink"
          target="_blank">Quotation; </a>
          
      </div>
    </div>

  </div> <!-- #end .form -->
  <div class="row" style="display: block;">
    <button
      style="width: 25%; float: right;"
      class="btn btn-success"
      [class.disabled]="!isSaveable"
      [disabled]="!isSaveable"
      (click)="onSendQuotation()">Send Quotation</button>
  </div>
</div> <!-- #end .container-fluid -->