import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TAG_INVOICE_REVIEW_MODAL } from '../../../services/tags/tags.service';
import { SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { InvoiceDetailsService, InvoiceDetails, DEFAULT_INVOICE_DETAILS } from '../../../services/invoice-details/invoice-details.service';
import { InvoiceDocumentService } from '../../../services/invoice-document/invoice-document.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageTemplate } from '../../../services/invoice-message/invoice-message.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UtilitiesService } from '../../../services/utilities.service';
import * as moment from 'moment';

@Component({
  selector: 'app-invoice-review-modal',
  templateUrl: './invoice-review-modal.component.html',
  styleUrls: ['./invoice-review-modal.component.scss']
})
export class InvoiceReviewModalComponent implements OnInit, OnChanges {

  title = 'Generate Invoice';

  @Input() invoice: MessageTemplate;
  @Input() isVisible: boolean;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  invoiceDetails: InvoiceDetails = this.util.objectCopy(DEFAULT_INVOICE_DETAILS);

  private id: number;
  private matterId: number;

  get currencySymbol(): string {
    return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
  } // end currencySymbol()


  constructor(
    private snackbarsService: SnackbarsService,
    private invoiceDetailsService: InvoiceDetailsService,
    private invoiceDocumentService: InvoiceDocumentService,
    private router: Router,
    private route: ActivatedRoute,
    private userProfileService: UserProfileService,
    private util: UtilitiesService
  ) { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.invoice && changes.invoice.currentValue) {
      this.invoiceDetailsService
          .getInvoiceDetails(this.invoice.SourceId)
          .subscribe({
        next: (details) => {
          this.invoiceDetails = details;
        },
        error: (error) => {
          const msg = 'Error loading Invoice Details.';
          console.error(msg, error);
        },
        complete: () => {
          // OnComplete
          // if (this.invoiceDetails &&
          //   this.invoiceDetails.invoi)
        }
      });
    }
  }

  /**
  * Formats the given contact details for safe displaying in the browser.
  * @param {string} details The contact details.
  */
  formatContactDetails(details: string): string {
    return details.replace(/\r/gi, ' ');
  } // end formatContactDetails()

  dismiss() {
    this.closeModal.emit(TAG_INVOICE_REVIEW_MODAL);
  } // end dismiss()

  /**
 * Formats a date string to a format acceptable for input elements of
 * type date.
 * @param {string} date The date string.
 */
  formatDate(date: string): string {
    if (!date) {
      this.invoiceDetails.InvoiceDate = new Date().toJSON();
    }

    return this.invoiceDetails
      .InvoiceDate
      .replace(/T{0,1}[\d:.]+Z{0,1}$/gi, '');
  } // end formatDate()

  /**
   * Called when the value of the date changes.
   * @param {*} event The date change event.
   */
  onDateChange(invoiceDetail: InvoiceDetails, value: Date): void {
    invoiceDetail.InvoiceDate = value ? value.toDateString() : '';
    if (value) {
      this.invoiceDetails.InvoiceDate = moment(value).format();
    }
  } // end onDateChange()

  /**
   * Parses a date into a display 'safe' format.
   * @param {string} date The date to be parse.
   */
  parseDate(date: string) {
    return this.util.parseDate(date);
  } // end parseDate()

} // end InvoiceReviewModalComponent{}
