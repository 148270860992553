import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-billing',
  templateUrl: './subscription-billing.component.html',
  styleUrls: ['./subscription-billing.component.scss']
})
export class SubscriptionBillingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
