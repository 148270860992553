import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ICrumb } from '../../services/advocate-detail-statement/advocate-detail-statement.service';
import { Message, DEFAULT_MESSAGE_SEND, MessagesService } from '../../services/messaging/messages/messages.service';
import { UtilitiesService } from '../../services/utilities.service';
import { ApiService } from '../../services/api.service';
import { Router, ActivatedRoute, NavigationStart, Event } from '@angular/router';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { QuotationService, SendQuotation, DEFAULT_SEND_QUOTATION } from '../../services/quotation/quotation.service';
import { PATH_DASHBOARD } from '../../services/appdata/app-config.service';

@Component({
  selector: 'app-advocate-quotation-email',
  templateUrl: './advocate-quotation-email.component.html',
  styleUrls: ['./advocate-quotation-email.component.scss']
})
export class AdvocateQuotationEmailComponent implements OnInit {

  crumbs: ICrumb[];
  selectedQuotationId: number;
  sendQuotation: SendQuotation = Object.assign({}, DEFAULT_SEND_QUOTATION);
  message: Message = Object.assign({}, DEFAULT_MESSAGE_SEND);
  downloadLink = this.api.apiURL + '/InvoiceDocument/Quotation?quotationId=';

  get hasContactEmailError(): boolean {
    return !this.util.areValidEmails(this.message.Recipients);
  } // end hasContactEmailError()

  get messageBodyWithoutStyle(): string {
    // this.message.Body  = '';
    if (this.message && this.message.Body) {
      this.message.Body = this.message.Body.replace('Your Mailing', '');
    
    return this.message.Body.substring(0, this.message.Body.indexOf('<style '))
      + this.message.Body.substring(this.message.Body.indexOf('</style>'));
    }
  } // end messageBodyWithoutStyle()

  get isSaveable(): boolean {
    return this.util.areValidEmails(this.message.Recipients);
  } // end isSaveable()

  @ViewChild('body') body: ElementRef;
  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private messagesService: MessagesService,
    private quotationService: QuotationService,
  ) { } // end constructor()

  ngOnInit() {
    this.crumbs = [
      {
      label: 'Send quotation via emal'
      }
    ];
    this.selectedQuotationId = +this.route.snapshot.paramMap.get('id');

    this.loadingService.showOverlay();
    this.downloadLink += this.selectedQuotationId;

    this.messagesService.getQuotationMessage(this.selectedQuotationId, 'Quotation').subscribe({ next: 
      message => {
        // On next
        this.message = message;
      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
      }
    });
  } // end ngOnInit()

  onSendQuotation() {
    this.loadingService.showOverlay();
    this.sendQuotation = Object.assign({}, DEFAULT_SEND_QUOTATION);
    const email = this.message.Recipients.split(';');
    this.sendQuotation.MessageId = this.message.ID;
    this.sendQuotation.Subject = this.message.Subject;
    this.sendQuotation.MessageBody = this.message.Body;
    this.sendQuotation.Email = this.message.Recipients;
    this.sendQuotation.EmailType = 'Quotation';
    this.quotationService.postSendQuotation(this.sendQuotation).subscribe({ next: (quote) => {
      // On next
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.router.navigate(['/' + PATH_DASHBOARD]);
      this.loadingService.hideOverlay();
    }
  });
  } // end onSendQuotation()

} // end AdvocateQuotationEmailCompoent{}
