import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { ProcessPaymentService, ProcessedTransactions } from '../../../services/process-payment/process-payment.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { DEFAULT_ACTIVITY_LOGS, ActivityLogs, ActivityLogsService } from '../../../services/activity-logs/activity-logs.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

interface ProcessTransactionHandle {
    data: ProcessedTransactions;
    state: {
        isVisible: boolean;
        isSelected: boolean;
        isOpen: boolean;
    };
}

@Component({
    selector: 'app-processed-bank-transactions',
    templateUrl: './processed-bank-transactions.component.html',
    styleUrls: ['./processed-bank-transactions.component.scss']
})
export class ProcessedBankTransactionsComponent implements OnInit {

    bankTransactions: any = [];
    showTransactions = true;
    processedTransactionsHandle: ProcessTransactionHandle[];
    searchFromTransactionsHandle: ProcessTransactionHandle[];
    selectAll: boolean;
    showActions = false;
    row = 0;
    selectedTab = 'invoice';
    sortDate: boolean;
    sortDescription: boolean;
    sortAmount: boolean;
    sortAccountNumber: boolean;
    sortBy: string;
    sortAscending: boolean;
    previousSortBy: string;

    showProofOfPaymentDoc = false;
    popId: number;
    profile: any;
    currentIndex: any = 0;
    displayTotalCount: number;
    currentCount = 0;
    takeRecords: any = 500;
    searchItems = new Subject<string>();
    searchTerm: string = '';
    isAllSelected: boolean = false;
    selectedrows: any = [];
    activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);
    isToggleSelectAll: boolean = false;
    @Output() promptInvoicesEvent = new EventEmitter<any>();
    @Output() refreshPage = new EventEmitter<string>();
    @Input() onShow: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('inputCheckSelectAll') inputCheckSelectAll: ElementRef;

    constructor(
        private loadingService: LoadingService,
        private snackbarsService: SnackbarsService,
        private userProfileService: UserProfileService,
        private activityLogService: ActivityLogsService,
        private processPaymentService: ProcessPaymentService,
    ) {
    }

    get currencySymbol(): string {
        if (this.userProfileService.userProfile.isImpersonator) {
            return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
        } else {
            return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
        }
    } // end currencySymbol()
    ngOnInit() {
        this.profile = this.userProfileService.userProfile;
        this.onShow.subscribe((response) => {
            if (response) {
                this.getProcessedBankTransactions();
                this.promptInvoicesEvent.emit();
                this.row = 0;
            }
        });
        this.loadingService.showOverlay();
        this.getProcessedBankTransactions();

        this.searchItems
            .pipe(
                debounceTime(1000),
                distinctUntilChanged()
            )
            .subscribe((value) => {
                this.bankTransactions = [];
                this.currentIndex = 0;
                this.currentCount = 0;
                if (this.selectedTab == 'invoice')
                    this.getProcessedBankTransactions();
                else if (this.selectedTab == 'person')
                    this.getPersonalBankTransactions();
                else if (this.selectedTab == 'exclude')
                    this.getExcludedBankTransactions();
                else if (this.selectedTab == 'refered-to-client')
                    this.getReferredBankTransactions();

            });

    } // end ngOnInit()

    getProcessedBankTransactions(isFirstTimeLoad: boolean = false) {
        if (isFirstTimeLoad) {
            this.bankTransactions = [];
            this.currentIndex = 0;
            this.currentCount = 0;
            this.displayTotalCount = 0;
        }
        this.loadingService.showOverlay();
        this.processPaymentService.getProcessedBankTransactions((this.currentIndex * this.takeRecords), this.takeRecords, this.searchTerm).subscribe({
            next: (_p_transaction) => {
                this.displayTotalCount = _p_transaction.TotalCount;
                if (this.displayTotalCount <= this.takeRecords)
                    this.currentCount = this.displayTotalCount;

                if (_p_transaction.BankTransactions) {
                    _p_transaction.BankTransactions.forEach(element => {
                        this.bankTransactions.push(element);
                        if (this.isToggleSelectAll)
                            this.selectedrows.push(element.Id);
                        else
                            this.selectedrows = [];
                    });
                }
            },
            error: (error) => {
                // On error
                this.loadingService.hideOverlay();
            },
            complete: () => {
                this.selectedTab = 'invoice';
                // this.showTransactions = this.displayTotalCount > 0 ? true : false;
                this.loadingService.hideOverlay();
            }
        });
    } // end getProcessedBankTransactions()

    getPersonalBankTransactions(isFirstTimeLoad: boolean = false) {
        if (isFirstTimeLoad) {
            this.bankTransactions = [];
            this.currentIndex = 0;
            this.currentCount = 0;
            this.displayTotalCount = 0;
        }
        this.loadingService.showOverlay();
        this.processPaymentService.getPersonalBankTransactions((this.currentIndex * this.takeRecords), this.takeRecords, this.searchTerm).subscribe({
            next: (_p_transaction) => {
                this.displayTotalCount = _p_transaction.TotalCount;
                if (this.displayTotalCount <= this.takeRecords)
                    this.currentCount = this.displayTotalCount;

                if (_p_transaction.BankTransactions) {
                    _p_transaction.BankTransactions.forEach(element => {
                        this.bankTransactions.push(element);
                        if (this.isToggleSelectAll)
                            this.selectedrows.push(element.Id);
                        else
                            this.selectedrows = [];
                    });
                }
            },
            error: (error) => {
                // On error
                this.loadingService.hideOverlay();
            },
            complete: () => {
                this.selectedTab = 'person';
                this.loadingService.hideOverlay();
            }
        });
    } // end getPersonalBankTransactions()

    getExcludedBankTransactions(isFirstTimeLoad: boolean = false) {
        debugger;
        if (isFirstTimeLoad) {
            this.bankTransactions = [];
            this.currentIndex = 0;
            this.currentCount = 0;
            this.displayTotalCount = 0;
        }
        this.loadingService.showOverlay();
        this.processPaymentService.getExcludedBankTransactions((this.currentIndex * this.takeRecords), this.takeRecords, this.searchTerm).subscribe({
            next: (_p_transaction) => {
                this.displayTotalCount = _p_transaction.TotalCount;
                if (this.displayTotalCount <= this.takeRecords)
                    this.currentCount = this.displayTotalCount;

                // console.log(_p_transaction);
                if (_p_transaction.BankTransactions) {
                    _p_transaction.BankTransactions.forEach(element => {
                        this.bankTransactions.push(element);
                        if (this.isToggleSelectAll)
                            this.selectedrows.push(element.Id);
                        else
                            this.selectedrows = [];
                    });
                    // console.log(this.bankTransactions);
                }
            },
            error: (error) => {
                // on error
                this.loadingService.hideOverlay();
            },
            complete: () => {
                this.selectedTab = 'exclude';
                this.loadingService.hideOverlay();
            }
        });
    } // end getExcludedBankTransactions()

    getReferredBankTransactions(isFirstTimeLoad: boolean = false) {
        if (isFirstTimeLoad) {
            this.bankTransactions = [];
            this.currentIndex = 0;
            this.currentCount = 0;
            this.displayTotalCount = 0;
        }
        this.loadingService.showOverlay();
        this.processPaymentService.getReferredBankTransactions((this.currentIndex * this.takeRecords), this.takeRecords, this.searchTerm).subscribe({
            next: (_p_transaction) => {
                this.displayTotalCount = _p_transaction.TotalCount;
                if (this.displayTotalCount <= this.takeRecords)
                    this.currentCount = this.displayTotalCount;

                if (_p_transaction.BankTransactions) {
                    _p_transaction.BankTransactions.forEach(element => {
                        this.bankTransactions.push(element);
                        if (this.isToggleSelectAll)
                            this.selectedrows.push(element.Id);
                        else
                            this.selectedrows = [];
                    });
                }
            },
            error: (error) => {
                // On error
                this.loadingService.hideOverlay();
            },
            complete: () => {
                this.selectedTab = 'refered-to-client';
                this.loadingService.hideOverlay();
            }
        });
    } // end getReferredBankTransactions()

    viewProofOfPayment(value: any) {
        if (value && value.HasAttachment) {
            this.popId = value.Id;
            this.showProofOfPaymentDoc = true;
        }
    } // end viewProofOfPayment()

    closeModal() {
        // this.popId = 0;
        this.showProofOfPaymentDoc = false;
    } // end closeModal()

    toggleSort(sortBy: string) {
        this.sortBy = sortBy;
        if (this.sortBy === this.previousSortBy) {
            this.sortAscending = !this.sortAscending;
        }
        switch (this.sortBy) {
            case 'date':
                this.bankTransactions.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortAccountNumber = false;
                        this.sortDate = true;
                        this.sortDescription = false;
                        this.sortAmount = false;
                        return a.Date.toUpperCase().localeCompare(b.Date.toUpperCase());
                    }
                    this.sortDate = false;
                    return b.Date.toUpperCase().localeCompare(a.Date.toUpperCase());
                });
                break;

            case 'account-number':
                this.bankTransactions.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortAccountNumber = true;
                        this.sortDate = false;
                        this.sortDescription = false;
                        this.sortAmount = false;
                        return a.Date.toUpperCase().localeCompare(b.Date.toUpperCase());
                    }
                    this.sortAccountNumber = false;
                    return b.Date.toUpperCase().localeCompare(a.Date.toUpperCase());
                });
                break;

            case 'description':
                this.bankTransactions.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortAccountNumber = false;
                        this.sortDate = false;
                        this.sortDescription = true;
                        this.sortAmount = false;
                        return a.TransactionDescription.toUpperCase().localeCompare(b.TransactionDescription.toUpperCase());
                    }
                    this.sortDescription = false;
                    return b.TransactionDescription.toUpperCase().localeCompare(a.TransactionDescription.toUpperCase());
                });
                break;

            case 'amount':
                this.bankTransactions.sort((a, b) => {
                    if (this.sortAscending) {
                        this.sortAccountNumber = false;
                        this.sortDate = false;
                        this.sortDescription = false;
                        this.sortAmount = true;
                        return a.Amount - b.Amount;
                    }
                    this.sortAmount = false;
                    return b.Amount - a.Amount;
                });
                break;
        }
        this.previousSortBy = this.sortBy;
    } // end toggleSort()

    togglePanel(value: string) {
        this.searchTerm = '';
        this.selectedrows = [];
        switch (value) {
            case 'invoice':
                this.selectedTab = value;
                this.processPaymentService.selectedprocessedTab = value;
                this.getProcessedBankTransactions(true);
                break;

            case 'person':
                this.selectedTab = value;
                this.processPaymentService.selectedprocessedTab = value;
                this.getPersonalBankTransactions(true);
                break;

            case 'exclude':
                this.selectedTab = value;
                this.processPaymentService.selectedprocessedTab = value;
                this.getExcludedBankTransactions(true);
                break;

            case 'refered-to-client':
                this.selectedTab = value;
                this.processPaymentService.selectedprocessedTab = value;
                this.getReferredBankTransactions(true);
                break;
        }
    } // end togglePanel()

    toggleSelectAll() {
        this.isAllSelected = this.inputCheckSelectAll.nativeElement.checked;
        this.isToggleSelectAll = this.isToggleSelectAll ? false : true;
        if (this.isToggleSelectAll)
            this.bankTransactions.forEach(element => { this.selectedrows.push(element.Id); });
        else
            this.selectedrows = [];
    } // end toggleSelectAll()

    toggleCheckbox(transaction: any) {
        if (this.selectedrows.includes(transaction.Id)) {
            const selectedServicesIndex: number = this.selectedrows.indexOf(transaction.Id);
            if (selectedServicesIndex !== -1)
                this.selectedrows.splice(selectedServicesIndex, 1);
        }
        else
            this.selectedrows.push(transaction.Id)
    } // end toggleCheckbox()

    allocatedToLawFirm(transaction: any): boolean {
        if (transaction && transaction.BankTransactionLines) {
            return Boolean(transaction.BankTransactionLines
                .filter(s => s.AssignedToLawFirm === true).length > 0);
        } else {
            return false;
        }
    } // end allocatedToLawfirm()

    onShowAllocation(allocatedTo: string) {

    } // end onShowAllocation()

    deleteAllocateToLawfirm(line: number) {
        this.processPaymentService.removeTransactionLines(line).subscribe({ next: (_delete) => { } });
    } // end deleteAllocateToLawfirm()

    onRemoveProcessedTransaction() {

        if (this.selectedTab === 'person') {
            this.loadingService.showOverlay();
            // Log activity Login
            const currentDate = new Date();
            this.activityLog.Action = 'Remove personal Bank transaction from proccessed';
            this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
            this.activityLog.LoggedApp = 'Web Application (Proccessed-bank-transaction)';
            if (this.userProfileService.userProfile.isImpersonator) {
                this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
            } else {
                this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
            }
            this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
            this.activityLog.ActionTable = 'Transactions';
            this.activityLog.JsonData = JSON.stringify(this.bankTransactions.filter(s => this.selectedrows));
            this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_activity) => { } });

            for (const trans of this.selectedrows) {
                this.processPaymentService.deleteTransactionLines(trans).subscribe({
                    next: (deleted) => {
                        // on next
                    },
                    error: (error) => {
                        // On error
                        this.loadingService.hideOverlay();
                    },
                    complete: () => {
                        // on Complete
                    }
                });
                this.processPaymentService.deleteProcessedBankTransactions(trans).subscribe({ next: (_delete) => { } });
            }
            this.refreshPage.emit('succes');
        } else if (this.selectedTab === 'refered-to-client') {
            this.loadingService.showOverlay();
            // this.processedTransactionsHandle.forEach(trans => {

            // Log activity Login
            const currentDate = new Date();
            this.activityLog.Action = 'Remove refered to a client Bank transaction';
            this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
            this.activityLog.LoggedApp = 'Web Application (Proccessed-bank-transaction)';
            if (this.userProfileService.userProfile.isImpersonator) {
                this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
            } else {
                this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
            }
            this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
            this.activityLog.ActionTable = 'Transactions';
            this.activityLog.JsonData = JSON.stringify(this.bankTransactions.filter(s => this.selectedrows));
            this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_activity) => { } });
            for (const trans of this.selectedrows) {
                this.processPaymentService.deleteTransactionLines(trans).subscribe({
                    next: (deleted) => {
                        // on next
                    },
                    error: (error) => {
                        // On error
                        this.loadingService.hideOverlay();
                    },
                    complete: () => {
                        // on Complete
                        this.loadingService.hideOverlay();
                    }
                });
                this.processPaymentService.deleteProcessedBankTransactions(trans).subscribe({ next: (_delete) => { } });
            }
            this.refreshPage.emit('succes');
        } else {
            // if (this.showActions) {
                const snack: Snack = {
                    label: 'Remove bank transaction',
                    action: null
                };
                this.loadingService.showOverlay();
                this.snackbarsService.make(snack).show();

                // Log activity Login
                const currentDate = new Date();
                this.activityLog.Action = 'Remove Bank transaction from proccessed';
                this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
                this.activityLog.LoggedApp = 'Web Application (Proccessed-bank-transaction)';
                if (this.userProfileService.userProfile.isImpersonator) {
                    this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
                    this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
                } else {
                    this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
                    this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
                }
                this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
                this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
                this.activityLog.ActionTable = 'Transactions';
                this.activityLog.JsonData = JSON.stringify(this.bankTransactions.filter(s => this.selectedrows));
                this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_activity) => { } });

                this.selectedrows.forEach(trans => {
                    const bankTransaction = this.bankTransactions.filter(x => x.Id === trans)[0];
                    if (bankTransaction.BankTransactionLines && bankTransaction.BankTransactionLines.length > 0) {
                        bankTransaction.BankTransactionLines.forEach(line => {
                            if (line.AssignedToLawFirm) {
                                this.deleteAllocateToLawfirm(line.Id);
                            }
                        });
                    }
                    this.processPaymentService.deleteProcessedBankTransactions(trans).subscribe({
                        next: (deleted) => {
                            // On next
                        },
                        error: (error) => {
                            if (error !== undefined || error !== null || error !== '')
                                snack.label = error;
                            else
                                // On error
                                snack.label = 'Something went wrong';
                            snack.action = null;
                            snack.type = SnackType.ERROR;
                            this.snackbarsService.dismiss().make(snack).show();
                            this.loadingService.hideOverlay();
                        },
                        complete: () => {
                            // On complete
                            snack.label = 'Transactions successfuly removed';
                            snack.action = null;
                            snack.type = SnackType.SUCCESS;
                            this.snackbarsService.dismiss().make(snack).show();
                            // this.loadingService.hideOverlay();
                            this.refreshPage.emit('succes');
                        }
                    });
                });
            // }
        }
    } // end onRemoveProcessedTransaction()

    getDateFormet(value: Date): string {
        return moment(value).format('DD/MM/YYYY');
    }

    numberConversion(value: number): string {
        return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    getAllocatedColor(value: string): string {
        if (value === 'None') {
            return '#cf624f';
        } else if (value === 'Fully') {
            return '#8abd37';
        } else if (value === 'Partial') {
            return '#e5c100';
        }
    } // end getAllocatedColor()

    onRowClick(val: any) {
        this.row = 0;
        if (val.Id !== 0) {
            this.row = val.Id;
            this.promptInvoicesEvent.emit(val);
        }
    } // end onRowClick()

    LoadMoreData() {
        this.currentCount = (this.currentIndex + 2) * this.takeRecords
        this.currentIndex = this.currentIndex + 1;
        if (this.selectedTab == 'invoice')
            this.getProcessedBankTransactions();
        else if (this.selectedTab == 'person')
            this.getPersonalBankTransactions();
        else if (this.selectedTab == 'exclude')
            this.getExcludedBankTransactions();
        else if (this.selectedTab == 'refered-to-client')
            this.getReferredBankTransactions();
    }
} // end ProcessedBankTransactionsComponent{}

