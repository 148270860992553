import {
    Component,
    OnInit,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    SimpleChanges
} from '@angular/core';

// import { TypeaheadMatch } from 'ngx-bootstrap';
import { ProcessPaymentService } from '../../../services/process-payment/process-payment.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { ContactsService } from '../../../services/contacts/contacts.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

const MIN_COMMENT_LENGTH = 5;



@Component({
    selector: 'app-reconcile-bank-allocate-model',
    templateUrl: './reconcile-bank-allocate-model.component.html',
    styleUrls: ['./reconcile-bank-allocate-model.component.scss']
})
export class ReconcileBankAllocateModelComponent implements OnInit, OnChanges {
    @Input() isVisible: boolean;

    @Input() title: any;
    @Input() type: string;

    @Output() closeModal: EventEmitter<string> = new EventEmitter();
    @Output() confirmAllocated: EventEmitter<any> = new EventEmitter();

    message: string;
    comments: string;
    confirmText: string;
    isConfirm: boolean;
    exceedsMaxCommentLength = false;

    lawFirmName: string;
    lawFirmId: number;
    AllSelectedFirm: any = [];
    SelectedFirm: any = [];
    // lawFirms: any = [];
    allLawFirms: any = [];

    @Input() lawFirms: any[];
    categories: any;
    Category = '';

    constructor(
        private processPaymentService: ProcessPaymentService,
        private loadingService: LoadingService,
        private contactService: ContactsService
        ) {

    } // end constructor()

    ngOnInit() {
        this.categories = [
            { label: 'The invoice is less than the payment amount.',  value: 'The invoice is less than the payment amount.' },
            { label: 'Reference incomplete.',  value: 'Reference incomplete.' },
            { label: 'Invoice already paid.',  value: 'Invoice already paid.' },
            { label: 'No reference used.',  value: 'No reference used.' },
            { label: 'Incorrect reference.',  value: 'Incorrect reference.' }
        ];
    } // end ngOnInit()

    ngOnChanges(changes: SimpleChanges) {

        switch (this.type) {
            case 'Exclude':
                this.message = 'Reason for Exclusion';
                this.confirmText = 'PROCESS EXCLUSIONS';
                break;
            case 'LawFirm':
                this.Category = '';
                this.message = 'Law Firm';
                this.confirmText = 'ALLOCATE TO LAW FIRM';
                // this.getLawFirms();
                break;
            case 'ReferToClient':
                this.message = 'Comments';
                this.confirmText = 'REFER TRANSACTIONS';
            break;
        }

    } // end ngOnChange()

    validate() {
        let isOk = true;
        if (this.comments.length < MIN_COMMENT_LENGTH) {
            isOk = false;
        }
        this.isConfirm = isOk;
    } // end validate(){}

    dismiss() {
        this.comments = '';
        this.lawFirmName = '';
        this.lawFirmId = null;
        this.lawFirms = [];
        this.validate();
        this.closeModal.emit('close');
    } // end dismiss()

    confirm() {
        this.confirmAllocated.emit({
            type: this.type,
            Reason: this.comments,
            lawFirmId: this.lawFirmId,
            lawFirm: this.lawFirmName,
            Category: this.Category
        });
        this.dismiss();
    } // end confirmDelete



    // getLawFirms() {
    //     this.loadingService.showOverlay();
    //      this.contactService.getLawFirmsByServiceProvider().subscribe(lawfirms => {
    //         this.lawFirms = lawfirms;
    //     },
    //     error => {
    //         // on Error
    //         this.loadingService.hideOverlay();
    //     },
    //     () => {
    //         // On complete
    //         this.loadingService.hideOverlay();
    //     });
    // }

    onInput(event) {
        if (event.inputType === 'deleteContentBackward') {
            this.SelectedFirm = this.lawFirms;
            this.AllSelectedFirm = this.allLawFirms;
            this.isConfirm = false;
        }
    }

    onChangeLawFirm(event: TypeaheadMatch): void {
        this.lawFirmName = event.item.FullName;
        this.lawFirmId = event.item.ContactID;
        this.isConfirm = true;
    }
} // end DeleteMatterModalComponent{}
