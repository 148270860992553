import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { retry, catchError } from 'rxjs/operators';

export interface FromCalendarItem {
  CalendarItemID: number;
  Subject: string;
  Date: string;
  FromDate: string;
  ToDate: string;
  RateType: string;
  SubCategoryID: number;
  ServiceID: number;
  Rate: number;
  Total: number;
  Discount: number;
  Quantity: number;
  IsVatable: boolean;
  FurtherDescription: string;
  ServiceProviderID: number;
  TimerValues?: {
    StartDate: string;
    EndDate: string;
  };
  CanEdit: boolean;
  ShowInList: boolean;
  MatterStatus: string;
  isAuxconCalendarEntry: boolean;
  OutlookCalendarEventId: string;
  isOutlookCalendarError: boolean;
} // end FromCalendarItem{}


export interface OutlookCalendarMatter {
  EventId: string;
  Description: string;
  StartTime: string;
  EndTime: string;
  FromDate: string;
  ToDate: string;
  SubCategoryID: number;
  RateType: string,
  ServiceProviderID: number,
  Total: number,
  MatterStatus: string,
  ServiceID: number,
  FurtherDescription: string;
  Rate: string;
  Discount: string;
  Vatable: boolean;
  LawFirm: string;
  Attorneys: string;
  ClientContactID: string;
  PlaintiffContactID: string;
  Palintiffss: string;
  DefendantContactID: string;
  Defendants: string;
  ContactID: string;
  PerKmRate: string;
  Term: string;
  AttorneyReference: string;
  Instructions: string;
  CaseNumber: string;
  isExistingMatter: boolean;
}

export const DEFAULT_CALENDAR_ITEM: FromCalendarItem = {
  CalendarItemID: 0,
  Subject: '',
  Date: '',
  FromDate: '',
  ToDate: '',
  RateType: 'Hourly',
  SubCategoryID: 0,
  ServiceID: 0,
  Rate: 0,
  // Rate: sessionStorage.getItem('userProfile') && JSON.parse(sessionStorage.getItem('userProfile')).billingDetails
  //   ? JSON.parse(sessionStorage.getItem('userProfile')).billingDetails.defaultHourlyRate
  //   : 0,
  Total: 0,
  Discount: 0,
  Quantity: 1,
  IsVatable: false,
  FurtherDescription: '',
  ServiceProviderID: 0,
  TimerValues: {
    StartDate: '',
    EndDate: ''
  },
  CanEdit: true,
  ShowInList: false,
  MatterStatus: '',
  isAuxconCalendarEntry: false,
  OutlookCalendarEventId: '',
  isOutlookCalendarError: false
};

@Injectable()
export class FromCalendarItemsService {

  fromCalendarItems: FromCalendarItem[];

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) {
  } // end constructor()

  /**
   * GET: returns FromCalendarItem data by Service Provider.
   * @returns {Observable<*>} Returns a collection of Calendar Items for a
   * given Service Provider (ID). Depending on the UX for this rendered data,
   * this could appear in a Calendar, Fee Detail (of a Service), or Task
   */
  getFromCalendarItems(): Observable<any> {
    return this.http.get(this.api.endpoints.fromCalendarItems +
      '?ServiceProviderID=' + this.api.serviceProviderID, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getFromCalendarItems()

  getFromCalendarItemsByDate(startMonth: number, startYear: number, endMonth: number, endYear: number, timeZoneId: string): Observable<any> {
    return this.http.get(this.api.endpoints.fromCalendarItems +
      '/GetCalendarItemsByDate?ServiceProviderID=' + this.api.serviceProviderID +
      '&startMonth=' + startMonth + '&startYear=' + startYear +
      '&endMonth=' + endMonth + '&endYear=' + endYear + '&timeZoneId=' + timeZoneId, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getFromCalendarItemsByDate()

  deleteOutlookEvent(OutlookCalendarEventId: string) {
    return this.http.delete(this.api.endpoints.fromCalendarItems +
      '/DeleteOutlookEvent?OutlookCalendarEventId=' + OutlookCalendarEventId, this.api.httpOptions);
  }

  /**
   * PUT: update FromCalendarItem data.
   * This object contains data for the CalendarItem being captured.
   * This data could originate in a Calendar, Fee Detail (of a Service),
   * or Task.
   * @param {FromCalendarItem} fromCalendarItem The FromCalendarItem object.
   * @returns {*} Returns an observable containing the updated object.
   */
  updateFromCalendarItem(
    fromCalendarItem: FromCalendarItem
  ): Observable<any> {
    return this.http.put(this.api.endpoints.fromCalendarItems +
      '?actionId=' + fromCalendarItem.CalendarItemID,
      fromCalendarItem, this.api.httpOptions).pipe(
        // retry(3),
        catchError(this.api.handleError)
      );
  } // end updateFromCalendarItem()

  /**
   * POST: insert new FromCalendarItem data.
   * This object contains data for the FromCalendarItem being captured
   * This data could originate in a Calendar, Fee Detail (of a Service), or Task
   * @param {FromCalendarItem} fromCalendarItem The new FromCalendarItem object.
   */
  createFromCalendarItem(fromCalendarItem: FromCalendarItem): Observable<any> {
    fromCalendarItem.ServiceProviderID = this.api.serviceProviderID;
    fromCalendarItem.TimerValues = null;
    return this.http.post(this.api.endpoints.fromCalendarItems,
      fromCalendarItem, this.api.httpOptions).pipe(
        // retry(3),
        catchError(this.api.handleError)
      );
  } // end create()

  /**
   * DELETE: delete FromCalendarItem data.
   * This object contains data for the FromCalendarItem being captured
   * This will delete calendar item and related data (e.g. invoice lines)
   * @param {number} calendarItemID The unique identifier of the CalendarItemID.
   */
  deleteFromCalendarItem(calendarItemID: number, timeZoneId : string, ServiceProviderID: number): Observable<any> {
    return this.http.delete(this.api.endpoints.fromCalendarItems +
      '?id=' + calendarItemID + "&timeZoneId=" + timeZoneId + "&contactID=" + ServiceProviderID, this.api.httpOptions);
  } // end deleteFromCalendarItem()


  saveNewMatter(outlookCalendarMatter: any): Observable<any> {
    return this.http.post(this.api.endpoints.fromCalendarItems + "/SaveNewMatter", outlookCalendarMatter, this.api.httpOptions);
  }

  private channel = new BroadcastChannel('IsAuthSet');
  sendAuthCode(isSet: any) {
    this.channel.postMessage(isSet);
  }

  getAuthCode(callback: (isSet: any) => void) {
    this.channel.onmessage = (event) => {
      callback(event.data);
    };
  }

  generateAccessToken(code: string, scopes: string): Observable<any> {
    return this.http.get(this.api.endpoints.outlookgenearteAccessToken + "/GenerateAccessToken?ContactID=" + this.api.serviceProviderID + "&scopes=" + scopes + "&code=" + code)
  }

  getContactGraphPermission(): Observable<any> {
    return this.http.get(this.api.endpoints.outlookgenearteAccessToken + "/GetContactGraphPermission?ContactID=" + this.api.serviceProviderID)
  }

  onDeleteContactGraphPermission(): Observable<any> {
    return this.http.delete(this.api.endpoints.outlookgenearteAccessToken + '/DeleteContactGraphPermission?ContactID=' + this.api.serviceProviderID, this.api.httpOptions);
  }

  checkCalendarPermission(): Observable<any> {
    return this.http.get(this.api.endpoints.outlookgenearteAccessToken + "/CheckCalendarPermission?ContactID=" + this.api.serviceProviderID)
  }

} // end FromCalendarItemsService{}

