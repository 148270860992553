<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <div class="row">
          <h2>{{title}}</h2>
          <button class="modal-dismiss" (click)="dismiss()">
            <i class="fa fa-close"></i>
          </button>
        </div> <!-- #end .row -->
      </div> <!--#end .modal-header -->

      <div class="modal-body form">
        <div class="container-fluid">
          <div class="row">
            <ul class="modal-header-list">
              <li *ngFor="let item of matterDescription">{{item.Description}}</li>
            </ul>
          </div> <!-- #end .row -->
        <!-- Comments -->
        <div class="row">
          <div class="form-group">
            <label for="comments">Comments</label>
            <textarea
              type="text"
              [id]="comments"
              [(ngModel)]="comments"
              placeholder="Reason for deleting the matter"
              (keyup)="validate()"></textarea>
              <p
                class="comment-length-indicator"
                [class.text-danger]="exceedsMaxCommentLength">{{numberOfCharactersLeft}}</p>
          </div>
          </div> <!-- #end .row -->
        </div> <!-- #end .container-fluid -->
      </div> <!--#end .modal-body -->

      <div class="modal-footer">
        <button
          class="btn btn-danger"
          [class.disabled]="!isDeleteable"
          (click)="confirm()"
          [disabled]="!isDeleteable">Delete matters</button>
      </div>
      <!--#end .modal-footer -->

    </div>
    <!--#end .modal-dialog -->
  </div>
  <!--#end .modal-content -->
</div>
<!--#end .modal -->