<div class="panel-area">
  <div class="row">
    <div class="form-group col-md-3">
      <label for="name">Advocate name<span title="Required field"> *</span></label>
      <input 
        *ngIf="!internalAssistant" 
        type="text" 
        id="name" 
        class="input" 
        placeholder="Name"
        [(ngModel)]="workingDetails.AdvocateFirstName"
        [ngClass]="{'invalid-input': !workingDetails.AdvocateFirstName}">

      <input 
        *ngIf="internalAssistant" 
        readonly 
        type="text" 
        id="name" 
        class="input" 
        placeholder="Name"
        [(ngModel)]="workingDetails.AdvocateFirstName">
    </div>
    <div class="form-group col-md-3">
      <label for="surname">Surname<span title="Required field"> *</span></label>
      <input 
        *ngIf="!internalAssistant" 
        type="text" 
        class="input" 
        id="surname" 
        placeholder="Surname"
        [(ngModel)]="workingDetails.AdvocateLastName"
        [ngClass]="{'invalid-input': !workingDetails.AdvocateLastName}">
      <input 
        *ngIf="internalAssistant" 
        readonly 
        type="text" 
        class="input" 
        id="surname" 
        placeholder="Surname"
        [(ngModel)]="workingDetails.AdvocateLastName">
    </div>
    <div class="form-group col-md-3">
      <label for="Id-number">ID number<span title="Required field"> *</span></label>
      <input 
        *ngIf="!internalAssistant" 
        type="text" 
        id="Id-number" 
        class="input" 
        placeholder="ID number"
        [(ngModel)]="workingDetails.AdvocateIdentityNumber"
        [ngClass]="{'invalid-input': !workingDetails.AdvocateIdentityNumber}">
      <input 
        *ngIf="internalAssistant" 
        readonly 
        type="text" 
        id="Id-number" 
        class="input" 
        placeholder="ID number"
        [(ngModel)]="workingDetails.AdvocateIdentityNumber">
    </div>
    <div class="form-group col-md-3">
      <label>Gender</label>
        <div class="input-group">
          <select name="gender" id="gender" class="input-date" [(ngModel)]="workingDetails.Gender"
            (change)="onChangeGender($event)">
              <option value="" selected="selected">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
          </select>
        </div>
    </div>    
  </div>

  <div class="row">
    <div class="form-group col-md-3">
      <label>Ethnicity</label>
        <div class="input-group">
          <select name="ethnicity" id="ethnicity" class="input-date" [(ngModel)]="workingDetails.Ethnicity"
            (change)="onChangeEthnicity($event)">
              <option value="" selected="selected">Select Ethnic Group</option>
              <option value="African">African</option>
              <option value="White">White</option>
              <option value="Coloured">Coloured</option>
              <option value="Indian / Asian">Indian / Asian</option>
              <option value="Other">Other</option>
          </select>
        </div>
    </div>
    <div class="form-group col-md-3">
      <div class="row">
        <div class="form-group col-md-4 p-0">
          <div class="form-group">
            <label>Admission Day<span title="Required field"> *</span></label>
            <div class="input-group">
              <select name="admissionDay" id="admissionDay" class="input-date"
                [(ngModel)]="workingDetails.AdmissionDay" (change)="onChangeAdmissionDay($event)"  [disabled]="details.AdmissionDay > 0">
                <option value="0" selected="selected">Select Day</option>
                <option *ngFor="let day of admissionDay" value="{{day}}">{{day}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="form-group">
            <label>Month<span title="Required field"> *</span></label>
            <div class="input-group">
              <select name="fromMonth" id="fromMonth" class="input-date" [(ngModel)]="workingDetails.AdmissionMonth"
              (change)="onChangeAdmissionMonth($event)" [disabled]="details.AdmissionMonth > 0">
                <option value="0" selected="selected">Select Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              <span *ngIf="isValidAdmissionMonth" class="text-danger">Select month</span>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="form-group">
            <label>Year<span title="Required field"> *</span></label>
            <div class="input-group">
              <select name="admissionYear" id="admissionYear" class="input-date"
                [(ngModel)]="workingDetails.AdmissionYear" (change)="onChangeAdmissionYear($event)" [disabled]="details.AdmissionYear > 0">
                <option value="0" selected="selected">Select Year</option>
                <option *ngFor="let year of admissionYear" value="{{year.year}}">{{year.year}}</option>
              </select>
              <span *ngIf="isValidAdmissionYear" class="text-danger">Select year</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group col-md-3 radio-group">
      <label for="seniority">Seniority<span title="Required field"> *</span></label>
      <div class="radio junior">
        <label class="junior">
          <input type="radio" name="junior" (click)="toggleJunior()"
            [checked]="workingDetails.Seniority === 'Junior'">Junior</label>&nbsp;&nbsp;
        <label>
          <input type="radio" name="senior" (click)="toggleSenior()"
            [checked]="workingDetails.Seniority === 'Senior'">Senior</label>
      </div>
    </div>
    <div class="form-group col-md-3">
      <label for="speciality" style="padding-right: 0px;">Speciality</label>
      <select
        class="form-control"
        id="speciality"
        [title]="workingDetails.Speciality"
        [(ngModel)]="workingDetails.Speciality">
        <option [value]="null">Choose a speciality</option>
        <option
          *ngFor="let speciality of specialities"
          [value]="speciality.value">{{speciality.label}}</option>
      </select>
    </div>    
  </div>
</div>

<div class="panel-area">
  <div class="row">
    <div class="form-group col-md-3">
      <div class="form-group">
        <label for="practice-type">Practice Type</label>
        <select *ngIf="!internalAssistant" id="practice-type" [(ngModel)]="workingDetails.PracticeType">
          <option value="" disabled selected hidden>Select Practice type</option>
          <option *ngFor="let practice of practiceType" [value]="practice.value"
            [selected]="practice.value === workingDetails.PracticeType">{{practice.label}}</option>
        </select>
        <input *ngIf="internalAssistant" readonly type="text" id="Id-number" class="input" placeholder="ID number"
          [(ngModel)]="workingDetails.PracticeType">
      </div>
    </div>
    <div class="form-group col-md-3">
      <div class="form-group">
        <label for="involvement-in-pracice">Involvement in practice</label>
        <select *ngIf="!internalAssistant" id="involvement-in-practice"
          [(ngModel)]="workingDetails.InvolvementInPractice">
          <option value="" disabled selected hidden>Select Involvement in practice</option>
          <option *ngFor="let involve of practiceInvolvement" [value]="involve.value"
            [selected]="involve.value === workingDetails.InvolvementInPractice">{{involve.label}}</option>
        </select>
        <input *ngIf="internalAssistant" readonly type="text" id="Id-number" class="input" placeholder="ID number"
          [(ngModel)]="workingDetails.InvolvementInPractice">
      </div>
    </div>
    <div class="form-group col-md-3">
      <div class="form-group">
        <label for="pre-advocacy">Pre-Advocacy</label>
        <select *ngIf="!internalAssistant" id="pre-advocacy" [(ngModel)]="workingDetails.PreAdvocacy">
          <option value="" disabled selected hidden>Select Pre-Advocate</option>
          <option *ngFor="let adv of preAdvocacy" [value]="adv.value"
            [selected]="adv.value === workingDetails.PreAdvocacy">{{adv.label}}</option>
        </select>
        <input *ngIf="internalAssistant" readonly type="text" id="Id-number" class="input" placeholder="ID number"
          [(ngModel)]="workingDetails.PreAdvocacy">
      </div>
    </div>
    <div class="form-group col-md-3">
      <label for="pre-advocacy-details">Pre-advocacy details</label>
      <input *ngIf="!internalAssistant" type="text" id="pre-advocacy-details" class="input"
        placeholder="Pre-advocacy details" [(ngModel)]="workingDetails.PreAdvocacyDetails">
      <input *ngIf="internalAssistant" readonly type="text" id="pre-advocacy-details" class="input"
        placeholder="Pre-advocacy details" [(ngModel)]="workingDetails.PreAdvocacyDetails">
    </div>
  </div>

  <div class="row" *ngIf="!internalAssistant && isPracticeManager">      
    <!-- <div class="col-md-3"></div> -->
    <div class="form-group">
      <label for="personality-type">Personality Type</label>
      <input *ngIf="!internalAssistant" type="text" id="personality-type" class="input" placeholder="Personality Type"
        [(ngModel)]="workingDetails.PersonalityType">
      <input *ngIf="internalAssistant" readonly type="text" id="personality-type" class="input"
        placeholder="Personality Type" [(ngModel)]="workingDetails.PersonalityType">
    </div>
    <div class="form-group">
      <label for="relationship-status">Relationship status</label>
      <select *ngIf="!internalAssistant" id="relationship-status" [(ngModel)]="workingDetails.RelationshipStatus">
        <option value="" disabled selected hidden>Select Relationship status</option>
        <option *ngFor="let relationship of relationshipStatus" [value]="relationship.value"
          [selected]="relationship.value === workingDetails.RelationshipStatus">{{relationship.label}}</option>
      </select>
      <input *ngIf="internalAssistant" readonly type="text" id="personality-type" class="input"
        placeholder="Personality Type" [(ngModel)]="workingDetails.RelationshipStatus">
    </div>
    <div class="form-group">
      <label for="partner-name">Partner name</label>
      <input *ngIf="!internalAssistant" type="text" id="partner-name" class="input" placeholder="Partner name"
        [(ngModel)]="workingDetails.PartnerName">
      <input *ngIf="internalAssistant" readonly type="text" id="partner-name" class="input" placeholder="Partner name"
        [(ngModel)]="workingDetails.PartnerName">
    </div>
    <div class="form-group">
      <label for="number-of-dependants">Number of dependants</label>
      <input *ngIf="!internalAssistant" type="text" id="number-of-dependants" class="input"
        [(ngModel)]="workingDetails.NumberOfDependants">
      <input *ngIf="internalAssistant" readonly type="text" id="number-of-dependants" class="input"
        [(ngModel)]="workingDetails.NumberOfDependants">
    </div>
  </div>
</div>

<div class="panel-area" *ngIf="!internalAssistant && isPracticeManager" >
  <div class="row">
    <div class="table-responsive">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="col" class="w30">Business Relationship Name</th>
            <th scope="col" class="w28">Type</th>
            <th scope="col" class="w20">Contact Number</th>
            <th scope="col" class="w20">Contact Email</th>
            <th scope="col" class="w2"></th>
          </tr>
          <tr class="tr" *ngFor="let contacts of contactRelationship">
            <td scope="row" class="w30">{{contacts.FullName}}</td>
            <td scope="row" class="w28">{{contacts.Relationship}}</td>
            <td scope="row" class="w20">{{contacts.CellPhone}}</td>
            <td scope="row" class="w20">{{contacts.Email}}</td>
            <td scope="row" class="w2">
              <i *ngIf="!internalAssistant" style="width: 10px;" class="fas fa-trash clickable"
                (click)="onRelationshipDelete(contacts)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="form-group">
      <label for="business-relationship-name">Business Relationship Name</label>
      <input
        type="text"
        id="business-relationship-name"
        class="input"
        placeholder="Business Relationship Name"
        [(ngModel)]="details.AdvocateFirstName">
    </div>
      <div class="form-group">
        <label for="type">Type</label>
        <select
          id="type"
          [(ngModel)]="">
          <option
            *ngFor="let type of businessType"
            [value]="type.value"
            [selected]="type.value === " disabled selected hidden>Select Type</option>
        </select>
      </div>
      <div class="form-group">
        <label for="contact-number">Contact Number</label>
        <input
          type="text"
          id="contact-number"
          class="input"
          placeholder="Contact Number"
          [(ngModel)]="details.AdvocateFirstName">
      </div>
      <div class="form-group">
        <label for="contact-email">Contact Email</label>
        <input
          type="text"
          id="contact-email"
          class="input"
          placeholder="Contact Email"
          [(ngModel)]="details.AdvocateFirstName">
      </div>
      <div class="form-group trash">
        <label></label>
        <i style="width: 10px;" class="fas fa-trash"></i>
      </div>-->
  </div>
  <div class="row" *ngIf="!internalAssistant">
    <span class="column-add-relationship">
      <span class="clickable" (click)="onAddBusinessRelationship()">
        <i class="fa fa-plus-circle auxcon-green"></i>
        <u class="auxcon-green">Add a business relationship</u>
      </span>
    </span>
  </div>
</div>

<!-- <button
  *ngIf="!internalAssistant"
  class="btn btn-success save-advocate-details"
  (click)="onSaveAdvocateDetails()">SAVE ADVOCATE DETAILS</button> -->

<app-add-relation-modal [isVisible]="showAddRelationModal" [advocates]="advocates"
  (cancel)="onDismissAddRelationModal()" (saveContact)="onSaveAddRelation($event)"></app-add-relation-modal>

<app-prompt-unsaved-changes-modal title="Remove Business Relationship"
  message="You are about to delete business relationship. Do you want to proceed?"
  [isVisible]="showBusinessRelationshipRemovePrompt" (cancel)="onHideBusinessRelationshipRemovePrompt()"
  (continue)="onBusinessRelationshipRemove()" btnTextCancel="Cancel" btnTextContinue="Proceed">
</app-prompt-unsaved-changes-modal>