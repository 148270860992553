import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  Category,
  DEFAULT_CATEGORY
} from '../../../services/categories/categories.service';
import {
  DEFAULT_SUB_CATEGORY,
  SubCategory
} from '../../../services/sub-categories/sub-categories.service';
import {
  FeeTemplate,
  DEFAULT_FEE_TEMPLATE,
  FeeTemplateService
} from '../../../services/fee-template/fee-template.service';
import { Snack, SnackbarsService, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { element } from 'protractor';

const MIN_COMMENT_LENGTH = 1;
const MAX_COMMENT_LENGTH = 3000;

export interface CategoryHandle {
  data: any;
  state: {
    isActive: boolean;
    isSelected: boolean;
    isVisible: boolean;
  };
}

@Component({
  selector: 'app-datalist-category',
  templateUrl: './datalist-category.component.html',
  styleUrls: ['./datalist-category.component.scss']
})
export class DatalistCategoryComponent implements OnInit, OnChanges {

  @Input() type: string;
  @Input() category: Category | SubCategory;
  @Input() value: string;
  @Input() list: Array<Category | SubCategory>;
  @Output() categoryChange: EventEmitter<Category | SubCategory>
              = new EventEmitter<Category | SubCategory>();
  @Output() input: EventEmitter<any> = new EventEmitter<any>();

  types: any = {
    category: 'category',
    subCategory: 'sub-category'
  };
  categoryList: CategoryHandle[] = [];
  isActive: boolean;
  iconClass: string;
  showFeeTemplateModal = false;
  activeFeeTemplate: FeeTemplate;
  numberOfCharactersLeft = 750;
  /*
    The displayValue renders based on the
    following cascading logic:
    - If a value is provided, that value is used in the input
    */
  displayValue: string;

  private timeoutId;
  private blurDebounceTime = 300;
  exceedsMaxCommentLength = false;
  private feeTemplateDescription: string;

  constructor(
    private feeTemplateService: FeeTemplateService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private utilityService: UtilitiesService
  ) { } // end constructor()

  ngOnInit() {
    if (this.value) {
      this.displayValue = this.value;
      this.validate();
    }
  } // end ngOnInit()

  get isValidText(): Boolean {
    // return Boolean(this.displayValue.length >= 4);
    // if (this.displayValue) {
    return !this.utilityService.hasTextValue(this.displayValue);
    // } else {
    //   return true;
    // }
  }

  onKeydown(event) {
    this.value = event.target.value;
    this.validate();
  }

  validate() {
    let isOk = true;
    this.numberOfCharactersLeft =
      (this.value) ? MAX_COMMENT_LENGTH - this.value.length : MAX_COMMENT_LENGTH;

    if (this.value.length < MIN_COMMENT_LENGTH) {
      isOk = false;
    }

    if (this.value.length > MAX_COMMENT_LENGTH) {
      this.exceedsMaxCommentLength = true;
      isOk = false;
    } else {
      this.exceedsMaxCommentLength = false;
    }

    // this.isDeleteable = isOk;
  } // end validate(){}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.type && !changes.type.currentValue) {
      this.type = this.types.category;
    }

    if (changes.category && !changes.category.currentValue) {
      if (this.type) {
        if (this.type.localeCompare('category') === 0) {
          this.category = Object.assign({}, DEFAULT_CATEGORY);
        }

        if (this.type.localeCompare('sub-category') === 0) {
          this.category = Object.assign({}, DEFAULT_SUB_CATEGORY);
          const cat = this.category as SubCategory;
          this.filterCategoryList(cat.SubCategory);
        }

        if (!this.value && this.category) {
          if (this.type === this.types.subCategory) {
            this.displayValue = this.category.SubCategory;
          } else {
            this.displayValue = this.category.Category;
          }
        }
      }
    }

    if (changes.value && changes.value.currentValue) {
      this.validate();
      this.displayValue = this.value;
    }

    if (changes.list && changes.list.currentValue) {
      this.validate();
      this.buildCategoryList();
    }
  } // end ngOnChanges()

  onBlur() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.hideList();
    }, this.blurDebounceTime);
  } // end onBlur()

  onFocus() {
    this.showList();
  } // end onFocus()

  onInput(event) {
    this.feeTemplateDescription = event.target.value;
    this.filterCategoryList(this.feeTemplateDescription);
    this.input.emit(event);
  } // end onInput()

  onSelect(category: CategoryHandle) {
    this.category = Object.assign({}, category.data);
    this.categoryChange.emit(this.category);
  } // end onSelect()

  toggleDropdown() {
    this.isActive = !this.isActive;
  } // end toggleDropdown()

  hideList() {
    this.isActive = false;
  } // end hideList()

  showList() {
    this.isActive = true;
  } // end showList()

  /**
   * Opens the edit fee template modal
   */
  createNewFeeTemplate() {
    this.activeFeeTemplate = Object.assign({}, DEFAULT_FEE_TEMPLATE);
    this.activeFeeTemplate.SubCategory = this.feeTemplateDescription;
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activeFeeTemplate.UserName =
        this.userProfileService.selectedUserProfile.auxconUserName;
    } else {
      this.activeFeeTemplate.UserName =
        this.userProfileService.userProfile.auxconUserName;
    }
    this.showFeeTemplateModal = true;
  } // end createNewFeeTemplate()


  onCloseModal() {
    this.showFeeTemplateModal = false;
  } // end onCloseModal()

  onSaveFeeTemplate(newFeeTemplate: FeeTemplate) {
    const snack: Snack = {
      label: 'Saving new fee template...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack).show();

    this.feeTemplateService.createFeeTemplate(newFeeTemplate).subscribe({
      next: (feeTemplate) => {
        newFeeTemplate.SubCategoryId = feeTemplate.Id;
      },
      error: (error) => {
        const msg = 'Error saving new fee template.';
        console.error(msg, error);
        snack.label = msg;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
      },
      complete: () => {
        snack.label = 'Fee template created.';
        snack.type = null;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        const categoryHandle: CategoryHandle = {
          data: newFeeTemplate,
          state: {
            isActive: false,
            isSelected: false,
            isVisible: true
          }
        };

        this.list.push(newFeeTemplate);
        this.buildCategoryList();
        this.filterCategoryList(newFeeTemplate.SubCategory);
        this.onSelect(categoryHandle);
      }
    });


    //   feeTemplate => {
    //     newFeeTemplate.SubCategoryId = feeTemplate.Id;
    //   },
    //   error => {
    //     const msg = 'Error saving new fee template.';
    //     console.error(msg, error);
    //     snack.label = msg;
    //     snack.type = SnackType.ERROR;
    //     this.snackbarsService.dismiss().make(snack, 5000).show();
    //   },
    //   () => {
    //     snack.label = 'Fee template created.';
    //     snack.type = null;
    //     this.snackbarsService.dismiss().make(snack, 5000).show();
    //     const categoryHandle: CategoryHandle = {
    //       data: newFeeTemplate,
    //       state: {
    //         isActive: false,
    //         isSelected: false,
    //         isVisible: true
    //       }
    //     };

    //     this.list.push(newFeeTemplate);
    //     this.buildCategoryList();
    //     this.filterCategoryList(newFeeTemplate.SubCategory);
    //     this.onSelect(categoryHandle);
    //   }
    // );
  } // end onSaveFeeTemplate()

  private buildCategoryList() {
    this.categoryList = [];

    this.list.map(item => {
      this.categoryList.push({
        data: item,
        state: {
          isActive: false,
          isSelected: false,
          isVisible: true
        }
      });
    });
  } // end buildCategoryList()

  private filterCategoryList(searchTerm: string) {
    searchTerm = this.utilityService.regexEscape(searchTerm);
    if (this.category && this.categoryList) {
      this.categoryList.forEach(item => {
        item.state.isVisible = this.type === this.types.subCategory
          ? Boolean(
              item.data.Category.match(
                new RegExp(searchTerm, 'gi')
              )
            ) ||
            Boolean(
              item.data.SubCategory.match(
                new RegExp(searchTerm, 'gi')
              )
            ) ||
            Boolean(
              item.data.RateType.match(
                new RegExp(searchTerm, 'gi')
              )
            )
          : Boolean(
              item.data.Category.match(
                new RegExp(searchTerm, 'gi')
              )
            );
      });
    }
  } // end filterCategoryList()
} // end DatalistCategoryComponent{}
