import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Router } from '@angular/router';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { AdvocateDetailStatementService } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { ReportsService } from '../../../services/reports/reports.service';
import { PATH_ADVOCATE_DETAIL } from '../../../services/appdata/app-config.service';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-law-firm-all-customers',
  templateUrl: './law-firm-all-customers.component.html',
  styleUrls: ['./law-firm-all-customers.component.scss']
})
export class LawFirmAllCustomersComponent implements OnInit {

  apiData: any = [];
  tabData: any = [];
  tooltipData: any = [];
  showTooltipOverlay = false;
  totalOutstanding = 0;
  @Input() activeTab: string;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onRowClick = new EventEmitter<string>();

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()
  constructor(
    private router: Router,
    private api: ApiService,
    private loadingService: LoadingService,
    private reportsService: ReportsService,
    private userProfileService: UserProfileService,
    private advocateDetailStatementService: AdvocateDetailStatementService,
  ) { } // end constructor()

  ngOnInit() {
    this.getApiData();
  } // end ngOnInit()

  getApiData(): void {
    this.loadingService.showOverlay();
    this.advocateDetailStatementService.getAllAdvocatesData().subscribe({
      next: (response) => {
      // On next
      if (response) {
        this.apiData = response; // .sort((a, b) => b.InvoiceDate.toUpperCase().localeCompare(a.InvoiceDate.toUpperCase()));
      }
    },
    error: (error) => {
      // On Error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On Complete
      this.loadingService.hideOverlay();
      this.switchTab();
    }
  });
  } // end getApiData()

  onTabClick(tab: string) {
    this.activeTab = tab;
    this.switchTab();
  } // end onTabClick()

  switchTab(): void {

    this.tabData = [];
    switch (this.activeTab) {
      case 'all':
        this.filterTabData('AllTotals');
        break;
      case 'current':
        this.filterTabData('CurrentTotals');
        break;
      case '30':
        this.filterTabData('ThirtyDaysTotals');
        break;
      case '60':
        this.filterTabData('SixtyDaysTotals');
        break;
      case '90':
        this.filterTabData('NinetyDaysTotals');
        break;
      case '98':
        this.filterTabData('NinetyEightDaysTotals');
        break;
    }
  } // end switchTab()

  filterTabData(key: string): void {
    if (this.tabData) {
      this.tabData = this.apiData[key].sort((a, b) => b.Outstanding - a.Outstanding);
      this.totalOutstanding = 0;
      for (const item of this.tabData) {
        this.totalOutstanding += item.Outstanding;
      }
    }
  } // end filterTabData()

  getBarWidth(value: number): string {
    let width = '0.95';
    if (value >= 1) {
      width = (value * 0.95).toString();
    }
    return width + '%';
  } // end getBarWidth()

  onHover(i: number, activeTab: string): void {
    let type = 0;
    if (activeTab === 'all') {
      type = null;
    } else if (activeTab === 'current') {
      type = +0;
    } else if (activeTab === '30') {
      type = +30;
    } else if (activeTab === '60') {
      type = +60;
    } else if (activeTab === '90') {
      type = +90;
    } else if (activeTab === '98') {
      type = +98;
    }
    this.showTooltipOverlay = true;
    this.tooltipData = [];
    this.advocateDetailStatementService.getByIndividualCustomer(i).subscribe({
      next: (response) => {
      if (response) {
        if (type !== null) {
          response = response.filter(a => a.due > 0 && a.daysOverdue === type);
        } else {
          response = response.filter(a => a.due > 0);
        }
        response.sort((a, b) => {
          return a.invoiceDate.toUpperCase().localeCompare(b.invoiceDate.toUpperCase());
        });
        if (response.length > 5) {
          this.tooltipData = response.slice(0, 5);
        } else {
          this.tooltipData = response;
        }
      }
    },
      error: (error) => {
        // Error
      },
      complete: () => {
        this.showTooltipOverlay = false;
      }
    });

  }

  getBackgroundColor(type: string): string {
    if (type.toLowerCase()  === 'all') {
      return '#646464';
    } else if (type.toLowerCase()  === '0') {
      return '#8abd37';
    } else if (type.toLowerCase()  === '30') {
      return '#e5c100';
    } else if (type.toLowerCase()  === '60') {
      return '#eb9e02';
    } else if (type.toLowerCase()  === '90') {
      return '#ff8000';
    } else if (type.toLowerCase()  === '98') {
      return '#cf624f';
    }
  } // end getBackgroundColor()

  getBottomRow(index: number, length: number): string {
    if (length > 0 && index === length - 1) {
      return '20px';
    }
    return '0px';
  } // end getBottomRow()

  onViewCustomerReport(debtorID: string) {
    // this.reportsService.individualCustomerFrom = 'all-customer-report';
    // this.reportsService.individualCustomerActiveTab = this.activeTab;
    // this.router.navigate([PATH_AGE_ANALYSIS_INDIVIDUAL_CUSTOMER + '/' + debtorID]);
    // this.onRowClick.emit(debtorID);
    this.router.navigate([PATH_ADVOCATE_DETAIL, this.api.serviceProviderID, debtorID]);
  }

} // end LawFirmAllCustomersComponent{}
