
import { map, catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';

export interface ClientContactLawfirm {
  lawfirm: number;
}

export interface AccountPlateformBankTransactionLog {
  PendingLog: string;
  MsgLogs: TransactionAttemptLogMsgDto[];
}

export const DEFAULT_ACCOUNTING_TRANSACTION_LOG: AccountPlateformBankTransactionLog = {
  PendingLog: '',
  MsgLogs: []
}

export const DEFAULT_TransactionAttemptLogMsgDto: TransactionAttemptLogMsgDto = {
  Attempt: 0,
  QueuedLogMsg: '',
  InProcessLogMsg: '',
  ErrorLogMsg: '',
  SuccessLogMsg: '',
  ErrorMessage: ''
}

export interface TransactionAttemptLogMsgDto {
  Attempt: number;
  QueuedLogMsg: string;
  InProcessLogMsg: string;
  ErrorLogMsg: string;
  SuccessLogMsg: string;
  ErrorMessage: string;
}

export interface AccountPlateformBankTransactions {
  Id: number;
  FullName: string;
  AccountNumber: string;
  TransactionDate: string;
  Description: string;
  TransactionStatus: string;
  TransactionType: string;
  Amount: string;
  Debit: string;
  ContactId: number;
  Credit: string;
  SysncDate: string;
  Processed: string;
  SyncDate: string;
  TransactionLogs: any[]
}

export const DEFAULT_ACCOUNTING_TRANSACTION_LIST: AccountPlateformBankTransactions = {
  Id: 0,
  FullName: '',
  AccountNumber: '',
  TransactionDate: '',
  SyncDate: '',
  Description: '',
  TransactionStatus: '',
  TransactionType: '',
  Amount: '',
  Debit: '',
  ContactId: 0,
  Credit: '',
  Processed: '',
  SysncDate: '',
  TransactionLogs: []
}

export const DEFAULT_ACCOUNTING_TRANSACTION_FILTER: AdvocateAccountPlateformBankTransactionFilter = {
  DateFilter: '',
  DescriptionOrAccountNumberFilter: '',
  StatusFilter: '',
  PageSize: 50,
  SkipRecords: 0
}

export interface AdvocateAccountPlateformBankTransactionFilter {
  DateFilter: string;
  DescriptionOrAccountNumberFilter: string;
  StatusFilter: string;
  PageSize: number;
  SkipRecords: number;
}

export interface LawfirmOverviewList {
  LawfirmId: number;
  LawfirmName: string;
  OutstandingAmount: number;
  LawfirmOverview: string;
  Feedback: string;
}

export const DEFAULT_LAWFIRM_OVERVIEW_LIST: LawfirmOverviewList = {
  LawfirmId: 0,
  LawfirmName: '',
  OutstandingAmount: 0,
  LawfirmOverview: '',
  Feedback: ''
};

export interface LawfirmPaidStatementHandle {
  data: LawfirmPaidStatement;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}
export interface LawfirmPaidStatement {
  Id: number;
  TransactionDate: string;
  AdvocateId: number;
  AdvocateName: string;
  TransactionDescription: string;
  TransactionAmount: number;
  LawfirmStatementDTO: AdvocateDetailStatement[];
}
export interface AdvocateDetailStatement {
  ServiceProviderId: number;
  InvoiceId: number;
  ProviderFullName: string;
  ServiceDescription: string;
  AttorneyName: string;
  AttorneyId: number;
  ProviderAddress: string;
  ProviderName: string;
  Phone: string;
  Fax: string;
  Email: string;
  Due: number;
  InvoiceNo: string;
  LawFirm: string;
  InvoiceDate: string;
  StatementDate: string;
  Total: number;
  Paid: number;
  PaymentStatus: string;
  NormalDays: number;
  ZeroDays: number;
  ThirtyDays: number;
  SixtyDays: number;
  NinetyDays: number;
  NinetyEightDays: number;
  Reference: string;
  TotalDaysOverdue: number;
  HasBrief: number;
  ServiceId: number;
  PaymentTerms: string;
  InvoiceStatusGroup: number;
  InvoiceStatus: string;
  DisputeStatus: string;
  DisputStatusId: number;
  DisputeNoteStatus: string;
  InvoiceEscaletedStatusGroup: number;
} // end AdvocateDetailStatement{}

export interface AdvocateDetailLineItem {
  Id: number;
  LineDate: string;
  Description: string;
  LineType: string;
  Quantity: number;
  Total: number;
  Rate: number;
  DisputeStatusId: number;
  DisputeStatus: string;
  DisputeDescription: string;
  DisputeSource: string;
  DisputeNote: string;
}

export const DEFAULT_ADVOCATE_DETAIL_LINE_ITEM: AdvocateDetailLineItem = {
  Id: 0,
  LineDate: '',
  Description: '',
  LineType: '',
  Quantity: 0,
  Total: 0,
  Rate: 0,
  DisputeStatusId: 0,
  DisputeStatus: '',
  DisputeDescription: '',
  DisputeSource: '',
  DisputeNote: ''
}

export interface InvoiceStatuses {
  Id: number;
  SourceId: number;
  Status: string;
  StatusDate: string;
  EndStatusDate: string;
  IsActive: boolean;
  Source: string;
  Description: string;
  Note: string;
}

export const DEFAULT_INVOICE_STATUSES: InvoiceStatuses = {
  Id: 0,
  SourceId: 0,
  Status: '',
  StatusDate: '',
  Description: '',
  EndStatusDate: '',
  IsActive: false,
  Note: '',
  Source: ''
};

export interface DisputeStatuses {
  Id: number;
  SourceId: number;
  Status: string;
  StatusDate: string;
  EndStatusDate: string;
  IsActive: boolean;
  Source: string;
  Description: string;
  Note: string;
}

export const DEFAULT_DISPUTE_STATUSES: DisputeStatuses = {
  Id: 0,
  SourceId: 0,
  Status: '',
  StatusDate: '',
  Description: '',
  EndStatusDate: '',
  IsActive: false,
  Note: '',
  Source: ''
};

export interface ICrumb {
  label: string;
  link?: string;
} // end ICrumb{}

export const DEFAULT_ADVOCATE_DETAIL_STATEMENT: AdvocateDetailStatement = {
  ServiceProviderId: 0,
  InvoiceId: 0,
  ProviderFullName: '',
  ServiceDescription: '',
  AttorneyName: '',
  AttorneyId: 0,
  ProviderAddress: '',
  ProviderName: '',
  Phone: '',
  Fax: '',
  Email: '',
  Due: 0,
  InvoiceNo: '',
  LawFirm: '',
  InvoiceDate: '',
  StatementDate: '',
  Total: 0,
  Paid: 0,
  PaymentStatus: '',
  NormalDays: 0,
  ZeroDays: 0,
  ThirtyDays: 0,
  SixtyDays: 0,
  NinetyDays: 0,
  NinetyEightDays: 0,
  Reference: '',
  TotalDaysOverdue: 0,
  HasBrief: 0,
  ServiceId: 0,
  PaymentTerms: '',
  InvoiceStatus: '',
  InvoiceStatusGroup: 0,
  DisputeStatus: '',
  DisputStatusId: 0,
  DisputeNoteStatus:  '',
  InvoiceEscaletedStatusGroup: 0
}; // end DEFAULT_ADVOCATE_DETAIL_STATEMENT{}

export interface AdvocateDetailLineItemHandle {
  data: AdvocateDetailLineItem;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    updated: boolean;
  }
}

export interface QueryReport {
  InvoiceId: number;
  Advocate: string;
  InvoiceNo: string;
  Reference: string;
  Attorney: string;
  PartyDetails: string;
  Type: string;
  RequestDate: string;
  ResolvedDate: string;
  Note: string;
};

@Injectable()
export class AdvocateDetailStatementService {

  activeTab: string;
  selectedInvoice: AdvocateDetailStatement;
  lawfirmId: number;
  advocateId: number;
  selectedAttorneyId: number;
  selectedFirmTab: string;
  allCustomerActiveTab: string;

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  /**
   * GET: Returns advocate details in from selected advocate summary
   * @returns {LawfirmID, AdvocateID} Returns all where lawfirm ID and advocate ID
   */
  getAdvocateDetailStatement(lawfirmId: number, advocateId: number): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.advocateDetailStatement +
      '?ClientID=' + lawfirmId + '&AdvocateId=' + advocateId,
      this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getAdvocateDetailStatement()

  getPaidStatement(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.lawfirmPaidStatement +
      '?client=' + this.api.serviceProviderID, this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end getPaidStatement()

  getPaidByAdvocateStatement(advocate: number): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.lawfirmPaidByAdvocateStatement +
      '?client=' + this.api.serviceProviderID +
      '&advocate=' + advocate, this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end getPaidStatement()

  getAllocated_To_Lawfirm(clientId: number): Observable<any> {
    return this.http.get(this.api.endpoints.allocatedToLawfirm +
      '?ServiceProviderID=' + clientId +
      '&ClientID=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getAllocatedToLawfirm()

  getAllocatedToFirm_LiveStatement(): Observable<any> {
    return this.http.get(this.api.endpoints.liveStatementAllocatedToLawfirm +
      '?ClientID=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getAllocatedToFirm_LiveStatement()

  /**
   * GET: Returns advocate details in from selected advocate summary
   * @returns {LawfirmID, AdvocateID} Returns all where lawfirm ID and advocate ID
   */
  getAllAdvocatesReport(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.allAdvocateReport +
      '?ClientId=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getAdvocateDetailStatement()

  getAllAdvocatesData(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.allAdvocateReport +
      '?ClientId=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getAdvocateDetailStatement()

  getByIndividualCustomer(clientId: number): Observable<any> {
    return this.http.get(this.api.endpoints.byIndividualCustomer +
      '?serviceProviderId=' + clientId + '&clientId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getLawfirmStatement(lawfirmId: number): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.lawfirmOustandingStatement +
      '?ClientID=' + lawfirmId,
      this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getAdvocateDetailStatement()

  getAdvocateList(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.distinctAdvocateList +
      '?ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end getAdvocateList()

  getAttorneyList(advocateId: number): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.allAttorneyData +
      '?ServiceProviderID=' + this.api.serviceProviderID + '&AdvocateId=' + advocateId,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end getAttorneyList()

  getAllCustomers(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.AttorneyAllCustomers +
      '?ClientId=' + this.api.serviceProviderID, this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end getAllCustomers()

  getByIndividualAttorneyCustomers(advocate: number): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.byIndividualAttorneyCustomers +
      '?ServiceProviderID=' + advocate + '&ClientID=' + this.api.serviceProviderID,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end getByIndividualAttorneyCustomers()

  generateExcelReport(url: string, jsonBody: any): Observable<any> {
    return this.http.get(url, {
      responseType: 'blob',
      observe: jsonBody
    }).pipe(map(
      (res) => {
        return new Blob([res], { type: 'application/ms-excel' });
      }));
  } // end generateExcelReport()

  downloadExcelFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

  getAccountBankTransactions(filterObj: any): Observable<any> {
    return this.http.post<any>(
      this.api.endpoints.getAccountPlateformTransaction +
      '?ServiceProviderID=' + this.api.serviceProviderID, filterObj,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  }

  getAccountBankTransactionLogs(transactionId: number): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.getAccountPlateformTrandsactionLog +
      '?accountTransactionId=' + transactionId,
      this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  }

  getLatestDispute(Id: number, status:string): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.lawfirmStatement + '/GetLatestDispute?ServiceProviderID=' + this.api.serviceProviderID +
      '&Id=' + Id + '&Source=' + status, this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end getLatestDispute()

  getInvoiceLineDetail(InvoiceId: number ): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.lawfirmStatement + '/OutstandingInvoiceLineDetail?ServiceProviderID=' + this.api.serviceProviderID +
      '&InvoiceId=' + InvoiceId, this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end getInvoiceLineDetail()

  updateInvoiceStatus(serviceId: number, status:string): Observable<any> {
    return this.http.put<any>(
      this.api.endpoints.lawfirmStatement + '/UpdateInvoiceStatus?ServiceProviderId=' + this.api.serviceProviderID +
      '&InvoiceId=' + serviceId + '&status=' + status, this.api.httpOptions      
    ).pipe(
      catchError(this.api.handleError)
    )
  } // end updateInvoiceStatus()

  updateDisputeStatus(status: DisputeStatuses): Observable<any> {
    return this.http.put<any>(
      this.api.endpoints.lawfirmStatement + '/UpdateDisputeStatuses?ServiceProviderId=' + this.api.serviceProviderID,
      status, this.api.httpOptions      
    ).pipe(
      catchError(this.api.handleError)
    )
  } // end updateDisputeStatus()

  updateDisputeStatusList(status: AdvocateDetailLineItem[]): Observable<any> {
    return this.http.put<any>(
      this.api.endpoints.lawfirmStatement + '/UpdateDisputeStatusesList?ServiceProviderId=' + this.api.serviceProviderID,
      status, this.api.httpOptions      
    ).pipe(
      catchError(this.api.handleError)
    )
  } // end updateDisputeStatusList()

  getAttorneyDetailStatement(lawfirmId: number, advocateId: number): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.lawfirmStatement + '/OutstandingStatementByAttorney' +
      '?ClientID=' + lawfirmId + '&ClientContactId=' + advocateId,
      this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getAttorneyDetailStatement()

  getQueryReport(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.lawfirmStatement + '/GetQueryReport' +
      '?ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getQueryReport()

  getQueryReportByAdvocateId(advocateId: number, invocieId: number): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.lawfirmStatement + '/GetQueryReportByAdvocateId?AdvocateId=' + advocateId +
      '&InvoiceId=' + invocieId + '&ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getQueryReportByAdvocateId()
} // end AdvocateDetailStatementService{}
