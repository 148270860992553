import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  DebtorTotalOutstanding,
  DebtorAdvocateDetail,
  PracticeAdvocateHealthService,
  DebtorTotalOutstandingHandle,
  DebtorAdvocateDetailHandle,
  OtherPracticeManagers
} from '../../../services/practice-advocate-health/practice-advocate-health.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

import { ReportsService } from '../../../services/reports/reports.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

@Component({
  selector: 'app-practice-by-lawfirm',
  templateUrl: './practice-by-lawfirm.component.html',
  styleUrls: ['./practice-by-lawfirm.component.scss']
})
export class PracticeByLawfirmComponent implements OnInit, OnChanges {

  lawfirmName = '';
  AdvocateName = '';
  tooltipData: any = [];
  showTooltipOverlay = false;

  // Search
  searchTerm: string;

  // Highlight the selected row
  row = 0;

  // Sorting
  sortBy: string;
  sortAscending: boolean;
  previousSortBy: string;
  sortLawfirm: boolean;
  sortTotalOutstanding: boolean;
  sortAdvocateCount: boolean;

  // Advocate detail
  otherPracticeManagers: OtherPracticeManagers[] = [];
  advocateDetail: DebtorAdvocateDetail[] = [];
  advocateDetailHandle: DebtorAdvocateDetailHandle[] = [];
  @Input() debtorTotalOutstandingHandle: DebtorTotalOutstandingHandle[];

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private practiceAdvocateHealthService: PracticeAdvocateHealthService
  ) { } // end constructor()

  ngOnInit() {

  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    // setTimeout(() => {
    // if (this.debtorTotalOutstandingHandle) {
    //   this.data = this.debtorTotalOutstandingHandle;
    // }
    // }, 8000);
  } // end ngOnChanges()

  getLawfirmBarWidth(lawfirmbar: DebtorTotalOutstanding): string {
    let value = 0;
    let maintotal = 0;
    for (const item of this.debtorTotalOutstandingHandle) {
      maintotal += item.data.Outstanding;
    }
    value = lawfirmbar.Outstanding / maintotal * 100;
    let width = '0.95';
    if (value >= 1) {
      width = (value * 0.95).toString();
    }
    return width + '%';
  } // end getLawfirmBarWidth()

  getBarWidth(value: number): string {
    let width = '0.95';
    if (value >= 1) {
      width = (value * 0.95).toString();
    }
    return width + '%';
  } // end getBarWidth()

  toggleSort(sortBy: string) {
    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }

    switch (this.sortBy) {
      case 'law-firm':
        this.debtorTotalOutstandingHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortLawfirm = true;
            this.sortTotalOutstanding = false;
            this.sortAdvocateCount = false;
            return a.data.Debtor.toUpperCase().localeCompare(b.data.Debtor.toUpperCase());
          }
          this.sortLawfirm = false;
          return b.data.Debtor.toUpperCase().localeCompare(a.data.Debtor.toUpperCase());
        });
      break;

      case 'outstanding':
        this.debtorTotalOutstandingHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortLawfirm = false;
            this.sortTotalOutstanding = true;
            this.sortAdvocateCount = false;
            return a.data.Outstanding - b.data.Outstanding;
          }
          this.sortTotalOutstanding = false;
          return b.data.Outstanding - a.data.Outstanding;
        });
      break;

      case 'advocate-number':
        this.debtorTotalOutstandingHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortLawfirm = false;
            this.sortTotalOutstanding = false;
            this.sortAdvocateCount = true;
            return a.data.AdvocateCount - b.data.AdvocateCount;
          }
          this.sortAdvocateCount = false;
          return b.data.AdvocateCount - a.data.AdvocateCount;
        });
      break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleSort()

  filterSelectedAdvocate() {
    // if (this.AdvocateName) {
    //   if (this.AdvocateName === '') {
    //     this.debtorTotalOutstanding = this.data.filter(_data => _data.debtor === this.lawfirmName);
    //   } else {
    //    this.debtorTotalOutstanding = this.debtorTotalOutstanding.
    //   }
    // } else {
    //   this.debtorTotalOutstanding = this.data.filter(_data => _data.debtor === this.lawfirmName);
    // }
  } // end filterSelectedAdvocate()

  onRowClick(data: DebtorTotalOutstanding) {
    this.row = 0;
    this.advocateDetailHandle = [];
    this.advocateDetail = [];
    this.loadingService.showOverlay();
    if (data.ClientId !== 0) {
      this.row = data.ClientId;
      this.practiceAdvocateHealthService.getPracticeLawfirmDetailOverdue(data.ClientId).subscribe({ next: (_data) => {
        // On next
        this.advocateDetail = _data;
        this.advocateDetail.forEach(advocate => {
          advocate.ClientId = data.ClientId;
          this.advocateDetailHandle.push({
            data: advocate,
            state: {
              isVisible: true,
              isSelected: false,
              isOpen: false
            }
          });
        });
        this.advocateDetailHandle.sort((a, b) => b.data.Outstanding - a.data.Outstanding);
      }});
    }
    this.practiceAdvocateHealthService.getOtherPracticeOverdueSummaryFirm(data.ClientId).subscribe({ next: (_data) => {
      // On next
      this.otherPracticeManagers = _data;
      this.loadingService.hideOverlay();
    }});

  } // end onRowClick()

  performSearch() {
    if (this.searchTerm) {
      this.debtorTotalOutstandingHandle.forEach(lawfirm => {
        if (
          !(lawfirm.data.Debtor.match(new RegExp(this.searchTerm, 'gi')))
        ) {
          lawfirm.state.isVisible = false;
        } else {
          lawfirm.state.isVisible = true;
        }
      });
    } else {
      this.debtorTotalOutstandingHandle.forEach(lawfirm => {
        lawfirm.state.isVisible = true;
      });
    }
  } // end performSearch()

  clearSearch() {
    this.searchTerm = '';
    this.performSearch();
  } // end clearSearch()

  onHover(firmData: DebtorTotalOutstanding, advocateData: DebtorAdvocateDetail) {
    let type = null;
    this.showTooltipOverlay = true;
    this.tooltipData = [];
    this.practiceAdvocateHealthService.getByIndividualInvoicesCustomer(firmData.ClientId, advocateData.AdvocateId).subscribe({ next: (response) => {
      if (response) {
        if (type !== null) {
          response = response.filter(a => a.due > 0 && a.daysOverdue === type);
        } else {
          response = response.filter(a => a.due > 0);
        }
        response.sort((a, b) => {
          return a.invoiceDate.toUpperCase().localeCompare(b.invoiceDate.toUpperCase());
        });
        if (response.length > 5) {
          this.tooltipData = response.slice(0, 5);
        } else {
          this.tooltipData = response;
        }
      }
    },
      error: (error) => {
        // Error
      },
      complete: () => {
        this.showTooltipOverlay = false;
      }
    });
  } // end onHover()

} // end PracticeByLawfirmComponent{}
