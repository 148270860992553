<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h3>{{title}}</h3>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">
        <!-- Modal tabs -->
        <div class="row row-nav">
          <nav class="navbar-tabs">
            <a 
              hidden
              class="tab"
              [class.active]="activeTab === 'client-detail'"
              (click)="setActiveTab('client-detail')">{{clientDetails}}</a>
            <a 
              class="tab"
              [class.active]="activeTab === 'notes-detail'"
              (click)="setActiveTab('notes-detail')">{{notesDetails}}</a>
          </nav> <!-- #end .navbar-tabs -->
        </div> <!-- #end .row .row-nav -->

        <div class="row">
          <div class="well well-fluid" *ngIf="activeTab === 'client-detail'">
            This is client or Advocate detail view
          </div> <!-- #end .well well-fluid -->
          <div class="well well-fluid" *ngIf="activeTab === 'notes-detail'">
            <div class="form-control">
              <div class="control-group">
                <label for="search">Find</label>&nbsp;
                <input type="search" placeholder="Search">
              </div>
              <div hidden class="control-group">
                <label for="conversation">Conversation </label>&nbsp;
                <app-small-radiobox
                  [(checked)]="isConvesrationChecked"
                  (click)="toggleCheckbox('conversation')">
                </app-small-radiobox>
              </div>
              <div hidden class="control-group">
                <label for="table">Table </label>&nbsp;
                <app-small-radiobox
                  [(checked)]="isTableChecked"
                  (click)="toggleCheckbox('table')">
                </app-small-radiobox>
              </div>
              
              <!-- Date from -->
              <div class="control-group date-control">
                <label style="min-width: 58px;" for="date-from">From Date</label>&nbsp;
                <input
                  readonly
                  type="text"
                  id="date-from"
                  class="input-date"
                  #_dateFrom="bsDatepicker"
                  bsDatepicker
                  placeholder="2019-10-01"
                  placement="bottom"
                  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
                  <i
                    class="fa fa-calendar"
                    [attr.aria-expanded]="_dateFrom.isOpen"></i>
              </div>
              
              <!-- Date to -->
              <div class="control-group date-control">
                <label style="min-width: 58px;" for="date-to">&nbsp; To Date</label>&nbsp;
                <input
                  readonly
                  type="text"
                  id="date-to"
                  class="input-date"
                  #_dateTo="bsDatepicker"
                  bsDatepicker
                  placeholder="2019-10-01"
                  placement="bottom"
                  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
                  <i
                    class="fa fa-calendar"
                    [attr.aria-expanded]="_dateTo.isOpen"></i>
              </div>
            </div>

            <!-- Table notes data -->
            <div *ngIf="isTableChecked">
              
              <!-- Table -->
              <div class="table-responsive">
                <table id="conversationTable" class="table table-striped">
                  <tr>
                    <th>Note About</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>User</th>
                    <th>Note Type</th>
                    <th>Note</th>
                  </tr>
                  <tr *ngFor="let data of notes">
                    <td  style="width: 19%;">{{data.ObjectTypeDescription}}</td>
                    <td *ngIf="isPracticeManager" [ngStyle]="{'font-weight': getUnReadMessageFont(data.UserCreatorStatus)}" style="width: 10%;">{{data.CaptureDate | date:'yyyy-MM-dd'}}</td>
                    <td *ngIf="!isPracticeManager && !data.Personal" [ngStyle]="{'font-weight': getAdvUnReadMessageFont(data.UserAssignedStatus)}" style="width: 10%;">{{data.CaptureDate | date:'yyyy-MM-dd'}}</td>

                    <td *ngIf="isPracticeManager"[ngStyle]="{'font-weight': getUnReadMessageFont(data.UserCreatorStatus)}" style="width: 10%;">{{data.CaptureDate | date:'h:mm:ss a'}}</td>
                    <td *ngIf="!isPracticeManager && !data.Personal"[ngStyle]="{'font-weight': getAdvUnReadMessageFont(data.UserAssignedStatus)}" style="width: 10%;">{{data.CaptureDate | date:'h:mm:ss a'}}</td>

                    <td *ngIf="isPracticeManager" [ngStyle]="{'font-weight': getUnReadMessageFont(data.UserCreatorStatus)}" style="width: 14%;">{{noteUserLogger(data)}}</td>
                    <td *ngIf="!isPracticeManager && !data.Personal" [ngStyle]="{'font-weight': getAdvUnReadMessageFont(data.UserAssignedStatus)}" style="width: 14%;">{{data.UserCreatorFullName}}</td>

                    <td *ngIf="isPracticeManager" [ngStyle]="{'font-weight': getUnReadMessageFont(data.UserCreatorStatus)}" style="width: 12%;">{{data.Subject}}</td>
                    <td *ngIf="!isPracticeManager && !data.Personal" [ngStyle]="{'font-weight': getAdvUnReadMessageFont(data.UserAssignedStatus)}" style="width: 12%;">{{data.Subject}}</td>

                    <td *ngIf="isPracticeManager && data.Subject === 'Instruction from PM'" [ngStyle]="{'font-weight': getUnReadMessageFont(data.UserCreatorStatus)}" class="col-note"(click)="onNoteDetailView(data)"><u style="color: blue;">{{data.ResponseText}} {{data.InstructionText}}</u></td>
                    <td *ngIf="isPracticeManager && data.InternalText && data.Subject !== 'Instruction from PM'" [ngStyle]="{'font-weight': getUnReadMessageFont(data.UserCreatorStatus)}" class="col-note"(click)="onNoteDetailView(data)"><u style="color: blue;">{{pmLatestNote(data)}}</u></td>
                    <td *ngIf="isPracticeManager && !data.InternalText" [ngStyle]="{'font-weight': getUnReadMessageFont(data.UserCreatorStatus)}" class="col-note"(click)="onNoteDetailView(data)"><u style="color: blue;">{{data.QuestionText}} - {{data.ExternalText}}</u></td>
                    <td *ngIf="!isPracticeManager && !data.Personal" [ngStyle]="{'font-weight': getAdvUnReadMessageFont(data.UserAssignedStatus)}" class="col-note"(click)="onAdvNoteDetailView(data)"><u style="color: blue;">{{data.QuestionText}} {{data.ExternalText}}</u></td>
                  </tr>
                </table>
                <div class="footer" *ngIf="isPracticeManager">
                  <span class="column-add-another-note">
                    <span class="clickable" (click)="onAddNewNote()">
                      <i class="fa fa-plus-circle auxcon-green"></i>
                      <u class="auxcon-green">add note</u>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <!-- Conversation notes data -->
            <div *ngIf="isConvesrationChecked">
              <div class="row">
                <h1>Conversation view Coming soon.</h1>
              </div>
            </div>
          </div> <!-- #end .well well-fluid -->
        </div> <!-- #end .row -->

      </div> <!-- #end .modal-body .form -->

      <!-- <div class="modal-footer">
        <div class="well well-fluid" *ngIf="activeTab === 'notes-detail'">
          <div class="row">
            <div
              class="form-control" 
              style="border:0; background-color: transparent;">
              <div class="control-group">
                <label for="category">Note Type</label>&nbsp;
                <select
                  id="category">
                  <option value="" selected>Note Category</option>
                </select>
              </div>
              <div class="control-group">
                <label for="sub-category">Note Detail</label>&nbsp;
                <select
                  id="sub-category">
                  <option value="" selected>Note Sub-Category</option>
                </select>
              </div>
                <div class="control-group date-control">
                  <label
                    for="date-to">Attribute</label>&nbsp;
                  <input
                    readonly 
                    type="text" 
                    id="date-to" 
                    class="input-date" 
                    #_dateTo="bsDatepicker"
                    bsDatepicker
                    placeholder="2019-10-01" 
                    placement="top" 
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">
                  <i 
                    class="fa fa-calendar" 
                    [attr.aria-expanded]="_dateTo.isOpen"></i>
                </div>
            </div>
          </div>
          <div class="row">
            <div 
              class="form-control" 
              style="border:0; background-color: transparent;">
              <div class="control-group">
                <label for="message">Message</label>&nbsp;
                <textarea
                  class="input-control"
                  placeholder="Add Note Message">
                </textarea>
              </div>
              <div class="option-groups">
                <div class="visibility-group">
                  <div class="text-center">
                    <label for="visibility">Visibility</label>
                  </div>
                  <div class="text-center">
                    <i class="far fa-question-circle"></i>
                  </div>
                </div>
                <div class="visibility-group">
                  <div class="">
                    <div class="control-group">
                      <label for="table">Internal </label>&nbsp;
                      <app-small-radiobox [(checked)]="isInternalChecked" (click)="toggleIntExtCheckbox('internal')">
                      </app-small-radiobox>
                    </div>
                  </div>
                  <div class="">
                    <div class="control-group">
                      <label for="table">External </label>&nbsp;
                      <app-small-radiobox [(checked)]="isExternalChecked" (click)="toggleIntExtCheckbox('external')">
                      </app-small-radiobox>
                    </div>
                  </div>
                </div>
              </div>
              <button style="height: auto;" (click)="onAddNote()" class="btn btn-success">ADD NOTE</button>
            </div>
          </div>
        </div>  
      </div> -->

    </div> <!-- #end .modal=dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->

<app-note-detail-modal
  *ngIf="onShowNoteDetail"
  [isVisible]="onShowNoteDetail"
  [selectedTab]="selectedTab"
  [objectType]="objectType"
  [noteDetailData]="noteDetailData"
  [instructionResponses]="instructionResponses"
  [lawfirmSummaryData]="lawfirmSummaryData"
  [noteCategory]="noteCategory"
  (closemodal)="onShowNoteDetailModalDismiss()"
  (saveNote)="onNoteSave($event)"
  (issueInstruction)="onInstructionIssued($event)"
  (updateNote)="OnAddResolution($event)"></app-note-detail-modal>

<app-advocate-note-modal
  *ngIf="showAdvocateNoteDetail" 
  [isVisible]="showAdvocateNoteDetail"
  [objectType]="objectType"
  [plaintiff]="invoicePlaintiff" 
  [defendant]="invoiceDefendant" 
  [invoiceDetails]="invoiceDetails"
  [selectedNote]="selectedNote" 
  [lawfirmSummaryData]="lawfirmSummaryData"
  [instructionResponses]="instructionResponses" 
  (closemodal)="closeModel('advocate-note')"
  (saveInstruction)="OnAddResolution($event)"></app-advocate-note-modal>