<div class="container-fluid">

  <div class="well">
    <span *ngFor="let crumb of crumbs; let i = index;">
      <span
        *ngIf="crumb.link"
        class="clickable"
        (click)="onClick(crumb)">
        <u>{{crumb.label}}</u>
      </span>
      <span *ngIf="!crumb.link">
        {{crumb.label}}
      </span>
      <span *ngIf="i < (crumbs.length - 1)">&nbsp;|&nbsp;</span>
    </span>

  </div> <!-- #end .well -->

</div> <!-- #end .container-fluid -->
