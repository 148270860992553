import { Component, OnInit, Input } from '@angular/core';
import { AgeAnalysisData } from '../../../services/age-analysis/age-analysis.service';
import { AdvocateDetailStatementService } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { Router } from '@angular/router';
import { PATH_UNPAID_INVOICES } from '../../../services/appdata/app-config.service';
import { LiveStatementService } from '../../../services/live-statement/live-statement.service';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-age-analysis',
  templateUrl: './age-analysis.component.html',
  styleUrls: ['./age-analysis.component.scss']
})
export class AgeAnalysisComponent implements OnInit {

  title = 'Age analysis';

  @Input() data: AgeAnalysisData;

  constructor(
    private advocateDetailStatementService: AdvocateDetailStatementService,
    private router: Router,
    private liveStatementService: LiveStatementService,
    private api: ApiService
  ) { }

  ngOnInit() {
  } // end ngOnInit()

  ageAnalysisClicked(clickedAge: string) {
    this.advocateDetailStatementService.activeTab = clickedAge;
    if (this.api.serviceProviderID) {
      this.router.navigate([PATH_UNPAID_INVOICES, this.api.serviceProviderID]);
    }
  }

} // end AgeAnalysisComponent()
