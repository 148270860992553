import { Injectable } from '@angular/core';
// import mandrill from 'mandrill-api/mandrill';

export const LAW_FIRM = 'Law Firm';
export const ATTORNEY = 'Attorney';
export const DEFENDANT = 'Defendant';
export const PLAINTIFF = 'Plaintiff';
export const CLIENT = 'Client';

@Injectable()
export class EmailService {

  // private mandrillApiKey: string = 'CxeilLlAxDYqeMbV5evQHQ';
  // private mandrillClient = new mandrill.Mandrill(this.mandrillApiKey);
  // private auxconSenderEmail: string = 'auxcontest@outlook.com';
  private auxconSenderEmail: string = 'gitfiyamusic@gmail.com';
  private auxconRecipientEmail: string = 'amasiye313@gmail.com';

  constructor() {} // end constructor()

  /**
   * The send() function allows you to send emails directly from a script.
   * @param to [Required] Specifies the receiver / receivers of the email.
   * @param subject [Required] Specifies the subject of the email. Note: This parameter cannot contain any newline characters
   * @param message [Required] Defines the message to be sent.
   * @param headers Specifies additional headers, like From, Cc, and Bcc.
   * @param params Specifies an additional parameter to the email program
   */
  send(
    to: string,
    subject: string,
    message: string,
    headers: any = {},
    params: any = {}
  ) {
    const from: string = this.auxconSenderEmail;
    // TODO: Implement general email wrapper function to abstract away 
    // implementation specific code. This will afford better modularity
  } // end send()

  /**
   * Creates a
   * @param {string} type Specifies the type of message.
   * @param {*} params Specifies additional parameters build the message.
   */
  createMessage(type: string, params: {}): string {
    let message = ``;

    return message;
  } // end createMessage()

  sendEmailToAddContact(
    contactType,
    firmName,
    attorneyName,
    contactNumber,
    fullName
  ) {
    // let timeToSend = moment().subtract(2, 'days').format('YYYY-MM-DD HH:MM:SS')
    const twoDaysAgo = new Date().getTime() - (2 * 1000 * 60 * 60 * 24);
    let timeToSend = new Date(twoDaysAgo).toJSON().replace(/[TZ]/gi, ' ');
    timeToSend = timeToSend.substr(0, timeToSend.indexOf('.'));

    const lawFirmMessage = '<p> Dear Auxcon,<br /><br /> I would like to request the addition of a new ' + contactType + ' to the database.<br /><br /> The details of the ' + contactType + ':<br /> Name: ' + firmName + '<br /> Contact person: ' + attorneyName + '<br /> Contact number: ' + contactNumber + '<br /> <br /> <br /> Yours sincerely<br /> ' + fullName + '</p>'
    const attorneyFirmMessage = '<p> Dear Auxcon,<br /><br /> I would like to request the addition of a new ' + contactType + ' to the database.<br /><br /> The details of the ' + contactType + ':<br /> Name: ' + firmName + '<br /> Contact person: ' + attorneyName + '<br />  Email address: ' + contactNumber + '<br /> <br /> <br /> Yours sincerely<br /> ' + fullName + '</p>'
    var message = {
      'html': (contactType === LAW_FIRM) ? lawFirmMessage : attorneyFirmMessage,
      'text': 'Example text content',
      'subject': 'New ' + contactType + ' request',
      'from_email': this.auxconSenderEmail,
      'from_name': 'Auxcon',
      'to': [{
        'email': this.auxconRecipientEmail,
        'name': 'Auxcon',
        'type': 'to'
      }],
      'headers': {
        'Reply-To': 'message.reply@example.com'
      },
      'important': false,
      'track_opens': null,
      'track_clicks': null,
      'auto_text': null,
      'auto_html': null,
      'inline_css': null,
      'url_strip_qs': null,
      'preserve_recipients': null,
      'view_content_link': null,
      'bcc_address': 'message.bcc_address@example.com',
      'tracking_domain': null,
      'signing_domain': null,
      'return_path_domain': null,
      'merge': true,
      'merge_language': 'mailchimp',
      'global_merge_vars': [{
        'name': 'merge1',
        'content': 'merge1 content'
      }],
      'merge_vars': [{
        'rcpt': 'recipient.email@example.com',
        'vars': [{
          'name': 'merge2',
          'content': 'merge2 content'
        }]
      }],
      'tags': [
        'password-resets'
      ],
      'subaccount': 'Aux-001',
      'google_analytics_domains': [
        'example.com'
      ],
      'google_analytics_campaign': 'message.from_email@example.com',
      'metadata': {
        'website': 'www.example.com'
      },
      'recipient_metadata': [{
        'rcpt': 'recipient.email@example.com',
        'values': {
          'user_id': 123456
        }
      }],
      'attachments': [],
      'images': []
    }

    // var = false
    var ipPool = 'Main Pool'
    var mailResponse
    // this.mandrillClient.messages.send({
    //   'message': message,
    //   'async': async,
    //   'ip_pool': ipPool,
    //   'send_at': timeToSend
    // }, function (result) {
    // }, function (e) {
    //   // Mandrill returns the error as an object with name and message keys
    //   // A mandrill error occurred: Unknown_Subaccount - No subaccount exists with the id 'customer-123'
    // });
  }
} // end EmailService()
