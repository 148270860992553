import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BankAccountHandle } from '../../../pages/advocate-process/manage-bank-accounts/manage-bank-accounts.component';
import { UtilitiesService } from '../../../services/utilities.service';
import { BankAccounts, DEFAULT_BANK_ACCOUNT } from '../../../services/bank-accounts/bank-accounts.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-add-edit-bank-account',
  templateUrl: './add-edit-bank-account.component.html',
  styleUrls: ['./add-edit-bank-account.component.scss']
})
export class AddEditBankAccountComponent implements OnInit, OnChanges {

  banks: any;
  preferrenceDate: any;
  accountTypes: any;
  bankProfileType: any;
  workingBankAccountHandle: BankAccounts;
  showUnsavedChangesPrompt: boolean;
  showUnInvalidRemiderDatePrompt: boolean = false;
  title = 'Add/edit secondary bank account details';

  showDeactivatePrimaryAccountPrompt: boolean;
  showDeactivatePrimaryOnlyAccountPrompt: boolean;

  @Input() isVisible: boolean;
  @Input() bankAccountHandle: BankAccounts;
  @Input() bankAccounts: BankAccounts[];
  @Input() addEditButtonText: string;
  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveBankAccount: EventEmitter<any> = new EventEmitter<any>();

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.bankAccountHandle, this.workingBankAccountHandle);
  } // end isDirty()
  constructor(
    private util: UtilitiesService,
    private api: ApiService
  ) { } // end contructor()

  ngOnInit() {
    if (!this.addEditButtonText) {
      this.addEditButtonText = 'Save';
    }
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.isVisible && !changes.isVisible.previousValue) {
    //   this.workingBankAccountHandle = this.util.objectCopy(this.bankAccountHandle);
    // }

    if (changes.bankAccountHandle && changes.bankAccountHandle.currentValue) {
      this.workingBankAccountHandle = this.util.objectCopy(this.bankAccountHandle);
    }

    this.banks = [
      { id: 1, name: 'First National Bank' },
      { id: 2, name: 'Absa Bank' },
      { id: 3, name: 'Nedbank' },
      { id: 4, name: 'INVESTEC BANK' },
      { id: 5, name: 'Standard Bank' },
      { id: 6, name: 'RMB Private Bank' },
      { id: 7, name: 'Capitec Bank' },
      { id: 8, name: 'Mercantile Bank' },
      { id: 9, name: 'Standard Bank - Private Bank' },
      { id: 10, name: 'Stanbic Bank' },
      { id: 11, name: 'Old Mutual Bank' },
      { id: 12, name: 'Discovery Bank' },
      { id: 13, name: 'Capitec Business Bank' },
      { id: 14, name: 'TymeBank' }
    ];

    this.accountTypes = [
      { label: 'Cheque/Current', value: 'Cheque Account' },
      { label: 'Savings', value: 'Savings Account' },
      { label: 'Transmission', value: 'Transmission Account' }
    ];

    this.bankProfileType = [
      { label: 'Secondary', value: 'Secondary' },
      { label: 'Primary', value: 'Primary' },
    ];

    let temp = [];
    for (let i = 1; i <= 31; i++) {
      temp.push({ Id: i, value: i });
    }
    this.preferrenceDate = temp;
  } // end ngOnChanges()

  onDismiss() {
    this.dismiss();
  }

  dismiss() {
    this.onHideUnsavedChangesPrompt();
    this.closeModal.emit('add-edit-bank-details');
  } // end dismiss()

  onCancel() {
    // this.bankAccountHandle = this.util.objectCopy(this.workingBankAccountHandle);
    this.dismiss();
  } // end onCancel()

  onShowUnSavedChangesPrompt() {
    if (this.isDirty) {
      this.showUnsavedChangesPrompt = true;
    } else {
      this.dismiss();
    }
  } // end onShowUnSavedChangesPrompt()

  changeStatus() {
    this.workingBankAccountHandle.PracticeManagerAccess = !this.workingBankAccountHandle.PracticeManagerAccess;
  } // end changeStatus()

  activateStatus() {
    if (this.workingBankAccountHandle.SPAccType === 'Primary' && this.bankAccounts.length > 1) {
      this.showDeactivatePrimaryAccountPrompt = true;
    } if (this.workingBankAccountHandle.SPAccType === 'Primary' && this.bankAccounts.length === 1) {
      this.showDeactivatePrimaryOnlyAccountPrompt = true;
    }  else {
    this.workingBankAccountHandle.Enabled = !this.workingBankAccountHandle.Enabled;
    }
  } // end activateStatus()

  onHideDeactivatePrimaryAccountPrompt() {
    this.showDeactivatePrimaryAccountPrompt = false;
  } // end onHideDeactivatePrimaryAccountPrompt()

  onHideDeactivatePrimaryOnlyAccountPrompt() {
    this.showDeactivatePrimaryOnlyAccountPrompt = false;
  } // end onHideDeactivatePrimaryOnlyAccountPrompt()

  onSave() {
    this.workingBankAccountHandle.Bank = this.banks.filter(b => b.id === +this.workingBankAccountHandle.BankId)[0].name;
    this.workingBankAccountHandle.ContactId = this.api.serviceProviderID;
    if (isNaN(this.workingBankAccountHandle.StatementReminderDay)) {
      this.workingBankAccountHandle.StatementReminderDay = null;
    }
    this.saveBankAccount.emit(this.workingBankAccountHandle);
    this.dismiss();
    this.workingBankAccountHandle = Object.assign({}, DEFAULT_BANK_ACCOUNT);
    this.showUnsavedChangesPrompt = false;
  } // end onSave()

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end onHideUnsavedChangesPrompt()

  onHideshowUnInvalidRemiderDatePromptPrompt() {
    this.showUnInvalidRemiderDatePrompt = false;
  }
} // end AddEditBankAccountComponent{}
