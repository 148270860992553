import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ICrumb } from '../../../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { InvoiceDetails, InvoiceDetailsService } from '../../../../../services/invoice-details/invoice-details.service';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../../../services/messaging/loading/loading.service';
import { SnackbarsService, SnackType, Snack } from '../../../../../services/messaging/snackbars/snackbars.service';
import { ApiService } from '../../../../../services/api.service';
import { InvoiceDocumentService } from '../../../../../services/invoice-document/invoice-document.service';
import {
    PATH_REPORTS,
    PATH_CUSTOMER_PAYMENT_RATING,
    PATH_INDIVIDUAL_CUSTOMER } from '../../../../../services/appdata/app-config.service';
import { ReportsService } from '../../../../../services/reports/reports.service';

@Component({
  selector: 'app-report-customer-invoice-preview',
  templateUrl: './report-customer-invoice-preview.component.html',
  styleUrls: ['./report-customer-invoice-preview.component.scss']
})
export class ReportCustomerInvoicePreviewComponent implements OnInit {
  crumbs: ICrumb[];
  invoice: InvoiceDetails;
  invoiceId: number;
  plaintiff: string;
  defendant: string;
  selectedDebtorID: number;

  _fileName;
  @ViewChild('pdfViewer') public pdfViewer;

  constructor(
    private route: ActivatedRoute,
    private invoiceDetailService: InvoiceDetailsService,
    private loadingService: LoadingService,
    private snackbarService: SnackbarsService,
    private api: ApiService,
    private invoiceDocumentService: InvoiceDocumentService,
    private reportsService: ReportsService
  ) { } // end constructor()

  ngOnInit() {
    this.invoiceId = +this.route.snapshot.paramMap.get('id');

    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Loading invoice...',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack, 5000).show();

    const url = this.api.endpoints.invoiceDocument + '?invoiceID=' + this.invoiceId;
    this.invoiceDocumentService.getInvoiceDocument(url)
      .subscribe(invoiceDoc => {
        // this._fileName = invoiceDoc;
        this.pdfViewer.name = 'name.pdf';
        this.pdfViewer.pdfSrc = invoiceDoc;
        this.pdfViewer.refresh();
      });

    this.invoiceDetailService.getInvoiceDetails(this.invoiceId)
      .subscribe({ next: (invoice_details) => {
        this.invoice = invoice_details;

        const desc = this.invoice.InvoiceParties.split(' // ');
        if (desc.length > 0) {
          this.plaintiff = desc[0];
        }
        if (desc.length > 1) {
          this.defendant = desc[1];
        }
      },
        error: (error) => {
          // On error
          snack.label = 'An error loading invoice,';
          snack.type = SnackType.ERROR;
          this.snackbarService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On complete
          snack.label = 'Invoice loaded.';
          snack.type = SnackType.SUCCESS;
          this.snackbarService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        }
      });
        if (this.reportsService.individualCustomerId > 0) {
          this.selectedDebtorID = this.reportsService.individualCustomerId;
        }
    this.crumbs = [
      {
        label: 'Reports',
        link: PATH_REPORTS
      },
      {
        label: 'My Practice',
        link: PATH_REPORTS
      },
      {
        label: 'Customer report - Payment Rating',
        link: PATH_CUSTOMER_PAYMENT_RATING
      },
      {
        label: 'Individual Customer Report',
        link: PATH_INDIVIDUAL_CUSTOMER + '/' + this.selectedDebtorID
      },
      {
        label: 'Invoice'
      }
    ];
  } // end ngOnInit()

  @Input()
  set fileName(fileName: string) {
    this._fileName = fileName;
  }
  get fileName() {
    return this._fileName;
  }

} // and ReportCustomerInvoicePreviewComponent{}
