import { Injectable } from '@angular/core';
import { Matter } from '../matters/matters.service';
import { Observable, Subject ,  of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import {
  CONTACT_TYPE_ID_LAWFIRM, CONTACT_TYPE_ID_ATTORNEYS
} from '../appdata/app-config.service';
import { catchError, retry } from 'rxjs/operators';
import { ClientContact } from '../client-contacts/client-contacts.service';
import { MatterQuotationDTO } from '../../services/quotation/quotation.service';
import { IndexedDBService } from '../indexed-db/indexed-db.service';

export interface LawFirm {
  ContactID: number;
  FullName: string;
  ContactTypeID: number;
  City: string;
  State: string;
} // end LawFirm{}

export const DEFAULT_LAW_FIRM: LawFirm = {
  ContactID: 0,
  FullName: '',
  ContactTypeID: 0,
  City: '',
  State: '',
}; // end LawFirm{}

export const LIST_NAME_LAW_FIRMS = 'lawFirms';
export const LIST_NAME_ATTORNEYS = 'attorneys';
export const LIST_NAME_PLAINTIFFS = 'plaintiffs';
export const LIST_NAME_DEFENDANTS = 'defendants';

@Injectable()
export class ListsService {

  lawFirms: any = {};
  attorneys: ClientContact[] = [];
  plaintiffs: string[] = [];
  defendants: string[] = [];
  attorneyRefs: string[] = [];
  instructions: string[] = [];

  private _lawFirms: any = {};
  private lawFirmsInitialized = false;

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private indexedDbService: IndexedDBService
  ) {
    this.initLawFirms().subscribe({next: 
      (lawFirms) => {
        this._lawFirms = lawFirms;
        this.indexedDbService.addData(LIST_NAME_LAW_FIRMS, { id: 'lawFirmsData', data: this._lawFirms })
          .then(() => {
            return this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData');
        })
        .then(retrievedData => {
        })
        .catch(error => console.error('Error storing or retrieving law firms data in IndexedDB a', error));
                },
          error: (error) => {
            const msg = 'Error initializing law firms.';
            console.error(msg, error);
          },
          complete: () => {
            this.lawFirmsInitialized = true;
            this.buildLawFirmsList();
          },
    });
  } // end constructor()

  initLawFirms(): Observable<any> {
    return this.http.get(this.api.endpoints.contacts +
      '?ContactTypeID=' + CONTACT_TYPE_ID_LAWFIRM +
      '&ServiceProviderID=' +  this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end initLawFirms()

  initAttornies(): Observable<any> {
    return this.http.get(this.api.endpoints.contacts +
      '?ContactTypeID=' + CONTACT_TYPE_ID_ATTORNEYS +
      '&ServiceProviderID=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end initAttornies()

  // getLawFirms(): Observable<any> {
  //   return of(this.lawFirms);
  // } // end getLawFirms()

  async  buildLawFirmsList() {
    if (!this.lawFirmsInitialized) {
      return;
    }
    this.lawFirms = [];
    if (this._lawFirms) {
      // this._lawFirms.forEach(firm => {
      //   if (!this.lawFirms[firm.FullName.charAt(0).toUpperCase()]) {
      //     this.lawFirms[firm.FullName.charAt(0).toUpperCase()] = [firm];
      //   } else {
      //     this.lawFirms[firm.FullName.charAt(0).toUpperCase()].push(firm);
      //   }
      // });
      
      this.lawFirms = this._lawFirms;
      try {
        const storedData = await this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData');
        
        // Check if storedData is not present or if its length is less than 3
        if (!storedData || (storedData.data && storedData.data.length < 3)) {
          await this.indexedDbService.addData(LIST_NAME_LAW_FIRMS, { id: 'lawFirmsData', data: this._lawFirms });
        }
      } catch (error) {
        console.error('Error retrieving or storing law firms data from IndexedDB:', error);
        try {
          // Attempt to store the data if it's not present or on error
          await this.indexedDbService.addData(LIST_NAME_LAW_FIRMS, { id: 'lawFirmsData', data: this._lawFirms });
        } catch (storeError) {
          console.error('Error storing law firms data in IndexedDB:', storeError);
        }
      }
    
      // if (localStorage.getItem(LIST_NAME_LAW_FIRMS) && localStorage.getItem(LIST_NAME_LAW_FIRMS).length < 3) {
      // localStorage.setItem(LIST_NAME_LAW_FIRMS, JSON.stringify(this._lawFirms));
      // }
    }
  } // end buildLawFirmsList()

  buildListsFromMatter(matters: Matter[]): Observable<any> {
    if (matters) {
      matters.map(matter => {
        const desc = matter.Description.split(' // ');

        // if (!this.attorneys.includes(matter.NewClientDetail)) {
        //   this.attorneys.push(matter.NewClientDetail);
        // }

        if (desc.length) {
          if (!this.plaintiffs.includes(desc[0])) {
            this.plaintiffs.push(desc[0]);
          }

          if (desc.length > 1) {
            if (!this.defendants.includes(desc[1])) {
              this.defendants.push(desc[1]);
            }
          }
        }

        if (!this.attorneyRefs.includes(matter.ClientRefNo)) {
          this.attorneyRefs.push(matter.ClientRefNo);
        }

        if (!this.instructions.includes(matter.Instructions)) {
          this.instructions.push(matter.Instructions);
        }
      });
    }

    return of({
      lawFirms:     this.lawFirms,
      attorneys:    this.attorneys,
      plaintiffs:   this.plaintiffs,
      defendants:   this.defendants,
      attorneyRefs: this.attorneyRefs,
      instructions: this.instructions
    });
  } // end buildListsFromMatter()

  buildListsFromQuotationMatter(matters: MatterQuotationDTO[]): Observable<any> {
    if (matters) {
      matters.map(matter => {
        const desc = matter.Description.split(' // ');

        if (desc.length) {
          if (!this.plaintiffs.includes(desc[0])) {
            this.plaintiffs.push(desc[0]);
          }

          if (desc.length > 1) {
            if (!this.defendants.includes(desc[1])) {
              this.defendants.push(desc[1]);
            }
          }
        }

        if (!this.attorneyRefs.includes(matter.ClientRefNo)) {
          this.attorneyRefs.push(matter.ClientRefNo);
        }

        if (!this.instructions.includes(matter.Instructions)) {
          this.instructions.push(matter.Instructions);
        }
      });
    }

    return of({
      lawFirms:     this.lawFirms,
      attorneys:    this.attorneys,
      plaintiffs:   this.plaintiffs,
      defendants:   this.defendants,
      attorneyRefs: this.attorneyRefs,
      instructions: this.instructions
    });
  } // end buildListsFromQuotationMatter()
} // end ListsService{}
