import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentTypes } from '../../../services/fee-items/fee-items.service';
import { ClientLetterOfDemand, DocumentServiceService } from '../../../../app/services/documents/document-service.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-document-types-modal',
  templateUrl: './document-types-modal.component.html',
  styleUrls: ['./document-types-modal.component.scss']
})
export class DocumentTypesModalComponent implements OnInit {

  selectedDocumentType = 0;
  lodDocumentId = 0;
  formData: FormData = new FormData();

  @Input() title: string;
  @Input() isVisible: boolean;
  @Input() btnTextCancel: string;
  @Input() btnTextAddFile: string;
  @Input() clientLetterOfDemand: any;
  @Input() documentTypes: DocumentTypes[];
  @Input() attachmentFile: File;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() upload: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private loadingService: LoadingService,
    private documentServices: DocumentServiceService
  ) { } // end constructor()

  get canAddFile(): boolean {
    return Boolean(this.selectedDocumentType > 0);
  } // end canAddFile()

  ngOnInit(): void {
    // console.log('WHAT WE HAVE HERE: ', this.clientLetterOfDemand);
  } // end ngOnInit()

  onCancel() {
     this.selectedDocumentType = 0;
     this.cancel.emit();
  } //  end onCancel()

  onAddFile() {
    this.loadingService.showOverlay();
    this.formData = new FormData();
    this.formData.append('OtherDocument', this.attachmentFile);

    this.documentServices.postOtherDocumentAttachment(this.clientLetterOfDemand.clientId, +this.selectedDocumentType, this.formData)
                         .subscribe({ next: (doc) => {
                          // On next
                          this.lodDocumentId = doc;
                         },
                        error: (error) => {
                          // On error
                          this.formData.delete('OtherDocument');
                          this.loadingService.hideOverlay();;;;;
                        },
                        complete: async () => {
                          // On complete
                          this.selectedDocumentType = 0;
                          await  lastValueFrom(this.documentServices.getClientLetterOfDemand(this.lodDocumentId)).then((lod) => {
                            if (lod) {
                              this.upload.emit(lod);
                            }
                          });
                          this.loadingService.hideOverlay();
                        }
                      });
  } // end onAddFile()

} // end DocumentTypesModalComponent{}
