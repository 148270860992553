<div class="block-title">Advocates</div>
<div class="row reconcile-search input-group">
  <input
    type="search"
    #inputCheckbox
    class="form-control"
    placeholder="Search for advocate"
    [(ngModel)]="searchTerm"
    (keyup)="performSearch()">
    <div class="input-group-append">
      <i *ngIf="!searchTerm" class="far fa-search"></i>
      <i *ngIf="searchTerm" (click)="clearSearch()" class="far fa-close"></i>
    </div> <!-- #end .input-group=append -->
</div> <!-- #end .row .reconcile-search .input-group -->

<div class="row m-0">
  <div class="table-responsive" style="max-height: 216px;">
    <table class="table table-striped">
      <tbody>
        <tr>
          <th (click)="toggleSort('advocate')" class="w-32" scope="col"> Advocate
            <span><i class="fa fa-sort" [class.fa-sort-down]="sortAdvocate"></i></span>
          </th>
          <th (click)="toggleSort('invoice-count')" class="w-5" scope="col"> Inv
            <span><i class="fa fa-sort" [class.fa-sort-down]="sortInvoiceCount"></i></span>
          </th>
          <th (click)="toggleSort('amount')" scope="col"> Amount
            <span><i class="fa fa-sort" [class.fa-sort-down]="sortAmount"></i></span>
          </th>
          <th scope="col">
            <!-- <span style="float: right; min-width: 80px;"><i class="fa fa-sort" [class.fa-sort-down]="sortAdvocate"></i></span> -->
          </th>
        </tr>
        <tr
          class="tr"
          *ngFor="let advocate of advocateDetailHandle"
          [ngStyle]="{'background-color': getInLegalBackgroungColor(advocate.data.IsInLegal)}">
          <td class="w-32" *ngIf="advocate.state.isVisible">{{advocate.data.AdvocateName}}</td>
          <td class="w-5" *ngIf="advocate.state.isVisible">{{advocate.data.InvoiceCount}}</td>
          <td *ngIf="advocate.state.isVisible">
            <div (mouseenter)="onLawfirmSummaryHover(advocate.data)" class="tool-tip">
              <a>{{advocate.data.Outstanding | currency: currencySymbol: currencySymbol:'1.2-2'}}</a>
              <div class="tooltiptext">
                <div class="container mt-5" *ngIf="showTooltipOverlay">
                  <img src="../../../../../assets/images/logos/spinner.gif" height="50" alt="spinner">
                </div>
                <div class="container" *ngIf="showTooltipOverlay===false">
                  <div class="row" style="justify-content: space-between !important; line-height: 0.8 !important;" *ngIf="lawfirmSummaryData">
                    <div class="form-group">
                      <label><b>Last Invoice Date</b></label>
                    </div>
                    <div class="form-group">
                      <label>{{lawfirmSummaryData.LastInvoiceDate | date:'yyyy-MM-dd'}}</label>
                    </div>
                  </div>
                  <div class="row" style="justify-content: space-between !important; line-height: 0.8 !important;" *ngIf="lawfirmSummaryData">
                    <div class="form-group">
                      <label><b>Last Payment Date</b></label>
                    </div>
                    <div class="form-group">
                      <label>{{lawfirmSummaryData.LastTransactionDate | date:'yyyy-MM-dd'}}</label>
                    </div>
                  </div>
                  <div class="row" style="justify-content: space-between !important; line-height: 0.8 !important;" *ngIf="lawfirmSummaryData">
                    <div class="form-group">
                      <label><b>Last Payment Amount</b></label>
                    </div>
                    <div class="form-group">
                      <label>{{lawfirmSummaryData.Amount | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                    </div>
                  </div>
                  <div class="row" style="justify-content: space-between !important; line-height: 0.8 !important;" *ngIf="lawfirmSummaryData">
                    <div class="form-group">
                      <label><b>12 Months Payment Amount</b></label>
                    </div>
                    <div class="form-group">
                      <label>{{lawfirmSummaryData.TotalPaidYear | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                    </div>
                  </div>
                  <div class="row" style="justify-content: space-between !important; line-height: 0.8 !important;" *ngIf="lawfirmSummaryData">
                    <div class="form-group">
                      <label><b>Average Payment</b></label>
                    </div>
                    <div class="form-group">
                      <label>{{lawfirmSummaryData.TotalAverage | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                    </div>
                  </div>
                  <div class="row" style="justify-content: space-between !important; line-height: 0.8 !important;" *ngIf="lawfirmSummaryData">
                    <div class="form-group">
                      <label class="red-text"><b>Partial Paid Invoices</b></label>
                    </div>
                    <div class="form-group">
                      <label class="red-text"><b>{{lawfirmSummaryData.PartialPaidInvoices}}</b></label>
                    </div>
                  </div>
                  <div class="row" style="justify-content: space-between !important; line-height: 0.8 !important;" *ngIf="lawfirmSummaryData">
                    <div class="form-group">
                      <label class="red-text"><b>Unallocated</b></label>
                    </div>
                    <div class="form-group">
                      <label class="red-text"><b>{{lawfirmSummaryData.UnAllocated | currency: currencySymbol: currencySymbol:'1.2-2'}}</b></label>
                    </div>
                  </div>
                  <div class="row" style="justify-content: space-between !important; line-height: 0.8 !important;" *ngIf="lawfirmSummaryData">
                    <div class="form-group">
                      <label><b>Total Due Across</b></label>
                    </div>
                    <div class="form-group">
                      <label>{{lawfirmSummaryData.TotalOutstandingInvoices | currency: currencySymbol: currencySymbol:'1.2-2'}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td *ngIf="advocate.state.isVisible">
            <div class="">
              <div class="" style="min-width: 140px">
                <div class="border-rounded-right"
                  [ngStyle]="{'background-color':  '#8abd37', 'width': getBarWidth(advocate.data.OutstandingPercentage)}">
                  <span class="margin-left"> {{advocate.data.OutstandingPercentage}}%</span>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="isInLegal">
              <i class="fa fa-toggle-on active" id="in-legal" *ngIf="advocate.data.IsInLegal === true" (click)="onInLegalChange(advocate.data)"></i>
              <i class="fa fa-toggle-on fa-rotate-180 inactive" id="in-legal" *ngIf="advocate.data.IsInLegal === false"
                (click)="onInLegalChange(advocate.data)"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div> <!-- #end .table-responsive -->
</div> <!-- #end .row .m-0 -->

<!-- Other outstanding amount -->
<div class="row m-0">
  <div class="table-responsive" style="max-height: 110px;">
    <table class="table table-striped">
      <tbody>
        <tr>
          <th scope="col">Practice Manager</th>
          <th scope="col">Adv</th>
          <th scope="col">Total Outstanding</th>
        </tr>
        <tr class="tr" *ngFor="let otherPractice of otherPracticeManagers">
          <th>{{otherPractice.PracticeManager}}</th>
          <th>{{otherPractice.AdvocateCount}}</th>
          <th>{{otherPractice.Due | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
        </tr>
      </tbody>
    </table> <!-- #end .table .table-striped-->
  </div>
</div> <!-- #end .row m--0 -->

<app-hand-over-to-lawfirm
  [isVisible]="onShowHandOverLawfirm"
  (cancel)="onShowHandOverLawfirmDismiss()"
  (continue)="onLegalChangeConfirm($event)"></app-hand-over-to-lawfirm>