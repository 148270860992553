<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">

<div class="badge" *ngIf="type === types.PLAINTIFFS">
  <input
    required 
    *ngIf="type === types.PLAINTIFFS" 
    [(ngModel)]="matter.PlaintiffFullName" 
    [typeahead]="plaintiffs_list"
    typeaheadOptionField="FullName" 
    [typeaheadSingleWords]="false" 
    (input)="onInput($event)" 
    class="categotyText"
    style="width: 90% !important" 
    placeholder="Select or start typing" 
    (typeaheadOnSelect)="onSelect($event)"
    [typeaheadOptionsLimit]="100" 
    typeaheadScrollable=true 
    typeaheadOptionsInScrollableView="20"
    [typeaheadMinLength]="0">
  <i title="Add New Plaintiff/defendant" class="fa fa-plus-circle fa-lg"
    (click)="addNewPlaintiffsDefendants('plaintiffs')"></i>
</div>

<div class="badge" *ngIf="type === types.DEFENDANTS">
  <input 
    *ngIf="type === types.DEFENDANTS" 
    [(ngModel)]="matter.DefendantFullName" 
    [typeahead]="defendant_list"
    typeaheadOptionField="FullName" 
    (input)="onInput($event)" 
    class="form-control" 
    style="width: 90% !important"
    placeholder="Select or start typing" 
    (typeaheadOnSelect)="onSelect($event)" 
    [typeaheadOptionsLimit]="50"
    typeaheadScrollable=true 
    typeaheadOptionsInScrollableView="20" 
    [typeaheadMinLength]="0">
  <!-- *ngIf="canAddDefendant" -->
  <i title="Add New defendant/Respondent" class="fa fa-plus-circle fa-lg"
    (click)="addNewPlaintiffsDefendants('defendants')"></i>
</div>

<app-add-plaintiffdefendant-modal
  [isVisible]="showAddPlaintiffModal" 
  [(plaintiffDefendant)]="plaintiffDefendant"
  (closeModal)="onCloseModal($event)" 
  (savePlaintiffDefendant)="onSavePlaintiffDefendant($event)">
</app-add-plaintiffdefendant-modal>