import { Input, Output, EventEmitter, OnChanges, SimpleChanges, Injectable } from "@angular/core";
import { UtilitiesService } from "../../services/utilities.service";

export interface IModal {
  isVisible: boolean;
  model: any;
  cancel?: EventEmitter<any>;
  cancelText?: string;
  confirm?: EventEmitter<any>;
  confirmText?: string;
  delete?: EventEmitter<any>;
  deleteText?: string;
  save?: EventEmitter<any>;
  saveText?: string;
} // end IModal{}

@Injectable()
export class Modal implements IModal, OnChanges {
  @Input() isVisible: boolean;
  @Input() model: any;
  @Input() title: string;
  @Input() cancelText: string;
  @Input() confirmText: string;
  @Input() deleteText: string;
  @Input() saveText: string;
  @Input() modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() save: EventEmitter<any> = new EventEmitter<any>();

  workingModel: any;

  constructor(
    protected util: UtilitiesService
  ) {
    this.cancelText = 'Cancel';
    this.confirmText = 'OK';
    this.deleteText = 'Delete';
    this.saveText = 'Save';
  } // end constructor()

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.model, this.workingModel);
  } // end isDirty()

  isSaveable(): boolean {
    return this.isDirty && Boolean(this.workingModel);
  } // isSaveable()

  isDeletable(): boolean {
    return Boolean(this.workingModel);
  } // isDeletable()

  isConfirmable(): boolean {
    return Boolean(this.workingModel);
  } // isConfirmable()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && changes.model.currentValue) {
      if (!changes.model.previousValue) {
        this.workingModel = Object.assign({}, this.model);
      }
    }

    if (changes.cancelText && !changes.cancelText.currentValue) {
      this.cancelText = 'Cancel';
    }
    if (changes.confirmText && !changes.confirmText.currentValue) {
      this.confirmText = 'OK';
    }
    if (changes.deleteText && !changes.deleteText.currentValue) {
      this.deleteText = 'Delete';
    }
    if (changes.saveText && !changes.saveText.currentValue) {
      this.saveText = 'Save';
    }
  } // end ngOnChanges()

  onCancel(event = null) {
    this.cancel.emit(event);
  } // end onCancel()

  onConfirm(event = null) {
    this.confirm.emit(event);
  } // end onConfirm()

  onDelete(event = null) {
    this.delete.emit(event);
  } // end onCancel()

  onSave(event = null) {
    this.save.emit(event);
    this.onCancel();
    // setTimeout((that) => { that.onCancel() }, 300, this);
  } // end onSave()
} // end Modal{}