import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {
  NotesObject,
  NoteCategory,
  DEFAULT_NOTE_CATEGORY,
  NoteTypes,
  NotesService,
  DEFAULT_NOTE_TYPE, Notes, DEFAULT_NOTE, InstructionResponses, DEFAULT_INSTRUCTION_RESPONSES
} from '../../../services/notes/notes.service';
import * as moment from 'moment';
import { Snack, SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { LawfirmSummaryOverview } from '../../../services/reports/reports.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
// import { TypeaheadMatch } from 'ngx-bootstrap';
import { InvoiceDetails } from '../../../services/invoice-details/invoice-details.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

@Component({
  selector: 'app-note-detail-modal',
  templateUrl: './note-detail-modal.component.html',
  styleUrls: ['./note-detail-modal.component.scss']
})
export class NoteDetailModalComponent implements OnInit {

  title: string;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  isInternalChecked: boolean;
  collectorChecked: boolean; // If this is true, we are sending an instruction to a collector
  advocateChecked = true;  // if this is true, we are requesting an instruction to the client.
  collectorNewChecked: boolean;
  advocateNewChecked = true;
  isExternalChecked: boolean;
  alertCollector = true;
  selectedNoteCategoryText: string;
  selectedNoteSubCategoryText: string;
  selectedNoteCategory: NoteCategory;
  selectedNoteSubCategory: NoteTypes;
  selectedActionDate: string;
  internalExternalText = '';
  internalText: string;
  resolutionText: string;

  selectedNote: Notes;
  noteSubCategoryList: NoteTypes[] = [];

  instructionText = '';
  
  @Input() isVisible: boolean;
  @Input() plaintiff: string;
  @Input() selectedResponseText: string;
  @Input() selectedResponse: InstructionResponses;
  @Input() defendant: string;
  @Input() objectType: string;
  @Input() selectedTab: string;
  @Input() _advocateChecked: boolean;
  @Input() noteDetailData: NotesObject;
  @Input() noteCategory: NoteCategory[];
  @Input() invoiceDetails: InvoiceDetails;
  @Input() instructionResponses: InstructionResponses[];
  @Input() lawfirmSummaryData: LawfirmSummaryOverview;
  @Output() closemodal: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateNote: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveNote: EventEmitter<any> = new EventEmitter<any>();
  @Output() issueInstruction: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveInstruction: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputCheckbox') inputCollectionsCheckbox: ElementRef;

  get isExternalInternal(): boolean {
    let isExternal = false;
    if (this.noteDetailData && this.noteDetailData.PriorityText.length > 0) {
      isExternal = true;
    } else {
      isExternal = false;
    }
    return isExternal;
  } // end isExternalInternal()

  // Is Practice Manager
  get isPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  }  // end isPracticeManager()

  get isAdvocateProfile(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ADVOCATE);
  } // isAdvocateProfile()

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private noteService: NotesService,
    private notesService: NotesService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService
  ) {
    this.isExternalChecked = true;
  } // end constructor()

  ngOnInit() {
    console.log('SELECTED NOTE: ', this.noteDetailData);
    if (this.selectedResponse) {
      this.selectedResponseText = this.selectedResponse.Responses;
      const event: any = {
        value: '',
        item: this.selectedResponse
    }
      this.onNoteResponseSelect(event)
    }
    if (this.noteDetailData && this.noteDetailData.Category)
      this.noteDetailData.Category = this.noteDetailData.Category.trim();

    if (this.noteDetailData && this.noteDetailData.Subject)
      this.noteDetailData.Subject = this.noteDetailData.Subject.trim();
    
    this.title = 'Note Detail';
    this.internalExternalText = '';
    this.instructionText = '';
    if (this._advocateChecked) {
      this.collectorNewChecked = this._advocateChecked;
      this.toggleNewColAdvCheckbox('collector');
    }
    // + this.noteDetailData.ObjectTypeDescription +
    // ' - ' + this.noteDetailData.UserOwnerFullName +
    // ' - ' + moment(this.noteDetailData.CaptureDate).format('YYYY-MM-DD') +
    // ' at ' + moment(this.noteDetailData.CaptureDate).format('h:mm:ss a');
  } // end ngOnInit()

  toggleSendToCollection() {
    this.alertCollector = !this.alertCollector;
  } // end toggleSendToCollection()

  onCancel() {
    this.closemodal.emit();
  } // end onCancel()

  noteUserLogger(note: NotesObject): string {
    let user = '';
    if (note.Personal) {
      user = note.UserCreatorFullName;
    } else if (!note.Personal && note.Category === 'Instruction') {
      user = note.UserCreatorFullName;
    } else if (!note.Personal && note.InstructionResponseId > 0) {
      user = note.UserOwnerFullName;
    } else {
      user = note.UserOwnerFullName;
    }
    return user;
  } // noteUserLogger()

  onInputCategory(event) {
    if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
      // this.selectedNoteCategoryText = '';
      this.selectedNoteCategory = Object.assign({}, DEFAULT_NOTE_CATEGORY);
      this.selectedNoteSubCategoryText = '';
      this.internalExternalText = '';
      this.selectedNoteSubCategory = Object.assign({}, DEFAULT_NOTE_TYPE);
      this.noteSubCategoryList = [];
    }
  } // end onInputCategory()

  onInputSubCategory(event) {
    if (event.inputType === 'deleteContdeleteContentBackwardentBackward' || Boolean(event.target.value.length < 3)) {
      // this.selectedNoteSubCategoryText = '';
      this.internalExternalText = '';
      this.selectedNoteSubCategory = Object.assign({}, DEFAULT_NOTE_TYPE);
    }
  } // end onInputSubCategory()

  onInputResponse() {
    // To do, Add invoice details and law firm summary view html view of this component.
  } // end onInputResponse()

  onNoteResponseSelect(event: TypeaheadMatch): void {
    if (event) {
      this.selectedResponse = Object.assign({}, DEFAULT_INSTRUCTION_RESPONSES);
      this.selectedResponse = event.item;
      this.selectedResponseText = event.item.Response;
    }
  } // end onNoteResponseSelect()

  toggleIntExtCheckbox(clicked: string) {
    this.isInternalChecked = !this.isInternalChecked;
  } // end toggleIntExtCheckbox()

  toggleColAdvCheckbox(value: string) {
    switch(value) {
      case 'collector':
        this.advocateChecked = false;
        this.collectorChecked = true;
      break;

      case 'advocate':
        this.advocateChecked = true;
        this.collectorChecked = false;
      break;
    }
  } // end toggleColAdvCheckbox()

  toggleNewColAdvCheckbox(value: string) {
    switch(value) {
      case 'collector':
        this.advocateNewChecked = false;
        this.collectorNewChecked = true;
      break;

      case 'advocate':
        this.advocateNewChecked = true;
        this.collectorNewChecked = false;
      break;
    }
  } // end toggleNewColAdvCheckbox()

  /**
   * Called when the date value has changed.
   * @param {Date} value The new date value.
   */
  onDateValueChange(value: Date): void {
    this.selectedActionDate = value.toDateString();
  } // end onDateValueChange()

  onIssueAnInstructionRequest() {
    this.selectedNote = Object.assign({}, DEFAULT_NOTE);
    this.selectedNote.ObjectId = this.noteDetailData.ObjectId;
    // this.selectedNote.NoteTypeId = this.noteDetailData.ObjectId;
    this.selectedNote.Subject = 'Instruction from PM'
    this.selectedNote.NoteTypeId = 0;
    // if (this.selectedNoteSubCategory.Personal) {
    //   this.selectedNote.InternalText = this.internalExternalText;
    // } else if (!this.selectedNoteSubCategory.Personal) {
    //   this.selectedNote.ExternalText = this.internalExternalText;
    // }
    // if (this.isInternalChecked) {
    //   this.selectedNote.InternalText = this.internalText;
    // }
    if (this.selectedTab !== '98') {
     this.selectedNote.IsVisibleUserOwner = this.alertCollector;
    } else {
      this.selectedNote.IsVisibleUserOwner = false;
    }
    this.selectedNote.InstructionText = this.internalExternalText;
    this.selectedNote.UserAssigned = this.userProfileService.selectedUserProfile.serviceProviderID;
    this.selectedNote.UserOwner = this.userProfileService.userProfile.serviceProviderID;
    this.selectedNote.UserCreator = this.userProfileService.userProfile.serviceProviderID;
    this.selectedNote.Priority = 1;
    this.selectedNote.CaptureDate = moment().format('YYYY-MM-DD HH:mm:ss');
    this.selectedNote.InstructionCaptureDate = moment().format('YYYY-MM-DD HH:mm:ss');
    this.selectedNote.InstructionResponseId = this.selectedResponse.Id;

    // if (this.selectedActionDate) {
      // this.selectedNote.ActionDate = moment(this.selectedActionDate).format('YYYY-MM-DD HH:mm:ss');
    // }
    this.issueInstruction.emit(this.selectedNote);
  } // end onIssueAnInstructionRequest()

  onAddNote() {
    this.selectedNote = Object.assign({}, DEFAULT_NOTE);
    this.selectedNote.ObjectId = this.noteDetailData.ObjectId;
    this.selectedNote.Subject = this.selectedNoteSubCategoryText;
    this.selectedNote.NoteTypeId = this.selectedNoteSubCategory.Id;
    if (this.selectedNoteSubCategory.Personal) {
      this.selectedNote.InternalText = this.internalExternalText;
      this.selectedNote.IsVisibleUserAssigned = false;
      this.selectedNote.IsVisibleUserOwner = false;
    } else if (!this.selectedNoteSubCategory.Personal) {
      this.selectedNote.ExternalText = this.internalExternalText;
    }
    if (this.isInternalChecked) {
      this.selectedNote.InternalText = this.internalText;
    }
    this.selectedNote.UserAssigned = this.userProfileService.selectedUserProfile.serviceProviderID;
    this.selectedNote.UserOwner = this.userProfileService.userProfile.serviceProviderID;
    this.selectedNote.UserCreator = this.userProfileService.userProfile.serviceProviderID;
    this.selectedNote.Priority = this.selectedNoteSubCategory.DefaultPriority;
    this.selectedNote.CaptureDate = moment().format('YYYY-MM-DD HH:mm:ss');
    if (this.selectedActionDate) {
      this.selectedNote.ActionDate = moment(this.selectedActionDate).format('YYYY-MM-DD HH:mm:ss');
    }
    this.saveNote.emit(this.selectedNote);
  } // end onAddNote()

  onForwardInstructionRequest() {
    const noteToSave: Notes = {
      Id: this.noteDetailData.Id,
      ObjectId: this.noteDetailData.ObjectId,
      Subject: this.noteDetailData.Subject,
      NoteTypeId: this.noteDetailData.NoteTypeId,
      InternalText: this.noteDetailData.InternalText,
      ExternalText: this.internalExternalText,
      UserOwner: this.noteDetailData.UserOwner,
      UserCreator: this.noteDetailData.UserCreator,
      UserAssigned: this.noteDetailData.UserAssigned,
      Priority: this.noteDetailData.Priority,
      CaptureDate: this.noteDetailData.CaptureDate,
      ActionDate: this.noteDetailData.ActionDate,
      ActivityDate: this.noteDetailData.ActivityDate,
      InstructionResponseId: 0,
      InstructionText: '',
      ResolutionText: '',
      MutliObjects: null,
      InternalInstructionNote: this.noteDetailData.InternalInstructionNote,
      InstructionCaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      ResolutionCaptureDate: null,
      IsVisibleUserOwner: false,
      IsVisibleUserCreator: false,
      IsVisibleUserAssigned: true
    };
    this.noteDetailData.UserCreatorStatus = true;
    this.noteDetailData.UserOwnerStatus = true;
    this.noteDetailData.UserAssignedStatus = false;
    //  this.notesService.putNoteInboxStatus(this.noteDetailData).toPromise();
    this.updateNote.emit(noteToSave);
    this.closemodal.emit();
  } // end onForwardInstructionRequest()

  onSaveInstructionResponce() {
    const noteToSave: Notes = {
      Id: this.noteDetailData.Id,
      ActionDate: this.noteDetailData.ActionDate,
      CaptureDate: this.noteDetailData.CaptureDate,
      ActivityDate: this.noteDetailData.ActivityDate,
      ExternalText: this.noteDetailData.ExternalText,
      InstructionResponseId: this.selectedResponse.Id,
      InstructionText: this.instructionText,
      InternalText: this.noteDetailData.InternalText,
      NoteTypeId: this.noteDetailData.NoteTypeId,
      ObjectId: this.noteDetailData.ObjectId,
      Priority: this.noteDetailData.Priority,
      Subject: this.noteDetailData.Subject,
      UserAssigned: this.noteDetailData.UserAssigned,
      UserCreator: this.noteDetailData.UserCreator === 0 ? this.userProfileService.userProfile.serviceProviderID : this.noteDetailData.UserCreator,
      UserOwner: this.noteDetailData.UserOwner,
      ResolutionText: '',
      MutliObjects: null,
      InternalInstructionNote: this.noteDetailData.InternalInstructionNote,
      InstructionCaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      ResolutionCaptureDate: null,

      IsVisibleUserOwner: true,
      IsVisibleUserCreator: true,
      IsVisibleUserAssigned: false
    };
    this.noteDetailData.UserCreatorStatus = true;
    this.noteDetailData.UserOwnerStatus = false;
    this.noteDetailData.UserAssignedStatus = true;
    this.notesService.putNoteInboxStatus(this.noteDetailData).subscribe({ next: (_data) => {}});
    this.updateNote.emit(noteToSave);
    this.closemodal.emit();
  } // end onSaveInstructionResponce()

  onResolutionSave() {
    const noteToSave: Notes = {
      Id: this.noteDetailData.Id,
      ObjectId: this.noteDetailData.ObjectId,
      Subject: this.noteDetailData.Subject,
      NoteTypeId: this.noteDetailData.NoteTypeId,
      InternalText: this.noteDetailData.InternalText,
      ExternalText: this.noteDetailData.ExternalText,
      UserOwner: this.noteDetailData.UserOwner,
      UserCreator: this.noteDetailData.UserCreator,
      UserAssigned: this.noteDetailData.UserAssigned,
      Priority: this.noteDetailData.Priority,
      CaptureDate: this.noteDetailData.CaptureDate,
      ActionDate: this.noteDetailData.ActionDate,
      ActivityDate: this.noteDetailData.ActivityDate,
      InstructionResponseId: this.noteDetailData.InstructionResponseId,
      InstructionText: this.noteDetailData.InstructionText,
      ResolutionText: this.resolutionText,
      InternalInstructionNote: this.noteDetailData.InternalInstructionNote,
      MutliObjects: this.noteDetailData.MutliObjects,
      InstructionCaptureDate: this.noteDetailData.InstructionCaptureDate,
      ResolutionCaptureDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      IsVisibleUserOwner: false,
      IsVisibleUserCreator: true,
      IsVisibleUserAssigned: false
    };
    this.noteDetailData.UserAssignedStatus = false;
    this.updateNote.emit(noteToSave);
    //  this.noteService.putNoteInboxStatus(this.noteDetailData).toPromise();
  } // end onResolutionSave()

  onNoteCategorySelect(event: TypeaheadMatch): void {
    if (event) {
      this.selectedNoteCategory = Object.assign({}, DEFAULT_NOTE_CATEGORY);
      this.selectedNoteCategory = event.item;
      this.selectedNoteCategoryText = event.item.Category;
      this.onGetNoteSubCategory(event.item.Id);
    }
  } // end onNoteCategorySelect()

  onNoteSubCategorySelect(event: TypeaheadMatch): void {
    if (event) {
      this.selectedNoteSubCategory = Object.assign({}, DEFAULT_NOTE_TYPE);
      this.selectedNoteSubCategory = event.item;
      this.selectedNoteSubCategoryText = event.item.Name;
      this.internalExternalText = event.item.Description;
    }
  } // end onNoteSubCategorySelect()

  onGetNoteSubCategory(categoryId: number) {
    this.noteService.getNoteSubCategory(categoryId).subscribe({ next: (data) => {
      this.noteSubCategoryList = data;
    },
    error: (error) => {
      // On error
    },
    complete: ()=> {
      // On complete
      this.noteSubCategoryList.sort((a, b) => {
        return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase());
      });
    }
  });
  } // end onGetNoteSubCategory()
} // end NoteDetailModalComponent{}
