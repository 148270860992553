import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-save-invoice-modal',
  templateUrl: './save-invoice-modal.component.html',
  styleUrls: ['./save-invoice-modal.component.scss']
})
export class SaveInvoiceModalComponent implements OnInit {

  @Input() isVisible: boolean;
  @Input() title: string;
  @Input() message: string;
  @Input() btnTextCancel: string;
  @Input() btnTextConfirm: string;
  @Input() btnTextContinue: string;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirm: EventEmitter<any> = new EventEmitter<any>();

  constructor() { } // end constructor()

  ngOnInit() {
    if (!this.title) {
      this.title = 'Send Invoice';
    }

    if (!this.message) {
      this.message =
        'Are you sure you want to proceed? You will not be able to edit the invoice on the next page.';
    }

    if (!this.btnTextConfirm) {
      this.btnTextConfirm = 'Proceed';
    }

    if (!this.btnTextCancel) {
      this.btnTextCancel = 'Cancel';
    }
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onConfirm() {
    this.confirm.emit();
  } // end onConfirm()

} // end SaveInvoiceModalComponent{}
