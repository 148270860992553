import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-unallocated-categories',
  templateUrl: './unallocated-categories.component.html',
  styleUrls: ['./unallocated-categories.component.scss']
})
export class UnallocatedCategoriesComponent implements OnInit {

  title = 'Allocate transactions to law firm';
  categories: any;
  Category = '';

  @Input() isVisible: boolean;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirm: EventEmitter<any> = new EventEmitter<any>();

  constructor() { } // end constructor()

  ngOnInit(): void {
    this.categories = [
      { label: 'The invoice is less than the payment amount.',  value: 'The invoice is less than the payment amount.' },
      { label: 'Reference incomplete.',  value: 'Reference incomplete.' },
      { label: 'Invoice already paid.',  value: 'Invoice already paid.' },
      { label: 'No reference used.',  value: 'No reference used.' },
      { label: 'Incorrect reference.',  value: 'Incorrect reference.' }
  ];
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onConfirm() {
    this.confirm.emit(this.Category);
  } // end onConfirm()

} // end UnallocatedCategoriesComponent{}
