<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2 class="modal-header-title">{{quotationNoteTitle}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> 
      <!-- #end .modal-header -->

      <div class="modal-body form">

        <div class="form-group">
          <label for="note">Note</label>
          <textarea
            [id]="notes"
            [(ngModel)]="workingQuotation.Notes"
            placeholder="Enter you qoutation note here"></textarea>
        </div>
        <!-- #end .form-group -->

      </div> 
      <!-- #end .modal-body .form -->

      <div class="modal-footer">
        <button
          class="btn btn-danger"
          (click)="onCancel()">Cancel</button>
        <span></span>
        <button
          class="btn btn-success"
          (click)="onSaveNote()">Save note</button>
      </div> <!-- #end .modal-footer -->
    
    </div>
    <!-- #end .modal-dialog -->
  </div> 
  <!-- #end .modal-content -->
</div>
<!-- #end .modal -->

<app-prompt-unsaved-changes-modal
  *ngIf="showUnsavedChangesPrompt"
  message="You have unsaved changes. Are you sure you want to leave the page?"
  [isVisible]="showUnsavedChangesPrompt"
  (cancel)="onHideUnsavedChangesPrompt()"
  (continue)="leavePage()">
</app-prompt-unsaved-changes-modal>