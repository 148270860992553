import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import {
  SnackbarsService, Snack, SnackType
} from '../../services/messaging/snackbars/snackbars.service';
import {
  InvoiceDetailsService,
  InvoiceDetails,
  DEFAULT_INVOICE_DETAILS
} from '../../services/invoice-details/invoice-details.service';
import { PATH_MATTERS, PATH_INVOICE_EMAIL, PATH_INVOICE_REVIEW } from '../../services/appdata/app-config.service';
import { InvoiceDocumentService } from '../../services/invoice-document/invoice-document.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../services/utilities.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import * as moment from 'moment';
// import { delay } from 'rxjs/operators';
// import { SubscriptionLike as ISubscription } from 'rxjs';
import { NavigationService } from '../../services/navigation/navigation.service';
import { format } from 'util';
import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
  selector: 'app-advocate-invoice-review',
  templateUrl: './advocate-invoice-review.component.html',
  styleUrls: ['./advocate-invoice-review.component.scss']
})
export class AdvocateInvoiceReviewComponent
  implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('notes') notesElement: ElementRef;
  title = 'Generate invoice';
  previewTitle = 'Tax Invoice (Preview)';

  showSaveInvoicePrompt: boolean;
  invoiceDetails: InvoiceDetails =
    this.util.objectCopy(DEFAULT_INVOICE_DETAILS);
  workingInvoiceDetails: InvoiceDetails =
    this.util.objectCopy(DEFAULT_INVOICE_DETAILS);
  showUnsavedChangesPrompt: boolean;

  private _id: number;
  private _matterId: number;
  private _subscritpions: ISubscription[] = [];
  private _exitRoute: string;
  private _canIgnoreNavigationBlock: boolean;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
    return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  get isDirty(): boolean {
    // TODO: Implement is dirty
    return !this.util.objectIsSame(this.invoiceDetails, this.workingInvoiceDetails);
  } // end isDirty()

  get isSaveable(): boolean {
    return !this.hasInvoiceDateLessThanError;
  } // end isSaveable()

  get hasInvoiceDateLessThanError(): boolean {
    var highDate = moment('1978-12-18');
    this.workingInvoiceDetails.InvoiceLines.forEach(line => {
      if (line.Description !== 'Discount allowed') {
        const lineDate = moment(line.Date);
        if (lineDate.isAfter(highDate)) {
          highDate = lineDate;
        }
      }
    });

    let invoiceDate = new Date(this.workingInvoiceDetails.InvoiceDate);
    let hd = moment(highDate.format('YYYY-MM-DD'));
    let id = moment(invoiceDate.toDateString());
    if (id.isSameOrAfter(hd)) {
      return false;
    }
    return true;
  }

  get isFutureDated(): boolean {
    let invoiceDate = new Date(this.workingInvoiceDetails.InvoiceDate);
    let now = moment().format('YYYY-MM-DD');
    let id = moment(invoiceDate.toDateString());
    if (id.isAfter(now)) {
      return true;
    }
    return false;
  }

  activeScreen: string;
  createInvNoInvDate = false;

  constructor(
    private snackbarsService: SnackbarsService,
    private invoiceDetailsService: InvoiceDetailsService,
    private invoiceDocumentService: InvoiceDocumentService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private util: UtilitiesService,
    private userProfileService: UserProfileService,
    private navigationService: NavigationService
  ) {
    document.title = route.snapshot.data.title;
  } // end constructor()

  ngOnInit() {
    this.loadingService.showOverlay();

    this.activeScreen = this.userProfileService.currentOrRequestedTab;
    let navSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (location.href.includes(PATH_INVOICE_REVIEW)) {
          this._exitRoute = event.url.replace('/', '');

          // if (this.isDirty && !this._canIgnoreNavigationBlock) {
          //   this.navigationService.canNavigate = false;
          //   this.showUnsavedChangesPrompt = true;
          // } else {
          //   this.navigationService.canNavigate = true;
          // }
        }
      }
    });
    this._subscritpions.push(navSub);

    this._id = +this.route.snapshot.paramMap.get('id');
    this._matterId = +this.route.snapshot.paramMap.get('serviceId');
    let gidSub =
      this.invoiceDetailsService.getInvoiceDetails(this._id).subscribe({next: 
      (details) => {
        this.invoiceDetails = details;
        if (this.invoiceDetails.InvoiceDate === null) {
          const d = new Date();
          this.invoiceDetails.InvoiceDate = this.util.parseDate(d.toString());
        }
        this.invoiceDetails.InvoiceLines.sort((a, b) => {
          return a.Date.toUpperCase().localeCompare(b.Date.toUpperCase());
        });
      },
      error: (error) => {
        const msg = 'Error loading invoice details.';
        console.error(msg, error);
        this.snackbarsService.dismiss().make({label: msg, action: null, type: SnackType.ERROR}, 5000).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.loadingService.hideOverlay();
        if (this.invoiceDetails &&
            this.invoiceDetails.InvoiceNote &&
            this.invoiceDetails.InvoiceNote.localeCompare('null') === 0) {
          this.invoiceDetails.InvoiceNote = '';
        }
        this.workingInvoiceDetails = this.util.objectCopy(this.invoiceDetails);
      }
    });
      this._subscritpions.push(gidSub);

    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  } // end ngOnInit()

  ngOnDestroy() {
    this._subscritpions.forEach((sub) => {
      sub.unsubscribe();
    });
  } // end ngOnDestroy()

  ngAfterViewInit() {
    // if (this.notesElement) {
    //   this.notesElement.nativeElement.focus();
    // }
  } // end ngAfterViewInit()

  /**
   * Formats the given contact details for safe displaying in the browser.
   * @param {string} details The contact details.
   */
  formatContactDetails(details: string): string {
    return details.replace(/\r/gi, `<br>`);
  } // end formatContactDetails()

  /**
   * Reopens the associated matter for editing.
   */
  onEditMatterDetails() {
    this.router.navigate([PATH_MATTERS, this._matterId]);
  } // end onEditMatterDetails()

  /**
   * Saves the invoice details.
   */
  onSaveInvoiceDetails() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Saving invoice details...',
      action: null
    };

    this.snackbarsService.snackbarComponent.make(snack, 5000).show();
    // console.log('WORKING_INVOICE_DETAILS: ', moment(this.workingInvoiceDetails.InvoiceDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
    this.workingInvoiceDetails.InvoiceDate = moment(this.workingInvoiceDetails.InvoiceDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    let uidSub = this.invoiceDetailsService
        .updateInvoiceDetails(this.workingInvoiceDetails.Id, this.workingInvoiceDetails)
        .subscribe({ next: (invoiceDetails) => {
          this.workingInvoiceDetails = invoiceDetails;
        },
        error: (error) => {
          const msg = 'Error saving details.';
          console.error(msg, error);
          snack.label = msg;
          snack.action = {
            label: 'Retry',
            handler: this.onSaveInvoiceDetails,
            params: this
          };
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        },
        complete: () => {
          // On Complete
          this.loadingService.hideOverlay();
          snack.label = 'Invoice details saved.';
          this.snackbarsService.dismiss().make(snack).show();
        }
      });
    this._subscritpions.push(uidSub);
  } // end onSaveInvoiceDetails()

  /**
   * Send the invoice to it's recepients.
   */
  onSendViaEmail() {
    this.loadingService.showOverlay();
    this.sentOnSaveInvoiceDetails();

    this.showSaveInvoicePrompt = false;
    this.loadingService.showOverlay();
    // First onSaveInvoiceDetails() then send
    // TODO: implement onSendViaEmail
    const snack: Snack = {
      label: 'Creating Invoice Document...',
      action: null
    };
    setTimeout(() => {
        this.snackbarsService.snackbarComponent.make(snack, 6000).show();
        snack.label = 'Opening ';
        if (this.activeScreen === 'take-on-data')
          {
            this.createInvNoInvDate = true;
          } else {
            this.createInvNoInvDate = false;
          }
        let cidSub = this.invoiceDocumentService
            .createInvoiceDocument(this.workingInvoiceDetails.Id, this.createInvNoInvDate)
            .subscribe({ next: 
              (next) => {
                if (typeof next === 'string') {
                  snack.label += next;
                }
                snack.label = snack.label.trim() + '...';
                this.snackbarsService.snackbarComponent.dismiss();
                this.snackbarsService.snackbarComponent.make(snack, 1500).show();
              },
              error: (error) => {
                this.loadingService.hideOverlay();
                const msg = 'Failed to create PDF document.';
                console.error(msg, error);
                snack.label = msg;
                snack.action = {
                  label: 'retry',
                  handler: this.onSendViaEmail,
                  params: [this]
                };
                snack.type = SnackType.ERROR;
                this.snackbarsService.dismiss().make(snack).show();
              },
              complete: () => {
                this.router.navigate([PATH_INVOICE_EMAIL, this._id, this._matterId]);
                this.loadingService.hideOverlay();
              },
            });
        this._subscritpions.push(cidSub);
        }, 3000);
  } // end onSendViaEmail()

  /**
   * Called when the value of the date changes.
   * @param {*} event The date change event.
   */
  onDateChange(invoiceDetail: InvoiceDetails, value: Date): void {
    invoiceDetail.InvoiceDate = value ? moment(value).format() : '';
    if (value) {
      this.workingInvoiceDetails.InvoiceDate = moment(value).format();
    }
    // console.log('SELECTED_WORKING_INVOICE_DETAILS: ', moment(this.workingInvoiceDetails.InvoiceDate).format());
  } // end onDateChange()

  /**
   * Parses a date into a display 'safe' format.
   * @param {string} date The date to be parse.
   */
  parseDate(date: string)  {
    return this.util.parseDate(date);
  } // end parseDate()

  onShowSaveInvoiceModal() {
    this.showSaveInvoicePrompt = true;
  } // end onShowCalendarItemModal()

  onHideSaveInvoiceModal() {
    this.showSaveInvoicePrompt = false;
  } // end onHideCalendarITemModal()

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end onHideUnsavedChangesPrompt()

  leavePage() {
    this.onHideUnsavedChangesPrompt();
    this._canIgnoreNavigationBlock = true;

    setTimeout((thisObj) => {
      this.router.navigate([this._exitRoute]);
    }, 200, this);
  } // end leavePage()

  //#region Saving on Send
  sentOnSaveInvoiceDetails() {
    this.workingInvoiceDetails.InvoiceDate = moment(this.workingInvoiceDetails.InvoiceDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    let uidSub = this.invoiceDetailsService
      .updateInvoiceDetails(this.workingInvoiceDetails.Id, this.workingInvoiceDetails)
      .subscribe({ next: (invoiceDetails) => {
        this.workingInvoiceDetails = invoiceDetails;
      },
        error: (error) => {
        },
        complete: () => {
          // On Complete
        }
      });
    this._subscritpions.push(uidSub);
  } // end onSaveInvoiceDetails()
  //#endregion
} // end AdvocateInvoiceReviewComponent{}
