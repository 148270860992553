import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationStart } from '@angular/router';
import {
  MattersService,
  Matter,
  DEFAULT_MATTER,
  BriefDocuments
} from '../../services/matters/matters.service';
import {
  FeeItemsService,
  Fee,
  DocumentTypes,
  DEFAULT_FEE,
} from '../../services/fee-items/fee-items.service';
import {
  UserProfileService,
  UserProfile
} from '../../services/user-profile/user-profile.service';
import {
  SnackbarsService,
  Snack,
  SnackType
} from '../../services/messaging/snackbars/snackbars.service';
import {
  LENGTH_LONG
} from '../../services/messaging/toast/toast.service';
import {
  ListsService,
  LawFirm,
  LIST_NAME_LAW_FIRMS
} from '../../services/lists/lists.service';
import {
  PATH_MATTERS,
  PATH_INVOICE_REVIEW,
  PATH_DASHBOARD,
  PATH_INVOICE_EMAIL,
  PATH_ADVOCATE_INVOICE_PREVIEW,
  PATH_QUOTATION,
  PATH_QUOTATION_PREVIEW
} from '../../services/appdata/app-config.service';
import { DefendantsService } from '../../services/defendants/defendants.service';
import { PlaintiffsService, Plaintiff } from '../../services/plaintiffs/plaintiffs.service';
import { SubCategoriesService, SubCategory } from '../../services/sub-categories/sub-categories.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../services/utilities.service';
import { NavigationService } from '../../services/navigation/navigation.service';
// import { SubscriptionLike as ISubscription } from 'rxjs';
import { MatterDetailsComponent } from '../../components/tables/matter-details/matter-details/matter-details.component';
import { MatterFeesTableComponent } from '../../components/tables/matter-fees-table/matter-fees-table/matter-fees-table.component';
import { delay } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { OtherClients, OtherClientsService } from '../../services/other-clients/other-clients.service';
import { InvoiceDocumentService } from '../../services/invoice-document/invoice-document.service';
import { InvoiceDetailsService, InvoiceDetails, InvoiceLine } from '../../services/invoice-details/invoice-details.service';
import { AddInvoiceNoteComponent } from '../../components/modals/add-invoice-note/add-invoice-note.component';
import { InvoicesService, Invoice, DEFAULT_INVOICE, InvoiceNote } from '../../services/invoices/invoices.service';
import {
  LoadingOverlayModalOptions,
  DEFAULT_LOADINGOVERLAY_OPTIONS
} from '../../components/modals/loading-overlay-modal/loading-overlay-modal.component';
import { DEFAULT_ACTIVITY_LOGS, ActivityLogs, ActivityLogsService } from '../../services/activity-logs/activity-logs.service';
import * as moment from 'moment';
import { UsercurrencyPipe } from '../../pipe/usercurrency.pipe';
import { HttpClient } from '@angular/common/http';
import { AdvocateDetailStatementService,
  ICrumb } from '../../services/advocate-detail-statement/advocate-detail-statement.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import {
  QuotationService,
  MatterQuotationDTO,
  DEFAULT_MATTER_QUOTATION_DTO,
  QuotationSummary,
  QuotationFeeItem,
  QuotationServiceLink,
  DEFAULT_QUOTATION_SERVICE_LINK
} from '../../services/quotation/quotation.service';
import { BOOL_TYPE } from '@angular/compiler/src/output/output_ast';
import { IndexedDBService } from '../../services/indexed-db/indexed-db.service';

@Component({
  selector: 'app-advocate-quotation',
  templateUrl: './advocate-quotation.component.html',
  styleUrls: ['./advocate-quotation.component.scss']
})
export class AdvocateQuotationComponent implements OnInit {
  newMatterQuotationId: number;
  selectedFeeTab: string;
  isOnLoading = false;
  lawFirms: LawFirm[] = [];
  attorneys: string[] = [];
  plaintiffs: Plaintiff[] = [];
  otherClients: OtherClients[] = [];
  defendants: string[] = [];
  attorneyRefs: string[] = [];
  newMatterId: number;
  instructions: string[] = [];
  selectedMatterQuotationId = 0;
  showDeleteQuotationModal: boolean;
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);
  selectedMatterQuotation: MatterQuotationDTO = Object.assign({}, DEFAULT_MATTER_QUOTATION_DTO);
  workingMatterQuotation: MatterQuotationDTO = Object.assign({}, DEFAULT_MATTER_QUOTATION_DTO);
  quotationServiceLink: QuotationServiceLink = Object.assign({}, DEFAULT_QUOTATION_SERVICE_LINK);

  actualFees: Fee[] = [];
  actualService: Matter = Object.assign({}, DEFAULT_MATTER);
  crumbs: ICrumb[];
  userProfile: UserProfile;
  private _matters: MatterQuotationDTO[];

  private _exitRoute: string;
  showUnsavedChangesPrompt: boolean;
  private _canIgnoreNavigationBlock: boolean;
  private _subscriptionNavigationStart: ISubscription;

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  showFeeTotalExceedQuotedPrompt: boolean;
  subCategories: SubCategory[] = [];
  quotationFees: QuotationFeeItem[] = [];
  workingQuotationFees: QuotationFeeItem[] = [];
  actualQuotationFees: QuotationFeeItem[] = [];
  workingActualQuotationFees: QuotationFeeItem[] = [];
  invoice: Invoice = Object.assign({}, DEFAULT_INVOICE);

  showAddQuotationNoteModal: boolean;
  showAddActualQuotationNoteModal: boolean;
  quotationSummary: QuotationSummary;
  showGenerateActualFeesPrompt: boolean;
  showGenerateQuotationFeesPrompt: boolean;
  showQuotationAcceptRejectReason: boolean;
  manualAcceptReject: string;

  isDropup = true;

  get isDirty(): boolean {
    return !this.util.objectIsSame(this.selectedMatterQuotation, this.workingMatterQuotation) ||
    !this.util.objectIsSame(this.quotationFees, this.workingQuotationFees) ;
  } // end isDirty()

  get isActualDirty(): boolean {
    return !this.util.objectIsSame(this.actualQuotationFees, this.workingActualQuotationFees) ||
      !this.util.objectIsSame(this.selectedMatterQuotation, this.workingMatterQuotation);
  } // end isActualDirty()

  get isSaveable(): boolean {
    return this.isDirty &&
    Boolean(this.workingMatterQuotation.LawfirmId) &&
    !Boolean(this.workingQuotationFees.find(s =>
        s.Rate.toString().startsWith('-'))) &&
    Boolean(this.workingMatterQuotation.PlaintiffFullName) &&
    Boolean(this.workingMatterQuotation.ClientRefNo) &&
    (Boolean(this.workingQuotationFees.length === 0) ? true :
      !Boolean(this.workingQuotationFees.find(fee =>
        fee.FurtherDescription.length === 0))
        && Boolean(this.workingMatterQuotation.QuotationLinkId === 0));
  } // end isSaveable()

  get isActualSaveable(): boolean {
    return this.isActualDirty &&
    !Boolean(this.workingActualQuotationFees.find(s =>
        s.Rate.toString().startsWith('-')))  &&
    (Boolean(this.workingActualQuotationFees.length === 0) ? true :
      !Boolean(this.workingActualQuotationFees.find(fee =>
        fee.FurtherDescription.length === 0)));
  } // end isActualSaveable()

  get isAttorney(): boolean {
    let attorneyProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      attorneyProfile =
        Boolean(this.userProfileService.selectedUserProfile.profileType ===
          this.profileTypes.ATTORNEY);
    } else {
      attorneyProfile =
        Boolean(this.userProfileService.userProfile.profileType ===
          this.profileTypes.ATTORNEY);
    }
    return attorneyProfile;
  } // end isAttorney()

  get isLawfirm(): boolean {
    let lawfirmProfile = false;
    if (this.userProfileService.userProfile.isImpersonator) {
      lawfirmProfile =
        Boolean(this.userProfileService.selectedUserProfile.profileType ===
          this.profileTypes.LAWFIRM);
    } else {
      lawfirmProfile =
        Boolean(this.userProfileService.userProfile.profileType ===
          this.profileTypes.LAWFIRM);
    }
    return lawfirmProfile;
  } // end isLawfirm()

  get isDeletable(): boolean {
    return Boolean(this.workingMatterQuotation.MatterQuotationId);
  } // end isDeletable()

  get canAcceptReject(): boolean {
    return Boolean(this.workingMatterQuotation.Status === 'Sent') &&
            Boolean(this.workingMatterQuotation.QuotationStateStatus === 'Pending');
  } // end canAcceptReject()

  get canAddNote(): boolean {
    return !Boolean(this.workingQuotationFees.length === 0) &&
    Boolean(this.workingMatterQuotation.MatterQuotationId > 0)
    && Boolean(this.workingMatterQuotation.QuotationLinkId === 0);
  } // end canAddNote()

  get hasFeesOnThisMatter(): boolean {
    let hasFees = false;

    if (this.workingQuotationFees.length) {
      this.workingQuotationFees.forEach(fee => {
        if (fee.FeeItemId) {
          hasFees = true;
        }
      });
    }
    return hasFees;
  } // end hasFeesOnThisMatter()

  get hasActualFeesOnThisMatter(): boolean {
    let hasFees = false;

    if (this.workingActualQuotationFees.length) {
      this.workingActualQuotationFees.forEach(fee => {
        if (fee.FeeItemId) {
          hasFees = true;
        }
      });
    }
    return hasFees;
  } // end hasFeesOnThisMatter()

  get quotationFeeReadOnly(): Boolean {
    return this.workingMatterQuotation.QuotationLinkId > 0;
  } // end quotationFeeReadOnly()

  get workingQuotationFeeTotal(): number {
    let total = 0;
    this.workingQuotationFees.forEach(data => {
      total += data.Total;
    });
    return total;
  } // end workingQuotationFeeTotal()

  get workingActualQuotationFeeTotal(): number {
    let total = 0;
    this.workingActualQuotationFees.forEach(data => {
      total += data.Total;
    });
    return total;
  } // end workingActualQuotationFeeTotal()

  get isGeneratable(): boolean {
    return this.hasFeesOnThisMatter &&
      !Boolean(this.isDirty) &&
      // !Boolean(this.workingQuotationFees.find(s => s.Rate.toString().startsWith('-'))) &&
      !Boolean(this.workingQuotationFees.find(s => s.FurtherDescription.length === 0)) &&
      Boolean(this.workingMatterQuotation.MatterQuotationId !== 0) &&
      Boolean(this.workingMatterQuotation.PlaintiffFullName !== '') &&
      Boolean(this.workingMatterQuotation.ClientRefNo !== '');
  } // end isGeneratable()

  get isActualGeneratable(): boolean {
    return this.hasActualFeesOnThisMatter &&
      !Boolean(this.isActualDirty) &&
      !Boolean(this.workingActualQuotationFees.find(s => s.Rate.toString().startsWith('-'))) &&
      !Boolean(this.workingActualQuotationFees.find(s => s.FurtherDescription.length === 0));
  } // end isActualGeneratable()

  constructor(
    private router: Router,
    private api: ApiService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    private listsService: ListsService,
    private navigation: NavigationService,
    private mattersService: MattersService,
    private loadingService: LoadingService,
    private invoiceService: InvoicesService,
    private feeItemsService: FeeItemsService,
    private snackbarsService: SnackbarsService,
    private indexedDbService: IndexedDBService,
    private quotationService: QuotationService,
    private defendantsService: DefendantsService,
    private plaintiffsService: PlaintiffsService,
    private userProfileService: UserProfileService,
    private otherClientService: OtherClientsService,
    private activityLogService: ActivityLogsService,
    private subCategoriesService: SubCategoriesService,
    private invoiceDetailService: InvoiceDetailsService,
    private invoiceDocumentService: InvoiceDocumentService,
    private advocateDetailStatementService: AdvocateDetailStatementService
  ) {
   } // end constructor()

  ngOnInit() {
    this.isOnLoading = true;
    this.loadingService.showOverlay();
    // this.lawFirms = JSON.parse(localStorage.getItem(LIST_NAME_LAW_FIRMS));
    this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData').then((data) => {
      if (data) {
          this.lawFirms = data.data; // Assuming your data is stored under the 'data' key
      } else {
          this.lawFirms = []; // Or any default value you wish to set
      }
  }).catch((error) => {
      console.error('Error retrieving law firms from IndexedDB', error);
      this.lawFirms = []; // Setting a default value in case of an error
  });

    this._subscriptionNavigationStart = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart && this.isDirty) {
        this._exitRoute = event.url;
        if (location.href.includes(PATH_QUOTATION)) {
          if (this.isDirty && !this._canIgnoreNavigationBlock) {
            this.navigation.canNavigate = false;
            this.showUnsavedChangesPrompt = true;
          } else {
            this.navigation.canNavigate = true;
          }
        }
      }
    });

    if (!this.userProfile) {
      const profile = this.userProfileService.userProfile;
      if (profile.isImpersonator) {
        this.userProfile = this.userProfileService.selectedUserProfile;
      } else {
        this.userProfile = this.userProfileService.userProfile;
      }

    }

    this.selectedMatterQuotationId = +this.route.snapshot.paramMap.get('id');
    if (this.selectedMatterQuotationId > 0) {

      this.quotationService.getSelectedMatterQuotation(this.selectedMatterQuotationId).subscribe({
        next: (quotation) => {
          this.selectedMatterQuotation = quotation;
          this.crumbs = [
            {
              label: 'Dashboard',
              link: PATH_DASHBOARD
            },
            {
              label: this.selectedMatterQuotation.Description
            }
          ];
        },
          error: (error) => {
          },
          complete: () => {
            this.workingMatterQuotation = Object.assign({}, this.selectedMatterQuotation);
          }
      });
      // this.listsService.buildListsFromQuotationMatter(this.workingMatterQuotation).subscribe(
      //   matter()
      // )
    } else {
      this.crumbs = [
        {
          label: 'Dashboard',
          link: PATH_DASHBOARD
        },
        {
          label: 'NEW QUOTATION & FEES'
        }
      ];
      // this.selectedMatterQuotation = Object.assign({}, DEFAULT_MATTER_QUOTATION_DTO);
      this.selectedMatterQuotation.MatterQuotationDate = new Date().toISOString();
      this.selectedMatterQuotation.HourlyRate =
      this.userProfile.billingDetails.defaultHourlyRate;
      this.selectedMatterQuotation.DailyRate =
      this.userProfile.billingDetails.defaultDailyRate;
      this.selectedMatterQuotation.PerKilometerRate =
      this.userProfile.billingDetails.defaultPerKilometerRate;

      // if (this.userProfileService.userProfile.impersonates) {
      //   this.selectedMatterQuotation.PaymentTerms = +this.userProfileService.selectedUserProfile.settings.aging;
      // } else {
        this.selectedMatterQuotation.PaymentTerms = 0;
      // }
      this.workingMatterQuotation = Object.assign({}, this.selectedMatterQuotation);
    }
    // this.selectedMatterQuotation.ActualDiscount = 0;
    // this.selectedMatterQuotation.Discount = 0;

    this.quotationService.getMatterQuotation().subscribe({ next:
      (quotations) => {
        // On next
        this._matters = quotations;

        // To do **** Get Invoice ****

        this.otherClientService
          .getOtherClients()
          .subscribe(otherClients => {
            this.otherClients = otherClients.filter(a => a.Flag === true);
            this.sortOtherClients();
        });
        this.defendantsService
          .getDefendants()
          .subscribe(defendants => {
            defendants.sort((a, b) => {
              return a.FullName.toUpperCase().localeCompare(b.FullName.toUpperCase());
            });
        });

        if (this._matters) {
          this.listsService.buildListsFromQuotationMatter(this._matters)
          .subscribe(matter => {
            this.attorneys = matter.attorneys;
            this.attorneyRefs = matter.attorneyRefs;
            this.instructions = matter.instructions;
          });
        }
      },
      error: (error) => {
        // On error
      },
      complete: () => {
        // On complete
        this.isOnLoading = false;
    }
  });
    if (this.selectedMatterQuotationId) {
      this.quotationFees = [];
      this.quotationService.getQuotationFees(this.selectedMatterQuotationId)
      .subscribe({ next: (fees) => {
        // On next
        this.quotationFees = fees;
        this.quotationFees.sort((a, b) => {
          return a.LineDate.toUpperCase().localeCompare(b.LineDate.toUpperCase());
        });
      },
      error: (error) => {
        // On error
      },
      complete: () => {
        // On complete
        this.quotationService.getQuotationDiscount(this.selectedMatterQuotationId).subscribe({
          next: (_quotation) => {
            const discount =  _quotation;
            if (discount) {
              this.selectedMatterQuotation.Discount = discount.Total;
              this.workingMatterQuotation = Object.assign({}, this.selectedMatterQuotation);
            }
          }
        })
        this.workingQuotationFees = this.util.objectCopy(this.quotationFees);
      }
    });

      if (this.workingMatterQuotation && this.workingMatterQuotation.QuotationLinkId > 0) {
        this.quotationService.getActualQuotationFees(this.workingMatterQuotation.ActualQuotationId)
        .subscribe({ next: (actual) => {
          // On next
          this.actualQuotationFees = actual;
          this.actualQuotationFees.sort((a, b) => {
            return a.LineDate.toUpperCase().localeCompare(b.LineDate.toUpperCase());
          });
        },
        error: (error) => {
          // On error
        },
        complete: () => {
          this.quotationService.getActualQuotationDiscount(this.selectedMatterQuotationId).subscribe({
            next: (_actualQuotation) => {
              const actualDiscount = _actualQuotation;
              if (actualDiscount) {
                this.selectedMatterQuotation.ActualDiscount = actualDiscount.Total;
                this.workingMatterQuotation = Object.assign({}, this.selectedMatterQuotation);
              }
            }
          })
          this.workingActualQuotationFees = this.util.objectCopy(this.actualQuotationFees);
        }
      });
      }
    }

    //#region Fetch Subcategories
    this.subCategoriesService.getSubCategoryAll().subscribe({ next:
      (subCategories) => {
        // On next
        this.subCategories = subCategories;
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.loadingService.hideOverlay();
      this.subCategories.sort((a, b) => {
        return a.Category.localeCompare(b.Category) || a.SubCategory.localeCompare(b.SubCategory);
      });
    }
  });
    //#endregion Fetch Subcategories
  } // end ngOnInit()

  closeModal(modal: string) {
    switch (modal) {
      case 'add-quotation-note':
        this.showAddQuotationNoteModal = false;
      break;

      case 'delete-quotation':
        this.showDeleteQuotationModal = false;
      break;

      case 'add-actual-quotation-note':
        this.showAddActualQuotationNoteModal = false;
      break;
      case 'quotation-note':
        this.showQuotationAcceptRejectReason = false;
      break;
    }
  } // end closeModal()

  onHideFeeTotalExceedQuotedPrompt() {
    this.showFeeTotalExceedQuotedPrompt = false;
  } // end onHideFeeTotalExceedQuotedPrompt()

  onSelectedTab(tab: string) {
    this.selectedFeeTab = tab;
  } // end onSelectedTab()

  onAddInvoiceNote() {
    this.loadingService.showOverlay();
    this.quotationService.getQuotation(this.workingMatterQuotation.MatterQuotationId)
    .subscribe({ next: (quotation) => {
      // On next
      this.quotationSummary = quotation;
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.showAddQuotationNoteModal = true;
      this.loadingService.hideOverlay();
    }
  });
  } // end onAddInvoiceNote()

  onAddActualNote() {
    this.loadingService.showOverlay();
    this.quotationService.getActualQuotation(this.workingMatterQuotation.ActualQuotationId)
    .subscribe({ next: (quotation) => {
      // On next
      this.quotationSummary = quotation;
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.showAddActualQuotationNoteModal = true;
      this.loadingService.hideOverlay();
    }
  });
  } // end onAddActualNote()

  initMatterQuotation() {
    this.selectedMatterQuotation = this.util.objectCopy(this.workingMatterQuotation);
  } // end initMatterQuotation()

  initQuotationFees() {
    this.quotationFees = this.util.objectCopy(this.workingQuotationFees);
  } // end initQuotationFees()

  sortOtherClients() {
    this.otherClients.sort((a, b) => {
      return a.FullName.toUpperCase().localeCompare(b.FullName.toUpperCase());
    });
  } // end sortOtherClients()

  onHideUnsavedChangesPrompt() {
    this.showUnsavedChangesPrompt = false;
  } // end onHideUnsavedChangePrompt()

  leavePage() {
    this._canIgnoreNavigationBlock = true;
    this.onHideUnsavedChangesPrompt();
    setTimeout((thisObj) => {
      thisObj.router.navigate([this._exitRoute.replace('/', '')]);
    }, 300, this);
  } // end leavePage()

  //#region All Save, Edit & Delete api calls
  save() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Saving quotation...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();

    // Quotation details to save
    this.workingMatterQuotation.Description =
      this.workingMatterQuotation.PlaintiffFullName;
    if (this.workingMatterQuotation.DefendantFullName) {
      this.workingMatterQuotation.Description += ' // ' + this.workingMatterQuotation.DefendantFullName;
    }
    this.workingMatterQuotation.ServiceProviderId = this.api.serviceProviderID;
    if (this.workingMatterQuotation.MatterQuotationId) {
      // Log activity
      const currentDate = new Date();
      this.activityLog.Action = 'Updating Quotation';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-quotations)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'Matter Quotation, Quotation';
      this.activityLog.JsonData = JSON.stringify(this.workingMatterQuotation);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_save_activity) => {}});

      this.workingMatterQuotation.PaymentTerms = Number(this.workingMatterQuotation.PaymentTerms);
      this.quotationService.updateQuotation(this.workingMatterQuotation.MatterQuotationId,
        this.workingMatterQuotation).subscribe({ next: (savedMatter) => {
          // On next
          this.newMatterQuotationId = savedMatter.MatterQuotationId;
          this.workingMatterQuotation = Object.assign(this.workingMatterQuotation, savedMatter);
        },
        error: (error) => {
          // On error
          snack.label = 'Matter quotation update failed.';
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack).show();
        },
        complete: () => {
          // On complete
          this.initMatterQuotation();
          snack.label = 'Matter quotation saved.';
          this.snackbarsService.dismiss().make(snack).show();
          this.saveFeeItems();
        }
      });
    } else {
      const currentDate = new Date();
      this.activityLog.Action = 'Create new Quotation';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-quotations)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'Matter Quotation, Quotation';
      this.activityLog.JsonData = JSON.stringify(this.workingMatterQuotation);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_save_activity) => {}});

      this.workingMatterQuotation.PaymentTerms = Number(this.workingMatterQuotation.PaymentTerms);
      this.quotationService.addQuotationMatter(this.workingMatterQuotation)
      .subscribe({ next: (matter) => {
        // On next
        this.newMatterQuotationId = matter.MatterQuotationId;
        this.workingMatterQuotation = Object.assign(this.workingMatterQuotation, matter);
      },
      error: (error) => {
        // On error
        snack.label = 'Saving quotation failed.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.initMatterQuotation();
        snack.label = 'Quotation saved.';
        this.snackbarsService.dismiss().make(snack).show();
        this.saveFeeItems();
      }
    });
    }
  } // end save()

  saveFeeItems() {
    if (this.workingQuotationFees.length > 0) {
      const snack: Snack = {
        label: 'Saving Fees...',
        action: null
      };
      this.snackbarsService.dismiss().make(snack, 5000).show();
      this.workingQuotationFees.forEach((fee, index) => {
        fee.MatterQuotationId = this.workingMatterQuotation.MatterQuotationId;
        fee.ServiceProviderId = this.workingMatterQuotation.ServiceProviderId;
        fee.MatterQuotationStatus = this.workingMatterQuotation.Status;
        if (!fee.IsVatable) {
          fee.IsVatable = false;
        }
      });
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Add or edit fees';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-quotation)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'QuotationLine';
      this.activityLog.JsonData = JSON.stringify(this.workingQuotationFees);
      // this.activityLogService.addActivityLog(this.activityLog).toPromise();
      this.quotationService.updateQuotationFees(this.workingQuotationFees)
      .subscribe({ next: (savedFees) => {
        // On next
        this.workingQuotationFees =  Object.assign(this.workingQuotationFees, savedFees);
      },
      error: (error) => {
        // On error
        snack.label = 'Error saving fees.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.initQuotationFees();
        this.workingQuotationFees.sort((a, b) => a.LineDate.toUpperCase().localeCompare(b.LineDate.toUpperCase()));
        snack.label = 'Quotation fees saved.';
        this.snackbarsService.dismiss().make(snack).show();
        if (this.newMatterQuotationId) {
          this.router.navigate([PATH_QUOTATION, this.newMatterQuotationId]);
        }
        this.loadingService.hideOverlay();
      }
    });
    } else {
      if (this.newMatterQuotationId) {
        this.router.navigate([PATH_QUOTATION, this.newMatterQuotationId]);
      }
      this.loadingService.hideOverlay();
    }
  } // end saveFeeItems()

  onWorkingQuoteChange(fees: QuotationFeeItem[]) {
    this.workingQuotationFees = [];
    this.workingQuotationFees = fees;
  } // end onWorkingQuoteChange()

  onWorkingActualQuoteChange(fees: QuotationFeeItem[]) {
    this.workingActualQuotationFees = [];
    this.workingActualQuotationFees = fees;
  } // end onWorkingActualQuoteChange()

  saveActual() {
    this.loadingService.showOverlay();
    this.quotationService.updateQuotation(this.workingMatterQuotation.MatterQuotationId,
      this.workingMatterQuotation).subscribe({next: (_update_quotation) => {
        this.workingMatterQuotation = _update_quotation;
      },
      error: (error) => {
        // On error
      },
      complete: () => {
        this.initMatterQuotation();
        if (this.workingActualQuotationFees.length > 0) {
          if (this.workingMatterQuotation.QuotationLinkId > 0) {
            if (Number(this.workingActualQuotationFeeTotal) > Number(this.workingQuotationFeeTotal)) {
              this.showFeeTotalExceedQuotedPrompt = true;
              this.loadingService.hideOverlay();
              return;
            }
          }
          const snack: Snack = {
            label: 'Saving Fees...',
            action: null
          };
          this.snackbarsService.dismiss().make(snack, 5000).show();
          this.workingActualQuotationFees.forEach((fee, index) => {
            fee.MatterQuotationId = this.workingMatterQuotation.MatterQuotationId;
            fee.ServiceProviderId = this.workingMatterQuotation.ServiceProviderId;
            fee.MatterQuotationStatus = this.workingMatterQuotation.Status;
            if (!fee.IsVatable) {
              fee.IsVatable = false;
            }
          });
          // Log activity Login
          const currentDate = new Date();
          this.activityLog.Action = 'Add or edit fees';
          this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
          this.activityLog.LoggedApp = 'Web Application (Advocate-quotation)';
          if (this.userProfileService.userProfile.isImpersonator) {
            this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
          } else {
            this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
            this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
          }
          this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
          this.activityLog.ActionTable = 'ActualQuotationLine';
          this.activityLog.JsonData = JSON.stringify(this.workingQuotationFees);
          // this.activityLogService.addActivityLog(this.activityLog).toPromise();
          this.quotationService.updateActualQuotationFees(this.workingActualQuotationFees)
          .subscribe({ next: (savedFees) => {
            // On next
            // this.workingActualQuotationFees = Object.assign(this.workingActualQuotationFees, savedFees);
          },
          error: (error) => {
            // On error
            snack.label = 'Error saving fees.';
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack).show();
            this.loadingService.hideOverlay();
          },
          complete: () => {
            // On complete
            this.quotationService.getActualQuotationFees(this.workingMatterQuotation.ActualQuotationId)
            .subscribe({ next: (actual) => {
              // On next
              this.actualQuotationFees = actual;
              this.actualQuotationFees.sort((a, b) => {
                return a.LineDate.toUpperCase().localeCompare(b.LineDate.toUpperCase());
              });
            },
            error: (error) => {
              // On error
            },
            complete: () => {
              this.workingActualQuotationFees = this.util.objectCopy(this.actualQuotationFees);
            this.workingActualQuotationFees.sort((a, b) => a.LineDate.toUpperCase().localeCompare(b.LineDate.toUpperCase()));
            snack.label = 'Quotation fees saved.';
            this.snackbarsService.dismiss().make(snack).show();
            // if (this.newMatterQuotationId) {
              this.router.navigate([PATH_QUOTATION, this.workingMatterQuotation.MatterQuotationId]);
            // }
            this.loadingService.hideOverlay();
            }
          });
          }
        });
        } else {
          this.loadingService.hideOverlay();
        }

      }
    });
  } // end saveActual()

  ignoreAndSave() {
    if (this.workingActualQuotationFees.length > 0) {
      this.loadingService.showOverlay();
      this.showFeeTotalExceedQuotedPrompt = false;
      const snack: Snack = {
        label: 'Saving Fees...',
        action: null
      };
      this.snackbarsService.dismiss().make(snack, 5000).show();
      this.workingActualQuotationFees.forEach((fee, index) => {
        fee.MatterQuotationId = this.workingMatterQuotation.MatterQuotationId;
        fee.ServiceProviderId = this.workingMatterQuotation.ServiceProviderId;
        fee.MatterQuotationStatus = this.workingMatterQuotation.Status;
        if (!fee.IsVatable) {
          fee.IsVatable = false;
        }
      });
      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Add or edit fees';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-quotation)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'ActualQuotationLine';
      this.activityLog.JsonData = JSON.stringify(this.workingQuotationFees);
      // this.activityLogService.addActivityLog(this.activityLog).toPromise();
      this.quotationService.updateActualQuotationFees(this.workingActualQuotationFees)
      .subscribe({ next: (savedFees) => {
        // On next
        this.workingActualQuotationFees = Object.assign(this.workingActualQuotationFees, savedFees);
      },
      error: (error) => {
        // On error
        snack.label = 'Error saving fees.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
      this.quotationService.getActualQuotationFees(this.workingMatterQuotation.ActualQuotationId)
        .subscribe({ next: (actual) => {
          // On next
          this.actualQuotationFees = actual;
          this.actualQuotationFees.sort((a, b) => {
            return a.LineDate.toUpperCase().localeCompare(b.LineDate.toUpperCase());
          });
        },
        error: (error) => {
          // On error
        },
        complete: () => {
          this.workingActualQuotationFees = this.util.objectCopy(this.actualQuotationFees);
          this.workingActualQuotationFees.sort((a, b) => a.LineDate.toUpperCase().localeCompare(b.LineDate.toUpperCase()));
          snack.label = 'Quotation fees saved.';
          this.snackbarsService.dismiss().make(snack).show();
          if (this.newMatterQuotationId) {
            this.router.navigate([PATH_QUOTATION, this.newMatterQuotationId]);
          }
          this.loadingService.hideOverlay();
        }
      });
      }
    });
    }
  } // end ignoreAndSave()

  onFeeDelete(deletableFee: QuotationFeeItem) {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Delete quotation fee...',
      action: null
    };
    this.snackbarsService.make(snack).show();
    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Delete quotation fee';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-quotation)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'QuotationLines';
    this.activityLog.JsonData = JSON.stringify(deletableFee);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity_delete) => {}});

    this.quotationService.deleteQuotationFee(deletableFee.FeeItemId)
    .subscribe({ next:
      (deletedFee) => {
        // On next
      },
      error: (error) => {
        // On error
        snack.label = 'Error deleting quotation fee.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        snack.label = 'Quotation fee deleted.';
        snack.type = SnackType.SUCCESS;
        this.initMatterQuotation();
        this.initQuotationFees();
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    });
  } // onFeeDelete()

  onActualFeeDelete(deletableFee: QuotationFeeItem) {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Delete quotation fee...',
      action: null
    };
    this.snackbarsService.make(snack).show();
    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Delete quotation fee';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-quotation)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'ActualQuotationLines';
    this.activityLog.JsonData = JSON.stringify(deletableFee);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activity) => {}});

    this.quotationService.deleteActualQuotationFee(deletableFee.FeeItemId)
    .subscribe({ next:
      (deletedFee) => {
        // On next
      },
      error: (error) => {
        // On error
        snack.label = 'Error deleting quotation fee.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        snack.label = 'Quotation fee deleted.';
        snack.type = SnackType.SUCCESS;
        this.quotationService.getActualQuotationFees(this.workingMatterQuotation.ActualQuotationId)
          .subscribe({ next: (actual) => {
            // On next
            this.actualQuotationFees = actual;
            this.actualQuotationFees.sort((a, b) => {
              return a.LineDate.toUpperCase().localeCompare(b.LineDate.toUpperCase());
            });
          },
          error: (error) => {
            // On error
          },
          complete: () => {
            this.workingActualQuotationFees = this.util.objectCopy(this.actualQuotationFees);
            this.workingActualQuotationFees.sort((a, b) => a.LineDate.toUpperCase().localeCompare(b.LineDate.toUpperCase()));
            snack.label = 'Quotation fees saved.';
            this.snackbarsService.dismiss().make(snack).show();
            // if (this.newMatterQuotationId) {
            this.router.navigate([PATH_QUOTATION, this.workingMatterQuotation.MatterQuotationId]);
            // }
            this.loadingService.hideOverlay();
          }
        });
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      }
    });
  } // onActualFeeDelete()

  onHideGenerateQuotationFeesPrompt() {
    this.showGenerateQuotationFeesPrompt = false;
  } // end onHideGenerateQuotationFeesPrompt()

  generateInvoice() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Generating quotaion...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();

    // Check if we have actual fees so we can generate quotation fees
    if (this.workingMatterQuotation.QuotationLinkId > 0) {
      this.showGenerateQuotationFeesPrompt = true;
      this.loadingService.hideOverlay();
    } else {
      this.quotationService.updateQuotationOutbox(this.workingMatterQuotation.QuotationId)
      .subscribe({ next: (quotation_tosend) => {
        // On next
      },
      error: (error) => {
        // On error
        snack.label = 'Error generating quotation.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.router.navigate([PATH_QUOTATION_PREVIEW, this.workingMatterQuotation.QuotationId]);
        this.loadingService.hideOverlay();
      }
    });
    }
  } // end generateInvoice()

  generateActualInvoice() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Generating quotaion...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();

    this.quotationService.updateQuotationOutbox(this.workingMatterQuotation.QuotationId)
    .subscribe({ next: (quotation_tosend) => {
      // On next
    },
    error: (error) => {
      // On error
      snack.label = 'Error generating quotation.';
      snack.type = SnackType.ERROR;
      this.snackbarsService.dismiss().make(snack).show();
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.router.navigate([PATH_QUOTATION_PREVIEW, this.workingMatterQuotation.QuotationId]);
      this.loadingService.hideOverlay();
    }
  });
  } // end generateActualInvoice()

  promptDelete() {
    this.showDeleteQuotationModal = true;
  } // end promptDelete()

  deleteQuotation(quotation) {
    this.loadingService.showOverlay();

    const snack: Snack = {
      label: 'Deleting quotation...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();

    // Log activity Login
    // const currentDate = new Date();
    // this.activityLog.Action = 'Delete matter';
    // this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    // this.activityLog.LoggedApp = 'Web Application (Advocate-matters)';
    // if (this.userProfileService.userProfile.isImpersonator) {
    //   this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
    //   this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    // } else {
    //   this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
    //   this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    // }
    // this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    // this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    // this.activityLog.ActionTable = 'Services, Invoices';
    // this.activityLog.JsonData = quotation;
    // this.activityLogService.addActivityLog(this.activityLog).toPromise();

    this.quotationService.onRemoveQuotation(
      quotation.quotation.MatterQuotationId,
      quotation.comments).subscribe({ next: (next) => {
        // On Next
      },
      error: (error) => {
        // On error
        snack.label = 'Error deleting quotation.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        snack.label = 'Quotation deleted.';
        this.snackbarsService.dismiss().make(snack).show();
        this.router.navigate([PATH_DASHBOARD]);
        this.loadingService.hideOverlay();
    }
  });
  } // end deleteQuotation()

  //#region Generating from actual fees
  onGenerateActualFees() {
    this.showGenerateActualFeesPrompt = true;
  } // end onGenerateActualFees()

  onHideGenerateActualFeesPrompt() {
    this.showGenerateActualFeesPrompt = false;
  } // end onHideGenerateActualFeesPrompt()

  CopyActualFees() {
    this.showGenerateActualFeesPrompt = false;
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Generating fees',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();

    let actualNote = null;
    this.quotationService.getActualQuotation(this.workingMatterQuotation.ActualQuotationId).subscribe({
      next: (_actual_note) => {
        actualNote = _actual_note;
      }
    });

    this.actualService = Object.assign({}, DEFAULT_MATTER);
    const currentDate = new Date();
    const _matter: Matter = {
      Briefs: 0,
      CaseNumber: this.workingMatterQuotation.CaseNumber,
      ClientContactID: this.workingMatterQuotation.AttorneyId,
      ClientRefNo: this.workingMatterQuotation.ClientRefNo,
      ContactID: this.workingMatterQuotation.LawfirmId,
      DailyRate: this.workingMatterQuotation.DailyRate,
      DateOfAppearance: this.workingMatterQuotation.DateOfAppearance,
      DefendantContactID: 0,
      DefendantFullName: null,
      Description: this.workingMatterQuotation.Description,
      Discount: this.workingMatterQuotation.ActualDiscount,
      DiscountPercentage: 0,
      HourlyRate: this.workingMatterQuotation.HourlyRate,
      Instructions: this.workingMatterQuotation.Instructions,
      InternalRefNo: this.workingMatterQuotation.InternalRefNo,
      InvoiceId: 0,
      LawFirm: this.workingMatterQuotation.LawFirm,
      NewClientDetail: this.workingMatterQuotation.NewClientDetail,
      OldMatterGuid: this.workingMatterQuotation.OldMatterGuid,
      PaymentReceived: 0,
      PaymentTerms: 0,
      PerKilometerRate: this.workingMatterQuotation.PerKilometerRate,
      PlaintiffContactID: 0,
      PlaintiffFullName: null,
      RAFClaim: this.workingMatterQuotation.RAFClaim,
      RejectReason: this.workingMatterQuotation.RejectReason,
      ServiceAction: 0,
      ServiceDate: moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
      ServiceID: 0,
      ServiceProviderID: this.workingMatterQuotation.ServiceProviderId,
      Status: null,
      Submit: false,
      Total: this.workingMatterQuotation.Total,
      XmlData: this.workingMatterQuotation.XmlData,
      MAAA: '',
      EXCLVAT: false,
      TrialDateText: '',
      ArbitrationPercentage: 0,
      IsArbitrationSplit: false
    };

    this.mattersService.addMatter(_matter)
    .subscribe({ next: (matter) => {
      // On next
      this.newMatterId = matter.ServiceID;
      this.actualService = this.util.objectCopy(matter);
      const invoiceNote: InvoiceNote = {
        Id: matter.InvoiceId,
        Notes: actualNote.Notes
      };
      this.invoiceService.updateInvoiceAddUpdateNote(invoiceNote).subscribe({next: (_update_note) => {}});
    },
    error: (error) => {
      // On error
      snack.label = 'Error saving matter...';
      snack.type = SnackType.ERROR;
      this.snackbarsService.dismiss().make(snack).show();
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.saveActualServiceFees();
    }
  });
  } // end CopyActualFees()
  saveActualServiceFees() {
    this.actualFees = [];
    this.workingActualQuotationFees.forEach(fee => {
      const _fee = Object.assign({}, DEFAULT_FEE);
      _fee.Date = fee.LineDate; // moment().toJSON();
      _fee.FurtherDescription = fee.FurtherDescription;
      _fee.Rate = fee.Rate;
      _fee.Quantity = fee.Quantity;
      _fee.Total = fee.Total;
      _fee.IsVatable = false;
      _fee.RateType = fee.RateType;
      _fee.ServiceID = this.newMatterId;
      _fee.ServiceProviderID = this.workingMatterQuotation.ServiceProviderId;
      _fee.SubCategoryID = 0;
      _fee.Subject = fee.FurtherDescription;
      this.actualFees.push(_fee);
    });
    this.feeItemsService.updateFees(this.actualFees)
    .subscribe({ next: (subFees) => {
      // On next
    },
    error: (error) => {
      // On error();
    },
    complete: () => {
      // On complete
      this.generateActualServiceInvoice();
    }
  });
  } // end saveActualServiceFees()

  generateActualServiceInvoice() {
    this.loadingService.showOverlay();
    //  this.GenerateSaveFeeItems();
    this.invoiceDocumentService
      .createInvoiceDocument(this.actualService.InvoiceId, false)
      .subscribe({ next:
      (next) => {
        // On next

      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.quotationService.onRemoveQuotation(
          this.workingMatterQuotation.MatterQuotationId,
          'Generated from actual fees').subscribe({next: (_gen) => {}});
        this._canIgnoreNavigationBlock = true;
        this.router.navigate([PATH_ADVOCATE_INVOICE_PREVIEW, this.actualService.InvoiceId]);
        this.loadingService.hideOverlay();
      }
    });
  } // end generateActualServiceInvoice()
//#endregion Generating from actual fees

  CopyFromQuotationFees() {
    this.showGenerateQuotationFeesPrompt = false;
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Generating fees',
      action: null
    };
     let actualNote = null;
     this.quotationService.getQuotation(this.workingMatterQuotation.MatterQuotationId).subscribe({
       next: (_actual_note) => {
         actualNote = _actual_note;
       }
     })
    this.snackbarsService.make(snack, 5000).show();
    this.actualService = Object.assign({}, DEFAULT_MATTER);
    const currentDate = new Date();
    const _matter: Matter = {
      Briefs: 0,
      CaseNumber: this.workingMatterQuotation.CaseNumber,
      ClientContactID: this.workingMatterQuotation.AttorneyId,
      ClientRefNo: this.workingMatterQuotation.ClientRefNo,
      ContactID: this.workingMatterQuotation.LawfirmId,
      DailyRate: this.workingMatterQuotation.DailyRate,
      DateOfAppearance: this.workingMatterQuotation.DateOfAppearance,
      DefendantContactID: 0,
      DefendantFullName: null,
      Description: this.workingMatterQuotation.Description,
      Discount: this.workingMatterQuotation.Discount,
      DiscountPercentage: 0,
      HourlyRate: this.workingMatterQuotation.HourlyRate,
      Instructions: this.workingMatterQuotation.Instructions,
      InternalRefNo: this.workingMatterQuotation.InternalRefNo,
      InvoiceId: 0,
      LawFirm: this.workingMatterQuotation.LawFirm,
      NewClientDetail: this.workingMatterQuotation.NewClientDetail,
      OldMatterGuid: this.workingMatterQuotation.OldMatterGuid,
      PaymentReceived: 0,
      PaymentTerms: 0,
      PerKilometerRate: this.workingMatterQuotation.PerKilometerRate,
      PlaintiffContactID: 0,
      PlaintiffFullName: null,
      RAFClaim: this.workingMatterQuotation.RAFClaim,
      RejectReason: this.workingMatterQuotation.RejectReason,
      ServiceAction: 0,
      ServiceDate: moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
      ServiceID: 0,
      ServiceProviderID: this.workingMatterQuotation.ServiceProviderId,
      Status: null,
      Submit: false,
      Total: this.workingMatterQuotation.Total,
      XmlData: this.workingMatterQuotation.XmlData,
      MAAA: '',
      EXCLVAT: false,
      TrialDateText: '',
      ArbitrationPercentage: 0,
      IsArbitrationSplit: false
    };

    this.mattersService.addMatter(_matter)
    .subscribe({next: (matter) => {
      // On next
      this.newMatterId = matter.ServiceID;
      this.actualService = this.util.objectCopy(matter);

      const invoiceNote: InvoiceNote = {
        Id: matter.InvoiceId,
        Notes: actualNote.Notes
      };
       this.invoiceService.updateInvoiceAddUpdateNote(invoiceNote).subscribe({next: (_update_note) => {}});
    },
    error: (error) => {
      // On error
      snack.label = 'Error saving matter...';
      snack.type = SnackType.ERROR;
      this.snackbarsService.dismiss().make(snack).show();
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.saveQuotationServiceFees();
    }
  });
  } // end CopyFromQuotationFees()
  saveQuotationServiceFees() {
    this.actualFees = [];
    this.workingQuotationFees.forEach(fee => {
      const _fee = Object.assign({}, DEFAULT_FEE);
      _fee.Date = moment().toJSON();
      _fee.FurtherDescription = fee.FurtherDescription;
      _fee.Rate = fee.Rate;
      _fee.Quantity = fee.Quantity;
      _fee.Total = fee.Total;
      _fee.IsVatable = false;
      _fee.RateType = fee.RateType;
      _fee.ServiceID = this.newMatterId;
      _fee.ServiceProviderID = this.workingMatterQuotation.ServiceProviderId;
      _fee.SubCategoryID = 0;
      _fee.Subject = fee.FurtherDescription;
      this.actualFees.push(_fee);
    });
    this.feeItemsService.updateFees(this.actualFees)
    .subscribe({ next: (subFees) => {
      // On next
    },
    error: (error) => {
      // On error();
    },
    complete: () => {
      // On complete
      this.generateQuotationServiceInvoice();
    }
  });
  } // end saveQuotationServiceFees()

  generateQuotationServiceInvoice() {
    this.loadingService.showOverlay();
    //  this.GenerateSaveFeeItems();
    this.invoiceDocumentService
      .createInvoiceDocument(this.actualService.InvoiceId, false)
      .subscribe({ next:
      (next) => {
        // On next

      },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.quotationServiceLink = Object.assign({}, DEFAULT_QUOTATION_SERVICE_LINK);
        this.quotationServiceLink.Services_Id = this.newMatterId;
        this.quotationServiceLink.MatterQuotationId = this.workingMatterQuotation.MatterQuotationId;
         this.createQuotationServiceLink(this.quotationServiceLink);
        this.router.navigate([PATH_ADVOCATE_INVOICE_PREVIEW, this.actualService.InvoiceId]);
        this.loadingService.hideOverlay();
      }
    });
  } // end generateQuotationServiceInvoice()

  createQuotationServiceLink(data: QuotationServiceLink) {
     this.quotationService.putQuotationServiceLink(data).subscribe({next: (_quotation_service) => {}});
  } // end createQuotationServiceLink()

  onManualAcceptOrReject(acceptOrReject: string) {
    if (this.canAcceptReject) {
      this.manualAcceptReject = acceptOrReject;
      this.loadingService.showOverlay();
      const snack: Snack = {
        label: 'Accept quotation...',
        action: null
      };
      this.snackbarsService.make(snack, 5000).show();

    // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Quotation accepted or rejected on behalf of client';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-quotation)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'MatterQuotation, Quotation';
      this.activityLogService.addActivityLog(this.activityLog).subscribe({next: (_activuty_log) => {}});
      this.quotationService.updateAcceptQuotation(this.workingMatterQuotation, this.manualAcceptReject).subscribe({ next: (data) => {
        // On next
    },
      error: (error) => {
        // On error
        snack.label = 'Error accepting/rejecting quotation.';
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack).show();
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On Complete
        snack.label = 'Quotation accepted/rejected.';
        snack.type = SnackType.SUCCESS;
        this.snackbarsService.dismiss().make(snack).show();
        this.showQuotationAcceptRejectReason = false;
        this.initMatterQuotation();
        this.router.navigate([PATH_QUOTATION, this.workingMatterQuotation.QuotationId]);
        location.reload();
        this.loadingService.hideOverlay();
      }
    });
    }
  } // end onManualAcceptOrReject()
  //#endregion All Save, Edit & Delete api calls
} // end AdvocateQuotationComponent{}
