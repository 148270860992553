import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { TAG_PROFORMA_INVOICE_MODAL } from '../../../services/tags/tags.service';
import { Matter } from '../../../services/matters/matters.service';
import { ApiService } from '../../../services/api.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

@Component({
  selector: 'app-proforma-invoice',
  templateUrl: './proforma-invoice.component.html',
  styleUrls: ['./proforma-invoice.component.scss']
})
export class ProformaInvoiceComponent implements OnInit, OnChanges {
  reportUrl; // = 'WorkInProgressMatter?ServiceId=228037'; // 'WorkInProgressMatter?ServiceId=228037';
  serviceId;

  selectedserviceId: number;
  selectedFileName: string;
  reportType: string;

  @Input() matter: Matter;
  @Input() popId: number;
  @Input() invoiceId: number;
  @Input() title: string;
  @Input() documentType: string;
  @Input() isVisible: boolean;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private api: ApiService,
    private userProfileService: UserProfileService
  ) { }

  ngOnInit() {
    if (!this.title) {
      this.title = 'Quotation';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.matter && changes.matter.currentValue) {

      if (this.documentType === 'Quotation') {
        this.reportType = 'Proforma';
      this.selectedserviceId = this.matter.ServiceID;
      this.selectedFileName = this.matter.Description.replace('//', 'vs');
      this.reportUrl = this.api.endpoints.workInProgressMatter +
      '=' + this.matter.ServiceID +
      '&currency=' + this.userProfileService.userProfile.currencyDetails.symbol;

      }
    }

    if (changes.popId && changes.popId.currentValue) {
      if (this.documentType === 'ProofOfPayment') {
        this.selectedserviceId = this.popId;
        this.reportType = 'ProofOfPayment';
        this.selectedFileName = 'Proof of payment';
        this.reportUrl = this.api.endpoints.proofOfPaymentAttachment +
          '?Type=ProofOfPayment' +
          '&ServiceProviderID=' + this.api.serviceProviderID +
          '&popId=' + this.popId;

      } else if (this.documentType === 'ProcessedProofOfPayment') {
        this.selectedserviceId = this.popId;
        this.reportType = 'ProcessedProofOfPayment';
        this.selectedFileName = 'Proof of payment';
        this.reportUrl = this.api.endpoints.proofOfPaymentAttachment +
        '?Type=ProcessedProofOfPayment' +
        '&ServiceProviderID=' + this.api.serviceProviderID +
        '&popId=' + this.popId;
      }
    }
    if (changes.invoiceId && changes.invoiceId.currentValue) {
      if (this.documentType === 'Invoice') {
        this.selectedserviceId = this.invoiceId;
        this.selectedFileName = 'Invoice';
        this.reportType = 'Invoice';
        this.reportUrl = this.api.endpoints.invoiceDocument + '?invoiceID=' + this.invoiceId;
      }
    }
  }
  dismiss() {
    this.closeModal.emit(TAG_PROFORMA_INVOICE_MODAL);
  } // end dismiss()
}
