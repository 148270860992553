import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry } from 'rxjs';
import { ApiService } from '../api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionBillingService {

  private refreshAppNavBar = new Subject<boolean>();
  RefreshAppNavBar = this.refreshAppNavBar.asObservable();

  constructor(private http: HttpClient, private api: ApiService) { }

  triggerRefresh(isRedirected: boolean) {
    this.refreshAppNavBar.next(isRedirected);
  }

  getSubscriptionsDetails(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.subscriptionBilling + "/getSubscriptionsPlansDetails?ContactID=" + this.api.serviceProviderID,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  saveSubscriptionsPlansDetails(data): Observable<any> {
    const requestData = {
      ContactId: this.api.serviceProviderID,
      selectedServicesForSave: data.selectedServicesForSave,
      selectedServicesForCancel: data.selectedServicesForCancel
    };
    return this.http.post(this.api.endpoints.subscriptionBilling +
      '/saveSubscriptionsPlansDetails', requestData, this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  }

  getVATDetails(): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.subscriptionBilling + "/getVATDetails",
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  getPaymentDetails(ContactSubscriptionPaymentsID: number): Observable<any> {
    return this.http.get<any>(
      this.api.endpoints.subscriptionBilling + "/getPaymentDetails?ContactSubscriptionPaymentsID=" + ContactSubscriptionPaymentsID,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }

  removeCancelPayments(): Observable<any> {
    return this.http.post<any>(
      this.api.endpoints.subscriptionBilling + '/removeCancelPayments?ContactId=' + this.api.serviceProviderID,
      this.api.httpOptions
    ).pipe(
      retry(0),
      catchError(this.api.handleError)
    );
  }
}