import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Matter } from '../../../services/matters/matters.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { TAG_ADD_NEW_LAW_FIRM_MODAL, TAG_ADD_ATTORNEY_FIRM_MODAL, TAG_ADD_PLAINTIFF_MODAL } from '../../../services/tags/tags.service';
import { Plaintiff, DEFAULT_PLAINTIFF_TEMPLATE, PlaintiffsService } from '../../../services/plaintiffs/plaintiffs.service';
import { Snack, SnackbarsService, SnackType } from '../../../services/messaging/snackbars/snackbars.service';

@Component({
  selector: 'app-datalist-plaintiffs',
  templateUrl: './datalist-plaintiffs.component.html',
  styleUrls: ['./datalist-plaintiffs.component.scss']
})
export class DatalistPlaintiffsComponent implements OnInit, OnChanges {

  @Input() defendantsList: Array<any>;
  @Input() plaintiffsList: Array<any>;
  @Input() matter: Matter;
  @Input() type: string;
  @Input() iconClass: string;
  @Input() required: boolean;
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  types = {
    OTHER_CLIENTS: 'other-clients',
    LAW_FIRMS: 'law-firms',
    ATTORNEYS: 'attorneys',
    PLAINTIFFS: 'plaintiffs',
    DEFENDANTS: 'defendants'
  };

  customSelected: string;
  plaintiffs_list: any[];
  defendant_list: any[];
  attorneyList: any[];
  private selectedItem: any;

  showNewLawFirmModal: boolean;
  showNewAttorneyModal: boolean;
  showLawfirmErrorPrompt: boolean;
  showAttorneyErrorPrompt: boolean;

  showAddPlaintiffModal = false;
  addNewbuttonClicked = false;

  plaintiffDefendant: Plaintiff = Object.assign({}, DEFAULT_PLAINTIFF_TEMPLATE);

  constructor(
    private plaintiffsService: PlaintiffsService,
    private snackbarsService: SnackbarsService
  ) { }

  ngOnInit() {
    if (this.plaintiffsList) {
      if (this.plaintiffsList.length === 0) {
        this.plaintiffs_list = this.plaintiffsList;
      }
    }

    if (this.defendantsList) {
      if (this.defendantsList.length === 0) {
        this.defendant_list = this.defendantsList;
      }
    }
  } // end ngOnInit()

  get canAddPlaintiff(): boolean {
    // if (this.selectedItem) {
      return !Boolean(this.matter.PlaintiffFullName);
    // } else {
    //   return true;
    // }
  } // end get canAddPlaintiff()

  get canAddDefendant(): boolean {
    // if (this.selectedItem) {
      return !Boolean(this.matter.DefendantFullName);
    // } else {
    //   return true;
    // }
  } // end get canAddDefendant()

  ngOnChanges(changes: SimpleChanges) {
    if (this.plaintiffsList) {
      this.plaintiffs_list = this.plaintiffsList;
    }

    if (this.defendantsList) {
      this.defendant_list = this.defendantsList;
    }
    if (changes.matter && changes.matter.currentValue) {
      // if (this.matter.PlaintiffFullName) {
      //   this.lawfirmList.forEach(s => s.FullName === this.matter.InternalRefNo);
      //   if (changes.matter && changes.matter.previousValue) {
      //     this.selectedItem = this.lawfirmList.find(firm => firm.FullName === this.matter.InternalRefNo);
      //   }
      // }
    }

    if (changes.matter && changes.matter.currentValue) {
      // if (this.matter.PlaintiffContactID) {
      //   this.attorneyList.forEach(s => s.FullName === this.matter.NewClientDetail);
      // }
    }
  } // end ngOnChanges()

  // onPlaintiffsChange() {
  //   switch (this.type) {
  //     case this.types.PLAINTIFFS:
  //       setTimeout(() => {
  //         if (!this.matter.PlaintiffContactID && this.matter.PlaintiffFullName && this.addNewbuttonClicked === false) {
  //           this.showLawfirmErrorPrompt = true;
  //         } else {
  //           this.showLawfirmErrorPrompt = false;
  //         }
  //       }, 300);
  //       break;

  //     case this.types.ATTORNEYS:
  //       setTimeout(() => {
  //         if (this.matter.NewClientDetail) {
  //           if (!this.matter.ClientContactID && this.addNewbuttonClicked === false) {
  //             this.showAttorneyErrorPrompt = true;
  //           } else {
  //             this.showAttorneyErrorPrompt = false;
  //           }
  //         }
  //       }, 300);
  //       break;
  //   }
  // } // end onLawfirmChange()

  onHideLawfirmErrorPrompt() {
    this.matter.InternalRefNo = '';
    this.showLawfirmErrorPrompt = false;
  } // end onHideLawfirmErrorPrompt()

  onHideAttorneyErrorPrompt() {
    this.matter.NewClientDetail = '';
    this.showAttorneyErrorPrompt = false;
  } // end onHideAttorneyErrorPrompt()

  onSelect(event: TypeaheadMatch): void {
    switch (this.type) {
      case this.types.PLAINTIFFS:
        this.selectedItem = event.item;
        this.customSelected = event.item.FullName;
        this.matter.PlaintiffFullName = event.item.FullName;
        this.matter.PlaintiffContactID = event.item.ContactID;
        this.select.emit(this.selectedItem);
        // this.canAddLawfirm = false;
        break;

      case this.types.DEFENDANTS:
        this.selectedItem = event.item;
        this.customSelected = event.item.FullName;
        this.matter.DefendantFullName = event.item.FullName;
        this.matter.DefendantContactID = event.item.ContactID;
        this.select.emit(this.selectedItem);
        break;
    }
  } // end onSelect{}

  onInput(event) {
    if (this.type === this.types.PLAINTIFFS) {
      if (event.inputType === 'deleteContentBackward') {
        this.selectedItem = null;
        this.matter.PlaintiffContactID = 0;
        // this.plaintiffs_list = [];
      }
    } else if (this.type === this.types.DEFENDANTS) {
      if (event.inputType === 'deleteContentBackward') {
        this.selectedItem = null;
        this.matter.DefendantContactID = 0;
      }
    }
  } // end onInput()

  addNewPlaintiffsDefendants(type: string) {
    switch (type) {
      case this.types.PLAINTIFFS:
        this.plaintiffDefendant.FullName = this.matter.PlaintiffFullName;
        this.showAddPlaintiffModal = true;
        break;
      case this.types.DEFENDANTS:
        this.plaintiffDefendant.FullName = this.matter.DefendantFullName;
        this.showAddPlaintiffModal = true;
    }
  } // end addNewLawfirmAttorney()

  onCloseModal(modal: string) {
    this.addNewbuttonClicked = false;
    switch (modal) {
      case TAG_ADD_PLAINTIFF_MODAL:
        this.showAddPlaintiffModal = false;
        break;

      case TAG_ADD_ATTORNEY_FIRM_MODAL:
        this.showNewAttorneyModal = false;
        break;
    }
  } // end onCloseModal()

  onSavePlaintiffDefendant(plaintiffDefendant) {
    if (plaintiffDefendant.FullName === undefined) {
      this.plaintiffDefendant = {
        FullName: plaintiffDefendant,
        ContactID: 0
      };
    }

    const isInList = this.plaintiffsList.find((item) => {
      return item.FullName.localeCompare(this.plaintiffDefendant.FullName) === 0;
    });
    if (!isInList) {
      const snack: Snack = {
        label: 'Saving new Plaintiff and Defendant...',
        action: null
      };
      this.snackbarsService.make(snack).show();
      this.plaintiffsService.createPlaintiff(this.plaintiffDefendant).subscribe({
        next: (plaintiff) => {
          this.plaintiffDefendant = plaintiff;
        },
        error: (error) => {
          const msg = 'Error saving new Plaintiff and Defendant.';
          console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack, 5000).show();
        },
        complete: () => {
          snack.label = 'Plaintiff and Defendant created.';
          snack.type = null;
          this.snackbarsService.dismiss().make(snack, 5000).show();
          this.plaintiffsList.push(this.plaintiffDefendant);
        }
      });
      //   plaintiff => {
      //     this.plaintiffDefendant = plaintiff;
      //   },
      //   error => {
      //     const msg = 'Error saving new Plaintiff and Defendant.';
      //     console.error(msg, error);
      //     snack.label = msg;
      //     snack.type = SnackType.ERROR;
      //     this.snackbarsService.dismiss().make(snack, 5000).show();
      //   },
      //   () => {
      //     snack.label = 'Plaintiff and Defendant created.';
      //     snack.type = null;
      //     this.snackbarsService.dismiss().make(snack, 5000).show();
      //     // const contactHandle = this.createContactHandle(this.plaintiffDefendant);
      //     // this.contactList.push(contactHandle);
      //     this.plaintiffsList.push(this.plaintiffDefendant);
      //     // this.changeList(this.plaintiffDefendant.FullName);
      //     // this.resetList();
      //     // this.onSelect(contactHandle);
      //   }
      // );
    } else {
      this.plaintiffDefendant = isInList;
      // const contactHandle = this.createContactHandle(this.plaintiffDefendant);
      // this.changeList(this.plaintiffDefendant.FullName);
      // this.resetList();
      // this.onSelect(contactHandle);
    }
  }
}
