import { Injectable } from '@angular/core';

@Injectable()
export class DataService {

  get data(): any {
    const storedData = JSON.parse(localStorage.getItem('_data'));
    return storedData ? storedData : this._data;
  } // end get data()

  set data(value: any) {
    this._data = value;
    localStorage.setItem('_data', JSON.stringify(this._data));
  } // end set data()

  private _data: any;
  constructor() {} // end constructor()
} // end DataService{}
