<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<app-breadcrumb [crumbs]="crumbs"></app-breadcrumb>
<div class="container-fluid">
    <div class="process-body row">
        <div class="left-block col-sm-8">
            <div class="block-title">Account Transactions </div>
            <div class="row reconcile-search input-group" style="Padding-bottom: 0px !important">
                <select id="sort-by" data-placeholder="'Another option'" placeholder="Filter" style="width: 20%;"
                    [(ngModel)]="orderBy">
                    <option *ngFor="let option of statusOptions" [value]="option" class="dropdown-item">
                        {{option | titlecase}}
                    </option>
                </select>
                <div class="col-xs-12 col-12 col-md-4 form-group form-outline">
                    <input type="search" placeholder="Search Date Range" class="form-control"
                        [(ngModel)]="SearchDateRange" bsDaterangepicker [bsConfig]="{ isAnimated: true }">
                </div>
                <div class="col-xs-12 col-12 col-md-4 form-group form-outline">
                    <input type="search" placeholder="Search Transactions Description or Account Number"
                        class="form-control" [(ngModel)]="SearchDescription">
                </div>
                <div class="col-xs-12 col-12 col-md-1 form-group form-outline">
                    <i class="fas fa-arrow-alt-circle-right ng-star-inserted" data-toggle="tooltip" data-placement="top"
                        title="Search" (click)="SearchTransactions()"
                        style="font-size:25px;color:#dbbb2d;margin-top:3px;"></i>
                        &nbsp;&nbsp;
                        <i class="fas fa-bank" data-toggle="tooltip" data-placement="top"
                        title="Push Transactions to Sage" (click)="updateSageTransactions()"
                        style="font-size:25px;color:#dbbb2d;margin-top:3px;"></i>
                </div>

            </div>

            <div class="row m-0">
                <div class="table-responsive" style="height: 400px; padding-bottom: 30px; overflow-y: scroll;">
                    <table class="table table-striped">
                        <tbody>
                            <tr style="position: sticky; top: 0; z-index: 1;">
                                <th scope="col" (click)="toggleSort('account-number')" style="width: 12%;">Account
                                    Number
                                    <span><i class="fa fa-sort"
                                            [class.fa-sort-down]="transactionAccountNumberAscending"></i></span>
                                </th>
                                <th scope="col" (click)="toggleSort('transaction-date')" style="width: 10%;">Transaction
                                    Date
                                    <span><i class="fa fa-sort"
                                            [class.fa-sort-down]="transactionDateAscending"></i></span>
                                </th>
                                <th scope="col" (click)="toggleSort('description')" style="width: 30%;">Description
                                    <span><i class="fa fa-sort"
                                            [class.fa-sort-down]="transactionDescriptionAscending"></i></span>
                                </th>
                                <th scope="col" (click)="toggleSort('debit')" style="width: 8%;">Debit
                                    <span><i class="fa fa-sort" [class.fa-sort-down]="debitAscending"></i></span>
                                </th>
                                <th scope="col" (click)="toggleSort('credit')" style="width: 8%;">Credit
                                    <span><i class="fa fa-sort" [class.fa-sort-down]="creditAscending"></i></span>
                                </th>
                                <th scope="col" (click)="toggleSort('allocated')" style="width: 14%;">Allocated
                                    <span><i class="fa fa-sort" [class.fa-sort-down]="allocationAscending"></i></span>
                                </th>
                                <th scope="col" (click)="toggleSort('transaction-status')" style="width: 10%;">
                                    Integration Status
                                    <span><i class="fa fa-sort"
                                            [class.fa-sort-down]="transactionStatusAscending"></i></span>
                                </th>
                                <th scope="col" (click)="toggleSort('sync-date')" style="width: 15%;">Integration Date
                                    <span><i class="fa fa-sort" [class.fa-sort-down]="syncDateAscending"></i></span>
                                </th>
                            </tr>
                            <tr *ngFor="let transaction of accountingTransactions" class="tr"
                                [class.active]="row == transaction.Id">
                                <td [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}"
                                    *ngIf="transaction.AccountNumber" (click)="selectedRow(transaction)">
                                    {{transaction.AccountNumber}}</td>
                                <td [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}"
                                    *ngIf="!transaction.AccountNumber" (click)="selectedRow(transaction)">-</td>
                                <td (click)="selectedRow(transaction)"
                                    [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}">
                                    {{transaction.TransactionDate |
                                    date:'yyyy-MM-dd'}}</td>
                                <td [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}"
                                    (click)="selectedRow(transaction)">{{transaction.Description}}</td>
                                <td [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}"
                                    *ngIf="transaction.Debit > 0" (click)="selectedRow(transaction)">R
                                    {{transaction.Debit | number : '1.2-2'}}</td>
                                <td [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}"
                                    *ngIf="transaction.Debit == 0" (click)="selectedRow(transaction)">-</td>
                                <td [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}"
                                    *ngIf="transaction.Debit < 0" (click)="selectedRow(transaction)">{{transaction.Debit
                                    | number : '1.2-2'}}</td>
                                <td [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}"
                                    *ngIf="transaction.Credit > 0" (click)="selectedRow(transaction)">R
                                    {{transaction.Credit | number : '1.2-2'}}</td>
                                <td [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}"
                                    *ngIf="transaction.Credit == 0" (click)="selectedRow(transaction)">-</td>
                                <td [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}"
                                    *ngIf="transaction.Credit < 0" (click)="selectedRow(transaction)">
                                    {{transaction.Credit | number : '1.2-2'}}</td>
                                <td [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}"
                                    (click)="selectedRow(transaction)">{{transaction.Processed}}</td>
                                <td [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}"
                                    (click)="selectedRow(transaction)">{{transaction.TransactionStatus}}</td>
                                <td [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}"
                                    *ngIf="transaction.SyncDate" (click)="selectedRow(transaction)">
                                    {{transaction.SyncDate | date:'yyyy-MM-dd'}}</td>
                                <td [ngStyle]="{'color': getErrorColor(transaction.TransactionStatus)}"
                                    *ngIf="!transaction.SyncDate" (click)="selectedRow(transaction)">-</td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <div class="block-title" *ngIf="accountingTransactions.length == 0" style="text-align: center;">
                            NO RECORD</div>
                    </div>
                </div>
            </div>
            <div class="row" style="padding-bottom: 0px;">
                <div class="col-md-10">
                    <div class="row">
                        <div class="col-md-2">
                            <select style="width: 70%;" placeholder="Filter" [(ngModel)]="pageSize"
                                (change)='onOptionsSelected($event)'>
                                <option *ngFor="let option of PageOptions" [value]="option" class="dropdown-item">
                                    {{option | titlecase}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6" style="margin-top: 10px;">
                            <p>{{displayTransactionCount}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-2" *ngIf="isMoreThenPageSizeRecord">
                    <div (click)="LoadMoreData()">
                        <a class="fa fa-plus-circle fa-lg" style="margin-top: 5px;margin-right: 5px;color:#8abd37"></a>
                        <u class="auxcon-green"
                            style="text-transform:uppercase;color:#8abd37;font-size:12px;font-weight:700;">Load More</u>
                    </div>
                </div>
            </div>
        </div>

        <div class="left-block col-sm-4" style="border:solid 1px #8abd37; background-color: #f4f9ed;">
            <div class="block-title">Integration Log</div>
            <div class="block-title text-left">Transaction Id : {{selectedTransactionId}}</div>
            <div class="block-title" *ngIf="!accountingTransactionLog" style="text-align: center; color:red;">No log is
                available yet</div>
            <div *ngIf="accountingTransactionLog" style="font-size: larger; margin : 0px 0 13px 13px;">
                <p *ngIf="accountingTransactionLog.PendingLog">{{accountingTransactionLog.PendingLog}}</p>
                <div *ngFor="let transactionLogs of accountingTransactionLog.MsgLogs">
                    <p style="margin-top:20px;"><b>Attempt {{transactionLogs.Attempt}}</b></p>
                    <p class="transactionlog" *ngIf="transactionLogs.QueuedLogMsg">{{transactionLogs.QueuedLogMsg}}</p>
                    <p class="transactionlog" *ngIf="transactionLogs.InProcessLogMsg">
                        {{transactionLogs.InProcessLogMsg}}</p>
                    <p class="transactionlog" *ngIf="transactionLogs.ErrorLogMsg">{{transactionLogs.ErrorLogMsg}}</p>
                    <p class="transactionlog" *ngIf="transactionLogs.ErrorMessage"
                        style="color: red;line-height: 15px; font-size: 12px;">
                        ({{transactionLogs.ErrorMessage}})</p>
                    <p class="transactionlog" *ngIf="transactionLogs.SuccessLogMsg">{{transactionLogs.SuccessLogMsg}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>