<div *ngIf="showTransactions === true">
    <nav class="bank-nav">
        <a class=" col-sm-2 active cursor">Processed to</a>
        <!-- <div class=" col-sm-2" [class.active]="'true'"> -->
        <a class="tab" [class.active]="selectedTab === 'invoice'" (click)="togglePanel('invoice')"><i
                class="far fa-file"></i><u>Invoices</u></a>
        <!-- </div> -->
        <!-- <div class=" col-sm-2" [class.active]="'true'"> -->
        <a class="tab" [class.active]="selectedTab === 'person'" (click)="togglePanel('person')"><i
                class="far fa-user-plus"></i><u>Personal</u></a>
        <!-- </div> -->
        <!-- <div class=" col-sm-2" [class.active]="'true'"> -->
        <a class="tab" [class.active]="selectedTab === 'exclude'" (click)="togglePanel('exclude')"><i
                class="far fa-minus-circle"></i><u>Excluded</u></a>
        <!-- </div> -->
        <!-- <div class=" col-sm-2 pr-0 pl-0" [class.active]="'true'"> -->
        <a class="tab" [class.active]="selectedTab === 'refered-to-client'"
            (click)="togglePanel('refered-to-client')"><i class="far fa-user-tie"></i><u>Refered to Client</u></a>
        <!-- </div> -->
        <!-- <div class=" col-sm-2 " [class.active]="showActions"> -->
        <a class="clickable" *ngIf="profile.serviceProviderID !== 29723" [class.active]="showActions"
            (click)="onRemoveProcessedTransaction()"><i class="far fa-trash-alt"
                style="margin-right: 4px; font-size: 15px;"></i><u style="font-size: 14px;">Remove</u></a>
        <!-- </div> -->
    </nav>
    <div class="row reconcile-search input-group">
        <input type="search" class="form-control" placeholder="Search" [(ngModel)]="searchTerm"
            (input)="this.searchItems.next($event)">
        <div class="input-group-append">
            <i class="far fa-search"></i>
        </div>
    </div>
    <div class="row reconcile-table">
        <div class="table-responsive" style="max-height: 400px; padding-bottom: 30px;">
            <table class="table table-striped ">
                <tbody>
                    <tr>
                        <th scope="col">
                            <div class="checkbox">
                                <input #inputCheckSelectAll type="checkbox" (change)="toggleSelectAll()">
                            </div>
                        </th>
                        <th scope="col" (click)="toggleSort('account-number')" style="min-width: 100px;">Account No.
                            <i class="fa fa-sort" [class.fa-sort-down]="sortAccountNumber"></i>
                        </th>
                        <th scope="col" (click)="toggleSort('date')" style="min-width: 80px;">Date
                            <i class="fa fa-sort" [class.fa-sort-down]="sortDate"></i>
                        </th>
                        <th scope="col" (click)="toggleSort('description')">Transaction Description
                            <i class="fa fa-sort" [class.fa-sort-down]="sortDescription"></i>
                        </th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col" (click)="toggleSort('amount')" style="min-width: 100px;" class="text-right">
                            Amount
                            <i class="fa fa-sort" [class.fa-sort-down]="sortAmount"></i>
                        </th>
                        <th></th>
                        <!-- <th scope="col" class="text-center">Allocated</th> -->
                    </tr>
                    <tr class="tr" *ngFor="let bank of bankTransactions" [class.active]="row == bank.Id">
                        <td scope="row">
                            <app-small-checkbox [checked]="isAllSelected || selectedrows.includes(bank.Id)"
                                (click)="toggleCheckbox(bank)"></app-small-checkbox>
                        </td>
                        <td style="min-width: 100px;" (click)="onRowClick(bank)">{{bank.AccountNo}}</td>
                        <td style="min-width: 80px;" (click)="onRowClick(bank)">{{bank.Date | date:'yyyy-MM-dd'}}</td>
                        <td style="width: 60%;" (click)="onRowClick(bank)">{{bank.TransactionDescription}}</td>
                        <td style="width: 4%;" (click)="onRowClick(bank)">
                            <i class="far fa-landmark" *ngIf="allocatedToLawFirm(bank)"></i>
                        </td>
                        <td style="width: 5%;" (click)="viewProofOfPayment(bank)">
                            <a *ngIf="bank.AdHocCollection === true" title="Ad-Hoc assistance requested">
                                <i class="fa fa-money" style="font-size: 14px !important;"></i>
                            </a>
                        </td>
                        <td style="width: 5%;" (click)="viewProofOfPayment(bank)">
                            <a *ngIf="bank.HasAttachment === true" title="Proof of payment attactment">
                                <i class="fal fa-paperclip" style="font-size: 14px !important;"></i>
                            </a>
                        </td>
                        <td class="text-right" style="min-width: 100px;" (click)="onRowClick(bank)">{{bank.Amount |
                            currency:currencySymbol}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="btnLoadMoreEnable" *ngIf="currentCount < displayTotalCount" style="float: right;"
                (click)="LoadMoreData()">
                <a class="fa fa-arrow-down fa-lg" style="margin-right: 5px;color:#8abd37"></a>
                <u class="auxcon-green"
                    style="text-transform:uppercase;color:#8abd37;font-size:12px;font-weight:700;">Load
                    More</u>
            </button>
        </div>
    </div>
</div>
<div class="reconcile" *ngIf="showTransactions === false">
    <div class="row">
        <i class="fal fa-check-circle"></i>
    </div>
    <div class="row">
        You have reconciled & processed all of your bank transactions
    </div>
</div>
<app-proforma-invoice #proformaInvoice title="Proof Of Payment" [popId]="popId" invoice="0"
    documentType="ProcessedProofOfPayment" matter="null" [isVisible]="showProofOfPaymentDoc"
    *ngIf="showProofOfPaymentDoc" (closeModal)="closeModal()">
</app-proforma-invoice>