import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UtilitiesService } from '../../../services/utilities.service';
import { Message, MessagesService, DEFAULT_MESSAGE_SEND } from '../../../services/messaging/messages/messages.service';
import { InvoiceDetails } from '../../../services/invoice-details/invoice-details.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack } from '../../../services/messaging/snackbars/snackbars.service';

const TITLE_SEND_CREDIT_NOTE = 'SEND CREDIT NOTE VIA EMAIL';

@Component({
  selector: 'app-send-credit-note-modal',
  templateUrl: './send-credit-note-modal.component.html',
  styleUrls: ['./send-credit-note-modal.component.scss']
})
export class SendCreditNoteModalComponent implements OnInit {


  title: string = TITLE_SEND_CREDIT_NOTE;
  btnText = 'SEND CREDIT NOTE';
  // recipients: string;
  toMessage: Message = Object.assign({}, DEFAULT_MESSAGE_SEND);

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Input() invoiceId: number;
  @Input() invoiceDetails: InvoiceDetails;

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private messagesService: MessagesService,
    private snackbarService: SnackbarsService,
  ) { }

  get hasContactEmailError(): boolean {
    if (!this.toMessage.Recipients)
      return false;

    return !this.util.areValidEmails(this.toMessage.Recipients);
  } // end hasContactEmailError()

  get isSaveable(): Boolean {
    if (!this.toMessage.Recipients)
      return false;

    return this.util.areValidEmails(this.toMessage.Recipients);
  } // end isSaveable()

  ngOnInit() {
    this.getCreditNoteMessage();
  }

  dismiss() {
    this.closeModal.emit('send-credit');
  }

  getCreditNoteMessage() {
    this.loadingService.showOverlay();
    this.messagesService.getCreditNoteMessage(this.invoiceDetails.CreditNoteId).subscribe({
      next: (message) => {
        this.toMessage = message;
        this.loadingService.hideOverlay();
      }, error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      }});
  }

  onSendCredit() {
    this.loadingService.showOverlay();
    this.toMessage.SendMessage = true;
    const snack: Snack = {
      label: 'Sending credit note.',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack, 5000).show();
    this.messagesService.putCreditNoteMessage(this.toMessage).subscribe({
      next: (res) => {
      this.closeModal.emit('send-credit');
      this.loadingService.hideOverlay();
    }, error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      snack.label = 'Credit note sent';
      this.snackbarService.dismiss().make(snack, 5000).show();
    }
  });
  }
}
