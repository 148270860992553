<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">
        <nav class="navbar-tabs">
          <button
            class="tab"
            *ngIf="isPracticeManager"
            [class.active]="tabView === 'collections'"
            (click)="changeTabView('collections')">Collections Documents</button>
          <button
            class="tab"
            [class.active]="tabView === 'other-documents'"
            (click)="changeTabView('other-documents')">Other Documents</button>
        </nav>
        <!-- <div class="row">
          <app-checkbox
            style="margin-left: 15px"
            [(checked)]="showAllBrief"
            (click)="toggleShowAllDocuments()"></app-checkbox> &nbsp;
            <span class="clickable"
              (click)="toggleShowAllDocuments()">
            {{showDocumentToggle}}</span>
        </div> #end .row -->
        <!-- <div > -->
          <div class="row" *ngIf="tabView === 'collections'">
            <div *ngFor="let file of collectionsDocument">
            <!-- Brief and other documents -->
                <div class="fileList" *ngIf="file.state.isVisible">
                  <div class="added-file" *ngIf="file.state.isVisible">
                    <div class="file-header" *ngIf="file.state.isVisible">
                      <h5 style="margin-top: 5px;" *ngIf="file.state.isVisible"><b>{{file.data.DocumentType}}</b></h5>
                    </div>
                    <div class="file-body" *ngIf="file.state.isVisible">
                      <!-- <i class="fas fa-file-pdf" *ngIf="getExtension(file.DocumentPath) === 'pdf'" aria-expanded="false"></i> -->
                      <i class="fas fa-file-pdf" *ngIf="getExtension(file.data.DocumentPath) === 'pdf'"></i>
                      <i class="fas fa-file-word" *ngIf="getExtension(file.data.DocumentPath) === 'docx'"></i> <a [href]="downloadLink + file.data.CollectionsDocumentId"
                        target="_blank">{{file.data.DocumentPath}}</a>
                    </div>
                    <div class="file-footer" *ngIf="file.state.isVisible">
                      Added: {{file.data.DateInserted | date: 'yyyy-MM-dd'}}
                    </div>
                  </div>
                </div>
              </div>
          </div> <!-- #end .row -->

          <div class="row" *ngIf="tabView === 'other-documents'">
            <div class="fileList" *ngFor="let file of clientLetterOfDemandView">
              <div class="added-file" *ngIf="file">
                <div class="file-header">
                  <h5 style="margin-top: 5px;"><b>{{file.DocumentType}}</b></h5>
      
                  <button class="file-dismiss"
                    style="border: 0 !important; background-color:transparent !important; margin-top: 5px;">
                    <i
                      *ngIf="file.DocumentTypeID !== 11 && file.DocumentTypeID !== 12 && file.DocumentTypeID !== 14"
                      class="fa fa-pencil"
                      style="margin-right: 5px;"
                      (click)="onFileEdit(file.DocumentType, file)"
                      aria-hidden="true"></i>
                    <i
                      *ngIf="file.DocumentTypeID !== 11 && file.DocumentTypeID !== 12 && file.DocumentTypeID !== 14"
                      class="fas fa-trash"
                      (click)="onFileRemove(file.DocumentType, file)"
                      aria-expanded="false"></i>
                  </button>
                </div>
                <div
                  class="file-body"
                  *ngIf="getExtension(file.DocumentPath) === 'pdf'"
                  (click)="onViewDocument(file)">
                  <i class="fas fa-file-pdf" aria-expanded="false"></i>
                  <a [href]="lodDownloadLink + file.LODDocumentId"
                        target="_blank">
                  <u>{{file.DocumentPath}}</u></a>
                </div>
                <div class="file-body"
                  *ngIf="getExtension(file.DocumentPath) === 'docx' || getExtension(file.DocumentPath) === 'msg'">
                  <!-- <i class="fas fa-file-pdf" *ngIf="getExtension(file.DocumentPath) === 'pdf'" aria-expanded="false"></i> -->
                  <i class="fas fa-file-word"></i> <a [href]="lodDownloadLink + file.BriefId"
                    target="_blank">{{file.DocumentPath}}</a>
                </div>
                <div class="file-footer">
                  Added: {{file.LODDate | date: 'yyyy-MM-dd'}}
                </div>
              </div>
            </div>
      
           <form
              *ngIf="clientLetterOfDemandView.Id === 0"
              class="dropzone"
              #inputAttachmentFile
              appFileDrop
              (fileDropped)="handleDrop($event)"
              (filesHovered)="dropzoneState($event)"
              [ngClass]="{'active': dropzoneActive}"
              id="dropFileForm" action="" method="POST">
             <input type="file"
                #inputUploadFile
                name="files[]"
                id="fileInput"
                accept=".pdf, .docx, .msg"
                multiple
                (change)="fileAttachmentChange($event)">
             <label
                for="fileInput"
                id="fileLabel"
                style="margin-bottom: 0!important;">
               <i class="fas fa-file-plus fa-2x"></i> <br/>
               To <b>add a document</b>, drag & drop a document here or <u>browse to it</u>
             </label>
           </form>
          </div>
          

          <div class="row" *ngIf="hasSentWithDocuments && tabView === 'collections'" style="justify-content: center;">
            <div class="text-center p-50">
              <b style="color: #cf624f; font-weight: 900;">No documents attached</b>
            </div>
          </div> <!-- #end .row -->

        </div> <!-- #end .modal-body .form -->

        <div class="modal-footer">
          <button
            class="btn btn-danger"
            (click)="dismiss()">Cancel</button>
          <button
            class="btn btn-info"
            (click)="dismiss()">Ok</button>
        </div> <!-- #end .modal-footer -->
      <!-- </div> -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->

<app-document-types-modal
[isVisible]="showUploadModalDialog"
(cancel)="onHideUploadModalDialog()"
(upload)="onUploadFile($event)"
btnTextCancel="Cancel"
btnTextAddFile="Add file"
[documentTypes]="documentTypes"
[clientLetterOfDemand]="clientLetterOfDemand"
[attachmentFile]="attachmentFile"
[title]="documentName"></app-document-types-modal>