import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-circular-indicator',
  templateUrl: './circular-indicator.component.html',
  styleUrls: ['./circular-indicator.component.scss']
})
export class CircularIndicatorComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() message: string;

  images: any;

  constructor() {
    this.images = {
      spinner: {
        path: '../../../../../assets/images/logos/spinner.gif',
        label: 'Auxcon spinner'
      } // spinner{}
    };
  } // end constructor()

  ngOnInit() {
  } // end ngOnInit()
} // end CircularIndicatorComponent{}
