import { Injectable } from '@angular/core';
import { ToastComponent } from '../../../components/messaging/toast/toast.component';

export const LENGTH_SHORT = 3500; // 2000 millis
export const LENGTH_LONG = 5000; // 3500 millis

export interface Toast {
  text: string;
} // end Toast{}

@Injectable()
export class ToastService {

  toastComponent: ToastComponent;
  queue: Array<any> = [];

  constructor() { } // end constructor()

  setToastComponent(toastComponent: ToastComponent) {
    this.toastComponent = toastComponent;
  } // end setToastComponent()

  make(toast: Toast, duration: number = LENGTH_LONG): ToastService {
    if (this.toastComponent) {
      this.toastComponent.duration = duration;
      this.toastComponent.make(toast);
    }
    return this;
  } // end make()

  show(): ToastService {
    if (this.toastComponent) {
      this.toastComponent.show();
    }
    return this;
  } // end show()

  cancel(): ToastService {
    if (this.toastComponent) {
      this.toastComponent.cancel();
    }
    return this;
  } // end cancel()
} // end ToastService{}
