import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TAG_ADD_PLAINTIFF_MODAL } from '../../../services/tags/tags.service';
import {
  Plaintiff
} from '../../../services/plaintiffs/plaintiffs.service';

const TITLE_NEW_PLAINTIFFDEFENDANT = 'Add new Plaintiff and Defendant';

@Component({
  selector: 'app-add-plaintiffdefendant-modal',
  templateUrl: './add-plaintiffdefendant-modal.component.html',
  styleUrls: ['./add-plaintiffdefendant-modal.component.scss']
})
export class AddPlaintiffdefendantModalComponent implements OnInit, OnChanges {

  @Input() isVisible: boolean;
  @Input() plaintiffDefendant: Plaintiff;
  @Output() plaintiffDefendantChange: EventEmitter<Plaintiff> = new EventEmitter<Plaintiff>();
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() savePlaintiffDefendant: EventEmitter<Plaintiff> = new EventEmitter<Plaintiff>();

  title: string = TITLE_NEW_PLAINTIFFDEFENDANT;
  // plaintiffDefendant: string = '';
  // categories: string[] = categories;
  // rateTypes: string[] = rateTypes;
  // availabilities: string[] = availabilities;

  constructor() {
    this.reset();
  } // end constructor()

  ngOnInit() {
  } // ngOnInit()

  ngOnChanges() {
    // if (changes.feeTemplate && changes.feeTemplate.currentValue) {
    //   this.model = Object.assign({}, changes.feeTemplate.currentValue);

    //   if (this.model.SubCategoryId) {
    //     this.title = TITLE_EDIT_FEE_TEMPLATE;
    //   } else {
    //     this.title = TITLE_NEW_FEE_TEMPLATE;
    //   }
    // }
  } // end ngOnChanges{}

  private reset() {
    // this.title = TITLE_NEW_FEE_TEMPLATE;
    // this.model = Object.assign({}, DEFAULT_FEE_TEMPLATE);
  } // end reset()

  onDescriptionChange(description: string) {

  } // end onDescriptionChange()

  dismiss() {
    this.reset();
    this.closeModal.emit(TAG_ADD_PLAINTIFF_MODAL);
  } // end dismiss()

  /**
   * Saves the fee template.
   */
  save() {
     this.savePlaintiffDefendant.emit(this.plaintiffDefendant);
     this.dismiss();
  } // end save()

  validate() {

  } // end validate()
  onDescriptionInput(event) {
    this.plaintiffDefendantChange.emit(event.target.value);
  }
}
