import { Component, OnInit, ViewEncapsulation, Input, ViewChild, OnDestroy } from '@angular/core';
import { ReportsService, PracticeManagementParameters } from '../../../services/reports/reports.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Subscription } from 'rxjs';
import * as d3 from 'd3-selection';
import * as moment from 'moment';
import * as d3Scale from 'd3-scale';
import * as d3Axis from 'd3-axis';
import * as d3Array from 'd3-array';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'invoiced-collected-widget',
  templateUrl: './invoiced-collected-widget.component.html',
  styleUrls: ['./invoiced-collected-widget.component.scss']
})
export class InvoicedCollectedWidgetComponent implements OnInit, OnDestroy {
  @Input() graphWidth: number;
  @Input() graphHeight: number;
  @Input() graphMargin: any;
  subscription: Subscription;
  //d3: any;

  isTargetMissed = false;
  collectedMissed = 0;
  invoicedMissed = 0;
  totalInvoiced = '';
  totalCollected = '';

  constructor(
    private reportService: ReportsService,
    private loadingService: LoadingService
  ) {

  }

  practiceManagementParameters: PracticeManagementParameters = {
    fromDate: '',
    toDate: ''
  };

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
   
    this.fetchData();

    this.practiceManagementParameters.fromDate = moment().subtract(4, 'months').format('YYYY-MM-01');
    this.practiceManagementParameters.toDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
    this.reportService.setInvoicedCollectedWidgetParameters(this.practiceManagementParameters);
  }

  fetchData(): void {
    this.subscription = this.reportService.getInvoicedCollectedWidgetParameters().subscribe({
      next: (parameter) => {
      if (parameter === null) {
        return;
      }
      this.loadingService.showOverlay();
      this.reportService.getInvoicedCollectedMonthlySummary(parameter.fromDate, parameter.toDate).subscribe({ next:
        (response) => {

          this.loadGraph(response, parameter.fromDate, parameter.toDate);
        },
        error: (error) => {
          // Error
          console.log(error);
        },
        complete: () => {
          this.loadingService.hideOverlay();
        }
      });
    }, error: (error) => {
      // Error
      console.log(error);
    }});
  }

  // draw graph
  loadGraph(graphData, startDate, endDate): void {

    d3.selectAll('#timeScale1 > *').remove();
    d3.selectAll('#timeScale > *').remove();

    if (graphData.length === 0) {
      this.totalInvoiced = this.totalCollected = '';
    }

    const dateStart = moment(startDate);
    const dateEnd = moment(endDate);
    const timeValues = [];

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      timeValues.push({ year: dateStart.format('YYYY'), month: dateStart.format('M') });
      dateStart.add(1, 'month');
    }

    const data = [];
    let totalInvoiced = 0;
    let totalCollected = 0;

    var missedCounter = 0;
    timeValues.map(t => {
      const obj = graphData.find(x => x.year == t.year && x.monthNo == t.month);
      if (obj) {
        var im = obj.invoiceTarget - obj.invoicedAmount;
        var cm = obj.collectionTarget - obj.collectedAmount;

        var transformed = {
          month: `${moment().month(t.month - 1).format('MMM')}' ${t.year.toString().substr(-2)}`,
          invoicedAmount: obj.invoicedAmount,
          invoiceTarget: obj.invoiceTarget,
          invoiceMissedTarget: im > 0 ? im : 0,
          collectedAmount: obj.collectedAmount,
          collectionTarget: obj.collectionTarget,
          collectionMissedTarget: cm > 0 ? cm : 0,
          currencySymbol: obj.currencySymbol
        
        };
      
        data.push(transformed);
    

        totalInvoiced += obj.invoicedAmount;
        totalCollected += obj.collectedAmount;

        if (transformed.collectionMissedTarget > 0) {
          this.collectedMissed += 1;
        }

        if (transformed.invoiceMissedTarget > 0) {
          this.invoicedMissed += 1;
        }
      }
      else {
        data.push({

          month: `${moment().month(t.month - 1).format('MMM')}' ${t.year.toString().substr(-2)}`,
          invoicedAmount: 0,
          invoiceTarget: 0,
          invoiceMissedTarget: 0,
          collectedAmount: 0,
          collectionTarget: 0,
          collectionMissedTarget: 0,
          currencySymbol: graphData.length > 0 ? graphData[0].currencySymbol : ''

        });
      }
    });

    if (this.collectedMissed >= 2 || this.invoicedMissed >= 2) {
      this.isTargetMissed = true;
    }
    else {
      this.isTargetMissed = false;
    }

    if (graphData.length > 0) {
      this.totalInvoiced = `${graphData[0].currencySymbol}${this.numberConversion(totalInvoiced.toFixed(2))}`;
      this.totalCollected = `${graphData[0].currencySymbol}${this.numberConversion(totalCollected.toFixed(2))}`;
    }

    var svg = d3.select('#timeScale1');
   
    var margin = this.graphMargin,
      width = this.graphWidth - margin.left - margin.right,
      height = this.graphHeight - margin.top - margin.bottom;

      

      var x0 = d3Scale.scaleBand().rangeRound([0, width]).padding(0.1);
     
    // var x0 = d3.scale.ordinal().rangeRoundBands([0, width], 0.1);
   
    var x1 = d3Scale.scaleBand();
   // d3Scale.scaleBand().domain()
    //var x1 = d3.scale.ordinal();
   
    var y = d3Scale.scaleLinear().range([height, 0]);
    // var y = d3.scale.linear().range([height, 0]);


    var xAxis = d3Axis.axisBottom(x0).tickSize(0);
    // var xAxis = d3.svg.axis()
    //   .scale(x0)
    //   .tickSize(0)
    //   .orient('bottom');

    var yAxis = d3Axis.axisLeft(y).ticks(5).tickFormat(d => (data.length > 0 ? `${data[0].currencySymbol}` : '') + this.numberConversion(d));
    // var yAxis = d3.svg.axis()
    //   .scale(y)
    //   .orient('left')
    //   .ticks(5)
    //   //.tickSize(0);
    //   .tickFormat(d => (data.length > 0 ? `${data[0].currencySymbol}` : '') + this.numberConversion(d));
 

     var color = d3Scale.scaleOrdinal().range(['#5e97d8', '#cf624f', '#8abd37', '#cf624f']);
    // var color = d3.scale.ordinal()
    //   .range(['#5e97d8', '#cf624f', '#8abd37', '#cf624f']);

    var colorArr = ['#5e97d8', '#8abd37', '#cf624f'];
    // var tooltip = d3.select('body').append('div').attr('class', 'toolTip');

    var svg = svg
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('font-size', '11px')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    var yBegin;

    var innerColumns = {
      'column1': ['invoicedAmount', 'invoiceMissedTarget'],
      'column2': ['collectedAmount', 'collectionMissedTarget']
    }

    var columnHeaders = ['invoicedAmount', 'invoiceMissedTarget', 'collectedAmount', 'collectionMissedTarget'];

    let this_ = this;
    data.forEach(function (d: any) {
      var yColumn = new Array();
      d.columnDetails = [];
      columnHeaders.map(function (name) {
        var ck = Object.keys(innerColumns);
        ck.map(ic => {
          if (innerColumns[ic].includes(name)) {
            if (!yColumn[ic]) {
              yColumn[ic] = 0;
            }
            yBegin = yColumn[ic];
            yColumn[ic] += +d[name];

            var isInvoice = name.includes('invoice');
            const mT = this_.numberConversion(isInvoice ? d.invoiceMissedTarget : d.collectionMissedTarget);
            const a = this_.numberConversion(isInvoice ? d.invoicedAmount : d.collectedAmount);
            const t = this_.numberConversion(isInvoice ? d.invoiceTarget : d.collectionTarget);
            d.columnDetails.push({
              name: name,
              column: ic,
              yBegin: yBegin,
              yEnd: +d[name] + yBegin,
              isRound: false,
              info: `<div class='left'>
                <div class='line bold'>${isInvoice ? 'Invoice' : 'Collection'} target <span class='missed'>missed by </span></div>
                <div class='line'><span class='title'>Amount ${isInvoice ? 'Invoiced' : 'Collected'}:</span></div>
                <div class='line'><span class='title'>Target:</span></div>
              </div>
              <div class='right'>
                <div class='line missed bold'>${(data.length > 0 ? `${data[0].currencySymbol}` : '')}${mT}</div>
                <div class='line'>${(data.length > 0 ? `${data[0].currencySymbol}` : '')}${a}</div>
                <div class='line'>${(data.length > 0 ? `${data[0].currencySymbol}` : '')}${t}</div>
              </div>
              <div style='clear:both;'></div>
              `
            });
          }
        })

      });
      d.total = d3Array.extent(d.columnDetails, function (d) {
        return d.yEnd;
      });

      // d.total = d3.max(d.columnDetails, function (d) {
      //   return d.yEnd;
      // });

      let iT = (d.columnDetails[1].yBegin != d.columnDetails[1].yEnd);
      let iA = !iT && (d.columnDetails[0].yBegin != d.columnDetails[0].yEnd);

      let cT = (d.columnDetails[3].yBegin != d.columnDetails[3].yEnd);
      let cA = !cT && (d.columnDetails[2].yBegin != d.columnDetails[2].yEnd);

      d.columnDetails[0]['isRound'] = iA;
      d.columnDetails[1]['isRound'] = iT;
      d.columnDetails[2]['isRound'] = cA;
      d.columnDetails[3]['isRound'] = cT;
    });

    x0.domain(data.map(function (d: any) { return d.month; }));

   // x1.domain(d3.keys(innerColumns)).rangeRoundBands([0, x0.rangeBand()]);
   x1.domain(['column1','column2']).range([0, x0.bandwidth()]);
   
   

  y.domain([0, d3Array.max(data, function (d) {
    return d3Array.max(d.total);
  })]);

    // y.domain([0, d3.max(data, function (d) {
    //   return d.total;
    // })]);

    svg.append('g')
      .attr('class', 'y axis')
      .style('fill', '#4a4a4a')
      .attr('transform', 'translate(0,' + height + ')')
      .attr('font-size', '11px')
      .call(xAxis);


    svg.append('g')
      .attr('class', 'grid')
      .attr('opacity', 0.3)
      .attr('font-size', '11px')
      .call(d3Axis.axisLeft(y).tickFormat('').tickSize(-width, 0));

    // svg.append('g')
    //   .attr('class', 'grid')
    //   .attr('opacity', 0.3)
    //   .attr('font-size', '11px')
    //   .call(d3.svg.axis().scale(y).orient('left').tickFormat('').tickSize(-width, 0));

    svg.append('g')
      .attr('class', 'y axis')
      .style('fill', '#4a4a4a')
      .call(yAxis)
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('font-size', '11px')
      .attr('y', 10)
      .attr('dy', '.7em')
      .style('text-anchor', 'end')
      .text('');

    var project_stackedbar = svg.selectAll('.project_stackedbar')
      .data(data)
      .enter().append('g')
      .attr('class', 'g')
      .attr('font-size', '11px')
      .attr('transform', function (d) { return 'translate(' + x0(d.month) + ',0)'; });

    project_stackedbar.selectAll('rect')
      .data(function (d) { return d.columnDetails; })
      .enter()
      .append('rect')
      //.attr('width', (x1.rangeBand() - 2) > 15 ? 15 : (x1.rangeBand() - 2))
      .attr('width',  (x1.bandwidth() - 2) > 15 ? 15 : (x1.bandwidth() - 2))
       .attr('x', function (d) {
      //   //return (x1(d.column) + (d.column.includes('2') ? 2 : (x1.rangeBand() - 18)));
        return (Number(x1(d.column)) + (d.column.includes('2') ? 2 : (x1.bandwidth() - 18)));
         
       })
      .attr('y', function (d) {
        return (y(d.yEnd) + (d.isRound ? 15 : 0));
      })
      .attr('height', function (d) {
       
        return ((y(d.yBegin) - y(d.yEnd)) - (d.isRound ? 15 : 0));
      })
      .style('fill', function (d) { return color(d.name); });

    var project_stackedbar_round = svg.selectAll('.project_stackedbar_round')
      .data(data)
      .enter().append('g')
      .attr('class', 'g')
      .attr('transform', function (d) { return 'translate(' + x0(d.month) + ',0)'; });

      
    project_stackedbar_round.selectAll('rect')
      .data(function (d) { return d.columnDetails; })
      .enter()
      .append('rect')
      //.attr('width', (x1.rangeBand() - 2) > 15 ? 15 : (x1.rangeBand() - 2))
      .attr('width', (x1.bandwidth() - 2) > 15 ? 15 : (x1.bandwidth() - 2))
      .attr('rx', function (d) {
        return (d.isRound ? 2 : 0);
      })
      .attr('ry', function (d) {
        return (d.isRound ? 2 : 0);
      })
       .attr('x', function (d) {
        //return (x1(d.column) + (d.column.includes('2') ? 2 : (x1.rangeBand() - 18)));
         return (Number(x1(d.column)) + (d.column.includes('2') ? 2 : (x1.bandwidth() - 18)));
       })
      .attr('y', function (d) {  
        return y(d.yEnd);
      })
      .attr('height', function (d) {  
        return y(d.yBegin) - y(d.yEnd);
      })
      .style('fill', function (d) { return color(d.name); });



    var legend = svg.append('g')
      .attr('class', 'legend')
      .attr('x', width)
      .attr('height', 50)
      .attr('width', 300)
      .attr('font-size', '11px')
      .attr('transform', 'translate(' + (width - 460) + ', ' + (height - 30) + ')');

    legend.selectAll('g').data(['Invoiced', 'Collected', 'Missed Target'])
      .enter()
      .append('g')
      .each(function (d, i) {
        var g = d3.select(this);
        g.append('rect')
          .attr('x', i * 120)
          .attr('rx', 2)
          .attr('y', 65)
          .attr('width', 15)
          .attr('height', 15)
          .attr('font-size', '11px')
          .style('fill', colorArr[i]);

        g.append('text')
          .attr('x', i * 120 + 20)
          .attr('y', 78)
          .attr('height', 30)
          .attr('width', 100)
          .attr('font-size', '11px')
          .style('fill', '#4a4a4a')
          .text(d);
      });
  }

  numberConversion(x): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

}
