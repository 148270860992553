<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">
        <div class="row">
          <app-checkbox
            style="margin-left: 15px"
            [(checked)]="showAllBrief"
            (click)="toggleShowAllDocuments()"></app-checkbox> &nbsp;
            <span class="clickable"
              (click)="toggleShowAllDocuments()">
            {{showDocumentToggle}}</span>
        </div> <!-- #end .row -->
        <div class="row">
          <div *ngFor="let file of briefDocumentHandle">
          <!-- Brief and other documents -->
              <div class="fileList" *ngIf="file.state.isVisible">
                <div class="added-file" *ngIf="file.state.isVisible">
                  <div class="file-header" *ngIf="file.state.isVisible">
                    <h5 style="margin-top: 5px;" *ngIf="file.state.isVisible"><b>{{file.data.DocumentType}}</b></h5>
                  </div>
                  <div class="file-body" *ngIf="file.state.isVisible">
                    <!-- <i class="fas fa-file-pdf" *ngIf="getExtension(file.DocumentPath) === 'pdf'" aria-expanded="false"></i> -->
                    <i class="fas fa-file-pdf" *ngIf="getExtension(file.data.DocumentPath) === 'pdf'"></i>
                    <i class="fas fa-file-word" *ngIf="getExtension(file.data.DocumentPath) === 'docx' || getExtension(file.data.DocumentPath) === 'msg'"></i> <a [href]="downloadLink + file.data.BriefId"
                      target="_blank">{{file.data.DocumentPath}}</a>
                  </div>
                  <div class="file-footer" *ngIf="file.state.isVisible">
                    Added: {{file.data.DateInserted | date: 'yyyy-MM-dd'}}
                  </div>
                </div>
              </div>
            </div>
        </div> <!-- #end .row -->

        <div class="row" *ngIf="hasSentWithDocuments" style="justify-content: center;">
          <div class="text-center p-50">
            <b style="color: #cf624f; font-weight: 900;">No documents attached</b>
          </div>
        </div> <!-- #end .row -->

      </div> <!-- #end .modal-body .form -->

      <div class="modal-footer">
        <button
          class="btn btn-success"
          (click)="confirm()">Ok</button>
      </div> <!-- #end .modal-footer -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->