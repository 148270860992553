import { Component, OnInit, Input } from '@angular/core';
import { ICrumb } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() crumbs: ICrumb[];

  constructor(
    private router: Router
  ) { } // end constructor()

  ngOnInit() {
  } // end ngOnInit()

  onClick(crumb: ICrumb) {
    if (crumb.link) {
      this.router.navigate([crumb.link]);
    }
  } // end onClick()
} // end BreadcrumbComponent{}
