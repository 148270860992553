<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onDismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body">

        <div class="row">
          <div class="form-group">
            <label for="advocate-name">Advocate:</label>
            <span class="form-control" style="margin-right: 5px; background-color: lightgray;"
            id="advocate-name">{{SelectedTransaction.ProviderFullName}}</span>
          </div>
          <div class="form-group">
            <label for="payment-date" style="margin-left: 5px;">Payment Date:</label>
            <span class="form-control" style="margin-left: 5px; background-color: lightgray;"
            id="payment-date">{{SelectedTransaction.InvoiceDate | date:'yyyy-MM-dd'}}</span>
          </div>
        </div> <!-- #end .row -->

        <div class="row">
          <div class="form-group">
            <label for="description">Description:</label>
            <span class="form-control" style="margin-right: 5px; background-color: lightgray;"
            id="description">{{SelectedTransaction.ServiceDescription}}</span>
          </div>
          <div class="form-group">
            <label for="amount-paid" style="margin-left: 5px;">Amount Paid:</label>
            <span class="form-control" style="margin-left: 5px; background-color: lightgray;"
            id="amount-paid">{{SelectedTransaction.Paid | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
          </div>
        </div> <!-- #end .row -->

        <div class="row">
          <div class="form-group">
            <label for="amount-unallocated">Amount Unallocated:</label>
            <span class="form-control" style="background-color: lightgray;"
            id="amount-unallocated">{{SelectedTransaction.Total | currency: currencySymbol: currencySymbol:'1.2-2'}}</span>
          </div>
        </div> <!-- #end .row -->

        <div class="row">
          <div class="form-group">
            <label for="payment-resolution">Payment Resolution:</label>
            <textarea
              class="form-control"
              type="text"
              id="payment-resolution"
              [(ngModel)]="paymentResolutionText"></textarea>
          </div>
        </div> <!-- #end .row -->

      </div> <!-- #end .modal-body -->

      <div class="modal-footer">
        <button class="btn btn-danger" (click)="onDismiss()">Cancel</button>
        <button class="btn btn-success" (click)="onConfirm()">Send</button>
      </div> <!-- #end .modal-footer -->

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->