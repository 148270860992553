import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AdvocateDetailStatement } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { TAG_PAYMENT_DETAIL_MODAL } from '../../../services/tags/tags.service';
import { PaymentDetail } from '../../../services/payment-detail/payment-detail.service';
// import { Condition } from 'selenium-webdriver';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

@Component({
  selector: 'app-payment-detail-modal',
  templateUrl: './payment-detail-modal.component.html',
  styleUrls: ['./payment-detail-modal.component.scss']
})
export class PaymentDetailModalComponent implements OnInit, OnChanges {

  _paid = 0;
  _outStanding = 0;

  @Input() data: PaymentDetail[];
  @Input() selectedInvoice: AdvocateDetailStatement;
  @Input() isVisible: boolean;
  @Input() confirmText: string;
  @Output() closeModal: EventEmitter<string> = new EventEmitter();

  get totalPaymentAmount(): number {
    if (!this.data) {
      return 0;
    }

    let total = 0;
    this.data.forEach(item => {
      total += item.Amount;
      this._paid = total;
    });

    return total;
  } // end totalPaymentAmount()

  get totalOutstandingAmount(): number {
    if (!this.data) {
      return 0;
    }

    let total = 0;
   total = this.data[0].InvoiceAmount - this._paid;
    // this.data.forEach(item => {
    //   total += item.Outstanding;
    //   this._outStanding = total;
    // });

    return total;
  } // end totalPaymentAmount()

  get totalInvoiced(): number {
    return this.data[0].InvoiceAmount;
  } // end totalInvoiced()

  get isSaveable(): boolean {
    // TODO: Implement saveable logic

    return true;
  } // end isSaveable()

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  constructor(
    private userProfileService: UserProfileService
  ) { } // end constructor()

  ngOnInit() {

  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
  }

  onSavePaymentDetail() {
    this.onConfirm(this.data);
    this.onDismiss();
  } // end onSavePaymentDetail()

  onConfirm(data: PaymentDetail[]) {

  } // end onConfirm()

  onDismiss() {
    this.dismiss();
  } // end onDismiss()

  dismiss() {
    this.closeModal.emit(TAG_PAYMENT_DETAIL_MODAL);
  } // end dismiss()

} // end PaymentDetailModalComponent{}
