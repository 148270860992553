<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end modal-header -->

      <div class="modal-body form">

      <!-- Report Body -->
      <!-- <div id="invoice"> -->
        <!-- <object data="http://www.orimi.com/pdf-test.pdf" width="100%" height="100%" type="application/pdf"> -->
          <embed src="https://toggl.com/landing/files/editable-invoice-template.pdf" width="100%" height="100%" type="application/pdf" />
        <!-- </object> -->
      <!-- </div> -->

      </div> <!-- #end modal-body form-->
    </div> <!-- #end modal-dialog-->
  </div> <!-- #end modal-content-->
</div> <!-- #end modal-->

