<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <div class="modal-header">
                <h2>{{title}}</h2>
                <button class="modal-dismiss" (click)="onDismiss()">
                    <i class="fa fa-close"></i>
                </button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="form-group">
                        <label for="advocate-name">Advocate Name <span title="required field"> *</span> </label>
                        <input type="text" class="form-control" [(ngModel)]="workingAdvocate.FullName"
                            placeholder="Enter Advocate name" (click)="$event.target.select()" autocomplete="nocxua">
                    </div>
                    <div class="form-group">
                        <label for="phone">Phone<span title="required field"> *</span></label>
                        <input type="text" class="form-control" (blur)="onPhoneNumberBlur()"
                            [(ngModel)]="workingAdvocate.Phone" placeholder="Enter Phone number"
                            (click)="$event.target.select()" autocomplete="nocxua">
                    </div>
                    <!-- <div class="form-group" *ngIf="this.workingAdvocate && this.workingAdvocate.Id">
                        <label for="identity">Identity</label>
                        <input type="text" class="form-control" [(ngModel)]="workingAdvocate.Identity"
                            placeholder="Enter ID Number" (click)="$event.target.select()" autocomplete="nocxua">
                    </div> -->
                </div>
                <div class="row">
                    <div class="form-group">
                        <label for="email">Email<span title="required field"> *</span></label>
                        <input
                            type="text"
                            class="form-control"
                            [class.has.error]="hasEmail"
                            id="email"
                            [(ngModel)]="workingAdvocate.Email"
                            placeholder="Enter Email address"
                            (click)="$event.target.select()"
                            (blur)="onEmailBlur()"
                            autocomplete="nocxua">
                            <span class="text-danger" *ngIf="showEmailExistsPopup===true">
                                Email already exist
                            </span>
                    </div>
                    <div class="form-group">
                        <label for="cell">Cell</label>
                        <input type="text" class="form-control" id="cell" [(ngModel)]="workingAdvocate.Cell"
                            placeholder="Enter Cell number" (click)="$event.target.select()" autocomplete="nocxua">
                    </div>
                    <div class="form-group">
                        <label for="service-provider-client" style="margin-bottom: 2px;">Service Provider?</label>
                        <div class="view-dashboard">
                            <i
                              class="fa fa-toggle-on active"
                              id="view-dashboard"
                              *ngIf="serviceProvider == true"
                              (click)="changeStatus();"></i>
                            <i
                              class="fa fa-toggle-on fa-rotate-180 inactive"
                              id="view-dashboard"
                              *ngIf="serviceProvider == false"
                              (click)="changeStatus();"></i>
                          </div>
                        <!-- <div class="radio-group">
                            <label for="service-provider" class="radio-label">
                                <input type="radio" name="gender" id="service-provider" [(ngModel)]="serviceProvider" />
                                <span class="checkmark"></span>
                                Serive Provider
                            </label>
                            <label for="client" class="radio-label">
                                <input type="radio" name="gender" id="female" [(ngModel)]="client"  />
                                <span class="checkmark"></span>
                                Client
                            </label>
                        </div> -->
                    </div>
                    <!-- <div class="form-group" *ngIf="this.workingAdvocate && this.workingAdvocate.Id">
                        <label for="level">Seniority</label>
                        <select class="form-control" id="seniority" [(ngModel)]="workingAdvocate.Level">
                            <option [value]="null">Choose a seniority</option>
                            <option *ngFor="let lvl of professionalLevels" [value]="lvl.value"
                                [selected]="lvl.value === workingAdvocate.Level">{{lvl.label}}</option>
                        </select>
                    </div> -->
                </div>
                
                <div class="row mt-3">
                    <!-- admission Day -->
                    <div class="form-group">
                        <label>Admission Day</label>
                        <div class="input-group">
                            <select name="admissionDay" id="admissionDay" class="input-date"
                                [(ngModel)]="checkAdmissionDay" (change)="onChangeAdmissionDay()">
                                <option value="0" selected="selected">Select Day</option>
                                <option *ngFor="let day of admissionDay" value="{{day}}">{{day}}</option>
                            </select>
                        </div>
                    </div>

                    <!-- admission Month-->
                    <div class="form-group">
                        <label>Admission Month</label>
                        <div class="input-group">
                            <select name="fromMonth" id="fromMonth" class="input-date"
                                [(ngModel)]="checkAdmissionMonth" (change)="onChangeAdmissionMonth()">
                                <option value="0" selected="selected">Select Month</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <span *ngIf="isValidAdmissionMonth" class="text-danger">Select month</span>
                        </div>
                    </div>
                  
                    <!-- admission Year -->
                    <div class="form-group">
                        <label>Admission Year <span title="required field"> *</span></label>
                        <div class="input-group">
                            <select name="admissionYear" id="admissionYear" class="input-date"
                                [(ngModel)]="checkAdmissionYear"  (change)="onChangeAdmissionYear()">
                                <option value="0" selected="selected">Select Year</option>
                                <option *ngFor="let year of admissionYear" value="{{year.year}}">{{year.year}}</option>
                            </select>
                            <span *ngIf="isValidAdmissionYear" class="text-danger">Select year</span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group">
                        <div class="col-md-12 pl-0 pr-0">
                            <label>Regional Bar <span title="required field"> *</span></label>
                            <div class="badge">
                                <input class="form-control" [(ngModel)]="workingAdvocate.RegionalBarName"
                                    [typeahead]="regionalbarlist" typeaheadOptionField="Name"
                                    [typeaheadSingleWords]="false" (input)="onInput($event)" class="categotyText"
                                    placeholder="Select or start typing" (typeaheadOnSelect)="onSelect($event)"
                                    [typeaheadOptionsLimit]="100" typeaheadScrollable=true
                                    typeaheadOptionsInScrollableView="20" [typeaheadMinLength]="0" type="regionalBar"
                                    class="form-control" [regionalbarlist]="regionalbarlist"
                                    [(selectedRegionalBar)]="selectedRegionalBar"
                                    (select)="onModelChange('regionalBar', $event)">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="chamber">Chamber <span title="required field"> *</span></label>
                        <div class="badge">
                            <input
                                class="form-control"
                                [(ngModel)]="workingAdvocate.Chamber"
                                [typeahead]="chambersDrp"
                                typeaheadOptionField="ChamberName"
                                [typeaheadSingleWords]="false"
                                (input)="onInput($event)"
                                class="categotyText"
                                placeholder="Select or start typing"
                                (typeaheadOnSelect)="onChamberSelect($event)"
                                [typeaheadOptionsLimit]="100"
                                typeaheadScrollable=true
                                typeaheadOptionsInScrollableView="20" [typeaheadMinLength]="0" type="regionalBar"
                                class="form-control" [chambersDrp]="chambersDrp"
                                [(workingAdvocate.Chamber)]="workingAdvocate.Chamber"
                                (select)="onModelChange('regionalBar', $event)">
                        </div>
                       .
                    </div>
                </div>  

                <div class="row">
                    <div class="form-group">
                        <label for="ethnicity">Ethnicity <span title="required field"> *</span></label>
                        <select class='select-option hb' name="ethnicity" [(ngModel)]="workingAdvocate.Ethincity">
                            <option value="" selected="selected" [disabled]="false">-- Select --</option>
                            <option *ngFor='let ethnicity of ethnicityDrp'>
                                {{ethnicity.label}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="gender">Gender <span title="required field"> *</span></label>
                        <select class='select-option hb' name="gender" [(ngModel)]="workingAdvocate.Gender">
                            <option value="" selected="selected" [disabled]="false">-- Select --</option>
                            <option *ngFor='let gender of genderDrp'>
                                {{gender.label}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="seniority">Seniority <span title="required field"> *</span></label>
                        <select class='select-option hb' name="seniority" [(ngModel)]="workingAdvocate.Level">
                            <option value="" selected="selected" [disabled]="false">-- Select --</option>
                            <option *ngFor='let seniority of seniorityDrp'>
                                {{seniority.label}}
                            </option>
                        </select>
                    </div>
                </div>  

                <div class="row">
                    <div class="form-group">
                        <label for="sa-id-number">SA ID Number</label>
                        <input
                            type="text"
                            class="form-control"
                            id="vat"
                            [(ngModel)]="workingAdvocate.Identity"
                            placeholder="SA ID Number"
                            (click)="$event.target.select()"
                            autocomplete="nocxua">
                    </div>
                    <div class="form-group">
                        <label for="street-address">Street Address<span title="required field"> *</span></label>
                        <textarea
                            type="text"
                            id="street-address"
                            class="address-form"
                            placeholder="Law Firm Street Address"
                            [(ngModel)]="workingAdvocate.StreetAddress"></textarea>
                    </div>
                </div>  

                <div class="modal-footer">
                    <button class="btn btn-danger" (click)="onDismiss()">Cancel</button>

                    <button *ngIf="this.workingAdvocate && this.workingAdvocate.Id === 0" class="btn btn-success"
                    [class.disabled]="!isSubmittable" [disabled]="!isSubmittable" (click)="save()">Save</button>

                    <button *ngIf="this.workingAdvocate && this.workingAdvocate.Id"
                        [class.disabled]="!canActivateAdvocate" [disabled]="!canActivateAdvocate" class="btn btn-info"
                        (click)="onActivateUser()">Activate User</button>

                    <button *ngIf="this.workingAdvocate && this.workingAdvocate.Id" class="btn btn-success"
                    [class.disabled]="!isSubmittableUpdate" [disabled]="!isSubmittableUpdate"
                        (click)="update()">Update</button>
                </div>
            </div>
        </div>
    </div>

<app-prompt-unsaved-changes-modal
        *ngIf="showEmailExistsPopup"
        title="EMAIL ALREADY EXIST"
        message="Please take note that an account with this email address already exists. Please contact Auxcon at 012 343 9124 or email leigh-ann@auxcon.co.za to reset your login details."
        [isVisible]="showEmailExistsPopup"
        (cancel)="closeEmailExistsPopup()"
        btnTextCancel="Cancel"
        btnTextContinue="Ok"
        (continue)="closeEmailExistsPopup()"></app-prompt-unsaved-changes-modal>