<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">

<app-proof-of-payments
(updateProofOfPayment)="getProofOfPayments()"></app-proof-of-payments>

<app-prompt-unsaved-changes-modal
    message="One or more Proof of Payments does not have an invoice linked, please link invoices from the invoice list provided."
    [isVisible]="showUnHandledPopChangesPrompt"
    (cancel)="onHideUnHandledPopChangesPrompt()"
    (continue)="leavePage()">
</app-prompt-unsaved-changes-modal>