<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="onCancel()">
          <i class="fa fa-close"></i>
        </button>
      </div>

      <div class="modal-body form">
        <div class="container-fluid">
          <div class="table-responsive">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <th scope="col" class="w-7">Log Date</th>
                  <th scope="col" class="w-7">Activity Date</th>
                  <th scope="col" class="w-7">Diarize Date</th>
                  <th scope="col" class="w-15">Debtor</th>
                  <th scope="col" class="w-10">Invoice</th>
                  <th scope="col" class="w-24">Description</th> <!-- InstructionText -->
                  <th scope="col" class="w-24">Instruction</th> 
                  <th scope="col" class="w-10">Action</th>
                  <th scope="col" class="w-10">Action Type</th>
                  <th scope="col" class="w-10">Logged By</th>
                </tr>
                <tr *ngFor="let client of collectionDebtorProgressHandle">
                  <td *ngIf="client.state.isVisible" scope="row">{{client.data.actionDate | date:'yyyy-MM-dd'}}</td>
                  <td *ngIf="client.state.isVisible" scope="row">{{client.data.activityDate | date:'yyyy-MM-dd'}}</td>
                  <td *ngIf="client.state.isVisible" scope="row">{{client.data.diaryDate | date:'yyyy-MM-dd'}}</td>
                  <td *ngIf="client.state.isVisible" scope="row">{{client.data.debtor}}</td>
                  <td *ngIf="client.state.isVisible" scope="row">{{client.data.invoiceNo}}</td>
                  <td *ngIf="client.state.isVisible" scope="row">{{client.data.description}}</td>
                  <td *ngIf="client.state.isVisible" scope="row">{{client.data.instructionText}}</td>
                  <td *ngIf="client.state.isVisible" scope="row">{{client.data.action}}</td>
                  <td *ngIf="client.state.isVisible" scope="row">{{client.data.actionType}}</td>
                  <td *ngIf="client.state.isVisible" scope="row">{{client.data.loggedBy}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          class="btn btn-danger"
          (click)="onCancel()">Cancel</button>
        
        <button
          class="btn btn-success"
          (click)="onCancel()">Ok</button>
      </div>

    </div>
  </div>
</div> <!-- #end modal -->