import {
  Component, OnInit, Input, OnChanges,
  SimpleChanges
} from '@angular/core';
import { Matter } from '../../services/matters/matters.service';
import { Router, ActivatedRoute, NavigationStart, Event } from '@angular/router';
import { PATH_DASHBOARD } from '../../services/appdata/app-config.service';
import { DataService } from '../../services/messaging/data/data.service';
import { SnackbarsService, Snack } from '../../services/messaging/snackbars/snackbars.service';
import { } from 'protractor';
import { ApiService } from '../../services/api.service';
import {
  MessageTemplate,
  InvoiceMessageService,
  DEFAULT_PREPARE_MESSAGE_SEND,
  MessageSend,
  DEFAULT_OUTBOX_MESSAGE_SEND
} from '../../services/invoice-message/invoice-message.service';
import * as moment from 'moment';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../services/utilities.service';
import { NavigationService } from '../../services/navigation/navigation.service';
// import { SubscriptionLike as ISubscription } from 'rxjs';
import { ICrumb } from '../../services/advocate-detail-statement/advocate-detail-statement.service';
import {
  ActivityLogs,
  DEFAULT_ACTIVITY_LOGS,
  ActivityLogsService
} from '../../services/activity-logs/activity-logs.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { SubscriptionLike as ISubscription } from 'rxjs';

interface MatterHandle {
  data: Matter;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

interface MessageTemplateHandle {
  data: MessageTemplate;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
    isSaveable: boolean;
    isLineErrorVisible: boolean;
    isFutureDatedErrorVisible: boolean;
    isLoad: boolean
    isGenerated: boolean,
    ErrorMessage: string
  };
}

@Component({
  selector: 'app-advocate-process-invoices',
  templateUrl: './advocate-process-invoices.component.html',
  styleUrls: ['./advocate-process-invoices.component.scss']
})
export class AdvocateProcessInvoicesComponent implements OnInit, OnChanges {

  crumbs: ICrumb[];
  @Input() matter: MatterHandle[];

  // send message
  message: MessageTemplate;
  messageTemplate: MessageTemplate[] = [];
  messageTemplateHandle: MessageTemplateHandle[] = [];

  messageSend: MessageSend[] = [];

  selectedMatter: Matter[] = [];
  matterHandles: MatterHandle[] = [];
  mat: any[] = [];
  showLeavePagePrompt: boolean;
  selectedURL: string;
  private _subscritpions: ISubscription[] = [];

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);
  selectedMatterTab: string;
  isAllInvoiceProcessed: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private dataService: DataService,
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private navigationService: NavigationService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private invoiceMessageService: InvoiceMessageService,
  ) {
    document.title = route.snapshot.data.title;
  } // end constructor()

  get isSaveable(): Boolean {
    return Boolean(!this.messageTemplateHandle.find(m =>
      m.state.isFutureDatedErrorVisible === true ||
      m.state.isLineErrorVisible === true ||
      m.state.isLoad === true));
  } // end isSaveable()

  async ngOnInit() {
    this.selectedMatterTab = localStorage.getItem("selectedMatterTab");
    this.crumbs = [{
      label: 'Generate Multiple Invoices'
    }];
    const navSub = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.selectedURL = event.url;
        // this.onNavigationStart(event);
        this.navigationService.canNavigate = false;
        this.showLeavePagePrompt = true;
      } else {
        // this.navigationService.canNavigate = true;
      }
    });
    this._subscritpions.push(navSub);

    this.loadingService.showOverlay();
    this.matterHandles = this.dataService.data.matters;
    const snack: Snack = {
      label: 'Generating invoices',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, 5000).show();

    let Ids = [];
    this.matterHandles.forEach(mat => {

      Ids.push(mat.data.InvoiceId);

      const message = Object.assign({}, DEFAULT_PREPARE_MESSAGE_SEND);
      message.ContactId = this.apiService.serviceProviderID;
      message.InvoiceID = mat.data.InvoiceId;
      message.ServiceId = mat.data.ServiceID;
      message.ClientName = mat.data.InternalRefNo;
      message.InvoiceParties = mat.data.Description;
      message.InvoiceTotal = mat.data.Total;

      const state = Object.assign({}, {
        isVisible: true,
        isSelected: false,
        isOpen: false,
        isSaveable: false,
        isFutureDatedErrorVisible: false,
        isLineErrorVisible: false,
        isLoad: true,
        isGenerated: false,
        ErrorMessage: ''
      });

      this.messageTemplate.push(this.message);
      this.messageTemplateHandle.push({
        data: message,
        state: state
      });
      this.loadingService.hideOverlay();
    });

    for (let i = 0; i < Ids.length; i++) {
      await this.generateDocumentHandler(Ids[i]);
    }

    // Ids.forEach(async element => {
    //   this.invoiceMessageService.generateDocument(element).subscribe({
    //     next: (res) => {
    //       this.messageTemplateHandle.forEach(element => {
    //         if (element.data.InvoiceID == res.InvoiceId) {
    //           element.state.isLoad = false;
    //           element.state.isGenerated = res.isGenerated ? true : false;
    //           element.state.ErrorMessage = res.isGenerated ? '' : res.ErrorMessage;

    //           if (res.invMsgTemplate.InvoiceDetails.InvoiceDate === null)
    //             res.invMsgTemplate.InvoiceDetails.InvoiceDate = new Date().toJSON();

    //           element.data.ContactId = this.apiService.serviceProviderID;
    //           element.data.InvoiceID = res.invMsgTemplate.InvoiceID;
    //           element.data.ServiceId = res.invMsgTemplate.ServiceId;
    //           element.data.ID = res.invMsgTemplate.MessageTemplate.ID;
    //           element.data.Source = res.invMsgTemplate.MessageTemplate.Source;
    //           element.data.SourceId = res.invMsgTemplate.MessageTemplate.SourceId;
    //           element.data.Recipients = res.invMsgTemplate.MessageTemplate.Recipients;
    //           element.data.EMailAddresses = res.invMsgTemplate.MessageTemplate.EMailAddresses;
    //           element.data.Description = res.invMsgTemplate.MessageTemplate.Description;
    //           element.data.DocumentPath = res.invMsgTemplate.MessageTemplate.DocumentPath;
    //           element.data.Status = res.invMsgTemplate.MessageTemplate.Status;
    //           element.data.Subject = res.invMsgTemplate.MessageTemplate.Subject;
    //           element.data.Body = res.invMsgTemplate.MessageTemplate.Body;
    //           element.data.RolloverService = res.invMsgTemplate.MessageTemplate.RolloverService;
    //           element.data.SendMessage = res.invMsgTemplate.MessageTemplate.SendMessage;
    //           element.data.MessageDate = res.invMsgTemplate.InvoiceDetails.InvoiceDate;
    //           element.data.ClientName = res.invMsgTemplate.InvoiceDetails.ClientName;
    //           element.data.InvoiceNote = res.invMsgTemplate.InvoiceDetails.InvoiceNote;
    //           element.data.InvoiceTotal = res.invMsgTemplate.InvoiceDetails.InvoiceTotalExcl;
    //           element.data.InvoiceParties = res.invMsgTemplate.InvoiceDetails.InvoiceParties;
    //           element.data.InvoiceNo = res.invMsgTemplate.InvoiceDetails.InvoiceNo;
    //           element.data.InvoiceDate = res.invMsgTemplate.InvoiceDetails.InvoiceDate;
    //         }
    //       });
    //     },
    //     error: (error) => { },
    //     complete: () => { }
    //   });
    // });



    // this.invoiceMessageService.getInvoiceMessage(Ids).subscribe({
    //   next: (details) => {
    //     details.forEach(element => {
    // const message = Object.assign({}, DEFAULT_PREPARE_MESSAGE_SEND);
    // message.ContactId = this.apiService.serviceProviderID;
    // message.InvoiceID = element.InvoiceID;
    // message.ServiceId = element.ServiceId;
    // message.ID = element.MessageTemplate.ID;
    // message.Source = element.MessageTemplate.Source;
    // message.SourceId = element.MessageTemplate.SourceId;
    // message.Recipients = element.MessageTemplate.Recipients;
    // message.EMailAddresses = element.MessageTemplate.EMailAddresses;
    // message.Description = element.MessageTemplate.Description;
    // message.DocumentPath = element.MessageTemplate.DocumentPath;
    // message.Status = element.MessageTemplate.Status;
    // message.Subject = element.MessageTemplate.Subject;
    // message.Body = element.MessageTemplate.Body;
    // message.RolloverService = element.MessageTemplate.RolloverService;
    // message.SendMessage = element.MessageTemplate.SendMessage;
    // message.MessageDate = element.InvoiceDetails.InvoiceDate;
    // message.ClientName = element.InvoiceDetails.ClientName;
    // message.InvoiceNote = element.InvoiceDetails.InvoiceNote;
    // message.InvoiceTotal = element.InvoiceDetails.InvoiceTotalExcl;
    // message.InvoiceParties = element.InvoiceDetails.InvoiceParties;
    // message.InvoiceNo = element.InvoiceDetails.InvoiceNo;
    // message.InvoiceDate = element.InvoiceDetails.InvoiceDate;

    // const state = Object.assign({}, {
    //   isVisible: true,
    //   isSelected: false,
    //   isOpen: false,
    //   isSaveable: false,
    //   isFutureDatedErrorVisible: false,
    //   isLineErrorVisible: false,
    //   isLoad: true,
    //   isGenerated: false,
    //   ErrorMessage: ''
    // });

    // this.messageTemplate.push(this.message);
    // this.messageTemplateHandle.push({
    //   data: message,
    //   state: state
    // });
    //   });
    // },
    // error: (error) => { },
    // complete: () => {
    //   this.loadingService.hideOverlay();
    // Ids.forEach(element => {
    //   this.invoiceMessageService.generateDocument(element).subscribe({
    //     next: (res) => {
    //       this.messageTemplateHandle.forEach(element => {
    //         if (element.data.InvoiceID == res.InvoiceId) {
    //           element.state.isLoad = false;
    //           element.state.isGenerated = res.isGenerated ? true : false;
    //           element.state.ErrorMessage = res.isGenerated ? '' : res.ErrorMessage;
    //         }
    //       });
    //     },
    //     error: (error) => { },
    //     complete: () => { }
    //   });
    // });
    //}
    //});

  } // end ngOnInit()

  async generateDocumentHandler(InvoiceId: number) {
    try {
      const res = await this.invoiceMessageService.generateDocument(InvoiceId);
      this.messageTemplateHandle.forEach(element => {
        if (element.data.InvoiceID == res.InvoiceId) {
          element.state.isLoad = false;
          element.state.isGenerated = res.isGenerated ? true : false;
          element.state.ErrorMessage = res.isGenerated ? '' : res.ErrorMessage;

          if (res.invMsgTemplate.InvoiceDetails.InvoiceDate === null)
            res.invMsgTemplate.InvoiceDetails.InvoiceDate = new Date().toJSON();

          element.data.ContactId = this.apiService.serviceProviderID;
          element.data.InvoiceID = res.invMsgTemplate.InvoiceID;
          element.data.ServiceId = res.invMsgTemplate.ServiceId;
          element.data.ID = res.invMsgTemplate.MessageTemplate.ID;
          element.data.Source = res.invMsgTemplate.MessageTemplate.Source;
          element.data.SourceId = res.invMsgTemplate.MessageTemplate.SourceId;
          element.data.Recipients = res.invMsgTemplate.MessageTemplate.Recipients;
          element.data.EMailAddresses = res.invMsgTemplate.MessageTemplate.EMailAddresses;
          element.data.Description = res.invMsgTemplate.MessageTemplate.Description;
          element.data.DocumentPath = res.invMsgTemplate.MessageTemplate.DocumentPath;
          element.data.Status = res.invMsgTemplate.MessageTemplate.Status;
          element.data.Subject = res.invMsgTemplate.MessageTemplate.Subject;
          element.data.Body = res.invMsgTemplate.MessageTemplate.Body;
          element.data.RolloverService = res.invMsgTemplate.MessageTemplate.RolloverService;
          element.data.SendMessage = res.invMsgTemplate.MessageTemplate.SendMessage;
          element.data.MessageDate = res.invMsgTemplate.InvoiceDetails.InvoiceDate;
          element.data.ClientName = res.invMsgTemplate.InvoiceDetails.ClientName;
          element.data.InvoiceNote = res.invMsgTemplate.InvoiceDetails.InvoiceNote;
          element.data.InvoiceTotal = res.invMsgTemplate.InvoiceDetails.InvoiceTotalExcl;
          element.data.InvoiceParties = res.invMsgTemplate.InvoiceDetails.InvoiceParties;
          element.data.InvoiceNo = res.invMsgTemplate.InvoiceDetails.InvoiceNo;
          element.data.InvoiceDate = res.invMsgTemplate.InvoiceDetails.InvoiceDate;
        }
      });
    } catch (error) {
      console.error('Error generating document:', error);
    }
  }

  hasInvoiceDateLessThanError(highestDate: string, invDate: string): boolean {
    const highDate = new Date(highestDate);
    const invoiceDate = new Date(invDate);
    const hd = moment(highDate.toDateString());
    const id = moment(invoiceDate.toDateString());
    if (id.isSameOrAfter(hd)) {
      return false;
    }
    return true;
  }

  isFutureDated(invDate: string): boolean {
    const invoiceDate = new Date(invDate);
    const now = moment().format('YYYY-MM-DD');
    const id = moment(invoiceDate.toDateString());
    if (id.isAfter(now)) {
      return true;
    }
    return false;
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this._subscritpions.forEach((sub) => {
      sub.unsubscribe();
    });
  } // end ngOnDestroy()

  ngOnChanges(changes: SimpleChanges) {
  } // end ngOnChanges()

  onMessageTemplateHandleChange(event) {
    this.messageTemplateHandle = event;
  } // end onMessageTemplateHandleChange()()

  onCancel() {
    this.router.navigate([PATH_DASHBOARD]);
  } // end onCancel()

  onSendViaEmail() {
    this.sendInvoiceMessage();
  } // end onSendViaEmail()

  onNavigationStart(event: NavigationStart) {
    const route = event.url;
    this.navigationService.canNavigate = false;
    this.showLeavePagePrompt = true;
  } // end onNavigationStart()

  onHideLeavePagePrompt() {
    this.showLeavePagePrompt = false;
  } // end onShowUnsavedChangesPrompt()

  onDismiss() {
    this.router.navigate([this.selectedURL]);
    this.showLeavePagePrompt = false;
    this.navigationService.canNavigate = true;
  }

  sendInvoiceMessage() {
    this.loadingService.showOverlay();
    this.messageTemplateHandle.forEach(details => {
      if (details.state.isGenerated) {
        const messageSend = Object.assign({}, DEFAULT_OUTBOX_MESSAGE_SEND);
        messageSend.Id = details.data.ID;
        messageSend.InvoiceID = details.data.SourceId;
        messageSend.ServiceProviderID = this.apiService.serviceProviderID;
        messageSend.MessageType = 2;
        messageSend.InvoiceDate = details.data.InvoiceDate;
        messageSend.InvoiceNote = details.data.InvoiceNote ? details.data.InvoiceNote : '';
        messageSend.Recipients = details.data.Recipients;
        messageSend.EMailAddresses = [details.data.EMailAddresses];
        messageSend.Subject = details.data.Subject;
        messageSend.Body = details.data.Body;
        messageSend.RolloverService = details.data.RolloverService;
        messageSend.SendMessage = true;

        // Log activity Login
        const currentDate = new Date();
        this.activityLog.Action = 'Send multiple invoices';
        this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
        this.activityLog.LoggedApp = 'Web Application (Advocate-process-invoices)';
        if (this.userProfileService.userProfile.isImpersonator) {
          this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
        } else {
          this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
          this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
        }
        this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
        this.activityLog.ActionTable = 'Messages';
        this.activityLog.JsonData = JSON.stringify(messageSend);
        this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (_activity) => { } });

        this.messageSend.push(messageSend);
      }
    });

    const snack: Snack = {
      label: 'Sending invoices...',
      action: null
    };
    this.invoiceMessageService
      .updateInvoiceMessage(1, this.messageSend)
      .subscribe({
        next: (sentmessage) => {

        },
        error: (error) => {
          const msg = 'Error updating invoice message.';
          console.error(msg, error);
        },
        complete: () => {
          // OnComplete
          this.loadingService.hideOverlay();
          snack.label = 'Invoices sent.';
          this.snackbarsService.dismiss().make(snack).show();
          // this.sendInvoice();
          this.router.navigate([PATH_DASHBOARD]);
          this.showLeavePagePrompt = false;
          this.navigationService.canNavigate = true;
        }
      });
    this.snackbarsService.make(snack, 5000).show();
  } // end sendInvoiceMessage()
}
