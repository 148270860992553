import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contacts, ContactsService, DEFAULT_CONTACTS } from '../../../services/contacts/contacts.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UtilitiesService } from '../../../services/utilities.service';
import { Snack, SnackType, SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';

@Component({
  selector: 'app-add-new-secretary',
  templateUrl: './add-new-secretary.component.html',
  styleUrls: ['./add-new-secretary.component.scss']
})
export class AddNewSecretaryComponent implements OnInit {

  title = 'Add new Secretary';
  workingSecretary: Contacts = DEFAULT_CONTACTS;

  @Input() isVisible: boolean;
  @Input() selectedSecretary: Contacts;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSaveSecretary: EventEmitter<any> = new EventEmitter<any>();

  get canActivateSecretary(): boolean {
    return Boolean(this.workingSecretary && this.workingSecretary.Id) &&
          Boolean(this.workingSecretary && !this.workingSecretary.UserName);
  } // end canActivateSecretary()

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private contactService: ContactsService,
    private snackbarsService: SnackbarsService,
  ) { } // end constructor()

  ngOnInit(): void {
    if (this.selectedSecretary && this.selectedSecretary.Id) {
      this.title = 'Edit Advocate';

    }
    this.workingSecretary = this.util.objectCopy(this.selectedSecretary);
  } // end ngOnInit()

  onDismiss() {
    this.closeModal.emit('secretary');
  } // end onDismiss()

  save() {
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: 'Adding new Secretary ...',
      action: null
    };
    this.snackbarsService.make(snack, 5000).show();

    this.workingSecretary.ContactContactType = 4;
    this.contactService.postNewAdvocate(this.workingSecretary).subscribe({ next: (data) => {
      // On next
    },
    error: (error) => {
      // On next
      console.log('ERROR ADDING SECRETARY: ', error);
      snack.label = error;
      snack.type = SnackType.ERROR;
      this.snackbarsService.dismiss().make(snack, 5000).show();

      this.loadingService.hideOverlay();
    },
    complete: () => {
        // On complete
        snack.label = 'New Secretary added';
        this.snackbarsService.dismiss().make(snack).show();
        this.onSaveSecretary.emit('saved');
      }
    });
  } // end save()

  onActivateUser() {
    this.loadingService.showOverlay();
    this.contactService.putActivateUser(this.workingSecretary.Id).subscribe({ next: (data) => {
      // ON next
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.onSaveSecretary.emit('updated');
    }
  })
  } // end onActivateUser()

  update() {
    this.loadingService.showOverlay();
    this.contactService.PutContact(this.workingSecretary).subscribe({ next: (data) => {
      // ON next
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.onSaveSecretary.emit('updated');
    }
  });
  } // end update()
} // end AddNewSecretaryComponent {}
