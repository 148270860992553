import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import {
  TAG_ADD_NEW_LAW_FIRM_MODAL,
  TAG_ADD_PLAINTIFF_MODAL,
  TAG_ADD_ATTORNEY_FIRM_MODAL,
  TAG_ADD_CLIENT_MODAL
} from '../../../services/tags/tags.service';
import { Matter } from '../../../services/matters/matters.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { PlaintiffsService, Plaintiff } from '../../../services/plaintiffs/plaintiffs.service';
import { SnackbarsService, SnackType, Snack } from '../../../services/messaging/snackbars/snackbars.service';
import { OtherClients, DEFAULT_OTHERCLIENTS_TEMPLATE, OtherClientsService } from '../../../services/other-clients/other-clients.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

interface ContactHandle {
  data: any;
  state: {
    isVisible: boolean;
    isSelected: boolean;
  };
} // end ContactHandle{}

@Component({
  selector: 'app-datalist-lawfirm',
  templateUrl: './datalist-lawfirm.component.html',
  styleUrls: ['./datalist-lawfirm.component.scss']
})

export class DatalistLawfirmComponent implements OnInit, OnChanges {

  @Input() otherClients: Array<any>;
  @Input() list: Array<any>;
  @Input() matter: Matter;
  @Input() type: string;
  @Input() iconClass: string;
  @Input() required: boolean;
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  types = {
    OTHER_CLIENTS: 'other-clients',
    LAW_FIRMS: 'law-firms',
    ATTORNEYS: 'attorneys',
    PLAINTIFFS: 'plaintiffs',
    DEFENDANTS: 'defendants'
  };

  customSelected: string;
  lawfirmList: any[];
  attorneyList: any[];
  other_clients: any[];
  private selectedItem: any;

  showNewLawFirmModal: boolean;
  showNewAttorneyModal: boolean;
  showLawfirmTab : boolean = true;
  showLawfirmErrorPrompt: boolean;
  showClientErrorPrompt: boolean;
  showAttorneyErrorPrompt: boolean;
  addNewbuttonClicked = false;
  showAddClientModal = false;
  promptTitle: string;
  message: string;
  showSaveContactModelPrompt: boolean;

  otherClient: OtherClients = Object.assign({}, DEFAULT_OTHERCLIENTS_TEMPLATE);

  constructor(
    private plaintiffsService: PlaintiffsService,
    private snackbarsService: SnackbarsService,
    private loadingService: LoadingService,
    private otherClientService: OtherClientsService
  ) { }

  ngOnInit() {
    if (this.list && this.list.length === 0) {
        this.lawfirmList = JSON.parse(localStorage.getItem('lawFirms'));
    } else {
      this.lawfirmList = this.list;
    }
  } // end ngOnInit()

  get canAddLawfirm(): boolean {
    if (this.selectedItem) {
      return !Boolean(this.selectedItem.ContactID > 0);
    } else {
      return true;
    }
  } // end get canAddLawfirm()

  get canAddAttorney(): boolean {
    if (this.selectedItem) {
      return !Boolean(this.selectedItem.ContactID > 0);
    } else {
      return true;
    }
  } // end get canAddAttorney()

  ngOnChanges(changes: SimpleChanges) {
    if (this.list) {
      this.attorneyList = this.list;
    }
      if (changes.matter && changes.matter.currentValue) {
        if (this.matter.InternalRefNo && this.lawfirmList) {
          this.lawfirmList.forEach(s => s.FullName === this.matter.InternalRefNo);
          if (changes.matter && changes.matter.previousValue) {
            this.selectedItem = this.lawfirmList.find(firm => firm.FullName === this.matter.InternalRefNo);
          }
        }
      }

    if (changes.matter && changes.matter.currentValue) {
      if (this.matter.NewClientDetail) {
        this.attorneyList.forEach(s => s.FullName === this.matter.NewClientDetail);
      }
    }

    if (this.otherClients) {
      this.other_clients = this.otherClients;
      if (this.otherClients.length === 0) {
      }
    }
  } // end ngOnChanges()

  onLawfirmChange() {
    switch (this.type) {
      case this.types.LAW_FIRMS:
      setTimeout (() => {
        if (!this.matter.ContactID && this.matter.InternalRefNo && this.addNewbuttonClicked === false) {
          this.showLawfirmErrorPrompt = true;
        } else {
          this.showLawfirmErrorPrompt = false;
        }
      }, 300);
      break;

      case this.types.OTHER_CLIENTS:
      setTimeout (() => {
        if (!this.matter.ContactID && this.matter.InternalRefNo && this.addNewbuttonClicked === false) {
          this.showClientErrorPrompt = true;
        } else {
          this.showClientErrorPrompt = false;
        }
      }, 300);
      break;

      case this.types.ATTORNEYS:
        setTimeout(() => {
          if (this.matter.NewClientDetail) {
            if (!this.matter.ClientContactID && this.addNewbuttonClicked === false) {
              this.showAttorneyErrorPrompt = true;
            } else {
              this.showAttorneyErrorPrompt = false;
            }
          }
        }, 300);
        break;
    }
  } // end onLawfirmChange()

  onHideLawfirmErrorPrompt() {
    this.matter.InternalRefNo = '';
    this.showLawfirmErrorPrompt = false;
  } // end onHideLawfirmErrorPrompt()

  onHideClientErrorPrompt() {
    this.matter.InternalRefNo = '';
    this.showClientErrorPrompt = false;
  } // end onHideClientErrorPrompt()

  onHideAttorneyErrorPrompt() {
    this.matter.NewClientDetail = '';
    this.showAttorneyErrorPrompt = false;
  } // end onHideAttorneyErrorPrompt()

  onSelect(event: TypeaheadMatch): void {
    switch (this.type) {
      case this.types.LAW_FIRMS:
        this.selectedItem = event.item;
        this.customSelected = event.item.FullName;
        this.matter.InternalRefNo = event.item.FullName;
        this.matter.ContactID = event.item.ContactID;
        this.select.emit(this.selectedItem);
        // this.canAddLawfirm = false;
      break;

      case this.types.OTHER_CLIENTS:
        this.selectedItem = event.item;
        this.customSelected = event.item.FullName;
        this.matter.InternalRefNo = event.item.FullName;
        this.matter.ContactID = event.item.ContactID;
        this.select.emit(this.selectedItem);
        // this.canAddLawfirm = false;
      break;

      case this.types.ATTORNEYS:
        this.selectedItem = event.item;
        this.customSelected = event.item.FullName;
        this.matter.NewClientDetail = event.item.FullName;
        this.matter.ClientContactID = event.item.ClientContactID;
        this.select.emit(this.selectedItem);
        break;
    }
  } // end onSelect{}

  onFocus() {
    if (this.matter.InternalRefNo === '') {
      this.attorneyList = [];
    }
  } // end onFocus()

  onInput (event) {
    if (this.type === this.types.LAW_FIRMS) {
      if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
        this.selectedItem = null;
        this.matter.ContactID = 0;
        this.matter.ClientContactID = 0;
        this.matter.NewClientDetail = '';
        this.attorneyList = [];
      }
    } else if (this.type === this.types.OTHER_CLIENTS) {
      if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
        this.selectedItem = null;
        this.matter.ContactID = 0;
        // this.matter.ClientContactID = 0;
        // this.matter.NewClientDetail = '';
        // this.attorneyList = [];
      }
    } else if (this.type === this.types.ATTORNEYS) {
      if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
        this.selectedItem = null;
        this.matter.ClientContactID = 0;
      }
    }
  } // end onInput()

  addNewLawfirmAttorney(type: string) {
    this.addNewbuttonClicked = true;
    switch (type) {
      case this.types.LAW_FIRMS:
        this.showNewLawFirmModal = true;
        break;
      case this.types.ATTORNEYS:
        this.showNewAttorneyModal = true;
        break;
        case this.types.OTHER_CLIENTS:
        this.showAddClientModal = true;
        break;
    }
  } // end addNewLawfirmAttorney()

  onCloseModal(modal: string) {
    this.addNewbuttonClicked = false;
    switch (modal) {
      case TAG_ADD_NEW_LAW_FIRM_MODAL:
        this.showNewLawFirmModal = false;
        break;

      case TAG_ADD_ATTORNEY_FIRM_MODAL:
        this.showNewAttorneyModal = false;
        break;

      case TAG_ADD_CLIENT_MODAL:
        this.showAddClientModal = false;
        break;
    }
  } // end onCloseModal()

  onSaveOtherClients(client: OtherClients) {
    this.loadingService.showOverlay();

    const isNewClient: boolean = client.ContactID < 1;
    const snack: Snack = {
      label: isNewClient
        ? 'Creating new Client...'
        : 'Saving Client...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, 5000).show();

    if (!isNewClient) {
      // Update an existing client
      this.otherClientService.updateOtherClient(client)
        .subscribe({
          next: (next) => {
            // On next
          this.otherClient = next;
          },
          error: (error) => {
            snack.label = 'Error updating client.';
            snack.type = SnackType.ERROR;
            this.loadingService.hideOverlay();
          },
          complete: () => {
            // On complete
            snack.label = 'Client updated.';
            snack.type = SnackType.SUCCESS;
            this.loadingService.hideOverlay();
          }
        });
          
        //   next => {
        //   // On next
        //   this.otherClient = next;
        //   // this.getClients();
        //   // this.loadingService.hideOverlay();
        // },
        //   error => {
        //     snack.label = 'Error updating client.';
        //     snack.type = SnackType.ERROR;
        //     this.loadingService.hideOverlay();
        //   },
        //   () => {
        //     // On complete
        //     snack.label = 'Client updated.';
        //     snack.type = SnackType.SUCCESS;
        //     this.loadingService.hideOverlay();
        //   });
    } else {
     this.otherClientService.searchContacts(client.MobileNumber).subscribe(
       cell_results => {
         // On next
        //  if (cell_results.length > 0) {
        //    this.promptTitle = 'Cell number already in use.';
        //    this.message = 'The Cell Cellnumber entered: ' +
        //           client.MobileNumber +
        //           ' already exist on our system, please use another one.';

        //     snack.label = this.message;
        //     this.snackbarsService.dismiss().make(snack).show();
        //     this.loadingService.hideOverlay();
        //     this.showSaveContactModelPrompt = true;
        //  } else {
           this.otherClientService.searchContacts(client.EmailAddress).subscribe(
             email_results => {
               // On next
              //  if (email_results.length > 0) {
              //    this.promptTitle = 'Email address already in use.';
              //    this.message = 'The email address entered: ' +
              //       client.EmailAddress +
              //       ' already exist on our system, please use another one.';

              //   snack.label = this.message;
              //   this.snackbarsService.dismiss().make(snack).show();
              //   this.loadingService.hideOverlay();
              //   this.showSaveContactModelPrompt = true;
              //  } else {
                 this.otherClientService.createClient(client)
                   .subscribe({
                     next: (created_client) => {
                      this.otherClient = created_client;
                     },
                     error: (error) => {
                      snack.label = 'Error on updating client';
                      snack.type = SnackType.ERROR;
                      this.snackbarsService.dismiss().make(snack, 5000).show();
                      this.loadingService.hideOverlay();
                     },
                     complete: () => {
                      snack.label = 'Error on updating client';
                      snack.type = SnackType.ERROR;
                      this.snackbarsService.dismiss().make(snack, 5000).show();
                      this.loadingService.hideOverlay();
                     }
                   });
                     
                  //   created_client => {
                  //    this.otherClient = created_client;
                  //    // On next
                  //  },
                  //    error => {
                  //      snack.label = 'Error on updating client';
                  //      snack.type = SnackType.ERROR;
                  //      this.snackbarsService.dismiss().make(snack, 5000).show();
                  //      this.loadingService.hideOverlay();
                  //    },
                  //    () => {
                  //      // On complete
                  //      snack.label = 'Client created';
                  //      snack.type = SnackType.SUCCESS;
                  //      this.snackbarsService.dismiss().make(snack, 5000).show();
                  //      this.otherClients.push(this.otherClient);
                  //      this.loadingService.hideOverlay();
                  //      // this.getClients();
                  //    });
              //  }
             }
           );
        //  }
       }
     );
    }
  } // end onSaveOtherClients()

  onHideSaveContactModelPrompt() {
    this.showSaveContactModelPrompt = false;
  } // end onHideSaveContactModelPrompt()

  // onSaveOtherClients(otherClient) {
  //   if (otherClient.FullName === undefined) {
  //     this.otherClient = {
  //       FullName: otherClient,
  //       ContactID: 0,
  //       EmailAddress: '',
  //       IDNumber: '',
  //       MobileNumber: '',
  //       WorkPhone: '',
  //       WorkFax: '',
  //       VATNumber: '',
  //       StreetAddressFull: ''
  //     };
  //   }

  //   const isInList = this.otherClients.find((item) => {
  //     return item.FullName.localeCompare(this.otherClient.FullName) === 0;
  //   });
  //   if (!isInList) {
  //     const snack: Snack = {
  //       label: 'Saving new Client...',
  //       action: null
  //     };
  //     this.snackbarsService.make(snack).show();
  //     this.plaintiffsService.createOtherClients(this.otherClient).subscribe(
  //       plaintiff => {
  //         this.otherClient = plaintiff;
  //       },
  //       error => {
  //         const msg = 'Error saving new Client.';
  //         console.error(msg, error);
  //         snack.label = msg;
  //         snack.type = SnackType.ERROR;
  //         this.snackbarsService.dismiss().make(snack, 5000).show();
  //       },
  //       () => {
  //         snack.label = 'Client created.';
  //         snack.type = null;
  //         this.snackbarsService.dismiss().make(snack, 5000).show();
  //         // const contactHandle = this.createContactHandle(this.otherClient);
  //         // this.contactList.push(contactHandle);
  //         this.otherClients.push(this.otherClient);
  //         // this.changeList(this.otherClient.FullName);
  //         // this.resetList();
  //         // this.onSelect(contactHandle);
  //       }
  //     );
  //   } else {
  //     this.otherClient = isInList;
  //     // const contactHandle = this.createContactHandle(this.otherClient);
  //     // this.changeList(this.otherClient.FullName);
  //     // this.resetList();
  //     // this.onSelect(contactHandle);
  //   }
  // }
}
