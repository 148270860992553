import { Component, OnInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { Router } from '@angular/router'
import { environment } from '../../../environments/environment'
import { LoadingService } from "../../services/messaging/loading/loading.service";
import { PATH_SIGN_IN } from '../../services/appdata/app-config.service';
import { UserProfileService, DEFAULT_EXTERNAL_USER, InviteExternalUser, UserOnboardingMinimum } from '../../services/user-profile/user-profile.service';
@Component({
  selector: 'app-user-singup',
  templateUrl: './user-singup.component.html',
  styleUrls: ['./user-singup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserSingupComponent implements OnInit, OnChanges {
  currentGuid: string = ''
  showWarningMessagePrompt: boolean = false;
  varificationErrorMessage: string = ''
  inValidVarificationRequest: boolean = false
  isNewAccountCreated: boolean = false
  isBasicInfoReceived: boolean = false
  isAuthenticateUser: boolean = false
  userOnbording: UserOnboardingMinimum
  externalUserModel: InviteExternalUser = DEFAULT_EXTERNAL_USER
  imageLogo: any = {
    path: '../../../assets/images/logos/auxcon-sign-in-brand.png',
    alt: 'Auxcon Logo'
  };
  constructor(private router: Router, private userProfileService: UserProfileService, private loadingService: LoadingService,) { }
  ngOnInit(): void {
    this.loadingService.showOverlay();
    let currentRoute = this.router.url.split('/')
    this.currentGuid = currentRoute[currentRoute.length - 1]
    if (this.currentGuid.includes('Authenticate')) {
      let authCode = this.currentGuid.split('=')[1]
      let existingUserSignIn = JSON.parse(localStorage.getItem('ExternalUserInviteModel'))
      //this.getOffice365UserPendingInfo(authCode, existingUserSignIn.Id)
      this.addNewUser(existingUserSignIn);
    } else {
      this.checkCurrentRequest(this.currentGuid);
    }
  }
  get getCustomeSuccessfulMessage(): string {
    if (!this.isOffice365User) {
      return 'Your registration has been successful an email will be sent to you containing your login credentials.'
    } else {
      return 'Your registration has been successful. You will be auto-redirected to the Auxcon dashboard shortly.';
    }
  } // end isExternalAttorney()
  get isOffice365User(): Boolean {
    if (this.externalUserModel.AccountType == 'Office') {
      return true
    }
    else {
      return false;
    }
  }

  ngOnChanges() {

  }
  getOffice365UserPendingInfo(authCode: any, identity: any) {
    this.loadingService.showOverlay();
    this.userProfileService.getOffice365UserPendingInfo(authCode, identity).subscribe({
      next: (response) => {
        if (typeof response != 'object') {
          this.inValidVarificationRequest = true
          this.varificationErrorMessage = response
        }
        else {
          this.isAuthenticateUser = true
          this.externalUserModel = Object.assign({}, response);
        }
      },
      error: (error) => {
        this.isBasicInfoReceived = true;
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.isBasicInfoReceived = true;
        this.loadingService.hideOverlay();
      }
    });
  }
  checkCurrentRequest(currentGUID: any) {
    this.loadingService.showOverlay();
    this.userProfileService.validateSingupUser(currentGUID).subscribe({
      next: (response) => {
        if (typeof response != 'object') {
          this.inValidVarificationRequest = true
          this.varificationErrorMessage = response
          this.loadingService.hideOverlay();
          return
        }
        else {
          this.inValidVarificationRequest = false
          localStorage.setItem('ExternalUserInviteModel', JSON.stringify(response))
          this.externalUserModel = Object.assign({}, response);
        }
      },
      error: (error) => {
        this.inValidVarificationRequest = true
        this.varificationErrorMessage = error
        this.isBasicInfoReceived = true;
        this.loadingService.hideOverlay();
      },
      complete: () => {
        this.loadingService.hideOverlay();
        this.isBasicInfoReceived = true;
      }
    });
  }
  onMicrosoftSign() {
    localStorage.setItem('ExternalUserInviteModel', JSON.stringify(this.externalUserModel))
    if (!this.isAuthenticateUser && this.isOffice365User && this.externalUserModel.TenantID) {
      window.location.href = 'https://login.microsoftonline.com/' + this.externalUserModel.TenantID + '/oauth2/v2.0/authorize?client_id=' + environment.clientId + '&redirect_uri=' + environment.redirectUrl + 'user-signup/Authenticate&response_mode=query&scope=openid&response_type=code&acr_values=urn%3amicrosoft%3apolicies%3amfa&prompt=select_account';
    } else {
      this.addNewUser(this.externalUserModel)
    }
  }
  onLogIn() {
    this.router.navigate([PATH_SIGN_IN]);
  }
  onBlurLastName($event: any) {
    this.externalUserModel.UserLastName = $event.target.value
  }
  onBlurFirstName($event: any) {
    this.externalUserModel.UserFirstName = $event.target.value
  }
  redirectPage() {
    if (!this.inValidVarificationRequest && this.isNewAccountCreated && this.isOffice365User) {
      setTimeout(() => {
        this.router.navigate([PATH_SIGN_IN]);
      }, 3000)
    }
  }
  
  onWarningMessagePrompt() {
    this.showWarningMessagePrompt = false
  }
  addNewUser(newUser: any) {
    if (!newUser.UserFirstName || !newUser.UserLastName) {
      this.loadingService.hideOverlay();
      return;
    }
    this.loadingService.showOverlay();
    let contactdetails = {
      EmailAddress: newUser.EmailAddress,
      MobileNumber: null
    };
    let persnolDetails = {
      JobTitle: newUser.EntityType,
      FirstName: newUser.UserFirstName,
      LastName: newUser.UserLastName,
      ServiceProviderID: 0,
      AuxconUserName: newUser.UserFirstName + newUser.UserLastName,
    };
    let optionalProcessing = {
      AddTemplates: false,
    };
    this.userOnbording = {
      isAlreadyOfficeUser: newUser.AccountType == 'Office' ? true : false,
      PersonalDetailsOnboard: persnolDetails,
      ContactDetailsMinimum: contactdetails,
      OptionalProcessing: optionalProcessing,
    };
    this.userProfileService.createNewUser(this.userOnbording, newUser.Id).subscribe({
      next: (response) => {
        this.loadingService.hideOverlay();
        if (typeof response == 'string') {
          this.varificationErrorMessage = response;
          this.showWarningMessagePrompt = true
          return
        } else {
          if (response['Status'] == "Failed") {
            this.isNewAccountCreated = false;
            this.inValidVarificationRequest = true;
            this.varificationErrorMessage = response['StatusError']
          }
          else {
            this.isNewAccountCreated = true;
            this.externalUserModel = Object.assign({}, newUser);
          }
        }
      },
      error: (error) => {
        this.isNewAccountCreated = false;
        this.loadingService.hideOverlay();
      },
      complete: () => {
        //this.isNewAccountCreated = true;
        this.isBasicInfoReceived = true;
        //this.externalUserModel = Object.assign({}, newUser);
        this.redirectPage();
      }
    });
  }
  onKeyUpFirstName(event){ 
    var k;  
    k = event.keyCode;  
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 ||  (k >= 48 && k <= 57));     
  }
  onKeyUpLastName(event){ 
    var k;  
    k = event.keyCode;  
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 ||  (k >= 48 && k <= 57));     
  }  
}
