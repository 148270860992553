import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { InvoiceDetails, InvoiceDetailsService } from '../../../services/invoice-details/invoice-details.service';
import { ApiService } from '../../../services/api.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
import { InvoiceDocumentService } from '../../../services/invoice-document/invoice-document.service';

@Component({
  selector: 'app-quotation-preview-modal',
  templateUrl: './quotation-preview-modal.component.html',
  styleUrls: ['./quotation-preview-modal.component.scss']
})
export class QuotationPreviewModalComponent implements OnInit, AfterViewInit {

  quotation: InvoiceDetails;
  reportUrl: string;
  activeTab: string;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  _fileName;
  @ViewChild('pdfViewer') public pdfViewer;
  @Input() quotationDetails: InvoiceDetails;
  @Input() quotationId: number;
  @Input() plaintiff: string;
  @Input() defendant: string;
  @Input() isVisible: boolean;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  // Is Practice Manager
  get isPracticeManager(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER);
  }  // end isPracticeManager()

  get isAdvocateProfile(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ADVOCATE);
  } // isAdvocateProfile()

  constructor(
    private api: ApiService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private invoiceDetailService: InvoiceDetailsService,
    private invoiceDocumentService: InvoiceDocumentService
  ) { } // constructor()

  ngAfterViewInit() {
    this.loadingService.showOverlay();
    this.reportUrl = this.api.endpoints.invoiceDocument +
    '/Quotation?quotationId=' + this.quotationId;

    this.invoiceDocumentService.getQuotationDocument(this.reportUrl).subscribe({
      next: (quotationDoc) => {
      this.pdfViewer.name = 'Quotation.pdf';
      this.pdfViewer.pdfSrc = quotationDoc;
      this.pdfViewer.refresh();
    },
    error: (error) => {
      // on error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.loadingService.hideOverlay();
    }
  });
  } // end ngAfterViewInit()

  ngOnInit() {
  } // end ngOnInit()

  dismiss() {
    this.closeModal.emit('quotation');
  } // end dismiss()

} // end QuotationPreviewModalComponent{}
