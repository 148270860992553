<div class="container-fluid" style="margin-top:0" *ngIf="isDataLoaded===true">
    <div class="individual-customer-container">
        <div class="individual-customer-well">
            <nav class="individual-customer-nav">
                <a class="tab" [class.active]="activeTab === 'overdue'" (click)="onTabClick('overdue')">Overdue
                    Invoices</a>
                <a class="tab" [class.active]="activeTab === 'current'" (click)="onTabClick('current')">0 – 90 day
                    Invoices</a>
                <a class="tab" [class.active]="activeTab === 'all'" (click)="onTabClick('all')">All outstanding</a>
                <a class="tab" [class.active]="activeTab === 'paid'" (click)="onTabClick('paid')">Paid Invoices</a>
                <a class="tab" style="min-width: 286px !important;" [class.active]="activeTab === 'unallocated'"
                    (click)="onTabClick('unallocated')">Unallocated Transactions
                    <i *ngIf="count != 0" class="fas fa-circle"><b>{{count}}</b></i>
                </a>
            </nav>
        </div>
    </div>
    <div class="row column-display-row" *ngIf="activeTab !== 'unallocated' && activeTab !== 'paid'">
        <div class="col-sm-6 offset-sm-6 text-left p-0">
            <div class="column-display-area">
                <div class="display-options">
                    <span class="column-search">
                        <button class="btn btn-text">
                            <i class="fa fa-search"></i>
                        </button>
                        <input type="search" placeholder="Search invoices" (keyup)="performSearch()"
                            [(ngModel)]="searchTerm" (keydown)="onKeyDown($event)">
                    </span>
                    <span class="column-order-by">
                        <label for="sort-by">Sort by:</label>
                        <select id="sort-by" [(ngModel)]="sortData" (change)=" orderData()">
                            <option value="" disabled selected hidden> Filter options </option>
                            <option value="invoiceDate"> Date </option>
                            <option value="matter"> Matter </option>
                            <option *ngIf="!isAssistantToLawfirm && !isAttorney && !isLawfirm" value="attorney"> Attorney </option>
                            <option value="invoiceNumber"> Invoice Number </option>
                            <option value="daysOverdue"> Overdue </option>
                            <option value="due"> Due </option>
                            <option value="paid"> Paid </option>
                            <option value="invoiceAmount"> Invoice Total </option>
                        </select>
                        <button class="btn btn-toggle" (click)="toggleAscending()">
                            <i class="fa" [class.fa-sort-amount-desc]="!sortAscending"
                                [class.fa-sort-amount-asc]="sortAscending"></i>
                        </button>
                        <!--#end .btn.btn-toggle -->
                    </span>

                </div>

            </div>
        </div>
    </div>
    <div class="row column-display-row" *ngIf="activeTab === 'paid'">
        <div class="col-sm-6 offset-sm-3 text-left p-0">
            <div class="column-display-area">
                <div class="display-options-paid">
                    <div class="column-report-date">
                        <i class="fa fa-refresh" (click)="onDateFieldRefresh()" title="Reset"></i>
                    </div>
                    <div class="column-report-date col-fs-0">
                        <label for="date" style="padding-right: 10px;" class="fromTo-date">From:</label>
                        <input type="text" class="input-date" #_fromDate="bsDatepicker" bsDatepicker
                            placeholder="2010-01-01" placement="bottom" [(ngModel)]="invoiceStartDate"
                            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                            (bsValueChange)="onInvoiceStartDateChange($event)">
                        <i class="fa fa-calendar" (click)="_fromDate.toggle()"
                            [attr.aria-expanded]="_toDate.isOpen"></i>
                    </div>
                    <div class="column-report-date col-fs-0">
                        <label for="date" style="padding-right: 10px;" class="fromTo-date">To:</label>
                        <input type="text" class="input-date" #_toDate="bsDatepicker" bsDatepicker
                            placeholder="2010-01-01" placement="bottom" [(ngModel)]="invoiceEndDate"
                            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
                            (bsValueChange)="onInvoiceEndDateChange($event)">
                        <i class="fa fa-calendar" (click)="_toDate.toggle()" [attr.aria-expanded]="_toDate.isOpen"></i>
                    </div>
                    <!-- <p class="fas fa-arrow-alt-circle-right"
                        (click)="onInvoiceEndDateChange($event)"></p> -->
                    <span class="column-search">
                        <button class="btn btn-text">
                            <i class="fa fa-search"></i>
                        </button>
                        <input type="search" placeholder="Search invoices" (keyup)="performSearch()"
                            [(ngModel)]="searchTerm" (keydown)="onKeyDown($event)">
                    </span>
                    <span class="column-order-by">
                        <label for="sort-by">Sort by:</label>
                        <select id="sort-by" [(ngModel)]="sortData" (change)=" orderData()">
                            <option value="" disabled selected hidden> Filter options </option>
                            <option value="invoiceDate"> Date </option>
                            <option value="matter"> Matter </option>
                            <option *ngIf="!isAssistantToLawfirm && !isAttorney && !isLawfirm" value="attorney"> Attorney </option>
                            <option value="invoiceNumber"> Invoice Number </option>
                            <option value="daysOverdue"> Overdue </option>
                            <option value="due"> Due </option>
                            <option value="paid"> Paid </option>
                            <option value="invoiceAmount"> Invoice Total </option>
                        </select>
                        <button class="btn btn-toggle" (click)="toggleAscending()">
                            <i class="fa" [class.fa-sort-amount-desc]="!sortAscending"
                                [class.fa-sort-amount-asc]="sortAscending"></i>
                        </button>
                        <!--#end .btn.btn-toggle -->
                    </span>

                </div>

            </div>
        </div>
    </div>
    <div class="row column-display-row" *ngIf="activeTab === 'unallocated' && activeTab !== 'paid'">
        <div class="col-sm-6 offset-sm-6 text-left p-0">
            <div class="column-display-area">
                <div class="display-options">
                    <span class="column-search">
                        <button class="btn btn-text">
                            <i class="fa fa-search"></i>
                        </button>
                        <input type="search" placeholder="Search" (keyup)="performUnallocatedSearch()"
                            [(ngModel)]="searchTerm" (keydown)="onKeyDown($event)">
                    </span>
                    <span class="column-order-by">
                        <label for="sort-by">Sort by:</label>
                        <select id="sort-by" [(ngModel)]="sortData" (change)=" orderData()">
                            <option value="" disabled selected hidden> Filter options </option>
                            <option value="sortUnallocatedDate"> Date </option>
                            <option value="sortUnallocatedDescription"> Description </option>
                            <option value="sortUnallocatedTransactionAmount"> Transaction Amount </option>
                            <option value="sortUnallocatedAmount"> Unallocated Amount </option>
                        </select>
                        <button class="btn btn-toggle" (click)="toggleAscendingUnallocated()">
                            <i class="fa" [class.fa-sort-amount-desc]="!sortAscending"
                                [class.fa-sort-amount-asc]="sortAscending"></i>
                        </button>
                        <!--#end .btn.btn-toggle -->
                    </span>

                </div>

            </div>
        </div>
    </div>
    <div class="table-responsive" *ngIf="activeTab !== 'unallocated'">
        <table class="table table-striped " style="border: 0;">
            <tbody class="tbody">
                <tr class="table-light">
                    <!-- <th class="w-5 align-bottom" (click)="toggleSort('date')">Date <i class="fa fa-sort" [class.fa-sort-down]="sortDate"></i></th>
                    <th class="w-25 align-bottom" (click)="toggleSort('matter')">Matter <i class="fa fa-sort" [class.fa-sort-down]="sortMatter"></i></th>
                    <th *ngIf="!isAssistantToLawfirm && !isAttorney && !isLawfirm" class="w-5 align-bottom text-left" (click)="toggleSort('attorney')">Attorney <i class="fa fa-sort" [class.fa-sort-down]="sortAttorney"></i></th>
                    <th *ngIf="isAssistantToLawfirm || isAttorney || isLawfirm" class="w-5 align-bottom text-left" (click)="toggleSort('attorney')">Client <i class="fa fa-sort" [class.fa-sort-down]="sortAttorney"></i></th>
                    <th class="w-5 align-bottom text-left" (click)="toggleSort('attorney-ref')">Reference <i class="fa fa-sort" [class.fa-sort-down]="sortAttorneyRef"></i></th>
                    <th class="w-5 align-bottom text-left" (click)="toggleSort('invoice-number')">Inv No. <i class="fa fa-sort" [class.fa-sort-down]="sortInvoiceNo"></i></th>
                    <th class="w-5 align-bottom text-left" (click)="toggleSort('days-overdue')">Aging <i class="fa fa-sort" [class.fa-sort-down]="sortDaysOverDue"></i></th>
                    <th class="w-5 align-bottom text-left" (click)="toggleSort('days-outstanding')">Days Outstanding <i class="fa fa-sort" [class.fa-sort-down]="sortDaysOutstanding"></i></th>
                    <th class="w-10 align-bottom text-left" (click)="toggleSort('due')">Due <i class="fa fa-sort" [class.fa-sort-down]="sortDue"></i></th>
                    <th class="w-10 align-bottom text-left" (click)="toggleSort('paid')">Paid <i class="fa fa-sort" [class.fa-sort-down]="sortPaid"></i></th>
                    <th class="w-10 align-bottom text-left" (click)="toggleSort('amount')">Invoice Total <i class="fa fa-sort" [class.fa-sort-down]="sortAmount"></i></th> -->
                    <th class="w-10 align-bottom" (click)="toggleSort('date')">Date <i class="fa fa-sort"
                            [class.fa-sort-down]="sortDate"></i></th>
                    <th class="w-20 align-bottom" (click)="toggleSort('matter')">Matter <i class="fa fa-sort"
                            [class.fa-sort-down]="sortMatter"></i></th>
                    <th class="w-10 align-bottom text-left" (click)="toggleSort('attorney')">Attorney <i
                            class="fa fa-sort" [class.fa-sort-down]="sortAttorney"></i></th>
                    <th class="w-7 align-bottom text-left" (click)="toggleSort('attorney-ref')">Attorney Ref <i
                            class="fa fa-sort" [class.fa-sort-down]="sortAttorneyRef"></i></th>
                    <th class="w-5 align-bottom text-left" (click)="toggleSort('invoice-number')">Inv No. <i
                            class="fa fa-sort" [class.fa-sort-down]="sortInvoiceNo"></i></th>
                    <th class="w-5 align-bottom text-left" (click)="toggleSort('days-overdue')">Aging <i
                            class="fa fa-sort" [class.fa-sort-down]="sortDaysOverDue"></i></th>
                    <th class="w-5 align-bottom text-left" (click)="toggleSort('days-outstanding')">Days Outstanding <i
                            class="fa fa-sort" [class.fa-sort-down]="sortDaysOutstanding"></i></th>
                    <th class="w-10 align-bottom text-left" (click)="toggleSort('due')">Due <i class="fa fa-sort"
                            [class.fa-sort-down]="sortDue"></i></th>
                    <th class="w-10 align-bottom text-left" (click)="toggleSort('paid')">Paid <i class="fa fa-sort"
                            [class.fa-sort-down]="sortPaid"></i></th>
                    <th class="w-10 align-bottom text-left" (click)="toggleSort('amount')">Invoice Total <i
                            class="fa fa-sort" [class.fa-sort-down]="sortAmount"></i></th>
                    <th class="text-right w-2" *ngIf="isPracticeManager"></th>
                    <th class="text-right w-2" *ngIf="isPracticeManager"></th>
                    <th class="text-right w-2"></th>
                    <th class="text-right w-2">Ad-Hoc
                            <i class="fa fa-question-circle hover-text" style="color: #0066ff !important; font-size: 14px;" aria-hidden="true">
                                <span class="tooltip-text left" style="font-size: 9px;">You are able to select invoices where you require Auxcon to assist you with collections. An 8.5% collection fee will be billed on a successful collection. Should you require more information please contact our office.</span>
                            </i>
                            <input
                                #inputCheckboxSelectAll
                                style="margin-left: 5px"
                                type="checkbox"
                                (change)="toggleSelectAllAdHoc()">
                    </th>
                    <!-- <th class="w-10"></th> -->
                </tr>


                <tr></tr>
                <tr *ngFor="let i of tabDataHandle;" role="tabpanel" class="tab-pane tab-fade" [ngStyle]="{'background-color': getADHOCBackgroungColor(i)}">
                    <td *ngIf="i.state.isVisible" [ngStyle]="{'background-color':  getBackgroundColor(i.data)}" style="white-space: nowrap;">{{i.data.invoiceDate | date:'yyyy-MM-dd'}}</td>
                    <td *ngIf="i.state.isVisible" [ngStyle]="{'background-color':  getBackgroundColor(i.data)}">{{i.data.matter}}</td>
                    <td *ngIf="i.state.isVisible" [ngStyle]="{ 'color' : getCellColor(i.data), 'background-color':  getBackgroundColor(i.data)}">{{i.data.attorney}}</td>
                    <td *ngIf="i.state.isVisible" [ngStyle]="{'background-color':  getBackgroundColor(i.data)}">{{i.data.attorneyRef}}</td>
                    <td *ngIf="i.state.isVisible" class="text-left" [ngStyle]="{'background-color':  getBackgroundColor(i.data)}"><u class="column-invoice-number"><a
                                (click)="onViewInvoice(i.data)">{{i.data.invoiceNumber}}</a></u></td>
                    <td *ngIf="i.state.isVisible" class="text-left"
                        [ngStyle]="{'color': getDayFontColor(i.data.daysOverdue), 'font-weight': getDayFontweight(i.data.daysOverdue), 'background-color':  getBackgroundColor(i.data) }">
                        {{i.data.type}}</td> <!--{{i.data.daysOverdue===98 ? 'Overdue' : i.data.daysOverdue}}-->
                    <td *ngIf="i.state.isVisible && activeTab !== 'paid'" class="text-left"
                        [ngStyle]="{'color': getDayFontColor(i.data.totalDaysOutstanding), 'font-weight': getDayFontweight(i.data.totalDaysOutstanding), 'background-color':  getBackgroundColor(i.data) }">
                        {{i.data.totalDaysOutstanding}}</td>
                    <td *ngIf="i.state.isVisible && activeTab === 'paid'" class="text-left"
                        [ngStyle]="{'color': getDayFontColor(i.data.paymentDays), 'font-weight': getDayFontweight(i.data.paymentDays), 'background-color':  getBackgroundColor(i.data) }">
                        {{i.data.paymentDays}}</td>
                    <td *ngIf="i.state.isVisible" class="text-left" [ngStyle]="{'background-color':  getBackgroundColor(i.data)}">{{i.data.due | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                    <td *ngIf="i.state.isVisible" class="text-left" [ngStyle]="{'background-color':  getBackgroundColor(i.data)}" style="cursor: pointer;" (click)="openPaymentDetailModal(i.data)">
                        <u>{{i.data.paid | currency: currencySymbol: currencySymbol:'1.2-2'}}</u></td>
                    <td *ngIf="i.state.isVisible" class="text-left" [ngStyle]="{'background-color':  getBackgroundColor(i.data)}">{{i.data.invoiceAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                    <td *ngIf="i.state.isVisible && isPracticeManager" [ngStyle]="{'background-color':  getBackgroundColor(i.data)}" class="w-2">
                        <app-small-checkbox
                            [(checked)]="i.data.discounting"
                            (click)="toggleDiscountingCheckbox(i.data)"></app-small-checkbox>
                    </td>
                    <td *ngIf="i.state.isVisible && isPracticeManager" [ngStyle]="{'background-color':  getBackgroundColor(i.data)}" class="w-2">
                        <app-small-checkbox
                            [(checked)]="i.state.isSelected"
                            (click)="toggleCheckbox(i)"></app-small-checkbox>
                    </td>
                    <td *ngIf="i.state.isVisible" [ngStyle]="{'background-color':  getBackgroundColor(i.data)}" style="cursor: pointer;" class="w-2">
                        <i style="color: blue;" *ngIf="i.data.notesDetail && i.data.notesDetail.length > 0 && incomingMessage(i.data.notesDetail) === true && isPracticeManager" (click)="onNotesOpen(i.data.notesDetail, i.data.invoiceId)" title="unread message" class="fa fa-envelope"></i>
                        <i style="color: blue;" *ngIf="i.data.notesDetail && i.data.notesDetail.length > 0 && incomingMessage(i.data.notesDetail) === false && isPracticeManager" (click)="onNotesOpen(i.data.notesDetail, i.data.invoiceId)" class="fa fa-envelope-open"></i>
                        <i *ngIf="(!i.data.notesDetail || i.data.notesDetail.length === 0 ) && isPracticeManager" title="Add new note" (click)="onAddNewNote(i.data.invoiceId)" class="fa fa-plus-circle fa-lg add-new-note"></i>
                        <!-- <i style="color: blue;" *ngIf="!isPracticeManager && i.data.notesDetail && hasAdvMessage(i.data.notesDetail) && incomingAdvMessage(i.data.notesDetail) === true" (click)="onNotesOpen(i.data.notesDetail, i.data.invoiceId)" title="unread message" class="fa fa-envelope"></i> -->
                        <!-- <i style="color: blue;" *ngIf="!isPracticeManager && i.data.notesDetail && hasAdvMessage(i.data.notesDetail) && incomingAdvMessage(i.data.notesDetail) === false" (click)="onNotesOpen(i.data.notesDetail, i.data.invoiceId)" class="fa fa-envelope-open"></i> -->
                    </td>
                    <td *ngIf="i.state.isVisible" class="text-right w-2" [ngStyle]="{'background-color':  getBackgroundColor(i.data)}">
                        <app-small-checkbox
                            *ngIf="!i.data.adHocCollections"
                            [(checked)]="i.state.isAdHocSelected"
                            (click)="toggleAdHocCheckbox(i)"></app-small-checkbox>

                        <app-small-checkbox
                            *ngIf="i.data.adHocCollections"
                            [(checked)]="i.data.adHocCollections"
                            (click)="onAdHocInvoiceRemove(i)"></app-small-checkbox>
                        <!-- 
                        <i 
                            *ngIf="i.data.adHocCollections"
                            class="far fa-minus-circle clickable" 
                            (click)="onAdHocInvoiceRemove(i)"></i> -->
                    </td>
                    <!-- <td class="text-center"><u>Add Action</u></td> -->
                </tr>
                <tr class="table-light">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th><u>Total</u></th>
                    <th></th>
                    <th class="text-left due-text-color">
                        {{totalDue  | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                    <th class="text-left">{{totalPaid  | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                    <th class="text-left">{{totalInvoiceAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                    <th class="text-right" style="white-space: nowrap;" *ngIf="isPracticeManager" colspan="2">
                        <!-- <span class="clickable" *ngIf="showAddNoteButton" (click)="onWriteOff()">
                            <u class="auxcon-green">write-off</u>
                        </span> -->
                        <div class="btn-group" dropdown [dropup]="isDropup" *ngIf="showAddNoteButton">
                            <button
                              id="button-dropup"
                              dropdownToggle
                              [class.disabled]="!showAddNoteButton"
                              [disabled]="!showAddNoteButton"
                              type="button"
                              class="btn dropdown-toggle our-dropdown-button"
                              aria-controls="dropdown-dropup" style="font: 14px / 1 FontAwesome; font-weight: 500 !important;"><b>Multi-Action </b><span class="caret"></span>
                            </button>
                            <ul id="dropdown-dropup" style="text-align: left;" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-dropup">
                                <li role="menuitem"><a style="width: 130px; text-align: center; padding: 5px 0 !important;" (click)="onSendMultiInvoiceNote()" class="dropdown-item">Add Note on Multiple</a></li>
                              <li role="menuitem"><a style="width: 130px; text-align: center; padding: 5px 0 !important;" (click)="onDownloadMultipleInvoices()" class="dropdown-item">Download Multiple</a></li>
                              <li role="menuitem"><a style="width: 130px; text-align: center; padding: 5px 0 !important;" (click)="onWriteOff()" class="dropdown-item">Write-off Multiple  </a></li>
                            </ul>
                        </div>    
                    </th>
                    <th class="text-right" style="white-space: nowrap;" colspan="2">
                        <!-- <span class="clickable" *ngIf="showAddNoteButton" (click)="onSendMultiInvoiceNote()">
                            <i class="fa fa-plus-circle auxcon-green"></i>
                            <u class="auxcon-green">note</u>
                        </span> -->
                        <span class="clickable" *ngIf="showAddAdHocButton" (click)="onSendAdHocRequest()">
                            <i class="fa fa-plus-circle auxcon-green"></i>
                            <u class="auxcon-green">Request Ad-Hoc</u>
                        </span>
                    </th>
                    <th class="text-right" style="white-space: nowrap;">

                    </th>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="table-responsive" *ngIf="activeTab === 'unallocated'">
        <table class="table table-striped " style="border: 0;">
            <tbody class="tbody">
                <tr class="table-light">
                    <th class=" align-bottom" (click)="toggleUnAllocatedSort('date')">Date <i class="fa fa-sort"
                            [class.fa-sort-down]="sortUnallocatedDate"></i></th>
                    <th class=" align-bottom" (click)="toggleUnAllocatedSort('description')">Description <i
                            class="fa fa-sort" [class.fa-sort-down]="sortUnallocatedDescription"></i></th>
                    <th class=" align-bottom text-left" (click)="toggleUnAllocatedSort('transaction-amount')">
                        Transaction Amount <i class="fa fa-sort"
                            [class.fa-sort-down]="sortUnallocatedTransactionAmount"></i></th>
                    <th class=" align-bottom text-left" (click)="toggleUnAllocatedSort('amount')">Unallocated Amount <i
                            class="fa fa-sort" [class.fa-sort-down]="sortUnallocatedAmount"></i></th>
                    <!-- <th class="w-20 align-bottom">Latest Comment</th> -->
                    <!-- <th class="w-10"></th> -->
                </tr>


                <tr></tr>
                <tr *ngFor="let i of allocatedToLawFirmHandle;" role="tabpanel" class="tab-pane tab-fade">
                    <td *ngIf="i.state.isVisible">{{i.data.transactionDate | date:'yyyy-MM-dd'}}</td>
                    <td *ngIf="i.state.isVisible">{{i.data.description}}</td>
                    <td *ngIf="i.state.isVisible" class="text-left">
                        {{i.data.transactionAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                    <td *ngIf="i.state.isVisible" class="text-left" style="cursor: pointer;">
                        {{i.data.amount | currency: currencySymbol: currencySymbol:'1.2-2'}}</td>
                    <!-- <td></td> -->
                    <!-- <td class="text-center"><u>Add Action</u></td> -->
                </tr>
                <tr class="table-light">
                    <th></th>
                    <th><u>Total</u></th>
                    <!-- <th></th> -->
                    <!-- <th></th> -->
                    <!-- <th></th> -->
                    <th class="text-left due-text-color">
                        {{unAllocatedTTransaction  | currency: currencySymbol: currencySymbol:'1.2-2'}}</th>
                    <th class="text-left">{{unAllocatedTAmount  | currency: currencySymbol: currencySymbol:'1.2-2'}}
                    </th>
                    <!-- <th></th>
                    <th></th> -->
                </tr>
            </tbody>
        </table>
    </div>
</div>

<app-payment-detail-modal confirmText="OK" [isVisible]="showPaymentDetailModal" [data]="paymentDetails"
    [selectedInvoice]="selectedInvoice" (closeModal)="closePaymentDetailModal()" (confirm)="savePaymentDetail($event)">
</app-payment-detail-modal>

<app-invoice-preview-modal
    *ngIf="showProformaInvoice"
    #invoicePreview
    [notesHandle]="notesHandle"
    [plaintiff]="invoicePlaintiff"
    [selectedTab]="selectedTab"
    [defendant]="invoiceDefendant"
    [invoiceId]="selectedInvoiceId"
    [invoiceDetails]="invoiceDetails"
    [feesQuoted]="feesQuoted"
    [isVisible]="showProformaInvoice"
    (closeModal)="closeModal()">
</app-invoice-preview-modal>
<app-client-note-modal
  [isVisible]="showInvoiceNoteDetail"
  [notes]="notes"
  (closeModal)="closeInvoiceNoteModal()"></app-client-note-modal>
<app-discounting-modal
    [isVisible]="showDiscounting"
    [individualCustomerData]="discountingInvoice"
    (cancel)="onCancelDiscounting()"
    (saveDetails)="onSaveDiscounting($event)"></app-discounting-modal>
<app-note-detail-modal
    *ngIf="onShowNoteDetail"
    [instructionResponses]="instructionResponses"
    [isVisible]="onShowNoteDetail"
    [plaintiff]="invoicePlaintiff"
    [defendant]="invoiceDefendant"
    [invoiceDetails]="invoiceDetails"
    [objectType]="objectType"
    [noteDetailData]="noteDetailData"
    [lawfirmSummaryData]="selectedLawfirmSummaryData"
    [noteCategory]="noteCategory"
    (closemodal)="onShowNoteDetailModalDismiss()"
    (saveNote)="onNoteSave($event)"
    (issueInstruction)="onInstructionIssued($event)"
    (updateNote)="OnAddResolution($event)"></app-note-detail-modal>

<app-multiple-invoice-note
    *ngIf="onShowMultiInvoiceNote"
    [isVisible]="onShowMultiInvoiceNote"
    [(isAdHocCollections)]="isAdHocCollections"
    [instructionResponses]="instructionResponses"
    [lawfirmName]="lawfirmName"
    [objectType]="objectType"
    [noteDetailData]="noteDetailData"
    [noteCategory]="noteCategory"
    [multiInvoiceNote]="multiInvoiceNote"
    (closemodal)="onShowMultiInvoiceNoteModalDismiss()"
    (saveMultiNote)="onSaveMultiNote($event)"
    (saveInstructionMultiNote)="onSaveInstructionOnMultiInvoice($event)"></app-multiple-invoice-note>

<app-prompt-unsaved-changes-modal
    title="DISABLE DISCOUNTING"
    message="You are about to disable discounting on this invoice. Do you wish to proceed?"
    [isVisible]="showDisableDiscountingPrompt"
    (cancel)="onHideDisableDiscountingPrompt()"
    btnTextCancel="Cancel"
    btnTextContinue="Proceed"
    (continue)="onDisableDiscounting()">
</app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal
    title="REQUEST FOR AD-HOC COLLECTION"
    [message]="onRequestAdHocMessage"
    [isVisible]="showRequestAdHocPrompt"
    (cancel)="onHideRequestAdHocPrompt()"
    btnTextCancel="Cancel"
    btnTextContinue="Proceed"
    (continue)="onRequestAdHoc()">
</app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal
    title="Request to remove Ad-Hoc collection"
    [message]="onRequestRemovalAdHocMessage"
    [isVisible]="showRequestRemovalAdHocPrompt"
    (cancel)="onHideRequestRemovalAdHocPrompt()"
    btnTextCancel="Cancel"
    btnTextContinue="Proceed"
    (continue)="onRequestRemovalAdHoc()">
</app-prompt-unsaved-changes-modal>

<app-write-off-multiple-invoices
    *ngIf="onShowWriteOffModal"
    [isVisible]="onShowWriteOffModal"
    [toWriteOffTransactionLine]="toWriteOffTransactionLine"
    (closeModal)="onCloseWriteoff($event)"
    (saveWriteOff)="onSaveWriteOff($event)"></app-write-off-multiple-invoices>
