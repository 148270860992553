<div class="container-fluid" (click)="onPageClick($event)">
  <div class="well well-fluid">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <th *ngIf="!isAttorney">Law Firm</th>
          <th *ngIf="isAttorney">Clients</th>
          <th>Matter Description</th>
          <th>Invoice Amount</th>
          <th>Invoice Date</th>
          <th>Recipient</th>
          <th>Note</th>
          <th></th>
          <th></th>
        </thead>
        <tbody>
          <tr *ngFor="let invoice of messageTemplateHandle">
            <td>{{invoice.data.ClientName}}</td>
            <td>{{invoice.data.InvoiceParties}}</td>
            <td>{{ invoice.data.InvoiceTotal | currency: 'R ' }}</td>
            <td>
              <span *ngIf="invoice.state.isLoad">
                Loading...
              </span>
              <span
                *ngIf="(!invoice.state.isLoad && invoice.state.isGenerated) || (!invoice.state.isLoad && !invoice.state.isGenerated)">
                {{ invoice.data.InvoiceDate | date:'yyyy-MM-dd' }}
              </span>
            </td>
            <td>
              <span *ngIf="invoice.state.isLoad">
                Loading...
              </span>
              <span
                *ngIf="(!invoice.state.isLoad && invoice.state.isGenerated) || (!invoice.state.isLoad && !invoice.state.isGenerated)">
                <input type="email" class="form-control" [ngModelOptions]="{updateOn: 'blur'}"
                  [class.has-error]="hasValidEmails(invoice)" (input)="onReceipientInput($event, invoice.data)"
                  [(ngModel)]="invoice.data.Recipients" [title]="invoice.data.Recipients"
                  (click)="$event.target.select()" [class.disabled]="isHistoricalData" [disabled]="isHistoricalData">
                <span *ngIf="hasValidEmails(invoice)" class="text-danger">Please enter a valid Email Address.</span>
              </span>
            </td>
            <td>
              <span *ngIf="invoice.state.isLoad">
                Loading...
              </span>
              <span
                *ngIf="(!invoice.state.isLoad && invoice.state.isGenerated) || (!invoice.state.isLoad && !invoice.state.isGenerated)">
                <input type="text" class="form-control" placeholder="Enter your note to attorney here"
                  [ngModel]="invoice.data.InvoiceNote" (ngModelChange)="invoice.data.InvoiceNote=$event"
                  [title]="invoice.data.InvoiceNote" [class.disabled]="isHistoricalData" [disabled]="isHistoricalData"
                  (focus)="$event.target.select()">
              </span>
            </td>
            <td style="position: relative; width: 150px;">
              <span class="btn-group">
                <span class="clickable" style="min-width: 100px !important;"
                  (click)="previewMessage(invoice.data)">Preview Invoice</span>
                <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupMenu(invoice)"></i>
              </span>
              <app-single-invoice-popup-menu #popupMenu [isVisible]="invoice.state.isOpen"
                [invoiceId]="invoice.data.InvoiceID" (dismiss)="onDismissMenu($event)"
                [messageTemplateHandle]="invoice.data"
                (removeInvoice)="removeInvoice($event)"></app-single-invoice-popup-menu>
            </td>
            <td style="text-align: center;">
              <span *ngIf="invoice.state.isLoad" style="padding-left: 0px; padding-right: 16px;">
                <img src="../../../../assets/images/logos/spinner.gif" width="20" />
              </span>
              <span class="checkIcon" *ngIf="!invoice.state.isLoad && invoice.state.isGenerated">
                <i class="fa fa-check" style="padding-right: 1px;"></i>
              </span>
              <span class="wraningMessage" *ngIf="!invoice.state.isLoad && !invoice.state.isGenerated">
                {{ invoice.state.ErrorMessage }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div class="table multiple-invoice">
      <div class="table-head">
        <div class="table-row">
          <span class="column-invoice-date">
            Invoice Date
          </span>
          <span class="column-invoice-amount">
            Invoice Amount
          </span>
          <span class="column-lawfirm" *ngIf="!isAttorney">
            Law Firm
          </span>
          <span class="column-lawfirm" *ngIf="isAttorney">
            Clients
          </span>
          <span class="column-matter-description">
            Matter Description
          </span>
          <span class="column-Recipient">
            Recipient
          </span>
          <span class="column-note">
            Note
          </span>
        </div>
      </div>
      <div class="table-body" *ngIf="messageTemplateHandle">
        <div *ngFor="let invoice of messageTemplateHandle" class="table-row">

          <input readonly class="form-control" class="column-invoice-date" style="border: 0; margin-left: 10px; padding-top: 5;
          padding-bottom: 5;" *ngIf="invoice.data.InvoiceNo === 'AUX000000' || invoice.data.InvoiceNo === null"
            [value]="invoice.data.InvoiceDate | date:'yyyy-MM-dd'" />

          <span class="column-invoice-date" style="padding-top: 5; padding-bottom: 5;"
            *ngIf="invoice.data.InvoiceNo !== 'AUX000000' && invoice.data.InvoiceNo !== null">
            {{invoice.data.InvoiceDate | date:'yyyy-MM-dd'}}
          </span>

          <span class="column-invoice-amount" style="padding-top: 5; padding-bottom: 5;">{{invoice.data.InvoiceTotal |
            currency: 'R '}}</span>

          <span class="column-lawfirm" style="padding-top: 5; padding-bottom: 5;"
            [title]="invoice.data.ClientName">{{invoice.data.ClientName}}</span>

          <span class="column-invoice-description" style="padding-top: 5; padding-bottom: 5;"
            [title]="invoice.data.InvoiceParties">{{invoice.data.InvoiceParties}}</span>

          <span class="column-Recipient" style="padding-top: 5; padding-bottom: 5;">
            <input type="email" class="form-control" [ngModelOptions]="{updateOn: 'blur'}"
              [class.has-error]="hasValidEmails(invoice)" (input)="onReceipientInput($event, invoice.data)"
              [(ngModel)]="invoice.data.Recipients" [title]="invoice.data.Recipients" (click)="$event.target.select()"
              [class.disabled]="isHistoricalData" [disabled]="isHistoricalData">
            <span *ngIf="hasValidEmails(invoice)" class="text-danger">Please enter a valid Email Address.</span>
          </span>

          <span class="column-note" style="padding-top: 5; padding-bottom: 5;">
            <input type="text" placeholder="Enter your note to attorney here" [ngModel]="invoice.data.InvoiceNote"
              (ngModelChange)="invoice.data.InvoiceNote=$event" [title]="invoice.data.InvoiceNote"
              [class.disabled]="isHistoricalData" [disabled]="isHistoricalData" (focus)="$event.target.select()">
          </span>

          <span class="column-action" style="padding-top: 5; padding-bottom: 5;">
            <span class="btn-group">
              <span class="clickable" style="min-width: 0px !important;" (click)="previewMessage(invoice.data)">Preview
                Invoice</span>
              <i class="fa fa-angle-down fa-lg clickable" (click)="togglePopupMenu(invoice)"></i>
            </span>
            <app-single-invoice-popup-menu #popupMenu [isVisible]="invoice.state.isOpen"
              [invoiceId]="invoice.data.InvoiceID" (dismiss)="onDismissMenu($event)"
              [messageTemplateHandle]="invoice.data"
              (removeInvoice)="removeInvoice($event)"></app-single-invoice-popup-menu>
          </span>
          <span class="column-action" style="padding-top: 5; padding-bottom: 5;">
            <span *ngIf="invoice.state.isLoad" style="padding-left: 0px; padding-right: 16px;">
              <img src="../../../../assets/images/logos/spinner.gif" width="20" />
            </span>
            <span class="checkIcon" *ngIf="!invoice.state.isLoad && invoice.state.isGenerated">
              <i class="fa fa-check" style="padding-right: 1px;"></i>
            </span>
            <span class="wraningMessage" *ngIf="!invoice.state.isLoad && !invoice.state.isGenerated">
              {{ invoice.state.ErrorMessage }}
            </span>
          </span>
        </div>
      </div>
    </div> -->
  </div>
</div>
<app-invoice-review-modal #invoicereviewmodal [invoice]="selectedMessage" [isVisible]="showInvoiceReviewModal"
  (closeModal)="closeModal('invoice-review-modal')">
</app-invoice-review-modal>