import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multiple-matters-popup-menu',
  templateUrl: './multiple-matters-popup-menu.component.html',
  styleUrls: ['./multiple-matters-popup-menu.component.scss']
})
export class MultipleMattersPopupMenuComponent implements OnInit {

  @Input() isVisible: boolean;
  @Output() generateMultipleInvoices: EventEmitter<any> = new EventEmitter();
  @Output() deleteMultipleMatters: EventEmitter<any> = new EventEmitter();
  @Output() dismiss: EventEmitter<any> = new EventEmitter();

  constructor() { } // end constructor()

  ngOnInit() {
    this.isVisible = false;
  } // end ngOnInit()

  onDismiss() {
    // this.isVisible = false;
    this.dismiss.emit();
  } // end onDismiss()

  onGenerateMultipleInvoices() {
    this.generateMultipleInvoices.emit();
    this.onDismiss();
  } // end onGenerateMultipleInvoices()

  onDeleteMultipleMatters() {
    this.deleteMultipleMatters.emit();
    this.onDismiss();
  } // end onDeleteMultipleMatters()

} // end MultipleMattersPopupMenuComponent{}
