<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div>

      <div class="modal-body form">
        <div class="row">
          <div class="form-group">
            <label for="payment-terms">Payment Terms</label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter payment terms"
              (input)="$event.target.value"
              [(ngModel)]="paymentTerms">
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          class="btn btn-danger"
          (click)="onCancel()">Cancel</button>

        <button
          class="btn btn-success"
          (click)="onSave()">Save</button>
      </div>

    </div>
  </div>
</div>
