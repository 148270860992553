import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Matter } from '../../../services/matters/matters.service';
import { MatterHandle } from '../../inputs/datalist-matter/datalist-matter.component';

@Component({
  selector: 'app-single-matter-popup-menu',
  templateUrl: './single-matter-popup-menu.component.html',
  styleUrls: ['./single-matter-popup-menu.component.scss']
})
export class SingleMatterPopupMenuComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() type: string;
  @Input() serviceID: number;
  @Input() matter: MatterHandle;
  // @Input() matter: MatterHandle;
  @Output() deleteMatter: EventEmitter<any> = new EventEmitter();
  @Output() previewInvoice: EventEmitter<any> = new EventEmitter();
  @Output() previewMessage: EventEmitter<any> = new EventEmitter();
  @Output() openMatter: EventEmitter<any> = new EventEmitter();
  @Output() viewQuotation: EventEmitter<any> = new EventEmitter();
  @Output() dismiss: EventEmitter<MatterHandle> = new EventEmitter();

  options: any = {
    deleteMatter: true,
    previewInvoice: true,
    previewMessage: true,
    openMatter: true,
    viewQuotation: true
  };

  constructor() {} // constructor()

  ngOnInit() {
    this.options.deleteMatter = true;
    this.options.previewInvoice = (this.type !== 'New');
    this.options.previewMessage = (this.type === 'Revise');
    this.options.openMatter = (this.type === 'Outbox');
    this.options.viewQuotation = (this.type === 'Capture Fees');
    // this.isVisible = false;
  } // ngOnInit()

  onDismiss() {
    // this.isVisible = false;
    this.dismiss.emit(this.matter);
  } // end dismiss()

  onDeleteMatter() {
    this.deleteMatter.emit(this.matter.data);
    this.onDismiss();
  } // end deleteMatter()

  get isInOutbox(): boolean {
    return Boolean(this.matter.data.Status === 'Outbox');
  } // end isInOutbox()

  onPreviewInvoice() {
    this.previewInvoice.emit(this.matter.data);
    this.onDismiss();
  } // end previewInvoice()

  onPreviewMessage() {
    this.previewMessage.emit(this.matter.data);
    this.onDismiss();
  } // end previewMessage()

  onOpenMatter() {
    this.openMatter.emit(this.matter.data);
    this.onDismiss();
  } // end openMatter()

  onViewQuotation() {
    this.viewQuotation.emit(this.matter.data);
    this.onDismiss();
  } // end viewQuotation()

} // end SingleMatterPopupMenuComponent{}
