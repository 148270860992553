import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ApiService
} from '../api.service';
import { catchError, retry } from 'rxjs/operators';

export interface MatterQuotationDTO {
  MatterQuotationId: number;
  MatterQuotationDate: string;
  Description: string;
  ClientRefNo: string;
  Instructions: string;
  DailyRate: number;
  HourlyRate: number;
  PerKilometerRate: number;
  Status: string;
  ServiceProviderId: number;
  AttorneyId: number;
  AttorneyName: string;
  LawfirmId: number;
  PlaintiffContactId: number;
  PlaintiffFullName: string;
  DefendantContactId: number;
  DefendantFullName: string;
  OldMatterGuid: string;
  NewClientDetail: string;
  Submit: boolean;
  RejectReason: string;
  InternalRefNo: string;
  QuotationId: number;
  XmlData: string;
  LawFirm: string;
  Total: number;
  ActualTotal: number;
  Due: number;
  PaymentTerms: number;
  Documents: number;
  CaseNumber: string;
  DateOfAppearance: string;
  Discount: number;
  ActualDiscount: number;
  RAFClaim: string;
  QuotationStateStatus: string;
  ActualQuotationId: number;
  QuotationLinkId: number;
  AcceptRejectNotes: string;
} // end MatterQuotationDTO()

export const DEFAULT_MATTER_QUOTATION_DTO: MatterQuotationDTO = {
  MatterQuotationId: 0,
  MatterQuotationDate: '',
  Description: '',
  ClientRefNo: '',
  Instructions: '',
  DailyRate: 0,
  HourlyRate: 0,
  PerKilometerRate: 0,
  Status: '',
  ServiceProviderId: 0,
  AttorneyId: 0,
  AttorneyName: '',
  LawfirmId: 0,
  PlaintiffContactId: 0,
  PlaintiffFullName: '',
  DefendantContactId: 0,
  DefendantFullName: '',
  OldMatterGuid: '',
  NewClientDetail: '',
  Submit: false,
  RejectReason: '',
  InternalRefNo: '',
  QuotationId: 0,
  XmlData: '',
  LawFirm: '',
  Total: 0,
  ActualTotal: 0,
  Due: 0,
  PaymentTerms: 0,
  Documents: 0,
  CaseNumber: '',
  Discount: 0,
  ActualDiscount: 0,
  DateOfAppearance: '',
  RAFClaim: '',
  QuotationStateStatus: '',
  ActualQuotationId: 0,
  QuotationLinkId: 0,
  AcceptRejectNotes: ''
}; // end MatterQuotationDTO()

export interface QuotationFeeItem {
  FeeItemId: number;
  Subject: string;
  LineDate: string;
  RateType: string;
  MatterQuotationId: number;
  Rate: number;
  Total: number;
  Quantity: number;
  IsVatable: boolean;
  FurtherDescription: string;
  ServiceProviderId: number;
  CanEdit: boolean;
  MatterQuotationStatus: string;
  SubCategoryID: number;
}

export const DEFAULT_QUOTATION_FEE_ITEM: QuotationFeeItem = {
  FeeItemId: 0,
  Subject: '',
  LineDate: '',
  RateType: '',
  MatterQuotationId: 0,
  Rate: 0,
  Total: 0,
  Quantity: 0,
  IsVatable: false,
  FurtherDescription: '',
  ServiceProviderId: 0,
  CanEdit: false,
  MatterQuotationStatus: '',
  SubCategoryID: 0
};

export interface QuotationSummary {
  Id: number;
  QuotationDate: string;
  QuotationNo: string;
  Total: number;
  VAT: number;
  TotalExcl: number;
  Notes: string;
  YourRef: string;
  IsPaid: boolean;
  MatterQuotationId: number;
  QuotationStatus: string;
}

export const DEFAULT_QUOTATION_SUMMARY: QuotationSummary = {
  Id: 0,
  QuotationDate: '',
  QuotationNo: '',
  Total: 0,
  VAT: 0,
  TotalExcl: 0,
  Notes: '',
  YourRef: '',
  IsPaid: false,
  MatterQuotationId: 0,
  QuotationStatus: '',
};

export interface MatterQuotationHandle {
  data: MatterQuotationDTO;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

export interface QuotationNote {
  Id: number;
  Notes: string;
}

export const DEFAULT_QUOTATION_NOTE: QuotationNote = {
   Id: 0,
   Notes: ''
};

export interface SendQuotation {
  MessageId: number;
  Subject: string;
  MessageBody: string;
  Email: string;
  EmailType: string;
}

export const DEFAULT_SEND_QUOTATION: SendQuotation = {
  MessageId: 0,
  Subject: '',
  MessageBody: '',
  Email: '',
  EmailType: ''
};

export interface QuotationServiceLink {
  Link_Id: number;
  Services_Id: number;
  MatterQuotationId: number;
}
export const DEFAULT_QUOTATION_SERVICE_LINK: QuotationServiceLink = {
  Link_Id: 0,
  Services_Id: 0,
  MatterQuotationId: 0,
};

@Injectable()
export class QuotationService {
  // selectedMatterQuotation: MatterQuotationDTO;
  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { } // end constrcutor()

  /**
   * GET: all MatterQuotations list
   */
  getMatterQuotation(): Observable<any> {
    return this.http.get<any>(this.api.endpoints.quotation +
      '/?ServiceProvider=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getMatterQuotation()

  getSelectedMatterQuotation(matterId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.quotation +
      '/QuotationMatter?ServiceProvider=' + this.api.serviceProviderID +
      '&matterQuotationId=' + matterId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getSelectedMatterQuotation()

  getQuotation(matterId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.quotation +
      '/Quotation?MatterQuotationId=' + matterId, this.api.httpOptions)
    .pipe(
      catchError(this.api.handleError)
    );
  } // end getQuotation()

  getActualQuotation(actualQuotationId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.quotation +
      '/ActualQuotation?actualQuotationId=' + actualQuotationId, this.api.httpOptions)
    .pipe(
      catchError(this.api.handleError)
    );
  } // end getActualQuotation()

  getQuotationDiscount(matterQuotationId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.quotation +
      '/Discount?ServiceProviderId=' + this.api.serviceProviderID +
      '&matterQuotationId=' + matterQuotationId, this.api.httpOptions)
    .pipe(catchError(this.api.handleError)
    );
  } // end getQuotationDiscount()

  getActualQuotationDiscount(matterQuotationId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.quotation +
      '/ActualDiscount?ServiceProviderId=' + this.api.serviceProviderID +
      '&matterQuotationId=' + matterQuotationId, this.api.httpOptions)
    .pipe(catchError(this.api.handleError)
    );
  } // end getQuotationDiscount()

  getQuotationById(quotationId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.quotation +
      '/QuotationById?QuotationId=' + quotationId, this.api.httpOptions)
    .pipe(
      catchError(this.api.handleError)
    );
  } // end getQuotationById()

  getQuotationFees(quotationId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.quotation +
      '/QuotationFee?ServiceProvider=' + this.api.serviceProviderID +
      '&QuotationId=' + quotationId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getQuotation()

  getActualQuotationFees(quotationId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.quotation +
      '/ActualQuotationFee?ServiceProvider=' + this.api.serviceProviderID +
      '&ActualQuotationId=' + quotationId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end getActualQuotationFees()

  getServiceQuotationLink(matterQuotationId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.quotation +
      '/ServiceQuotationLink?Id=' + matterQuotationId, this.api.httpOptions)
    .pipe(catchError(this.api.handleError)
    );
  } // end getServiceQuotationLink()

  getServiceQuotationFees(serviceId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.quotation +
      '/ServiceQuotationFees?serviceId=' + serviceId +
      '&ServiceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end getServiceQuotationFees()

  getActualFeesQuoted(ServiceId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.quotation +
      '/ActualFeesQuoted?ServiceProvider=' + this.api.serviceProviderID +
      '&ServiceId=' + ServiceId, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end getActualFeesQuoted()

  addQuotationMatter(matter: MatterQuotationDTO): Observable<any> {
    return this.http.post<MatterQuotationDTO>(
      this.api.endpoints.quotation, matter,
      this.api.httpOptions
    )
    .pipe(
      catchError(this.api.handleError)
    );
  } // end addQuotationMatter{}

  updateQuotation(id: number, matter: MatterQuotationDTO): Observable<any> {
    return this.http.put<MatterQuotationDTO>(this.api.endpoints.quotation +
      '/MatterQuotation?id=' + id, matter, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // ed updateQuotation()

  putQuotationServiceLink(data: QuotationServiceLink): Observable<any> {
    return this.http.put<QuotationServiceLink>(this.api.endpoints.quotation +
      '/ServiceQuotationLink', data, this.api.httpOptions)
    .pipe(catchError(this.api.handleError)
    );
  } // end putQuotationServiceLink()

  updateQuotationFees(fee: QuotationFeeItem[]): Observable<any> {
    return this.http.put(this.api.endpoints.quotation +
      '/MultipleQuotationFees', {feeItemList: fee}, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end updateQuotationFees()

  updateActualQuotationFees(fee: QuotationFeeItem[]): Observable<any> {

    return this.http.put(this.api.endpoints.quotation +
      '/MultipleActualQuotationFees', {feeItemList: fee}, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end updateActualQuotationFees()

  updateQuotationOutbox(quotationId: number): Observable<any> {
    return this.http.put(this.api.endpoints.quotation +
      '/QuotationOutbox?quotationId=' + quotationId, '',
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end updateQuotationOutbox()

  updateQuotationNote(note: QuotationNote): Observable<any> {
    return this.http.put<QuotationNote>(this.api.endpoints.quotation +
      '/UpdateNote', note, this.api.httpOptions)
    .pipe(catchError(this.api.handleError)
    );
  } // end updateQuotationNote()

  updateActualQuotationNote(note: QuotationNote): Observable<any> {
    return this.http.put<QuotationNote>(this.api.endpoints.quotation +
      '/UpdateActualNote', note, this.api.httpOptions)
    .pipe(catchError(this.api.handleError)
    );
  } // end updateActualQuotationNote()

  updateAcceptQuotation(quote: MatterQuotationDTO, status: string): Observable<any> {
    return this.http.put<MatterQuotationDTO>(this.api.endpoints.quotation +
      '/AdvocateAcceptQuote?status=' + status, quote, this.api.httpOptions)
    .pipe(catchError(this.api.handleError)
    );
  } // updateAcceptQuotation()

  postSendQuotation(quotation: SendQuotation): Observable<any> {
    return this.http.post<SendQuotation>(this.api.endpoints.quotation +
      '/SendQuotation', quotation, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end postSendQuotation{}

  deleteQuotationFee(feeId: number): Observable<{}> {
    return this.http.delete(this.api.endpoints.quotation +
      '/' + feeId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end deleteQuotationFee()

  deleteActualQuotationFee(feeId: number): Observable<{}> {
    return this.http.delete(this.api.endpoints.quotation +
      '/ActualQuotationFeeItem?id=' + feeId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end deleteActualQuotationFee()

  reviseQuotation(quotationId: number): Observable<any> {
    return this.http.delete(this.api.endpoints.quotation +
      '/ReviseQuotation?quotationId=' + quotationId, this.api.httpOptions)
    .pipe(catchError(this.api.handleError)
    );
  } // end reviseQuotation{}

  onRemoveQuotation(matterQuotationId: number, deleteReason: string): Observable<any> {
    return this.http.delete(this.api.endpoints.quotation +
      '/RemoveQuotation?Id=' + matterQuotationId + '&deleteReason=' + deleteReason,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
    );
  } // end onRemoveQuotation()
} // end QuotationService{}
