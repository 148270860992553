import { Component, OnChanges, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Snack, SnackbarsService, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { LENGTH_LONG } from '../../../services/messaging/toast/toast.service';
import { ManageBlackListRequest, ManageBlackListService, MANAGE_BLACKLIST_REQUEST } from '../../../services/manage-BlackList/manage-BlackList.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-add-BlackList-modal',
  templateUrl: './add-BlackList-modal.component.html',
  styleUrls: ['./add-BlackList-modal.component.scss']
})
export class AddBlackListModalComponent implements OnInit, OnChanges {
  @ViewChild('pdfViewer') public pdfViewer;

  bsValue = new Date();
  bsValueDemandDate = new Date();
  @Input() isVisible: boolean;
  @Input() pendingLawfirm: any[];
  @Input() currentLawfirmId: number;
  @Input() blackListLawfirm: ManageBlackListRequest;
  @Input() selectedLawfirmName: string;
  @Input() isGCBUser: boolean = false;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshGrid: EventEmitter<any> = new EventEmitter();

  selectedAdvocate: ManageBlackListRequest = MANAGE_BLACKLIST_REQUEST;
  advocatelist: ManageBlackListRequest[] = [];
  selectedRegionalBarlist: ManageBlackListRequest;

  title = 'GCB Listings for GHTD Incorporeted';
  attachmentFile: File;
  attachmentName = '';
  attachment: string;
  ReportingAdvDrp: number;
  currentLaufirmNme: string
  showNewAdvocateModal: boolean = false
  showTab: boolean
  isLawfirmTabVisible: boolean = true
  reportingAdvocateName: string
  isDeleteInPeogress: boolean = false;
  showUnsavedChangesPrompt: boolean;
  tabView: string;
  tempBlackListLawfirm: ManageBlackListRequest = MANAGE_BLACKLIST_REQUEST;
  modelData: any[];
  manageGCBReportData: any[];
  advocateList: any;
  drpReportingAdv: number;
  reportDate: any;
  searchTerm: any;
  isMandatory: boolean = false;
  showDeletePopPrompt: boolean;
  showAdvocateDropDown: boolean = false;
  showLawfirmTab: boolean = false;
  showAdvocateListTab: boolean = false;
  lawFirmBlackListId: number;
  lawfirmAdvocateHeader: string;
  status: string;
  reportingBarFullName: string;
  reportingBarId: string;
  regionalBarName: string;
  filterExstAdvocate: any;
  isAdvocateNameSelected: boolean = false;
  viewAttachedPdf: boolean = false;
  gcbDeleteNotes: string;
  isGCBDirectRemoval: boolean = false;
  gcbDeleteAttachment: string;
  notesInvalid: boolean = false;
  gcbDeletAttachmentFile: File;

  constructor(
    private loadingService: LoadingService,
    private manageBlackListService: ManageBlackListService,
    private snackbarsService: SnackbarsService,
  ) {
    this.tabView = 'manage-gcb-reports';
  }

  ngOnInit(): void { }
  ngOnChanges() {
    this.currentLaufirmNme = this.selectedLawfirmName;
    this.modelData = this.pendingLawfirm;
    this.showTab = this.isGCBUser;
    this.isAdvocateNameSelected = false;
    this.getLawfirmBlackList();
  }

  getLawfirmBlackList() {
    this.advocateList = [];
    this.manageBlackListService.getLawfirmAdvocates(false)
      .subscribe({
        next: (response) => {
          this.reportingBarFullName = response.RegionalBars[0].FullName
          this.reportingBarId = response.RegionalBars[0].Id
          this.advocateList = response.LawfirmAdvocateList;
        },
        complete: () => {
          //this.loadingService.hideOverlay();
        }
      })
    this.manageGCBReports();
  }
  getExtension(filename) {
    return filename.toString().split('.')[1];
  }
  attachmentChange(e) {
    this.attachmentFile = e.target.files[0];
    this.attachment = this.attachmentFile.name;
  } // end attachmentChange()
  gcbDeleteAttachmentChange(e) {
    this.gcbDeletAttachmentFile = e.target.files[0];
    this.gcbDeleteAttachment = this.gcbDeletAttachmentFile.name;
  } // end gcbDeleteAttachmentChange()
  onSave() {
    this.loadingService.showOverlay();
    if (this.blackListLawfirm.ReportingAdvocateID &&
      this.blackListLawfirm.ReportingDate && this.blackListLawfirm.DemandDate && this.attachment && this.blackListLawfirm.HasConfirmedPopiCompliance) {
      this.isMandatory = false;
      this.blackListLawfirm.LawfirmID = this.currentLawfirmId
    }
    else {
      this.isMandatory = true;
      this.loadingService.hideOverlay();
      return;
    }
    this.loadingService.showOverlay();
    const snack: Snack = {
      label: "Requesting to add defaulters list",
      action: null,
    };
    this.snackbarsService.make(snack, LENGTH_LONG).show();
    this.blackListLawfirm.ReportingBarId = this.reportingBarId
    this.manageBlackListService.onSave(this.blackListLawfirm)
      .subscribe({
        next: (response) => {
          this.blackListLawfirm = response;
        },
        error: (error) => {
          snack.label = error;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack).show();
        },
        complete: () => {
          snack.label = "Defaulting request sent successfully";
          snack.type = SnackType.SUCCESS;
          this.snackbarsService.dismiss().make(snack).show();
          this.uploadBlackListFile(this.blackListLawfirm.LawfirmBlackListID)
        }
      })
  }
  uploadBlackListFile(lawfirmBlackListID) {
    const formData = new FormData()
    formData.append('BlackListFile', this.attachmentFile);
    this.manageBlackListService.onBlackListFileUpload(formData, lawfirmBlackListID)
      .subscribe({
        next: (response) => {
          this.blackListLawfirm = response;
          //this.loadingService.hideOverlay();
        },
        error: (error) => {
          const snack: Snack = {
            label: error,
            type: SnackType.ERROR,
            action: null,
          };
          this.snackbarsService.dismiss().make(snack).show();
          this.loadingService.hideOverlay();
        },
        complete: () => {
          this.reportingAdvocateName = null;
          this.tabView = 'manage-gcb-reports';
          setTimeout(() => {
            this.isVisible = false;
            this.closeModal.emit(true);
            this.loadingService.hideOverlay();
          }, 5000);
        }
      })
  }
  onInput(event) {
    if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
    }
    this.filterExstAdvocate = this.advocateList.filter(x => x.FullName.toLowerCase() === event.target.value.toLowerCase());

    if (this.filterExstAdvocate.length == 0) {
      this.reportingAdvocateName = null;
      this.blackListLawfirm.ReportingAdvocateID = null;
      this.isAdvocateNameSelected = true;
    }
    else {
      this.isAdvocateNameSelected = false;
    }
  }
  onBlurAdvocateName() {
    if (this.blackListLawfirm.ReportingAdvocateID) {
      this.isAdvocateNameSelected = false;
    } else {
      this.isAdvocateNameSelected = true;
    }
  }
  onFocusAdvocateName() {
    this.onBlurAdvocateName();
  }
  onModelChange(event, selectedContactLocality: ManageBlackListRequest) {
    this.blackListLawfirm.ReportingAdvocateID = selectedContactLocality.ReportingAdvocateID;
  } // end onModelChange()
  onSelect(event: TypeaheadMatch): void {
    this.selectedRegionalBarlist = MANAGE_BLACKLIST_REQUEST;
    this.selectedRegionalBarlist.ReportingAdvocateID = event.item.Id;
    this.reportingAdvocateName = event.item.ReportingAdvocateName;
    this.blackListLawfirm.ReportingAdvocateID = event.item.ReportingAdvocateId;
    this.isMandatory = false;
    this.isAdvocateNameSelected = false;
  }
  manageGCBReports() {
    this.loadingService.showOverlay();
    this.manageGCBReportData = [];
    if (this.currentLawfirmId) {
      this.loadingService.showOverlay();
      this.manageBlackListService.getbyLawfirmApprovedReqList(this.currentLawfirmId, 'Approved')
        .subscribe({
          next: (response) => {
            this.manageGCBReportData = response;
            if (this.currentLawfirmId != null) {
              response.forEach(item => {
                this.manageGCBReportData.push()
              });
            }
            this.loadingService.hideOverlay();
          },
        })
    }
  }
  onDismiss(refreshGrid: boolean) {
    this.loadingService.showOverlay();
    this.isVisible = false;
    this.tabView = 'manage-gcb-reports';
    this.reportingAdvocateName = null;
    this.isMandatory = false;
    this.closeModal.emit(refreshGrid);
  } // end onDismiss()

  onDeleteLawfirmListingsItem() {
    this.loadingService.showOverlay();
    if (this.isDeleteInPeogress || !this.gcbDeleteNotes) {
      this.notesInvalid = !this.gcbDeleteNotes;
      return;
    }

    if (this.isDeleteInPeogress)
      return;
    this.isDeleteInPeogress = true
    const formData = new FormData()
    formData.append('GCBDeleteAttachment', this.gcbDeletAttachmentFile);

    this.manageBlackListService.onDeleteLawfirmListingsItem(formData, this.lawFirmBlackListId,this.gcbDeleteNotes)
      .subscribe({
        next: (response: any) => {
          this.isGCBDirectRemoval = response.IsGCBDirectRemoval;
          this.onHideDeletePopPrompt();
          this.getLawfirmBlackList();
          this.isDeleteInPeogress = false;
          this.refreshGrid.emit(this.isGCBDirectRemoval);
        },
      })
  }
  onDeletePopPromptShow(lawfirmBlacklistId: number) {
    this.showDeletePopPrompt = true;
    this.lawFirmBlackListId = lawfirmBlacklistId;
  }
  onHideDeletePopPrompt() {
    this.showDeletePopPrompt = false;
    this.gcbDeleteNotes = null;
    this.gcbDeleteAttachment = null;
    this.notesInvalid = false;
  } // end onHideDeletePopPrompt()
  changeTabView(selectedTab: string) {
    //this.clearInputs();
    this.tabView = selectedTab;
    if (this.tabView === 'manage-gcb-reports') {

      this.loadingService.hideOverlay();
      this.title = ' manage-gcb-reports';
    } else if (this.tabView === 'add-new-gcb-listings') {
      this.blackListLawfirm = Object.assign({}, MANAGE_BLACKLIST_REQUEST);
      this.attachmentFile = null;
      this.attachment = '';

      this.loadingService.hideOverlay();
      this.title = 'add-new-gcb-listings ';
    }
  } // end changeTabView()
  promptAttachment() {
    jQuery('#attachmentUpload').val(null);
    jQuery('#attachmentUpload').trigger('click');
  } // end promptAttachment File Browsing()
  promptGCBDeleteAttachment() {
    jQuery('#gcbDeleteAttachmentUpload').val(null);
    jQuery('#gcbDeleteAttachmentUpload').trigger('click');
  } // end promptAttachment File Browsing()
  performReportingAdvocateSearch() {
    if (this.searchTerm) {
      this.advocateList.forEach((RepotingAdvocateData) => {
        if (!(RepotingAdvocateData.data.Lawfirm.match(new RegExp(this.searchTerm, 'gi')))) {
          RepotingAdvocateData.state.isVisible = false;
        } else {
          RepotingAdvocateData.state.isVisible = true;
        }
      });
    }
    else {
      this.advocateList.forEach((RepotingAdvocateData) => {
        RepotingAdvocateData.state.isVisible = true;
      });
    }
  }
  downloadBlackListPDF(filePath: string) {
    if (!filePath)
      return;
    else {
      this.viewAttachedPdf = true
      this.manageBlackListService.getBlackListUploadedDocumentFile(filePath)
        .subscribe({
          next: (response) => {
            this.pdfViewer.name = 'name.pdf';
            this.pdfViewer.pdfSrc = response; // pdfSrc can be Blob or Uint8Array
            this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
          },
        })
    }
  }
  dismiss() {
    this.viewAttachedPdf = false;
  } // end dismiss()
  addNewAdvocate() {
    this.showNewAdvocateModal = true;
    this.regionalBarName = this.reportingBarFullName;
  }
  closeAdvocateModal() {
    this.loadingService.showOverlay();
    this.showNewAdvocateModal = false;
    this.loadingService.hideOverlay();
  }
  isDirectCloseAdvocateModal() {
    this.showNewAdvocateModal = false;
    this.loadingService.hideOverlay();
  }

  selectedExstAdvocateName(event) {
    this.getLawfirmBlackList();
    this.reportingAdvocateName = event.LawfirmName;
    this.blackListLawfirm.ReportingAdvocateID = event.ReportingAdvocateId;
  }
}
