import { Component, OnInit } from '@angular/core';
import { ManageBlackListService } from '../../services/manage-BlackList/manage-BlackList.service';
import { LoadingService } from '../../services/messaging/loading/loading.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-gcb-response-handler',
  templateUrl: './gcb-response-handler.component.html',
  styleUrls: ['./gcb-response-handler.component.scss']
})
export class GCBResponseHandlerComponent implements OnInit {
  statusUpdate: string;
  blackListLawfirmId: number;
  cancelErrorMessage: string;
  onShowApprovedRejected: boolean = false;
  onShowCancel: boolean = false;
  currentBarName: string
  currentLawfirmName: string
  encryptedQueryParam: string
  statusApproved: boolean = false;
  statusRejected: boolean = false;
  reportingBar: string;
  lawfirmName: string;
  fileName: string;
  flag: string;
  approvedRejectedByID: number;
  isGCBDeleteReq: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private router: Router,
    private manageBlackListService: ManageBlackListService,
  ) { }

  ngOnInit(): void {
    this.loadingService.showOverlay();

    let currentURL = this.router.url;
    this.encryptedQueryParam = currentURL.split('/')[2];
    let decodedString = window.atob(decodeURIComponent(this.encryptedQueryParam));

    const queryParams = new URLSearchParams(decodedString);
    this.fileName = queryParams.get('FileName');
    const approvedRejectedByID = queryParams.get('approvedRejectedByID');
    this.approvedRejectedByID = parseInt(approvedRejectedByID);
    const isGCBDeleteReq = queryParams.get('isGCBDeleteReq');

    if (isGCBDeleteReq === "true")
      this.isGCBDeleteReq = true;

    if (isGCBDeleteReq)
      this.onGCBDeleteReqApproved_Rejected();
    else
      this.onApproved_Rejected();
  }

  onApproved_Rejected() {
    this.loadingService.showOverlay();
    this.manageBlackListService.onApproved_Rejected(this.encryptedQueryParam, true)
      .subscribe({
        next: (response) => {
          if (typeof response == 'object') {
            this.onShowApprovedRejected = true;
            this.currentBarName = response.ReportingBarName
            this.currentLawfirmName = response.LawfirmName
            if (response.Status == 'Approved') {
              this.statusApproved = true;
              this.statusRejected = false;
            } else if (response.Status == 'Rejected') {
              this.statusRejected = true;
              this.statusApproved = false;
            }
          }
          if (typeof response == 'string') {
            this.onShowCancel = true;
            this.cancelErrorMessage = response
          }
          this.loadingService.hideOverlay();
        },
      })
  }

  onGCBDeleteReqApproved_Rejected() {
    this.loadingService.showOverlay();
    this.manageBlackListService.onApproved_RejectedGCBDeleteRequest(this.encryptedQueryParam, this.fileName, this.approvedRejectedByID)
      .subscribe({
        next: (response) => {
          if (typeof response == 'object') {
            this.onShowApprovedRejected = true;
            this.currentBarName = response.ReportingBarName
            this.currentLawfirmName = response.LawfirmName
            if (response.Status == 'Approved') {
              this.statusApproved = true;
              this.statusRejected = false;
            } else if (response.Status == 'Rejected') {
              this.statusRejected = true;
              this.statusApproved = false;
            }
          }
          if (typeof response == 'string') {
            this.onShowCancel = true;
            this.cancelErrorMessage = response
          }
          this.loadingService.hideOverlay();
        },
      })
  }
}
