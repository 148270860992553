import { Component, OnInit } from '@angular/core';
import { AgeAnalysisData } from '../../../services/age-analysis/age-analysis.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { LiveStatementService, StatementByAdvocate } from '../../../services/live-statement/live-statement.service';
import { SnackbarsService } from '../../../services/messaging/snackbars/snackbars.service';
import { ICrumb } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { PATH_ATTORNEY_DASHBOARD } from '../../../services/appdata/app-config.service';

@Component({
  selector: 'app-attorney-age-analysis',
  templateUrl: './attorney-age-analysis.component.html',
  styleUrls: ['./attorney-age-analysis.component.scss']
})
export class AttorneyAgeAnalysisComponent implements OnInit {

  ageAnalysisData: AgeAnalysisData;
  statementByAdvocate: StatementByAdvocate[];
  crumbs: ICrumb[];

  constructor(
    private loadingService: LoadingService,
    private snackbarsService: SnackbarsService,
    private liveStatementService: LiveStatementService
  ) { } // end contructor()

  ngOnInit() {
    this.crumbs = [
      {
        label: 'LIVE STATEMENT',
        link: PATH_ATTORNEY_DASHBOARD
      },
      {
        label: 'AGE ANALYSIS'
      }
    ];
     this.getAgeAnalysisData();
  } // end ngOnInit()

  getAgeAnalysisData() {
    this.loadingService.showOverlay();
    this.liveStatementService.getAdvocateStatementAdvocateSummary().subscribe({ next: (advocates) =>{
      if (advocates) {
        this.statementByAdvocate = advocates.StatementByAdvocate;
        this.ageAnalysisData = {
          NormalDay: advocates.ZeroDays,
          ZeroDays: advocates.ZeroDays,
          ThirtyDays: advocates.ThirtyDays,
          SixtyDays: advocates.SixtyDays,
          NinetyDays: advocates.NinetyDays,
          NinetyEightDays: advocates.NinetyEightDays,
          InvoiceStatusGroup: advocates.InvoiceStatusGroup,
          Total: advocates.Total
        };
      }
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.loadingService.hideOverlay();
    }
  });
  } // end getAgeAnalysisData()
} // end AttorneyAgeAnalysisComponent{}
