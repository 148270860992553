import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { ToastService } from '../messaging/toast/toast.service';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

export interface Defendant {
  FullName: string;
} // end Defendant{}

@Injectable()
export class DefendantsService {

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private toastService: ToastService
  ) { } // end constructor()

  getDefendants(): Observable<any> {
    return this.http.get(this.api.endpoints.defendants +
      '?ServiceProviderID=' + this.api.serviceProviderID,
      this.api.httpOptions)
      .pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end getDefendants()

  createDefendant(defendant: Defendant): Observable<any> {
    return this.http.post(this.api.endpoints.defendants,
      defendant, this.api.httpOptions).pipe(
        retry(3),
        catchError(this.api.handleError)
      );
  } // end createDefendant()

} // end DefendantsService{}
