<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button
          class="modal-dismiss"
          (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div>

      <div class="modal-body form">
        <!-- Comment -->
        <div class="form-group">
          <label for="comment">Comments</label>
          <textarea
            type="text"
            [id]="comments"
            [(ngModel)]="comments"
            placeholder="Reason for deleting the quotation"
            (keyup)="validate()"></textarea>
            <p
              class="comment-length-indicator"
              [class.text-danger]="exceedsMaxCommentLength">{{numberOfCharactersLeft}}</p>
        </div>
      </div>

    </div>

    <div class="modal-footer">

      <button
        class="btn btn-danger"
        [disabled]="!isDeleteable"
        [class.disabled]="!isDeleteable"
        (click)="confirm()">Delete quotation</button>

    </div>
  </div>
</div> <!-- #end .modal -->