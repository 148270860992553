<div *ngIf="!isError && isSuccess" class="container-fluid page-wrapper">
    <div class="row w-100" style="padding-top: 17vh;">
        <div class="col-sm-9" style="background: #fff;  padding: 20px; border-radius: 5px">
            <div class="row icon-success">
                <i class="fal fa-check-circle fa-10x" style="margin-bottom:25px;"></i>
            </div>
            <div *ngIf="isSuccessMessage" class="alert alert-success Email-label-alert-success">
                You have successfully been verified.
            </div>
            <div *ngIf="!isSuccessMessage" class="alert alert-success Email-label-alert-success">
                You have successfully been verified. <br> You will receive a welcome email containing your login
                details.
            </div>       
        </div>
    </div>
</div>
<div *ngIf="isError && !isSuccess" class="container-fluid page-wrapper">
    <div class="row w-100" style="padding-top: 17vh;">
        <div class="col-sm-9" style="background: #fff;  padding: 20px; border-radius: 5px">
            <div class="row icon-danger">
                <i class="fal fa-times-circle fa-10x" style="margin-bottom:25px;"></i>
            </div>
            <div class="alert alert-danger Email-label-alert-danger">
                {{ErrorMessage}}
            </div>
        </div>
    </div>
</div>