import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ViewChild,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
// import { CalendarComponent } from 'ng-fullcalendar';
// import { Options } from 'fullcalendar';
import { AdvocateCalendarComponent } from '../../../pages/advocate-calendar/advocate-calendar.component';
import { DEFAULT_DATE_FORMAT } from '../../inputs/date-picker/date-picker.component';

@Component({
  selector: 'app-date-picker-modal',
  templateUrl: './date-picker-modal.component.html',
  styleUrls: ['./date-picker-modal.component.scss']
})
export class DatePickerModalComponent implements OnInit {

  @Input() isVisible: boolean;
  @Input() format: string;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(AdvocateCalendarComponent) ucDatepicker: AdvocateCalendarComponent;

  options: any;
  title: string;

  constructor() {} // end constructor()

  ngOnInit() {
    if (!this.format) {
      this.format = DEFAULT_DATE_FORMAT;
    }
    this.options = {
      defaultView: 'month',
      editable: true,
      eventStartEditable: false,
      eventLimit: true,
      views: {
        agenda: {
          eventLimit: 3 // Display 3 events per cell
        }
      },
      header: {
        left: 'prevYear, prev',
        center: 'title',
        right: 'next, nextYear'
      },
      selectable: false,
      nowIndicator: true
    }
  } // end ngOnInit()

  onDayClick(detail) {
    this.select.emit(detail.date.format(this.format));
    this.dismiss();
  } // end onDayClick()

  onModalClick(event) {
  } // end  onModalClick($event)

  dismiss() {
    this.cancel.emit();
  } // end dismiss()
} // end DatePickerModalComponent{}
