import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  Chamber,
  BarSociety,
  ChambersService,
  AdvocateChambers
} from '../../../services/chambers/chambers.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

@Component({
  selector: 'app-datalist-chambers',
  templateUrl: './datalist-chambers.component.html',
  styleUrls: ['./datalist-chambers.component.scss']
})
export class DatalistChambersComponent implements OnInit {

  types = {
    BAR_SOCIETY: 'bar-society',
    CHAMBERS: 'chambers'
  };

  @Input() type: string;
  @Input() chambers: Chamber[];
  @Input() barSociety: BarSociety[];
  @Input() advocateChambers: AdvocateChambers;
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private loadingService: LoadingService,
    private chambersService: ChambersService
  ) { } // end constructor()

  ngOnInit() {

  } // end ngOnInit()

  onSelectBarSociety(event: TypeaheadMatch): void {
    this.loadingService.showOverlay();
    this.advocateChambers.BarSocietyId = event.item.Id;
    this.advocateChambers.BarSociety = event.item.Name;
    this.select.emit(this.advocateChambers);
    // this.chambersService.getChambers(event.item.Id).subscribe(
    //   data => {
    //     // On next
    //     this.chambers = data;
    //   },
    //   error => {
    //     // On error
    //     this.loadingService.hideOverlay();
    //   },
    //   () => {
    //     // On complete
    //     this.loadingService.hideOverlay();
    //   });
  } // end onSelectBarSociety()

  onInput(event) {
    if (this.type === this.types.BAR_SOCIETY) {
      if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
        this.advocateChambers.BarSocietyId = 0;
        this.advocateChambers.Chamber = '';
        this.advocateChambers.ChamberId = 0;
        this.advocateChambers.Address = '';
        this.advocateChambers.RoomNumber = '';
        this.advocateChambers.Website = '';
        this.chambers = [];
      }
    } else if (this.type === this.types.CHAMBERS) {
      if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
        this.advocateChambers.ChamberId = 0;
        this.advocateChambers.Address = '';
        this.advocateChambers.RoomNumber = '';
        this.advocateChambers.Website = '';
      }
    }
  } // end onInput()

  onSelectChamber(event: TypeaheadMatch): void {
    this.advocateChambers.ChamberId = event.item.Id;
    this.advocateChambers.Chamber = event.item.ChamberName;
    this.advocateChambers.Address = event.item.Address;
    this.advocateChambers.Email = event.item.Email;
    this.advocateChambers.Phone = event.item.Phone;
    this.select.emit(this.advocateChambers);
  } // end onSelectChamber()

} // end DatalistChambersComponent{}
