import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvocateDetailStatement,
        AdvocateDetailStatementService } from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

@Component({
  selector: 'app-state-invoice-status',
  templateUrl: './state-invoice-status.component.html',
  styleUrls: ['./state-invoice-status.component.scss']
})
export class StateInvoiceStatusComponent implements OnInit {
  title: string = 'Status Update'
  invoiceStatus: string = '0;'
  invoiceStatusList: any;
  @Input() isVisible: boolean;
  @Input() advocateDetailStatement: AdvocateDetailStatement;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatedStatus: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private loadingService: LoadingService,
    private advocateDetailService: AdvocateDetailStatementService
  ) { } // end constructor()

  ngOnInit(): void {
    this.invoiceStatusList = [
      {label: 'Dispute / Query sent to Advocate.', value: 'Dispute / Query sent to Advocate.'},
      {label: 'File prepared for Section Head (all documents included).', value: 'File prepared for Section Head (all documents included).'},
      {label: 'File sent to Section Head for signature.', value: 'File sent to Section Head for signature.'},
      {label: 'File sent to Head of State for signature.', value: 'File sent to Head of State for signature.'},
      {label: 'Invoice sent to client for approval (4-day review period).', value: 'Invoice sent to client for approval (4-day review period).'},
      {label: 'Invoice submitted to Accounts Department (30-day payment processing).', value: 'Invoice submitted to Accounts Department (30-day payment processing).'},
    ]
  } // end ngOnInit()

  onCancel() {
    this.cancel.emit();
  } // end onCancel()

  onStatusUpdate() {
    this.loadingService.showOverlay();
    this.advocateDetailService.updateInvoiceStatus(this.advocateDetailStatement.InvoiceId, this.invoiceStatus).subscribe({ next: (next) => {
      // On next
      if (next) {
        this.advocateDetailStatement.DisputStatusId = next.Id;
      }
    },
      error: (error) => {
        // On error
        this.loadingService.hideOverlay();
      },
      complete: () => {
        // On complete
        this.advocateDetailStatement.InvoiceStatus = this.invoiceStatus;
        this.updatedStatus.emit(this.advocateDetailStatement);
      }
    });
  } // end onStatusUpdate();

} // end StateInvoiceStatusComponent()
