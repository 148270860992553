<div class="container-fluid pageWrapper pl-0">
    <h2 class="page-title">Office 365 Setup</h2>
    <div class="row">
        <div class="col-lg-4 outlook-sync mt-3">
            <i class="fa fa-info-circle fa-lg tool-tip">
                <div class="tooltiptext">
                    <p class="textLeftalign" style="font-size: 10px;text-align: justify;">By granting Auxcon access to
                        your Outlook calendar, you enable a seamless synchronization of all your Outlook Calendar
                        entries with the Auxcon Calendar. This integration empowers you to effortlessly transform your
                        Outlook Calendar events into billable matter entries through the Auxcon platform. In essence,
                        this streamlined process enhances your efficiency in tracking and managing billable activities
                        associated with your calendar events.
                    </p>
                </div>
            </i>
            <div class="card outlook-sync-card" [class.disabled]="selectedCard !== null && selectedCard !== 1"
                [class.selected]="selectedCard === 1 && selectedCard !== null"
                (click)="selectCard(1, selectedCard == 1 && !errorMessage ? 'Unlink' : '')">
                <div class="row p-0">
                    <div class="col-lg-12 p-5">
                        <div class="CardHeading">
                            <div class="card-heading">
                                <i class="fas fa-solid fa-calendar-check fa-3x"></i>
                                <h3 class="p-3">Setup Outlook Calendar</h3>
                            </div>
                            <div class="card-subHeading">
                                <span *ngIf="selectedCard"> {{ selectedCard == 1 && !errorMessage ? "Unlink" :
                                    (selectedCard == 1 && errorMessage ? errorMessage : "") }} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 outlook-sync mt-3">
            <i class="fa fa-info-circle fa-lg tool-tip">
                <div class="tooltiptext">
                    <p class="textLeftalign" style="font-size: 10px; text-align: justify;"> This feature enables you to
                        send your invoices and statements directly to your law firms using your personal email address.
                        Opting for this functionality grants you the flexibility to manage your communication
                        seamlessly. In the event that you choose not to utilize this option, invoices and statements
                        will continue to be send from our default sender address, ensuring a consistent and reliable
                        delivery process.
                    </p>
                </div>
            </i>
            <div class="card outlook-sync-card"
                [class.disabled]="isCardDisabled || (selectedCard !== null && selectedCard !== 2)"
                [class.selected]="selectedCard === 2 && selectedCard !== null"
                (click)="selectCard(2, selectedCard == 2 && !errorMessage ? 'Unlink' : '')">
                <div class="row p-0">
                    <div class="col-lg-12 p-5">
                        <div class="CardHeading">
                            <div class="card-heading">
                                <i class="fas fa-solid fa-envelope fa-3x"></i>
                                <h3 class="p-3">Setup sender email</h3>
                            </div>
                            <div class="card-subHeading">
                                <span *ngIf="selectedCard"> {{ selectedCard == 2 && !errorMessage ? "Unlink" :
                                    (selectedCard == 2 && errorMessage ? errorMessage : "") }} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 outlook-sync mt-3">
            <div class="card outlook-sync-card" [class.disabled]="selectedCard !== null && selectedCard !== 3"
                [class.selected]="selectedCard === 3 && selectedCard !== null"
                (click)="selectCard(3, selectedCard == 3 && !errorMessage ? 'Unlink' : '')">
                <div class="row p-0">
                    <div class="col-lg-12 p-5">
                        <div class="CardHeading">
                            <div class="card-heading">
                                <i class="fas fa-solid fa-check-double fa-3x"></i>
                                <h3 class="p-3">Setup both</h3>
                            </div>
                            <div class="card-subHeading">
                                <span *ngIf="selectedCard"> {{ selectedCard == 3 && !errorMessage ? "Unlink" :
                                    (selectedCard == 3 && errorMessage ? errorMessage : "") }} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-prompt-unsaved-changes-modal title="Remove Office 365 setup"
    message="Are you sure you want to remove the {{selectedCard === 1 ? 'Calendar' : (selectedCard === 2 ? 'Inbox' : (selectedCard === 3 ? 'Office 365' : ''))}} setup?"
    [isVisible]="showUnLinkPopPrompt" btnTextCancel="Cancel" btnTextContinue="Proceed"
    (cancel)="onHideUnLinkPopPrompt()" (continue)="onDeleteContactGraphPermission()">
</app-prompt-unsaved-changes-modal>