import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface AdvocateHealth {
  Advocate: string;
  UserName: string;
  FeeTarget: number;
  IncomeTarget: number;
  Id: number;
  Total_Due_98: number;
  Total_Due_98_Vs_Target: number;
  Paid120Days: number;
  Paid_120_Days_Vs_Target: number;
  Total_Invoiced_120: number;
  Total_Invoiced_120_Vs_Target: number;
  Last_Transaction: string;
  Last_Statement_Date: string;
  Last_Invoice_Created: string;
  Collect_from: number;
  collect: string;
  Comment: string;
  LastMeetingDate: string;
  CollectedLastMonth: number;
  LastStatementReminderDate: string;
  Collection: boolean;
  IsAccess: boolean;
  Selected: boolean;
}

export const DEFAULT_ADVOCATE_HEALTH: AdvocateHealth = {
  Advocate: '',
  UserName: '',
  FeeTarget: 0,
  IncomeTarget: 0,
  Id: 0,
  Total_Due_98: 0,
  Total_Due_98_Vs_Target: 0,
  Paid120Days: 0,
  Paid_120_Days_Vs_Target: 0,
  Total_Invoiced_120: 0,
  Total_Invoiced_120_Vs_Target: 0,
  Last_Transaction: '',
  Last_Statement_Date: '',
  Last_Invoice_Created: '',
  Collect_from: 0,
  collect: '',
  Comment: '',
  LastMeetingDate: '',
  CollectedLastMonth: 0,
  LastStatementReminderDate: '',
  Collection: false,
  IsAccess: false,
  Selected: false
};

export interface AdvocateHealthHandle {
  data: AdvocateHealth;
  state: {
    isVisible: boolean;
    isOpen: boolean;
    isSelected: boolean;
    invoicedColor: string;
    collectedColor: string;
    collectedThisMonthColor: string;
    calculateLastMeetingDate: number;
    calculateLastInvoiceDate: number;
    calculateLastTransactionDate: number;
    calculateLastStatementDate: number;
    // calculateLastInvoicedThreeMonthsDate: number;
    // calculateLastCollectedThreeMonthsDate: number;
  };
}

export interface AdvocatePracticeSummary {
  Advocate: string;
  InvoiceTarget: number;
  CollectionTarget: number;
  TotalInvoicedThisMonth: number;
  TotalInvoicedLastMonth: number;
  TotalCollectedThisMonth: number;
  TotalCollectedLastMonth: number;
  LastMeetingDate: string;
  InvoicedBelowTarget: number;
  InvoiceAboveTarget: number;
  CollectedBelowTarget: number;
  CollectedAboveTarget: number;
  CollectedThisMonthPercentage: number;
  Age: number;
  AdvocateAge: string;
}

export const DEFAULT_ADVOCATE_PRACTICE_SUMMARY: AdvocatePracticeSummary = {
  Advocate: '',
  InvoiceTarget: 0,
  CollectionTarget: 0,
  TotalInvoicedThisMonth: 0,
  TotalInvoicedLastMonth: 0,
  TotalCollectedThisMonth: 0,
  TotalCollectedLastMonth: 0,
  LastMeetingDate: '',
  InvoicedBelowTarget: 0,
  InvoiceAboveTarget: 0,
  CollectedBelowTarget: 0,
  CollectedAboveTarget: 0,
  Age: 0,
  AdvocateAge: '',
  CollectedThisMonthPercentage: 0
};

export interface FrequencyTotals {
  Aging98Total: number;
  Aging90Total: number;
  Aging60Total: number;
  Aging30Total: number;
  AgingCurrentTotal: number;
  TotalOutstanding: number;
  Type: string;
}

export interface PracticeAgeAnalysis {
  ServiceProviderId: number;
  Aging98Percentage: number;
  Aging90Percentage: number;
  Aging60Percentage: number;
  Aging30Percentage: number;
  AgingCurrentPercentage: number;
  FrequencyTotals: FrequencyTotals[];
}

export const DEFAULT_PRACTICE_AGE_ANALYSIS: PracticeAgeAnalysis = {
  ServiceProviderId: 0,
  Aging98Percentage: 0,
  Aging90Percentage: 0,
  Aging60Percentage: 0,
  Aging30Percentage: 0,
  AgingCurrentPercentage: 0,
  FrequencyTotals: []
};

export interface AdvocatePracticeSummaryHandle {
  data: AdvocatePracticeSummary;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

export interface AllTotals {
  AdvocateId: number;
  AdvocateName: string;
  ClientId: number;
  Debtor: string;
  InvoiceCount: number;
  LatestComments: string;
  Outstanding: number;
  OutstandingPercentage: number;
  Type: string;
}

export interface CurrentTotals {
  AdvocateId: number;
  AdvocateName: string;
  ClientId: number;
  Debtor: string;
  InvoiceCount: number;
  LatestComments: string;
  Outstanding: number;
  OutstandingPercentage: number;
  Type: string;
}

export interface ThirtyDaysTotals {
  AdvocateId: number;
  AdvocateName: string;
  ClientId: number;
  Debtor: string;
  InvoiceCount: number;
  LatestComments: string;
  Outstanding: number;
  OutstandingPercentage: number;
  Type: string;
}

export interface SixtyDaysTotals {
  AdvocateId: number;
  AdvocateName: string;
  ClientId: number;
  Debtor: string;
  InvoiceCount: number;
  LatestComments: string;
  Outstanding: number;
  OutstandingPercentage: number;
  Type: string;
}

export interface NinetyDaysTotals {
  AdvocateId: number;
  AdvocateName: string;
  ClientId: number;
  Debtor: string;
  InvoiceCount: number;
  LatestComments: string;
  Outstanding: number;
  OutstandingPercentage: number;
  Type: string;
}

export interface NinetyEightDaysTotals {
  AdvocateId: number;
  AdvocateName: string;
  ClientId: number;
  Debtor: string;
  InvoiceCount: number;
  LatestComments: string;
  Outstanding: number;
  OutstandingPercentage: number;
  Type: string;
}

export interface PracticeAllCustomer {
  allTotals: AllTotals[];
  currentTotals: CurrentTotals[];
  thirtyDaysTotals: ThirtyDaysTotals[];
  sixtyDaysTotals: SixtyDaysTotals[];
  ninetyDaysTotals: NinetyDaysTotals[];
  ninetyEightDaysTotals: NinetyEightDaysTotals[];
}

export interface OtherPracticeManagers {
  due: number;
  practiceManager: string;
  advocateCount: number;
}

export interface DebtorAdvocateDetail {
  ClientId: number;
  AdvocateId: number;
  AdvocateName: string;
  Outstanding: number;
  InvoiceCount: number;
  OutstandingPercentage: number;
  IsInLegal: boolean;
}
export const DEFAULT_ADVOCATE_DETAIL: DebtorAdvocateDetail = {
  ClientId: 0,
  AdvocateId: 0,
  AdvocateName: '',
  Outstanding: 0,
  InvoiceCount: 0,
  OutstandingPercentage: 0,
  IsInLegal: false
};

export interface DebtorAdvocateDetailHandle {
  data: DebtorAdvocateDetail;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
} // end DebtorAdvocateDetailHandle{}

export interface DebtorTotalOutstanding {
  ClientId: number;
  Debtor: string;
  AdvocateId: number;
  AdvocateName: string;
  Outstanding: number;
  FirmPercentage: number;
  AdvocateCount: number;
  AdvocateList: DebtorAdvocateDetail[];
}

export interface DebtorTotalOutstandingHandle {
  data: DebtorTotalOutstanding;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
} // end DebtorTotalOutstandingHandle{}

export enum ReportName {
  AdvocateSummary = 'advocateSummary',
  AdvocateDetail = 'advocateDetail',
  AgeAnalysis = 'ageAnalysis',
  PracticeAllCustomer = 'practiceAllCustomer',
  PracticeByLawfirm = 'practiceByLawfirm'
} // end ReportName{}

export enum ReportType {
  BusinessReports = 'businessReports',
  FinancialReports = 'financialReports',
  LiveStatement = 'liveStatement',
  PracticeManagementReports = 'practiceManagementReports',
  IndustryReports = 'industryReport',
  MyAdvocates = 'myAdvocates'
} // end ReportType{}

export interface ClientSubscriptionSummary {
  AuxconProfileId: number;
  ClientCount: number;
  MonthlySiteAmount: number;
  ReconciliationAmount: number;
  InvoicingAmount: number;
  ExpenseAmount: number;
  CollectionsAmount: number;
  CollectionsClientCount: number;
  ExpensesClientCount: number;
  InvoicingClientCount: number;
  ReconClientCount: number;
  SiteRentalClientCount: number;
}

export const DEFAULT_SUBSCRIPTION_SUMMARY: ClientSubscriptionSummary = {
  AuxconProfileId: 0,
  ClientCount: 0,
  MonthlySiteAmount: 0,
  ReconciliationAmount: 0,
  InvoicingAmount: 0,
  ExpenseAmount: 0,
  CollectionsAmount: 0,
  CollectionsClientCount: 0,
  ExpensesClientCount: 0,
  InvoicingClientCount: 0,
  ReconClientCount: 0,
  SiteRentalClientCount: 0
};

export interface ClientSubscriptionDetail {
  AuxconProfileId: number;
  AdvocateId: number;
  AdvocateName: string;
  ChargedAmount: number;
  TotalOwingAuxcon: number;
  InvoicedAmount: number;
}

@Injectable()
export class PracticeAdvocateHealthService {
  reportSortType: string;
  allCustomerActiveTab: string;
  selectedReportType: string;
  selectedReportName: string;

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { } // end constructor()

  getAdvocateHealthData(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceAdvocateHealth +
      '?practiceManagerId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getAdvocateHealthData()

  updateLastMeetingDate(advocateDetails: AdvocateHealth): Observable<any> {
    return this.http.put(this.api.endpoints.UpdateLastMeetingDate, advocateDetails, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getAdvocateHealthData()

  getAdvocatePracticeSummary(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceAdvocateSummary +
      '?practiceManagerId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(catchError(this.api.handleError));
  } // end getAdvocatePracticeSummary()

  getPracticeAgeAnalysis(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceAgeAnalysis +
      '?practiceManagerId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(catchError(this.api.handleError));
  } // end getPracticeAgeAnalysis()

  getPracticeAllCustomer(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceAllCustomer +
      '?practiceManagerId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(catchError(this.api.handleError));
  } // end getPracticeAllCustomer()

  getPracticeByCustomer(serviceProviderId: number): Observable<any> {
    return this.http.get(this.api.endpoints.practiceByCustomer +
      '?practiceManagerId=' + this.api.serviceProviderID + '&ServiceProviderID=' + serviceProviderId,
      this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getPracticeByCustomer()

  getPracticeByLawfirmOverdue(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceByLawfirm +
      '?practiceManagerId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(catchError(this.api.handleError));
  } // end getPracticeByLawfirmOverdue()

  getPracticeLawfirmDetailOverdue(lawfirmId: number): Observable<any> {
    return this.http.get(this.api.endpoints.practiceLawfirmDetail +
      '?practiceManagerId=' + this.api.serviceProviderID + '&LawfirmId=' + lawfirmId
      , this.api.httpOptions)
      .pipe(catchError(this.api.handleError));
  } // end getPracticeLawfirmDetailOverdue()

  getOtherPracticeOverdueSummaryFirm(lawfirmId: number): Observable<any> {
    return this.http.get(this.api.endpoints.practiceLawfirmDetailSummary +
      '?practiceManagerId=' + this.api.serviceProviderID + '&LawfirmID=' + lawfirmId
      , this.api.httpOptions)
      .pipe(catchError(this.api.handleError));
  } // end getOtherPracticeOverdueSummaryFirm()

  getSubscriptionSummary(): Observable<any> {
    return this.http.get(this.api.endpoints.subscriptionSummary +
      '?auxconId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getSubscriptionSummary()

  getSubscriptionDetail(option: string): Observable<any> {
    return this.http.get(this.api.endpoints.subscriptionDetail +
      '?auxconId=' + this.api.serviceProviderID + '&option=' + option,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getSubscriptionDetail()

  getByIndividualInvoicesCustomer(clientId: number, advocateId): Observable<any> {
    return this.http.get(this.api.endpoints.byIndividualCustomer +
      '?serviceProviderId=' + advocateId + '&clientId=' + clientId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end getByIndividualInvoicesCustomer()

  updateSelected(data: AdvocateHealth): Observable<any> {
    return this.http.put(this.api.endpoints.practiceUpdateSelected,
      data, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  } // end updateSelected {}
} // end PraciceAdvocateHealthService{}
