<div class="modal" *ngIf="isVisible">
  <div class="modal-content" style="margin: 8.75rem auto auto !important;">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss(true)">
          <i class="fa fa-close"></i>
        </button>
      </div><!--#end .modal-header -->

      <div class="modal-body form">
        <nav class="navbar-tabs" *ngIf="showLawFirmTab">
          <button class="tab" [class.active]="tabView === 'add-advocate'" (click)="changeTabView('add-advocate')">Add
            Advocate</button>
        </nav>
        <!-- tab -->
        <!-- Bar society -->
        <div class="form-group">
          <label>Regional Bar<span class="text-danger"> *</span></label>
          <div *ngIf="!isGCBUserBar" class="input-group" style="margin-bottom: 10px;">
            <input class="form-control" disabled type="text" [(ngModel)]="CurrentRegionalBar" autocomplete="off"
              typeaheadOptionField="FullName" />
          </div>
          <div class="input-group" *ngIf="isGCBUserBar" style="margin-bottom: 10px;">
            <input class="form-control" [(ngModel)]="RegionalBarName" [typeahead]="regionalBars"
              regionalBarName="regionalBarName" typeaheadOptionField="FullName" [typeaheadSingleWords]="false"
              class="categotyText" class="form-control" (blur)="onBlurRegionalBar($event)"
              (input)="onInputAdvocate($event)" placeholder="Select or start typing"
              (typeaheadOnSelect)="onSelectRegionalBar($event)" [typeaheadOptionsLimit]="1000" typeaheadScrollable=true
              typeaheadOptionsInScrollableView="10" [typeaheadMinLength]="0" type="regionalBars"
              [regionalBars]="regionalBars" [(selectedRegionalBar)]="selectedRegionalBar"
              (select)="onChangeRegionalBarModel('selectedRegionalBar', $event)" />
          </div>
          <span *ngIf="isValidRegionalBarName" class="text-danger">Please select a regional bar.</span>
        </div>

        <div class="form-group">
          <label>Advocate name<span class="text-danger"> *</span></label>
          <div class="input-group" style="margin-bottom: 10px;">
            <input class="form-control" [(ngModel)]="ExstAdvocateFullName" [typeahead]="exsistingAdvocateData"
              typeaheadOptionField="FullName" [typeaheadSingleWords]="false" (input)="onInputAdvocate($event)"
              (blur)="onBlurAdvocateName()" class="categotyText" class="form-control"
              placeholder="Select or start typing" (typeaheadOnSelect)="onSelectAdvocate($event)"
              [typeaheadOptionsLimit]="1000" typeaheadScrollable=true typeaheadOptionsInScrollableView="15"
              [typeaheadMinLength]="0" type="exsistingAdvocateData" [exsistingAdvocateData]="exsistingAdvocateData"
              [(selectedAdvocate)]="selectedAdvocate" (select)="onModelChange('exsistingAdvocateData', $event)"
              [class.has-error]="isValidAdvocateName" />
          </div>
          <span *ngIf="isValidAdvocateName" class="text-danger">Please enter a valid Advocate name.</span>
        </div>

        <!-- Phone number -->
        <div class="form-group pt-2" *ngIf="onSelectExstField">
          <label for="phone-number">Phone number<span title="Required field"> *</span></label>
          <input type="text" [id]="phone-number" (focus)="onFocusAdvPhoneNumber()" (blur)="onBlurAdvPhoneNumber()"
            class="form-control" [class.has-error]="isValidAdvocatePhoneNumber" [(ngModel)]="phoneNumber"
            placeholder="Advocate cell phone number" />
          <span *ngIf="isValidAdvocatePhoneNumber" class="text-danger">Please enter a valid phone number.</span>
        </div>

        <!-- Email Address -->
        <div class="form-group" *ngIf="onSelectExstField">
          <label for="email">Email<span title="Required field"> *</span></label>
          <input type="text" class="form-control" (focus)="onFocusAdvEmail()" (blur)="onBlurAdvEmail()"
            [class.has-error]="isValidAdvocateEmail" [id]="email" [(ngModel)]="email"
            placeholder="Advocate email address" />
          <span *ngIf="isValidAdvocateEmail" class="text-danger">Please enter a valid email address.</span>
        </div>

        <!-- cell -->
        <div class="form-group" *ngIf="onSelectExstField">
          <label for="cell">Cell<span title="Required field"> *</span></label>
          <input type="text" class="form-control" id="cell" [class.has-error]="isValidAdvocateCell" [(ngModel)]="cell"
            placeholder="Enter Cell number" (focus)="onFocusAdvCell()" (blur)="onBlurAdvCell()" />
          <span *ngIf="isValidAdvocateCell" class="text-danger">Please enter a valid cell number.</span>
        </div>

        <div class="form-group row col-md-12 m-0">
          <!-- admission Day -->
          <div class="form-group col-md-4 pt-2 pl-0" *ngIf="onSelectExstField">
            <label>Admission Day</label>
            <div class="input-group">
              <select name="ddlAdmissionDay" id="admissionDay" class="input-date"
                [(ngModel)]="checkAdmissionDay" (change)="onChangeAdmissionDay()">
                <option value="0" selected="selected">Select Day</option>
                <option *ngFor="let day of admissionDay" value="{{day}}">{{day}}</option>
              </select>
            </div>
          </div>

          <!-- admission Month-->
          <div class="form-group col-md-4 pt-2" *ngIf="onSelectExstField">
            <label>Admission Month</label>
            <div class="input-group">
              <select name="fromMonth" id="fromMonth" class="input-date" [(ngModel)]="checkAdmissionMonth"
              (change)="onChangeAdmissionMonth()">
                <option value="0" selected="selected">Select Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              <span *ngIf="isValidAdmissionMonth" class="text-danger">Select month</span>
            </div>
          </div>
            <!-- <input class="form-control input-date" name="date" style="margin: 0 !important;" type="text" id="date-from"
              #_dateFrom="bsDatepicker" bsDatepicker [bsValue]="bsValue" [maxDate]="bsValue" [(ngModel)]="admissionDate"
              [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}" placement="right"
              (bsValueChange)="onDateValueChange($event)" autocomplete="off">
            <div class="input-group-append" (click)="_dateFrom.toggle()" [attr.aria-expanded]="_dateFrom.isOpen">
              <span class="input-group-text"><i class="fa fa-calendar"></i></span>
            </div> -->
          <!-- admission Year -->
          <div class="form-group col-md-4 pt-2 pr-0 m-0" *ngIf="onSelectExstField">
            <label>Admission Year</label>
            <div class="input-group">
              <select name="admissionYear" id="admissionYear" class="input-date"
                [(ngModel)]="checkAdmissionYear" (change)="onChangeAdmissionYear()">
                <option value="0" selected="selected">Select Year</option>
                <option *ngFor="let year of admissionYear" value="{{year.year}}">{{year.year}}</option>
              </select>
              <span *ngIf="isValidAdmissionYear" class="text-danger">Select year</span>
            </div>
          </div>

        </div>


        <!-- </div> -->
      </div>
      <!--#end .modal-body -->

      <div class="modal-footer" *ngIf="!isLinkBtnEnable">
        <button class="btn btn-success" [class.disabled]="!isSubmittable" (click)="sendRequest()"
          [disabled]="!isSubmittable">Send Request</button>
      </div><!-- #end .modal-footer -->
      <div class="modal-footer" *ngIf="isLinkBtnEnable">
        <button class="btn btn-success" (click)="LinkExstAdvocate()">Link</button>
      </div><!-- #end .modal-footer -->

    </div><!--#end .modal-dialog -->
  </div><!--#end .modal-content -->
</div><!--#end .modal -->

<app-prompt-unsaved-changes-modal message="Are you sure you want to leave this screen? You have unsaved changes."
  [isVisible]="showUnsavedChangesPrompt" (cancel)="onHideUnsavedChangesPrompt()"
  (continue)="onDismissPromptUnsavedChangesModal()"></app-prompt-unsaved-changes-modal>
<!--#end app-prompt-unsaved-changes-modal -->