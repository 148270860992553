import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdvocateDetailStatementService,
  AdvocateDetailStatement,
  ICrumb,
  LawfirmPaidStatement} from '../../../services/advocate-detail-statement/advocate-detail-statement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { SnackbarsService, Snack, SnackType } from '../../../services/messaging/snackbars/snackbars.service';
import { PATH_ANALYTICS, PATH_REPORTS, PATH_ATTORNEY_DASHBOARD } from '../../../services/appdata/app-config.service';
import { ReportsService, AllocatedToLawfirm } from '../../../services/reports/reports.service';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';

@Component({
  selector: 'app-unpaid-invoices',
  templateUrl: './unpaid-invoices.component.html',
  styleUrls: ['./unpaid-invoices.component.scss']
})
export class UnpaidInvoicesComponent implements OnInit, OnDestroy {

  title: string;
  private lawfirmId: number;
  advocateDetailStatement: AdvocateDetailStatement[];
  lawfirmPaidStatement: LawfirmPaidStatement[] = [];
  allocatedToLawFirm: AllocatedToLawfirm[];
  crumbs: ICrumb[];
  showReportTable: boolean;
  generateButton = 'Generate .xlsx Statement';

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    LAWFIRM: 'Lawfirm'
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private snackbarService: SnackbarsService,
    private advocateDetailStatementService: AdvocateDetailStatementService,
    private reportService: ReportsService,
    private userProfileService: UserProfileService
  ) { }

  ngOnInit() {
    if (this.advocateDetailStatementService.activeTab) {
      this.title = this.advocateDetailStatementService.activeTab;
    }

    this.loadingService.showOverlay();
    this.lawfirmId = +this.route.snapshot.paramMap.get('id');
      this.crumbs = [
        {
          label: 'Live Statement',
          link: PATH_ATTORNEY_DASHBOARD
        }
      ];

    const snack: Snack = {
      label: 'loading Unpaid invoices...',
      action: null
    };
    this.snackbarService.snackbarComponent.make(snack, 5000).show();
    this.getAllocatedToFirmData();

    this.advocateDetailStatement = JSON.parse(sessionStorage.getItem('advocateDetailStatement'));
    if (this.advocateDetailStatement && this.advocateDetailStatement.length > 0) {

    } else {
      // this.advocateDetailStatement =  this.advocateDetailStatementService.getLawfirmStatement(this.lawfirmId).toPromise();
      sessionStorage.setItem('advocateDetailStatement', JSON.stringify(this.advocateDetailStatement));
    }

    // this.advocateDetailStatementService.getLawfirmStatement(this.lawfirmId)
    // .subscribe(lawfirm_details => {
    //   this.advocateDetailStatement = lawfirm_details;
    //   this.advocateDetailStatementService.lawfirmId = this.lawfirmId;

      this.crumbs.push({
        label: 'Unpaid invoices'
      });

      // this.loadingService.hideOverlay();
    // },
    // error => {
      // On error
    //   snack.label = 'Error loading unpaid invoices.';
    //   snack.type = SnackType.ERROR;
    //   this.snackbarService.dismiss().make(snack).show();
    //   this.loadingService.hideOverlay();
    // },
    // () => {
      // On complete
      this.loadingService.hideOverlay();
    // });

    // this.advocateDetailStatementService.getPaidStatement().subscribe(paidData => {
    //   this.lawfirmPaidStatement = paidData;
    // });
    // Bank transaction and paid invoices
    this.lawfirmPaidStatement = JSON.parse(localStorage.getItem('lawfirmPaidStatement'));
    if (this.lawfirmPaidStatement && this.lawfirmPaidStatement.length > 0) {

    } else {
      // this.lawfirmPaidStatement =  this.advocateDetailStatementService.getPaidStatement().toPromise();
      localStorage.setItem('lawfirmPaidStatement', JSON.stringify(this.lawfirmPaidStatement));
    }
  } // end ngOnInit()

  getAllocatedToFirmData() {
    this.advocateDetailStatementService.getAllocatedToFirm_LiveStatement().subscribe({ next: (data) => {
      // On next
      this.allocatedToLawFirm = data;
    }});
  } // end getAllocatedToFirmData()

  ngOnDestroy() {
    this.reportService.selectedReport = 'liveStatement';
  }
} // end UnpaidInvoicesComponent()
