import { Injectable } from '@angular/core';
import {
  NumberFormatStyle,
  getLocaleNumberFormat
} from '@angular/common';
import { Observable ,  of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import {
  CHART_COLOR_BLUE,
  CHART_COLOR_GREEN,
  CHART_COLOR_GREY,
  CHART_COLOR_RED,
  CHART_COLOR_WHITE,
  CHART_COLOR_YELLOW
} from '../appdata/app-config.service';
import { ApiService } from '../api.service';
import { catchError } from 'rxjs/operators';
import { SnackbarsService, Snack, SnackType } from '../messaging/snackbars/snackbars.service';
import { UtilitiesService } from '../utilities.service';

const DEFAULT_MIN_TICK_VALUE = 0;
const DEFAULT_MAX_TICK_VALUE = 30000000;
const CHART_BAR_THICKNESS = 25;
const CHART_BAR_PERCENTAGE = 25;
const BASE_FONT_COLOR = '#4a4a4a';

export interface DashboardData {
  serviceProviderId: number;
  serviceProviderName: string;
  collectedMonthlyTarget: string;
  invoicedMonthlyTarget: string;
  collectedYearlyTarget: string;
  collectedMonthlyRecord: string;
  invoicedMonthlyRecord: string;
  collectedYearlyRecord: string;
  invoicedYearlyRecord: string;
  workMaxValue: number;
  workOptimalRangeStartValue: number;
  workOptimalRangeEndValue: number;
  workSummary: string;
  workScore: number;
  invoicedScore: number;
  collectedScore: number;
  symbolValue: string;
  invoicedMaxValue: number;
  invoicedOptimalRangeStartValue: number;
  invoicedOptimalRangeEndValue: number;
  thisMonthInvoicedSummary: string;
  lastMonthInvoicedSummary: string;
  last2MonthInvoicedSummary: string;
  thisYearInvoicedSummary: string;
  lastYearInvoicedSummary: string;
  last2YearInvoicedSummary: string;
  collectedMaxValue: number;
  collectedOptimalRangeStartValue: number;
  collectedOptimalRangeEndValue: number;
  thisMonthCollectedSummary: string;
  lastMonthCollectedSummary: string;
  last2MonthCollectedSummary: string;
  thisYearCollectedSummary: string;
  lastYearCollectedSummary: string;
  last2YearCollectedSummary: string;
  workInProgressDescription: string;
  invoicedDescription: string;
  receiptsDescription: string;
  thisMonthDescription: string;
  lastMonthDescription: string;
  last2MonthDescription: string;
  thisFinancialYearDescription: string;
  lastFinancialYearDescription: string;
  last2FinancialYearDescription: string;
  thisMonthCurrentMatterTotalExcl: string;
  lastMonthCurrentMatterTotalExcl: string;
  last2MonthCurrentMatterTotalExcl: string;
  thisYearCurrentMatterTotalExcl: string;
  lastYearCurrentMatterTotalExcl: string;
  last2YearCurrentMatterTotalExcl: string;
  yearlyWorkStartValue: string;
  yearlyWorkEndValue: string;
  yearlyWorkMaxValue: string;
  yearlyCollectedStartValue: string;
  yearlyCollectedEndValue: string;
  yearlyCollectedMaxValue: string;
}

export const DEFAULT_DASHBOARD_DATA = {
  serviceProviderId: 0,
  serviceProviderName: '',
  collectedMonthlyTarget: '',
  invoicedMonthlyTarget: '',
  collectedYearlyTarget: '',
  collectedMonthlyRecord: '',
  invoicedMonthlyRecord: '',
  collectedYearlyRecord: '',
  invoicedYearlyRecord: '',
  workMaxValue: 0,
  workOptimalRangeStartValue: 0,
  workOptimalRangeEndValue: 0,
  workSummary: '',
  workScore: 0,
  invoicedScore: 0,
  collectedScore: 0,
  symbolValue: '',
  invoicedMaxValue: 0,
  invoicedOptimalRangeStartValue: 0,
  invoicedOptimalRangeEndValue: 0,
  thisMonthInvoicedSummary: '',
  lastMonthInvoicedSummary: '',
  last2MonthInvoicedSummary: '',
  thisYearInvoicedSummary: '',
  lastYearInvoicedSummary: '',
  last2YearInvoicedSummary: '',
  collectedMaxValue: 0,
  collectedOptimalRangeStartValue: 0,
  collectedOptimalRangeEndValue: 0,
  thisMonthCollectedSummary: '',
  lastMonthCollectedSummary: '',
  last2MonthCollectedSummary: '',
  thisYearCollectedSummary: '',
  lastYearCollectedSummary: '',
  last2YearCollectedSummary: '',
  workInProgressDescription: '',
  invoicedDescription: '',
  receiptsDescription: '',
  thisMonthDescription: '',
  lastMonthDescription: '',
  last2MonthDescription: '',
  thisFinancialYearDescription: '',
  lastFinancialYearDescription: '',
  last2FinancialYearDescription: '',
  thisMonthCurrentMatterTotalExcl: '',
  lastMonthCurrentMatterTotalExcl: '',
  last2MonthCurrentMatterTotalExcl: '',
  thisYearCurrentMatterTotalExcl: '',
  lastYearCurrentMatterTotalExcl: '',
  last2YearCurrentMatterTotalExcl: '',
  yearlyWorkStartValue: '',
  yearlyWorkEndValue: '',
  yearlyWorkMaxValue: '',
  yearlyCollectedStartValue: '',
  yearlyCollectedEndValue: '',
  yearlyCollectedMaxValue: ''
};

@Injectable()
export class DashboardDataService {

  currentMattersData: any = {
    monthly: {
      currentTotalLabel: '',
      currentThismonthLabel: '',
      currentLastMonthLabel: '',
      currentlast2MonthLast: '',

      currentTotalValue: 0,
      currentThismonthValue: 0,
      currentLastMonthValue: 0,
      currentMonthBeforValue: 0,

      currentRangeStart: 0,
      currentRangeEnd: 0,
      currentMaxValue: 0,
      currentScore: 0
    },
    yearly: {
      thisYearcurrentLabel: '',
      lastYearcurrentLabel: '',
      last2YearscurrentLabel: '',

      thisYearcurrentTotalValue: 0,
      lastYearcurrentValue: 0,
      last2YearscurrentValue: 0,

      thisYearcurrentRangeStart: 0,
      thisYearcurrentRangeEnd: 0,
      thisYearcurrentMaxValue: 0,
      thisYearcurrentScore: 0
    }
  };
  invoicedData: any = {
    monthly: {
      invoicedThismonthLabel: '',
      invoicedLastMonthLabel: '',
      invoicedMonthBeforeLast: '',

      invoicedTotalValue: 0,
      invoicedThismonthValue: 0,
      invoicedLastMonthValue: 0,
      invoicedMonthBeforValue: 0,

      invoicedRangeStart: 0,
      invoicedRangeEnd: 0,
      invoicedMaxValue: 0,
      invoicedScore: 0,
      invoicedMonthlyTarget: '',
      invoicedMonthlyRecord: ''
    },
    yearly: {
      thisYearInvoicedLabel: '',
      lastYearInvoicedLabel: '',
      last2YearsInvoicedLabel: '',

      thisYearInvoicedTotalValue: 0,
      lastYearInvoicedValue: 0,
      last2YearsInvoicedValue: 0,

      thisYearInvoicedRangeStart: 0,
      thisYearInvoicedRangeEnd: 0,
      thisYearInvoicedMaxValue: 0,
      thisYearInvoicedScore: 0,
      InvoicedYearlyTarget: '',
      InvoicedYearlyRecord: ''
    }
  };
  collectedData: any = {
    monthly: {
      receiptThismonthLabel: '',
      receiptLastMonthLabel: '',
      receiptMonthBeforeLast: '',

      receiptTotalValue: 0,
      receiptThismonthValue: 0,
      receiptLastMonthValue: 0,
      receiptMonthBeforValue: 0,

      receiptRangeStart: 0,
      receiptRangeEnd: 0,
      receiptMaxValue: 0,
      receiptScore: 0,
      receiptMonthlyTarget: '',
      receiptMonthlyRecord: ''
    },
    yearly: {
      receiptThisYearLabel: '',
      receiptLastYearLabel: '',
      receiptYearBeforeLast: '',

      receiptTotalYearValue: 0,
      receiptThisYearValue: 0,
      receiptLastYearValue: 0,
      receiptYearBeforValue: 0,

      receiptYearRangeStart: 0,
      receiptYearRangeEnd: 0,
      receiptYearMaxValue: 0,
      receiptYearScore: 0,
      receiptYearlyTarget: '',
      receiptYearlyRecord: '',
    }
  };

  dashboardData: DashboardData = this.util.objectCopy(DEFAULT_DASHBOARD_DATA);

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private snackbarsService: SnackbarsService,
    private util: UtilitiesService
  ) {
    // Since dashboard data can offered to be slightly out of date,
    // we employ eventual consistency by caching the latest data in the local
    // storeage for faster loading.
    // if (localStorage) {
    //   const dashboardData = localStorage.getItem('dashboardData');
    //   if (dashboardData) {
    //     this.dashboardData = JSON.parse(dashboardData);

    //     for (let x in this.dashboardData) {
    //       if (this.dashboardData.hasOwnProperty(x) &&
    //       typeof this.dashboardData[x] === typeof null) {
    //         this.dashboardData[x] = '';
    //       }
    //     }
    //   } else {
    //     const snack: Snack = {
    //       label: 'Initializing Dashboard data...',
    //       action: null
    //     };
    //     this.snackbarsService.make(snack, 5000).show();

    //     this.getDashboardData().subscribe(
    //       dashboard => {
    //         localStorage.setItem('dashboardData', JSON.stringify(dashboard));
    //         // const msg = 'Dashboard data loaded.';
    //         // snack.label = msg;
    //         // this.snackbarsService.dismiss().make(snack, 5000).show();
    //       },
    //       error => {
    //         const msg = 'Error loading dashboard data.';
    //         console.error(msg, error);
    //         // snack.label = msg;
    //         // snack.type = SnackType.ERROR;
    //         // this.snackbarsService.dismiss().make(snack, 5000).show();
    //       },
    //       () => { }
    //     );
    //   }
    // }
  } // end constructor()

  getDashboardData(): Observable<any> {
    return this.http.get(this.api.endpoints.dashboardData +
      '/' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getCurrentMattersData(dashboardData: DashboardData): Observable<any> {
    const currentMattersValues = {
      monthly: [
        dashboardData
          .thisMonthCurrentMatterTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        dashboardData
          .lastMonthCurrentMatterTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        dashboardData
          .last2MonthCurrentMatterTotalExcl
          .replace(/[A-Z\s,]/gi, '')
      ],
      yearly: [
        dashboardData
          .thisYearCurrentMatterTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        dashboardData
          .lastYearCurrentMatterTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        dashboardData
          .last2YearCurrentMatterTotalExcl
          .replace(/[A-Z\s,]/gi, '')
      ]
    };
    const currentMattersColors = {
      monthly: this.getBarChartColors(currentMattersValues.monthly),
      yearly: this.getBarChartColors(currentMattersValues.yearly)
    };
    this.currentMattersData = {
      monthly: {
        currentTotalLabel: 'Total',
        currentThismonthLabel: dashboardData.thisMonthDescription,
        currentLastMonthLabel: dashboardData.lastMonthDescription,
        currentlast2MonthLast: dashboardData.last2MonthDescription,

        currentTotalValue: dashboardData.workScore,
        currentThismonthValue: dashboardData
          .thisMonthCurrentMatterTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        currentLastMonthValue: dashboardData
          .lastMonthCurrentMatterTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        currentMonthBeforValue: dashboardData
          .last2MonthCurrentMatterTotalExcl
          .replace(/[A-Z\s,]/gi, ''),

        currentRangeStart: dashboardData.workOptimalRangeStartValue,
        currentRangeEnd: dashboardData.workOptimalRangeEndValue,
        currentMaxValue: dashboardData.workMaxValue,
        currentScore: dashboardData.workScore
      },
      yearly: {
        thisYearcurrentLabel: dashboardData.thisFinancialYearDescription,
        lastYearcurrentLabel: dashboardData.lastFinancialYearDescription,
        last2YearscurrentLabel: dashboardData.last2FinancialYearDescription,

        thisYearcurrentTotalValue: dashboardData
          .thisYearCurrentMatterTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        lastYearcurrentValue: dashboardData
          .lastYearCurrentMatterTotalExcl
          .replace(/[A-Z\s,]/gi, ''),
        last2YearscurrentValue: dashboardData
          .last2YearCurrentMatterTotalExcl
          .replace(/[A-Z\s,]/gi, ''),

        thisYearcurrentRangeStart: dashboardData.yearlyWorkStartValue,
        thisYearcurrentRangeEnd: dashboardData.yearlyWorkEndValue,
        thisYearcurrentMaxValue: dashboardData.yearlyWorkMaxValue,
        // thisYearcurrentScore: dashboardData.workScore
      }
    };
    return of(this.currentMattersData);
  } // end getCurrentMattersData()

  getInvoicedData(dashboardData: DashboardData): Observable<any> {
    const invoicedValues = {
      monthly: [
        dashboardData.thisMonthInvoicedSummary.replace(/[A-Z\s,]/gi, ''),
        dashboardData.lastMonthInvoicedSummary.replace(/[A-Z\s,]/gi, ''),
        dashboardData.last2MonthInvoicedSummary.replace(/[A-Z\s,]/gi, '')
      ],
      yearly: [
        dashboardData.thisYearInvoicedSummary.replace(/[A-Z\s,]/gi, ''),
        dashboardData.lastYearInvoicedSummary.replace(/[A-Z\s,]/gi, ''),
        dashboardData.last2YearInvoicedSummary.replace(/[A-Z\s,]/gi, '')
      ]
    };

    const invoicedMonthlyTarget =
      dashboardData.invoicedMonthlyTarget.replace(/[A-Z\s,]/gi, '');
    const invoicedMonthlyRecord =
      dashboardData.invoicedMonthlyRecord.replace(/[A-Z\s,]/gi, '');

    const invoicedYearlyRecord =
      dashboardData.invoicedYearlyRecord.replace(/[A-Z\s,]/gi, '');

    const invoicedColors = {
      monthly: this.getBarChartColors(invoicedValues.monthly,
        invoicedMonthlyRecord),
      yearly: this.getBarChartColors(invoicedValues.yearly,
        invoicedYearlyRecord)
    };
    this.invoicedData = {
      monthly: {
        invoicedThismonthLabel: dashboardData.thisMonthDescription,
        invoicedLastMonthLabel: dashboardData.lastMonthDescription,
        invoicedMonthBeforeLast: dashboardData.last2MonthDescription,

        invoicedTotalValue: dashboardData.workScore,
        invoicedThismonthValue: dashboardData.thisMonthInvoicedSummary.replace(/[A-Z\s,]/gi, ''),
        invoicedLastMonthValue: dashboardData.lastMonthInvoicedSummary.replace(/[A-Z\s,]/gi, ''),
        invoicedMonthBeforValue: dashboardData.last2MonthInvoicedSummary.replace(/[A-Z\s,]/gi, ''),

        invoicedRangeStart: dashboardData.workOptimalRangeStartValue,
        invoicedRangeEnd: dashboardData.workOptimalRangeEndValue,
        invoicedMaxValue: dashboardData.workMaxValue,
        invoicedScore: dashboardData.invoicedScore,
        invoicedMonthlyTarget: dashboardData.invoicedMonthlyTarget,
        invoicedMonthlyRecord: dashboardData.invoicedMonthlyRecord
      },
      yearly: {
        thisYearInvoicedLabel: dashboardData.thisFinancialYearDescription,
        lastYearInvoicedLabel: dashboardData.lastFinancialYearDescription,
        last2YearsInvoicedLabel: dashboardData.last2FinancialYearDescription,

        thisYearInvoicedTotalValue: dashboardData
          .thisYearInvoicedSummary
          .replace(/[A-Z\s,]/gi, ''),
        lastYearInvoicedValue: dashboardData
          .lastYearInvoicedSummary
          .replace(/[A-Z\s,]/gi, ''),
        last2YearsInvoicedValue: dashboardData
          .last2YearInvoicedSummary
          .replace(/[A-Z\s,]/gi, ''),

        thisYearInvoicedRangeStart: dashboardData.yearlyWorkStartValue,
        thisYearInvoicedRangeEnd: dashboardData.yearlyWorkEndValue,
        thisYearInvoicedMaxValue: dashboardData.yearlyWorkMaxValue,
        InvoicedYearlyTarget: dashboardData.invoicedMonthlyTarget,
        InvoicedYearlyRecord: dashboardData.invoicedYearlyRecord
        // thisYearcurrentScore: dashboardData.workScore
      }
    };
    return of(this.invoicedData);
  } // end getInvoicedData()

  getCollectedData(dashboardData: DashboardData): Observable<any> {
    const collectedValues = {
      monthly: [
        dashboardData.thisMonthCollectedSummary.replace(/[A-Z\s,]/gi, ''),
        dashboardData.lastMonthCollectedSummary.replace(/[A-Z\s,]/gi, ''),
        dashboardData.last2MonthCollectedSummary.replace(/[A-Z\s,]/gi, '')
      ],
      yearly: [
        dashboardData.thisYearCollectedSummary.replace(/[A-Z\s,]/gi, ''),
        dashboardData.lastYearCollectedSummary.replace(/[A-Z\s,]/gi, ''),
        dashboardData.last2YearCollectedSummary.replace(/[A-Z\s,]/gi, '')
      ]
    };

    const collectedMonthlyTarget =
      dashboardData.collectedMonthlyTarget.replace(/[A-Z\s,]/gi, '');
    const collectedMonthlyRecord =
      dashboardData.collectedMonthlyRecord.replace(/[A-Z\s,]/gi, '');

    const collectedYearlyRecord =
      dashboardData.collectedYearlyRecord.replace(/[A-Z\s,]/gi, '');

    const collectedColors = {
      monthly: this.getBarChartColors(collectedValues.monthly,
        collectedMonthlyRecord),
      yearly: this.getBarChartColors(collectedValues.yearly,
        collectedYearlyRecord)
    };

    this.collectedData = {
      monthly: {
        receiptThismonthLabel: dashboardData.thisMonthDescription,
        receiptLastMonthLabel: dashboardData.lastMonthDescription,
        receiptMonthBeforeLast: dashboardData.last2MonthDescription,

        receiptTotalValue: dashboardData.collectedScore,
        receiptThismonthValue: dashboardData.thisMonthCollectedSummary.replace(/[A-Z\s,]/gi, ''),
        receiptLastMonthValue: dashboardData.lastMonthCollectedSummary.replace(/[A-Z\s,]/gi, ''),
        receiptMonthBeforValue: dashboardData.last2MonthCollectedSummary.replace(/[A-Z\s,]/gi, ''),

        receiptRangeStart: dashboardData.collectedOptimalRangeStartValue,
        receiptRangeEnd: dashboardData.collectedOptimalRangeEndValue,
        receiptMaxValue: dashboardData.collectedMaxValue,
        receiptScore: dashboardData.collectedScore,
        receiptMonthlyTarget: dashboardData.collectedMonthlyTarget,
        receiptMonthlyRecord: dashboardData.collectedMonthlyRecord
      },
      yearly: {
        receiptThisYearLabel: dashboardData.thisFinancialYearDescription,
        receiptLastYearLabel: dashboardData.lastFinancialYearDescription,
        receiptYearBeforeLast: dashboardData.last2FinancialYearDescription,

        // receiptTotalYearValue: dashboardData.collectedScore,
        receiptThisYearValue: dashboardData.thisYearCollectedSummary.replace(/[A-Z\s,]/gi, ''),
        receiptLastYearValue: dashboardData.lastYearCollectedSummary.replace(/[A-Z\s,]/gi, ''),
        receiptYearBeforValue: dashboardData.last2YearCollectedSummary.replace(/[A-Z\s,]/gi, ''),

        receiptYearRangeStart: dashboardData.yearlyCollectedStartValue,
        receiptYearRangeEnd: dashboardData.yearlyCollectedEndValue,
        receiptYearMaxValue: dashboardData.yearlyCollectedMaxValue,
        // receiptYearScore: dashboardData.collectedScore,
        receiptYearlyTarget: dashboardData.collectedYearlyTarget,
        receiptYearlyRecord: dashboardData.collectedYearlyRecord
      }
    };
    return of(this.collectedData);
  } // end getCollectedData()

  getData(): Observable<any> {
    return of(this.dashboardData);
  } // end getData()

  formatChartCurrency(label, index, labels) {
    const value: NumberFormatStyle = label;
    return 'P ' + (value / 1000) + 'k';
  } // end formatCurrency()

  formatChartDataLabel(label, index, labels) {
    const num =
      Number.parseFloat(label)
        .toLocaleString('en-US', {
          minimumFractionDigits: 2
        });
    return ('P' + num.replace(/,/gi, ' '));
    // return (num);
  } // end formatChartDataLabel()

  /**
   * Returns an array of HEX color values based on values of the given array.
   * The colors assigned are either, GREEN, BLUE or RED where:
   * - GREEN: Values that match or surpass a calculated threshold.
   * - YELLOW: Values that fall slightly below a calculated threshold.
   * - RED: Values that fall greatly below a calculated threshold.
   * @param chartValues An array of chart values.
   * @param targetValue The target value to be used in color calculations.
   */
  getBarChartColors(
    chartValues: Array<any>,
    targetValue: any = null
  ): Array<string> {
    const barChartColors = [];

    const threshold = targetValue
      ? Math.round(targetValue as number)
      : Math.round(Math.max(...chartValues as number[]));

    chartValues.forEach(value => {
      const roundedValue = Math.round(value);

      if (roundedValue >= threshold) {
        barChartColors.push(CHART_COLOR_GREEN);

      } else if (roundedValue < threshold &&
        roundedValue >= (1 / 2) * threshold) {
        barChartColors.push(CHART_COLOR_YELLOW);

      } else if (roundedValue < (1 / 2) * threshold) {
        barChartColors.push(CHART_COLOR_RED);

      }
    });

    return barChartColors;
  } // end getBarChartColors()

  /**
   * Returns the number of ticks to render for a chart based on the
   * given chart values. This function returns tick values that will
   * produce the most even spacing between ticks.
   * @param {Array<*>} chartValues An array of chart values.
   * @return {number} Returns the number of ticks to render for a chart.
   */
  getMaxTicks(chartValues: Array<number>, maxValue: number = null): number {
    let maxTick = DEFAULT_MAX_TICK_VALUE;
    if (!maxValue) {
      maxValue = Math.max(...chartValues);
    }
    const baseTickValue = DEFAULT_MAX_TICK_VALUE;

    if (maxValue < (baseTickValue / 8)) {
      maxTick = (baseTickValue / 8);
    } else if (maxValue < (baseTickValue / 4)) {
      maxTick = (baseTickValue / 4);
    } else if (maxValue < (baseTickValue / 2)) {
      maxTick = (baseTickValue / 2);
    } else if (maxValue < baseTickValue) {
      maxTick = baseTickValue;
    } else if (maxValue > baseTickValue) {
      maxTick = (baseTickValue * 2);
    } else if (maxValue > baseTickValue * 2) {
      maxTick = (baseTickValue * 4);
    } else if (maxValue > baseTickValue * 4) {
      maxTick = (baseTickValue * 8);
    }

    return maxTick;
  } // end getMaxTicks()

  getAveragePaymentDays(): Observable<any> {
    return this.http.get(this.api.endpoints.averagePaymentDaysReport +
      '?serviceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getAgeAnalysis(): Observable<any> {
    return this.http.get(this.api.endpoints.ageAnalysisReport +
      '?serviceProviderId=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getCurrentMonthly(): Observable<any> {
    return this.http.get(this.api.endpoints.currentMonthlyReport +
      '?id=' + this.api.serviceProviderID, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
      );
  }

  getTaxLiabilityData(): Observable<any> {
    return this.http.get(this.api.endpoints.practiceManagement + 
        '/api/TaxLiabilityWidgetData?ServiceProviderID=' + this.api.serviceProviderID, this.api.httpOptions
    ).pipe(
      catchError(this.api.handleError)
    );
  } // end getTaxLiabilityData()

} // end DashboardDataService{}
