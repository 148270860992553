import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Contacts, ContactsHandle, ContactsService, DEFAULT_CONTACTS } from '../../../services/contacts/contacts.service';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-add-new-attorney',
  templateUrl: './add-new-attorney.component.html',
  styleUrls: ['./add-new-attorney.component.scss']
})
export class AddNewAttorneyComponent implements OnInit {

  title = 'Add new Attorney';
  newAttorney: Contacts = DEFAULT_CONTACTS;
  @Input() isVisible: boolean;
  @Input() selectedLawfirm: Contacts;
  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSaveAttorney: EventEmitter<Contacts> = new EventEmitter<Contacts>();

  get isSaveable() {
    return Boolean(this.newAttorney && this.newAttorney.FullName) &&
    Boolean(this.newAttorney && this.newAttorney.Email) &&
    Boolean(this.newAttorney && this.newAttorney.Cell);
  } // end isSaveable()

  constructor(
    private util: UtilitiesService,
    private loadingService: LoadingService,
    private contactsService: ContactsService
  ) { 
    this.newAttorney = {
      AccountHolder: '',
      AccountNumber: '',
      AccountType: '',
      BranchCode: '',
      Cell: '',
      CollectThreshold: '',
      ContactContactType: 0,
      Locality: '',
      ContactLocality: 0,
      DefaultDailyRate: 0,
      DefaultHourlyRate: 0,
      DefaultPerKilometerRate: 0,
      Docex: '',
      Email: '',
      Fax: '',
      FeeTarget: 0,
      FullName : '',
      Id: 0,
      Identity: '',
      Image: '',
      IncomeTarget: 0,
      LastStatementDownload: '',
      Level: '',
      OfficeRoom: '',
      Phone: '',
      PostalAddress: '',
      SecurityLinks: '',
      Speciality: '',
      Status: '',
      StreetAddress: '',
      Suburb: '',
      ToCollect: 0,
      UserName: '',
      Vatno: '',
      WebSite: '',
      ZipCode: '',
      IsSelected: false,
      AdmissionDay : null,
      AdmissionMonth : null,
      AdmissionYear : null,
      AdmissionDate : null,
      AdmissionDateStr : '',
      RegionalBarId : 0,
      RegionalBarName : '',
      ChamberID: 0,
      TenantID: '',
      Domain: '',
      ContactTypeID: 2,
      Ethincity: 'African',
      Gender: 'Male',
      Chamber: ''
    };
  } // end constructor()

  ngOnInit(): void {
    if (this.selectedLawfirm) {
      this.title = 'Add new Attorney for ' + this.selectedLawfirm.FullName;
    }
  } // end ngOnInit()

  dismiss() {
    this.newAttorney = {
      AccountHolder: '',
      AccountNumber: '',
      AccountType: '',
      BranchCode: '',
      Cell: '',
      CollectThreshold: '',
      ContactContactType: 0,
      Locality: '',
      ContactLocality: 0,
      DefaultDailyRate: 0,
      DefaultHourlyRate: 0,
      DefaultPerKilometerRate: 0,
      Docex: '',
      Email: '',
      Fax: '',
      FeeTarget: 0,
      FullName : '',
      Id: 0,
      Identity: '',
      Image: '',
      IncomeTarget: 0,
      LastStatementDownload: '',
      Level: '',
      OfficeRoom: '',
      Phone: '',
      PostalAddress: '',
      SecurityLinks: '',
      Speciality: '',
      Status: '',
      StreetAddress: '',
      Suburb: '',
      ToCollect: 0,
      UserName: '',
      Vatno: '',
      WebSite: '',
      ZipCode: '',
      IsSelected: false,
      AdmissionDay : null,
      AdmissionMonth : null,
      AdmissionYear : null,
      AdmissionDate : null,
      AdmissionDateStr : '',
      RegionalBarId : 0,
      RegionalBarName : '',
      ChamberID: 0,
      TenantID: '',
      Domain: '',
      ContactTypeID: 2,
      Ethincity: 'African',
      Gender: 'Male',
      Chamber: ''
    };
    this.closeModal.emit('add-new-attorney');
  } // dismiss()

  onAddAttorney() {
    this.loadingService.showOverlay();
    if (this.newAttorney && this.newAttorney.FullName &&
        this.newAttorney && this.newAttorney.Cell &&
        this.newAttorney && this.newAttorney.Email) {
          this.contactsService.postNewAttorneyInFirm(this.newAttorney, this.selectedLawfirm.Id).subscribe({ next: (data) => {
            // On next
          },
          error: (error) => {
            // On error
            this.loadingService.hideOverlay();
          },
          complete: () => {
            // On complete
            this.newAttorney = {
              AccountHolder: '',
              AccountNumber: '',
              AccountType: '',
              BranchCode: '',
              Cell: '',
              CollectThreshold: '',
              ContactContactType: 0,
              Locality: '',
              ContactLocality: 0,
              DefaultDailyRate: 0,
              DefaultHourlyRate: 0,
              DefaultPerKilometerRate: 0,
              Docex: '',
              Email: '',
              Fax: '',
              FeeTarget: 0,
              FullName : '',
              Id: 0,
              Identity: '',
              Image: '',
              IncomeTarget: 0,
              LastStatementDownload: '',
              Level: '',
              OfficeRoom: '',
              Phone: '',
              PostalAddress: '',
              SecurityLinks: '',
              Speciality: '',
              Status: '',
              StreetAddress: '',
              Suburb: '',
              ToCollect: 0,
              UserName: '',
              Vatno: '',
              WebSite: '',
              ZipCode: '',
              IsSelected: false,
              AdmissionDay : null,
              AdmissionMonth : null,
              AdmissionYear : null,
              AdmissionDate : null,
              AdmissionDateStr : '',
              RegionalBarId : 0,
              RegionalBarName : '',
              ChamberID: 0,
              TenantID: '',
              Domain: '',
              ContactTypeID: 2,
              Ethincity: 'African',
              Gender: 'Male',
              Chamber: ''
            };
            this.onSaveAttorney.emit(this.selectedLawfirm);
            this.loadingService.hideOverlay();
          }
        });
      }
  } // end onAddAttorney()
} // end AddNewAttorneyComponent {}
