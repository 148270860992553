<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="modal" *ngIf="isVisible">
  <div class="modal-content">
    <div class="modal-dialog">

      <div class="modal-header">
        <h2>{{title}}</h2>
        <button class="modal-dismiss" (click)="dismiss()">
          <i class="fa fa-close"></i>
        </button>
      </div> <!-- #end .modal-header -->

      <div class="modal-body form">
        <div class="row">

          <div class="form-group">
            <label for="advocate-name">Business Relationship Name</label>
            <input
              required
              [(ngModel)]="selectedRelation"
              [typeahead]="advocates"
              typeaheadOptionField="Advocate"
              [typeaheadSingleWords]="false"
              (input)="onInput($event)"
              class="advocates"
              placeholder="Select or start typing"
              (typeaheadOnSelect)="onSelect($event)"
              [typeaheadOptionsLimit]="50"
              typeaheadSCrollable="true"
              (blur)="onAdvocateChange()"
              typeaheadOptionsInScrollableView="20"
              [typeaheadMinLength]="0">
          </div>

          <div class="form-group">
            <label for="type">Type</label>
            <select
              id="type"
              [(ngModel)]="selectedRelationshipStatus">
              <option value="" disabled selected hidden>Select Relationship type</option>
              <option
                *ngFor="let type of businessType"
                [value]="type.value"
                [selected]="type.value === selectedRelationshipStatus">{{type.label}}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="cell">Contact Number</label>
            <input
              readonly
              id="cell"
              class="input"
              placeholder="Cell number"
              [(ngModel)]="contactRelationship.CellPhone">
          </div>

          <div class="form-group">
            <label for="email">Contact Email</label>
            <input
              readonly
              id="email"
              class="input"
              placeholder="Email"
              [(ngModel)]="contactRelationship.Email">
          </div>

        </div>
      </div> <!-- #end .modal-body .form -->

      <div class="modal-footer">
        <button class="btn btn-danger" (click)="onCancel()">CANCEL</button>
        <button
          class="btn btn-success"
          (click)="onSave()"
          [class.disabled]="!isSaveable"
          [disabled]="!isSaveable">SAVE</button>
      </div>

    </div> <!-- #end .modal-dialog -->
  </div> <!-- #end .modal-content -->
</div> <!-- #end .modal -->